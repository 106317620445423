import { Button } from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ErrorIcon from '@material-ui/icons/Error';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import CachedIcon from '@material-ui/icons/Cached';
import AddIcon from '@material-ui/icons/Add';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import ModalExchangeRefund from './ModalExchangeRefund';
import ModalRequestDevolutionRefund from './ModalRequestDevolutionRefund';
import API from '../../../../../services/api';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {}
}))(MenuItem);

const B2wAnswerBar = ({ ticketData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [exchangeRefundModalState, setExchangeRefundModalState] = useState(
    false
  );

  const [currentStep, setCurrentStep] = useState(0);

  const [openDevolutionModal, setOpenDevolutionModal] = useState(false);

  const [hasExchange, setHasExchange] = useState(false);
  const [canAttend, setCanAttend] = useState(false);
  const [allowCreateExchange, setAllowCreateExchange] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [devolutionRefundFlag, setDevolutionRefundFlag] = useState('');
  const [stepsLimit, setStepsLimit] = useState(1);
  const [causes, setCauses] = useState([]);
  const [items, setItems] = useState([]);

  const instances = ticketData?.marketplaceBundle?.dataTrocaReembolsos;

  const firstStep = 0;

  const existExchangeInformation = () => {
    if (instances.length > 0) {
      setHasExchange(true);
      instances.forEach(instance => {
        if (
          instance.action_canAttend === 'true' ||
          instance.action_canReleaseExchange === 'true' ||
          instance.action_canReleaseRefund === 'true' ||
          instance.action_canReportProblem === 'true' ||
          instance.action_canReturnPurchase === 'true'
        ) {
          setCanAttend(true);
        }
      });
    }
  };

  const handleCancelamentoClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCancelamentoClose = () => {
    setAnchorEl(null);
  };

  const handleHaveOpenExchange = () => {
    const haveOpenExchange = instances.filter(item => item.closed === 'false');

    if (haveOpenExchange.length === 0) {
      setAllowCreateExchange(true); // true to show btn new instance
    }
  };

  const handleStepsNext = () => {
    if (currentStep < stepsLimit) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleStepsPrev = () => {
    if (currentStep > firstStep) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleCloseModal = () => {
    setOpenDevolutionModal(false);
  };

  const handleOpenModal = flag => {
    setDevolutionRefundFlag(flag);
    setOpenDevolutionModal(true);
    const data = {
      ticketId: ticketData.id,
      action: flag,
      deliveryId: ticketData?.marketplaceBundle?.delivery_id,
      orderB2W: ticketData?.marketplaceBundle?.order_id
    };

    API.post('/auth/ms-ticket/v1/tickets/b2w/new-instance-1', data).then(
      response => {
        setCauses(response.data.causes);
        setItems(response.data.items);
        if (response.data.causes.length === 0) {
          enqueueSnackbar(t('i18n.b2w.modal.exchange.alert'), {
            variant: 'warning'
          });
          setOpenDevolutionModal(false);
        }
      }
    );
  };

  useEffect(() => {
    setHasExchange(false);
    setCanAttend(false);
    existExchangeInformation();
    handleHaveOpenExchange();
  }, [ticketData]);

  return (
    <>
      {allowCreateExchange && (
        <>
          <Button
            onClick={e => {
              handleCancelamentoClick(e);
            }}
            color="primary"
          >
            <AddIcon fontSize="small" className="header-btn--icon" />
            Nova Instância
          </Button>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCancelamentoClose}
          >
            <StyledMenuItem
              onClick={() => {
                handleOpenModal('EXCHANGE');
                setStepsLimit(1);
                setCurrentStep(0);
              }}
            >
              <ListItemIcon>
                <CompareArrowsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Trocar produto" />
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => {
                handleOpenModal('RETURN');
                setCurrentStep(0);
                setStepsLimit(2);
              }}
            >
              <ListItemIcon>
                <CachedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Reembolso" />
            </StyledMenuItem>
          </StyledMenu>
        </>
      )}
      {hasExchange && (
        <Button
          onClick={() => {
            setExchangeRefundModalState(true);
          }}
          color="primary"
        >
          {canAttend ? (
            <ErrorIcon
              style={{ fill: 'red' }}
              fontSize="small"
              className="header-btn--icon"
            />
          ) : (
            <AutorenewIcon fontSize="small" className="header-btn--icon" />
          )}
          {t('i18n.b2w.phrases.exchangerefund')}
        </Button>
      )}
      <ModalExchangeRefund
        ticketData={ticketData}
        openState={exchangeRefundModalState}
        closeParent={() => {
          setExchangeRefundModalState(false);
        }}
      />

      <ModalRequestDevolutionRefund
        ticketData={ticketData}
        openState={openDevolutionModal}
        closeParent={() => {
          setOpenDevolutionModal(false);
        }}
        modalType={devolutionRefundFlag}
        stepsLimit={stepsLimit}
        currentStep={currentStep}
        handleStepsNext={handleStepsNext}
        handleStepsPrev={handleStepsPrev}
        firstStep={firstStep}
        handleCloseModal={handleCloseModal}
        causes={causes}
        items={items}
      />
    </>
  );
};

export default B2wAnswerBar;
