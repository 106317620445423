import React from 'react';
import { Pie } from '@ant-design/plots';

const DemoPie2 = ({ total, list, colors }) => {
  const data = list;
  const config = {
    appendPadding: 100,
    data,
    angleField: 'answered',
    colorField: 'attendant',
    color: colors,
    autoFit: true,
    radius: 1,
    innerRadius: 0.6,

    legend: false,
    label: false,
    interactions: [
      {
        type: 'element-selected'
      },
      {
        type: 'element-active'
      }
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontFamily: 'Roboto'
        },
        content: total
      }
    }
  };
  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        height: '240px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div style={{ width: '710px', position: 'absolute' }}>
        <Pie {...config} />
      </div>
    </div>
  );
};

export { DemoPie2 };
