import { IconButton, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { COLORS } from '../settings/colors.config';

const RemoveFilterCardButton = styled(IconButton)`
  margin-top: -10px;
  margin-right: -15px;
  svg {
    font-size: 16px;
  }
`;

const cardStyle = {
  background: '#eee',
  padding: '0px 10px'
};

const typographyStyle = {
  color: COLORS.greenText,
  textTransform: 'capitalize'
};

const FilterCardHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
`;

const CustomTypography = styled(Typography)`
  color: ${COLORS.grayText};
  font-size: 12px;
`;

const CustomTypographyDescribe = styled(Typography)`
  font-size: 12px;
  color: ${COLORS.grayText};
`;

const CustomChip = styled('div')`
  display: flex;
  align-items: top;
  border: 1px solid #ccc;
  padding: 0px 5px 3px;
  border-radius: 3px;
  margin-right: 5px;
  justify-content: space-between;
  background: ${COLORS.gray};
  font-size: 12px;
  margin-top: 5px;
  color: ${COLORS.grayText};
  p {
    padding-top: 5px;
  }

  button {
    margin-left: 5px;
    border: 0px;
    padding: 0px 3px 5px;
    color: ${COLORS.greenText};
    cursor: pointer;
  }
`;

export {
  RemoveFilterCardButton,
  cardStyle,
  typographyStyle,
  FilterCardHeader,
  CustomTypography,
  CustomTypographyDescribe,
  CustomChip
};
