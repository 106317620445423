import { Grid, Box } from '@material-ui/core';
import React from 'react';
import { CustomTypograph } from '../components/custom.components';
import DonutCard from '../components/PresaleOnline/DonutCard';
import { getDashboardData } from './config';

const DashboardContent = ({ dataEquipDash, dashboardFilter, isAdmin }) => {
  const dashboardData = getDashboardData(dataEquipDash);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Grid container spacing={1} justifyContent="flex-start">
            <Grid item xl={12} lg={12} md={12}>
              <Box mb={2}>
                <CustomTypograph variant="h3">
                  Pré-venda <b>Online B2B</b>
                </CustomTypograph>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {dashboardData.map(
              ({
                title,
                cardType,
                data,
                totalItem,
                goalPercent,
                tooltipText,
                goalColor,
                goalText,
                type
              }) => (
                <Grid item sm={12} md={4} xl={4} xs={4} key={Math.random()}>
                  <DonutCard
                    {...{
                      title,
                      cardType,
                      data,
                      totalItem,
                      goalPercent,
                      tooltipText,
                      dashboardFilter,
                      isAdmin,
                      goalColor,
                      goalText,
                      type,
                      isB2B: true,
                      hideMeta: true
                    }}
                    showMoreDetails
                  />
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardContent;
