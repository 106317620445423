import React from 'react';
import { useTranslation } from 'react-i18next';

export default function RaStatusTranslate({ status }) {
  const { t } = useTranslation();

  if (status === 5) {
    return <>{t('i18n.ticketcontainer.RA_NOT_ANSWERED')}</>;
  }

  if (status === 6) {
    return <>{t('i18n.ticketcontainer.RA_ANSWERED')}</>;
  }

  if (status === 7) {
    return <>{t('i18n.ticketcontainer.RA_CONSUMER_ANSWER')}</>;
  }

  if (status === 8) {
    return <>{t('i18n.ticketcontainer.RA_COMPANY_ANSWER')}</>;
  }

  if (status === 9) {
    return <>{t('i18n.ticketcontainer.RA_RATED')}</>;
  }

  if (status === 10) {
    return <>{t('i18n.ticketcontainer.RA_FROZEN')}</>;
  }

  if (status === 12) {
    return <>{t('i18n.ticketcontainer.RA_DISABLED_BY_THE_CONSUMER')}</>;
  }

  if (status === 13) {
    return <>{t('i18n.ticketcontainer.RA_INACTIVE_IN_RECLAME_AQUI')}</>;
  }

  if (status === 18) {
    return <>{t('i18n.ticketcontainer.RA_RATED_RESOLVED')}</>;
  }

  if (status === 19) {
    return <>{t('i18n.ticketcontainer.RA_RATED_UNRESOLVED')}</>;
  }

  if (status === 20) {
    return <>{t('i18n.ticketcontainer.RA_ANSWER')}</>;
  }

  return <>{t('i18n.ticketcontainer.RA_STATUS_OPEN')}</>;
}
