import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Grid } from '@material-ui/core';
import Page from '../../../components/Page';
import AcerModal from '../../../components/AcerModal';
import { useMarketplaceListPreSale } from '../../../hooks/hooks';
import formHelper from '../../../utils/formHelper';
import {
  getProductFromBundle,
  getProductIdFromBundle
} from '../services/presaleServices';
import PreSaleChatTabs from './preSaleChatTabs.component';

const PreSaleChatModal = ({ openState, closeModal, chatModalData }) => {
  const { t } = useTranslation();
  const marketPlaces = useMarketplaceListPreSale();

  const actualProductInfo = getProductFromBundle(chatModalData.actualProtocol);
  const messagesActualProduct = [];
  const messagesAllproducts = [];

  const getMessages = () => {
    const protocolsOrderedByProduct = chatModalData.protocols?.sort(
      formHelper.compareItemId
    );
    if (protocolsOrderedByProduct) {
      protocolsOrderedByProduct.forEach(protocol => {
        if (
          getProductIdFromBundle(protocol) === actualProductInfo.id &&
          protocol.marketplaceBundle.status !== 'DELETED'
        ) {
          protocol.marketplaceGenericMessages.forEach(element => {
            messagesActualProduct.push({
              ...element,
              id: protocol.id,
              productInfo: getProductFromBundle(protocol)
            });
          });
        }
        protocol.marketplaceGenericMessages.forEach(el => {
          messagesAllproducts.push({
            ...el,
            productInfo: getProductFromBundle(protocol)
          });
        });
      });
    }
  };

  useEffect(() => {
    if (openState) {
      getMessages();
    }
  }, [openState]);

  return (
    <AcerModal
      fullWidth
      maxWidth="md"
      open={openState}
      modalTitle={`${
        marketPlaces.find(m => m.value === chatModalData.marketplace)?.text
      } / ${t('i18n.presale.CHAT_WITH')} / ${chatModalData.customerName}`}
      onClose={closeModal}
    >
      <Page>
        <Container>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <PreSaleChatTabs
                  chatModalData={chatModalData}
                  messagesAllproducts={messagesAllproducts}
                  messagesActualProduct={messagesActualProduct}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Page>
    </AcerModal>
  );
};

export default PreSaleChatModal;
