import React from 'react';
import { CardGoalInfoText } from './styles';

const GoalInfo = ({ bgColor, textColor, textValue }) => {
  return (
    <div
      style={{
        color: textColor,
        backgroundColor: bgColor,
        padding: '8px 24px',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '20px'
      }}
    >
      <CardGoalInfoText>{textValue}</CardGoalInfoText>
    </div>
  );
};

export default GoalInfo;
