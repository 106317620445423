import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  Grid,
  Paper,
  Typography,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import RefreshIcon from '@material-ui/icons/Refresh';
import Page from '../../../../components/Page';
import ListMarketplaces from './ListMarketplaces.component';
import Log from './Log.component';
import { COLORS } from '../../../../styles/settings/colors.config';
import timezoneFormat from '../../../../utils/timezoneFormat';

import API from '../../../../services/api';
// import VisitorsWebpage from './VisitorsWebpage';
import HeaderTitle from '../../../../components/HeaderTitle';

const useStyles = makeStyles(() => ({
  paperList: {
    padding: '20px 20px 10px 20px',
    height: '100%'
  },
  paperVisitor: {
    padding: '20px 20px 0px 20px',
    height: '100%'
  },
  typoPageTitle: {
    fontSize: '20px',
    marginLeft: '24px',
    marginTop: '15px',
    marginBottom: '20px'
  },
  typoWeight: {
    fontWeight: 400,
    color: `${COLORS.blackText}`
  },
  spinner: {
    marginInlineStart: '15px',
    marginBlockStart: '5px'
  },
  iconRefresh: {
    marginBlockStart: '-3px'
  }
}));

const MonitorMarketplace = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [apiName, setApiName] = useState('');
  const [apiStatus, setApiStatus] = useState('');
  const [apiDate, setApiDate] = useState('');
  const [apisCondition, setApisCondition] = useState([]);
  const [apiDetail, setApiDetail] = useState([]);
  // const [quantityVisitors, setQuantityVisitors] = useState(0);

  const compareText = (a, b) => {
    const textA = a.text.toUpperCase();
    const textB = b.text.toUpperCase();
    let comparison = 0;
    if (textA > textB) {
      comparison = 1;
    } else if (textA < textB) {
      comparison = -1;
    }
    return comparison;
  };

  const getContent = (manual = false) => {
    if (manual) {
      setApiName('');
      setApiStatus('');
      setApiDate('');
      setApiDetail([]);
    }
    setApisCondition([]);
    setLoading(true);

    API.get(`/auth/lambda/monitor/get-status-monitor`)
      .then(response => {
        setApisCondition(response.data.sort(compareText));
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.monitor.STATUS_REQUEST_ERROR'), {
          variant: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });

    // API.get(`/auth/ms-chat/chats/all-visitors-number`)
    //   .then(response => {
    //     setQuantityVisitors(response.data);
    //   })
    //   .catch(() => { });
  };

  const getDetail = (status, name, date, logId) => {
    if (status !== 'green') {
      setLoading(true);
      API.get(`/auth/lambda/monitor/get-log-detail?idlog=${logId}`)
        .then(response => {
          setApiDetail(response.data.logs);
        })
        .catch(() => {
          enqueueSnackbar(t('i18n.monitor.DETAIL_REQUEST_ERROR'), {
            variant: 'error'
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setApiDetail([]);
    }
    setApiStatus(status);
    setApiName(name);
    setApiDate(date);
  };

  useEffect(() => {
    getContent();
    const callEndpointTimer = setInterval(() => {
      getContent();
    }, 60000);
    return () => {
      clearInterval(callEndpointTimer);
    };
  }, []);

  return (
    <Page title="Monitor" style={{ overflow: 'hidden' }}>
      <Container maxWidth={false}>
        <Box mt={3}>
          <Grid
            container
            spacing={0}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={3}>
              <HeaderTitle title={t('i18n.monitor.PAGE_TITLE')} />
            </Grid>
            <Grid
              item
              container
              spacing={0}
              xs={9}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Typography
                  variant="h6"
                  align="right"
                  className={classes.typoWeight}
                >
                  {`${t('i18n.monitor.LAST_FETCH')}: ${timezoneFormat(
                    new Date(),
                    'DD/MM HH:mm'
                  )}`}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {loading ? (
                  <CircularProgress size={20} className={classes.spinner} />
                ) : (
                  <Tooltip title={t('i18n.groupcontainer.REFRESH')}>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        getContent(true);
                      }}
                      className={classes.iconRefresh}
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper className={classes.paperList}>
                <ListMarketplaces
                  apisCondition={apisCondition}
                  getDetail={getDetail}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paperList}>
                <Log
                  apiName={apiName}
                  apiStatus={apiStatus}
                  apiDate={apiDate}
                  apiDetail={apiDetail}
                  loading={loading}
                />
              </Paper>
            </Grid>
          </Grid>
          {/* <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper className={classes.paperVisitor}>
                <VisitorsWebpage quantityVisitors={quantityVisitors} />
              </Paper>
            </Grid>
            <Grid item xs={6} />
          </Grid> */}
        </Box>
      </Container>
    </Page>
  );
};

export default MonitorMarketplace;
