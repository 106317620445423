import { Button, Divider, Grid, Typography, Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '@material-ui/icons/Error';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import timezoneFormat from '../../../../../utils/timezoneFormat';
import AcerModal from '../../../../../components/AcerModal';
import ModalProductStatus from './ModalProductStatus';
import ModalReportProblem from './ModalReportProblem';
import ModalRelease from './ModalRelease';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  icon: {
    fill: 'red',
    marginLeft: '1em'
  },
  divider: {
    marginTop: '1em'
  },
  button: {
    marginTop: '2em'
  },
  productDescription: {
    marginRight: '1.5em'
  },
  refund: {
    display: 'flex',
    alignItems: 'center'
  },
  refundIcon: {
    fontSize: '1em',
    fill: theme.palette.primary.main,
    marginInlineStart: '0.5em'
  }
}));

const ModalExchangeRefund = ({ ticketData, openState, closeParent }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [productStatusModal, setProductStatusModal] = useState(false);
  const [reportProblemModal, setReportProblemModal] = useState(false);
  const [releaseModal, setReleaseModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [instanceActive, setInstanceActive] = useState({});
  const [isReturnPurchase, setIsReturnPurchase] = useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <AcerModal
      fullWidth
      maxWidth="md"
      open={openState}
      modalTitle={t('i18n.b2w.phrases.exchangerefund')}
      onClose={closeParent}
    >
      <div className={classes.root}>
        {ticketData?.marketplaceBundle?.dataTrocaReembolsos.map(
          (instance, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index}>
                <Accordion
                  expanded={expanded === index}
                  onChange={handleChange(index)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {instance.type === 'Reembolso'
                        ? t('i18n.b2w.modal.exchangerefund.REFUND')
                        : t('i18n.b2w.modal.exchangerefund.EXCHANGE')}
                      : <strong>{instance?.id || '-'}</strong>
                    </Typography>
                    <Typography className={classes.heading}>
                      {t('i18n.b2w.modal.exchangerefund.DATE')}:{' '}
                      <strong>{timezoneFormat(instance.creationDate)}</strong>
                    </Typography>
                    {(instance.action_canAttend === 'true' ||
                      instance.action_canReleaseExchange === 'true' ||
                      instance.action_canReleaseRefund === 'true' ||
                      instance.action_canReportProblem === 'true' ||
                      instance.action_canReturnPurchase === 'true') && (
                      <ErrorIcon className={classes.icon} fontSize="small" />
                    )}
                    {(instance?.id === null || instance?.id === undefined) && (
                      <Alert severity="warning">
                        {t('i18n.b2w.modal.exchangerefund.MESSAGE')}
                      </Alert>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <Typography>
                          <strong>
                            {instance.type === 'Reembolso'
                              ? t('i18n.b2w.modal.exchangerefund.REFUND')
                              : t('i18n.b2w.modal.exchangerefund.EXCHANGE')}
                            :{' '}
                          </strong>
                          {instance?.id || ''}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          <strong>
                            {t('i18n.b2w.modal.exchangerefund.CREATION_DATE')}:{' '}
                          </strong>
                          {timezoneFormat(instance.creationDate, 'DD/MM/YYYY')}{' '}
                          {t('i18n.b2w.modal.exchangerefund.CREATION_DATE_AT')}{' '}
                          {timezoneFormat(instance.creationDate, 'HH:mm')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          <strong>
                            {t('i18n.b2w.modal.exchangerefund.CREATED_BY')}:{' '}
                          </strong>
                          {instance.userCreate}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          <strong>
                            {t('i18n.b2w.modal.exchangerefund.DEADLINE')}:{' '}
                          </strong>
                          {instance?.endTaskDate &&
                            timezoneFormat(
                              instance.endTaskDate,
                              'DD/MM/YYYY'
                            )}{' '}
                          {instance?.endTaskDate &&
                            t(
                              'i18n.b2w.modal.exchangerefund.CREATION_DATE_AT'
                            )}{' '}
                          {instance?.endTaskDate &&
                            timezoneFormat(instance.endTaskDate, 'HH:mm')}
                        </Typography>
                      </Grid>
                      {instance?.refunds && instance?.refunds.length > 0 && (
                        <Grid item>
                          <div className={classes.refund}>
                            <Typography>
                              <strong>
                                {t('i18n.b2w.modal.exchangerefund.PAYMENT')}:{' '}
                              </strong>
                              {instance.refunds[0].name
                                .replaceAll('ï¿½', 'é')
                                .replaceAll('Cartéo', 'Cartão')}{' '}
                              {Number(instance.refunds[0].value).toLocaleString(
                                'pt-br',
                                {
                                  style: 'currency',
                                  currency: 'BRL'
                                }
                              )}
                            </Typography>
                            <Tooltip title={instance.refunds[0].information}>
                              <HelpOutlineIcon className={classes.refundIcon} />
                            </Tooltip>
                          </div>
                        </Grid>
                      )}
                      <Grid item>
                        <Typography>
                          <strong>
                            {t('i18n.b2w.modal.exchangerefund.REASON')}:{' '}
                          </strong>
                          {instance.reason}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          <strong>
                            {t(
                              'i18n.b2w.modal.exchangerefund.REASON_DESCRIPTION'
                            )}
                            :{' '}
                          </strong>
                          {instance.reasonDescription}
                        </Typography>
                      </Grid>
                      <Divider className={classes.divider} />
                      <Grid item container>
                        <Grid item md={8}>
                          <Typography>
                            <strong>
                              {t('i18n.b2w.modal.exchangerefund.PRODUCT')}
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid item md={2}>
                          <Typography>
                            <strong>
                              {t(
                                'i18n.b2w.modal.exchangerefund.PRODUCT_QUANTITY'
                              )}
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid item md={2}>
                          <Typography>
                            <strong>
                              {t('i18n.b2w.modal.exchangerefund.PRODUCT_PRICE')}
                            </strong>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      {instance?.originalItems?.map(product => (
                        <Grid
                          item
                          container
                          direction="column"
                          key={product.id}
                        >
                          <Grid item container>
                            <Grid item md={8}>
                              <Typography
                                className={classes.productDescription}
                              >
                                {product.name}
                              </Typography>
                            </Grid>
                            <Grid item md={2}>
                              <Typography> {product.quantity}</Typography>
                            </Grid>
                            <Grid item md={2}>
                              <Typography>
                                {Number(product.salePrice).toLocaleString(
                                  'pt-br',
                                  {
                                    style: 'currency',
                                    currency: 'BRL'
                                  }
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                      {instance.tracking &&
                        instance.tracking.length > 0 &&
                        instance.tracking.map(track => (
                          <Grid
                            item
                            container
                            direction="column"
                            key={`${track.label}${track.date}`}
                          >
                            <Alert
                              severity={
                                track.name === 'Problema Informado'
                                  ? 'warning'
                                  : 'success'
                              }
                            >
                              {`${timezoneFormat(track.date)} - ${track.name}`}
                            </Alert>
                          </Grid>
                        ))}
                      <Grid
                        item
                        container
                        justifyContent="flex-end"
                        spacing={1}
                        className={classes.button}
                      >
                        <Grid item>
                          {instance.action_canReturnPurchase === 'true' && (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                setInstanceActive(instance);
                                setIsReturnPurchase(true);
                                setProductStatusModal(true);
                              }}
                            >
                              {t(
                                'i18n.b2w.modal.exchangerefund.ACTION_RETURN_PURCHASE'
                              )}
                            </Button>
                          )}
                        </Grid>
                        <Grid item>
                          {instance.action_canReportProblem === 'true' && (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                setInstanceActive(instance);
                                setReportProblemModal(true);
                              }}
                            >
                              {t(
                                'i18n.b2w.modal.exchangerefund.ACTION_REPORT_PROBLEM'
                              )}
                            </Button>
                          )}
                        </Grid>
                        <Grid item>
                          {instance.action_canReleaseRefund === 'true' && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setInstanceActive(instance);
                                setReleaseModal(true);
                              }}
                            >
                              {t(
                                'i18n.b2w.modal.exchangerefund.ACTION_RELEASE_REFUND'
                              )}
                            </Button>
                          )}
                        </Grid>
                        <Grid item>
                          {instance.action_canReleaseExchange === 'true' && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setInstanceActive(instance);
                                setReleaseModal(true);
                              }}
                            >
                              {t(
                                'i18n.b2w.modal.exchangerefund.ACTION_RELEASE_EXCHANGE'
                              )}
                            </Button>
                          )}
                        </Grid>
                        <Grid item>
                          {instance.action_canAttend === 'true' && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setInstanceActive(instance);
                                setProductStatusModal(true);
                              }}
                            >
                              {t(
                                'i18n.b2w.modal.exchangerefund.ACTION_FULFILL_REQUEST'
                              )}
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          }
        )}
      </div>
      <ModalProductStatus
        isReturnPurchase={isReturnPurchase}
        instance={instanceActive}
        ticketId={ticketData?.id}
        deliveryId={ticketData?.marketplaceBundle?.delivery_id}
        openState={productStatusModal}
        closeParent={() => {
          setProductStatusModal(false);
          setInstanceActive({});
        }}
      />
      <ModalReportProblem
        instance={instanceActive}
        ticketId={ticketData?.id}
        deliveryId={ticketData?.marketplaceBundle?.delivery_id}
        openState={reportProblemModal}
        closeParent={() => {
          setReportProblemModal(false);
          setInstanceActive({});
        }}
      />
      <ModalRelease
        instance={instanceActive}
        ticketId={ticketData?.id}
        deliveryId={ticketData?.marketplaceBundle?.delivery_id}
        openState={releaseModal}
        closeParent={() => {
          setReleaseModal(false);
          setInstanceActive({});
        }}
      />
    </AcerModal>
  );
};

export default ModalExchangeRefund;
