import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Tooltip,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InputMask from 'react-input-mask';
import { StyledTooltip } from '../../components/StyledTooltip';
import Page from '../../components/Page';
import { inputStyles } from '../../components/inputStyle';
import formHelper from '../../utils/formHelper';
import {
  PageTitle,
  TypoTitle,
  DividerTitle,
  PaperParam,
  CardParam,
  DescriptionParam,
  BoxParam,
  BoxActions,
  InputValue
} from './styles';
import API from '../../services/api';
import HeaderTitle from '../../components/HeaderTitle';

const Setup = () => {
  const classes = inputStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [enabled, setEnabled] = useState('true');
  const [id, setId] = useState(null);
  const [formData, setFormdata] = useState(
    formHelper.simpleData2FormData({
      statusBusyLimit: {
        required: true,
        timeField: true
      }
    })
  );

  const isFormOk = () => {
    const result = formHelper.validaForm(formData, setFormdata);
    if (!result) {
      enqueueSnackbar('Preencha corretamente todos os campos', {
        variant: 'error'
      });
    }
    return result;
  };

  const convertToMinutes = value => {
    if (value === '--:--') {
      return 0;
    }
    const [hours, minutes] = value.split(':');
    return Number(hours) * 60 + Number(minutes);
  };

  const mintuesToHm = value => {
    if (value === '0' || !value) {
      setEnabled('false');
    }
    const hours = value / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    const hDisplay = `${rhours < 10 ? `0${rhours}` : rhours}`;
    const mDisplay = `${rminutes < 10 ? `0${rminutes}` : rminutes}`;
    return `${hDisplay}:${mDisplay}`;
  };

  const submitForm = async () => {
    if (isFormOk()) {
      const minutes = String(
        convertToMinutes(formHelper.formData2PostData(formData).statusBusyLimit)
      );
      const postData = {
        id,
        value: minutes,
        name: 'MIN_WARNING_BUSY_STATUS_USER',
        description: 'MIN_WARNING_BUSY_STATUS_USER',
        status: minutes !== '0'
      };
      try {
        await API.post('/auth/ms-ticket/v1/parameters', postData);
        if (postData.value === '0') {
          setEnabled('false');
        }
        formHelper.setFormvalue(
          formData,
          setFormdata,
          'statusBusyLimit',
          mintuesToHm(minutes)
        );
        enqueueSnackbar(t('i18n.newAutomation.SAVE_SUCCESS'), {
          variant: 'success'
        });
      } catch {
        enqueueSnackbar(t('i18n.simplephrases.ERROR'), { variant: 'error' });
      }
    }
  };

  const CustomTooltip = () => {
    return (
      <StyledTooltip>{t('i18n.setup.PARAM_USER_ALERT_HINT')}</StyledTooltip>
    );
  };

  const getParameters = () => {
    API.post('/auth/ms-ticket/v1/parameters/filter', {
      name: 'MIN_WARNING_BUSY_STATUS_USER'
    })
      .then(response => {
        if (response.data._embedded) {
          formHelper.setFormvalue(
            formData,
            setFormdata,
            'statusBusyLimit',
            mintuesToHm(
              response.data._embedded.systemParamsResourceList[0].value
            )
          );
          setId(response.data._embedded.systemParamsResourceList[0].id);
        } else {
          setEnabled('false');
          enqueueSnackbar(t('i18n.simplephrases.ERROR'), { variant: 'error' });
        }
      })
      .catch(() => {
        setEnabled('false');
      });
  };

  useEffect(() => {
    getParameters();
  }, []);

  useEffect(() => {
    formHelper.setFormvalue(
      formData,
      setFormdata,
      'statusBusyLimit',
      enabled === 'true' ? ' ' : '--:--'
    );
  }, [enabled]);

  return (
    <Page title="Configurações" style={{ overflow: 'hidden' }}>
      <Container maxWidth={false}>
        <Box mt={3}>
          <PageTitle>
            <HeaderTitle title={t('i18n.dashboardlayout.SETUP')} />
          </PageTitle>
          <DividerTitle />
          <PageTitle>
            <TypoTitle variant="h6">{t('i18n.groupcontainer.USERS')}</TypoTitle>
          </PageTitle>
          <CardParam>
            <PaperParam>
              <BoxParam mb={2}>
                <DescriptionParam>
                  <Typography>
                    {' '}
                    <b>Status</b>
                  </Typography>
                  <Tooltip title={<CustomTooltip />}>
                    <HelpOutlineIcon fontSize="small" color="primary" />
                  </Tooltip>
                </DescriptionParam>
                <Grid container alignItems="center">
                  <Grid item xs={7}>
                    <Typography>
                      {t('i18n.setup.PARAM_USER_ALERT')}
                      {' "'}
                      <b>{t('i18n.simplephrases.STATUS_BUSY')}</b>
                      {'": '}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="position"
                        row
                        value={enabled}
                        onChange={event => setEnabled(event.target.value)}
                      >
                        <FormControlLabel
                          value="true"
                          control={
                            <Radio
                              color="primary"
                              className={classes.radioGrey}
                            />
                          }
                          label={t('i18n.simplewords.ENABLE')}
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="false"
                          control={
                            <Radio
                              color="primary"
                              className={classes.radioGrey}
                            />
                          }
                          label={t('i18n.simplewords.DISABLE')}
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs={7} />
                    <Grid item xs={5}>
                      <InputValue>
                        <Typography
                          style={{
                            color: enabled === 'false' ? 'lightgrey' : 'inherit'
                          }}
                        >
                          Tempo para aparecer o alerta:
                        </Typography>
                        <InputMask
                          mask="99:99"
                          maskChar=""
                          value={formData.statusBusyLimit.value}
                          onChange={htmlElemtEvent => {
                            formHelper.setFormvalue(
                              formData,
                              setFormdata,
                              'statusBusyLimit',
                              htmlElemtEvent.target.value
                            );
                          }}
                          error={formData.statusBusyLimit.error}
                          disabled={enabled === 'false'}
                        >
                          {() => (
                            <TextField
                              label="hh:mm"
                              size="small"
                              variant="outlined"
                              style={{ width: '75px' }}
                            />
                          )}
                        </InputMask>
                      </InputValue>
                    </Grid>
                  </Grid>
                </Grid>
              </BoxParam>
              <BoxActions mt={5} mb={2}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!id}
                  onClick={() => {
                    submitForm();
                  }}
                >
                  {t('i18n.simplewords.SAVE')}
                </Button>
              </BoxActions>
            </PaperParam>
          </CardParam>
        </Box>
      </Container>
    </Page>
  );
};

export default Setup;
