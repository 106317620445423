import React from 'react';
import B2wAnswerBar from './b2w/B2wAnswerBar';
import CnovaAnswerBar from './cnova/CnovaAnswerBar';
import ReclameAquiAnswerBar from './reclame_aqui/ReclameAquiAnswerBar';
import MercadoLivreAnswerBar from './mercado_livre/MercadoLivreAnswerBar';
import MagaluAnswerBar from './magalu/MagaluAnswerBar';

const MarketPlaceToolbar = ({ ticketData }) => {
  if (!ticketData) return <></>;
  switch (ticketData.marketPlace) {
    case 'B2W':
      return <B2wAnswerBar ticketData={ticketData} />;
    case 'CNOVA':
      return <CnovaAnswerBar ticketData={ticketData} />;
    case 'RECLAME_AQUI_ACER_BRASIL':
    case 'RECLAME_AQUI_ACER_LOJA':
      return <ReclameAquiAnswerBar ticketData={ticketData} />;
    case 'MERCADO_LIVRE':
      return <MercadoLivreAnswerBar ticketData={ticketData} />;
    case 'MAGALU':
      if (
        ticketData.ticketStatus === 'CLOSED' ||
        ticketData.ticketStatus === 'RESOLVED'
      ) {
        return <MagaluAnswerBar ticketData={ticketData} />;
      }
      return <></>;
    default:
      return <></>;
  }
};

export default MarketPlaceToolbar;
