const getCapMessageForCustomer = (
  customerName,
  orderId,
  newCollectId,
  prazo
) => {
  const capMessageForCustomer = `Olá ${customerName || ''}, bom dia!
                
Para troca/cancelamento do produto relacionado ao Pedido ${orderId} pedimos que faça a impressão do termo de devolução anexo, preencha e coloque-o junto ao produto.
Segue código de postagem para que nos envie o produto, sem custo:

${newCollectId}

Data de validade: ${prazo}

Para postar o produto é necessário levá-lo até uma agência dos correios e informar o código da Autorização de Postagem.

O produto deve ser enviado na caixa original com todos os acessórios.

Para maior comodidade e agilidade no processo de postagem, sua encomenda deverá estar adequadamente embalada e fechada, acompanhada da nota fiscal afixado na parte externa da encomenda.

Pedimos por gentileza que após a postagem, nos informe o código de rastreio, para que possamos acompanhar o recebimento e posteriormente efetuarmos a troca/cancelamento do pedido.

Qualquer dúvida estamos à disposição!`;
  return capMessageForCustomer;
};
export default {
  getCapMessageForCustomer
};
