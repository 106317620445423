import { defaultHoursNumber } from '../services/config';
import { defaultMarketplacesDashboardFilter } from '../../../hooks/hooks';
import Auth from '../../../auth/auth.service';
import userService from '../../../services/user.service';
import { WEEK_INTERVAL } from '../services/constants';

const filterNameInStorage = 'messagesDashFilter';

const messagesTabName = 'messageDashboardTab';

const checkPermission = () => {
  if (Auth.getAccessToken() !== '' && Auth.getAccessToken() !== null) {
    if (userService.hasRole('dashboard_specialist_pos_sale'))
      return 'specialist';
    if (userService.hasRole('dashboard_analyst_pos_sale')) return 'analyst';
  }
  return false;
};

const defaultFilter = {
  attendant: checkPermission() === 'analyst' ? userService.getUsername() : '',
  dateAliasInAWayHumanCanRead: 'weekly',
  // eslint-disable-next-line camelcase
  report_type: '',
  status: '',
  tabView: 'messageDashboardTab',
  ticket_type: 'AFTER_SALE',
  periodType: 'weekly',
  isRealTime: false,
  marketplace: defaultMarketplacesDashboardFilter,
  startDate: WEEK_INTERVAL.startDate,
  endDate: WEEK_INTERVAL.endDate
};

export {
  defaultHoursNumber,
  defaultFilter,
  filterNameInStorage,
  messagesTabName
};
