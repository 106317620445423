import React from 'react';
import moment from 'moment';

import { Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { inputStyles } from './inputStyle';

export const formatDateToPayload = date => {
  if (date) {
    const dates = date.split(' ')[0].split('/');
    const hours = date.split(' ')[1];
    const day = dates[0];
    const month = dates[1];
    const year = dates[2];

    const formatDate = `${year}-${month}-${day}T${hours}`;

    return formatDate;
  }

  return null;
};

export const formatEndDateToPayload = date => {
  if (date) {
    const dates = date.split(' ')[0].split('/');
    const hours = date.split(' ')[1];
    const day = parseInt(dates[0], 10) + 1;
    const month = dates[1];
    const year = dates[2];

    const formatDate = `${year}-${month}-${
      day < 10 ? `0${day}` : day
    }T${hours}`;

    return formatDate;
  }

  return null;
};

export const formatEndDateToPayloadV2 = date => {
  if (date) {
    const dates = date.split(' ')[0].split('/');
    const hours = date.split(' ')[1];
    const day = dates[0];
    const month = dates[1];
    const year = dates[2];

    const newDate = new Date(`${year}-${month}-${day} ${hours}`);
    newDate.setDate(newDate.getDate() + 1);
    const formattedDate = moment(newDate).format('YYYY-MM-DDT02:59:59');
    return formattedDate;
  }

  return null;
};

export const formatDateUTC = date => {
  return moment(date).format(`YYYY-MM-DDT03:00:00`);
};

export const factoryDatesUTCV2 = creationDate => {
  const { startDate, endDate } = creationDate;

  if (!startDate || !endDate) {
    return '';
  }

  const start = moment(startDate).format(`DD/MM/YYYY 03:00:00`);

  const end = moment(endDate).format(`DD/MM/YYYY 03:00:00`);

  return { start, end };
};

export const factoryDatesUTC = creationDate => {
  const { startDate, endDate } = creationDate;

  if (!startDate || !endDate) {
    return '';
  }

  const start = moment(startDate).format(`DD/MM/YYYY 03:00:00`);
  const end = moment(endDate).format(`DD/MM/YYYY 03:00:00`);

  return { start, end };
};

export const factoryCreationDateUTC = creationDate => {
  const { startDate, endDate } = creationDate;

  if (!startDate || !endDate) {
    return '';
  }

  const start = moment(new Date(startDate))
    .add({ days: 1 })
    .format('DD/MM/YYYY 03:00:00');

  const end = moment(new Date(endDate))
    .add({ days: 1 })
    .format('DD/MM/YYYY 02:59:59');

  return `${start}-${end}`;
};

export const factoryCreationDate = creationDate => {
  const { startDate, endDate } = creationDate;

  if (!startDate || !endDate) {
    return '';
  }

  const start = moment(new Date(startDate))
    .add({ days: 1 })
    .format('DD/MM/YYYY 03:00:00');

  const end = moment(endDate)
    .add({ days: 1 })
    .format('DD/MM/YYYY 02:59:59');

  return `${start}-${end}`;
};

export const InputDateComponent = ({ form, setForm, name, label }) => {
  const classes = inputStyles();

  return (
    <TextField
      label={label}
      value={form[name] || ''}
      name={name}
      onChange={e => {
        setForm(e);
      }}
      className={classes.inputSmall}
      size="small"
      type="date"
      variant="outlined"
      fullWidth
      format="dd/MM/yyyy"
      InputLabelProps={{
        shrink: true
      }}
    />
  );
};

const DateComponent = ({ form, setForm }) => {
  const { t } = useTranslation();
  const classes = inputStyles();
  return (
    <Grid container spacing={2}>
      <Grid item lg={6}>
        <TextField
          size="small"
          type="date"
          label={t('i18n.dashboard.START_DATE')}
          variant="outlined"
          className={classes.inputSmall}
          format="dd/MM/yyyy"
          InputLabelProps={{
            shrink: true
          }}
          fullWidth
          value={form.startDate || ''}
          name="startDate"
          onChange={e => {
            setForm(e);
          }}
        />
      </Grid>
      <Grid item lg={6}>
        <TextField
          size="small"
          type="date"
          label={t('i18n.dashboard.END_DATE')}
          variant="outlined"
          format="dd/MM/yyyy"
          className={classes.inputSmall}
          InputLabelProps={{
            shrink: true
          }}
          fullWidth
          value={form.endDate || ''}
          name="endDate"
          onChange={e => {
            setForm(e);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DateComponent;
