import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { useParams } from 'react-router';
import { StyledMessage, StyledLink } from '../styles';

const SanitizedMessage = ({ message, imageLinks, color, openLinkNewTab }) => {
  const { id } = useParams();

  if (message) {
    const messageBlocks = message?.split('\n');

    const mountedMessage = messageBlocks?.map(item => {
      const rxp = /\[(.*?)\]/g;
      let messageParts;

      // Ajuste para corrigir um problema de duplicação de mensanges no protocolo 220823202659091970 em produção
      // O ajuste só serve para este caso específico. Não foram encontrados mais casos com o mesmo bug.
      if (id === '220823202659091970') {
        messageParts = message.split(rxp).filter(part => {
          return part.length > 6;
        });
      } else {
        messageParts = message.split(rxp);
      }

      if (messageParts.length > 1) {
        return (
          <div key={Math.random()}>
            {messageParts.map(msg => {
              const sanitized = msg.trim();

              if (
                sanitized.includes('http://') ||
                sanitized.includes('https://')
              ) {
                return (
                  <StyledLink
                    href={sanitized}
                    target="_blank"
                    rel="noreferrer"
                    key={Math.random()}
                  >
                    {sanitized}
                  </StyledLink>
                );
              }

              return (
                <Typography
                  key={Math.random()}
                  component="span"
                  display="inline"
                >
                  {sanitized}
                </Typography>
              );
            })}
          </div>
        );
      }

      return (
        <Typography
          key={Math.random()}
          className={imageLinks && 'message-link'}
          component="span"
          display="inline"
          style={{
            whiteSpace: 'pre-line',
            overflowWrap: 'break-word',
            color: `${color || ''}`
          }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(item || '\n')
          }}
          onClick={imageLinks && (() => openLinkNewTab(imageLinks))}
        />
      );
    });

    return mountedMessage;
  }
  return message;
};

const MessageWrapper = ({
  message,
  // score,
  openLinkNewTab,
  imageLinks,
  color = false
}) => {
  const { t } = useTranslation();

  if (message === 'i18n.ticket.DEFAULT_CLAIM_GENERIC_MESSAGE') {
    message = t(message);
  }
  if (imageLinks) {
    message = `${message}</br>${t('i18n.simplephrases.LINK_NEW_TAB')}`;
  }

  return (
    <StyledMessage>
      <SanitizedMessage {...{ message, imageLinks, color, openLinkNewTab }} />
    </StyledMessage>
  );
};

export default MessageWrapper;
