import {
  makeStyles,
  createStyles,
  FormControl,
  Select,
  MenuItem,
  Box
} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {
  CancelSection,
  OrdersHeaderSection,
  OrdersHeaderValue
} from '../../../Dialogs/styles';

const useStyles = makeStyles(() =>
  createStyles({
    inputSelect: {
      '& .MuiOutlinedInput-root': {
        height: '30px',
        fontSize: '12px',
        width: '90%',
        backgroundColor: 'white'
      }
    }
  })
);

const orderLocationList = [
  { id: 1, title: 'Acer' },
  { id: 2, title: 'Cliente' },
  { id: 3, title: 'Correios' },
  { id: 4, title: 'Suspensão de Entrega' },
  { id: 5, title: 'Extravio' }
];

const compare = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  // a deve ser igual a b
  return 0;
};

const TransportSectionHeader = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: #666666;
  margin-bottom: ${props => props.marginBottom}px;
`;

export default function CancelOptions({
  reasonCancellation,
  cancelDetail,
  orderLocation,
  setOrderLocation
}) {
  const classes = useStyles();
  return (
    <CancelSection>
      <Box display="flex" flexDirection="column" width="90%">
        <TransportSectionHeader marginBottom={10}>
          Qual o motivo do cancelamento?
        </TransportSectionHeader>
        <OrdersHeaderSection>
          <OrdersHeaderValue>{reasonCancellation}</OrdersHeaderValue>
        </OrdersHeaderSection>
      </Box>
      <Box display="flex" flexDirection="column" width="90%">
        <TransportSectionHeader marginBottom={10}>
          Detalhe do Cancelamento
        </TransportSectionHeader>
        <OrdersHeaderSection>
          <OrdersHeaderValue>{cancelDetail}</OrdersHeaderValue>
        </OrdersHeaderSection>
      </Box>
      <Box display="flex" flexDirection="column" width="90%">
        <TransportSectionHeader>
          Com quem está o produto?
        </TransportSectionHeader>
        <FormControl variant="outlined" className={classes.inputSelect}>
          <Select
            value={orderLocation || ''}
            onChange={e => {
              setOrderLocation(e.target.value);
            }}
          >
            <MenuItem disabled value="">
              Selecione
            </MenuItem>
            {orderLocationList
              .sort((a, b) => compare(a.title, b.title))
              .map(item => (
                <MenuItem value={item.title} key={Math.random()}>
                  {item.title}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </CancelSection>
  );
}
