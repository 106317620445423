const presaleOnlineB2BDashboardFilter = {};
const presaleOnlineB2BDashboardEquipData = {};
const presaleOnlineB2BDashboardIndividualData = {};
const presaleOnlineB2BDashboardLoading = true;
const presaleOnlineB2BDashboardRemovedKey = '';

const presaleOnlineB2BDashboardEquipDataReducer = (
  state = presaleOnlineB2BDashboardEquipData,
  action
) => {
  switch (action.type) {
    case 'SET_PRESALE_ONLINE_B2B_DASHBOARD_EQUIP_DATA':
      state = action.equipData;
      return state;
    default:
      return state;
  }
};

const presaleOnlineB2BDashboardRemovedKeyReducer = (
  state = presaleOnlineB2BDashboardRemovedKey,
  action
) => {
  switch (action.type) {
    case 'SET_PRESALE_ONLINE_B2B_DASHBOARD_REMOVED_KEY':
      state = action.key;
      return state;
    default:
      return state;
  }
};

const presaleOnlineB2BDashboardIndividualDataReducer = (
  state = presaleOnlineB2BDashboardIndividualData,
  action
) => {
  switch (action.type) {
    case 'SET_PRESALE_ONLINE_B2B_DASHBOARD_INDIVIDUAL_DATA':
      state = action.individualData;
      return state;
    default:
      return state;
  }
};

const presaleOnlineB2BDashboardLoadingReducer = (
  state = presaleOnlineB2BDashboardLoading,
  action
) => {
  switch (action.type) {
    case 'SET_PRESALE_ONLINE_B2B_DASHBOARD_LOADING':
      state = action.loading;
      return state;
    default:
      return state;
  }
};

const presaleOnlineB2BDashboardFilterReducer = (
  state = presaleOnlineB2BDashboardFilter,
  action
) => {
  switch (action.type) {
    case 'SET_PRESALE_ONLINE_B2B_DASHBOARD_FILTER':
      state = action.filter;
      return state;
    default:
      return state;
  }
};

export {
  presaleOnlineB2BDashboardFilterReducer,
  presaleOnlineB2BDashboardLoadingReducer,
  presaleOnlineB2BDashboardEquipDataReducer,
  presaleOnlineB2BDashboardIndividualDataReducer,
  presaleOnlineB2BDashboardRemovedKeyReducer
};
