import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  LinearProgress,
  makeStyles,
  Typography
} from '@material-ui/core';
// import ReactPlayer from 'react-player';
import { PictureAsPdf } from '@material-ui/icons';
import Lightbox from 'react-image-lightbox';
import API from '../../../../services/api';
import timezoneFormat from '../../../../utils/timezoneFormat';
import MessageWrapper from './MessageWrapper';
import fileDownload from './fileDownload';
import { FileContainer } from '../styles';
import marketPlacesEnum from '../../../../enums/marketPlaces.enum';

const MessageBaloonInside = ({
  showRecipients,
  marketPlace,
  mlClaimId,
  idIncidenteCNOVA,
  colorDefault,
  message,
  senderName
}) => {
  const { t } = useTranslation();
  const useStyles = makeStyles(() => ({
    root: {
      backgroundColor: colorDefault,
      paddingBottom: '16px!important'
    }
  }));
  const classes = useStyles();

  const {
    getFileFromUrl,
    getFileBase64FromUrl,
    openLinkNewTab,
    getFile
  } = fileDownload(marketPlace, mlClaimId, idIncidenteCNOVA);

  const [currentImage, setCurrentImage] = useState(false);
  const [openLightbox, setOpenLightbox] = useState(false);

  let messageToFormated = '';

  if (message?.to) {
    const translatedMessage = message.to
      .replace('CUSTOMER', 'Cliente')
      .replace('OPERATOR', 'Operador')
      .replace('Operator', 'Operador');

    if (translatedMessage.length > 9) {
      [messageToFormated] = translatedMessage.split('Operador');
      messageToFormated += ' e Operador';
    } else {
      messageToFormated = translatedMessage;
    }
  }

  const mediaLength =
    message?.mediaUrl0?.length > 0 || message?.MediaUrl0?.length > 0;

  let mediaUrl = false;
  let mediaContentType = false;
  let smsStatus = false;

  if (message?.MediaUrl0) {
    mediaUrl = message?.MediaUrl0[0];
  }

  if (message?.mediaUrl0) {
    mediaUrl = message?.mediaUrl0[0];
  }

  if (message?.mediaContentType0) {
    mediaContentType = message?.mediaContentType0[0];
  }

  if (message?.MediaContentType0) {
    mediaContentType = message?.MediaContentType0[0];
  }

  if (message?.smsStatus) {
    smsStatus = message?.smsStatus;
  }

  if (message?.SmsStatus) {
    smsStatus = message?.SmsStatus;
  }

  return (
    <Card>
      <CardContent className={classes.root}>
        {!mediaLength && (
          <MessageWrapper
            message={message?.message}
            score={message?.sentimentAnalysisScore}
            openLinkNewTab={openLinkNewTab}
            imageLinks={message.imageLink}
            smsStatus={smsStatus}
          />
        )}

        {/* {mediaLength &&
          (mediaContentType === 'video/mp4' ||
            mediaContentType === 'audio/ogg') && (
            <Box mb={2}>
              <ReactPlayer
                url={mediaUrl}
                controls
                width="100%"
                height={mediaContentType === 'audio/ogg' ? 50 : '100%'}
              />
            </Box>
          )} */}

        {mediaLength && mediaContentType === 'image/jpeg' && (
          <Box display="flex" justifyContent="center">
            <div
              onClick={() => {
                setCurrentImage(mediaUrl);
                setOpenLightbox(true);
              }}
              aria-hidden="true"
            >
              <img src={mediaUrl} alt="wpp" width="100%" />
            </div>
          </Box>
        )}

        {mediaLength &&
          mediaContentType !== 'image/jpeg' &&
          mediaContentType !== 'video/mp4' &&
          mediaContentType !== 'audio/ogg' && (
            <FileContainer
              onClick={() => {
                getFileFromUrl(mediaUrl);
              }}
            >
              <span>
                <PictureAsPdf />
              </span>{' '}
              <span style={{ fontFamily: 'Roboto' }}>{message.message}</span>
            </FileContainer>
          )}

        {openLightbox && (
          <Lightbox
            mainSrc={currentImage}
            onCloseRequest={() => {
              setOpenLightbox(false);
              setCurrentImage(false);
            }}
          />
        )}

        <Grid container>
          <Grid item lg={12}>
            <Typography
              color="textSecondary"
              align="right"
              variant="caption"
              component="h2"
            >
              {`${timezoneFormat(message.date)
                .split(' ')
                .join(' às ')} - `}
              {senderName()}
            </Typography>
          </Grid>
        </Grid>
        {showRecipients && (
          <Grid container>
            <Grid item lg={12}>
              <Typography
                color="textSecondary"
                align="right"
                variant="caption"
                component="h2"
              >
                {[
                  marketPlacesEnum.CARREFOUR,
                  marketPlacesEnum.KABUM,
                  marketPlacesEnum.COLOMBO
                ].includes(marketPlace) &&
                  message?.to &&
                  `${t('i18n.newAutomation.recipients')}: ${messageToFormated}`}
              </Typography>
            </Grid>
          </Grid>
        )}
        {message.status && <LinearProgress />}
        {message.attachments &&
          marketPlace !== marketPlacesEnum.VIVO &&
          message.attachments.map((m, index) => {
            return (
              <span key={m.key || index}>
                <Button
                  onClick={() => {
                    getFile(
                      m?.name || m?.filename,
                      m?.key,
                      m?.url,
                      m?.original_filename
                    );
                  }}
                >
                  {m?.original_filename?.substr(0, 40) ||
                    m?.name?.substr(0, 40) ||
                    m?.filename?.substr(0, 40) ||
                    'Download'}
                </Button>
                <br />
                <Divider />
              </span>
            );
          })}
        {message.attachments &&
          marketPlace === marketPlacesEnum.VIVO &&
          message.attachments.map(m => {
            return (
              <span key={Math.random()}>
                <Button
                  onClick={() => {
                    getFile(
                      m?.name || m?.filename,
                      m?.key,
                      null,
                      m?.fileOrigin
                    );
                  }}
                >
                  {m?.name?.substr(0, 40) || 'Download'}
                </Button>
                <br />
                <Divider />
              </span>
            );
          })}
        {message.attachmentUrl && message?.requiresAuthorization === false && (
          <span>
            <Button
              onClick={() => {
                getFileFromUrl(message.attachmentUrl, message.attachmentName);
              }}
            >
              {message.attachmentName?.substr(0, 40) || 'Download'}
            </Button>
            <br />
            <Divider />
          </span>
        )}
        {message.attachmentUrl && message?.requiresAuthorization === true && (
          <span>
            <Button
              onClick={() => {
                const fileType = message.attachmentName.split('.')[1];
                API.get(message.attachmentUrl).then(response =>
                  getFileBase64FromUrl(
                    response.data.data,
                    message.attachmentName,
                    fileType
                  )
                );
              }}
            >
              {message.attachmentName?.substr(0, 40) || 'Download'}
            </Button>
            <br />
            <Divider />
          </span>
        )}
        {/* DOWNLOAD ANEXO CNOVA VIA API - EM DESENVOLVIMENTO */}
        {message.attachment && (
          <span>
            <Button
              onClick={() => {
                getFile(
                  message.attachment?.name,
                  null,
                  null,
                  null,
                  message.attachment?.id
                );
              }}
            >
              {message.attachment?.name || 'Download'}
            </Button>
            <br />
            <Divider />
          </span>
        )}
      </CardContent>
    </Card>
  );
};

export default MessageBaloonInside;
