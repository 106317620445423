import { CircularProgress } from '@material-ui/core';
import React from 'react';
import Messages from '../../messages/messages';

const TicketMessages = ({
  dataFetched,
  setChat,
  chat,
  setAttendant,
  attendantAnswerToBeInputed,
  dadosPedido,
  getTicket,
  socketMessageData,
  setTicketStatus,
  ticketStatus,
  attendant,
  reason,
  isReasonFullfield
}) => {
  if (!dataFetched) return <CircularProgress />;

  return (
    <Messages
      updateChatState={newState => {
        setChat(newState);
      }}
      chat={chat}
      updateAttendant={newAttendant => setAttendant(newAttendant)}
      messageFromParent={attendantAnswerToBeInputed}
      dadosPedido={dadosPedido}
      getTicket={getTicket}
      socketMessageData={socketMessageData}
      setTicketStatus={setTicketStatus}
      setAttendant={setAttendant}
      ticketStatus={ticketStatus}
      attendant={attendant}
      reason={reason}
      isReasonFullfield={isReasonFullfield}
    />
  );
};

export default TicketMessages;
