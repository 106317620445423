/* eslint-disable */
import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import API from '../../services/api';
import userService from '../../services/user.service';
import marketPlacesEnum from '../../enums/marketPlaces.enum';
import { getFilterInStorage } from './services/presaleServices';
import { defaultFilterFactory, payloadFactory } from './filter.config';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    overflow: 'hidden',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(7)
  }
}));

const usePreSaleHooks = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const userName = userService.getUsername();

  const [question, setQuestion] = useState({});
  const [count, setCount] = useState();
  const [rowsPerPage, setRowsPerPage] = useState();
  const [page, setPage] = useState(0);
  const [changeRowsPerPage] = useState();
  const [loading, setLoading] = useState(true);
  const [dataTableDetails, setDataTableDetails] = useState({});
  const [opendetailsModal, setOpendetailsModal] = useState(false);
  const [openChatModal, setOpenChatModal] = useState(false);
  const [chatModalData, setChatModalData] = useState({});
  const [openConfirmModal, setConfirmModal] = useState(false);
  const [detailsModalData, setDetailsModalData] = useState({});
  const [blockInput, setBlockInput] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [enableButton, setEnableButton] = useState(true);
  const [currentTicket, setCurrentTicket] = useState({});

  const [activateProtocol, setActivateProtocol] = useState({});
  const [hasFetchError, setFetchError] = useState(false);

  const [indexActivate, setIndexActivate] = useState(0);

  const [haveMessage, setHaveMessage] = useState(false);

  const storedFilterData = sessionStorage.getItem('preSalefilterData');

  const [currentPage, setCurrentPage] = useState(
    Number(sessionStorage.getItem('lastTicketsPage')) || 0
  );

  const [filterData, setFilterData] = useState(defaultFilterFactory());

  const changePage = newPage => {
    setPage(newPage);
  };

  const getData = async (reset = true, filter) => {
    if (page === undefined) return false;

    setLoading(true);

    if (reset) {
      setQuestion({});
      setActivateProtocol({});
    }

    const payload = payloadFactory(filterData);

    setTimeout(async () => {
      try {
        const response = await API.post(
          `/auth/ms-ticket/v1/tickets/?page=${page}&sort=creationDate,${payload?.orderBy ||
          'DESC'}`,
          payload
        );
        const mData = response.data;
        const content = mData?._embedded;
        const details = mData.page;
        setData(content?.ticketResourceList);
        setLoading(false);
        setFetchError(false);

        setTotalResults(details.totalElements);
        setCount(details.totalElements);
        setRowsPerPage(details.size);
        setPage(details.number);
      } catch (e) {
        setFetchError(true);
      }
    }, 1000);
  };

  const getDefaultData = async () => {
    const filterStorage = getFilterInStorage();
    const payload = {
      marketPlace: '',
      ticketType: 'PRE_SALE',
      marketPlaceList: filterStorage?.marketPlace,
      orderBy: 'ASC',
      ticketStatus: 'NEW'
    };

    try {
      const response = await API.post(
        `/auth/ms-ticket/v1/tickets/?page=${page}&sort=creationDate,DESC`,
        payload
      );
      return response.data;
    } catch (e) {
      setFetchError(true);
    }
  };

  const handleShowTicket = (item, index) => {
    setActivateProtocol(item.id);
    setQuestion(item);
    setIndexActivate(index);
  };

  const handleSetQuestionToAnswer = (item, index) => {
    if (item.serviceAttendantLogin === userName) {
      handleShowTicket(item, index);
    }

    if (item.marketPlace === 'MERCADO_LIVRE') {
      if (item.ticketStatus === 'ANSWERED') {
        handleShowTicket(item, index);
      }

      if (
        item.serviceAttendantLogin !== userName &&
        item.ticketStatus === 'NEW'
      ) {
        setConfirmModal(true);
      }
    }

    if (item.marketPlace === 'YOURVIEWS') {
      if (
        item.marketplaceBundle.moderate !== 1 &&
        item.marketplaceBundle.moderate !== 2
      ) {
        handleShowTicket(item, index);
      }

      if (
        item.serviceAttendantLogin !== userName &&
        item.marketplaceBundle.moderate === 1
      ) {
        setConfirmModal(true);
      }
    }
  };

  const componentSetMarketplaceFilter = val => {
    if (typeof filterData.marketPlace === 'string') {
      filterData.marketPlace = filterData.marketPlace.split(',');
    }

    let currentMarketplaceFilters = filterData.marketPlace;
    if (!currentMarketplaceFilters.includes(val)) {
      currentMarketplaceFilters = [];
      currentMarketplaceFilters.push(val);
      const defaultFilterData = {
        marketPlace: currentMarketplaceFilters,
        ticketStatus: 'NEW',
        orderBy: 'ASC',
        ticketType: 'PRE_SALE'
      };

      sessionStorage.setItem('lastTicketsPage', 0);
      setCurrentPage(0);
      setPage(0);
      setFilterData(defaultFilterData);
    }
  };

  const filterFormData = {
    serviceAttendantLogin: {
      value: '',
      label: 'i18n.ticketslistview.ATTENDANT'
    },
    id: { value: '', label: 'ID' },
    customerCpf: { value: '', label: 'i18n.ticketslistview.DOCUMENT' },
    orderId: { value: '', label: 'i18n.ticketslistview.ORDER_ID_PEDECOM' },
    ticketType: { value: '', label: 'i18n.ticketslistview.TICKET_TYPE' },
    creationDate: { value: '', label: 'i18n.ticketslistview.OPENING_DATE' },
    tags: { value: '', label: 'Tags' },
    vipClient: { value: '', label: 'i18n.ticketslistview.VIPCLIENT' },
    reclamacaoMercadoLivre: {
      value: '',
      label: 'i18n.ticketslistview.ML_CLAIM'
    },
    ticketStatus: { value: '', label: 'i18n.ticketslistview.STATUS' },
    filtersB2W: {
      value: '',
      label: 'i18n.ticketslistview.B2W_STATUS'
    },
    cancellationStatus: { value: '', label: 'Status do Cancelamento' },
    sla: { value: '', label: 'i18n.ticketslistview.SLA' }
  };

  const removeFilterFromChip = key => {
    const newFilterData = JSON.parse(JSON.stringify(filterData));

    // Whatsapp comentado em 16/03/2022 para publicação da R3 - Descomentar assim que liberado pelo cliente
    //if (key === 'whatsapp') {
    //newFilterData.whatsapp = '';
    //} else {
    delete newFilterData[key];
    //}

    sessionStorage.setItem(
      'preSalefilterUseQueryParams',
      JSON.stringify(false)
    );

    setFilterData(newFilterData);
  };

  const openModal = (show, modalData) => {
    setChatModalData(modalData);
    setOpenChatModal(show);
  };

  const openProductDetailsModal = (show, modalData) => {
    setDetailsModalData(modalData);
    setOpendetailsModal(show);
  };

  const closeChatModal = () => {
    if (openChatModal) {
      setOpenChatModal(false);
    }
  };

  const closedetailsModal = () => {
    if (opendetailsModal) {
      setOpendetailsModal(false);
    }
  };

  const closeConfirmModal = () => {
    if (openConfirmModal) {
      setConfirmModal(false);
    }
  };

  const removeFilter = filterKey => {
    const changes = {};
    changes[filterKey] = '';
  };

  useEffect(() => {
    const { data, index } = currentTicket;
    if (currentTicket?.data) {
      handleSetQuestionToAnswer(data, index);
    }
  }, [currentTicket]);

  useEffect(() => {
    sessionStorage.setItem('preSalefilterData', JSON.stringify(filterData));

    getData(_, { ...{ ticketType: 'PRE_SALE' }, ...filterData });
  }, [filterData, page]);

  return {
    classes,
    data,
    question,
    count,
    rowsPerPage,
    page,
    changeRowsPerPage,
    loading,
    dataTableDetails,
    opendetailsModal,
    openChatModal,
    chatModalData,
    detailsModalData,
    activateProtocol,
    openConfirmModal,
    blockInput,
    filterData,
    totalResults,
    enableButton,
    setFilterData,
    setBlockInput,
    setData,
    setQuestion,
    setCount,
    setRowsPerPage,
    setPage,
    setLoading,
    setDataTableDetails,
    setOpendetailsModal,
    setOpenChatModal,
    setChatModalData,
    setDetailsModalData,
    setActivateProtocol,
    setConfirmModal,
    changePage,
    getData,
    getDefaultData,
    handleSetQuestionToAnswer,
    openModal,
    openProductDetailsModal,
    closeChatModal,
    closedetailsModal,
    closeConfirmModal,
    hasFetchError,
    componentSetMarketplaceFilter,
    setCurrentPage,
    filterFormData,
    removeFilterFromChip,
    removeFilter,
    setEnableButton,
    indexActivate,
    setIndexActivate,
    currentTicket,
    setCurrentTicket,
    handleShowTicket,
    setBlockInput,
    haveMessage,
    setHaveMessage,
    setTotalResults,
    setCount,
    setRowsPerPage,
    setPage
  };
};

export default usePreSaleHooks;
