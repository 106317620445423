import {
  makeStyles,
  createStyles,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Box
} from '@material-ui/core';
import React from 'react';
import {
  Transport,
  TransportSection,
  TransportSectionHeader
} from '../../../Dialogs/styles';

const useStyles = makeStyles(() =>
  createStyles({
    smallRadioButton: {
      margin: '0 5px 0 0',
      '& svg': {
        width: '0.7em',
        height: '0.7em',
        color: '#6AA84F'
      }
    },
    radioGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center'
    },
    inputSmall: {
      width: '135px',
      height: '30px',
      fontSize: '12px'
    }
  })
);

const CancellingTransportSection = ({
  transportType,
  handleTransportType,
  handleSearchCAP,
  rastreioDevolucao,
  setRastreioDevolucao,
  numCAP,
  setNumCAP,
  dataEmissao,
  setDataEmissao,
  statusCAP,
  setStatusCAP
}) => {
  const classes = useStyles();
  return (
    <Transport>
      <TransportSection>
        <Grid container style={{ width: '100%' }}>
          <Grid item xs={6}>
            <TransportSectionHeader>
              Qual a forma de devolução?
            </TransportSectionHeader>

            <RadioGroup
              value={transportType}
              onChange={handleTransportType}
              className={classes.radioGroup}
            >
              <FormControlLabel
                value="CAP"
                control={<Radio />}
                className={classes.smallRadioButton}
                label={<span style={{ fontSize: '12px' }}>CAP</span>}
              />
              {handleSearchCAP(true)}
              <FormControlLabel
                value="Transportadora"
                control={<Radio />}
                label={<span style={{ fontSize: '12px' }}>Transportadora</span>}
                className={classes.smallRadioButton}
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={2} />

          <Grid item xs={4}>
            <TransportSectionHeader>
              Rastreio da Devolução
            </TransportSectionHeader>
            <div>
              <TextField
                value={rastreioDevolucao}
                size="small"
                variant="outlined"
                onChange={ev => {
                  setRastreioDevolucao(ev.target.value);
                }}
                InputProps={{
                  className: classes.inputSmall
                }}
              />
            </div>
          </Grid>
        </Grid>
      </TransportSection>
      <TransportSection padding="5px 0 0 0">
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TransportSectionHeader>No do CAP</TransportSectionHeader>
            <div>
              {' '}
              <TextField
                value={numCAP}
                size="small"
                variant="outlined"
                onChange={e => {
                  setNumCAP(e.target.value);
                }}
                InputProps={{
                  className: classes.inputSmall
                }}
              />
            </div>
          </Grid>

          <Grid item xs={4}>
            <Box>
              <TransportSectionHeader>Data Emissão</TransportSectionHeader>
              <div>
                {' '}
                <TextField
                  value={dataEmissao}
                  size="small"
                  variant="outlined"
                  type="date"
                  onChange={e => {
                    setDataEmissao(e.target.value);
                  }}
                  InputProps={{
                    className: classes.inputSmall
                  }}
                />
              </div>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box>
              <TransportSectionHeader>Status CAP</TransportSectionHeader>
              <div>
                <TextField
                  value={statusCAP}
                  size="small"
                  variant="outlined"
                  onChange={e => {
                    setStatusCAP(e.target.value);
                  }}
                  InputProps={{
                    className: classes.inputSmall
                  }}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </TransportSection>
    </Transport>
  );
};

export default CancellingTransportSection;
