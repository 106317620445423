import { Box } from '@material-ui/core';
import React from 'react';
import Item from './Item';

export default function ItemsSection({ details, setDetails }) {
  return (
    <Box
      paddingX={5}
      style={{
        overflow: 'auto',
        maxHeight: '200px',
        minHeight: '100px',
        marginTop: '10px'
      }}
    >
      {details?.itensToCancel?.map((item, i) => (
        <Item {...{ item, i, setDetails, details }} key={Math.random()} />
      ))}
    </Box>
  );
}
