/*eslint-disable */
import tableHelper from '../../../utils/tableHelper';
import moment from 'moment';

const mockChartData = [
  {
    dia: '2021-03-26',
    qtd: 5,
    dia_da_semana: 'Friday',
    name: null
  },
  {
    dia: '2021-03-27',
    qtd: 2,
    dia_da_semana: 'Saturday',
    name: null
  },
  {
    dia: '2021-03-29',
    qtd: 3,
    dia_da_semana: 'Monday',
    name: null
  },
  {
    dia: '2021-03-30',
    qtd: 3,
    dia_da_semana: 'Tuesday',
    name: null
  },
  {
    dia: '2021-04-01',
    qtd: 2,
    dia_da_semana: 'Thursday',
    name: null
  },
  {
    dia: '2021-04-02',
    qtd: 1,
    dia_da_semana: 'Friday',
    name: null
  },
  {
    dia: '2021-04-03',
    qtd: 1,
    dia_da_semana: 'Saturday',
    name: null
  },
  {
    dia: '2021-04-05',
    qtd: 4,
    dia_da_semana: 'Monday',
    name: null
  },
  {
    dia: '2021-04-06',
    qtd: 3,
    dia_da_semana: 'Tuesday',
    name: null
  },
  {
    dia: '2021-04-07',
    qtd: 7,
    dia_da_semana: 'Wednesday',
    name: null
  },
  {
    dia: '2021-04-08',
    qtd: 9,
    dia_da_semana: 'Thursday',
    name: null
  },
  {
    dia: '2021-04-09',
    qtd: 5,
    dia_da_semana: 'Friday',
    name: null
  },
  {
    dia: '2021-04-10',
    qtd: 3,
    dia_da_semana: 'Saturday',
    name: null
  },
  {
    dia: '2021-04-11',
    qtd: 1,
    dia_da_semana: 'Sunday',
    name: null
  },
  {
    dia: '2021-04-12',
    qtd: 2,
    dia_da_semana: 'Monday',
    name: null
  },
  {
    dia: '2021-04-13',
    qtd: 6,
    dia_da_semana: 'Tuesday',
    name: null
  },
  {
    dia: '2021-04-14',
    qtd: 6,
    dia_da_semana: 'Wednesday',
    name: null
  },
  {
    dia: '2021-04-15',
    qtd: 12,
    dia_da_semana: 'Thursday',
    name: null
  },
  {
    dia: '2021-04-16',
    qtd: 14,
    dia_da_semana: 'Friday',
    name: null
  },
  {
    dia: '2021-04-17',
    qtd: 9,
    dia_da_semana: 'Saturday',
    name: null
  },
  {
    dia: '2021-04-18',
    qtd: 3,
    dia_da_semana: 'Sunday',
    name: null
  },
  {
    dia: '2021-04-19',
    qtd: 16,
    dia_da_semana: 'Monday',
    name: null
  },
  {
    dia: '2021-04-20',
    qtd: 23,
    dia_da_semana: 'Tuesday',
    name: null
  },
  {
    dia: '2021-04-21',
    qtd: 5,
    dia_da_semana: 'Wednesday',
    name: null
  }
];

const mockChartAttendant = [
  {
    dia: '2020-12-21',
    qtd: 1,
    dia_da_semana: 'Monday',
    name: 'jorge'
  },
  {
    dia: '2021-02-22',
    qtd: 1,
    dia_da_semana: 'Monday',
    name: 'jorge'
  },
  {
    dia: '2021-03-06',
    qtd: 1,
    dia_da_semana: 'Saturday',
    name: 'jorge'
  },
  {
    dia: '2021-03-08',
    qtd: 2,
    dia_da_semana: 'Monday',
    name: 'jorge'
  },
  {
    dia: '2021-03-10',
    qtd: 1,
    dia_da_semana: 'Wednesday',
    name: 'jorge'
  },
  {
    dia: '2021-03-12',
    qtd: 1,
    dia_da_semana: 'Friday',
    name: 'jorge'
  },
  {
    dia: '2021-03-15',
    qtd: 1,
    dia_da_semana: 'Monday',
    name: 'jorge'
  }
];

const translateLabels = label => {
  let newLabel = '';

  switch (label) {
    case 'Monday':
      newLabel = 'Seg';
      break;
    case 'Tuesday':
      newLabel = 'Ter';
      break;
    case 'Wednesday':
      newLabel = 'Qua';
      break;
    case 'Thursday':
      newLabel = 'Qui';
      break;
    case 'Friday':
      newLabel = 'Sex';
      break;
    case 'Saturday':
      newLabel = 'Sáb';
      break;
    case 'Sunday':
      newLabel = 'Dom';
      break;
    case 'SALUTATION':
      newLabel = 'SAUDAÇÃO';
      break;
    case 'OTHERS':
      newLabel = 'OUTROS';
      break;
    case 'SATISFACTION':
      newLabel = 'SATISFAÇÃO';
      break;
    default:
      newLabel = label;
      break;
  }

  return newLabel;
};

const stringToDate = stringDate => {
  const newDate = stringDate.split(' ');
  const getDate = newDate[0].split('/');
  const newYear = getDate[2];
  const newMonth = getDate[1];
  const newDay = getDate[0];

  const newStringDate = `${newYear}-${newMonth}-${newDay}T${newDate[1]}:00`;
  return newStringDate;
};

const getCurrentDate = () => {
  const d = new Date();
  const month = d.getMonth() + 1;
  const day = d.getDay();
  const year = d.getFullYear();

  return { day, month, year };
};

const formatDate = stringDate => {
  if (stringDate) {
    const newDate = stringDate.split('-');
    const currentDate = `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
    const currentMonth = parseInt(newDate[1], 10);
    const currentYear = parseInt(newDate[0], 10);
    const currentDay = parseInt(newDate[2], 10);
    const currentDayMonth = `${newDate[2]}/${newDate[1]}`;

    return {
      currentDate,
      currentMonth,
      currentYear,
      currentDay,
      currentDayMonth
    };
  }
  return stringDate;
};

const enumerateDaysBetweenDates = (startDate, endDate) => {
  let date = [];
  while (moment(startDate) <= moment(endDate)) {
    date.push(startDate);
    startDate = moment(startDate)
      .add(1, 'days')
      .format('YYYY-MM-DD');
  }
  return date;
};

const get7LastDays = () => {
  const dateTo = moment()
    .add(1, 'days')
    .format('YYYY-MM-DD');
  const dateFrom = moment()
    .add(1, 'days')
    .subtract(7, 'd')
    .format('YYYY-MM-DD');
  const dateList = enumerateDaysBetweenDates(dateFrom, dateTo);

  return { dateTo, dateFrom, dateList };
};

const percentInTotal = (value, total) => {
  return Math.floor((value / total) * 100);
};

const totalReasons = list => {
  let total = 0;
  list?.map(item => {
    total += item.qtd;
  });

  return total;
};

const handleNotHaveDataAttendant = () => {
  /**
   * Dados vazios do atendente no dashboard de protocolo
   */

  const attendant = {
    em_atendimento: 0,
    abertos: 0,
    aguardando_atendimento: 0,
    aguardando_cliente: 0,
    novos: 0,
    serviceAttendantLogin: '',
    sla_estourado: 0,
    sla_vence_3_dias: 0,
    sla_vence_hoje: 0,
    sla_estoura_12_plus: 0,
    tempo_medio_primeira_resposta: 0,
    satisfacao: 0,
    tempo_medio_solved: 0,
    reclamacao_ML: 0,
    mediation: 0,
    total_sem_motivo: 0,
    fechados: 0,
    suspensos: 0,
    resolvidos: 0,
    aguardando_marketplace: 0,
    fechados_ref_open: 0,
    n_atendimentos: [],
    qtd_atendidos: [],
    motivos: [],
    total_mensagens: 0,
    total_enviada_cliente: 0,
    total_enviada_acer: 0,
    total_media_mensagens: 0,
    total_media_mensagens_cliente: 0,
    total_media_mensagens_acer: 0,
    qtd_volume_espec_msg_acer: 0,
    qtd_volume_espec_msg_cliente: 0,
    qtd_volume_espec_msg_marketplace: 0,
    qtd_volume_espec_msg_total: 0,
    total_geral_msg: 0,
    total_geral_cliente: 0,
    total_geral_acer: 0,
    motivos_n1: [],
    motivos_n2: [],
    motivos_por_analistas: [],
    lista_final_profissionais: []
  };

  return attendant;
};

const handleNotHaveEquipData = () => {
  const equip = {
    
    incidentes_abertos: '0',
    incidentes_fechados: '0',
    reclamacao_magalu: '0',
    total_incidentes: '0',
    devolutiva_cliente_geral: '0',
    aguardando_comercial_geral: '0',
    aguardando_financeiro_geral: '0',
    aguardando_estorno_geral: '0',
    aguardando_fiscal_geral: '0',
    aguardando_ti_geral: '0',
    aguardando_reclame_aqui_geral: '0',
    aguardando_postagem_cliente_geral: '0',
    aguardando_marketing_geral: '0',
    aguardando_logistica_geral: '0',
    em_atendimento_geral: '0',
    abertos_geral: '0',
    aguardando_atendimento_geral: '0',
    aguardando_cliente_geral: '0',
    aguardando_fechamento_geral: '0',
    novos_geral: '0',
    suspensos_geral: '0',
    resolvidos_geral: '0',
    fechados_geral_ref_open: '0',
    aguardando_marketplace_geral: '0',
    sla_estourado_geral: '0',
    sla_vence_3_dias_geral: '0',
    sla_vence_hoje_geral: '0',
    sla_estoura_12_plus: '0',
    tempo_medio_primeira_resposta_geral: tableHelper.formatMinutes(0),
    tempo_medio_solved_geral: tableHelper.formatMinutes(0),
    satisfacao_geral: '0',
    mediation: '0',
    total_sem_motivo: '0',
    reclamacao_ML: '0',
    fechados_geral: '0',
    n_atendimentos_geral: [{ label: 'Dom', value: 8 }],
    qtd_atendidos_geral: [{ type: 'Equipe', day: '01/04', value: 10 }],
    motivos_geral: [
      { label: 'Cancelado', value: 10, percent: 50 },
      { label: 'Defeito', value: 10, percent: 50 }
    ],
    data_grafico_por_dia_geral: mockChartData,
    reasons: [],
    totalReason: '0',
    tempo_medio_solved_geral_10: tableHelper.formatMinutes(0),
    tempo_medio_primeira_resposta_geral_10: tableHelper.formatMinutes(0),
    total_mensagens: '0',
    total_enviada_cliente: '0',
    total_enviada_acer: '0',
    total_media_mensagens: '0',
    total_media_mensagens_cliente: '0',
    total_media_mensagens_acer: '0',
    qtd_volume_espec_msg_acer: '0',
    qtd_volume_espec_msg_cliente: '0',
    qtd_volume_espec_msg_marketplace: '0',
    qtd_volume_espec_msg_total: '0',
    total_geral_msg: '0',
    total_geral_cliente: '0',
    total_geral_acer: '0',
    protocolo_cancelamento_troca: {
      cancelamento_iniciado: '0',
      aguardando_postagem: '0',
      objeto_postado: '0',
      aguardando_chegada_na_acer: '0',
      aguardando_logistica: '0',
      aguardando_conferencia_do_especialista: '0',
      aguardando_service: '0',
      aguardando_efetivacao_do_estorno: '0',
      estorno_realizado: '0',
      processo_cancelado: '0',
      finalizado: '0'
    },
    motivos_n1: [],
    motivos_n2: [],
    motivos_por_analistas: [],
    lista_final_profissionais: []
  };

  return equip;
};

const handleSeparateEquipData = data => {
  /**
   * Serviço que trata os dados do dashboard de protocolos e da equipe. Retorna os dados da equipe tratados
   */

  const {
    aguardando_atendimento_geral,
    abertos_geral,
    devolutiva_cliente_geral,
    aguardando_cliente_geral,
    aguardando_fechamento_geral,
    novos_geral,
    sla_estourado_geral,
    sla_vence_3_dias_geral,
    sla_vence_hoje_geral,
    mediation,
    total_sem_motivo,
    reclamacao_ML,
    n_atendimentos_geral,
    qtd_atendidos_geral,
    data_grafico_por_dia_geral,
    suspensos_geral,
    resolvidos_geral,
    aguardando_marketplace_geral,
    total_mensagens,
    total_enviada_cliente,
    total_enviada_acer,
    total_media_mensagens,
    total_media_mensagens_cliente,
    total_media_mensagens_acer,
    qtd_volume_espec_msg_acer,
    qtd_volume_espec_msg_cliente,
    qtd_volume_espec_msg_marketplace,
    qtd_volume_espec_msg_total,
    total_geral_msg,
    total_geral_cliente,
    total_geral_acer,
    protocolo_cancelamento_troca,
    incidentes_abertos,
    incidentes_fechados,
    total_incidentes,
    score_marketplace,
    reclamacao_magalu,
    motivos_n1,
    motivos_n2,
    motivos_n3,
    motivos_por_analistas,
    lista_final_profissionais,
    aguardando_reclame_aqui_geral,
    aguardando_ti_geral,
    aguardando_postagem_cliente_geral,
    aguardando_logistica_geral,
    aguardando_fiscal_geral,
    aguardando_estorno_geral,
    aguardando_marketing_geral,
    aguardando_financeiro_geral,
    aguardando_comercial_geral
  } = data.data_ref_open;

  const {
    satisfacao_geral,
    tempo_medio_primeira_resposta_geral,
    tempo_medio_solved_geral,
    fechados_geral,
    motivos_geral,
    reasons,
    tempo_medio_primeira_resposta_geral_10,
    tempo_medio_solved_geral_10
  } = data.data_ref_closed;

  const equip = {
    em_atendimento_geral:
      parseInt(aguardando_atendimento_geral || '0', 10) +
      parseInt(aguardando_cliente_geral || '0', 10),
    abertos_geral: abertos_geral || '0',
    devolutiva_cliente_geral: devolutiva_cliente_geral || 0,
    aguardando_atendimento_geral: aguardando_atendimento_geral || '0',
    aguardando_fechamento_geral: aguardando_fechamento_geral || '0',
    aguardando_cliente_geral: aguardando_cliente_geral || '0',
    aguardando_reclame_aqui_geral: aguardando_reclame_aqui_geral || '0',
    aguardando_ti_geral: aguardando_ti_geral || '0',
    aguardando_postagem_cliente_geral: aguardando_postagem_cliente_geral || '0',
    aguardando_logistica_geral: aguardando_logistica_geral || '0',
    aguardando_fiscal_geral: aguardando_fiscal_geral || '0',
    aguardando_estorno_geral: aguardando_estorno_geral || '0',
    aguardando_marketing_geral: aguardando_marketing_geral || '0',
    aguardando_financeiro_geral: aguardando_financeiro_geral || '0',
    aguardando_comercial_geral: aguardando_comercial_geral || '0',
    novos_geral: novos_geral || '0',
    suspensos_geral: suspensos_geral || '0',
    resolvidos_geral: resolvidos_geral || '0',
    fechados_geral_ref_open: data.data_ref_open.fechados_geral || '0',
    aguardando_marketplace_geral: aguardando_marketplace_geral || '0',
    sla_estourado_geral: sla_estourado_geral || '0',
    sla_vence_3_dias_geral: sla_vence_3_dias_geral || '0',
    sla_vence_hoje_geral: sla_vence_hoje_geral || '0',
    sla_estoura_12_plus: data.data_ref_open['sla_estoura_12_+'] || '0',
    tempo_medio_primeira_resposta_geral:
      tableHelper.formatMinutes(tempo_medio_primeira_resposta_geral) ||
      tableHelper.formatMinutes(0),
    tempo_medio_solved_geral:
      tableHelper.formatMinutes(tempo_medio_solved_geral) ||
      tableHelper.formatMinutes(0),
    satisfacao_geral: satisfacao_geral || '0',
    mediation: mediation || '0',
    total_sem_motivo: total_sem_motivo || '0',
    reclamacao_ML: reclamacao_ML || '0',
    fechados_geral: fechados_geral || '0',
    n_atendimentos_geral: n_atendimentos_geral || [{ label: 'Dom', value: 8 }],
    qtd_atendidos_geral: qtd_atendidos_geral || [
      { type: 'Equipe', day: '01/04', value: 10 }
    ],
    motivos_geral: motivos_geral || [
      { label: 'Cancelado', value: 10, percent: 50 },
      { label: 'Defeito', value: 10, percent: 50 }
    ],
    data_grafico_por_dia_geral: data_grafico_por_dia_geral || mockChartData,
    reasons: reasons || [],
    totalReason: totalReasons(reasons),
    tempo_medio_solved_geral_10:
      tableHelper.formatMinutes(tempo_medio_solved_geral_10) ||
      tableHelper.formatMinutes(0),
    tempo_medio_primeira_resposta_geral_10:
      tableHelper.formatMinutes(tempo_medio_primeira_resposta_geral_10) ||
      tableHelper.formatMinutes(0),
    total_mensagens: total_mensagens || '0',
    total_enviada_cliente: total_enviada_cliente || '0',
    total_enviada_acer: total_enviada_acer || '0',
    total_media_mensagens: total_media_mensagens || '0',
    total_media_mensagens_cliente: total_media_mensagens_cliente || '0',
    total_media_mensagens_acer: total_media_mensagens_acer || '0',
    qtd_volume_espec_msg_acer: qtd_volume_espec_msg_acer || '0',
    qtd_volume_espec_msg_cliente: qtd_volume_espec_msg_cliente || '0',
    qtd_volume_espec_msg_marketplace: qtd_volume_espec_msg_marketplace || '0',
    qtd_volume_espec_msg_total: qtd_volume_espec_msg_total || '0',
    total_geral_msg: total_geral_msg || '0',
    total_geral_cliente: total_geral_cliente || '0',
    total_geral_acer: total_geral_acer || '0',
    protocolo_cancelamento_troca: protocolo_cancelamento_troca || {
      cancelamento_iniciado: '0',
      aguardando_postagem: '0',
      objeto_postado: '0',
      aguardando_chegada_na_acer: '0',
      aguardando_logistica: '0',
      aguardando_conferencia_do_especialista: '0',
      aguardando_service: '0',
      aguardando_efetivacao_do_estorno: '0',
      estorno_realizado: '0',
      processo_cancelado: '0',
      finalizado: '0'
    },
    incidentes_abertos: incidentes_abertos || '0',
    incidentes_fechados: incidentes_fechados || '0',
    total_incidentes: total_incidentes || '0',
    score_marketplace: score_marketplace || {},
    reclamacao_magalu: reclamacao_magalu || '0',
    motivos_n1: motivos_n1 || [],
    motivos_n2: motivos_n2 || [],
    motivos_n3: motivos_n3 || [],
    motivos_por_analistas: motivos_por_analistas || [],
    lista_final_profissionais: lista_final_profissionais || []
  };

  return equip;
};

const handleSeparateAttendantData = (dataOpen, dataClosed) => {
  /**
   * Serviço que trata os dados do dashboard de protocolos e individual. Retorna os dados individuais tratados
   */
  const {
    abertos,
    devolutiva_cliente,
    aguardando_atendimento,
    aguardando_cliente,
    aguardando_fechamento,
    aguardando_reclame_aqui,
    aguardando_ti,
    aguardando_postagem_cliente,
    aguardando_logistica,
    aguardando_fiscal,
    aguardando_estorno,
    aguardando_marketing,
    aguardando_financeiro,
    aguardando_comercial,
    novos,
    sla_estourado,
    serviceAttendantLogin,
    sla_vence_3_dias,
    sla_vence_hoje,
    reclamacao_ML,
    mediation,
    total_sem_motivo,
    n_atendimentos,
    qtd_atendidos,
    data_grafico_por_dia_profissional,
    suspensos,
    resolvidos,
    aguardando_marketplace,
    total_mensagens,
    total_enviada_cliente,
    total_enviada_acer,
    total_media_mensagens,
    total_media_mensagens_cliente,
    total_media_mensagens_acer,
    qtd_volume_espec_msg_acer,
    qtd_volume_espec_msg_cliente,
    qtd_volume_espec_msg_marketplace,
    qtd_volume_espec_msg_total,
    total_geral_msg,
    total_geral_cliente,
    total_geral_acer,
    total_incidentes,
    incidentes_abertos,
    incidentes_fechados,
    reclamacao_magalu,
    motivos_n1,
    motivos_n2,
    motivos_n3,
    motivos_por_analistas,
    lista_final_profissionais
  } = dataOpen;

  const {
    satisfacao,
    tempo_medio_primeira_resposta,
    tempo_medio_solved,
    fechados,
    motivos,
    reasons,
    tempo_medio_primeira_resposta_10,
    tempo_medio_solved_10
  } = dataClosed;

  const attendant = {
    em_atendimento:
      parseInt(aguardando_atendimento || '0', 10) +
      parseInt(aguardando_cliente || '0', 10),
    abertos: abertos || '0',
    aguardando_atendimento: aguardando_atendimento || '0',
    aguardando_cliente: aguardando_cliente || '0',
    aguardando_fechamento: aguardando_fechamento || '0',
    aguardando_reclame_aqui: aguardando_reclame_aqui || '0',
    aguardando_ti: aguardando_ti || '0',
    aguardando_postagem_cliente: aguardando_postagem_cliente || '0',
    aguardando_logistica: aguardando_logistica || '0',
    aguardando_fiscal: aguardando_fiscal || '0',
    aguardando_estorno: aguardando_estorno || '0',
    aguardando_marketing: aguardando_marketing || '0',
    aguardando_financeiro: aguardando_financeiro || '0',
    aguardando_comercial: aguardando_comercial || '0',
    novos: novos || '0',
    suspensos: suspensos || '0',
    devolutiva_cliente: devolutiva_cliente || 0,
    resolvidos: resolvidos || '0',
    fechados_ref_open: dataOpen.fechados || '0',
    aguardando_marketplace: aguardando_marketplace || '0',
    serviceAttendantLogin: serviceAttendantLogin || '0',
    sla_estourado: sla_estourado || '0',
    sla_vence_3_dias: sla_vence_3_dias || '0',
    sla_vence_hoje: sla_vence_hoje || '0',
    sla_estoura_12_plus: dataOpen['sla_estoura_12_+'] || '0',
    tempo_medio_primeira_resposta:
      tableHelper.formatMinutes(tempo_medio_primeira_resposta) ||
      tableHelper.formatMinutes(0),
    satisfacao: satisfacao || '0',
    tempo_medio_solved:
      tableHelper.formatMinutes(tempo_medio_solved) ||
      tableHelper.formatMinutes(0),
    reclamacao_ML: reclamacao_ML || '0',
    mediation: mediation || '0',
    total_sem_motivo: total_sem_motivo || '0',
    fechados: fechados || '0',
    n_atendimentos: n_atendimentos || [{ label: 'Dom', value: 4 }],
    qtd_atendidos: qtd_atendidos || [
      { type: 'Individual', day: '01/04', value: 5 }
    ],
    motivos: motivos || [
      { label: 'Cancelado', value: 2, percent: 20 },
      { label: 'Defeito', value: 8, percent: 80 }
    ],
    data_grafico_por_dia_profissional:
      data_grafico_por_dia_profissional || mockChartAttendant,
    reasons: reasons || [],
    totalReason: totalReasons(reasons),
    tempo_medio_solved_10:
      tableHelper.formatMinutes(tempo_medio_solved_10) ||
      tableHelper.formatMinutes(0),
    tempo_medio_primeira_resposta_10:
      tableHelper.formatMinutes(tempo_medio_primeira_resposta_10) ||
      tableHelper.formatMinutes(0),
    total_mensagens: total_mensagens || '0',
    total_enviada_cliente: total_enviada_cliente || '0',
    total_enviada_acer: total_enviada_acer || '0',
    total_media_mensagens: total_media_mensagens || '0',
    total_media_mensagens_cliente: total_media_mensagens_cliente || '0',
    total_media_mensagens_acer: total_media_mensagens_acer || '0',
    qtd_volume_espec_msg_acer: qtd_volume_espec_msg_acer || '0',
    qtd_volume_espec_msg_cliente: qtd_volume_espec_msg_cliente || '0',
    qtd_volume_espec_msg_marketplace: qtd_volume_espec_msg_marketplace || '0',
    qtd_volume_espec_msg_total: qtd_volume_espec_msg_total || '0',
    total_geral_msg: total_geral_msg || '0',
    total_geral_cliente: total_geral_cliente || '0',
    total_geral_acer: total_geral_acer || '0',
    total_incidentes: total_incidentes || '0',
    incidentes_abertos: incidentes_abertos || '0',
    incidentes_fechados: incidentes_fechados || '0',
    reclamacao_magalu: reclamacao_magalu || '0',
    motivos_n1: motivos_n1 || [],
    motivos_n2: motivos_n2 || [],
    motivos_n3: motivos_n3 || [],
    motivos_por_analistas: motivos_por_analistas || [],
    lista_final_profissionais: lista_final_profissionais || []
  };
  return attendant;
};

const handleSeparateChatEquipData = data => {
  /**
   * Serviço que trata os dados do dashboard de chat e equipe. Retorna os dados da equipe tratados
   */

  const {
    conexao_perdida_geral,
    em_atendimento_geral,
    duracao_media_atendimento_geral,
    em_atendimento_robo_enviados_especialista_geral,
    finalizados_geral,
    finalizados_pelo_robo_geral,
    na_fila_geral,
    novos_geral,
    qtd_media_msg_cliente_geral,
    qtd_media_res_especialista_geral,
    qtd_media_res_robo_geral,
    total_responstas_robo_revisao_especialista_geral,
    n_atendimentos_geral,
    n_atendimentos_data_geral,
    qtd_chats_atendidos_geral,
    finalizados_pelo_especialistas_geral,
    data_grafico_por_dia_geral,
    reasons,
    robot_hits, // performance_robo_geral
    pre_chat, // em_atendimento_robo_respostas_automaticas_geral
    pre_chat_not_ended,
    satisfacao, //satisfacao_media_geral
    satisfacao_10_ultimos
  } = data;

  const equip = {
    conexao_perdida_geral: conexao_perdida_geral || '0',
    em_atendimento_geral: em_atendimento_geral || '0',
    duracao_media_atendimento_geral:
      tableHelper.formatMinutes(duracao_media_atendimento_geral) ||
      tableHelper.formatMinutes(0),
    em_atendimento_robo_enviados_especialista_geral:
      em_atendimento_robo_enviados_especialista_geral || '0',
    em_atendimento_robo_respostas_automaticas_geral: pre_chat || '0',
    finalizados_geral: finalizados_geral || '0',
    finalizados_pelo_robo_geral: finalizados_pelo_robo_geral || '0',
    na_fila_geral: na_fila_geral || '0',
    novos_geral: novos_geral || '0',
    performance_robo_geral: robot_hits || '0',
    qtd_media_msg_cliente_geral: qtd_media_msg_cliente_geral || '0',
    qtd_media_res_especialista_geral: qtd_media_res_especialista_geral || '0',
    qtd_media_res_robo_geral: qtd_media_res_robo_geral || '0',
    satisfacao_media_geral: satisfacao || '0',
    satisfacao_ultimos_atendimentos: satisfacao_10_ultimos,
    total_responstas_robo_revisao_especialista_geral: total_responstas_robo_revisao_especialista_geral || [
      { label: 'Aceitas', value: 10, percent: 50 },
      { label: 'Não aceitas', value: 10, percent: 50 }
    ],
    n_atendimentos_data_geral: n_atendimentos_data_geral || [],
    n_atendimentos_geral: n_atendimentos_geral || '0',
    qtd_chats_atendidos_geral: qtd_chats_atendidos_geral || [],
    finalizados_pelo_especialistas_geral:
      finalizados_pelo_especialistas_geral || '0',
    data_grafico_por_dia_geral: data_grafico_por_dia_geral || [],
    reasons: reasons || [],
    totalReason: totalReasons(reasons),
    pre_chat_not_ended: pre_chat_not_ended || '0'
  };

  return equip;
};

const handleSeparateChatAttendantData = (data, attendantChartData) => {
  /**
   * Serviço que trata os dados do dashboard de chat e individual. Retorna os dados individuais tratados
   */

  const {
    conexao_perdida,
    duracao_media_atendimento,
    em_atendimento,
    finalizados,
    finalizados_pelo_especialista,
    na_fila,
    novos,
    qtd_chats_atendidos,
    qtd_media_msg_cliente,
    qtd_media_msg_atendente, //qtd_media_res_especialista
    qtd_media_res_robo,
    serviceAttendantLogin,
    n_atendimentos,
    n_atendimentos_data,
    satisfacao, //satisfacao_media
    satisfacao_10_ultimos,
    total_atendidos
  } = data;
  const attendant = {
    conexao_perdida: conexao_perdida || '0',
    duracao_media_atendimento:
      tableHelper.formatMinutes(duracao_media_atendimento) ||
      tableHelper.formatMinutes(0),
    em_atendimento: em_atendimento || '0',
    finalizados: finalizados || '0',
    finalizados_pelo_especialista: finalizados_pelo_especialista || '0',
    na_fila: na_fila || '0',
    novos: novos || '0',
    qtd_media_msg_cliente: qtd_media_msg_cliente || '0',
    qtd_media_res_especialista: qtd_media_msg_atendente || '0',
    qtd_media_res_robo: qtd_media_res_robo || '0',
    serviceAttendantLogin: serviceAttendantLogin || '0',
    n_atendimentos: n_atendimentos || '0',
    n_atendimentos_data: n_atendimentos_data || [],
    qtd_chats_atendidos: qtd_chats_atendidos || [],
    data_grafico_por_dia_individual: attendantChartData || [],
    satisfacao_media: satisfacao || '0',
    satisfacao_ultimos_atendimentos: satisfacao_10_ultimos || '0',
    total_atendidos: total_atendidos || '0'
  };

  return attendant;
};

const handleNotHaveDataChatAttendant = () => {
  /**
   * Dados vazios do atendente no dashboard de chat
   */

  const attendant = {
    conexao_perdida: '0',
    duracao_media_atendimento: '0',
    em_atendimento: '0',
    finalizados: '0',
    finalizados_pelo_especialista: '0',
    na_fila: '0',
    novos: '0',
    qtd_media_msg_cliente: '0',
    qtd_media_res_especialista: '0',
    qtd_media_res_robo: '0',
    satisfacao_media: '0',
    serviceAttendantLogin: '',
    n_atendimentos: [],
    n_atendimentos_data: [],
    qtd_chats_atendidos: [],
    total_atendidos: '0'
  };

  return attendant;
};

const dynamicSort = property => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function(a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

export const colors = [
  '#80C343',
  '#7794E2',
  '#C3D48B',
  '#55A38B',
  '#EAEFC8',
  '#008489',
  '#005452',
  '#1C9997',
  '#329542',
  '#84AD34',
  '#CCE2C4',
  '#6379F1',
  '#7DBB56',
  '#6681DE',
  '#7AB36A',
  '#6989CA',
  '#76AA7D',
  '#6D92B7',
  '#73A290',
  '#709AA4'
];

export const translateStatusLabels = label => {
  switch (label) {
    case 'NEW':
      return 'Novo';
    case 'ANSWERED_PUBLIC':
      return 'Respondido público';
    case 'ANSWERED_PRIVATE':
      return 'Respondido privado';
    case 'IGNORED':
      return 'Ignorado';
    case 'ANSWERED_REMOVED':
      return 'Respondido removido';
    case 'BANNED':
      return 'Banido';
    case 'REMOVED':
      return 'Pergunda excluida';
    case 'CLOSED_UNANSWERED':
      return 'Fechado sem resposta';
    case 'PAUSED':
      return 'Anúncio pausado';
    case 'UNDER_REVIEW':
      return 'Sob revisão';
    case 'CLOSED':
      return 'Fechado';
    case 'ANSWERED':
      return 'Respondido';
    default:
      return '';
  }
};

export const compare = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  // a deve ser igual a b
  return 0;
};

export default {
  handleNotHaveDataAttendant,
  handleNotHaveEquipData,
  handleSeparateEquipData,
  handleSeparateAttendantData,
  handleSeparateChatEquipData,
  handleSeparateChatAttendantData,
  handleNotHaveDataChatAttendant,
  stringToDate,
  formatDate,
  getCurrentDate,
  get7LastDays,
  percentInTotal,
  translateLabels,
  dynamicSort,
  compare
};
