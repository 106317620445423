import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  ThemeProvider
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../../components/inputStyle';

import { ModalCustomerHistoricContext } from './context';
import SelectAttendant from '../../../../../components/SelectAttendant.component';
import SelectStatus from '../../../../../components/SelectStatus.component';
import FilterInput from '../../../../../components/FilterInput.component';
import DateComponent, {
  factoryCreationDate
} from '../../../../../components/Date.component';

import userService from '../../../../../services/user.service';

const FilterForm = ({ setOpen }) => {
  const { t } = useTranslation();
  const { setFilter } = useContext(ModalCustomerHistoricContext);
  const [filterForm, setFilterForm] = useState({});
  const [creationDate, setCreationDate] = useState({});

  const userName = userService.getUsername();
  const isAdmin = userService.hasGroup('admin');

  const handleSubmitFilter = e => {
    e.preventDefault();
    setFilter(filterForm);
    setOpen(false);
  };

  const handleSetFilter = e => {
    setFilterForm({
      ...filterForm,
      [e.target.name]: e.target.value
    });
  };

  const handleClearFilter = () => {
    setFilterForm({});
    setFilter({});
    setCreationDate({});
  };

  const handleSetCreationDate = e => {
    setCreationDate({
      ...creationDate,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    setFilterForm({
      ...filterForm,
      creationDate: factoryCreationDate(creationDate)
    });
  }, [creationDate]);

  return (
    <ThemeProvider theme={theme}>
      <div style={{ background: '#f4f6f8', borderLeft: '1px solid #cccccc' }}>
        <form onSubmit={e => handleSubmitFilter(e)}>
          <Grid container>
            <Grid item>
              <Box
                style={{
                  width: '500px',
                  padding: '12px 24px 24px',
                  border: '0'
                }}
              >
                <Typography variant="body1">
                  {t('i18n.ticketslistview.MORE_FILTER_OPTIONS')}
                </Typography>
                <Box mb={2} mt={2}>
                  <Grid container>
                    <Grid item xs={7}>
                      <SelectAttendant
                        {...{
                          form: filterForm,
                          setForm: handleSetFilter,
                          name: 'serviceAttendantLogin',
                          label: t('i18n.ticketslistview.ATTENDANT'),
                          isAdmin,
                          userName
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={2}>
                  <Grid container>
                    <Grid item xs={7}>
                      <SelectStatus
                        {...{
                          form: filterForm,
                          setForm: handleSetFilter,
                          name: 'ticketStatus',
                          label: t('i18n.ticketslistview.STATUS')
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={2}>
                  <Grid container>
                    <Grid item xs={7}>
                      <FilterInput
                        {...{
                          form: filterForm,
                          setForm: handleSetFilter,
                          name: 'id',
                          label: 'Nº Protocolo Conecta'
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={2}>
                  <Grid container>
                    <Grid item xs={7}>
                      <FilterInput
                        {...{
                          form: filterForm,
                          setForm: handleSetFilter,
                          name: 'orderId',
                          label: 'Nº Pedido ou PEDECOM'
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={2}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    style={{ marginTop: '15px' }}
                  >
                    Data de abertura do protocolo:
                  </Typography>
                </Box>
                <Box mb={2}>
                  <DateComponent
                    {...{ form: creationDate, setForm: handleSetCreationDate }}
                  />
                </Box>

                <Divider />
                <br />
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    variant="contained"
                    onClick={() => handleClearFilter()}
                  >
                    {t('i18n.ticketslistview.CLEAR')}
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    {t('i18n.dashboard.APPLY_FILTER')}
                  </Button>
                </div>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
    </ThemeProvider>
  );
};

export default FilterForm;
