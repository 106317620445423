import React from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

import CardCustomTitle from '../components/CardCustomTitle';
import DonutChart from '../../PresaleOnline/DonutChart';
import { COLORS } from '../../../../../styles/settings/colors.config';
import { getTitleData } from '../functions/commonFunctions';

const RightAlignedParagraph = styled.span`
  display: flex;
  justify-content: flex-end;
  color: #999999;
`;

const BoldInfo = styled.span`
  font-weight: bold;
  margin: 0 5px 0 5px;
  color: ${COLORS.grayText};
`;

const getScore = type => {
  switch (type) {
    case 'delay':
      return 0.4;
    case 'complain':
      return 4.5;
    case 'cancelled':
      return 1.3;
    default:
      return '';
  }
};

const ScoreDetailText = ({ type }) => {
  switch (type) {
    case 'delay':
      return (
        <Typography style={{ fontSize: '14px' }}>
          <RightAlignedParagraph>
            De
            <BoldInfo>930</BoldInfo>
            pedidos
          </RightAlignedParagraph>
          <RightAlignedParagraph>
            <BoldInfo>350</BoldInfo>
            enviados com atraso
          </RightAlignedParagraph>
          <RightAlignedParagraph>
            Um total de
            <BoldInfo>2,2%</BoldInfo>
          </RightAlignedParagraph>
        </Typography>
      );
    case 'complain':
      return (
        <Typography style={{ fontSize: '14px' }}>
          <RightAlignedParagraph>
            De
            <BoldInfo>930</BoldInfo>
            pedidos
          </RightAlignedParagraph>
          <RightAlignedParagraph>
            <BoldInfo>75</BoldInfo>
            enviados com atraso
          </RightAlignedParagraph>
          <RightAlignedParagraph>
            Um total de
            <BoldInfo>5,5%</BoldInfo>
          </RightAlignedParagraph>
        </Typography>
      );
    case 'cancelled':
      return (
        <Typography style={{ fontSize: '14px' }}>
          <RightAlignedParagraph>
            De
            <BoldInfo>930</BoldInfo>
            pedidos
          </RightAlignedParagraph>
          <RightAlignedParagraph>
            <BoldInfo>0</BoldInfo>
            cancelados
          </RightAlignedParagraph>
          <RightAlignedParagraph>
            Um total de
            <BoldInfo>0%</BoldInfo>
          </RightAlignedParagraph>
        </Typography>
      );
    default:
      return <></>;
  }
};

const getTitle = type => {
  switch (type) {
    case 'delay':
      return 'Expedidos com atraso';
    case 'complain':
      return 'Pedidos com reclamação';
    case 'cancelled':
      return 'Pedidos Cancelados';
    default:
      return '';
  }
};

const getDescriptionHint = type => {
  switch (type) {
    case 'delay':
      return `Este indicador analisa a quantidade de pedidos com atraso na expedição 
      em relação ao total de pedidos com data prevista de expedição.`;
    case 'complain':
      return `Este indicador analisa a quantidade de reclamações abertas em relação ao 
      total de pedidos com data prevista de expedição`;
    case 'cancelled':
      return `Este indicador analisa a quantidade de cancelamentos em relação ao total 
      de pedidos com data prevista de expedição`;
    default:
      return '';
  }
};

const getGraphGoal = type => {
  let text = '';

  switch (type) {
    case 'delay':
      text = '<5% Meta';
      break;
    case 'complain':
      text = '<2% Meta';
      break;
    case 'cancelled':
      text = '<3% Meta';
      break;
    default:
      text = '';
  }

  return {
    style: {
      fontWeight: '400',
      fontSize: '10px',
      lineHeight: '11.72px',
      fontFamily: 'Roboto',
      marginTop: '15px',
      color: '#A9A9A9'
    },
    content: text
  };
};

const generalScoreColor = (score, type) => {
  const colors = {
    level1: '#699E18',
    level2: '#7BD7D4',
    level3: '#F2E18D',
    level4: '#F1C593',
    level5: '#F69789'
  };

  if (type === 'delay') {
    if (score <= 5) {
      return colors.level1;
    }
    if (score > 5 && score <= 10) {
      return colors.level2;
    }
    if (score > 10 && score <= 15) {
      return colors.level3;
    }
    if (score > 15 && score <= 20) {
      return colors.level4;
    }
    return colors.level5;
  }

  if (type === 'complain') {
    if (score <= 2) {
      return colors.level1;
    }
    if (score > 2 && score <= 5) {
      return colors.level2;
    }
    if (score > 5 && score <= 6) {
      return colors.level3;
    }
    if (score > 6 && score <= 10) {
      return colors.level4;
    }
    return colors.level5;
  }

  if (type === 'cancelled') {
    if (score <= 3) {
      return colors.level1;
    }
    if (score > 3 && score <= 5) {
      return colors.level2;
    }
    if (score > 5 && score <= 8) {
      return colors.level3;
    }
    if (score > 8 && score <= 10) {
      return colors.level4;
    }
    return colors.level5;
  }
};

const fillDonutGraph = (score = 0) => {
  const data = [
    {
      type: 'Nota',
      value: score
    }
  ];
  return data;
};

const getChartColors = (score, type) => {
  const colorArray = [];
  colorArray.push(generalScoreColor(score, type));
  return colorArray;
};

const ScoreInfoCardCNOVA = ({ type }) => {
  const score = getScore(type);
  const possibleScore = 5;
  const chartData = fillDonutGraph(score, possibleScore);
  const titleData = getTitleData(score, '40px');
  const titleSubContent = getGraphGoal(type);
  const colors = getChartColors(score, type);
  const title = getTitle(type);
  const descriptionHint = getDescriptionHint(type);

  return (
    <Card style={{ width: '100%', borderRadius: '10px' }}>
      <CardCustomTitle {...{ title, descriptionHint }} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <DonutChart
              data={chartData}
              innerRadius={0.8}
              radius={0.6}
              totalValue={score}
              height="130px"
              {...{ colors, titleData, titleSubContent }}
            />
          </Grid>
          <Grid
            item
            xs={7}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <ScoreDetailText {...{ type }} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ScoreInfoCardCNOVA;
