// import { createStore, combineReducers } from 'redux';
const showFavoriteModalContext = false;
const favoriteContext = false;
const favoriteLimitContext = 0;
const editFavoriteContext = false;
const loadFavorites = [];

const showFavoriteModalReducer = (state = showFavoriteModalContext, action) => {
  switch (action.type) {
    case 'SHOW_FAVORITE_MODAL':
      state = action.showFavoriteModal;
      return state;
    default:
      return state;
  }
};

const editFavoriteReducer = (state = editFavoriteContext, action) => {
  switch (action.type) {
    case 'EDIT_FAVORITE':
      state = action.editFavorite;
      return state;
    default:
      return state;
  }
};

const loadFavoritesReducer = (state = loadFavorites, action) => {
  switch (action.type) {
    case 'LOAD_FAVORITES':
      state = action.favorites;
      return state;
    default:
      return state;
  }
};

const showFavoriteLimitReducer = (state = favoriteLimitContext, action) => {
  switch (action.type) {
    case 'SHOW_FAVORITE_LIMIT':
      state = action.favoriteLimit;
      return state;
    default:
      return state;
  }
};

const setFavoriteReducer = (state = favoriteContext, action) => {
  switch (action.type) {
    case 'SET_FAVORITE':
      state = action.favorite;
      return state;
    default:
      return state;
  }
};

export {
  showFavoriteModalReducer,
  setFavoriteReducer,
  showFavoriteLimitReducer,
  editFavoriteReducer,
  loadFavoritesReducer
};
