import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import './mixins/chartjs';
import DashboardPrivateRoute from './utils/routes/dashboardPrivateRoute';
import MainLayoutPublicRoute from './utils/routes/mainLayoutPublicRoute';
import MonitorPrivateRoute from './utils/routes/monitorPrivateRoute';
import LoginView from './views/auth/LoginView';
import AutomationView from './views/automation';
import AutomationList from './views/automation/AutomationList';
import ChatContainer from './views/chats/ChatContainer';
// import ChatsListView from './views/chats/ChatViewList/chat-view-list';
// import ChatsBeta from './views/chats/NewChat';
import ChangePasswordView from './views/auth/ChangePasswordView';
import ForgotPasswordView from './views/auth/ForgotPasswordView';
import ChatsV2 from './views/chats/NewChatV2';
import CompanyContainer from './views/companies/CompanyContainer';
import CompaniesListView from './views/companies/CompanyListView';
import NotFoundView from './views/errors/NotFoundView';
import GroupContainer from './views/group/GroupContainer';
import GroupsListView from './views/group/GroupListView';
import MacrosEdit from './views/macros/MacrosEdit';
import MacrosList from './views/macros/MacrosList';
import NewTicket from './views/tickets/GuideTIcket/NewTicket';
import TicketContainer from './views/tickets/TicketContainer';
import TicketsListView from './views/tickets/TicketsListView';
import UserContainer from './views/users/UserContainer';
import UsersListView from './views/users/UserListView';
import UserView from './views/users/UserView';
// import ChatHistoryPage from './views/chats/ChatContainer/ChatsList';
import MonitorMarketplace from './layouts/DashboardLayout/Tools/Monitor/MonitorMarketplace';
import AnaliseAnunciosListView from './views/analiseAnuncios/ListView';
import CampaignCalendar from './views/campaign-calendar/CampaignCalendar';
import BlockedIPs from './views/chats/BlockedIPs';
import ChatHistoryPageV2 from './views/chats/ChatContainer/ChatsList';
import { ChatSettings } from './views/chats/Settings';
import { ChatTriggers } from './views/chats/Triggers';
import DashboardPanelv2 from './views/dashboardV2';
import EvaluationView from './views/evaluations';
import KnowledgeBaseListView from './views/knowledge/KnowLedgeList';
import KnowledgeBaseCategoryContainer from './views/knowledge/KnowledgeCategoriesContainer';
import KnowledgeBaseCategoryListView from './views/knowledge/KnowledgeCategoriesList';
import KnowledgeBaseContainer from './views/knowledge/KnowledgeContainer';
import MonitorChat from './views/monitor/Chat';
import PresaleChatIframe from './views/presale-chat-iframe';
import PreSaleView from './views/presaleV2';
import ProfileContainer from './views/profiles/Container/index';
import ProfileView from './views/profiles/ListView/index';
import QuickAnswersEdit from './views/quick-answers/QuickAnswersEdit';
import QuickAnswersList from './views/quick-answers/QuickAnswersList';
import DepartmentDetailsIframe from './views/departmentDetails/departmentDetails';
import DepartmentListIframe from './views/departmentList/departmentList';
import Setup from './views/setup/Setup';
import TagsListView from './views/tags';
import LegalProcessDetail from './views/tickets/LegalProcess/LegalProcessDetail';
import LogisticsDetail from './views/tickets/TicketContainer/Cancelling/Views/Logistics/Details';
import Logistics from './views/tickets/TicketContainer/Cancelling/Views/Logistics/Index';
import ServiceDetail from './views/tickets/TicketContainer/Cancelling/Views/Service/Details';
import Service from './views/tickets/TicketContainer/Cancelling/Views/Service/Index';
import TicketIframe from './views/tickets/TicketContainer/Iframe';
import TicketDetailsIframe from './views/tickets/TicketContainer/TicketDetails/TicketDetailsIframe';
import UserManagementIframe from './views/userManagement/UserManagementIframe';
import BlockedIPsIframe from './views/chatBlockedIPs/BlockedIPsList';
import ChatHistory from './views/chatHistory';
import ChatHistoryDetail from './views/chatHistoryDetail';

export default function Routes() {
  return (
    <LastLocationProvider>
      <Switch>
        <MainLayoutPublicRoute component={LoginView} path="/" exact />
        <MainLayoutPublicRoute component={LoginView} path="/login" exact />
        <MainLayoutPublicRoute
          component={ChangePasswordView}
          path="/change-password"
          exact
        />
        <MainLayoutPublicRoute
          component={ForgotPasswordView}
          path="/reset-password"
          exact
        />
        <DashboardPrivateRoute
          component={DashboardPanelv2}
          path="/dashboardPanel"
          mustHaveRole="dashboard"
          exact
        />
        <DashboardPrivateRoute
          component={TicketsListView}
          path="/ticketsAfterSale"
          mustHaveRole="ticket_list"
          exact
        />

        <DashboardPrivateRoute
          component={EvaluationView}
          path="/ticketsEvaluation"
          mustHaveRole="ticket_list"
          exact
        />

        <DashboardPrivateRoute
          component={PreSaleView}
          path="/ticketsPreSale"
          mustHaveRole="ticket_pre_list"
          exact
        />
        <DashboardPrivateRoute
          component={UsersListView}
          path="/old-users"
          exact
          mustHaveRole="user"
        />
        <DashboardPrivateRoute
          component={UserView}
          path="/users/:user"
          mustHaveRole="user"
          exact
        />
        <DashboardPrivateRoute
          component={UserContainer}
          path="/user-container/new"
          mustHaveRole="user"
          exact
        />
        <DashboardPrivateRoute
          component={ProfileView}
          path="/profiles"
          exact
          mustHaveRole="user"
        />

        <DashboardPrivateRoute
          component={UserManagementIframe}
          path="/users-management"
          exact
          mustHaveRole="user"
        />

        <DashboardPrivateRoute
          component={DepartmentListIframe}
          path="/department/:department/list"
          exact
          mustHaveRole="user"
        />

        <DashboardPrivateRoute
          component={DepartmentDetailsIframe}
          path="/department/:department/details/:id"
          exact
          mustHaveRole="user"
        />

        <DashboardPrivateRoute
          component={ProfileContainer}
          path="/profiles/new"
          exact
          mustHaveRole="user"
        />
        <DashboardPrivateRoute
          component={ProfileContainer}
          path="/profiles/edit/:id"
          mustHaveRole="user"
          exact
        />
        <DashboardPrivateRoute
          component={CompaniesListView}
          path="/companies"
          exact
          mustHaveRole="company_list"
        />
        <DashboardPrivateRoute
          component={CompanyContainer}
          path="/company-container"
          exact
          mustHaveRole="company_new"
        />
        <DashboardPrivateRoute
          component={GroupsListView}
          path="/groups"
          exact
          mustHaveRole="group"
        />
        <DashboardPrivateRoute
          component={GroupContainer}
          path="/groups/:groupname"
          exact
          mustHaveRole="group"
        />
        <DashboardPrivateRoute
          component={TicketContainer}
          path="/ticket-container/:id"
          mustHaveRole="ticket_detail"
          exact
        />
        <DashboardPrivateRoute
          component={KnowledgeBaseListView}
          path="/knowledge-base"
          mustHaveRole="knowledge_base"
          exact
        />
        <DashboardPrivateRoute
          component={KnowledgeBaseContainer}
          path="/knowledge-base/:id"
          mustHaveRole="knowledge_base"
          exact
        />
        <DashboardPrivateRoute
          component={KnowledgeBaseContainer}
          path="/knowledge-base/new"
          mustHaveRole="knowledge_base"
          exact
        />
        <DashboardPrivateRoute
          component={KnowledgeBaseCategoryListView}
          path="/knowledge-base-categories"
          mustHaveRole="knowledge_base"
          exact
        />
        <DashboardPrivateRoute
          component={KnowledgeBaseCategoryContainer}
          path="/knowledge-base-categories/:id"
          mustHaveRole="knowledge_base"
          exact
        />
        <DashboardPrivateRoute
          component={KnowledgeBaseCategoryContainer}
          path="/knowledge-base-categories/new"
          mustHaveRole="knowledge_base"
          exact
        />
        <DashboardPrivateRoute
          component={NewTicket}
          path="/newGuideTicket/"
          mustHaveRole="ticket_new"
          exact
        />
        <DashboardPrivateRoute
          component={PresaleChatIframe}
          path="/chat"
          mustHaveRole="chat"
          exact
        />

        <DashboardPrivateRoute
          component={PresaleChatIframe}
          path="/chat/b2b"
          mustHaveRole="chat_b2b"
          exact
        />

        <DashboardPrivateRoute
          component={ChatsV2}
          path="/chats"
          exact
          mustHaveRole="chat"
        />
        <DashboardPrivateRoute
          component={ChatsV2}
          path="/chats/b2b"
          exact
          mustHaveRole="chat_b2b"
        />

        <DashboardPrivateRoute
          component={MonitorChat}
          path="/monitor/chat"
          exact
          mustHaveRole="monitor_chat"
        />
        <MonitorPrivateRoute
          component={MonitorChat}
          path="/monitor/chat/:screen"
          exact
          mustHaveRole="monitor_chat"
        />

        <DashboardPrivateRoute
          component={ChatHistoryPageV2}
          path="/historico-chats"
          exact
          mustHaveRole="chat"
        />

        <DashboardPrivateRoute
          component={ChatHistoryPageV2}
          path="/historico-chats/b2b"
          exact
          mustHaveRole="chat_b2b"
        />

        <DashboardPrivateRoute
          component={BlockedIPs}
          path="/blocked-ips"
          exact
          mustHaveRole="chat"
        />

        <DashboardPrivateRoute
          component={BlockedIPsIframe}
          path="/chat/clientes-bloqueados"
          exact
          mustHaveRole="chat"
        />

        <DashboardPrivateRoute
          component={BlockedIPsIframe}
          path="/chat/clientes-bloqueados/b2b"
          exact
          mustHaveRole="chat"
        />

        <DashboardPrivateRoute
          component={BlockedIPs}
          path="/blocked-ips/b2b"
          exact
          mustHaveRole="chat_b2b"
        />

        <DashboardPrivateRoute
          component={AutomationList}
          path="/automations"
          exact
          mustHaveRole="automations"
        />
        <DashboardPrivateRoute
          component={ChatContainer}
          path="/chat-container/:id"
          exact
          mustHaveRole="chat"
        />
        <DashboardPrivateRoute
          component={AutomationView}
          path="/automation/:id"
          exact
          mustHaveRole="automations"
        />
        <DashboardPrivateRoute
          component={MacrosList}
          path="/macros"
          exact
          mustHaveRole="macros"
        />
        <DashboardPrivateRoute
          component={MacrosEdit}
          path="/macros/new"
          exact
        />
        <DashboardPrivateRoute
          component={QuickAnswersList}
          path="/quick-answers"
          exact
          mustHaveRole="macros"
        />
        <DashboardPrivateRoute
          component={QuickAnswersEdit}
          path="/quick-answers/new"
          exact
        />
        <DashboardPrivateRoute
          component={Setup}
          path="/setup"
          exact
          mustHaveRole="system_params_list"
        />
        <DashboardPrivateRoute
          component={CampaignCalendar}
          path="/campaign-calendar"
          exact
          mustHaveRole="campaign"
        />
        <DashboardPrivateRoute
          component={TagsListView}
          path="/tags"
          exact
          mustHaveRole="tags"
        />
        <DashboardPrivateRoute
          component={AnaliseAnunciosListView}
          path="/salesAdAnalysis"
          exact
          mustHaveRole="sales_ad_analysis"
        />
        <DashboardPrivateRoute
          component={Logistics}
          path="/cancelling/logistics"
          exact
        />
        <DashboardPrivateRoute
          component={LogisticsDetail}
          path="/logistics-details/:id"
          exact
        />
        <DashboardPrivateRoute
          component={ServiceDetail}
          path="/service-details/:id"
          exact
        />
        <DashboardPrivateRoute
          component={Service}
          path="/cancelling/service"
          exact
        />
        <DashboardPrivateRoute
          component={MonitorMarketplace}
          path="/monitor"
          exact
        />
        <DashboardPrivateRoute
          component={LegalProcessDetail}
          path="/legal-process/:id"
          mustHaveRole="ticket_detail"
          exact
        />
        <DashboardPrivateRoute
          component={ChatSettings}
          path="/chats/settings"
          exact
        />

        <DashboardPrivateRoute
          component={ChatSettings}
          path="/chats/settings/:business_type"
          exact
        />

        <DashboardPrivateRoute
          component={ChatTriggers}
          path="/chats/triggers"
          exact
        />

        <DashboardPrivateRoute
          component={ChatTriggers}
          path="/chats/triggers/:business_type"
          exact
        />

        <DashboardPrivateRoute
          component={TicketIframe}
          path="/tickets/details/:marketplace/:id"
          exact
        />

        <DashboardPrivateRoute
          component={TicketDetailsIframe}
          path="/ticket/details/:marketplace/:id"
          exact
        />

        <DashboardPrivateRoute
          component={ChatHistory}
          path="/chat/historico"
          exact
        />

        <DashboardPrivateRoute
          component={ChatHistory}
          path="/chat/historico/b2b"
          exact
        />

        <DashboardPrivateRoute
          component={ChatHistoryDetail}
          path="/chat/historico/:id"
        />

        <DashboardPrivateRoute
          component={ChatHistoryDetail}
          path="/chat/historico/b2b/:id"
        />

        <Route component={NotFoundView} />
      </Switch>
    </LastLocationProvider>
  );
}
