/* eslint-disable react/self-closing-comp */
import React, { useState, useEffect } from 'react';
import { AppBar, Box, makeStyles, Toolbar } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import config from '../../config';
import userService from '../../services/user.service';
import API from '../../services/api';
import Logo from '../../components/Logo';
import UserInfo from './Tools/UserInfo';
import OnlineAlert from './Tools/OnlineAlert';
import { DrawerMenu } from './drawerMenu';
import { registerStatus } from '../../stores/userStatusStore';
import { FavoriteModal } from './favoritesBar/favoriteModal';
import { FavoritesBar } from './favoritesBar/favoritesBar';

// const query = new URLSearchParams(window.location.search);
// const fakeHeader = query.get('header');

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  formControl: {
    minWidth: 200
  },
  paper: {
    top: '0px !important'
  },
  list: {
    width: 300,
    height: '100%'
  },
  fullList: {
    width: 'auto'
  }
  // toolbar: {
  //   minHeight: fakeHeader ? 48 : 64
  // }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  let getHealthMonitor;
  let userAlive; // setintervals para checar se o Monitor está sem falhas e enviar um "alive" do usuário logado

  const classes = useStyles();
  const [online, setOnline] = useState({ online: true, severity: 'green' });
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // Verifica se o status retornado pelo Backend é diferente do existente no localStorage e
  // caso positivo atualiza o Backend com o status do localStorage.
  // Isto visa resolver o problema do usuário que fecha abruptamente o Conecta e ao retornar
  // não atualiza o status no BD.
  const checkAndUpdateStatus = (statusFromBD) => {
    const statusFromLocalStorage = localStorage.getItem('status');
    if (
      statusFromBD &&
      statusFromLocalStorage &&
      statusFromBD !== statusFromLocalStorage
    ) {
      registerStatus(statusFromLocalStorage);
    }
  };

  const handleHealthCheck = () => {
    if (
      window.location.pathname !== '/login' ||
      window.location.pathname !== '/change-password'
    ) {
      API.get(`/auth/lambda/monitor/get-monitor-health`)
        .then((response) => {
          setOnline(response?.data);
        })
        .catch(() => {});
    }
  };

  const handleAliveCheck = () => {
    if (
      window.location.pathname !== '/login' &&
      userService.getCompanyName() === config.cognito.ownerCompany
    ) {
      API.post(`/auth/ms-ticket/v1/user-status-ping`)
        .then((response) => {
          checkAndUpdateStatus(response?.data?.results[0]?.status);
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    // handleHealthCheck();
    getHealthMonitor = setInterval(() => {
      handleHealthCheck();
    }, 60000); // 1 minutes
    userAlive = setInterval(() => {
      handleAliveCheck();
    }, 300000); // 5 minutes
    return () => {
      clearInterval(getHealthMonitor);
      clearInterval(userAlive);
    };
  }, []);

  // const AcerHeaderFake = () => (
  //   <div
  //     style={{
  //       width: '100%',
  //       height: '45px',
  //       minHeight: '45px',
  //       background: '#5f5f5f',
  //       display: 'flex',
  //       alignItems: 'center',
  //       gap: 8,
  //       padding: '10px 24px'
  //     }}
  //   >
  //     <svg
  //       width="75"
  //       height="18"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //       className="_logo_1ath8_63"
  //     >
  //       <path
  //         d="M74.877.82c-.253-.375-.568-.628-1.84-.728-.338-.023-1.442-.061-3.305-.061-4.898 0-8.218 1.487-9.928 4.415.468-2.951-2.407-4.423-8.632-4.423-7.314 0-11.876 2.944-13.693 8.831-.674 2.193-.743 3.987-.214 5.382l-1.725.115c-1.35.1-3.12.192-4.21.192-2.675 0-4.339-.453-4.975-1.35-.575-.805-.514-2.3.153-4.484 1.112-3.611 3.88-5.244 8.296-5.244 2.292 0 4.515.238 4.515.238.307-.023.445-.338.414-.606l-.046-.345-.16-1.219c-.1-.797-.699-1.272-1.78-1.418A36.517 36.517 0 0 0 34.935 0h-.061C29.1.015 25.075 1.909 22.798 5.688 23.633 1.894 20.965 0 14.778 0 11.345 0 8.875.1 7.38.291 6.238.437 5.533.905 5.287 1.717l-.49 1.564c-.092.284.046.43.414.43h.015c.268-.039 1.319-.139 3.105-.284 2.361-.192 4.117-.284 5.267-.284 3.404 0 4.838.851 4.3 2.553-.114.376-.375.613-.773.705-3.22.537-5.773.92-7.667 1.15-5.32.675-8.395 2.33-9.215 4.975-1.135 3.627 1.71 5.443 8.525 5.443 3.404 0 6.256-.199 8.563-.582 1.335-.223 2.002-.56 2.27-1.418l1.272-4.04c-.13 4.04 2.7 6.071 8.51 6.071 2.515 0 4.9-.084 6.019-.276 1.188-.207 1.755-.468 2.024-1.364l.253-1.273c1.234 1.924 3.948 2.89 8.134 2.89 4.416 0 7-.123 7.728-.368.613-.2.996-.514 1.134-.958.023-.077.039-.161.054-.253l.529-1.717c.092-.3-.046-.445-.406-.43l-2.83.138c-1.885.108-3.365.161-4.43.161-2.01 0-3.381-.138-4.14-.414-1.12-.429-1.649-1.28-1.603-2.575l8.955-1.15c4.017-.514 6.7-1.748 8.057-3.71l-3.036 10.57c-.061.2-.038.361.07.476.114.13.306.2.59.2h3.71c.644 0 .882-.338.959-.614l3.273-11.407c.43-1.503 1.81-2.23 4.224-2.23 2.178 0 3.81.022 4.861.06h.084c.207 0 .575-.076.76-.567l.574-1.564c.115-.306.085-.575-.069-.797m-59.73 13.715c-1.158.184-2.821.268-4.99.268-3.765 0-5.413-.759-4.938-2.285.345-1.088 1.326-1.748 2.967-1.97l8.571-1.15-1.61 5.129v.008Zm39.606-9.246c-.36 1.173-2.04 1.97-5.03 2.4L42.57 8.709l.184-.583c.59-1.924 1.434-3.235 2.522-3.933 1.081-.69 2.852-1.042 5.298-1.042 3.22 0 4.607.72 4.163 2.146Z"
  //         fill="#fff"
  //       ></path>
  //     </svg>
  //   </div>
  // );

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        {/* {fakeHeader && <AcerHeaderFake />}
      <Toolbar className={classes.toolbar}> */}
        <Logo />
        <Box flexGrow={1}>
          <FavoritesBar />
        </Box>
        <OnlineAlert online={online} />
        <UserInfo name={userService.getUsername()} />
        <DrawerMenu
          {...{ toggleDrawer, state, classes, getHealthMonitor, userAlive }}
        />
      </Toolbar>
      <FavoriteModal />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  className: '',
  onMobileNavOpen: () => {}
};

export default TopBar;
