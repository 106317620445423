import React, { useEffect } from 'react';
import {
  Box,
  Container,
  Button,
  CircularProgress,
  Divider
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Page from '../../../components/Page';
import ChatTriggersHooks from './hooks/chatTriggers.hooks';

import { StyledSpinner } from '../../../styles/components/SpinnerDefault';
import HeaderTitle from '../../../components/HeaderTitle';

import {
  AddTriggerModal,
  HeaderSubtitle
} from './components/addTrigger.modal.component';
import { HelpIcon } from '../../../components/HelpIcon.component';
import { ConfigSection } from './components/ConfigSection';
import MainHeader from './components/MainHeader';

const TriggerSettingsTitle = ({ handleEdit, setShowModal, t }) => {
  const { business_type } = useParams();
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <HeaderTitle
          title={`${t('i18n.monitor.chat.TRIGGERS_TITLE')} ${
            business_type ? 'B2B' : 'B2C'
          }`}
        />
        <Button
          color="primary"
          onClick={() => {
            handleEdit({});
            setShowModal(true);
          }}
          startIcon={<Add />}
        >
          {t('i18n.monitor.chat.TRIGGERS_NEW')}
        </Button>
      </Box>

      <Box mt={3}>
        <Divider />
      </Box>
    </>
  );
};

const TriggerSettingsSection = ({
  title,
  data,
  hint,
  type,
  handleEdit,
  handleDeleteUnique,
  handleEnable,
  handleGet,
  handleDelete
}) => {
  return (
    <>
      <Box mt={1} display="flex" style={{ gap: '8px' }}>
        <HeaderSubtitle>{title}</HeaderSubtitle>
        <HelpIcon tooltip={hint} />
      </Box>

      <Box mt={3}>
        <ConfigSection
          {...{
            data,
            type,
            handleEdit,
            handleDeleteUnique,
            handleEnable,
            handleGet,
            handleDelete
          }}
        />
      </Box>

      <Box mt={3}>
        <Divider />
      </Box>
    </>
  );
};

const ChatTriggers = () => {
  const {
    t,
    loading,
    showModal,
    setShowModal,
    refreshTableContent,
    handleEdit,
    handleClose,
    handlePost,
    handleDeleteUnique,
    editItem,
    setEditItem,
    defaultSchedule,
    otherEnabledSchedules,
    disabledSchedules,
    handleEnable,
    handleGet,
    handleDelete
  } = ChatTriggersHooks();

  useEffect(() => {
    refreshTableContent();
  }, []);

  return (
    <Page title="">
      <Container maxWidth={false} style={{ opacity: loading ? '0.5' : '1' }}>
        <Box mt={3}>
          <TriggerSettingsTitle {...{ handleEdit, setShowModal, t }} />
          <Box mt={1}>
            <MainHeader />
          </Box>

          {/** Default Trigger */}
          <TriggerSettingsSection
            title={t('i18n.monitor.chat.TRIGGERS_DEFAULT_ACTIVE')}
            hint={`Configurações de horário de atendimento do chat. Configure um horário 
            padrão para o funcionamento do chat em dias normais e crie um novo horário de 
            atendimento para dias específicos. Ative o horário customizado para o horário de 
            atendimento customizado ser habilitado nos dias determinados.`}
            data={defaultSchedule}
            type="default"
            {...{ handleEdit, handleDeleteUnique, handleEnable }}
          />

          {/** Temporary Enabled Triggers */}
          <TriggerSettingsSection
            title={t('i18n.monitor.chat.TRIGGERS_OTHERS')}
            hint="Outros horários de atendimento cadastrados"
            data={otherEnabledSchedules}
            {...{
              handleEdit,
              handleDeleteUnique,
              handleEnable,
              handleGet,
              handleDelete
            }}
          />

          {/** Other disabled schedules */}
          <TriggerSettingsSection
            title={t('i18n.monitor.chat.TRIGGERS_OTHERS_DISABLED')}
            hint="Outros horários de atendimento cadastrados"
            data={disabledSchedules}
            {...{
              handleEdit,
              handleDeleteUnique,
              handleEnable,
              handleGet,
              handleDelete
            }}
          />
        </Box>
      </Container>
      <AddTriggerModal
        {...{
          showModal,
          setShowModal,
          refreshTableContent,
          editItem,
          setEditItem,
          handleClose,
          handlePost,
          defaultSchedule
        }}
      />
      <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
        <CircularProgress />
      </StyledSpinner>
    </Page>
  );
};

export { ChatTriggers };
