import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  ButtonGroup,
  CardActions,
  Grid,
  Tooltip
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { InsertDriveFile } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import TicketMacroInput from '../../TicketContainer/TicketComponents/TicketMacroInput';
import { useMarketplaceList } from '../../../../hooks/hooks';
import marketPlacesEnum from '../../../../enums/marketPlaces.enum';
import MessageInput from './MessageInput.component';
import MessageRecipient from './MessageRecipient.component';
import {
  StyledTooltip,
  StyledButton,
  StyledTextarea,
  StyledBox,
  GridActions,
  GridActionsMacroAttachments,
  GridActionsButtonAttachments
} from '../styles';

import { isB2BProtocol } from '../../../../utils/b2bHelper';
import ScheduleButton from './schedule/ScheduleButton';
import { getScheduleStatus } from './schedule/functions';

const MessageActions = ({
  typeMessage,
  chatState,
  chat,
  dadosPedido,
  getTicket,
  handleAppliedMacro,
  attachPDFTerm,
  disableAttachment = false,
  getRootProps,
  getInputProps,
  files,
  loading,
  mlTicketblocked,
  resposta,
  setResposta,
  setRecipientClient,
  setRecipientOperator,
  setAssignResponsible,
  setSendEmail,
  disableSend = false,
  sendResponse,
  hideMacro = false,
  b2wChatClassification,
  setB2wModalOpen,
  b2wSelectedClassification
}) => {
  const [scheduleOpen, setScheduleOpen] = useState(false);
  const { t } = useTranslation();
  const marketplacesFromHooks = useMarketplaceList();

  const agendamento = chat?.protocolSchedulingResource;

  // Marketplaces onde o envio de Anexo e Termo de Devolução são permitidos
  const allowedToSendAttachment = () => {
    if (typeMessage === 'internalMessage') {
      return true;
    }
    const result = marketplacesFromHooks.some(
      el => el.allowedToSendAttachment && el.value === chatState?.marketPlace
    );
    return result;
  };

  const isGenericMessage = () => {
    return typeMessage === 'normalMessage';
  };

  const emitMagaluAlert = () => {
    if (
      chatState?.marketPlace === marketPlacesEnum.MAGALU &&
      chat?.marketplaceBundle?.reason === 'Reclamação' &&
      typeMessage === 'normalMessage'
    ) {
      return true;
    }
    return false;
  };

  return (
    <CardActions disableSpacing className="message-text">
      <GridActions container spacing={0}>
        <GridActionsMacroAttachments container spacing={0}>
          <Grid item lg={9}>
            {hideMacro ? (
              <></>
            ) : (
              <TicketMacroInput
                chat={chat}
                dadosPedido={dadosPedido}
                getTicket={getTicket}
                handleAppliedMacro={handleAppliedMacro}
                typeMessage={typeMessage}
                style={{ width: '100%', background: '#fff' }}
                noLabel
                small
                placeholder={t('i18n.macros.APPLY_MACRO')}
              />
            )}
          </Grid>
          <GridActionsButtonAttachments item lg={3}>
            {allowedToSendAttachment() && (
              <>
                {isGenericMessage() && isB2BProtocol(chat) && (
                  <StyledTooltip
                    title={t('i18n.ticketcontainer.INSERT_RETURN_TERM')}
                  >
                    <StyledButton
                      variant="outlined"
                      color="primary"
                      onClick={() => attachPDFTerm()}
                      size="small"
                    >
                      <InsertDriveFile />
                    </StyledButton>
                  </StyledTooltip>
                )}
                <Tooltip title={t('i18n.chatview.SEND_ATTACHMENT')}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    disabled={disableAttachment}
                    {...getRootProps({ className: 'dropzone' })}
                    size="small"
                  >
                    <AttachFileIcon />
                  </StyledButton>
                </Tooltip>
              </>
            )}
          </GridActionsButtonAttachments>
        </GridActionsMacroAttachments>
        <Grid item md={12}>
          {emitMagaluAlert() && (
            <Alert severity="warning">
              {t('i18n.ticketcontainer.MAGALU_MEDIATION_MESSAGE')}
            </Alert>
          )}

          <MessageInput
            typeMessage={typeMessage}
            marketplace={chatState?.marketPlace}
            loading={loading}
            resposta={resposta}
            setResposta={setResposta}
            fieldBlocked={typeMessage === 'MLClaim' ? false : mlTicketblocked}
            i18nPlaceholder={
              typeMessage === 'internalMessage'
                ? 'i18n.chatview.TYPE_INTERNAL_NOTE'
                : 'i18n.chatview.TYPE_MESSAGE'
            }
          />

          <Box m={1}>
            <StyledTextarea>
              <Box m={1}>
                {getInputProps && (
                  <>
                    <input {...getInputProps()} />
                    {files.length > 0 && <> {files} </>}
                  </>
                )}
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  <ScheduleButton
                    {...{ scheduleOpen, setScheduleOpen, agendamento, chat }}
                  />
                  {agendamento && (
                    <span style={{ fontFamily: 'Roboto' }}>
                      {getScheduleStatus(agendamento.status)}
                    </span>
                  )}
                </Box>
                <div />
                <StyledBox>
                  {isGenericMessage() && (
                    <MessageRecipient
                      marketplace={chatState?.marketPlace}
                      setRecipientClient={setRecipientClient}
                      setRecipientOperator={setRecipientOperator}
                      setAssignResponsible={setAssignResponsible}
                      setSendEmail={setSendEmail}
                    />
                  )}
                  <ButtonGroup
                    disableElevation
                    variant="contained"
                    color="primary"
                    style={{ gap: '30px' }}
                  >
                    {b2wChatClassification && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setB2wModalOpen(true);
                        }}
                      >
                        {b2wSelectedClassification?.subject?.description ||
                          t(
                            'i18n.ticketcontainer.B2W_CLASSIFICATION_SELECTION'
                          )}
                      </Button>
                    )}
                    <Button
                      disabled={disableSend}
                      onClick={() => {
                        sendResponse(resposta);
                      }}
                    >
                      {t('i18n.usercontainer.SEND')}
                    </Button>
                  </ButtonGroup>
                </StyledBox>
              </Box>
            </StyledTextarea>
          </Box>
        </Grid>
      </GridActions>
    </CardActions>
  );
};

export default MessageActions;
