import {
  Box,
  Divider,
  Popover,
  Typography,
  makeStyles
} from '@material-ui/core';

import Tooltip from '@material-ui/core/Tooltip';
import { AccountCircle } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import config from '../../../config';
import userService from '../../../services/user.service';
import { store } from '../../../stores/userStatusStore';
import API from '../../../services/api';

const StyledIcon = styled.span`
  border-radius: 100%;
  background-color: ${props => props.color};
  margin-left: 5px;
  display: flex;
  cursor: ${props => (props.pointer ? 'pointer' : '')};
`;

const StyledUserName = styled.span`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: ${props => (props.status === 'deactivated' ? '#666666' : 'white')};
`;

const loadFillColor = status => {
  switch (status) {
    case 'active':
      return '#83E554';
    case 'busy':
      return '#DB6362';
    case 'lunch':
      return '#3F73D6';
    case 'inactive':
      return '#FFBD2A';
    case 'deactivated':
      return '#B7B8AA';
    default:
      return 'gray';
  }
};

const StyledMenuContainer = styled.div`
  padding: 10px;
`;

const SmallMenuTitle = styled.div`
  font-size: 12px;
`;

const MenuStatus = styled.div`
  font-size: 14px;
  font-weight: bold;

  span {
    color: ${props => props.color};
  }
`;

const Dot = styled.div`
  height: 12px;
  width: 12px;
  background-color: ${props => props.color};
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
`;

const ActionItem = styled.div`
  font-size: 12px;
  cursor: pointer;
`;

const translateStatus = status => {
  switch (status) {
    case 'active':
      return 'Ativo';
    case 'inactive':
      return 'Inativo';
    case 'busy':
      return 'Em outra atividade';
    default:
      return 'Ativo';
  }
};

const setUserToBusy = status => {
  if (status === 'busy') {
    store.dispatch({ type: 'CHANGE_STATUS', status: 'active' });
    return false;
  }
  store.dispatch({ type: 'CHANGE_STATUS', status: 'busy' });
};

const MenuComponent = ({ status, time }) => {
  return (
    <StyledMenuContainer>
      <Box display="flex" flexDirection="column" mb={1}>
        <SmallMenuTitle>Status:</SmallMenuTitle>
        <MenuStatus {...{ color: loadFillColor(status) }}>
          {translateStatus(status)} - <span>{time}</span>
        </MenuStatus>
      </Box>
      <Divider />
      <Box mt={1} display="flex" alignItems="center">
        <Dot color={status === 'busy' ? 'green' : 'red'} />
        <ActionItem onClick={() => setUserToBusy(status)} id="busy">
          {' '}
          {status === 'busy' ? 'Voltar para ativo' : 'Em outra atividade'}
        </ActionItem>
      </Box>
    </StyledMenuContainer>
  );
};

const getStatus = (status, setStatus, setTime, setCount) => {
  store.subscribe(() => {
    const innerStatus = store.getState().status;
    setStatus(innerStatus);
    setTime(store.getState().time);
    if (innerStatus === 'busy') {
      setCount(store.getState().count);
    }
  });
};

const StyledMenu = styled(Popover)`
  font-family: Roboto;
  padding: 5px;
  margin-top: 10px;
`;

const StyledTypography = styled(Typography)`
  padding: 5px 10px 5px 10px;
  color: white;
  font-size: 12px;
`;

const Icon = ({ name }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [status, setStatus] = useState('active');
  const [time, setTime] = useState('00h00m');
  const [count, setCount] = useState(0);
  const [alertBusyTimer, setAlertBusyTimer] = useState(0);
  const [warning, setWarning] = useState(false);
  const fillColor = loadFillColor(status);
  const companyFromToken = userService.getCompanyName();
  const ownerCompany = companyFromToken === config.cognito.ownerCompany;

  const getAlertBusyTimer = async () => {
    try {
      const response = await API.post('/auth/ms-ticket/v1/parameters/filter', {
        name: 'MIN_WARNING_BUSY_STATUS_USER'
      });
      const timer = response.data._embedded.systemParamsResourceList[0].value;
      setAlertBusyTimer(timer);
    } catch {
      setAlertBusyTimer(0);
    }
  };

  useEffect(() => {
    if (ownerCompany) {
      getStatus(status, setStatus, setTime, setCount);
    }
  }, []);

  useEffect(() => {
    const countMinutes = count > 0 ? count / 60 : count;
    if (
      status === 'busy' &&
      alertBusyTimer > 0 &&
      countMinutes > alertBusyTimer &&
      !warning
    ) {
      enqueueSnackbar(
        `Você está há mais de ${alertBusyTimer} minuto(s) em outra atividade`,
        {
          variant: 'warning',
          persist: true
        }
      );
      setWarning(true);
    }
  }, [count]);

  useEffect(() => {
    if (status !== 'busy') {
      setWarning(false);
    }
    if (status === 'busy') {
      getAlertBusyTimer();
    }
  }, [status]);

  const handleMenuOpen = e => {
    setAnchorMenu(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorMenu(null);
  };

  const openMenu = Boolean(anchorMenu);

  const StatusTextComponent = () => {
    return (
      <StyledTypography>
        Status: <b>{translateStatus(status)}</b>
      </StyledTypography>
    );
  };

  const fillIconColor = () => {
    if (status === 'deactivated') {
      return '#919297';
    }
    return 'white';
  };

  const useStyles = makeStyles(() => ({
    arrow: {
      fontSize: 20,
      '&:before': {
        border: 'solid #666666',
        boxSizing: 'border-box',
        borderColor: 'transparent transparent #666666 transparent',
        transform: `skew(55deg)`
      }
    },
    tooltip: {
      border: '1px solid #666666',
      color: '#666666',
      placement: 'bottom-end'
    }
  }));

  const classes = useStyles();

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginLeft="5px"
        marginRight="5px"
        cursor="pointer"
      >
        <Tooltip
          title={<StatusTextComponent />}
          arrow
          classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        >
          <StyledUserName {...{ status }}>{name}</StyledUserName>
        </Tooltip>
      </Box>
      <StyledIcon
        onClick={e => {
          if (ownerCompany) {
            if (anchorMenu) {
              handleMenuClose();
            } else {
              handleMenuOpen(e);
            }
          }
        }}
        pointer={ownerCompany}
        color={fillColor}
      >
        <AccountCircle
          style={{
            margin: '-4px',
            fill: fillIconColor()
          }}
          fontSize="large"
        />
      </StyledIcon>

      <StyledMenu
        open={openMenu}
        anchorEl={anchorMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handleMenuClose}
      >
        <MenuComponent {...{ status, time }} />
        <div id="container" />
      </StyledMenu>
    </>
  );
};

export default function UserInfo({ name }) {
  return <Icon {...{ name }} />;
}
