import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  TextField
} from '@material-ui/core';
import React from 'react';
import OrderItem from './OrderItem';

import {
  BoldValue,
  CancelHeader,
  CustomerSection,
  DetailsContainer,
  DetailsSection,
  OrangeWarning,
  OrdersHeaderSection,
  OrdersHeaderTitle,
  OrdersHeaderValue
} from '../../../Dialogs/styles';
import { numberToBRL } from '../../../../../../../helpers/utils';
// import { useStyles } from './styles';

const useStyles = makeStyles(() =>
  createStyles({
    inputOrangeSmall: {
      width: '100px',
      height: '30px',
      fontSize: '12px',
      fontWeight: 'bold',
      color: '#ED7D31',
      '& .MuiOutlinedInput-input': {
        textAlign: 'right',
        backgroundColor: '#FFFFFF'
      }
    }
  })
);

export default function Details({
  orderData,
  cancellingData,
  orderTotalValue,
  tracking,
  finance,
  view,
  authorizeDevolution,
  totalRefund,
  setTotalRefund
}) {
  const classes = useStyles();
  return (
    <DetailsSection>
      <Box display="flex">
        <CancelHeader style={{ marginRight: '10px' }}>
          Cancelar todos os itens do pedido?
        </CancelHeader>
        <OrdersHeaderValue>
          {cancellingData?.isCancellationOfAllItens ? 'Sim' : 'Não'}
        </OrdersHeaderValue>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <div style={{ width: '360px' }}>
          <OrangeWarning>Itens para cancelamento:</OrangeWarning>
        </div>
        <div
          style={{
            width: '90px',
            display: 'inline-block',
            alignSelf: 'flex-end'
          }}
        >
          Código <br />
          (Part Number)
        </div>
        <div
          style={{
            width: '50px',
            display: 'inline-block',
            alignSelf: 'flex-end'
          }}
        >
          QTD Original
        </div>
        <div
          style={{
            width: '60px',
            display: 'inline-block',
            alignSelf: 'flex-end'
          }}
        >{`QTD ${cancellingData?.cancellationType}`}</div>
        <div
          style={{
            width: '75px',
            display: 'inline-block',
            alignSelf: 'flex-end'
          }}
        >
          Valor Un
        </div>
        <div
          style={{
            width: '75px',
            display: 'inline-block',
            alignSelf: 'flex-end'
          }}
        >
          Valor Total
        </div>
      </Box>
      <DetailsContainer>
        {orderData?.itens?.map(item => {
          return (
            <OrderItem
              key={Math.random()}
              {...{
                item,
                cancellingData
              }}
            />
          );
        })}
        <Box
          display="flex"
          justifyContent="space-between"
          style={{
            borderBottom: '1px solid #CCCCCC',
            paddingBottom: '5px'
          }}
          mb={2}
        >
          <div style={{ width: '430px' }}> </div>
          <div
            style={{
              width: '300px',
              color: '#434343',
              fontSize: '11px',
              fontWeight: 'normal',
              textAlign: 'right',
              margin: '5px 5px 15px 0px'
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={8} style={{ height: '15px' }}>
                Subtotal:
              </Grid>
              <Grid item xs={4} style={{ height: '15px' }}>
                <BoldValue>{numberToBRL(orderTotalValue)}</BoldValue>
              </Grid>
              <Grid item xs={8} style={{ height: '15px' }}>
                Desconto:
              </Grid>
              <Grid item xs={4} style={{ height: '15px' }}>
                <BoldValue>{numberToBRL(0)}</BoldValue>
              </Grid>
              <Grid item xs={8} style={{ height: '15px' }}>
                Frete:
              </Grid>
              <Grid item xs={4} style={{ height: '15px' }}>
                <BoldValue>{numberToBRL(orderData?.totalFrete || 0)}</BoldValue>
              </Grid>
            </Grid>
          </div>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <div style={{ width: '430px' }}> </div>
          <div
            style={{
              width: '300px',
              color: '#434343',
              fontSize: '11px',
              fontWeight: 'normal',
              textAlign: 'right'
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={8} style={{ height: '15px' }}>
                Total:
              </Grid>
              <Grid item xs={4} style={{ height: '15px' }}>
                <BoldValue>
                  {numberToBRL(orderTotalValue + (orderData?.totalFrete || 0))}
                </BoldValue>
              </Grid>
            </Grid>
          </div>
        </Box>
      </DetailsContainer>
      <Box display="flex" justifyContent="space-between" mt={1}>
        <Box display="flex" jusfitycontent="space-between">
          <CustomerSection margin>
            <OrdersHeaderTitle>Nome do cliente</OrdersHeaderTitle>
            <OrdersHeaderValue>
              {orderData?.cliente?.nome || 'Aguarde...'}
            </OrdersHeaderValue>
          </CustomerSection>
          <OrdersHeaderSection margin>
            <OrdersHeaderTitle>CPF</OrdersHeaderTitle>
            <OrdersHeaderValue>
              {orderData?.cliente?.documento || 'Aguarde...'}
            </OrdersHeaderValue>
          </OrdersHeaderSection>
        </Box>
        {!tracking &&
          !finance &&
          !view &&
          cancellingData?.cancellationType === 'ESTORNO' && (
            <Box display="flex" alignItems="center">
              <OrangeWarning marginRight="10px">
                Solicitar estorno de R$
              </OrangeWarning>
              {authorizeDevolution ? (
                <div>{numberToBRL(totalRefund)}</div>
              ) : (
                <div>
                  <TextField
                    value={totalRefund}
                    size="small"
                    variant="outlined"
                    onChange={e => {
                      setTotalRefund(e.target.value);
                    }}
                    InputProps={{ root: classes.inputOrangeSmall }}
                  />
                </div>
              )}
            </Box>
          )}
        {(finance || tracking || view) && (
          <>
            {cancellingData?.cancellationType === 'ESTORNO' && (
              <OrangeWarning marginRight="10px">
                Valor do Estorno: {numberToBRL(totalRefund)}
              </OrangeWarning>
            )}
          </>
        )}
      </Box>
    </DetailsSection>
  );
}
