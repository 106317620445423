import React, { useState } from 'react';

import { Box, Card, Divider, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../../styles/settings/colors.config';
import {
  CardDataWrapper,
  CustomCardContent,
  CardTitle,
  InConstruction,
  CardTitleTypography,
  CustomArrowDropDown,
  CustomCardContentNoPadding,
  CustomTypographLink,
  CustomArrowDropDownUp,
  CustomTypograph
} from '../../styles';
import CardHint from '../../components/CardHint';
import IndividualDataCard from '../../components/IndividualDataCard';
import handleOpenNewTabHook from '../../components/handleGetTabFilterHook';
import TeamDataCard from './sla/TeamDataCard';
import formHelper from '../../../../utils/formHelper';

const hint = () => {
  return (
    <ul style={{ listStyleType: 'none' }}>
      <li>
        Motivos de protocolos de Nível 1, Nível 2 e Nível 3 especificados por analistas
      </li>
    </ul>
  );
};

const DividerWithArrowClosed = () => {
  return (
    <>
      <Box marginLeft="22px" marginRight="22px">
        <Divider />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        marginBottom="-35px"
        marginTop="-2px"
      >
        <CustomArrowDropDown iconcolor="#80C343" />
      </Box>
    </>
  );
};

const DividerWithArrowOpened = () => {
  return (
    <>
      <Box marginLeft="22px" marginRight="22px">
        <Divider />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        marginBottom="-29px"
        marginTop="-32px"
      >
        <CustomArrowDropDownUp iconcolor="#80C343" />
      </Box>
    </>
  );
};

const ItemData = ({
  item,
  headerData,
  handleOpenNewTab,
  withDrillDown,
  reasonGroup
}) => {
  return (
    <Grid
      container
      justifycontent="flex-start"
      alignItems="center"
      xs={12}
      spacing={1}
    >
      <Grid item xs={4}>
        {withDrillDown ? (
          <CustomTypographLink
            align="right"
            style={{
              fontSize: headerData ? '24px' : '24px',
              marginInlineStart: '-15px'
            }}
            onClick={() => {
              handleOpenNewTab(item.label, reasonGroup);
            }}
          >
            {formHelper.addZeroToCompleteTwoDigits(item.value)}
          </CustomTypographLink>
        ) : (
          <CustomTypograph
            align="right"
            colortext={COLORS.dashboardNoDrilldownItems}
            style={{
              fontSize: '24px',
              marginInlineStart: '-15px'
            }}
          >
            {formHelper.addZeroToCompleteTwoDigits(item.value)}
          </CustomTypograph>
        )}
      </Grid>
      <Grid item xs={7}>
        <p
          style={{
            fontSize: '12px',
            fontWeight: '500',
            color: COLORS.dashboardNoDrilldownItems
          }}
        >
          {item.label}
        </p>
      </Grid>
    </Grid>
  );
};

const ReasonsCard = ({
  descriptionHint,
  headerMultipleData,
  isTeamView,
  showInConstruction,
  headerData,
  showMoreDetails,
  extraDataN1,
  extraDataN2,
  extraDataN3,
}) => {
 
  const { t } = useTranslation();
  const dashboardFilter = useSelector(state => state.dashboardFilterReducer);
  const { handleOpenNewTab } = handleOpenNewTabHook({
    dashboardFilter,
    t,
    key: 'sla'
  });
  const [open, setOpen] = useState(false);
  
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Card
        style={{ width: '100%', borderRadius: '20px', paddingBottom: '30px' }}
      >
        <CardTitle>
          <CardTitleTypography>
            Motivos N1, N2 e N3 por Analista
          </CardTitleTypography>
          {descriptionHint && <CardHint hint={hint()} noWidth />}
        </CardTitle>
        <Divider />

        <Grid container spacing={2}>
          {headerMultipleData?.map(item => (
            <Grid item xs={4} key={item.subtitle} >
              <CustomCardContent>
                <CardDataWrapper>
                  {!isTeamView && (
                    <IndividualDataCard
                      firstLabel={item.firstLabel}
                      firstData={item.firstData}
                      secondaryLabel={item.secondaryLabel}
                      secondaryData={item.secondaryData}
                      withDrillDown={item.action}
                      title={item.subtitle}
                      handleOpenNewTab={handleOpenNewTab}
                    />
                  )}

                  {isTeamView && (
                    <TeamDataCard {...{ item, handleOpenNewTab, isTeamView }} />
                  )}
                </CardDataWrapper>
              </CustomCardContent>
            </Grid>
          ))}
        </Grid>

        {showMoreDetails && (
          <>
            {headerData && !open && (
              <span onClick={() => setOpen(true)} aria-hidden="true">
                <DividerWithArrowClosed />
              </span>
            )}
            {headerData && open && (
              <span onClick={() => setOpen(false)} aria-hidden="true">
                <DividerWithArrowOpened />
              </span>
            )}

            {open && (
              <CustomCardContentNoPadding>
                <Grid container spacing={1}>
                  <Grid item xs={4} style={{ paddingLeft: '25px' }}>
                    {extraDataN1?.map(
                      item =>
                        item.show && (
                          <ItemData
                            {...{
                              item,
                              headerData,
                              handleOpenNewTab,
                              withDrillDown: true,
                              reasonGroup: 'N1'
                            }}
                            key={Math.random()}
                          />
                        )
                    )}
                  </Grid>
                  <Grid item xs={4} style={{ paddingLeft: '25px', borderLeft: '1px solid #D9D9D9',borderRight: '1px solid #D9D9D9' }}>
                    {extraDataN2?.map(
                      item =>
                        item.show && (
                          <ItemData
                            {...{
                              item,
                              headerData,
                              handleOpenNewTab,
                              withDrillDown: true,
                              reasonGroup: 'N2'
                            }}
                            key={Math.random()}
                          />
                        )
                    )}
                  </Grid>
                  <Grid item xs={4} style={{ paddingLeft: '25px', borderLeft: '1px solid #D9D9D9',borderRight: '1px solid #D9D9D9' }}>
                    {extraDataN3?.map(
                      item =>
                        item.show && (
                          <ItemData
                            {...{
                              item,
                              headerData,
                              handleOpenNewTab,
                              withDrillDown: true,
                              reasonGroup: 'N3'
                            }}
                            key={Math.random()}
                          />
                        )
                    )}
                  </Grid>
                </Grid>
              </CustomCardContentNoPadding>
            )}
          </>
        )}
      </Card>
      {showInConstruction && <InConstruction />}
    </div>
  );
};

export default ReasonsCard;
