/*eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { COLORS } from '../../../styles/settings/colors.config';
import { useReportTypesList } from '../../../hooks/hooks';

import timezoneFormat from '../../../utils/timezoneFormat';

const convertFreqToString = freq => {
  switch (freq) {
    case 'W':
      return 'Em Semanas';
    case 'M':
      return 'Em Meses';
    default:
      return 'Em Dias';
  }
};

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

const convertDateFilter = (date, period) => {
  if (period === 'end') {
    return moment(date)
      .subtract(1, 'days')
      .format('DD/MM/YYYY');
  }

  return timezoneFormat(date, 'DD/MM/YYYY');
};

const FilterChipDescribe = ({ filterKey, value }) => {
  const { t } = useTranslation();
  const reportTypes = useReportTypesList();

  if (filterKey === 'status') {
    return <>{t(`i18n.ticketslistview.ticketStatus.${value}`)}</>;
  }

  if (filterKey === 'report_type') {
    const label = reportTypes.find(item => {
      return item.value === value;
    });
    return <>{label.label}</>;
  }

  return <>{value}</>;
};

const FilterHeaderDescribe = ({ filterKey, value, startDate, endDate }) => {
  const { t } = useTranslation();

  if (filterKey === 'realTime') {
    return (
      <>
        <>{t('i18n.dashboard.timefilter.realTime')}</>
      </>
    );
  }

  if (filterKey === 'hourly') {
    return (
      <>
        <>{`${value} ${t('i18n.ticketslistview.ticketStatus.hours_ago')}`}</>
      </>
    );
  }

  if (filterKey === 'weekly') {
    return (
      <>
        <>{`${t('i18n.dashboard.timefilter.last_seven_days')}`}</>
      </>
    );
  }

  if (filterKey === 'byperiod') {
    return (
      <>
        <>{t(`i18n.dashboard.timefilter.${value}`)}</>
      </>
    );
  }

  if (filterKey === 'custom') {
    const newStartDate = convertDateFilter(startDate, 'start');
    let newEndDate;
    if (endDate !== 'Invalid date') {
      newEndDate = convertDateFilter(endDate, 'end');
    }

    return (
      <>{newEndDate ? `de ${newStartDate} a ${newEndDate}` : newStartDate}</>
    );
  }

  return <>{value}</>;
};

export { FilterHeaderDescribe, FilterChipDescribe };
