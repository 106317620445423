import React from 'react';
import { CardContent, Grid } from '@material-ui/core';
import AgentMessageCard from '../baloons/AgentMessageCard';
import ClientMessageCard from '../baloons/ClientMessageCard';
import { StyledPaper } from '../styles';

const MediationB2w = ({ b2wMediation }) => {
  return (
    <>
      <StyledPaper className="message-paper">
        <CardContent>
          {b2wMediation?.map(m => {
            let div;
            if (m.agent === 'CUSTOMER') {
              div = (
                <Grid container spacing={1} style={{ marginBottom: '12px' }}>
                  <Grid item md={10} xs={12}>
                    <ClientMessageCard
                      message={m}
                      customerName={m.username}
                      marketPlace="B2W"
                      typeMessage="b2wMediationMessage"
                    />
                  </Grid>
                </Grid>
              );
            } else {
              div = (
                <Grid container spacing={1} style={{ marginBottom: '12px' }}>
                  <Grid item md={2} xs={12} />
                  <Grid item md={10} xs={12}>
                    <AgentMessageCard
                      message={m}
                      marketPlace="B2W"
                      typeMessage="b2wMediationMessage"
                    />
                  </Grid>
                </Grid>
              );
            }
            return <div key={Math.random()}>{div}</div>;
          })}
        </CardContent>
      </StyledPaper>
    </>
  );
};

export default MediationB2w;
