/* eslint-disable react/destructuring-assignment */
import { Container, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../services/api';
import Page from '../../../components/Page';
import LegalProcessTitle from './LegalProcessTitle';
import LegalProcessTicketHeader from './LegalProcessTicketHeader';
import LegalProcessInfoHeader from './LegalProcessInfoHeader';
import LegalProcessInfoDetail from './LegalProcessInfoDetail';
import LegalProcessResume from './LegalProcessResume';
import LegalProcessFiles from './LegalProcessFiles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

export default function LegalProcessDetail(props) {
  const [marketPlace] = useState('B2W');
  const [ticketType] = useState('AFTER_SALE');
  const [ticketData, setTicketData] = useState('');
  const [protocolData, setProtocolData] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const getTicket = async () => {
    const protocolID = props.match.params.id;
    let ticketId;

    try {
      const { data } = await API.get(
        `/auth/ms-ticket/v1/marketplace-juridical/${protocolID}`
      );
      ticketId = data.ticketId;
      setProtocolData(data);
    } catch (error) {
      enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
        variant: 'error'
      });
    }

    if (ticketId) {
      try {
        const { data } = await API.get(
          `/auth/ms-ticket/v1/tickets/${ticketId}`
        );
        setTicketData(data);
      } catch (error) {
        enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
          variant: 'error'
        });
      }
    }
  };

  useEffect(() => {
    getTicket();
  }, []);

  const classes = useStyles();

  const observacao = protocolData?.protocolsVariablesList?.find(
    it => it.variableId === 'obsEtapaB' || it.variableId === 'obsEtapaC'
  );

  const resumoInicial = protocolData?.protocolsVariablesList?.find(
    it => it.variableId === '963_prju_H_R10_resInicial_txt'
  );

  const resumoSentenca = protocolData?.protocolsVariablesList?.find(
    it => it.variableId === '809_resumo_sentenca_txt'
  );

  const formattedObservacao = observacao?.domains[0].desc
    .split('\n')
    .map(item => <p>{item}</p>);

  const formattedResumoInicial = resumoInicial?.domains[0].desc
    .split('\n')
    .map(item => <p>{item}</p>);

  const formattedResumoSentenca = resumoSentenca?.domains[0].desc
    .split('\n')
    .map(item => <p>{item}</p>);

  return (
    <>
      <Page
        className={classes.root}
        title={t('i18n.legalprotocol.LEGAL_PROCESSES_TITLE')}
      >
        <Container maxWidth={false}>
          <LegalProcessTitle
            {...{ marketPlace, ticketType, ticketData, protocolData }}
          />
          <LegalProcessTicketHeader data={ticketData} raTicketsList={[]} />
          <LegalProcessInfoHeader {...{ protocolData }} />
          <LegalProcessInfoDetail {...{ protocolData }} />
          {resumoInicial && (
            <LegalProcessResume
              title={t('i18n.legalprotocol.INITIAL_SUMMARY')}
              text={formattedResumoInicial}
            />
          )}
          {observacao && (
            <LegalProcessResume
              title={t('i18n.legalprotocol.COMMENTS')}
              text={formattedObservacao}
            />
          )}
          {resumoSentenca && (
            <LegalProcessResume
              title={t('i18n.legalprotocol.VERDICT_SUMMARY')}
              text={formattedResumoSentenca}
            />
          )}
          <LegalProcessFiles {...{ protocolData }} />
        </Container>
      </Page>
    </>
  );
}
