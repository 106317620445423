import { Box } from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import {
  CancelSection,
  CustomTypography,
  OrdersHeaderSection,
  OrdersHeaderTitle,
  OrdersHeaderValue,
  TransportSectionHeader,
  VerticalDivider
} from '../../../Dialogs/styles';

export default function CancelOptions({ cancellingData }) {
  return (
    <CancelSection>
      <Box display="flex" flexDirection="column">
        <TransportSectionHeader>
          Qual o motivo do cancelamento?
        </TransportSectionHeader>
        <OrdersHeaderSection>
          <OrdersHeaderValue>
            {cancellingData.reasonCancellation}
          </OrdersHeaderValue>
        </OrdersHeaderSection>
      </Box>
      <Box display="flex" flexDirection="column">
        <TransportSectionHeader>Detalhe do Cancelamento</TransportSectionHeader>
        <OrdersHeaderSection>
          <OrdersHeaderValue>{cancellingData.detail}</OrdersHeaderValue>
        </OrdersHeaderSection>
      </Box>
      <Box display="flex" flexDirection="column">
        <TransportSectionHeader>
          Com quem está o produto?
        </TransportSectionHeader>
        <OrdersHeaderSection>
          <OrdersHeaderValue>
            {cancellingData.productInPossession}
          </OrdersHeaderValue>
        </OrdersHeaderSection>
      </Box>
      <VerticalDivider height="40px" />
      <Box display="flex">
        <Box>
          <OrdersHeaderTitle marginBottom="10px">
            Cancelamento Solicitado em
          </OrdersHeaderTitle>
          <CustomTypography bold>
            {moment(cancellingData.stepsTimeLine[0].date).format('DD/MM/YYYY')}
          </CustomTypography>
        </Box>
        <Box marginLeft="20px">
          <OrdersHeaderTitle marginBottom="10px">Responsável</OrdersHeaderTitle>
          <CustomTypography bold>{cancellingData.specialist}</CustomTypography>
        </Box>
      </Box>
    </CancelSection>
  );
}
