import React, { useEffect, useState } from 'react';
import { Switch, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { changeLanguage } from '../../i18n';
import { COLORS } from '../../styles/settings/colors.config';

const CustomTypography = styled(Typography)`
  color: ${COLORS.greenText};
`;

const SwitchLanguage = () => {
  const [lang, setLang] = useState('pt_br');
  const [state, setState] = React.useState({
    checkedA: false
  });

  const handleChangeSwitch = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (event.target.checked) {
      localStorage.setItem('language', 'en');
      setLang('en');
      changeLanguage('en');
    } else {
      localStorage.setItem('language', 'pt_br');
      setLang('pt_br');
      changeLanguage('pt_br');
    }
  };

  useEffect(() => {
    const storageLanguage = localStorage.getItem('language');

    if (storageLanguage === 'pt_br') {
      setState({ ...state, checkedA: false });
    }
    if (storageLanguage === 'en') {
      setState({ ...state, checkedA: true });
    }

    setLang(localStorage.getItem('language') ?? 'pt_br');
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Switch
        checked={state.checkedA}
        onChange={handleChangeSwitch}
        name="checkedA"
        color="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <CustomTypography>{lang === 'pt_br' ? 'PT' : 'ENG'}</CustomTypography>
    </div>
  );
};

export default SwitchLanguage;
