import React, { useState } from 'react';
import { Typography, Box, Grid, Button, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';
import { Delete } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../../utils/ConfirmationModal';
import { brFormat } from '../../../../utils/timezoneFormat';
import { COLORS } from '../../../../styles/settings/colors.config';

const CustomBox = styled(Box)`
  font-family: Roboto;
  background: ${props => (props.active === 'true' ? '#FFEED2' : '#fff')};
  box-shadow: 0px 0px 6px #00000030;
  margin-top: 16px;
  border-radius: 20px;
  border: ${props =>
    props.active === 'true' && `2px solid ${COLORS.WarningColorSLA}`};
`;

const CustomTypography = styled.span`
  color: ${COLORS.grayText};
`;

const CustomText = styled(Typography)`
  color: ${COLORS.greenText};
  font-weight: bold;
  :hover {
    text-decoration: underline;
  }
`;

const ChatPeriodText = ({ startDate, endDate }) => {
  const fixedStartDate = brFormat(startDate, 'DD/MM/YYYY');
  const fixedEndDate = brFormat(endDate, 'DD/MM/YYYY');

  return (
    <>
      {endDate && (
        <>
          <div>
            <b>de {fixedStartDate}</b>
          </div>
          <div>
            <b>a {fixedEndDate}</b>
          </div>
          <CustomTypography>
            Após a vigência retorna ao horário padrão
          </CustomTypography>
        </>
      )}

      {!endDate && (
        <>
          <div>
            <b>a partir de {fixedStartDate}</b>
          </div>
          <CustomTypography>Sem término</CustomTypography>
        </>
      )}
    </>
  );
};

const ConfigRow = ({ item, handleEdit, type, handleEnable, handleDelete }) => {
  const newItem = { ...item };
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  let convertedCreatedDate;

  if (item?.creationDate) {
    const createdDate = new Date(item?.creationDate);
    createdDate.setHours(createdDate.getHours() - 3);
    convertedCreatedDate = moment(createdDate).format('DD/MM/YYYY HH:mm');
  }

  return (
    <CustomBox
      display="flex"
      style={{ width: '100%' }}
      py={4}
      px={2}
      active={item?.isCurrent ? 'true' : 'false'}
    >
      <Grid container spacing={3}>
        <Grid item xs={1}>
          {type !== 'default' && (
            <IconButton
              color="primary"
              onClick={() => setConfirmationModalOpen(true)}
            >
              <Delete />
            </IconButton>
          )}
        </Grid>

        <Grid item xs={3}>
          <CustomText>{item?.description}</CustomText>
        </Grid>

        <Grid item xs={1}>
          {item?.timeFirstTrigger} min
        </Grid>

        <Grid item xs={1}>
          {item?.timeSecondTrigger} min
        </Grid>

        <Grid item xs={3}>
          <ChatPeriodText
            startDate={item?.validityStart}
            endDate={item?.validityEnd}
          />
        </Grid>

        <Grid item xs={1}>
          <b>{convertedCreatedDate}</b>
        </Grid>

        <Grid item xs={1}>
          <Button color="primary" onClick={() => handleEdit(newItem)}>
            Editar
          </Button>
        </Grid>

        <Grid item xs={1}>
          {type !== 'default' && (
            <Button color="primary" onClick={() => handleEnable(item)}>
              {item?.isActive ? 'Desabilitar' : 'Habilitar'}
            </Button>
          )}
        </Grid>
      </Grid>

      <ConfirmationModal
        message="Confirma a exclusão do gatilho?"
        title="Atenção"
        submitConfirmation={() => handleDelete(item?.id)}
        openState={confirmationModalOpen}
        closeParent={() => {
          setConfirmationModalOpen(false);
        }}
      />
    </CustomBox>
  );
};

const ConfigSection = ({
  data,
  type,
  handleEdit,
  handleDeleteUnique,
  handleEnable,
  handleGet,
  handleDelete
}) => {
  const newData = [...data];
  const { t } = useTranslation();
  return (
    <Box style={{ width: '100%' }}>
      {newData.map(item => {
        return (
          <ConfigRow
            key={Math.random()}
            {...{
              item,
              handleDeleteUnique,
              handleEdit,
              type,
              handleEnable,
              handleGet,
              handleDelete
            }}
          />
        );
      })}
      {data.length === 0 && (
        <Alert severity="info">
          {t('i18n.monitor.chat.SETTINGS_NO_TRIGGERS')}
        </Alert>
      )}
    </Box>
  );
};

export { ConfigSection };
