import {
  Button,
  CardContent,
  Grid,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import styled from 'styled-components';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { COLORS } from '../../../../styles/settings/colors.config';

export const MessageArea = styled(Grid)``;

export const MessageAreaButtons = styled('div')`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-itens: flex-start;
`;

export const MessageAreaButtonsTools = styled(MessageAreaButtons)`
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
`;

export const CustomField = styled(TextField)`
  .MuiInputBase-root.Mui-disabled,
  textarea {
    border-top: 0px solid transparent;
    border-radius: 0px 0px 3px 3px;
    padding: 10px !important;
    font-size: 12px;
  }

  .MuiOutlinedInput-multiline {
    padding: 0px;
  }
  .MuiOutlinedInput-root {
    border-radius: 0px 0px 3px 3px !important;
  }
`;

export const OnlineButton = styled(Button)`
  height: 30px;
`;

export const StyledBox = styled(CardContent)`
  font-weight: bold;
  .online {
    color: ${COLORS.blackText};
  }
  display: flex;
  justify-content: space-between;
  .status {
    border: 1px solid #9e9e9e;
    border-radius: 50%;
    height: 10px;
    margin-right: 8px;
    width: 10px;
    &.online {
      border-color: #8bc34a;
      background: #8bc34a;
    }
    &.offline {
      background: #fff;
    }
  }
  .btn-history {
  }
`;

export const PaperStyled = styled(Paper)`
  p {
    cursor: pointer;
    padding: 16px;
    &:hover {
      background-color: ${COLORS.lightGray};
    }
  }
`;

export const Accordion = styled(MuiAccordion)`
  margin: 0px !important;
  background: ${props => props.expanded && '#f1f1f1'};
  p {
    font-weight: ${props => props.expanded && 'bold'};
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
    aling-items: center;
  }
`;
export const AccordionDetails = styled(MuiAccordionDetails)`
  display: block;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  max-height: 590px;
  @media (max-width: 1399px) {
    max-height: 290px;
  }
`;

export const NotifyWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const Notify = styled.span`
  background: ${COLORS.greenText};
  color: #fff;
  padding: 3px 10px;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

export const NotifyTitle = styled(Typography)`
  color: ${COLORS.grayText};
`;

export const CustomLink = styled(Typography)`
  cursor: pointer;
  :hover {
    background-color: #ddd;
  }
  a {
    padding: 15px;
    font-size: 16px;
    color: ${COLORS.grayText};
    display: block;
    text-decoration: none !important;
  }
`;
