import React from 'react';
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid
} from '@material-ui/core';

import { inputStyles } from './inputStyle';

/**
 * Radio group to forms
 * @param {String} name - String - Name of the checkbox group
 * @param {String} label - String - Label of the checkbox group
 * @param {Object} form - Object - State of form that recieve the checkbox values
 * @param {Function} setForm - Function - Open controller
 * @param {Array} options - Array - List of the checkbox
 * @param {String} gapOptions - String - (Optional) Gap space between radio options
 */

const RadioComponent = ({
  name,
  form,
  setForm,
  label,
  options,
  gapOptions
}) => {
  const classes = inputStyles();

  return (
    <Grid container>
      <Grid item xs={5}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={7}>
        <FormControl
          component="fieldset"
          style={{
            display: 'flex',
            gap: '20px',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <RadioGroup
            value={form[name] || ''}
            name={name}
            onChange={htmlElemtEvent => {
              setForm({
                ...form,
                [name]: htmlElemtEvent.target.value
              });
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              style={{
                gap: gapOptions || '29px',
                marginLeft: '40px'
              }}
            >
              {options.map(item => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio className={classes.radio} color="primary" />}
                  label={item.name}
                />
              ))}
            </Box>
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default RadioComponent;
