import { Card, Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  border: 1px solid #b7b7b7;
  border-radius: 8px;
  padding: 10px 20px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 12px;
  margin-top: 10px;
`;

const StyledTitle = styled.div`
  font-weight: bold;
  color: #434343;
  display: flex;
  justify-content: flex-start;
`;

const StyledItem = styled.div`
  color: #434343;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export default function LegalProcessResume({ title, text }) {
  return (
    <StyledCard>
      <Grid container>
        <Grid item xs={12}>
          <StyledTitle>{title}</StyledTitle>
          <StyledItem>{text}</StyledItem>
        </Grid>
      </Grid>
    </StyledCard>
  );
}
