const marketplaceScoreLoading = false;
const marketplaceScoreDashboardTeamData = {};
const marketplaceScoreDashboardIndividualData = {};
const marketplaceScoreDashboardFilter = {};

const marketplaceScoreDashboardTeamDataReducer = (
  state = marketplaceScoreDashboardTeamData,
  action
) => {
  switch (action.type) {
    case 'SET_MARKETPLACE_SCORE_DASHBOARD_TEAM_DATA':
      state = action.teamData;
      return state;
    default:
      return state;
  }
};

const marketplaceScoreDashboardIndividualDataReducer = (
  state = marketplaceScoreDashboardIndividualData,
  action
) => {
  switch (action.type) {
    case 'SET_MARKETPLACE_SCORE_DASHBOARD_INDIVIDUAL_DATA':
      state = action.individualData;
      return state;
    default:
      return state;
  }
};

const marketPlaceScoreLoadingReducer = (
  state = marketplaceScoreLoading,
  action
) => {
  switch (action.type) {
    case 'SET_MARKETPLACE_SCORE_DASHBOARD_LOADING':
      state = action.loading;
      return state;
    default:
      return state;
  }
};

const marketPlaceScoreDashboardFilterReducer = (
  state = marketplaceScoreDashboardFilter,
  action
) => {
  switch (action.type) {
    case 'SET_MARKETPLACE_SCORE_DASHBOARD_FILTER':
      state = action.filter;
      return state;
    default:
      return state;
  }
};

export {
  marketPlaceScoreLoadingReducer,
  marketplaceScoreDashboardTeamDataReducer,
  marketplaceScoreDashboardIndividualDataReducer,
  marketPlaceScoreDashboardFilterReducer
};
