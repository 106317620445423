import { Box, Button, Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import API from '../../../../../../services/api';
import { Cap } from '../../../RightMenuComponents/CAP/CAP';
import CancellingTransportSection from '../Components/Sections/CancellingTransport';

import { ActionsSection, VerticalDivider } from '../../Dialogs/styles';
import OrdersHeaderDiv from '../Components/Sections/OrdersHeader';
import CancelOptions from './Sections/CancelOptions';

import CancelItemsContainer from './Sections/CancelItemsContainer';
import {
  retrieveOrderHeaderData,
  validateCancelling
} from '../../functions/functions';
import OpenConfirm from '../../Dialogs/OpenConfirm';

const StyledButton = styled(Button)`
  text-transform: unset !important;
`;

export default function Attendant({
  data,
  updateAttendantAnswerToBeInputed,
  setOpen,
  reason,
  dataOrder
  // setReloadPending
}) {
  const [reasonCancellation, setReasonCancellation] = useState('');
  const [cancelDetail, setCancelDetail] = useState('');
  const [orderLocation, setOrderLocation] = useState('');
  const [transportType, setTransportType] = useState('');
  const [cancelAll, setCancelAll] = useState('');
  const [rastreioDevolucao, setRastreioDevolucao] = useState('');
  const [numCAP, setNumCAP] = useState('');
  const [dataEmissao, setDataEmissao] = useState('');
  const [statusCAP, setStatusCAP] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [isShippingCompany, setIsShippingCompany] = useState(false);
  const [isExchange] = useState(false);
  const [totalRefund, setTotalRefund] = useState(0);
  const [capReceivedData, setCapReceivedData] = useState('');
  const [cancellationType, setCancellationType] = useState('');
  const [cancellingDialog, setCancellingDialog] = useState(false);
  const [prazoCAP, setPrazoCAP] = useState('');
  const [tipoCAP, setTipoCAP] = useState('');
  const [idClienteCAP, setIdClienteCAP] = useState('');
  const [horaSolicitacaoCAP, setHoraSolicitacaoCAP] = useState('');

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    const total = selectedItems.reduce((acc, item) => {
      return acc + item.valueToCancel * item.quantityToCancel;
    }, 0);
    setTotalRefund(total.toLocaleString('pt-BR', { minimumFractionDigits: 2 }));
  }, [selectedItems]);

  useEffect(() => {
    setReasonCancellation(data.reasonNew.name);
    setCancelDetail(data.reasonNew.desc);
  }, [data]);

  useEffect(() => {
    if (capReceivedData) {
      const dataMX = capReceivedData.data_solicitacao.split('/');
      const newDate = `${dataMX[2]}-${dataMX[1]}-${dataMX[0]}`;
      setDataEmissao(newDate);
      setNumCAP(capReceivedData.numero_coleta);
      setStatusCAP(capReceivedData.status_objeto);
      setPrazoCAP(capReceivedData.prazo);
      setTipoCAP(capReceivedData.tipo);
      setIdClienteCAP(capReceivedData.id_cliente);
      setHoraSolicitacaoCAP(capReceivedData.hora_solicitacao);
    }
  }, [capReceivedData]);

  useEffect(() => {
    if (
      reason === 'troca' ||
      reason?.code === 'troca' ||
      reason?.name === 'Troca'
    ) {
      setCancellationType('TROCA');
    } else {
      setCancellationType('ESTORNO');
    }
  }, [reason]);

  const orderHeaderDataLine1 = retrieveOrderHeaderData(dataOrder, 1);
  const orderHeaderDataLine2 = retrieveOrderHeaderData(dataOrder, 2);
  const orderHeaderDataLine3 = retrieveOrderHeaderData(dataOrder, 3);

  const handleSearchCAP = () => {
    return (
      <Cap
        updateAttendantAnswerToBeInputed={updateAttendantAnswerToBeInputed}
        ticketData={data}
        setCapReceivedData={setCapReceivedData}
        cancellation
        capReceivedData={capReceivedData}
      />
    );
  };

  const handleTransportType = ev => {
    if (ev.target.value === 'Transportadora') {
      setIsShippingCompany(true);
      setNumCAP('');
      setDataEmissao('');
      setStatusCAP('');
    } else {
      setIsShippingCompany(false);
    }

    setTransportType(ev.target.value);
  };

  const handleCancelling = async () => {
    const processTotalRefund = refund => {
      return Number(refund.replace('.', '').replace(',', '.'));
    };

    const processedItems = selectedItems.map(item => {
      return { ...item, date: '', status: '', detail: '', isAuthorized: false };
    });

    const json = {
      conectaTicketId: data.id,
      specialist: data.serviceAttendantLogin,
      reasonCancellation,
      detail: cancelDetail,
      isExchange,
      nf: dataOrder.numeroNF,
      dataNF: dataOrder.dataNF,
      itensNumberNF: dataOrder.itens.length,
      productInPossession: orderLocation,
      isProductInPossessionAcer: orderLocation === 'Acer',
      reverseLogisticsEntity: {
        tipo: tipoCAP,
        idCliente: idClienteCAP,
        numeroColeta: numCAP,
        prazo: prazoCAP,
        dataSolicitacao: dataEmissao,
        horaSolicitacao: horaSolicitacaoCAP,
        statusObjeto: statusCAP
      },
      order: dataOrder,
      objectTracking: rastreioDevolucao,
      itensToCancel: processedItems,
      isShippingCompany,
      reversedValue: processTotalRefund(totalRefund),
      isCancellationOfAllItens: cancelAll === 'SIM',
      cancellationType
    };

    await API.post(`/auth/ms-ticket/v1/cancellationProcess/create`, json).catch(
      () => {
        enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
          variant: 'error'
        });
        return false;
      }
    );

    enqueueSnackbar(
      `Solicitação de CANCELAMENTO ${
        cancellationType !== 'TROCA' ? 'COM ESTORNO' : 'COM TROCA'
      } criada com SUCESSO`,
      {
        variant: 'success'
      }
    );
    setOpen(false);
    // setReloadPending(true);
    /**
     * Voltando com a atualização da página uma vez que o processo de cancelamento e troca ainda não está
     * 100% mapeado. Estudar como isto será implementado a partir da R5
     */
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleCancelAll = ev => {
    const { value } = ev.target;

    const items = dataOrder?.itens.map((it, i) => {
      return {
        ...it,
        i,
        quantityToCancel: it.quantidade,
        valueToCancel: it.valorBruto
      };
    });

    if (value === 'SIM') {
      setSelectedItems(items);
    } else {
      setSelectedItems([]);
    }

    setCancelAll(value);
  };

  const orderTotalValue =
    dataOrder?.itens?.reduce(
      (acc, item) => acc + item.valorBruto * item.quantidade,
      0
    ) || 0;

  const validateAndOpenDialog = () => {
    const valid = validateCancelling(
      cancellationType,
      reasonCancellation,
      cancelDetail,
      orderLocation,
      enqueueSnackbar,
      transportType,
      isShippingCompany,
      numCAP,
      dataEmissao,
      statusCAP,
      rastreioDevolucao,
      selectedItems
    );
    if (valid) setCancellingDialog(true);
  };

  return (
    <Box width="100%">
      <CancelOptions
        {...{
          reasonCancellation,
          cancelDetail,
          setCancelDetail,
          orderLocation,
          setOrderLocation
        }}
      />
      <Box paddingX={2} paddingY={2} style={{ fontFamily: 'Roboto' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <OrdersHeaderDiv
              {...{
                orderHeaderDataLine1,
                orderHeaderDataLine2,
                orderHeaderDataLine3
              }}
            />
          </Grid>

          <Grid item xs={6} style={{ display: 'flex' }}>
            <VerticalDivider height="120px" />
            <CancellingTransportSection
              {...{
                transportType,
                handleTransportType,
                handleSearchCAP,
                rastreioDevolucao,
                setRastreioDevolucao,
                numCAP,
                setNumCAP,
                dataEmissao,
                setDataEmissao,
                statusCAP,
                setStatusCAP
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <CancelItemsContainer
        {...{
          cancelAll,
          handleCancelAll,
          cancellationType,
          dataOrder,
          selectedItems,
          setSelectedItems,
          setTotalRefund,
          setCancelAll,
          orderTotalValue,
          isExchange,
          totalRefund
        }}
      />
      <ActionsSection>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={validateAndOpenDialog}
        >
          Solicitar Cancelamento{' '}
          {cancellationType !== 'TROCA' ? 'com Estorno' : 'com Troca'}
        </StyledButton>
      </ActionsSection>
      <OpenConfirm
        open={cancellingDialog}
        setOpen={setCancellingDialog}
        {...{
          handleCancelling,
          cancelAll,
          dataOrder,
          totalRefund,
          cancellationType
        }}
      />
    </Box>
  );
}
