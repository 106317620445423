import React, { useContext } from 'react';
import styled from 'styled-components';
import { CustomTypography } from './card.component';

import { ThemeContext } from '../../../layouts/MonitorLayout';

const CustomStatus = styled('div')`
  display: flex;
  gap: 8px;
  margin: 8px;
  align-items: center;
  :before {
    border-radius: 100%;
    display: block;
    height: 16px;
    width: 16px;
    content: '';
    background: ${props => (props.online ? props.success : props.danger)};
  }
`;

const StatusComponent = ({ online, color }) => {
  const theme = useContext(ThemeContext);

  return (
    <CustomStatus
      online={online}
      success={theme.successV3}
      danger={theme.dangerV2}
    >
      <CustomTypography style={{ color }} font={theme?.font24 || '16px'}>
        {online ? 'Online' : 'Offline'}
      </CustomTypography>
    </CustomStatus>
  );
};

export { StatusComponent };
