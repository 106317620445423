import moment from 'moment';

const stringToDate = (stringDate) => {
  const parts = stringDate.split(' ');
  const date = parts[0];
  const time = parts[1];
  const dateParts = date.split('/');
  const timeParts = time.split(':');
  return new Date(
    dateParts[2],
    dateParts[1] - 1,
    dateParts[0],
    timeParts[0],
    timeParts[1]
  );
};

function strikeThrough(text) {
  return text
    .split('')
    .map((char) => `${char}\u0336`)
    .join('');
}

const filterChipLabeler = (key, value, translator, allDay) => {
  if (key === 'satisfactionScore') {
    return `${value} ${value === 1 ? 'estrela' : 'estrelas'}`;
  }

  if (key === 'hasOpinion') {
    switch (value) {
      case true:
        return 'Sim';
      default:
        return 'Não';
    }
  }

  if (key === 'responseTimeRelationalOperator') {
    switch (value) {
      case 'LESS_THAN_OR_EQUAL':
        return 'Menor ou igual a';
      case 'GREATER_THAN':
        return 'Maior que';
      default:
        return '';
    }
  }

  if (key === 'type') {
    switch (value) {
      case 'pre':
        return 'Offline Acer Store';
      case 'preMl':
        return 'Offline ML';
      case 'chat':
        return 'Online B2C';
      case 'chatB2b':
        return 'Online B2B';
      case 'saudacao_pre_venda_off':
        return translator('i18n.quickanswer.TYPE_OFFLINE_DEFAULT');
      default:
        return value;
    }
  }

  if (key === 'satisfactionScore') {
    switch (value) {
      case 'no-evaluation':
        return 'Sem Avaliação';
      case 'true':
        return 'Like';
      case 'false':
        return 'Dislike';
      default:
        return value;
    }
  }

  if (key === 'commentOnTheScore') {
    if (value) return 'Sim';
  }

  if (key === 'mobile' || key === 'withReason') {
    switch (value) {
      case 'true':
        return translator('i18n.simplewords.YES');
      case 'false':
        return translator('i18n.simplewords.NO');
      default:
        break;
    }
  }

  if (key === 'mediatedOpened') {
    switch (value) {
      case true:
        return translator('i18n.simplewords.YES');
      case false:
        return translator('i18n.simplewords.NO');
      default:
        break;
    }
  }

  if (key === 'mediatedClosed') {
    switch (value) {
      case true:
        return translator('i18n.simplewords.YES');
      case false:
        return translator('i18n.simplewords.NO');
      default:
        break;
    }
  }

  if (key === 'incidentClosed') {
    switch (value) {
      case true:
        return translator('i18n.simplewords.YES');
      case false:
        return translator('i18n.simplewords.NO');
      default:
        break;
    }
  }

  if (key === 'incidentOpened') {
    switch (value) {
      case true:
        return translator('i18n.simplewords.YES');
      case false:
        return translator('i18n.simplewords.NO');
      default:
        break;
    }
  }

  if (key === 'magaluClaim') {
    switch (value) {
      case 'true':
        return translator('i18n.simplewords.YES');
      case 'false':
        return translator('i18n.simplewords.NO');
      case true:
        return translator('i18n.simplewords.YES');
      case false:
        return translator('i18n.simplewords.NO');
      default:
        break;
    }
  }

  if (key === 'sla') {
    switch (value.toLowerCase()) {
      case 'expired':
        return 'SLA vencido';
      case 'expires':
        return 'SLA vence hoje';
      case 'expires_today':
        return 'SLA vence hoje';
      default:
        break;
    }
  }

  if (key === 'protocolSchedulingStatus') {
    switch (value) {
      case 'EXPIRED':
        return 'Vencido';
      case 'EXPIRES_TODAY':
        return 'Vence hoje';
      case 'ACTIVE':
        return 'Ativos';
      default:
        break;
    }
  }

  if (key === 'reclameAqui') {
    if (value === 'true') {
      return translator('i18n.marketplaces.RECLAME_AQUI');
    }
    return strikeThrough(translator('i18n.marketplaces.RECLAME_AQUI'));
  }
  if (key === 'vipClient') {
    if (value === 'true') {
      return translator('i18n.ticketcontainer.CLIENT_BL');
    }
    return strikeThrough(translator('i18n.ticketcontainer.CLIENT_BL'));
  }

  if (key === 'marketPlaceJuridicalIdList') {
    if (value === 'true') {
      return translator('i18n.legalprotocol.HAS_LEGAL_PROCESSES');
    }
    return strikeThrough(translator('i18n.legalprotocol.HAS_LEGAL_PROCESSES'));
  }

  if (key === 'reclamacaoMercadoLivre') {
    if (value === 'true') {
      return translator(`i18n.ticketslistview.ML_CLAIM`);
    }
    return strikeThrough(translator(`i18n.ticketslistview.ML_CLAIM`));
  }
  if (key === 'filtersB2W') {
    if (value === 'new') {
      return translator(`i18n.ticketslistview.B2W_INSTANCES_NEW`);
    }
    if (value === 'inProgress') {
      return translator(`i18n.ticketslistview.B2W_INSTANCES_INPROGRESS`);
    }
  }
  if (key === 'creationDate') {
    const userTimeZone = new Date().getTimezoneOffset();
    let from = value?.split('-')[0];
    let to = value?.split('-')[1] ? value?.split('-')[1] : '';
    if (allDay) {
      from = moment(stringToDate(from)).format('DD/MM/YYYY 00:00');
      to = to ? moment(stringToDate(to)).format('DD/MM/YYYY 23:59') : '';
    } else {
      from = moment(stringToDate(from))
        .utcOffset(userTimeZone * -2)
        .format('DD/MM/YYYY HH:mm');
      to = to
        ? moment(stringToDate(to))
            .utcOffset(userTimeZone * -2)
            .format('DD/MM/YYYY HH:mm')
        : '';
    }
    return `${from} - ${to}`;
  }

  if (key === 'ticketStatus') {
    if (value === 'NEW,AWAITING,ANSWERED,AWAITING_PARTNER,CUSTOMER_RETURN,AWAITING_RA,AWAITING_TI,AWAITING_CLIENT_POST,AWAITING_LOGISTICS,AWAITING_FISCAL,AWAITING_REFUND,AWAITING_MARKETING,AWAITING_FINANCIAL,AWAITING_COMMERCIAL')
      return 'Todos abertos';
    return translator(`i18n.ticketslistview.ticketStatus.${value}`).replace(
      'i18n.ticketslistview.ticketStatus.',
      ''
    );
  }

  return translator(`i18n.ticketslistview.${value}`).replace(
    'i18n.ticketslistview.',
    ''
  );
};
export default {
  filterChipLabeler
};
