/* eslint-disable no-underscore-dangle */
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Loader from '../../../components/Loader';
import Page from '../../../components/Page';
import API from '../../../services/api';
import { CustomBreadCrumbs } from '../../../styles/components/CustomDataTable';
import { StyledSubmitActions } from '../../../styles/components/StyledSubmitActions.styled';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  textEditWidth: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  tableScrollBody: {
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  tableScrollContainer: {
    height: '200px'
  },
  breadcrumbs: {
    marginTop: '15px',
    marginLeft: '24px'
  }
}));

const AutomationView = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const knowledgeId = useParams().id;
  const isCreateCase = () => knowledgeId === 'new';
  const [categoryId, setCategoryId] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [shortAnswer, setShortAnswer] = useState('');
  const [categoryOptions, setCategoryOptioins] = useState([]);
  const [loading, setLoading] = useState(true);

  const postData = (data) => {
    API.post(`/auth/lambda/knowledge`, data)
      .then(() => {
        history.push('/knowledge-base');
        enqueueSnackbar(t('i18n.knowledge.SAVE_EDIT_QUESTION_SUCCESS'), {
          variant: 'success'
        });
        axios.get(
          `${process.env.REACT_APP_FRONTEND_FAQ}/api/faq/invalidate-cache`
        );
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.knowledge.SAVE_QUESTION_ERROR'), {
          variant: 'error'
        });
      });
  };

  const putData = (data) => {
    API.put(`/auth/lambda/knowledge`, data)
      .then(() => {
        history.push('/knowledge-base');
        enqueueSnackbar(t('i18n.knowledge.SAVE_EDIT_QUESTION_SUCCESS'), {
          variant: 'success'
        });
        axios.get(
          `${process.env.REACT_APP_FRONTEND_FAQ}/api/faq/invalidate-cache`
        );
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.knowledge.SAVE_QUESTION_ERROR'), {
          variant: 'error'
        });
      });
  };

  const submitData = () => {
    const data = {
      question,
      answer,
      short_answer: shortAnswer,
      category_id: categoryId,
      business_type: businessType
    };

    if (knowledgeId !== 'new') {
      data._id = knowledgeId;
      putData(data);
    } else {
      postData(data);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const { data } = await API.get(`/auth/lambda/knowledge/category`);
      if (data.status) {
        setCategoryOptioins([]);
      } else {
        setCategoryOptioins(data);
      }
      setLoading(false);
    };
    getData();
  }, []);

  useEffect(() => {
    if (!isCreateCase()) {
      API.get(`/auth/lambda/knowledge`, {
        params: { _id: knowledgeId }
      }).then((response) => {
        setQuestion(response.data[0].question);
        setCategoryId(response.data[0].category_id.$oid);
        setShortAnswer(response.data[0].short_answer);
        setBusinessType(response.data[0].business_type);
        setAnswer(response.data[0].answer);
      });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <Page className={classes.root} title="FAQ">
      {loading ? (
        <Loader />
      ) : (
        <Container maxWidth={false}>
          <CustomBreadCrumbs
            className={classes.breadcrumbs}
            gutterBottom
            aria-label="breadcrumb"
          >
            <Typography variant="h6">
              <Link color="inherit" to="/knowledge-base">
                {t('i18n.knowledge.FAQ')}
              </Link>
            </Typography>
            {isCreateCase() ? (
              <Typography variant="h6" color="textPrimary">
                {t('i18n.knowledge.NEW_QUESTION')}
              </Typography>
            ) : (
              <Typography color="textPrimary">{question}</Typography>
            )}
          </CustomBreadCrumbs>
          <Box mt={2}>
            <Card>
              <CardContent>
                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <Box mb={2}>
                      <TextField
                        value={question}
                        onChange={(ev) => {
                          setQuestion(ev.target.value);
                        }}
                        fullWidth
                        label={t('i18n.knowledge.QUESTION')}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box mb={2}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel
                          id="demo-simple-select-outlined_type"
                          required
                        >
                          {t('i18n.ticketcontainer.TYPE')}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined_type"
                          id="demo-simple-select-outlined_type"
                          value={businessType}
                          onChange={(e) => {
                            setBusinessType(e.target.value);
                            setCategoryId('');
                          }}
                          label={t('i18n.ticketcontainer.TYPE')}
                          required
                        >
                          <MenuItem value="">Selecione</MenuItem>
                          <MenuItem value="B2B">B2B</MenuItem>
                          <MenuItem value="B2C">B2C</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box display="flex" flexDirection="column">
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                      >
                        <FormControl fullWidth variant="outlined">
                          <InputLabel
                            id="demo-simple-select-outlined-label"
                            required
                          >
                            {t('i18n.knowledge.SECTION')}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={categoryId}
                            onChange={(ev) => {
                              setCategoryId(ev.target.value);
                            }}
                            label={t('i18n.knowledge.SECTION')}
                            required
                          >
                            {categoryOptions
                              ?.filter(
                                (item) =>
                                  businessType === '' ||
                                  item.business_type === businessType
                              )
                              .map((item) => (
                                <MenuItem
                                  key={item?._id?.$oid}
                                  value={item?._id?.$oid}
                                >
                                  {item.title}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <TextField
                        value={shortAnswer}
                        onChange={(ev) => {
                          setShortAnswer(ev.target.value);
                        }}
                        fullWidth
                        className={classes.textEditWidth}
                        label={t('i18n.knowledge.SHORT_ANSWER')}
                        multiline
                        minRows={3}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <TextField
                        value={answer}
                        onChange={(ev) => {
                          setAnswer(ev.target.value);
                        }}
                        fullWidth
                        className={classes.textEditWidth}
                        label={t('i18n.knowledge.ANSWER')}
                        multiline
                        minRows={8}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <StyledSubmitActions>
                  <Tooltip title="Cancelar">
                    <Button
                      onClick={() => {
                        history.push('/knowledge-base');
                      }}
                      variant="contained"
                      color="default"
                    >
                      {t('i18n.knowledge.CANCEL')}
                    </Button>
                  </Tooltip>
                  <Tooltip title={t('i18n.newAutomation.SAVE')}>
                    <Button
                      onClick={submitData}
                      variant="contained"
                      color="primary"
                    >
                      {t('i18n.newAutomation.SAVE')}
                    </Button>
                  </Tooltip>
                </StyledSubmitActions>
              </CardContent>
            </Card>
          </Box>
        </Container>
      )}
    </Page>
  );
};

export default AutomationView;
