import styled from 'styled-components';
import { COLORS } from '../../../styles/settings/colors.config';

export const FilterWrapper = styled('div')`
  overflow-y: auto;
  max-height: 650px;
  overflow-x: hidden;
  height: 100%;
  margin-bottom: 20px;
  padding-right: 15px;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: ${COLORS.btnSecondaryColor};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.btnSecondaryColor};
    border: 2px solid ${COLORS.btnSecondaryColor};
  }

  @media (max-width: 1366px) {
    max-height: 390px;
  }
`;
