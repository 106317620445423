import React from 'react';
import { CardContent, Grid } from '@material-ui/core';
import AgentMessageCard from '../baloons/AgentMessageCard';
import ClientMessageCard from '../baloons/ClientMessageCard';
import B2WMarketplaceSupportModal from '../b2w/B2WMarketplaceSupportModal';
import MessageActions from '../components/MessageActions.component';
import { StyledPaper } from '../styles';

const B2wHelpMessages = ({
  b2wHelp,
  normalMessageRespostaLoading,
  marketplaceModalOpen,
  setMarketplaceModalOpen,
  filesToUpload,
  b2wHelpAnswer,
  getRootProps,
  getInputProps,
  files,
  setB2wHelpAnswer,
  b2wChatClassification,
  setB2wModalOpen,
  b2wSelectedClassification,
  sendResponse
}) => {
  return (
    <>
      <StyledPaper className="message-paper">
        <CardContent>
          {b2wHelp?.map(m => {
            let div;
            if (m.agent === 'CUSTOMER' || m.agent === 'EMPLOYEE') {
              div = (
                <Grid container spacing={1} style={{ marginBottom: '12px' }}>
                  <Grid item md={10} xs={12}>
                    <ClientMessageCard
                      message={m}
                      customerName={m.agent}
                      marketPlace="B2W"
                      typeMessage="b2wHelpMessage"
                    />
                  </Grid>
                </Grid>
              );
            } else {
              div = (
                <Grid container spacing={1} style={{ marginBottom: '12px' }}>
                  <Grid item md={2} xs={12} />
                  <Grid item md={10} xs={12}>
                    <AgentMessageCard
                      message={m}
                      marketPlace="B2W"
                      typeMessage="b2wHelpMessage"
                    />
                  </Grid>
                </Grid>
              );
            }
            return <div key={Math.random()}>{div}</div>;
          })}
          {normalMessageRespostaLoading && (
            <div key="loadingNewMlmessage">
              <Grid container spacing={1}>
                <Grid item md={10} xs={12} />
                <Grid item md={10} xs={12}>
                  <AgentMessageCard
                    message={{
                      message: b2wHelpAnswer,
                      status: 'loading',
                      agent: 'ABO'
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </CardContent>
        <B2WMarketplaceSupportModal
          open={marketplaceModalOpen}
          setOpen={setMarketplaceModalOpen}
        />
      </StyledPaper>

      <MessageActions
        typeMessage="b2wHelpMessage"
        disableAttachment={filesToUpload.length > 0 || b2wHelpAnswer.length > 0}
        loading={filesToUpload.length > 0}
        resposta={b2wHelpAnswer}
        setResposta={setB2wHelpAnswer}
        disableSend={
          b2wChatClassification !== null &&
          Object.entries(b2wSelectedClassification).length === 0
        }
        sendResponse={sendResponse}
        b2wChatClassification={b2wChatClassification}
        b2wSelectedClassification={b2wSelectedClassification}
        setB2wModalOpen={setB2wModalOpen}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        files={files}
        chatState={{ marketPlace: 'B2W' }}
        hideMacro
      />
    </>
  );
};

export default B2wHelpMessages;
