import React from 'react';

import { SvgIcon, MuiThemeProvider } from '@material-ui/core';

const Attending = ({ theme }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <SvgIcon
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1000 1000"
        enableBackground="new 0 0 1000 1000"
      >
        <g>
          <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
            <path d="M4562.8,4981.6c-1311.6-150.8-2465.8-979.3-3043-2183.8c-209.8-439.4-367.2-1090.8-367.2-1530.2v-100.6l-150.8-21.9c-417.5-65.6-758.6-376-865.7-784.8C88,170.1,88-1701.1,136.1-1891.3c98.4-376,391.3-666.7,769.5-765.1c96.2-26.2,262.3-32.8,710.5-32.8h583.7l8.7,2124.9c6.6,2076.7,8.8,2131.4,52.5,2312.8c244.8,985.9,900.6,1724.8,1821,2050.5c325.7,113.7,518.1,144.3,918.1,144.3s592.4-30.6,918.1-144.3c922.5-325.7,1576.1-1060.2,1821-2050.5c43.7-181.4,45.9-233.9,52.5-2409c8.7-2457.1,13.1-2376.2-129-2649.5c-89.6-170.5-297.3-376-467.8-465.6c-209.9-111.5-360.7-137.7-797.9-137.7h-384.8l-80.9,76.5c-43.7,41.5-111.5,80.9-150.8,87.4c-39.3,6.6-415.3,10.9-839.4,6.6c-736.7-6.6-771.7-8.8-828.5-52.5c-139.9-102.7-150.8-137.7-150.8-467.8c0-330.1,10.9-365,150.8-467.8c56.8-43.7,91.8-45.9,828.5-52.4c424.1-4.4,800.1,0,839.4,6.5c39.3,6.6,104.9,45.9,148.6,85.3l78.7,74.3l485.3,10.9c413.2,8.8,509.3,17.5,649.3,56.8c762.9,223,1296.3,876.6,1348.8,1657l13.1,203.3h231.7c376,0,609.9,83.1,835,299.5c139.9,133.3,242.7,308.2,292.9,498.4c48.1,190.2,48.1,2061.4,0,2251.6c-107.1,408.8-448.1,719.2-865.7,784.8l-150.8,21.9v100.6c0,150.8-41.5,467.8-87.4,695.2C8523.9,3095,7767.5,4070,6711.6,4599C6062.4,4926.9,5284.2,5064.6,4562.8,4981.6z" />
            <path d="M2551.6-765.5v-1930.2l98.4,13.1c218.6,30.6,432.8,188,535.6,397.8l54.6,109.3v1410v1410l-54.6,109.3c-102.8,209.9-317,367.2-535.6,397.9l-98.4,13.1V-765.5z" />
            <path d="M7229.7,1121c-216.4-74.3-376-236.1-446-456.9c-32.8-94-35-264.5-30.6-1473.4l6.6-1366.3l54.7-109.3c102.7-209.9,317-367.2,535.6-397.8l98.4-13.1v1928.1v1925.9h-59C7354.4,1156,7284.4,1140.7,7229.7,1121z" />
          </g>
        </g>
      </SvgIcon>
    </MuiThemeProvider>
  );
};

export default Attending;
