import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import AuthService from './auth.service';
import config from '../config';
import { translateWithParams } from '../i18n';
import API, { createAxiosInstance } from '../services/api';

const API_WITHOUT_INTERCEPTOR = createAxiosInstance();

const HttpInterceptor = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [redirect] = React.useState(useHistory());
  let isRefreshing = false;
  const { t } = useTranslation();

  const isAnonymousRequest = request => {
    return (
      request.url === `${config.cognito.authUrl}/login` ||
      request.url === `${config.cognito.authUrl}/refresh-token` ||
      request.url === `${config.cognito.authUrl}/first-login` ||
      request.url ===
        `${config.cognito.authUrl}/backoffice-force-password-change`
    );
  };

  const onRefreshTokenError = () => {
    AuthService.cleanTokens();
    AuthService.logout(redirect);
    enqueueSnackbar(t('i18n.simplephrases.NOT_AUTHORIZED'), {
      variant: 'error'
    });
  };

  API.interceptors.request.use(
    async request => {
      const accessToken = AuthService.getAccessToken();

      if (accessToken && !isRefreshing) {
        const decodedAccessToken = jwt_decode(accessToken);

        const currentTime = moment();
        const tokenExpirationTime = moment(decodedAccessToken.exp * 1000);

        const isAboutToExpireOrIsExpired =
          tokenExpirationTime.diff(currentTime, 'minutes') <= 2;

        if (isAboutToExpireOrIsExpired) {
          try {
            isRefreshing = true;
            const refreshTokenPayload = {
              refreshToken: AuthService.getRefreshToken()
            };

            const { data } = await API_WITHOUT_INTERCEPTOR.post(
              `${config.cognito.authUrl}/refresh-token`,
              refreshTokenPayload
            );

            AuthService.setAccessTokens({ idToken: data.idToken });
            request.headers.Authorization = `${data.idToken}`;

            return request;
          } catch {
            onRefreshTokenError();
          }
          isRefreshing = false;
        }
      }
      request.headers.Authorization = accessToken;

      return request;
    },
    error => {
      return Promise.reject(error);
    }
  );

  API.interceptors.response.use(
    async response => {
      const { data } = response;
      if (!!data && !!data.message) {
        const message = translateWithParams(data);
        if (
          data?.message?.key === 'i18n.ticket.ML.CREATE_NEW_TICKET_SUCCESS' ||
          data?.message?.key === 'i18n.ticket.Magalu.NEWPROTOCOL_SUCCESS' ||
          data?.message?.key === 'i18n.ticket.B2W.CREATE_NEW_TICKET_SUCCESS' ||
          data?.message?.key === 'i18n.ticket.CNOVA_CREATE_PROTOCOL_SUCCESS'
        ) {
          enqueueSnackbar(message, {
            variant: 'success',
            persist: true
          });
        } else if (message !== '') {
          enqueueSnackbar(message, {
            variant: 'success'
          });
        }
      }

      return response;
    },
    async error => {
      const { data } = error;
      const originalRequest = error.config;
      const shouldRetry =
        error?.response?.status === 401 &&
        !originalRequest.isRetry &&
        !isAnonymousRequest(originalRequest);

      if (shouldRetry) {
        originalRequest.isRetry = true;
        try {
          const refreshTokenPayload = {
            refreshToken: AuthService.getRefreshToken()
          };

          const response = await API_WITHOUT_INTERCEPTOR.post(
            `${config.cognito.authUrl}/refresh-token`,
            refreshTokenPayload
          );

          AuthService.setAccessTokens({ idToken: response.data.idToken });
          originalRequest.headers.Authorization = `${response.data.idToken}`;

          return API(originalRequest);
        } catch {
          onRefreshTokenError();
        }
      }

      if (!!data && !!data.message) {
        const message = translateWithParams(data);
        enqueueSnackbar(message, {
          variant: 'error'
        });
      }
      return Promise.reject(error);
    }
  );

  return children;
};

export default HttpInterceptor;
