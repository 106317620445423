import React, { useEffect } from 'react';
import styled from 'styled-components';
import { searchB2bOnCurrentUrl } from '../../utils/b2bHelper';

const IframeWrapper = styled.div`
  width: 100%;
  height: 100%;
  zoom: 110%;
  overflow: hidden;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;

const BlockedIPsIframe = () => {
  const B2B_URL = `${
    process.env.REACT_APP_NEW_CONNECTA
  }/chat/clientes-bloqueados/b2b?refreshToken=${localStorage.getItem(
    'refreshToken'
  )}`;

  const B2C_URL = `${
    process.env.REACT_APP_NEW_CONNECTA
  }/chat/clientes-bloqueados?refreshToken=${localStorage.getItem(
    'refreshToken'
  )}`;

  useEffect(() => {
    const BASE_TITLE = `Clientes Bloqueados ${
      searchB2bOnCurrentUrl() ? 'B2B' : 'B2C'
    }`;
    document.title = BASE_TITLE;
  }, []);

  return (
    <IframeWrapper>
      <Iframe src={searchB2bOnCurrentUrl() ? B2B_URL : B2C_URL} />
    </IframeWrapper>
  );
};

export default BlockedIPsIframe;
