import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../styles/settings/colors.config';

const HeaderTitleContainer = styled.div`
  display: block;
  padding-left: ${props => `${props.paddingLeft}px` ?? 0};
  margin-right: ${props =>
    props.marginRight ? `${props.marginRight}px` : '40px'};

  h2 {
    color: ${COLORS.grayText};
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }
`;

/**
 * This component is responsible to
 * add the header title page.
 * @param {title} string Title text that will be showed in the page
 */
const HeaderTitle = ({ title, paddingLeft, marginRight }) => {
  return (
    <HeaderTitleContainer paddingLeft={paddingLeft} marginRight={marginRight}>
      <h2>{title}</h2>
    </HeaderTitleContainer>
  );
};

export default HeaderTitle;
