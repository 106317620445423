import {
  Typography,
  Grid,
  Button,
  CircularProgress,
  Card,
  Divider,
  CardContent
} from '@material-ui/core';

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ChipInput from './ChipInput';

import API from '../../../services/api';

const CustonGrid = styled(Grid)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 30px 0px;
`;

const SynonymsCRUD = () => {
  const { t } = useTranslation();
  const [addSynonyms, setAddSynonyms] = useState(false);
  const [synonyms, setSynonyms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [words, setWords] = useState([]);

  const handleRefreshSynonymsList = () => {
    setIsLoading(true);
    API.get(`/auth/ms-ia/api/v1/synonyms`)
      .then(response => {
        setSynonyms(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    setWords([]);
  };

  useEffect(() => {
    handleRefreshSynonymsList();
  }, []);

  return (
    <Grid container spacing={3}>
      <CustonGrid item xs={12}>
        {addSynonyms && (
          <Button
            color="default"
            variant="contained"
            size="small"
            startIcon={<HighlightOffIcon />}
            onClick={() => setAddSynonyms(false)}
            style={{ marginRight: '15px' }}
          >
            {t('i18n.sales_ad_analysis.cancel')}
          </Button>
        )}

        <Button
          color="primary"
          variant="contained"
          size="small"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => setAddSynonyms(true)}
        >
          {t('i18n.synonyms.add')}
        </Button>
      </CustonGrid>
      {addSynonyms && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                {t('i18n.synonyms.register')}
              </Typography>
            </CardContent>
            <Divider />
            <ChipInput
              words={words}
              handleRefreshSynonymsList={handleRefreshSynonymsList}
              showDelete="false"
              removeAddLine="true"
              setWords={setWords}
            />
          </Card>
        </Grid>
      )}
      <Grid item xs={12}>
        {isLoading && (
          <div
            style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <CircularProgress />
          </div>
        )}
        <Card style={{ opacity: isLoading ? '0.5' : '1' }}>
          <CardContent>
            <Typography variant="h6">{t('i18n.synonyms.list')}</Typography>
          </CardContent>
          <Divider />
          {synonyms
            .map(item => (
              <div key={item.synonym[0]}>
                <Grid item xs={12}>
                  <ChipInput
                    item={item}
                    words={item.synonym}
                    handleRefreshSynonymsList={handleRefreshSynonymsList}
                  />
                </Grid>
              </div>
            ))
            .reverse()}
        </Card>
      </Grid>
      {/* {synonyms.length > 0 && (
        <ListSynonyms>
          <Typography variant="h6">{t('i18n.synonyms.list')}</Typography>
          <br />
          {synonyms.map(item => (
            <div key={item.synonym[0]}>
              <Grid item xs={12}>
                <ChipInput
                  item={item}
                  words={item.synonym}
                  handleRefreshSynonymsList={handleRefreshSynonymsList}
                />
              </Grid>
            </div>
          ))}
        </ListSynonyms>
      )} */}
    </Grid>
  );
};

export default SynonymsCRUD;
