import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import Loader from '../../../components/Loader';
import API from '../../../services/api';
import Page from '../../../components/Page';
import ChatHistory from '../chat/history';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  title: {
    fontSize: '20px',
    fontWeight: '400'
  }
}));

// id equals hashConnection from ChatHistoryPage.js
const ChatContainer = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [chat, setChat] = useState(history.location.message);
  const [loading, setLoading] = useState(true);

  const handleGetChat = () => {
    setLoading(true);
    if (!history.location.message) {
      API.get(`/auth/ms-chat/chats/${id}`)
        .then(resp => {
          setChat(resp.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    handleGetChat();
  }, []);

  return (
    <Page className={classes.root} title="Ticket Container">
      {loading ? (
        <Loader />
      ) : (
        <Container maxWidth={false}>
          <Breadcrumbs
            gutterBottom
            aria-label="breadcrumb"
            className={classes.title}
          >
            <Link color="inherit" to="/chats">
              Chats
            </Link>
            <Link color="inherit" to="/historico-chats">
              {t('i18n.chatview.CHAT_HISTORY')}
            </Link>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.title}
            >
              {t('i18n.chatview.CHAT_DETAIL')}
            </Typography>
          </Breadcrumbs>
          <Box mt={3}>
            <Grid container spacing={1}>
              <ChatHistory {...{ chat, handleGetChat }} />
              <Grid item lg={9} md={9} xs={12} />
            </Grid>
          </Box>
        </Container>
      )}
    </Page>
  );
};

export default ChatContainer;
