import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AcerModal from '../components/AcerModal';

const ConfirmationModal = ({
  openState,
  message,
  closeParent,
  submitConfirmation,
  title = false
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(openState);
  const [messageState, setMessage] = useState(message);

  const handleClose = () => {
    closeParent();
    setOpen(false);
  };

  const internalSubmitConfirmation = () => {
    submitConfirmation();
  };

  useEffect(() => {
    setOpen(openState);
    setMessage(message);
  }, [openState]);

  return (
    <AcerModal
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleClose}
      modalTitle={title || messageState}
      hasAction
      cancelActionFunc={() => closeParent()}
      confirmActionLabel={t('i18n.simplewords.CONFIRM')}
      confirmActionFunc={() => internalSubmitConfirmation()}
      cancelActionLabel={t('i18n.simplewords.CANCEL')}
    >
      <Typography>{messageState}</Typography>
    </AcerModal>
  );
};

export default ConfirmationModal;
