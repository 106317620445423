import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  TextField,
  Tooltip
} from '@material-ui/core';
import { Add, HelpOutline } from '@material-ui/icons';
import { createStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { StyledSpinner } from '../../../../styles/components/SpinnerDefault';
import { CustomDataTableWrapper } from '../../../../styles/components/CustomDataTable';
import { COLORS } from '../../../../styles/settings/colors.config';
import AcerModal from '../../../../components/AcerModal';
import B2WMarketplaceSupportTable from './B2WMarketplaceSupportTable';
import B2WMarketplaceNewSupportModal from './B2WMarketplaceNewSupportModal';
import API from '../../../../services/api';

const Section = styled.div`
  background-color: ${props => (props.bg ? '#f3f3f3' : 'white')};
  padding: 10px 20px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

// const ItemHeader = styled.div`
//   font-size: 13px;
//   font-weight: bold;
//   color: #666666;
//   margin-bottom: 5px;
// `;

const ItemValue = styled.div`
  color: #3d85c6;
  font-size: 14px;
  font-weight: bold;
`;

const useStyles = makeStyles(() =>
  createStyles({
    inputSmall: {
      width: '200px',
      height: '30px',
      fontSize: '12px',
      fontWeight: 'bold',
      marginRight: '10px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        textAlign: 'left'
      }
    },
    smallButton: {
      margin: '1px',
      fontSize: '12px',
      display: 'inline-block',
      padding: '4px 10px',
      minHeight: 0,
      minWidth: 0
    }
  })
);

const filterCheckboxes = [
  {
    id: 'respondidos',
    label: 'Respondidos',
    valueSelected: 'AguardandoResposta'
  },
  {
    id: 'analise',
    label: 'Em Análise',
    valueSelected:
      'Novo,Em Atendimento N1,Em Atendimento N2,Em Atendimento N3,Reaberto'
  },
  {
    id: 'fechados',
    label: 'Fechados',
    valueSelected: 'Encerrado'
  },
  {
    id: 'cancelados',
    label: 'Cancelados',
    valueSelected: 'Cancelado'
  },
  {
    id: 'finalizados',
    label: 'Finalizados',
    valueSelected: 'Fechado'
  }
];

const columns = [
  {
    name: 'id',
    label: 'Nº Chamado',
    options: {
      sort: true
    }
  },
  {
    name: 'category',
    label: 'Categoria',
    options: {
      sort: true
    }
  },
  {
    name: 'subject',
    label: 'Assunto',
    options: {
      sort: true
    }
  },
  {
    name: 'creationDate',
    label: 'Data Abertura',
    options: {
      sort: true
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      sort: true
    }
  }
];

const BlueText = styled.span`
  color: ${COLORS.blueText};
  font-weight: bold;
`;

const BlueTextCount = ({ count }) => (
  <BlueText>{String(count).padStart(2, '0')}</BlueText>
);

export default function B2WMarketplaceSupportModal({ open, setOpen }) {
  const [chamado, setChamado] = useState('');
  const [filters, setFilters] = useState({
    respondidos: false,
    analise: false,
    fechados: false,
    cancelados: false,
    finalizados: false
  });
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage] = useState(20);
  const [reportData, setReportData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [sortData, setSortData] = useState({
    column: 'id',
    direction: 'none'
  });

  const classes = useStyles();

  const switchItemValue = value => {
    switch (value) {
      case 'Fechado':
        return 'Finalizado';
      case 'Encerrado':
        return 'Fechado';
      case 'Novo':
        return 'Em análise';
      case 'Em Atendimento N1':
        return 'Em análise';
      case 'Em Atendimento N2':
        return 'Em análise';
      case 'Em Atendimento N3':
        return 'Em análise';
      case 'Reaberto':
        return 'Em análise';
      case 'AguardandoResposta':
        return 'Chamado Respondido';
      default:
        return value;
    }
  };

  const fetchData = async (page = 0, search = {}) => {
    if (open) {
      const sortingParameters =
        sortData?.direction !== 'none'
          ? `&sort=${sortData?.column},${sortData?.direction}`
          : '';

      setLoading(true);
      const { data } = await API.post(
        `/auth/ms-ticket/v1/marketplace-support/find-filtered?page=${page}${sortingParameters}`,
        search
      );

      const newData = data.content.map(item => {
        return { ...item, status: switchItemValue(item.status) };
      });

      setRows(newData);
      setReportData(newData);
      setCount(data.totalElements);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) fetchData(0);
  }, [open]);

  const handleFilter = () => {
    const keys = Object.keys(filters);
    let searchTerm = '';
    keys.forEach(key => {
      if (filters[key]) {
        const findedFilter = filterCheckboxes.find(item => item.id === key);
        searchTerm = `${searchTerm + findedFilter.valueSelected},`;
      }
    });
    return {
      id: chamado,
      status: searchTerm.slice(0, searchTerm.length - 1)
    };
  };

  const handleSearch = (page = 0) => {
    setCurrentPage(page);
    const search = handleFilter();
    fetchData(page, search);
  };

  const sortTableData = tableState => {
    const column = tableState.sortOrder.name;
    const { direction } = tableState.sortOrder;
    const newSortData = { ...sortData };
    newSortData.column = column;
    newSortData.direction = direction;
    setSortData(newSortData);
  };

  useEffect(() => {
    handleSearch(0);
  }, [filters]);

  useEffect(() => {
    if (sortData?.direction !== 'none') {
      const search = handleFilter();
      fetchData(0, search);
    }
  }, [sortData]);

  const handleChangeFilter = e => {
    setFilters({ ...filters, [e.target.name]: !filters[e.target.name] });
  };

  const handleClose = () => {
    setFilters({
      respondidos: false,
      analise: false,
      fechados: false,
      cancelados: false,
      finalizados: false
    });
    setChamado('');
    setOpen(false);
  };

  return (
    <>
      <AcerModal
        fullWidth
        maxWidth="lg"
        onClose={() => handleClose()}
        open={open}
        modalTitle="Suporte Marketplace"
        freeBody
      >
        <Section bg>
          <Grid
            container
            style={{ display: 'flex-start', alignItems: 'center' }}
          >
            <Grid item xs={4}>
              {/* <ItemHeader>Qual o marketplace?</ItemHeader> */}
              <ItemValue>Americanas</ItemValue>
            </Grid>
            <Grid item xs={4}>
              {/* <ItemHeader>Buscar chamado:</ItemHeader> */}
              <TextField
                value={chamado}
                placeholder="Informe o nº do chamado"
                size="small"
                variant="outlined"
                onChange={e => setChamado(e.target.value)}
                InputProps={{
                  className: classes.inputSmall
                }}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.smallButton}
                onClick={() => handleSearch(0)}
              >
                Buscar
              </Button>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: 'flex',
                alignItems: 'bottom',
                justifyContent: 'flex-end'
              }}
            >
              <Button onClick={() => setOpenAddModal(true)} color="primary">
                <Add fontSize="small" className="header-btn--icon" />
                Abrir Chamado
              </Button>
            </Grid>
          </Grid>
        </Section>
        <Section>
          <Grid container>
            <Grid
              item
              xs={4}
              style={{ display: 'flex-start', alignItems: 'center' }}
            >
              <span>
                <BlueTextCount {...{ count }} /> resultados encontrados
              </span>
              <Tooltip
                title={`O tempo de resposta do suporte é de até 2 dias úteis a partir da data do chamado. 
              Por aqui, você consulta os seus chamados gerais mais recentes, abertos a partir de outubro de 2021.
              Para consultar chamados jurídicos, de sugestões e chamados gerais antigos, acesse Outros chamados.`}
              >
                <HelpOutline
                  style={{ color: COLORS.greenText, marginLeft: '10px' }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={8}>
              <Grid>
                <span>Filtrar por:</span>
              </Grid>
              <Grid>
                <FormGroup row>
                  {filterCheckboxes.map(filterItem => (
                    <FormControlLabel
                      key={Math.random()}
                      control={
                        <Checkbox
                          checked={filters[filterItem.id]}
                          onChange={handleChangeFilter}
                          name={filterItem.id}
                          color="primary"
                        />
                      }
                      label={filterItem.label}
                    />
                  ))}
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </Section>
        <Section bg>
          <CustomDataTableWrapper style={{ opacity: loading ? '0.3' : '1' }}>
            <B2WMarketplaceSupportTable
              {...{
                count,
                rows,
                columns,
                rowsPerPage,
                reportData,
                currentPage,
                handleSearch,
                loading,
                sortTableData,
                setCurrentPage,
                fetchData
              }}
            />

            <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
              <CircularProgress />
            </StyledSpinner>
          </CustomDataTableWrapper>
        </Section>
      </AcerModal>
      <B2WMarketplaceNewSupportModal {...{ openAddModal, setOpenAddModal }} />
    </>
  );
}
