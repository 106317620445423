import React from 'react';
import {
  Checkbox,
  Radio,
  RadioGroup,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const MessageRecipient = ({
  marketplace,
  setRecipientClient,
  setRecipientOperator,
  setAssignResponsible,
  setSendEmail
}) => {
  const { t } = useTranslation();

  if (['CARREFOUR', 'KABUM'].includes(marketplace)) {
    return (
      <FormControl component="fieldset">
        <FormLabel style={{ color: 'inherit' }}>
          {`${t('i18n.newAutomation.recipients')}:`}
        </FormLabel>
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value="client"
            control={<Checkbox color="primary" />}
            label="Cliente"
            labelPlacement="end"
            onChange={event => setRecipientClient(event.target.checked)}
          />
          <FormControlLabel
            value="operator"
            control={<Checkbox color="primary" />}
            label="Operador"
            labelPlacement="end"
            onChange={event => setRecipientOperator(event.target.checked)}
          />
        </FormGroup>
      </FormControl>
    );
  }
  if (['NETSHOES'].includes(marketplace)) {
    return (
      <FormControl component="fieldset">
        <FormLabel style={{ color: 'inherit' }} />
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value
            control={<Checkbox color="primary" />}
            label={t('i18n.ticketcontainer.NETSHOES_ASSIGN_RESPONSABLE')}
            labelPlacement="end"
            onChange={event => setAssignResponsible(event.target.checked)}
          />
          <FormControlLabel
            value="email"
            control={<Checkbox color="primary" defaultChecked />}
            label={t('i18n.ticketcontainer.NETSHOES_SEND_EMAIL')}
            labelPlacement="end"
            onChange={event => setSendEmail(event.target.checked)}
          />
        </FormGroup>
      </FormControl>
    );
  }

  if (['COLOMBO'].includes(marketplace)) {
    return (
      <FormControl component="fieldset">
        <FormLabel style={{ color: 'inherit' }}>
          {`${t('i18n.newAutomation.recipients')}:`}
        </FormLabel>
        <RadioGroup aria-label="position" row>
          <FormControlLabel
            value="client"
            control={<Radio color="primary" />}
            label="Cliente/Colombo"
            labelPlacement="end"
            onChange={event => {
              setRecipientClient(event.target.checked);
              setRecipientOperator(false);
            }}
          />
          <FormControlLabel
            value="operator"
            control={<Radio color="primary" />}
            label="Colombo"
            labelPlacement="end"
            onChange={event => {
              setRecipientOperator(event.target.checked);
              setRecipientClient(false);
            }}
          />
        </RadioGroup>
      </FormControl>
    );
  }
  return <></>;
};

export default MessageRecipient;
