/* eslint-disable no-underscore-dangle */
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Loader from '../../../components/Loader';
import Page from '../../../components/Page';
import API from '../../../services/api';
import { CustomBreadCrumbs } from '../../../styles/components/CustomDataTable';
import { StyledSubmitActions } from '../../../styles/components/StyledSubmitActions.styled';

// import ActionModal from './ActionModal';

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  textEditWidth: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  tableScrollBody: {
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  tableScrollContainer: {
    height: '200px'
  },
  breadcrumbs: {
    marginTop: '15px',
    marginLeft: '24px'
  }
}));

const AutomationView = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const knowledgeId = useParams().id;
  const isCreateCase = () => knowledgeId === 'new';
  const [title, setTitle] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(true);

  const postData = data => {
    API.post(`/auth/lambda/knowledge/category`, data)
      .then(() => {
        history.push('/knowledge-base-categories');
        enqueueSnackbar(t('i18n.knowledge.SAVE_EDIT_SECTION_SUCCESS'), {
          variant: 'success'
        });
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.knowledge.SAVE_SECTION_ERROR'), {
          variant: 'error'
        });
      });
  };

  const putData = data => {
    API.put(`/auth/lambda/knowledge/category`, data)
      .then(() => {
        history.push('/knowledge-base-categories');
        enqueueSnackbar(t('i18n.knowledge.SAVE_EDIT_SECTION_SUCCESS'), {
          variant: 'success'
        });
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.knowledge.SAVE_SECTION_ERROR'), {
          variant: 'error'
        });
      });
  };

  const submitData = () => {
    const data = {
      title,
      description,
      business_type: businessType
    };

    if (knowledgeId !== 'new') {
      data._id = knowledgeId;
      putData(data);
    } else {
      postData(data);
    }
  };

  useEffect(() => {
    if (!isCreateCase()) {
      API.get(`/auth/lambda/knowledge/category`, {
        params: { _id: knowledgeId }
      })
        .then(response => {
          setTitle(response.data[0].title);
          setDescription(response.data[0].description);
          setBusinessType(response.data[0].business_type);
          setLoading(false);
        })
        .catch(() => {
          // setFetchError(true);
        });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <Page className={classes.root} title={t('i18n.knowledge.FAQ_SECTIONS')}>
      {loading ? (
        <Loader />
      ) : (
        <Container maxWidth={false}>
          <CustomBreadCrumbs
            className={classes.breadcrumbs}
            gutterBottom
            aria-label="breadcrumb"
          >
            <Typography variant="h6">
              <Link color="inherit" to="/knowledge-base-categories">
                {t('i18n.knowledge.FAQ_SECTIONS')}
              </Link>
            </Typography>
            {isCreateCase() ? (
              <Typography variant="h6" color="textPrimary">
                {t('i18n.knowledge.NEW_SECTION')}
              </Typography>
            ) : (
              <Typography variant="h6" color="textPrimary">
                {title}
              </Typography>
            )}
          </CustomBreadCrumbs>
          {/* {isCreateCase() ? (
            <Typography variant="h2" gutterBottom>
              {t('i18n.knowledge.NEW_SECTION')}
            </Typography>
          ) : (
            <Typography variant="h2" gutterBottom>
              {t('i18n.knowledge.EDIT_SECTION')} - {title}
            </Typography>
          )} */}
          <Box mt={2}>
            <Card>
              <CardContent>
                <Grid container spacing={5}>
                  <Grid item xs={9}>
                    <Box mb={2}>
                      <TextField
                        value={title}
                        onChange={ev => {
                          setTitle(ev.target.value);
                        }}
                        fullWidth
                        label={t('i18n.knowledge.TITLE')}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box mb={2}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel
                          id="demo-simple-select-outlined_type"
                          required
                        >
                          {t('i18n.ticketcontainer.TYPE')}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined_type"
                          id="demo-simple-select-outlined_type"
                          value={businessType}
                          onChange={e => {
                            setBusinessType(e.target.value);
                          }}
                          label={t('i18n.ticketcontainer.TYPE')}
                          required
                        >
                          <MenuItem value="">Selecione</MenuItem>
                          <MenuItem value="B2B">B2B</MenuItem>
                          <MenuItem value="B2C">B2C</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <TextField
                        value={description}
                        onChange={ev => {
                          setDescription(ev.target.value);
                        }}
                        fullWidth
                        className={classes.textEditWidth}
                        label={t('i18n.knowledge.DESCRIPTION')}
                        multiline
                        minRows={4}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <StyledSubmitActions>
                  <Tooltip title="Cancelar">
                    <Button
                      onClick={() => {
                        history.push('/knowledge-base-categories');
                      }}
                      variant="contained"
                      color="default"
                    >
                      {t('i18n.knowledge.CANCEL')}
                    </Button>
                  </Tooltip>
                  <Tooltip title={t('i18n.newAutomation.SAVE')}>
                    <Button
                      onClick={submitData}
                      variant="contained"
                      color="primary"
                    >
                      {t('i18n.newAutomation.SAVE')}
                    </Button>
                  </Tooltip>
                </StyledSubmitActions>
              </CardContent>
            </Card>
          </Box>
        </Container>
      )}
    </Page>
  );
};

export default AutomationView;
