import { Box, Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { numberToBRL } from '../../../../../helpers/utils';
import { CardDataDivider } from '../../../styles';
import CardCustomTitle from '../components/CardCustomTitle';

const QuantityValue = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  color: #666666;
`;

const InfoLabel = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
`;

const CurrencyValue = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 47px;
  color: #434343;
`;

const InfoBox = ({ children }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
  >
    {children}
  </Box>
);

const QuantityInfo = ({ quantity }) => (
  <InfoBox>
    <QuantityValue component="span">
      {String(quantity).padStart(2, '0')}
    </QuantityValue>
    <InfoLabel component="span">Quantidade</InfoLabel>
  </InfoBox>
);

const CurrencyInfo = ({ value }) => (
  <InfoBox>
    <CurrencyValue component="span">{numberToBRL(value)}</CurrencyValue>
    <InfoLabel component="span">Valor</InfoLabel>
  </InfoBox>
);

const SalesInfoCardCNOVA = ({ item }) => {
  const { title, quantity, value } = item;

  return (
    <Card style={{ width: '100%', marginBottom: '20px', borderRadius: '10px' }}>
      <CardCustomTitle {...{ title }} />
      <CardContent>
        <Box display="flex" justifyContent="center">
          <QuantityInfo {...{ quantity }} />
          <CardDataDivider dividerMargin="20px" />
          <CurrencyInfo {...{ value }} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default SalesInfoCardCNOVA;
