import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import FilterAccordion from './filterAccordion.component';
import { convertDateToFilter } from '../services/convertDate';
import indexHook from '../indexHook';
import API from '../../../services/api';
import { filterNameInStorage } from './config';
import FilterForm from './filterForm.component';

const FilterDashboard = ({
  open,
  setOpen,
  dashboardFilter,
  setDashboardFilter
}) => {
  const reduxDispatcher = useDispatch();
  const { enqueueSnackbar, t } = indexHook();

  const [filter, setFilter] = useState(dashboardFilter);
  const [submit, setSubmit] = useState(false);
  const [dataEquipDash, setDataEquipDash] = useState('');

  const handleGetDashboard = async currentFilter => {
    reduxDispatcher({
      type: 'SET_MARKETPLACE_SCORE_DASHBOARD_LOADING',
      loading: true
    });
    const { marketplace, startDate } = currentFilter;
    const data = { marketplace, date: startDate || undefined };

    await API.post(`auth/lambda/dash-score-mkt-bi/filtered-score`, data)
      .then(response => {
        setDataEquipDash(response.data);
      })
      .catch(() => {
        reduxDispatcher({
          type: 'SET_MARKETPLACE_SCORE_DASHBOARD_TEAM_DATA',
          teamData: {}
        });
        enqueueSnackbar(t('i18n.dashboard.components.no_data'), {
          variant: 'error'
        });
      })
      .finally(() => {
        reduxDispatcher({
          type: 'SET_MARKETPLACE_SCORE_DASHBOARD_LOADING',
          loading: false
        });
      });
  };

  useEffect(() => {
    if (submit && filter) {
      const { startDate } = convertDateToFilter(filter);
      filter.startDate = startDate;

      if (filter?.periodType === 'realTime') {
        delete filter.startDate;
      }

      reduxDispatcher({
        type: 'SET_MARKETPLACE_SCORE_DASHBOARD_FILTER',
        filter
      });
      setDashboardFilter(filter);
    }
  }, [filter, submit]);

  useEffect(() => {
    if (dataEquipDash !== '') {
      reduxDispatcher({
        type: 'SET_MARKETPLACE_SCORE_DASHBOARD_TEAM_DATA',
        teamData: dataEquipDash
      });
    }
  }, [dataEquipDash]);

  useEffect(() => {
    sessionStorage.setItem(
      filterNameInStorage,
      JSON.stringify(dashboardFilter)
    );
    handleGetDashboard(dashboardFilter);
  }, [dashboardFilter]);

  return (
    <>
      <FilterAccordion
        open={open}
        setFilter={setFilter}
        setSubmit={setSubmit}
        filter={dashboardFilter}
        // dashboardFilter={dashboardFilter}
        filterName="newfilter"
        // defaultFilter={defaultFilter}
      />
      {open && (
        <FilterForm
          setFilter={setFilter}
          setSubmit={setSubmit}
          setOpen={setOpen}
          filter={filter}
          filterName="newfilter"
        />
      )}
    </>
  );
};

export default FilterDashboard;
