import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const reviewEnum = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' }
];

const FilterReview = ({ filterForm, setFilterForm }) => {
  const { t } = useTranslation();

  return (
    <Box mb={2}>
      <Grid container>
        <Grid item xs={7}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel>{t('i18n.preSale.REVIEW')}</InputLabel>
            <Select
              defaultValue=""
              value={filterForm.rating || ''}
              className="small-input"
              onChange={htmlElemtEvent => {
                setFilterForm({
                  ...filterForm,
                  rating: htmlElemtEvent.target.value
                });
              }}
              label={t('i18n.ticketslistview.STATUS')}
            >
              <MenuItem value="">
                <em>{t('i18n.ticketslistview.NONE')}</em>
              </MenuItem>
              {reviewEnum.map(review => {
                return (
                  <MenuItem value={review.value} key={review.value}>
                    {review.label} {t('i18n.ticketslistview.STAR')}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};
export default FilterReview;
