import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  Breadcrumbs,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';
import config from '../../../config';
import userService from '../../../services/user.service';
import { StyledSpinner } from '../../../styles/components/SpinnerDefault';
import FetchContentError from '../../../components/FetchContentError';
import Page from '../../../components/Page';
import API from '../../../services/api';
import {
  CustomDataTableWrapper,
  CustomDataTable,
  CustomTableButton
} from '../../../styles/components/CustomDataTable';
import CustomFooter from '../../../styles/components/CustomFooter';

const useStyles = makeStyles(() => ({
  breadcrumbs: {
    marginTop: '15px'
  }
}));

const GroupsListView = ({ customToolbarSelect }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [hasFetchError, setFetchError] = useState(false);
  const companyFromToken = userService.getCompanyName();
  const [data, setData] = useState([]);
  const [groupList, setGroupList] = useState([]);

  const { t } = useTranslation();

  const columns = [
    {
      name: 'name',
      label: t('i18n.grouplistview.NAME'),
      options: {
        filter: false,
        sort: true
      }
    }
  ];

  const findRow = (id, rowData) => {
    const len = rowData.length;
    for (let i = 0; i < len; i++) {
      if (rowData[i].name === id) {
        return rowData[i];
      }
    }
  };

  function compare(a, b) {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }

  const loadTableContent = () => {
    setLoading(true);
    API.get(`/auth/ms-users/groups`)
      .then(response => {
        const groups = response.data;
        setData(groups.sort(compare));
        setLoading(false);
        setFetchError(false);
      })
      .catch(() => {
        setFetchError(true);
      });
  };

  const deleteGroups = groupsToDelete => {
    API.post(`/auth/ms-users/groups/removeGroup`, groupsToDelete)
      .then(() => {})
      .catch(() => {
        enqueueSnackbar(t('i18n.grouplistview.DELETE_GROUP_ERROR'), {
          variant: 'error'
        });
      })
      .finally(() => {
        loadTableContent();
      });
  };

  const checkGroupsBeforeDelete = selectedGroups => {
    const reservedGroups = [];
    const groupsToDelete = [];
    selectedGroups.forEach(group => {
      if (['atendente', 'admin'].includes(group)) {
        reservedGroups.push(group);
      } else {
        groupsToDelete.push({ userGroup: group });
      }
    });
    if (reservedGroups.length > 0) {
      enqueueSnackbar(
        `${t('i18n.grouplistview.RESERVED_GROUPS')}: ${reservedGroups.join(
          ' e '
        )}`,
        {
          variant: 'warning'
        }
      );
      loadTableContent();
    } else {
      deleteGroups(groupsToDelete);
    }
  };

  useEffect(() => {
    loadTableContent();
  }, []);

  useEffect(() => {
    if (data) {
      if (companyFromToken === config.cognito.ownerCompany) {
        setGroupList(data.filter(el => !el.name.includes(':')));
      } else {
        setGroupList(
          data.filter(el => el.name.includes(`${companyFromToken}:`))
        );
      }
    }
  }, [data]);

  if (hasFetchError) {
    return <FetchContentError fetch={loadTableContent} />;
  }

  const options = {
    filterType: 'checkbox',
    viewColumns: false,
    filter: false,
    onRowClick: rowData => {
      const row = findRow(rowData[0], data);
      history.push({
        pathname: `groups/${String(rowData[0])}`,
        state: { data: row }
      });
    },
    onRowsDelete: deleteRow => {
      const keys = Object.keys(deleteRow.lookup).map(s => {
        return parseInt(s, 10);
      });
      const groupsToDelete = keys.map(k => {
        return data[k].name;
      });
      checkGroupsBeforeDelete(groupsToDelete);
    },
    customToolbarSelect,
    textLabels: {
      body: {
        noMatch: !loading
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: column =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    },
    customToolbar: () => {
      return (
        <>
          <IconButton disabled>|</IconButton>
          <CustomTableButton
            variant="text"
            color="primary"
            size="small"
            onClick={() => {
              history.push('/users');
            }}
          >
            {t('i18n.grouplistview.USERS')}
          </CustomTableButton>
          <IconButton disabled>|</IconButton>
          <CustomTableButton
            variant="text"
            color="primary"
            size="small"
            onClick={() => {
              history.push({
                pathname: '/groups/create',
                state: { groups: data }
              });
            }}
            startIcon={<AddIcon />}
          >
            {t('i18n.groupcontainer.CREATE_GROUP')}
          </CustomTableButton>
        </>
      );
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
        />
      );
    }
  };

  return (
    <>
      <Page title={t('i18n.grouplistview.GROUPS')}>
        <Container maxWidth={false}>
          <Box mt={3}>
            <CustomDataTableWrapper style={{ opacity: loading ? '0.3' : '1' }}>
              <CustomDataTable
                title={
                  <Breadcrumbs
                    className={classes.breadcrumbs}
                    gutterBottom
                    aria-label="breadcrumb"
                  >
                    <Typography variant="h2">
                      <Link
                        color="inherit"
                        style={{ textDecoration: 'none' }}
                        to="/users"
                      >
                        {t('i18n.userlistview.USERS')}
                      </Link>
                    </Typography>
                    <Typography variant="h2" color="textPrimary">
                      {t('i18n.grouplistview.GROUPS')}
                    </Typography>
                  </Breadcrumbs>
                }
                data={groupList}
                columns={columns}
                options={options}
                selectableRowsOnClick
                className="table-clickable"
              />
            </CustomDataTableWrapper>
            <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
              <CircularProgress />
            </StyledSpinner>
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default GroupsListView;
