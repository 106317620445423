import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import { COLORS } from '../../../../styles/settings/colors.config';

const ShortcutIndicator = ({ currentShortcutShow, handleDeleteShortcut }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
      }}
    >
      <Typography variant="body1">
        {t('i18n.ticketslistview.MORE_FILTER_OPTIONS')}
      </Typography>
      {currentShortcutShow?.name?.length > 0 && (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Typography
            variant="body1"
            style={{ color: COLORS.grayText, fontWeight: 'bold' }}
          >
            Atalho aplicado:
          </Typography>
          <Typography variant="body1" style={{ color: COLORS.orange }}>
            {currentShortcutShow?.name}
          </Typography>
          <Tooltip title="Excluir atalho">
            <DeleteIcon
              style={{
                color: '#888',
                fontSize: '20px',
                cursor: 'pointer'
              }}
              onClick={() => handleDeleteShortcut(currentShortcutShow)}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default ShortcutIndicator;
