// Manter em ordem alfabética da descrição
export default {
  CUSTOMER_GUIDE: 'CUSTOMER_GUIDE', // ACER STORE
  B2W: 'B2W', // AMERICANAS
  CARREFOUR: 'CARREFOUR',
  CNOVA: 'CNOVA',
  COLOMBO: 'COLOMBO',
  KABUM: 'KABUM',
  MAGALU: 'MAGALU',
  MERCADO_LIVRE: 'MERCADO_LIVRE',
  NETSHOES: 'NETSHOES',
  VIVO: 'VIVO'
};
