import { Typography, Button } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import AcerModal from '../../../../../components/AcerModal';

import { COLORS } from '../../../../../styles/settings/colors.config';
import API from '../../../../../services/api';

const CustomBody = styled('div')`
  display: flex;
  justify-content: flex-start;
  padding: 0px 60px 0px;
  gap: 15px;
  margin: 20px 0px 0px;
`;

const AlertIcon = styled('div')`
  max-width: 60px;
  max-height: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLORS.btnWarningColor};
  color: ${COLORS.btnWarningColor};
  margin-right: 15px;
  font-size: 60px;
  width: 40%;
`;

const CustomTypography = styled(Typography)`
  color: ${COLORS.grayText};
`;

const CloseContent = ({
  setOpenCloseTicketModal,
  selectedSupport,
  setOpenDetailsModal,
  fetchData
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [disabledButton, setDisabledButton] = useState(false);

  const { id } = selectedSupport;
  const HandleCloseTicket = () => {
    const data = { ticketId: id, action: 'Encerrado' };
    setDisabledButton(true);

    try {
      API.post(
        '/auth/ms-ticket/v1/marketplace-support/b2w/close-ticket',
        data
      ).then(() => {
        setDisabledButton(false);
        setOpenDetailsModal(false);
        setOpenCloseTicketModal(false);
        fetchData();
      });
    } catch (e) {
      enqueueSnackbar(t('i18n.B2W.SUPPORT.CLOSE_TICKET_ERROR'), {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <CustomBody style={{ background: COLORS.gray }}>
        <div style={{ padding: '20px 0px', display: 'flex' }}>
          <AlertIcon>!</AlertIcon>
          <CustomTypography>
            Ao escolher esta opção, <b>o seu chamado de nº</b>{' '}
            <span style={{ color: COLORS.blueText }}>
              {` `} {`${id}`}{' '}
            </span>
            {` `}
            <b>
              <span style={{ color: COLORS.btnWarningColor }}>
                será fechado!
              </span>
            </b>
          </CustomTypography>
        </div>
      </CustomBody>
      <CustomBody>
        <CustomTypography variant="h4">O que deseja fazer?</CustomTypography>
      </CustomBody>
      <CustomBody>
        <div
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            width: '100%',
            marginBottom: '20px'
          }}
        >
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => setOpenCloseTicketModal(false)}
          >
            Voltar
          </Button>
          <Button
            onClick={() => HandleCloseTicket()}
            color="primary"
            variant="contained"
            size="small"
            disabled={disabledButton}
          >
            Fechar chamado
          </Button>
        </div>
      </CustomBody>
    </>
  );
};

export default function CloseTicketModal({
  openCloseTicketModal,
  setOpenCloseTicketModal,
  selectedSupport,
  setOpenDetailsModal,
  fetchData
}) {
  return (
    <AcerModal
      fullWidth
      maxWidth="sm"
      onClose={() => setOpenCloseTicketModal(false)}
      open={openCloseTicketModal}
      modalTitle="Fechar um chamado"
      freeBody
    >
      <CloseContent
        {...{
          openCloseTicketModal,
          setOpenCloseTicketModal,
          selectedSupport,
          setOpenDetailsModal,
          fetchData
        }}
      />
    </AcerModal>
  );
}
