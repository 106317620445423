import { Chip } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import TabTitleWithIndicator from './components/TabTitleWithIndicator.component';
import marketPlacesEnum from '../../../enums/marketPlaces.enum';
import { useMarketplaceList } from '../../../hooks/hooks';
import API from '../../../services/api';
import { blockMlTicket } from '../TicketContainer/TicketService/MLblockTicket';

const useMessagesHooks = (chat, updateChatState, tabValue) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [chatState, setChat] = useState(chat);
  const [resposta, setResposta] = useState('');
  const [messages, setMessages] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);

  const [mlClaimsFilesToUpload, setMlClaimsFilesToUpload] = useState([]);
  const [mlClaims, setMlClaims] = useState([]);
  const [mlClaimResposta, setMlClaimResposta] = useState('');
  const [mlClaimRespostaLoading, setMlClaimRespostaLoading] = useState(false);
  const [b2wMediation, setB2wMediation] = useState([]);
  const [b2wHelp, setB2wHelp] = useState([]);
  const [b2wHelpAnswer, setB2wHelpAnswer] = useState('');
  const [b2wSelectedClassification, setB2wSelectedClassification] = useState(
    {}
  );
  const [b2wChatClassification, setB2wChatClassification] = useState({});

  const [internalMessages, setInternalMessages] = useState([]);
  const [internalMessagesResposta, setInternalMessagesResposta] = useState('');

  const [privateMessagesMagalu, setPrivateMessagesMagalu] = useState([]);
  const [privateMessageAnswerMagalu, setPrivateMessageAnswerMagalu] = useState(
    ''
  );
  const [
    privateMessageMagaluLoading,
    setPrivateMessageMagaluLoading
  ] = useState(false);

  const [internalMessageLoading, setInternalMessageLoading] = useState(false);
  const [
    normalMessageRespostaLoading,
    setNormalMessageRespostaLoading
  ] = useState(false);

  const [botMessage, setBotMessage] = useState(chat.ticketAssistantMessages);

  // Mudar para 'true' para exibir as respostas automáticas do robô na seção de Mensagens no Detalhe do Protocolo
  const [showBotMessage, setShowBotMessage] = useState('false');

  const [recipientClient, setRecipientClient] = useState(false);
  const [recipientOperator, setRecipientOperator] = useState(false);
  const [assignResponsible, setAssignResponsible] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const [firstMediatorResponseDate, setFirstMediatorResponseDate] = useState(
    ''
  );

  const { mlTicketblocked } = blockMlTicket(chat);
  const marketplaces100PercentMediated = useMarketplaceList().filter(
    el => el.fullMediated === true
  );

  // CARREFOUR e KABUM id personalizado por cliente para uso no envio da mensagem (destinatário)
  const getCustomerIdMirakl = () => {
    const customerId = chatState?.marketplaceBundle?.authorized_participants?.filter(
      item => item.type === 'CUSTOMER'
    )[0]?.id;
    return customerId;
  };

  const isMlClaimReturnType = () => {
    return (
      chatState?.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity?.type ===
      'returns'
    );
  };

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const convertFileToBase64String = async element => {
    const result = await toBase64(element);
    const resultedFileBase64 = result.split('base64,');
    return resultedFileBase64[1];
  };

  const onDrop = acceptedFiles => {
    const newFilesState = [...filesToUpload];
    if (chatState?.marketPlace === marketPlacesEnum.B2W) {
      if (newFilesState.length + acceptedFiles.length > 1) {
        enqueueSnackbar(t('i18n.simplephrases.MAX_ONE_FILE'), {
          variant: 'error'
        });
        return;
      }
    }
    if (newFilesState.length + acceptedFiles.length > 4) {
      enqueueSnackbar(t('i18n.simplephrases.MAX_FOUR_FILE'), {
        variant: 'error'
      });
      return;
    }
    acceptedFiles.forEach(async element => {
      if (element.size > 2000000) {
        enqueueSnackbar(
          `A imagem '${element.path}' não pode ser enviada pois ela tem mais de 2MB`,
          {
            variant: 'error'
          }
        );
        return;
      }
      if (
        tabValue === 'tabInternalMessages' ||
        (chatState?.marketPlace !== marketPlacesEnum.B2W &&
          chatState?.marketPlace !== marketPlacesEnum.CARREFOUR &&
          chatState?.marketPlace !== marketPlacesEnum.KABUM)
      ) {
        const reader = new FileReader();
        const fileByteArray = [];
        reader.readAsArrayBuffer(element);
        reader.onloadend = evt => {
          if (evt.target.readyState === FileReader.DONE) {
            const arrayBuffer = evt.target.result;
            const array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < array.length; i++) {
              fileByteArray.push(array[i]);
            }
          }
        };
        newFilesState.push({
          path: element.path || element.name,
          binary: fileByteArray,
          type: element.type
        });
        setFilesToUpload(newFilesState);
      } else if (chatState?.marketPlace === marketPlacesEnum.B2W) {
        const attachmentObject = {
          path: element.path || element.name,
          name: element.path || element.name,
          key: element.name,
          content: await convertFileToBase64String(element)
        };
        setFilesToUpload([attachmentObject]);
      } else if (
        [marketPlacesEnum.CARREFOUR, marketPlacesEnum.KABUM].includes(
          chatState?.marketPlace
        )
      ) {
        newFilesState.push({
          path: element.path || element.name,
          name: element.path || element.name,
          key: element.name,
          content: await convertFileToBase64String(element)
        });
        setFilesToUpload(newFilesState);
      }
    });
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    maxFiles: 4
  });

  const files = filesToUpload.map((file, index) => (
    <Chip
      key={Math.random()}
      style={{ margin: '2px' }}
      label={file.path}
      onDelete={() => {
        const newFilesState = [...filesToUpload];
        newFilesState.splice(index, 1);
        setFilesToUpload(newFilesState);
      }}
    />
  ));

  const parseB2wToDefaultMessageObject = b2wMessages => {
    const newMessagesObj = [];
    if (b2wMessages) {
      b2wMessages.forEach(el => {
        newMessagesObj.push({
          agent: el.origin.type,
          attachmentUrl: el?.attachment?.url || null,
          attachmentName: el?.attachment?.filename || null,
          requiresAuthorization: false,
          date: el.creationDate,
          message: el.content,
          username: el.origin.name
        });
      });
    }
    return newMessagesObj;
  };

  const upDateChatByTicketData = ticketData => {
    setMessages(
      ticketData?.marketplaceGenericMessages ||
        ticketData?.marketplaceBundle?.genericMessages ||
        messages
    );
    setMlClaims(
      ticketData?.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity
        ?.messages || mlClaims
    );
    setInternalMessages(ticketData?.aboInternalMessages || internalMessages);
    setB2wMediation(
      parseB2wToDefaultMessageObject(
        ticketData?.marketplaceBundle?.dataMessagesMediadas?.messages
      )
    );
    setB2wHelp(
      parseB2wToDefaultMessageObject(
        ticketData?.marketplaceBundle?.dataMessagesAjudaB2W?.messages
      )
    );
    setB2wChatClassification(ticketData?.marketplaceBundle?.chatClassification);
    setPrivateMessagesMagalu(
      chat?.marketplaceBundle?.dataMessageMediatedMagalu || []
    );
    setChat(ticketData);
    updateChatState(ticketData);
  };

  const updateChat = () => {
    API.get(`/auth/ms-ticket/v1/tickets/${chat.id}`).then(response => {
      const chatAux = response?.data;
      upDateChatByTicketData(chatAux);
    });
  };

  // Incluir atualização do whatsapp aqui
  // Useeffect para cada atualização do websocket

  const checkIfResponseIsEmpty = response => {
    if (!response.trim()) {
      enqueueSnackbar(t('i18n.ticketslistview.INSERT_ANSWER'), {
        variant: 'warning'
      });
      return true;
    }
    return false;
  };

  const isMlClaimTicket = () => {
    return chat?.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity?.claim_id;
  };

  const getSendResponseUrl = () => {
    if (
      chatState?.marketPlace === marketPlacesEnum.B2W &&
      filesToUpload.length > 0
    ) {
      return `/auth/ms-ticket/v1/tickets/b2w/send-attachment`;
    }
    if (
      chatState?.marketPlace === marketPlacesEnum.B2W &&
      filesToUpload.length === 0
    ) {
      return `/auth/ms-ticket/v1/tickets/b2w/send-message`;
    }
    if (chatState?.marketPlace === marketPlacesEnum.MAGALU) {
      return `/auth/ms-ticket/v1/tickets/magalu/send-message`;
    }
    if (chatState?.marketPlace === marketPlacesEnum.COLOMBO) {
      return `/auth/ms-ticket/v1/tickets/colombo/send-message`;
    }
    if (chatState?.marketPlace === marketPlacesEnum.VIVO) {
      // return vivo messaging endpoint
      return `/auth/ms-ticket/v1/tickets/neoassist/VIVO/send-message`;
    }
    if (chatState?.marketPlace === marketPlacesEnum.NETSHOES) {
      return `/auth/ms-ticket/v1/tickets/netshoes/send-message`;
    }
    if (
      [marketPlacesEnum.CARREFOUR, marketPlacesEnum.KABUM].includes(
        chatState?.marketPlace
      )
    ) {
      return `/auth/ms-ticket/v1/tickets/mirakl/reply-thread`;
    }
    if (chatState?.marketPlace === 'MERCADO_LIVRE') {
      return `/auth/ms-ticket/v1/tickets/mercadolivre/after-sale/send-answer`;
    }
    return `/auth/ms-ticket/v1/tickets/send-answer`;
  };

  const payloadMessageB2w = resp => {
    const messageTo =
      tabValue === 'B2wHelpMessages' ? 'B2W_SELLER' : 'CUSTOMER_SELLER';
    if (filesToUpload.length > 0) {
      if (Object.entries(b2wSelectedClassification).length > 0) {
        return {
          ticketId: chatState.id,
          // eslint-disable-next-line camelcase
          message_to: messageTo,
          delivery_id: chatState.marketplaceBundle.delivery_id,
          order_id: chatState.marketplaceBundle.order_id,
          message: resp,
          // eslint-disable-next-line camelcase
          message_type: 'TEXT',
          subject: b2wSelectedClassification.subject,
          attachment: filesToUpload[0]
        };
      }
      return {
        ticketId: chatState.id,
        // eslint-disable-next-line camelcase
        message_to: messageTo,
        delivery_id: chatState.marketplaceBundle.delivery_id,
        order_id: chatState.marketplaceBundle.order_id,
        attachment: filesToUpload[0]
      };
    }
    if (Object.entries(b2wSelectedClassification).length > 0) {
      return {
        ticketId: chatState.id,
        // eslint-disable-next-line camelcase
        message_to: messageTo,
        delivery_id: chatState.marketplaceBundle.delivery_id,
        message: resp,
        // eslint-disable-next-line camelcase
        message_type: 'TEXT',
        subject: b2wSelectedClassification.subject
      };
    }
    return {
      ticketId: chatState.id,
      // eslint-disable-next-line camelcase
      message_to: messageTo,
      delivery_id: chatState.marketplaceBundle.delivery_id,
      message: resp,
      // eslint-disable-next-line camelcase
      message_type: 'TEXT'
    };
  };

  // CARREFOUR e KABUM
  // Retorna o conteúdo do campo "to" usado no Payload na mensagem para identificar o destinatário
  const mountRecipentObjectMirakl = () => {
    const recipientObject = [];
    if (recipientClient && !recipientOperator) {
      recipientObject.push({
        type: 'CUSTOMER',
        id: getCustomerIdMirakl() || null
      });
    }
    if (recipientOperator && !recipientClient) {
      recipientObject.push({ type: 'OPERATOR' });
    }
    if (recipientClient && recipientOperator) {
      recipientObject.push(
        { type: 'CUSTOMER', id: getCustomerIdMirakl() || null },
        { type: 'OPERATOR' }
      );
    }
    return recipientObject;
  };

  // COLOMBO
  // Retorna o conteúdo do campo "to" usado no Payload na mensagem para identificar o destinatário
  const mountRecipentObjectColombo = () => {
    let recipientObject = '';
    if (recipientClient && !recipientOperator) {
      recipientObject = 'CUSTOMER';
    }
    if (recipientOperator && !recipientClient) {
      recipientObject = 'COLOMBO';
    }
    return recipientObject;
  };

  const getSendResponseMessagePayload = resp => {
    if (chatState?.marketPlace === marketPlacesEnum.B2W) {
      return payloadMessageB2w(resp);
    }
    if (chatState?.marketPlace === marketPlacesEnum.COLOMBO) {
      return {
        id: chat.id,
        ticketId: chatState?.marketplaceBundle.ticketId,
        status: 'ONGOING',
        recipient: mountRecipentObjectColombo(),
        content: resp,
        protocol: chatState?.marketplaceBundle.protocol,
        files: filesToUpload?.map(file => {
          return {
            name: file.path
              .replace(/[^*a-zA-Z\d\s._-]/g, '')
              .replace(/[\s]/g, '_'),
            type: file.type,
            content: file.binary
          };
        })
      };
    }
    if (
      [marketPlacesEnum.CARREFOUR, marketPlacesEnum.KABUM].includes(
        chatState?.marketPlace
      )
    ) {
      return {
        ticketId: chat.id,
        body: resp,
        to: mountRecipentObjectMirakl(),
        files: filesToUpload?.map(file => {
          return {
            name: file.path
              .replace(/[^*a-zA-Z\d\s._-]/g, '')
              .replace(/[\s]/g, '_'),
            key: file.key,
            content: file.content
          };
        })
      };
    }
    if (chatState?.marketPlace === marketPlacesEnum.MAGALU) {
      return {
        ticketId: chatState?.id,
        isPrivate: false,
        message: resp
      };
    }
    if (chatState?.marketPlace === marketPlacesEnum.NETSHOES) {
      return {
        ticketId: chatState?.id,
        codigoAtendimento: chat?.marketplaceBundle?.codigoAtendimento,
        descricaoTramite: resp,
        enviarEmail: sendEmail,
        flagAtribuiResponsabilidadeNetshoes: assignResponsible,
        idLojista: chat?.marketplaceBundle?.codigoLojista,
        nomeCliente: chatState?.customerName,
        emailCliente: chatState?.customerEmail,
        emailFornecedor: chat?.marketplaceBundle?.emailFornecedor,
        numeroPedido: chat?.marketplaceBundle?.numeroPedido
      };
    }
    if (chatState?.marketPlace === marketPlacesEnum.VIVO) {
      const file = filesToUpload?.map(f => {
        return {
          name: f.path.replace(/[^*a-zA-Z\d\s._-]/g, '').replace(/[\s]/g, '_'),
          key: f.path.replace(/[^*a-zA-Z\d\s._-]/g, '').replace(/[\s]/g, '_'),
          type: f.type,
          content: f.binary
        };
      });

      return {
        ticketId: chat.id,
        message: resp,
        attachment: filesToUpload.length > 0 ? file[0] : {}
      };
    }
    return {
      ticketId: chat.id,
      message: resp,
      attachments: filesToUpload?.map(file => {
        return {
          name: file.path
            .replace(/[^*a-zA-Z\d\s._-]/g, '')
            .replace(/[\s]/g, '_'),
          content: file.binary
        };
      })
    };
  };

  const finishNormalMessagePost = () => {
    setNormalMessageRespostaLoading(false);
    setFilesToUpload([]);
    setResposta('');
    setB2wHelpAnswer('');
    setB2wSelectedClassification({});
  };

  const parseToFieldToString = message => {
    const { to } = message;
    let newTo = '';

    if (_.isArray(to)) {
      to.forEach(item => {
        newTo += `${item.type} `;
      });
    } else {
      newTo = to;
    }

    return { ...message, to: newTo };
  };

  // Whatsapp comentado em 16/03/2022 para publicação da R3 - Descomentar assim que liberado pelo cliente
  /*
  const sendWhatsappMessage = (resp, waId) => {
    const data = {
      text: resp,
      WaId: waId,
      files: filesToUpload?.map(file => {
        return {
          name: file.path
            .replace(/[^*a-zA-Z\d\s._-]/g, '')
            .replace(/[\s]/g, '_'),
          type: file.type,
          content: file.binary
        };
      })
    };
    setNormalMessageRespostaLoading(true);
    API.post(`${process.env.REACT_APP_WHATSAPP}/send-message`, data)
      .catch(() => {
        enqueueSnackbar('Não foi possível enviar a mensagem', {
          variant: 'error'
        });
      })
      .finally(() => {
        const parsedMessage = parseToFieldToString(resp);
        const newNormalMessages = [
          ...messages,
          {
            message: resp,
            agent: 'ABO',
            ...parsedMessage
          }
        ];
        setMessages(newNormalMessages);
        updateChat();

        finishNormalMessagePost();
      });
    // return data;
  }; */

  const sendResponse = (resp, agent = 'ABO') => {
    // TODO GET USERNAME
    // const userName = userService.getUsername();

    // Whatsapp comentado em 16/03/2022 para publicação da R3 - Descomentar assim que liberado pelo cliente
    /*
    if (chatState.marketplaceBundle.waId) {
      sendWhatsappMessage(resp, chatState.marketplaceBundle.waId);
      return false;
    }

    if (chatState.marketplaceBundle.WaId) {
      sendWhatsappMessage(resp, chatState.marketplaceBundle.WaId);
      return false;
    }
    */

    if (!resp.trim() && (!filesToUpload || filesToUpload.length === 0)) {
      const msg = t('i18n.chat.NO_MESSAGE');
      enqueueSnackbar(msg, {
        variant: 'warning'
      });
      return;
    }

    setNormalMessageRespostaLoading(true);
    const newMessage = getSendResponseMessagePayload(resp);

    API.post(getSendResponseUrl(), newMessage)
      .then(() => {
        const parsedMessage = parseToFieldToString(newMessage);
        const newNormalMessages = [
          ...messages,
          {
            message: resp,
            agent,
            ...parsedMessage
          }
        ];
        setMessages(newNormalMessages);
        updateChat();
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
          variant: 'error'
        });
      })
      .finally(() => {
        finishNormalMessagePost();
      });
  };

  const handleAcceptAnswer = answer => {
    sendResponse(answer, 'Robot');
    setShowBotMessage('false');
  };

  const sendMlClaimResponse = resp => {
    if (checkIfResponseIsEmpty(resp)) {
      return;
    }

    const postPayload = {
      ticketId: chat.id,
      message: resp,
      attachments: filesToUpload?.map(file => {
        return {
          name: file.path
            .replace(/[^*a-zA-Z\d\s._-]/g, '')
            .replace(/[\s]/g, '_'),
          content: file.binary
        };
      })
      // attachments: mlClaimsFilesToUpload?.map(file => {
      //   return {
      //     name: file.path
      //       .replace(/[^*a-zA-Z\d\s._-]/g, '')
      //       .replace(/[\s]/g, '_'),
      //     content: file.binary
      //   };
      // })
    };
    setMlClaimRespostaLoading(true);
    API.post(
      `/auth/ms-ticket/v1/tickets/mercadolivre/claims/send-answer`,
      postPayload
    )
      .then(() => {
        const newMlClaims = [
          {
            message: resp,
            sender_role: 'respondent'
          },
          ...mlClaims
        ];
        setMlClaims(newMlClaims);
        setMlClaimResposta('');
        setShowBotMessage('false');
        // setMlClaimsFilesToUpload([]);
        setFilesToUpload([]);
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
          variant: 'error'
        });
      })
      .finally(() => {
        setMlClaimRespostaLoading(false);
      });
  };

  const sendInternalMessage = async resp => {
    if (filesToUpload.length === 0) {
      if (checkIfResponseIsEmpty(resp)) {
        return;
      }
    }

    setInternalMessageLoading(true);
    try {
      await API.post(
        `/auth/ms-ticket/v1/tickets/${chat.id}/new-internal-message`,
        {
          message: resp,
          attachments: filesToUpload?.map(file => {
            return {
              name: file.path
                .replace(/[^*a-zA-Z\d\s._-]/g, '')
                .replace(/[\s]/g, '_'),
              content: file.binary
            };
          })
        }
      );
      const newInternalMessages = [
        ...internalMessages,
        {
          message: resp
        }
      ];
      setInternalMessages(newInternalMessages);
      setInternalMessagesResposta('');
      setFilesToUpload([]);
      updateChat();
    } catch (error) {
      enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
        variant: 'warning'
      });
    }
    setInternalMessageLoading(false);
  };

  const sendPrivateMessageMagalu = async () => {
    const message = {
      ticketId: chatState?.id,
      isPrivate: true,
      message: privateMessageAnswerMagalu
    };

    setPrivateMessageMagaluLoading(true);
    try {
      await API.post(getSendResponseUrl(), message);
      const newPrivateMagalu = [
        ...internalMessages,
        {
          message: privateMessageAnswerMagalu
        }
      ];
      setPrivateMessagesMagalu(newPrivateMagalu);
      setPrivateMessageAnswerMagalu('');
      updateChat();
    } catch (error) {
      enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
        variant: 'warning'
      });
    }
    setPrivateMessageMagaluLoading(false);
  };

  function compare(a, b) {
    const nameA = a.description.toUpperCase();
    const nameB = b.description.toUpperCase();
    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }

  const genericMessagesTabTitle = msg => {
    if (
      marketplaces100PercentMediated.some(el =>
        el.value.includes(chatState.marketPlace)
      )
    ) {
      return (
        <TabTitleWithIndicator
          title={t('i18n.ticketcontainer.TAB_MEDIATED')}
          messagesCount={msg}
        />
      );
    }

    return (
      <TabTitleWithIndicator
        title={t('i18n.ticketslistview.TAB_MESSAGES')}
        messagesCount={messages}
      />
    );
  };

  const handleAppliedMacro = (msg, typeMessage) => {
    if (typeMessage === 'normalMessage') {
      setResposta(msg.message);
    } else if (typeMessage === 'internalMessage') {
      setInternalMessagesResposta(msg.message);
    } else if (typeMessage === 'MLClaim') {
      setMlClaimResposta(msg.message);
    }
  };

  const handleEditBotMessage = botMsg => {
    setResposta(botMsg);
  };

  const handleDeleteBotMessage = async botMsg => {
    const msgsBoot = botMessage.filter(item => {
      if (item.answer !== botMsg.answer) {
        return true;
      }
      return false;
    });

    try {
      await API.post(
        `/auth/ms-ticket/v1/tickets/${chat.id}/negative-ticketAssistantMessage`,
        botMsg
      ).then(() => {
        setBotMessage(msgsBoot);
      });
    } catch (error) {
      enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
        variant: 'warning'
      });
    }
  };

  const findFirstModeratingMessage = () => {
    const moderatorMessages = mlClaims
      .filter(
        message =>
          message.sender_role === 'mediator' ||
          message.receiver_role === 'mediator'
      )
      .sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

    if (moderatorMessages.length > 0) {
      setFirstMediatorResponseDate(moderatorMessages[0].date);
    }
  };

  const attachPDFTerm = () => {
    const findTerm = filesToUpload.filter(
      file => file.path === 'termoDevolucaoMercadoriaV2.pdf'
    );

    if (findTerm?.length > 0) {
      enqueueSnackbar(t('i18n.simplephrases.TERM_ALREADY_INSERTED'), {
        variant: 'warning'
      });
      return false;
    }

    fetch('/static/termoDevolucaoMercadoriaV2.pdf')
      .then(res => res.blob()) // Gets the response and returns it as a blob
      .then(blob => {
        const file = new File([blob], 'termoDevolucaoMercadoriaV2.pdf', {
          type: 'application/pdf'
        });
        onDrop([file]);
        enqueueSnackbar(t('i18n.simplephrases.TERM_SUCCESS'), {
          variant: 'success'
        });
      });
  };

  return {
    resposta,
    mlClaimResposta,
    mlClaimRespostaLoading,
    b2wMediation,
    b2wHelp,
    b2wHelpAnswer,
    b2wChatClassification,
    internalMessagesResposta,
    privateMessagesMagalu,
    privateMessageMagaluLoading,
    internalMessageLoading,
    normalMessageRespostaLoading,
    showBotMessage,
    setRecipientClient,
    setRecipientOperator,
    setAssignResponsible,
    setSendEmail,
    firstMediatorResponseDate,
    mlTicketblocked,
    isMlClaimReturnType,
    getRootProps,
    getInputProps,
    acceptedFiles,
    files,
    isMlClaimTicket,
    handleAcceptAnswer,
    sendMlClaimResponse,
    sendInternalMessage,
    sendPrivateMessageMagalu,
    compare,
    genericMessagesTabTitle,
    handleAppliedMacro,
    handleEditBotMessage,
    handleDeleteBotMessage,
    findFirstModeratingMessage,
    attachPDFTerm,
    setFilesToUpload,
    setResposta,
    setMlClaimResposta,
    setMessages,
    messages,
    upDateChatByTicketData,
    onDrop,
    mlClaims,
    chatState,
    internalMessages,
    botMessage,
    filesToUpload,
    recipientClient,
    recipientOperator,
    sendResponse,
    setInternalMessagesResposta,
    privateMessageAnswerMagalu,
    setPrivateMessageAnswerMagalu,
    setMlClaimsFilesToUpload,
    mlClaimsFilesToUpload,
    updateChat,
    setB2wHelpAnswer,
    b2wSelectedClassification,
    setB2wSelectedClassification
  };
};

export default useMessagesHooks;
