import {
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import React, { useState } from 'react';
import { ThumbDown, ThumbUp } from '@material-ui/icons';
import API from '../../../../services/api';
import { COLORS } from '../../../../styles/settings/colors.config';
import userService from '../../../../services/user.service';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  iconPadding: {
    verticalAlign: 'bottom'
  },
  spanPadding: {
    verticalAlign: 'bottom',
    fontFamily: 'Roboto',
    fontWeight: '500'
  },
  marginTop: {
    marginTop: '24px'
  },
  opinion: {
    minWidth: '100%',
    marginTop: '10px',
    maxHeight: '145px',
    overflow: 'auto',
    color: COLORS.grayText
  }
}));

const TicketSatisfaction = ({ data }) => {
  const classes = useStyles();
  const [sentRequest, setSentRequest] = useState(
    data.customerSatisfactionScoreSend
  );

  const sendTicketRequest = () => {
    API.post(
      `/auth/ms-ticket/v1/tickets/${data.id}/request-satisfaction-review`
    )
      .then(() => {
        setSentRequest(true);
      })
      .catch(() => {
        setSentRequest(false);
      });
  };

  const renderView = () => {
    if (data.marketplaceBundle.customerGuideServiceOpinionList.length > 0) {
      return (
        <>
          <Grid item xs={12}>
            {data.marketplaceBundle.customerGuideServiceOpinionList[0]
              .satisfactionScore ? (
              <>
                {' '}
                <ThumbUp className={classes.iconPadding} color="primary" />
                <span className={classes.spanPadding}>
                  {' '}
                  &nbsp;&nbsp;O cliente gostou desse atendimento
                </span>
              </>
            ) : (
              <>
                {' '}
                <ThumbDown
                  className={classes.iconPadding}
                  style={{ color: COLORS.btnDangerColor }}
                />
                <span className={classes.spanPadding}>
                  {' '}
                  &nbsp;&nbsp;O cliente não gostou desse atendimento.
                </span>
              </>
            )}
          </Grid>
          <Grid item xs={12} className={classes.opinion}>
            {data.marketplaceBundle.customerGuideServiceOpinionList[0]
              .opinion &&
              userService.hasRole('ticket_view_evaluation') &&
              data.marketplaceBundle.customerGuideServiceOpinionList[0].opinion
                .split('\n')
                .map(i => {
                  return <Typography align="left">{i}</Typography>;
                })}
          </Grid>
        </>
      );
    }
    if (data.ticketStatus === 'CLOSED') {
      return (
        <Grid item>
          <span>Não foi feita uma avaliação desse atendimento.</span>
        </Grid>
      );
    }
    if (!sentRequest) {
      return (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => {
            sendTicketRequest();
          }}
          startIcon={<MailIcon />}
        >
          Requisitar avaliação
        </Button>
      );
    }
    return (
      <Grid item>
        <span style={{ lineHeight: 'center' }}> Email Enviado!</span>
      </Grid>
    );
  };

  return (
    (data.ticketStatus === 'RESOLVED' || data.ticketStatus === 'CLOSED') &&
    data.marketPlace === 'CUSTOMER_GUIDE' && (
      <Card className={classes.marginTop}>
        <CardContent>
          <Grid container direction="column" alignItems="center">
            {renderView()}
          </Grid>
        </CardContent>
      </Card>
    )
  );
};

export default TicketSatisfaction;
