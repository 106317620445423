import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AcerModal from '../../../../../components/AcerModal';
import { COLORS } from '../../../../../styles/settings/colors.config';
import ModalPostDelivery from './ModalPostDelivery';
import ModalDeniedExchange from './ModalDeniedExchange';
import ModalReleaseWarning from './ModalReleaseWarning';
import ModalRelease from './ModalRelease';

const StyledCardProduct = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  margin: 24px 0;
  padding: 0 32px;
  text-align: center;

  .product-description {
    margin: 8px 0 24px;
  }

  .icon {
    height: 56px;
    margin: 0 0 36px;
    width: 56px;

    &.blocked {
      fill: ${COLORS.btnDangerColor};
    }
    &.alert {
      fill: ${COLORS.btnWarningColor};
    }
    &.success {
      fill: ${COLORS.btnSuccessColor};
    }
  }

  .product-select {
    > div {
      height: 38px;
      position: relative;
      top: 7px;
    }
  }

  button {
    position: relative;
    top: 7px;
  }
`;

const ModalProductStatus = ({
  isReturnPurchase,
  instance,
  ticketId,
  deliveryId,
  openState,
  closeParent
}) => {
  const { t } = useTranslation();
  const [deliveryModal, setDeliveryModal] = useState(false);
  const [refuseModal, setRefuseModal] = useState(false);
  const [releaseWarningModal, setReleaseWarningModal] = useState(false);
  const [releaseModal, setReleaseModal] = useState(false);
  const [deliveryType, setDeliveryType] = useState('');

  const handleDeliveryChange = e => {
    setDeliveryType(e.target.value);
    setDeliveryModal(true);
  };

  return (
    <>
      <AcerModal
        fullWidth
        maxWidth="md"
        open={openState}
        modalTitle={t('i18n.b2w.phrases.WHERE_IS_PRODUCT')}
        onClose={closeParent}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} md={4}>
            <StyledCardProduct>
              <Typography variant="h3" className="product-title">
                {t('i18n.b2w.phrases.WHERE_IS_PRODUCT.NOT_SENT')}
              </Typography>
              <Typography variant="body1" className="product-description">
                {t('i18n.b2w.phrases.WHERE_IS_PRODUCT.NOT_SENT_DESCRIPTION')}
              </Typography>
              <CancelIcon className="icon blocked" />
              {isReturnPurchase && (
                <Button
                  variant="outlined"
                  onClick={() => setReleaseModal(true)}
                >
                  {t('i18n.b2w.phrases.WHERE_IS_PRODUCT.NOT_SENT_RELEASE')}
                </Button>
              )}
              {/* {!isReturnPurchase && (
                <Button variant="outlined">
                  {t('i18n.b2w.phrases.WHERE_IS_PRODUCT.NOT_SENT_RELEASE')}
                </Button>
              )} */}
              {!isReturnPurchase && (
                <Button
                  variant="outlined"
                  onClick={() => setReleaseWarningModal(true)}
                >
                  {t('i18n.b2w.phrases.WHERE_IS_PRODUCT.NOT_SENT_RELEASE')}
                </Button>
              )}
            </StyledCardProduct>
          </Grid>

          <Grid item xs={12} md={4}>
            <StyledCardProduct>
              <Typography variant="h3" className="product-title">
                {t('i18n.b2w.phrases.WHERE_IS_PRODUCT.IN_TRANSPORT')}
              </Typography>
              <Typography variant="body1" className="product-description">
                {t(
                  'i18n.b2w.phrases.WHERE_IS_PRODUCT.IN_TRANSPORT_DESCRIPTION'
                )}
              </Typography>
              <LocalShippingIcon className="icon alert" />
              <Button variant="outlined" onClick={() => setRefuseModal(true)}>
                {t('i18n.b2w.phrases.WHERE_IS_PRODUCT.IN_TRANSPORT_REFUSE')}
              </Button>
            </StyledCardProduct>
          </Grid>

          <Grid item xs={12} md={4}>
            <StyledCardProduct>
              <Typography variant="h3" className="product-title">
                {t('i18n.b2w.phrases.WHERE_IS_PRODUCT.IN_TRANSPORT_DELIVERED')}
              </Typography>
              <Typography variant="body1" className="product-description">
                {t(
                  'i18n.b2w.phrases.WHERE_IS_PRODUCT.IN_TRANSPORT_DELIVERED_DESCRIPTION'
                )}
              </Typography>
              <CheckCircleIcon className="icon success" />
              <FormControl
                fullWidth
                variant="outlined"
                className="product-select"
              >
                <InputLabel>
                  {t('i18n.b2w.phrases.WHERE_IS_PRODUCT.IN_TRANSPORT_SELECT')}
                </InputLabel>

                {isReturnPurchase && (
                  <Select
                    label={t('i18n.newAutomation.TYPE_OF_ACTION')}
                    onChange={handleDeliveryChange}
                    value={deliveryType}
                  >
                    <MenuItem value="collect-returnPurchase">
                      {t(
                        'i18n.b2w.phrases.WHERE_IS_PRODUCT.IN_TRANSPORT_COLECT'
                      )}
                    </MenuItem>
                    <MenuItem value="post-returnPurchase">
                      {t('i18n.b2w.phrases.WHERE_IS_PRODUCT.IN_TRANSPORT_POST')}
                    </MenuItem>
                  </Select>
                )}
                {!isReturnPurchase && (
                  <Select
                    label={t('i18n.newAutomation.TYPE_OF_ACTION')}
                    onChange={handleDeliveryChange}
                    value={deliveryType}
                  >
                    <MenuItem value="collect">
                      {t(
                        'i18n.b2w.phrases.WHERE_IS_PRODUCT.IN_TRANSPORT_COLECT'
                      )}
                    </MenuItem>
                    <MenuItem value="post">
                      {t('i18n.b2w.phrases.WHERE_IS_PRODUCT.IN_TRANSPORT_POST')}
                    </MenuItem>
                  </Select>
                )}
              </FormControl>
            </StyledCardProduct>
          </Grid>
        </Grid>
      </AcerModal>
      <ModalPostDelivery
        instance={instance}
        ticketId={ticketId}
        deliveryId={deliveryId}
        openState={deliveryModal}
        handleClose={() => {
          closeParent();
          setDeliveryModal(false);
          setDeliveryType('');
        }}
        deliveryType={deliveryType}
      />
      <ModalDeniedExchange
        ticketId={ticketId}
        instance={instance}
        openState={refuseModal}
        closeParent={() => {
          closeParent();
          setRefuseModal(false);
        }}
      />
      <ModalRelease
        ticketId={ticketId}
        instance={instance}
        openState={releaseModal}
        closeParent={() => {
          closeParent();
          setReleaseModal(false);
        }}
      />
      <ModalReleaseWarning
        ticketId={ticketId}
        instance={instance}
        openState={releaseWarningModal}
        closeParent={() => {
          closeParent();
          setReleaseWarningModal(false);
        }}
      />
    </>
  );
};

export default ModalProductStatus;
