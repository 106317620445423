import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { Link, useHistory } from 'react-router-dom';
import userService from '../../../services/user.service';
import config from '../../../config';
import Page from '../../../components/Page';
import API from '../../../services/api';
import formHelper from '../../../utils/formHelper';
import { CustomBreadCrumbs } from '../../../styles/components/CustomDataTable';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  breadcrumbs: {
    marginTop: '15px',
    marginLeft: '24px'
  }
}));

const UserContainer = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const [mascaraTelefone, setMascaraTelefone] = useState('(99)99999-9999');
  const [isSaving, setSaving] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [groupAll, setGroupAll] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const companyFromToken = userService.getCompanyName();
  const [formData, setFormdata] = useState(
    formHelper.simpleData2FormData({
      name: {
        required: true
      },
      firstName: {
        required: true
      },
      lastName: {
        required: true
      },
      password: {
        required: true
      },
      email: {
        required: true
      },
      phone_number: {
        required: true,
        masked: true,
        leaveOnlyNumbersWhenSubmit: true
      },
      group: {
        required: true
      },
      companyName: {
        required: true
      },
      profileName: {
        required: true
      },
      userType: {
        required: true
      },
      nickname: {
        required: true
      }
    })
  );

  const formIsOK = () => {
    if (!formData.companyName.value) {
      formHelper.setFormvalue(
        formData,
        setFormdata,
        'companyName',
        companyFromToken
      );
    }
    if (!formHelper.validaForm(formData, setFormdata)) {
      enqueueSnackbar(t('i18n.newticket.CHECK_IF_FIELDS_ARE_FILLED'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      return false;
    }
    if (!formHelper.validateEmail(formData.email.value)) {
      enqueueSnackbar(t('i18n.validation.INVALIDEMAIL'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      formHelper.setFormError(formData, setFormdata, 'email');
      return false;
    }
    const re = new RegExp(
      '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+]).{8,}$'
    );
    if (!re.test(formData.password.value)) {
      enqueueSnackbar(t('i18n.validation.INVALIDPASSWORD'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      formHelper.setFormError(formData, setFormdata, 'password');
      return false;
    }
    if (formData.password.value.length <= 7) {
      enqueueSnackbar(t('i18n.validation.INVALIDPASSWORD'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      formHelper.setFormError(formData, setFormdata, 'password');
      return false;
    }
    if (formHelper.existsEmptySpaces(formData.name.value)) {
      enqueueSnackbar(t('i18n.validation.INVALIDUSERNAME'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      formHelper.setFormError(formData, setFormdata, 'name');
      return false;
    }
    return true;
  };

  const saveUser = () => {
    if (!formIsOK()) {
      return;
    }
    setSaving(true);
    const postData = formHelper.formData2PostData(formData);
    postData.groupResources = [{ name: postData.group }];
    delete postData.group;
    postData.optIn = false;

    // const addProfile = { profileName: '' };
    // const profileToPostData = { ...postData, ...addProfile };
    const profileToPostData = { ...postData };

    API.post(`/auth/ms-users/users`, profileToPostData)
      .then(() => {
        history.goBack();
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.usercontainer.FAILSAVE'), {
          variant: 'error'
        });
      })
      .finally(() => setSaving(false));
  };

  function compare(a, b) {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }

  const userTypeList = [
    { name: 'Temporário', value: 'temporario' },
    { name: 'Contrato', value: 'contrato' }
  ];

  const fillNickname = surname => {
    if (formData.firstName && surname) {
      const trimFirstName = formData.firstName.value.replace(' ', '');
      const firstPart =
        trimFirstName.slice(0, 1).toUpperCase() +
        trimFirstName.slice(1).toLowerCase();
      const secondPart = surname.slice(0, 1).toUpperCase();
      const newNickName = firstPart + secondPart;
      formHelper.setFormvalue(formData, setFormdata, 'nickname', newNickName);
    }
  };

  const fetchPrimaryInfo = () => {
    // Empresas cadastradas
    if (companyFromToken === config.cognito.ownerCompany) {
      API.post(`/auth/ms-ticket/company/list`).then(response => {
        const companies = response.data._embedded.companyResourceList;
        setCompanyList(companies.sort(compare));
      });
    }

    // Perfis Cadastrados
    API.get(`/auth/ms-users/profile-journey/find-all`).then(response => {
      setProfileList(response.data);
    });

    //  Grupos cadastrados
    API.get(`/auth/ms-users/groups`).then(response => {
      const allGroups = response.data;
      setGroupAll(allGroups.sort(compare));
    });
  };

  const updateGroupList = () => {
    // Se usuário logado é da empresa Acer e a empresa selecionada for Acer > Acesso a ver os Grupos da Acer (que são todos aquqles que NÃO tem : na descrição)
    if (
      companyFromToken === config.cognito.ownerCompany &&
      formData.companyName.value === config.cognito.ownerCompany
    ) {
      setGroupList(groupAll.filter(el => !el.name.includes(':')));
      return;
    }

    // Se usuário logado é da empresa Acer e a empresa selecionada for "estrangeira" > Preencher o Grupo com “admin_externo”
    // Este contexto é quando o adminstrador da Acer cadastra um administrador estrangeiro
    if (
      companyFromToken === config.cognito.ownerCompany &&
      formData.companyName.value !== config.cognito.ownerCompany
    ) {
      setGroupList([
        {
          name: 'admin_externo',
          description: null
        }
      ]);
      formHelper.setFormvalue(formData, setFormdata, 'group', 'admin_externo');
      return;
    }

    // Se usuário logado é de empresa "estrangeira" > Grupos visíveis apenas da respectiva empresa
    if (companyFromToken !== config.cognito.ownerCompany) {
      setGroupList(
        groupAll.filter(el => el.name.includes(`${companyFromToken}:`))
      );
    }
  };

  useEffect(() => {
    fetchPrimaryInfo();
  }, []);

  // Preeenche a empresa com o mesmo valor vindo do usuário logado
  useEffect(() => {
    if (companyFromToken) {
      formHelper.setFormvalue(
        formData,
        setFormdata,
        'companyName',
        companyFromToken
      );
    }
  }, [companyFromToken]);

  // Se mudar a empresa selecionada o Grupo preenchido é removido e uma nova lista de grupos e montada de acordo com a empresa
  useEffect(() => {
    formHelper.setFormvalue(formData, setFormdata, 'group', '');
    if (groupAll) {
      updateGroupList();
    }
  }, [formData.companyName.value, groupAll]);

  return (
    <Page className={classes.root} title="User Container">
      <Container maxWidth={false}>
        <CustomBreadCrumbs
          className={classes.breadcrumbs}
          gutterBottom
          aria-label="breadcrumb"
        >
          <Typography variant="h6">
            <Link color="inherit" to="/users">
              {t('i18n.usercontainer.USERS')}
            </Link>
          </Typography>
          <Typography variant="h6" color="textPrimary">
            {t('i18n.usercontainer.NEW_USER')}
          </Typography>
        </CustomBreadCrumbs>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="sm">
            <Box>
              <Card>
                <CardContent>
                  <Typography variant="h3">
                    {t('i18n.usercontainer.NEW_USER')}
                  </Typography>
                </CardContent>
                <Divider />
                <CardContent>
                  <Box mb={2}>
                    {userService.hasRole(
                      'user_can_add_user_external_mktplace'
                    ) ? (
                      <FormControl
                        error={formData.companyName.error}
                        fullWidth
                        variant="outlined"
                      >
                        <InputLabel>{t('i18n.companies.COMPANY')}</InputLabel>
                        <Select
                          value={formData.companyName.value}
                          onChange={htmlElemtEvent => {
                            formHelper.setFormvalue(
                              formData,
                              setFormdata,
                              'companyName',
                              htmlElemtEvent.target.value
                            );
                          }}
                          label={t('i18n.companies.COMPANY')}
                        >
                          {companyList?.map(company => (
                            <MenuItem key={company.name} value={company.name}>
                              <Chip
                                color="default"
                                size="medium"
                                label={company.name}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <TextField
                        disabled
                        label={t('i18n.companies.COMPANY')}
                        value={companyFromToken}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  </Box>

                  <Box mb={2}>
                    <TextField
                      value={formData.firstName.value}
                      onChange={htmlElemtEvent => {
                        formHelper.setFormvalue(
                          formData,
                          setFormdata,
                          'firstName',
                          htmlElemtEvent.target.value
                        );
                      }}
                      error={formData.firstName.error}
                      // label={t('i18n.usercontainer.LOGIN')}
                      label="Nome"
                      variant="outlined"
                      fullWidth
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      value={formData.lastName.value}
                      onChange={htmlElemtEvent => {
                        formHelper.setFormvalue(
                          formData,
                          setFormdata,
                          'lastName',
                          htmlElemtEvent.target.value
                        );
                        fillNickname(htmlElemtEvent.target.value);
                      }}
                      error={formData.lastName.error}
                      // label={t('i18n.usercontainer.LOGIN')}
                      label="Sobrenome"
                      variant="outlined"
                      fullWidth
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      value={formData.nickname.value}
                      onChange={htmlElemtEvent => {
                        formHelper.setFormvalue(
                          formData,
                          setFormdata,
                          'nickname',
                          htmlElemtEvent.target.value
                        );
                      }}
                      error={formData.nickname.error}
                      disabled={!userService.hasGroup('admin')}
                      // label={t('i18n.usercontainer.LOGIN')}
                      label="Nickname"
                      variant="outlined"
                      fullWidth
                    />
                  </Box>

                  <Box mb={2}>
                    <FormControl
                      error={formData.profileName.error}
                      fullWidth
                      variant="outlined"
                    >
                      <InputLabel>Perfil</InputLabel>
                      <Select
                        value={formData.profileName.value}
                        onChange={htmlElemtEvent => {
                          formHelper.setFormvalue(
                            formData,
                            setFormdata,
                            'profileName',
                            htmlElemtEvent.target.value
                          );
                        }}
                        label="Perfil"
                      >
                        {profileList?.map(profile => (
                          <MenuItem
                            key={Math.random()}
                            // eslint-disable-next-line
                            value={profile}
                          >
                            <Chip
                              color="default"
                              size="medium"
                              label={profile}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box mb={2}>
                    <FormControl
                      error={formData.userType.error}
                      fullWidth
                      variant="outlined"
                    >
                      <InputLabel>Tipo do Usuário</InputLabel>
                      <Select
                        value={formData.userType.value}
                        onChange={htmlElemtEvent => {
                          formHelper.setFormvalue(
                            formData,
                            setFormdata,
                            'userType',
                            htmlElemtEvent.target.value
                          );
                        }}
                        label="Tipo do Usuário"
                      >
                        {userTypeList?.map(type => (
                          <MenuItem key={type.name} value={type.value}>
                            <Chip
                              color="default"
                              size="medium"
                              label={type.name}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box mb={2}>
                    <TextField
                      onChange={htmlElemtEvent => {
                        formHelper.setFormvalue(
                          formData,
                          setFormdata,
                          'name',
                          htmlElemtEvent.target.value
                        );
                      }}
                      error={formData.name.error}
                      label={t('i18n.usercontainer.LOGIN')}
                      defaultValue=""
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      onChange={htmlElemtEvent => {
                        formHelper.setFormvalue(
                          formData,
                          setFormdata,
                          'password',
                          htmlElemtEvent.target.value
                        );
                      }}
                      type="password"
                      error={formData.password.error}
                      label={t('i18n.usercontainer.TEMPORARY_PASSWORD')}
                      defaultValue=""
                      variant="outlined"
                      helperText={t('i18n.loginview.PASSWORD_RULES')}
                      fullWidth
                    />
                    {/* // TODO add translate */}
                  </Box>
                  <Box mb={2}>
                    <TextField
                      onChange={htmlElemtEvent => {
                        formHelper.setFormvalue(
                          formData,
                          setFormdata,
                          'email',
                          htmlElemtEvent.target.value
                        );
                      }}
                      error={formData.email.error}
                      label={t('i18n.usercontainer.EMAIL')}
                      defaultValue=""
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                  <Box mb={2}>
                    <InputMask
                      mask={mascaraTelefone}
                      onChange={htmlElemtEvent => {
                        formHelper.setFormvalue(
                          formData,
                          setFormdata,
                          'phone_number',
                          htmlElemtEvent.target.value
                        );
                      }}
                      onBlur={htmlElemtEvent => {
                        formHelper.telefoneFlex(
                          'phone_number',
                          formData,
                          setFormdata,
                          setMascaraTelefone,
                          htmlElemtEvent
                        );
                      }}
                      onFocus={() => {
                        setMascaraTelefone('(99)99999-9999');
                      }}
                    >
                      {() => (
                        <TextField
                          error={formData.phone_number.error}
                          label={t('i18n.usercontainer.PHONE')}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    </InputMask>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      error={formData.group.error}
                      fullWidth
                      variant="outlined"
                    >
                      <InputLabel>{t('i18n.usercontainer.GROUP')}</InputLabel>
                      <Select
                        value={formData.group.value}
                        onChange={htmlElemtEvent => {
                          formHelper.setFormvalue(
                            formData,
                            setFormdata,
                            'group',
                            htmlElemtEvent.target.value
                          );
                        }}
                        label={t('i18n.usercontainer.GROUP')}
                      >
                        {groupList?.map(group => (
                          <MenuItem
                            key={group.name + group.description}
                            value={group.name}
                          >
                            <Chip
                              color="default"
                              size="medium"
                              label={group.name}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </CardContent>
                <Divider />
                <CardContent>
                  <Grid container justifyContent="space-between">
                    <Grid item lg={6}>
                      <Button
                        variant="contained"
                        color="default"
                        type="button"
                        onClick={() => history.goBack()}
                      >
                        {t('i18n.usercontainer.CANCEL')}
                      </Button>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          saveUser();
                        }}
                        disabled={isSaving}
                      >
                        {t('i18n.newticket.SEND')}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Container>
        </Box>
      </Container>
    </Page>
  );
};

export default UserContainer;
