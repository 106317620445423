/*eslint-disable */
import React, { useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  IconButton,
  Tooltip
} from '@material-ui/core';
import styled from 'styled-components';
import ChipInput from 'material-ui-chip-input';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import API from '../../../services/api';

const CustomCardContent = styled(CardContent)`
  padding: 15px !important;
  border-radius: 0px;
`;
const CustomChipInput = styled(ChipInput)`
  width: 100%;
`;

const CustomCard = styled(Card)`
  margin-bottom: 0px;
  border-bottom: 1px solid #ccc;
  box-shadow: none;
  border-radius: 0px;
`;

const SynonymsCRUD = ({
  words,
  handleRefreshSynonymsList,
  item,
  showDelete = 'true',
  setWords,
  removeAddLine = 'false'
}) => {
  const { t } = useTranslation();
  const [yourChips, setYourChips] = useState(words);
  const { enqueueSnackbar } = useSnackbar();

  const handleAddChip = chip => {
    setYourChips([...yourChips, ...[chip]]);
  };

  const handleDeleteChip = chip => {
    const refreshList = yourChips.filter(subitem => {
      return subitem !== chip;
    });
    setYourChips(refreshList);
  };

  const handleSuccess = () => {
    enqueueSnackbar(t('i18n.synonyms.success'), {
      variant: 'success'
    });
  };

  const handleError = () => {
    enqueueSnackbar(t('i18n.synonyms.error'), {
      variant: 'error'
    });
  };

  const handleSubmit = () => {
    const data = { synonym: yourChips };

    if (!item) {
      API.post(`/auth/ms-ia/api/v1/synonyms`, data)
        .then(() => {
          handleSuccess();
          handleRefreshSynonymsList();
          if (removeAddLine === 'true') {
            setYourChips([]);
          }
        })
        .catch(() => {
          handleError();
        });
    } else {
      data._id = item?._id;
      API.put(`/auth/ms-ia/api/v1/synonyms`, data)
        .then(() => {
          handleSuccess();
          handleRefreshSynonymsList();
        })
        .catch(() => {
          handleError();
        });
    }
  };

  const handleDelete = () => {
    if (item) {
      API.delete(`/auth/ms-ia/api/v1/synonyms/${item?._id}`)
        .then(() => {
          enqueueSnackbar(t('i18n.synonyms.delete.success'), {
            variant: 'success'
          });
          handleRefreshSynonymsList();
        })
        .catch(() => {
          enqueueSnackbar(t('i18n.synonyms.delete.error'), {
            variant: 'error'
          });
        });
    }
  };

  return (
    <CustomCard>
      <CustomCardContent>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <CustomChipInput
              value={yourChips}
              onAdd={chip => handleAddChip(chip)}
              onDelete={(chip, index) => handleDeleteChip(chip, index)}
              variant="outlined"
            />
          </Grid>

          <Grid item container xs={2}>
            <Grid item xs={6} style={{ display: 'flex' }}>
              <Tooltip title={t('i18n.simplewords.SAVE')}>
                <IconButton
                  size="small"
                  fontSize="small"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  <SaveIcon />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item xs={6} style={{ display: 'flex' }}>
              {showDelete === 'true' && (
                <Tooltip title={t('i18n.datatable.selectedrows.DELETE')}>
                  <IconButton
                    size="small"
                    fontSize="small"
                    color="default"
                    variant="outlined"
                    onClick={() => {
                      handleDelete();
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CustomCardContent>
    </CustomCard>
  );
};

export default SynonymsCRUD;
