export const chatClosingReasons = [
  {
    contactingMainReason: 'Cliente Offline',
    finishingReason: ['Cliente Offline']
  },
  {
    contactingMainReason: 'Críticas',
    finishingReason: ['Sobre o site', 'Sobre o app', 'Sobre um produto']
  },
  {
    contactingMainReason: 'Dúvidas sobre Produto',
    finishingReason: [
      'Cadeira',
      'Desktop',
      'Headset',
      'Mochila',
      'Monitor',
      'Mouse',
      'Notebook',
      'Produtos Digitais',
      'Projetor',
      'Teclado'
    ]
  },
  {
    contactingMainReason: 'Dúvidas sobre site',
    finishingReason: [
      'Acer Club',
      'Bug',
      'Cashback',
      'Conta de Usuário',
      'Cupom',
      'Formas',
      'Promoções',
      'Troca Inteligente'
    ]
  },
  {
    contactingMainReason: 'Solicitação pós venda',
    finishingReason: [
      'Atraso na entrega',
      'Código de rastreio',
      'Dúvida sobre o pagamento',
      'Nota fiscal',
      'Pedido cancelado',
      'Solicitação de retorno'
    ]
  },
  {
    contactingMainReason: 'Sugestões',
    finishingReason: ['Sobre o site', 'Sobre o app', 'Sobre um produto']
  },
  {
    contactingMainReason: 'Suporte Técnico',
    finishingReason: [
      'Reposição de peças',
      'Reparo dentro da garantia',
      'Dúvida sobre a garantia'
    ]
  }
];

export const handleGetOptions = (mainReason) => {
  switch (mainReason) {
    case 'Dúvidas sobre Produto':
      return [
        { name: 'Cadeira', value: 'Cadeira' },
        { name: 'Desktop', value: 'Desktop' },
        { name: 'Headset', value: 'Headset' },
        { name: 'Mochila', value: 'Mochila' },
        { name: 'Monitor', value: 'Monitor' },
        { name: 'Mouse', value: 'Mouse' },
        { name: 'Notebook', value: 'Notebook' },
        { name: 'Produtos Digitais', value: 'Produtos Digitais' },
        { name: 'Projetor', value: 'Projetor' },
        { name: 'Teclado', value: 'Teclado' }
      ];
    case 'Dúvidas sobre site':
      return [
        { name: 'Acer Club', value: 'Acer Club' },
        { name: 'Bug', value: 'Bug' },
        { name: 'Cashback', value: 'Cashback' },
        { name: 'Conta de Usuário', value: 'Conta de Usuário' },
        { name: 'Cupom', value: 'Cupom' },
        { name: 'Formas de Pagamento', value: 'Formas de Pagamento' },
        { name: 'Promoções', value: 'Promoções' },
        { name: 'Troca Inteligente', value: 'Troca Inteligente' }
      ];
    case 'Solicitação pós venda':
      return [
        { name: 'Atraso na entrega', value: 'Atraso na entrega' },
        { name: 'Código de rastreio', value: 'Código de rastreio' },
        { name: 'Dúvida sobre o pagamento', value: 'Dúvida sobre o pagamento' },
        { name: 'Nota fiscal', value: 'Nota fiscal' },
        { name: 'Pedido cancelado', value: 'Pedido cancelado' },
        { name: 'Solicitação de retorno', value: 'Solicitação de retorno' }
      ];
    case 'Suporte Técnico':
      return [
        { name: 'Reposição de peças', value: 'Reposição de peças' },
        {
          name: 'Reparo dentro da garantia',
          value: 'Reparo dentro da garantia'
        },
        { name: 'Dúvida sobre a garantia', value: 'Dúvida sobre a garantia' }
      ];
    case 'Críticas':
      return [
        { name: 'Sobre o site', value: 'Sobre o site' },
        { name: 'Sobre o app', value: 'Sobre o app' },
        { name: 'Sobre um produto', value: 'Sobre um produto' }
      ];
    case 'Sugestões':
      return [
        { name: 'Sobre o site', value: 'Sobre o site' },
        { name: 'Sobre o app', value: 'Sobre o app' },
        { name: 'Sobre um produto', value: 'Sobre um produto' }
      ];
    case 'Cliente Offline':
      return [{ name: 'Cliente Offline', value: 'Cliente Offline' }];

    default:
      return [];
  }
};
