import { createTheme } from '@material-ui/core';
import { COLORS } from '../../../../../styles/settings/colors.config';

const svgTheme = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: '3rem',
        width: '2em',
        height: '2em'
      }
    }
  }
});

const svgThemeSuccess = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: '2rem',
        width: '2em',
        height: '2em',
        fill: COLORS.greenText
      }
    }
  }
});

const svgThemeDanger = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: '2rem',
        width: '2em',
        height: '2em',
        fill: COLORS.btnDangerColor
      }
    }
  }
});

const svgThemeGray = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: '2rem',
        width: '2em',
        height: '2em',
        fill: COLORS.meduimGray
      }
    }
  }
});

export { svgTheme, svgThemeSuccess, svgThemeDanger, svgThemeGray };
