import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import {
  SectionTabs,
  SectionTitle,
  SectionProductList,
  SectionTitleWarning
} from './SectionTabs';
import ProductItem from './ProductItem';
import ProductItemML from './ProductItemML';
import ProductListHeader from './ProductListHeader';
import ProductSubtotalML from './ProductSubtotalML';
import ProductSubtotal from './ProductSubtotal';

const TabDetails = ({
  productsLIst,
  orderData,
  data,
  aftersaleMlWithoutOrder
}) => {
  const { t } = useTranslation();
  const paymentsMlList = data?.marketplaceBundle?.order?.payments;
  const orderFromMarketplaceBundle = data.marketplaceBundle;

  const [productsAllFields, setProductsAllFields] = useState([]);
  const productMlFromMarketplaceBundle =
    data?.marketPlace === 'MERCADO_LIVRE'
      ? data?.marketplaceBundle?.order?.order_items
      : [];

  const formatLink = (idProd, title) => {
    const [, id] = idProd?.split('MLB');
    const titleFormated = title.replaceAll(' ', '-').replaceAll(',', '');
    return `https://produto.mercadolivre.com.br/MLB-${id}-${titleFormated}`;
  };

  const createMlLink = () => {
    productMlFromMarketplaceBundle.forEach(protocol => {
      const link = formatLink(protocol?.item?.id, protocol?.item?.title);
      productsLIst.forEach(order => {
        if (protocol.item.seller_sku === order.partNumber) {
          const newProductList = [
            ...productsAllFields,
            {
              partNumber: order.partNumber,
              descricao: order.descricao,
              quantidate: order.quantidade,
              valorBruto: order.valorBruto,
              totalBruto: order.totalBruto,
              link
            }
          ];
          setProductsAllFields(newProductList);
        }
      });
    });
  };

  useEffect(() => {
    if (productMlFromMarketplaceBundle.length > 0 && productsLIst?.length > 0) {
      createMlLink();
    } else if (productsLIst?.length > 0) {
      setProductsAllFields([...productsLIst]);
    }
  }, []);

  return (
    <>
      <SectionTabs>
        <SectionTitle>
          {t('i18n.ticketcontainer.DETAILS')}{' '}
          {data?.ticketType !== 'PRE_SALE' && 'do Pedido'}
          {data.marketPlace === 'MERCADO_LIVRE' && aftersaleMlWithoutOrder && (
            <SectionTitleWarning>
              {`* `}
              {t('i18n.ticketcontainer.ML_ORDER_WARNING')}
            </SectionTitleWarning>
          )}
        </SectionTitle>

        <ProductListHeader {...{ t }} />

        <SectionProductList>
          {data.marketPlace === 'MERCADO_LIVRE' && aftersaleMlWithoutOrder
            ? data?.marketplaceBundle?.order?.order_items.map(prod => (
                <ProductItemML {...{ prod, formatLink, t }} />
              ))
            : productsAllFields?.map(item => (
                <ProductItem {...{ item, t }} key={Math.random()} />
              ))}
        </SectionProductList>

        {data.marketPlace === 'MERCADO_LIVRE' && aftersaleMlWithoutOrder ? (
          <ProductSubtotalML
            {...{ paymentsMlList, orderData, orderFromMarketplaceBundle, t }}
          />
        ) : (
          <ProductSubtotal {...{ orderData, orderFromMarketplaceBundle, t }} />
        )}
      </SectionTabs>
    </>
  );
};

export default TabDetails;
