/*eslint-disable */

import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  ListItemAvatar,
  Typography,
  List,
  ListItemText,
  ListItem
} from '@material-ui/core';
import styled from 'styled-components';

import { useMarketplaceList } from '../../../hooks/hooks';
import indexHook from '../indexHook';

import { COLORS } from '../../../styles/settings/colors.config';

const CustomListItem = styled(ListItem)`
  .MuiAvatar-circular {
    background: ${props => props.filteractivate === 'true' && COLORS.greenText};
  }
`;

const FilterAccordion = ({
  open,
  setFilter,
  setSubmit,
  filter
  // dashboardFilter
}) => {
  const { t } = indexHook();
  const MARKETS_PLACE = useMarketplaceList().filter(item => {
    return item.value === 'All' || item.value === 'CUSTOMER_GUIDE';
  });
  const [expanded, setExpanded] = useState('panel1');
  const [marketPlacesSelected, setMarketPlacesSelected] = useState(
    filter.marketplace
  );

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleSetMarketplace = (marketplace, marketPlacesList) => {
    if (marketplace !== 'All' && marketPlacesList) {
      const searchedMarketPlace = marketPlacesList?.find(item => {
        return item === marketplace;
      });

      if (searchedMarketPlace === marketplace) {
        const listWithoutMarketplaceSearched = marketPlacesList?.filter(
          item => {
            return item !== marketplace && item !== 'All';
          }
        );
        setMarketPlacesSelected(listWithoutMarketplaceSearched);
        setFilter({
          ...filter,
          ...{ marketplace: listWithoutMarketplaceSearched }
        });
        setSubmit(false);
      }

      if (!searchedMarketPlace) {
        setMarketPlacesSelected([...marketPlacesList, ...[marketplace]]);
        setFilter({
          ...filter,
          ...{ marketplace: [...marketPlacesList, ...[marketplace]] }
        });
        setSubmit(false);
      }
    } else {
      const searchedMarketPlace =
        marketPlacesList &&
        marketPlacesList?.find(item => {
          return item === 'All';
        });

      if (searchedMarketPlace === 'All') {
        setMarketPlacesSelected([]);
        setFilter({ ...filter, ...{ marketplace: [] } });
        setSubmit(false);
      }

      if (!searchedMarketPlace) {
        const list = MARKETS_PLACE.map(item => {
          return item.value;
        });
        setMarketPlacesSelected(list);
        setFilter({ ...filter, ...{ marketplace: list } });
        setSubmit(false);
      }
    }
  };

  useEffect(() => {
    setMarketPlacesSelected(filter.marketplace);
  }, [filter]);

  return (
    <div>
      <Accordion
        square
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography className={`${open ? 'menu-opened' : 'menu-closed'}`}>
            {t('i18n.ticketslistview.MARKETPLACE_FILTER')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List className="marketplace-list">
            {MARKETS_PLACE.map(value => {
              const labelId = `checkbox-list-secondary-label-${value}`;

              return (
                <CustomListItem
                  onClick={() => {
                    handleSetMarketplace(value.value, marketPlacesSelected);
                  }}
                  filteractivate={
                    !!marketPlacesSelected &&
                      !!marketPlacesSelected?.find(item => {
                        return item === value.value;
                      })
                      ? 'true'
                      : 'false'
                  }
                  key={value.icon}
                  button
                >
                  <ListItemAvatar>
                    <Avatar
                      style={{
                        backgroundColor:
                          !!marketPlacesSelected &&
                          !!marketPlacesSelected?.find(item => {
                            return item === value.value;
                          }) &&
                          COLORS.greenText,
                        width: '27px',
                        height: '27px',
                        fontSize: '12px'
                      }}
                    >
                      {value.icon}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText id={labelId} primary={value.text} />
                </CustomListItem>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FilterAccordion;
