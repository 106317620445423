/*eslint-disable */
import React from 'react';

import { Box, Card, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  CustomTypograph,
  CustomTypographLink,
  MainCardData,
  CardTitle,
  CustomHintText,
  CustomParagraphArrowDropDownUp,
  CustomLabel,
  CustomSmall,
  CardContentWrapper,
  CustomMainData,
  StyledLabel,
  CardItem,
  ArrowGrid
} from '../../styles.v2';

import { handleOpenNewTabv3 } from '../handleGetTabFilterHook';

import { HelpIcon } from '../../../../components/HelpIcon.component';

import formHelper from '../../../../utils/formHelper';

import { translateStatusLabels } from '../../services/dashboardServices';

const factoryColumnData = obj => {
  if (obj) {
    let list = [];
    const keys = Object?.keys(obj);
    const values = Object?.values(obj);
    for (let i = 0; i < keys.length; i++) {
      list.push({ label: keys[i], value: values[i], status: keys[i] });
    }

    return list;
  }
};

const HeaderData = ({
  label,
  data,
  showDivider,
  type,
  status,
  isTeamView,
  cardName
}) => {
  const dashboardFilter = useSelector(
    state => state.presaleOfflineDashboardFilterReducer
  );
  const withDrillDown = dashboardFilter?.marketplace?.length === 1 || false;

  return (
    <div
      style={{ minWidth: '120px', display: 'flex', justifyContent: 'center' }}
    >
      <MainCardData>
        <StyledLabel>
          <span>{label}</span>
        </StyledLabel>
        {withDrillDown ? (
          <CustomTypographLink
            fontSize="30px"
            fontWeight="500"
            onClick={() => {
              handleOpenNewTabv3({
                dashboardFilter,
                type,
                status,
                isTeamView,
                cardName
              });
            }}
          >
            {_.isNaN(data) ? 0 : data}
          </CustomTypographLink>
        ) : (
          <CustomMainData fontSize="30px">{data}</CustomMainData>
        )}

        {showDivider && (
          <CustomParagraphArrowDropDownUp showDivider={showDivider} />
        )}
      </MainCardData>
    </div>
  );
};

const ColumnData = ({
  columnData,
  showDivider,
  percent,
  isTeamView,
  userName,
  withDrillDown,
  showColumnHeaderData,
  cardName
}) => {
  const dashboardFilter = useSelector(
    state => state.presaleOfflineDashboardFilterReducer
  );
  const { data } = columnData;
  const dataList = factoryColumnData(data);

  return (
    <Box style={{ width: '100%' }}>
      {showColumnHeaderData && (
        <>
          <MainCardData>
            {!isTeamView ? (
              <>
                <CustomLabel marginBottom={'11px'}>
                  {columnData.label}
                </CustomLabel>
                <div>
                  <CustomTypograph>Atribuido à {userName}</CustomTypograph>
                </div>
              </>
            ) : (
              <CustomLabel>{columnData.label}</CustomLabel>
            )}

            {withDrillDown ? (
              <CustomTypographLink
                fontSize="30px"
                fontWeight="500"
                onClick={() => {
                  handleOpenNewTabv3({
                    dashboardFilter,
                    status: columnData.label,
                    isTeamView,
                    cardName
                  });
                }}
              >
                {formHelper.addZeroToCompleteTwoDigits(columnData.total)}
              </CustomTypographLink>
            ) : (
              <CustomMainData fontSize="30px">
                {formHelper.addZeroToCompleteTwoDigits(columnData.total)}
              </CustomMainData>
            )}
          </MainCardData>
        </>
      )}

      {showDivider && (
        <>
          <ArrowGrid>
            <CustomParagraphArrowDropDownUp showDivider />
          </ArrowGrid>
          <Divider />
        </>
      )}

      {dataList?.length > 0 && (
        <Box>
          <Box display={'flex'} flexDirection="column" alignItems="start">
            {dataList?.map(item => (
              <CardItem key={Math.random()}>
                <CustomTypographLink
                  onClick={() => {
                    handleOpenNewTabv3({
                      dashboardFilter,
                      status: item.label,
                      isTeamView,
                      cardName
                    });
                  }}
                  minwidth={'50%'}
                  textalign={'right'}
                  fontSize={'24px'}
                  lineheight={'28.13px'}
                >
                  {!percent
                    ? formHelper.addZeroToCompleteTwoDigits(item.value)
                    : item.value + '%'}
                </CustomTypographLink>
                <CustomSmall style={{ minWidth: '50%' }}>
                  {translateStatusLabels(item.label)}
                </CustomSmall>
              </CardItem>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const PresaleOfflineCardList = ({
  cardType,
  firstLabel,
  secondaryLabel,
  isTeamView,
  headerData,
  showMoreDetails,
  extraDataColumn1,
  extraDataColumn2,
  firstData,
  secondaryData,
  descriptionHint,
  cancelling,
  percent,
  userName,
  withDrillDown,
  showMarketplaceDetails,
  showColumnHeaderData,
  cardName
}) => {
  const { t } = useTranslation();

  const dashboardFilter = useSelector(
    state => state.presaleOfflineDashboardFilterReducer
  );

  firstData = firstData < 10 ? `0${firstData}` : firstData;
  secondaryData = secondaryData < 10 ? `0${secondaryData}` : secondaryData;

  const title = (cardType, dashboardFilter) => {
    switch (cardType) {
      case 'qtdNewProtocols':
        return (
          <p>
            Quantidade de <b>Perguntas Novas</b> não atribuidas
          </p>
        );
      case 'answers':
        return (
          <p>
            <b>Tempo médio</b> de respostas
          </p>
        );
      case 'qtdProtocols':
        return (
          <p>
            Quantidade total de <b>Perguntas</b>
          </p>
        );
      case 'qtdAttendantProtocols':
        return (
          <p>
            Quantidade de <b>Perguntas</b> atribuidas à{' '}
            {dashboardFilter?.attendant}
          </p>
        );
      default:
        return '';
    }
  };

  const cardStatus = () => {
    switch (cardType) {
      case 'qtdNewProtocols':
        return 'NEW';
      default:
        return '';
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Card
        style={{ width: '100%', marginBottom: '25px', borderRadius: '20px' }}
      >
        <CardTitle>
          <CustomTypograph variant="h5">
            {title(cardType, dashboardFilter)}
          </CustomTypograph>
          <Box style={{ gap: '12px' }} display="flex" justifyContent="flex-end">
            {descriptionHint && (
              <HelpIcon
                tooltip={<CustomHintText>{descriptionHint}</CustomHintText>}
                arrow
                placement="top"
              />
            )}
          </Box>
        </CardTitle>
        <Divider />

        <CardContentWrapper>
          {headerData && (
            <Box
              style={{
                marginBottom:
                  showMarketplaceDetails && showColumnHeaderData && '22px'
              }}
            >
              <Box
                display={'flex'}
                justifyContent={'space-around'}
                style={{ gap: '22px' }}
              >
                {!isTeamView && (
                  <HeaderData
                    showDivider={showMarketplaceDetails && showColumnHeaderData}
                    label={firstLabel}
                    data={firstData}
                    {...{
                      withDrillDown,
                      type: 'individual',
                      status: cardStatus(),
                      isTeamView,
                      cardName
                    }}
                  />
                )}
                <HeaderData
                  showDivider={
                    isTeamView && showMarketplaceDetails && showColumnHeaderData
                  }
                  label={secondaryLabel}
                  data={secondaryData}
                  {...{
                    withDrillDown,
                    type: 'equip',
                    status: cardStatus(),
                    isTeamView,
                    cardName
                  }}
                />
              </Box>
              {showMarketplaceDetails && showColumnHeaderData && <Divider />}
            </Box>
          )}

          {showMarketplaceDetails && (
            <Box
              display={'flex'}
              justifyContent={'space-around'}
              style={{ gap: '22px' }}
            >
              {extraDataColumn1?.show && (
                <ColumnData
                  columnData={extraDataColumn1}
                  showDivider={showMoreDetails}
                  {...{
                    percent,
                    isTeamView,
                    userName,
                    withDrillDown,
                    showColumnHeaderData,
                    cardName
                  }}
                />
              )}

              {extraDataColumn2?.show && (
                <ColumnData
                  columnData={extraDataColumn2}
                  showDivider={showMoreDetails}
                  {...{
                    percent,
                    isTeamView,
                    userName,
                    withDrillDown,
                    showColumnHeaderData,
                    cardName
                  }}
                />
              )}
            </Box>
          )}
        </CardContentWrapper>
      </Card>
    </div>
  );
};

export { PresaleOfflineCardList };
