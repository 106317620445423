import React, { useState, useEffect } from 'react';
import { Grid, Tooltip, CircularProgress, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import SaveIcon from '@material-ui/icons/Save';
// import { Warning } from '@material-ui/icons';
import Warning from '../../../../icons/Warning';
import API from '../../../../services/api';
import {
  SectionTabs,
  SectionTitle,
  SectionTitleWarning,
  SectionContent,
  SectionContentWrapper,
  SectionContentTitle,
  SectionText,
  ClientAlert,
  AlertIcon,
  SectionLink,
  TicketAlertIcons,
  SmallButton,
  CustomTextField
} from './SectionTabs';

import formHelper from '../../../../utils/formHelper';
import marketPlacesEnum from '../../../../enums/marketPlaces.enum';

import userService from '../../../../services/user.service';
import { isB2BProtocol } from '../../../../utils/b2bHelper';

const CustomerTabDetails = ({
  handleHaveReclameAqui,
  handleCPFSearch,
  handleSearchCode,
  handleSearchCAP,
  orderData,
  dataClient,
  productInfo,
  vipStatus,
  loadingVip,
  setLoadingVip,
  setVip,
  data,
  aftersaleMlWithoutOrder
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [CAPData, setCAPData] = useState({});
  const [trackingReturn, setTrackingReturn] = useState({});
  const [vipReason, setVipReason] = useState(data?.vipReason || '');
  const [showVipReason, setShowVipReason] = useState(false);

  const claimId =
    data?.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity?.claim_id;

  const fetchCAPData = async () => {
    const response = await API.get(
      `/auth/ms-ticket/v1/reverse-logistics/track-reverse-post?pedidoColetaId=${data?.reverseLogisticsEntity?.numeroColeta}`
    );
    setCAPData(response.data);
  };
  useEffect(() => {
    if (data?.reverseLogisticsEntity?.numeroColeta) fetchCAPData();
  }, [data]);

  const getReturnsDataFromML = async () => {
    if (
      data?.marketPlace === 'MERCADO_LIVRE' &&
      data?.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity?.type ===
        'returns'
    ) {
      try {
        const response = await API.get(
          `/auth/ms-ticket/v1/tickets/mercadolivre/get-claim-tracking/${claimId}`
        );
        setTrackingReturn(response.data);
      } catch (error) {
        enqueueSnackbar(t('i18n.ticketcontainer.ML_RETURNS_ERROR'), {
          variant: 'error'
        });
      }
    }
  };
  useEffect(() => {
    getReturnsDataFromML();
  }, []);

  const customerName = dataClient?.nome || data?.customerName;
  const customerAddress = dataClient?.endereco
    ? `${dataClient.endereco} ${dataClient.numero}`
    : null;

  const customerPhone =
    dataClient?.telefone ||
    data?.customerPhone ||
    t('i18n.customerinfo.NOT_PROVIDED');

  const customerVipClient = vipStatus;
  const customerMlAlert =
    data.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity;
  const customerMagaluAlert = data.marketplaceBundle?.reason === 'Reclamação';
  const customerRedAlert = !!(
    customerMlAlert ||
    customerVipClient ||
    customerMagaluAlert
  );

  const customerDeliveryDate = orderData?.dataEntrega
    ? moment(orderData?.dataEntrega).format('DD/MM/YYYY')
    : null;
  const customerExpeditionDate = orderData?.dataExpedicao
    ? moment(orderData?.dataExpedicao).format('DD/MM/YYYY')
    : null;

  const handleVipReason = () => {
    return (
      <Tooltip
        title={t('i18n.customerinfo.VIP_SAVE')}
        PopperProps={{
          disablePortal: true,
          popperOptions: {
            positionFixed: true,
            modifiers: {
              preventOverflow: {
                enabled: true,
                boundariesElement: 'window'
              }
            }
          }
        }}
      >
        <SmallButton
          style={{ marginInlineStart: '5px', marginBlockStart: '6px' }}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            setLoadingVip(true);
            setVip(true, vipReason);
          }}
        >
          <SaveIcon size={20} />
        </SmallButton>
      </Tooltip>
    );
  };

  const handleAlert = () => {
    return vipStatus ? (
      <AlertIcon
        onClick={() => {
          setLoadingVip(true);
          setShowVipReason(false);
          setVipReason('');
          setVip(false);
        }}
        data-tooltip={t('i18n.ticketcontainer.CLIENT_BL_DEACTIVATE')}
        redAlert={vipStatus}
      >
        {loadingVip ? (
          <CircularProgress size={20} style={{ marginBlockEnd: '15px' }} />
        ) : (
          '*'
        )}
      </AlertIcon>
    ) : (
      <AlertIcon
        onClick={() => {
          setShowVipReason(true);
        }}
        data-tooltip={t('i18n.ticketcontainer.CLIENT_BL_ACTIVATE')}
        redAlert={vipStatus}
      >
        {loadingVip ? (
          <CircularProgress size={20} style={{ marginBlockEnd: '15px' }} />
        ) : (
          '*'
        )}
      </AlertIcon>
    );
  };

  const getProductLink = () => {
    const [, id] = productInfo?.id.split('MLB');
    const titleFormated = productInfo.title.replaceAll(' ', '-');
    return `https://produto.mercadolivre.com.br/MLB-${id}-${titleFormated}`;
  };

  const translateStatus = statusML => {
    switch (statusML) {
      case 'opened':
        return 'Iniciado processo';
      case 'shipped':
        return 'Postado';
      case 'closed':
        return 'Reembolso realizado';
      case 'delivered':
        return 'Entrega realizada';
      case 'cancelled':
        return 'Cancelado pelo comprador';
      case 'expired':
        return 'Expirado prazo para envio';
      default:
        return statusML;
    }
  };

  const fraudListKeys = data?.historicCancellations
    ? Object.keys(data?.historicCancellations)
    : ['cancellations', 'exchanges'];

  const renderFraudAlert = (ticketType, quantity, show) => {
    const tooltipMessage = () => {
      if (quantity === 3) {
        return `ATENÇÃO: esta é a 3ª solicitação de ${ticketType} deste mesmo CPF/CNPJ nos últimos 12 meses`;
      }

      if (quantity > 3) {
        return `ATENÇÃO: existem 3 ou mais solicitações de ${ticketType} deste mesmo CPF/CNPJ nos últimos 12 meses`;
      }

      return '';
    };

    if (quantity >= 3 && show)
      return (
        <Tooltip title={tooltipMessage()} key={Math.random()}>
          <span>
            <Warning key={Math.random()} />
          </span>
        </Tooltip>
      );
  };

  return (
    <>
      <SectionTabs>
        <SectionTitle>
          {t('i18n.ticketcontainer.CLIENT_DATA')}
          {data.marketPlace === 'MERCADO_LIVRE' && aftersaleMlWithoutOrder && (
            <SectionTitleWarning>
              {`* `}
              {t('i18n.ticketcontainer.ML_ORDER_WARNING')}
            </SectionTitleWarning>
          )}
        </SectionTitle>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.CLIENT_NAME')}:
              </SectionContentTitle>
              <SectionContent>
                <ClientAlert redAlert={customerRedAlert}>
                  {userService.hasRole('vip_new') && handleAlert()}
                  <SectionText alert={customerRedAlert} bold>
                    {customerName}
                  </SectionText>
                  {(customerMlAlert || customerMagaluAlert) && (
                    <TicketAlertIcons
                      data-tooltip={
                        customerMlAlert
                          ? t('i18n.ticketcontainer.COMPLAINT_RA')
                          : t('i18n.ticketcontainer.COMPLAINT_MAGALU')
                      }
                    >
                      !
                    </TicketAlertIcons>
                  )}
                </ClientAlert>
                {isB2BProtocol(data) && (
                  <SectionText mb>{handleHaveReclameAqui()}</SectionText>
                )}
              </SectionContent>
              {(showVipReason || vipStatus) && (
                <>
                  <CustomTextField
                    size="small"
                    variant="outlined"
                    label={t('i18n.customerinfo.VIP_REASON')}
                    value={vipReason}
                    onChange={event => {
                      setVipReason(event.target.value);
                    }}
                    inputProps={{ maxLength: 20 }}
                    helperText={`${vipReason.length}/20`}
                  />
                  {handleVipReason()}
                </>
              )}
            </SectionContentWrapper>
          </Grid>

          <Grid item lg={6} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.CLIENT_EMAIL')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold style={{ wordBreak: 'break-all' }}>
                  {data?.customerEmail || t('i18n.customerinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={6} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>CPF/CNPJ:</SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  <Box display="flex" alignItems="center" component="span">
                    {formHelper.cpfCnpjFormat(dataClient?.documento) ||
                      formHelper.cpfCnpjFormat(data?.customerCPF) ||
                      t('i18n.customerinfo.NOT_PROVIDED')}{' '}
                    {handleCPFSearch()}
                    {fraudListKeys?.map(item => {
                      const ticketType =
                        item === 'cancellations' ? 'Cancelamento' : 'Troca';

                      const show = data?.reasonNew?.name === ticketType;

                      return renderFraudAlert(
                        ticketType,
                        data?.historicCancellations
                          ? data?.historicCancellations[item]?.length
                          : 0,
                        show
                      );
                    })}
                  </Box>
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.customerinfo.ADDRESS')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText>
                  {customerAddress || t('i18n.customerinfo.NOT_PROVIDED')}{' '}
                  {dataClient?.complemento && <br />}
                  {dataClient?.complemento && dataClient?.complemento}
                  {dataClient?.bairro && <br />}
                  {dataClient?.bairro && dataClient?.bairro}
                  {dataClient?.referencia && <br />}
                  {dataClient?.referencia && dataClient?.referencia}
                  <br /> {t('i18n.customerinfo.ZIP_CODE')}:{' '}
                  {dataClient?.cep || t('i18n.customerinfo.NOT_PROVIDED')} |{' '}
                  {dataClient?.cidade || t('i18n.customerinfo.NOT_PROVIDED')} |{' '}
                  {dataClient?.uf || t('i18n.customerinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={6} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.customerinfo.PHONE_NUMBER')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {customerPhone}
                  {dataClient?.celular && <br />}
                  {dataClient?.celular && dataClient?.celular}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
        </Grid>
      </SectionTabs>
      {data.ticketType !== 'PRE_SALE' && (
        <>
          <SectionTabs>
            <SectionTitle>
              {t('i18n.ticketcontainer.DELIVERY_DATA')}
            </SectionTitle>
            <Grid container spacing={2}>
              <Grid item lg={6} xs={12}>
                <SectionContentWrapper>
                  <SectionContentTitle>
                    {t('i18n.trackingmodal.TRACKING_CODE')}:
                  </SectionContentTitle>
                  <SectionContent>
                    <SectionText bold>
                      {orderData?.rastro || t('i18n.customerinfo.NOT_PROVIDED')}{' '}
                      {handleSearchCode(orderData?.rastro || '')}
                    </SectionText>
                  </SectionContent>
                </SectionContentWrapper>
              </Grid>
              <Grid item lg={6} xs={12}>
                <SectionContentWrapper>
                  <SectionContentTitle>
                    {t('i18n.ticketcontainer.SHIPPING_DATE')}:
                  </SectionContentTitle>
                  <SectionContent>
                    <SectionText bold>
                      {customerExpeditionDate ||
                        t('i18n.customerinfo.NOT_PROVIDED')}
                    </SectionText>
                  </SectionContent>
                </SectionContentWrapper>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item lg={6} xs={12}>
                <SectionContentWrapper>
                  <SectionContentTitle>
                    {t('i18n.customerinfo.SHIPPING_COMPANY')}:
                  </SectionContentTitle>
                  <SectionContent>
                    <SectionText bold>
                      {orderData?.transportadora ||
                        t('i18n.customerinfo.NOT_PROVIDED')}
                    </SectionText>
                  </SectionContent>
                </SectionContentWrapper>
              </Grid>
              <Grid item lg={6} xs={12}>
                <SectionContentWrapper>
                  <SectionContentTitle>
                    {t('i18n.customerinfo.DELIVERY_COST')}:
                  </SectionContentTitle>
                  <SectionContent>
                    <SectionText bold>
                      {orderData?.totalFrete?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                      }) || 'R$ 0,00'}
                    </SectionText>
                  </SectionContent>
                </SectionContentWrapper>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item lg={6} xs={12}>
                <SectionContentWrapper>
                  <SectionContentTitle>
                    {t('i18n.ticketcontainer.DELIVERY_STATUS')}:
                  </SectionContentTitle>
                  <SectionContent>
                    <SectionText bold>
                      {orderData?.situacaoRastro ||
                        t('i18n.customerinfo.NOT_PROVIDED')}
                    </SectionText>
                  </SectionContent>
                </SectionContentWrapper>
              </Grid>
              <Grid item lg={6} xs={12}>
                <SectionContentWrapper>
                  <SectionContentTitle>
                    {t('i18n.ticketcontainer.REAL_DATE_DELIVERY')}:
                  </SectionContentTitle>
                  <SectionContent>
                    <SectionText bold>
                      {' '}
                      {customerDeliveryDate ||
                        t('i18n.customerinfo.NOT_PROVIDED')}
                    </SectionText>
                  </SectionContent>
                </SectionContentWrapper>
              </Grid>
            </Grid>
          </SectionTabs>
          {/**
           * Comentada a seção que exibe o cap dentro do ticket, uma vez que, conforme conversa com a Jéssica em 27/06/2022
           * conduzida pela Mariana o CAP ficará exclusivamente dentro do Cancelamento e Troca. Comentei esta parte por ainda
           * não termos informações de UX sobre a redefinição da tela de detalhes do protocolo, caso necessário basta descomentar.
           *
           * Retornando com a parte de exibição do CAP em 21/007/2022, uma vez que não há ainda a definição correta de como isto ira funcionar
           */}
          {data?.marketPlace !== marketPlacesEnum.MERCADO_LIVRE && (
            <SectionTabs>
              <SectionTitle>CAP</SectionTitle>
              <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                  <SectionContentWrapper>
                    <SectionContentTitle>
                      {t('i18n.ticketcontainer.CAP_CODE')}:
                    </SectionContentTitle>
                    <SectionContent>
                      <SectionText bold>
                        {data?.reverseLogisticsEntity?.numeroColeta ||
                          t('i18n.customerinfo.NOT_PROVIDED')}{' '}
                        {handleSearchCAP()}
                      </SectionText>
                    </SectionContent>
                  </SectionContentWrapper>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <SectionContentWrapper>
                    <SectionContentTitle>
                      {' '}
                      {t('i18n.ticketcontainer.CAP_CREATION_DATE')}:
                    </SectionContentTitle>
                    <SectionContent>
                      <SectionText bold>
                        {data?.reverseLogisticsEntity?.dataSolicitacao ||
                          t('i18n.customerinfo.NOT_PROVIDED')}{' '}
                      </SectionText>
                    </SectionContent>
                  </SectionContentWrapper>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <SectionContentWrapper>
                    <SectionContentTitle>
                      {t('i18n.ticketcontainer.CAP_TRACKING_CODE')}:
                    </SectionContentTitle>
                    <SectionContent>
                      <SectionText bold>
                        {CAPData?.objeto?.numero_etiqueta ||
                          t('i18n.customerinfo.NOT_PROVIDED')}{' '}
                      </SectionText>
                    </SectionContent>
                  </SectionContentWrapper>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <SectionContentWrapper>
                    <SectionContentTitle>
                      {t('i18n.ticketcontainer.CAP_DEADLINE')}:
                    </SectionContentTitle>
                    <SectionContent>
                      <SectionText bold>
                        {data?.reverseLogisticsEntity?.prazo ||
                          t('i18n.customerinfo.NOT_PROVIDED')}{' '}
                      </SectionText>
                    </SectionContent>
                  </SectionContentWrapper>
                </Grid>
              </Grid>
            </SectionTabs>
          )}
          {data?.marketPlace === marketPlacesEnum.MERCADO_LIVRE &&
            data?.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity?.type ===
              'returns' && (
              <SectionTabs>
                <SectionTitle>
                  {t('i18n.ticketcontainer.ML_RETURNS_TITLE')}
                </SectionTitle>
                <Grid container spacing={2}>
                  <Grid item lg={6} xs={12}>
                    <SectionContentWrapper>
                      <SectionContentTitle>
                        {t('i18n.trackingmodal.TRACKING_CODE')}:
                      </SectionContentTitle>
                      <SectionContent>
                        <SectionText bold>
                          {trackingReturn?.shipping?.tracking_number ||
                            t('i18n.customerinfo.NOT_PROVIDED')}{' '}
                          {handleSearchCode(
                            trackingReturn?.shipping?.tracking_number || ''
                          )}
                        </SectionText>
                      </SectionContent>
                    </SectionContentWrapper>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <SectionContentWrapper>
                      <SectionContentTitle>
                        {t('i18n.ticketcontainer.ML_RETURNS_ESTIMATED_DATE')}:
                      </SectionContentTitle>
                      <SectionContent>
                        <SectionText bold>
                          {(trackingReturn?.shipping?.estimated_delivery_time &&
                            moment(
                              trackingReturn?.shipping?.estimated_delivery_time
                            ).format('DD/MM/YYYY')) ||
                            t('i18n.customerinfo.NOT_PROVIDED')}
                        </SectionText>
                      </SectionContent>
                    </SectionContentWrapper>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={6} xs={12}>
                    <SectionContentWrapper>
                      <SectionContentTitle>
                        {t('i18n.ticketcontainer.ML_RETURNS_ACTUAL_STATUS')}:
                      </SectionContentTitle>
                      <SectionContent>
                        <SectionText bold>
                          {translateStatus(trackingReturn?.shipping?.status) ||
                            t('i18n.customerinfo.NOT_PROVIDED')}
                        </SectionText>
                      </SectionContent>
                    </SectionContentWrapper>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <SectionContentWrapper>
                      <SectionContentTitle>
                        {t('i18n.ticketcontainer.ML_RETURNS_ACTUAL_DATE')}:
                      </SectionContentTitle>
                      <SectionContent>
                        <SectionText bold>
                          {' '}
                          {(trackingReturn?.shipping?.last_updated &&
                            moment(
                              trackingReturn?.shipping?.last_updated
                            ).format('DD/MM/YYYY')) ||
                            t('i18n.customerinfo.NOT_PROVIDED')}
                        </SectionText>
                      </SectionContent>
                    </SectionContentWrapper>
                  </Grid>
                </Grid>
              </SectionTabs>
            )}
        </>
      )}

      {data.ticketType === 'PRE_SALE' && (
        <>
          <SectionTabs>
            <SectionTitle>
              {t('i18n.ticketcontainer.DETAILS')}{' '}
              {data?.ticketType !== 'PRE_SALE' ? 'do Pedido' : 'do Produto'}
            </SectionTitle>
            <Grid container spacing={2}>
              {data.marketPlace === 'MERCADO_LIVRE' && (
                <Grid item lg={8} xs={12}>
                  <SectionContentWrapper>
                    <SectionContentTitle>
                      {t('i18n.ticketcontainer.PRODUCT')}:
                    </SectionContentTitle>
                    <SectionContent>
                      <SectionLink
                        bold
                        target="_blank"
                        rel="noreferrer"
                        href={getProductLink()}
                      >
                        {productInfo.title}
                      </SectionLink>
                    </SectionContent>
                  </SectionContentWrapper>
                </Grid>
              )}
              <Grid item lg={4} xs={12}>
                <SectionContentWrapper>
                  <SectionContentTitle>
                    {t('i18n.newAutomation.VALUE')}:
                  </SectionContentTitle>
                  <SectionContent>
                    <SectionText bold>
                      {productInfo?.price.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                      })}
                    </SectionText>
                  </SectionContent>
                </SectionContentWrapper>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={8} xs={12}>
                <SectionContentWrapper>
                  <SectionContentTitle>PN:</SectionContentTitle>
                  <SectionContent>
                    <SectionText bold>{productInfo?.id}</SectionText>
                  </SectionContent>
                </SectionContentWrapper>
              </Grid>
              <Grid item lg={4} xs={12}>
                <SectionContentWrapper>
                  <SectionContentTitle>
                    {t('i18n.ticketcontainer.STOCK')}
                  </SectionContentTitle>
                  <SectionContent>
                    <SectionText bold>
                      {productInfo?.available_quantity}
                    </SectionText>
                  </SectionContent>
                </SectionContentWrapper>
              </Grid>
            </Grid>
          </SectionTabs>
        </>
      )}
    </>
  );
};

export default CustomerTabDetails;
