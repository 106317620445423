import API from '../../../../services/api';

const handleGetNotification = async chat => {
  let lastAdminMessage;
  let messageCount = 0;

  const clientHistory = await API.get(
    `no-auth/ms-chat/existing-chat/${chat.hashConnection}`
  );

  // if (chat?.messages) { // get notifications by messages in websocket
  if (clientHistory.data.messages) {
    // get notification by messages in historic
    const revertMessages = clientHistory.data.messages
      ?.map(item => item)
      .reverse();
    while (!lastAdminMessage && messageCount !== revertMessages.length) {
      if (!revertMessages[messageCount]?.login) {
        messageCount += 1;
      } else {
        lastAdminMessage = true;
      }
    }
  }

  return { messageCount, read: clientHistory.data.read };
};

const searchB2bOnCurrentUrl = () => {
  const urlWindow = window.location.href;
  if (urlWindow.search('b2b') > -1) {
    return true;
  }
  return false;
};

export { handleGetNotification, searchB2bOnCurrentUrl };
