import { Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SectionContent,
  SectionContentTitle,
  SectionContentWrapper,
  SectionTabs,
  SectionText,
  SmallButton
} from '../../../../views/tickets/TicketContainer/TicketComponents/SectionTabs';

const OrderPaymentTabDetails = ({ orderData, handleClickOpenInvoice }) => {
  const { t } = useTranslation();

  const dataCriacaoFormatada =
    orderData?.dataCriacao &&
    moment(orderData?.dataCriacao).format('DD/MM/YYYY');

  const dataNFFormatada = moment(orderData?.dataNF).format('DD/MM/YYYY');

  return (
    <>
      <SectionTabs>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.ORDER_NUMBER')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {orderData?.idPedMarket || t('i18n.orderinfo.NOT_PROVIDED')}{' '}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.ACCOMPLISHED_IN')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {dataCriacaoFormatada || t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.orderinfo.SITUATION')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {orderData?.situacao
                    ? orderData?.situacao
                    : t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.ORDER_NUMBER')}{' '}
                {t('i18n.ticketcontainer.ECOMMERCE')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {orderData?.idMarketplace
                    ? orderData?.idMarketplace
                    : t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.orderinfo.ORDER')} Sales Order:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {orderData?.idPedidoERP || t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>PEDECOM:</SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {orderData?.idPedEcom || t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.NF_NUMBER')}
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {orderData?.numeroNF || t('i18n.orderinfo.NOT_PROVIDED')}{' '}
                  {/* {handleSearchNF()} */}
                  <SmallButton
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleClickOpenInvoice}
                  >
                    <SearchIcon />
                  </SmallButton>
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={8} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.DISPATCHED_IN')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {orderData?.dataNF
                    ? dataNFFormatada
                    : t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.PAYMENT_TYPE')}:
              </SectionContentTitle>
              <SectionContent>
                {orderData?.pagamentos?.map(pagamento => {
                  return (
                    <SectionText
                      mb
                      bold
                      key={`${pagamento.bandeira}${pagamento.parcelas}${pagamento.valorPago}`}
                    >
                      {' '}
                      {pagamento?.metodo || t('i18n.orderinfo.NOT_PROVIDED')}
                    </SectionText>
                  );
                })}
                {(!orderData?.pagamentos ||
                  orderData?.pagamentos?.length === 0) && (
                  <SectionText>{t('i18n.orderinfo.NOT_PROVIDED')}</SectionText>
                )}
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.AMOUNT_PARCEL')}:
              </SectionContentTitle>
              <SectionContent>
                {orderData?.pagamentos?.map(pagamento => {
                  return (
                    <SectionText mb bold key={pagamento.parcelas}>
                      {' '}
                      {pagamento.parcelas === 1
                        ? `${pagamento.parcelas} parcela`
                        : `${pagamento.parcelas} parcelas` ||
                          t('i18n.orderinfo.NOT_PROVIDED')}
                    </SectionText>
                  );
                })}
                {(!orderData?.pagamentos ||
                  orderData?.pagamentos?.length === 0) && (
                  <SectionText>{t('i18n.orderinfo.NOT_PROVIDED')}</SectionText>
                )}
                <SectionText bold>{}</SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.DISCOUNT_COUPON')}:
              </SectionContentTitle>
              <SectionContent>
                {orderData?.cuponsDesconto?.map(cupom => {
                  return (
                    <SectionText mb bold key={cupom.cupom}>
                      {' '}
                      {cupom.cupom || t('i18n.orderinfo.NOT_PROVIDED')}
                    </SectionText>
                  );
                })}
                {(!orderData?.cuponsDesconto ||
                  orderData?.cuponsDesconto?.length === 0) && (
                  <SectionText>{t('i18n.orderinfo.NOT_PROVIDED')}</SectionText>
                )}
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
        </Grid>
      </SectionTabs>
    </>
  );
};

export default OrderPaymentTabDetails;
