import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { ModalCustomerHistoricContext } from './context';

import API from '../../../../../services/api';
import { useMarketplaceListValue } from '../../../../../hooks/hooks';
import AcerModal from '../../../../../components/AcerModal';

import FilterDrawer from './filterDrawer.component';
import DataTableComponent from './datatable.component';

import { TicketContainerContext } from '../../context';

const CustomModal = styled(AcerModal)`
  .MuiDialog-paper {
    display: none;
    overflow-y: hidden !important;
  }

  .MuiDialogTitle-root {
    display: none;
  }
`;

const ModalCustomerHistoric = ({ open, onClose, customerCpf }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setCountTickets } = useContext(TicketContainerContext);

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(0);
  const [filter, setFilter] = useState({});
  const marketPlace = useMarketplaceListValue();

  const [sortData, setSortData] = useState({
    column: 'vipClient',
    direction: 'none',
  });

  const sortTableData = tableState => {
    const column =
      tableState.sortOrder.name === 'sla_hoursSinceCreation'
        ? 'controlTimeSLA'
        : tableState.sortOrder.name;
    const { direction } = tableState.sortOrder;
    const newSortData = { ...sortData };
    newSortData.column = column;
    newSortData.direction = direction;
    setSortData(newSortData);
  };

  const refreshTableContent = async page => {
    setLoading(true);
    setCurrentPage(page);

    const sortingParameters =
      sortData?.direction !== 'none'
        ? `&sort=${sortData?.column},${sortData?.direction}`
        : '';

    const customerFilter = {
      ...filter,
      ...{ customerCpf, marketPlace, ticketType: 'AFTER_SALE' },
    };
    try {
      const response = await API.post(
        `/auth/ms-ticket/v1/tickets/?page=${page}${sortingParameters}`,
        customerFilter,
      );
      const mData = response.data;
      const content = mData?._embedded;

      setData(content?.ticketResourceList);
      setCountTickets(content?.ticketResourceList.length);
      setTotalElements(mData?.page?.totalElements);
      setTotalPages(mData?.page?.totalPages);
      setSize(mData?.page?.size);
    } catch (error) {
      enqueueSnackbar(t(error.response.data.key), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const context = {
    loading,
    filter,
    setFilter,
    data,
    currentPage,
    totalPages,
    totalElements,
    size,
    sortTableData,
    refreshTableContent,
    sortData,
    setSortData,
  };

  useEffect(() => {
    refreshTableContent(0);
  }, [sortData]);

  useEffect(() => {
    if (Object.keys(filter).length > 0) refreshTableContent(0);
  }, [filter]);

  return (
    <CustomModal
      fullWidth
      maxWidth="xl"
      open={open}
      scroll="body"
      modalTitle={t('i18n.ticketcontainer.protocol_historic')}
      onClose={onClose}
    >
      <ModalCustomerHistoricContext.Provider value={context}>
        <div style={{ display: 'flex' }}>
          <div style={{ position: 'relative', minWidth: '60px' }}>
            <FilterDrawer />
          </div>
          <div style={{ minHeight: '450px', width: '100%' }}>
            <DataTableComponent />
          </div>
        </div>
      </ModalCustomerHistoricContext.Provider>
    </CustomModal>
  );
};

export default ModalCustomerHistoric;
