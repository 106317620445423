import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AcerModal from '../../../components/AcerModal';

const StyledDialogActions = styled(DialogActions)`
  margin-top: 1.5em;
`;

const ModalMagaluNewTicket = ({
  openState,
  closeParent,
  submitConfirmation
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(openState);
  const [protocolType, setProtocolType] = useState('');
  const [orderId, setOrderId] = useState('');

  const typesProtocol = [
    { type: 'mktplace-manuseio', name: 'Atraso na entrega' },
    { type: 'mktplace-cancelamento-de-pedido', name: 'Cancelamento' },
    { type: 'mktplace-duvidas-gerais', name: 'Dúvidas gerais' },
    { type: 'mktplace-entrega', name: 'Entrega' },
    { type: 'mktplace-pagamento', name: 'Pagamento' },
    { type: 'mktplace-produto', name: 'Produto' },
    { type: 'mktplace-reclamacao', name: 'Reclamação' },
    { type: 'mktplace-troca-de-produto', name: 'Troca' }
  ];

  const handleClose = () => {
    closeParent();
    setOpen(false);
    setProtocolType('');
    setOrderId('');
  };

  useEffect(() => {
    setOpen(openState);
    setProtocolType('');
    setOrderId('');
  }, [openState]);

  return (
    <AcerModal
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={handleClose}
      modalTitle={`${t('i18n.ticketslistview.NEW_GUIDE_TICKET')} ${t(
        'i18n.marketplaces.MAGALU'
      )}`}
    >
      <Grid container direction="column" alignItems="stretch" spacing={2}>
        {/* Loja Mobbibuy desativada */}
        {/* <Grid item lg>
          <FormControl fullWidth variant="outlined" className="product-select">
            <InputLabel>{t('i18n.ticketslistview.STORE')}</InputLabel>
            <Select
              label={t('i18n.newticket.MAGALU_PROTOCOL_TYPE')}
              onChange={event => setStoreSelected(event.target.value)}
              value={storeSelected}
            >
              {stores.map(store => {
                return (
                  <MenuItem key={store.id} value={store.id}>
                    {store.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item lg>
          <TextField
            size="medium"
            value={orderId}
            onChange={event => {
              setOrderId(event.target.value);
            }}
            fullWidth
            label={t('i18n.newticket.MAGALU_ORDERID')}
            variant="outlined"
          />
        </Grid>
        <Grid item lg>
          <FormControl fullWidth variant="outlined" className="product-select">
            <InputLabel>{t('i18n.ticketslistview.TICKET_TYPE')}</InputLabel>
            <Select
              label={t('i18n.newticket.MAGALU_PROTOCOL_TYPE')}
              onChange={event => {
                setProtocolType(event.target.value);
              }}
              value={protocolType}
            >
              {typesProtocol.map(type => {
                return (
                  <MenuItem key={type.type} value={type.type}>
                    {type.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <StyledDialogActions>
        <Button onClick={handleClose} color="primary">
          {t('i18n.simplewords.CANCEL')}
        </Button>
        <Button
          disabled={!orderId || !protocolType}
          onClick={() =>
            submitConfirmation('aceroficial', orderId, protocolType)
          }
          color="primary"
          autoFocus
          variant="contained"
        >
          {t('i18n.simplewords.CONFIRM')}
        </Button>
      </StyledDialogActions>
    </AcerModal>
  );
};

export default ModalMagaluNewTicket;
