import React from 'react';
import styled from 'styled-components';
import { Typography, CardContent, TextField } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { ArrowDropDown, Build } from '@material-ui/icons';
import ArrowDropDownUp from '@material-ui/icons/ArrowDropUp';
import { COLORS } from '../../styles/settings/colors.config';

export const CardDataWrapper = styled('div')`
  display: flex;
  justify-content: ${(props) =>
    props.jusfityContent === true ? 'space-between' : 'center'};
  align-items: start;

  .main-data {
    font-size: 35px;
    font-weight: bold;
    color: #aaa;
    margin-block-start: 5px;
  }
`;

export const CustomArrowDropDownUp = styled(ArrowDropDownUp)`
  color: ${(props) => (props.iconcolor ? props.iconcolor : 'lightgrey')};
  font-size: 55px;
`;

export const CustomArrowDropDown = styled(ArrowDropDown)`
  color: ${(props) => props.iconcolor};
  font-size: 55px;
  margin-top: -22px;
  z-index: 2;
`;

export const CustomParagraphArrowDropDownUp = styled('p')`
  margin-bottom: -42px;
  margin-top: -15px;
`;

export const CardTitle = styled(CardContent)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.custompadding && '16px 24px !important'};
  font-size: ${(props) => props.custompadding && '16px !important'};
  line-height: ${(props) => props.custompadding && '18.75px !important'};
  color: ${(props) => props.custompadding && '#666666 !important'};
`;

export const CustomHelpIcon = styled(HelpOutlineIcon)`
  &:hover {
    color: ${COLORS.darkGreenText};
  }
`;

export const CustomDownloadIcon = styled(CloudDownloadIcon)`
  &:hover {
    color: ${COLORS.darkGreenText};
  }
`;

export const CustomHintText = styled.span`
  padding: 5px 10px 5px 0px;
  color: white;
  font-size: 14px;
  font-family: Roboto;
`;

export const CustomCardContent = styled(CardContent)`
  padding: 15px 60px;
  @media (max-width: 1440px) {
    padding: 15px 22px;
  }
`;

export const CardContentSubtitle = styled(CardContent)`
  padding-block-end: 0px;
`;

export const CustomLineCardContent = styled(CardContent)`
  padding: 0px 60px 0px 60px !important;
  @media (max-width: 1440px) {
    padding: 0px 22px 0px 22px !important;
  }
`;

export const CustomLineCardContentSideBySide = styled(CardContent)`
  padding: 15px 60px 15px 60px !important;
  @media (max-width: 1440px) {
    padding: 15px 22px 15px 22px !important;
  }
`;

export const CustomCardContentNoPadding = styled(CardContent)`
  padding: 0px 10px 15px 0px !important;
  margin-top: 10px !important;
`;

export const CustomTypograph = styled(Typography)`
  color: ${(props) => (props.colortext ? props.colortext : COLORS.grayText)};
  margin-top: ${(props) => (props.margintop ? props.margintop : '0px')};
`;

export const CustomTypographLink = styled(CustomTypograph)`
  cursor: pointer;
  color: ${COLORS.greenText};
`;

export const CustomTypographRed = styled(Typography)`
  color: ${COLORS.btnDangerColor};
`;

export const CustomTypographLowWeight = styled(Typography)`
  color: ${COLORS.grayText};
  font-weight: 400;
`;

export const CardDataDivider = styled('div')`
  height: 30px;
  width: 1px;
  margin: 0px 20px;
  display: block;
  background: ${COLORS.dashboardTeamDataGray};
  margin-top: ${(props) => props.dividerMargin || '35px'};
`;

export const MainCardData = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .label {
    font-size: 12px;
    line-height: 14px;
    font-weight: medium;
  }

  .sla-label-expired {
    font-size: 12px;
    line-height: 14px;
    color: rgb(216, 59, 77);
    font-weight: medium;
  }

  .sla-label-expires {
    font-size: 12px;
    line-height: 14px;
    color: ${COLORS.WarningColorSLA};
    font-weight: medium;
  }

  .main-data {
    font-size: 30px;
    font-weight: 500;
    color: ${COLORS.dashboardTeamDataGray};
  }

  .main-data-stronger {
    margin-top: 5px;
    font-size: 30px;
    font-weight: 500;
    color: ${COLORS.dashboardNoDrilldownItems};
  }
`;

export const SecondaryCardData = styled(MainCardData)`
  .main-data {
    font-size: 30px;
    font-weight: 500;
    color: ${COLORS.dashboardTeamDataGray};
  }
`;

export const LineCardData = styled(MainCardData)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0px;
  }

  .main-data {
    font-size: 35px;
    margin-right: 10px;
    text-align: end;
    width: 25%;
  }

  .label {
    width: 75%;
    margin-bottom: 0px;
    font-size: 85%;
  }
`;

export const LineCardWrapper = styled('div')`
  display: flex;
  justify-content: ${(props) =>
    !props.jusfityContent ? 'center' : 'flex-start'};
`;

const InConstructionStyled = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  // opacity: 0.8;
  z-index: 9;
  background: #eeeeee50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InConstructionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const InConstructionContent = styled('div')`
  background: #f60;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  border-radius: 20px;
  transform: ${(props) => (props.horizontal ? '' : 'rotate(-30deg)')};
  svg {
    color: #fff;
  }
`;

export const CustomChartTitle = styled('div')`
  padding: 30px 0px 0px;
`;

export const CustomTextField = styled(TextField)`
  margin-left: 15px;
`;

export const CustonFilter = styled('div')`
  display: flex;
  align-items: center;
  gap: 2;
`;

export const AlertLabel = styled(Typography)`
  color: ${COLORS.btnDangerColor};
  font-size: 12px;
`;

export const CustonIcon = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  svg {
    font-size: 5.5rem;
    fill: ${COLORS.grayText}60;
  }
`;

export const CustomLabel = styled(Typography)`
  color: ${COLORS.blueText};
  font-weight: bold;
`;

export const CustomSublabel = styled(Typography)`
  color: ${COLORS.grayText}80;
  font-size: 16px;
  font-weight: bold;
`;

export const CustomSmall = styled('p')`
  font-size: 85%;
`;

export const InConstruction = ({ text, horizontal }) => {
  return (
    <InConstructionStyled>
      <InConstructionWrapper>
        <InConstructionContent {...{ horizontal }}>
          <Build />
          <Typography>{text || 'Dados em construção'}</Typography>
        </InConstructionContent>
      </InConstructionWrapper>
    </InConstructionStyled>
  );
};

export const CardTitleTypography = styled(Typography)`
  font-size: 14px;
  color: ${(props) => props.colortext || '#666666'};
  font-weight: bold;
`;
