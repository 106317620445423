import userService from '../../../services/user.service';

export const defaultFilterFactory = () => {
  const userName = userService.getUsername();
  const isAdmin = userService.hasGroup('admin');

  return {
    ip: '',
    hashConnection: '',
    businessType: '',
    clientName: '',
    clientEmail: '',
    blockedByUser: !isAdmin ? userName : '',
    blockedReason: '',
    blockedDate: '',
    unblockedByUser: !isAdmin ? userName : '',
    unblockedReason: '',
    unblockedDate: '',
    blockedLogging: [],
    isBlocked: ''
  };
};
