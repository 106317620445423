import moment from 'moment';
import { defaultDateFormat } from '../services/convertDate';
import { defaultHoursNumber } from '../services/config';
import { defaultMarketplacesDashboardFilter } from '../../../hooks/hooks';

const filterNameInStorage = 'newFilterDashReport';

const reportsTabName = 'reportsDashboardTab';

const defaultFilter = {
  page: 0,
  size: 15,
  // eslint-disable-next-line camelcase
  report_type: '',
  periodType: 'custom',
  marketplace: defaultMarketplacesDashboardFilter,
  startDate: defaultDateFormat(
    moment(new Date())
      .startOf('day')
      .add(3, 'hours')
  ),
  endDate: defaultDateFormat(
    moment(new Date())
      .endOf('day')
      .add(3, 'hours')
  )
};

export {
  defaultHoursNumber,
  defaultFilter,
  filterNameInStorage,
  reportsTabName
};
