import React from 'react';
import { COLORS } from '../../../../../../../styles/settings/colors.config';
import Dot from './DotItemComponents/Dot';
import DotButton from './DotItemComponents/DotButton';
import ItemConnector from './DotItemComponents/ItemConnector';
import ItemLabel from './DotItemComponents/ItemLabel';
import {
  StyledDotContainer,
  StyledDotSpace,
  LabelDate,
  StyledLabelStartTime
} from './styles';

export default function DotItem({
  position,
  date,
  status,
  statusCondition,
  showStatus,
  showTime,
  timePassed,
  showButton,
  buttonActions,
  buttonLabel,
  endProcess,
  showDetailsButton,
  viewModeAction
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <LabelDate>{date}</LabelDate>
      <DotButton {...{ showButton, buttonActions, buttonLabel }} />

      {!date && position !== 'end' && <StyledDotSpace />}

      <StyledDotContainer>
        <Dot {...{ status, statusCondition, endProcess, position }} />
        <ItemConnector {...{ position, statusCondition, endProcess }} />
      </StyledDotContainer>

      <ItemLabel
        {...{
          status,
          statusCondition,
          showDetailsButton,
          showStatus,
          viewModeAction
        }}
      />

      <StyledLabelStartTime
        color={COLORS.timeline.startedDatetime}
        showTime={showTime}
      >
        {position === 'start' ? timePassed : '.'}
      </StyledLabelStartTime>
    </div>
  );
}
