import moment from 'moment';
// import { COLORS } from '../styles/settings/colors.config';

const HOURS = 24;
const MINUTES = 60;
const SECONDS = 60;
const TOTAL_SECONDS_HOUR = MINUTES * SECONDS;
const TOTAL_SECONDS_DAY = TOTAL_SECONDS_HOUR * HOURS;
const MILLISECONDS = 1000;
const MINUTES_LIMIT = 60;
const TOTAL_MINUTES_TWO_DAYS = 2880; // Total of minutes in two days.
const TOTAL_MINUTES_ONE_DAY = 1440; // Total of minutes in one day.
const TOTAL_MINUTES_HALF_DAY = 720; // Total of minutes in half day.
const LIMIT_DAYS_TO_ALERT_OPEN = 7; // Limit number to alert of the open of ticket.

// Exibição no Dashboard do Card Tempo Médio
// const formatMinutes = mins => {
//   const minutes = Math.floor(mins);
//   let h = Math.floor(minutes / 60);
//   let m = minutes % 60;
//   h = h < 10 ? `0${h}` : h;
//   m = m < 10 ? `0${m}` : m;
//   return `${h}h${m}m`;
// };

// Exibição de minutos em dias, horas e minutos na Listagem, Detalhe do Procolo e Dashboard
const formatMinutes = (inputMinutes, destination = '') => {
  let minutesFormated = `${Math.floor(inputMinutes)}m`;
  if (inputMinutes >= MINUTES_LIMIT) {
    const day = Math.floor(inputMinutes / (MINUTES * HOURS));
    const hour = Math.floor((inputMinutes % (MINUTES * HOURS)) / SECONDS);
    const min = Math.floor(inputMinutes % MINUTES);
    const displayDay = day > 0 ? `${day}d` : '';
    const displayHour = hour > 0 ? `${hour}h` : '';
    const displayMin = min > 0 ? `${min}m` : '';
    minutesFormated = `${displayDay} ${displayHour} ${displayMin}`;
  } else if (inputMinutes === 0 && destination === 'protocol') {
    minutesFormated = '';
  }
  return minutesFormated;
};

const setRowStyle = (rowData, index, data) => {
  const row = data[index];

  if (row?.sla_hoursSinceCreation > TOTAL_MINUTES_TWO_DAYS) {
    return {
      borderLeft: `5px solid transparent `,
      paddingLeft: '5px',
      position: 'relative'
    };
  }
  return {
    borderLeft: '1px solid #eee',
    paddingLeft: '9px',
    position: 'relative'
  };
};

const timeDiffCalc = date => {
  const format = 'YYYY-MM-DDTHH:mm:ss';
  const userTimeZone = new Date().getTimezoneOffset();
  const newPastDate = moment
    .utc(date)
    .utcOffset(userTimeZone * -1)
    .format(format);
  const datePast = new Date(newPastDate);
  const dateNow = new Date();

  let diffInMilliSeconds = Math.abs(datePast - dateNow) / MILLISECONDS;

  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * TOTAL_SECONDS_DAY;

  const hours = Math.floor(diffInMilliSeconds / TOTAL_SECONDS_HOUR) % HOURS;
  diffInMilliSeconds -= hours * TOTAL_SECONDS_HOUR;

  const minutes = Math.floor(diffInMilliSeconds / SECONDS) % MINUTES;
  diffInMilliSeconds -= minutes * SECONDS;

  let difference = '';

  if (days > 0) {
    difference += days === 1 ? `${days}d ` : `${days}d `;
    difference += hours === 0 || hours === 1 ? `${hours}h ` : `${hours}h `;
  } else {
    difference += hours === 0 || hours === 1 ? `${hours}h ` : `${hours}h `;
  }

  return { difference, days };
};

export default {
  setRowStyle,
  timeDiffCalc,
  formatMinutes,
  TOTAL_MINUTES_TWO_DAYS,
  TOTAL_MINUTES_ONE_DAY,
  TOTAL_MINUTES_HALF_DAY,
  LIMIT_DAYS_TO_ALERT_OPEN
};
