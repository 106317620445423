import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { COLORS } from '../../../styles/settings/colors.config';

const useStyles = makeStyles(() => ({
  tableContainer: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    marginBottom: '10px'
  },
  headerItem: {
    fontWeight: 'bold',
    color: '#434343',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  headerItemBlue: {
    fontWeight: 'bold',
    color: COLORS.cardMessageBlue,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  headerItemGreen: {
    fontWeight: 'bold',
    color: COLORS.greenText,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  itemContainer: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    border: '1px solid #b7b7b7',
    backgroundColor: 'white',
    padding: '10px 20px 10px 20px',
    borderRadius: '10px',
    marginBottom: '10px'
  }
}));

const SectionHeader = styled(Typography)`
  color: ${COLORS.grayText};
  font-size: 20px;
  margin: 10px 0;
`;

const InfoHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Container maxWidth className={classes.tableContainer}>
      <Grid container spacing={2} justifyContent="bottom">
        <Grid
          item
          xs={2}
          className={classes.headerItem}
          direction="column"
          align="left"
        >
          {t('i18n.ticketcontainer.PROTOCOL')}
        </Grid>
        <Grid
          item
          xs={1}
          className={classes.headerItem}
          direction="column"
          align="left"
        >
          {t('i18n.legalprotocol.STATUS_PROCESS')}
        </Grid>
        <Grid
          item
          xs={2}
          className={classes.headerItem}
          direction="column"
          align="left"
        >
          {t('i18n.orderinfo.ORDER')}
        </Grid>
        <Grid
          item
          xs={2}
          className={classes.headerItem}
          direction="column"
          align="left"
        >
          {t('i18n.CAP.delivery')}
        </Grid>
        <Grid
          item
          xs={1}
          className={classes.headerItem}
          direction="column"
          align="left"
        >
          {t('i18n.ticketcontainer.RA_DATE')}
        </Grid>
        <Grid
          item
          xs={1}
          className={classes.headerItem}
          direction="column"
          align="left"
        >
          {t('i18n.b2w.modal.exchangerefund.DEADLINE')}
        </Grid>
        <Grid
          item
          xs={2}
          className={classes.headerItem}
          direction="column"
          align="left"
        >
          {t('i18n.ordersmodal.ORDER_CLIENT')}
        </Grid>
        <Grid
          item
          xs={1}
          className={classes.headerItem}
          direction="column"
          align="left"
        >
          {t('i18n.legalprotocol.CUSTOMER_CPF')}
        </Grid>
      </Grid>
    </Container>
  );
};

const InfoHeaderDetail = ({ protocolData }) => {
  const classes = useStyles();

  const status = protocolData?.status === 'T' ? 'Aberto' : 'Encerrado';

  const nomeCliente = protocolData?.protocolsVariablesList?.find(
    it =>
      it.variableId === 'nomeCliente' ||
      it.variableId === '963_nome_cliente' ||
      it.variableId === '809_prju_nome_cliente'
  );

  const dateTimeSplitted = protocolData?.dateTime?.split(' ');
  const deadlineSplitted = protocolData?.deadline?.split(' ');

  return (
    <Container maxWidth className={classes.itemContainer}>
      <Grid container spacing={2} justifyContent="bottom">
        <Grid
          item
          xs={2}
          className={classes.headerItem}
          direction="column"
          align="left"
        >
          {protocolData?.protocol}
        </Grid>
        <Grid
          item
          xs={1}
          className={classes.headerItemBlue}
          direction="column"
          align="left"
        >
          {status}
        </Grid>
        <Grid
          item
          xs={2}
          className={classes.headerItemGreen}
          direction="column"
          align="left"
        >
          {protocolData?.order}
        </Grid>
        <Grid
          item
          xs={2}
          className={classes.headerItem}
          direction="column"
          align="left"
        >
          {protocolData?.delivery}
        </Grid>
        <Grid
          item
          xs={1}
          className={classes.headerItem}
          direction="column"
          align="left"
        >
          {dateTimeSplitted && dateTimeSplitted[0]}
          <br />
          {dateTimeSplitted && dateTimeSplitted[1]}
        </Grid>
        <Grid
          item
          xs={1}
          className={classes.headerItem}
          direction="column"
          align="left"
        >
          {deadlineSplitted && deadlineSplitted[0]}
          <br />
          {deadlineSplitted && deadlineSplitted[1]}
        </Grid>
        <Grid
          item
          xs={2}
          className={classes.headerItem}
          direction="column"
          align="left"
        >
          {nomeCliente?.domains[0].desc}
        </Grid>
        <Grid
          item
          xs={1}
          className={classes.headerItem}
          direction="column"
          align="left"
        >
          {protocolData?.cpf}
        </Grid>
      </Grid>
    </Container>
  );
};

export default function LegalProcessInfoHeader({ protocolData }) {
  const { t } = useTranslation();
  return (
    <Box mt={4}>
      <SectionHeader>{t('i18n.legalprotocol.PROCESS_INFO')}</SectionHeader>
      <InfoHeader />
      <InfoHeaderDetail {...{ protocolData }} />
    </Box>
  );
}
