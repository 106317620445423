const blockMlStatus = status => {
  switch (status) {
    case 'blocked_by_time':
      return ' O prazo para envio de mensagens expirou. Máximo 30 dias após a última mensagem.';
    case 'blocked_by_buyer':
      return ' O comprador bloqueou o recebimento de mensagens.';
    case 'blocked_by_mediation':
      return ' A compra está sendo mediada. Etapa: mediação.';
    case 'blocked_by_claim':
      return ' A compra está sendo mediada. Etapa: reclamação.';
    case 'blocked_by_fulfillment':
      return ' tratando-se de uma venda com Fulfillment, a mensageria estará disponível no momento da entrega do produto (estado de envio: delivered).';
    case 'blocked_by_payment':
      return ' O pagamento ainda não foi efetuado pelo comprador.';
    case 'blocked_by_conversation_initiated_by_seller':
      return ' toda a compra é de produtos de Supermercado e o vendedor inicia a conversa. Apenas no Brasil, quando a compra é realizada com Fulfillment, o comprador dos produtos do Supermercado não poderá iniciar a conversa até receber o produto.';
    case 'blocked_by_conversation_use_message_api':
      return ' o vendedor tenta se comunicar por action-guide quando o comprador já iniciou a conversa.';
    case 'blocked_by_conversation_initiated_by_seller_limited':
      return ' Quando o comprador iniciar a conversa, a mensageria do vendedor não será bloqueada.';
    case 'blocked_by_cancelled_order_by_fraud':
      return 'Compra cancelada por fraude.';
    case 'blocked_by_cancelled_order':
      return 'Compra cancelada por solicitação de cancelamento';
    default:
      return '';
  }
};

const blockMlMessageStatus = status => {
  switch (status) {
    case 'social_network_link':
      return ' Não enviamos sua mensagem porque contém links externos.';
    case 'out_of_place_language':
      return ' A mensagem não foi entregue por causa da linguagem inapropriada.';
    case 'link_short_url':
      return ' A mensagem não foi entregue por causa do link encurtado na mensagem.';
    case 'automatic_message':
      return ' Esta é uma mensagem automática.';
    case 'personal_data':
      return ' Esta mensagem contêm informações pessoais.';
    case 'link_mercadopago':
      return ' Esta mensagem contêm link do Mercado Pago.';
    default:
      return '';
  }
};

const blockMlTicket = ticket => {
  const conversationStatus = ticket?.marketplaceBundle?.conversation_status;

  return (
    ticket?.marketPlace === 'MERCADO_LIVRE' && {
      mlTicketblocked: conversationStatus?.status === 'blocked',
      reason: blockMlStatus(conversationStatus?.substatus)
    }
  );
};

const blockMlTicketClaims = ticket => {
  const claimStatus =
    ticket?.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity?.status;

  return (
    ticket?.marketPlace === 'MERCADO_LIVRE' && {
      mlTicketblocked: claimStatus === 'closed'
    }
  );
};

const blockMlMessageTicket = message => {
  const detail = message?.detail;

  return detail?.moderationStatus === 'rejected'
    ? blockMlMessageStatus(detail?.moderationReason)
    : false;
};

export { blockMlTicket, blockMlMessageTicket, blockMlTicketClaims };
