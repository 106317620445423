import {
  Button,
  Typography,
  Divider,
  Card,
  CardContent,
  Grid,
  Tooltip,
  FormControl,
  RadioGroup,
  Radio,
  Box,
  FormControlLabel
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Campaign } from '../../tickets/TicketContainer/TicketComponents/Campaign';
import API from '../../../services/api';
import userService from '../../../services/user.service';
import TicketMacroInput from './TicketMacroInput';
import RobotMessageCard from '../../tickets/messages/components/RobotMessageCard';
import {
  getProductLink,
  getProductFromBundle
} from '../services/presaleServices';
import { COLORS } from '../../../styles/settings/colors.config';
import { inputStyles } from '../../../components/inputStyle';
import {
  MessageBody,
  MessageButtons,
  MessageContent,
  MessageHeader,
  MessageTextField,
  MessageTextWrapper,
  MessageWrapper,
  MessageProductContent,
  MessageProductContentItem,
  MessageProductData,
  MessageProductHeader,
  MessageProductLink,
  MessageAnswerOption,
  TabLink,
  CustomIcon,
  TabLinkDisabled,
  AnswerStatusBadge
} from '../styles';

const CustomMessageProductContentItem = ({ label, value, isPrice }) => {
  return (
    <MessageProductContentItem>
      <Typography>{label}</Typography>
      <Typography>
        <b>
          {isPrice
            ? value?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              })
            : value}
        </b>
      </Typography>
    </MessageProductContentItem>
  );
};

const MessageStatusBadge = ({ isPublic }) => {
  return (
    <AnswerStatusBadge
      bgColor={isPublic ? COLORS.greenText : COLORS.btnDangerColor}
    >
      {isPublic ? 'Pública' : 'Privada'}
    </AnswerStatusBadge>
  );
};

const PreSaleMessage = ({
  data,
  openModal,
  getData,
  blockInput,
  setHaveMessage,
  id,
  activateProtocol,
  indexTicket,
  lengthList,
  setBlockInput,
  setLoading
}) => {
  const { t } = useTranslation();
  const classes = inputStyles();
  const { enqueueSnackbar } = useSnackbar();
  const userName = userService.getUsername();
  const [message, setMessage] = useState('');
  const isSameAttendant = userName === data.serviceAttendantLogin;
  // const [botMessage, setBotMessage] = useState(data?.ticketAssistantMessages);
  const botMessage = data?.ticketAssistantMessages;
  const [showBotMessage, setShowBotMessage] = useState('true');
  const [loadingMacro, setLoadingMacro] = useState(false);
  const [itemStatus, setItemStatus] = useState(
    data?.marketplaceBundle?.item?.status
  );
  const [answerOption, setAnswerOption] = useState('public');
  const productInfo = getProductFromBundle(data);
  const { marketPlace } = data;

  const getEndpoint = () => {
    if (data.marketPlace === 'MERCADO_LIVRE') {
      return '/auth/ms-ticket/v1/tickets/send-answer';
    }
    if (data.marketPlace === 'YOURVIEWS') {
      return `/auth/ms-ticket/v1/tickets/yourviews/answer-pre-sale-question`;
    }
  };

  const mountPayload = () => {
    if (data.marketPlace === 'MERCADO_LIVRE') {
      return {
        message,
        ticketId: data.id
      };
    }
    if (data.marketPlace === 'YOURVIEWS') {
      return {
        ticketId: data.id,
        message,
        isPrivateAnswer: answerOption !== 'public'
      };
    }
  };

  const afterSucced = () => {
    getData(true, false);
    setMessage('');
    setShowBotMessage('false');
  };

  const handleSubmitAnswer = async eligibleToAnswer => {
    if (eligibleToAnswer) {
      setLoading(true);
      if (data.marketPlace === 'MERCADO_LIVRE') {
        API.post(getEndpoint(), mountPayload())
          .then(() => {
            afterSucced();
          })
          .catch(() => {
            enqueueSnackbar(t('i18n.ticketcontainer.ANUNCIO_PAUSADO_ERROR'), {
              variant: 'error'
            });
          });
      }
      if (data.marketPlace === 'YOURVIEWS') {
        API.post(getEndpoint(), mountPayload())
          .then(() => {
            afterSucced();
          })
          .catch(() => {});
      }
    } else {
      enqueueSnackbar(t('i18n.ticketcontainer.presale_already_been_answer'), {
        variant: 'error'
      });
    }
  };

  const handleAppliedMacro = answer => {
    setMessage(answer?.INSERT_MESSAGE?.value.replace(/\\n/g, '\n'));
    setBlockInput(false);
  };

  const alreadyAnswered =
    data.marketPlace === 'MERCADO_LIVRE'
      ? data.ticketStatus === 'ANSWERED'
      : data?.marketplaceBundle?.moderate > 2;

  const answer = alreadyAnswered
    ? data?.marketplaceGenericMessages[1]?.message
    : null;

  const mountChatHistory = chatList => {
    const protocols = [...chatList];

    const newChatList = {
      customerName: data.customerName,
      marketplace: data.marketPlace,
      actualProtocol: data,
      protocols
    };
    return newChatList;
  };

  const getChatHistory = () => {
    const payload = {
      ticketType: 'PRE_SALE',
      customerId: data.customerId,
      marketPlace: data.marketPlace
    };
    API.post('/auth/ms-ticket/v1/tickets/adds', payload)
      .then(response => {
        openModal(true, mountChatHistory(response.data));
      })
      .catch(() => {});
  };

  const insertMessage = robotAnswer => {
    if (message === '') {
      setMessage(robotAnswer);
    } else {
      const messageAux = [message];
      setMessage(messageAux[0].replace('\n', `\n\n${robotAnswer}\n`));
    }
  };

  const handleAcceptAnswer = robotAnswer => {
    insertMessage(robotAnswer);
    setShowBotMessage('false');
  };

  const handleEditBotMessage = robotAnswer => {
    insertMessage(robotAnswer);
    // setUnsetMessage(true);
  };

  const handleDeleteBotMessage = async botResponse => {
    try {
      await API.post(
        `/auth/ms-ticket/v1/tickets/${data.id}/negative-ticketAssistantMessage`,
        botResponse
      ).then(() => {});
    } catch (error) {
      enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
        variant: 'warning'
      }).finally(() => {
        setShowBotMessage('false');
      });
    }
  };

  const notAllowedInput = () => {
    // if (alreadyAnswered || loadingMacro || blockInput) {
    if (alreadyAnswered || blockInput || itemStatus === 'paused') {
      return true;
    }
    return false;
  };

  const getCurrentTicket = (d, submit) => {
    if (d?.id) {
      try {
        API.get(`/auth/ms-ticket/v1/tickets/${d.id}`).then(response => {
          setItemStatus(response.data?.marketplaceBundle?.item?.status);
          if (
            submit &&
            response?.data?.marketplaceBundle?.item?.status !== 'paused'
          ) {
            const eligibleToAnswer =
              response.data.ticketStatus === 'NEW' &&
              response.data.serviceAttendantLogin === userName;
            handleSubmitAnswer(eligibleToAnswer);
          }
        });
      } catch (e) {
        enqueueSnackbar('Não foi possível buscar o ticket', {
          variant: 'warning'
        });
      }
    }
  };

  const AnswerOption = () => {
    return (
      <MessageAnswerOption>
        <Tooltip title={t('i18n.presale.ANSWER_HINT')}>
          <HelpOutlineIcon fontSize="small" color="primary" />
        </Tooltip>
        <Typography>Tornar Pergunta e Resposta:</Typography>
        <FormControl
          component="fieldset"
          style={{
            display: 'flex',
            gap: '20px',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <RadioGroup
            value={answerOption}
            onChange={htmlElemtEvent => {
              setAnswerOption(htmlElemtEvent.target.value);
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              style={{
                gap: '7px'
              }}
            >
              <FormControlLabel
                className={classes.radioLabelBold}
                style={{ color: COLORS.presaleOrange }}
                value="public"
                control={<Radio className={classes.radio} color="primary" />}
                label={t('i18n.simplewords.PUBLIC')}
              />
              <FormControlLabel
                className={classes.radioLabel}
                value="private"
                control={<Radio className={classes.radio} color="primary" />}
                label={t('i18n.simplewords.PRIVATE')}
              />
            </Box>
          </RadioGroup>
        </FormControl>
      </MessageAnswerOption>
    );
  };

  useEffect(() => {
    if (message !== '' && message !== undefined) {
      setHaveMessage(true);
    } else {
      setHaveMessage(false);
    }
  }, [message]);

  useEffect(() => {
    setMessage('');
    setAnswerOption('public');
  }, [data]);

  useEffect(() => {
    if (id === activateProtocol) {
      getCurrentTicket(data, false);
    }
  }, [data, id, activateProtocol]);

  return id === activateProtocol ? (
    <MessageWrapper
      className={data && data?.id ? 'activate' : 'inactivate'}
      activeAbsolute={lengthList === indexTicket}
    >
      <MessageHeader>
        <Typography component="span">{t('i18n.knowledge.ANSWER')}</Typography>
        {answer && data.marketplace === 'YOURVIEWS' && (
          <MessageStatusBadge
            isPublic={data?.marketplaceGenericMessages[1]?.isPublic}
          />
        )}
      </MessageHeader>
      {!alreadyAnswered && data?.id && (
        <TicketMacroInput
          chat={data}
          dadosPedido={[]}
          handleAppliedMacro={handleAppliedMacro}
          typeMessage="normalMessage"
          noLabel
          disabled={
            !data?.id || isSameAttendant ? !isSameAttendant : blockInput
          }
          placeholder={t('i18n.quickanswer.PICK_A_QUICK_ANSWER')}
          type="fast"
          place="pre"
          style={{ background: '#fff' }}
          small
          executeMacro
          setLoadingMacro={setLoadingMacro}
          marketPlace={marketPlace}
        />
      )}
      {/* MUDAR VALOR PARA 'true' PARA REATIVAR O ROBÔ */}
      {!alreadyAnswered &&
        showBotMessage === 'hide' &&
        botMessage?.map((robotAnswer, index) => (
          <Grid
            container
            key={robotAnswer.answer}
            spacing={1}
            style={{ marginBottom: '0px', marginTop: '8px' }}
          >
            <Grid item xs={12}>
              <RobotMessageCard
                answer={robotAnswer}
                handleAcceptAnswer={handleAcceptAnswer}
                handleEditBotMessage={handleEditBotMessage}
                handleDeleteBotMessage={handleDeleteBotMessage}
                index={index}
                showBtns={!loadingMacro}
              />
            </Grid>
          </Grid>
        ))}
      <MessageContent>
        <MessageBody>
          <MessageTextWrapper>
            <MessageTextField
              multiline
              className="message-textarea"
              placeholder={
                loadingMacro
                  ? t('i18n.datatable.body.NOMATCH_LOADING')
                  : t('i18n.quickanswer.TYPE_MESSAGE')
              }
              minRows={6}
              disabled={notAllowedInput()}
              readOnly={!data?.id}
              value={answer || message}
              onChange={element => {
                setMessage(element.target.value);
              }}
              size="small"
              fullWidth
              variant="outlined"
            />
            {!alreadyAnswered && (
              <MessageButtons right>
                <Button
                  color="primary"
                  onClick={() => getCurrentTicket(data, true)}
                  variant="contained"
                  disabled={notAllowedInput()}
                >
                  {t('i18n.newticket.SEND')}
                </Button>
                {itemStatus === 'paused' && (
                  <Typography style={{ color: '#c00' }}>
                    {t('i18n.exception.preSale.ads_paused')}
                  </Typography>
                )}
                {data.marketPlace === 'YOURVIEWS' && <AnswerOption />}
              </MessageButtons>
            )}
          </MessageTextWrapper>
          <Card style={{ borderRadius: '10px', marginTop: '15px' }}>
            {data?.id && (
              <MessageProductData>
                <MessageProductHeader>
                  <div style={{ position: 'relative' }}>
                    <img
                      src={
                        productInfo?.picture ||
                        'https://http2.mlstatic.com/resources/frontend/statics/img-not-available/1.1.0/O.gif'
                      }
                      style={{ maxWidth: '60px' }}
                      alt="Ml"
                    />
                    {data?.marketplaceBundle?.already_bought === true && (
                      <Tooltip
                        title="Cliente efetuou a compra"
                        PopperProps={{
                          disablePortal: true,
                          popperOptions: {
                            positionFixed: true,
                            modifiers: {
                              preventOverflow: {
                                enabled: true,
                                boundariesElement: 'window'
                              }
                            }
                          }
                        }}
                      >
                        <CustomIcon>
                          <ShoppingCartIcon />
                        </CustomIcon>
                      </Tooltip>
                    )}
                  </div>

                  <Typography>
                    <MessageProductLink
                      target="_blank"
                      href={getProductLink(productInfo, data.marketPlace)}
                      noreferrer
                    >
                      {productInfo?.title}
                    </MessageProductLink>
                  </Typography>
                </MessageProductHeader>
                <br />
                <Divider />
                {productInfo?.price && (
                  <MessageProductContent>
                    <CustomMessageProductContentItem
                      label={t('i18n.presale.PRICE')}
                      value={productInfo?.price}
                      isPrice
                    />
                    <CustomMessageProductContentItem
                      label={t('i18n.presale.IN_STOCK')}
                      value={productInfo?.available_quantity}
                    />
                    <CustomMessageProductContentItem
                      label="Part number"
                      value={productInfo?.id}
                    />
                  </MessageProductContent>
                )}
                <br />
                {loadingMacro ? (
                  <TabLinkDisabled>
                    {`${t('i18n.presale.CHAT_HISTORY')} ${data?.customerName}`}
                  </TabLinkDisabled>
                ) : (
                  <TabLink
                    onClick={() => {
                      getChatHistory();
                    }}
                  >
                    {`${t('i18n.presale.CHAT_HISTORY')} ${data?.customerName}`}
                  </TabLink>
                )}
              </MessageProductData>
            )}
            <Divider />
            <CardContent>
              <Typography>Campanhas associadas ao atendimento: </Typography>
              <Campaign
                data={data}
                getData={getData}
                blockInput={
                  !data?.id || isSameAttendant
                    ? !isSameAttendant
                    : blockInput || alreadyAnswered
                }
                type="tickets"
                postUrl="/auth/lambda/campaign/post"
              />
            </CardContent>
          </Card>
        </MessageBody>
      </MessageContent>
    </MessageWrapper>
  ) : (
    <></>
  );
};

export default PreSaleMessage;
