import React from 'react';
import { Typography, IconButton } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import AuthService from '../../auth/auth.service';
import API from '../../services/api';
import { COLORS } from '../../styles/settings/colors.config';
import config from '../../config';
import userService from '../../services/user.service';

const CustomTypography = styled(Typography)`
  color: ${COLORS.greenText};
`;

const LogoutButton = ({ getHealthMonitor, userAlive }) => {
  const { t } = useTranslation();
  const [redirect] = React.useState(useHistory());

  const logout = async () => {
    if (userService.getCompanyName() === config.cognito.ownerCompany) {
      await API.post('/auth/ms-ticket/v1/user-productivity-status', {
        status: 'offline'
      });
    }
    localStorage.removeItem('status');
    localStorage.removeItem('busy');
    AuthService.logout(redirect);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <CustomTypography>{t('i18n.login.EXIT')}</CustomTypography>
      <IconButton
        color="primary"
        onClick={() => {
          if (getHealthMonitor) {
            clearInterval(getHealthMonitor);
          }
          if (userAlive) {
            clearInterval(userAlive);
          }
          logout();
        }}
      >
        <InputIcon />
      </IconButton>
    </div>
  );
};

export default LogoutButton;
