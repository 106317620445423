import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../services/api';
import formHelper from '../../../utils/formHelper';
import config from '../../../config';

const FilterAttendant = ({ filterForm, setFilterForm }) => {
  const { t } = useTranslation();
  const [attendantList, setAttendantList] = useState([]);
  const [attendantValue, setAttendantValue] = useState('');

  useEffect(() => {
    API.get(
      `/auth/ms-users/groups/${config.cognito.preSaleGroupName}/users`
    ).then(response => {
      setAttendantList(response.data.sort(formHelper.compareName));
    });
  }, []);

  useEffect(() => {
    setAttendantValue(filterForm?.serviceAttendantLogin || '');
  }, [filterForm]);

  return (
    <>
      <Box mb={2}>
        <Grid container>
          <Grid item xs={7}>
            <FormControl size="small" variant="outlined" fullWidth>
              <InputLabel>{t('i18n.ticketslistview.ATTENDANT')}</InputLabel>
              <Select
                size="small"
                value={attendantValue}
                onChange={htmlElemtEvent => {
                  setAttendantValue(htmlElemtEvent.target.value);
                  setFilterForm({
                    ...filterForm,
                    serviceAttendantLogin: htmlElemtEvent.target.value
                  });
                }}
                label={t('i18n.ticketslistview.ATTENDANT')}
                className="small-input"
              >
                <MenuItem value="">
                  <em>{t('i18n.ticketslistview.NONE')}</em>
                </MenuItem>
                {attendantList.map(user => {
                  return (
                    <MenuItem key={user?.name} value={user?.name}>
                      {user?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FilterAttendant;
