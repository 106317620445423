/*eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import statusEnum from '../../../enums/ticketsStatus.enum';
import indexHook from '../indexHook';
import API from '../../../services/api';
// import formHelper from '../../../utils/formHelper';
import { useSelector } from 'react-redux';
import InputDate from '../components/date.component';
import { filterNameInStorage, defaultFilter } from './config';
import { useReportTypesList } from '../../../hooks/hooks';
import userService from '../../../services/user.service';

const FilterForm = ({
  filter,
  setFilter,
  setSubmit,
  filterName,
  setOpen,
  attendantList
}) => {
  const { t, isAdmin } = indexHook();
  const reportTypes = useReportTypesList();

  const storageFilter = JSON.parse(sessionStorage.getItem(filterNameInStorage));
  const [localFilterData, setLocalFilterData] = useState({});
  const [blockButton, setBlockButton] = useState(false);

  const handleSubmitFilter = (e, filterData) => {
    e.preventDefault();
    filterData.marketplace = filter.marketplace;

    setFilter({
      ...filter,
      ...filterData
    });

    setSubmit(true);
    setOpen(false);
  };

  const clearFilter = () => {
    setFilter(defaultFilter);
    setLocalFilterData(defaultFilter);
    sessionStorage.setItem(filterNameInStorage, JSON.stringify(defaultFilter));
  };

  useEffect(() => {
    setLocalFilterData(storageFilter);
  }, []);

  return (
    <form onSubmit={e => handleSubmitFilter(e, localFilterData)}>
      <Grid container>
        <Grid item>
          <Box
            style={{ width: '500px', padding: '12px 24px 24px', border: '0' }}
          >
            <Box mb={2}>
              <FormControl size="small" variant="outlined" fullWidth>
                <InputLabel>
                  {t('i18n.dashboard.mktplacereport.report_type_column')}
                </InputLabel>
                <Select
                  size="small"
                  defaultValue=""
                  value={localFilterData.report_type || ''}
                  label={t('i18n.dashboard.mktplacereport.report_type_column')}
                  disabled={!isAdmin}
                  onChange={htmlElemtEvent => {
                    setLocalFilterData({
                      ...localFilterData,
                      ...{ report_type: htmlElemtEvent.target.value }
                    });
                  }}
                >
                  <MenuItem value="">
                    <em>{t('i18n.ticketslistview.ticketStatus.All')}</em>
                  </MenuItem>
                  {reportTypes.map(
                    item =>
                    userService.hasRole(item.role) && (
                      <MenuItem value={item.value} key={Math.random()}>
                          {item.label}
                        </MenuItem>
                      )
                  )}
                </Select>
              </FormControl>
            </Box>

            <Typography>
              <span style={{ marginBottom: '10px' }}>
                {t('i18n.dashboard.mktplacereport.report_creation_date')}
              </span>
            </Typography>

            <InputDate
              localFilterData={localFilterData}
              setLocalFilterData={setLocalFilterData}
              dashboardFilter={filter}
              setBlockButton={setBlockButton}
              notShowRadio
            />

            <Divider />
            <br />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="contained" onClick={() => clearFilter()}>
                {t('i18n.ticketslistview.CLEAR')}
              </Button>
              <Button
                disabled={blockButton}
                variant="contained"
                color="primary"
                type="submit"
              >
                {t('i18n.dashboard.APPLY_FILTER')}
              </Button>
            </div>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default FilterForm;
