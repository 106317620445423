import {
  Card,
  Grid,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileCopy } from '@material-ui/icons';
import styled from 'styled-components';
import { Alert } from '@material-ui/lab';
import { COLORS } from '../../../styles/settings/colors.config';
import tableHelper from '../../../utils/tableHelper';
import timezoneFormat from '../../../utils/timezoneFormat';

import {
  CardContainer,
  CardHeader,
  CardHeaderContent,
  CardNoShadowNoBackground,
  CardText,
  CardWrapper
} from '../TicketContainer/TicketComponents/SectionTabs';
import TicketMLBundle from '../TicketContainer/TicketComponents/TicketMLBundle';
import reasonList from '../../../utils/reasonList';

const SectionHeader = styled(Typography)`
  color: ${COLORS.grayText};
  font-size: 20px;
  margin: 10px 0;
`;

// class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined"
function TabPanel({ children, value, index, header, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
      className={`customTabs ${
        header ? 'customTabsAjustHeader' : 'customTabsAdjust'
      }`}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const alertSLA = slaMinutes => {
  const slaAlertLimit = tableHelper.TOTAL_MINUTES_ONE_DAY;
  const slaWarningLimit = tableHelper.TOTAL_MINUTES_HALF_DAY;
  if (slaMinutes >= slaAlertLimit) {
    return 'alert';
  }
  if (slaMinutes >= slaWarningLimit) {
    return 'warning';
  }
  return 'green';
};

const LegalProcessTicketHeader = ({
  data,
  raTicketsList,
  marketplaceBundle,
  raOpenModal
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState('tabTicketInformation');

  const ticketId = data.id;
  const date = data.creationDate;
  const sla = data.sla_hoursSinceCreation;
  const raLinkedTicket = raTicketsList[0]?.linked ? raTicketsList[0] : false;
  const subject = data?.subject;
  const hasBorder =
    data.sla_hoursSinceCreation > tableHelper.TOTAL_MINUTES_TWO_DAYS;

  const validRobotReason = robotReason => {
    if (robotReason === 'outros') {
      return null;
    }
    if (robotReason === 'garantia') {
      return 'suporte_tecnico';
    }
    return robotReason;
  };
  const [reason] = useState(validRobotReason(data?.reason) || '');

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const copyProtocol = label => {
    navigator.clipboard.writeText(label);
    enqueueSnackbar(`Texto copiado: ${label}`, { variant: 'success' });
  };

  const ticketLabel = (id, label) => {
    return (
      <>
        <p
          style={{ fontSize: '11px', textTransform: 'none', textAlign: 'left' }}
          id="protocol"
        >
          {label}
        </p>
        <p>
          {id}{' '}
          <Tooltip
            placement="top"
            title={t('i18n.ticketcontainer.HINT_COPY_PROTOCOL')}
          >
            <FileCopy
              style={{ fontSize: '14px', color: '#cccccc' }}
              onClick={() => copyProtocol(id)}
            />
          </Tooltip>
        </p>
      </>
    );
  };

  const handleReasonDescription = reasonCode => {
    const resultFilter = reasonList().filter(
      reasonItem => reasonItem.code === reasonCode
    );
    if (resultFilter.length > 0) {
      return resultFilter[0].description;
    }
    return reasonCode;
  };

  const showHeaderDetails =
    data?.marketplaceBundle?.idProtocoloCNOVA ||
    data?.marketplaceBundle?.protocol ||
    data?.marketPlace === 'NETSHOES' ||
    data?.marketplaceBundle?.prot ||
    data?.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity;

  return (
    <>
      {data ? (
        <>
          <SectionHeader>
            {t('i18n.legalprotocol.LINKED_PROTOCOL_INFO')}
          </SectionHeader>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <CardNoShadowNoBackground className="cardHeader customTabBorderAdjust">
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab
                    value="tabTicketInformation"
                    label={ticketLabel(
                      ticketId,
                      `${t('i18n.ticketcontainer.PROTOCOL')} Conecta`
                    )}
                  />
                </Tabs>
                <TabPanel value={tabValue} index="tabTicketInformation" header>
                  <Paper>
                    <Card>
                      <CardContainer
                        border={hasBorder.toString()}
                        container
                        style={{ borderRight: '1px transparent' }}
                      >
                        <CardWrapper item xs={2} lg={2}>
                          <CardHeader>
                            {t('i18n.ticketslistview.OPENING_DATE')}
                          </CardHeader>
                          <CardHeaderContent>
                            <CardText>{timezoneFormat(date)}</CardText>
                            <CardText>
                              {tableHelper.timeDiffCalc(date).difference}
                            </CardText>
                          </CardHeaderContent>
                        </CardWrapper>
                        <CardWrapper item xs={1} lg={1}>
                          <CardHeader>SLA</CardHeader>
                          <CardHeaderContent>
                            <CardText alert={alertSLA(sla)}>
                              {tableHelper.formatMinutes(sla, 'protocol')}
                            </CardText>
                          </CardHeaderContent>
                        </CardWrapper>

                        <CardWrapper item xs={2} lg={2}>
                          <CardHeader>Status</CardHeader>
                          <CardHeaderContent>
                            <CardText>
                              {t(
                                `i18n.ticketslistview.ticketStatus.${data.ticketStatus}`
                              )}
                            </CardText>
                          </CardHeaderContent>
                        </CardWrapper>
                        {/* )} */}

                        <CardWrapper item xs={2} lg={2}>
                          <CardHeader>
                            {t('i18n.ticketcontainer.ESPECIALIST')}
                            {/* Especialista */}
                          </CardHeader>
                          <CardHeaderContent>
                            <CardText> {data.serviceAttendantLogin} </CardText>
                          </CardHeaderContent>
                        </CardWrapper>
                        <CardWrapper item xs={2} lg={2}>
                          <CardHeader>
                            {t('i18n.ticketcontainer.SUBJECT')}
                          </CardHeader>
                          <CardHeaderContent>
                            <CardText>{subject}</CardText>
                          </CardHeaderContent>
                        </CardWrapper>
                        <CardWrapper item xs={2} lg={2}>
                          <CardHeader>
                            {t('i18n.ticketcontainer.REASON')}
                          </CardHeader>
                          <CardHeaderContent>
                            <CardText>
                              {handleReasonDescription(reason)}
                            </CardText>
                          </CardHeaderContent>
                        </CardWrapper>
                      </CardContainer>
                      {showHeaderDetails && (
                        <CardContainer container>
                          <TicketMLBundle
                            marketplaceBundle={marketplaceBundle}
                            data={data}
                            handleShowDetails={raOpenModal}
                            raLinkedTicket={raLinkedTicket}
                          />
                        </CardContainer>
                      )}
                    </Card>
                  </Paper>
                </TabPanel>
              </CardNoShadowNoBackground>
            </Grid>
          </Grid>
        </>
      ) : (
        <Alert severity="warning">
          Não há protocolos Conecta associados a este processo.
        </Alert>
      )}
    </>
  );
};

export default LegalProcessTicketHeader;
