import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../../styles/settings/colors.config';

import marketPlacesEnum from '../../../../enums/marketPlaces.enum';
import { blockMlMessageTicket } from '../../TicketContainer/TicketService/MLblockTicket';
import MessageBaloonInside from '../components/MessageBaloonInside.component';
import { TypoBlockMessage } from '../styles';

const AgentMessageCard = ({
  message,
  mlClaimId,
  marketPlace,
  idIncidenteCNOVA,
  typeMessage
}) => {
  const { t } = useTranslation();

  const blockMessage = blockMlMessageTicket(message);

  let colorDefault = COLORS.cardMessageGreen;
  if (
    message?.receiver_role === 'mediator' ||
    ([
      marketPlacesEnum.CARREFOUR,
      marketPlacesEnum.KABUM,
      marketPlacesEnum.VIVO
    ].includes(marketPlace) &&
      message?.agent !== 'ABO') ||
    (['b2wHelpMessage', 'b2wMediationMessage'].includes(typeMessage) &&
      message.agent !== 'SELLER')
  ) {
    colorDefault = COLORS.cardMessagePink;
  }

  const senderName = () => {
    if (message.username === 'SAC') {
      return message.username;
    }
    if (!message.username) {
      return message.agent;
    }
    if (message.username && message?.agent === 'EMPLOYEE') {
      return `Marketplace: ${message.username}`;
    }
    if (message.username) {
      return `${t('i18n.ticketslistview.ACER_USER')}: ${message.username}`;
    }
  };

  return (
    <>
      <MessageBaloonInside
        showRecipients
        {...{
          marketPlace,
          mlClaimId,
          idIncidenteCNOVA,
          colorDefault,
          message,
          senderName
        }}
      />
      {blockMessage && (
        <TypoBlockMessage align="center">{blockMessage}</TypoBlockMessage>
      )}
    </>
  );
};

export default AgentMessageCard;
