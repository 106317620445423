// Utilizado na exibição dos privilégios na listagem Usuários > Grupo de Usuários
// { menu: 'hide' } faz com que não apareça na listagem
// em caso de algo já desenvolvido no Back-end mas que não fará parte da Release
// por exemplo: a rotina de Cancelamento

// A ordenação a mesma do Menu (esquerda para direita )
// 10 faixa Dashboard
// 20 faixa Pós-venda e assim por diante

const rolesList = () => {
  return [
    {
      role: 'dashboard',
      description: 'Dashboard',
      menu: 'hide',
      order: 10
    },
    {
      role: 'dashboard_admin',
      description: 'Dashboard visão Administrativa',
      menu: 'hide',
      order: 11
    },
    {
      role: 'dashboard_show_ticket',
      description: 'Dashboard aba de protocolos',
      menu: 'hide',
      order: 12
    },
    {
      role: 'dashboard_show_chat',
      description: 'Dashboard aba de chats',
      menu: 'hide',
      order: 13
    },
    {
      role: 'dashboard_show_report',
      description: 'Dashboard aba de relatórios',
      menu: 'Dashboard',
      order: 14
    },
    {
      role: 'dashboard_show_report_status_user',
      description: 'Ver relatório de status',
      menu: 'Dashboard',
      order: 15
    },
    {
      role: 'ticket_b2b_list',
      description: 'Acesso ao B2B',
      menu: 'Dashboard',
      order: 16
    },

    {
      role: 'ticket_list',
      description: 'Listagem de Protocolos: Pós-venda',
      menu: 'Pós-venda',
      order: 21
    },
    {
      role: 'ticket_detail',
      description: 'Detalhe do Protocolo / Resposta Pré-venda',
      menu: 'Pós-venda / Pré-venda',
      order: 22
    },
    {
      role: 'ticket_new',
      description: 'Criar novo Protocolo',
      menu: 'Pós-venda',
      order: 23
    },
    {
      role: 'ticket_view_evaluation',
      description: 'Visualizar comentário da avaliação do cliente Acer Store',
      menu: 'Pós-venda',
      order: 24
    },
    {
      role: 'ticket_assign_all',
      description: 'Atribuir protocolos para demais usuários',
      menu: 'Pós-venda',
      order: 25
    },
    {
      role: 'protocol_filter_new',
      description: 'Criação de filtro de protocolos',
      menu: 'hide',
      order: 26
    },
    {
      role: 'protocol_filter_delete',
      description: 'Apagar filtro de protocolos',
      menu: 'hide',
      order: 27
    },
    {
      role: 'protocol_filter_list',
      description: 'Listar filtro de protocolos',
      menu: 'hide',
      order: 28
    },
    {
      role: 'protocol_filter_detail',
      description: 'Ver filtro de protocolos',
      menu: 'hide',
      order: 29
    },
    {
      role: 'vip_new',
      description: 'Destacar o cliente (vermelho)',
      menu: 'Pós-venda',
      order: 30
    },
    {
      role: 'ticket_send_message_closed_resolved',
      description: 'Enviar mensagem em protocolo Fechado/Resolvido',
      menu: 'Pós-venda',
      order: 31
    },
    {
      role: 'evaluations',
      description: 'Avaliações',
      menu: 'hide',
      order: 32
    },
    {
      role: 'ticket_pre_list',
      description: 'Listagem de protocolos de pré-venda',
      menu: 'Pré-venda',
      order: 33
    },
    {
      role: 'chat',
      description:
        'Atendimento Chat B2C - Online: Chat, Histórico e IPs Bloqueados',
      menu: 'Pré-venda',
      order: 34
    },
    {
      role: 'chat_b2b',
      description:
        'Atendimento Chat B2B - Online: Chat, Histórico e IPs Bloqueados',
      menu: 'Pré-venda',
      order: 35
    },
    {
      role: 'chat_historic_admin',
      description: 'Histórico de chats - Consultar por analista',
      menu: 'Pré-venda',
      order: 36
    },
    {
      role: 'favorite_new',
      description: 'Criar favorito',
      menu: 'Ferramentas',
      order: 37
    },
    {
      role: 'favorite_list',
      description: 'Consultar favoritos',
      menu: 'Ferramentas',
      order: 38
    },
    {
      role: 'favorite_detail',
      description: 'Ver favorito',
      menu: 'Ferramentas',
      order: 39
    },
    {
      role: 'favorite_delete',
      description: 'Apagar favorito',
      menu: 'Ferramentas',
      order: 40
    },
    {
      role: 'monitor_chat',
      description: 'Monitoramento do chat',
      menu: 'Pré-venda',
      order: 36
    },
    {
      role: 'sales_ad_analysis',
      description: 'Análise de Anúncios',
      menu: 'hide',
      order: 41
    },
    {
      role: 'support_list',
      description: 'Suporte Marketplace - Listagem',
      menu: 'Ferramentas',
      order: 42
    },
    {
      role: 'support_new',
      description: 'Suporte Marketplace - Abrir chamado',
      menu: 'Ferramentas',
      order: 43
    },
    {
      role: 'support_detail',
      description: 'Suporte Marketplace - Detalhe do chamado',
      menu: 'Ferramentas',
      order: 44
    },
    { role: 'tags', description: 'Tags', menu: 'Ferramentas', order: 46 },
    {
      role: 'automations',
      description: 'Automação',
      menu: 'Área administrativa',
      order: 51
    },
    {
      role: 'automation_new',
      description: 'Criar nova automação',
      menu: 'hide',
      order: 52
    },
    {
      role: 'automation_delete',
      description: 'Apagar automação',
      menu: 'hide',
      order: 53
    },
    {
      role: 'automation_list',
      description: 'Listar automações',
      menu: 'hide',
      order: 54
    },
    {
      role: 'automation_detail',
      description: 'Ver automação',
      menu: 'hide',
      order: 55
    },
    {
      role: 'campaign',
      description: 'Campanhas',
      menu: 'Área administrativa',
      order: 56
    },
    {
      role: 'campaign_new',
      description: 'Criar nova campanha',
      menu: 'hide',
      order: 57
    },
    {
      role: 'campaign_delete',
      description: 'Apagar campanha',
      menu: 'hide',
      order: 58
    },
    {
      role: 'campaign_list',
      description: 'Listar campanha',
      menu: 'hide',
      order: 59
    },
    {
      role: 'campaign_detail',
      description: 'Ver campanha',
      menu: 'hide',
      order: 60
    },
    {
      role: 'company',
      description: 'Empresas',
      menu: 'hide',
      order: 61
    },
    {
      role: 'company_delete',
      description: 'Empresas - Apagar',
      menu: 'hide',
      order: 62
    },
    {
      role: 'company_list',
      description: 'Empresas - Listagem',
      menu: 'Área administrativa',
      order: 63
    },
    {
      role: 'company_new',
      description: 'Empresas - Criar',
      menu: 'Área administrativa',
      order: 64
    },
    {
      role: 'company_detail',
      description: 'Empresas - Editar',
      menu: 'Área administrativa',
      order: 64
    },
    {
      role: 'system_params_list',
      description: 'Configurações - Visualizar',
      menu: 'Área administrativa',
      order: 70
    },
    {
      role: 'system_params_detail',
      description: 'Configurações - Detalhe',
      menu: 'hide',
      order: 71
    },
    {
      role: 'system_params_new',
      description: 'Configurações - Criar novo parâmetro',
      menu: 'hide',
      order: 72
    },
    {
      role: 'system_params_update',
      description: 'Configurações - Alterar',
      menu: 'Área administrativa',
      order: 73
    },
    {
      role: 'system_params_delete',
      description: 'Configurações - Excluir parâmetro',
      menu: 'hide',
      order: 74
    },
    {
      role: 'knowledge_base',
      description: 'FAQ',
      menu: 'Área adminstrativa',
      order: 80
    },
    {
      role: 'macros',
      description: 'Macros (Pós-venda) e Repostas Rápidas (Pré-venda)',
      menu: 'Área administrativa',
      order: 90
    },
    {
      role: 'user',
      description: 'Usuários',
      menu: 'Área administrativa',
      order: 99
    },
    {
      role: 'user_can_add_user_external_mktplace',
      description: 'Usuário - Associar Empresa',
      menu: 'Área administrativa',
      order: 100
    },
    {
      role: 'group',
      description: 'Grupos de Usuários',
      menu: 'Área administrativa',
      order: 101
    },
    {
      role: 'cancellation_process_new', // BOTAO SOLICITAR CANCELAMENTO
      description: 'Solicitar Cancelamento',
      menu: 'hide',
      order: 900
    },
    {
      role: 'cancellation_process_detail', // MENU LOGISTICA e SERVICE/TROCA
      description: 'Cancelamento: Logistica e Service/Troca',
      menu: 'hide',
      order: 901
    },
    {
      role: 'cancellation_process_cancel', // BOTAO DE CANCELAR PROCESSO DE CANCELAMENTO
      description: 'Cancelar processo de cancelamento',
      menu: 'hide',
      order: 902
    },
    {
      role: 'cancellation_process_logistics', // BOTAO DE CANCELAR PROCESSO DE CANCELAMENTO
      description: 'Logística',
      menu: 'hide',
      order: 903
    },
    {
      role: 'cancellation_process_specialist', // BOTAO DE CANCELAR PROCESSO DE CANCELAMENTO
      description: 'Service/Troca',
      menu: 'hide',
      order: 904
    },
    {
      role: 'cancellation_process_service', // BOTAO DE CANCELAR PROCESSO DE CANCELAMENTO
      description: 'Service/Troca',
      menu: 'hide',
      order: 904
    },
    {
      role: 'juridical_list',
      description: 'Listar processos jurídicos',
      menu: 'hide',
      order: 905
    },
    {
      role: 'juridical_download',
      description: 'Baixar processo jurídico',
      menu: 'hide',
      order: 906
    },
    {
      role: 'juridical_detail',
      description: 'Ver detalhes jurídicos',
      menu: 'hide',
      order: 907
    },
    {
      role: 'send_email',
      description: 'Enviar emails',
      menu: 'hide',
      order: 908
    },
    {
      role: 'invoice_detail',
      description: 'Ver detalhes da nota fiscal',
      menu: 'hide',
      order: 909
    },
    {
      role: 'order_new',
      description: 'Criar novo pedido',
      menu: 'hide',
      order: 910
    },
    {
      role: 'order_list',
      description: 'Listar pedidos',
      menu: 'hide',
      order: 911
    },
    {
      role: 'order_detail',
      description: 'Ver pedido',
      menu: 'hide',
      order: 912
    },
    {
      role: 'reverse_logistics_new',
      description: 'Criar logística reversa',
      menu: 'hide',
      order: 913
    },
    {
      role: 'reverse_logistics_list',
      description: 'Listar logística reversa',
      menu: 'hide',
      order: 914
    },
    {
      role: 'reverse_logistics_detail',
      description: 'Ver logística reversa',
      menu: 'hide',
      order: 915
    },
    {
      role: 'protocol_change_status',
      description:
        'Possibilita seleção de status resolvido em tickets bloqueados do ML',
      menu: 'Pós-venda',
      order: 916
    },
    {
      role: 'specialist_pre_sale_online',
      description: 'Especialista - acesso ao Dashboard Pré-Venda Online',
      menu: 'Dashboard',
      order: 917
    },
    {
      role: 'analyst_pre_sale_online',
      description: 'Analista - Acesso ao Dashboard Pré-Venda Online',
      menu: 'Dashboard',
      order: 918
    },
    {
      role: 'specialist_pre_sale_offline',
      description: 'Especialista - Acesso ao Dashboard Pré-Venda Offline',
      menu: 'Dashboard',
      order: 919
    },
    {
      role: 'analyst_pre_sale_offline',
      description: 'Analista - Acesso ao Dashboard Pré-Venda Offline',
      menu: 'Dashboard',
      order: 920
    },
    {
      role: 'dashboard_score_marketplace',
      description: 'Acesso ao Dashboard Notas Marketplace',
      menu: 'Dashboard',
      order: 921
    },
    {
      role: 'dashboard_specialist_pos_sale',
      description: 'Especialista - Acesso ao Dashboard Pós-Venda',
      menu: 'Dashboard',
      order: 922
    },
    {
      role: 'dashboard_analyst_pos_sale',
      description: 'Analista - Acesso ao Dashboard Pós-Venda',
      menu: 'Dashboard',
      order: 922
    },
    {
      role: 'profile',
      description: 'Perfis',
      menu: 'hide',
      order: 923
    },
    {
      role: 'attending_time',
      description: 'Horário de atendimento',
      menu: 'Área Administrativa',
      order: 924
    },
    {
      role: 'chat_trigger',
      description: 'Gatilhos do Chat',
      menu: 'Área Administrativa',
      order: 924
    }
  ];
};

export default rolesList;
