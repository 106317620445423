import { Box, Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import OrderItem from './OrderItem';
import { DetailsContainer } from '../../../Dialogs/styles';

const BoldValue = styled.span`
  font-weight: bold;
`;

const toBRL = string => {
  return string.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
};

export default function OrderItems({
  dataOrder,
  cancelAll,
  selectedItems,
  setSelectedItems,
  setTotalRefund,
  setCancelAll,
  orderTotalValue
}) {
  return (
    <DetailsContainer>
      {dataOrder?.itens?.map((item, i) => {
        return (
          <OrderItem
            key={Math.random()}
            orderItems={dataOrder?.itens}
            {...{
              item,
              cancelAll,
              selectedItems,
              setSelectedItems,
              i,
              setTotalRefund,
              setCancelAll
            }}
          />
        );
      })}
      <Box
        display="flex"
        justifyContent="space-between"
        style={{
          borderBottom: '1px solid #CCCCCC',
          paddingBottom: '5px'
        }}
        mb={2}
      >
        <div style={{ width: '430px' }}> </div>
        <div
          style={{
            width: '300px',
            color: '#434343',
            fontSize: '11px',
            fontWeight: 'normal',
            textAlign: 'right',
            margin: '5px 5px 15px 0px'
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={8} style={{ height: '15px' }}>
              Subtotal:
            </Grid>
            <Grid item xs={4} style={{ height: '15px' }}>
              <BoldValue>{toBRL(orderTotalValue)}</BoldValue>
            </Grid>
            <Grid item xs={8} style={{ height: '15px' }}>
              Desconto:
            </Grid>
            <Grid item xs={4} style={{ height: '15px' }}>
              <BoldValue>{toBRL(0)}</BoldValue>
            </Grid>
            <Grid item xs={8} style={{ height: '15px' }}>
              Frete:
            </Grid>
            <Grid item xs={4} style={{ height: '15px' }}>
              <BoldValue>{toBRL(dataOrder?.totalFrete || 0)}</BoldValue>
            </Grid>
          </Grid>
        </div>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <div style={{ width: '430px' }}> </div>

        <div
          style={{
            width: '300px',
            color: '#434343',
            fontSize: '11px',
            fontWeight: 'normal',
            textAlign: 'right'
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={8} style={{ height: '15px' }}>
              Total:
            </Grid>
            <Grid item xs={4} style={{ height: '15px' }}>
              <BoldValue>
                {toBRL(orderTotalValue + (dataOrder?.totalFrete || 0))}
              </BoldValue>
            </Grid>
          </Grid>
        </div>
      </Box>
    </DetailsContainer>
  );
}
