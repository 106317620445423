import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  SectionTabs,
  SectionContent,
  SectionContentWrapper,
  SectionContentTitle,
  SectionCustomGrid,
  SectionProductItem,
  SectionProductList,
  SectionText,
  SectionTotalDetails
} from '../../../../views/tickets/TicketContainer/TicketComponents/SectionTabs';

const OrderTabDetails = ({ productslist, orderData }) => {
  const { t } = useTranslation();

  let total = 0.0;
  if (orderData?.itens) {
    orderData.itens.forEach(item => {
      total += item.totalBruto;
    });
  }

  let totalPayment = 0.0;
  // if (orderData?.pagamentos?.length > 0) {
  //   orderData.pagamentos.forEach(element => {
  //     totalPayment += element.valorPago;
  //   });
  // }

  let totalDiscount = 0.0;
  if (orderData?.itens?.length > 0) {
    orderData.itens.forEach(element => {
      totalDiscount += element.totalDesconto || 0.0;
    });
  }

  totalPayment = total + (orderData?.totalFrete || 0.0) - totalDiscount;
  totalDiscount = totalDiscount.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  });
  totalPayment = totalPayment.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  });
  total = total.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  });
  return (
    <>
      <SectionTabs>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={4}>
            <SectionContentWrapper>
              <SectionContentTitle>Item:</SectionContentTitle>
            </SectionContentWrapper>
          </Grid>
          <SectionCustomGrid item lg={2} xs={2}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.QUANTITY')}:
              </SectionContentTitle>
            </SectionContentWrapper>
          </SectionCustomGrid>
          <SectionCustomGrid item lg={3} xs={3}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.UNIT_VALUE')}:
              </SectionContentTitle>
            </SectionContentWrapper>
          </SectionCustomGrid>
          <SectionCustomGrid item lg={3} xs={3}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.TOTAL_VALUE')}:
              </SectionContentTitle>
            </SectionContentWrapper>
          </SectionCustomGrid>
        </Grid>

        <SectionProductList>
          {productslist?.map(item => (
            <SectionProductItem container spacing={2} key={item.partNumber}>
              <Grid item lg={4} xs={3}>
                <SectionContentWrapper>
                  <SectionText
                    style={{ fontSize: '14px', paddingLeft: '10px' }}
                  >
                    {t('i18n.ticketcontainer.CODE')}{' '}
                    <span>{item.partNumber}</span>
                  </SectionText>
                  <SectionContent>
                    <SectionText style={{ fontSize: 'small' }}>
                      {item.descricao.substr(0, 60)}
                    </SectionText>
                  </SectionContent>
                </SectionContentWrapper>
              </Grid>
              <SectionCustomGrid item lg={2} xs={3}>
                <SectionContentWrapper>
                  <SectionContent>
                    <SectionText>{item.quantidade}</SectionText>
                  </SectionContent>
                </SectionContentWrapper>
              </SectionCustomGrid>
              <SectionCustomGrid item lg={3} xs={3}>
                <SectionContentWrapper>
                  <SectionContent>
                    <SectionText>
                      {item.valorBruto.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                      }) || 'R$ 0,00'}
                    </SectionText>
                  </SectionContent>
                </SectionContentWrapper>
              </SectionCustomGrid>
              <SectionCustomGrid item lg={3} xs={3}>
                <SectionContentWrapper>
                  <SectionContent>
                    <SectionText>
                      {item.totalBruto.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                      }) || 'R$ 0,00'}
                    </SectionText>
                  </SectionContent>
                </SectionContentWrapper>
              </SectionCustomGrid>
            </SectionProductItem>
          ))}
        </SectionProductList>
        <Grid container spacing={2}>
          <SectionTotalDetails item mt="true" lg={12} xs={12}>
            <SectionText>Subtotal:</SectionText>
            <SectionText>{total}</SectionText>
          </SectionTotalDetails>
          <SectionTotalDetails item lg={12} xs={12}>
            <SectionText>Desconto:</SectionText>
            <SectionText>{totalDiscount}</SectionText>
          </SectionTotalDetails>
          <SectionTotalDetails item lg={12} xs={12}>
            <SectionText>Frete:</SectionText>
            <SectionText>
              {orderData?.totalFrete?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              }) || 'R$ 0,00'}
            </SectionText>
          </SectionTotalDetails>
          <SectionTotalDetails borderdotted="true" item lg={12} xs={12}>
            <SectionText bold>Total:</SectionText>
            <SectionText bold>{totalPayment}</SectionText>
          </SectionTotalDetails>
        </Grid>
      </SectionTabs>
    </>
  );
};

export default OrderTabDetails;
