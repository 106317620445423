import React, { useEffect } from 'react';
import styled from 'styled-components';
import { searchB2bOnCurrentUrl } from '../../utils/b2bHelper';

const IframeWrapper = styled.div`
  width: 100%;
  height: 100%;
  zoom: 110%;
  overflow: hidden;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;

export default function PresaleChatIframe() {
  const B2C_URL = `${
    process.env.REACT_APP_NEW_CONNECTA
  }/chat?refreshToken=${localStorage.getItem('refreshToken')}`;

  const B2B_URL = `${
    process.env.REACT_APP_NEW_CONNECTA
  }/chat/b2b?refreshToken=${localStorage.getItem('refreshToken')}`;

  const BASE_TITLE = `Chat ${searchB2bOnCurrentUrl() ? 'B2B' : 'B2C'}`;

  useEffect(() => {
    document.title = BASE_TITLE;

    const listener = (message) => {
      if (message.data.type === 'notification-count') {
        if (message.data.notificationCount === 0) document.title = BASE_TITLE;
        else {
          document.title = `(${message.data.notificationCount}) ${BASE_TITLE}`;
        }
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  return (
    <>
      <IframeWrapper>
        <Iframe src={searchB2bOnCurrentUrl() ? B2B_URL : B2C_URL} />
      </IframeWrapper>
    </>
  );
}
