import React, { useState } from 'react';
import { Card, Tab, Tabs, Paper } from '@material-ui/core';
import PreSaleHistory from './preSaleHistoryMessages.component';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      className="customTabs"
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const PreSaleChatTabs = ({
  chatModalData,
  messagesAllproducts,
  messagesActualProduct
}) => {
  const [tabValue, setTabValue] = useState('tabActualProduct');

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Card>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="scrollable auto tabs example"
        >
          <Tab value="tabActualProduct" label="Perguntas neste Anúncio" />
          <Tab value="tabAllProducts" label="Todas as Perguntas" />
        </Tabs>
        <TabPanel value={tabValue} index="tabActualProduct">
          <Paper>
            <PreSaleHistory
              marketplace={chatModalData.marketplace}
              customerName={chatModalData.customerName}
              messages={messagesActualProduct}
            />
          </Paper>
        </TabPanel>
        <TabPanel value={tabValue} index="tabAllProducts">
          <Paper>
            <PreSaleHistory
              marketplace={chatModalData.marketplace}
              customerName={chatModalData.customerName}
              messages={messagesAllproducts}
            />
          </Paper>
        </TabPanel>
      </Card>
    </>
  );
};

export default PreSaleChatTabs;
