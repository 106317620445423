const listHours = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 24, 48];
const byPeriodValues = [
  'in_current_month',
  'previous_month',
  'last_seven_days',
  'last_thirty_days',
  'last_six_months',
  'in_current_year'
];

const defaultHoursNumber = 7;

export { listHours, byPeriodValues, defaultHoursNumber };
