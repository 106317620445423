import { Button, makeStyles, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { COLORS } from '../../../../../styles/settings/colors.config';

export const useStyles = makeStyles({
  inputSelect: {
    width: '100%',
    paddingBottom: '10px',
    '& .MuiOutlinedInput-root': {
      height: '30px',
      fontSize: '12px',
      width: '95%',
      backgroundColor: 'white'
    }
  },
  inputTextArea: {
    width: '95%',
    '& .MuiOutlinedInput-root': {
      fontSize: '12px',
      padding: '10px',
      backgroundColor: 'white'
    }
  },
  chip: {
    margin: '2px',
    border: `1px solid ${COLORS.greenText}`,
    color: COLORS.darkGreenText
  },
  buttonNoDecoration: {
    textTransform: 'unset !important'
  }
});

export const Section = styled.div`
  background-color: ${props => (props.bg ? '#f3f3f3' : 'white')};
  padding: 10px 20px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

export const ItemHeader = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: #666666;
  margin-bottom: 5px;
  display: flex;
`;

export const ItemValue = styled.div`
  color: #3d85c6;
  font-size: 14px;
  font-weight: bold;
`;

export const GraySectionTitle = styled(Typography)`
  color: ${COLORS.meduimGray};
  margin-bottom: 10px;
`;

export const StyledButton = styled(Button)`
  padding: 2px 5px !important;
  font-size: 0.5rem;
  min-width: inherit;
  margin-right: 10px;
`;

export const ButtonNoCapital = styled(Button)`
  text-decoration: none !important;
`;
