import React, { useEffect, useState } from 'react';
import { Switch, Tooltip, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';

const FavoriteSwitch = () => {
  const reduxDispatcher = useDispatch();
  const [state, setState] = useState({
    checkedA: false
  });

  const handleChangeSwitch = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
    reduxDispatcher({
      type: 'EDIT_FAVORITE',
      editFavorite: event.target.checked
    });
  };

  useEffect(() => {
    const storageLanguage = localStorage.getItem('language');

    if (storageLanguage === 'pt_br') {
      setState({ ...state, checkedA: false });
    }
    if (storageLanguage === 'en') {
      setState({ ...state, checkedA: true });
    }
  }, []);

  useEffect(() => {
    reduxDispatcher({
      type: 'EDIT_FAVORITE',
      editFavorite: false
    });
  }, []);

  return (
    <Tooltip
      title={
        <Typography>
          Editar barra de favoritos: clique na estrela ao lado do link que
          deseja exibir como atalho na barra verde do Conecta. Para retirar,
          basta desmarcar a estrela e clicar em excluir na janela. Caso queira
          editar, clique na estrela, faça a edição na janela e clique em salvar
        </Typography>
      }
      placement="left"
    >
      <div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Switch
            checked={state.checkedA}
            onChange={handleChangeSwitch}
            name="checkedA"
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
      </div>
    </Tooltip>
  );
};

export default FavoriteSwitch;
