import axios from 'axios';
import config from '../config';

export const createAxiosInstance = () =>
  axios.create({
    baseURL: config.backend.url
  });

const API = createAxiosInstance();

export default API;
