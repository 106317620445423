import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MessageProductHeader, MessageProductLink } from '../styles';
import { getProductLink } from '../services/presaleServices';
import ClientMessageCard from '../../tickets/messages/baloons/ClientMessageCard';
import AgentMessageCard from '../../tickets/messages/baloons/AgentMessageCard';
import { SectionTabs } from '../../tickets/TicketContainer/TicketComponents/SectionTabs';

const PreSaleHistoryMessages = ({ marketplace, messages, customerName }) => {
  const { t } = useTranslation();
  const styleClient = { marginInlineEnd: '120px', marginBlockEnd: '3px' };
  const styleAttendant = { marginInlineStart: '120px', marginBlockEnd: '3px' };

  let currentProduct = '';

  const Title = ({ productInfo }) => {
    return (
      <>
        <Typography style={{ fontWeight: 'bold' }}>
          {t('i18n.simplewords.ADS')}:
        </Typography>
        <MessageProductHeader>
          <div style={{ position: 'relative' }}>
            <img
              src={
                productInfo.picture ||
                'https://http2.mlstatic.com/resources/frontend/statics/img-not-available/1.1.0/O.gif'
              }
              style={{ maxWidth: '60px' }}
              alt="Ml"
            />
          </div>
          <Typography>
            <MessageProductLink
              target="_blank"
              href={getProductLink(productInfo, marketplace)}
              noreferrer
            >
              {productInfo.title}
            </MessageProductLink>
          </Typography>
        </MessageProductHeader>
      </>
    );
  };

  return (
    <SectionTabs>
      <Grid
        direction="column"
        container
        spacing={2}
        style={{
          overflowY: 'auto',
          overFlowX: 'hidden'
        }}
      >
        {messages?.map((item, index) => {
          let newProduct = false;
          if (item.productInfo.id !== currentProduct) {
            currentProduct = item.productInfo.id;
            newProduct = true;
          } else {
            newProduct = false;
          }
          if (item.agent === 'CLIENT') {
            return (
              <div key={Math.random()}>
                <Grid item xs={12}>
                  {newProduct && (
                    <Box mt={index === 0 ? 1 : 4}>
                      <Title productInfo={item.productInfo} />
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} style={styleClient} key={Math.random()}>
                  <ClientMessageCard
                    message={item}
                    customerName={customerName}
                  />
                </Grid>
              </div>
            );
          }

          return (
            <Grid item xs={12} style={styleAttendant} key={Math.random()}>
              <AgentMessageCard message={item} />
            </Grid>
          );
        })}
      </Grid>
    </SectionTabs>
  );
};

export default PreSaleHistoryMessages;
