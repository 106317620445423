import { Container, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { CustomBreadCrumbs } from '../../../../../../../styles/components/CustomDataTable';

const useStyles = makeStyles(() => ({
  breadcrumbs: {
    marginTop: '15px',
    marginLeft: '15px'
  }
}));

export default function Header() {
  const classes = useStyles();

  return (
    <Container
      maxWidth="xl"
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <CustomBreadCrumbs
        className={classes.breadcrumbs}
        gutterBottom
        aria-label="breadcrumb"
      >
        <Typography variant="h6">Service</Typography>

        <Typography>Detalhe da Troca</Typography>
      </CustomBreadCrumbs>
    </Container>
  );
}
