/* eslint-disable camelcase */
import React, { useContext } from 'react';
import styled from 'styled-components';

import { ThemeContext } from '../../../layouts/MonitorLayout';

import { CustomLabel, CustomTypography } from './card.component';

const CustomIndicator = styled(CustomTypography)`
  background: #454545;
  color: ${props => props.color};
  padding: 0px 16px;
  font-size: ${props => (props.font ? props.font : '25px')};
  border-radius: 5px;
  min-width: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
`;
const AttendantCardV2 = ({ clerk, chats_per_clerks, online }) => {
  const theme = useContext(ThemeContext);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        marginBottom: '16px'
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <CustomIndicator
            font={theme?.font28}
            color={online ? theme.success : theme.danger}
          >
            {chats_per_clerks}
          </CustomIndicator>
        </div>
        <div>
          <CustomLabel color={theme.color} font={theme?.font28}>
            {clerk}
          </CustomLabel>
        </div>
      </div>
    </div>
  );
};

export { AttendantCardV2 };
