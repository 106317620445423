import {
  makeStyles,
  createStyles,
  Box,
  TextField,
  Grid,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Tooltip,
  Chip,
  CircularProgress
} from '@material-ui/core';

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import { InsertDriveFile } from '@material-ui/icons';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import moment from 'moment';
import InputMask from 'react-input-mask';
import { COLORS } from '../../../styles/settings/colors.config';
import CustomFooter from '../../../styles/components/CustomFooter';
import {
  CustomDataTable,
  CustomDataTableWrapper
} from '../../../styles/components/CustomDataTable';
import AcerModal from '../../../components/AcerModal';
import ModalAcerNewTicket from './ModalAcerNewTicket';
import API from '../../../services/api';
import { SmallButton } from '../TicketContainer/TicketComponents/SectionTabs';
import formHelper from '../../../utils/formHelper';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: '0px !important'
    }
  })
);

const SectionHeader = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: #666666;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '5px')};
`;

const SectionHeaderValue = styled.div`
  color: #3d85c6;
  font-size: 12px;
  font-weight: bold;
`;

const SectionHeaderProductValue = styled.div`
  margin-block-end: 3px;
  color: #3d85c6;
  font-size: 12px;
  font-weight: bold;
`;

const Section = styled.div`
  font-family: Roboto;
  display: flex;
  background-color: ${props => (props.bg ? '#f3f3f3' : 'white')};
  margin-bottom: 10px;
  padding: 10px 20px;
  justify-content: space-between;
  width: 100%;
`;

const CustomTextField = styled(TextField)`
  background: #fff;
  p {
    background: #f4f6f8;
    margin: 0;
    padding: 4px 14px 0px 14px;
  }
`;

const StyledTooltip = styled(Tooltip)`
  margin-right: 2px;
`;

const mlColumns = [
  { name: 'orderId', label: 'Número Pedido' },
  { name: 'orderDate', label: 'Data Pedido' },
  { name: 'orderValue', label: 'Valor' },
  { name: 'customerDocument', label: 'CPF Cliente' },
  { name: 'customerName', label: 'Nome Cliente' }
];

const americanasColumns = [
  { name: 'orderId', label: 'Número Pedido' },
  { name: 'orderDate', label: 'Data Pedido' },
  { name: 'orderValue', label: 'Valor' },
  { name: 'deliveryId', label: 'Número Entrega' },
  { name: 'deliveryType', label: 'Tipo Entrega' },
  { name: 'promisedDate', label: 'Data Prometida' },
  { name: 'orderStatus', label: 'Status' },
  { name: 'customerDocument', label: 'CPF Cliente' },
  { name: 'customerName', label: 'Nome Cliente' }
];

const americanasReasons = [
  { title: 'Cancelamento sem autorização', id: 52 },
  { title: 'Carta de cancelamento', id: 53 },
  { title: 'Reembolso de frete', id: 51 },
  { title: 'Troca e reembolso', id: 60 }
];

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const convertFileToBase64String = async element => {
  const result = await toBase64(element);
  const resultedFileBase64 = result.split('base64,');
  return resultedFileBase64[1];
};

const LoadTextField = ({
  marketplace,
  messageType,
  selectedOrder,
  reasonCancellation,
  handleClose,
  reasonDetail,
  newDate,
  dataCnova,
  solution
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();
  const [resposta, setResposta] = useState('');
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const styledHelperText = {
    textAlign: 'right'
  };

  const sendMessage = async () => {
    let json;
    let url;

    if (marketplace === 'MERCADO LIVRE') {
      json = {
        option_id: 'OTHER',
        text: resposta
      };
      url = `/auth/ms-ticket/v1/tickets/mercadolivre/create-new-ticket/${selectedOrder.orderId}`;
    }

    if (marketplace === 'CNOVA') {
      json = {
        orderId: Number(dataCnova.orderId),
        deliveryId: dataCnova?.items[0]?.deliveryId,
        reasonId: Number(reasonDetail.split(',')[1]),
        solution: {
          id: solution?.id,
          annotation: resposta
        }
      };

      if (filesToUpload.length > 0) {
        if (marketplace !== 'CNOVA') {
          json.attachments = { ...filesToUpload[0] };
        } else {
          json.solution.attachments = filesToUpload;
        }
      }

      url = `/auth/ms-ticket/v1/tickets/cnova/create-incident`;
    }

    if (marketplace === 'AMERICANAS') {
      json = {
        // eslint-disable-next-line
        message_to: messageType,
        delivery_id: selectedOrder.deliveryId,
        order_id: selectedOrder.orderId,
        message: resposta,
        // eslint-disable-next-line
        message_type: 'TEXT'
      };

      if (reasonCancellation) {
        const reasonObject = americanasReasons.find(
          item => item.title === reasonCancellation
        );

        json.subject = {
          description: reasonObject.title,
          id: reasonObject.id,
          menuId: 'CHAT_ASSUNTO_PORTAL'
        };
      }

      if (filesToUpload.length > 0) {
        json.attachment = { ...filesToUpload[0] };
      }

      url = `/auth/ms-ticket/v1/tickets/b2w/send-message`;
    }

    let result;
    try {
      setLoadingCreate(true);
      result = await API.post(url, json);
      enqueueSnackbar('Mensagem enviada!', {
        variant: 'success'
      });
      handleClose();
    } catch {
      if (result?.data?.Error) {
        enqueueSnackbar(t(result.data.Error), {
          variant: 'error'
        });
        return false;
      }
      enqueueSnackbar(t('i18n.simplephrases.ERROR'), { variant: 'error' });
    } finally {
      setLoadingCreate(false);
    }
  };

  const onDrop = acceptedFiles => {
    const fileSizeLimit = marketplace === 'CNOVA' ? 1000000 : 2000000;
    const newFilesState = [...filesToUpload];
    if (marketplace === 'AMERICANAS') {
      if (newFilesState.length + acceptedFiles.length > 1) {
        enqueueSnackbar(t('i18n.simplephrases.MAX_ONE_FILE'), {
          variant: 'error'
        });
        return;
      }
    }
    if (newFilesState.length + acceptedFiles.length > 4) {
      enqueueSnackbar(t('i18n.simplephrases.MAX_FOUR_FILE'), {
        variant: 'error'
      });
      return;
    }
    acceptedFiles.forEach(async element => {
      if (element.size > fileSizeLimit) {
        enqueueSnackbar(
          `O arquivo '${
            element.path
          }' não pode ser enviado, pois tem mais de ${fileSizeLimit /
            1000000}MB`,
          {
            variant: 'error'
          }
        );
        return;
      }

      if (marketplace === 'AMERICANAS') {
        const attachmentObject = {
          path: element.path || element.name,
          name: element.path || element.name,
          key: element.name,
          content: await convertFileToBase64String(element)
        };
        setFilesToUpload([attachmentObject]);
      }

      if (marketplace === 'CNOVA') {
        const attachmentObject = {
          name: element.path || element.name,
          key: element.path || element.name,
          type: element.type,
          content: await convertFileToBase64String(element)
        };
        setFilesToUpload([attachmentObject]);
      }
    });
  };

  const attachPDFTerm = () => {
    const findTerm = filesToUpload.filter(
      file => file.path === 'termoDevolucaoMercadoriaV2.pdf'
    );

    if (findTerm?.length > 0) {
      enqueueSnackbar(t('i18n.simplephrases.TERM_ALREADY_INSERTED'), {
        variant: 'warning'
      });
      return false;
    }

    fetch('/static/termoDevolucaoMercadoriaV2.pdf')
      .then(res => res.blob()) // Gets the response and returns it as a blob
      .then(blob => {
        const file = new File([blob], 'termoDevolucaoMercadoriaV2.pdf', {
          type: 'application/pdf'
        });
        onDrop([file]);
        enqueueSnackbar(t('i18n.simplephrases.TERM_SUCCESS'), {
          variant: 'success'
        });
      });
  };

  const files = filesToUpload.map((file, index) => (
    <Chip
      key={Math.random()}
      style={{ margin: '2px' }}
      label={file?.path || file?.fileName || file?.name}
      onDelete={() => {
        const newFilesState = [...filesToUpload];
        newFilesState.splice(index, 1);
        setFilesToUpload(newFilesState);
      }}
    />
  ));

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept:
      marketplace === 'CNOVA'
        ? 'image/jpeg,image/png,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        : '*',
    maxFiles: 4
  });

  useEffect(() => {
    onDrop(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <>
      <Section bg style={{ display: 'flex', flexDirection: 'column' }}>
        {['AMERICANAS', 'CNOVA'].includes(marketplace) && (
          <Box
            display="flex"
            justifyContent="flex-end"
            mb={1}
            style={{ gap: '10px' }}
          >
            <StyledTooltip title={t('i18n.ticketcontainer.INSERT_RETURN_TERM')}>
              <SmallButton
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => attachPDFTerm(filesToUpload)}
              >
                <InsertDriveFile />
              </SmallButton>
            </StyledTooltip>

            <Tooltip title={t('i18n.chatview.SEND_ATTACHMENT')}>
              <SmallButton
                size="small"
                variant="contained"
                color="primary"
                {...getRootProps({ className: 'dropzone' })}
              >
                <AttachFileIcon />
              </SmallButton>
            </Tooltip>
          </Box>
        )}
        {marketplace === 'MERCADO LIVRE' && (
          <CustomTextField
            multiline
            className="message-textarea"
            rows={8}
            value={resposta}
            onChange={httmlElemtEvent => {
              setResposta(httmlElemtEvent.target.value);
            }}
            FormHelperTextProps={{
              style: styledHelperText
            }}
            size="small"
            fullWidth
            variant="outlined"
            inputProps={{ maxLength: '350' }}
            helperText={`${resposta?.length}/350`}
          />
        )}
        {marketplace === 'AMERICANAS' && (
          <CustomTextField
            FormHelperTextProps={{
              className: classes.helperText
            }}
            multiline
            className="message-textarea"
            rows={8}
            value={resposta}
            onChange={httmlElemtEvent => {
              setResposta(httmlElemtEvent.target.value);
            }}
            size="small"
            fullWidth
            variant="outlined"
          />
        )}
        {marketplace === 'CNOVA' && (
          <CustomTextField
            FormHelperTextProps={{
              className: classes.helperText
            }}
            multiline
            className="message-textarea"
            rows={4}
            placeholder={t('i18n.simplephrases.DETAILED_DESCRIPTION')}
            value={resposta}
            onChange={httmlElemtEvent => {
              setResposta(httmlElemtEvent.target.value);
            }}
            size="small"
            fullWidth
            variant="outlined"
          />
        )}
        <Box m={1}>
          <input {...getInputProps()} />
          {files.length > 0 && <> {files} </>}
        </Box>
      </Section>
      <Section style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          style={{ width: '320px' }}
          variant="contained"
          color="primary"
          onClick={sendMessage}
          disabled={
            (marketplace === 'CNOVA' && !solution) ||
            (marketplace === 'CNOVA' &&
              ['3592', '3595', '3481'].includes(reasonDetail.split(',')[1]) &&
              newDate === '') ||
            (marketplace === 'CNOVA' && resposta.trim().length === 0)
          }
          esxecd
        >
          {loadingCreate ? (
            <CircularProgress size={20} style={{ color: 'white' }} />
          ) : (
            t('i18n.simplephrases.CREATE_PROTOCOL_SEND_MESSAGE')
          )}
        </Button>
      </Section>
    </>
  );
};

const SearchSection = ({
  marketplace,
  orderNumber,
  setOrderNumber,
  deliveryID,
  setDeliveryID,
  customerCPF,
  setCustomerCPF,
  handleSearch,
  loadingSearch
}) => {
  const { t } = useTranslation();
  const [maskCpfCnpj, setMaskCpfCnpj] = useState('999.999.999-999');

  const styledInput = {
    width: '180px',
    height: '30px',
    fontSize: '12px'
  };

  const handleCpfCnpjMask = value => {
    value = value.replace(/\D/g, '');
    if (value.length > 11) {
      setMaskCpfCnpj('99.999.999/9999-99');
    } else if (value.length <= 11) {
      setMaskCpfCnpj('999.999.999-99');
    }
  };
  return (
    <Box display="flex" flexDirection="column" width="90%">
      <SectionHeader>{t('i18n.simplephrases.SEARCH_BY')}: </SectionHeader>
      <Grid container>
        {marketplace === 'MERCADO LIVRE' && (
          <Grid item xs={3}>
            <TextField
              value={orderNumber}
              placeholder={t('i18n.ticketcontainer.ORDER_NUMBER')}
              size="small"
              variant="outlined"
              onChange={ev => {
                setOrderNumber(ev.target.value);
              }}
              InputProps={{
                style: styledInput
              }}
            />
          </Grid>
        )}
        {marketplace === 'CNOVA' && (
          <Grid item xs={3}>
            <TextField
              value={deliveryID}
              placeholder={t('i18n.ticketcontainer.DELIVERY_NUMBER')}
              size="small"
              variant="outlined"
              onChange={ev => {
                setDeliveryID(ev.target.value);
              }}
              InputProps={{
                style: styledInput
              }}
            />
          </Grid>
        )}
        {marketplace === 'AMERICANAS' && (
          <>
            <Grid item xs={2}>
              <TextField
                value={deliveryID}
                placeholder={t('i18n.ticketcontainer.DELIVERY_NUMBER')}
                size="small"
                variant="outlined"
                onChange={ev => {
                  setDeliveryID(ev.target.value);
                }}
                disabled={!!customerCPF}
                InputProps={{
                  style: styledInput
                }}
              />
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <SectionHeader>ou</SectionHeader>
            </Grid>
            <Grid item xs={2}>
              <InputMask
                onPaste={e =>
                  handleCpfCnpjMask(e.clipboardData.getData('Text'))
                }
                mask={maskCpfCnpj}
                maskChar=""
                size="small"
                value={customerCPF}
                disabled={!!deliveryID}
                onChange={htmlElemtEvent => {
                  setCustomerCPF(htmlElemtEvent.target.value);
                  if (htmlElemtEvent.target.value.length > 14) {
                    setMaskCpfCnpj('99.999.999/9999-99');
                  } else {
                    setMaskCpfCnpj('999.999.999-999');
                  }
                }}
              >
                {() => (
                  <TextField
                    size="small"
                    InputProps={{
                      style: styledInput
                    }}
                    placeholder={t('i18n.simplewords.CUSTOMER_CPF')}
                    variant="outlined"
                    fullWidth
                  />
                )}
              </InputMask>
            </Grid>
          </>
        )}
        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSearch}
          >
            {loadingSearch ? (
              <CircularProgress size={20} style={{ color: 'white' }} />
            ) : (
              t('i18n.ticketslistview.SEARCH')
            )}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const ModalNewTicket = ({
  openState,
  closeParent,
  marketplace,
  refreshTableContent
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openState);
  const [orderNumber, setOrderNumber] = useState('');
  const [customerCPF, setCustomerCPF] = useState('');
  const [deliveryID, setDeliveryID] = useState('');
  const [columns, setColumns] = useState([]);
  const [loading] = useState(false);
  const { t } = useTranslation();
  const [selectedOrder, setSelectedOrder] = useState('');
  const [messageType, setMessageType] = useState('');
  const [reasonCancellation, setReasonCancellation] = useState('');
  const [data, setData] = useState([]);
  const [dataCnova, setDataCnova] = useState({});
  const [reasonGroup, setReasonGroup] = useState('');
  const [reasonDetailList, setReasonDetailList] = useState([]);
  const [actionList, setActionList] = useState([]);
  const [reasonDetail, setReasonDetail] = useState('');
  const [solution, setSolution] = useState(null);
  const [newDate, setNewDate] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const getSolutionsByReasons = async reasonDetailString => {
    if (marketplace !== 'CNOVA') return;

    const reasonId = reasonDetailString.split(',')[1];

    await API.get(
      `/auth/ms-ticket/v1/tickets/cnova/check-create-incident/solutions/${deliveryID}/${dataCnova.orderId}/${reasonId}`
    )
      .then(response => {
        setActionList(response.data.solutions);
        if (response.data.solutions?.length > 0) {
          setSolution(response.data.solutions[0]);
        }
      })
      .catch(error => {
        setActionList([]);

        try {
          const matchedErrorString = error.response.data.params[0].match(
            /\[(.*?)\]/
          )[1];

          const errorObject = JSON.parse(matchedErrorString);
          enqueueSnackbar(errorObject.message, { variant: 'error' });
        } catch {
          //
        }
      });
  };

  const handleSearch = async () => {
    setData([]);
    setDataCnova({});

    let url;

    const cleanedDocument =
      customerCPF.length === 12
        ? customerCPF
            .replace('.', '')
            .replace('.', '')
            .replace('-', '')
        : customerCPF
            .replace('.', '')
            .replace('.', '')
            .replace('.', '')
            .replace('/', '')
            .replace('-', '');

    if (marketplace === 'AMERICANAS') {
      url = `/auth/ms-ticket/v1/tickets/b2w/check-send-message?deliveryId=${deliveryID}&document=${cleanedDocument}`;
    }

    if (marketplace === 'MERCADO LIVRE') {
      url = `/auth/ms-ticket/v1/tickets/mercadolivre/check-create-ticket/${orderNumber}`;
    }

    if (marketplace === 'CNOVA') {
      url = `/auth/ms-ticket/v1/tickets/cnova/check-create-incident/${deliveryID}`;
    }

    let result;
    try {
      setLoadingSearch(true);
      result = await API.get(url);
    } catch (err) {
      if (err?.response?.data?.message) {
        const { key } = err.response.data.message;
        enqueueSnackbar(t(key), { variant: 'error' });
      } else {
        enqueueSnackbar(t('i18n.simplephrases.ERROR'), { variant: 'error' });
      }
      return false;
    } finally {
      setLoadingSearch(false);
    }

    const objResult = result.data;

    if (objResult.orderDate) {
      objResult.orderDate = moment(objResult.orderDate).format('DD/MM/YYYY');
    }

    if (objResult.promisedDate) {
      objResult.promisedDate = moment(objResult.promisedDate).format(
        'DD/MM/YYYY'
      );
    }
    if (marketplace === 'CNOVA') {
      setDataCnova(objResult);
    } else {
      setData([{ ...objResult }]);
    }
  };

  const options = {
    filter: false,
    selectableRows: 'none',
    print: false,
    search: false,
    viewColumns: false,
    download: false,
    onCellClick: (colData, cellMeta) => {
      const row = data[cellMeta.rowIndex];
      setSelectedOrder(row);
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          rowsPerPageOptions={[20]}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: !loading
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: column =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };

  const handleClose = () => {
    closeParent();
    setSelectedOrder('');
    setOpen(false);
    setOrderNumber('');
    setCustomerCPF('');
    setDeliveryID('');
    setData([]);
    setDataCnova({});
    setReasonGroup('');
    setReasonDetail('');
    setNewDate('');
  };

  useEffect(() => {
    setOpen(openState);
  }, [openState]);

  useEffect(() => {
    if (marketplace === 'MERCADO LIVRE') setColumns(mlColumns);
    if (marketplace === 'AMERICANAS') setColumns(americanasColumns);
  }, [marketplace]);

  useEffect(() => {
    if (reasonGroup) {
      const [group, subGroup] = reasonGroup.split(',');
      const detailList = dataCnova.levels
        .find(el => el.name === group)
        .reasons.find(el => Number(el.id) === Number(subGroup))?.reasons;
      setReasonDetailList(detailList);
      if (detailList?.length === 1) {
        setReasonDetail(`${detailList[0].name},${detailList[0].id}`);
      }
    }
  }, [reasonGroup]);

  useEffect(() => {
    if (newDate) {
      setNewDate('');
    }

    if (solution) {
      setSolution(null);
    }

    if (reasonDetail) {
      getSolutionsByReasons(reasonDetail);
    }
  }, [reasonDetail]);

  useEffect(() => {
    setReasonGroup('');
    setReasonDetail('');
    setNewDate('');
    setSolution(null);
  }, [dataCnova]);

  const changeMessageType = e => {
    setMessageType(e.target.value);
  };

  const compare = (a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  const OrderTable = () => (
    <CustomDataTableWrapper style={{ width: '100%' }}>
      <CustomDataTable
        data={data}
        columns={columns}
        options={options}
        selectableRowsOnClick
        className="table-clickable"
      />
    </CustomDataTableWrapper>
  );

  const renameBrand = brandCode => {
    switch (brandCode) {
      case 'CB':
        return 'Casas Bahia';
      case 'PF':
        return 'Ponto Frio';
      case 'EX':
        return 'Extra';
      default:
        return brandCode;
    }
  };

  const OrderHeader = () => (
    <Grid container spacing={1} style={{ padding: '10px' }}>
      <Grid item xs={2}>
        <Box display="flex" flexDirection="column" width="95%">
          <SectionHeader>Marketplace</SectionHeader>
          <SectionHeaderValue>{marketplace}</SectionHeaderValue>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box display="flex" flexDirection="column" width="95%">
          {marketplace === 'CNOVA' ? (
            <>
              <SectionHeader>
                {t('i18n.ticketcontainer.DELIVERY_NUMBER')}
              </SectionHeader>
              <SectionHeaderValue>
                {Object.keys(dataCnova).length > 0 &&
                  dataCnova?.items[0]?.deliveryId}
              </SectionHeaderValue>
            </>
          ) : (
            <>
              <SectionHeader>
                {t('i18n.ticketcontainer.ORDER_NUMBER')}
              </SectionHeader>
              <SectionHeaderValue>{selectedOrder.orderId}</SectionHeaderValue>
            </>
          )}
        </Box>
      </Grid>
      {marketplace !== 'CNOVA' && (
        <Grid item xs={2}>
          <Box display="flex" flexDirection="column" width="95%">
            <SectionHeader>{t('i18n.newticket.ORDER_DATE')}</SectionHeader>
            <SectionHeaderValue>{selectedOrder.orderDate}</SectionHeaderValue>
          </Box>
        </Grid>
      )}
      {marketplace === 'CNOVA' && (
        <>
          <Grid item xs={2}>
            <Box display="flex" flexDirection="column" width="95%">
              <SectionHeader>{t('i18n.ticketslistview.STORE')}</SectionHeader>
              <SectionHeaderValue>
                {Object.keys(dataCnova).length > 0 &&
                  renameBrand(dataCnova?.brand)}
              </SectionHeaderValue>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" width="95%">
              <SectionHeader>{t('i18n.simplewords.CUSTOMER')}</SectionHeader>
              <SectionHeaderValue>
                {Object.keys(dataCnova).length > 0 && dataCnova?.customer}
              </SectionHeaderValue>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );

  const Products = () => {
    return (
      <Grid container spacing={4} style={{ padding: '10px' }}>
        <Grid item xs={9}>
          <Box display="flex" flexDirection="column" width="95%">
            <SectionHeader>
              {t('i18n.b2w.modal.exchangerefund.PRODUCT')}
            </SectionHeader>
            {Object.keys(dataCnova).length > 0 &&
              dataCnova?.items.map(el => {
                return (
                  <SectionHeaderProductValue key={el.id}>
                    {el.name}
                  </SectionHeaderProductValue>
                );
              })}
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box display="flex" flexDirection="column" width="95%">
            <SectionHeader style={{ textAlign: 'center' }}>
              {/* {t('i18n.b2w.modal.exchange.QUANTITY')} */}
              Qtd.
            </SectionHeader>
            {Object.keys(dataCnova).length > 0 &&
              dataCnova?.itemSummary.map(el => {
                return (
                  <SectionHeaderProductValue
                    style={{ textAlign: 'center' }}
                    key={el.id}
                  >
                    {el.quantity}
                  </SectionHeaderProductValue>
                );
              })}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box display="flex" flexDirection="column" width="95%">
            <SectionHeader>Total</SectionHeader>
            {Object.keys(dataCnova).length > 0 &&
              dataCnova?.itemSummary.map(el => {
                return (
                  <SectionHeaderProductValue key={el.id}>
                    {el.total.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                    }) || 'R$ 0,00'}
                  </SectionHeaderProductValue>
                );
              })}
          </Box>
        </Grid>
      </Grid>
    );
  };

  const Reason = () => {
    const styledInput = {
      height: '30px',
      fontSize: '12px'
    };

    const styledInputDate = {
      width: '144px',
      height: '30px',
      fontSize: '12px'
    };

    return (
      <Grid container spacing={1} style={{ padding: '10px' }}>
        <Grid item xs={4}>
          <Box display="flex" flexDirection="column" width="95%">
            <SectionHeader>
              {reasonGroup
                ? `${t('i18n.ticketcontainer.REASON')} > ${
                    reasonGroup.split(',')[0]
                  }`
                : t('i18n.ticketcontainer.REASON')}
            </SectionHeader>
            <FormControl variant="outlined" fullWidth size="small">
              <Select
                native
                style={styledInput}
                value={reasonGroup}
                onChange={event => {
                  setReasonGroup(event.target.value);
                  setReasonDetail('');
                }}
              >
                <option disabled value="">
                  {t('i18n.simplephrases.SELECT_REASON')}
                </option>
                {Object.keys(dataCnova).length > 0 &&
                  dataCnova.levels.sort(formHelper.compareName).map(el => {
                    return (
                      <optgroup key={el.id} label={el.name}>
                        {el.reasons.map(sub => {
                          return (
                            <option key={sub.id} value={`${el.name},${sub.id}`}>
                              {sub.name}
                            </option>
                          );
                        })}
                      </optgroup>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" flexDirection="column" width="95%">
            <SectionHeader>
              {t('i18n.ticketcontainer.REASON_DETAIL')}
            </SectionHeader>
            <FormControl variant="outlined" fullWidth size="small">
              <Select
                native
                style={styledInput}
                value={reasonDetail}
                onChange={event => {
                  setReasonDetail(event.target.value);
                }}
              >
                <option disabled value="">
                  {t('i18n.simplephrases.SELECT_REASON')}
                </option>
                {reasonDetailList.length > 0 &&
                  reasonDetailList.sort(formHelper.compareName).map(el => {
                    return (
                      <option key={el.id} value={`${el.name},${el.id}`}>
                        {el.name}
                      </option>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {marketplace === 'CNOVA' && (
          <Grid item xs={3}>
            <Box display="flex" flexDirection="column" width="95%">
              <SectionHeader>{t('i18n.cnova.phrases.action')}</SectionHeader>
              <FormControl variant="outlined" fullWidth size="small">
                <Select
                  native
                  style={styledInput}
                  value={solution}
                  onChange={event => {
                    setSolution(event.target.value);
                  }}
                >
                  <option disabled value="">
                    {t('i18n.simplephrases.ACTION')}
                  </option>
                  {actionList.length > 0 &&
                    actionList.sort(formHelper.compareName).map(el => {
                      return (
                        <option key={el.id} value={`${el.name},${el.id}`}>
                          {el.name}
                        </option>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        )}
        {['3592', '3595', '3481'].includes(reasonDetail.split(',')[1]) && (
          <Grid item xs={3}>
            <Box display="flex" flexDirection="column" width="95%">
              <SectionHeader>
                {t('i18n.cnova.phrases.newdeliverydate')}
              </SectionHeader>
              <SectionHeaderValue>
                <TextField
                  size="small"
                  type="date"
                  variant="outlined"
                  InputProps={{
                    style: styledInputDate
                  }}
                  value={newDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  onChange={htmlElemtEvent => {
                    setNewDate(htmlElemtEvent.target.value);
                  }}
                />
              </SectionHeaderValue>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  };

  const SendMessageOption = () => {
    const styledForm = {
      '& .MuiOutlinedInput-root': {
        height: '30px',
        fontSize: '12px',
        width: '90%',
        backgroundColor: 'white'
      }
    };

    const styledRadio = {
      '&$checked': {
        color: COLORS.greenText
      }
    };

    return (
      <>
        {marketplace === 'AMERICANAS' ? (
          <Grid container spacing={5} style={{ padding: '20px' }}>
            <Grid item xs={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <SectionHeader marginBottom="1px">
                    {t('i18n.simplephrases.SEND_MESSAGE')}:
                  </SectionHeader>
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={messageType}
                  onChange={changeMessageType}
                  row
                >
                  <FormControlLabel
                    value="CUSTOMER_SELLER"
                    control={
                      <Radio
                        classes={{
                          root: styledRadio,
                          checked: classes.checked
                        }}
                        color="primary"
                      />
                    }
                    label="Cliente"
                  />
                  <FormControlLabel
                    value="B2W_SELLER"
                    control={
                      <Radio
                        classes={{
                          root: styledRadio
                          // checked: classes.checked
                        }}
                        color="primary"
                      />
                    }
                    label="Americanas"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {messageType === 'B2W_SELLER' && (
              <Grid item xs={4}>
                <Box display="flex" flexDirection="column">
                  <SectionHeader marginBottom="10px">
                    Qual o motivo da mensagem?
                  </SectionHeader>
                  <FormControl
                    variant="outlined"
                    // className={classes.inputSelect}
                    style={{ styledForm }}
                  >
                    <Select
                      value={reasonCancellation || ''}
                      onChange={e => {
                        setReasonCancellation(e.target.value);
                      }}
                    >
                      <MenuItem disabled value="">
                        {t('i18n.ticketcontainer.REASON_CONFIRMATION')}
                      </MenuItem>
                      {americanasReasons
                        .sort((a, b) => compare(a.title, b.title))
                        .map(item => (
                          <MenuItem value={item.title} key={Math.random()}>
                            {item.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            )}
          </Grid>
        ) : (
          <div>
            <SectionHeader>
              {t('i18n.simplephrases.SEND_MESSAGE')}:
            </SectionHeader>
            <SectionHeaderValue>
              {t('i18n.simplewords.CUSTOMER')}
            </SectionHeaderValue>
          </div>
        )}
      </>
    );
  };

  return (
    <AcerModal
      classes={classes}
      maxWidth={`${marketplace === 'AMERICANAS' ? 'lg' : 'md'}`}
      fullWidth
      open={open}
      onClose={handleClose}
      modalTitle={`Criar protocolo - ${marketplace}`}
      freeBody
    >
      {!selectedOrder &&
        marketplace !== 'ACER STORE B2B' &&
        marketplace !== 'ACER STORE B2C' && (
          <>
            <Section>
              <SearchSection
                {...{
                  orderNumber,
                  setOrderNumber,
                  customerCPF,
                  setCustomerCPF,
                  deliveryID,
                  setDeliveryID,
                  marketplace,
                  handleSearch,
                  loadingSearch
                }}
              />
            </Section>
            {marketplace === 'CNOVA' ? (
              <>
                <Section bg>
                  <OrderHeader />
                </Section>
                <Section bg style={{ marginBlockStart: '-30px' }}>
                  <Products />
                </Section>
                <Section>
                  <Reason />
                </Section>
                <LoadTextField
                  {...{
                    marketplace,
                    messageType,
                    reasonCancellation,
                    selectedOrder,
                    handleClose,
                    setData,
                    reasonDetail,
                    newDate,
                    dataCnova,
                    solution
                  }}
                />
              </>
            ) : (
              <Section bg>
                <OrderTable />
              </Section>
            )}
          </>
        )}
      {selectedOrder &&
        marketplace !== 'ACER STORE B2C' &&
        marketplace !== 'ACER STORE B2B' && (
          <>
            <Section bg>
              <OrderHeader />
            </Section>
            <Section>
              <SendMessageOption />
            </Section>

            <LoadTextField
              {...{
                marketplace,
                messageType,
                reasonCancellation,
                selectedOrder,
                handleClose,
                setData
              }}
            />
          </>
        )}
      {(marketplace === 'ACER STORE B2B' ||
        marketplace === 'ACER STORE B2C') && (
        <ModalAcerNewTicket
          {...{ handleClose, refreshTableContent, marketplace }}
        />
      )}
    </AcerModal>
  );
};

export default ModalNewTicket;
