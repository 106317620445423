import { createTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import userService from '../services/user.service';
import MAGALU_LOGO from '../assets/images/magalu_logo.png';
import ACER_LOGO from '../assets/images/acer_logo.png';
import CNOVA_LOGO from '../assets/images/Cnova_logo.png';
import MERCADO_LIVRE_LOGO from '../assets/images/logo_mercado_livre.png';
import NETSHOES_LOGO from '../assets/images/logo_netshoes.png';
import B2W_LOGO from '../assets/images/b2w_logo.png';
import CARREFOUR_LOGO from '../assets/images/carrefour_logo.png';
import KABUM_LOGO from '../assets/images/kabum_logo.png';
import COLOMBO_LOGO from '../assets/images/colombo_logo.png';
import VIVO_LOGO from '../assets/images/vivo_logo.png';

export const useMarketplaceList = () => {
  const { t } = useTranslation();

  // fullMediated: Efeito na aba de Mensagens e no Dashboard
  // allowedToSendAttachment: Efeito na aba de Mensagens para exibir botão de Anexo
  // allowedActions: Efeito em Mensageria para exibir botões de ação
  // logo: Logo carregada nos detalhes do protocolo de pós Venda
  // logoStyle: Estilo do logo

  return [
    {
      icon: t('i18n.ticketslistview.ALL_MARKETPLACES_ICON'),
      value: 'All',
      text: t('i18n.ticketslistview.ALL_MARKETPLACES')
    },
    {
      icon: t('i18n.marketplaces.INITIALS.CUSTOMER_GUIDE_B2B'),
      value: 'CUSTOMER_GUIDE_B2B',
      text: 'Acer Store B2B',
      fullMediated: false,
      allowedToSendAttachment: true,
      allowedActions: true,
      logo: ACER_LOGO,
      logoStyle: 'store-acer'
    },
    {
      icon: t('i18n.marketplaces.INITIALS.CUSTOMER_GUIDE'),
      value: 'CUSTOMER_GUIDE',
      text: 'Acer Store B2C',
      fullMediated: false,
      allowedToSendAttachment: true,
      allowedActions: true,
      logo: ACER_LOGO,
      logoStyle: 'store-acer'
    },
    {
      icon: t('i18n.marketplaces.INITIALS.B2W'),
      value: 'B2W',
      text: 'Americanas',
      fullMediated: false,
      allowedToSendAttachment: true,
      allowedActions: true,
      logo: B2W_LOGO,
      logoStyle: 'store-b2w',
      withGrades: true
    },
    {
      icon: t('i18n.marketplaces.INITIALS.CARREFOUR'),
      value: 'CARREFOUR',
      text: 'Carrefour',
      fullMediated: true,
      allowedToSendAttachment: true,
      allowedActions: true,
      logo: CARREFOUR_LOGO,
      logoStyle: 'store-acer'
    },
    {
      icon: t('i18n.marketplaces.INITIALS.COLOMBO'),
      value: 'COLOMBO',
      text: 'Colombo',
      fullMediated: true,
      allowedToSendAttachment: true,
      allowedActions: true,
      logo: COLOMBO_LOGO,
      logoStyle: 'store-acer'
    },
    {
      icon: t('i18n.marketplaces.INITIALS.CNOVA'),
      value: 'CNOVA',
      text: 'Cnova',
      fullMediated: true,
      allowedToSendAttachment: false,
      allowedActions: false,
      logo: CNOVA_LOGO,
      logoStyle: 'store-cnova',
      withGrades: false
    },
    {
      icon: t('i18n.marketplaces.INITIALS.KABUM'),
      value: 'KABUM',
      text: 'Kabum',
      fullMediated: true,
      allowedToSendAttachment: true,
      allowedActions: true,
      logo: KABUM_LOGO,
      logoStyle: 'store-acer'
    },
    {
      icon: t('i18n.marketplaces.INITIALS.MAGALU'),
      value: 'MAGALU',
      text: 'Magalu',
      fullMediated: false,
      allowedToSendAttachment: false,
      allowedActions: true,
      logo: MAGALU_LOGO,
      logoStyle: 'store-ml',
      withGrades: true
    },
    {
      icon: t('i18n.marketplaces.INITIALS.MERCADO_LIVRE'),
      value: 'MERCADO_LIVRE',
      text: 'Mercado Livre',
      fullMediated: false,
      allowedToSendAttachment: true,
      allowedActions: true,
      logo: MERCADO_LIVRE_LOGO,
      logoStyle: 'store-ml',
      withGrades: true
    },
    {
      icon: t('i18n.marketplaces.INITIALS.NETSHOES'),
      value: 'NETSHOES',
      text: 'Netshoes',
      fullMediated: true,
      allowedToSendAttachment: false,
      allowedActions: true,
      logo: NETSHOES_LOGO,
      logoStyle: 'store-netshoes'
    },
    {
      icon: t('i18n.marketplaces.INITIALS.VIVO'),
      value: 'VIVO',
      text: 'Vivo',
      fullMediated: true,
      allowedToSendAttachment: true,
      allowedActions: true,
      logo: VIVO_LOGO,
      logoStyle: 'store-acer'
    }
  ];
};

export const useMarketplaceListValue = () => {
  const marketPlace = useMarketplaceList();

  const marketPlaceFilterString = marketPlace
    .map(item => {
      return item.value;
    })
    .toString();

  return marketPlaceFilterString;
};

export const defaultMarketplacesDashboardFilter =
  sessionStorage.getItem('accessToken') &&
  userService.hasRole('ticket_b2b_list')
    ? ['CUSTOMER_GUIDE']
    : [
        'All',
        'CUSTOMER_GUIDE',
        'B2W',
        'CARREFOUR',
        'COLOMBO',
        'CNOVA',
        'KABUM',
        'MAGALU',
        'MERCADO_LIVRE',
        'NETSHOES',
        'VIVO'
      ];

export const defaultMarketplacesDashboardFilterPresaleOffline = [
  'All',
  'YOURVIEWS',
  'MERCADO_LIVRE'
];

export const defaultMarketplaceScoreFilter = ['B2W'];

export const useMarketplaceListPreSale = () => {
  const { t } = useTranslation();
  return [
    {
      icon: t('i18n.ticketslistview.ALL_MARKETPLACES_ICON'),
      value: 'All',
      text: t('i18n.ticketslistview.ALL_MARKETPLACES')
    },
    {
      icon: t('i18n.marketplaces.INITIALS.CUSTOMER_GUIDE'),
      value: 'YOURVIEWS',
      text: 'Acer Store',
      activePreSale: true
    },
    {
      icon: t('i18n.marketplaces.INITIALS.MERCADO_LIVRE'),
      value: 'MERCADO_LIVRE',
      text: 'Mercado Livre',
      activePreSale: true
    }
  ];
};

export const useMarketplaceListEvaluation = () => {
  return [
    {
      icon: 'AS',
      value: 'YOURVIEWS_REVIEW',
      text: 'Acer Store',
      activePreSale: true
    }
  ];
};

export const getMuiTheme = () =>
  createTheme({
    overrides: {
      MUIDataTable: {
        tableRoot: {
          backgroundColor: 'transparent',
          borderCollapse: 'separate',
          borderSpacing: '0 10px'
        },
        paper: {
          boxShadow: 'none',
          backgroundColor: 'transparent'
        },
        head: {
          backgroundColor: 'transparent'
        }
      },
      MUIDataTableHeadCell: {
        root: {
          borderBottom: 'none',
          backgroundColor: 'transparent !important'
        }
      },
      MuiTableCell: {
        head: {
          backgroundColor: 'transparent !important'
        }
      },
      MuiTableBody: {
        root: {
          backgroundColor: 'white !important'
        }
      }
    }
  });

export const useReportTypesList = () => {
  const { t } = useTranslation();
  const reportTypes = [
    {
      label: `${t('i18n.ticketslistview.AFTER_SALE')}`,
      value: 'pos-venda',
      role: 'dashboard'
    },
    {
      label: `${t('i18n.ticketslistview.AFTER_SALE_VOLUMETRY')}`,
      value: 'volumetria-pos',
      role: 'dashboard'
    },
    {
      label: `${t('i18n.dashboard.mktplacereport.after_sale_custom')}`,
      value: 'pos-venda-custom',
      role: 'dashboard'
    },
    {
      label: `${t('i18n.dashboard.mktplacereport.offline_presale')}`,
      value: 'pre-venda-offline',
      role: 'dashboard'
    },
    {
      label: `${t('i18n.dashboard.mktplacereport.online_presale')}`,
      value: 'pre-venda-online',
      role: 'dashboard'
    },
    {
      label: `${t('i18n.dashboard.mktplacereport.online_presale_custom')}`,
      value: 'pre-venda-online-custom',
      role: 'dashboard'
    },
    {
      label: `Pré-Venda Online - IPs bloqueados`,
      value: 'pre-venda-online-blocked-ips',
      role: 'dashboard'
    },
    {
      label: `${t('i18n.dashboard.mktplacereport.status_users')}`,
      value: 'status-users',
      role: 'dashboard_show_report_status_user'
    },
    {
      label: `${t('i18n.dashboard.mktplacereport.score_mkt')}`,
      value: 'score_mkt',
      role: 'dashboard'
    },
    {
      label: `${t('i18n.dashboard.mktplacereport.composition_score_mkt')}`,
      value: 'composition_score_mkt',
      role: 'dashboard'
    }
  ];

  return reportTypes;
};
