import React from 'react';
import { COLORS } from '../../../../../../../../styles/settings/colors.config';
import { InsideConnector } from '../styles';

export default function ItemConnector({
  position,
  statusCondition,
  endProcess
}) {
  const connectorColor = () => {
    if (statusCondition === 'passed') return COLORS.timeline.passedBorderColor;
    if (endProcess) return COLORS.timeline.passedBorderColor;
    return COLORS.timeline.defaultBorderColor;
  };

  return (
    <>
      {position === 'start' && (
        <InsideConnector
          bgColor={connectorColor || COLORS.timeline.defaultBorderColor}
          width={50}
          left="50%"
        />
      )}
      {position === 'middle' && (
        <>
          <InsideConnector
            bgColor={connectorColor || COLORS.timeline.defaultBorderColor}
            width={50}
            left="50%"
          />
          <InsideConnector
            bgColor={connectorColor || COLORS.timeline.defaultBorderColor}
            width={50}
            right="50%"
          />
        </>
      )}
      {position === 'end' && (
        <InsideConnector
          bgColor={connectorColor || COLORS.timeline.defaultBorderColor}
          width={50}
          right="50%"
        />
      )}
    </>
  );
}
