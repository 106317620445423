import { Box, Card, Divider, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import _ from 'lodash';
import Item from '../Item';

import { COLORS } from '../../../../../../../styles/settings/colors.config';
import { numberToBRL } from '../../../../../../../helpers/utils';

const useStyles = makeStyles(() => ({
  totalLabel: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  totalValue: {
    fontWeight: 'bold',
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '15px',
    color: COLORS.meduimGray
  },
  orangeLabel: {
    display: 'flex',
    color: COLORS.WarningColorSLA,
    fontWeight: 'bold',
    fontSize: '13px',
    alignItems: 'flex-end'
  }
}));

export default function ItemDetails({ details, setDetails, cleanedDetails }) {
  const classes = useStyles();

  const itensNotCancelled = _.differenceWith(
    details?.order?.itens,
    cleanedDetails?.itensToCancel,
    _.isEqual
  );

  const totalTroca = details?.itensToCancel?.reduce(
    (acc, item) => acc + item.valorBruto,
    0
  );

  const totalPedido = details?.order?.itens?.reduce(
    (acc, item) => acc + item.totalBruto,
    0
  );
  const totalDesconto = details?.order?.itens?.reduce(
    (acc, item) => acc + item.totalDesconto,
    0
  );
  const totalPayment =
    totalPedido + (details?.order?.totalFrete || 0.0) - totalDesconto;

  return (
    <Box
      maxWidth="xl"
      style={{
        overflow: 'auto',
        maxHeight: '230px',
        minHeight: '100px',
        marginTop: '10px'
      }}
    >
      <Card style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
        {details?.itensToCancel?.map((item, i) => (
          <Item
            {...{ item, i, setDetails, details }}
            cancelled
            key={Math.random()}
          />
        ))}
        {itensNotCancelled.length > 0 && (
          <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
        )}
        {itensNotCancelled?.map((item, i) => (
          <Item {...{ item, i, setDetails, details }} key={Math.random()} />
        ))}

        <Divider />

        <Grid
          container
          spacing={2}
          style={{
            marginTop: '10px',
            fontFamily: 'Roboto',
            fontSize: '12px'
          }}
        >
          <Grid
            item
            xs={6}
            className={classes.orangeLabel}
            style={{
              justifyContent: 'flex-end'
            }}
          >
            Valor total para troca
          </Grid>

          <Grid
            item
            xs={2}
            className={classes.orangeLabel}
            style={{
              justifyContent: 'center'
            }}
          >
            {numberToBRL(totalTroca)}
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontWeight: 'bold',
              alignItems: 'flex-end'
            }}
          >
            |
          </Grid>
          <Grid item xs={3}>
            <Grid container>
              <Grid item xs={6} className={classes.totalLabel}>
                Total
              </Grid>
              <Grid item xs={6} className={classes.totalValue}>
                {numberToBRL(totalPedido)}
              </Grid>
              <Grid item xs={6} className={classes.totalLabel}>
                Desconto e Cupom
              </Grid>
              <Grid item xs={6} className={classes.totalValue}>
                {numberToBRL(totalDesconto)}
              </Grid>
              <Grid item xs={6} className={classes.totalLabel}>
                Frete
              </Grid>
              <Grid item xs={6} className={classes.totalValue}>
                {numberToBRL(details?.order?.totalFrete)}
              </Grid>
              <Grid item xs={6} className={classes.totalLabel}>
                Total Pago
              </Grid>
              <Grid item xs={6} className={classes.totalValue}>
                {numberToBRL(totalPayment)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}
