import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Error } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import API from '../../../../../../services/api';
import userService from '../../../../../../services/user.service';
import CancelConfirmDialog from '../../Dialogs/CancelConfirm';
import { useStyles } from './Sections/styles';
import CancelOptions from './Sections/CancelOptions';
import OrderInfo from './Sections/OrderInfo';
import Details from './Sections/Details';
import RetrieveInfo from './Sections/RetrieveInfo';
import Actions from './Sections/Actions';

export default function ExpertViewComponent({
  data,
  authorizeDevolution,
  tracking,
  finance,
  view
}) {
  const classes = useStyles();
  const [totalRefund, setTotalRefund] = useState(0);
  const [cancellingData] = useState(data.cancellationProcess);
  const { enqueueSnackbar } = useSnackbar();
  const [trackingNumber, setTrackingNumber] = useState('');
  const [dateReceiptAcer, setDateReceiptAcer] = useState('');
  const [cancellingModal, setCancellingModal] = useState(false);
  const [reason, setReason] = useState('');
  const [reversedBy, setReversedBy] = useState('');
  const [detail, setDetail] = useState('');

  const steps = data?.cancellationProcess?.stepsTimeLine;
  const orderData = data?.cancellationProcess?.order;

  const stepsSize = steps.length;
  const lastStep = steps[stepsSize - 1];

  const handleFinishConference = async (specialistDetail = null) => {
    const newStep = {
      name: 'Conferido pelo especialista',
      date: new Date(),
      reason: null,
      userName: userService.getUsername()
    };

    const json = {
      ...data.cancellationProcess,
      lastStepName:
        data.cancellationProcess.cancellationType === 'ESTORNO'
          ? 'Aguardando efetivação do estorno'
          : 'Aguardando Service',
      specialistDetail,
      isReviewedBySpecialist: true,
      reversedValue: Number(totalRefund.replace('.', '').replace(',', '.')),
      stepsTimeLine: [...data.cancellationProcess.stepsTimeLine, newStep]
    };

    await API.put(`/auth/ms-ticket/v1/cancellationProcess/update`, json);

    enqueueSnackbar(
      `Processo liberado para ${data.cancellationProcess.cancellationType}`,
      {
        variant: 'success'
      }
    );
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  useEffect(() => {
    setTotalRefund(
      Number(cancellingData?.reversedValue).toLocaleString('pt-BR', {
        minimumFractionDigits: 2
      })
    );
  }, []);

  const orderTotalValue =
    orderData?.itens?.reduce(
      (acc, item) => acc + item.valorBruto * item.quantidade,
      0
    ) || 0;

  const requestPI = async () => {
    await API.put(`/auth/ms-ticket/v1/cancellationProcess/updateBySpecialist`, {
      id: data.cancellationProcess.id,
      isPIRequested: true,
      reversedValue: Number(totalRefund.replace('.', '').replace(',', '.'))
    });
    enqueueSnackbar('Processo sinalizado como Aguardando PI', {
      variant: 'success'
    });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const updateTrackingData = async () => {
    const json = {
      ...cancellingData,
      trackingNumber:
        trackingNumber === '' ? cancellingData.trackingNumber : trackingNumber,
      dateReceiptAcer:
        dateReceiptAcer === ''
          ? cancellingData.dateReceiptAcer
          : dateReceiptAcer
    };
    const result = await API.put(
      `/auth/ms-ticket/v1/cancellationProcess/update`,
      json
    );
    if (result.status === 200) {
      enqueueSnackbar('Dados Atualizados', {
        variant: 'success'
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    }
  };

  const handleCancel = async () => {
    const result = await API.put(
      `/auth/ms-ticket/v1/cancellationProcess/cancelProcess/${cancellingData.id}`,
      { reason }
    );
    if (result.status === 200) {
      enqueueSnackbar('Solicitação cancelada com sucesso!', {
        variant: 'success'
      });
      setCancellingModal(false);
      setTimeout(() => {
        window.location.reload();
      }, 200);
    } else {
      enqueueSnackbar('Erro ao cancelar solicitação', {
        variant: 'error'
      });
    }
  };

  const handleReversal = async option => {
    if (option === 'yes' && !reversedBy) {
      enqueueSnackbar(`Escolha por qual meio o estorno vai ser realizado.`, {
        variant: 'error'
      });
      return false;
    }

    if (option === 'no' && !detail) {
      enqueueSnackbar(
        `Preencha o motivo pelo qual o estorno não será realizado.`,
        {
          variant: 'error'
        }
      );
      return false;
    }

    const json = {
      ...cancellingData,
      lastStepName: 'Estorno realizado',
      isReviewedByFinancial: option === 'yes',
      financialDetail: detail,
      reversedBy
    };

    const result = await API.put(
      `/auth/ms-ticket/v1/cancellationProcess/update`,
      json
    );

    if (result.status === 200) {
      enqueueSnackbar(`Processo registrado!`, {
        variant: 'success'
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      enqueueSnackbar(`Erro ao registrar processo!`, {
        variant: 'error'
      });
    }
  };

  return (
    <Box width="100%">
      <CancelOptions {...{ cancellingData }} />
      {cancellingData.isProcessCancelled && (
        <Box padding="5px 10px">
          <Alert severity="warning" className={classes.alert} icon={<Error />}>
            Processo cancelado por {lastStep.userName}. Motivo:{' '}
            {lastStep.reason}
          </Alert>
        </Box>
      )}

      <OrderInfo
        {...{
          orderData,
          cancellingData,
          tracking,
          lastStep,
          setTrackingNumber,
          setDateReceiptAcer
        }}
      />

      <Details
        {...{
          orderData,
          cancellingData,
          orderTotalValue,
          tracking,
          finance,
          view,
          authorizeDevolution,
          totalRefund,
          setTotalRefund
        }}
      />

      {finance && !cancellingData.isReviewedByFinancial && (
        <RetrieveInfo {...{ reversedBy, setReversedBy, detail, setDetail }} />
      )}

      <Actions
        {...{
          tracking,
          finance,
          view,
          cancellingData,
          handleReversal,
          updateTrackingData,
          setCancellingModal,
          requestPI,
          handleFinishConference
        }}
      />

      <CancelConfirmDialog
        open={cancellingModal}
        setOpen={setCancellingModal}
        {...{ handleCancel, reason, setReason, cancellingData }}
      />
    </Box>
  );
}
