import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AcerModal from '../../../../../components/AcerModal';

const ConfirmationModal = ({
  openState,
  closeParent,
  submitConfirmation,
  protocolName
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(openState);

  const handleClose = () => {
    closeParent();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(openState);
  }, [openState]);

  return (
    <AcerModal
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={handleClose}
      modalTitle={t('i18n.ticketslistview.NEW_GUIDE_TICKET')}
      hasAction
      confirmActionLabel={t('i18n.simplewords.CONFIRM')}
      confirmActionFunc={() => submitConfirmation()}
      cancelActionLabel={t('i18n.simplewords.CANCEL')}
    >
      <Typography>
        {t('i18n.ticketcontainer.MAGALU_CONFIRMATION')}{' '}
        <strong>{protocolName.toLowerCase()}</strong>?
      </Typography>
    </AcerModal>
  );
};

export default ConfirmationModal;
