import React, { useState } from 'react';

import { ChatWrapper } from './NewChatWrapper';
import { ChatText } from './NewChatText';

let skt = null;
let clInAtt = null;

window.addEventListener('focus', () => {
  if (skt && clInAtt.hashConnection) {
    skt.sendMessageControl(
      { message: 'ViewedByAttendant' },
      clInAtt.hashConnection
    );
  }
});

const ChatContext = ({ socket, clientInAttendance }) => {
  skt = socket;
  clInAtt = clientInAttendance;
  const [attendantMessage, setAttendantMessage] = useState({});

  return (
    <div id="chat-context">
      <ChatWrapper
        socket={socket || undefined}
        clientInAttendance={clientInAttendance}
        attendantMessage={attendantMessage}
      />
      <ChatText
        socket={socket || undefined}
        clientInAttendance={clientInAttendance}
        setAttendantMessage={setAttendantMessage}
      />
    </div>
  );
};

export { ChatContext };
