import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { ChatsListContext } from './context';

import Page from '../../../../components/Page';
import { StyledSpinner } from '../../../../styles/components/SpinnerDefault';

import FilterDrawer from './filterDrawer.component';
import DataTableComponent from './datatable.component';

import { defaultFilterFactory } from './filter.config';

import API from '../../../../services/api';

import FetchContentError from '../../../../components/FetchContentError';

import { searchB2bOnCurrentUrlV2 } from '../../../../utils/b2bHelper';

import {
  formatDateToPayload,
  formatEndDateToPayloadV2
} from '../../../../components/Date.component';

const ChatHistoryView = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(defaultFilterFactory(true));
  const [currentPage, setCurrentPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalElements, setTotalElements] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [hasFetchError, setFetchError] = useState(false);

  const factorySortParams = sort => {
    if (sort) {
      const value = Object.values(sort);
      return `${value[0]},${value[1]}`;
    }

    return '';
  };

  const factoryPayload = body => {
    const statusMinusAll = body?.status.filter(item => {
      return item !== 'All';
    });

    const payload = {
      ...body,
      customerSatisfaction: body.customerSatisfaction
        ? body.customerSatisfaction
        : undefined,
      status: statusMinusAll || null,
      createdIni: !body?.drillDown
        ? formatDateToPayload(body?.creationDate?.split('-')[0])
        : body?.createdIni,
      createdFim: !body?.drillDown
        ? formatEndDateToPayloadV2(body?.creationDate?.split('-')[1])
        : body?.createdFim,
      creationDate: null,
      firstResponseTimeInSeconds:
        body.firstResponseTimeInSeconds &&
        parseInt(body.firstResponseTimeInSeconds, 10)
    };

    delete payload.ruleOperator;

    return payload;
  };

  const refreshTableContent = async (page, sort) => {
    setCurrentPage(page);
    setLoading(true);

    if (filter?.status?.length === 0) {
      enqueueSnackbar(t('i18n.chatview.STATUS_INFO'), {
        variant: 'warning'
      });
      setLoading(false);
      return;
    }

    const sortParams = factorySortParams(sort);
    const payload = factoryPayload(filter);
    
    try {
      const response = await API.post(
        `/auth/ms-chat/chats/filter-chats?businessType=${searchB2bOnCurrentUrlV2()}&page=${page}&size=${size}&sort=${sortParams}`,
        payload
      );

      const mData = response.data;

      if (mData._embedded) {
        setData(mData._embedded.chatResourceList);
      } else {
        setData([]);
      }

      setTotalElements(mData?.page?.totalElements);
      setTotalPages(mData?.page?.totalPages);
      setSize(mData?.page?.size);

      sessionStorage.setItem('historyFilter', JSON.stringify(filter));
    } catch (error) {
      setFetchError(true);
    } finally {
      setLoading(false);
    }
  };

  const context = {
    loading,
    data,
    refreshTableContent,
    filter,
    setFilter,
    currentPage,
    totalPages,
    totalElements,
    size
  };

  useEffect(() => {
    refreshTableContent(0);
  }, [filter]);

  if (hasFetchError) {
    return <FetchContentError fetch={refreshTableContent} />;
  }

  return (
    <ChatsListContext.Provider value={context}>
      <Page>
        <FilterDrawer
          {...{ showList: true, filter, setFilter, fieldName: 'status' }}
        />
        <Container maxWidth={false}>
          <Box mt={3} ml={7}>
            <DataTableComponent />
            <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
              <CircularProgress />
            </StyledSpinner>
          </Box>
        </Container>
      </Page>
    </ChatsListContext.Provider>
  );
};

export default ChatHistoryView;
