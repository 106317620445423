const presaleOnlineDashboardFilter = {};
const presaleOnlineDashboardEquipData = {};
const presaleOnlineDashboardIndividualData = {};
const presaleOnlineDashboardLoading = true;
const presaleOnlineDashboardRemovedKey = '';

const presaleOnlineDashboardEquipDataReducer = (
  state = presaleOnlineDashboardEquipData,
  action
) => {
  switch (action.type) {
    case 'SET_PRESALE_ONLINE_DASHBOARD_EQUIP_DATA':
      state = action.equipData;
      return state;
    default:
      return state;
  }
};

const presaleOnlineDashboardRemovedKeyReducer = (
  state = presaleOnlineDashboardRemovedKey,
  action
) => {
  switch (action.type) {
    case 'SET_PRESALE_ONLINE_DASHBOARD_REMOVED_KEY':
      state = action.key;
      return state;
    default:
      return state;
  }
};

const presaleOnlineDashboardIndividualDataReducer = (
  state = presaleOnlineDashboardIndividualData,
  action
) => {
  switch (action.type) {
    case 'SET_PRESALE_ONLINE_DASHBOARD_INDIVIDUAL_DATA':
      state = action.individualData;
      return state;
    default:
      return state;
  }
};

const presaleOnlineDashboardLoadingReducer = (
  state = presaleOnlineDashboardLoading,
  action
) => {
  switch (action.type) {
    case 'SET_PRESALE_ONLINE_DASHBOARD_LOADING':
      state = action.loading;
      return state;
    default:
      return state;
  }
};

const presaleOnlineDashboardFilterReducer = (
  state = presaleOnlineDashboardFilter,
  action
) => {
  switch (action.type) {
    case 'SET_PRESALE_ONLINE_DASHBOARD_FILTER':
      state = action.filter;
      return state;
    default:
      return state;
  }
};

export {
  presaleOnlineDashboardFilterReducer,
  presaleOnlineDashboardLoadingReducer,
  presaleOnlineDashboardEquipDataReducer,
  presaleOnlineDashboardIndividualDataReducer,
  presaleOnlineDashboardRemovedKeyReducer
};
