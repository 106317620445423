/* eslint-disable no-await-in-loop */
import { Subject } from 'rxjs';
import config from './config';

class GeneralWebSocket {
  static instance = null;

  constructor() {
    this.url = config.websocket.generalurl;
    this.identificationMsg = { action: 'sendmessage', identifier: 'abo' };
    this.generalMessageSubject = new Subject();
    this.closeGeneralMessageSubject = new Subject();
    this.errorGeneralMessageSubject = new Subject();
    const token = localStorage.getItem('accessToken');
    this.socket = new WebSocket(`${this.url}?authorization=${token}`);
    this.socket.onopen = event => {
      this.onConnectionOpen(event);
    };
  }

  async heartBeat() {
    // eslint-disable-next-line
    while (true) {
      const wait = seconds =>
        new Promise(resolve => {
          setTimeout(resolve, seconds);
        });
      await wait(60000).then(() => {
        this.send({ action: 'heartbeat' });
      });
    }
  }

  static getConnection() {
    if (this.instance) {
      return this.instance;
    }
    this.instance = new GeneralWebSocket();
    return this.instance;
  }

  onConnectionOpen() {
    this.send(this.identificationMsg);
    this.setOnMessage();
    this.setOnClose();
    this.setOnError();
    this.heartBeat();
  }

  send(message, callback) {
    this.waitForConnection(() => {
      this.socket.send(JSON.stringify(message));
      if (typeof callback !== 'undefined') {
        callback();
      }
    }, 1000);
  }

  waitForConnection(callback, interval) {
    if (this.socket.readyState === 1) {
      callback();
    } else {
      setTimeout(() => {
        this.waitForConnection(callback, interval);
      }, interval);
    }
  }

  setOnMessage() {
    this.socket.onmessage = event => {
      this.generalMessageSubject.next(event);
    };
  }

  setOnClose() {
    this.socket.onclose = event => {
      this.closeGeneralMessageSubject.next(event);
    };
  }

  setOnError() {
    this.socket.onerror = event => {
      this.errorGeneralMessageSubject.next(event);
    };
  }
}

export default GeneralWebSocket;
