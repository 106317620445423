import React from 'react';

import { Tooltip, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ThumbDown, ThumbUp, PhoneIphone, Refresh } from '@material-ui/icons';

import i18n from '../../../../i18n';
import timezoneFormat from '../../../../utils/timezoneFormat';
import { COLORS } from '../../../../styles/settings/colors.config';
import tableHelper from '../../../../utils/tableHelper';
import CustomFooter from '../../../../styles/components/CustomFooter';

const defaultColumOptions = {
  filter: false,
  sort: false
};

function translateStatus(label) {
  switch (label) {
    case 'CLOSED':
      return i18n.t('i18n.chatview.STATUS_CLOSED');
    case 'ANSWERD':
      return i18n.t('i18n.chatview.STATUS_ANSWERD');
    case 'WAITING_QUEUE':
      return i18n.t('i18n.chatview.STATUS_WAITING_QUEUE');
    case 'WAITING_ANSWER':
      return i18n.t('i18n.chatview.STATUS_WAITING_ANSWER');
    case 'LOSE_CLERK':
      return i18n.t('i18n.chatview.STATUS_LOSE_CLERK');
    default:
      return label;
  }
}

export const ColumnsConfig = ({ t }) => {
  return [
    {
      name: 'businessType',
      label: t('i18n.chat.BUSINESS_TYPE'),
      options: defaultColumOptions
    },
    {
      name: 'created',
      label: '',
      options: {
        sort: true,
        customBodyRender: (value, rowData) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <strong>{timezoneFormat(rowData?.rowData[1])}</strong>
              {rowData?.rowData[2] && (
                <Tooltip title="Origem do chat : Aplicativo Mobile Acer Store">
                  <PhoneIphone />
                </Tooltip>
              )}
            </div>
          );
        },
        customHeadLabelRender: () => {
          return (
            <div className="creationDefaultHeader">
              {t('i18n.ticketslistview.OPENING_DATE_CHAT')}
            </div>
          );
        }
      }
    },
    {
      name: 'mobile',
      label: ' ',
      options: defaultColumOptions
    },
    {
      name: 'statusChat',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return (
            <>
              <strong>{translateStatus(value)}</strong>
            </>
          );
        }
      }
    },
    {
      name: 'name',
      label: t('i18n.ticketcontainer.CLIENT_NAME'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, rowData) => {
          const hash = rowData.rowData[11];
          return (
            <>
              <strong key={value}>
                <Link
                  style={{ color: '#000' }}
                  to={`/chat-container/${hash}`}
                  rel="noopener"
                  target="_blank"
                >
                  {value}
                </Link>
              </strong>
            </>
          );
        }
      }
    },
    {
      name: 'email',
      label: 'E-mail',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'chatCustomerOpinion',
      label: t('i18n.simplewords.RATING'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => {
          if (value?.satisfactionScore === true) {
            return <ThumbUp color="primary" />;
          }
          if (value?.satisfactionScore === false) {
            return <ThumbDown style={{ color: COLORS.btnDangerColor }} />;
          }

          return <span>&nbsp;&nbsp;&nbsp;&nbsp;-</span>;
        }
      }
    },
    {
      name: 'contactingMainReason',
      label: t('i18n.ticketcontainer.REASON'),
      options: {
        customBodyRender: value => {
          return <span>{value}</span>;
        }
      }
    },
    {
      name: 'contactingSubReason',
      label: t('i18n.ticketcontainer.REASON_DETAIL'),
      options: {
        customBodyRender: value => {
          return <span>{value}</span>;
        }
      }
    },
    {
      name: 'login',
      label: t('i18n.chatview.SPECIALIST'),
      options: defaultColumOptions
    },
    {
      name: 'closed_by_clerk',
      label: 'Encerrado por',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => {
          if (value) {
            return <p>Analista</p>;
          }
          return <p>Cliente</p>;
        }
      }
    },
    {
      name: 'hashConnection',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return <span style={{ display: 'none' }}>{value}</span>;
        }
      }
    }
  ];
};

export const OptionsConfig = ({
  t,
  loading,
  data,
  currentPage,
  totalElements,
  size,
  refreshTableContent,
  history
}) => {
  return {
    page: currentPage,
    rowsPerPage: size,
    count: totalElements,
    rowsPerPageOptions: [20],

    serverSide: true,
    pagination: true,

    filter: false,
    viewColumns: false,
    selectableRows: false,
    print: false,
    search: false,
    download: false,

    onTableChange: (action, tableState) => {
      if (action === 'changePage' || action === 'changeRowsPerPage') {
        if (currentPage !== tableState.page) {
          refreshTableContent(tableState.page, tableState.sortOrder);
        }
      }
      if (action === 'sort') {
        refreshTableContent(tableState.page, tableState.sortOrder);
      }
    },
    customToolbarSelect: () => {},
    setRowProps: (row, index) => ({
      style: tableHelper.setRowStyle(row, index, data)
    }),
    onCellClick: (colData, cellMeta) => {
      if (cellMeta.colIndex === 4) {
        return () => {};
      }
      const { hashConnection } = data[cellMeta.rowIndex];
      history.push(`../chat-container/${hashConnection}`);
    },
    customToolbar: () => {
      return (
        <Tooltip title="Atualizar">
          <IconButton onClick={() => refreshTableContent()} aria-label="delete">
            <Refresh />
          </IconButton>
        </Tooltip>
      );
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          rowsPerPageOptions={[20]}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: !loading
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: column =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };
};

export const FilterFormData = {
  search: { value: '', label: 'Palavra-chave' },
  customerSatisfaction: { value: '', label: 'Avaliação' },
  hasOpinion: { value: '', label: 'Comentário na avaliação' },
  responseTimeRelationalOperator: { value: '', label: 'Tempo de resposta' },
  firstResponseTimeInSeconds: { value: '', label: 'Segundos' },
  ruleOperator: { value: '', label: 'Tempo de resposta' },
  name: { value: '', label: 'i18n.ticketcontainer.CLIENT_NAME' },
  email: { value: '', label: 'E-mail' },
  mobile: { value: '', label: 'i18n.ticketcontainer.BY_APP' },
  login: {
    value: '',
    label: 'i18n.ticketslistview.ATTENDANT'
  },
  contactingMainReason: {
    value: '',
    label: 'i18n.ticketcontainer.REASON'
  },
  contactingSubReason: {
    value: '',
    label: 'i18n.ticketcontainer.REASON_DETAIL'
  },
  creationDate: {
    value: '',
    label: 'i18n.ticketslistview.OPENING_DATE_CHAT'
  },
  createdIni: {
    value: '',
    label: 'Data de início'
  },
  createdFim: {
    value: '',
    label: 'Data fim'
  },
  status: { value: '', label: 'i18n.ticketslistview.STATUS' }
};
