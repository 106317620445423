export const gotoBottom = () => {
  const element = document.getElementById('message-box');
  setTimeout(() => {
    if (element) {
      element.scrollTop = element.scrollHeight - element.clientHeight;
    }
  }, 200);
};

export const submitAttachment = (
  file,
  enqueueSnackbar,
  t,
  setIsSendingAttachment,
  setSubmitAttachmentFile
) => {
  if (file.size > 2000000) {
    enqueueSnackbar(
      t('i18n.newticket.THE_IMAGE') + t('i18n.newticket.CANNOT_BE_SENT'),
      {
        variant: 'error'
      }
    );
    setIsSendingAttachment(true);
    return;
  }
  setIsSendingAttachment(false);

  const reader = new FileReader();
  const base64file = [];
  reader.readAsDataURL(file);
  reader.onloadend = evt => {
    if (evt.target.readyState === FileReader.DONE) {
      base64file.push(evt.currentTarget.result);
    }

    const submitFile = {
      name: file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
      content: base64file[0].split(',')[1]
    };

    setSubmitAttachmentFile(submitFile);
  };
};

export const findCurrentCustomerReason = (
  customersReasons,
  clientInAttendance
) => {
  return customersReasons.find(
    customerReason =>
      customerReason.customer === clientInAttendance.hashConnection
  );
};
