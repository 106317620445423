import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { COLORS } from '../../../styles/settings/colors.config';
import {
  brtToUTC,
  convertToInput,
  defaultDateFormat
} from '../services/convertDate';

import indexHook from '../indexHook';
import {
  byPeriodValues,
  defaultHoursNumber,
  listHours
} from '../services/config';
import { WEEK_INTERVAL } from '../services/constants';

const calcMaxPreSaleDateMarch = (startDate) => {
  const convertDate = moment(startDate).add('hours', 3);
  // const convertDateAdd30Days = new Date(
  //   convertDate.setDate(convertDate.getDate() + 30)
  // );
  return convertDate
    .add('days', convertDate.daysInMonth() - 1)
    .format('YYYY-MM-DD');
  // return moment(convertDateAdd30Days).format('YYYY-MM-DD');
};

const calcMaxPreSaleDate = (startDate, tabView) => {
  const shouldCalc = ['presaleOnlineTab', 'presaleOnlineB2BTab'].includes(
    tabView
  );

  if (startDate && shouldCalc) {
    return calcMaxPreSaleDateMarch(startDate);
  }

  return null;
};

const InputDate = ({
  localFilterData,
  setLocalFilterData,
  setBlockButton,
  notShowRadio = false,
  blockDate = '2021-09-30'
}) => {
  const { t } = indexHook();
  const { tabView } = localFilterData ?? {};

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [periodType, setPeriodType] = useState('realTime');
  const [hourlyValue, setHourlyValue] = useState(defaultHoursNumber.toString());
  const [byPeriodValue, setByPeriodValue] = useState(byPeriodValues[0]);

  useEffect(() => {
    const strtDt = new Date(blockDate);
    const endDt = new Date(startDate);

    if (endDt <= strtDt && periodType !== 'realTime') {
      setBlockButton(true);
    } else {
      setBlockButton(false);
    }

    if (startDate) {
      setLocalFilterData({
        ...localFilterData,
        ...{ startDate: defaultDateFormat(brtToUTC(startDate, 'start')) }
      });
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      setLocalFilterData({
        ...localFilterData,
        ...{
          endDate: defaultDateFormat(brtToUTC(endDate, 'end'))
        }
      });
    }
  }, [endDate]);

  useEffect(() => {
    if (localFilterData?.periodType === 'custom') {
      const start = convertToInput(localFilterData?.startDate, 'start');
      const end = convertToInput(localFilterData?.endDate, 'end');

      setStartDate(start);
      setEndDate(end);
    }

    setPeriodType(localFilterData?.periodType || 'realTime');
    setHourlyValue(localFilterData?.dateAliasInAWayHumanCanRead);
    setByPeriodValue(localFilterData?.dateAliasInAWayHumanCanRead);
  }, [localFilterData]);

  const limitDateText = () => {
    if (localFilterData?.tabView === 'marketplaceScoreTab') {
      if (localFilterData?.marketplace.includes('MERCADO_LIVRE')) {
        return t('i18n.dashboard.START_DATE_ALERT_SCORE_TODAY_MERCADO_LIVRE');
      }
      return t('i18n.dashboard.START_DATE_ALERT_SCORE_TODAY');
    }
    return t('i18n.dashboard.START_DATE_ALERT');
  };

  const limitDateCustomText = () => {
    if (localFilterData?.tabView === 'marketplaceScoreTab') {
      if (localFilterData.marketplace.includes('MERCADO_LIVRE')) {
        return t('i18n.dashboard.START_DATE_ALERT_SCORE_MERCADO_LIVRE');
      }
      return t('i18n.dashboard.START_DATE_ALERT_SCORE');
    }
    return t('i18n.dashboard.START_DATE_ALERT');
  };

  const isAbleToShowLastSevenDaysFilters = React.useMemo(() => {
    return [
      'dashboardTab',
      'messageDashboardTab',
      'presaleOfflineTab',
      'presaleOnlineTab',
      'presaleOnlineB2BTab'
    ].includes(tabView);
  }, [tabView]);

  const removeSixMonthAndYearPeriodsWhenViewIsEqualsPostSale = (
    period,
    view
  ) => {
    const isPostSaleView =
      view === 'dashboardTab' || view === 'messageDashboardTab';
    const isCurrentPeriodAbleToRemove =
      period === 'last_six_months' || period === 'in_current_year';

    return !(isPostSaleView && isCurrentPeriodAbleToRemove);
  };

  return (
    <>
      {!notShowRadio && (
        <>
          <Typography>{t('i18n.dashboard.timefilter.title')}</Typography>
          <Box mb={2} display="flex" flexDirection="row">
            <FormControl component="fieldset">
              <RadioGroup
                value={periodType}
                onChange={(ev) => {
                  setPeriodType(ev.target.value);
                  setHourlyValue('7');
                  setByPeriodValue(byPeriodValues[0]);
                }}
              >
                <Box display="flex" flexDirection="column">
                  {[
                    'dashboardTab',
                    'messageDashboardTab',
                    'marketplaceScoreTab'
                  ].includes(localFilterData?.tabView) && (
                    <FormControlLabel
                      value="realTime"
                      control={<Radio color="primary" />}
                      label={t('i18n.dashboard.timefilter.realTime')}
                      onClick={() => {
                        setPeriodType('realTime');
                        setStartDate('2021-09-30');
                        setLocalFilterData({
                          ...localFilterData,
                          ...{
                            periodType: 'realTime',
                            dateAliasInAWayHumanCanRead: 'realTime',
                            isRealTime: true,
                            endDate: undefined,
                            status: ''
                          }
                        });
                        setBlockButton(false);
                      }}
                    />
                  )}
                  {periodType === 'realTime' && (
                    <Typography
                      style={{
                        color: COLORS.WarningColorSLA,
                        fontWeight: 'semibold',
                        fontSize: '14px'
                      }}
                    >
                      {limitDateText()}
                    </Typography>
                  )}
                  {isAbleToShowLastSevenDaysFilters && (
                    <FormControlLabel
                      value="weekly"
                      control={<Radio color="primary" />}
                      label={t('i18n.dashboard.timefilter.last_seven_days')}
                      onClick={() => {
                        setPeriodType('weekly');
                        setLocalFilterData({
                          ...localFilterData,
                          ...{
                            periodType: 'weekly',
                            isRealTime: false,
                            dateAliasInAWayHumanCanRead: byPeriodValues[5],
                            startDate: WEEK_INTERVAL.startDate,
                            endDate: WEEK_INTERVAL.endDate
                          }
                        });
                      }}
                    />
                  )}
                  {localFilterData?.tabView !== 'marketplaceScoreTab' &&
                    localFilterData?.tabView !== 'presaleOnlineTab' &&
                    localFilterData?.tabView !== 'presaleOnlineB2BTab' && (
                      <>
                        <FormControlLabel
                          value="hourly"
                          control={<Radio color="primary" />}
                          label={t('i18n.dashboard.timefilter.hourly')}
                          onClick={() => {
                            setPeriodType('hourly');
                            setLocalFilterData({
                              ...localFilterData,
                              ...{
                                periodType: 'hourly',
                                isRealTime: false,
                                dateAliasInAWayHumanCanRead: '7'
                              }
                            });
                            setHourlyValue('7');
                          }}
                        />
                        <FormControlLabel
                          value="byperiod"
                          control={<Radio color="primary" />}
                          label={t('i18n.dashboard.timefilter.byperiod')}
                          onClick={() => {
                            setPeriodType('byperiod');
                            setLocalFilterData({
                              ...localFilterData,
                              ...{
                                periodType: 'byperiod',
                                isRealTime: false,
                                dateAliasInAWayHumanCanRead: byPeriodValues[0]
                              }
                            });
                            setByPeriodValue(byPeriodValues[0]);
                          }}
                        />
                      </>
                    )}

                  <FormControlLabel
                    value="custom"
                    control={<Radio color="primary" />}
                    label={t('i18n.dashboard.timefilter.custom')}
                    onClick={() => {
                      setPeriodType('custom');
                      setLocalFilterData({
                        ...localFilterData,
                        ...{
                          dateAliasInAWayHumanCanRead: 'custom',
                          periodType: 'custom',
                          startDate: '',
                          endDate: '',
                          isRealTime: false
                        }
                      });
                      setEndDate('');
                      setStartDate('');
                    }}
                  />
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
        </>
      )}
      {periodType === 'hourly' && (
        <Box mb={2}>
          <FormControl size="small" variant="outlined" fullWidth>
            <InputLabel>{t('i18n.dashboard.timefilter.hourly')}</InputLabel>
            <Select
              size="small"
              value={hourlyValue}
              onChange={(htmlElemtEvent) => {
                setHourlyValue(htmlElemtEvent.target.value);
                setLocalFilterData({
                  ...localFilterData,
                  dateAliasInAWayHumanCanRead: htmlElemtEvent.target.value
                });
              }}
              label={t('i18n.dashboard.timefilter.hourly')}
            >
              {listHours.map((hour) => {
                return (
                  <MenuItem key={hour} value={`${hour}`}>
                    {hour} {t('i18n.dashboard.timefilter.hours_ago')}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      )}
      {periodType === 'byperiod' && (
        <Box mb={2}>
          <FormControl size="small" variant="outlined" fullWidth>
            <InputLabel>{t('i18n.dashboard.timefilter.byperiod')}</InputLabel>
            <Select
              size="small"
              value={byPeriodValue}
              onChange={(htmlElemtEvent) => {
                setByPeriodValue(htmlElemtEvent.target.value);
                setLocalFilterData({
                  ...localFilterData,
                  dateAliasInAWayHumanCanRead: htmlElemtEvent.target.value
                });
              }}
              label={t('i18n.dashboard.timefilter.byperiod')}
            >
              {byPeriodValues
                .filter((period) =>
                  removeSixMonthAndYearPeriodsWhenViewIsEqualsPostSale(
                    period,
                    tabView
                  )
                )
                .map((period) => {
                  return (
                    <MenuItem key={period} value={`${period}`}>
                      {t(`i18n.dashboard.timefilter.${period}`)}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>
      )}
      {periodType === 'custom' &&
        localFilterData?.tabView !== 'marketplaceScoreTab' && (
          <Box>
            <Grid container className="mY-3" spacing={2}>
              <Grid item lg={6}>
                <TextField
                  id="date"
                  variant="outlined"
                  label={t('i18n.dashboard.START_DATE')}
                  type="date"
                  required
                  format="dd/MM/yyyy"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item lg={6}>
                <TextField
                  id="date"
                  variant="outlined"
                  label={t('i18n.dashboard.END_DATE')}
                  type="date"
                  required
                  format="dd/MM/yyyy"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    inputProps: {
                      max: calcMaxPreSaleDate(startDate, tabView)
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      {periodType === 'custom' &&
        localFilterData.tabView === 'marketplaceScoreTab' && (
          <Box>
            <TextField
              id="date"
              variant="outlined"
              label="Data"
              type="date"
              required
              format="dd/MM/yyyy"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ inputProps: { min: '2023-01-19' } }} // Change this date when release 5 be lauched
            />

            <Typography
              style={{
                color: COLORS.WarningColorSLA,
                fontWeight: 'semibold',
                fontSize: '14px'
              }}
            >
              {limitDateCustomText()}
            </Typography>

            {localFilterData?.tabView === 'marketplaceScoreTab' && (
              <Typography
                style={{
                  color: COLORS.WarningColorSLA,
                  fontWeight: 'semibold',
                  fontSize: '14px'
                }}
              >
                Dados disponíveis a partir da data inicial em 19/01/2023.
              </Typography>
            )}
          </Box>
        )}
    </>
  );
};

export default InputDate;
