import { TextField } from '@material-ui/core';
import styled from 'styled-components';

export const StyledInputWhiteBg = styled(TextField)`
  border: 1px solid #e2e2e1;
  overflow: hidden;
  border-radius: 4px;
  background-color: #fff;
  .MuiInputBase-root {
    background: white;
  }
`;
