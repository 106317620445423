/*eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import userService from '../../../../services/user.service';
import {
  Tooltip,
  Typography,
  IconButton,
} from '@material-ui/core';
import { LockOpen, Lock, Check } from '@material-ui/icons';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import AcerModal from '../../../../components/AcerModal';

import { BlockedIPsContext } from '../../BlockedIPs/context'

import SelectComponent from '../../../../components/Select.component';

import API from '../../../../services/api';

const handleUnblockFactory = ({ user, reason }) => {
  const dateNow = moment(new Date()).add({ hours: 3 }).format('YYYY-MM-DDTHH:mm:ss');
  const data = {
    ...user,
    ...{
      unblockedReason: reason,
      unblockedDate: dateNow,
      blockedLogging: [],
      unblockedByUser: userService.getUsername(),
      isBlocked: false
    }
  }
  return data;
}

export const useUnBlockUserHooks = ({ refreshTableContent }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleUnBlock = async ({ user, reason }) => {

    const data = handleUnblockFactory({ user, reason });

    if (user?.ip) {
      await API.post(
        `/auth/ms-chat/chats/unblock`,
        data
      ).catch((err) => {
        enqueueSnackbar('Não foi possível desbloquear esse IP.', { variant: "error" })
        console.log(err.response);
      }).finally(() => {
        refreshTableContent();
      });
    }
  };

  return {
    handleUnBlock
  };
};

export const UnBlockReasonModal = ({
  showUnBlockReasonModal,
  setShowUnBlockReasonModal,
  setOpenModal,
  user
}) => {

  const { refreshTableContent } = useContext(BlockedIPsContext);
  const { handleUnBlock } = useUnBlockUserHooks({ refreshTableContent });

  const [form, setForm] = useState({});

  const handleSetForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    setForm({})
  }, [])

  return (
    <AcerModal
      fullWidth
      open={showUnBlockReasonModal}
      maxWidth="sm"
      modalTitle="Motivo do Desbloqueio"
      hasAction
      confirmActionFunc={() => {
        handleUnBlock({ user, reason: form.reason })
        setShowUnBlockReasonModal(false);
        setOpenModal(false);
      }}
      confirmActionLabel="Confirmar"
      cancelActionFunc={() => setShowUnBlockReasonModal(false)}
      cancelActionLabel="Cancelar"
      onClose={() => setShowUnBlockReasonModal(false)}
      confirmActionFuncDisabled={!form['reason']}
    >
      <SelectComponent
        {...{ form, setForm: handleSetForm, label: 'Motivo do desbloqueio do IP:', name: 'reason', required: true }}
        options={[
          { name: 'Bloqueio indevido', value: 'Bloqueio indevido' },
        ]}
      />
    </AcerModal>
  );
};

export const UnBlockModal = () => {

  const {
    openModal,
    setOpenModal,
    user
  } = useContext(BlockedIPsContext);

  const [showUnBlockReasonModal, setShowUnBlockReasonModal] = useState(false);

  return (
    <>
      <AcerModal
        fullWidth
        open={openModal}
        maxWidth="sm"
        modalTitle="Atenção"
        hasAction
        confirmActionFunc={() => setShowUnBlockReasonModal(true)}
        confirmActionLabel="Confirmar"
        cancelActionFunc={() => setOpenModal(false)}
        cancelActionLabel="Cancelar"
        onClose={() => setOpenModal(false)}
      >
        <Typography>
          <b>Deseja realizar o desbloqueio do IP desse cliente?</b>
          <br />Ele poderá abrir chats novamente.
        </Typography>
      </AcerModal>

      <UnBlockReasonModal
        {...{
          showUnBlockReasonModal,
          setShowUnBlockReasonModal,
          setOpenModal,
          user
        }}
      />
    </>
  );
};

export const UnBlockButton = ({
  setOpenModal,
  setUser,
  rowData
}) => {

  return rowData.isBlocked !== false && (
    <Tooltip title="Desbloquear IP">
      <IconButton
        variant="contained"
        color="primary"
        onClick={() => {
          setUser(rowData);
          setOpenModal(true);
        }}
      >
        <LockOpen />
      </IconButton>
    </Tooltip>
  )
};
