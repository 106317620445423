import { makeStyles, Tooltip } from '@material-ui/core';
import React from 'react';
import { CustomHelpIcon, CustomHintText } from '../styles';

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth: 'none'
  }
}));

const CardHint = ({ hint, noWidth }) => {
  const classes = useStyles();
  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
        popperOptions: {
          positionFixed: true,
          modifiers: {
            preventOverflow: {
              enabled: true,
              boundariesElement: 'window'
            }
          }
        }
      }}
      placement="top"
      arrow
      classes={noWidth && { popper: classes.customWidth }}
      title={<CustomHintText>{hint}</CustomHintText>}
    >
      <CustomHelpIcon fontSize="small" color="primary" />
    </Tooltip>
  );
};

export default CardHint;
