import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { ptBR, enUS } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import Page from '../../../components/Page';
import API from '../../../services/api';
import formHelper from '../../../utils/formHelper';
import { CustomBreadCrumbs } from '../../../styles/components/CustomDataTable';
import { brDay, usDay } from '../functions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  breadcrumbs: {
    marginTop: '15px',
    marginLeft: '24px'
  }
}));

const ProfileContainer = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const [isSaving, setSaving] = useState(false);
  const [checkedDays, setCheckedDays] = useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false
  });
  const [profile] = useState(history?.location?.state?.data);

  const [formData, setFormdata] = useState(
    formHelper.simpleData2FormData({
      profileName: {
        required: true
      },
      weekdays: {
        required: true
      },
      startJourney: {
        required: true
      },
      endJourney: {
        required: true
      }
    })
  );

  const isWeekDayEmpty = () => {
    let empty = true;
    Object.keys(checkedDays).forEach(item => {
      if (checkedDays[item]) {
        empty = false;
      }
    });
    return empty;
  };

  const formIsOK = () => {
    if (
      !formData.profileName.value ||
      !formData.startJourney.value ||
      !formData.endJourney.value ||
      isWeekDayEmpty()
    ) {
      enqueueSnackbar(t('i18n.newticket.CHECK_IF_FIELDS_ARE_FILLED'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      return false;
    }

    return true;
  };

  const addProfile = postData => {
    setSaving(true);
    API.post(`/auth/ms-users/profile-journey/create`, postData)
      .then(() => {
        setTimeout(() => {
          window.location.href = '/profiles';
          return false;
        }, 3000);
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.usercontainer.FAILSAVE'), {
          variant: 'error'
        });
        setSaving(false);
      });
  };

  const updateProfile = postData => {
    API.put(`/auth/ms-users/profile-journey/update`, {
      ...postData,
      // eslint-disable-next-line
      _id: profile._id
    })
      .then(() => {
        setTimeout(() => {
          window.location.href = '/profiles';
          return false;
        }, 3000);
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.usercontainer.FAILSAVE'), {
          variant: 'error'
        });
        setSaving(false);
      });
  };

  const saveUser = () => {
    setSaving(true);
    if (!formIsOK()) {
      return;
    }

    const checkedItems = Object.keys(checkedDays).filter(
      day => checkedDays[day]
    );

    const postData = {
      profileName: formData.profileName.value,
      weekdays: checkedItems,
      startJourney: moment(formData.startJourney.value).format('HH:mm'),
      endJourney: moment(formData.endJourney.value).format('HH:mm')
    };

    if (!profile) {
      addProfile(postData);
      setSaving(false);
      return false;
    }
    updateProfile(postData);
  };

  const dateFromTime = time => {
    const mx = time.split(':');
    const dateVar = new Date();
    dateVar.setHours(mx[0]);
    dateVar.setMinutes(mx[1]);

    return dateVar;
  };

  const loadProfileData = () => {
    const { profileName, startJourney, endJourney } = profile;
    const newState = { ...formData };
    newState.startJourney.value = dateFromTime(startJourney);
    newState.endJourney.value = dateFromTime(endJourney);
    newState.profileName.value = profileName;

    setFormdata(newState);

    const dayState = { ...checkedDays };
    profile.weekdays.forEach(day => {
      dayState[usDay(day)] = true;
    });
    setCheckedDays(dayState);
  };

  useEffect(() => {
    if (profile) {
      loadProfileData();
    }
  }, [profile]);

  const handleChange = e => {
    setCheckedDays({ ...checkedDays, [e.target.name]: e.target.checked });
  };

  const language = localStorage.getItem('language') ?? 'pt_br';

  return (
    <MuiPickersUtilsProvider
      locale={language === 'pt_br' ? ptBR : enUS}
      utils={DateFnsUtils}
    >
      <Page className={classes.root} title="Novo perfil">
        <Container maxWidth={false}>
          <CustomBreadCrumbs
            className={classes.breadcrumbs}
            gutterBottom
            aria-label="breadcrumb"
          >
            <Typography variant="h6">
              <Link color="inherit" to="/profiles">
                {t('i18n.profilelistview.PROFILES')}
              </Link>
            </Typography>
            <Typography variant="h6" color="textPrimary">
              {profile
                ? t('i18n.profilecontainer.UPDATE_PROFILE')
                : t('i18n.profilecontainer.NEW_PROFILE')}
            </Typography>
          </CustomBreadCrumbs>
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
          >
            <Container maxWidth="md">
              <Box>
                <Card>
                  <CardContent>
                    <Typography variant="h3">
                      {profile
                        ? t('i18n.profilecontainer.UPDATE_PROFILE')
                        : t('i18n.profilecontainer.NEW_PROFILE')}
                    </Typography>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <Box mb={2}>
                      <TextField
                        value={formData.profileName.value}
                        onChange={htmlElemtEvent => {
                          formHelper.setFormvalue(
                            formData,
                            setFormdata,
                            'profileName',
                            htmlElemtEvent.target.value
                          );
                        }}
                        error={formData.profileName.error}
                        label="Nome"
                        variant="outlined"
                        fullWidth
                      />
                    </Box>

                    <Box mb={2}>
                      <Typography>Dias de trabalho</Typography>
                      {Object.keys(checkedDays).map(item => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checkedDays[item]}
                                onChange={handleChange}
                                name={item}
                              />
                            }
                            key={item}
                            label={brDay(item)}
                          />
                        );
                      })}
                    </Box>

                    <Box mb={2} display="flex" style={{ gap: 10 }}>
                      <TimePicker
                        clearable
                        ampm={false}
                        label="Hora Início"
                        value={formData.startJourney.value || new Date()}
                        fullWidth
                        inputVariant="outlined"
                        onChange={htmlElemtEvent => {
                          formHelper.setFormvalue(
                            formData,
                            setFormdata,
                            'startJourney',
                            htmlElemtEvent
                          );
                        }}
                      />

                      <TimePicker
                        clearable
                        ampm={false}
                        label="Hora Término"
                        value={formData.endJourney.value || new Date()}
                        fullWidth
                        inputVariant="outlined"
                        onChange={htmlElemtEvent => {
                          formHelper.setFormvalue(
                            formData,
                            setFormdata,
                            'endJourney',
                            htmlElemtEvent
                          );
                        }}
                      />
                    </Box>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <Grid container justifyContent="space-between">
                      <Grid item lg={6}>
                        <Button
                          variant="contained"
                          color="default"
                          type="button"
                          onClick={() => history.goBack()}
                        >
                          {t('i18n.usercontainer.CANCEL')}
                        </Button>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            saveUser();
                          }}
                          disabled={isSaving}
                        >
                          {t('i18n.newticket.SEND')}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Container>
          </Box>
        </Container>
      </Page>
    </MuiPickersUtilsProvider>
  );
};

export default ProfileContainer;
