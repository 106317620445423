import {
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Button,
  Box,
  Divider,
  ListItemIcon,
  makeStyles,
  IconButton
} from '@material-ui/core';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { COLORS } from '../../../styles/settings/colors.config';

const drawerWidth = 560;

const StyledFilterContainer = styled.div`
  padding-left: 50px;
`;

const drawerUseStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  hide: {
    display: 'none'
  },
  drawer: {
    position: 'absolute',
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '58px'
  },
  content: {
    marginTop: '67px',
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  paper: {
    paddingTop: '60px',
    zIndex: '200'
  }
}));

const StyledDrawer = styled(Drawer)`
  .menu-closed {
    max-width: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  > div {
    max-height: calc(100vh - 60px);
    overflow-x: hidden;
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      width: 5px;
      background-color: ${COLORS.btnSecondaryColor};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${COLORS.btnSecondaryColor};
      border: 2px solid ${COLORS.btnSecondaryColor};
    }
  }
  ul {
    padding-bottom: 0;
  }
`;

const FilterDrawer = ({
  categories,
  applyFilter,
  fullData,
  triggerApplyFilter
}) => {
  const { t } = useTranslation();
  const drawerClasses = drawerUseStyles();

  const [open, setOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [families, setFamilies] = useState([]);
  const [selectedFamilies, setSelectedFamilies] = useState([]);

  const filterFamilies = () => {
    const newFamilyArray = [];

    fullData.map(item => {
      if (selectedCategories.includes(item.category)) {
        item.families.map(subitem => {
          newFamilyArray.push(subitem.name);
          return subitem;
        });
      }
      return item;
    });
    setSelectedFamilies([]);
    setFamilies(newFamilyArray);
  };

  useEffect(() => {
    filterFamilies();
  }, [selectedCategories]);

  const cleanForm = () => {
    setSelectedCategories([]);
    setFamilies([]);
    setSelectedFamilies([]);
  };

  useEffect(() => {
    if (selectedCategories.length > 0) {
      applyFilter(selectedCategories, selectedFamilies, false);
    }
  }, [triggerApplyFilter]);
  return (
    <StyledDrawer
      // onClick={() => setOpen(true)}
      // onMouseLeave={() => setOpen(false)}
      variant="permanent"
      className={clsx(drawerClasses.drawer, {
        [drawerClasses.drawerOpen]: open,
        [drawerClasses.drawerClose]: !open
      })}
      classes={{
        paper: clsx({
          [drawerClasses.drawerOpen]: open,
          [drawerClasses.drawerClose]: !open,
          [drawerClasses.paper]: drawerClasses.paper
        })
      }}
      PaperProps={{
        style: { marginLeft: '122px !important' }
      }}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
    >
      <List dense>
        <ListItem>
          <ListItemIcon>
            <FilterListIcon
              style={{ color: COLORS.greenText }}
              onClick={() => setOpen(true)}
            />
          </ListItemIcon>
          <ListItemText primary="Filtros" />
          <ListItem
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <IconButton
              style={{ color: COLORS.greenText }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </ListItem>
        </ListItem>

        <Divider />
      </List>
      <StyledFilterContainer>
        <Box style={{ width: '500px', padding: '12px 24px 24px', border: '0' }}>
          <br />
          <Box mb={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>
                {t('i18n.sales_ad_analysis.data.category')}
              </InputLabel>
              <Select
                multiple
                value={selectedCategories}
                onChange={htmlElemtEvent => {
                  setSelectedCategories(htmlElemtEvent.target.value);
                }}
                label={t('i18n.sales_ad_analysis.data.category')}
              >
                {categories.map(item => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl
              disabled={families.length === 0}
              variant="outlined"
              fullWidth
            >
              <InputLabel>
                {t('i18n.sales_ad_analysis.data.families')}
              </InputLabel>
              <Select
                multiple
                value={selectedFamilies}
                onChange={htmlElemtEvent => {
                  setSelectedFamilies(htmlElemtEvent.target.value);
                }}
                label={t('i18n.sales_ad_analysis.data.families')}
              >
                {families.map(item => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Divider />
          <br />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="contained"
              onClick={() => {
                cleanForm();
              }}
            >
              {t('i18n.ticketslistview.CLEAR')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                applyFilter(selectedCategories, selectedFamilies, true);
                setOpen(false);
              }}
            >
              {t('i18n.dashboard.APPLY_FILTER')}
            </Button>
          </div>
        </Box>
      </StyledFilterContainer>
    </StyledDrawer>
  );
};

export default FilterDrawer;
