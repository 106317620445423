import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterInput from '../../../../components/FilterInput.component';

const Pedecom = ({ filterForm, handleSetFilter }) => {
  const { t } = useTranslation();
  return (
    <Box mt={1}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FilterInput
            {...{
              form: filterForm,
              setForm: handleSetFilter,
              name: 'orderId',
              label: t('i18n.ticketslistview.ORDER_ID_PEDECOM')
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Pedecom;
