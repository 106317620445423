import { TableCell, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CustomDataTable } from '../../../../styles/components/CustomDataTable';
import CustomFooter from '../../../../styles/components/CustomFooter';
import B2WMarketplaceDetailsSupportModal from './B2WMarketplaceDetailsSupportModal';

const CustomRow = props => {
  const { data, setSelectedSupport, setOpenDetailsModal, reportData } = props;

  return (
    <TableRow
      style={{ cursor: 'pointer' }}
      onClick={() => {
        const findedItem = reportData.find(item => item.id === data[0]);
        setSelectedSupport(findedItem);
        setOpenDetailsModal(true);
      }}
    >
      {data.map((d, i) => {
        const returnValues = date => {
          if (date) {
            return moment(d).format('DD/MM/YYYY HH:mm');
          }
          return d;
        };

        return (
          <TableCell key={Math.random()}>
            {i === 3 && returnValues(true)}

            {i !== 3 && returnValues()}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default function B2WMarketplaceSupportTable({
  count,
  rows,
  columns,
  rowsPerPage,
  reportData,
  currentPage,
  handleSearch,
  loading,
  sortTableData,
  setCurrentPage,
  fetchData
}) {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedSupport, setSelectedSupport] = useState({});

  const { t } = useTranslation();
  const options = {
    selectableRows: 'none',
    filter: false,
    pagination: true,
    viewColumns: false,
    print: false,
    search: false,
    rowsPerPage,
    rowsPerPageOptions: [20],
    count,
    download: false,
    page: currentPage,
    serverSide: true,
    hint: 'Ordenar',
    onTableChange: (action, tableState) => {
      if (action === 'sort') {
        sortTableData(tableState);
        setCurrentPage(0);
      }
    },
    customRowRender: (data, dataIndex) => {
      return (
        <CustomRow
          {...{
            data,
            reportData,
            dataIndex,
            setSelectedSupport,
            setOpenDetailsModal
          }}
          key={Math.random()}
        />
      );
    },
    customFooter: (changeRowsPerPage, textLabels) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={() => {}}
          rowsPerPageOptions={[20]}
          callback={pageNumber => {
            handleSearch(pageNumber);
          }}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: !loading
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: column =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };

  return (
    <>
      <CustomDataTable data={rows} columns={columns} options={options} />
      <B2WMarketplaceDetailsSupportModal
        {...{
          openDetailsModal,
          setOpenDetailsModal,
          selectedSupport,
          sortTableData,
          fetchData
        }}
      />
    </>
  );
}
