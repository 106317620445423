import { COLORS } from '../../../../../styles/settings/colors.config';

const getTitleData = (score = '0.0', size = '50px') => {
  return {
    style: {
      fontWeight: 'semibold',
      fontSize: size,
      fontFamily: 'Roboto',
      color: COLORS.grayText,
      marginBottom: '10px',
      marginTop: '15px'
    },
    content: score.toString().replace('.', ',')
  };
};

const getTitleSubContent = possibleScore => {
  return {
    style: {
      fontWeight: 'bold',
      fontSize: '14px',
      fontFamily: 'Roboto',
      marginTop: '15px',
      color: '#999999'
    },
    content: `De 0 a ${possibleScore}`
  };
};

const fillDonutGraph = (score = 0, possibleScore) => {
  const data = [
    {
      type: 'Nota',
      value: score
    },
    {
      type: '',
      value: possibleScore - score
    }
  ];
  return data;
};

const generalScoreColor = score => {
  if (score >= 4) {
    return COLORS.greenText;
  }
  if (score === 3) {
    return '#EAC502';
  }
  return COLORS.btnDangerColor;
};

const getChartColors = score => {
  const colorArray = [];
  colorArray.push(generalScoreColor(score), 'white');
  return colorArray;
};

export {
  getTitleData,
  getTitleSubContent,
  fillDonutGraph,
  getChartColors,
  generalScoreColor
};
