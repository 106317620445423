import { Box, CircularProgress, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledSpinner } from '../../../styles/components/SpinnerDefault';
import Page from '../../../components/Page';
import config from '../../../config';
import API from '../../../services/api';

import { searchB2bOnCurrentUrlV2 } from '../../../utils/b2bHelper';

import DataTableComponent from './datatable.component';
import { BlockedIPsContext } from './context';
import { defaultFilterFactory } from './filter.config';

import FilterDrawer from './filterDrawer.component';

const BlockedIPs = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(defaultFilterFactory());
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState({});

  const refreshTableContent = () => {
    setLoading(true);

    API.post(
      `${
        config.backend.url
      }/auth/ms-chat/chats/all-blocked-ips?businessType=${searchB2bOnCurrentUrlV2()}`,
      filter
    )
      .then(response => {
        setData(
          response.data.sort(
            (a, b) => Date.parse(b.blockedDate) - Date.parse(a.blockedDate)
          )
        );
      })
      .catch(() => {
        enqueueSnackbar('Não foi possível buscar os IPs bloqueados', {
          variant: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    refreshTableContent();
  }, [filter]);

  const context = {
    loading,
    data,
    refreshTableContent,
    filter,
    setFilter,
    openModal,
    setOpenModal,
    user,
    setUser
  };

  return (
    <BlockedIPsContext.Provider value={context}>
      <Page title={t('i18n.chatview.BLOCKED_UNBLOCKED_IPS')}>
        <FilterDrawer />
        <Container maxWidth={false}>
          <Box mt={3} ml={7}>
            <DataTableComponent />
            <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
              <CircularProgress />
            </StyledSpinner>
          </Box>
        </Container>
      </Page>
    </BlockedIPsContext.Provider>
  );
};

export default BlockedIPs;
