/* eslint-disable */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Divider,
  // ListItemAvatar,
  ListItemIcon,
  makeStyles,
  Typography,
  IconButton,
  Button,
  Tooltip
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { COLORS } from '../../styles/settings/colors.config';
import FilterContainer from './FilterContainer';
// import ShortcutContainer from './TicketsListView/ShortcutContainer';

import { useStyles } from '../../components/inputStyle';

import API from '../../services/api';

import {
  CustomListItemText,
  CustomListItemAvatar,
  CustomListItem
} from './styles';

import { useMarketplaceListPreSale } from '../../hooks/hooks';

const drawerWidth = 710;

const StyledFilterContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  height: 100%;

  .list-title {
    padding: 0 20px 8px;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    height: 48px;
    min-height: 30px;
  }

  .marketplace-list {
    width: 100%;
  }

  .filter-options {
    margin: 0 16px;
  }
`;

const drawerUseStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  hide: {
    display: 'none'
  },
  drawer: {
    position: 'absolute',
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '60px'
  },
  content: {
    marginTop: '67px',
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  paper: {
    paddingTop: '60px',
    zIndex: '200'
  }
}));

const StyledDrawer = styled(Drawer)`
  .menu-closed {
    max-width: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  > div {
    overflow-x: hidden;
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      width: 5px;
      background-color: ${COLORS.btnSecondaryColor};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${COLORS.btnSecondaryColor};
      border: 2px solid ${COLORS.btnSecondaryColor};
    }
  }
  ul {
    padding-bottom: 0;
  }
`;

const TicketsFilter = ({
  shortcutSelected,
  shortcutsFilter: SHORTCUTS_FILTER,
  setMarketplaceFunction,
  filterData,
  setFilterData,
  setCurrentPage,
  filterFormData,
  removeFilterFromChip
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const drawerClasses = drawerUseStyles();

  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = React.useState('panel2');
  // const [showCreateShortcut, setShowCreateShortcut] = useState(false);
  // const [shortCutList, setShortcutList] = useState([]);
  // const [shortcutData, setShortcutData] = useState({});
  const [shortcutFilterData, setShortcutFilterData] = useState({});
  const [currentShortcutShow, setCurrentShortcutShow] = useState({});

  const MARKETS_PLACE =
    useMarketplaceListPreSale().filter(item => {
      return item.activePreSale;
    });

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    // handleShowCreateShortcut(false);
  };

  const handleOpenCloseFilter = () => {
    setOpen(!open);
  };

  return (
    <StyledDrawer
      variant="permanent"
      className={clsx(drawerClasses.drawer, {
        [drawerClasses.drawerOpen]: open,
        [drawerClasses.drawerClose]: !open
      })}
      classes={{
        paper: clsx({
          [drawerClasses.drawerOpen]: open,
          [drawerClasses.drawerClose]: !open,
          [drawerClasses.paper]: drawerClasses.paper
        })
      }}
      PaperProps={{
        style: { marginLeft: '122px !important' }
      }}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <List dense style={{ display: 'flex', width: '100%' }}>
        <ListItem
          style={{
            cursor: 'pointer'
          }}
        >
          <ListItemIcon onClick={handleOpenCloseFilter}>
            <Tooltip title={open ? 'Fechar filtro' : 'Abrir filtro'}>
              <FilterListIcon color="primary" />
            </Tooltip>
          </ListItemIcon>
          <ListItemText
            primary={t('i18n.ticketslistview.PRE_TICKETS_FILTER')}
            style={{ color: COLORS.greenText }}
          />
        </ListItem>
        <ListItem
          style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Tooltip title="Fechar">
            <IconButton color="primary" onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </ListItem>
      </List>
      <Divider />
      <StyledFilterContainer>
        <div>
          <Accordion
            square
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionDetails>
              <List className="marketplace-list">
                {MARKETS_PLACE.map(value => {
                  const selectedColor =
                    filterData.marketPlace.includes(value.value) &&
                    theme.palette.primary.main;
                  const labelId = `checkbox-list-secondary-label-${value}`;
                  return (
                    <CustomListItem
                      onClick={() => {
                        setMarketplaceFunction(value.value);
                      }}
                      key={value.icon}
                      button
                    >
                      <CustomListItemAvatar>
                        <Tooltip title={open ? '' : value.text}>
                          <Avatar
                            style={{
                              backgroundColor: selectedColor,
                              width: '27px',
                              height: '27px',
                              fontSize: '12px'
                            }}
                          >
                            {value.icon}
                          </Avatar>
                        </Tooltip>
                      </CustomListItemAvatar>
                      <CustomListItemText
                        id={labelId}
                        primary={value.text}
                        selectedcolor={String(selectedColor)}
                      />
                    </CustomListItem>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        </div>
        <FilterContainer
          filterData={filterData}
          setFilterData={setFilterData}
          className="filter-options"
          filterFormData={filterFormData}
          removeFilterFromChip={removeFilterFromChip}
          // shortcutFilterData={shortcutFilterData}
          // setShortcutFilterData={setShortcutFilterData}
          // setCurrentShortcutShow={setCurrentShortcutShow}
          // currentShortcutShow={currentShortcutShow}
          onFilterChange={v => {
            sessionStorage.setItem('lastTicketsPage', 0);
            setCurrentPage(0);
            setFilterData(v);
          }}
          setOpen={setOpen}
        />
      </StyledFilterContainer>
    </StyledDrawer>
  );
};

export default TicketsFilter;
