import React, { useState, useEffect } from 'react';
import { Card, Divider, Tabs, Tab, Typography } from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ArrowForwardIos } from '@material-ui/icons';
import styled from 'styled-components';

import { BlockButton, BlockModal } from './components/BlockUser.components';

const CustomTabsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomTab = styled(Tab)`
  flex-grow: 0;
  font-size: small;
`;

const MessageActions = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  gap: 30px;
`;

const CustomCustomerLabel = styled.div`
  letter-spacing: 0.05em;
  color: gray;
  font-height: semibold;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

const ChatDetailSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.3em;
`;

const CustomTypography = styled(Typography)`
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

const ChatHeader = ({
  clientInAttendance,
  socket,
  setShowDetails,
  chatTabValue,
  setChatTabValue,
  showDetails
}) => {
  let subscribe;

  const reduxDispatcher = useDispatch();
  const { t } = useTranslation();

  const showHistoric = useSelector(state => state.showHistoricChatReducer);
  const showVisitors = useSelector(state => state.showVisitorsReducer);
  const showClientTalk = useSelector(state => state.showClientTalkReducer);
  const allDataOfCurrentClient = useSelector(
    state => state.clientInAttendanceDataReducer
  );

  // const disconnect = useSelector(state => state.disconnectChatReducer);

  const [clientPulled, setClientPulled] = useState({});
  const [currentClientInAttendance, setCurrentClientInAttendance] = useState(
    {}
  );
  const [openState, setOpenState] = useState(false);

  const myClientNameEmail = useSelector(
    state => state.clientInAttendanceReducer
  );
  const [name, setName] = useState(clientInAttendance.name);

  const initSubscribeSocket = () => {
    subscribe = socket?.newchatSubject.subscribe(value => {
      const response = JSON.parse(value.data);
      if (response?.system?.action === 'OUT') {
        // Quando um atendente puxa um atendimento
        // de outro atendente
        setClientPulled(response.system);
      }

      // if (response?.system?.action === 'DISCONNECT') {
      //   setCurrentClientInAttendance({});
      // }
    });
  };

  const handleTitle = () => {
    if (showHistoric === true) {
      return t('i18n.chat.ACTIVE_CHAT');
    }
    if (showHistoric === false) {
      return t('i18n.chat.CURRENT_CHAT');
    }
  };

  const handleTabChange = (event, newValue) => {
    setChatTabValue(newValue);

    reduxDispatcher({
      type: 'SET_TAB_VIEW_CHAT',
      tabViewChat: false
    });
  };

  useEffect(() => {
    if (
      currentClientInAttendance.hashConnection &&
      currentClientInAttendance.hashConnection === clientPulled.hashConnection
    ) {
      setCurrentClientInAttendance({});
      setChatTabValue('currentChat');
    }
  }, [clientPulled]);

  useEffect(() => {
    setCurrentClientInAttendance(clientInAttendance);
  }, [clientInAttendance, showVisitors]);

  useEffect(() => {
    if (socket) {
      initSubscribeSocket();
    }
  }, [socket]);

  useEffect(() => {
    if (subscribe) {
      subscribe.unsubscribe();
    }
  }, []);

  useEffect(() => {
    const clientUpdatedList = myClientNameEmail.filter(item => {
      return item.hashConnection === clientInAttendance.hashConnection;
    });

    const lastUpdateClient = clientUpdatedList[clientUpdatedList.length - 1];

    if (lastUpdateClient?.name) {
      setName(lastUpdateClient.name);
    }

    if (lastUpdateClient === undefined) {
      setName(clientInAttendance.name);
    }

    // if (
    //   myClientNameEmail.hashConnection === clientInAttendance.hashConnection
    // ) {
    //   setName(myClientNameEmail.name);
    // } else {
    //   setName(clientInAttendance.name);
    // }
  }, [myClientNameEmail, clientInAttendance]);

  return (
    <>
      <Card style={{ borderRadius: '3px 3px 0px 0px' }}>
        <CustomTabsHeader>
          <Tabs
            value={chatTabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="scrollable auto tabs example"
          >
            {showClientTalk && clientInAttendance.name && (
              <CustomTab
                value="currentChat"
                // label={showChatHistory === true ? t('i18n.chat.ACTIVE_CHAT') : t('i18n.chat.CURRENT_CHAT')}
                label={currentClientInAttendance?.name && handleTitle()}
              />
            )}
            {showClientTalk && clientInAttendance.name && (
              <CustomTab value="chatDetails" label="+ Informações" />
            )}
            {showClientTalk && clientInAttendance.name && (
              <CustomTab
                value="chatHistory"
                label={t('i18n.chat.PREVIOUS_CHATS')}
              />
            )}
          </Tabs>
          {showClientTalk && clientInAttendance.name && (
            <MessageActions>
              {!showHistoric && <BlockButton {...{ setOpenState }} />}

              <CustomCustomerLabel>
                {t('i18n.simplewords.CUSTOMER')}: <b>{name?.toUpperCase()}</b>
              </CustomCustomerLabel>
              {!showDetails && (
                <ChatDetailSection>
                  <CustomTypography
                    color="primary"
                    onClick={() => setShowDetails(true)}
                  >
                    {t('i18n.chatview.CHAT_DETAIL')}
                  </CustomTypography>
                  <ArrowForwardIos fontSize="small" color="primary" />
                </ChatDetailSection>
              )}
            </MessageActions>
          )}
        </CustomTabsHeader>

        <Divider />
      </Card>
      <BlockModal
        {...{
          openState,
          setOpenState,
          ip: allDataOfCurrentClient.ip,
          hashConnection: allDataOfCurrentClient.hashConnection,
          closeChat: true,
          allDataOfCurrentClient
        }}
      />
    </>
  );
};

export { ChatHeader };
