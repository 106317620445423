import config from '../../config';

class MonitorConnection {
  static instance;

  constructor() {
    this.url = config.websocket.monitorurl;
    this.token = localStorage.getItem('accessToken');
    this.connectMonitor();
  }

  connectMonitor = () => {
    this.socket = new WebSocket(`${this.url}?authorization=${this.token}`);
    if (this.socket) {
      this.socket.onopen = () => {
        this.heartBeat();
      };
      this.socket.onmessage = event => {
        // this.onmessage(value);
        const message = JSON.parse(event.data);
        return message;
        // console.log(value.data);
        // return value;
      };
    }
  };

  static getConnection() {
    if (this.instance) {
      return this.instance;
    }
    this.instance = new MonitorConnection();
    return this.instance;
  }

  async heartBeat() {
    this.send({ action: 'heartbeat' });
    this.heartbeatInterval = setInterval(() => {
      if (this.socket) {
        this.send({ action: 'heartbeat' });
      } else {
        clearInterval(this.heartbeatInterval);
      }
    }, 60000);
  }

  subscribe(channel) {
    if (this.socket) {
      return this.socket.onmessage({
        action: 'subscribe',
        channel: `${channel}`
      });
    }
  }

  send(message, callback) {
    this.waitForConnection(() => {
      this.socket.send(JSON.stringify(message));
      if (typeof callback !== 'undefined') {
        callback();
      }
    }, 1000);
  }

  waitForConnection(callback, interval) {
    if (this.socket.readyState === 1) {
      callback();
    } else {
      setTimeout(() => {
        this.waitForConnection(callback, interval);
      }, interval);
    }
  }

  // send(message, callback) {
  //   this.socket.send(JSON.stringify(message), event => {
  //     callback(event);
  //   });
  // }

  onmessage(callback) {
    this.socket.onmessage = event => {
      callback(JSON.parse(event.data));
    };
  }

  close() {
    clearInterval(this.heartbeatInterval);
    // eslint-disable-next-line no-unused-expressions
    this.socket && this.socket.close();
  }
}

export default MonitorConnection;

/* eslint-disable no-await-in-loop */

// import { Subject } from 'rxjs';
// import config from '../../config';

// class MonitorConnection {
//   static instance = null;

//   constructor() {
//     this.url = config.websocket.monitorurl;
//     this.identificationMsg = { action: 'sendmessage', identifier: 'abo' };
//     this.generalMessageSubject = new Subject();
//     this.closeGeneralMessageSubject = new Subject();
//     this.errorGeneralMessageSubject = new Subject();
//     const token = localStorage.getItem('accessToken');
//     this.socket = new WebSocket(`${this.url}?authorization=${token}`);
//     this.socket.onopen = event => {
//       this.onConnectionOpen(event);
//     };
//   }

//   async heartBeat() {
//     while (true) {
//       const wait = seconds =>
//         new Promise(resolve => {
//           setTimeout(resolve, seconds);
//         });
//       await wait(60000).then(() => {
//         this.send({ action: 'heartbeat' });
//       });
//     }
//   }

//   static getConnection() {
//     if (this.instance) {
//       return this.instance;
//     }
//     this.instance = new MonitorConnection();
//     return this.instance;
//   }

//   onConnectionOpen() {
//     // this.send(this.identificationMsg);
//     this.setOnMessage();
//     this.setOnClose();
//     this.setOnError();
//     this.heartBeat();
//   }

//   send(message, callback) {
//     this.waitForConnection(() => {
//       this.socket.send(JSON.stringify(message));
//       if (typeof callback !== 'undefined') {
//         callback();
//       }
//     }, 1000);
//   }

//   waitForConnection(callback, interval) {
//     if (this.socket.readyState === 1) {
//       callback();
//     } else {
//       setTimeout(() => {
//         this.waitForConnection(callback, interval);
//       }, interval);
//     }
//   }

//   setOnMessage() {
//     this.socket.onmessage = event => {
//       this.generalMessageSubject.next(event);
//     };
//   }

//   setOnClose() {
//     this.socket.onclose = event => {
//       this.closeGeneralMessageSubject.next(event);
//     };
//   }

//   setOnError() {
//     this.socket.onerror = event => {
//       this.errorGeneralMessageSubject.next(event);
//     };
//   }
// }

// export default MonitorConnection;
