/*eslint-disable */
import React, { useState } from 'react';

import { Box, Card, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AccessTime } from '@material-ui/icons';
import {
  CustomTypograph,
  MainCardData,
  CardTitle,
  CustonIcon,
  CustomHintText,
  CustomParagraphArrowDropDownUp,
  CustomParagraphArrowDropUpDonw,
  CustomLabel,
  CardContentWrapper,
  CustomMainData,
  StyledTypography,
  ItemWrapper,
  CardItem
} from '../../styles.v2';
import handleOpenNewTabHook from '../handleGetTabFilterHook';

import { HelpIcon } from '../../../../components/HelpIcon.component';

import { formatHour } from '../../services/convertDate';

// import { CustomArrowDropDown } from '../../styles';

export const ItemData = ({ item }) => {
  return (
    <CardItem style={{ width: '100%' }}>
      <ItemWrapper
        style={{ minWidth: '50%', maxWidth: '50%', textAlign: 'right' }}
      >
        <StyledTypography>
          <b>{formatHour(item.tma)}</b>
        </StyledTypography>
        {/* <CustomSublabel>{item.value}% da meta</CustomSublabel> */}
      </ItemWrapper>
      <CustomLabel style={{ minWidth: '50%', maxWidth: '50%' }}>
        {item?.attendant?.length > 0 ? item.attendant : 'Sem registro'}
      </CustomLabel>
    </CardItem>
  );
};

const ColumnData = ({ columnData, showDivider, percent, isTeamView }) => {
  const [show, setShow] = useState(true);

  return (
    <Box style={{ width: '100%' }}>
      <MainCardData>
        <CustomLabel>{columnData.label}</CustomLabel>
        <CustomMainData lineheight="27px" fontSize="30px">
          {columnData?.total?.length > 0
            ? formatHour(columnData.total)
            : formatHour('00:00:00')}
        </CustomMainData>
      </MainCardData>

      {isTeamView && (
        <>
          <MainCardData>
            <CustomParagraphArrowDropDownUp showDivider={showDivider} />
          </MainCardData>
          <Divider />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CustomParagraphArrowDropUpDonw
              onClick={() => setShow(!show)}
              showDivider={showDivider}
            />
          </div>
          {show && (
            <Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                {columnData?.data?.map(item => (
                  <ItemData item={item} key={Math.random()} />
                ))}
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

const PresaleOfflineTimeCardList = ({
  cardType,
  firstLabel,
  secondaryLabel,
  isTeamView,
  headerData,
  showMoreDetails,
  extraDataColumn1,
  extraDataColumn2,
  firstData,
  secondaryData,
  subtitle,
  subtitle2,
  isRealTime,
  descriptionHint,
  showInConstruction,
  downloadProcotols,
  cancelling,
  showIcon,
  percent
}) => {
  const { t } = useTranslation();

  const dashboardFilter = useSelector(state => state.dashboardFilterReducer);
  const { handleOpenNewTab } = handleOpenNewTabHook({
    dashboardFilter,
    t,
    key: cancelling ? 'lastStepName' : 'ticketStatus'
  });

  firstData = firstData < 10 ? `0${firstData}` : firstData;
  secondaryData = secondaryData < 10 ? `0${secondaryData}` : secondaryData;

  const title = cardType => {
    switch (cardType) {
      case 'answers':
        return (
          <p>
            <b>Tempo médio</b> de respostas
          </p>
        );
      case 'qtdProtocols':
        return (
          <p>
            Quantidade de <b>Protocolos</b> de pré-venda
          </p>
        );
      default:
        return '';
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Card
        style={{ width: '100%', marginBottom: '20px', borderRadius: '20px' }}
      >
        <CardTitle>
          <CustomTypograph variant="h5">{title(cardType)}</CustomTypograph>
          <Box style={{ gap: '10px' }} display="flex" justifyContent="flex-end">
            {descriptionHint && (
              <HelpIcon
                tooltip={<CustomHintText>{descriptionHint}</CustomHintText>}
                arrow
                placement="top"
              />
            )}
          </Box>
        </CardTitle>
        <Divider />

        <CardContentWrapper>
          <CustonIcon>
            <AccessTime />
          </CustonIcon>

          <Box
            display="flex"
            justifyContent="center"
            style={{ gap: '22px', width: '100%' }}
            mt={2}
          >
            {extraDataColumn1.show && (
              <ColumnData
                columnData={extraDataColumn1}
                showDivider
                {...{ showMoreDetails, percent, isTeamView }}
              />
            )}

            {extraDataColumn2.show && (
              <ColumnData
                columnData={extraDataColumn2}
                showDivider
                {...{ showMoreDetails, percent, isTeamView }}
              />
            )}
          </Box>
        </CardContentWrapper>
      </Card>
    </div>
  );
};

export { PresaleOfflineTimeCardList };
