import React from 'react';
import styled from 'styled-components';

const IframeWrapper = styled.div`
  width: 100%;
  height: 100%;
  zoom: 110%;
  overflow: hidden;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;

const UserManagementIframe = () => {
  return (
    <IframeWrapper>
      <Iframe
        src={`${
          process.env.REACT_APP_NEW_CONNECTA
        }/user-management?refreshToken=${localStorage.getItem('refreshToken')}`}
      />
    </IframeWrapper>
  );
};

export default UserManagementIframe;
