import { Card, Divider, Grid } from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import { COLORS } from '../../../../../../../styles/settings/colors.config';
import { Content, Label } from './styles';

export default function MainDetails({ details }) {
  const logisticsInteraction = details?.stepsTimeLine?.find(
    i => i.name === 'Parecer emitido pela Logística'
  );

  return (
    <Card style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
      <Grid
        container
        style={{ fontFamily: 'Roboto', padding: '5px' }}
        spacing={3}
      >
        <Grid item xs={3}>
          <Label>Nota Fiscal Origem</Label>
          <Content customcolor={COLORS.greenText}>
            {details?.order?.numeroNF}
          </Content>
        </Grid>
        <Grid item xs={2}>
          <Label>Pedecom</Label>
          <Content>{details?.order?.idPedEcom}</Content>
        </Grid>
        <Grid item xs={2}>
          <Label>Marketplace</Label>
          <Content customcolor={COLORS.greenText}>
            {details?.order?.marketplace}
          </Content>
        </Grid>
        <Grid item xs={2}>
          <Label>Pedido Marketplace</Label>
          <Content>{details?.order?.idPedMarket}</Content>
        </Grid>
        <Grid item xs={3}>
          <Label>Protocolo Conecta</Label>
          <Content customcolor={COLORS.greenText}>
            {details?.conectaTicketId}
          </Content>
        </Grid>
      </Grid>
      <Divider />
      <Grid
        container
        style={{ fontFamily: 'Roboto', padding: '5px' }}
        spacing={3}
      >
        <Grid item xs={3}>
          <Label>Tipo da Devolução</Label>
          <Content>
            {details?.isCancellationOfAllItens ? 'Total' : 'Parcial'}
          </Content>
        </Grid>
        <Grid item xs={2}>
          <Label>Data Parecer Logística</Label>
          <Content>
            {moment(logisticsInteraction?.date).format('DD/MM/YYYY')}
          </Content>
        </Grid>
        <Grid item xs={2}>
          <Label>Parecer Logística NF</Label>
          <Content>{details?.statusLogistics}</Content>
        </Grid>
        <Grid item xs={2}>
          <Label>Motivo da Troca</Label>
          <Content>{details?.reasonCancellation}</Content>
        </Grid>
        <Grid item xs={3}>
          <Label>Detalhe do Motivo</Label>
          <Content>{details?.detail}</Content>
        </Grid>
      </Grid>
      <Divider />
      <Grid
        container
        style={{ fontFamily: 'Roboto', padding: '5px' }}
        spacing={3}
      >
        <Grid item xs={3}>
          <Label>Liberado pelo Atendimento</Label>
          <Content>{details?.isReviewedByLogistics ? 'SIM' : 'NÂO'}</Content>
        </Grid>
        <Grid item xs={2}>
          <Label>Analista Atendimento</Label>
          <Content>{details?.specialist}</Content>
        </Grid>
        <Grid item xs={4}>
          <Label>Observação Atendimento</Label>
          <Content>{details?.specialistDetail}</Content>
        </Grid>
        <Grid item xs={3}>
          <Label>Status da Troca</Label>
          <Content customcolor={COLORS.WarningColorSLA}>
            {details?.lastStepName}
          </Content>
        </Grid>
      </Grid>
    </Card>
  );
}
