import { Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import React from 'react';
import { CustomHintText } from '../../../styles';
import DonutChart from '../../PresaleOnline/DonutChart';

const MainScoreCard = ({
  chartData,
  colors,
  totalValue,
  titleData,
  titleSubContent,
  hintText,
  period
}) => {
  return (
    <>
      <div style={{ position: 'relative', top: '0px', left: '0px' }}>
        <DonutChart
          data={chartData}
          innerRadius={0.8}
          height="200px"
          {...{ colors, totalValue, titleData, titleSubContent }}
          titleSize="70px"
        />
        <div style={{ position: 'absolute', top: '10px', left: '6px' }}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
              popperOptions: {
                positionFixed: true,
                modifiers: {
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: 'window'
                  }
                }
              }
            }}
            placement="top"
            arrow
            title={<CustomHintText>{hintText}</CustomHintText>}
          >
            <InfoOutlined fontSize="medium" color="primary" />
          </Tooltip>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          fontWeight: 400,
          marginTop: '20px'
        }}
      >
        {period}
      </div>
    </>
  );
};

export default MainScoreCard;
