import { IconButton } from '@material-ui/core';
import { QueryBuilder } from '@material-ui/icons';
import React, { useState } from 'react';

import RichTooltip from '../../../../../components/RichTooltip';
import { getScheduleIconColor } from './functions';
import ScheduleInfo from './ScheduleInfo';
import ScheduleWindow from './ScheduleWindow';

const ScheduleButton = ({
  scheduleOpen,
  setScheduleOpen,
  agendamento,
  chat,
  props
}) => {
  const [editing, setEditing] = useState(false);

  return (
    <RichTooltip
      content={
        agendamento && !editing ? (
          <ScheduleInfo
            closePopover={() => setScheduleOpen(false)}
            schedule={agendamento}
            {...{ chat, setEditing }}
          />
        ) : (
          <ScheduleWindow
            closePopover={() => setScheduleOpen(false)}
            schedule={agendamento}
            {...{ chat, editing }}
          />
        )
      }
      open={scheduleOpen}
      placement="bottom"
    >
      <IconButton
        {...props}
        onClick={() => {
          setScheduleOpen(!scheduleOpen);
        }}
      >
        <QueryBuilder
          style={{ color: getScheduleIconColor(agendamento?.status) }}
        />
      </IconButton>
    </RichTooltip>
  );
};

export default ScheduleButton;
