import React, { useEffect, useState } from 'react';
import { Grid, Divider, IconButton, Card } from '@material-ui/core';
import styled from 'styled-components';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../../styles/settings/colors.config';
import API from '../../../../services/api';

const DetailsWrapper = styled(Grid)`
  border-left: 1px solid #ccc;
  height: 100%;
`;

const CustomHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MessageActions = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChatItem = styled('div')`
  margin-bottom: 20px;
`;

const ChatText = styled('p')`
  font-size: ${props => (props.size ? props.size : '14px')};
  color: ${props => (props.color ? props.color : COLORS.grayText)};
  bold: ${props => (props.bold ? props.bold : 'normal')};
  padding: ${props => (props.padding ? props.padding : '0px 15px')};
  font-weight: ${props => props.bold && props.bold};
`;

const ChatWrapper = styled('div')`
  padding: 15px 0px;
`;

const ChatLinkCard = styled('a')`
  background-color: ${COLORS.whiteText};
  padding: 15px;
  display: block;
  color: ${COLORS.greenText};
  :hover {
    text-decoration: underline;
  }
`;

const ChatScroll = styled('div')`
  max-height: 425px;
  height: 368px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const NewHistoryClient = ({
  setShowClienHistoric,
  lastMessage,
  clientInAttendance,
  setShowHistoric
}) => {
  const { t } = useTranslation();

  const [historicInMessages, setHistoricInMessages] = useState([]);
  const [newLastMessage, setNewLastMessage] = useState({});

  const handleGetClientTalk = async client => {
    const clientHistory = await API.get(
      `no-auth/ms-chat/existing-chat/${client.hashConnection}`
    );

    const messagePages = [];
    const { messages } = clientHistory.data;

    messages
      .filter(item => {
        return !item.login && item;
      })
      .map(item => {
        const searchedItem = messagePages.find(subitem => {
          return subitem.parentURL === item.messagePage.parentURL;
        });
        if (!searchedItem) {
          messagePages.push(item.messagePage);
        }
        return item;
      });

    // setHistoricInMessages(messagePages.reverse());
    setHistoricInMessages(messagePages);
  };

  useEffect(() => {
    if (clientInAttendance?.hashConnection) {
      handleGetClientTalk(clientInAttendance);
    }
  }, [clientInAttendance]);

  useEffect(() => {
    if (newLastMessage?.messagePage) {
      const lastHistoric = historicInMessages[historicInMessages.length - 1];
      const { messagePage } = newLastMessage;
      if (
        lastHistoric &&
        lastHistoric?.parentTitle !== messagePage?.parentTitle
      ) {
        setHistoricInMessages([...historicInMessages, ...[messagePage]]);
      }
      setNewLastMessage({});
    }
  }, [newLastMessage, historicInMessages]);

  useEffect(() => {
    setNewLastMessage(lastMessage);
  }, [lastMessage]);

  return (
    <Card style={{ height: '100%' }}>
      <DetailsWrapper item xs={12}>
        <CustomHeader>
          <ChatText>{t('i18n.chatview.TITLE_HISTORY')}</ChatText>
          <MessageActions>
            <IconButton
              onClick={() => {
                setShowClienHistoric(false);
                setShowHistoric(false);
              }}
              color="primary"
            >
              <HighlightOff />
            </IconButton>
          </MessageActions>
        </CustomHeader>
        <Divider />
        <div>
          <ChatWrapper>
            <ChatItem>
              <ChatText>{t('i18n.chatview.CURRENT_PAGE')}:</ChatText>
            </ChatItem>
            <ChatItem>
              <ChatLinkCard
                href={lastMessage?.messagePage?.parentURL}
                target="_blank"
              >
                <p>{lastMessage?.messagePage?.parentTitle}</p>
              </ChatLinkCard>
            </ChatItem>
          </ChatWrapper>
          <ChatWrapper>
            <ChatItem>
              <ChatText>{t('i18n.chatview.PREVIOUS_PAGES')}:</ChatText>
            </ChatItem>
            <ChatScroll>
              {historicInMessages
                ?.map(item => {
                  return (
                    <div key={item?.parentURL}>
                      <ChatItem>
                        <ChatLinkCard href={item?.parentURL} target="_blank">
                          <p>{item?.parentTitle}</p>
                        </ChatLinkCard>
                      </ChatItem>
                    </div>
                  );
                })
                .reverse()}
            </ChatScroll>
          </ChatWrapper>
        </div>
      </DetailsWrapper>
    </Card>
  );
};

export { NewHistoryClient };
