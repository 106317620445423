const statusEnum = () => {
  return [
    {
      label: 'i18n.preSale.UNDER_REVIEW',
      value: 'UNDER_REVIEW',
      marketplace: 'MERCADO_LIVRE'
    },
    {
      label: 'i18n.preSale.CLOSED_UNANSWERED',
      value: 'CLOSED_UNANSWERED',
      marketplace: 'MERCADO_LIVRE'
    },
    {
      label: 'i18n.preSale.PAUSED',
      value: 'PAUSED',
      marketplace: 'MERCADO_LIVRE'
    },
    {
      label: 'i18n.preSale.NEW',
      value: 'NEW',
      marketplace: 'MERCADO_LIVRE'
    },

    {
      label: 'i18n.preSale.DELETED',
      value: 'DELETED',
      marketplace: 'MERCADO_LIVRE'
    },
    {
      label: 'i18n.preSale.ANSWERED',
      value: 'ANSWERED',
      marketplace: 'MERCADO_LIVRE'
    },
    {
      label: 'i18n.preSale.NEW',
      value: 'NEW',
      marketplace: 'YOURVIEWS_REVIEW'
    },
    {
      label: 'i18n.preSale.ANSWERED',
      value: 'ANSWERED',
      marketplace: 'YOURVIEWS_REVIEW'
    }
  ];
};

export default statusEnum;
