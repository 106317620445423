import React from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { CustomTypograph } from '../custom.components';
import MainScoreCard from './components/MainScoreCard';
import { CardTitle } from '../../styles';
import {
  fillDonutGraph,
  getChartColors,
  getTitleData,
  getTitleSubContent
} from './functions/commonFunctions';
import ScoreInfoCardCNOVA from './CNOVA/ScoreInfoCardCNOVA';
import SalesInfoCardCNOVA from './CNOVA/SalesInfoCardCNOVA';

const CNOVADashboard = ({ score, possibleScore, isRealTime, startDate }) => {
  const chartData = fillDonutGraph(score, possibleScore);
  const colors = getChartColors(score);
  const totalValue = score;
  const titleData = getTitleData(score, '60px');
  const titleSubContent = getTitleSubContent(possibleScore);

  const hintText = `Aqui, a nota da sua loja é baseada no desempenho de 3 
  indicadores: expedição, reclamação e cancelamento. A nota é calculada no 
  período dos últimos 30 dias`;

  const infoCards = ['delay', 'complain', 'cancelled'];

  const salesInfo = [
    {
      title: 'Aprovados hoje',
      descriptionHint: 'hint',
      quantity: 5,
      value: 13894.5
    },
    {
      title: 'Aprovados nos últimos 07 dias',
      descriptionHint: 'hint',
      quantity: 82,
      value: 305973.88
    },
    {
      title: 'Aprovados nos últimos 30 dias',
      descriptionHint: 'hint',
      quantity: 135,
      value: 558302.77
    }
  ];

  let period;

  if (isRealTime) {
    const end = new Date();
    end.setDate(end.getDate() - 60);
    const startFormatted = moment(end).format('DD/MM/YYYY');

    period = (
      <Typography
        component="span"
        style={{ fontSize: '12px', lineHeight: '14px', color: '#5F676C' }}
      >
        Período: <b>de {startFormatted} até hoje</b>
      </Typography>
    );
  } else {
    const endFormatted = moment(new Date(startDate)).format('DD/MM/YYYY');
    const end = new Date(startDate);
    end.setDate(end.getDate() - 60);
    const startFormatted = moment(end).format('DD/MM/YYYY');

    period = (
      <Typography
        component="span"
        style={{ fontSize: '12px', lineHeight: '14px', color: '#5F676C' }}
      >
        Período:{' '}
        <b>
          {startFormatted} a {endFormatted}
        </b>
      </Typography>
    );
  }

  return (
    <>
      <CustomTypograph variant="h3">CNOVA</CustomTypograph>
      <Card style={{ backgroundColor: 'white', marginTop: '10px' }}>
        <CardTitle>
          <CustomTypograph variant="h5">Nota</CustomTypograph>
        </CardTitle>
        <CardContent style={{ marginTop: '-20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <MainScoreCard
                {...{
                  chartData,
                  colors,
                  totalValue,
                  titleData,
                  titleSubContent,
                  hintText,
                  period
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <Grid container spacing={2}>
                {infoCards.map(item => (
                  <Grid item xs={4} key={Math.random()}>
                    <ScoreInfoCardCNOVA type={item} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ backgroundColor: 'white', marginTop: '22px' }}>
        <CardTitle>
          <CustomTypograph variant="h5">Pedidos</CustomTypograph>
        </CardTitle>
        <CardContent style={{ marginTop: '-20px' }}>
          <Grid container spacing={2}>
            {salesInfo.map(item => (
              <Grid item xs={4} key={Math.random()}>
                <SalesInfoCardCNOVA {...{ item }} key={Math.random()} />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default CNOVADashboard;
