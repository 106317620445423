import { ExpandMore } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import API from '../../../../services/api';
import { searchB2bOnCurrentUrl } from '../../../../utils/b2bHelper';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Notify,
  NotifyTitle,
  NotifyWrapper
} from '../styles/styles';
import { CustomerCards } from './ClientItem.component';
import { SpecialistItem } from './SpecialistItem.component';
import TabVisitorsTable from './TabVisitorsTable';

let intervalToVerifyConnection;

const TabTools = ({ expanded, handleChange, newUserStatus }) => {
  const { t } = useTranslation();
  const reduxDispatcher = useDispatch();

  const [waitingAtQueue, setWaitingAtQueue] = useState([]);
  const [inAttendance, setInAttendance] = useState([]);
  const [specialistsOnline, setSpecialistsOnline] = useState([]);
  const [showChatHistory, setShowChatHistory] = useState('');

  const HandleShowHistory = show => {
    setShowChatHistory(show);
  };
  const loadAttendances = async () => {
    const attandanceData = await API.get(
      `/auth/ms-chat/chats/consolidated-status?businessType=${
        !searchB2bOnCurrentUrl() ? 'B2C' : 'B2B'
      }`
    );
    const {
      clientsOnlineAttending,
      allClerksOnline,
      clientsOnQueue,
      allVisitorsNumbers
    } = attandanceData.data;

    if (
      showChatHistory === true &&
      clientsOnlineAttending.length === 0 &&
      clientsOnQueue.length === 0
    ) {
      HandleShowHistory('');
    }

    reduxDispatcher({
      type: 'VISITORS_ONLINE',
      visitorsOnline: allVisitorsNumbers
    });

    setInAttendance(clientsOnlineAttending);
    setWaitingAtQueue(clientsOnQueue);
    setSpecialistsOnline(allClerksOnline);

    // handleGetClerksOnline(allClerksOnline);
  };

  const handleLoadClerks = async () => {
    const clerksOnline = await API.get(
      `/auth/ms-chat/chats/all-clerks-online?businessType=${
        !searchB2bOnCurrentUrl() ? 'B2C' : 'B2B'
      }`
    );
    setSpecialistsOnline(clerksOnline.data);
  };

  useEffect(() => {
    const tenSecondsinMiliseconds = 10000;
    const intervalOfREquests = setInterval(() => {
      loadAttendances();
    }, tenSecondsinMiliseconds);

    handleLoadClerks();

    return () => {
      clearInterval(intervalOfREquests);
      clearInterval(intervalToVerifyConnection);
    };
  }, []);

  return (
    <>
      <Accordion
        square
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <NotifyWrapper>
            <Notify>
              {waitingAtQueue?.data?.length || [].length}
              {` de ${waitingAtQueue?.total || [].length}`}
            </Notify>
            <NotifyTitle>{t('i18n.chatview.QUEUE')}</NotifyTitle>
          </NotifyWrapper>
        </AccordionSummary>
        <AccordionDetails>
          {waitingAtQueue?.data?.map(item => (
            <CustomerCards
              key={item?.hashConnection}
              chat={item}
              showToolBars
              newUserStatus={newUserStatus}
              loadAttendances={loadAttendances}
            />
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3d-content"
          id="panel3d-header"
        >
          <NotifyWrapper>
            <Notify>{inAttendance?.length}</Notify>
            <NotifyTitle>{t('i18n.chatview.IN_SERVICE')}</NotifyTitle>
          </NotifyWrapper>
        </AccordionSummary>
        <AccordionDetails>
          {inAttendance?.map(item => (
            <CustomerCards
              chat={item}
              showToolBars
              newUserStatus={newUserStatus}
              loadAttendances={loadAttendances}
              key={Math.random()}
            />
          ))}
        </AccordionDetails>
      </Accordion>

      <Accordion
        square
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel4d-content"
          id="panel4d-header"
        >
          <NotifyWrapper>
            <Notify>{specialistsOnline?.length}</Notify>
            <NotifyTitle>
              {t('i18n.chatview.SPECIALISTS_IN_SERVICE')}
            </NotifyTitle>
          </NotifyWrapper>
        </AccordionSummary>
        <AccordionDetails>
          {specialistsOnline?.map(item => (
            <SpecialistItem login={item.login} key={Math.random()} />
          ))}
        </AccordionDetails>
      </Accordion>

      <TabVisitorsTable expanded={expanded} />
    </>
  );
};

export default TabTools;
