import React, { useState, useEffect } from 'react';
import {
  FormControl,
  TextField,
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Button,
  FormLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styled from 'styled-components';
import AcerModal from '../../../../components/AcerModal';
import { COLORS } from '../../../../styles/settings/colors.config';
import API from '../../../../services/api';

import {
  utcFormat,
  utcFormatEndDate,
  brFormatEndDate
} from '../../../../utils/timezoneFormat';

const CustomTextField = styled(TextField)`
  input[type='date'] {
    font-size: 14px;
    width: 100px;
  }
`;

const CounterWrapper = styled.span`
  font-family: Roboto;
`;

const CounterNumber = styled.span`
  font-weight: bold;
  color: ${props => props.red && 'red'};
`;

const HeaderSubtitle = styled(Typography)`
  color: ${COLORS.WarningV3};
`;

const useStyles = makeStyles(() => ({
  inputWrapper: { width: '60%', gap: '16px' },
  inputHour: { fontSize: '12px' },
  boxWrapper: { gap: '16px' }
}));

const CharacterCount = ({ currentLength, limit }) => {
  const closeToTheLimit = currentLength >= limit - 10;

  return (
    <CounterWrapper>
      <CounterNumber red={closeToTheLimit}>{currentLength}</CounterNumber>/
      <CounterNumber red>{limit}</CounterNumber> caracteres
    </CounterWrapper>
  );
};

const AddTriggerModal = ({
  showModal,
  refreshTableContent,
  editItem,
  handleClose,
  defaultSchedule,
  handlePost
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();
  const [form, setForm] = useState({});

  const handleMountData = dataForm => {
    const {
      startDate,
      endDate,
      isStandard,
      isCurrent,
      timeFirstTrigger,
      timeSecondTrigger
    } = dataForm;

    dataForm.validityStart = utcFormat(startDate, 'YYYY-MM-DDT03:00:00');
    dataForm.validityEnd = endDate
      ? utcFormatEndDate(endDate, 'YYYY-MM-DDT02:59:59')
      : null;

    dataForm.isStandard = isStandard === 'true' || !!isStandard;

    if (isCurrent) {
      dataForm.isCurrent = isCurrent === 'true';
    }
    dataForm.timeFirstTrigger = Number(timeFirstTrigger);
    dataForm.timeSecondTrigger = Number(timeSecondTrigger);
    dataForm.creationDate = utcFormat(new Date(), 'YYYY-MM-DDTHH:mm:ss');

    if (defaultSchedule.length === 0) {
      dataForm.isStandard = true;
      dataForm.isActive = true;
    } else {
      dataForm.isActive = dataForm.isActive !== '';
    }

    return dataForm;
  };

  const handleMountForm = dataForm => {
    const { validityStart, validityEnd, isStandard } = dataForm;

    dataForm.startDate = validityStart
      ? moment(validityStart).format('YYYY-MM-DD')
      : moment(new Date()).format('YYYY-MM-DD');
    dataForm.endDate = validityEnd
      ? brFormatEndDate(validityEnd, 'YYYY-MM-DD')
      : null;
    dataForm.isStandard =
      isStandard === 'true' || !!isStandard || defaultSchedule.length === 0;

    if (dataForm.isStandard) {
      dataForm.isActive = true;
    }

    setForm(dataForm);
  };

  const handleUpdate = async updateData => {
    try {
      await API.put('/auth/ms-chat/trigger/update', updateData).then(() => {
        refreshTableContent();
        handleClose();
      });
    } catch (err) {
      enqueueSnackbar(t(err?.response?.data?.message?.key), {
        variant: 'error'
      });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const data = handleMountData(form);

    if (data.id) {
      handleUpdate(data);
    } else {
      handlePost(data);
    }
  };

  useEffect(() => {
    if (!editItem?.id) {
      if (defaultSchedule.length === 0) {
        setForm({
          startDate: moment(new Date()).format('YYYY-MM-DD')
        });
      } else {
        setForm({});
      }
    }

    if (showModal) {
      handleMountForm(editItem);
    }
  }, [showModal, editItem]);

  return (
    <AcerModal
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={showModal}
      modalTitle={`${editItem?.id ? 'Editar' : 'Criar'} Gatilho do Chat`}
      freeBody
    >
      <form style={{ overflow: 'hidden' }} onSubmit={e => handleSubmit(e)}>
        <Box p={2} style={{ background: COLORS.bgModal }}>
          <FormControl fullWidth>
            <TextField
              label={t('i18n.monitor.chat.SETTINGS_MODAL_DESCRIPTION')}
              size="small"
              variant="outlined"
              required
              value={form.description || ''}
              onChange={e => {
                setForm({
                  ...form,
                  description: e.target.value
                });
              }}
            />
          </FormControl>
        </Box>
        <Box p={2}>
          <Grid container>
            <Grid item md={6}>
              <Box
                display="flex"
                alignItems="center"
                className={classes.boxWrapper}
              >
                <Box display="flex" alignItems="center">
                  <Typography>
                    {t('i18n.monitor.chat.SETTINGS_MODAL_VALIDITY')}
                  </Typography>
                </Box>
                <Box display="flex" className={classes.boxWrapper}>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.boxWrapper}
                  >
                    <Typography>
                      {t('i18n.monitor.chat.SETTINGS_MODAL_FROM')}
                    </Typography>
                    <CustomTextField
                      size="small"
                      variant="outlined"
                      type="date"
                      disabled={form.isStandard}
                      required
                      value={form.startDate || ''}
                      onChange={e => {
                        setForm({
                          ...form,
                          startDate: e.target.value
                        });
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        inputProps: {
                          min: moment(new Date()).format('YYYY-MM-DD')
                        }
                      }}
                    />
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.boxWrapper}
                  >
                    <Typography
                      style={{ color: form.isStandard && COLORS.grayText }}
                    >
                      {t('i18n.monitor.chat.SETTINGS_MODAL_UNTIL')}
                    </Typography>
                    <CustomTextField
                      size="small"
                      variant="outlined"
                      type="date"
                      disabled={form.isStandard}
                      value={form.endDate || ''}
                      onChange={e => {
                        setForm({
                          ...form,
                          endDate: e.target.value
                        });
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        inputProps: {
                          min: moment(new Date()).format('YYYY-MM-DD')
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box display="flex" pl={2}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Habilitar gatilho</FormLabel>
                  <RadioGroup
                    row
                    onChange={e => {
                      setForm({ ...form, isActive: e.target.value });
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      checked={form.isActive}
                      disabled={form.isStandard}
                      control={<Radio />}
                      label="Sim"
                    />
                    <FormControlLabel
                      value=""
                      checked={!form.isActive}
                      disabled={form.isStandard}
                      control={<Radio />}
                      label="Não"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              style={{
                background: COLORS.bgModal,
                borderRight: '8px solid #fff'
              }}
            >
              <Box p={2}>
                <Typography>
                  {t('i18n.monitor.chat.TRIGGERS_LABEL_1')}
                </Typography>
                <Box
                  justifyContent="center"
                  display="flex"
                  className={classes.boxWrapper}
                  mt={2}
                >
                  <Box>
                    <Box>
                      <Typography>
                        <b>{t('i18n.monitor.chat.TRIGGERS_1')}</b>
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        className={classes.inputWrapper}
                      >
                        <Typography>
                          {t('i18n.monitor.chat.TRIGGERS_TIME')}
                        </Typography>
                        <FormControl>
                          <InputMask
                            mask="99"
                            maskChar=""
                            value={form.timeFirstTrigger || ''}
                            onChange={e => {
                              setForm({
                                ...form,
                                timeFirstTrigger: e.target.value
                              });
                            }}
                          >
                            {() => (
                              <TextField
                                variant="outlined"
                                size="small"
                                required
                              />
                            )}
                          </InputMask>
                        </FormControl>
                        <Typography>min</Typography>
                      </Box>
                    </Box>
                    <Box mt={2}>
                      <Typography>
                        <b>{t('i18n.monitor.chat.TRIGGERS_2')}</b>
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        className={classes.inputWrapper}
                      >
                        <Typography>
                          {t('i18n.monitor.chat.TRIGGERS_TIME')}
                        </Typography>
                        <FormControl>
                          <InputMask
                            mask="99"
                            maskChar=""
                            value={form.timeSecondTrigger || ''}
                            onChange={e => {
                              setForm({
                                ...form,
                                timeSecondTrigger: e.target.value
                              });
                            }}
                          >
                            {() => (
                              <TextField
                                variant="outlined"
                                size="small"
                                required
                              />
                            )}
                          </InputMask>
                        </FormControl>
                        <Typography>min</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              style={{
                background: COLORS.bgModal,
                borderLeft: '8px solid #fff'
              }}
            >
              <Box p={2}>
                <Typography>
                  {t('i18n.monitor.chat.TRIGGERS_LABEL_2')}
                </Typography>
                <Box display="flex" className={classes.boxWrapper} mt={2}>
                  <TextField
                    label={t('i18n.monitor.chat.SETTINGS_MODAL_MESSAGE')}
                    size="small"
                    type="date"
                    fullWidth
                    multiline
                    required
                    inputProps={{ maxLength: 200 }}
                    minRows={6}
                    variant="outlined"
                    value={form.messageClient || ''}
                    onChange={e => {
                      setForm({
                        ...form,
                        messageClient: e.target.value
                      });
                    }}
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <CharacterCount
                    currentLength={form?.messageClient?.length || 0}
                    limit={200}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={1} p={2} display="flex" justifyContent="space-between">
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleClose()}
          >
            {t('i18n.monitor.chat.SETTINGS_MODAL_CANCEL')}
          </Button>
          <Button color="primary" variant="contained" type="submit">
            {t('i18n.monitor.chat.SETTINGS_MODAL_SAVE')}
          </Button>
        </Box>
      </form>
    </AcerModal>
  );
};

export { AddTriggerModal, HeaderSubtitle };
