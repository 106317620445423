import { Button } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import CallIcon from '@material-ui/icons/Call';
import EventIcon from '@material-ui/icons/Event';
import GroupIcon from '@material-ui/icons/Group';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import CancellingConfirmation from './CancellingConfirmation';
import NewDeliveryDate from './NewDeliveryDate';
import ModalDefault from './ModalDefault';

import API from '../../../../../services/api';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {}
}))(MenuItem);

const cnovaActions = (
  t, // translate
  id, // action id
  setModalDefaultState, // event
  setNovaDataEntregaModalState, // event
  setCancellingConfirmationModalState, // event
  handleGetDetailsCancellation, // event
  setAction // event
) => {
  if (id === 1691) {
    return (
      <StyledMenuItem
        onClick={() => {
          setModalDefaultState(true);
          setAction(9999);
        }}
        color="primary"
      >
        <ListItemIcon>
          <LocalShippingIcon fontSize="small" className="header-btn--icon" />
        </ListItemIcon>
        <ListItemText primary={t('i18n.cnova.phrases.canceldelivery')} />
      </StyledMenuItem>
    );
  }

  if (id === 2684) {
    return (
      <StyledMenuItem
        onClick={() => {
          setModalDefaultState(true);
          setAction(2684);
        }}
        color="primary"
      >
        <ListItemIcon>
          <CancelPresentationIcon
            fontSize="small"
            className="header-btn--icon"
          />
        </ListItemIcon>
        <ListItemText primary={t('i18n.cnova.phrases.enddeal')} />
      </StyledMenuItem>
    );
  }

  if (id === 2709) {
    return (
      <StyledMenuItem
        onClick={() => {
          setCancellingConfirmationModalState(true);
          handleGetDetailsCancellation();
        }}
      >
        <ListItemIcon>
          <ThumbUpAltIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('i18n.cnova.phrases.cancellingconfirm')} />
      </StyledMenuItem>
    );
  }

  if (id === 2710) {
    return (
      <StyledMenuItem
        onClick={() => {
          setModalDefaultState(true);
          setAction(2710);
        }}
      >
        <ListItemIcon>
          <ThumbDownAltIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('i18n.cnova.phrases.cancellingreject')} />
      </StyledMenuItem>
    );
  }

  if (id === 2711) {
    return (
      <StyledMenuItem
        onClick={() => {
          setModalDefaultState(true);
          setAction(2711);
        }}
        color="primary"
      >
        <ListItemIcon>
          <GroupIcon fontSize="small" className="header-btn--icon" />
        </ListItemIcon>
        <ListItemText primary={t('i18n.cnova.phrases.viavarejoassignment')} />
      </StyledMenuItem>
    );
  }

  if (id === 3657) {
    return (
      <StyledMenuItem
        onClick={() => {
          setNovaDataEntregaModalState(true);
        }}
        color="primary"
      >
        <ListItemIcon>
          <EventIcon fontSize="small" className="header-btn--icon" />
        </ListItemIcon>
        <ListItemText primary={t('i18n.cnova.phrases.newdeliverydate')} />
      </StyledMenuItem>
    );
  }

  if (id === 3775) {
    return (
      <StyledMenuItem
        onClick={() => {
          setModalDefaultState(true);
          setAction(3775);
        }}
        color="primary"
      >
        <ListItemIcon>
          <CallIcon fontSize="small" className="header-btn--icon" />
        </ListItemIcon>
        <ListItemText primary={t('i18n.cnova.phrases.answerclient')} />
      </StyledMenuItem>
    );
  }

  if (id === 3849) {
    return (
      <StyledMenuItem
        onClick={() => {
          setModalDefaultState(true);
          setAction(3849);
        }}
        color="primary"
      >
        <ListItemIcon>
          <ScheduleIcon fontSize="small" className="header-btn--icon" />
        </ListItemIcon>
        <ListItemText primary={t('i18n.cnova.phrases.pickingscheduled')} />
      </StyledMenuItem>
    );
  }

  if (id === 3850) {
    return (
      <StyledMenuItem
        onClick={() => {
          setModalDefaultState(true);
          setAction(3850);
        }}
        color="primary"
      >
        <ListItemIcon>
          <LocalShippingIcon fontSize="small" className="header-btn--icon" />
        </ListItemIcon>
        <ListItemText primary={t('i18n.cnova.phrases.releasedelivery')} />
      </StyledMenuItem>
    );
  }

  if (id === 9998) {
    return (
      <StyledMenuItem
        onClick={() => {
          setModalDefaultState(true);
          setAction(9999);
        }}
        color="primary"
      >
        <ListItemIcon>
          <LocalShippingIcon fontSize="small" className="header-btn--icon" />
        </ListItemIcon>
        <ListItemText primary={t('i18n.cnova.phrases.unfeasible')} />
      </StyledMenuItem>
    );
  }
  if (id === 9999) {
    return (
      <StyledMenuItem
        onClick={() => {
          setModalDefaultState(true);
          setAction(9999);
        }}
        color="primary"
      >
        <ListItemIcon>
          <LocalShippingIcon fontSize="small" className="header-btn--icon" />
        </ListItemIcon>
        <ListItemText primary={t('i18n.cnova.phrases.redeliveryfeasible')} />
      </StyledMenuItem>
    );
  }
};

const CnovaAnswerBar = ({ ticketData }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleActionsClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCancelamentoClose = () => {
    setAnchorEl(null);
  };

  const [modalDefaultState, setModalDefaultState] = useState(false);
  const [action, setAction] = useState(0);
  const [novaDataEntregaModalState, setNovaDataEntregaModalState] = useState(
    false
  );
  const [
    cancellingConfirmationModalState,
    setCancellingConfirmationModalState
  ] = useState(false);

  const [devolution, setDevolution] = useState('');
  const [restitution, setRestitution] = useState('');
  const [reason, setReason] = useState('');

  const handleGetDetailsCancellation = () => {
    API.get(`/auth/ms-ticket/v1/tickets/cnova/details-cancellation`, {
      params: {
        IdIncidente: ticketData?.marketplaceBundle?.idIncidenteCNOVA
      }
    })
      .then(response => {
        setDevolution(response.data.devolucao);
        setReason(response.data.motivo);
        setRestitution(response.data.reembolsoDesc);
      })
      .catch(() => {
        enqueueSnackbar('Não foram encontrados detalhes de cancelamento', {
          variant: 'error'
        });
      });
  };

  const cnovaActionsList = ticketData?.marketplaceBundle?.actionProtocol;

  const actionAvailable = ticketData?.marketplaceBundle?.actionAvailable;

  return (
    <>
      <Button
        disabled={actionAvailable !== true}
        onClick={e => {
          handleActionsClick(e);
        }}
        color="primary"
      >
        <FormatListBulletedIcon fontSize="small" className="header-btn--icon" />
        {t('i18n.cnova.phrases.actions')}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCancelamentoClose}
      >
        {cnovaActionsList?.map(item => (
          <div key={item.id}>
            {cnovaActions(
              t,
              item.id,
              setModalDefaultState,
              setNovaDataEntregaModalState,
              setCancellingConfirmationModalState,
              handleGetDetailsCancellation,
              setAction
            )}
          </div>
        ))}
      </StyledMenu>
      <ModalDefault
        ticketData={ticketData}
        openState={modalDefaultState}
        hasAction
        closeParent={() => {
          setModalDefaultState(false);
        }}
        action={action}
      />
      <NewDeliveryDate
        ticketId={ticketData?.id}
        idIncidenteCNOVA={ticketData?.marketplaceBundle?.idIncidenteCNOVA}
        pedido={ticketData?.marketplaceBundle?.pedido}
        openState={novaDataEntregaModalState}
        closeParent={() => {
          setNovaDataEntregaModalState(false);
        }}
      />
      <CancellingConfirmation
        ticketData={ticketData}
        openState={cancellingConfirmationModalState}
        closeParent={() => {
          setCancellingConfirmationModalState(false);
        }}
        devolution={devolution}
        reason={reason}
        restitution={restitution}
      />
    </>
  );
};

export default CnovaAnswerBar;
