const searchB2bOnCurrentUrl = () => {
  const urlWindow = window.location.href;
  if (urlWindow.search('b2b') > -1) {
    return true;
  }
  return false;
};

const searchB2bOnCurrentUrlV2 = () => {
  const urlWindow = window.location.href;
  if (urlWindow.search('b2b') > -1) {
    return 'B2B';
  }
  return 'B2C';
};

const isB2BProtocol = (data) => {
  if (data.marketPlace !== 'CUSTOMER_GUIDE_B2B') {
    return true;
  }
  return false;
};

const haveB2BOnFilter = (filter) => {
  const searched = filter.find((item) => {
    return item === 'CUSTOMER_GUIDE_B2B';
  });

  if (searched) {
    return true;
  }
  return true;
};

export {
  searchB2bOnCurrentUrl,
  searchB2bOnCurrentUrlV2,
  isB2BProtocol,
  haveB2BOnFilter
};
