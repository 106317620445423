export const logisticsStatuses = [
  { value: 'moving', label: 'Aguardando Chegada na Acer' },
  { value: 'waiting', label: 'Aguardando Logística' },
  { value: 'finished', label: 'Parecer emitido pela logística' }
];

export const serviceStatuses = [
  { value: 'moving', label: 'Aguardando chegada em Service' },
  { value: 'waiting', label: 'Aguardando Service' },
  { value: 'finished', label: 'Troca liberada pela Service' }
];
