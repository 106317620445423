import React from 'react';
import { CircularProgress, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { StyledSpinner } from '../../../styles/components/SpinnerDefault';
import { CustomTabPanel } from '../components/custom.components';
import Header from './header.component';
import { marketplaceScoreTabName } from './config';
import DashboardContent from './DashboardContent';

const MessageScoreDashboard = ({
  tabValue,
  dashboardFilter,
  setDashboardFilter
}) => {
  const loading = useSelector(state => state.marketPlaceScoreLoadingReducer);

  const dataEquipDash = useSelector(
    state => state.marketplaceScoreDashboardTeamDataReducer
  );

  const marketPlacesSelected = dashboardFilter.marketplace || [];

  return (
    <CustomTabPanel value={tabValue} index={marketplaceScoreTabName}>
      <Header
        dashboardFilter={dashboardFilter}
        setDashboardFilter={setDashboardFilter}
        dataEquipDash={dataEquipDash}
      />
      <br />
      <Divider />
      <br />
      {!loading ? (
        <DashboardContent
          isAdmin={dashboardFilter.attendant === ''}
          {...{ dataEquipDash, dashboardFilter, marketPlacesSelected }}
        />
      ) : (
        <>
          <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
            <CircularProgress />
          </StyledSpinner>
        </>
      )}
    </CustomTabPanel>
  );
};

export default MessageScoreDashboard;
