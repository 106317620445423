const getFilterInStorage = () => {
  return JSON.parse(sessionStorage.getItem('evaluationfilterData'));
};

const getProductLink = (productInfo, marketplace) => {
  if (marketplace === 'MERCADO_LIVRE') {
    const productId = productInfo?.id.split('MLB')[1];
    const titleFormated = productInfo?.title
      ?.replaceAll(' ', '-')
      .replaceAll(',', '')
      .replace(/[^a-z0-9\s]/gi, '');
    return `https://produto.mercadolivre.com.br/MLB-${productId}-${titleFormated}`;
  }
  if (marketplace === 'YOURVIEWS_REVIEW') {
    return productInfo.url;
  }
};

const getProductFromBundle = data => {
  if (data?.marketPlace === 'MERCADO_LIVRE') {
    const productMl = data?.marketplaceBundle?.item;
    const product = {
      picture: productMl?.pictures ? productMl?.pictures[0]?.url : null,
      title: productMl.title,
      id: productMl.id,
      price: productMl.price,
      // eslint-disable-next-line camelcase
      available_quantity: productMl.available_quantity
    };
    return product;
  }
  if (data?.marketPlace === 'YOURVIEWS_REVIEW') {
    const productAcer = data?.marketplaceBundle?.product;
    const product = {
      picture: productAcer?.image,
      title: productAcer.name,
      url: productAcer.url,
      id: productAcer.productId
    };
    return product;
  }
};

const getProductIdFromBundle = protocol => {
  if (protocol.marketPlace === 'MERCADO_LIVRE') {
    return protocol.marketplaceBundle?.item_id;
  }
  if (protocol.marketPlace === 'YOURVIEWS_REVIEW') {
    return protocol.marketplaceBundle?.product?.productId;
  }
};

export {
  getProductLink,
  getFilterInStorage,
  getProductFromBundle,
  getProductIdFromBundle
};
