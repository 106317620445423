import { Grid } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SectionContent,
  SectionContentTitle,
  SectionContentWrapper,
  SectionTabs,
  SectionText
} from '../../../../views/tickets/TicketContainer/TicketComponents/SectionTabs';

const OrderPaymentTabDetails = ({ orderData, dataClient }) => {
  const { t } = useTranslation();

  // const dataCriacaoFormatada =
  //   orderData?.dataCriacao &&
  //   moment(orderData?.dataCriacao).format('DD/MM/YYYY');

  const customerDeliveryDate = orderData?.dataEntrega
    ? moment(orderData?.dataEntrega).format('DD/MM/YYYY')
    : null;
  const customerExpeditionDate = orderData?.dataExpedicao
    ? moment(orderData?.dataExpedicao).format('DD/MM/YYYY')
    : null;

  const customerAddress = orderData?.endereco
    ? `${orderData.endereco} ${orderData.numero}`
    : null;

  return (
    <>
      <SectionTabs>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.CLIENT_NAME')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {dataClient?.nome || t('i18n.orderinfo.NOT_PROVIDED')}{' '}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          {/* <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.CLIENT_EMAIL')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {dataCriacaoFormatada || t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>CPF/CNPJ:</SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {orderData?.situacao
                    ? orderData?.situacao
                    : t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid> */}
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={8} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.customerinfo.ADDRESS')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText>
                  {customerAddress || t('i18n.customerinfo.NOT_PROVIDED')}{' '}
                  {dataClient?.complemento && <br />}
                  {dataClient?.complemento && dataClient?.complemento}
                  {dataClient?.bairro && <br />}
                  {dataClient?.bairro && dataClient?.bairro}
                  {dataClient?.referencia && <br />}
                  {dataClient?.referencia && dataClient?.referencia}
                  <br /> {t('i18n.customerinfo.ZIP_CODE')}:{' '}
                  {dataClient?.cep || t('i18n.customerinfo.NOT_PROVIDED')} |{' '}
                  {dataClient?.cidade || t('i18n.customerinfo.NOT_PROVIDED')} |{' '}
                  {dataClient?.uf || t('i18n.customerinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.customerinfo.PHONE_NUMBER')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {dataClient?.telefone || t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.trackingmodal.TRACKING_CODE')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {orderData?.rastro || t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.customerinfo.SHIPPING_COMPANY')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {orderData?.transportadora ||
                    t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.SHIPPING_DATE')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {customerExpeditionDate || t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.customerinfo.DELIVERY_COST')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {orderData?.totalFrete || t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.DELIVERY_STATUS')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {orderData?.situacaoRastro ||
                    t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.REAL_DATE_DELIVERY')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {customerDeliveryDate || t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
        </Grid>
      </SectionTabs>
    </>
  );
};

export default OrderPaymentTabDetails;
