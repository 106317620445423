import { Grid, Typography, makeStyles, Divider } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HelpIcon from '@material-ui/icons/Help';
import ErrorIcon from '@material-ui/icons/Error';
import { COLORS } from '../../../../styles/settings/colors.config';
import MarketplaceItem from './MarketplaceItem.component';

const useStyles = makeStyles(theme => ({
  gridTitle: {
    marginBlockEnd: '15px',
    backgroundColor: theme.palette.background.dark
  },
  typoColumn: {
    fontSize: '16px',
    fontWeight: 400,
    color: `${COLORS.blackText}`
  },
  dividerSpace: {
    marginBlockStart: '20px'
  },
  gridCaptionSpace: {
    marginBlockStart: '5px'
  },
  captionAlignment: {
    display: 'flex',
    alignItems: 'center'
  },
  typoCaption: {
    marginLeft: '4px'
  }
}));

const ListMarketplaces = ({ apisCondition, getDetail }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Grid container spacing={1} className={classes.gridTitle}>
        <Grid item xs={1}>
          <Typography variant="h6" className={classes.typoColumn} />
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" className={classes.typoColumn}>
            {`${t('i18n.monitor.API_NAME')}`}
          </Typography>
        </Grid>
        {/* <Grid item xs={2}>
          <Typography variant="h6" className={classes.typoColumn}>
            {`${t('i18n.macros.ACTION')}`}
          </Typography>
        </Grid> */}
        <Grid item xs={2}>
          <Typography variant="h6" className={classes.typoColumn}>
            {`${t('i18n.monitor.FREQUENCY')}`}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" className={classes.typoColumn}>
            {`${t('i18n.monitor.START_TIME')}`}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" className={classes.typoColumn}>
            {`${t('i18n.monitor.END_TIME')}`}
          </Typography>
        </Grid>
      </Grid>
      {apisCondition.map(item => (
        <MarketplaceItem item={item} key={item.text} getDetail={getDetail} />
      ))}
      <Divider className={classes.dividerSpace} />
      <Grid
        container
        direction="column"
        spacing={1}
        className={classes.gridCaptionSpace}
      >
        <Grid item xs={2} className={classes.captionAlignment}>
          <>
            <CheckCircleIcon
              fontSize="small"
              style={{ color: COLORS.btnSuccessColor }}
            />
            <Typography variant="caption" className={classes.typoCaption}>
              {t('i18n.monitor.STATUS_SUCCESS')}
            </Typography>
          </>
        </Grid>
        <Grid item xs={8} className={classes.captionAlignment}>
          <>
            <HelpIcon
              fontSize="small"
              style={{ color: COLORS.WarningColorSLA }}
            />
            <Typography variant="caption" className={classes.typoCaption}>
              {t('i18n.monitor.STATUS_INCOMPLETE')}
            </Typography>
          </>
        </Grid>
        <Grid item xs={2} className={classes.captionAlignment}>
          <>
            <ErrorIcon
              fontSize="small"
              style={{ color: COLORS.btnDangerColor }}
            />
            <Typography variant="caption" className={classes.typoCaption}>
              {t('i18n.monitor.STATUS_FAIL')}
            </Typography>
          </>
        </Grid>
        {/* <Grid item xs={8} className={classes.captionAlignment}>
          <>
            <Typography variant="caption" className={classes.typoCaption}>
              <span style={{ fontSize: '15px' }}>*</span> Monitor em teste
            </Typography>
          </>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default ListMarketplaces;
