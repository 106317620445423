import moment from 'moment';
import { COLORS } from '../../../../../styles/settings/colors.config';

export const retrieveOrderHeaderData = (dataOrder, line) => {
  switch (line) {
    case 1:
      return [
        {
          title: 'Pedido Marketplace',
          value: dataOrder?.idPedMarket || 'Aguarde...',
          margin: true
        },
        {
          title: 'Data Pedido',
          value:
            moment(dataOrder?.dataCriacao).format('DD/MM/YYYY') || 'Aguarde...',
          margin: true
        },
        {
          title: 'Marketplace',
          value: dataOrder?.marketplace || 'Aguarde...',
          margin: false
        }
      ];
    case 2:
      return [
        {
          title: 'Pedido Comercial',
          value: dataOrder?.idPedMarket || 'Aguarde...',
          margin: true
        },
        {
          title: 'Nota fiscal Origem',
          value: dataOrder?.numeroNF || 'Aguarde...',
          margin: true
        },
        {
          title: 'Data Nota fiscal',
          value: moment(dataOrder?.dataNF).format('DD/MM/YYYY') || 'Aguarde...',
          margin: false
        }
      ];
    case 3:
      return [
        {
          title: 'Forma de pagamento',
          value:
            (dataOrder?.pagamentos?.length > 0 &&
              dataOrder?.pagamentos[0].metodo) ||
            'Aguarde...',
          margin: true
        },
        {
          title: 'Parcelamento',
          value:
            (dataOrder?.pagamentos?.length > 0 &&
              `${dataOrder?.pagamentos[0].parcelas}x`) ||
            'Aguarde...',
          margin: true
        },
        {
          title: '',
          value: '',
          margin: false
        }
      ];
    default:
      return [];
  }
};

export const validateCancelling = (
  cancellationType,
  reasonCancellation,
  cancelDetail,
  orderLocation,
  enqueueSnackbar,
  transportType,
  isShippingCompany,
  numCAP,
  dataEmissao,
  statusCAP,
  rastreioDevolucao,
  selectedItems
) => {
  if (
    !cancellationType ||
    !reasonCancellation ||
    !cancelDetail ||
    !orderLocation
  ) {
    enqueueSnackbar('"Com quem esta o produto?" precisa ser preenchido.', {
      variant: 'error'
    });
    return false;
  }

  if (!transportType) {
    enqueueSnackbar(
      'Escolha CAP ou Transportadora como meio de transporte da mmercadoria de devolução.',
      {
        variant: 'error'
      }
    );
    return false;
  }

  if (!isShippingCompany && (!numCAP || !dataEmissao || !statusCAP)) {
    enqueueSnackbar('Preencha os dados do CAP antes de continuar.', {
      variant: 'error'
    });
    return false;
  }

  if (isShippingCompany && !rastreioDevolucao) {
    enqueueSnackbar('Preencha o numero do rastreio antes de continuar.', {
      variant: 'error'
    });
    return false;
  }

  if (selectedItems.length === 0) {
    enqueueSnackbar('Selecione pelo menos um produto para continuar.', {
      variant: 'error'
    });
    return false;
  }

  return true;
};

export const msToHM = miliseconds => {
  const hours = miliseconds / (1000 * 60 * 60);
  const absHours = Math.floor(hours);

  const minutes = (hours - absHours) * 60;
  const absMinutes =
    Math.floor(minutes) < 10 ? `0${Math.floor(minutes)}` : Math.floor(minutes);

  let color;

  if (hours >= 24) {
    color = COLORS.btnDangerColor;
  }

  if (hours >= 12 && hours <= 23) {
    color = COLORS.orange;
  }

  if (hours <= 11) {
    color = COLORS.greenText;
  }

  return { absHours, absMinutes, color };
};

export function capitalizeFirstLetter(string) {
  const newString = string.toLowerCase();
  return newString.charAt(0).toUpperCase() + newString.slice(1);
}
