/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import {
  Box,
  Card,
  CardContent,
  Divider,
  Tooltip,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import formHelper from '../../../utils/formHelper';

import { COLORS } from '../../../styles/settings/colors.config';
import {
  CustomTypograph,
  MainCardData,
  SecondaryCardData,
  CardDataWrapper,
  CustomCardContent,
  CardDataDivider,
  CardTitle,
  CustomHelpIcon,
  CustomHintText,
  CustomArrowDropDownUp,
  CustomLineCardContentSideBySide,
  CustomParagraphArrowDropDownUp,
  CustomTypographLowWeight
} from '../styles';

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth: 'none'
  }
}));

const MessagingCardData = ({
  firstLabel,
  secondaryLabel,
  isTeamView,
  headerData,
  showMoreDetails,
  extraData,
  firstData,
  secondaryData,
  cardType,
  subtitle,
  subtitle2,
  descriptionHint,
  mediumData
}) => {
  const classes = useStyles();
  // firstData = firstData < 10 ? `0${firstData}` : firstData;
  // secondaryData = secondaryData < 10 ? `0${secondaryData}` : secondaryData;
  firstData = formHelper.addZeroToCompleteTwoDigits(firstData);
  secondaryData = formHelper.addZeroToCompleteTwoDigits(secondaryData);

  const title = () => {
    switch (cardType) {
      case 'generic': {
        return (
          <>
            <b>Mensagens</b> trocadas entre <b>Acer e Cliente</b>
          </>
        );
      }
      case 'other': {
        return (
          <>
            <b>Mensagens</b> especializadas Marketplace
          </>
        );
      }
      case 'total': {
        return (
          <>
            <b>Total Geral de Mensagens</b> Cliente + Acer + Marketplace
          </>
        );
      }
      default: {
        return 'Mensagens';
      }
    }
  };

  const hint = () => {
    switch (cardType) {
      case 'generic': {
        return (
          <>
            <b>Total nos protocolos</b>
            <br />
            <ul style={{ listStyleType: 'none' }}>
              <li>
                <b>Enviadas pelo Cliente:</b> Quantidade de mensagens enviadas
                pelo cliente na aba 'Mensagens' do Conecta.
                <br />
              </li>
              <li>
                <b>Enviadas pela Acer:</b> Quantidade de mensagens enviadas pela
                Acer na aba 'Mensagens' do Conecta.
                <br />
              </li>
              <li>
                <b>Total:</b> Soma "Enviadas Pelo Cliente" + "Enviadas Pela
                Acer"
              </li>
            </ul>
            <br />
            <b>Média por protocolo</b>
            <ul style={{ listStyleType: 'none' }}>
              <li>
                <b>Enviadas pelo Cliente:</b> "Enviadas pelo Cliente do Card
                Total nos protocolos" / "Total do Card Protocolos Abertos no
                Período"
              </li>
              <li>
                <b>Enviadas pela Acer:</b> "Enviadas pela Acer do Card Total nos
                protocolos" / "Total do Card Protocolos Abertos no Período"
              </li>
              <li>
                <b>Total:</b> "Total do Card Total nos protocolos" / "Total do
                Card Protocolos Abertos no Período"
              </li>
            </ul>
          </>
        );
      }
      case 'other': {
        return (
          <ul style={{ listStyleType: 'none' }}>
            <li>
              <b>Total:</b> Quantidade de mensagens enviadas fora da aba
              'Mensagens' do Conecta
              <br />
            </li>
            <li>
              <b>Enviadas pelo Cliente:</b> Quantidade de mensagens enviadas
              pelo Cliente fora da aba 'Mensagens' do Conecta
              <br />
            </li>
            <li>
              <b>Enviadas pela Acer:</b> Quantidade de mensagens enviadas pela
              Acer fora da aba 'Mensagens' do Conecta
              <br />
            </li>
            <li>
              <b>Enviadas pelo Marketplace:</b> Quantidade de mensagens enviadas
              pelo Marketplace fora da aba 'Mensagens' do Conecta
            </li>
          </ul>
        );
      }
      case 'total': {
        return (
          <>
            Somatório dos valores exibidos nos demais quadros que tratam
            mensagens.
          </>
        );
      }
      default: {
        return 'Mensagens';
      }
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Card
        style={{ width: '100%', marginBottom: '20px', borderRadius: '20px' }}
      >
        <CardTitle>
          <Box
            display="flex"
            justifyContent="flex-start"
            flexWrap="wrap"
            flexDirection="row"
            style={{ gap: '5px' }}
          >
            <CustomTypographLowWeight variant="h5">
              {title()}
            </CustomTypographLowWeight>
          </Box>

          <Box style={{ gap: '10px' }} display="flex" justifyContent="flex-end">
            {descriptionHint && (
              <Tooltip
                placement="top"
                PopperProps={{
                  disablePortal: true,
                  popperOptions: {
                    positionFixed: true,
                    modifiers: {
                      preventOverflow: {
                        enabled: true,
                        boundariesElement: 'window'
                      }
                    }
                  }
                }}
                classes={{ tooltip: classes.customWidth }}
                arrow
                title={<CustomHintText>{hint()}</CustomHintText>}
              >
                <CustomHelpIcon fontSize="small" color="primary" />
              </Tooltip>
            )}
          </Box>
        </CardTitle>
        <Divider />
        {subtitle && (
          <CardContent style={{ marginBlockEnd: '-10px' }}>
            <CustomTypograph variant="h6" colortext={COLORS.cardMessageBlue}>
              {subtitle}
            </CustomTypograph>
          </CardContent>
        )}
        {headerData && (
          <CustomCardContent>
            <CardDataWrapper>
              {!isTeamView && (
                <>
                  <MainCardData>
                    <p className="label">{firstLabel}</p>
                    <p className="main-data">{firstData}</p>
                    <CustomParagraphArrowDropDownUp>
                      <CustomArrowDropDownUp />
                    </CustomParagraphArrowDropDownUp>
                  </MainCardData>
                  <CardDataDivider />
                  <SecondaryCardData>
                    <p className="label">{secondaryLabel}</p>
                    <p className="main-data">{secondaryData}</p>
                  </SecondaryCardData>
                </>
              )}

              {isTeamView && (
                <MainCardData>
                  <p className="label">{secondaryLabel}</p>
                  <p className="main-data">{secondaryData}</p>
                  {showMoreDetails && (
                    <CustomParagraphArrowDropDownUp>
                      <CustomArrowDropDownUp />
                    </CustomParagraphArrowDropDownUp>
                  )}
                </MainCardData>
              )}
            </CardDataWrapper>
          </CustomCardContent>
        )}

        {headerData && <Divider />}
        <CustomLineCardContentSideBySide>
          <Grid
            container
            direction="row"
            justifycontent="flex-start"
            style={{ marginLeft: '1em' }}
            spacing={1}
          >
            {extraData?.map(
              item =>
                item.show && (
                  <Grid
                    item
                    container
                    justifycontent="flex-start"
                    alignItems="center"
                    xs={12}
                    key={Math.random()}
                    spacing={2}
                  >
                    <Grid item lg={6}>
                      <CustomTypograph
                        align="right"
                        style={{ fontSize: headerData ? '35px' : '45px' }}
                      >
                        {formHelper.addZeroToCompleteTwoDigits(item.value)}
                      </CustomTypograph>
                    </Grid>
                    <Grid item lg={6}>
                      <p style={{ fontSize: '85%' }}>{item.label}</p>
                    </Grid>
                  </Grid>
                )
            )}
          </Grid>
        </CustomLineCardContentSideBySide>
        {mediumData && <Divider />}
        {subtitle2 && (
          <CardContent style={{ marginBlockEnd: '-10px' }}>
            <CustomTypograph variant="h6" colortext={COLORS.cardMessageBlue}>
              {subtitle2}
            </CustomTypograph>
          </CardContent>
        )}
        {mediumData && (
          <CustomLineCardContentSideBySide>
            <Grid
              container
              direction="row"
              justifycontent="flex-start"
              style={{ marginLeft: '1em' }}
              spacing={1}
            >
              {mediumData?.map(
                item =>
                  item.show && (
                    <Grid
                      item
                      container
                      justifycontent="flex-start"
                      alignItems="center"
                      xs={12}
                      key={Math.random()}
                      spacing={2}
                    >
                      <Grid item lg={isTeamView ? 5 : 5}>
                        <CustomTypograph
                          align="right"
                          style={{ fontSize: headerData ? '35px' : '45px' }}
                        >
                          {item.value}
                        </CustomTypograph>
                      </Grid>
                      <Grid item lg={isTeamView ? 7 : 7}>
                        <p style={{ fontSize: '85%' }}>{item.label}</p>
                      </Grid>
                    </Grid>
                  )
              )}
            </Grid>
          </CustomLineCardContentSideBySide>
        )}
      </Card>
    </div>
  );
};

export default MessagingCardData;
