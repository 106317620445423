import React from 'react';

import B2WDashboard from '../components/MarketplaceScore/B2WDashboard';
import MagaluDashboard from '../components/MarketplaceScore/MagaluDashboard';
import MLDashboard from '../components/MarketplaceScore/MLDashboard';
import CNOVADashboard from '../components/MarketplaceScore/CNOVADashboard';

export default function DashboardContent({ dataEquipDash, dashboardFilter }) {
  const { score_b2w, score_MAGALU, score_MERCADO_LIVRE } = dataEquipDash;
  const { marketplace, isRealTime, startDate } = dashboardFilter;

  return (
    <>
      {marketplace.includes('B2W') && (
        <B2WDashboard
          score={score_b2w?.score_geral_B2W}
          possibleScore={5}
          {...{ score_b2w }}
        />
      )}

      {marketplace.includes('MAGALU') && (
        <MagaluDashboard
          score={score_MAGALU?.score_geral_MAGALU}
          possibleScore={5}
          {...{ score_MAGALU }}
        />
      )}

      {marketplace.includes('MERCADO_LIVRE') && (
        <MLDashboard
          score={score_MERCADO_LIVRE?.score_MERCADO_LIVRE_real_time}
          possibleScore={5}
          {...{ score_MERCADO_LIVRE, isRealTime, startDate }}
        />
      )}

      {marketplace.includes('CNOVA') && (
        <CNOVADashboard
          score={5}
          possibleScore={5}
          {...{ isRealTime, startDate }}
        />
      )}
    </>
  );
}
