import React from 'react';
import { useTranslation } from 'react-i18next';
import ProtocolCardDataWithDrillDown from '../../components/ProtocolCardDataWithDrillDown.component';

const MediationCard = ({
  isAdmin,
  dataEquipDash,
  dataIndividualDash,
  hintByMarketplace,
  marketplace
}) => {
  const { t } = useTranslation();
  return (
    <ProtocolCardDataWithDrillDown
      title="mediation"
      showMoreDetails={false}
      isTeamView={isAdmin}
      firstLabel={t('i18n.dashboard.components.individual')}
      secondaryLabel={t('i18n.dashboard.components.equip')}
      secondaryData={dataEquipDash?.mediation}
      firstData={dataIndividualDash?.mediation}
      descriptionHint={hintByMarketplace(marketplace)}
    />
  );
};

export default MediationCard;
