import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import API from '../../../../services/api';
import { COLORS } from '../../../../styles/settings/colors.config';
import { CustomChip } from '../../../../styles/components/Tags';

const StyledTagField = styled(TextField)`
  .MuiInput-underline:before {
    border-bottom: 0;
  }
  .MuiInput-underline:after {
    border-bottom: 0;
  }
  .MuiInput-underline:hover {
    border-bottom: 0;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0;
  }

  .MuiAutocomplete-tagSizeSmall {
    border-radius: 5px;
    background: ${COLORS.gray};
    border: 1px solid #ccc;
  }

  .MuiInputBase-input::placeholder {
    color: ${COLORS.greenText};
    opacity: 1;
  }

  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    width: auto;
  }
  .MuiAutocomplete-inputRoot[class*='MuiInput-root'][class*='MuiInput-marginDense']
    .MuiAutocomplete-input {
    cursor: pointer;
  }
`;

const StyledAutoComplete = styled(Autocomplete)`
  .MuiAutocomplete-endAdornment {
    display: none;
  }
  padding: 5px;
  background: ${props => props.disabled && '#f1f1f1'};
`;

const Products = ({ blockInput = false, tagOptions, clientData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [selectedTagOptions, setSelectedTagOptions] = useState(
    clientData?.productsFromVtex || []
  );

  const updateProduct = (partNumber, action) => {
    API.put(
      `/auth/ms-chat/productFromVtex/update-product-vtex/${clientData.hashConnection}`,
      { partNumber, action }
    ).catch(() => {
      enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
        variant: 'error'
      });
    });
  };

  const handleRemoveChip = chip => {
    const selectedTags = selectedTagOptions?.filter(item => {
      return item?.partNumber !== chip?.partNumber;
    });

    setSelectedTagOptions(selectedTags);

    const { partNumber } = chip;
    updateProduct(partNumber, 'remove');
  };

  const getAlreadySelectedIds = () => {
    const alreadySelectedIds = selectedTagOptions.map(selectedItem => {
      return selectedItem?.partNumber;
    });
    return alreadySelectedIds;
  };

  const addTag = value => {
    const alreadySelectedIds = getAlreadySelectedIds();
    value.map(item => {
      const itemId = item?.partNumber;
      if (!alreadySelectedIds?.includes(itemId)) {
        setSelectedTagOptions(value);
        const { partNumber } = item;
        updateProduct(partNumber, 'add');
      }
      return item;
    });
  };

  useEffect(() => {
    if (clientData?.productsFromVtex?.length > 0) {
      const ticketproducts = clientData?.productsFromVtex;
      setSelectedTagOptions(ticketproducts);
    } else {
      setSelectedTagOptions([]);
    }
  }, [clientData]);

  return (
    <Box mb={2}>
      <StyledAutoComplete
        fullWidth={false}
        multiple
        onChange={(event, value, reason) => {
          if (reason === 'select-option') {
            addTag(value, true);
          }
          // addTag(value);
        }}
        disabled={blockInput}
        noOptionsText="-"
        disableClearable
        size="small"
        options={tagOptions}
        getOptionLabel={option =>
          `${option?.partNumber} - ${option?.productName}`
        }
        defaultValue={[]}
        value={selectedTagOptions}
        renderTags={value =>
          value.map(
            option =>
              option?.productName && (
                <CustomChip key={Math.random()}>
                  <p>
                    {option?.partNumber} - {option?.productName}
                  </p>
                  <button
                    type="button"
                    onClick={() => handleRemoveChip(option)}
                  >
                    x
                  </button>
                </CustomChip>
              )
          )
        }
        renderInput={params => (
          <StyledTagField
            {...params}
            variant="standard"
            placeholder="+ Produto"
          />
        )}
      />
    </Box>
  );
};

export default Products;
