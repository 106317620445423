/* eslint-disable func-names */
import React, { useEffect, useState } from 'react';
import { Link, Typography } from '@material-ui/core';
import styled from 'styled-components';
import PersonIcon from '@material-ui/icons/Person';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import API from '../../../../services/api';
import { COLORS } from '../../../../styles/settings/colors.config';
import timezone from '../../../../utils/timezoneFormat';

const Message = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  justify-content: ${props => (props.isAttendant ? 'flex-end' : 'flex-start')};
`;

const MessageDetails = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  order: ${props => (props.isAttendant ? '1' : '0')};
  margin: ${props =>
    props.isAttendant ? '0px 0px 0px 5px' : '0px 5px 0px 0px'};
`;

const MessageIcon = styled('div')`
  border-radius: 1000px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  background: #ccc;
  font-size: 25px;
  svg {
    font-size: 16px;
  }
`;

const MessageWrapper = styled('div')`
  border-radius: 10px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  width: 80%;
  background-color: ${props =>
    props.isAttendant ? COLORS.cardMessageGreen : COLORS.cardMessageWhite};
  border-color: ${props => (props.error ? '#c00' : '#ccc')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${props => (props.isAttendant ? 'flex-end' : 'flex-start')};
  // word-break: break-all;

  h5,
  p {
    color: ${props => (props.error ? '#c00' : '#263238')};
    opacity: ${props => (props.error ? '0.7' : '1')};
  }
`;

const MessageName = styled(Typography)`
  font-size: 10px;
  margin-top: 3px;
`;

const MessageText = styled(Typography)`
  white-space: pre-line;
  width: 100%;
  font-size: 14px;
`;

const HourMessage = styled(Typography)`
  font-size: 10px;
`;

function urlify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => {
    return `<a target="_blank" href="${url}" title="${url}" rel="noreferrer" style="word-break: break-all">${url}</a>`;
  });
}

const MessageItem = ({
  name,
  message,
  urlRaw,
  hour,
  isAttendant,
  login,
  nameAttachment,
  error = false
}) => {
  const messages = message?.split('\n');
  const [newAttachment, setNewAttachment] = useState(null);
  
  const handleGetNewAttachment = async signedUrl => {
    try {
      const response = await API.post('/auth/ms-chat/chats/presigned-url', {
        attachmentRawUrl: signedUrl
      });

      setNewAttachment(response?.data?.attachment_presigned_url);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Erro ao obter a nova URL:', err);
    }
  };

  useEffect(() => {
    if (newAttachment) {
      window.open(newAttachment, '_blank');
    }
  }, [newAttachment]);

  return (
    <Message isAttendant={isAttendant}>
      <MessageDetails isAttendant={isAttendant}>
        <MessageIcon>
          {isAttendant ? <PermContactCalendarIcon /> : <PersonIcon />}
        </MessageIcon>
        <HourMessage variant="subtitle2">{timezone(hour, 'HH:mm')}</HourMessage>
      </MessageDetails>
      <MessageWrapper isAttendant={isAttendant} error={error}>
        {nameAttachment && nameAttachment !== 'false' ? (
          <Typography>
            <Link
              target="_blank"
              style={{ wordBreak: 'break-all' }}
              rel="noreferrer"
              href={newAttachment || urlRaw}
              onClick={e => {
                e.preventDefault();
                handleGetNewAttachment(
                  urlRaw || message?.slice(0, message?.indexOf('?'))
                );
              }}
            >
              {nameAttachment}
            </Link>
          </Typography>
        ) : (
          <>
            {messages?.map(
              item =>
                item.length > 0 && (
                  <MessageText
                    variant="h5"
                    key={item}
                    dangerouslySetInnerHTML={{ __html: urlify(item) }}
                  />
                )
            )}
          </>
        )}

        <MessageName>{name || login}</MessageName>
        {error && (
          <MessageName>
            Essa mensagem não pode ser entregue. Tente enviá-la novamente.
          </MessageName>
        )}
      </MessageWrapper>
    </Message>
  );
};

export { MessageItem };
