import {
  Box,
  CircularProgress,
  Container,
  IconButton
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';
import { StyledSpinner } from '../../styles/components/SpinnerDefault';
import FetchContentError from '../../components/FetchContentError';
import Page from '../../components/Page';
import userService from '../../services/user.service';
import API from '../../services/api';
import {
  CustomDataTableWrapper,
  CustomDataTable,
  CustomTableButton
} from '../../styles/components/CustomDataTable';
import CustomFooter from '../../styles/components/CustomFooter';
import HeaderTitle from '../../components/HeaderTitle';

const CompaniesListView = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [hasFetchError, setFetchError] = useState(false);

  const { t } = useTranslation();

  const columns = [
    {
      name: 'vtex_id',
      label: t('i18n.companies.ID_VTEX'),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'name',
      label: t('i18n.companies.NAME'),
      options: {
        filter: false,
        sort: true
      }
    }
  ];

  const [data, setData] = useState([]);

  function compare(a, b) {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }

  const refreshTable = () => {
    setLoading(true);
    setFetchError(false);
    API.post(`/auth/ms-ticket/company/list`)
      .then(response => {
        const companies = response.data._embedded.companyResourceList;
        const sorted = companies.sort(compare);
        setData(sorted);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(refreshTable, []);

  if (hasFetchError) {
    return <FetchContentError fetch={refreshTable} />;
  }

  const options = {
    pagination: true,
    selectableRows: false,
    viewColumns: false,
    filter: false,
    print: false,
    download: false,
    customToolbarSelect: () => {},
    onCellClick: (colData, cellMeta) => {
      if (userService.hasRole('company_detail')) {
        history.push({
          pathname: `/company-container`,
          state: { company: data[cellMeta.rowIndex], companies: data }
        });
      } else {
        enqueueSnackbar(t('i18n.companies.DENIED_EDITION'), {
          variant: 'warning'
        });
      }
    },
    customToolbar: () => {
      if (userService.hasRole('company_new')) {
        return (
          <>
            <IconButton disabled>|</IconButton>
            <CustomTableButton
              variant="text"
              color="primary"
              size="small"
              onClick={() => {
                history.push({
                  pathname: 'company-container',
                  state: { companies: data }
                });
              }}
              startIcon={<AddIcon />}
            >
              {t('i18n.companies.CREATE')}
            </CustomTableButton>
          </>
        );
      }
      return <></>;
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: !loading
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: column =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      toolbar: {
        search: t('i18n.datatable.toolbar.SEARCH'),
        downloadCsv: 'Download CSV',
        print: 'Print',
        viewColumns: 'View Columns',
        filterTable: 'Filter Table'
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };

  return (
    <>
      <Page title={t('i18n.companies.COMPANIES')}>
        <Container maxWidth={false}>
          <Box mt={3}>
            <CustomDataTableWrapper style={{ opacity: loading ? '0.3' : '1' }}>
              <CustomDataTable
                title={<HeaderTitle title={t('i18n.companies.COMPANIES')} />}
                data={data}
                columns={columns}
                options={options}
                selectableRowsOnClick
                className="table-clickable"
              />
            </CustomDataTableWrapper>
            <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
              <CircularProgress />
            </StyledSpinner>
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default CompaniesListView;
