import {
  Box,
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { logisticsStatuses, serviceStatuses } from './options';

const drawerUseStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  hide: {
    display: 'none'
  },
  select: {
    height: '20px',
    backgroundColor: 'white'
  }
}));

const Container = ({
  search,
  setSearch,
  status,
  setStatus,
  handleSearch,
  filterType
}) => {
  const classes = drawerUseStyles();
  const [selectItems, setSelectItems] = useState([]);

  const loadSelectItems = () => {
    switch (filterType) {
      case 'logistics':
        return logisticsStatuses;
      case 'service':
        return serviceStatuses;
      default:
        return logisticsStatuses;
    }
  };

  useEffect(() => {
    setSelectItems(loadSelectItems());
  }, [filterType]);

  const clearFilter = () => {
    setSearch('');
    setStatus('waiting');
  };

  return (
    <Box
      padding="20px 30px 0 30px"
      height="74vh"
      style={{ backgroundColor: '#F3F3F3', width: '100%' }}
    >
      <Box mb={2} style={{ width: '100%' }}>
        <TextField
          size="small"
          value={search || ''}
          onChange={e => {
            setSearch(e.target.value);
          }}
          fullWidth
          style={{ backgroundColor: 'white' }}
          label="Nota Fiscal"
          variant="outlined"
        />
      </Box>
      <Box mb={2}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            defaultValue=""
            value={status || ''}
            onChange={e => {
              setStatus(e.target.value);
            }}
            inputProps={{
              className: classes.select
            }}
            label="Status"
          >
            <MenuItem value="">
              <em>Nenhum</em>
            </MenuItem>
            {selectItems.map(item => (
              <MenuItem value={item.value} key={Math.random()}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box mb={2} mt={4} display="flex" justifyContent="space-between">
          <Button variant="outlined" color="secondary" onClick={clearFilter}>
            Limpar
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSearch(search, status)}
          >
            Filtrar
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Container;
