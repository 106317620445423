import {
  TextField,
  Typography,
  Chip,
  Select,
  ListItemText,
  ListItemAvatar,
  ListItem
} from '@material-ui/core';
import styled from 'styled-components';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { COLORS } from '../../styles/settings/colors.config';
import PreSalePagination from './components/preSalePagination.component';

const CustomIcon = styled('div')`
  color: ${COLORS.whiteText};
  background: ${COLORS.greenText};
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  svg {
    color: ${COLORS.whiteText};
    fill: ${COLORS.whiteText};
  }
`;

const PreSaleTabWrapper = styled('div')`
  // height: 100%;
`;

const CustomDeleteOutlineIcon = styled(DeleteOutlineIcon)`
  color: ${COLORS.grayText};
  cursor: pointer;
  opacity: 0.9;
  font-size: 20px;
`;

const PreSaleScrollDirection = styled('div')`
  direction: ltr;
  height: 100%;
  display: block;
  background: transparent;
  min-height: 390px;
`;

const PreSaleCustomFooter = styled(PreSalePagination)`
  display: grid !important;
`;

const MessageWrapper = styled('div')`
  position: ${props => !props.activeAbsolute && 'absolute'};
  z-index: 102;
  padding: 15px 30px;
  width: 100%;
  background: #fff;
  height: 100%;
  &.activate {
    background: #deedd6;
    opacity: 1;
    height: auto;
    padding-bottom: 30px;
    border-radius: 0px 10px 10px 10px;
  }
`;

const MessageTextField = styled(TextField)`
  background: #fff;
  margin-bottom: 15px;

  p {
    background: #f4f6f8;
    margin: 0;
    padding: 4px 14px 0px 14px;
  }
`;

const MessageProductData = styled('div')`
  width: 100%;
  padding: 15px;
  // margin-top: 15px;
  //border-radius: 5px;
  // border: 1px solid #eee;
  // background: ${COLORS.whiteText};
`;

const MessageHeader = styled('div')`
  p {
    font-size: 14px;
    color: ${COLORS.grayText};
    font-weight: bold;
    margin-bottom: 10px;
  }
`;
const MessageContent = styled('div')`
  margin-top: 10px;
`;
const MessageBody = styled('div')``;
const MessageTextWrapper = styled('div')``;

const MessageChip = styled(Chip)`
  padding: 0px;
  font-size: 10px;
  background: ${COLORS.greenText};
  color: #fff;
  margin-bottom: 10px;
  margin-right: 2px;
  height: 22px;
`;

const MessageProductImg = styled('img')`
  margin-right: 15px;
`;

const MessageProductLink = styled('a')`
  color: ${COLORS.greenText};
  font-weight: bold;
  word-break: break-all;
`;

const MessageProductHeader = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
`;

const MessageAnswerOption = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  p {
    font-size: 12px;
    color: ${COLORS.grayText};
  }
`;

const TabImageText = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
`;

const TabQuestionProduct = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MessageProductContent = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
`;

const MessageProductContentItem = styled('div')`
  p {
    color: ${COLORS.grayText};
    font-size: 14px;
  }
`;

const MessageButtons = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: ${props => (props.right ? 'row-reverse' : '')};
`;

const TabMessageFlex = styled.div`
  display: flex;
`;

const StyledTicketHeader = styled.header`
  // border-bottom: 2px solid ${COLORS.cardMessageGray};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  // margin-bottom: 24px;
  padding: 0 0 24px;

  .header-details {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;

    img {
      position: relative;
      top: 2px;
      width: 90px;
    }

    .store-ml {
      top: 5px;
      width: 55px;
    }

    .store-acer {
      width: 110px;
    }

    .store-b2w {
      width: 55px;
    }

    &--marketplace {
      border-right: 3px solid #999999;
      color: ${COLORS.grayText};
      font-size: 28px;
      margin: 5px 32px 0 24px;
      padding-right: 32px;
      text-transform: capitalize;
    }

    &--marketplaceNoTextTransform {
      border-right: 3px solid #999999;
      color: ${COLORS.grayText};
      font-size: 28px;
      margin: 5px 32px 0 24px;
      padding-right: 32px;
    }

    &--ticket {
      color: ${COLORS.greenText};
      font-size: 27px;
      font-weight: bold;
      margin: 5px 0 0;
    }
  }

  .header-details--action {
    align-self: flex-end;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    top: 16px;

    button:not(:last-child) {
      &::after {
        background: #999999;
        content: '';
        height: 24px;
        margin-left: 24px;
        width: 1px;
      }
    }
  }

  .header-btn--icon {
    margin-right: 16px;
  }
`;
const TabWrapper = styled('div')`
  margin-bottom: 30px;
  width: 100%;

  display: flex;
  justity-content: space-between;
  align-items: center;
  border-right: 30px solid transparent;

  &.activate {
    border-right: 30px solid #deedd6;
  }
`;

const TabWrapperDetails = styled('div')`
  width: 100%;
  padding: 0px 15px 30px 30px;
  border-radius: 10px;
  display: table;
  align-items: center;
  background: ${props => (props.blocked === 'CLOSED' ? '#eee' : '#fff')};
  // box-shadow: 0px 0px 1px #00000070;

  .stars {
    display: none;
  }

  .evaluationMessage {
    display: -webkit-box;
    height: 50px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.activate {
    background: #deedd6;
    border-right: 1px solid transparent;
    box-shadow: none;
    border-radius: 10px 0px 0px 10px;
    :hover {
      background: #deedd6;
    }

    .stars {
      display: block;
    }

    .evaluationMessage {
      display: block;
      height: fit-content;
      -webkit-line-clamp: inherit;
      -webkit-box-orient: inherit;
      overflow: inherit;
      text-overflow: inherit;
    }
  }
`;

const TabDetails = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 12px;
    color: ${COLORS.grayText};
  }
`;
const TabDetailsFooter = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block-end: -30px;

  p {
    font-size: 12px;
    color: ${COLORS.grayText};
  }
`;
const TabHeader = styled('div')`
  background: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  margin: 10px 0px;
  border: 1px solid #eee;
  p {
    width: 100%;
    display: block;
    text-align: left;
    font-size: 14px;
    color: ${COLORS.grayText};
  }
`;

const TabFirstMessage = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  p {
    font-size: 16px;
    font-weight: bold;
    color: ${COLORS.grayText};
    width: 100%;
  }
`;

const TabLink = styled(Typography)`
  color: ${COLORS.greenText} !important;
  font-weight: ${props => (props.unbold ? 'inherit' : 'bold')};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const TabLinkDisabled = styled(Typography)`
  color: ${COLORS.greenText} !important;
  font-weight: bold;
`;

const TabLinkBreak = styled(Typography)`
  color: ${COLORS.greenText} !important;
  font-weight: bold;
  cursor: pointer;
  word-break: break-all;
  :hover {
    text-decoration: underline;
  }
`;

const TabLinkEllipsis = styled('span')`
  color: ${COLORS.greenText} !important;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (min-width: 1024px) {
    max-width: 29em;
  }
  @media screen and (min-width: 1440px) {
    max-width: 35em;
  }
  @media screen and (min-width: 1920px) {
    max-width: 48em;
  }
  :hover {
    text-decoration: underline;
  }
`;

const TabText = styled(Typography)`
  color: ${COLORS.grayText} !important;
`;

const TabContent = styled('div')`
  padding: 10px 10px 15px 15px;
  border: 2px solid #eee;
  border-radius: 5px;
  background-color: whitesmoke;
  width: 100%;
`;

const TabBody = styled(TabDetails)`
  margin-top: 10px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const CustomerSpan = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: flex-start;
  min-width: 200px;
  max-width: 200px;

  @media (max-width: 1366px) {
    min-width: 150px;
  }
`;

const TabIconButton = styled.div`
  display: flex;
  width: 50px;
  align-items: center;
  color: ${COLORS.greenText} !important;
`;

const TabIconButtonWrapper = styled.div`
  padding: 10px;
  cursor: pointer;
`;

const TabDelete = styled('div')`
  display: flex;
  align-items: center;
`;

export const CustomSelect = styled(Select)`
  .MuiFormControl-root.MuiFormControl-fullWidth {
    height: 40px;
  }

  label {
    top: -8px;
  }
`;

const CustomListItemText = styled(ListItemText)`
  span {
    font-weight: ${props => props.selectedcolor && '500'};
    text-transform: capitalize !important;
    color: ${COLORS.grayText};
  }
`;

const CustomListItemAvatar = styled(ListItemAvatar)`
  min-width: 43px;
`;

const CustomListItem = styled(ListItem)`
  padding-top: 5px;
  padding-bottom: 4px;
`;

const CustomTextField = styled(TextField)`
  .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 12px) scale(0.8);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }

  .PrivateNotchedOutline-legendNotched-46 {
    max-width: 1000px;
    transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
  }
`;

const MessageReviews = styled('div')`
  svg {
    fill: #ffcc00;
  }
`;

const MessageEvaluation = styled(Typography)``;
const MessageIndication = styled(Typography)`
  span {
    color: ${COLORS.blueText};
  }
`;

export {
  CustomListItemText,
  CustomListItemAvatar,
  CustomListItem,
  CustomTextField,
  CustomDeleteOutlineIcon,
  PreSaleTabWrapper,
  PreSaleCustomFooter,
  PreSaleScrollDirection,
  MessageTextField,
  MessageProductData,
  MessageHeader,
  MessageContent,
  MessageBody,
  MessageTextWrapper,
  MessageProductImg,
  MessageProductLink,
  MessageProductHeader,
  MessageProductContent,
  MessageProductContentItem,
  MessageWrapper,
  MessageButtons,
  MessageChip,
  MessageAnswerOption,
  CustomIcon,
  StyledTicketHeader,
  TabWrapper,
  TabDetails,
  TabDetailsFooter,
  TabHeader,
  TabContent,
  TabBody,
  TabLink,
  TabText,
  TabDelete,
  CustomerSpan,
  TabFirstMessage,
  TabMessageFlex,
  TabIconButton,
  TabWrapperDetails,
  TabIconButtonWrapper,
  TabQuestionProduct,
  TabImageText,
  TabLinkEllipsis,
  TabLinkBreak,
  TabLinkDisabled,
  MessageEvaluation,
  MessageIndication,
  MessageReviews
};
