import {
  Card,
  Table,
  TableBody,
  TableCell,
  // TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
// import StarIcon from '@material-ui/icons/Star';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AcerModal from '../../../../components/AcerModal';
import RaStatusTranslate from '../raStatusTranslate';

const CustomTableRow = styled(TableRow)`
  td {
    font-weight: ${props => (props.linked === 'true' ? '500' : '')};
  }
  :hover {
    background: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }
`;

const CustomTableCell = styled(TableCell)`
  font-size: 12px;
`;

const CustomRACard = styled(Card)`
  max-height: 500px;
  overflow: auto;
`;

const ModalLinkComplaint = ({
  openLinkModal,
  setOpenLinkModal,
  handleShowRADetails,
  raTickets,
  onlyLinkedRaTickets,
  raTicketsFromProtocol
}) => {
  const { t } = useTranslation();

  if (!raTickets && !raTicketsFromProtocol) {
    return;
  }

  if (onlyLinkedRaTickets) {
    if (raTickets?.length > 0) {
      raTickets = raTickets.filter(ra => ra.linked === true);
    } else if (raTicketsFromProtocol?.length > 0) {
      raTickets = raTicketsFromProtocol.filter(ra => ra.linked === true);
    }
  } else if (raTickets?.length === 0) {
    raTickets = raTicketsFromProtocol;
  }

  return (
    <AcerModal
      fullWidth
      maxWidth="lg"
      onClose={() => setOpenLinkModal(false)}
      open={openLinkModal}
      modalTitle={`${t('i18n.dashboardlayout.TICKETS')} ${t(
        'i18n.marketplaces.RECLAME_AQUI'
      )}`}
    >
      <CustomRACard>
        <Table stickyHeader>
          <caption style={{ captionSide: 'top' }}>
            <Alert severity="info">{t('i18n.ticketcontainer.RA_INFO')}</Alert>
          </caption>
          <TableHead>
            <TableRow>
              <TableCell>
                {t('i18n.ticketcontainer.RA_RELATED1')}
                <br /> {t('i18n.ticketcontainer.RA_RELATED2')}
              </TableCell>
              <TableCell>{t('i18n.ticketcontainer.RA_ID')}</TableCell>
              <TableCell>{t('i18n.ticketcontainer.HUGME_ID')}</TableCell>
              <TableCell>{t('i18n.ticketslistview.STATUS')}</TableCell>
              <TableCell>{t('i18n.ticketcontainer.RA_TITLE')}</TableCell>
              <TableCell>{t('i18n.ticketcontainer.RA_DATE')} </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {raTickets?.map(item => (
              <CustomTableRow
                onClick={() => {
                  handleShowRADetails(item);
                }}
                key={`${item.idReclameAquiAPI}${item.idHugmeAPI}`}
                linked={item?.linked?.toString()}
              >
                <CustomTableCell>
                  {item.linked && <CheckBoxOutlinedIcon color="primary" />}
                  {!item.linked && (
                    <CheckBoxOutlineBlankOutlinedIcon
                      style={{ fill: 'grey' }}
                    />
                  )}
                </CustomTableCell>
                <CustomTableCell>{item.idReclameAquiAPI}</CustomTableCell>
                <CustomTableCell>
                  {item.idHugmeAPI.length > 0 ? item.idHugmeAPI.join(', ') : ''}
                </CustomTableCell>
                <CustomTableCell>
                  <RaStatusTranslate status={item.idStatusReclameAqui} />
                </CustomTableCell>

                <CustomTableCell>{item.complaintTitle}</CustomTableCell>
                <CustomTableCell>
                  {moment(item.creationDate).format('DD/MM/YYYY HH:mm')}
                </CustomTableCell>
              </CustomTableRow>
            ))}
          </TableBody>
        </Table>
      </CustomRACard>
    </AcerModal>
  );
};

export default ModalLinkComplaint;
