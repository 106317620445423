import React, { useContext } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Button,
  Box
} from '@material-ui/core';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { COLORS } from '../../../styles/settings/colors.config';
import { ThemeContext } from '../../../layouts/MonitorLayout';

const StyledCustomCard = styled(Card)`
  border-radius: ${props => (props.rounded ? props.rounded : '20px')};
  display: flex;
  flex-direction: column;
  hr {
    background-color: ${props => props.screen && '#777'};
  }
`;

const CustomCardHeader = styled(CardHeader)`
  color: ${COLORS.grayText};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CustomCardContent = styled(CardContent)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomTypography = styled(Typography)`
  font-size: ${props => (props.font ? props.font : '45px')};
  color: ${props => (props.color ? props.color : COLORS.grayText)};
  font-weight: 500;
`;

const CustomLabel = styled(Typography)`
  color: ${props => props.color};
  font-size: ${props => (props.font ? props.font : '1rem')};
`;

const CustomDivider = styled('div')`
  height: 40px;
  background-color: #aaa;
  width: 2px;
  display: block;
`;

const CustomHeader = styled(Typography)`
  color: ${props => props.color};
  font-size: ${props => props.font && props.font};
`;

const CustomButton = styled(Button)`
  position: fixed;
  left: 24px;
  bottom: 24px;
`;

const GoBackButton = ({ link }) => {
  const history = useHistory();

  return (
    <CustomButton
      variant="outlined"
      color="default"
      onClick={() => {
        history.push(link);
      }}
    >
      Voltar
    </CustomButton>
  );
};

const Title = ({ header, title, color, headerColor, font }) => {
  return (
    <div style={{ display: 'flex', gap: '8px', fontSize: font }}>
      <CustomHeader font={font} color={headerColor}>
        {header}
      </CustomHeader>
      <CustomHeader font={font} color={color}>
        <b>{title}</b>
      </CustomHeader>
    </div>
  );
};

const CustomCard = ({
  header,
  title,
  mainData,
  showMoreDetails,
  extraData,
  children,
  flag,
  color
}) => {
  const theme = useContext(ThemeContext);

  return (
    <StyledCustomCard
      style={{
        background: theme.cardBackground,
        borderRadius: theme?.rounded0
      }}
      screen={theme.screen}
    >
      <CustomCardHeader
        title={
          <Title
            {...{
              header,
              title,
              color,
              headerColor: theme.color,
              font: theme?.font24
            }}
          />
        }
        action={flag}
      />
      <Divider />

      {children}

      {mainData && (
        <CardContent>
          <CustomLabel color={theme.color} font={theme?.font20}>
            Total
          </CustomLabel>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <CustomTypography font={theme?.font32 || '45px'} color={color}>
              {mainData}
            </CustomTypography>
          </div>
        </CardContent>
      )}

      {showMoreDetails && (
        <>
          <Divider />
          <CardContent>
            <Box display="flex" justifyContent="center">
              <table style={{ width: '90%' }}>
                {extraData.map(item => (
                  <tr
                    key={item.key}
                    // style={{
                    //   display: 'flex',
                    //   alignItems: 'center',
                    //   width: '100%',
                    //   justifyContent: 'space-evenly'
                    // }}
                  >
                    <td style={{ width: '100px' }}>
                      <CustomLabel
                        color={theme.color}
                        variant="subtitle1"
                        font={theme?.font20}
                      >
                        {item.key}
                      </CustomLabel>
                    </td>
                    <td style={{ width: '100px' }}>
                      <CustomTypography
                        font={theme?.font28 || '35px'}
                        color={theme.color}
                      >
                        {item.value}
                      </CustomTypography>
                    </td>
                    <td style={{ width: '40px' }}>
                      <CustomTypography font={theme?.font20}>
                        |
                      </CustomTypography>
                    </td>
                    <td style={{ width: '100px' }}>
                      <CustomTypography
                        color={theme.color}
                        variant="subtitle1"
                        font={theme?.font28 || '35px'}
                      >
                        {item.percent}%
                      </CustomTypography>
                    </td>
                    {/* <CustomLabel
                    color={theme.color}
                    variant="subtitle1"
                    font={theme?.font20}
                  >
                    {item.key}
                  </CustomLabel>
                  <CustomTypography
                    font={theme?.font28 || '35px'}
                    color={theme.color}
                    style={{ width: '200px' }}
                  >
                    {item.value}
                  </CustomTypography>
                  <CustomTypography font={theme?.font20}>|</CustomTypography>
                  <CustomTypography
                    color={theme.color}
                    variant="subtitle1"
                    font={theme?.font28 || '35px'}
                    style={{ minWidth: '100px', textAlign: 'center' }}
                  >
                    {item.percent}%
                  </CustomTypography> */}
                  </tr>
                ))}
              </table>
            </Box>
          </CardContent>
        </>
      )}
    </StyledCustomCard>
  );
};

export {
  CustomCard,
  StyledCustomCard,
  CustomCardHeader,
  CustomCardContent,
  CustomTypography,
  CustomLabel,
  CustomDivider,
  CustomButton,
  GoBackButton
};
