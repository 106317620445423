import { createStore } from 'redux';
import API from '../services/api';
import config from '../config';
import userService from '../services/user.service';

const secondsToHms = d => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  // const s = Math.floor((d % 3600) % 60);

  const hDisplay = `${h < 10 ? `0${h}` : h}h`;
  const mDisplay = `${m < 10 ? `0${m}` : m}m`;
  return hDisplay + mDisplay;
};

export const registerStatus = async status => {
  if (userService.getCompanyName() === config.cognito.ownerCompany) {
    await API.post('/auth/ms-ticket/v1/user-productivity-status', { status });
  }
};

const INACTIVE_TIME = 300;

const startCounter = (
  state = {
    count: 0,
    activeCount: 0,
    status: localStorage.getItem('status') || 'active',
    time: '00h00m'
  },
  action
) => {
  const count = state.count + 5;
  const activeCount = state.activeCount + 5;

  if (action.type === 'CHANGE_COUNTER') {
    if (localStorage.getItem('busy') === 'true') {
      state.status = 'busy';
    }

    if (state.status === 'active') {
      if (activeCount > INACTIVE_TIME) {
        registerStatus('inactive');
        localStorage.setItem('status', 'inactive');
        return {
          ...state,
          count: 0,
          activeCount: 0,
          status: 'inactive',
          time: secondsToHms(count)
        };
      }

      return { ...state, count, activeCount, time: secondsToHms(count) };
    }

    return { ...state, count, activeCount, time: secondsToHms(count) };
  }

  if (action.type === 'CHANGE_STATUS') {
    if (
      !localStorage.getItem('status') &&
      localStorage.getItem('accessToken')
    ) {
      registerStatus('active');
      localStorage.setItem('status', 'active');
    }
    if (localStorage.getItem('status') !== action.status) {
      localStorage.setItem('status', action.status);
      if (action.status !== state.status) {
        registerStatus(action.status);
      }
    }

    if (action.status === 'active') {
      localStorage.removeItem('busy');
      return {
        ...state,
        activeCount: 0,
        count: 0,
        status: 'active',
        time: secondsToHms(0)
      };
    }

    if (action.status === 'busy') {
      localStorage.setItem('busy', 'true');
      return {
        ...state,
        activeCount: 0,
        count: 0,
        status: 'busy',
        time: secondsToHms(0)
      };
    }

    if (action.status !== state.status) {
      return {
        ...state,
        activeCount: 0,
        count: 0,
        status: action.status,
        time: secondsToHms(0)
      };
    }
    return {
      ...state,
      status: action.status,
      activeCount: 0,
      time: secondsToHms(count)
    };
  }
};

export const store = createStore(startCounter);
