import React, { useState, useEffect } from 'react';

import {
  Button,
  Grid,
  TextField,
  Card,
  CardContent,
  FormControl,
  CircularProgress,
  Typography,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import SendIcon from '@material-ui/icons/Send';
import Attachfile from '@material-ui/icons/AttachFile';

import { Alert } from '@material-ui/lab';
import TicketMacroInput from '../../tickets/TicketContainer/TicketComponents/TicketMacroInput';
import API from '../../../services/api';
import userService from '../../../services/user.service';
import AcerModal from '../../../components/AcerModal';

import { searchB2bOnCurrentUrl } from '../../../utils/b2bHelper';
import { chatClosingReasons } from '../helpers/chatHelpers';
import {
  CustomField,
  MessageArea,
  MessageAreaButtons,
  MessageAreaButtonsTools
} from './styles/styles';
import {
  findCurrentCustomerReason,
  gotoBottom,
  submitAttachment
} from './services/functions';

const ChatText = ({ socket, clientInAttendance, setAttendantMessage }) => {
  const { t } = useTranslation();
  const reduxDispatcher = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const showHistoric = useSelector(state => state.showHistoricChatReducer);
  const disconnect = useSelector(state => state.disconnectChatReducer);
  const showClientTalk = useSelector(state => state.showClientTalkReducer);
  const customersReasons = useSelector(state => state.customerReasonReducer);

  const [newAttendantMessage, setNewAttendantMessage] = useState('');
  const [attendantFile, setAttendantFile] = useState('');
  const [openState, setOpenState] = useState(false);

  const [submitAttachmentFile, setSubmitAttachmentFile] = useState('');
  const [isSendingAttachment, setIsSendingAttachment] = useState(false);

  const [urlFile, setUrlFile] = useState('');
  const [urlAnexoRaw, setUrlAnexoRaw] = useState('');

  const [chatData, setChatData] = useState({});
  const [
    selectedContactingMainReason,
    setSelectedContactingMainReason
  ] = useState('');
  const [selectedFinishingReason, setSelectedFinishingReason] = useState('');
  const [finishingReasonList, setFinishingReasonList] = useState([]);

  const connectedState = useSelector(state => state.connectedStateReducer);

  const isEmpty = str => !str.trim().length;

  useEffect(() => {
    if (clientInAttendance) {
      const foundedCustomer = findCurrentCustomerReason(
        customersReasons,
        clientInAttendance
      );
      if (foundedCustomer) {
        const {
          contactingMainReason,
          contactingSubReason
        } = foundedCustomer.reason;
        setSelectedContactingMainReason(contactingMainReason);
        setSelectedFinishingReason(contactingSubReason);
      } else if (clientInAttendance.contactingReason) {
        setSelectedContactingMainReason(
          clientInAttendance.contactingReason.contactingMainReason
        );
        setSelectedFinishingReason(
          clientInAttendance.contactingReason.contactingSubReason
        );
      } else {
        setSelectedContactingMainReason('');
        setSelectedFinishingReason('');
      }
    }
  }, [clientInAttendance]);

  useEffect(() => {
    if (selectedContactingMainReason) {
      const { finishingReason } = chatClosingReasons.find(
        item => item.contactingMainReason === selectedContactingMainReason
      );
      setFinishingReasonList(finishingReason);
    }
  }, [selectedContactingMainReason]);

  const handleSubmitMessage = sendFile => {
    const messageToCurrentClientInAttendance = {
      date: new Date(),
      to: clientInAttendance.from,
      hashConnection: clientInAttendance.hashConnection,
      message: newAttendantMessage || urlFile.url_presigned,
      // eslint-disable-next-line
      name_attachment: sendFile && urlFile.name_attachment,
      from: clientInAttendance.to,
      login: userService.getUsername(),
      attachment: submitAttachmentFile,
      urlAnexoRaw
    };

    setNewAttendantMessage('');
    setAttendantFile('');

    socket.sendMessageTo(
      messageToCurrentClientInAttendance,
      clientInAttendance.hashConnection,
      sendFile
    );

    reduxDispatcher({
      type: 'SET_NOTIFICATION',
      notification: messageToCurrentClientInAttendance
    });
    setAttendantMessage(messageToCurrentClientInAttendance);
    gotoBottom();
  };

  const handleSubmitFile = e => {
    e.preventDefault();
    setIsSendingAttachment(true);
    const environmentS3Url = process.env.REACT_APP_SUBDOMAIN_ATTACHMENT;
    API.post(`/no-auth/ms-chat/send-attachment`, submitAttachmentFile)
      .then(response => {
        setUrlAnexoRaw(
          `${environmentS3Url}.s3.amazonaws.com/${response.data.key}`
        );
        setUrlFile(response.data);
      })
      .catch(err => {
        // eslint-disable-next-line
        console.log(err);
      })
      .finally(() => {
        setIsSendingAttachment(false);
        setOpenState(false);
        setAttendantFile('');
      });
  };

  const handleCloseChat = async () => {
    API.get(
      `/auth/ms-chat/chats/close/connection/${clientInAttendance.hashConnection}`
    )
      .then(async () => {
        const { connection, hashConnection } = clientInAttendance;
        await API.post(`/auth/ms-chat/chats/contacting-reason`, {
          connection,
          hashConnection,
          contactingMainReason: selectedContactingMainReason,
          contactingSubReason: selectedFinishingReason
        });

        setSelectedContactingMainReason('');
        setSelectedFinishingReason('');
        enqueueSnackbar(t('i18n.chatview.MESSAGE_CLOSE'), {
          variant: 'success'
        });
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.chatview.MESSAGE_CLOSE_ERROR'), {
          variant: 'error'
        });
      });
  };

  const handleOpenCloseModal = () => {
    if (openState) {
      setOpenState(false);
      setAttendantFile('');
    } else {
      setOpenState(true);
    }
  };

  const handleAppliedMacro = answer => {
    setNewAttendantMessage(answer?.INSERT_MESSAGE?.value.replace(/\\n/g, '\n'));
  };

  useEffect(() => {
    if (urlFile) {
      handleSubmitMessage(true);
    }
  }, [urlFile]);

  useEffect(() => {
    if (Object.keys(clientInAttendance).length > 0) {
      setChatData({ ...clientInAttendance, id: null });
    }
    setNewAttendantMessage('');
  }, [clientInAttendance]);

  useEffect(() => {
    if (socket && clientInAttendance?.hashConnection) {
      socket.sendMessageControl(
        { message: 'ViewedByAttendant' },
        clientInAttendance.hashConnection
      );
    }
  }, [socket, clientInAttendance]);

  const updateReason = async (field, value, mainReason = null) => {
    const { connection, hashConnection } = clientInAttendance;

    if (field === 'contactingMainReason') {
      await API.post(`/auth/ms-chat/chats/contacting-reason`, {
        connection,
        hashConnection,
        contactingMainReason: value,
        contactingSubReason: ''
      });
    }

    if (field === 'contactingSubReason') {
      await API.post(`/auth/ms-chat/chats/contacting-reason`, {
        connection,
        hashConnection,
        contactingMainReason: mainReason,
        contactingSubReason: value
      });
    }
  };

  return (
    <>
      {connectedState === 'false' && (
        <Alert severity="error" style={{ width: '100%' }}>
          Foi detectada uma queda na conexão da internet ou do chat. Verifique
          sua conexão e recarregue a página para continuar utilizando o chat.
        </Alert>
      )}
      {connectedState === 'true' && (
        <Grid container>
          <Grid item xs={12}>
            <MessageArea container spacing={3}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <CustomField
                    id="outlined-multiline-static"
                    label={t('i18n.chatview.MESSAGE')}
                    disabled={showHistoric || disconnect || !showClientTalk}
                    multiline
                    fullWidth
                    rows={3}
                    variant="outlined"
                    value={newAttendantMessage}
                    onChange={e => setNewAttendantMessage(e.target.value)}
                    onKeyUp={e => {
                      if (e?.keyCode === 13 && !e.shiftKey) {
                        if (!isEmpty(newAttendantMessage)) {
                          handleSubmitMessage(false);
                        } else {
                          setNewAttendantMessage('');
                        }
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            </MessageArea>
          </Grid>
          <Grid item xs={12}>
            <Card
              style={{ borderRadius: '0px 0px 5px 5px', overflow: 'inherit' }}
            >
              <CardContent>
                <MessageAreaButtonsTools container>
                  <div>
                    <Button
                      style={{
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        minWidth: '130px'
                      }}
                      size="small"
                      disabled={
                        showHistoric ||
                        disconnect ||
                        !showClientTalk ||
                        !selectedContactingMainReason ||
                        !selectedFinishingReason
                      }
                      color="secondary"
                      variant="contained"
                      onClick={() => handleCloseChat(clientInAttendance)}
                    >
                      {t('i18n.chatview.CLOSE')}
                    </Button>
                  </div>

                  <FormControl size="small" variant="outlined" fullWidth>
                    <InputLabel
                      style={{
                        backgroundColor: 'white',
                        padding: '0 10px',
                        color:
                          showHistoric || disconnect || !showClientTalk
                            ? '#999DA0'
                            : '#828282'
                      }}
                    >
                      {t('i18n.ticketcontainer.REASON')}
                    </InputLabel>
                    <Select
                      value={selectedContactingMainReason}
                      variant="outlined"
                      size="small"
                      disabled={showHistoric || disconnect || !showClientTalk}
                      onChange={htmlElemtEvent => {
                        setSelectedContactingMainReason(
                          htmlElemtEvent.target.value
                        );
                        setSelectedFinishingReason('');
                        reduxDispatcher({
                          type: 'UPDATE_CUSTOMER_REASON',
                          payload: {
                            customer: clientInAttendance.hashConnection,
                            reason: {
                              contactingMainReason: htmlElemtEvent.target.value,
                              contactingSubReason: ''
                            }
                          }
                        });
                        updateReason(
                          'contactingMainReason',
                          htmlElemtEvent.target.value
                        );
                      }}
                    >
                      {chatClosingReasons.map(item => {
                        return (
                          <MenuItem
                            key={Math.random()}
                            value={item.contactingMainReason}
                          >
                            {item.contactingMainReason}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl size="small" variant="outlined" fullWidth>
                    <InputLabel
                      style={{
                        backgroundColor: 'white',
                        padding: '0 10px',
                        color:
                          showHistoric || disconnect || !showClientTalk
                            ? '#999DA0'
                            : '#828282'
                      }}
                    >
                      {t('i18n.ticketcontainer.REASON_DETAIL')}
                    </InputLabel>
                    <Select
                      value={selectedFinishingReason}
                      variant="outlined"
                      size="small"
                      disabled={showHistoric || disconnect || !showClientTalk}
                      onChange={htmlElemtEvent => {
                        setSelectedFinishingReason(htmlElemtEvent.target.value);
                        reduxDispatcher({
                          type: 'UPDATE_CUSTOMER_REASON',
                          payload: {
                            customer: clientInAttendance.hashConnection,
                            reason: {
                              contactingMainReason: selectedContactingMainReason,
                              contactingSubReason: htmlElemtEvent.target.value
                            }
                          }
                        });
                        updateReason(
                          'contactingSubReason',
                          htmlElemtEvent.target.value,
                          selectedContactingMainReason
                        );
                      }}
                    >
                      {finishingReasonList.map(item => {
                        return (
                          <MenuItem key={Math.random()} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Button
                    size="small"
                    style={{
                      marginRight: '20px',
                      paddingTop: '8px',
                      paddingBottom: '8px'
                    }}
                    color="default"
                    variant="contained"
                    onClick={() => {
                      handleOpenCloseModal();
                    }}
                    disabled={showHistoric || disconnect || !showClientTalk}
                  >
                    <Attachfile />
                  </Button>
                  <TicketMacroInput
                    style={{ width: '100%' }}
                    disabled={showHistoric || disconnect || !showClientTalk}
                    chat={chatData}
                    dadosPedido={[]}
                    handleAppliedMacro={handleAppliedMacro}
                    typeMessage="normalMessage"
                    type="fast"
                    place={!searchB2bOnCurrentUrl() ? 'chat' : 'chatB2b'}
                    noLabel
                    small
                    placeholder={t('i18n.quickanswer.PICK_A_QUICK_ANSWER')}
                  />

                  <div>
                    <Button
                      style={{
                        paddingTop: '8px',
                        paddingBottom: '8px'
                      }}
                      disabled={
                        showHistoric ||
                        disconnect ||
                        newAttendantMessage === undefined ||
                        !showClientTalk ||
                        newAttendantMessage?.length === 0
                      }
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={() => handleSubmitMessage(false)}
                      startIcon={<SendIcon />}
                    >
                      {t('i18n.chatview.TOOLTIP_SEND')}
                    </Button>
                  </div>
                </MessageAreaButtonsTools>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      <AcerModal
        fullWidth
        maxWidth="md"
        open={openState}
        modalTitle="Enviar anexo"
        onClose={handleOpenCloseModal}
      >
        {isSendingAttachment && <CircularProgress />}

        <form onSubmit={e => handleSubmitFile(e)}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <TextField
                value={attendantFile}
                id="outlined-multiline-static"
                type="file"
                fullWidth
                size="small"
                required
                variant="outlined"
                onChange={e => {
                  setAttendantFile(e.target.value);
                  if (e.target.files[0]) {
                    submitAttachment(
                      e.target.files[0],
                      enqueueSnackbar,
                      t,
                      setIsSendingAttachment,
                      setSubmitAttachmentFile
                    );
                  }
                }}
                inputProps={{
                  accept: 'application/pdf, image/png, image/gif, image/jpeg',
                  required: true
                }}
              />
              <Typography variant="subtitle2" style={{ fontSize: '10px' }}>
                Somente PDF, PNG e JPEG
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <MessageAreaButtons>
                <Button
                  color="default"
                  variant="contained"
                  startIcon={<SendIcon />}
                  type="submit"
                  // onClick={() => handleSubmitFile()}
                  disabled={!attendantFile || isSendingAttachment}
                >
                  Enviar
                </Button>
              </MessageAreaButtons>
            </Grid>
          </Grid>
        </form>
      </AcerModal>
    </>
  );
};

export { ChatText };
