/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  CardContent,
  Typography,
  Divider,
  Card
} from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// import LaunchIcon from '@material-ui/icons/Launch';
// import AttachmentIcon from '@material-ui/icons/Attachment';

import Campaign from './components/Campaign.component';
import Products from './components/Products.component';
import API from '../../../services/api';

import { searchB2bOnCurrentUrl } from '../../../utils/b2bHelper';

// const handleGetCurrentClient = async (clientInAttendance, set) => {
//   if (clientInAttendance.hashConnection) {
//     try {
//       const clientHistory = await API.get(
//         `no-auth/ms-chat/existing-chat/${clientInAttendance.hashConnection}`
//       );
//       set(clientHistory.data);
//     } catch (error) {
//       // eslint-disable-next-line no-console
//       console.log(error);
//     }
//   }
// };

const NewChatMoreInformations = ({
  clientInAttendance,
  lastMessage,
  productTagOptions,
  campaingTagOptions,
  clientData
  // currentProductSearched
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return (
    <>
      <Card>
        <Grid container>
          <Grid item xs={12}>
            <Divider />
            <CardContent>
              <Grid container>
                {!searchB2bOnCurrentUrl() && (
                  <Grid item xs={6}>
                    <Typography>Campanhas associadas ao atendimento: </Typography>
                    <Campaign clientData={clientData} tagOptions={campaingTagOptions} type="chats" blockInput={false} />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Typography>Produtos associados ao atendimento: </Typography>
                  <Products
                    clientData={clientData}
                    type="chats"
                    blockInput={false}
                    lastMessage={lastMessage}
                    // currentProductSearched={currentProductSearched}
                    tagOptions={productTagOptions}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export { NewChatMoreInformations };
