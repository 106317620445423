import { Box, Menu, MenuItem, Tooltip } from '@material-ui/core';
import PostAddIcon from '@material-ui/icons/PostAdd';
import {
  convertToRaw,
  EditorState,
  Modifier,
  convertFromHTML,
  ContentState
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import MUIRichTextEditor from 'mui-rte';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const MailComponent = ({ currentMailBodyState, updateMailBodyContent }) => {
  const { t } = useTranslation();
  const [mailBodyContentHolder, setMailBodyContentHolder] = useState(null);
  const [mailBodyHintMenuOpenState, setMailBodyHintMenuOpenState] = useState(
    null
  );
  const [mailBodyHintMenuAnchor, setMailBodyHintMenuAnchor] = useState(null);

  const [editorEditorState, setEditorEditorState] = useState(
    EditorState.createEmpty()
  );

  const rawHtmlToEditorstate = html => {
    const customEntityConverter = entityMap => {
      // eslint-disable-next-line no-underscore-dangle
      const getEntities = entityMap.__getAll();
      const newEntityMap = getEntities.map(entity => {
        if (entity.type === 'IMAGE') {
          entity.data.url = entity.data.src;
        }
        return entity;
      });
      return newEntityMap;
    };

    const blocksFromHTML = convertFromHTML(html);
    const content = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      customEntityConverter(blocksFromHTML.entityMap)
    );
    return EditorState.createWithContent(content);
  };

  useEffect(() => {
    if (currentMailBodyState) {
      setEditorEditorState(rawHtmlToEditorstate(currentMailBodyState));
    } else {
      setEditorEditorState(EditorState.createEmpty());
    }
  }, [currentMailBodyState]);

  const [tempEditorState, setTempEditorState] = useState('');

  const handleChange = event => {
    const options = {
      entityStyleFn: entity => {
        const entityType = entity.get('type').toLowerCase();
        if (entityType === 'image') {
          const data = entity.getData();
          return {
            element: 'img',
            attributes: {
              src: data.url,
              width: data.width,
              height: data.height
            }
          };
        }
      }
    };
    const content = stateToHTML(event.getCurrentContent(), options);
    setMailBodyContentHolder(content);
  };
  const includeTextToEditor = (text, editorState) => {
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    );

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-characters'
    );
    return EditorState.forceSelection(
      newEditorState,
      newContent.getSelectionAfter()
    );
  };

  const hintItems = [
    'cliente.email',
    'cliente.nome',
    'ticket.id',
    'ticket.dataAbertura',
    'ticket.dataUltimaResposta'
  ];

  return (
    <Box height="100px" style={{ height: '200px', overflowY: 'scroll' }}>
      <Menu
        anchorEl={mailBodyHintMenuAnchor}
        keepMounted
        open={Boolean(mailBodyHintMenuOpenState)}
        onClose={() => {
          setMailBodyHintMenuOpenState(null);
        }}
      >
        {hintItems.map(item => {
          return (
            <MenuItem
              key={item}
              onClick={() => {
                setEditorEditorState(
                  includeTextToEditor(`{{${item}}}`, tempEditorState)
                );
              }}
            >
              {item}
            </MenuItem>
          );
        })}
      </Menu>
      <MUIRichTextEditor
        defaultValue={JSON.stringify(
          convertToRaw(editorEditorState.getCurrentContent())
        )}
        label="Insira algum conteúdo aqui."
        inlineToolbar
        controls={[
          'title',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'undo',
          'redo',
          'link',
          'media',
          'clear',
          'keyIncluder'
        ]}
        customControls={[
          {
            name: 'keyIncluder',
            icon: (
              <Tooltip title={t('i18n.newAutomation.KEY_HINTS')}>
                <PostAddIcon />
              </Tooltip>
            ),
            type: 'callback',
            onClick: (editorState, name, anchor) => {
              setTempEditorState(editorState);
              setMailBodyHintMenuAnchor(anchor);
              setMailBodyHintMenuOpenState(true);
            }
          }
        ]}
        onChange={handleChange}
        onBlur={() => {
          updateMailBodyContent(mailBodyContentHolder);
        }}
      />
    </Box>
  );
};

export default MailComponent;
