import React from 'react';
import OrdersModal from '../../../../../../layouts/DashboardLayout/Tools/OrdersModal';

export const OrdersModalIframe = ({
  ticketData,
  openOrderModal,
  setOpenOrderModal,
  ticketOrders
}) => {
  return (
    <OrdersModal
      key={ticketData?.id}
      open={openOrderModal}
      onClose={() => setOpenOrderModal(false)}
      customerCpf={ticketData?.customerCPF}
      orderId={ticketOrders?.idPedMarket || ''}
      customerName={
        ticketData?.customerName || ticketOrders?.cliente?.nome || ''
      }
    />
  );
};
