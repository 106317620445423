import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RadioComponent from '../../../../components/Radio.component';

const B2WLegalProcess = ({ filterForm, setFilterForm }) => {
  const { t } = useTranslation();
  return (
    <Grid container display="flex" alignItems="center">
      <RadioComponent
        {...{
          name: 'marketPlaceJuridicalIdList',
          form: filterForm,
          setForm: setFilterForm,
          label: t('i18n.legalprotocol.LEGAL_PROCESS')
        }}
        options={[
          { name: t('i18n.simplewords.NO'), value: 'false' },
          { name: t('i18n.simplewords.YES'), value: 'true' }
        ]}
      />
    </Grid>
  );
};

export default B2WLegalProcess;
