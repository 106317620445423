import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import MAGALU_LOGO from '../../../assets/images/magalu_logo.png';
import ACER_LOGO from '../../../assets/images/acer_logo.png';
import CNOVA_LOGO from '../../../assets/images/Cnova_logo.png';
import MERCADO_LIVRE_LOGO from '../../../assets/images/logo_mercado_livre.png';
import NETSHOES_LOGO from '../../../assets/images/logo_netshoes.png';
import B2W_LOGO from '../../../assets/images/b2w_logo.png';
import CARREFOUR_LOGO from '../../../assets/images/carrefour_logo.png';
import KABUM_LOGO from '../../../assets/images/kabum_logo.png';
import COLOMBO_LOGO from '../../../assets/images/colombo_logo.png';
import VIVO_LOGO from '../../../assets/images/vivo_logo.png';
import marketPlacesEnum from '../../../enums/marketPlaces.enum';
import { COLORS } from '../../../styles/settings/colors.config';

const StyledTicketHeader = styled.header`
  border-bottom: 2px solid ${COLORS.cardMessageGray};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 0 0 24px;

  .header-details {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;

    img {
      position: relative;
      top: 2px;
      width: 90px;
    }

    .store-ml {
      top: 5px;
      width: 55px;
    }

    .store-acer {
      width: 110px;
    }

    .store-b2w {
      width: 55px;
    }

    &--marketplace {
      border-right: 3px solid #999999;
      color: ${COLORS.grayText};
      font-size: 28px;
      margin: 5px 32px 0 24px;
      padding-right: 32px;
      text-transform: capitalize;
    }

    &--marketplace-no-border {
      color: ${COLORS.grayText};
      font-size: 28px;
      margin: 5px 0 0 24px;
      text-transform: capitalize;
    }

    &--marketplaceNoTextTransform {
      border-right: 3px solid #999999;
      color: ${COLORS.grayText};
      font-size: 28px;
      margin: 5px 32px 0 24px;
      padding-right: 32px;
    }

    &--ticket {
      color: ${COLORS.greenText};
      border-right: 3px solid #999999;
      font-size: 27px;
      font-weight: bold;
      padding-right: 32px;
      margin: 5px 32px 0 24px;
    }
  }

  .header-details--action {
    align-self: flex-end;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    top: 16px;

    button:not(:last-child) {
      &::after {
        background: #999999;
        content: '';
        height: 24px;
        margin-left: 24px;
        width: 1px;
      }
    }
  }

  .header-btn--icon {
    margin-right: 16px;
  }
`;

const getLogoImg = marketPlace => {
  switch (marketPlace) {
    case marketPlacesEnum.CNOVA:
      return (
        <img
          src={CNOVA_LOGO}
          alt="Logo da loja CNOVA"
          className="store-cnova"
        />
      );
    case marketPlacesEnum.B2W:
      return <img src={B2W_LOGO} alt="Logo da B2W" className="store-b2w" />;
    case marketPlacesEnum.NETSHOES:
      return (
        <img
          src={NETSHOES_LOGO}
          alt="Logo da NETSHOES"
          className="store-netshoes"
          // style={{ width: '54px' }}
        />
      );
    case marketPlacesEnum.MERCADO_LIVRE:
      return (
        <img
          src={MERCADO_LIVRE_LOGO}
          alt="Logo da loja Mercado Livre"
          className="store-ml"
        />
      );
    case marketPlacesEnum.MAGALU:
      return (
        <img
          src={MAGALU_LOGO}
          alt="Logo da loja Magazine Luiza"
          className="store-magalu"
        />
      );
    case marketPlacesEnum.CUSTOMER_GUIDE:
      return (
        <img
          src={ACER_LOGO}
          alt="Logo da loja Acer Store"
          className="store-acer"
        />
      );
    case marketPlacesEnum.CARREFOUR:
      return (
        <img
          src={CARREFOUR_LOGO}
          alt="Logo da loja Carrefour"
          className="store-acer"
        />
      );
    case marketPlacesEnum.KABUM:
      return (
        <img src={KABUM_LOGO} alt="Logo da loja Kabum" className="store-acer" />
      );
    case marketPlacesEnum.COLOMBO:
      return (
        <img
          src={COLOMBO_LOGO}
          alt="Logo da loja Colombo"
          className="store-acer"
        />
      );
    case marketPlacesEnum.VIVO:
      return (
        <img src={VIVO_LOGO} alt="Logo da loja Vivo" className="store-acer" />
      );
    default:
      break;
  }
};

const LegalProcessTitle = ({
  marketPlace,
  ticketType,
  ticketData,
  protocolData
}) => {
  const { t } = useTranslation();
  const processNumber = protocolData?.protocolsVariablesList?.find(
    it =>
      it.variableId === 'numProcesso' ||
      it.variableId === '963_prju_processo_num' ||
      it.variableId === '809_prju_processo_num'
  );

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <StyledTicketHeader>
          <div className="header-details">
            {getLogoImg(marketPlace)}

            <Typography
              component="h2"
              variant="h2"
              className={
                ticketData?.marketPlace === marketPlacesEnum.MAGALU
                  ? 'header-details--marketplaceNoTextTransform'
                  : 'header-details--marketplace'
              }
            >
              {t(`i18n.marketplaces.${marketPlace}`).toLocaleLowerCase()}
            </Typography>
            <Typography
              variant="h3"
              component="span"
              className="header-details--ticket"
            >
              {t(`i18n.newAutomation.${ticketType}`)}
            </Typography>
            <Typography
              variant="h3"
              component="span"
              className="header-details--ticket"
            >
              {t('i18n.legalprotocol.LEGAL_PROCESSES_TITLE')}
            </Typography>
            <Typography
              variant="h3"
              component="span"
              className="header-details--marketplace-no-border"
            >
              {t('i18n.legalprotocol.PROCESS')} {processNumber?.domains[0].desc}
            </Typography>
          </div>
        </StyledTicketHeader>
      </Grid>
    </Grid>
  );
};

export default LegalProcessTitle;
