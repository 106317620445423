import marketPlacesEnum from '../../../../enums/marketPlaces.enum';
import API from '../../../../services/api';

const FileDownload = (marketPlace, mlClaimId, idIncidenteCNOVA) => {
  const getFileFromUrl = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
  };

  const getFileBase64FromUrl = (hash, filename, fileType) => {
    const link = document.createElement('a');
    switch (fileType) {
      case 'pdf':
        link.href = `data:application/pdf;base64,${hash}`;
        break;
      default:
        link.href = `data:application/pdf;base64,${hash}`;
        break;
    }
    link.download = filename;
    link.click();
  };

  const openLinkNewTab = links => {
    links.forEach(link => {
      const anchorElement = document.createElement('a');
      anchorElement.href = link;
      anchorElement.target = '_blank';
      anchorElement.click();
    });
  };

  const getFileBlob = (
    purpose,
    filename,
    key,
    originalFilename,
    attachmentId
  ) => {
    let endpoint = '';
    switch (purpose) {
      case 'MLClaim':
        endpoint = `/auth/ms-ticket/v1/tickets/mercadolivre/get-claim-attachs/${mlClaimId}/${filename}`;
        break;
      case 'mirakl':
        endpoint = `/auth/ms-ticket/v1/tickets/mirakl/download/${marketPlace}/${key}`;
        break;
      case 'VIVO':
        endpoint = `/auth/ms-ticket/v1/tickets/neoassist/download/${marketPlace}/${key}?fileOrigin=${originalFilename}`;
        break;
      case 'cnova':
        endpoint = `/auth/ms-ticket/v1/tickets/cnova/incident/${idIncidenteCNOVA}/attachment/${attachmentId}`;
        break;
      default:
        return '';
    }

    API.get(endpoint, {
      responseType: 'blob'
    }).then(response => {
      const downloadUrl = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = downloadUrl;
      if (purpose === 'VIVO' || purpose === 'mirakl') {
        link.setAttribute('download', `${filename}`);
      } else {
        link.setAttribute('download', `${originalFilename}`);
      }
      document.body.appendChild(link);
      link.click();
    });
  };

  const getFile = (filename, key, url, originalFilename, attachmentId) => {
    if (url) {
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      return link.click();
    }
    if (mlClaimId) {
      getFileBlob('MLClaim', filename, key, originalFilename, attachmentId);
      return;
    }
    if (
      [marketPlacesEnum.CARREFOUR, marketPlacesEnum.KABUM].includes(marketPlace)
    ) {
      getFileBlob('mirakl', filename, key, originalFilename, attachmentId);
      return;
    }
    if (marketPlacesEnum.VIVO === marketPlace) {
      getFileBlob('VIVO', filename, key, originalFilename, attachmentId);
      return;
    }
    if (idIncidenteCNOVA) {
      getFileBlob('cnova', filename, key, originalFilename, attachmentId);
      return;
    }
    API.get(`/auth/ms-ticket/v1/files/${key}`).then(response => {
      const link = document.createElement('a');
      link.href = `data:image/png;base64,${response.data.content}`;
      link.download = filename;
      link.click();
    });
  };

  return {
    getFileFromUrl,
    getFileBase64FromUrl,
    openLinkNewTab,
    getFile
  };
};

export default FileDownload;
