import { Tabs } from '@material-ui/core';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabTitleWithIndicator from './components/TabTitleWithIndicator.component';
import marketPlacesEnum from '../../../enums/marketPlaces.enum';
import { reclameAquiPrivateMessagesTab } from '../../../services/reclameAqui';
import {
  CardNoShadowNoBackground,
  StyledTab
} from '../TicketContainer/TicketComponents/SectionTabs';
import B2wClassificationModal from './b2w/b2wClassificationModal';

import TabGenericMessages from './tabs/GenericMessages';
import TabInternalMessages from './tabs/InternalMessages';
import TabMagaluMessages from './tabs/MagaluMessages';
import TabMlClaimsMessages from './tabs/MlClaimsMessages';
import TabB2wHelpMessages from './tabs/B2wHelpMessages';
import TabMediationB2w from './tabs/MediationB2w';

import messagesHooks from './messagesHook';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
      className="customTabs customTabsMessage customTabsAjust"
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const Messages = ({
  chat,
  updateChatState,
  messageFromParent,
  dadosPedido,
  getTicket,
  socketMessageData,
  setTicketStatus,
  setAttendant,
  ticketStatus,
  attendant,
  reason,
  isReasonFullfield
}) => {
  const { t } = useTranslation();
  const tabStyles = {
    messagesTab: {
      position: 'relative',
      top: '1px'
    }
  };

  const [tabValue, setTabValue] = useState('tabGenericMessages');
  const [b2wModalOpen, setB2wModalOpen] = useState(false);
  const [marketplaceModalOpen, setMarketplaceModalOpen] = useState(false);

  const {
    resposta,
    mlClaimResposta,
    mlClaimRespostaLoading,
    b2wMediation,
    b2wHelp,
    b2wHelpAnswer,
    b2wChatClassification,
    internalMessagesResposta,
    privateMessagesMagalu,
    privateMessageMagaluLoading,
    internalMessageLoading,
    normalMessageRespostaLoading,
    showBotMessage,
    setRecipientClient,
    setRecipientOperator,
    setAssignResponsible,
    setSendEmail,
    firstMediatorResponseDate,
    mlTicketblocked,
    isMlClaimReturnType,
    getRootProps,
    getInputProps,
    acceptedFiles,
    files,
    isMlClaimTicket,
    handleAcceptAnswer,
    sendMlClaimResponse,
    sendInternalMessage,
    sendPrivateMessageMagalu,
    compare,
    genericMessagesTabTitle,
    handleAppliedMacro,
    handleEditBotMessage,
    handleDeleteBotMessage,
    findFirstModeratingMessage,
    attachPDFTerm,
    setFilesToUpload,
    setResposta,
    setMlClaimResposta,
    setMessages,
    messages,
    upDateChatByTicketData,
    onDrop,
    mlClaims,
    chatState,
    internalMessages,
    botMessage,
    filesToUpload,
    recipientClient,
    recipientOperator,
    sendResponse,
    setInternalMessagesResposta,
    privateMessageAnswerMagalu,
    setPrivateMessageAnswerMagalu,
    setB2wHelpAnswer,
    b2wSelectedClassification,
    setB2wSelectedClassification
  } = messagesHooks(chat, updateChatState, tabValue);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setFilesToUpload([]);
  };

  const setMessageInSelectedTabPanel = message => {
    if (!message) {
      return;
    }
    if (tabValue === 'tabGenericMessages') {
      setResposta(message);
    }
    if (tabValue === 'tabMlClaimsMessages') {
      setMlClaimResposta(message);
    }
    if (tabValue === 'reclameAquiPrivateMessages') {
      setMlClaimResposta(message);
    }
  };

  useEffect(() => {
    setMessages(
      socketMessageData?.marketplaceGenericMessages ||
        socketMessageData?.marketplaceBundle?.genericMessages ||
        messages
    );
    setTicketStatus(socketMessageData?.ticketStatus || ticketStatus);
    setAttendant(socketMessageData?.serviceAttendantLogin || attendant);
  }, [socketMessageData]);

  useEffect(() => {
    setMessageInSelectedTabPanel(messageFromParent);
  }, [messageFromParent]);

  useEffect(() => {
    upDateChatByTicketData(chat);
  }, [chat]);

  useEffect(() => {
    onDrop(acceptedFiles);
  }, [acceptedFiles]);

  useEffect(() => {
    if (tabValue === 'B2wHelpMessages') {
      setB2wModalOpen(b2wChatClassification !== null);
    }
  }, [tabValue]);

  useEffect(() => {
    if (mlClaims.length > 0) {
      findFirstModeratingMessage();
    }
  }, [mlClaims]);

  return (
    <CardNoShadowNoBackground>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        style={tabStyles.messagesTab}
      >
        <StyledTab
          value="tabGenericMessages"
          label={genericMessagesTabTitle(messages)}
        />
        {reclameAquiPrivateMessagesTab(chatState?.marketPlace, t)}
        <StyledTab
          value="tabInternalMessages"
          label={
            <TabTitleWithIndicator
              title={t('i18n.ticketslistview.TAB_INTERNAL_MESSAGES')}
              messagesCount={internalMessages}
            />
          }
        />
        {chat.marketPlace === marketPlacesEnum.MAGALU && (
          <StyledTab
            value="magaluInternalTab"
            label={
              <TabTitleWithIndicator
                title="Magalu"
                messagesCount={privateMessagesMagalu}
              />
            }
          />
        )}
        {isMlClaimTicket() && (
          <StyledTab
            value="tabMlClaimsMessages"
            label={
              <TabTitleWithIndicator
                title={t('i18n.ticketslistview.TAB_ML_CLAIMS')}
                messagesCount={mlClaims}
                isMlClaim
              />
            }
          />
        )}
        {chatState?.marketPlace === marketPlacesEnum.B2W && (
          <StyledTab
            value="B2wHelpMessages"
            label={
              <TabTitleWithIndicator
                title={t('i18n.ticketslistview.TAB_B2W_HELP')}
                messagesCount={b2wHelp}
              />
            }
          />
        )}
        {chatState?.marketPlace === marketPlacesEnum.B2W &&
          chatState?.marketplaceBundle?.dataMessagesMediadas !== null && (
            <StyledTab
              value="mediationB2w"
              label={
                <TabTitleWithIndicator
                  title={t('i18n.ticketslistview.TAB_B2W_MEDIATION')}
                  messagesCount={b2wMediation}
                />
              }
            />
          )}

        {/* <StyledTab value="whatsapp" label="Whatsapp" /> */}
      </Tabs>
      {/* Aba Mensagens/Mensagens 100% mediadas */}
      <TabPanel value={tabValue} index="tabGenericMessages">
        <TabGenericMessages
          {...{
            messages,
            chat,
            chatState,
            showBotMessage,
            botMessage,
            normalMessageRespostaLoading,
            dadosPedido,
            getTicket,
            handleAcceptAnswer,
            handleEditBotMessage,
            handleDeleteBotMessage,
            handleAppliedMacro,
            reason,
            attachPDFTerm,
            filesToUpload,
            resposta,
            setResposta,
            files,
            recipientClient,
            setRecipientClient,
            recipientOperator,
            setRecipientOperator,
            getRootProps,
            getInputProps,
            mlTicketblocked,
            setAssignResponsible,
            setSendEmail,
            isReasonFullfield,
            sendResponse
          }}
        />
      </TabPanel>
      {/* Aba Anotações Internas */}
      <TabPanel value={tabValue} index="tabInternalMessages">
        <TabInternalMessages
          {...{
            chat,
            dadosPedido,
            getTicket,
            handleAppliedMacro,
            internalMessages,
            internalMessageLoading,
            internalMessagesResposta,
            getInputProps,
            getRootProps,
            files,
            setInternalMessagesResposta,
            sendInternalMessage
          }}
        />
      </TabPanel>
      {/* Aba Magalu (mediações) */}
      {chatState?.marketPlace === marketPlacesEnum.MAGALU && (
        <TabPanel value={tabValue} index="magaluInternalTab">
          <TabMagaluMessages
            {...{
              privateMessagesMagalu,
              privateMessageMagaluLoading,
              privateMessageAnswerMagalu,
              setPrivateMessageAnswerMagalu,
              sendPrivateMessageMagalu,
              reason,
              isReasonFullfield
            }}
          />
        </TabPanel>
      )}
      {/* Aba Reclamações/Mediações MERCADO LIVRE   */}
      <TabPanel value={tabValue} index="tabMlClaimsMessages">
        <TabMlClaimsMessages
          {...{
            mlClaims,
            chatState,
            firstMediatorResponseDate,
            mlClaimRespostaLoading,
            mlClaimResposta,
            chat,
            dadosPedido,
            getTicket,
            isMlClaimReturnType,
            sendMlClaimResponse,
            setMlClaimResposta,
            mlTicketblocked,
            handleAppliedMacro,
            getInputProps,
            getRootProps,
            files
          }}
        />
      </TabPanel>
      {/* Aba Ajuda Americanas   */}
      <TabPanel value={tabValue} index="B2wHelpMessages">
        <TabB2wHelpMessages
          {...{
            b2wHelp,
            normalMessageRespostaLoading,
            marketplaceModalOpen,
            setMarketplaceModalOpen,
            filesToUpload,
            b2wHelpAnswer,
            getRootProps,
            getInputProps,
            files,
            setB2wHelpAnswer,
            b2wChatClassification,
            setB2wModalOpen,
            b2wSelectedClassification,
            sendResponse
          }}
        />
      </TabPanel>
      {/* Aba Mediação Americanas  */}
      <TabPanel value={tabValue} index="mediationB2w">
        <TabMediationB2w b2wMediation={b2wMediation} />
      </TabPanel>
      <B2wClassificationModal
        openState={
          b2wChatClassification?.classification?.length > 0 && b2wModalOpen
        }
        closeParent={() => {
          setB2wModalOpen(false);
        }}
        classification={b2wChatClassification?.classification?.sort(compare)}
        submitSelection={(id, description) => {
          setB2wModalOpen(false);
          if (description !== 'SUPPORT') {
            setB2wSelectedClassification({
              subject: {
                description,
                id: Number(id),
                menuId: b2wChatClassification.menuId
              }
            });
          } else {
            setMarketplaceModalOpen(true);
          }
        }}
      />
    </CardNoShadowNoBackground>
  );
};

export default Messages;
