/* eslint-disable react/no-array-index-key */
import {
  Card,
  CardContent,
  Chip,
  Divider,
  Paper,
  TextField,
  Typography,
  Button,
  Tooltip,
  Box
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { InsertDriveFile } from '@material-ui/icons';
import AcerModal from '../../../../../components/AcerModal';
import API from '../../../../../services/api';
// import ConfirmationModal from '../../../../../utils/ConfirmationModal';

const ModalDefault = ({
  ticketData,
  openState,
  closeParent,
  hasAction,
  action
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [open, setOpen] = useState(openState);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [comment, setComment] = useState('');
  const [endpoint, setEndpoint] = useState('');
  const [msgError, setMsgError] = useState('');
  const [title, setTitle] = useState('');
  const [filesList, setFilesList] = useState([]);
  const [formData, setFormData] = useState({ date: null });

  const onDrop = acceptedFiles => {
    // const newFilesState = filesToUpload;
    const newFilesState = [];
    // if (filesToUpload.length + acceptedFiles.length > 4) {
    //   enqueueSnackbar(
    //     t('i18n.newticket.IMPOSSIBLE_TO_INCLUDE_MORE_THAN_4_FILES'),
    //     {
    //       variant: 'error'
    //     }
    //   );
    //   return;
    // }
    acceptedFiles.forEach(element => {
      if (element.size > 2000000) {
        enqueueSnackbar(
          t('i18n.newticket.THE_IMAGE') +
            element.path +
            t('i18n.newticket.CANNOT_BE_SENT'),
          {
            variant: 'error'
          }
        );
        return;
      }
      const reader = new FileReader();
      const fileByteArray = [];
      const base64file = [];
      // reader.readAsArrayBuffer(element);
      reader.readAsDataURL(element);
      reader.onloadend = evt => {
        if (evt.target.readyState === FileReader.DONE) {
          base64file.push(evt.currentTarget.result);
          const arrayBuffer = evt.target.result;
          const array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < array.length; i++) {
            fileByteArray.push(array[i]);
          }
        }

        newFilesState.push({
          path: element.path,
          name: element.name,
          binary: fileByteArray,
          data: base64file[0]
        });

        setFilesList(newFilesState);
      };
    });
  };

  const handleClose = () => {
    closeParent();
    setOpen(false);
    setFilesToUpload([]);
    setComment('');
    setFormData({ date: null });
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept:
      'image/jpeg,image/png,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  });

  const files = filesToUpload.map((file, index) => (
    <>
      <Chip
        key={index}
        style={{ margin: '2px' }}
        label={file.path || file.name}
        onDelete={() => {
          const newFilesState = [...filesToUpload];
          newFilesState.splice(index, 1);
          setFilesToUpload(newFilesState);
        }}
      />
    </>
  ));

  const submitAssignment = async () => {
    const postData = {
      idIncidentCNOVA: ticketData?.marketplaceBundle?.idIncidenteCNOVA,
      ticketId: ticketData?.id,
      text: comment,
      ...formData,
      solutionId: action,
      attachments:
        filesToUpload.length === 0
          ? null
          : filesToUpload.map(file => {
              return {
                fileName: file.path || file.name,
                data: file.data.split(',')[1]
              };
            })
    };
    try {
      await API.post(endpoint, postData);
    } catch (error) {
      enqueueSnackbar(t(msgError), {
        variant: 'error'
      });
    } finally {
      handleClose();
    }
  };

  const actionVariables = () => {
    // eslint-disable-next-line default-case
    switch (action) {
      case 1691: {
        setEndpoint('/auth/ms-ticket/v1/tickets/cnova/cancel-delivery');
        setMsgError('i18n.ticket.CNOVA_CANCELDELIVERY_FAIL');
        setTitle('i18n.cnova.phrases.canceldelivery');
        break;
      }
      case 2684: {
        setEndpoint('/auth/ms-ticket/v1/tickets/cnova/end-deal');
        setMsgError('i18n.ticket.CNOVA_END_DEAL_FAIL');
        setTitle('i18n.cnova.phrases.enddeal');
        break;
      }
      case 2710: {
        setEndpoint('/auth/ms-ticket/v1/tickets/cnova/reject-cancellation');
        setMsgError('i18n.ticket.CNOVA_REJECT_CANCELLATION_FAIL');
        setTitle('i18n.cnova.phrases.cancellingreject');
        break;
      }
      case 2711: {
        setEndpoint('/auth/ms-ticket/v1/tickets/cnova/assign-to-viavarejo');
        setMsgError('i18n.ticket.CNOVA_ASSIGN_VIA_VAREJO_FAIL');
        setTitle('i18n.cnova.phrases.viavarejoassignment');
        break;
      }
      case 3775: {
        setEndpoint('/auth/ms-ticket/v1/tickets/cnova/respond-customer');
        setMsgError('i18n.ticket.CNOVA_RESPOND_TO_CUSTOMER_FAIL');
        setTitle('i18n.cnova.phrases.answerclient');
        break;
      }
      case 3849: {
        setEndpoint('/auth/ms-ticket/v1/tickets/cnova/order-picking-scheduled');
        setMsgError('i18n.ticket.CNOVA_ORDER_PICKING_SCHEDULE_FAIL');
        setTitle('i18n.cnova.phrases.pickingscheduled');
        break;
      }
      case 3850: {
        setEndpoint('/auth/ms-ticket/v1/tickets/cnova/delivery-released');
        setMsgError('i18n.ticket.CNOVA_DELIVERY_RELEASED_FAIL');
        setTitle('i18n.cnova.phrases.releasedelivery');
        break;
      }
      case 9998: {
        setEndpoint('/auth/ms-ticket/v1/tickets/cnova/unfeasible-delivery');
        setMsgError('i18n.ticket.CNOVA_UNFEASIBLE_FAIL');
        setTitle('i18n.cnova.phrases.unfeasible');
        break;
      }
      case 9999: {
        setEndpoint('/auth/ms-ticket/v1/tickets/cnova/viable-delivery');
        setMsgError('i18n.ticket.CNOVA_REDELIVERY_FEASIBLE_FAIL');
        setTitle('i18n.cnova.phrases.redeliveryfeasible');
        break;
      }
    }
  };

  const attachPDFTerm = () => {
    const findTerm = filesToUpload.filter(
      file =>
        file.path === 'termoDevolucaoMercadoriaV2.pdf' ||
        file.name === 'termoDevolucaoMercadoriaV2.pdf'
    );

    if (findTerm?.length > 0) {
      enqueueSnackbar(t('i18n.simplephrases.TERM_ALREADY_INSERTED'), {
        variant: 'warning'
      });
      return false;
    }

    fetch('/static/termoDevolucaoMercadoriaV2.pdf')
      .then(res => res.blob()) // Gets the response and returns it as a blob
      .then(blob => {
        const file = new File([blob], 'termoDevolucaoMercadoriaV2.pdf', {
          type: 'application/pdf'
        });
        onDrop([file]);
        enqueueSnackbar(t('i18n.simplephrases.TERM_SUCCESS'), {
          variant: 'success'
        });
      });
  };

  useEffect(() => {
    setOpen(openState);
    actionVariables();
  }, [openState]);

  useEffect(() => {
    onDrop(acceptedFiles);
  }, [acceptedFiles]);

  useEffect(() => {
    setFilesToUpload([...filesToUpload, ...filesList]);
  }, [filesList]);

  return (
    <>
      {/* <ConfirmationModal
        message={confirmationModalMessage}
        submitConfirmation={() => {
          confirmationModalAction();
          setConfirmationModalOpen(false);
        }}
        openState={confirmationModalOpen}
        closeParent={() => {
          setConfirmationModalOpen(false);
        }}
      /> */}
      <AcerModal
        maxWidth="md"
        fullWidth
        open={open}
        hasAction={hasAction}
        onClose={handleClose}
        modalTitle={t(title)}
        confirmActionLabel={t('i18n.simplewords.FINISH')}
        confirmActionFunc={() => {
          submitAssignment();
        }}
        cancelActionLabel={t('i18n.usercontainer.CANCEL')}
      >
        <Card>
          <CardContent>
            {action === 3849 && (
              <Box mb={2}>
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  type="date"
                  variant="outlined"
                  label={t('i18n.cnova.phrases.scheduleddate')}
                  onChange={e =>
                    setFormData({
                      date: moment(e.target.value).format('YYYY-MM-DD')
                    })
                  }
                />
              </Box>
            )}
            {action === 9999 && (
              <Box mb={2} display="flex" style={{ gap: '13px' }}>
                <TextField
                  style={{ width: '300px' }}
                  variant="outlined"
                  label={t('i18n.ticket.CNOVA_TRACKING_CODE')}
                  onChange={e =>
                    setFormData({
                      SROCode: e.target.value
                    })
                  }
                />
                <TextField
                  style={{ width: '565px' }}
                  variant="outlined"
                  label={t('i18n.ticket.CNOVA_SHIPPING_COMPANY')}
                  onChange={e =>
                    setFormData({
                      shippingCompany: e.target.value
                    })
                  }
                />
              </Box>
            )}
            <TextField
              value={comment}
              onChange={ev => {
                setComment(ev.target.value);
              }}
              label={t('i18n.simplewords.COMMENTS')}
              fullWidth
              multiline
              variant="outlined"
              rows={3}
              // inputProps={{ maxLength: '255' }}
              // helperText={`${comment?.length}/255`}
            />
          </CardContent>
          <Divider />
          <CardContent>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '15px'
              }}
            >
              <Typography variant="overline">
                {t('i18n.newticket.SEND_FILES')}
              </Typography>
              <Tooltip title={t('i18n.ticketcontainer.INSERT_RETURN_TERM')}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => attachPDFTerm()}
                >
                  <InsertDriveFile />
                </Button>
              </Tooltip>
            </div>
            <Paper
              {...getRootProps({ className: 'dropzone' })}
              style={{
                padding: '20px',
                border: 'dashed 2px #ccc',
                textAlign: 'center'
              }}
              variant="outlined"
            >
              <input {...getInputProps()} />
              <Typography>
                {t('i18n.newticket.DROP_FILES_HERE')} <br />
                {t('i18n.newticket.MAX_FILES')}
              </Typography>
            </Paper>
            <br />
            {files}
          </CardContent>
        </Card>
      </AcerModal>
    </>
  );
};

export default ModalDefault;
