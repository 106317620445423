import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { CustomBreadCrumbs } from '../../../../../../../../styles/components/CustomDataTable';
import { COLORS } from '../../../../../../../../styles/settings/colors.config';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  breadcrumbs: {
    marginTop: '15px',
    marginLeft: '15px'
  }
}));

export default function Header() {
  const classes = useStyles();
  return (
    <Box
      paddingX={5}
      // maxwidth="false"
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <CustomBreadCrumbs
        className={classes.breadcrumbs}
        gutterBottom
        aria-label="breadcrumb"
      >
        <Typography variant="h6">Logística</Typography>

        <Typography variant="h6" style={{ color: COLORS.blueText }}>
          Detalhe da Devolução
        </Typography>
      </CustomBreadCrumbs>
    </Box>
  );
}
