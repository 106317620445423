/* eslint-disable react/no-array-index-key */
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import AcerModal from '../../../../../components/AcerModal';
import API from '../../../../../services/api';
import ConfirmationModal from '../../../../../utils/ConfirmationModal';

const reasonsOptions = [
  {
    value: '1',
    reasonText: 'A Reclamação de outra empresa',
    label: 'OTHER_COMPANY_CLAIM'
  },
  {
    value: '4',
    reasonText: 'Reclamação em duplicidade',
    label: 'DUPLICATED_CLAIM'
  },
  {
    value: '5',
    reasonText: 'Conteúdo impróprio',
    label: 'INAPPROPRIATE_CONTENT'
  },
  {
    value: '7',
    reasonText: 'Reclamação infundada',
    label: 'UNSUBSTANTIATED_COMPLAINT'
  },
  {
    value: '15',
    reasonText: 'Reclamação de terceiros',
    label: 'THIRD_PARTY_COMPLAINT'
  },
  {
    value: '16',
    reasonText: 'Reclamação trabalhista',
    label: 'WORKING_COMPLAINT'
  },
  {
    value: '17',
    reasonText: 'A empresa não violou o direito do consumidor',
    label: 'THE_COMPANY_DID_NOT_VIOLATE_THE_CONSUMERS_RIGHT'
  },
  { value: '19', reasonText: 'Este é um caso de fraude', label: 'FRAUD_CASE' }
];

const ModerationRequest = ({ ticketData, openState, closeParent }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const inputFile = useRef(null);

  const [open, setOpen] = useState(openState);
  const [comment, setComment] = useState('');
  const [reason, setReason] = useState('');
  const [attachFile, setAttachFile] = useState('');
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
  const [confirmationModalAction, setConfirmationModalAction] = useState();

  const cleanModal = () => {
    setAttachFile(null);
    setComment('');
    setReason('');
  };

  const handleClose = () => {
    closeParent();
    setOpen(false);
    cleanModal();
  };

  const onDrop = acceptedFiles => {
    const newFilesState = [...filesToUpload];
    if (newFilesState.length + acceptedFiles.length > 4) {
      enqueueSnackbar('Não é possível incluir mais que 4 imagens', {
        variant: 'error'
      });
      return;
    }
    acceptedFiles.forEach(element => {
      if (element.size > 2000000) {
        enqueueSnackbar(
          `A imagem '${element.path}' não pode ser enviada pois ela tem mais de 2MB`,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          }
        );
        return;
      }
      const reader = new FileReader();
      const fileByteArray = [];
      reader.readAsArrayBuffer(element);
      reader.onloadend = evt => {
        if (evt.target.readyState === FileReader.DONE) {
          const arrayBuffer = evt.target.result;
          const array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < array.length; i++) {
            fileByteArray.push(array[i]);
          }
        }
      };
      newFilesState.push({
        path: element.path,
        binary: fileByteArray
      });
    });
    setFilesToUpload(newFilesState);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 4
  });

  const files = filesToUpload.map((file, index) => (
    <Chip
      key={index}
      style={{ margin: '2px' }}
      label={file.path}
      onDelete={() => {
        const newFilesState = [...filesToUpload];
        newFilesState.splice(index, 1);
        setFilesToUpload(newFilesState);
      }}
    />
  ));

  const getSendResponseMessagePayload = () => {
    const { reasonText } = reasonsOptions.find(obj => obj.value === reason);

    return {
      idTicket: ticketData?.id,
      id: ticketData?.marketplaceBundle?.idHugmeAPI,
      message: comment,
      reason,
      reasonText,
      attachments: filesToUpload?.map(file => {
        return { name: file.path, content: file.binary };
      })
    };
  };

  const submitRequest = () => {
    const newMessage = getSendResponseMessagePayload();
    API.post(
      `/auth/ms-ticket/v1/tickets/reclame-aqui/request-moderation`,
      newMessage
    )
      .then(response => {
        setComment('');
        setReason('');
        inputFile.current.value = null;
        setAttachFile(null);
        handleClose();
        if (response && response.message && response.message.key)
          enqueueSnackbar(t(response.message.key), {
            variant: 'success'
          });
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
          variant: 'error'
        });
      })
      .finally(() => handleClose());
  };

  useEffect(() => {
    setOpen(openState);
  }, [openState]);

  return (
    <>
      <ConfirmationModal
        message={confirmationModalMessage}
        submitConfirmation={() => {
          confirmationModalAction();
          setConfirmationModalOpen(false);
        }}
        openState={confirmationModalOpen}
        closeParent={() => {
          setConfirmationModalOpen(false);
        }}
      />
      <AcerModal
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}
        modalTitle={t('i18n.reclame_aqui.phrases.request_moderation')}
        confirmActionLabel={t('i18n.simplewords.FINISH')}
        confirmActionFunc={() => {
          if (!comment || !reason) {
            enqueueSnackbar(
              t('i18n.reclame_aqui.modal.request_moderation.FILL_FORM'),
              { variant: 'warning' }
            );
            return;
          }
          setConfirmationModalAction(() => () => {
            submitRequest();
          });
          setConfirmationModalMessage(
            t('i18n.reclame_aqui.modal.request_moderation.confirm')
          );
          setConfirmationModalOpen(true);
        }}
      >
        <Card>
          <CardContent>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('i18n.newticket.REASON')}</InputLabel>
              <Select
                value={reason}
                onChange={htmlElemtEvent => {
                  setReason(htmlElemtEvent.target.value);
                }}
                label={t('i18n.newticket.REASON')}
              >
                {reasonsOptions.map(item => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {t(
                        `i18n.reclame_aqui.modal.request_moderation.REASON.${item.label}`
                      )}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </CardContent>
          <Divider />

          <CardContent>
            <TextField
              value={comment}
              onChange={ev => {
                setComment(ev.target.value);
              }}
              label={t('i18n.simplewords.COMMENTS')}
              fullWidth
              multiline
              variant="outlined"
              rows={3}
            />
            <Box style={{ float: 'left' }} m={1}>
              <input {...getInputProps()} />
              {files.length > 0 && <> {files} </>}
            </Box>
            <Box mt={2}>
              <Tooltip
                title={`${t('i18n.chatview.TOOLTIP_ATTACH')} (JPG | PNG)`}
              >
                <Button
                  {...getRootProps({ className: 'dropzone' })}
                  color="primary"
                  variant="contained"
                >
                  <AttachFileIcon />
                </Button>
              </Tooltip>
              {attachFile && (
                <Chip
                  style={{ marginLeft: '10px' }}
                  label={attachFile?.name}
                  onClick={() => {}}
                  onDelete={() => {
                    inputFile.current.value = null;
                    setAttachFile(null);
                  }}
                />
              )}
            </Box>
          </CardContent>
        </Card>
      </AcerModal>
    </>
  );
};

export default ModerationRequest;
