export const emojiSentiments = [
  { id: 1, emoji: '🤬', tooltip: 'i18n.emojis.ANGRY' },
  { id: 2, emoji: '😠', tooltip: 'i18n.emojis.BAD' },
  { id: 3, emoji: '😑', tooltip: 'i18n.emojis.NEUTRAL' },
  { id: 4, emoji: '🙂', tooltip: 'i18n.emojis.SMILE' },
  { id: 5, emoji: '😀', tooltip: 'i18n.emojis.HAPPY' }
];

export const getSatisfactionEmoji = percent => {
  if (percent <= 20) {
    return emojiSentiments[0];
  }
  if (percent > 20 && percent <= 40) {
    return emojiSentiments[1];
  }
  if (percent > 40 && percent <= 60) {
    return emojiSentiments[2];
  }
  if (percent > 60 && percent <= 80) {
    return emojiSentiments[3];
  }
  if (percent > 80 && percent <= 100) {
    return emojiSentiments[4];
  }
};
