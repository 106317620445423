import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const stringToDate = stringDate => {
  const parts = stringDate.split(' ');
  const date = parts[0];
  const time = parts[1];
  const dateParts = date.split('/');
  const timeParts = time.split(':');
  return new Date(
    dateParts[2],
    dateParts[1] - 1,
    dateParts[0],
    timeParts[0],
    timeParts[1]
  );
};

const FilterCardDescriber = ({ filterKey, value }) => {
  const { t } = useTranslation();

  if (filterKey === 'creationDate') {
    const userTimeZone = new Date().getTimezoneOffset();
    let from = value?.split('-')[0];
    let to = value?.split('-')[1];
    from = moment(stringToDate(from))
      .utcOffset(userTimeZone * -2)
      .format('DD/MM/YYYY HH:mm');
    to = moment(stringToDate(to))
      .utcOffset(userTimeZone * -2)
      .format('DD/MM/YYYY HH:mm');
    return `${from} - ${to}`;
  }

  if (filterKey === 'ticketStatus') {
    return <>{t(`i18n.preSale.${value}`)}</>;
  }

  return <>{t(`i18n.ticketslistview.ticketStatus.${value}`)}</>;
};

export default FilterCardDescriber;
