export default {
  cognito: {
    authUrl: process.env.REACT_APP_COGNITO_AUTH_URL,
    clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    chatGroupName: process.env.REACT_APP_COGNITO_CHAT_GROUPNAME,
    preSaleGroupName: process.env.REACT_APP_COGNITO_PRESALE_GROUPNAME,
    ownerCompany: process.env.REACT_APP_COGNITO_OWNER_COMPANY
  },
  backend: {
    url: process.env.REACT_APP_BACKEND_URI,
    macroURL: `${process.env.REACT_APP_BACKEND_URI}/auth/lambda/macros`
  },
  websocket: {
    chaturl: process.env.REACT_APP_BACKEND_CHAT_WSS,
    chaturlb2b: process.env.REACT_APP_BACKEND_GENERAL_WSS, // TODO Trocar para websocket do b2b
    generalurl: process.env.REACT_APP_BACKEND_GENERAL_WSS,
    logisticsMessages: process.env.REACT_APP_BACKEND_LOGISTICA_WSS,
    monitorurl: process.env.REACT_APP_BACKEND_MONITOR_WSS
  },
  capServiceCodes: process.env.REACT_APP_CAP_SERVICE_CODES
};
