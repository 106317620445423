import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import FilterForm from './filterForm.component';
import { convertDateToFilter } from '../services/convertDate';
import indexHook from '../indexHook';
import API from '../../../services/api';
import dashboardServices from '../services/dashboardServices';
import formHelper from '../../../utils/formHelper';
import { filterNameInStorage } from './config';

const FilterDashboard = ({
  open,
  setOpen,
  dashboardFilter,
  setDashboardFilter
}) => {
  const reduxDispatcher = useDispatch();
  const { isAdmin, userName, enqueueSnackbar, t } = indexHook();

  const [filter, setFilter] = useState(dashboardFilter);
  const [submit, setSubmit] = useState(false);
  const [dataIndividualDash, setDataIndividualDash] = useState('');
  const [dataEquipDash, setDataEquipDash] = useState('');
  const [attendantList, setAttendantList] = useState([]);

  const handleErroGetAttendantData = () => {
    setDataIndividualDash(dashboardServices.handleNotHaveDataChatAttendant());
  };

  const handleGetPeriodAttendantDataChat = response => {
    /**
     * Se tiver um atendente no filtro, a função vai procurar, nos dados retornados do backend, dados relacionados ao atendente.
     * Dados individuas do dashboard de chat por período
     */

    if (dashboardFilter.attendant) {
      if (response.data.lista_final_profissionais.length > 0) {
        const attendant = response.data.lista_final_profissionais.filter(
          item => {
            return item.atendente === dashboardFilter.attendant;
          }
        );
        if (attendant.length > 0) {
          setDataIndividualDash(
            dashboardServices.handleSeparateChatAttendantData(attendant[0])
          );
        } else {
          handleErroGetAttendantData();
        }
      } else {
        handleErroGetAttendantData();
      }
    }
  };

  const handleGetDashboard = currentFilter => {
    reduxDispatcher({ type: 'SET_CHATS_DASHBOARD_LOADING', loading: true });

    const data = { ...currentFilter, ...{ attendant: undefined } };

    API.post(`auth/lambda/dash-chat-bi/filtered-bi`, data)
      .then(response => {
        setDataEquipDash(
          dashboardServices.handleSeparateChatEquipData(response.data)
        );
        handleGetPeriodAttendantDataChat(response);
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.dashboard.components.no_data'), {
          variant: 'error'
        });
      })
      .finally(() => {
        reduxDispatcher({
          type: 'SET_CHATS_DASHBOARD_LOADING',
          loading: false
        });
      });
  };

  useEffect(() => {
    if (submit) {
      const { startDate, endDate } = convertDateToFilter(filter);
      filter.startDate = startDate;
      filter.endDate = endDate;
    }

    filter.attendant = !isAdmin ? userName : filter.attendant;

    reduxDispatcher({ type: 'SET_CHATS_DASHBOARD_FILTER', filter });
    setDashboardFilter(filter);
  }, [filter, submit]);

  useEffect(() => {
    if (dataEquipDash !== '') {
      reduxDispatcher({
        type: 'SET_CHATS_DASHBOARD_EQUIP_DATA',
        equipData: dataEquipDash
      });
    }
  }, [dataEquipDash]);

  useEffect(() => {
    if (dataIndividualDash !== '') {
      reduxDispatcher({
        type: 'SET_CHATS_DASHBOARD_INDIVIDUAL_DATA',
        individualData: dataIndividualDash
      });
    }
  }, [dataIndividualDash]);

  useEffect(() => {
    sessionStorage.setItem(
      filterNameInStorage,
      JSON.stringify(dashboardFilter)
    );
    handleGetDashboard(dashboardFilter);
  }, [dashboardFilter]);

  useEffect(() => {
    API.get(`/auth/ms-users/groups/atendente/users`).then(response => {
      setAttendantList(response.data.sort(formHelper.compareName));
    });
  }, []);

  return (
    <>
      <div style={{ width: '60px', borderRight: '1px solid #eee' }} />
      {open && (
        <FilterForm
          setFilter={setFilter}
          setSubmit={setSubmit}
          setOpen={setOpen}
          filter={filter}
          attendantList={attendantList}
        />
      )}
    </>
  );
};

export default FilterDashboard;
