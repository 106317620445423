import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import AcerModal from '../../../../../components/AcerModal';
import { COLORS } from '../../../../../styles/settings/colors.config';
import API from '../../../../../services/api';

const ModalText = styled('p')`
  font-size: 16px;
  line-height: 1.5em;
`;

const ModalTextField = styled(TextField)`
  margin-top: 1em;
`;

const CustomTableCell = styled(TableCell)`
  background: #eee;
`;

const CustomInput = styled('input')`
  border-radius: 4px;
  height: 40px;
  border: 1px solid #ccc;
  margin: 0px 10px;
  padding-left: 10px;
  width: ${props => (props.width ? props.width : 'inherit')};
  :focus,
  :visited,
  :focus-visible,
  :focus-within,
  :active {
    border-color: ${COLORS.greenText};
  }
`;

const ModalGrid = styled(Grid)`
  margin-top: 20px;
`;

const ModalGridButton = styled(Grid)`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ModalPostDelivery = ({
  instance,
  ticketId,
  deliveryId,
  openState,
  handleClose,
  deliveryType
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [textFieldDelivery, setTextFieldDelivery] = useState('');
  const [nDays, setNDays] = useState('0');
  const [deliveryCode, setDeliveryCode] = useState('');

  const postData = {
    ticketId,
    instanceId: instance.id,
    returnNote: textFieldDelivery,
    returnType: 'POST',
    returnTime: nDays,
    postCode: deliveryCode,
    // eslint-disable-next-line camelcase
    delivery_id: deliveryId
  };

  const postDataCollect = {
    ticketId,
    instanceId: instance.id,
    returnTime: nDays,
    returnNote: textFieldDelivery,
    returnType: 'COLLECT'
  };

  const clearForm = () => {
    setTextFieldDelivery(`${t('i18n.b2w.modal.postdelivery.TEXTAREA_1')}
${t('i18n.b2w.modal.postdelivery.TEXTAREA_2')}
${
  deliveryType === 'post'
    ? t('i18n.b2w.modal.postdelivery.TEXTAREA_3')
    : t('i18n.b2w.modal.postdelivery.TEXTAREA_6')
}`);
    setNDays('0');
    setDeliveryCode('');
  };

  const handleSubmit = () => {
    if (deliveryType === 'post' || deliveryType === 'post-returnPurchase') {
      API.post(`/auth/ms-ticket/v1/tickets/b2w/post-cap`, postData)
        .then(() => {})
        .catch(() => {
          enqueueSnackbar(t('i18n.ticket.B2W.SEND_CAP_FAIL'), {
            variant: 'error'
          });
        })
        .finally(() => {
          handleClose();
          clearForm();
        });
    }
    if (
      deliveryType === 'collect' ||
      deliveryType === 'collect-returnPurchase'
    ) {
      API.post(
        `/auth/ms-ticket/v1/tickets/b2w/schedule-collection`,
        postDataCollect
      )
        .then(() => {})
        .catch(() => {
          enqueueSnackbar(t('i18n.ticket.B2W.SEND_COLLECT_FAIL'), {
            variant: 'error'
          });
        })
        .finally(() => {
          handleClose();
          clearForm();
        });
    }
  };

  useEffect(() => {
    setTextFieldDelivery(
      `${t('i18n.b2w.modal.postdelivery.TEXTAREA_1')} \n${t(
        'i18n.b2w.modal.postdelivery.TEXTAREA_2'
      )} \n${
        deliveryType.includes('post')
          ? t('i18n.b2w.modal.postdelivery.TEXTAREA_3')
          : t('i18n.b2w.modal.postdelivery.TEXTAREA_6')
      }`
    );
  }, [deliveryType]);

  return (
    <AcerModal
      fullWidth
      maxWidth="md"
      open={openState}
      modalTitle={
        deliveryType.includes('post')
          ? t('i18n.b2w.modal.postdelivery.TITLE_POST')
          : t('i18n.b2w.modal.postdelivery.TITLE_COLLECT')
      }
      onClose={handleClose}
    >
      <Card>
        <CardContent>
          <Grid container>
            <ModalGrid item lg={12}>
              <ModalText>
                {t('i18n.b2w.modal.postdelivery.HELLO')},{' '}
                {instance.customerName}
              </ModalText>
              <ModalText>
                {instance.type === 'Reembolso'
                  ? t('i18n.b2w.modal.postdelivery.YOUR_SOLICITATION_REFUND')
                  : t(
                      'i18n.b2w.modal.postdelivery.YOUR_SOLICITATION_EXCHANGE'
                    )}{' '}
                {instance.orderId},{' '}
                {t('i18n.b2w.modal.postdelivery.IN_TREATMENT')}
              </ModalText>
            </ModalGrid>
            <ModalGrid item lg={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <ModalText>
                        {t('i18n.b2w.modal.postdelivery.PRODUCT_TITLE')}
                      </ModalText>
                    </TableCell>
                    <CustomTableCell>
                      <ModalText>
                        {t('i18n.b2w.modal.postdelivery.QUANTITY')}
                      </ModalText>
                    </CustomTableCell>
                    <CustomTableCell>
                      <ModalText>
                        {t('i18n.b2w.modal.postdelivery.VALUE')}
                      </ModalText>
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {instance?.originalItems?.map(product => (
                    <TableRow key={product.id}>
                      <TableCell>
                        <ModalText>{product.name}</ModalText>
                      </TableCell>
                      <CustomTableCell>
                        <ModalText>{product.quantity}</ModalText>
                      </CustomTableCell>
                      <CustomTableCell>
                        <ModalText>
                          {Number(product.salePrice).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          })}
                        </ModalText>
                      </CustomTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ModalGrid>

            {deliveryType.includes('post') && (
              <>
                <ModalGrid item lg={12}>
                  <ModalText>
                    {t('i18n.b2w.modal.postdelivery.THE_CODE')}{' '}
                    <CustomInput
                      value={deliveryCode}
                      onChange={e => setDeliveryCode(e.target.value)}
                    />
                    , {t('i18n.b2w.modal.postdelivery.ITS_VALID')}
                    <CustomInput
                      type="number"
                      inputProps={{ max: '999' }}
                      value={nDays}
                      onChange={e => setNDays(e.target.value)}
                      width="50px"
                    />
                    {t('i18n.b2w.modal.postdelivery.DAYS')}.
                  </ModalText>
                </ModalGrid>
                <ModalGrid item lg={12}>
                  <ModalText>
                    {t('i18n.b2w.modal.postdelivery.AFTER_DEVOLUTION')}
                  </ModalText>
                </ModalGrid>
              </>
            )}

            <ModalGrid item lg={12}>
              <ModalText>
                {deliveryType.includes('post')
                  ? t('i18n.b2w.modal.postdelivery.SEE_BELOW_POST')
                  : t('i18n.b2w.modal.postdelivery.SEE_BELOW_COLLECT')}
              </ModalText>
              <ModalTextField
                multiline
                className="message-textarea"
                rows={8}
                size="small"
                fullWidth
                value={textFieldDelivery}
                onChange={e => setTextFieldDelivery(e.target.value)}
                variant="outlined"
              />
            </ModalGrid>

            {deliveryType.includes('collect') && (
              <>
                <ModalGrid item lg={12}>
                  <ModalText>
                    {t('i18n.b2w.modal.postdelivery.THE_COLLECTION')}
                    <CustomInput
                      type="number"
                      inputProps={{ max: '999' }}
                      value={nDays}
                      onChange={e => setNDays(e.target.value)}
                      width="50px"
                    />
                    {t('i18n.b2w.modal.postdelivery.DAYS')}.
                  </ModalText>
                </ModalGrid>
                <ModalGrid item lg={12}>
                  <ModalText>
                    {t('i18n.b2w.modal.postdelivery.AFTER_DEVOLUTION')}
                  </ModalText>
                </ModalGrid>
              </>
            )}
            <Grid item container direction="row" justifyContent="flex-end">
              {/* <ModalGridButton item lg={7}>
                <Button
                  variant="contained"
                  color="default"
                  onClick={handleClose}
                >
                  {t('i18n.simplewords.CANCEL')}
                </Button>
              </ModalGridButton> */}
              <ModalGridButton item lg={5}>
                <Button
                  disabled={
                    deliveryType.includes('post') && deliveryCode === ''
                  }
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  {t('i18n.b2w.modal.postdelivery.SUBMIT_INFORMATIONS')}
                </Button>
              </ModalGridButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </AcerModal>
  );
};

export default ModalPostDelivery;
