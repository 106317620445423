import { Divider } from '@material-ui/core';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import RequestDialog from '../../Dialogs/RequestDialog';
import DotsSection from './Components/DotsSection';
import { tempUpdate } from './functions';

export default function Timeline({
  data,
  setOpenDevolutionConference,
  setAuthorizeDevolution,
  setCurrentCancelProcess,
  // setUpdateTracking,
  setCancellingViewMode
}) {
  const [openReversalDialog, setOpenReversalDialog] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { id } = data;

  const steps = data?.cancellationProcess?.stepsTimeLine;

  const stepsSize = steps.length;
  const lastStep = steps[stepsSize - 1];

  const buttonActions = () => {
    setCurrentCancelProcess(id);

    switch (lastStep.name) {
      case 'Aguardando Postagem':
        tempUpdate(data, 'Objeto Postado', t, enqueueSnackbar);
        break;
      case 'Aguardando Chegada na Acer':
        tempUpdate(data, 'Objeto Entregue na Acer', t, enqueueSnackbar);
        break;
      case 'Aguardando conferência do especialista':
        setOpenDevolutionConference(true);
        setAuthorizeDevolution(false);
        break;
      case 'Troca liberada pela Service':
        tempUpdate(
          data,
          'Objeto Postado - processo finalizado',
          t,
          enqueueSnackbar
        );
        break;
      case 'Estorno realizado':
        tempUpdate(
          data,
          'Cliente informado - processo finalizado',
          t,
          enqueueSnackbar
        );
        break;
      default:
        return false;
    }
  };

  const viewModeAction = () => {
    setCurrentCancelProcess(id);
    setCancellingViewMode(true);
  };

  return (
    <>
      <DotsSection
        {...{ data, viewModeAction, buttonActions, setCurrentCancelProcess }}
      />
      <div style={{ padding: '0 100px 20px 100px' }}>
        <Divider />
      </div>
      <RequestDialog
        open={openReversalDialog}
        type="finance"
        data={data}
        setOpen={setOpenReversalDialog}
        currentCancelProcess={data.cancellationProcess}
        title="Registrar estorno"
      />
    </>
  );
}
