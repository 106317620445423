import {
  Card,
  CardContent,
  createTheme,
  Divider,
  Grid,
  LinearProgress,
  ThemeProvider,
  Typography,
  withStyles
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';
import { floatToDecimal } from '../../../../../helpers/utils';
import { COLORS } from '../../../../../styles/settings/colors.config';
import CardCustomTitle from '../components/CardCustomTitle';

const PercentStyle = styled.span`
  font-family: Roboto;
  font-size: 40px;
  font-weight: semibold;
  color: ${COLORS.grayText};
`;

const VariationStyle = styled.span`
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  min-height: 40px;
  color: ${props => (props.negative ? COLORS.btnDangerColor : COLORS.blueText)};
`;

const DescryptionStyle = styled.span`
  font-family: Roboto;
  font-size: 30px;
  font-weight: semibold;
  display: flex;
  align-items: flex-end;
  min-height: 40px;
  justify-content: flex-end;
  color: ${COLORS.grayText};

  .small-word {
    font-size: 18px;
    margin-left: 10px;
    margin-bottom: 2px;
  }
`;

const cardTheme = createTheme({
  overrides: {
    MuiCardContent: {
      root: {
        padding: '3px 16px 3px 16px',
        marginTop: '-25px'
      }
    }
  }
});

const BorderLinearProgressRed = withStyles(() => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  bar: {
    borderRadius: 5,
    backgroundColor: COLORS.btnDangerColor
  }
}))(LinearProgress);

const BorderLinearProgressGreen = withStyles(() => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  bar: {
    borderRadius: 5,
    backgroundColor: COLORS.greenText
  }
}))(LinearProgress);

const calcGoalPercent = (value, goal, type) => {
  if (type === 'cancelling') {
    if (value < goal) return 100;
    if (value === goal) return 99;
    const diffPercent = 1 - (value - goal) / goal / value;
    return diffPercent * 100;
  }

  if (type === 'cr') {
    if (value < goal) return 100;
    if (value === goal) return 99;
    const diffPercent = 100 * +(1 - (value - goal) / value);
    return diffPercent;
  }

  return value / goal > 1 ? 100 : Math.round((value / goal) * 100);
};

const ScoreInfoCardMagalu = ({
  title,
  type,
  descriptionHint,
  negative,
  mainPercent,
  growPercent,
  countNumber,
  numberComplement,
  goal,
  goalText
}) => {
  const goalPercent = calcGoalPercent(mainPercent, goal, type);
  return (
    <Card style={{ width: '100%', marginBottom: '20px', borderRadius: '10px' }}>
      <CardCustomTitle {...{ title, descriptionHint }} />
      <ThemeProvider theme={cardTheme}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <PercentStyle>
                {floatToDecimal(mainPercent)}
                {(type === 'cancelling' || type === 'delaySending') && '%'}
              </PercentStyle>
            </Grid>
            <Grid item xs={3}>
              {growPercent && (
                <VariationStyle negative={negative}>
                  {negative ? <ArrowDownward /> : <ArrowUpward />}
                  {floatToDecimal(growPercent)}
                </VariationStyle>
              )}
            </Grid>
            <Grid item xs={6}>
              <DescryptionStyle>
                {countNumber}
                <span className="small-word">{numberComplement}</span>
              </DescryptionStyle>
            </Grid>
          </Grid>
          <Divider style={{ marginBottom: '10px' }} />
          <Typography>{goalPercent >= 100 ? 'Bom' : 'Ruim'}</Typography>

          {goalPercent >= 100 ? (
            <BorderLinearProgressGreen
              variant="determinate"
              value={goalPercent}
            />
          ) : (
            <BorderLinearProgressRed
              variant="determinate"
              value={goalPercent}
            />
          )}
          <Typography
            style={{
              color: COLORS.meduimGray,
              marginTop: '5px',
              fontWeight: 'bold'
            }}
          >
            {goalText}
          </Typography>
        </CardContent>
      </ThemeProvider>
    </Card>
  );
};

export default ScoreInfoCardMagalu;
