import {
  Box,
  makeStyles,
  RadioGroup,
  createStyles,
  FormControlLabel,
  Radio,
  TextField
} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import {
  CancelHeader,
  CustomerSection,
  DetailsSection,
  OrangeWarning,
  OrdersHeaderSection,
  OrdersHeaderValue
} from '../../../Dialogs/styles';
import OrderItems from './OrderItems';

const useStyles = makeStyles(() =>
  createStyles({
    smallRadioButton: {
      margin: '0 5px 0 0',
      '& svg': {
        width: '0.7em',
        height: '0.7em',
        color: '#6AA84F'
      }
    },
    radioGroupCancelling: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      width: '150px'
    },
    inputOrangeSmall: {
      width: '100px',
      height: '30px',
      fontSize: '12px',
      fontWeight: 'bold',
      color: '#ED7D31',
      '& .MuiOutlinedInput-input': {
        textAlign: 'right',
        backgroundColor: '#FFFFFF'
      }
    }
  })
);

const OrdersHeaderTitle = styled.div`
  color: #666666;
  font-size: 12px;
`;

export default function CancelItemsContainer({
  cancelAll,
  handleCancelAll,
  cancellationType,
  dataOrder,
  selectedItems,
  setSelectedItems,
  setTotalRefund,
  setCancelAll,
  orderTotalValue,
  isExchange,
  totalRefund
}) {
  const classes = useStyles();
  return (
    <DetailsSection>
      <Box display="flex" justifyContent="space-between" alignItems="bottom">
        <div style={{ width: '360px' }}>
          <CancelHeader>Cancelar todos os itens do pedido?</CancelHeader>

          <RadioGroup
            value={cancelAll}
            onChange={handleCancelAll}
            className={classes.radioGroupCancelling}
          >
            <FormControlLabel
              value="SIM"
              control={<Radio />}
              className={classes.smallRadioButton}
              label={<span style={{ fontSize: '12px' }}>SIM</span>}
            />
            <FormControlLabel
              value="NÃO"
              control={<Radio />}
              label={<span style={{ fontSize: '12px' }}>NÃO</span>}
              className={classes.smallRadioButton}
            />
          </RadioGroup>
          {cancelAll === 'NÃO' && (
            <OrangeWarning>Selecione os itens para cancelamento:</OrangeWarning>
          )}
        </div>
        <div
          style={{
            width: '90px',
            display: 'inline-block',
            alignSelf: 'flex-end'
          }}
        >
          Código <br />
          (Part Number)
        </div>
        <div
          style={{
            width: '50px',
            display: 'inline-block',
            alignSelf: 'flex-end'
          }}
        >
          QTD Original
        </div>
        <div
          style={{
            width: '60px',
            display: 'inline-block',
            alignSelf: 'flex-end'
          }}
        >{`QTD ${cancellationType}`}</div>
        <div
          style={{
            width: '75px',
            display: 'inline-block',
            alignSelf: 'flex-end'
          }}
        >
          Valor Un
        </div>
        <div
          style={{
            width: '75px',
            display: 'inline-block',
            alignSelf: 'flex-end'
          }}
        >
          Valor Total
        </div>
      </Box>

      <OrderItems
        {...{
          dataOrder,
          cancelAll,
          selectedItems,
          setSelectedItems,
          setTotalRefund,
          setCancelAll,
          orderTotalValue
        }}
      />

      <Box display="flex" justifyContent="space-between" mt={1}>
        <Box display="flex">
          <CustomerSection margin>
            <OrdersHeaderTitle>Nome do cliente</OrdersHeaderTitle>
            <OrdersHeaderValue>
              {dataOrder?.cliente?.nome || 'Aguarde...'}
            </OrdersHeaderValue>
          </CustomerSection>
          <OrdersHeaderSection margin>
            <OrdersHeaderTitle>CPF</OrdersHeaderTitle>
            <OrdersHeaderValue>
              {dataOrder?.cliente?.documento || 'Aguarde...'}
            </OrdersHeaderValue>
          </OrdersHeaderSection>
        </Box>
        <Box display="flex" alignItems="center">
          {!isExchange &&
            selectedItems.length > 0 &&
            cancellationType !== 'TROCA' && (
              <>
                <OrangeWarning marginRight="10px">
                  Solicitar estorno de R$
                </OrangeWarning>
                <div>
                  <TextField
                    value={totalRefund}
                    size="small"
                    variant="outlined"
                    onChange={e => {
                      setTotalRefund(e.target.value);
                    }}
                    InputProps={{ root: classes.inputOrangeSmall }}
                  />
                </div>
              </>
            )}
        </Box>
      </Box>
    </DetailsSection>
  );
}
