import { Box, Button } from '@material-ui/core';
import { KeyboardBackspace } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  text-transform: none;
`;

export default function ActionButtons({ details, updateData }) {
  return (
    <Box
      paddingX={5}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '25px'
      }}
    >
      <StyledButton
        variant="outlined"
        color="primary"
        style={{ background: 'white' }}
        startIcon={<KeyboardBackspace />}
        onClick={() => {
          window.location.href = '/cancelling/logistics';
        }}
      >
        {!details.isReviewedByLogistics ? 'Cancelar e Voltar' : 'Voltar'}
      </StyledButton>
      <StyledButton
        variant="contained"
        color="primary"
        onClick={updateData}
        disabled={details.isReviewedByLogistics}
      >
        Enviar parecer logística
      </StyledButton>
    </Box>
  );
}
