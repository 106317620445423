import React, { useContext } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../styles/settings/colors.config';
import acerLogo from '../../../assets/images/acer_logo.png';
import { ThemeContext } from '../../../layouts/MonitorLayout';
import { MonitorCount } from './monitorCount.component';
import { CustomTypography } from './card.component';

const HeaderTitleContainer = styled.div`
  display: block;
  padding-left: ${props => `${props.paddingLeft}px` ?? 0};
  margin-right: ${props =>
    props.marginRight ? `${props.marginRight}px` : '40px'};

  h2 {
    color: ${COLORS.grayText};
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }
`;

const MonitorHeader = ({
  title,
  paddingLeft,
  marginRight,
  setMonitorData,
  setIsLoading,
  title2
}) => {
  const theme = useContext(ThemeContext);
  const { screen } = theme;

  return screen !== 'tv' ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
      }}
    >
      <div
        style={{
          width: '40%'
        }}
      >
        <HeaderTitleContainer
          paddingLeft={paddingLeft}
          marginRight={marginRight}
        >
          <h2>{title}</h2>
        </HeaderTitleContainer>
      </div>

      <div
        style={{
          width: '100%'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            justifyContent: 'flex-end'
          }}
        >
          <CustomTypography font="1.5em">
            Dados do período:{' '}
            <b style={{ color: theme.warningV3 }}>Neste momento</b>
          </CustomTypography>
          <MonitorCount {...{ setMonitorData, setIsLoading }} />
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          gap: '8px',
          alignItems: 'center'
        }}
      >
        <img src={acerLogo} alt="Acer logo" width="122px" height="28px" />
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <CustomTypography font={theme?.font32} color={theme.color}>
            {title2} -
          </CustomTypography>
          <CustomTypography font={theme?.font32}>
            <b style={{ color: theme.warningV3 }}>Neste momento</b>
          </CustomTypography>
        </div>
      </div>
      <MonitorCount {...{ setMonitorData, setIsLoading }} />
    </div>
  );
};

export { MonitorHeader };
