import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Page from '../../components/Page';
import API from '../../services/api';
import formHelper from '../../utils/formHelper';
import { CustomBreadCrumbs } from '../../styles/components/CustomDataTable';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  breadcrumbs: {
    marginTop: '15px',
    marginLeft: '24px'
  }
}));

const UserContainer = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const companySelected = history.location?.state?.company;
  const companies = history.location?.state?.companies;
  const [isSaving, setSaving] = useState(false);
  const [id, setId] = useState('');
  const [formData, setFormdata] = useState(
    formHelper.simpleData2FormData({
      vtex_id: {
        required: true,
        value: ''
      },
      name: {
        required: true,
        value: ''
      }
    })
  );

  const formIsOK = () => {
    if (!formHelper.validaForm(formData, setFormdata)) {
      enqueueSnackbar(t('i18n.newticket.CHECK_IF_FIELDS_ARE_FILLED'), {
        variant: 'error'
      });
      return false;
    }
    if (formHelper.existsEmptySpaces(formData.vtex_id.value)) {
      enqueueSnackbar(t('i18n.validation.INVALID_EMPTY_SPACES_BETWEEN'), {
        variant: 'error'
      });
      formHelper.setFormError(formData, setFormdata, 'vtex_id');
      return false;
    }
    if (formHelper.existsEmptySpaces(formData.name.value)) {
      enqueueSnackbar(t('i18n.validation.INVALID_EMPTY_SPACES_BETWEEN'), {
        variant: 'error'
      });
      formHelper.setFormError(formData, setFormdata, 'name');
      return false;
    }
    if (formHelper.containsSpecialChars(formData.name.value)) {
      enqueueSnackbar(t('i18n.validation.HAS_SPECIAL_CHARACTER'), {
        variant: 'error'
      });
      formHelper.setFormError(formData, setFormdata, 'name');
      return false;
    }
    if (
      companies &&
      companies.some(el => el.vtex_id === formData.vtex_id.value)
    ) {
      enqueueSnackbar(t('i18n.validation.ALREADY_EXIST'), {
        variant: 'error'
      });
      formHelper.setFormError(formData, setFormdata, 'vtex_id');
      return false;
    }
    if (
      !companySelected &&
      companies &&
      companies.some(
        el => el.name.toUpperCase() === formData.name.value.toUpperCase()
      )
    ) {
      enqueueSnackbar(t('i18n.validation.ALREADY_EXIST'), {
        variant: 'error'
      });
      formHelper.setFormError(formData, setFormdata, 'name');
      return false;
    }
    return true;
  };

  const saveCompany = () => {
    if (!formIsOK()) {
      return;
    }
    setSaving(true);
    const postData = formHelper.formData2PostData(formData);
    if (id) {
      // Edição
      postData.id = id;
    }
    API.post(`/auth/ms-ticket/company`, postData)
      .then(() => {
        history.goBack();
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.companies.SAVE_FAIL'), {
          variant: 'error'
        });
      })
      .finally(() => setSaving(false));
  };

  useEffect(() => {
    if (companySelected) {
      formHelper.setFormvalue(
        formData,
        setFormdata,
        'vtex_id',
        companySelected?.vtex_id || ''
      );
      formHelper.setFormvalue(
        formData,
        setFormdata,
        'name',
        companySelected?.name || ''
      );
      setId(companySelected?.id);
    }
  }, [companySelected]);

  return (
    <Page className={classes.root} title="Company Container">
      <Container maxWidth={false}>
        <CustomBreadCrumbs
          className={classes.breadcrumbs}
          gutterBottom
          aria-label="breadcrumb"
        >
          <Typography variant="h6">
            <Link color="inherit" to="/companies">
              {t('i18n.companies.COMPANIES')}
            </Link>
          </Typography>
          <Typography variant="h6" color="textPrimary">
            {t('i18n.companies.NEW_COMPANY')}
          </Typography>
        </CustomBreadCrumbs>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="sm">
            <Box mt={3}>
              <Card>
                <CardContent>
                  <Typography variant="h3">
                    {t('i18n.companies.NEW_COMPANY')}
                  </Typography>
                </CardContent>
                <Divider />
                <CardContent>
                  <Box mb={2}>
                    <TextField
                      onChange={htmlElemtEvent => {
                        formHelper.setFormvalue(
                          formData,
                          setFormdata,
                          'vtex_id',
                          htmlElemtEvent.target.value
                        );
                      }}
                      error={formData.vtex_id.error}
                      label={t('i18n.companies.ID_VTEX')}
                      value={formData.vtex_id.value}
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      disabled={!!companySelected}
                      onChange={htmlElemtEvent => {
                        formHelper.setFormvalue(
                          formData,
                          setFormdata,
                          'name',
                          htmlElemtEvent.target.value
                        );
                      }}
                      error={formData.name.error}
                      label={t('i18n.companies.NAME')}
                      value={formData.name.value}
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                </CardContent>
                <Divider />
                <CardContent>
                  <Grid container justifyContent="space-between">
                    <Grid item lg={6}>
                      <Button
                        variant="contained"
                        color="default"
                        type="button"
                        onClick={() => history.goBack()}
                      >
                        {t('i18n.simplewords.GOBACK')}
                      </Button>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          saveCompany();
                        }}
                        disabled={isSaving}
                      >
                        {t('i18n.simplewords.SAVE')}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Container>
        </Box>
      </Container>
    </Page>
  );
};

export default UserContainer;
