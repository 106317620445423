import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import API from '../../../../services/api';
import { isB2BProtocol } from '../../../../utils/b2bHelper';
import { TicketContainerContext } from '../context';
import * as S from './styles';
import {
  TrackingModalIframe,
  InvoiceModalIframe,
  OrdersModalIframe,
  AcerCapModal,
  ModalCustomerHistoricIframe,
  ModalLinkComplaintIframe,
  ModalTicketsDetailReclameAquiIframe,
  ModalRequestDevolutionRefundIframe,
  ModalExchangeRefundIframe,
  B2WMarketplaceSupportModalIframe
} from './components';

const TicketDetailsIframe = () => {
  const { id, marketplace } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  // Estados
  const [countTickets, setCountTickets] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [ticketData, setTicketData] = useState({});
  const [openRATicketDetail, setOpenRATicketDetail] = useState(false);
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [raTicketsList, setRaTicketsList] = useState([]);
  const [dataRaToShow, setDataRaToShow] = useState('');
  const [ticketOrders, setTicketOrders] = useState({});
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [openCapModal, setOpenCapModal] = useState(false);
  const [openTracking, setOpenTracking] = useState(false);
  const [messageReceived, setMessageReceived] = useState('');
  const [openDevolutionModal, setOpenDevolutionModal] = useState(false);
  const [exchangeRefundModalState, setExchangeRefundModalState] =
    useState(false);
  const [devolutionRefundFlag, setDevolutionRefundFlag] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsLimit, setStepsLimit] = useState(1);
  const [causes, setCauses] = useState([]);
  const [items, setItems] = useState([]);
  const [marketplaceModalOpen, setMarketplaceModalOpen] = useState(false);

  const firstStep = 0;

  // Funções auxiliares
  const handleStepsNext = () => {
    if (currentStep < stepsLimit) {
      setCurrentStep(currentStep + 1);
    }
  };
  const handleStepsPrev = () => {
    if (currentStep > firstStep) {
      setCurrentStep(currentStep - 1);
    }
  };
  const handleNewAmericanasRefundExchangeCloseModal = () => {
    setOpenDevolutionModal(false);
  };
  const handleOpenModal = async ({ flag, dataTicket }) => {
    setDevolutionRefundFlag(flag);
    setOpenDevolutionModal(true);
    setTicketData(dataTicket);
    const data = {
      ticketId: dataTicket?.id,
      action: flag,
      deliveryId: dataTicket?.marketplaceBundle?.delivery_id,
      orderB2W: dataTicket?.marketplaceBundle?.order_id
    };

    await API.post('/auth/ms-ticket/v1/tickets/b2w/new-instance-1', data).then(
      (response) => {
        setCauses(response.data.causes);
        setItems(response.data.items);
        if (response.data.causes.length === 0) {
          enqueueSnackbar(t('i18n.b2w.modal.exchange.alert'), {
            variant: 'warning'
          });
          setOpenDevolutionModal(false);
        }
      }
    );
  };
  const getTicketDetails = async () => {
    const { data } = await API.get(`/auth/ms-ticket/v1/tickets/${id}`);
    setTicketData(data);
  };
  const handleShowRADetails = (ra) => {
    setOpenRATicketDetail(true);
    setDataRaToShow(ra);
  };
  const handleCloseInvoice = () => {
    setOpenInvoiceModal(false);
  };
  const getItemRaTicket = (data) => {
    const raData = {
      cpf: data?.customerCPF?.replace(/\D/g, '')
    };

    API.post(
      `/auth/ms-ticket/v1/tickets/check-reclame-aqui/${data?.id}`,
      raData
    )
      .then((response) => {
        const raDataResponse = response.data;

        setRaTicketsList(raDataResponse);
        setOpenLinkModal(true);
        // setOpenRATicketDetail(false);
      })
      .catch(() => {});
  };
  const getRATickets = () => {
    const raData = {
      cpf: ticketData?.customerCPF?.replace(/\D/g, '')
    };

    function compareID(a, b) {
      let comparison = 0;
      if (Number(a.idReclameAquiAPI) < Number(b.idReclameAquiAPI)) {
        comparison = 1;
      } else if (Number(a.idReclameAquiAPI) > Number(b.idReclameAquiAPI)) {
        comparison = -1;
      }
      return comparison;
    }

    if (
      ticketData?.customerCPF ||
      ticketData?.marketPlace === 'MERCADO_LIVRE'
    ) {
      if (isB2BProtocol(ticketData)) {
        API.post(
          `/auth/ms-ticket/v1/tickets/check-reclame-aqui/${ticketData?.id}`,
          raData
        )
          .then((response) => {
            let raDataResponse = response.data.sort(compareID);
            raDataResponse = raDataResponse.sort((x) => {
              if (x.linked === false || x.linked === null) {
                return 0;
              }
              if (x) {
                return -1;
              }
              return 1;
            });
            setRaTicketsList(raDataResponse);
            setOpenRATicketDetail(false);
          })
          .catch(() => {});
      }
    }

    // checkRaRelatedToProcotol();
  };
  const handleLinkRATicket = (raTicketId) => {
    const dataRATicket = {
      idReclameAquiAPI: raTicketId
    };
    API.post(
      `/auth/ms-ticket/v1/tickets/assign-reclame-aqui/${ticketData?.id}`,
      dataRATicket
    )
      .then(() => {
        getRATickets();
      })
      .catch(() => {
        // enqueueSnackbar(t('i18n.simplephrases.ERROR'), { variant: 'error' });
      })
      .finally(() => {
        setOpenRATicketDetail(false);
        setOpenLinkModal(false);
      });
  };

  const handleListener = useCallback(
    (e) => {
      if (e.data.type === 'modal-complain-here') {
        setTicketData(e.data.ticketData);
        getItemRaTicket(e.data.ticketData);
      }
      if (e.data.type === 'modal-historic') {
        setTicketData(e.data.ticketData);
        setTicketOrders(e.data.ticketOrder);
        setOpenModal(true);
      }
      if (e.data.type === 'modal-cap') {
        setOpenCapModal(true);
        setMessageReceived('modal-cap');
      }
      if (e.data.type === 'modal-order-number') {
        setTicketData(e.data.ticketData);
        setTicketOrders(e.data.ticketOrder);
        setOpenOrderModal(true);
      }
      if (e.data.type === 'modal-invoice-number') {
        setTicketData(e.data.ticketData);
        setTicketOrders(e.data.ticketOrder);
        setOpenInvoiceModal(true);
      }
      if (e.data.type === 'modal-tracking') {
        setOpenTracking(true);
        setMessageReceived('modal-tracking');
      }
      if (e.data.type === 'modal-americanas-new-instance') {
        setCurrentStep(0);
        setStepsLimit(1);
        handleOpenModal({
          flag: e.data.flag,
          dataTicket: e.data.ticketData
        });
        setOpenDevolutionModal(true);
      }
      if (e.data.type === 'modal-americanas-exchange-refund') {
        setTicketData(e.data.ticketData);
        setExchangeRefundModalState(true);
      }
      if (e.data.type === 'modal-americanas-support') {
        setMarketplaceModalOpen(true);
      }
    },
    [ticketData]
  );

  // Efeitos
  useEffect(() => {
    if (openCapModal) {
      getTicketDetails();
    }
  }, [openCapModal]);
  useEffect(() => {
    if (!openCapModal && messageReceived === 'modal-cap') {
      const iframe = document.getElementById('ticketIframe');
      // eslint-disable-next-line
      iframe.src = iframe.src;
    }
  }, [messageReceived, openCapModal]);
  useEffect(() => {
    if (!openOrderModal) {
      const iframe = document.getElementById('ticketIframe');
      // eslint-disable-next-line
      iframe.src = iframe.src;
    }
  }, [openOrderModal]);
  useEffect(() => {
    window.addEventListener('message', handleListener);
    return () => window.removeEventListener('message', handleListener);
  }, [handleListener]);

  // Renderização
  return (
    <TicketContainerContext.Provider value={{ countTickets, setCountTickets }}>
      <S.IframeWrapper>
        <S.StyledIframe
          id="ticketIframe"
          allow="clipboard-read; clipboard-write"
          src={`${
            process.env.REACT_APP_NEW_CONNECTA
          }/ticket/details/${marketplace}/${id}?refreshToken=${localStorage.getItem(
            'refreshToken'
          )}`}
        />
      </S.IframeWrapper>
      {openCapModal && (
        <AcerCapModal
          openCapModal={openCapModal}
          setOpenCapModal={setOpenCapModal}
          ticketData={ticketData}
        />
      )}
      {marketplaceModalOpen && (
        <B2WMarketplaceSupportModalIframe
          marketplaceModalOpen={marketplaceModalOpen}
          setMarketplaceModalOpen={setMarketplaceModalOpen}
        />
      )}
      {openInvoiceModal && (
        <InvoiceModalIframe
          handleCloseInvoice={handleCloseInvoice}
          openInvoiceModal={openInvoiceModal}
          ticketData={ticketData}
        />
      )}
      {openModal && (
        <ModalCustomerHistoricIframe
          openModal={openModal}
          setOpenModal={setOpenModal}
          ticketData={ticketData}
          ticketOrder={ticketOrders}
        />
      )}
      {exchangeRefundModalState && (
        <ModalExchangeRefundIframe
          exchangeRefundModalState={exchangeRefundModalState}
          setExchangeRefundModalState={setExchangeRefundModalState}
          ticketData={ticketData}
        />
      )}
      {openLinkModal && (
        <ModalLinkComplaintIframe
          handleShowRADetails={handleShowRADetails}
          openLinkModal={openLinkModal}
          raTicketsList={raTicketsList}
          setOpenLinkModal={setOpenLinkModal}
          ticketData={ticketData}
        />
      )}
      {openDevolutionModal && (
        <ModalRequestDevolutionRefundIframe
          causes={causes}
          currentStep={currentStep}
          devolutionRefundFlag={devolutionRefundFlag}
          firstStep={firstStep}
          handleNewAmericanasRefundExchangeCloseModal={
            handleNewAmericanasRefundExchangeCloseModal
          }
          handleStepsNext={handleStepsNext}
          handleStepsPrev={handleStepsPrev}
          items={items}
          openDevolutionModal={openDevolutionModal}
          setOpenDevolutionModal={setOpenDevolutionModal}
          stepsLimit={stepsLimit}
        />
      )}
      {openRATicketDetail && (
        <ModalTicketsDetailReclameAquiIframe
          dataRaToShow={dataRaToShow}
          handleLinkRATicket={handleLinkRATicket}
          openRATicketDetail={openRATicketDetail}
          setOpenRATicketDetail={setOpenRATicketDetail}
        />
      )}
      {openOrderModal && (
        <OrdersModalIframe
          openOrderModal={!!openOrderModal}
          ticketData={ticketData}
          ticketOrders={ticketOrders}
          setOpenOrderModal={setOpenOrderModal}
        />
      )}
      {openTracking && (
        <TrackingModalIframe
          openTracking={openTracking}
          setOpenTracking={setOpenTracking}
        />
      )}
    </TicketContainerContext.Provider>
  );
};

export default TicketDetailsIframe;
