const { default: styled } = require('styled-components');

export const IframeWrapper = styled('div')`
  width: 100%;
  height: 100%;
  zoom: 110%;
  overflow: hidden;
`;

export const StyledIframe = styled('iframe')`
  border: 0px;
  width: 100%;
  height: 100%;
`;
