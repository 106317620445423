import { Card, CardContent, Grid } from '@material-ui/core';
import React from 'react';

import { CardTitle } from '../../styles';
import CardDataWithIcons from '../CardDataWithIcons.component';
import { CustomTypograph } from '../custom.components';
import {
  getB2WScoreHint,
  getReputationCards,
  getTitle,
  infoCards,
  storeLevelList
} from './B2W/cardData';
import ScoreInfoCardB2W from './B2W/ScoreInfoCardB2W';
import {
  fillDonutGraph,
  getChartColors,
  getTitleData,
  getTitleSubContent
} from './functions/commonFunctions';
import MainScoreCard from './components/MainScoreCard';

const B2WDashboard = ({ score, possibleScore, score_b2w }) => {
  const chartData = fillDonutGraph(score, possibleScore);
  const colors = getChartColors(score);
  const totalValue = score;
  const titleData = getTitleData(score, '70px');
  const titleSubContent = getTitleSubContent(possibleScore);

  const currentStoreLevel =
    storeLevelList.find(
      item => item.level === score_b2w?.loyalty_faixa_venda
    ) || storeLevelList[0];

  const reputationCards = getReputationCards(currentStoreLevel, score_b2w);

  const hintText = `Esta foi a nota que você obteve no período de vendas
  selecionado. Quando você fizer novas vendas, a avaliação do seu
  desempenho é gerada automaticamente e a nota será atualizada com
  base nos pedidos feitos nos últimos 30 dias.`;

  return (
    <>
      <CustomTypograph variant="h3">Americanas</CustomTypograph>
      <Card style={{ backgroundColor: 'white', marginTop: '10px' }}>
        <CardTitle>
          <CustomTypograph variant="h5">Nota</CustomTypograph>
        </CardTitle>
        <CardContent style={{ marginTop: '-20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <MainScoreCard
                {...{
                  chartData,
                  colors,
                  totalValue,
                  titleData,
                  titleSubContent,
                  hintText
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <Grid container spacing={2}>
                {infoCards.map(item => (
                  <Grid item xs={4} key={Math.random()}>
                    <ScoreInfoCardB2W
                      title={getTitle(item)}
                      descriptionHint={getB2WScoreHint(item)}
                      type={item}
                      {...{
                        score_b2w,
                        getChartColors,
                        fillDonutGraph,
                        getTitleData,
                        getTitleSubContent
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ backgroundColor: 'white', marginTop: '10px' }}>
        <CardTitle>
          <CustomTypograph variant="h5">Sua reputação</CustomTypograph>
        </CardTitle>
        <CardContent>
          <Grid container spacing={2}>
            {reputationCards.map(item => (
              <Grid item xs={3} key={Math.random()}>
                <CardDataWithIcons
                  title={item?.title}
                  icon={item?.icon}
                  text={item?.text}
                  textBold={item?.textBold}
                  descriptionHint={item?.descriptionHint}
                  sales={item?.sales}
                  shippments={item?.shippments}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default B2WDashboard;
