import React from 'react';

const { SvgIcon, MuiThemeProvider } = require('@material-ui/core');

const BronzeMedal = ({ theme }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <SvgIcon
        viewBox="0 0 38 52"
        width="100"
        height="100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.87939 31.0491L21.2287 34.3581L16.5016 52L11.8464 44.6749L4.15227 48.691L8.87939 31.0491Z"
          fill="#F80032"
        />
        <path
          d="M29.2876 31.0491L16.9383 34.3581L21.6654 52L26.3206 44.6749L34.0147 48.691L29.2876 31.0491Z"
          fill="#C60028"
        />
        <mask
          id="path-3-outside-1_2006_23370"
          maskUnits="userSpaceOnUse"
          x="0.5"
          y="0"
          width="37"
          height="38"
          fill="black"
        >
          <rect fill="white" x="0.5" width="37" height="38" />
          <path d="M0.5 18.2642C0.5 8.17717 8.67717 0 18.7642 0C28.8512 0 37.0284 8.17717 37.0284 18.2642C37.0284 28.3512 28.8512 36.5284 18.7642 36.5284C8.67717 36.5284 0.5 28.3512 0.5 18.2642Z" />
        </mask>
        <g filter="url(#filter0_i_2006_23370)">
          <path
            d="M0.5 18.2642C0.5 8.17717 8.67717 0 18.7642 0C28.8512 0 37.0284 8.17717 37.0284 18.2642C37.0284 28.3512 28.8512 36.5284 18.7642 36.5284C8.67717 36.5284 0.5 28.3512 0.5 18.2642Z"
            fill="#CF7432"
          />
        </g>
        <path
          d="M0.5 0H37.0284H0.5ZM37.0284 19.2642C37.0284 29.3512 28.8512 37.5284 18.7642 37.5284C8.67716 37.5284 0.5 29.3512 0.5 19.2642V18.2642C0.5 27.799 8.67716 35.5284 18.7642 35.5284C28.8512 35.5284 37.0284 27.799 37.0284 18.2642V19.2642ZM0.5 36.5284V0V36.5284ZM37.0284 0V36.5284V0Z"
          fill="#844C24"
          mask="url(#path-3-outside-1_2006_23370)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2847 25.6308H25.4608V28.6139H13.2847V25.6308ZM19.3005 11.9935C20.3568 11.9719 21.4034 12.2151 22.3588 12.7041C23.2501 13.1779 23.6957 14.0718 23.6957 15.3858V20.3884C23.6957 20.7354 23.7021 21.1557 23.7149 21.6493C23.708 21.9138 23.7643 22.1757 23.8784 22.4095C23.984 22.5562 24.1234 22.6701 24.2825 22.7398V23.1601H21.3013C21.2235 22.9604 21.1654 22.7524 21.128 22.5395C21.0952 22.3459 21.0695 22.1257 21.0509 21.8789C20.6668 22.3097 20.226 22.6765 19.7429 22.9672C19.1293 23.3281 18.4405 23.5115 17.7427 23.4999C16.8904 23.5355 16.055 23.2352 15.3909 22.6545C14.7569 22.0539 14.4143 21.1691 14.463 20.2574C14.3921 19.054 14.9745 17.9163 15.9536 17.3456C16.7092 16.9542 17.5247 16.7167 18.3594 16.6451L19.2922 16.5252C19.6628 16.4916 20.028 16.4074 20.3792 16.2748C20.7184 16.1753 20.9571 15.8458 20.9659 15.4646C21.0002 15.087 20.8245 14.7234 20.5187 14.5393C20.1053 14.3446 19.6557 14.2572 19.2058 14.2841C18.7025 14.2277 18.2013 14.4101 17.8311 14.7843C17.6217 15.0739 17.4886 15.4196 17.4461 15.7848H14.7998C14.8231 14.9324 15.0922 14.1095 15.5692 13.4318C16.2935 12.4729 17.5373 11.9935 19.3005 11.9935ZM20.9546 17.857C20.7884 17.9668 20.6128 18.0591 20.4303 18.1324C20.1936 18.219 19.9507 18.2842 19.7041 18.3274L19.0885 18.4472C18.6585 18.5054 18.2397 18.6372 17.8478 18.8377C17.4373 19.073 17.1896 19.5449 17.2124 20.048C17.1796 20.4476 17.3257 20.8401 17.6052 21.1028C17.878 21.3241 18.2118 21.4386 18.5523 21.4277C19.1335 21.4306 19.7018 21.2422 20.1823 20.8875C20.6792 20.5274 20.9373 19.8704 20.9566 18.9165L20.9546 17.857ZM25.4608 7.30568V10.2888H13.2847V7.30568H25.4608Z"
          fill="#FAFAFA"
        />
        <path
          d="M36.2784 18.2642C36.2784 27.937 28.437 35.7784 18.7642 35.7784C9.09138 35.7784 1.25 27.937 1.25 18.2642C1.25 8.59137 9.09137 0.75 18.7642 0.75C28.437 0.75 36.2784 8.59138 36.2784 18.2642Z"
          stroke="#E07D35"
          strokeWidth="1.5"
        />
      </SvgIcon>
    </MuiThemeProvider>
  );
};

const SilverMedal = ({ theme }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <SvgIcon
        viewBox="0 0 38 52"
        width="100"
        height="100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.87988 31.0491L21.2292 34.3581L16.5021 52L11.8468 44.6749L4.15276 48.691L8.87988 31.0491Z"
          fill="#F80032"
        />
        <path
          d="M29.2881 31.0491L16.9388 34.3581L21.6659 52L26.3211 44.6749L34.0152 48.691L29.2881 31.0491Z"
          fill="#C60028"
        />
        <mask
          id="path-3-outside-1_2006_23432"
          maskUnits="userSpaceOnUse"
          x="0.5"
          y="0"
          width="37"
          height="38"
          fill="black"
        >
          <rect fill="white" x="0.5" width="37" height="38" />
          <path d="M0.5 18.2642C0.5 8.17717 8.67717 0 18.7642 0C28.8512 0 37.0284 8.17717 37.0284 18.2642C37.0284 28.3512 28.8512 36.5284 18.7642 36.5284C8.67717 36.5284 0.5 28.3512 0.5 18.2642Z" />
        </mask>
        <g filter="url(#filter0_i_2006_23432)">
          <path
            d="M0.5 18.2642C0.5 8.17717 8.67717 0 18.7642 0C28.8512 0 37.0284 8.17717 37.0284 18.2642C37.0284 28.3512 28.8512 36.5284 18.7642 36.5284C8.67717 36.5284 0.5 28.3512 0.5 18.2642Z"
            fill="#AFB6B8"
          />
        </g>
        <path
          d="M0.5 0H37.0284H0.5ZM37.0284 19.2642C37.0284 29.3512 28.8512 37.5284 18.7642 37.5284C8.67716 37.5284 0.5 29.3512 0.5 19.2642V18.2642C0.5 27.799 8.67716 35.5284 18.7642 35.5284C28.8512 35.5284 37.0284 27.799 37.0284 18.2642V19.2642ZM0.5 36.5284V0V36.5284ZM37.0284 0V36.5284V0Z"
          fill="#757D7D"
          mask="url(#path-3-outside-1_2006_23432)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2852 25.6308H25.4613V28.6139H13.2852V25.6308ZM19.301 11.9935C20.3573 11.9719 21.4038 12.2151 22.3593 12.7041C23.2506 13.1779 23.6962 14.0718 23.6962 15.3858V20.3884C23.6962 20.7354 23.7026 21.1557 23.7154 21.6493C23.7085 21.9138 23.7648 22.1757 23.8789 22.4095C23.9845 22.5562 24.1239 22.6701 24.2829 22.7398V23.1601H21.3018C21.224 22.9604 21.1659 22.7524 21.1284 22.5395C21.0957 22.3459 21.07 22.1257 21.0514 21.8789C20.6673 22.3097 20.2265 22.6765 19.7434 22.9672C19.1298 23.3281 18.441 23.5115 17.7432 23.4999C16.8909 23.5355 16.0555 23.2352 15.3914 22.6545C14.7574 22.0539 14.4148 21.1691 14.4635 20.2574C14.3926 19.054 14.975 17.9163 15.9541 17.3456C16.7097 16.9542 17.5251 16.7167 18.3598 16.6451L19.2927 16.5252C19.6633 16.4916 20.0285 16.4074 20.3797 16.2748C20.7189 16.1753 20.9575 15.8458 20.9664 15.4646C21.0007 15.087 20.825 14.7234 20.5191 14.5393C20.1058 14.3446 19.6562 14.2572 19.2063 14.2841C18.703 14.2277 18.2017 14.4101 17.8316 14.7843C17.6222 15.0739 17.4891 15.4196 17.4466 15.7848H14.8003C14.8236 14.9324 15.0926 14.1095 15.5696 13.4318C16.294 12.4729 17.5378 11.9935 19.301 11.9935ZM20.9551 17.857C20.7889 17.9668 20.6133 18.0591 20.4308 18.1324C20.1941 18.219 19.9512 18.2842 19.7046 18.3274L19.0889 18.4472C18.659 18.5054 18.2402 18.6372 17.8482 18.8377C17.4378 19.073 17.1901 19.5449 17.2129 20.048C17.1801 20.4476 17.3262 20.8401 17.6057 21.1028C17.8785 21.3241 18.2123 21.4386 18.5528 21.4277C19.134 21.4306 19.7023 21.2422 20.1828 20.8875C20.6797 20.5274 20.9378 19.8704 20.9571 18.9165L20.9551 17.857ZM25.4613 7.30568V10.2888H13.2852V7.30568H25.4613Z"
          fill="#FAFAFA"
        />
        <path
          d="M36.2784 18.2642C36.2784 27.937 28.437 35.7784 18.7642 35.7784C9.09138 35.7784 1.25 27.937 1.25 18.2642C1.25 8.59137 9.09137 0.75 18.7642 0.75C28.437 0.75 36.2784 8.59138 36.2784 18.2642Z"
          stroke="#A6AEAE"
          strokeWidth="1.5"
        />
      </SvgIcon>
    </MuiThemeProvider>
  );
};

const GoldMedal = ({ theme }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <SvgIcon
        viewBox="0 0 38 52"
        width="100"
        height="100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.87988 31.0492L21.2292 34.3581L16.5021 52L11.8468 44.6749L4.15276 48.691L8.87988 31.0492Z"
          fill="#F80032"
        />
        <path
          d="M29.2881 31.0492L16.9388 34.3581L21.6659 52L26.3211 44.6749L34.0152 48.691L29.2881 31.0492Z"
          fill="#C60028"
        />
        <mask
          id="path-3-outside-1_2006_23379"
          maskUnits="userSpaceOnUse"
          x="0.5"
          y="0"
          width="37"
          height="38"
          fill="black"
        >
          <rect fill="white" x="0.5" width="37" height="38" />
          <path d="M0.5 18.2642C0.5 8.17717 8.67717 0 18.7642 0C28.8512 0 37.0284 8.17717 37.0284 18.2642C37.0284 28.3512 28.8512 36.5284 18.7642 36.5284C8.67717 36.5284 0.5 28.3512 0.5 18.2642Z" />
        </mask>
        <g filter="url(#filter0_i_2006_23379)">
          <path
            d="M0.5 18.2642C0.5 8.17717 8.67717 0 18.7642 0C28.8512 0 37.0284 8.17717 37.0284 18.2642C37.0284 28.3512 28.8512 36.5284 18.7642 36.5284C8.67717 36.5284 0.5 28.3512 0.5 18.2642Z"
            fill="#E3C513"
          />
        </g>
        <path
          d="M0.5 0H37.0284H0.5ZM37.0284 19.2642C37.0284 29.3512 28.8512 37.5284 18.7642 37.5284C8.67716 37.5284 0.5 29.3512 0.5 19.2642V18.2642C0.5 27.799 8.67716 35.5284 18.7642 35.5284C28.8512 35.5284 37.0284 27.799 37.0284 18.2642V19.2642ZM0.5 36.5284V0V36.5284ZM37.0284 0V36.5284V0Z"
          fill="#BFA406"
          mask="url(#path-3-outside-1_2006_23379)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2852 25.6308H25.4613V28.6139H13.2852V25.6308ZM19.301 11.9935C20.3573 11.9719 21.4038 12.2151 22.3593 12.7041C23.2506 13.1779 23.6962 14.0718 23.6962 15.3858V20.3884C23.6962 20.7354 23.7026 21.1557 23.7154 21.6493C23.7085 21.9138 23.7648 22.1757 23.8789 22.4095C23.9845 22.5562 24.1239 22.6701 24.2829 22.7398V23.1601H21.3018C21.224 22.9604 21.1659 22.7524 21.1284 22.5395C21.0957 22.3459 21.07 22.1257 21.0514 21.8789C20.6673 22.3097 20.2265 22.6765 19.7434 22.9672C19.1298 23.3281 18.441 23.5115 17.7432 23.4999C16.8909 23.5355 16.0555 23.2352 15.3914 22.6545C14.7574 22.0539 14.4148 21.1691 14.4635 20.2574C14.3926 19.054 14.975 17.9163 15.9541 17.3456C16.7097 16.9542 17.5251 16.7167 18.3598 16.6451L19.2927 16.5252C19.6633 16.4916 20.0285 16.4074 20.3797 16.2748C20.7189 16.1753 20.9575 15.8458 20.9664 15.4646C21.0007 15.087 20.825 14.7234 20.5191 14.5393C20.1058 14.3446 19.6562 14.2572 19.2063 14.2841C18.703 14.2277 18.2017 14.4101 17.8316 14.7843C17.6222 15.0739 17.4891 15.4196 17.4466 15.7848H14.8003C14.8236 14.9324 15.0926 14.1095 15.5696 13.4318C16.294 12.4729 17.5378 11.9935 19.301 11.9935ZM20.9551 17.857C20.7889 17.9668 20.6133 18.0591 20.4308 18.1324C20.1941 18.219 19.9512 18.2842 19.7046 18.3274L19.0889 18.4472C18.659 18.5054 18.2402 18.6372 17.8482 18.8377C17.4378 19.073 17.1901 19.5449 17.2129 20.048C17.1801 20.4476 17.3262 20.8401 17.6057 21.1028C17.8785 21.3241 18.2123 21.4386 18.5528 21.4277C19.134 21.4306 19.7023 21.2422 20.1828 20.8875C20.6797 20.5274 20.9378 19.8704 20.9571 18.9165L20.9551 17.857ZM25.4613 7.30568V10.2888H13.2852V7.30568H25.4613Z"
          fill="#FAFAFA"
        />
        <path
          d="M36.2784 18.2642C36.2784 27.937 28.437 35.7784 18.7642 35.7784C9.09138 35.7784 1.25 27.937 1.25 18.2642C1.25 8.59137 9.09137 0.75 18.7642 0.75C28.437 0.75 36.2784 8.59138 36.2784 18.2642Z"
          stroke="#EFCE10"
          strokeWidth="1.5"
        />
      </SvgIcon>
    </MuiThemeProvider>
  );
};

const DiamondMedal = ({ theme }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <SvgIcon
        viewBox="0 0 38 52"
        width="100"
        height="100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.1276 7.87244H26.8723L34.3511 21.2554L26.8723 34.6386H11.1276L3.64893 21.2554L11.1276 7.87244Z"
          fill="#F80032"
        />
        <path
          d="M3.7062 21.2554L11.157 7.92244H26.843L34.2938 21.2554L26.843 34.5886H11.157L3.7062 21.2554Z"
          stroke="white"
          strokeOpacity="0.45"
          strokeWidth="0.1"
        />
        <path
          d="M19.0012 0L26.8735 7.87234H11.1289L19.0012 0Z"
          fill="#C60028"
        />
        <path
          d="M0.5 11.0214L11.1277 7.87244L3.64895 21.2555L0.5 11.0214Z"
          fill="#C60028"
        />
        <path
          d="M19.0012 42.5107L26.8735 34.6386H11.1289L19.0012 42.5107Z"
          fill="#C60028"
        />
        <path
          d="M37.5 31.4895L26.8723 34.6385L34.3511 21.2554L37.5 31.4895Z"
          fill="#C60028"
        />
        <path d="M19 0L11.1277 7.87234L0.5 11.0213L19 0Z" fill="#A2122F" />
        <path
          d="M19 42.5107L11.1277 34.6386L0.5 31.4896L19 42.5107Z"
          fill="#A2122F"
        />
        <path
          d="M0.5 11.0212L3.64894 21.2552L0.5 31.4893V11.0212Z"
          fill="#A2122F"
        />
        <path d="M19 0L26.8723 7.87234L37.5 11.0213L19 0Z" fill="#A2122F" />
        <path
          d="M19 42.5107L26.8723 34.6386L37.5 31.4896L19 42.5107Z"
          fill="#A2122F"
        />
        <path
          d="M37.5 11.0212L34.3511 21.2552L37.5 31.4893V11.0212Z"
          fill="#A2122F"
        />
        <rect
          x="31.2959"
          y="15.4466"
          width="6.19376"
          height="27.3268"
          transform="rotate(60 31.2959 15.4466)"
          fill="white"
          fillOpacity="0.3"
        />
        <rect
          x="28.6963"
          y="11.1"
          width="1.55338"
          height="28.2855"
          transform="rotate(60 28.6963 11.1)"
          fill="white"
          fillOpacity="0.3"
        />
        <path
          d="M0.5 31.4895L11.1277 34.6385L3.64895 21.2554L0.5 31.4895Z"
          fill="#C60028"
        />
        <path
          d="M37.5 11.0214L26.8723 7.87244L34.3511 21.2555L37.5 11.0214Z"
          fill="#C60028"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4502 28.601H24.5502V31.45H13.4502V28.601ZM18.9344 15.577C19.8973 15.5564 20.8513 15.7887 21.7224 16.2557C22.5349 16.7081 22.9411 17.5618 22.9411 18.8167V23.5944C22.9411 23.9258 22.947 24.3272 22.9586 24.7986C22.9523 25.0512 23.0037 25.3013 23.1076 25.5246C23.2039 25.6647 23.331 25.7735 23.476 25.84V26.2414H20.7583C20.6874 26.0507 20.6344 25.8521 20.6003 25.6487C20.5704 25.4639 20.547 25.2536 20.53 25.0179C20.1799 25.4293 19.7781 25.7796 19.3377 26.0572C18.7783 26.4018 18.1504 26.577 17.5142 26.566C16.7373 26.6 15.9757 26.3132 15.3703 25.7586C14.7923 25.185 14.48 24.3399 14.5244 23.4692C14.4598 22.32 14.9907 21.2334 15.8832 20.6884C16.5721 20.3146 17.3154 20.0878 18.0764 20.0194L18.9268 19.9049C19.2646 19.8728 19.5975 19.7924 19.9177 19.6658C20.227 19.5707 20.4445 19.256 20.4526 18.892C20.4839 18.5314 20.3236 18.1841 20.0448 18.0083C19.668 17.8224 19.2582 17.7389 18.848 17.7646C18.3892 17.7107 17.9322 17.8849 17.5948 18.2423C17.4039 18.5189 17.2826 18.8491 17.2439 19.1978H14.8314C14.8526 18.3838 15.0979 17.5978 15.5328 16.9506C16.1931 16.0349 17.327 15.577 18.9344 15.577ZM20.4423 21.1768C20.2908 21.2817 20.1307 21.3698 19.9643 21.4398C19.7485 21.5225 19.5271 21.5848 19.3023 21.626L18.741 21.7405C18.3491 21.7961 17.9673 21.9219 17.61 22.1134C17.2358 22.3381 17.01 22.7888 17.0308 23.2693C17.0009 23.651 17.1341 24.0257 17.3889 24.2766C17.6376 24.488 17.9419 24.5974 18.2523 24.587C18.7821 24.5897 19.3002 24.4098 19.7382 24.0711C20.1912 23.7272 20.4265 23.0997 20.4441 22.1887L20.4423 21.1768ZM24.5502 11.1V13.949H13.4502V11.1H24.5502Z"
          fill="white"
        />
      </SvgIcon>
    </MuiThemeProvider>
  );
};

export { BronzeMedal, SilverMedal, GoldMedal, DiamondMedal };
