import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../../styles/settings/colors.config';
import ChatProtocolCardDataSideBySide from '../../components/ChatProtocolCardDataSideBySide.component';

const CurrentOpenedProtocolsCard = ({
  isRealTime,
  isAdmin,
  handleIsRealTime,
  dataEquipDash,
  dataIndividualDash
}) => {
  const { t } = useTranslation();
  return (
    <ChatProtocolCardDataSideBySide
      isRealTime={isRealTime}
      cardType="openedProtocols"
      firstLabel={t('i18n.dashboard.components.individual')}
      secondaryLabel={t('i18n.dashboard.components.equip')}
      isTeamView={isAdmin}
      headerData
      showMoreDetails
      firstData={handleIsRealTime(
        dataIndividualDash?.abertos,
        dataIndividualDash?.abertos
      )}
      secondaryData={handleIsRealTime(
        dataEquipDash?.abertos_geral,
        dataEquipDash?.abertos_geral
      )}
      descriptionHint={
        isRealTime
          ? t('i18n.dashboard.components.hint.real_time')
          : t('i18n.dashboard.components.hint.open_in_period')
      }
      // downloadProcotols={() => {
      //   downloadProcotols('download-opened-protocol-bi');
      // }}

      extraData={[
        {
          label: t('i18n.ticketcontainer.NEW'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.novos_geral
            : dataIndividualDash?.novos,
          percent: isAdmin
            ? dataEquipDash?.novos_geral / dataEquipDash?.abertos_geral
            : dataIndividualDash?.novos / dataIndividualDash?.abertos,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.CUSTOMER_RETURN'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.devolutiva_cliente_geral
            : dataIndividualDash?.devolutiva_cliente,
          percent: isAdmin
            ? dataEquipDash?.devolutiva_cliente_geral / dataEquipDash?.abertos_geral
            : dataIndividualDash?.devolutiva_cliente / dataIndividualDash?.abertos,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.RESOLVED'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.resolvidos_geral
            : dataIndividualDash?.resolvidos,
          percent: isAdmin
            ? dataEquipDash?.resolvidos_geral / dataEquipDash?.abertos_geral
            : dataIndividualDash?.resolvidos / dataIndividualDash?.abertos,
          divider: false,
          show: !isRealTime
        },
        {
          label: t('i18n.ticketcontainer.CLOSED'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.fechados_geral_ref_open
            : dataIndividualDash?.fechados_ref_open,
          percent: isAdmin
            ? dataEquipDash?.fechados_geral_ref_open /
            dataEquipDash?.abertos_geral
            : dataIndividualDash?.fechados_ref_open /
            dataIndividualDash?.abertos,
          divider: false,
          show: !isRealTime
        },
        {
          label: t('i18n.ticketcontainer.AWAITING'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.aguardando_atendimento_geral
            : dataIndividualDash?.aguardando_atendimento,
          percent: isAdmin
            ? dataEquipDash?.aguardando_atendimento_geral /
            dataEquipDash?.abertos_geral
            : dataIndividualDash?.aguardando_atendimento /
            dataIndividualDash?.abertos,
          goal: 1.5,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.ANSWERED'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.aguardando_cliente_geral
            : dataIndividualDash?.aguardando_cliente,
          percent: isAdmin
            ? dataEquipDash?.aguardando_cliente_geral /
            dataEquipDash?.abertos_geral
            : dataIndividualDash?.aguardando_cliente /
            dataIndividualDash?.abertos,
          goal: 10,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.AWAITING_COMMERCIAL'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.aguardando_comercial_geral
            : dataIndividualDash?.aguardando_comercial,
          percent: isAdmin
            ? dataEquipDash?.aguardando_comercial_geral /
            dataEquipDash?.abertos_geral
            : dataIndividualDash?.aguardando_comercial /
            dataIndividualDash?.abertos,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.AWAITING_REFUND'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.aguardando_estorno_geral
            : dataIndividualDash?.aguardando_estorno,
          percent: isAdmin
            ? dataEquipDash?.aguardando_estorno_geral /
            dataEquipDash?.abertos_geral
            : dataIndividualDash?.aguardando_estorno /
            dataIndividualDash?.abertos,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.AWAITING_CLOSED'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.aguardando_fechamento_geral || 0
            : dataIndividualDash?.aguardando_fechamento || 0,
          percent: isAdmin
            ? dataEquipDash?.aguardando_fechamento_geral /
            dataEquipDash?.abertos_geral
            : dataIndividualDash?.aguardando_fechamento /
            dataIndividualDash?.abertos,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.AWAITING_FISCAL'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.aguardando_fiscal_geral
            : dataIndividualDash?.aguardando_fiscal,
          percent: isAdmin
            ? dataEquipDash?.aguardando_fiscal_geral /
            dataEquipDash?.abertos_geral
            : dataIndividualDash?.aguardando_fiscal /
            dataIndividualDash?.abertos,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.AWAITING_FINANCIAL'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.aguardando_financeiro_geral
            : dataIndividualDash?.aguardando_financeiro,
          percent: isAdmin
            ? dataEquipDash?.aguardando_financeiro_geral /
            dataEquipDash?.abertos_geral
            : dataIndividualDash?.aguardando_financeiro /
            dataIndividualDash?.abertos,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.AWAITING_LOGISTICS'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.aguardando_logistica_geral
            : dataIndividualDash?.aguardando_logistica,
          percent: isAdmin
            ? dataEquipDash?.aguardando_logistica_geral /
            dataEquipDash?.abertos_geral
            : dataIndividualDash?.aguardando_logistica /
            dataIndividualDash?.abertos,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.AWAITING_MARKETING'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.aguardando_marketing_geral
            : dataIndividualDash?.aguardando_marketing,
          percent: isAdmin
            ? dataEquipDash?.aguardando_marketing_geral /
            dataEquipDash?.abertos_geral
            : dataIndividualDash?.aguardando_marketing /
            dataIndividualDash?.abertos,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.AWAITING_PARTNER'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.aguardando_marketplace_geral
            : dataIndividualDash?.aguardando_marketplace,
          percent: isAdmin
            ? dataEquipDash?.aguardando_marketplace_geral /
            dataEquipDash?.abertos_geral
            : dataIndividualDash?.aguardando_marketplace /
            dataIndividualDash?.abertos,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.AWAITING_CLIENT_POST'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.aguardando_postagem_cliente_geral
            : dataIndividualDash?.aguardando_postagem_cliente,
          percent: isAdmin
            ? dataEquipDash?.aguardando_postagem_cliente_geral /
            dataEquipDash?.abertos_geral
            : dataIndividualDash?.aguardando_postagem_cliente /
            dataIndividualDash?.abertos,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.AWAITING_RA'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.aguardando_reclame_aqui_geral
            : dataIndividualDash?.aguardando_reclame_aqui,
          percent: isAdmin
            ? dataEquipDash?.aguardando_reclame_aqui_geral /
            dataEquipDash?.abertos_geral
            : dataIndividualDash?.aguardando_reclame_aqui /
            dataIndividualDash?.abertos,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.AWAITING_TI'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.aguardando_ti_geral
            : dataIndividualDash?.aguardando_ti,
          percent: isAdmin
            ? dataEquipDash?.aguardando_ti_geral /
            dataEquipDash?.abertos_geral
            : dataIndividualDash?.aguardando_ti /
            dataIndividualDash?.abertos,
          divider: false,
          show: true
        }
      ]}
    />
  );
};

export default CurrentOpenedProtocolsCard;
