import React, { useState, useEffect } from 'react';
import { CardContent, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';

import { MessageItem } from './components/MessageItem.component';

import API from '../../../services/api';

const MessagesBox = styled('div')`
  min-height: 350px;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0px 0px;
  border: 0px solid #ccc;
  background-color: #eee;
  padding: 10px;
  @media (min-width: 1400px) {
    min-height: 500px;
    max-height: 500px;
  }
`;
let getTalk;

const ChatWrapper = ({ socket, clientInAttendance, attendantMessage }) => {
  let subscribe;

  const { enqueueSnackbar } = useSnackbar();
  const reduxDispatcher = useDispatch();

  const showHistoric = useSelector(state => state.showHistoricChatReducer);
  const showVisitors = useSelector(state => state.showVisitorsReducer);
  const showClientTalk = useSelector(state => state.showClientTalkReducer);

  const [clientTalk, setClientTalk] = useState([]);
  const [newMessage, setNewMessage] = useState({});
  const [clientPulled, setClientPulled] = useState({});
  const [showMessageError, setShowMessageError] = useState(false);
  const [userIsTyping, setUserIsTyping] = useState(false);

  const gotoBottom = () => {
    const element = document.getElementById('message-box');
    setTimeout(() => {
      if (element) {
        element.scrollTop = element.scrollHeight - element.clientHeight;
      }
    }, 200);
  };

  const initSubscribeSocket = () => {
    subscribe = socket?.newchatSubject.subscribe(value => {
      const response = JSON.parse(value.data);

      if (response?.system?.action === 'OUT') {
        // Quando um atendente puxa um atendimento
        // de outro atendente
        setClientPulled(response.system);
      }

      if (response?.message && response?.message === 'Internal server error') {
        // Quando o cliente fecha a aba do navegador e o atendente manda mesnagem
        setShowMessageError(true);
        enqueueSnackbar(
          'O cliente esta se reconectando ou encerrou o chat. Envie uma nova mensagem em alguns instantes.',
          {
            variant: 'warning',
            preventDuplicate: true
          }
        );
      }

      if (response?.message === 'Typing') {
        setUserIsTyping(true);
      }

      if (response?.message === 'Typing off') {
        setUserIsTyping(false);
      }

      if (
        response?.message &&
        response?.message !== 'Forbidden' &&
        response?.message !== 'Internal server error' &&
        response?.message !== 'Typing'
      ) {
        setNewMessage(response);
      }
    });
  };

  const handleGetClientTalk = async client => {
    if (client?.hashConnection) {
      const clientHistory = await API.get(
        `no-auth/ms-chat/existing-chat/${client.hashConnection}`
      );
      reduxDispatcher({
        type: 'SET_CLIENT_IN_ATTENDANCE',
        clientInAttendanceData: clientHistory.data
      });

      setClientTalk(clientHistory.data.messages);
      gotoBottom();
    }
  };

  useEffect(() => {
    if (
      newMessage.hashConnection === clientInAttendance.hashConnection &&
      !showHistoric
    ) {
      setClientTalk([...clientTalk, ...[newMessage]]);
      gotoBottom();
    }
  }, [newMessage, clientInAttendance]);

  useEffect(() => {
    if (clientInAttendance.hashConnection === clientPulled.hashConnection) {
      setClientTalk([]);
      reduxDispatcher({ type: 'SHOW_CHAT_HISTORIC', show: true });
    }
  }, [clientPulled]);

  useEffect(() => {
    if (showMessageError && clientTalk[clientTalk.length - 1]?.login) {
      clientTalk[clientTalk.length - 1].error = true;
      setClientTalk(clientTalk);
    }

    if (showMessageError) {
      setShowMessageError(false);
    }
  }, [showMessageError, clientTalk]);

  useEffect(() => {
    setClientTalk([]);
    if (clientInAttendance.hashConnection) {
      handleGetClientTalk(clientInAttendance);
    }
  }, [clientInAttendance]);

  useEffect(() => {
    const show = showHistoric;
    clearInterval(getTalk);

    if (show) {
      setClientTalk([]);
      handleGetClientTalk(clientInAttendance);

      getTalk = setInterval(() => {
        handleGetClientTalk(clientInAttendance);
      }, 10000);
    }

    if (!show) {
      clearInterval(getTalk);
    }
  }, [showHistoric, clientInAttendance]);

  useEffect(() => {
    if (attendantMessage?.login) {
      setClientTalk([...clientTalk, ...[attendantMessage]]);
    }
  }, [attendantMessage]);

  useEffect(() => {
    if (socket) {
      initSubscribeSocket();
    }
  }, [socket]);

  useEffect(() => {
    if (showVisitors) {
      setClientTalk([]);
    } else {
      initSubscribeSocket();
    }
  }, [showVisitors]);

  useEffect(() => {
    if (subscribe) {
      subscribe.unsubscribe();
    }
  }, []);

  return (
    <MessagesBox width="100%" id="message-box">
      <CardContent>
        {showClientTalk &&
          clientTalk?.map(item => (
            <MessageItem
              key={Math.random()}
              name={item.name}
              email={item.email}
              message={item.text || item.message}
              hour={item.date}
              login={item.login}
              isAttendant={!(item.name || item.email)}
              nameAttachment={item.name_attachment}
              error={item.error}
              urlRaw={item.url_anexo_raw}
            />
          ))}
        {userIsTyping && (
          <Typography size="small" style={{ color: '#666' }}>
            Usuário está digitando...
          </Typography>
        )}
      </CardContent>
    </MessagesBox>
  );
};

export { ChatWrapper };
