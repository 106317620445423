/* eslint-disable no-nested-ternary */
import { Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import timezoneFormat from '../../../../utils/timezoneFormat';
import mlOrderStatusTranslate from '../../../../utils/mlOrderStatusTranslate';
import {
  SectionContent,
  SectionContentTitle,
  SectionTitleWarning,
  SectionContentWrapper,
  SectionTabs,
  SectionText,
  SectionTitle,
  SmallButton
} from './SectionTabs';
import marketPlacesEnum from '../../../../enums/marketPlaces.enum';

const PaymentTabDetails = ({
  handleSearchOrder,
  orderData,
  handleClickOpenInvoice,
  data,
  aftersaleMlWithoutOrder
}) => {
  const { t } = useTranslation();

  const orderFromMarketplaceBundle = data.marketplaceBundle;
  const paymentsList =
    orderFromMarketplaceBundle?.order_details?.payment?.methods;

  const dataCriacaoFormatada =
    orderData?.dataCriacao &&
    moment(orderData?.dataCriacao).format('DD/MM/YYYY');

  const dataCriacaoFormatadaML =
    orderFromMarketplaceBundle?.order?.date_created &&
    moment(orderFromMarketplaceBundle?.order?.date_created).format(
      'DD/MM/YYYY'
    );

  const dataNFFormatada = moment(orderData?.dataNF).format('DD/MM/YYYY');

  const paymentApprovedFromBundle = orderFromMarketplaceBundle?.dataOrders?.timeline?.find(
    item => item.category === 'APR'
  );

  const loadMLCancelDetail = () => {
    if (
      orderFromMarketplaceBundle?.order?.tags &&
      !orderFromMarketplaceBundle?.order?.cancel_detail
    ) {
      const fraudSearched = orderFromMarketplaceBundle?.order?.tags.find(
        item => {
          return item === 'fraud_risk_detected';
        }
      );
      if (fraudSearched) {
        return 'Detectamos irregularidades na conta do comprador';
      }
    }
    if (orderFromMarketplaceBundle?.order?.cancel_detail) {
      switch (orderFromMarketplaceBundle?.order?.cancel_detail?.description) {
        case 'Mediations cancel the order':
          return 'O comprador não poderá reiniciar uma reclamação por esta venda';
        case 'A fraud tag cancel the order':
          return 'Foi necessário cancelá-lo porque encontramos irregularidades';
        case 'The shipment was not delivered':
          return 'Te devolvemos o pacote, pois não conseguimos entregá-lo ao comprador';
        case 'There is a mediation with status cancel_purchase':
          return 'Cancelada pelo comprador';
        case 'Item is closed because its not active or its expired':
          return 'O item está fechado ou expirou';
        case 'There is a feedback from seller with the reason buyer_regrets':
          return 'Reclamação encerrada com reembolso para o comprador';
        case 'There is a feedback from seller with the reason dubious_buyer':
          return 'Você cancelou a venda';
        case undefined:
          return '-';
        default:
          return orderFromMarketplaceBundle?.order?.cancel_detail?.description;
      }
    }
  };

  return (
    <>
      <SectionTabs>
        <SectionTitle>
          {t('i18n.ticketcontainer.PAYMENT_DATA')}
          {data.marketPlace === 'MERCADO_LIVRE' && aftersaleMlWithoutOrder && (
            <SectionTitleWarning>
              {`* `}
              {t('i18n.ticketcontainer.ML_ORDER_WARNING')}
            </SectionTitleWarning>
          )}
        </SectionTitle>
        {/* N. do Pedido e Situação */}
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.ORDER_NUMBER')}:
              </SectionContentTitle>
              <SectionContent>
                {data?.marketPlace === marketPlacesEnum.B2W && (
                  <SectionText bold>
                    {data?.marketplaceBundle?.order_id ||
                      t('i18n.orderinfo.NOT_PROVIDED')}{' '}
                    {handleSearchOrder()}
                  </SectionText>
                )}
                {data?.marketPlace !== marketPlacesEnum.B2W && (
                  <SectionText bold>
                    {(orderData?.idPedMarket === null && data.orderId) ||
                      orderData?.idPedMarket ||
                      orderFromMarketplaceBundle?.order?.id ||
                      t('i18n.orderinfo.NOT_PROVIDED')}{' '}
                    {handleSearchOrder()}
                  </SectionText>
                )}
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={6} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.PAYMENT_STATUS')}:
              </SectionContentTitle>
              <SectionContent>
                {data.marketPlace === 'MERCADO_LIVRE' ? (
                  <>
                    <SectionText bold>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {orderData?.situacao
                        ? orderData?.situacao
                        : orderFromMarketplaceBundle?.order?.status
                        ? mlOrderStatusTranslate(
                            orderFromMarketplaceBundle?.order?.status
                          )
                        : t('i18n.orderinfo.NOT_PROVIDED')}
                      {orderFromMarketplaceBundle?.order?.cancel_detail?.date &&
                        ` ${t('i18n.simplewords.ON')} ${timezoneFormat(
                          orderFromMarketplaceBundle?.order?.cancel_detail
                            ?.date,
                          'DD/MM/YYYY'
                        )}`}
                    </SectionText>
                    <SectionText fSize="12px">
                      {loadMLCancelDetail()}
                    </SectionText>
                  </>
                ) : (
                  <SectionText bold>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {orderFromMarketplaceBundle?.dataOrders?.status?.description
                      ? orderFromMarketplaceBundle?.dataOrders?.status
                          ?.description
                      : orderFromMarketplaceBundle?.statusRealDoPedido
                      ? orderFromMarketplaceBundle?.statusRealDoPedido
                      : orderData?.situacao
                      ? orderData?.situacao
                      : t('i18n.orderinfo.NOT_PROVIDED')}
                  </SectionText>
                )}
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
        </Grid>
        {/* Inclusão e Pagamento */}
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.ACCOMPLISHED_IN')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {dataCriacaoFormatada ||
                    dataCriacaoFormatadaML ||
                    t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          {paymentApprovedFromBundle && (
            <Grid item lg={6} xs={12}>
              <SectionContentWrapper>
                <SectionContentTitle>
                  {t('i18n.ticketcontainer.PAYMENT_DATE')}:
                </SectionContentTitle>
                <SectionContent>
                  <SectionText bold>
                    {moment(paymentApprovedFromBundle?.date).format(
                      'DD/MM/YYYY'
                    )}
                  </SectionText>
                </SectionContent>
              </SectionContentWrapper>
            </Grid>
          )}
        </Grid>

        {/* N. Pedido E-commerce e PEDECOM */}
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            {' '}
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.ORDER_NUMBER')}{' '}
                {t('i18n.ticketcontainer.ECOMMERCE')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {orderData?.idMarketplace
                    ? orderData?.idMarketplace
                    : t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={6} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>PEDECOM:</SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {orderData?.idPedEcom || t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
        </Grid>
        {/* Pedido Sales Order(todos Mktps exceto ML) e N. Entrega(apenas B2W) */}
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.orderinfo.ORDER')} Sales Order:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {orderData?.idPedidoERP || t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          {data?.marketPlace === marketPlacesEnum.B2W && (
            <Grid item lg={6} xs={12}>
              <SectionContentWrapper>
                <SectionContentTitle>
                  {t('i18n.ticketcontainer.DELIVERY_NUMBER')}
                </SectionContentTitle>
                <SectionContent>
                  <SectionText bold>
                    {data?.marketplaceBundle?.delivery_id
                      ? data?.marketplaceBundle?.delivery_id
                      : t('i18n.orderinfo.NOT_PROVIDED')}
                  </SectionText>
                </SectionContent>
              </SectionContentWrapper>
            </Grid>
          )}
        </Grid>
      </SectionTabs>

      <SectionTabs>
        <SectionTitle>{t('i18n.ticketcontainer.NF_DATA')}</SectionTitle>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.NF_NUMBER')}
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {orderData?.numeroNF || t('i18n.orderinfo.NOT_PROVIDED')}{' '}
                  {/* {handleSearchNF()} */}
                  <SmallButton
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleClickOpenInvoice}
                  >
                    <SearchIcon />
                  </SmallButton>
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={6} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.DISPATCHED_IN')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText bold>
                  {orderData?.dataNF
                    ? dataNFFormatada
                    : t('i18n.orderinfo.NOT_PROVIDED')}
                </SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
        </Grid>
      </SectionTabs>

      <SectionTabs>
        <SectionTitle>{t('i18n.ticketcontainer.DATA_PAYMENT')}</SectionTitle>

        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.PAYMENT_TYPE')}:
              </SectionContentTitle>
              <SectionContent>
                {orderData?.pagamentos?.map(pagamento => {
                  return (
                    <SectionText
                      mb
                      bold
                      key={`${pagamento.bandeira}${pagamento.parcelas}${pagamento.valorPago}`}
                    >
                      {' '}
                      {data?.marketplaceBundle?.paymentForm ||
                        pagamento.metodo ||
                        t('i18n.orderinfo.NOT_PROVIDED')}
                    </SectionText>
                  );
                })}
                {(!orderData?.pagamentos ||
                  orderData?.pagamentos?.length === 0) &&
                  data.marketPlace !== 'MERCADO_LIVRE' &&
                  paymentsList?.map(payment => (
                    <SectionText>
                      {payment.description || t('i18n.orderinfo.NOT_PROVIDED')}
                    </SectionText>
                  ))}
                {(!orderData?.pagamentos ||
                  orderData?.pagamentos?.length === 0) &&
                  orderFromMarketplaceBundle?.order?.payments?.map(payment => {
                    return (
                      <SectionText mb bold key={payment.id}>
                        {' '}
                        {payment.payment_method_id ||
                          t('i18n.orderinfo.NOT_PROVIDED')}
                      </SectionText>
                    );
                  })}
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={6} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.AMOUNT_PARCEL')}:
              </SectionContentTitle>
              <SectionContent>
                {orderData?.pagamentos?.map(pagamento => {
                  return (
                    <SectionText mb bold key={pagamento.parcelas}>
                      {' '}
                      {pagamento.parcelas === 1
                        ? `${pagamento.parcelas} parcela`
                        : `${pagamento.parcelas} parcelas` ||
                          t('i18n.orderinfo.NOT_PROVIDED')}
                    </SectionText>
                  );
                })}
                {(!orderData?.pagamentos ||
                  orderData?.pagamentos?.length === 0) &&
                  data.marketPlace !== 'MERCADO_LIVRE' && (
                    <SectionText>
                      {t('i18n.orderinfo.NOT_PROVIDED')}
                    </SectionText>
                  )}
                {(!orderData?.pagamentos ||
                  orderData?.pagamentos?.length === 0) &&
                  orderFromMarketplaceBundle?.order?.payments?.map(payment => {
                    return (
                      <SectionText mb bold key={payment.id}>
                        {' '}
                        {payment.installments === 1
                          ? `${payment.installments} parcela`
                          : `${payment.installments} parcelas` ||
                            t('i18n.orderinfo.NOT_PROVIDED')}
                      </SectionText>
                    );
                  })}
                <SectionText bold>{}</SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          {aftersaleMlWithoutOrder && data.marketPlace === 'MERCADO_LIVRE' && (
            <Grid item lg={6} xs={12}>
              <SectionContentWrapper>
                <SectionContentTitle>
                  {t('i18n.ticketcontainer.PAYMENT_STATUS')}:
                </SectionContentTitle>
                <SectionContent>
                  {orderFromMarketplaceBundle?.order?.payments?.map(payment => {
                    return (
                      <SectionText mb bold key={payment.id}>
                        {' '}
                        {mlOrderStatusTranslate(payment.status) ||
                          t('i18n.orderinfo.NOT_PROVIDED')}
                      </SectionText>
                    );
                  })}
                </SectionContent>
              </SectionContentWrapper>
            </Grid>
          )}
        </Grid>
      </SectionTabs>
    </>
  );
};

export default PaymentTabDetails;
