import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  TableFooter,
  TableRow,
  TableCell,
  Button,
  TablePagination,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { InConstruction } from '../../views/dashboardV2/styles';
import { COLORS } from '../settings/colors.config';

const CustomTablePagination = styled(TablePagination)`
  width: 100%;
  .MuiTablePagination-spacer {
    display: none;
  }
`;
const CustomTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    color: ${COLORS.greenText};
  }
`;

const CustomTableFooter = styled(TableFooter)`
  display: grid;
`;

const CustomTableFooterButton = styled(Button)`
  text-transform: none;
`;

const useStyles = makeStyles(() => ({
  root: {
    flexShrink: 0,
    marginLeft: 'auto',
    display: 'flex'
  },
  pageSelection: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row',
    marginBottom: '0.1em',
    marginLeft: '2em',
    marginRight: '2em'
  },
  pageInput: {
    width: '70px',
    marginLeft: '10px',
    marginRight: '10px',
    color: 'red'
  },
  tableCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 12px 0px 12px'
  },
  textfieldClass: {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none'
      }
    }
  }
}));

const TablePaginationActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
  show
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const previousPageDisabled = !show ? true : page === 0;
  const lastPageNumber = Math.ceil(count / rowsPerPage);
  const nextPageDisabled = !show ? true : page >= lastPageNumber - 1;

  const [localPage, setLocalPage] = useState(0);

  useEffect(() => {
    setLocalPage(count === 0 ? page : page + 1);
  }, []);

  return (
    <div className={classes.root}>
      <CustomTableFooterButton
        style={{
          color: previousPageDisabled ? COLORS.btnSecondaryColor : null
        }}
        color="secondary"
        onClick={handleBackButtonClick}
        disabled={previousPageDisabled}
        startIcon={<KeyboardArrowLeft />}
        aria-label="previous page"
      >
        {t('i18n.datatable.pagination.PREVIOUS')}
      </CustomTableFooterButton>
      <div className={classes.pageSelection}>
        {t('i18n.datatable.pagination.PAGE')}
        <div className={classes.pageInput}>
          <CustomTextField
            size="small"
            color="primary"
            variant="outlined"
            disabled={!show}
            value={localPage}
            type="number"
            className={classes.textfieldClass}
            inputProps={{
              maxLength: 4
            }}
            onClick={e => {
              e.target.select();
            }}
            onChange={event => {
              if (
                event.target.value < 1 ||
                event.target.value > lastPageNumber
              ) {
                return;
              }
              setLocalPage(event.target.value);
              onPageChange(event, Number(event.target.value) - 1);
            }}
          />
        </div>
        {t('i18n.datatable.pagination.DISPLAYROWS')} {lastPageNumber}
      </div>
      <CustomTableFooterButton
        onClick={handleNextButtonClick}
        style={{
          color: nextPageDisabled ? COLORS.btnSecondaryColor : null
        }}
        disabled={nextPageDisabled}
        endIcon={<KeyboardArrowRight />}
        aria-label="next page"
      >
        {t('i18n.datatable.pagination.NEXT')}
      </CustomTableFooterButton>

      {!show && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <InConstruction
            horizontal
            text="Atribua os protocolos selecionados antes de mudar de página"
          />
        </div>
      )}
    </div>
  );
};

const CustomQuickAwnserFooter = ({
  filterData,
  count,
  textLabels,
  rowsPerPage,
  page,
  rowsPerPageOptions = [10, 20, 40],
  changePage,
  changeRowsPerPage,
  callback,
  pageVariableToTriggerPageChangeFromParentComponent = null,
  selectedRowsState = []
}) => {
  const DEBOUNCE_TIME_FETCH = 1000 * 0.7;
  const { t } = useTranslation();
  const classes = useStyles();
  const timeoutId = useRef();
  const [auxPage, setAuxPage] = useState(page);
  const [lastFilterData, setLastFilterData] = useState(filterData);

  const handleRowChange = event => {
    changeRowsPerPage(event.target.value);
    if (callback) {
      callback(auxPage, event.target.value);
    }
  };

  function debounce(func, timeout = 1000) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const handlePageChange = (_, newPage) => {
    setAuxPage(newPage);
    if (callback) {
      callback(newPage, rowsPerPage);
    }
  };

  useEffect(() => {
    if (
      filterData.name !== lastFilterData.name ||
      filterData.type !== lastFilterData.type ||
      filterData.message !== lastFilterData.message
    ) {
      setAuxPage(0);
      setLastFilterData(filterData);
    }
  }, [filterData]);
  
  useEffect(() => {
    if (page !== 0) {
      setAuxPage(page);
    }
  }, [page]);

  useEffect(() => {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      changePage(auxPage);
    }, DEBOUNCE_TIME_FETCH);
  }, [auxPage]);

  useEffect(() => {
    if (pageVariableToTriggerPageChangeFromParentComponent) {
      handlePageChange(
        null,
        pageVariableToTriggerPageChangeFromParentComponent.page
      );
    }
  }, [pageVariableToTriggerPageChangeFromParentComponent]);

  return (
    <CustomTableFooter>
      <TableRow>
        <TableCell variant="footer" className={classes.tableCell}>
          <CustomTablePagination
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            show={selectedRowsState.length === 0 ? 'true' : 'false'}
            onRowsPerPageChange={handleRowChange}
            page={auxPage}
            labelRowsPerPage={textLabels.rowsPerPage}
            labelDisplayedRows={({ from, to }) =>
              `${t(
                'i18n.datatable.pagination.SHOWINGOFF'
              )}: ${from}-${to} ${textLabels?.displayRows ||
                t('i18n.datatable.pagination.DISPLAYROWS')} ${count}`
            }
            rowsPerPageOptions={rowsPerPageOptions}
            ActionsComponent={() => {
              return (
                <TablePaginationActions
                  {...{ count, page, rowsPerPage }}
                  onPageChange={debounce(handlePageChange)}
                  show={selectedRowsState.length === 0}
                />
              );
            }}
          />
        </TableCell>
      </TableRow>
    </CustomTableFooter>
  );
};

export default CustomQuickAwnserFooter;
