import { Grid, Typography } from '@material-ui/core';
import React from 'react';

const MLColorGraph = ({ score }) => {
  const scoreList = [
    { score: 1, backgroundColor: 'red' },
    { score: 2, backgroundColor: 'orange' },
    { score: 3, backgroundColor: 'yellow' },
    { score: 4, backgroundColor: '#BEE571' },
    { score: 5, backgroundColor: 'green' }
  ];

  return (
    <Grid
      container
      spacing={2}
      style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}
    >
      {scoreList.map(item => {
        return (
          <Grid item xs={2} key={Math.random()}>
            <div
              style={{
                backgroundColor: item.backgroundColor,
                opacity: item.score === score ? 1 : 0.5,
                minHeight: item.score === score && '30px'
              }}
            >
              &nbsp;
            </div>
          </Grid>
        );
      })}
      <Grid item xs={2}>
        <Typography>{score}/5</Typography>
      </Grid>
    </Grid>
  );
};

export default MLColorGraph;
