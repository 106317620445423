/* eslint-disable react/no-array-index-key */
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Paper,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router';
import Page from '../../../components/Page';
import API from '../../../services/api';
import formHelper from '../../../utils/formHelper';
import reasonList from '../../../utils/reasonList';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const NewTicket = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [mascaraTelefone, setMascaraTelefone] = useState('(99)99999-9999');
  const [maskCpfCnpj, setMaskCpfCnpj] = React.useState('999.999.999-999');
  const [filesToUpload, setFilesToUpload] = useState([]);
  const onDrop = acceptedFiles => {
    const newFilesState = [...filesToUpload];
    if (newFilesState.length + acceptedFiles.length > 4) {
      enqueueSnackbar(
        t('i18n.newticket.IMPOSSIBLE_TO_INCLUDE_MORE_THAN_4_IMAGES'),
        {
          variant: 'error'
        }
      );
      return;
    }
    acceptedFiles.forEach(element => {
      if (element.size > 2000000) {
        enqueueSnackbar(
          t('i18n.newticket.THE_IMAGE') +
            element.path +
            t('i18n.newticket.CANNOT_BE_SENT'),
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          }
        );
        return;
      }
      const reader = new FileReader();
      const fileByteArray = [];
      reader.readAsArrayBuffer(element);
      reader.onloadend = evt => {
        if (evt.target.readyState === FileReader.DONE) {
          const arrayBuffer = evt.target.result;
          const array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < array.length; i++) {
            fileByteArray.push(array[i]);
          }
        }
      };
      newFilesState.push({
        path: element.path,
        binary: fileByteArray
      });
    });
    setFilesToUpload(newFilesState);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    maxFiles: 4
  });

  const files = filesToUpload.map((file, index) => (
    <Chip
      key={index}
      style={{ margin: '2px' }}
      label={file.path}
      onDelete={() => {
        const newFilesState = [...filesToUpload];
        newFilesState.splice(index, 1);
        setFilesToUpload(newFilesState);
      }}
    />
  ));

  const history = useHistory();
  const [formData, setFormdata] = useState(
    formHelper.simpleData2FormData({
      cpf: {
        value: '',
        required: true,
        masked: true
      },
      email: {
        required: true
      },
      phone: {
        required: true,
        masked: true,
        leaveOnlyNumbersWhenSubmit: true
      },
      reasonNew: {
        required: true
      },
      detail: {
        required: true
      },
      orderNumber: {
        required: false
      },
      subject: {
        required: true
      },
      description: {
        required: true
      }
    })
  );

  const handleCpfCnpjMask = value => {
    value = value.replace(/\D/g, '');
    if (value.length > 11) {
      setMaskCpfCnpj('99.999.999/9999-99');
    } else if (value.length <= 11) {
      setMaskCpfCnpj('999.999.999-99');
    }
  };

  const [reasonDetail, setReasonDetail] = useState([]);

  useEffect(() => {
    const [group, name] = formData.reasonNew.value.split(',');
    const foundDetail = reasonList().find(
      el => el.name === name && el.group === group
    );
    let details = [];
    if (foundDetail) {
      details = foundDetail.detail.map(item => {
        return item.name;
      });
    }
    setReasonDetail(details);
  }, [formData.reasonNew.value]);

  const submitForm = () => {
    if (formData?.cpf?.value) {
      formData.cpf.value = formData.cpf.value.replace(/\D/g, '');
    }
    if (!formHelper.validaForm(formData, setFormdata)) {
      enqueueSnackbar(t('i18n.newticket.CHECK_IF_FIELDS_ARE_FILLED'), {
        variant: 'error'
      });
      return;
    }

    const postData = formHelper.formData2PostData(formData);
    postData.attachments = filesToUpload.map(file => {
      return {
        name: file.path,
        content: file.binary
      };
    });
    postData.reasonNew = {
      group: formData.reasonNew.value.split(',')[0],
      name: formData.reasonNew.value.split(',')[1],
      desc: formData.detail.value
    };

    API.post(
      `/auth/ms-ticket/v1/tickets/customerguide/new-attendant-ticket`,
      postData
    )
      .then(() => {
        history.push('/ticketsAfterSale');
      })
      .catch(() => {});
  };

  return (
    <Page className={classes.root} title={t('i18n.newticket.NEW_GUIDE_TICKET')}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        width="100%"
        justifyContent="center"
      >
        <Container maxWidth={false}>
          <Breadcrumbs gutterBottom aria-label="breadcrumb">
            <Link color="inherit" to="/ticketsAfterSale">
              {t('i18n.ticketcontainer.TICKETS')}
            </Link>
            <Typography color="textPrimary">
              {t('i18n.newticket.NEW_GUIDE_TICKET')}
            </Typography>
          </Breadcrumbs>
          <Box
            mb={3}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Card>
              <CardContent>
                <Box mb={2}>
                  <InputMask
                    onPaste={e =>
                      handleCpfCnpjMask(e.clipboardData.getData('Text'))
                    }
                    mask={maskCpfCnpj}
                    maskChar=""
                    value={formData.cpf.value}
                    onChange={htmlElemtEvent => {
                      formHelper.setFormvalue(
                        formData,
                        setFormdata,
                        'cpf',
                        htmlElemtEvent.target.value
                      );
                      if (htmlElemtEvent.target.value.length > 14) {
                        setMaskCpfCnpj('99.999.999/9999-99');
                      } else {
                        setMaskCpfCnpj('999.999.999-999');
                      }
                    }}
                  >
                    {() => (
                      <TextField
                        error={formData.cpf.error}
                        label={t('i18n.newticket.CPF')}
                        variant="outlined"
                        helperText={t('i18n.newticket.CPF_HELPER_TEXT')}
                        fullWidth
                      />
                    )}
                  </InputMask>
                </Box>
                <Box mb={2}>
                  <TextField
                    onChange={htmlElemtEvent => {
                      formHelper.setFormvalue(
                        formData,
                        setFormdata,
                        'email',
                        htmlElemtEvent.target.value
                      );
                    }}
                    error={formData.email.error}
                    label={t('i18n.newticket.EMAIL')}
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                  />
                </Box>
                <Box mb={2}>
                  <InputMask
                    mask={mascaraTelefone}
                    onChange={htmlElemtEvent => {
                      formHelper.setFormvalue(
                        formData,
                        setFormdata,
                        'phone',
                        htmlElemtEvent.target.value
                      );
                    }}
                    onBlur={htmlElemtEvent => {
                      formHelper.telefoneFlex(
                        'phone',
                        formData,
                        setFormdata,
                        setMascaraTelefone,
                        htmlElemtEvent
                      );
                    }}
                    onFocus={() => {
                      setMascaraTelefone('(99)99999-9999');
                    }}
                  >
                    {() => (
                      <TextField
                        error={formData.phone.error}
                        label={t('i18n.newticket.PHONE')}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  </InputMask>
                </Box>
                <Box mb={2}>
                  <FormControl
                    error={formData.reasonNew.error}
                    fullWidth
                    variant="outlined"
                  >
                    <InputLabel>
                      {formData.reasonNew.value
                        ? t('i18n.newticket.REASON')
                        : ''}
                    </InputLabel>
                    <Select
                      native
                      value={formData.reasonNew.value}
                      onChange={htmlElemtEvent => {
                        formHelper.setFormvalue(
                          formData,
                          setFormdata,
                          'reasonNew',
                          htmlElemtEvent.target.value
                        );
                        formHelper.setFormvalue(
                          formData,
                          setFormdata,
                          'detail',
                          ''
                        );
                      }}
                      label={t('i18n.newticket.REASON')}
                    >
                      <option disabled value="">
                        Selecione um motivo
                      </option>
                      <optgroup label="N1">
                        {reasonList()
                          .filter(el => el.group === 'N1')
                          .map(el => {
                            return (
                              <option
                                key={`${el.group}${el.name}`}
                                value={[el.group, el.name]}
                              >
                                {el.name}
                              </option>
                            );
                          })}
                      </optgroup>
                      <optgroup label="N2">
                        {reasonList()
                          .filter(el => el.group === 'N2')
                          .map(el => {
                            return (
                              <option
                                key={`${el.group}${el.name}`}
                                value={[el.group, el.name]}
                              >
                                {el.name}
                              </option>
                            );
                          })}
                      </optgroup>
                      <optgroup label="N3">
                        {reasonList()
                          .filter(el => el.group === 'N3')
                          .map(el => {
                            return (
                              <option
                                key={`${el.group}${el.name}`}
                                value={[el.group, el.name]}
                              >
                                {el.name}
                              </option>
                            );
                          })}
                      </optgroup>
                    </Select>
                  </FormControl>
                </Box>
                <Box mb={2}>
                  <FormControl
                    error={formData.detail.error}
                    fullWidth
                    variant="outlined"
                  >
                    <InputLabel>
                      {formData.detail.value
                        ? t('i18n.newticket.REASON_DETAIL')
                        : ''}
                    </InputLabel>
                    <Select
                      native
                      value={formData.detail.value}
                      onChange={htmlElemtEvent => {
                        formHelper.setFormvalue(
                          formData,
                          setFormdata,
                          'detail',
                          htmlElemtEvent.target.value
                        );
                      }}
                      label={t('i18n.newticket.REASON_DETAIL')}
                    >
                      <option disabled value="">
                        Selecione o detalhe do motivo
                      </option>
                      {reasonDetail.map((el, index) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <option key={index} value={el}>
                            {el}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Box mb={2}>
                  <TextField
                    onChange={htmlElemtEvent => {
                      formHelper.setFormvalue(
                        formData,
                        setFormdata,
                        'orderNumber',
                        htmlElemtEvent.target.value
                      );
                    }}
                    error={formData.orderNumber.error}
                    label={t('i18n.newticket.ORDER_NUMBER')}
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    onChange={htmlElemtEvent => {
                      formHelper.setFormvalue(
                        formData,
                        setFormdata,
                        'subject',
                        htmlElemtEvent.target.value
                      );
                    }}
                    error={formData.subject.error}
                    label={t('i18n.newticket.SUBJECT')}
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    onChange={htmlElemtEvent => {
                      formHelper.setFormvalue(
                        formData,
                        setFormdata,
                        'description',
                        htmlElemtEvent.target.value
                      );
                    }}
                    error={formData.description.error}
                    fullWidth
                    label={t('i18n.newticket.DESCRIPTION')}
                    multiline
                    rows={4}
                    variant="outlined"
                  />
                </Box>
              </CardContent>
              <Divider />
              <CardContent>
                <Typography variant="overline">
                  {t('i18n.newticket.SEND_FILES')}
                </Typography>
                <Paper
                  {...getRootProps({ className: 'dropzone' })}
                  style={{
                    padding: '20px',
                    border: 'dashed 2px #ccc',
                    textAlign: 'center'
                  }}
                  variant="outlined"
                >
                  <input {...getInputProps()} />
                  <Typography>
                    {t('i18n.newticket.DROP_FILES_HERE')} <br />
                    {t('i18n.newticket.MAX_IMAGES')}
                  </Typography>
                </Paper>
                <br />
                {files}
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container>
                  <Grid item lg={6} />
                  <Grid item lg={6}>
                    <Button
                      style={{ float: 'right' }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      {t('i18n.newticket.SEND')}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default NewTicket;
