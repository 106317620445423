import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Divider,
  Box,
  ThemeProvider,
  createTheme,
  Grid
} from '@material-ui/core';
import styled from 'styled-components';

import { Alert } from '@material-ui/lab';
import MUIDataTable from 'mui-datatables';
import { useSelector } from 'react-redux';
import { COLORS } from '../../../../styles/settings/colors.config';
import {
  CustomTypograph,
  CardTitle,
  CustomHintText,
  CustomParagraphArrowDropDownUp,
  ArrowGrid,
  CustomTypographLink
} from '../../styles.v2';
import { HelpIcon } from '../../../../components/HelpIcon.component';
import { DemoPie2 as Donut } from './PresaleOfflineDonutChart.component.v4';
import {
  handleOpenNewTabv3,
  dataTableFilterFactory
} from '../handleGetTabFilterHook';

import userService from '../../../../services/user.service';

import {
  CustomArrowDropDown,
  CardDataWrapper,
  CustomCardContent
} from '../../styles';

import { colors } from '../../services/dashboardServices';

const TableValueLabel = styled.span`
  font-size: 20px;
  color: ${COLORS.grayText};
  font-weight: bold;
`;

const TableValuePercentLabel = styled.span`
  font-size: 12px;
  color: ${COLORS.meduimGray};
  font-weight: bold;
`;

const theme = createTheme({
  overrides: {
    MuiToolbar: { root: { display: 'none' } },
    MuiTableHead: {
      root: {
        display: 'none'
      }
    }
  }
});

export const tableOptions = {
  serverSide: false,
  pagination: false,
  download: false,
  print: false,
  filter: false,
  search: false,
  viewColumns: false,
  selectableRows: 'none'
};

const TableValue = ({ value, color, percent, dashboardFilter, attendant }) => {
  const isML =
    dashboardFilter.marketplace.length === 1 &&
    dashboardFilter.marketplace[0] === 'MERCADO_LIVRE';
  const isAdmin = userService.hasGroup('admin');
  const withDrillDown = attendant !== 'Equipe' ? true : !!isAdmin;

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} style={{ display: 'flex' }}>
        <div
          style={{ width: '20px', height: '20px', backgroundColor: color }}
        />
      </Grid>

      <Grid
        item
        xs={8}
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'column'
        }}
      >
        {isML && withDrillDown ? (
          <CustomTypographLink
            fontWeight="500"
            onClick={() => {
              handleOpenNewTabv3(
                dataTableFilterFactory({ dashboardFilter, attendant, isML })
              );
              // handleOpenNewTabv3({ dashboardFilter, status: 'ANSWERED', isTeamView, type });
            }}
          >
            {value}
          </CustomTypographLink>
        ) : (
          <TableValueLabel>{value}</TableValueLabel>
        )}
        <TableValuePercentLabel>
          {percent ? percent?.toFixed(2) : '0'}%
        </TableValuePercentLabel>
      </Grid>
    </Grid>
  );
};

const PresaleOfflineDonutChartV2 = ({
  dataChart,
  descriptionHint,
  showMoreDetails,
  isTeamView
}) => {
  const dashboardFilter = useSelector(
    state => state.presaleOfflineDashboardFilterReducer
  );

  const { qtd_answered_total_team, qtd_answered_per_clerk } = dataChart;

  const [showDetails, setShowDetails] = useState(showMoreDetails);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const tableData = qtd_answered_per_clerk?.map((item, index) => {
    return {
      type: item.attendant || 'Sem Registro',
      value: item.answered,
      color: colors[index],
      percent: item.answered
        ? (item.answered / qtd_answered_total_team) * 100
        : 0
    };
  });

  const columns = [
    {
      name: 'value',
      label: 'Total',
      options: {
        customBodyRenderLite: dataIndex => {
          const { value, percent, color, type } = tableData[dataIndex];

          return (
            <TableValue
              {...{
                value,
                percent,
                color,
                dashboardFilter,
                isTeamView,
                attendant: type
              }}
            />
          );
        }
      }
    },
    {
      name: 'type',
      label: 'Atendente',
      options: {
        customBodyRender: value => {
          return (
            <span
              aria-hidden="true"
              style={{ color: COLORS.grayText /** cursor: 'pointer' */ }}
              /** Uncomment line below when implementing drilldown */
              // onClick={() => handleOpenNewTab(value)}
            >
              {value}
            </span>
          );
        }
      }
    }
  ];

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Card style={{ width: '100%', borderRadius: '20px' }}>
        <CardTitle>
          <CustomTypograph variant="h5">
            Quantidade de <b>Perguntas Respondidas</b>
          </CustomTypograph>
          <Box style={{ gap: '10px' }} display="flex" justifyContent="flex-end">
            {descriptionHint && (
              <HelpIcon
                tooltip={<CustomHintText>{descriptionHint}</CustomHintText>}
                arrow
                placement="top"
              />
            )}
          </Box>
        </CardTitle>
        <Divider />

        {qtd_answered_total_team ? (
          <CardContent style={{ zoom: '110%' }}>
            <Donut
              total={qtd_answered_total_team}
              list={qtd_answered_per_clerk}
              {...{ colors }}
            />
          </CardContent>
        ) : (
          <CustomCardContent>
            <CardDataWrapper>
              <Alert severity="info">Não há dados disponíveis</Alert>
            </CardDataWrapper>
          </CustomCardContent>
        )}

        <ArrowGrid>
          <CustomParagraphArrowDropDownUp showDivider />
        </ArrowGrid>
        <Divider />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CustomArrowDropDown
            onClick={toggleDetails}
            iconcolor={COLORS.greenText}
          />
        </div>

        {showDetails && qtd_answered_total_team > 0 && (
          <ThemeProvider theme={theme}>
            <MUIDataTable
              title={' '}
              data={tableData}
              columns={columns}
              options={tableOptions}
            />
          </ThemeProvider>
        )}
      </Card>
    </div>
  );
};

export { PresaleOfflineDonutChartV2 };
