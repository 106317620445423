import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import statusEnum from '../enums/statusEnum';

const FilterStatus = ({ marketplace, filterForm, setFilterForm }) => {
  const { t } = useTranslation();
  
  return (
    <Box mb={2}>
      <Grid container>
        <Grid item xs={7}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel>{t('i18n.preSale.STATUS')}</InputLabel>
            <Select
              defaultValue=""
              value={filterForm.ticketStatus || ''}
              className="small-input"
              onChange={htmlElemtEvent => {
                setFilterForm({
                  ...filterForm,
                  ticketStatus: htmlElemtEvent.target.value
                });
              }}
              label={t('i18n.ticketslistview.STATUS')}
            >
              <MenuItem value="">
                <em>{t('i18n.ticketslistview.NONE')}</em>
              </MenuItem>
              {statusEnum()
                .filter(status => status.marketplace === marketplace)
                .map(status => {
                  return (
                    <MenuItem value={status.value} key={status.value}>
                      {t(status.label)}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};
export default FilterStatus;
