import { Card, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  border: 1px solid #b7b7b7;
  border-radius: 8px;
  padding: 10px 20px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 12px;
`;

const StyledTitle = styled.div`
  font-weight: bold;
  color: #434343;
  display: flex;
  justify-content: flex-start;
`;

const StyledItem = styled.div`
  color: #434343;
  display: flex;
  justify-content: flex-start;
`;

export default function LegalProcessInfoDetail({ protocolData }) {
  const { t } = useTranslation();
  const { protocolsVariablesList } = protocolData;
  const filteredVariables = protocolsVariablesList?.filter(
    item =>
      item.variableId !== 'obsEtapaB' &&
      item.variableId !== '963_prju_H_R10_resInicial_txt' &&
      item.variableId !== 'obsEtapaC' &&
      item.variableId !== '809_resumo_sentenca_txt'
  );

  return (
    <StyledCard>
      <Grid container spacing={2}>
        {protocolData?.subject && (
          <Grid item xs={3} style={{ marginBottom: '15px' }}>
            <StyledTitle>{t('i18n.ticketcontainer.SUBJECT')}</StyledTitle>
            <StyledItem>{protocolData?.subject}</StyledItem>
          </Grid>
        )}
        {filteredVariables?.map(item => {
          return (
            <Grid item xs={3} style={{ marginBottom: '15px' }}>
              <StyledTitle>{item.name}</StyledTitle>
              <StyledItem>{item.domains[0].desc}</StyledItem>
            </Grid>
          );
        })}
      </Grid>
    </StyledCard>
  );
}
