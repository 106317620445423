import { ArrowForward, Clear, Done } from '@material-ui/icons';
import React from 'react';

const PositionIcon = ({ status, endProcess, position, statusCondition }) => {
  if (
    status.includes('Recusado') ||
    status.includes('cancelada') ||
    status.includes('não efetivado')
  )
    return (
      <Clear style={{ fontSize: '13px', fontWeight: 'bold', color: 'white' }} />
    );

  switch (position) {
    case 'start':
      return (
        <ArrowForward
          style={{ fontSize: '13px', fontWeight: 'bold', color: 'white' }}
        />
      );
    case 'middle':
      if (statusCondition === 'passed') {
        return (
          <Done
            style={{ fontSize: '13px', fontWeight: 'bold', color: 'white' }}
          />
        );
      }
      return '';
    case 'end':
      if (endProcess === 'canceled') {
        return (
          <Clear
            style={{ fontSize: '13px', fontWeight: 'bold', color: 'white' }}
          />
        );
      }
      return (
        <Done
          style={{ fontSize: '13px', fontWeight: 'bold', color: 'white' }}
        />
      );

    default:
      return '';
  }
};

export default PositionIcon;
