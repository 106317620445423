import React from 'react';

import { Box, Card, Divider, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  CardDataWrapper,
  CustomCardContent,
  CardTitle,
  InConstruction,
  CardTitleTypography,
  CustomArrowDropDown
} from '../../styles';
import CardHint from '../../components/CardHint';
import IndividualDataCard from '../../components/IndividualDataCard';
import handleOpenNewTabHook from '../../components/handleGetTabFilterHook';
import TeamDataCard from './sla/TeamDataCard';

const hint = () => {
  return (
    <ul style={{ listStyleType: 'none' }}>
      <li>
        <b>Vencido:</b> quantidade de protocolos com SLA igual ou superior a 24h
      </li>
      <li>
        <b>Vence hoje:</b> quantidade de protocolos com SLA superior a 12h e
        menor que 24h
      </li>
    </ul>
  );
};

const DividerWithArrow = () => {
  return (
    <>
      <Box marginLeft="22px" marginRight="22px">
        <Divider />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        marginBottom="-35px"
        marginTop="-2px"
      >
        <CustomArrowDropDown iconcolor="#D9D9D9" />
      </Box>
    </>
  );
};

const SLACard = ({
  descriptionHint,
  headerMultipleData,
  isTeamView,
  showInConstruction
}) => {
  const { t } = useTranslation();
  const dashboardFilter = useSelector(state => state.dashboardFilterReducer);
  const { handleOpenNewTab } = handleOpenNewTabHook({
    dashboardFilter,
    t,
    key: 'sla'
  });
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Card style={{ width: '100%', borderRadius: '20px' }}>
        <CardTitle>
          <CardTitleTypography>SLA</CardTitleTypography>
          {descriptionHint && <CardHint hint={hint()} noWidth />}
        </CardTitle>
        <Divider />

        {!isTeamView && (
          <>
            <Grid
              container
              spacing={2}
              style={{ marginTop: '5px', marginBottom: '5px' }}
            >
              {headerMultipleData?.map(item => (
                <Grid item xs={6} key={item.subtitle}>
                  <TeamDataCard {...{ item, handleOpenNewTab, isTeamView }} />
                </Grid>
              ))}
            </Grid>
            <DividerWithArrow />
          </>
        )}
        <Grid container spacing={2}>
          {headerMultipleData?.map(item => (
            <Grid item xs={6} key={item.subtitle}>
              <CustomCardContent>
                <CardDataWrapper>
                  {!isTeamView && (
                    <IndividualDataCard
                      firstLabel={item.firstLabel}
                      firstData={item.firstData}
                      secondaryLabel={item.secondaryLabel}
                      secondaryData={item.secondaryData}
                      withDrillDown={item.action}
                      title={item.subtitle}
                      handleOpenNewTab={handleOpenNewTab}
                    />
                  )}

                  {isTeamView && (
                    <TeamDataCard {...{ item, handleOpenNewTab, isTeamView }} />
                  )}
                </CardDataWrapper>
              </CustomCardContent>
            </Grid>
          ))}
        </Grid>
      </Card>
      {showInConstruction && <InConstruction />}
    </div>
  );
};

export default SLACard;
