/* eslint-disable camelcase */
import {
  TableBody,
  Table,
  TableRow,
  Paper,
  TableCell,
  TableHead,
  TableContainer,
  makeStyles,
  Typography,
  Box,
  Divider,
  Grid,
  Switch,
  Button,
  IconButton
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import API from '../../../services/api';
import AnalysisDescriptionInput from './AnalysisDescriptionInput';
import ConfirmationModal from '../../../utils/ConfirmationModal';

const useStyles = makeStyles(() => ({
  greenRow: {
    'background-color': '#dfffe6'
  },
  redRow: {
    'background-color': '#f100000d'
  }
}));

const AntSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: '#f1000047',
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch);

const AnalysisView = ({
  dealersAnalysisData,
  dealersAnalysisProdutName,
  onSuccessfulEdition
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
  const [confirmationModalAction, setConfirmationModalAction] = useState();
  const [editMode, setEditMode] = useState(false);

  const originalDealersAnalysisDataState = JSON.parse(
    JSON.stringify(dealersAnalysisData)
  );
  const [dealersAnalysisDataState, setDealersAnalysisDataState] = useState(
    JSON.parse(JSON.stringify(dealersAnalysisData))
  );

  useEffect(() => {
    setDealersAnalysisDataState(
      JSON.parse(JSON.stringify(dealersAnalysisData))
    );
  }, [dealersAnalysisData]);

  if (dealersAnalysisData.length === 0) {
    return (
      <Typography>{t('i18n.sales_ad_analysis.data.analysisError')}</Typography>
    );
  }

  const submitEdit = () => {
    API.post(`/auth/ms-ia/api/v1/dealer-analysis/update-analysis`, {
      modelo: dealersAnalysisProdutName,
      result_analise: dealersAnalysisDataState
    })
      .then(() => {
        enqueueSnackbar(t('i18n.simplephrases.SUCCESS'), {
          variant: 'success'
        });
        onSuccessfulEdition();
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
          variant: 'error'
        });
      });
  };

  return (
    <>
      <Box justifyContent="space-between" display="flex">
        <Typography variant="h2">{dealersAnalysisProdutName}</Typography>
        {!editMode && (
          <Box display="flex">
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setEditMode(true);
              }}
            >
              {t('i18n.sales_ad_analysis.edit')}
            </Button>
          </Box>
        )}
        {editMode && (
          <Box display="flex" justifyContent="space-between" width="220px">
            <Button
              variant="contained"
              color="default"
              size="small"
              onClick={() => {
                setEditMode(false);
                setDealersAnalysisDataState(originalDealersAnalysisDataState);
              }}
              startIcon={<HighlightOffIcon />}
            >
              {t('i18n.sales_ad_analysis.cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<CheckCircleOutlineIcon />}
              onClick={() => {
                setConfirmationModalAction(() => () => {
                  submitEdit();
                });
                setConfirmationModalMessage(
                  t('i18n.sales_ad_analysis.confirmation.overwriteAnalysis')
                );
                setConfirmationModalOpen(true);
              }}
            >
              {t('i18n.sales_ad_analysis.save')}
            </Button>
          </Box>
        )}
      </Box>
      <Box mt={2} mb={2}>
        <Divider />
      </Box>
      {editMode && (
        <Box mb={2}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => {
              const newState = [
                { description: '', verified: false },
                ...dealersAnalysisDataState
              ];
              setDealersAnalysisDataState(newState);
            }}
          >
            {t('i18n.sales_ad_analysis.addField')}
          </Button>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('i18n.sales_ad_analysis.data.field')}</TableCell>
              <TableCell>
                {t('i18n.sales_ad_analysis.data.analysisResult')}
              </TableCell>
              {editMode && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {dealersAnalysisDataState.map((row, index) => (
              <TableRow
                className={row.verified ? classes.greenRow : classes.redRow}
                key={Math.random()}
              >
                <TableCell>
                  {!editMode && row.description}
                  {editMode && (
                    <AnalysisDescriptionInput
                      onDebounce={value => {
                        const newState = [...dealersAnalysisDataState];
                        newState[index].description = value;
                        setDealersAnalysisDataState(newState);
                      }}
                      value={row.description}
                    />
                  )}
                </TableCell>

                <TableCell width="175px">
                  {!editMode && (
                    <>
                      {row.verified ? (
                        <CheckCircleOutlineIcon />
                      ) : (
                        <HighlightOffIcon />
                      )}
                    </>
                  )}

                  {editMode && (
                    <Typography component="div">
                      <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item display="flex">
                          <HighlightOffIcon />
                        </Grid>
                        <Grid style={{ marginTop: '-5px' }}>
                          <AntSwitch
                            checked={row.verified}
                            onChange={() => {
                              const newState = [...dealersAnalysisDataState];
                              newState[index].verified = !newState[index]
                                .verified;
                              setDealersAnalysisDataState(newState);
                            }}
                            name="checkedC"
                          />
                        </Grid>
                        <Grid item display="flex">
                          <CheckCircleOutlineIcon />
                        </Grid>
                      </Grid>
                    </Typography>
                  )}
                </TableCell>
                {editMode && (
                  <TableCell width="80px">
                    <IconButton
                      onClick={() => {
                        setConfirmationModalAction(() => () => {
                          const newState = [...dealersAnalysisDataState];
                          newState.splice(index, 1);
                          setDealersAnalysisDataState(newState);
                        });
                        setConfirmationModalMessage(
                          t('i18n.sales_ad_analysis.confirmation.deleteField')
                        );
                        setConfirmationModalOpen(true);
                      }}
                      aria-label="delete"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationModal
        message={confirmationModalMessage}
        submitConfirmation={() => {
          confirmationModalAction();
          setConfirmationModalOpen(false);
        }}
        openState={confirmationModalOpen}
        closeParent={() => {
          setConfirmationModalOpen(false);
        }}
      />
    </>
  );
};

export default AnalysisView;
