import {
  Box,
  Button,
  CardContent,
  Divider,
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  TableBody,
  TableHead
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@material-ui/icons/Search';
import API from '../../../../../services/api';

const CapFind = ({ collectIdProp }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [collectData, setCollectData] = useState({ historico: [] });
  const [collectId, setCollectId] = useState(collectIdProp || '');

  useEffect(() => {
    setCollectId(collectIdProp);
    setCollectData({ historico: [] });
  }, [collectIdProp]);

  const findCap = () => {
    API.get(
      `/auth/ms-ticket/v1/reverse-logistics/track-reverse-post?pedidoColetaId=${collectId}`
    )
      .then(response => {
        setCollectData(response.data);
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.CAP.find.error'), {
          variant: 'error'
        });
      });
  };
  return (
    <>
      <CardContent>
        {collectIdProp && (
          <Box mb={2}>
            <Typography variant="overline">
              {t('i18n.CAP.collectIdForTicket')}: {collectIdProp}
            </Typography>
          </Box>
        )}
        <Grid container spacing={2}>
          <Grid lg={10} item>
            <TextField
              size="small"
              fullWidth
              label={t('i18n.CAP.collectId')}
              variant="outlined"
              value={collectId || ''}
              onChange={htmlElemtEvent => {
                setCollectId(htmlElemtEvent.target.value);
              }}
            />
          </Grid>
          <Grid lg={2} item>
            <Box>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  findCap();
                }}
              >
                <SearchIcon />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      {collectData?.numero_pedido && (
        <>
          <CardContent>
            <Typography>
              {t('i18n.CAP.find.orderNumber')} : {collectData?.numero_pedido}
            </Typography>
            <Typography>
              {t('i18n.CAP.find.clientControl')} :{' '}
              {collectData?.controle_cliente}
            </Typography>
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant="overline">
              {t('i18n.CAP.find.history')}
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('i18n.CAP.find.history.status')}</TableCell>
                    <TableCell>
                      {t('i18n.CAP.find.history.statusDescription')}
                    </TableCell>
                    <TableCell>
                      {t('i18n.CAP.find.history.updateDate')}
                    </TableCell>
                    <TableCell>
                      {t('i18n.CAP.find.history.updateHour')}
                    </TableCell>
                    <TableCell>{t('i18n.CAP.find.history.obs')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {collectData?.historico?.map(row => (
                    <TableRow key={row?.data_atualizacao}>
                      <TableCell component="th" scope="row">
                        {row?.status}
                      </TableCell>
                      <TableCell>{row?.descricao_status}</TableCell>
                      <TableCell>{row?.data_atualizacao}</TableCell>
                      <TableCell>{row?.hora_atualizacao}</TableCell>
                      <TableCell>{row?.observacao}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant="overline">
              {t('i18n.CAP.find.object')}
            </Typography>
            <Typography>
              {t('i18n.CAP.find.object.labelId')} :{' '}
              {collectData?.objeto?.numero_etiqueta}
            </Typography>
            <Typography>
              {t('i18n.CAP.find.object.customerObjectControl')} :{' '}
              {collectData?.objeto?.controle_objeto_cliente}
            </Typography>
            <Typography>
              {t('i18n.CAP.find.object.lastStatus')} :{' '}
              {collectData?.objeto?.ultimo_status}
            </Typography>
            <Typography>
              {t('i18n.CAP.find.object.statusDescription')} :{' '}
              {collectData?.objeto?.descricao_status}
            </Typography>
            <Typography>
              {t('i18n.CAP.find.object.lastUpdateDate')} :{' '}
              {collectData?.objeto?.data_ultima_atualizacao}
            </Typography>
            <Typography>
              {t('i18n.CAP.find.object.lastUpdateHour')} :{' '}
              {collectData?.objeto?.hora_ultima_atualizacao}
            </Typography>
            <Typography>
              {t('i18n.CAP.find.object.dueDate')} : {collectData?.prazo}
            </Typography>
          </CardContent>
        </>
      )}
    </>
  );
};

export default CapFind;
