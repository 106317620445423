import React from 'react';
import styled from 'styled-components';
import {
  Typography,
  CardContent,
  TextField,
  Grid,
  Box
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Build } from '@material-ui/icons';
import ArrowDropDownUp from '@material-ui/icons/ArrowDropUp';
import { COLORS } from '../../styles/settings/colors.config';

export const CardDataWrapper = styled('div')`
  display: flex;
  justify-content: ${props =>
    props.jusfityContent === true ? 'space-between' : 'center'};
  align-items: start;
`;

export const CustomArrowDropDownUp = styled(ArrowDropDownUp)`
  color: lightgrey;
  font-size: 55px;
`;

export const CustomMainData = styled(Typography)`
  font-size: ${props => (props.fontSize ? props.fontSize : '32px')};
  line-height: ${props => (props.lineheight ? props.lineheight : '37.5px')};
  font-weight: 500;
  color: ${COLORS.grayText};
  margin-top: ${props => (props.marginTop ? props.marginTop : '11px')};
`;

// export const CustomParagraphArrowDropDownUp = styled('p')`
//   // margin-bottom: -42px;
//   // margin-top: -15px;
// `;

export const ArrowGrid = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const CustomParagraphArrowDropDownUp = styled('div')`
  content: '';
  width: 12px;
  height: 12px;
  display: block;
  border: 12px solid transparent;
  border-color: transparent transparent
    ${props => (props.showDivider ? 'lightgrey' : 'transparent')} transparent;
`;

export const CustomParagraphArrowDropUpDonw = styled('div')`
  content: '';
  width: 12px;
  height: 12px;
  display: block;
  border: 12px solid transparent;
  border-color: ${props =>
      props.showDivider ? COLORS.greenText : 'transparent'}
    transparent transparent transparent;
  margin-bottom: -14px;
`;

export const CardTitle = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 16px 22px;
`;

export const CustomHelpIcon = styled(HelpOutlineIcon)`
  &:hover {
    color: ${COLORS.darkGreenText};
  }
`;

export const CustomDownloadIcon = styled(CloudDownloadIcon)`
  &:hover {
    color: ${COLORS.darkGreenText};
  }
`;

export const CustomHintText = styled(Typography)`
  padding: 5px 10px 5px 10px;
  color: white;
  font-size: 14px;
`;

export const CustomCardContent = styled(CardContent)`
  padding: 16px 60px 22px;
  @media (max-width: 1440px) {
    padding: 16px 20px 22px;
  }
`;

export const CardContentSubtitle = styled(CardContent)`
  padding-block-end: 0px;
`;

export const CustomLineCardContent = styled(CardContent)`
  padding: 0px 60px 0px 60px !important;
  @media (max-width: 1440px) {
    padding: 0px 22px 0px 22px !important;
  }
`;

export const CustomLineCardContentSideBySide = styled(CardContent)`
  padding: 15px 60px 15px 60px !important;
  @media (max-width: 1440px) {
    padding: 15px 22px 15px 22px !important;
  }
`;

export const CustomCardContentNoPadding = styled(CardContent)`
  padding: 0px 10px 15px 0px !important;
`;

export const CustomTypograph = styled(Typography)`
  color: ${props => (props.colortext ? props.colortext : COLORS.grayText)};
  margin-top: ${props => (props.margintop ? props.margintop : '0px')};
  min-width: ${props => (props.minwidth ? props.minwidth : 'inherit')};
  text-align: ${props => (props.textalign ? props.textalign : 'inherit')};
  line-height: ${props => (props.lineheight ? props.lineheight : 'inherit')};
`;

export const CustomTypographLink = styled(CustomTypograph)`
  cursor: pointer;
  color: ${COLORS.greenText};
  font-size: ${props => (props.fontsize ? props.fontsize : '24px')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'inherit')};
`;

export const CustomTypographRed = styled(Typography)`
  color: ${COLORS.btnDangerColor};
`;

export const CustomTypographLowWeight = styled(Typography)`
  color: ${COLORS.grayText};
  font-weight: 400;
`;

export const CardDataDivider = styled('div')`
  height: 35px;
  width: 2px;
  margin: 0px 20px;
  display: block;
  background: #aaa;
  margin-top: 35px;
}
`;

export const MainCardData = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .label {
    font-size: 14px;
  }
  .main-data {
    font-size: 45px;
    font-weight: 500;
    color: ${COLORS.grayText};
  }
`;

export const SecondaryCardData = styled(MainCardData)`
  .main-data {
    font-size: 35px;
    color: #aaa;
    margin-block-start: 5px;
  }
`;

export const LineCardData = styled(MainCardData)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0px;
  }

  .main-data {
    font-size: 35px;
    margin-right: 10px;
    text-align: end;
    width: 25%;
  }

  .label {
    width: 75%;
    margin-bottom: 0px;
    font-size: 85%;
  }
`;

export const LineCardWrapper = styled('div')`
  display: flex;
  justify-content: ${props =>
    !props.jusfityContent ? 'center' : 'flex-start'};
`;

const InConstructionStyled = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  // opacity: 0.8;
  z-index: 9;
  background: #eeeeee50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InConstructionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const InConstructionContent = styled('div')`
  background: #f60;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  border-radius: 20px;
  transform: rotate(-30deg);
  svg {
    color: #fff;
  }
`;

export const CustomChartTitle = styled('div')`
  padding: 30px 0px 0px;
`;

export const CustomTextField = styled(TextField)`
  margin-left: 15px;
`;

export const CustonFilter = styled('div')`
  display: flex;
  align-items: center;
  gap: 2;
`;

export const AlertLabel = styled(Typography)`
  color: ${COLORS.btnDangerColor};
  font-size: 12px;
`;

export const CustonIcon = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  svg {
    font-size: 5.5rem;
    fill: ${COLORS.grayText}60;
  }
`;

export const CustomLabel = styled(Typography)`
  color: ${COLORS.blueText};
  font-weight: bold;
  font-size: 14px;
  line-height: 16.41px;
  margin-bottom: ${props =>
    props.marginBottom ? props.marginBottom : 'inherit'};
`;

export const CustomSublabel = styled(Typography)`
  color: ${COLORS.grayText}80;
  font-size: 16px;
  font-weight: bold;
`;

export const CustomSmall = styled('p')`
  font-size: 85%;
`;

export const CardContentWrapper = styled('div')`
  padding: 11px 22px 22px;
`;

export const StyledLabel = styled(Typography)`
  font-size: 14px;
`;

export const CustomGridContainer = styled(Grid)`
  position: absolute;
  height: 100vh;
  z-index: 1000;
  opacity: 0.5;
`;

export const CustomGridItem = styled(Grid)`
  height: 100%;
`;

export const GridItem = styled(Grid)`
  background: #fc0;
  height: 100%;
  width: 100%;
  display: block;
`;

export const StyledTypography = styled(Typography)`
  font-size: ${props => (props.fontSize ? props.fontSize : '24px')};
  font-weight: ${props => (props.weight ? props.weight : 'inherit')};
  line-height: ${props => (props.lineHeight ? props.lineHeight : 'inherit')};
  margin-top: ${props => (props.marginTop ? props.marginTop : 'inherit')};
  color: ${COLORS.grayText};
`;

export const ItemWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const CardItem = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'center'};
  gap: 16px;
  margin-top: 22px;
  width: 100%;
`;

export const GridOverlay = ({ spacing }) => {
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <CustomGridContainer container spacing={spacing}>
        <CustomGridItem item xs={1}>
          <GridItem />
        </CustomGridItem>
        <CustomGridItem item xs={1}>
          <GridItem />
        </CustomGridItem>
        <CustomGridItem item xs={1}>
          <GridItem />
        </CustomGridItem>
        <CustomGridItem item xs={1}>
          <GridItem />
        </CustomGridItem>
        <CustomGridItem item xs={1}>
          <GridItem />
        </CustomGridItem>
        <CustomGridItem item xs={1}>
          <GridItem />
        </CustomGridItem>
        <CustomGridItem item xs={1}>
          <GridItem />
        </CustomGridItem>
        <CustomGridItem item xs={1}>
          <GridItem />
        </CustomGridItem>
        <CustomGridItem item xs={1}>
          <GridItem />
        </CustomGridItem>
        <CustomGridItem item xs={1}>
          <GridItem />
        </CustomGridItem>
        <CustomGridItem item xs={1}>
          <GridItem />
        </CustomGridItem>
        <CustomGridItem item xs={1}>
          <GridItem />
        </CustomGridItem>
      </CustomGridContainer>
    </div>
  );
};

export const InConstruction = () => {
  return (
    <InConstructionStyled>
      <InConstructionWrapper>
        <InConstructionContent>
          <Build />
          <Typography>Dados em construção</Typography>
        </InConstructionContent>
      </InConstructionWrapper>
    </InConstructionStyled>
  );
};
