import { Box, Button, Link, Tooltip, Typography } from '@material-ui/core';
import { Gavel, QueryBuilder, WhatsApp } from '@material-ui/icons';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import StarIcon from '@material-ui/icons/Star';
import styled from 'styled-components';
import Warning from '../../../../icons/Warning';
import userService from '../../../../services/user.service';
import { COLORS } from '../../../../styles/settings/colors.config';
import tableHelper from '../../../../utils/tableHelper';
import timezoneFormat from '../../../../utils/timezoneFormat';
import SchedulePopover from '../components/SchedulePopover';
import { assertTicketDetailsUrl } from '../helpers/assertTicketsDetailUrl';

const scores = [1, 2, 3, 4, 5];

const TimeSLACount = styled('p')`
  font-weight: bold;
`;

const ClientAlert = styled('p')`
  color: ${(props) => (props.redAlert ? `${COLORS.btnDangerColor}` : '#000')};
  font-weight: ${(props) => (props.redAlert ? 'bold' : 'inherit')};
`;

export const getColumns = (
  t,
  data,
  defaultSortChanged,
  classes,
  formHelper,
  groups,
  setCurrentCancelProcess,
  setCurrentProtocol,
  setOpenReversalDialog
) => {
  const columns = [
    {
      name: 'id',
      label: t('i18n.ticketslistview.ID'),
      options: {
        setCellProps: (row, index) => ({
          style: tableHelper.setRowStyle(row, index, data)
        }),
        customBodyRender: (value, row) =>
          userService.hasRole('ticket_detail') ? (
            <>
              {row.rowData[3] > tableHelper.TOTAL_MINUTES_TWO_DAYS && (
                <div
                  style={{
                    display: 'block',
                    height: '100%',
                    background: '#c00',
                    width: '5px',
                    position: 'absolute',
                    left: '-57px',
                    top: '0px'
                  }}
                />
              )}
              <Typography style={{ width: 170, fontSize: 12 }}>
                <strong key={value}>
                  <Link
                    style={{ color: '#000' }}
                    href={`/${assertTicketDetailsUrl(row.rowData[5], value)}`}
                    rel="noopener"
                    target="_blank"
                  >
                    {value}
                  </Link>
                </strong>
              </Typography>
              {(row.rowData[5] === 'CUSTOMER_GUIDE_B2B' ||
                row.rowData[5] === 'CUSTOMER_GUIDE') &&
                scores.map((s) => (
                  <StarIcon
                    style={{
                      fill:
                        s <=
                        row.rowData[8].customerGuideServiceOpinionList?.[0]
                          ?.satisfactionScore
                          ? '#FADB14'
                          : '#F0F0F0'
                    }}
                  />
                ))}
            </>
          ) : (
            <strong key={value}>{value}</strong>
          )
      }
    },
    {
      name: 'creationDate',
      label: t('i18n.ticketslistview.OPENING_DATE_LIST'),
      options: {
        sort: true,
        customBodyRender: (value) => {
          return (
            <>
              <strong>{timezoneFormat(value)}</strong>
              <TimeSLACount days={tableHelper.timeDiffCalc(value).days}>
                {tableHelper.timeDiffCalc(value).difference}
              </TimeSLACount>
            </>
          );
        }
      }
    },
    {
      name: 'sla_hoursSinceCreation',
      label: '',
      options: {
        sort: true,
        customBodyRender: (value) => {
          const sla = tableHelper.formatMinutes(value, 'protocol');
          const slaAlertLimit = tableHelper.TOTAL_MINUTES_ONE_DAY;
          const slaWarningLimit = tableHelper.TOTAL_MINUTES_HALF_DAY;
          return (
            <span
              style={{
                color:
                  // eslint-disable-next-line no-nested-ternary
                  value >= slaAlertLimit
                    ? COLORS.btnDangerColor
                    : value >= slaWarningLimit
                    ? COLORS.WarningColorSLA
                    : COLORS.greenText,
                fontWeight: 'bold'
              }}
            >
              {sla}
            </span>
          );
        },
        customHeadLabelRender: () => {
          return (
            <div className="hoursSinceCreationDefaultHeader">
              {t('i18n.ticketslistview.WAITING_TIME')}
              {!defaultSortChanged && <ArrowDownwardIcon className="font18" />}
            </div>
          );
        }
      }
    },
    {
      name: 'ticketStatus',
      label: t('i18n.ticketslistview.STATUS_LIST'),
      options: {
        sort: true,
        customBodyRender: (value) => {
          const scheduled = false;

          return (
            <Box
              display="flex"
              justifyContent="left"
              alignItems="center"
              gridGap={5}
            >
              <strong
                style={{
                  color: value === 'NEW' ? COLORS.greenText : '#263238'
                }}
              >
                {t(`i18n.ticketslistview.ticketStatus.${value}`)}
              </strong>
              {scheduled && <QueryBuilder />}
            </Box>
          );
        }
      }
    },
    {
      name: 'ticketStatus',
      label: ' ',
      options: {
        sort: true,
        customBodyRender: (value, rowData) => {
          const chat = data[rowData.rowIndex];
          const agendamentoSchedule = chat?.protocolSchedulingResource;

          return (
            <div>
              <SchedulePopover id={chat.id} agendamento={agendamentoSchedule} />
            </div>
          );
        }
      }
    },
    {
      name: 'marketPlace',
      label: 'Marketplace',
      options: {
        sort: true,
        customBodyRender: (value, rowData) => {
          /** TODO: hasLegalProcesses logic */
          const row = data[rowData.rowIndex];
          const hasLegalProcesses = row?.marketPlaceJuridicalIdList?.length > 0;

          return (
            <>
              {t(`i18n.marketplaces.${value}`)}
              {hasLegalProcesses && (
                <Tooltip
                  title={`Este protocolo possui ${row?.marketPlaceJuridicalIdList?.length} processos`}
                >
                  <Gavel className={classes.icon} />
                </Tooltip>
              )}
            </>
          );
        }
      }
    },

    {
      name: 'serviceAttendantLogin',
      label: t('i18n.ticketslistview.ATTENDANT'),
      options: {
        sort: true
      }
    },

    {
      name: 'subject',
      label: t('i18n.ticketslistview.SUBJECT'),
      options: {
        sort: true,
        customBodyRender: (value, rowData) => {
          const row = data[rowData.rowIndex];
          if (row.subject === 'WHATSAPP') {
            return (
              <Box display="flex" alignItems="center">
                <span>Pergunta de pós-venda</span>
                <WhatsApp
                  style={{
                    color: COLORS.greenText,
                    fontSize: '16px',
                    marginLeft: '7px'
                  }}
                />
              </Box>
            );
          }
          return (
            <Typography style={{ width: 200, fontSize: 12 }}>
              {value}
            </Typography>
          );
        }
      }
    },
    {
      name: 'marketplaceBundle',
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'customerName',
      label: t('i18n.ticketslistview.CUSTOMER'),
      options: {
        sort: true,
        customBodyRender: (value, rowData) => {
          const row = data[rowData.rowIndex];
          const { vipClient } = row;
          const mlAlert =
            row.marketplaceBundle.mercadoLivreAfterSaleClaimEntity;
          const magaluAlert = row.marketplaceBundle?.reason === 'Reclamação';
          const redAlert = !!(vipClient || mlAlert || magaluAlert);

          return (
            <ClientAlert redAlert={redAlert}>
              {vipClient && <> * </>}
              <Typography style={{ width: 200, fontSize: 12 }} component="span">
                {value}
              </Typography>
              {(mlAlert || magaluAlert) && <> ! </>}
            </ClientAlert>
          );
        }
      }
    },
    {
      name: 'customerCPF',
      label: t('i18n.ticketslistview.DOCUMENT'),
      options: {
        setCellProps: () => ({
          style: { minWidth: '11.6em' }
        }),
        customBodyRender: (value, rowData) => {
          const row = data[rowData.rowIndex];
          const { cancellationProcess } = row;
          const reversalStatus =
            cancellationProcess?.lastStepName ===
            'Aguardando efetivação do estorno';

          const finished = cancellationProcess?.stepsTimeLine?.find(
            (step) =>
              step.name === 'Estorno realizado' ||
              step.name === 'Estorno não efetivado'
          );

          const fraudListKeys = row.historicCancellations
            ? Object.keys(row.historicCancellations)
            : ['cancellations', 'exchanges'];

          const renderFraudAlert = (ticketType, quantity, show) => {
            const tooltipMessage = () => {
              if (quantity === 3) {
                return `ATENÇÃO: esta é a 3ª solicitação de ${ticketType} deste mesmo CPF/CNPJ nos últimos 12 meses`;
              }

              if (quantity > 3) {
                return `ATENÇÃO: existem 3 ou mais solicitações de ${ticketType} deste mesmo CPF/CNPJ nos últimos 12 meses`;
              }

              return '';
            };

            if (quantity >= 3 && show)
              return (
                <Tooltip title={tooltipMessage()} key={Math.random()}>
                  <span>
                    <Warning
                      key={Math.random()}
                      style={{
                        fontSize: '14px',
                        marginLeft: '4px'
                      }}
                    />
                  </span>
                </Tooltip>
              );
          };

          return (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {formHelper.cpfCnpjFormat(value)}
                {fraudListKeys.map((item) => {
                  const ticketType =
                    item === 'cancellations' ? 'Cancelamento' : 'Troca';

                  const show =
                    data[rowData.rowIndex]?.reasonNew?.name === ticketType;
                  return renderFraudAlert(
                    ticketType,
                    row?.historicCancellations
                      ? row.historicCancellations[item].length
                      : 0,
                    show
                  );
                })}
              </div>
              {reversalStatus &&
                !finished &&
                groups.find(
                  (group) => group === 'admin' || group === 'especialista'
                ) && (
                  <div>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ fontSize: '9px' }}
                      onMouseDown={() => {
                        setCurrentCancelProcess(cancellationProcess);
                        setCurrentProtocol(row);
                        setOpenReversalDialog(true);
                      }}
                    >
                      Registrar estorno
                    </Button>
                  </div>
                )}
            </>
          );
        }
      }
    }
  ];

  return columns;
};
