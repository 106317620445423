import React from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Grid } from '@material-ui/core';
import { COLORS } from '../../../../styles/settings/colors.config';
import {
  CardWrapper,
  CardHeader,
  CardHeaderContent,
  CardText
} from './SectionTabs';

import marketplaceBundleLabel from '../../../../utils/marketplaceBundleLabelHelper';

const TicketMLBundle = ({ marketplaceBundle, data }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        borderTop: '1px solid #ccc',
        display: 'flex',
        width: '100%',
        paddingTop: '15px'
      }}
    >
      {(data?.marketplaceBundle?.idProtocoloCNOVA ||
        data?.marketplaceBundle?.protocol ||
        data?.marketPlace === 'NETSHOES' ||
        data?.marketPlace === 'VIVO' ||
        data?.marketplaceBundle?.prot) && (
        <Grid container>
          <Grid xs={4} lg={4} item>
            <CardWrapper>
              <CardHeader>
                {t('i18n.ticketcontainer.MARKETPLACE_PROTOCOL')}
              </CardHeader>
              <CardHeaderContent>
                <CardText>
                  {data?.marketplaceBundle?.idProtocoloCNOVA ||
                    data?.marketplaceBundle?.protocol ||
                    data?.marketplaceBundle?.codigoAtendimento ||
                    data?.marketplaceBundle?.prot ||
                    data?.marketplaceBundle?.protocolo ||
                    t('i18n.customerinfo.NOT_PROVIDED')}
                </CardText>
              </CardHeaderContent>
            </CardWrapper>
          </Grid>
        </Grid>
      )}

      {['KABUM', 'CARREFOUR'].includes(data?.marketPlace) &&
        data?.marketplaceBundle?.orders[0] && (
          <Grid container>
            <Grid xs={4} lg={4} item>
              <CardWrapper>
                <CardHeader>
                  {t('i18n.ticketslistview.INCIDENT_STATE')}
                </CardHeader>
                <CardHeaderContent>
                  <CardText>
                    {data?.marketplaceBundle?.orders[0]?.has_incident && (
                      <Chip
                        label={t('i18n.ticketslistview.INCIDENT_OPENED')}
                        style={{
                          backgroundColor: COLORS.WarningV2,
                          color: COLORS.orange
                        }}
                      />
                    )}

                    {!data?.marketplaceBundle?.orders[0]?.has_incident && (
                      <Chip
                        label={t('i18n.ticketslistview.INCIDENT_CLOSED')}
                        color="primary"
                      />
                    )}
                  </CardText>
                </CardHeaderContent>
              </CardWrapper>
            </Grid>
          </Grid>
        )}

      <Grid container>
        {data?.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity ? (
          <Grid xs={4} lg={2} item>
            <CardWrapper>
              <CardHeader>
                {t('i18n.ticketcontainer.COMPLAINT_RA_LINKED')}
              </CardHeader>
              <CardHeaderContent>
                <CardText>
                  {data?.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity
                    ?.claim_id || t('i18n.customerinfo.NOT_PROVIDED')}
                </CardText>
              </CardHeaderContent>
            </CardWrapper>
          </Grid>
        ) : (
          <></>
        )}

        {marketplaceBundle?.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity
          ?.type ? (
          <Grid xs={4} lg={2} item>
            <CardWrapper>
              <CardHeader>
                {t('i18n.ticketcontainer.TYPE')}
                :
              </CardHeader>
              <CardHeaderContent>
                <CardText>
                  {marketplaceBundleLabel(
                    marketplaceBundle?.marketplaceBundle
                      ?.mercadoLivreAfterSaleClaimEntity?.type
                  ) || t('i18n.customerinfo.NOT_PROVIDED')}
                </CardText>
              </CardHeaderContent>
            </CardWrapper>
          </Grid>
        ) : (
          <></>
        )}

        {marketplaceBundle?.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity
          ?.status ? (
          <Grid xs={4} lg={2} item>
            <CardWrapper>
              <CardHeader>
                {t('i18n.ticketcontainer.STATUS.RA')}
                :
              </CardHeader>
              <CardHeaderContent>
                <CardText>
                  {marketplaceBundleLabel(
                    marketplaceBundle?.marketplaceBundle
                      ?.mercadoLivreAfterSaleClaimEntity?.status
                  ) || t('i18n.customerinfo.NOT_PROVIDED')}
                </CardText>
              </CardHeaderContent>
            </CardWrapper>
          </Grid>
        ) : (
          <></>
        )}
        {marketplaceBundle?.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity
          ?.stage ? (
          <>
            <Grid xs={4} lg={2} item>
              <CardWrapper>
                <CardHeader>
                  {t('i18n.ticketcontainer.STAGE')}
                  :
                </CardHeader>
                <CardHeaderContent>
                  <CardText>
                    {marketplaceBundleLabel(
                      marketplaceBundle?.marketplaceBundle
                        ?.mercadoLivreAfterSaleClaimEntity?.stage
                    ) || t('i18n.customerinfo.NOT_PROVIDED')}
                  </CardText>
                </CardHeaderContent>
              </CardWrapper>
            </Grid>

            <Grid xs={4} lg={2} item>
              <CardWrapper>
                <CardHeader>
                  {t('i18n.newticket.REASON')}
                  :
                </CardHeader>
                <CardHeaderContent>
                  <CardText>
                    {marketplaceBundle?.marketplaceBundle
                      ?.mercadoLivreAfterSaleClaimEntity?.reason_detail ||
                      t('i18n.customerinfo.NOT_PROVIDED')}
                  </CardText>
                </CardHeaderContent>
              </CardWrapper>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </div>
  );
};

export default TicketMLBundle;
