import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import {
  Divider,
  ListItemIcon,
  makeStyles,
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import styled from 'styled-components';
import clsx from 'clsx';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';

import { COLORS } from '../../../../../styles/settings/colors.config';

import FilterForm from './filterForm.component';

const drawerWidth = 730;

const drawerUseStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  hide: {
    display: 'none'
  },
  drawer: {
    position: 'absolute',
    maxWidth: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    maxWidth: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '60px'
  },
  content: {
    marginTop: '67px',
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  paper: {
    zIndex: '200',
    position: 'relative'
  }
}));

const StyledFilterContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  height: 100%;

  .list-title {
    padding: 0 20px 8px;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    height: 48px;
    min-height: 30px;
  }

  .marketplace-list {
    width: 100%;
  }

  .filter-options {
    margin: 0 16px;
  }
`;

const StyledDrawer = styled(Drawer)`
  .menu-closed {
    max-width: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  > div {
    // max-height: calc(100vh - 60px);
    overflow-x: hidden;
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      width: 5px;
      background-color: ${COLORS.btnSecondaryColor};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${COLORS.btnSecondaryColor};
      border: 2px solid ${COLORS.btnSecondaryColor};
    }
  }
  ul {
    padding-bottom: 0;
  }
`;

const FilterDrawer = () => {
  const drawerClasses = drawerUseStyles();
  const [open, setOpen] = useState(false);
  const handleOpenCloseFilter = () => {
    setOpen(!open);
  };

  return (
    <StyledDrawer
      variant="permanent"
      className={clsx(drawerClasses.drawer, {
        [drawerClasses.drawerOpen]: open,
        [drawerClasses.drawerClose]: !open
      })}
      classes={{
        paper: clsx({
          [drawerClasses.drawerOpen]: open,
          [drawerClasses.drawerClose]: !open,
          [drawerClasses.paper]: drawerClasses.paper
        })
      }}
      PaperProps={{
        style: { marginLeft: '122px !important' }
      }}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
    >
      <List dense style={{ display: 'flex', width: '100%' }}>
        <ListItem
          style={{
            cursor: 'pointer'
          }}
        >
          <ListItemIcon onClick={handleOpenCloseFilter}>
            <Tooltip title={open ? 'Fechar filtro' : 'Abrir filtro'}>
              <FilterListIcon color="primary" />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Filtrar" />
        </ListItem>
        <ListItem
          style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Tooltip title="Fechar">
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </ListItem>
      </List>
      <Divider />

      <StyledFilterContainer>
        <div style={{ minWidth: '60px' }} />
        <FilterForm {...{ setOpen }} />
      </StyledFilterContainer>
    </StyledDrawer>
  );
};

export default FilterDrawer;
