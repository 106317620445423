import React from 'react';
import ModalExchangeRefund from '../../../MarketPlaceToolbar/b2w/ModalExchangeRefund';

export const ModalExchangeRefundIframe = ({
  ticketData,
  exchangeRefundModalState,
  setExchangeRefundModalState,
}) => {
  return (
    <ModalExchangeRefund
      ticketData={ticketData}
      openState={exchangeRefundModalState}
      closeParent={() => {
        setExchangeRefundModalState(false);
      }}
    />
  );
};
