import React, { useState } from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ArrowBackIos, ArrowForwardIos, Star } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ConfirmationModal from '../../../utils/ConfirmationModal';
import timezoneFormat from '../../../utils/timezoneFormat';
import {
  TabWrapper,
  TabDetails,
  TabDetailsFooter,
  TabContent,
  TabFirstMessage,
  TabBody,
  TabMessageFlex,
  TabWrapperDetails,
  TabIconButton,
  CustomDeleteOutlineIcon,
  TabIconButtonWrapper,
  TabQuestionProduct,
  TabImageText,
  MessageProductLink,
  TabLinkEllipsis,
  MessageEvaluation,
  MessageIndication
} from '../styles';
import API from '../../../services/api';
import { COLORS } from '../../../styles/settings/colors.config';
import {
  getProductLink,
  getProductFromBundle
} from '../services/presaleServices';

const TranslateStatus = (t, status) => {
  switch (status) {
    case 'NEW':
      return (
        <span style={{ color: COLORS.presaleOrange }}>
          {t('i18n.preSale.NEW')}
        </span>
      );
    case 'DELETED':
      return <span style={{ color: '#c00' }}>{t('i18n.preSale.DELETED')}</span>;
    case 'BANNED':
      return (
        <span style={{ color: COLORS.grayText }}>
          {t('i18n.preSale.BANNED')}
        </span>
      );
    case 'ANSWERED':
      return (
        <span style={{ color: COLORS.grayText }}>
          {t('i18n.preSale.ANSWERED')}
        </span>
      );
    case 'CLOSED_UNANSWERED':
      return (
        <span style={{ color: COLORS.grayText }}>
          {t('i18n.preSale.CLOSED_UNANSWERED')}
        </span>
      );
    case 'UNDER_REVIEW':
      return (
        <span style={{ color: COLORS.grayText }}>
          {t('i18n.preSale.UNDER_REVIEW')}
        </span>
      );
    default:
      return (
        <span style={{ color: COLORS.greenText }}>
          {t('i18n.newAutomation.NEW')}
        </span>
      );
  }
};

const MessageReview = ({ rating }) => {
  const rt = ['1', '2', '3', '4', '5'];

  return (
    <Box display="flex">
      {rt.map((item, index) => {
        return index < rating ? (
          <Star key={item} style={{ color: ' #ffcc00' }} />
        ) : (
          <Star key={item} color="disabled" />
        );
      })}
    </Box>
  );
};

const PreSaleTab = ({
  data,
  index,
  activateProtocol,
  setCurrentTicket,
  getData,
  haveMessage
}) => {
  const { t } = useTranslation();
  const questionMX = timezoneFormat(data.creationDate).split('T');
  const questionDate = `${questionMX[0].split(' ')[0]} às ${
    questionMX[0].split(' ')[1]
  }`;

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
  const [confirmationModalAction, setConfirmationModalAction] = useState();
  const status = data?.marketplaceBundle?.item?.status; // Status anúncio: pausado ou não
  const { enqueueSnackbar } = useSnackbar();

  const productInfo = getProductFromBundle(data);

  const handleBlockTicket =
    data.ticketStatus === 'CLOSED' ||
    data.ticketStatus === 'BLOCKED' ||
    data.ticketStatus === 'REMOVED'
      ? 'CLOSED'
      : 'NEW';

  const handleDeleteMLMessage = async id => {
    const url = `/auth/ms-ticket/v1/tickets/mercadolivre/delete-question/${id}`;

    API.delete(url)
      .then(() => {
        getData(true, false);
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.exception.ERROR_ON_DELETE_QUESTION'), {
          variant: 'error'
        });
      });
  };

  const handleQuestionWithoutAnswer = () => {
    setConfirmationModalAction(() => () => {
      setCurrentTicket({ data, index });
      // setUnsetMessage(false);
    });
    setConfirmationModalMessage(t('i18n.presale.WARNING_LEAVE_MSG'));
    setConfirmationModalOpen(true);
  };

  const atributedStatus = () => {
    if (!data.serviceAttendantLogin) {
      return t('i18n.presale.ASSIGNED_BY');
    }
    if (data.ticketStatus === 'NEW' && data.serviceAttendantLogin) {
      return t('i18n.presale.ASSIGNED_BY');
    }
    return t('i18n.presale.ANSWERED_BY');
  };

  return (
    <>
      <TabWrapper
        blocked={handleBlockTicket}
        className={activateProtocol === data.id && 'activate'}
        active={activateProtocol === data.id}
        id={data.id}
      >
        <TabWrapperDetails
          blocked={handleBlockTicket}
          className={activateProtocol === data.id && 'activate'}
          active={activateProtocol === data.id}
        >
          <div style={{ width: '100%', display: 'table-cell' }}>
            <TabDetails>
              <Typography style={{ padding: '10px 0px' }}>
                Status:{' '}
                <b>{TranslateStatus(t, data?.marketplaceBundle?.status)}</b>
              </Typography>

              {/* <div>
                <Typography style={{ padding: '10px 0px' }}>
                  Avaliação:
                  <span
                    style={{
                      color: COLORS.presaleOrange,
                      paddingLeft: '5px'
                    }}
                  >
                    <b>Privada</b>
                  </span>
                </Typography>
              </div> */}

              <Typography>
                {atributedStatus()}: <b>{data.serviceAttendantLogin}</b>
              </Typography>
            </TabDetails>

            <TabMessageFlex>
              <TabContent>
                <TabFirstMessage>
                  {data.ticketStatus === 'NEW' &&
                    data.marketPlace === 'MERCADO_LIVRE' && (
                      <Tooltip
                        title={t('i18n.presale.REMOVE_PROTOCOL')}
                        PopperProps={{
                          disablePortal: true,
                          popperOptions: {
                            positionFixed: true,
                            modifiers: {
                              preventOverflow: {
                                enabled: true,
                                boundariesElement: 'window'
                              }
                            }
                          }
                        }}
                      >
                        <CustomDeleteOutlineIcon
                          onClick={() => handleDeleteMLMessage(data.id)}
                        />
                      </Tooltip>
                    )}
                  <TabImageText>
                    <img
                      src={
                        productInfo?.picture ||
                        'https://http2.mlstatic.com/resources/frontend/statics/img-not-available/1.1.0/O.gif'
                      }
                      style={{ maxWidth: '60px' }}
                      alt="Ml"
                    />
                    <TabQuestionProduct>
                      <Tooltip
                        title={productInfo?.title}
                        PopperProps={{
                          disablePortal: true,
                          popperOptions: {
                            positionFixed: true,
                            modifiers: {
                              preventOverflow: {
                                enabled: true,
                                boundariesElement: 'window'
                              }
                            }
                          }
                        }}
                      >
                        <Typography>
                          <MessageProductLink
                            target="_blank"
                            href={getProductLink(productInfo, data.marketPlace)}
                            noreferrer
                          >
                            <TabLinkEllipsis>
                              {productInfo?.title}
                            </TabLinkEllipsis>
                          </MessageProductLink>
                        </Typography>
                      </Tooltip>
                      <MessageReview rating={data?.marketplaceBundle?.rating} />
                      <MessageEvaluation className="evaluationMessage">
                        {data?.marketplaceBundle?.review}
                      </MessageEvaluation>
                      <br />
                      <MessageIndication className="stars">
                        {data?.marketplaceBundle?.customFields[0]?.name}
                        <span>
                          {data?.marketplaceBundle?.customFields[0]?.values[0]}
                        </span>
                      </MessageIndication>
                    </TabQuestionProduct>
                  </TabImageText>
                </TabFirstMessage>
                <TabBody>
                  <Tooltip
                    title={t('i18n.ticketcontainer.RA_DATE')}
                    PopperProps={{
                      disablePortal: true,
                      popperOptions: {
                        positionFixed: true,
                        modifiers: {
                          preventOverflow: {
                            enabled: true,
                            boundariesElement: 'window'
                          }
                        }
                      }
                    }}
                  >
                    <Typography>{questionDate}</Typography>
                  </Tooltip>
                  <Typography style={{ padding: '10px 0px' }}>
                    Por: <b>{data?.customerName}</b>
                  </Typography>
                  {/* <Typography style={{ padding: '10px 0px' }}>
                    De: <b>Localização do cliente</b>
                  </Typography> */}
                </TabBody>
              </TabContent>
            </TabMessageFlex>

            {data.marketPlace === 'YOURVIEWS_REVIEW' && (
              <TabDetailsFooter>
                <Typography style={{ padding: '10px 0px' }}>
                  {t('i18n.ticketcontainer.PROTOCOL')}:{' '}
                  <b>{data?.marketplaceBundle?.reviewId}</b>
                </Typography>
                <Typography style={{ padding: '10px 0px' }}>
                  {t('i18n.aftersale.ACER_ID')}: <b>{data?.id}</b>
                </Typography>
                {/* <Typography style={{ padding: '10px 0px' }}>
                  {t('i18n.orderinfo.ORDER')}:
                  <b>{data.order_id}</b>
                </Typography> */}
              </TabDetailsFooter>
            )}
          </div>
          <div
            style={{
              display: 'table-cell',
              verticalAlign: 'middle',
              minWidth: '15px'
            }}
          >
            {status !== 'paused' && (
              <TabIconButton
                color="primary"
                onClick={() => {
                  if (activateProtocol !== data.id) {
                    // eslint-disable-next-line no-unused-expressions
                    haveMessage
                      ? handleQuestionWithoutAnswer()
                      : setCurrentTicket({ data, index });
                  }
                }}
                disabled
                size="medium"
              >
                {(data.ticketStatus === 'NEW' ||
                  data.ticketStatus === 'CLOSED') && (
                  <TabIconButtonWrapper>
                    {activateProtocol === data.id ? (
                      <ArrowBackIos style={{ marginLeft: '4px' }} />
                    ) : (
                      <ArrowForwardIos />
                    )}
                  </TabIconButtonWrapper>
                )}
              </TabIconButton>
            )}
          </div>
        </TabWrapperDetails>
      </TabWrapper>
      <ConfirmationModal
        title={t('i18n.simplewords.WARNING')}
        message={confirmationModalMessage}
        submitConfirmation={() => {
          confirmationModalAction();
          setConfirmationModalOpen(false);
        }}
        openState={confirmationModalOpen}
        closeParent={() => {
          setConfirmationModalOpen(false);
        }}
      />
    </>
  );
};

export default PreSaleTab;
