import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import FilterAccordion from './filterAccordion.component';
import FilterForm from './filterForm.component';
import {
  convertDateToFilter,
  defaultDateFormat
} from '../services/convertDate';
import indexHook from '../indexHook';
import API from '../../../services/api';
import formHelper from '../../../utils/formHelper';
import { filterNameInStorage } from './config';

const FilterDashboard = ({
  open,
  setOpen,
  dashboardFilter,
  setDashboardFilter
}) => {
  const reduxDispatcher = useDispatch();
  // const reportFilter = useSelector(
  //   state => state.reportsDashboardFilterReducer
  // );
  const { isAdmin, userName } = indexHook();

  const [filter, setFilter] = useState(dashboardFilter);
  const [submit, setSubmit] = useState(false);
  const [attendantList, setAttendantList] = useState([]);

  // const handleGetDashboard = currentFilter => {

  //   reduxDispatcher({ type: 'SET_REPORTS_DASHBOARD_LOADING', loading: true });

  //   const data = { ...currentFilter, ...{ attendant: undefined, marketplace: currentFilter.marketplace.toString().replace("ALL", "").replaceAll(",", " ") } };
  //   // console.log(data);

  //   API.post(`auth/lambda/reports`, data)
  //     .then(response => {
  //       setReports(response.data);
  //     })
  //     .catch(() => {
  //       enqueueSnackbar(t('i18n.dashboard.components.no_data'), {
  //         variant: 'error'
  //       });
  //     })
  //     .finally(() => {
  //       reduxDispatcher({
  //         type: 'SET_REPORTS_DASHBOARD_LOADING',
  //         loading: false
  //       });
  //     });
  // };

  useEffect(() => {
    if (submit) {
      const { startDate, endDate } = convertDateToFilter(filter);
      filter.startDate = startDate;
      filter.endDate = endDate;
    }

    if (filter.periodType === 'realTime') {
      filter.startDate = defaultDateFormat(
        moment(new Date('2021-10-01')).add(6, 'hours')
      );
    }

    filter.attendant = !isAdmin ? userName : filter?.attendant;

    setDashboardFilter(filter);
    // reduxDispatcher({
    //   type: 'SET_REPORTS_DASHBOARD_FILTER',
    //   filter: filter
    // });
  }, [filter, submit]);

  useEffect(() => {
    sessionStorage.setItem(
      filterNameInStorage,
      JSON.stringify(dashboardFilter)
    );
    reduxDispatcher({
      type: 'SET_REPORTS_DASHBOARD_FILTER',
      filter: dashboardFilter
    });
    // handleGetDashboard(dashboardFilter);
  }, [dashboardFilter]);

  useEffect(() => {
    API.get(`/auth/ms-users/groups/atendente/users`).then(response => {
      setAttendantList(response.data.sort(formHelper.compareName));
    });
  }, []);

  // useEffect(() => {
  //   console.log(reportFilter)
  // }, [reportFilter])

  return (
    <>
      <FilterAccordion
        open={open}
        setFilter={setFilter}
        setSubmit={setSubmit}
        filter={dashboardFilter}
        filterName="newfilter"
      />
      {open && (
        <FilterForm
          setFilter={setFilter}
          setSubmit={setSubmit}
          setOpen={setOpen}
          filter={filter}
          attendantList={attendantList}
        />
      )}
    </>
  );
};

export default FilterDashboard;
