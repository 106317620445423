import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ClientMessageCard = ({
  message,
  wrap = false,
  name = null,
  align = null
}) => {
  const { t } = useTranslation();
  return (
    <Card
      style={{
        display: 'flex',
        width: align ? '80%' : '100%',
        justifyContent: align || 'flex-start'
      }}
    >
      <CardContent style={{ width: '100%' }}>
        {wrap ? (
          <Typography>
            {message?.text?.split('\n').map(item => {
              return (
                <span key={Math.random()}>
                  {item.replace('<p>', '').replace('</p>', '')}
                  <br />
                </span>
              );
            })}
          </Typography>
        ) : (
          <Typography variant="h5" component="h2">
            {message.text}
          </Typography>
        )}
        <Grid container>
          <Grid item lg={6}>
            <Typography
              color="textSecondary"
              align="left"
              variant="caption"
              component="h2"
            >
              {moment(message.date).format('DD/MM/YYYY HH:mm')}
            </Typography>
          </Grid>
          <Grid item lg={6}>
            <Typography
              color="textSecondary"
              align="right"
              variant="caption"
              component="h2"
            >
              {name || t('i18n.chatview.CLIENT_MESSAGE')}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ClientMessageCard;
