import { Box, TextField } from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import {
  OrderSection,
  OrdersHeader,
  OrdersHeaderSection,
  OrdersHeaderTitle,
  OrdersHeaderValue,
  Transport,
  TransportSection,
  VerticalDivider
} from '../../../Dialogs/styles';
import { useStyles } from './styles';
import { retrieveOrderHeaderData } from '../../../functions/functions';

export default function OrderInfo({
  orderData,
  cancellingData,
  tracking,
  lastStep,
  setTrackingNumber,
  setDateReceiptAcer
}) {
  const classes = useStyles();

  const orderHeaderDataLine1 = retrieveOrderHeaderData(orderData, 1);
  const orderHeaderDataLine2 = retrieveOrderHeaderData(orderData, 2);
  const orderHeaderDataLine3 = retrieveOrderHeaderData(orderData, 3);

  return (
    <OrderSection>
      <OrdersHeader width="460px">
        <Box
          display="flex"
          justifyContent="space-between"
          marginBottom={1}
          alignItems="center"
        >
          {orderHeaderDataLine1.map(({ margin, title, value }) => (
            <OrdersHeaderSection margin={margin} key={Math.random()}>
              <OrdersHeaderTitle>{title}</OrdersHeaderTitle>
              <OrdersHeaderValue>{value}</OrdersHeaderValue>
            </OrdersHeaderSection>
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          marginBottom={1}
          alignItems="center"
        >
          {orderHeaderDataLine2.map(({ margin, title, value }) => (
            <OrdersHeaderSection margin={margin} key={Math.random()}>
              <OrdersHeaderTitle>{title}</OrdersHeaderTitle>
              <OrdersHeaderValue>{value}</OrdersHeaderValue>
            </OrdersHeaderSection>
          ))}
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {orderHeaderDataLine3.map(({ margin, title, value }) => (
            <OrdersHeaderSection margin={margin} key={Math.random()}>
              <OrdersHeaderTitle>{title}</OrdersHeaderTitle>
              <OrdersHeaderValue>{value}</OrdersHeaderValue>
            </OrdersHeaderSection>
          ))}
        </Box>
      </OrdersHeader>
      <VerticalDivider height="120px" />
      <Transport>
        <TransportSection>
          <Box marginRight="10px" width="120px">
            <OrdersHeaderTitle>CAP</OrdersHeaderTitle>
            <OrdersHeaderValue>
              {cancellingData?.reverseLogisticsEntity?.numeroColeta}
            </OrdersHeaderValue>
          </Box>
          <Box marginRight="10px" width="120px">
            <OrdersHeaderTitle>Data CAP</OrdersHeaderTitle>
            <OrdersHeaderValue>
              {moment(
                cancellingData?.reverseLogisticsEntity?.dataSolicitacao
              ).format('DD/MM/YYYY')}
            </OrdersHeaderValue>
          </Box>
          <Box marginRight="10px" width="120px">
            <OrdersHeaderTitle>Status CAP</OrdersHeaderTitle>
            <OrdersHeaderValue>
              {cancellingData?.reverseLogisticsEntity?.statusObjeto}
            </OrdersHeaderValue>
          </Box>
        </TransportSection>

        {!tracking && lastStep.name !== 'Estorno Efetivado' && (
          <TransportSection marginTop="5px">
            <Box marginRight="10px" width="300px">
              <OrdersHeaderTitle>Status</OrdersHeaderTitle>
              <OrdersHeaderValue>
                {cancellingData?.lastStatusTracking?.descricao}
              </OrdersHeaderValue>
            </Box>
          </TransportSection>
        )}

        {tracking ? (
          <TransportSection marginTop="20px">
            <Box marginRight="10px" width="150px">
              <OrdersHeaderTitle>Codigo Rastreamento</OrdersHeaderTitle>
              <div>
                <TextField
                  defaultValue={cancellingData.trackingNumber}
                  size="small"
                  variant="outlined"
                  onChange={e => {
                    setTrackingNumber(e.target.value);
                  }}
                  InputProps={{
                    className: classes.inputMedium
                  }}
                />
              </div>
            </Box>

            <Box marginRight="10px" width="130px">
              <OrdersHeaderTitle>Data Chegada</OrdersHeaderTitle>
              <div>
                <TextField
                  defaultValue={cancellingData.dateReceiptAcer}
                  size="small"
                  variant="outlined"
                  type="date"
                  onChange={e => {
                    setDateReceiptAcer(e.target.value);
                  }}
                  InputProps={{
                    className: classes.inputMedium
                  }}
                />
              </div>
            </Box>
          </TransportSection>
        ) : (
          <TransportSection marginTop="10px">
            <Box marginRight="10px" width="240px">
              <OrdersHeaderTitle>Parecer Final Logística</OrdersHeaderTitle>
              <OrdersHeaderValue>
                {cancellingData.statusLogistics || '-'}
              </OrdersHeaderValue>
            </Box>
            <Box marginRight="10px" width="120px">
              <OrdersHeaderTitle>Data Logística</OrdersHeaderTitle>
              <OrdersHeaderValue>
                {cancellingData?.stepsTimeLine?.find(t =>
                  t.name.includes('Logística')
                )?.date
                  ? moment(
                      cancellingData?.stepsTimeLine?.find(t =>
                        t.name.includes('Logística')
                      )?.date
                    ).format('DD/MM/YYYY')
                  : '-'}
              </OrdersHeaderValue>
            </Box>
          </TransportSection>
        )}

        {lastStep.name === 'Estorno Efetivado' && (
          <TransportSection marginTop="10px">
            <Box marginRight="10px" width="120px">
              <OrdersHeaderTitle>Estorno por</OrdersHeaderTitle>
              <OrdersHeaderValue>
                {cancellingData.reversedBy || '-'}
              </OrdersHeaderValue>
            </Box>
            <Box marginRight="10px" width="120px">
              <OrdersHeaderTitle>Responsável</OrdersHeaderTitle>
              <OrdersHeaderValue>{lastStep?.userName || '-'}</OrdersHeaderValue>
            </Box>
            <Box marginRight="10px" width="120px">
              <OrdersHeaderTitle>Comentários</OrdersHeaderTitle>
              <OrdersHeaderValue>
                {cancellingData?.financialDetail || '-'}
              </OrdersHeaderValue>
            </Box>
          </TransportSection>
        )}
      </Transport>
    </OrderSection>
  );
}
