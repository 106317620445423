import React from 'react';
import { useTranslation } from 'react-i18next';
import ProtocolCardDataWithDrillDown from '../../components/ProtocolCardDataWithDrillDown.component';

const ClaimMLCard = ({ isAdmin, dataIndividualDash, dataEquipDash }) => {
  const { t } = useTranslation();
  return (
    <ProtocolCardDataWithDrillDown
      title="claimML"
      showMoreDetails={false}
      isTeamView={isAdmin}
      firstLabel={t('i18n.dashboard.components.individual')}
      secondaryLabel={t('i18n.dashboard.components.equip')}
      firstData={dataIndividualDash?.reclamacao_ML}
      secondaryData={dataEquipDash?.reclamacao_ML}
      descriptionHint={t('i18n.dashboard.components.hint_complaints_ML')}
    />
  );
};

export default ClaimMLCard;
