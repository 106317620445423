import { Grid, Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ChatProtocolCardDataSideBySide from '../components/ChatProtocolCardDataSideBySide.component';
import MessagingCardData from '../components/MessagingCardData.component';
import { CustomTypograph } from '../components/custom.components';
import { COLORS } from '../../../styles/settings/colors.config';

const LabelSendedBy = (from, who) => (
  <>
    Enviadas {from} <b>{who}</b>
  </>
);

const DashboardContent = ({
  isAdmin,
  dataEquipDash,
  dataIndividualDash,
  dashboardFilter
  // marketPlacesSelected
}) => {
  const { t } = useTranslation();
  const { isRealTime } = dashboardFilter;
  const handleIsRealTime = (realTimeData, periodData) => {
    if (isRealTime) {
      return realTimeData;
    }
    return periodData;
  };

  return (
    <>
      <Grid container spacing={5}>
        <Grid item md={12}>
          <Grid container spacing={3} justifyContent="flex-start">
            <Grid item xl={12} lg={12} md={12}>
              <Box mb={2}>
                <CustomTypograph variant="h3">
                  {t('i18n.dashboard.components.protocols')} -{' '}
                  <b>{t('i18n.ticketslistview.TAB_MESSAGES')}</b>
                </CustomTypograph>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              lg={3}
              md={6}
              sm={12}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <ChatProtocolCardDataSideBySide
                isRealTime={isRealTime}
                cardType="openedProtocols"
                subtitle2="Por Status:"
                firstLabel={t('i18n.dashboard.components.individual')}
                secondaryLabel={t('i18n.dashboard.components.equip')}
                isTeamView={isAdmin}
                headerData
                firstData={handleIsRealTime(
                  dataIndividualDash?.abertos,
                  dataIndividualDash?.abertos
                )}
                secondaryData={handleIsRealTime(
                  dataEquipDash?.abertos_geral,
                  dataEquipDash?.abertos_geral
                )}
                descriptionHint={
                  isRealTime
                    ? t('i18n.dashboard.components.hint.real_time')
                    : t('i18n.dashboard.components.hint.open_in_period')
                }
              />
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              sm={12}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <MessagingCardData
                descriptionHint={t(
                  'i18n.dashboard.components.hint.messages.generics'
                )}
                cardType="generic"
                subtitle="Total nos protocolos:"
                subtitle2="Média por protocolo:"
                headerData
                firstLabel={t('i18n.dashboard.components.individual')}
                secondaryLabel={t('i18n.dashboard.components.equip')}
                firstData={dataIndividualDash?.total_mensagens}
                secondaryData={dataEquipDash?.total_mensagens}
                isTeamView={isAdmin}
                showMoreDetails
                extraData={[
                  {
                    label: LabelSendedBy('pelo', 'Cliente'),
                    color: COLORS.grayText,
                    value: isAdmin
                      ? dataEquipDash?.total_enviada_cliente
                      : dataIndividualDash?.total_enviada_cliente,
                    divider: false,
                    show: true
                  },
                  {
                    label: LabelSendedBy('pela', 'Acer'),
                    color: COLORS.grayText,
                    value: isAdmin
                      ? dataEquipDash?.total_enviada_acer
                      : dataIndividualDash?.total_enviada_acer,
                    divider: false,
                    show: true
                  }
                ]}
                mediumData={[
                  {
                    label: 'Cliente + Acer',
                    color: COLORS.grayText,
                    value: isAdmin
                      ? dataEquipDash?.total_media_mensagens
                      : dataIndividualDash?.total_media_mensagens,
                    divider: false,
                    show: true
                  },
                  {
                    label: LabelSendedBy('pelo', 'Cliente'),
                    color: COLORS.grayText,
                    value: isAdmin
                      ? dataEquipDash?.total_media_mensagens_cliente
                      : dataIndividualDash?.total_media_mensagens_cliente,
                    divider: false,
                    show: true
                  },
                  {
                    label: LabelSendedBy('pela', 'Acer'),
                    color: COLORS.grayText,
                    value: isAdmin
                      ? dataEquipDash?.total_media_mensagens_acer
                      : dataIndividualDash?.total_media_mensagens_acer,
                    divider: false,
                    show: true
                  }
                ]}
              />
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={12}
              xs={12}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <MessagingCardData
                descriptionHint={t(
                  'i18n.dashboard.components.hint.messages.other'
                )}
                cardType="other"
                headerData
                firstLabel={t('i18n.dashboard.components.individual')}
                secondaryLabel={t('i18n.dashboard.components.equip')}
                firstData={dataIndividualDash?.qtd_volume_espec_msg_total}
                secondaryData={dataEquipDash?.qtd_volume_espec_msg_total}
                isTeamView={isAdmin}
                showMoreDetails
                extraData={[
                  {
                    label: LabelSendedBy('pelo', 'Cliente'),
                    color: COLORS.grayText,
                    value: isAdmin
                      ? dataEquipDash?.qtd_volume_espec_msg_cliente
                      : dataIndividualDash?.qtd_volume_espec_msg_cliente,
                    divider: false,
                    show: true
                  },
                  {
                    label: LabelSendedBy('pela', 'Acer'),
                    color: COLORS.grayText,
                    value: isAdmin
                      ? dataEquipDash?.qtd_volume_espec_msg_acer
                      : dataIndividualDash?.qtd_volume_espec_msg_acer,
                    divider: false,
                    show: true
                  },
                  {
                    label: LabelSendedBy('pelo', 'Marketplace'),
                    color: COLORS.grayText,
                    value: isAdmin
                      ? dataEquipDash?.qtd_volume_espec_msg_marketplace
                      : dataIndividualDash?.qtd_volume_espec_msg_marketplace,
                    divider: false,
                    show: true
                  }
                ]}
              />
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={12}
              xs={12}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <MessagingCardData
                descriptionHint={t(
                  'i18n.dashboard.components.hint.messages.total'
                )}
                cardType="total"
                headerData
                firstLabel={t('i18n.dashboard.components.individual')}
                secondaryLabel={t('i18n.dashboard.components.equip')}
                firstData={dataIndividualDash?.total_geral_msg}
                secondaryData={dataEquipDash?.total_geral_msg}
                isTeamView={isAdmin}
                showMoreDetails
                extraData={[
                  {
                    label: LabelSendedBy('pelo', 'Cliente'),
                    color: COLORS.grayText,
                    value: isAdmin
                      ? dataEquipDash?.total_geral_cliente
                      : dataIndividualDash?.total_geral_cliente,
                    divider: false,
                    show: true
                  },
                  {
                    label: LabelSendedBy('pela', 'Acer'),
                    color: COLORS.grayText,
                    value: isAdmin
                      ? dataEquipDash?.total_geral_acer
                      : dataIndividualDash?.total_geral_acer,
                    divider: false,
                    show: true
                  },
                  {
                    label: LabelSendedBy('pelo', 'Marketplace'),
                    color: COLORS.grayText,
                    value: isAdmin
                      ? dataEquipDash?.qtd_volume_espec_msg_marketplace
                      : dataIndividualDash?.qtd_volume_espec_msg_marketplace,
                    divider: false,
                    show: true
                  }
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardContent;
