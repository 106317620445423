import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../../styles/settings/colors.config';
import 'react-image-lightbox/style.css';
import MessageBaloonInside from '../components/MessageBaloonInside.component';

const ClientMessageCard = ({
  message,
  customerName,
  mlClaimId,
  magaluMediation,
  marketPlace,
  idIncidenteCNOVA,
  typeMessage
}) => {
  const { t } = useTranslation();
  let colorDefault = COLORS.cardMessageWhite;
  if (mlClaimId || magaluMediation) {
    colorDefault = COLORS.cardMessageYellow;
  } else if (['MARKETPLACE', 'BOT'].includes(customerName)) {
    colorDefault = COLORS.cardMessagePink;
  } else if (typeMessage === 'b2wHelpMessage' && customerName === 'EMPLOYEE') {
    colorDefault = COLORS.cardMessageYellow;
  }

  const firstCustomerName = customerName ? customerName.split(' ')[0] : null;

  const senderName = () => {
    if (firstCustomerName === null) {
      return message.agent;
    }
    if (['MARKETPLACE', 'BOT'].includes(firstCustomerName)) {
      return firstCustomerName;
    }
    if (
      ['EMPLOYEE'].includes(firstCustomerName) &&
      typeMessage === 'b2wHelpMessage'
    ) {
      return `Marketplace: ${message.username}`;
    }

    return `${t('i18n.ticketslistview.CUSTOMER')}: ${firstCustomerName} `;
  };

  return (
    <MessageBaloonInside
      {...{
        marketPlace,
        mlClaimId,
        idIncidenteCNOVA,
        colorDefault,
        message,
        senderName
      }}
    />
  );
};

export default ClientMessageCard;
