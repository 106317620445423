import React from 'react';
import { Card, Divider, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import handleOpenNewTabHook from './handleGetTabFilterHook';
import formHelper from '../../../utils/formHelper';

import {
  MainCardData,
  CardDataWrapper,
  CustomCardContent,
  CardTitle,
  CustomTypographLink,
  CardTitleTypography
} from '../styles';
import CardHint from './CardHint';
import IndividualDataCard from './IndividualDataCard';
import { COLORS } from '../../../styles/settings/colors.config';

const cardTitle = cardType => {
  switch (cardType) {
    case 'withoutReason': {
      return <b>Sem Motivos</b>;
    }
    case 'claimML': {
      return (
        <>
          <b>
            Com Reclamação <span style={{ color: COLORS.blueText }}>ML</span>
          </b>
        </>
      );
    }
    case 'magaluClaim': {
      return (
        <>
          <b>
            Com Reclamação{' '}
            <span style={{ color: COLORS.blueText }}>Magalu</span>
          </b>
        </>
      );
    }
    case 'mediation': {
      return <b>Com Mediação</b>;
    }

    case 'cnovaClaim': {
      return (
        <b>
          Com Reclamação <span style={{ color: COLORS.blueText }}>CNOVA</span>
        </b>
      );
    }
    default: {
      return '';
    }
  }
};

const GoalPercentInfo = ({ goalPercent, currentPercent }) => {
  const percentColor = () => {
    if (currentPercent === 0) {
      return '#999999';
    }

    if (currentPercent * 100 > goalPercent) {
      return COLORS.btnDangerColor;
    }

    return '#999999';
  };

  const filterMarketPlace = JSON.parse(sessionStorage.getItem('newfilter'));

  return (
    <div style={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '500' }}>
      {filterMarketPlace?.marketplace[0] !== 'CUSTOMER_GUIDE_B2B' ? (
        <>
          <span
            style={{
              color: percentColor(),
              marginRight: '8px'
            }}
          >
            {_.isNaN(currentPercent) ? 0 : Math.round(currentPercent * 100)}%
          </span>
          <span style={{ color: '#4F88C4' }}>{`Meta <= ${goalPercent}%`}</span>
        </>
      ) : null}
    </div>
  );
};

const TeamDataCard = ({
  secondaryLabel,
  withDrillDown,
  handleOpenNewTab,
  secondaryData,
  goalInfo,
  goalPercent,
  currentPercent,
  title
}) => (
  <MainCardData>
    <p className="label">{secondaryLabel}</p>
    {withDrillDown ? (
      <CustomTypographLink
        align="right"
        style={{
          fontSize: '30px',
          fontWeight: '500'
        }}
        onClick={() => {
          handleOpenNewTab(title);
        }}
      >
        {secondaryData}
      </CustomTypographLink>
    ) : (
      <p className="main-data">{secondaryData}</p>
    )}
    {goalInfo && <GoalPercentInfo {...{ goalPercent, currentPercent }} />}
  </MainCardData>
);

const ProtocolCardDataWithDrillDown = ({
  isTeamView,
  firstLabel,
  firstData,
  secondaryLabel,
  secondaryData,
  descriptionHint,
  title,
  goalInfo,
  goalPercent,
  currentPercent,
  withDrillDown = true
}) => {
  firstData = formHelper.addZeroToCompleteTwoDigits(firstData);
  secondaryData = formHelper.addZeroToCompleteTwoDigits(secondaryData);

  const { t } = useTranslation();

  const dashboardFilter = useSelector(state => state.dashboardFilterReducer);
  const { handleOpenNewTab } = handleOpenNewTabHook({
    dashboardFilter,
    t,
    key: title
  });

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Card style={{ width: '100%', borderRadius: '20px' }}>
        <CardTitle>
          <CardTitleTypography variant="h5">
            {cardTitle(title)}
          </CardTitleTypography>
          <Box style={{ gap: '10px' }} display="flex" justifyContent="flex-end">
            {descriptionHint && <CardHint hint={descriptionHint} />}
          </Box>
        </CardTitle>
        <Divider />
        <CustomCardContent>
          <CardDataWrapper>
            {!isTeamView && (
              <IndividualDataCard
                {...{
                  firstLabel,
                  secondaryLabel,
                  handleOpenNewTab,
                  firstData,
                  secondaryData,
                  withDrillDown,
                  title
                }}
              />
            )}

            {isTeamView && (
              <TeamDataCard
                {...{
                  secondaryLabel,
                  withDrillDown,
                  handleOpenNewTab,
                  secondaryData,
                  title,
                  goalPercent,
                  currentPercent,
                  goalInfo
                }}
              />
            )}
          </CardDataWrapper>
        </CustomCardContent>
      </Card>
    </div>
  );
};

export default ProtocolCardDataWithDrillDown;
