import { Box, Card, Divider } from '@material-ui/core';
import React, { Fragment } from 'react';
import { CardTitle, CustomTypograph } from '../../../../dashboardV2/styles';
import { COLORS } from '../../../../../styles/settings/colors.config';
import ClientMessageCard from '../../../../chats/chat/ClientMessageCard';
import AgentMessageCard from '../../../../chats/chat/AgentMessageCard';

const ConversationCard = ({ selectedSupport }) => {
  const { ticketDetails, ticketComments } = selectedSupport;

  const firstMessage = {
    date: ticketDetails[0].creationDate,
    text: ticketDetails[0].description
  };

  return (
    <Card
      style={{
        width: '100%',
        borderRadius: '20px'
      }}
    >
      <CardTitle>
        <CustomTypograph variant="h5">
          Conversa com Equipe Americanas Marketplace
        </CustomTypograph>
      </CardTitle>
      <Divider />
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        style={{ backgroundColor: COLORS.backgroundGray }}
        padding="10px"
        maxHeight="350px"
        overflow="auto"
      >
        {ticketComments.map(comment => {
          const message = {
            text: comment.comment,
            date: comment.createdDate
          };

          if (comment.whoName === 'suporte') {
            return (
              <Fragment key={Math.random()}>
                <Box display="flex" width="100%">
                  <ClientMessageCard
                    message={message}
                    wrap
                    align="flex-start"
                    name={
                      comment.commentOwner === 'Integração Portal'
                        ? 'Acer'
                        : comment.commentOwner
                    }
                  />
                </Box>
                <br />
              </Fragment>
            );
          }
          return (
            <Fragment key={Math.random()}>
              <Box display="flex" width="100%">
                <AgentMessageCard
                  message={message}
                  wrap
                  key={Math.random()}
                  bg
                  align="flex-end"
                  name={
                    comment.commentOwner === 'Integração Portal'
                      ? 'Acer'
                      : comment.commentOwner
                  }
                />
              </Box>
              <br />
            </Fragment>
          );
        })}
        <Box display="flex" justifyContent="flex-end" width="100%">
          <AgentMessageCard message={firstMessage} wrap bg align="flex-end" />
        </Box>
      </Box>
    </Card>
  );
};

export default ConversationCard;
