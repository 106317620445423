/* eslint-disable no-unused-vars */
import { CircularProgress, Grid, makeStyles, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useSnackbar } from 'notistack';
import API from '../../../services/api';
import ChatAverageTimeCard from '../components/ChatAverageTime.component';
import AverageTimeCard from '../components/AverageTime.component';
import BarChartDashboard from '../components/BarChartDashboard.component';
// import DonutChartDashboard from '../components/DonutChartDashboard.component';
import ChatProtocolCardData from '../components/ChatProtocolCardData.component';
import SatisfactionCard from '../components/Satisfaction.component';
import ProtocolAttendanceCompare from '../components/ChatProtocolAttendanceCompare.component';
import GaugeChartDashboard from '../components/ChatGaugeChartDashboard.component';

import { CustomTypograph } from '../styles';

const useStyles = makeStyles(theme => ({
  downloadIconContainer: {
    maxWidth: '35px',
    marginLeft: '3px'
  },
  downloadIcon: {
    cursor: 'pointer',
    fill: theme.palette.primary.main,
    '&:hover': {
      fill: theme.palette.primary.dark
    }
  }
}));

const checkShowBarChart = status => {
  if (status === 'previous_month') {
    return false;
  }

  if (status === 'last_six_months') {
    return false;
  }

  if (status === 'in_current_year') {
    return false;
  }

  if (status === null) {
    return false;
  }

  return true;
};

const DashboardContent = ({
  dataEquipDashChat,
  dataIndividualDashChat,
  isAdmin,
  dashboardFilter
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { isRealTime } = dashboardFilter;

  const showBarChart = checkShowBarChart(
    dashboardFilter.dateAliasInAWayHumanCanRead
  );
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item md={8}>
          <Grid container spacing={3} justifyContent="flex-start">
            <Grid item xl={11} lg={11} md={11}>
              <CustomTypograph variant="h3">
                {t('i18n.dashboard.components.chats')}
              </CustomTypograph>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={4}>
              {/* ABERTOS NO PERÍODO */}
              <ChatProtocolCardData
                title={
                  !isRealTime
                    ? t('i18n.dashboard.components.open_in_period')
                    : t('i18n.dashboard.components.finished')
                }
                isTeamView={isAdmin}
                headerData
                firstLabel={t('i18n.dashboard.components.individual')}
                secondaryLabel={t('i18n.dashboard.components.equip')}
                firstData={dataIndividualDashChat?.total_atendidos}
                secondaryData={dataEquipDashChat?.n_atendimentos_geral}
                descriptionHint={t(
                  'i18n.dashboard.components.hint.opened_chats'
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardContent;
