import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  RadioGroup,
  Radio,
  ThemeProvider,
  makeStyles
  // makeStyles
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import statusEnum from '../../../enums/ticketsStatus.enum';
import API from '../../../services/api';
import './styles.css';
import { COLORS } from '../../../styles/settings/colors.config';
import marketPlacesEnum from '../../../enums/marketPlaces.enum';

import { inputStyles, theme } from '../../../components/inputStyle';

import FilterAttendants from './components/FilterAttendants';

const FilterWrapper = styled('div')`
  overflow-y: auto;
  overflow-x: inherit;
  height: 100%;
  margin-bottom: 20px;
  padding-right: 15px;

  @media (min-width: 1367px) {
    min-height: 539px;
  }

  @media (max-width: 1366px) {
    max-height: inherit;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: ${COLORS.btnSecondaryColor};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.btnSecondaryColor};
    border: 2px solid ${COLORS.btnSecondaryColor};
  }

  @media (max-width: 1366px) {
    max-height: 390px;
  }
`;

const FilterHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${COLORS.grayText};
  svg {
    font-size: 20px;
    color: ${COLORS.grayText};
  }
`;

const CustomBox = styled(Box)`
  width: 560px;
  padding: 12px 24px 24px;
  border: 0;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #ccc;
  @media (min-width: 1367px) {
    min-height: 650px;
  }
`;

const StyledSelect = styled(Select)`
  -webkit-appearance: none;

  option {
    -webkit-appearance: none;
  }

  option:checked {
    background-color: #d6ffc8;
    background-opacity: 6%;
  }

  background-color: #ffffff;
`;

const useStyles = makeStyles(() => ({
  selectRoot: {
    '&:focus': {
      backgroundColor: '#ffffff'
    }
  }
}));

const ShortcutContainer = ({
  shortcutData,
  handleRefreshShortcut,
  setOpen,
  setCreatedShortcut,
  setShowCreateShortcut
}) => {
  const { t } = useTranslation();
  const classes = inputStyles();
  const localClasses = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading] = useState(false);
  const [inputValueCreationDate, setInputValueCreationDate] = useState({
    from: '',
    to: ''
  });
  const [dateFrom, setDateFrom] = useState('');
  const [timeFrom, setTimeFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [timeTo, setTimeTo] = useState('');
  const [marketplacesSelected, setMarketplacesSelected] = useState([]);
  const [shortCutName, setShortCutName] = useState('');
  const [checkedWhatsapp, setCheckedWhatsapp] = useState('');
  const [filterForm, setFilterForm] = useState({});
  const [sendDisabled, setSendDisabled] = useState(false);

  const changeDateTime = (toOrFrom, date, time) => {
    if (!time && date) {
      time = 'T00:00';
      // eslint-disable-next-line no-unused-expressions
      toOrFrom === 'from' ? setTimeFrom('00:00') : setTimeTo('23:59');
    }
    if (time) {
      time = `T${time}`;
    }
    if (!date && time) {
      date = moment(Date.now()).format('YYYY-MM-DD');
      // eslint-disable-next-line no-unused-expressions
      toOrFrom === 'from' ? setDateFrom(date) : setDateTo(date);
    }
    const newState = { ...inputValueCreationDate };
    // eslint-disable-next-line no-unused-expressions
    toOrFrom === 'from'
      ? (newState.from = `${date}${time}`)
      : (newState.to = `${date}${time}`);
    setInputValueCreationDate(newState);
  };

  const handleChangeMultiple = event => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setMarketplacesSelected(value);
  };

  const handleFormatDate = (date, lessday = false) => {
    const currentdate = date.split('-');
    const day = currentdate[0];
    const month = currentdate[1];
    const year = currentdate[2];
    let newDate;
    if (lessday) {
      newDate = moment(`${month}-${day}-${year}`)
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
    } else {
      newDate = moment(`${month}-${day}-${year}`).format('YYYY-MM-DD');
    }
    return newDate;
  };

  const handleSetDate = creationDate => {
    const datefrom = creationDate
      .split('-')[0]
      .split(' ')[0]
      .replaceAll('/', '-');
    const dateto = creationDate
      .split('-')[1]
      .split(' ')[0]
      .replaceAll('/', '-');
    setDateFrom(handleFormatDate(datefrom));
    setDateTo(handleFormatDate(dateto, true));
  };

  const handleSubmitShortcut = () => {
    setSendDisabled(true);
    let creationDate;

    if (inputValueCreationDate.from && inputValueCreationDate.to) {
      creationDate = `${moment
        .utc(inputValueCreationDate.from)
        .utcOffset(new Date().getTimezoneOffset())
        .format('DD/MM/YYYY HH:mm:00')}-${moment
        .utc(inputValueCreationDate.to)
        .utcOffset(new Date().getTimezoneOffset())
        .format('DD/MM/YYYY HH:mm:59')}`;
    }

    if (!shortCutName) {
      enqueueSnackbar('Informe um nome para o atalho.', {
        variant: 'error'
      });
      setSendDisabled(false);
      return false;
    }

    const shortcut = {
      name: shortCutName,
      fields: JSON.stringify({
        creationDate,
        filtersB2W: filterForm.filtersB2W,
        marketPlace: marketplacesSelected.join(),
        reclamacaoMercadoLivre: filterForm.reclamacaoMercadoLivre,
        serviceAttendantLogin: filterForm.serviceAttendantLogin,
        ticketStatus: filterForm.ticketStatus,
        ticketType: 'AFTER_SALE',
        whatsapp: checkedWhatsapp,
        reclameAqui: filterForm.reclameAqui,
        sla: filterForm.sla,
        mediatedOpened: filterForm.mediatedOpened,
        mediatedClosed: filterForm.mediatedClosed,
        incidentOpened: filterForm.incidentOpened,
        incidentClosed: filterForm.incidentClosed
      })
    };

    try {
      API.post('/auth/ms-ticket/v1/protocol-filter/create', shortcut).then(
        response => {
          handleRefreshShortcut();
          enqueueSnackbar('Atalho salvo com sucesso.', {
            variant: 'success'
          });
          setCreatedShortcut(response.data);
          setOpen(false);
          setSendDisabled(false);
        }
      );
    } catch (e) {
      enqueueSnackbar('Erro salvando atalho.', {
        variant: 'error'
      });
      setSendDisabled(false);
    }
  };

  useEffect(() => {
    changeDateTime('from', dateFrom, timeFrom);
  }, [dateFrom, timeFrom]);

  useEffect(() => {
    changeDateTime('to', dateTo, timeTo);
  }, [dateTo, timeTo]);

  useEffect(() => {
    if (shortcutData.fields) {
      setShortCutName(shortcutData.name);
      setMarketplacesSelected(shortcutData.fields.marketPlace);
      handleSetDate(shortcutData.fields.creationDate);
      setFilterForm(shortcutData.fields);
      setCheckedWhatsapp(shortcutData.fields.whatsapp);
    }

    if (!shortcutData.name) {
      setShortCutName('');
      setFilterForm({});
      setMarketplacesSelected([]);
      setDateTo('');
      setDateFrom('');
      setCheckedWhatsapp('');
    }
  }, [shortcutData]);

  const SHORTCUT_MAX_LENGTH = 15;

  return (
    <ThemeProvider theme={theme}>
      <Grid container style={{ backgroundColor: '#f4f6f8' }}>
        <Grid item>
          <CustomBox>
            <FilterWrapper>
              <FilterHeader>
                <Typography variant="body1">
                  {t('i18n.ticketslistview.MORE_FILTER_OPTIONS')} -{' '}
                  {shortcutData?.name || 'Criação de atalho'}
                </Typography>
                {/* <Tooltip title="Excluir filtro">
                  <DeleteIcon onClick={() => handleDeleteShortcut()} />
                </Tooltip> */}
              </FilterHeader>
              <br />
              <Divider />
              <br />
              {!isLoading && (
                <>
                  <Box mb={2}>
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          size="small"
                          value={shortCutName}
                          // fullWidth
                          style={{ width: '285px' }}
                          className={classes.inputSmall}
                          onChange={e => setShortCutName(e.target.value)}
                          label="Nome para o atalho"
                          variant="outlined"
                          inputProps={{ maxLength: SHORTCUT_MAX_LENGTH }}
                        />
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          style={{ width: '285px' }}
                        >
                          <Typography
                            style={{ color: COLORS.grayText, fontSize: '12px' }}
                          >
                            {shortCutName.length}/{SHORTCUT_MAX_LENGTH}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mb={2}>
                    <Typography>
                      {t('i18n.shortcut.SELECT_MARKETPLACES')}:
                    </Typography>

                    <StyledSelect
                      multiple
                      native
                      label="Marketplace"
                      classes={{ root: localClasses.selectRoot }}
                      fullWidth
                      variant="outlined"
                      value={marketplacesSelected}
                      onChange={handleChangeMultiple}
                      inputProps={{
                        id: 'select-multiple-native'
                      }}
                    >
                      {/* <option value="ALL">Todos os marketplaces</option> */}
                      {Object.keys(marketPlacesEnum).map(key => {
                        return (
                          <option value={key} key={key}>
                            {t(`i18n.marketplaces.${key}`)}
                          </option>
                        );
                      })}
                    </StyledSelect>
                  </Box>

                  <FilterAttendants
                    {...{ classes }}
                    filterForm={filterForm}
                    setFilterForm={setFilterForm}
                  />

                  <Box mb={2}>
                    <Grid container>
                      <Grid item xs={7}>
                        <FormControl variant="outlined" fullWidth size="small">
                          <InputLabel>
                            {t('i18n.ticketslistview.STATUS')}
                          </InputLabel>
                          <Select
                            defaultValue=""
                            value={filterForm.ticketStatus || ''}
                            onChange={htmlElemtEvent => {
                              setFilterForm({
                                ...filterForm,
                                ticketStatus: htmlElemtEvent.target.value
                              });
                            }}
                            className="small-input"
                            size="small"
                            label={t('i18n.ticketslistview.STATUS')}
                          >
                            <MenuItem value="">
                              <em>{t('i18n.ticketslistview.NONE')}</em>
                            </MenuItem>
                            {statusEnum.map(status => {
                              return (
                                <MenuItem value={status} key={status}>
                                  {t(
                                    `i18n.ticketslistview.ticketStatus.${status}`
                                  )}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
              {/* Whatsapp comentado em 16/03/2022 para publicação da R3 - Descomentar assim que liberado pelo cliente */}
              {/* {marketplacesSelected.includes('CUSTOMER_GUIDE') && (
                <Box>
                  <Grid container display="flex" alignItems="center">
                    <Grid item xs={6}>
                      <Typography>
                        {t('i18n.ticketslistview.SERVICE_CHANNEL')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        component="fieldset"
                        style={{
                          display: 'flex',
                          gap: '20px',
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        <RadioGroup
                          value={checkedWhatsapp || ''}
                          onChange={htmlElemtEvent => {
                            setCheckedWhatsapp(htmlElemtEvent.target.value);
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            style={{
                              gap: '32px'
                            }}
                          >
                            <FormControlLabel
                              value="false"
                              control={
                                <Radio
                                  className={classes.radio}
                                  color="primary"
                                />
                              }
                              label="Site"
                            />
                            <FormControlLabel
                              value="true"
                              control={
                                <Radio
                                  className={classes.radio}
                                  color="primary"
                                />
                              }
                              label="WhatsApp"
                            />
                          </Box>
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              )} */}
              <Box mt={1}>
                <Typography
                  gutterBottom
                  variant="h5"
                  style={{ marginTop: '15px' }}
                >
                  Filtrar somente por:
                </Typography>
              </Box>
              {/* RECLAME AQUI */}
              <Grid container display="flex" alignItems="center">
                <Grid item xs={6}>
                  <Typography>
                    {t('i18n.ticketslistview.RECLAME_AQUI_RELATED')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    component="fieldset"
                    style={{
                      display: 'flex',
                      gap: '20px',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <RadioGroup
                      value={filterForm.reclameAqui || ''}
                      onChange={htmlElemtEvent => {
                        setFilterForm({
                          ...filterForm,
                          reclameAqui: htmlElemtEvent.target.value
                        });
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        style={{
                          gap: '29px'
                        }}
                      >
                        <FormControlLabel
                          value="false"
                          control={
                            <Radio className={classes.radio} color="primary" />
                          }
                          label={t('i18n.simplewords.NO')}
                        />
                        <FormControlLabel
                          value="true"
                          control={
                            <Radio className={classes.radio} color="primary" />
                          }
                          label={t('i18n.simplewords.YES')}
                        />
                      </Box>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Box>
                <Grid container display="flex" alignItems="center">
                  <Grid item xs={6}>
                    <Typography>{t('i18n.ticketslistview.SLA')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      component="fieldset"
                      style={{
                        display: 'flex',
                        gap: '20px',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <RadioGroup
                        value={filterForm.sla || ''}
                        onChange={htmlElemtEvent => {
                          setFilterForm({
                            ...filterForm,
                            sla: htmlElemtEvent.target.value
                          });
                        }}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          // style={{ marginInlineStart: '196px' }}
                        >
                          <FormControlLabel
                            value="expired"
                            control={
                              <Radio
                                className={classes.radio}
                                color="primary"
                              />
                            }
                            label={t('i18n.ticketslistview.SLA_EXPIRED')}
                          />
                          <FormControlLabel
                            value="expires"
                            control={
                              <Radio
                                className={classes.radio}
                                color="primary"
                              />
                            }
                            label={t('i18n.ticketslistview.SLA_EXPIRE_TODAY')}
                          />
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              {marketplacesSelected.find(item => {
                return (
                  true && ['B2W', 'MAGALU', 'MERCADO_LIVRE'].includes(item)
                );
              }) && (
                <Box>
                  <FormControl
                    component="fieldset"
                    style={{
                      display: 'flex',
                      gap: '22px',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <FormLabel style={{ color: 'inherit' }}>
                      {t('i18n.ticketslistview.MEDIATED')}
                    </FormLabel>
                    <FormGroup aria-label="position" row>
                      <Box
                        display="flex"
                        flexDirection="row"
                        style={{ marginInlineStart: '154px' }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              className={classes.checkboxFilter}
                              checked={filterForm.mediatedOpened || false}
                              onChange={event => {
                                setFilterForm({
                                  ...filterForm,
                                  mediatedOpened: event.target.checked
                                });
                              }}
                              color="primary"
                            />
                          }
                          label="Abertos"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              className={classes.checkboxFilter}
                              checked={filterForm.mediatedClosed || false}
                              onChange={event => {
                                setFilterForm({
                                  ...filterForm,
                                  mediatedClosed: event.target.checked
                                });
                              }}
                              color="primary"
                            />
                          }
                          label="Fechados"
                        />
                      </Box>
                    </FormGroup>
                  </FormControl>
                </Box>
              )}
              {marketplacesSelected.find(item => {
                return true && ['KABUM', 'CARREFOUR'].includes(item);
              }) && (
                <Box mb={1}>
                  <FormControl
                    component="fieldset"
                    style={{
                      display: 'flex',
                      gap: '22px',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <FormLabel style={{ color: 'inherit' }}>
                      Incidentes
                    </FormLabel>
                    <FormGroup aria-label="position" row>
                      <Box
                        display="flex"
                        flexDirection="row"
                        style={{ marginInlineStart: '154px' }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              className={classes.checkboxFilter}
                              checked={filterForm.incidentOpened || false}
                              onChange={event => {
                                setFilterForm({
                                  ...filterForm,
                                  incidentOpened: event.target.checked
                                });
                              }}
                              color="primary"
                            />
                          }
                          label="Abertos"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              className={classes.checkboxFilter}
                              checked={filterForm.incidentClosed || false}
                              onChange={event => {
                                setFilterForm({
                                  ...filterForm,
                                  incidentClosed: event.target.checked
                                });
                              }}
                              color="primary"
                            />
                          }
                          label="Fechados"
                        />
                      </Box>
                    </FormGroup>
                  </FormControl>
                </Box>
              )}
              <Box mt={2}>
                <Typography gutterBottom variant="h5">
                  {t('i18n.ticketslistview.OPENING_DATE')}:
                </Typography>
                <br />
                <Grid container spacing={2}>
                  <Grid item lg={6}>
                    <TextField
                      size="small"
                      type="date"
                      className={classes.inputSmall}
                      label={t('i18n.dashboard.START_DATE')}
                      variant="outlined"
                      value={dateFrom}
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                      onChange={htmlElemtEvent => {
                        setDateFrom(htmlElemtEvent.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <TextField
                      size="small"
                      type="date"
                      className={classes.inputSmall}
                      label={t('i18n.dashboard.END_DATE')}
                      variant="outlined"
                      value={dateTo}
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                      onChange={htmlElemtEvent => {
                        setDateTo(htmlElemtEvent.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              {marketplacesSelected.find(item => {
                return true && item === 'B2W';
              }) && (
                <>
                  <br />
                  <Divider />
                  <Box mt={3}>
                    <Grid container>
                      <Grid item xs={9}>
                        <Typography variant="h5">Americanas</Typography>
                        <br />
                        <FormControl variant="outlined" fullWidth size="small">
                          <InputLabel>
                            {t('i18n.ticketslistview.B2W_STATUS')}
                          </InputLabel>
                          <Select
                            defaultValue=""
                            value={filterForm.filtersB2W || ''}
                            onChange={htmlElemtEvent => {
                              setFilterForm({
                                ...filterForm,
                                filtersB2W: htmlElemtEvent.target.value
                              });
                            }}
                            className="small-input"
                            label={t('i18n.ticketslistview.B2W_STATUS')}
                          >
                            <MenuItem value="">
                              <em>{t('i18n.ticketslistview.NONE')}</em>
                            </MenuItem>
                            <MenuItem value="new">
                              {t('i18n.ticketslistview.B2W_INSTANCES_NEW')}
                            </MenuItem>
                            <MenuItem value="inProgress">
                              {t(
                                'i18n.ticketslistview.B2W_INSTANCES_INPROGRESS'
                              )}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
              {marketplacesSelected.find(item => {
                return true && item === 'MERCADO_LIVRE';
              }) && (
                <>
                  <br />
                  <Divider />
                  <Box mt={3}>
                    <Typography variant="h5">Mercado Livre</Typography>
                    <br />
                    <FormControl
                      component="fieldset"
                      style={{
                        display: 'flex',
                        gap: '20px',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <Typography>
                        {t('i18n.ticketslistview.ML_CLAIM')}
                      </Typography>
                      <RadioGroup
                        value={filterForm.reclamacaoMercadoLivre || ''}
                        onChange={htmlElemtEvent => {
                          setFilterForm({
                            ...filterForm,
                            reclamacaoMercadoLivre: htmlElemtEvent.target.value
                          });
                        }}
                      >
                        <Box display="flex" flexDirection="row">
                          <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label={t('i18n.simplewords.NO')}
                          />
                          <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label={t('i18n.simplewords.YES')}
                          />
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </>
              )}
            </FilterWrapper>
            <Divider />
            <br />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginRight: '10px'
              }}
            >
              <Button
                onClick={() => {
                  setShowCreateShortcut(false);
                }}
                variant="contained"
              >
                Cancelar
              </Button>
              <Button
                variant="outlined"
                color="primary"
                disabled={sendDisabled}
                onClick={() => handleSubmitShortcut()}
              >
                Salvar atalho
              </Button>
            </div>
            <br />
          </CustomBox>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default ShortcutContainer;
