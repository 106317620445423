import { Box, Grid } from '@material-ui/core';
import { MonetizationOn } from '@material-ui/icons';
import React from 'react';
import {
  SectionText,
  SectionTextDiv,
  SectionTotalDetails
} from './SectionTabs';

const ProductSubtotalML = ({
  paymentsMlList,
  orderData,
  orderFromMarketplaceBundle,
  t
}) => {
  let subtotalMl = 0.0;
  let discountMl = 0.0;
  let discountMlRaw = 0.0;
  let shippingCostMl = 0.0;
  let totalAmountMl = 0.0;
  if (paymentsMlList) {
    paymentsMlList.forEach(payment => {
      subtotalMl += payment.transaction_amount;
      discountMl += payment.coupon_amount;
      discountMlRaw += payment.coupon_amount;
      shippingCostMl += payment.shipping_cost;
      totalAmountMl += payment.total_paid_amount;
    });
  }
  subtotalMl = subtotalMl.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  });
  discountMl = discountMl.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  });
  shippingCostMl = shippingCostMl.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  });
  totalAmountMl = totalAmountMl.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  });

  return (
    <Grid container spacing={2}>
      <SectionTotalDetails item mt="true" lg={12} xs={12}>
        <SectionText>Subtotal:</SectionText>
        <SectionText>{subtotalMl}</SectionText>
      </SectionTotalDetails>
      <SectionTotalDetails item lg={12} xs={12}>
        <SectionText>Desconto:</SectionText>
        <SectionText>{discountMl}</SectionText>
      </SectionTotalDetails>
      {discountMlRaw > 0 && (
        <SectionTotalDetails item lg={12} xs={12}>
          {orderData?.cuponsDesconto?.length > 0 &&
            orderData?.cuponsDesconto?.map(cupom => {
              return (
                <SectionTextDiv mb bold fSize="12px" key={cupom.cupom}>
                  <Box display="flex" alignItems="center">
                    <MonetizationOn
                      style={{ marginRight: '3px' }}
                      color="primary"
                    />
                    {cupom.cupom || t('i18n.orderinfo.NOT_PROVIDED')}
                  </Box>
                </SectionTextDiv>
              );
            })}
          {(!orderData?.cuponsDesconto ||
            orderData?.cuponsDesconto?.length === 0) &&
            orderFromMarketplaceBundle?.order?.payments?.map(payment => {
              return (
                <span key={Math.random()}>
                  {payment?.coupon_id && (
                    <SectionTextDiv mb bold fSize="12px">
                      <Box display="flex" alignItems="center">
                        <MonetizationOn
                          style={{ marginRight: '3px' }}
                          color="primary"
                        />

                        {payment.coupon_id || t('i18n.orderinfo.NOT_PROVIDED')}
                      </Box>
                    </SectionTextDiv>
                  )}
                </span>
              );
            })}
        </SectionTotalDetails>
      )}
      <SectionTotalDetails item lg={12} xs={12}>
        <SectionText>Frete:</SectionText>
        <SectionText>{shippingCostMl}</SectionText>
      </SectionTotalDetails>
      <SectionTotalDetails borderdotted="true" item lg={12} xs={12}>
        <SectionText bold>Total:</SectionText>
        <SectionText bold>{totalAmountMl}</SectionText>
      </SectionTotalDetails>
    </Grid>
  );
};

export default ProductSubtotalML;
