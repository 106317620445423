import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  // Fade,
  FormControl,
  Grid,
  // IconButton,
  InputLabel,
  // ListItem,
  // ListItemIcon,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
// import Popper from '@material-ui/core/Popper';
// import CloseIcon from '@material-ui/icons/Close';
// import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SearchIcon from '@material-ui/icons/Search';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../../../../config';
import { StyledSubmitActions } from '../../../../../styles/components/StyledSubmitActions.styled';
import API from '../../../../../services/api';
import brstates from '../../../../../utils/br_states';
import formHelper from '../../../../../utils/formHelper';
import CapFind from './CAPFind';
import preDefinedMessages from '../../../../messages/messages';
import AcerModal from '../../../../../components/AcerModal';
import {
  SmallButton,
  SmallButtonCancellation
} from '../../TicketComponents/SectionTabs';
import './styles.css';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '60px',
    flexShrink: 0
  },
  drawerPaper: {
    width: '60px',
    zIndex: '19'
  },
  buttons: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  }
}));

export const Cap = ({
  ticketData,
  updateAttendantAnswerToBeInputed,
  setCapReceivedData = null,
  cancellation = null,
  capReceivedData = null,
  alreadyOpened = false,
  setOpenCapModal = null
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [collecttionAlreadySentid, setCollecttionAlreadySentid] = useState(
    ticketData?.cancellationProcess?.reverseLogisticsEntity?.numeroColeta
  );
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(alreadyOpened);
  const [placement, setPlacement] = useState();
  const [selectedOption, setSelectedOption] = useState('view');
  const [numerCartaoOptions, setNumerCartaoOptions] = useState([]);
  const serviceCodeOptions = JSON.parse(config.capServiceCodes);

  const capDefaultData = {
    numeroCartao: {
      required: true
    },
    serviceCode: {
      required: true
    }
  };
  const [capFormData, setCapFormdata] = useState(
    formHelper.simpleData2FormData(capDefaultData)
  );

  const remetenteDefaultData = {
    nome: {
      required: true
    },
    logradouro: {
      required: true
    },
    numero: {
      required: true
    },
    complemento: {
      required: false
    },
    bairro: {
      required: true
    },
    referencia: {
      required: false
    },
    cidade: {
      required: true
    },
    uf: {
      required: true
    },
    cep: {
      required: true
    },
    ddd: {
      required: true
    },
    telefone: {
      required: true
    },
    email: {
      required: false
    },
    cienciaConteudoProibido: {
      required: false,
      value: ''
    },
    identificacao: {
      required: false
    },
    dddCelular: {
      required: false
    },
    celular: {
      required: false
    },
    sms: {
      required: false
    },
    restricaoAnac: {
      required: false,
      value: ''
    }
  };
  const [remetenteFormData, setRemetenteFormData] = useState(
    formHelper.simpleData2FormData(remetenteDefaultData)
  );

  const destinatarioDefaultData = {
    nome: {
      required: true
    },
    logradouro: {
      required: true
    },
    numero: {
      required: true
    },
    complemento: {
      required: false
    },
    bairro: {
      required: true
    },
    referencia: {
      required: false
    },
    cidade: {
      required: true
    },
    uf: {
      required: true
    },
    cep: {
      required: true
    },
    ddd: {
      required: false
    },
    telefone: {
      required: false
    },
    email: {
      required: false
    },
    cienciaConteudoProibido: {
      required: false,
      value: ''
    }
  };
  const [destinatarioFormData, setDestinatarioFormData] = useState(
    formHelper.simpleData2FormData(destinatarioDefaultData)
  );

  const coletasSolicitadasDefaultData = {
    tipo: {
      required: true
    },
    idCliente: {
      required: true,
      value: ''
    },
    numero: {
      required: false
    },
    cartao: {
      required: false
    },
    ag: {
      required: true
    },
    valorDeclarado: {
      required: false
    },
    descricao: {
      required: true
    },
    servicoAdicional: {
      required: false
    },
    ar: {
      required: true
    },
    cklist: {
      required: false,
      value: ''
    },
    documento: {
      required: false,
      value: ''
    },
    remetente: {
      required: false,
      value: null
    },
    produto: {
      required: false,
      value: null
    }
  };
  const [coletasSolicitadasFormData, setColetasSolicitadasFormData] = useState(
    formHelper.simpleData2FormData(coletasSolicitadasDefaultData)
  );

  const objColDefaultData = {
    item: {
      value: 1,
      required: true
    },
    desc: {
      required: false
    },
    entrega: {
      required: false
    },
    num: {
      required: false
    },
    id: {
      required: false
    }
  };
  const [objColFormData, setObjColFormData] = useState(
    formHelper.simpleData2FormData(objColDefaultData)
  );

  const produtoDefaultData = {
    codigo: {
      required: false
    },
    tipo: {
      required: false
    },
    qtd: {
      required: false
    }
  };
  const [produtoFormData, setProdutoFormData] = useState(
    formHelper.simpleData2FormData(produtoDefaultData)
  );

  const eraseForm = () => {
    setCapFormdata(capDefaultData);
    setRemetenteFormData(remetenteDefaultData);
    setDestinatarioFormData(destinatarioDefaultData);
    setColetasSolicitadasFormData(coletasSolicitadasDefaultData);
    setObjColFormData(objColDefaultData);
    setProdutoFormData(produtoDefaultData);
  };

  const submitForm = () => {
    const validaCapFormData = formHelper.validaForm(
      capFormData,
      setCapFormdata
    );
    const validaRemetenteFormData = formHelper.validaForm(
      remetenteFormData,
      setRemetenteFormData
    );
    const validaDestinatarioFormData = formHelper.validaForm(
      destinatarioFormData,
      setDestinatarioFormData
    );
    const validaColetasSolicitadasFormData = formHelper.validaForm(
      coletasSolicitadasFormData,
      setColetasSolicitadasFormData
    );
    const validaObjColFormData = formHelper.validaForm(
      objColFormData,
      setObjColFormData
    );
    const validaProdutoFormData = formHelper.validaForm(
      produtoFormData,
      setProdutoFormData
    );
    if (
      !validaCapFormData ||
      !validaRemetenteFormData ||
      !validaDestinatarioFormData ||
      !validaColetasSolicitadasFormData ||
      !validaObjColFormData ||
      !validaProdutoFormData
    ) {
      enqueueSnackbar(t('i18n.newticket.CHECK_IF_FIELDS_ARE_FILLED'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      return;
    }

    const capPostData = formHelper.formData2PostData(capFormData);
    const remetentePostData = formHelper.formData2PostData(remetenteFormData);
    const destinatarioPostData = formHelper.formData2PostData(
      destinatarioFormData
    );
    const coletasSolicitadasPostData = formHelper.formData2PostData(
      coletasSolicitadasFormData
    );
    const objColPostData = formHelper.formData2PostData(objColFormData);
    const produtoPostData = formHelper.formData2PostData(produtoFormData);

    const finalPostData = {
      ...capPostData,
      ticketId: ticketData.id,
      postagemDestinatario: { ...destinatarioPostData },
      remetente: { ...remetentePostData },
      coletasSolicitadas: { ...coletasSolicitadasPostData },
      objCol: { ...objColPostData },
      produto: { ...produtoPostData }
    };
    API.post(
      `/auth/ms-ticket/v1/reverse-logistics/request-reverse-post`,
      finalPostData
    )
      .then(response => {
        const { data } = response;
        if (data?.resultado_solicitacao?.descricao_erro) {
          enqueueSnackbar(data?.resultado_solicitacao?.descricao_erro, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          });
          return;
        }
        const newCollectId = data?.resultado_solicitacao?.numero_coleta;

        if (setCapReceivedData) setCapReceivedData(data?.resultado_solicitacao);

        enqueueSnackbar(`${t('i18n.CAP.createdSuccess')} ID: ${newCollectId}`, {
          variant: 'success'
        });
        setCollecttionAlreadySentid(data?.resultado_solicitacao?.numero_coleta);
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.CAP.createdError'), {
          variant: 'error'
        });
      });
  };

  const fetchReversePostData = () => {
    API.get(
      `/auth/ms-ticket/v1/reverse-logistics/get-data-reverse-post?orderId=${ticketData.orderId}&ticketId=${ticketData.id}`
    )
      .then(response => {
        const { data } = response;
        setNumerCartaoOptions(data.numeroCartao.split(' - '));
        const {
          postagemDestinatario,
          remetente,
          coletasSolicitadas,
          objCol,
          produto
        } = data;
        Object.entries(postagemDestinatario).forEach(([key, value]) => {
          formHelper.setFormvalue(
            destinatarioFormData,
            setDestinatarioFormData,
            key,
            value || ''
          );
        });
        Object.entries(remetente).forEach(([key, value]) => {
          formHelper.setFormvalue(
            remetenteFormData,
            setRemetenteFormData,
            key,
            value || ''
          );
        });
        Object.entries(objCol).forEach(([key, value]) => {
          formHelper.setFormvalue(
            objColFormData,
            setObjColFormData,
            key,
            value || ''
          );
        });
        Object.entries(coletasSolicitadas).forEach(([key, value]) => {
          formHelper.setFormvalue(
            coletasSolicitadasFormData,
            setColetasSolicitadasFormData,
            key,
            value || ''
          );
        });
        Object.entries(produto).forEach(([key, value]) => {
          formHelper.setFormvalue(
            produtoFormData,
            setProdutoFormData,
            key,
            value || ''
          );
        });
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (selectedOption === 'create') {
      fetchReversePostData();
    }
  }, [selectedOption]);

  const handleClick = newPlacement => event => {
    setAnchorEl(event.currentTarget);
    setOpen(prev => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
    fetchReversePostData();
  };

  const handleClose = () => {
    setOpen(false);
    if (setOpenCapModal) {
      setOpenCapModal(false);
    }
  };

  return (
    <>
      {/* <Tooltip title={t('i18n.ticketcontainer.CAP')} placement="left">
        <ListItem disabled={open} button>
          <ListItemIcon onClick={handleClick('left-start')}>
            {' '}
            <LocalShippingIcon />
          </ListItemIcon>
        </ListItem>
      </Tooltip> */}
      {cancellation ? (
        <SmallButtonCancellation
          variant="contained"
          color="primary"
          size="small"
          onClick={handleClick('left-start')}
        >
          <SearchIcon style={{ fontSize: '12px' }} />
        </SmallButtonCancellation>
      ) : (
        <SmallButton
          variant="contained"
          color="primary"
          size="small"
          onClick={handleClick('left-start')}
        >
          <SearchIcon />
        </SmallButton>
      )}
      <AcerModal
        fullWidth
        classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
        onClose={handleClose}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        modalTitle={t('i18n.ticketcontainer.CAP')}
      >
        <div>
          <Card
          // style={{
          //   width: '880px',
          //   marginLeft: '-16px',
          //   boxShadow: 'rgba(0,0,0,0.31) 2px 0px 2em'
          // }}
          // raised
          >
            {/* <CardContent>
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
                style={{ float: 'right' }}
                size="small"
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
              <Typography>{t('i18n.ticketcontainer.CAP')}</Typography>
            </CardContent> */}
            <Divider />
            <CardContent>
              <div className="tabButtonsHolder">
                <div className={classes.buttons}>
                  <Button
                    onClick={() => {
                      setSelectedOption('view');
                    }}
                    variant="contained"
                    color={selectedOption === 'view' ? 'primary' : 'default'}
                    size="small"
                  >
                    {t('i18n.ticketcontainer.CAP.search')}
                  </Button>
                  {ticketData?.orderId && (
                    <Button
                      onClick={() => {
                        setSelectedOption('create');
                      }}
                      variant="contained"
                      color={
                        selectedOption === 'create' ? 'primary' : 'default'
                      }
                      size="small"
                    >
                      {t('i18n.ticketcontainer.CAP.add')}
                    </Button>
                  )}
                </div>
                {collecttionAlreadySentid && (
                  <Button
                    onClick={() => {
                      const { customerName, orderId } = ticketData;

                      const reverseLogisticsEntity =
                        capReceivedData ||
                        ticketData?.reverseLogisticsEntity ||
                        ticketData?.cancellationProcess?.reverseLogisticsEntity;

                      const capMessageForCustomer = preDefinedMessages.getCapMessageForCustomer(
                        customerName,
                        orderId,
                        collecttionAlreadySentid,
                        reverseLogisticsEntity.prazo
                      );

                      updateAttendantAnswerToBeInputed(capMessageForCustomer);
                      setOpen(false);
                    }}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    {t('i18n.CAP.sendThroughMessageButton')}
                  </Button>
                )}
              </div>
            </CardContent>
            <Divider />
            <div
              style={{
                display: selectedOption === 'view' ? 'block' : 'none'
              }}
            >
              <CapFind collectIdProp={collecttionAlreadySentid} />
            </div>
            <div
              style={{
                overflowY: 'scroll',
                height: '450px',
                display: selectedOption === 'create' ? 'block' : 'none'
              }}
            >
              {numerCartaoOptions.length > 0 ? (
                <div>
                  <CardContent>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item container lg={6}>
                        <Typography variant="overline">
                          {t('i18n.CAP.post_card_title')}
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid lg={11} item>
                            <Box mb={1}>
                              <FormControl
                                size="small"
                                error={capFormData.numeroCartao.error}
                                variant="outlined"
                                fullWidth
                              >
                                <InputLabel>
                                  {t('i18n.CAP.post_card')}
                                </InputLabel>
                                <Select
                                  value={capFormData.numeroCartao.value}
                                  onChange={htmlElemtEvent => {
                                    formHelper.setFormvalue(
                                      capFormData,
                                      setCapFormdata,
                                      'numeroCartao',
                                      htmlElemtEvent.target.value
                                    );
                                  }}
                                  label={t('i18n.CAP.post_card')}
                                >
                                  {numerCartaoOptions.map(item => {
                                    return (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item container lg={6}>
                        <Typography variant="overline">
                          {t('i18n.CAP.service_code_title')}
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid lg={11} item>
                            <Box mb={1}>
                              <FormControl
                                size="small"
                                error={capFormData.serviceCode.error}
                                variant="outlined"
                                fullWidth
                              >
                                <InputLabel>
                                  {t('i18n.CAP.service_code')}
                                </InputLabel>
                                <Select
                                  value={capFormData.serviceCode.value}
                                  onChange={htmlElemtEvent => {
                                    formHelper.setFormvalue(
                                      capFormData,
                                      setCapFormdata,
                                      'serviceCode',
                                      htmlElemtEvent.target.value
                                    );
                                  }}
                                  label={t('i18n.CAP.service_code')}
                                >
                                  {serviceCodeOptions.map(item => {
                                    return (
                                      <MenuItem
                                        key={item.code}
                                        value={item.code}
                                      >
                                        {`${item.code} - ${item.serviceName}`}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <Typography variant="overline">
                      {t('i18n.CAP.sender_data')}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid lg={12} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={remetenteFormData.nome.error}
                            fullWidth
                            label={t('i18n.CAP.name')}
                            variant="outlined"
                            value={remetenteFormData.nome.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                remetenteFormData,
                                setRemetenteFormData,
                                'nome',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid lg={12} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={remetenteFormData.identificacao.error}
                            fullWidth
                            label={t('i18n.CAP.document')}
                            variant="outlined"
                            value={remetenteFormData.identificacao.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                remetenteFormData,
                                setRemetenteFormData,
                                'identificacao',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid lg={12} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={remetenteFormData.logradouro.error}
                            fullWidth
                            label={t('i18n.CAP.address')}
                            variant="outlined"
                            value={remetenteFormData.logradouro.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                remetenteFormData,
                                setRemetenteFormData,
                                'logradouro',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid lg={4} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={remetenteFormData.numero.error}
                            fullWidth
                            label={t('i18n.CAP.house_number')}
                            variant="outlined"
                            value={remetenteFormData.numero.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                remetenteFormData,
                                setRemetenteFormData,
                                'numero',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid lg={8} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={remetenteFormData.bairro.error}
                            fullWidth
                            label={t('i18n.CAP.neighborhood')}
                            variant="outlined"
                            value={remetenteFormData.bairro.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                remetenteFormData,
                                setRemetenteFormData,
                                'bairro',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid lg={6} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={remetenteFormData.complemento.error}
                            fullWidth
                            label={t('i18n.CAP.additional_information')}
                            variant="outlined"
                            value={remetenteFormData.complemento.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                remetenteFormData,
                                setRemetenteFormData,
                                'complemento',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid lg={6} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={remetenteFormData.referencia.error}
                            fullWidth
                            label={t('i18n.CAP.reference')}
                            variant="outlined"
                            value={remetenteFormData.referencia.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                remetenteFormData,
                                setRemetenteFormData,
                                'referencia',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid lg={5} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={remetenteFormData.cep.error}
                            fullWidth
                            label={t('i18n.CAP.zipcode')}
                            variant="outlined"
                            value={remetenteFormData.cep.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                remetenteFormData,
                                setRemetenteFormData,
                                'cep',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid lg={2} item>
                        <Box mb={1}>
                          <FormControl
                            size="small"
                            error={remetenteFormData.uf.error}
                            variant="outlined"
                            fullWidth
                          >
                            <InputLabel>{t('i18n.CAP.uf')}</InputLabel>
                            <Select
                              value={remetenteFormData.uf.value}
                              onChange={htmlElemtEvent => {
                                formHelper.setFormvalue(
                                  remetenteFormData,
                                  setRemetenteFormData,
                                  'uf',
                                  htmlElemtEvent.target.value
                                );
                              }}
                              label={t('i18n.CAP.uf')}
                            >
                              {brstates().map(item => {
                                return (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid lg={5} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={remetenteFormData.cidade.error}
                            fullWidth
                            label={t('i18n.CAP.city')}
                            variant="outlined"
                            value={remetenteFormData.cidade.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                remetenteFormData,
                                setRemetenteFormData,
                                'cidade',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Typography variant="overline">
                      {t('i18n.CAP.phone')}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid lg={2} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={remetenteFormData.ddd.error}
                            fullWidth
                            label={t('i18n.CAP.regionalCode')}
                            variant="outlined"
                            value={remetenteFormData.ddd.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                remetenteFormData,
                                setRemetenteFormData,
                                'ddd',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid lg={4} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={remetenteFormData.telefone.error}
                            fullWidth
                            label={t('i18n.CAP.phone')}
                            variant="outlined"
                            value={remetenteFormData.telefone.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                remetenteFormData,
                                setRemetenteFormData,
                                'telefone',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid lg={6} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={remetenteFormData.email.error}
                            fullWidth
                            label={t('i18n.CAP.email')}
                            variant="outlined"
                            value={remetenteFormData.email.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                remetenteFormData,
                                setRemetenteFormData,
                                'email',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Typography variant="overline">
                      {t('i18n.CAP.cellphone')}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid lg={2} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={remetenteFormData.dddCelular.error}
                            fullWidth
                            label={t('i18n.CAP.regionalCode')}
                            variant="outlined"
                            value={remetenteFormData.dddCelular.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                remetenteFormData,
                                setRemetenteFormData,
                                'dddCelular',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid lg={4} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={remetenteFormData.celular.error}
                            fullWidth
                            label={t('i18n.CAP.cellphone')}
                            variant="outlined"
                            value={remetenteFormData.celular.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                remetenteFormData,
                                setRemetenteFormData,
                                'celular',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid lg={6} item>
                        <Box mb={1}>
                          <FormControl
                            size="small"
                            error={remetenteFormData.sms.error}
                            variant="outlined"
                            fullWidth
                          >
                            <InputLabel>{t('i18n.CAP.notifySms')}</InputLabel>
                            <Select
                              value={remetenteFormData.sms.value}
                              onChange={htmlElemtEvent => {
                                formHelper.setFormvalue(
                                  remetenteFormData,
                                  setRemetenteFormData,
                                  'sms',
                                  htmlElemtEvent.target.value
                                );
                              }}
                              label={t('i18n.CAP.notifySms')}
                            >
                              <MenuItem value="true">Sim</MenuItem>
                              <MenuItem value="false">Não</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <Typography variant="overline">
                      {t('i18n.CAP.destiny_data')}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid lg={12} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={destinatarioFormData.nome.error}
                            fullWidth
                            label={t('i18n.CAP.name')}
                            variant="outlined"
                            value={destinatarioFormData.nome.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                destinatarioFormData,
                                setDestinatarioFormData,
                                'nome',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid lg={12} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={destinatarioFormData.logradouro.error}
                            fullWidth
                            label={t('i18n.CAP.address')}
                            variant="outlined"
                            value={destinatarioFormData.logradouro.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                destinatarioFormData,
                                setDestinatarioFormData,
                                'logradouro',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid lg={4} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={destinatarioFormData.numero.error}
                            fullWidth
                            label={t('i18n.CAP.house_number')}
                            variant="outlined"
                            value={destinatarioFormData.numero.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                destinatarioFormData,
                                setDestinatarioFormData,
                                'numero',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid lg={8} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={destinatarioFormData.bairro.error}
                            fullWidth
                            label={t('i18n.CAP.neighborhood')}
                            variant="outlined"
                            value={destinatarioFormData.bairro.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                destinatarioFormData,
                                setDestinatarioFormData,
                                'bairro',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid lg={6} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={destinatarioFormData.complemento.error}
                            fullWidth
                            label={t('i18n.CAP.additional_information')}
                            variant="outlined"
                            value={destinatarioFormData.complemento.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                destinatarioFormData,
                                setDestinatarioFormData,
                                'complemento',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid lg={6} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={destinatarioFormData.referencia.error}
                            fullWidth
                            label={t('i18n.CAP.reference')}
                            variant="outlined"
                            value={destinatarioFormData.referencia.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                destinatarioFormData,
                                setDestinatarioFormData,
                                'referencia',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid lg={5} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={destinatarioFormData.cep.error}
                            fullWidth
                            label={t('i18n.CAP.zipcode')}
                            variant="outlined"
                            value={destinatarioFormData.cep.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                destinatarioFormData,
                                setDestinatarioFormData,
                                'cep',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid lg={2} item>
                        <Box mb={1}>
                          <FormControl
                            size="small"
                            error={destinatarioFormData.uf.error}
                            variant="outlined"
                            fullWidth
                          >
                            <InputLabel>{t('i18n.CAP.uf')}</InputLabel>
                            <Select
                              value={destinatarioFormData.uf.value}
                              onChange={htmlElemtEvent => {
                                formHelper.setFormvalue(
                                  destinatarioFormData,
                                  setDestinatarioFormData,
                                  'uf',
                                  htmlElemtEvent.target.value
                                );
                              }}
                              label={t('i18n.CAP.uf')}
                            >
                              {brstates().map(item => {
                                return (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid lg={5} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={destinatarioFormData.cidade.error}
                            fullWidth
                            label={t('i18n.CAP.city')}
                            variant="outlined"
                            value={destinatarioFormData.cidade.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                destinatarioFormData,
                                setDestinatarioFormData,
                                'cidade',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Typography variant="overline">
                      {t('i18n.CAP.phone')}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid lg={2} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={destinatarioFormData.ddd.error}
                            fullWidth
                            label={t('i18n.CAP.regionalCode')}
                            variant="outlined"
                            value={destinatarioFormData.ddd.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                destinatarioFormData,
                                setDestinatarioFormData,
                                'ddd',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid lg={4} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={destinatarioFormData.telefone.error}
                            fullWidth
                            label={t('i18n.CAP.phone')}
                            variant="outlined"
                            value={destinatarioFormData.telefone.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                destinatarioFormData,
                                setDestinatarioFormData,
                                'telefone',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid lg={6} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={destinatarioFormData.email.error}
                            fullWidth
                            label={t('i18n.CAP.email')}
                            variant="outlined"
                            value={destinatarioFormData.email.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                destinatarioFormData,
                                setDestinatarioFormData,
                                'email',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>{' '}
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <Typography variant="overline">
                      {t('i18n.CAP.requested_gatherings')}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid lg={6} item>
                        <Box mb={1}>
                          <Tooltip
                            placement="left"
                            title={t('i18n.CAP.type_helper')}
                          >
                            <FormControl
                              size="small"
                              error={coletasSolicitadasFormData.tipo.error}
                              variant="outlined"
                              fullWidth
                            >
                              <InputLabel>{t('i18n.CAP.type')}</InputLabel>

                              <Select
                                value={coletasSolicitadasFormData.tipo.value}
                                onChange={htmlElemtEvent => {
                                  formHelper.setFormvalue(
                                    coletasSolicitadasFormData,
                                    setColetasSolicitadasFormData,
                                    'tipo',
                                    htmlElemtEvent.target.value
                                  );
                                }}
                                label={t('i18n.CAP.type')}
                              >
                                <MenuItem value="CA">
                                  CA = {t('i18n.CAP.residencial_gathering')}
                                </MenuItem>
                                <MenuItem value="C">
                                  C = {t('i18n.CAP.domiciliary_gathering')}
                                </MenuItem>
                                <MenuItem value="A">
                                  A = {t('i18n.CAP.posting_authorization')}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Tooltip>
                        </Box>
                      </Grid>
                      <Grid lg={6} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={coletasSolicitadasFormData.idCliente.error}
                            fullWidth
                            label={t('i18n.CAP.clientId')}
                            variant="outlined"
                            value={coletasSolicitadasFormData.idCliente.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                coletasSolicitadasFormData,
                                setColetasSolicitadasFormData,
                                'idCliente',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid lg={12} item>
                        <Box mb={1}>
                          <Tooltip title={t('i18n.CAP.capnumero_helper')}>
                            <TextField
                              size="small"
                              error={coletasSolicitadasFormData.numero.error}
                              fullWidth
                              label={t('i18n.CAP.capnumero')}
                              variant="outlined"
                              value={coletasSolicitadasFormData.numero.value}
                              onChange={htmlElemtEvent => {
                                formHelper.setFormvalue(
                                  coletasSolicitadasFormData,
                                  setColetasSolicitadasFormData,
                                  'numero',
                                  htmlElemtEvent.target.value
                                );
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid lg={4} item>
                        <Box mb={1}>
                          <Tooltip title={t('i18n.CAP.ag_helper')}>
                            <TextField
                              size="small"
                              error={coletasSolicitadasFormData.ag.error}
                              fullWidth
                              label={t('i18n.CAP.ag')}
                              variant="outlined"
                              value={coletasSolicitadasFormData.ag.value}
                              onChange={htmlElemtEvent => {
                                formHelper.setFormvalue(
                                  coletasSolicitadasFormData,
                                  setColetasSolicitadasFormData,
                                  'ag',
                                  htmlElemtEvent.target.value
                                );
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Grid>
                      <Grid lg={3} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={
                              coletasSolicitadasFormData.valorDeclarado.error
                            }
                            fullWidth
                            label={t('i18n.CAP.declaredValue')}
                            variant="outlined"
                            value={
                              coletasSolicitadasFormData.valorDeclarado.value
                            }
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                coletasSolicitadasFormData,
                                setColetasSolicitadasFormData,
                                'valorDeclarado',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid lg={5} item>
                        <Box mb={1}>
                          <TextField
                            size="small"
                            error={coletasSolicitadasFormData.descricao.error}
                            fullWidth
                            label={t('i18n.CAP.description')}
                            variant="outlined"
                            value={coletasSolicitadasFormData.descricao.value}
                            onChange={htmlElemtEvent => {
                              formHelper.setFormvalue(
                                coletasSolicitadasFormData,
                                setColetasSolicitadasFormData,
                                'descricao',
                                htmlElemtEvent.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid lg={5} item>
                        <Box mb={1}>
                          <Tooltip title={t('i18n.CAP.colSolCartao_helper')}>
                            <TextField
                              size="small"
                              error={coletasSolicitadasFormData.cartao.error}
                              fullWidth
                              label={t('i18n.CAP.colSolCartao')}
                              variant="outlined"
                              value={coletasSolicitadasFormData.cartao.value}
                              onChange={htmlElemtEvent => {
                                formHelper.setFormvalue(
                                  coletasSolicitadasFormData,
                                  setColetasSolicitadasFormData,
                                  'cartao',
                                  htmlElemtEvent.target.value
                                );
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Grid>
                      <Grid lg={4} item>
                        <Box mb={1}>
                          <FormControl
                            size="small"
                            error={coletasSolicitadasFormData.ar.error}
                            variant="outlined"
                            fullWidth
                          >
                            <InputLabel>{t('i18n.CAP.ar')}</InputLabel>
                            <Select
                              value={coletasSolicitadasFormData.ar.value}
                              onChange={htmlElemtEvent => {
                                formHelper.setFormvalue(
                                  coletasSolicitadasFormData,
                                  setColetasSolicitadasFormData,
                                  'ar',
                                  htmlElemtEvent.target.value
                                );
                              }}
                              label={t('i18n.CAP.ar')}
                            >
                              <MenuItem value="0">
                                {t('i18n.simplewords.NO')}
                              </MenuItem>
                              <MenuItem value="1">
                                {t('i18n.simplewords.YES')}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid lg={3} item>
                        <Box mb={1}>
                          <Tooltip
                            title={t('i18n.CAP.additionalService_helper')}
                          >
                            <TextField
                              size="small"
                              error={
                                coletasSolicitadasFormData.servicoAdicional
                                  .error
                              }
                              fullWidth
                              label={t('i18n.CAP.additionalService')}
                              variant="outlined"
                              value={
                                coletasSolicitadasFormData.servicoAdicional
                                  .value
                              }
                              onChange={htmlElemtEvent => {
                                formHelper.setFormvalue(
                                  coletasSolicitadasFormData,
                                  setColetasSolicitadasFormData,
                                  'servicoAdicional',
                                  htmlElemtEvent.target.value
                                );
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <StyledSubmitActions>
                      <Button
                        onClick={() => {
                          eraseForm();
                        }}
                        variant="contained"
                      >
                        LIMPAR
                      </Button>
                      <Button
                        onClick={() => {
                          submitForm();
                        }}
                        variant="contained"
                        color="primary"
                      >
                        ENVIAR
                      </Button>
                    </StyledSubmitActions>
                  </CardContent>
                </div>
              ) : (
                <CardContent>
                  <Typography>{t('i18n.CAP.preInfoError')}</Typography>
                </CardContent>
              )}
            </div>
          </Card>
        </div>
      </AcerModal>
    </>
  );
};

// export default Cap;
