import {
  CardContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  TextField
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ChipInput from 'material-ui-chip-input';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MailComponent from './MailComponent';

const useStyles = makeStyles(() => ({
  inputRoot: {
    height: '60px'
  }
}));

const SendEmail = ({ properties, setProperties, setMailBodyContentHolder }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [destiniesHintMenuOpenState, setDestiniesHintMenuOpenState] = useState(
    null
  );

  const [actionProperties, setActionProperties] = useState(properties);

  const setActionPropsState = (key, value) => {
    const newState = { ...actionProperties };
    newState[key] = value;
    setActionProperties(newState);
  };

  useEffect(() => {
    setProperties(actionProperties);
  }, [actionProperties]);

  const appendDestiniesList = newValue => {
    const currentDestinies = actionProperties.destinatarios || [];
    if (currentDestinies.includes(newValue)) {
      return;
    }
    const newDestinies = currentDestinies;
    newDestinies.push(newValue);
    setActionPropsState('destinatarios', newDestinies);
  };

  return (
    <>
      <CardContent>
        <TextField
          size="small"
          fullWidth
          value={actionProperties.subject || []}
          onChange={ev => {
            setActionPropsState('subject', ev.target.value);
          }}
          label={t('i18n.newAutomation.SUBJECT')}
          variant="outlined"
        />
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item lg={11}>
            <ChipInput
              variant="outlined"
              fullWidth
              classes={classes}
              label={t('i18n.newAutomation.recipients')}
              value={actionProperties.destinatarios || []}
              onAdd={chip => {
                appendDestiniesList(chip);
              }}
              onDelete={chip => {
                const newDestinies = actionProperties.destinatarios.filter(
                  item => item !== chip
                );
                setActionPropsState('destinatarios', newDestinies);
              }}
            />
          </Grid>
          <Grid item lg={1}>
            <IconButton
              onClick={event => {
                setDestiniesHintMenuOpenState(event.currentTarget);
              }}
            >
              <AddIcon fontSize="large" />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={destiniesHintMenuOpenState}
              keepMounted
              open={Boolean(destiniesHintMenuOpenState)}
              onClose={() => {
                setDestiniesHintMenuOpenState(null);
              }}
            >
              <MenuItem
                onClick={() => {
                  appendDestiniesList('{{cliente.email}}');
                }}
              >
                cliente.email
              </MenuItem>
              <MenuItem
                onClick={() => {
                  appendDestiniesList('{{atendente.email}}');
                }}
              >
                atendente.email
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <MailComponent
          currentMailBodyState={actionProperties.emailBody}
          updateMailBodyContent={value => {
            setMailBodyContentHolder(value);
          }}
        />
      </CardContent>
    </>
  );
};

export default SendEmail;
