import React from 'react';
import { Card, CardContent, Divider } from '@material-ui/core';
// import moment from 'moment';
// import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  CustomTypograph,
  CardDataWrapper,
  CustomCardContent,
  CardDataDivider,
  LineCardWrapper,
  LineCardData,
  InConstruction
} from '../styles';

const MainCardData = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .label {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .main-data {
    font-size: 30px;
    font-weight: 500;
    color: #666666;
    .emoji {
      font-size: 30px;
    }
  }
`;

const SecondaryCardData = styled(MainCardData)`
  .main-data {
    font-size: 25px;
    color: #aaa;
  }
`;

const AverangeTimeCard = ({
  title,
  firstLabel,
  secondaryLabel,
  isTeamView,
  extraData,
  isRealTime,
  className,
  showInConstruction
}) => {
  // const { t } = useTranslation();

  return (
    <div style={{ position: 'relative' }}>
      <Card
        style={{ width: '100%', marginBottom: '20px' }}
        className={className}
      >
        <CardContent>
          <CustomTypograph variant="h5">{title}</CustomTypograph>
        </CardContent>
        <Divider />
        {extraData?.map(item => {
          if (item.show) {
            return (
              <div key={item.label}>
                <CardContent>
                  <CustomTypograph variant="h6">{item.label}</CustomTypograph>
                </CardContent>
                <CustomCardContent>
                  <CardDataWrapper jusfityContent={!isTeamView}>
                    {!isTeamView && (
                      <>
                        <MainCardData>
                          <p className="label">{firstLabel}</p>
                          <p className="main-data">{item.attendantData}</p>
                        </MainCardData>
                        <CardDataDivider />
                        <SecondaryCardData>
                          <p className="label">{secondaryLabel}</p>
                          <p className="main-data">{item.equipData}</p>
                        </SecondaryCardData>
                      </>
                    )}

                    {isTeamView && (
                      <MainCardData>
                        <p className="label">{secondaryLabel}</p>
                        <p className="main-data">{item.equipData}</p>
                      </MainCardData>
                    )}
                  </CardDataWrapper>
                </CustomCardContent>
                {isRealTime && (
                  <CustomCardContent>
                    <LineCardWrapper jusfityContent={!isTeamView}>
                      <LineCardData>
                        <p className="main-data" style={{ color: '#c00' }}>
                          {item.averageData}
                        </p>
                        <p className="label">Últimos 10 atendimentos</p>
                      </LineCardData>
                    </LineCardWrapper>
                  </CustomCardContent>
                )}

                {item.divider && <Divider />}
              </div>
            );
          }
          return <></>;
        })}
      </Card>
      {showInConstruction && <InConstruction />}
    </div>
  );
};

export default AverangeTimeCard;
