import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../../styles/settings/colors.config';
import ChatProtocolCardDataSideBySide from '../../components/ChatProtocolCardDataSideBySide.component';
import { compare } from '../../services/dashboardServices';

const ProtocolCard = ({
  isRealTime,
  isAdmin,
  // handleIsRealTime,
  dataEquipDash,
  dataIndividualDash
}) => {
  const { t } = useTranslation();

  const getAttendantData = () =>
    isAdmin
      ? dataEquipDash.lista_final_profissionais
      : dataIndividualDash.lista_final_profissionais;

  const total_equip = _.isEmpty(dataEquipDash?.lista_final_profissionais)
    ? 0
    : dataEquipDash?.lista_final_profissionais?.reduce(
        (acc, item) => acc + item.abertos,
        0
      ) || 0;
  const total_indiv = dataIndividualDash?.abertos || 0;

  const getExtraData = () => {
    return _.isEmpty(getAttendantData())
      ? []
      : getAttendantData()
          ?.sort((a, b) => compare(b.abertos, a.abertos))
          ?.map(item => {
            return {
              label:
                item.serviceAttendantLogin === 'unassigned'
                  ? 'Sem analista'
                  : item.serviceAttendantLogin,
              color: COLORS.grayText,
              value: item.abertos,
              divider: false,
              percent: item.abertos / total_equip,
              show: true
            };
          });
  };

  return (
    <ChatProtocolCardDataSideBySide
      isRealTime={isRealTime}
      cardType="attendantProtocols"
      firstLabel={t('i18n.dashboard.components.individual')}
      secondaryLabel={t('i18n.dashboard.components.equip')}
      isTeamView={isAdmin}
      headerData
      showMoreDetails={isAdmin}
      firstData={total_indiv}
      secondaryData={total_equip}
      descriptionHint={
        isRealTime
          ? t('i18n.dashboard.components.hint.real_time')
          : t('i18n.dashboard.components.hint.open_in_period')
      }
      extraData={getExtraData()}
    />
  );
};

export default ProtocolCard;
