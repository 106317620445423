import { makeStyles } from '@material-ui/core';
import { COLORS } from '../styles/settings/colors.config';

const theme = {
  overrides: {
    MuiTextField: {
      root: {
        fontSize: '12px',
        height: '30px',
        '& .small-input': {
          height: 30,
          background: '#fff'
        }
      }
    },
    MuiFormControl: {
      root: {
        fontSize: '12px',
        height: '30px',
        '& .small-input': {
          height: 30,
          background: '#fff'
        },
        '& .medium-input': {
          height: 30,
          background: '#fff',
          width: '250px'
        },
        '& .large-input': {
          height: 30,
          background: '#fff',
          width: '400px'
        },
        '& .small-input-native': {
          fontSize: '12px',
          height: 30,
          background: '#fff'
        }
      }
    },
    MuiInputLabel: {
      shrink: {
        fontSize: '12px'
      },
      root: {
        fontSize: '12px'
      }
    },
    MuiOutlinedInput: {
      root: {
        '& .small-input': {
          height: '30px'
        }
      }
    }
  }
};

const inputStyles = makeStyles(() => ({
  reasonIcon: {
    fontSize: '1.2em',
    marginLeft: '15px',
    cursor: 'pointer',
    color: 'gray'
  },
  inputRoot: {
    height: '30px',
    fontSize: '12px',
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      padding: 2
    }
  },
  input: {
    backgroundColor: 'white'
    // height: '30px',
    // fontSize: '12px'
  },
  inputRootRed: {
    color: COLORS.btnDangerColor,
    fontWeight: 'bolder'
  },
  option: {
    fontSize: '12px'
  },
  inputSmall: {
    height: '30px',
    fontSize: '12px',
    backgroundColor: 'white',
    '& input': {
      height: '10px'
    }

    // '&.MuiInputLabel-outlined.MuiInputLabel-marginDense': {
    //   height: '30px',
    //   fontSize: '12px',
    //   width: '90%',
    //   backgroundColor: 'white',
    //   transform: 'translate(14px, 12px) scale(0.8);'
    // }
  },
  inputMedium: {
    height: '30px',
    fontSize: '12px',
    backgroundColor: 'white',
    width: '250px',
    '& input': {
      height: '10px'
    }
  },
  inputLarge: {
    height: '30px',
    fontSize: '12px',
    backgroundColor: 'white',
    width: '340px',
    '& input': {
      height: '10px'
    }
  },
  checkbox: {
    '&:not(.Mui-checked)': {
      content: '""',
      left: 10,
      top: 0,
      height: 15,
      width: 20,
      color: COLORS.grayText,
      backgroundColor: 'white',
      marginInlineEnd: '22px'
    },
    '&.Mui-checked': {
      color: COLORS.greenText,
      marginBlockStart: '-12px'
    }
  },
  checkboxFilter: {
    '&:not(.Mui-checked)': {
      content: '""',
      left: 10,
      top: 0,
      height: 15,
      width: 20,
      color: COLORS.grayText,
      backgroundColor: 'white',
      marginInlineEnd: '22px'
    }
  },
  checkboxFilterPreSale: {
    '&:not(.Mui-checked)': {
      content: '""',
      left: 10,
      top: 0,
      height: 20,
      width: 20,
      color: COLORS.grayText,
      backgroundColor: 'white'
    },
    '&.Mui-checked': {
      color: COLORS.greenText,
      marginBlockStart: '0px',
      height: 20
    }
  },
  radio: {
    '&:not(.Mui-checked)': {
      content: '""',
      left: 11,
      top: 0,
      height: 15,
      width: 20,
      color: COLORS.grayText,
      backgroundColor: 'white',
      marginInlineEnd: '22px'
    }
  },
  radioGrey: {
    '&:not(.Mui-checked)': {
      content: '""',
      left: 11,
      top: 0,
      height: 15,
      width: 20,
      color: COLORS.grayText,
      backgroundColor: '#f4f6f8',
      marginInlineEnd: '22px'
    }
  },
  radioLabel: {
    '& .MuiTypography-body1': {
      fontSize: '12px'
    }
  },
  radioLabelBold: {
    '& .MuiTypography-body1': {
      fontSize: '12px',
      fontWeight: 'bold'
    }
  },
  inputSelect: {
    '&.MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      height: '30px',
      fontSize: '12px',
      width: '90%',
      backgroundColor: 'white',
      transform: 'translate(14px, 12px) scale(0.8);'
    }
  },
  resize: {
    fontSize: '12px',
    height: '9px',
    '&[class*="MuiTextField-root"] label': {
      transform: 'translate(14px, 12px) scale(0.8)'
    }
  }
}));

export { inputStyles, theme };
