import { makeStyles, createStyles, DialogContent } from '@material-ui/core';
import React from 'react';
import AcerModal from '../../../../../components/AcerModal';
import ExpertView from '../Views/Expert/Index';
import AttendantView from '../Views/Attendant/Index';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: '0px !important'
    }
  })
);

const loadComponent = (
  type,
  data,
  setOpen,
  authorizeDevolution,
  currentCancelProcess,
  reason,
  dataOrder,
  updateAttendantAnswerToBeInputed,
  setReloadPending
) => {
  switch (type) {
    case 'firstLevel':
      return (
        <AttendantView
          {...{
            data,
            setOpen,
            reason,
            dataOrder,
            updateAttendantAnswerToBeInputed,
            setReloadPending
          }}
        />
      );
    case 'especialist':
      return (
        <ExpertView
          data={data}
          authorizeDevolution={authorizeDevolution}
          currentCancelProcess={currentCancelProcess}
        />
      );
    case 'tracking':
      return (
        <ExpertView
          data={data}
          tracking
          authorizeDevolution={authorizeDevolution}
          currentCancelProcess={currentCancelProcess}
        />
      );
    case 'finance':
      return (
        <ExpertView
          data={data}
          finance
          authorizeDevolution={authorizeDevolution}
          currentCancelProcess={currentCancelProcess}
        />
      );
    case 'view':
      return (
        <ExpertView
          data={data}
          view
          currentCancelProcess={currentCancelProcess}
        />
      );
    default:
      return false;
  }
};

export default function RequestDialog({
  open,
  type,
  setOpen,
  data,
  updateAttendantAnswerToBeInputed,
  authorizeDevolution,
  title,
  currentCancelProcess,
  reason,
  dataOrder,
  setReloadPending
}) {
  const classes = useStyles();
  return (
    <AcerModal
      classes={classes}
      fullWidth
      maxWidth="md"
      onClose={() => setOpen(false)}
      open={open}
      modalTitle={title}
      updateAttendantAnswerToBeInputed={updateAttendantAnswerToBeInputed}
      freeBody
    >
      <DialogContent>
        {loadComponent(
          type,
          data,
          setOpen,
          authorizeDevolution,
          currentCancelProcess,
          reason,
          dataOrder,
          updateAttendantAnswerToBeInputed,
          setReloadPending
        )}
      </DialogContent>
    </AcerModal>
  );
}
