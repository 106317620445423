import React from 'react';

import { SvgIcon, MuiThemeProvider } from '@material-ui/core';

const Check = ({ theme }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <SvgIcon
        height="800px"
        width="800px"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
      >
        <g>
          <g id="check_x5F_alt">
            <path
              d="M16,0C7.164,0,0,7.164,0,16s7.164,16,16,16s16-7.164,16-16S24.836,0,16,0z M13.52,23.383
			L6.158,16.02l2.828-2.828l4.533,4.535l9.617-9.617l2.828,2.828L13.52,23.383z"
            />
          </g>
        </g>
      </SvgIcon>
    </MuiThemeProvider>
  );
};

export default Check;
