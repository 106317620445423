import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { searchB2bOnCurrentUrl } from '../chats/NewChatV2/services/notificationCount';

const IframeWrapper = styled.div`
  width: 100%;
  height: 100%;
  zoom: 110%;
  overflow: hidden;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;

const ChatHistoryDetail = () => {
  const { id } = useParams();

  const b2bUrl = `${
    process.env.REACT_APP_NEW_CONNECTA
  }/chat/historico/b2b/${id}?refreshToken=${localStorage.getItem(
    'refreshToken'
  )}`;

  const b2cUrl = `${
    process.env.REACT_APP_NEW_CONNECTA
  }/chat/historico/${id}?refreshToken=${localStorage.getItem('refreshToken')}`;

  useEffect(() => {
    const BASE_TITLE = `Histórico de Chat ${
      searchB2bOnCurrentUrl() ? 'B2B' : 'B2C'
    }`;
    document.title = BASE_TITLE;
  }, []);

  return (
    <IframeWrapper>
      <Iframe src={searchB2bOnCurrentUrl() ? b2bUrl : b2cUrl} />
    </IframeWrapper>
  );
};

export default ChatHistoryDetail;
