import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import FilterAccordion from './filterAccordion.component';
import FilterForm from './filterForm.component';
import {
  convertDateToFilter,
  defaultDateFormat
} from '../services/convertDate';
import indexHook from '../indexHook';
import API from '../../../services/api';
import dashboardServices from '../services/dashboardServices';
import formHelper from '../../../utils/formHelper';
import { filterNameInStorage } from './config';

const FilterDashboard = ({
  open,
  setOpen,
  dashboardFilter,
  setDashboardFilter
}) => {
  const reduxDispatcher = useDispatch();
  const { isAdmin, userName, enqueueSnackbar, t } = indexHook();

  const [filter, setFilter] = useState(dashboardFilter);
  const [submit, setSubmit] = useState(false);
  const [dataIndividualDash] = useState('');
  const [dataEquipDash, setDataEquipDash] = useState('');
  const [attendantList, setAttendantList] = useState([]);

  const handleGetDashboard = async (currentFilter) => {
    reduxDispatcher({
      type: 'SET_PRESALE_ONLINE_B2B_DASHBOARD_LOADING',
      loading: true
    });
    let marketplaceList = '';

    currentFilter.marketplace
      .filter((item) => item !== 'All')
      .forEach((item, i) => {
        marketplaceList =
          `${marketplaceList} ${i}` === 0 ? item.replace(' ', '') : item;
      });

    const data = {
      ...currentFilter,
      ...{
        marketplace: marketplaceList.replaceAll('CUSTOMER_GUIDE', 'ACER_STORE')
      }
    };

    if (data.startDate) {
      data.startDate = defaultDateFormat(moment(data.startDate).startOf('day'));
    }
    if (data.endDate) {
      data.endDate = defaultDateFormat(
        moment(data.endDate).subtract(3, 'hours')
      );
    }

    await API.post(`auth/lambda/dash-attendant/pre-sale-online`, data)
      .then((response) => {
        setDataEquipDash(response.data);
      })
      .catch(() => {
        setDataEquipDash(dashboardServices.handleNotHaveEquipData());
        enqueueSnackbar(t('i18n.dashboard.components.no_data'), {
          variant: 'error'
        });
      })
      .finally(() => {
        reduxDispatcher({
          type: 'SET_PRESALE_ONLINE_B2B_DASHBOARD_LOADING',
          loading: false
        });
      });
  };

  useEffect(() => {
    if (submit) {
      const { startDate, endDate } = convertDateToFilter(filter);
      filter.startDate = startDate;
      filter.endDate = endDate;
    }

    if (filter.periodType === 'realTime') {
      filter.startDate = defaultDateFormat(
        moment(new Date('2021-10-01')).add(6, 'hours')
      );
    }

    filter.attendant = !isAdmin ? userName : filter.attendant;
    reduxDispatcher({
      type: 'SET_PRESALE_ONLINE_B2B_DASHBOARD_FILTER',
      filter
    });
    setDashboardFilter(filter);
  }, [filter, submit]);

  useEffect(() => {
    if (dataEquipDash !== '') {
      reduxDispatcher({
        type: 'SET_PRESALE_ONLINE_B2B_DASHBOARD_EQUIP_DATA',
        equipData: dataEquipDash
      });
    }
  }, [dataEquipDash]);

  useEffect(() => {
    if (dataIndividualDash !== '') {
      reduxDispatcher({
        type: 'SET_PRESALE_ONLINE_B2B_DASHBOARD_INDIVIDUAL_DATA',
        individualData: dataIndividualDash
      });
    }
  }, [dataIndividualDash]);

  useEffect(() => {
    sessionStorage.setItem(
      filterNameInStorage,
      JSON.stringify(dashboardFilter)
    );
    handleGetDashboard(dashboardFilter);
  }, [dashboardFilter]);

  useEffect(() => {
    API.get(`/auth/ms-users/groups/Chat/users`).then((response) => {
      setAttendantList(response.data.sort(formHelper.compareName));
    });
  }, []);

  return (
    <>
      <FilterAccordion
        open={open}
        setFilter={setFilter}
        setSubmit={setSubmit}
        filter={dashboardFilter}
        // dashboardFilter={dashboardFilter}
        filterName="newfilter"
        // defaultFilter={defaultFilter}
      />
      {open && (
        <FilterForm
          setFilter={setFilter}
          setSubmit={setSubmit}
          setOpen={setOpen}
          filter={filter}
          filterName="newfilter"
          // defaultFilter={defaultFilter}
          attendantList={attendantList}
        />
      )}
    </>
  );
};

export default FilterDashboard;
