import React from 'react';
import { Star, StarBorderOutlined } from '@material-ui/icons';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import userService from '../../../services/user.service';

const CustomIconButton = styled(IconButton)`
  opacity: ${props => (props.hide ? '0' : '1')};
`;

const handleMakeFields = (details, type) => {
  let fields = [];

  switch (type) {
    case 'link':
      fields = [...fields, ...[{ type: 'link', value: details.path }]];
      break;
    case 'tab':
      break;
    case 'filter':
      break;
    default:
  }

  return fields;
};

const FavoriteButton = ({ hide, details, type, className }) => {
  const reduxDispatcher = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const favoriteLimit = useSelector(state => state.showFavoriteLimitReducer);

  const handleEditFavorite = favorite => {
    reduxDispatcher({
      type: 'SHOW_FAVORITE_MODAL',
      showFavoriteModal: true
    });
    reduxDispatcher({
      type: 'SET_FAVORITE',
      favorite
    });
  };

  const handleSubmitFavorite = (detail, t) => {
    if (favoriteLimit > 4) {
      enqueueSnackbar(
        `Não é possível adicionar mais de 5 favoritos. Exclua pelo menos um favorito antes de adicionar um novo.`,
        {
          variant: 'error',
          persist: true
        }
      );
      return false;
    }

    const cleanedText = detail.text && detail.text.replace('- ', '');
    const favoriteDetails = {
      name: cleanedText || detail.name,
      fields: handleMakeFields(detail, t),
      username: userService.getUsername(),
      type: t
    };

    handleEditFavorite(favoriteDetails);
  };

  return (
    <>
      {!details.edit ? (
        <Tooltip
          title={<Typography>Adicionar favorito</Typography>}
          placement="left"
        >
          <span>
            <CustomIconButton
              onClick={() => handleSubmitFavorite(details, type)}
              hide={hide}
              // disabled={favoriteLimit > 4}
              color="primary"
              className={className}
            >
              <StarBorderOutlined />
            </CustomIconButton>
          </span>
        </Tooltip>
      ) : (
        <Tooltip
          title={<Typography>Editar favorito</Typography>}
          placement="left"
        >
          <span>
            <CustomIconButton
              hide={hide}
              color="primary"
              onClick={() => handleEditFavorite(details, type)}
            >
              <Star />
            </CustomIconButton>
          </span>
        </Tooltip>
      )}
    </>
  );
};

export { FavoriteButton };
