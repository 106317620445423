import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import LegalProcessSearchModal from '../../../views/tickets/LegalProcess/LegalProcessSearchModal';
import B2WMarketplaceSupportModal from '../../../views/tickets/messages/b2w/B2WMarketplaceSupportModal';

const IframeWrapper = styled.div`
  width: 100%;
  height: 100%;
  border: 0;
  position: fixed;
  z-index: 9999 !important;
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};

  * {
    pointer-events: all;
  }
`;
const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  position: fixed;
  inset: 0px;

  background-color: transparent;
`;

const InvoiceModalIframe = ({ open }) => {
  return createPortal(
    <IframeWrapper open={open}>
      <Iframe
        tabIndex={-1}
        loading="lazy"
        id="invoice-iframe"
        seamless
        src={`${
          process.env.REACT_APP_NEW_CONNECTA
        }/modals/invoice?refreshToken=${localStorage.getItem('refreshToken')}`}
      />
    </IframeWrapper>,
    document.body
  );
};

const TrackingModalIframe = ({ open }) => {
  return createPortal(
    <IframeWrapper open={open}>
      <Iframe
        tabIndex={-1}
        loading="lazy"
        id="tracking-iframe"
        seamless
        src={`${
          process.env.REACT_APP_NEW_CONNECTA
        }/modals/tracking?refreshToken=${localStorage.getItem('refreshToken')}`}
      />
    </IframeWrapper>,
    document.body
  );
};

const OrdersModalIframe = ({ open }) => {
  return createPortal(
    <IframeWrapper open={open}>
      <Iframe
        tabIndex={-1}
        loading="lazy"
        id="orders-iframe"
        seamless
        src={`${
          process.env.REACT_APP_NEW_CONNECTA
        }/modals/orders?refreshToken=${localStorage.getItem('refreshToken')}`}
      />
    </IframeWrapper>,
    document.body
  );
};

const ModalsComponent = ({
  openOrders,
  setOpenOrders,
  openInvoice,
  setOpenInvoice,
  openTracking,
  setOpenTracking,
  openSearchLegalProcess,
  setOpenSearchLegalProcess,
  openMarketplaceSupportModal,
  setOpenMarketplaceSupportModal
}) => {
  useEffect(() => {
    const listener = (message) => {
      switch (message.data.type) {
        case 'closing-invoice-modal': {
          setOpenInvoice(false);
          break;
        }
        case 'closing-tracking-modal': {
          setOpenTracking(false);
          break;
        }
        case 'closing-orders-modal': {
          setOpenOrders(false);
          break;
        }
        default:
          break;
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  useEffect(() => {
    // setTimeout(() => {
    //   if (openInvoice) {
    //   const invoiceIframe = document.getElementById('invoice-iframe');
    //   invoiceIframe.contentWindow.postMessage(
    //     { type: 'opening-invoice-modal' },
    //     `*`
    //   );
    // }
    // if (openTracking) {
    //   const trackingIframe = document.getElementById('tracking-iframe');
    //   trackingIframe.contentWindow.postMessage(
    //     { type: 'opening-tracking-modal' },
    //     `*`
    //   );
    // }

    // if (openOrders) {
    //   const ordersIframe = document.getElementById('orders-iframe');
    //   ordersIframe.contentWindow.postMessage(
    //     { type: 'opening-orders-modal' },
    //     `*`
    //   );
    // }
    // }, 250);
  }, [openInvoice, openTracking, openOrders]);

  return (
    <>
      {/* <OrdersModal open={openOrders} onClose={() => setOpenOrders(false)} /> */}
      <LegalProcessSearchModal
        open={openSearchLegalProcess}
        onClose={() => setOpenSearchLegalProcess(false)}
      />
      <B2WMarketplaceSupportModal
        open={openMarketplaceSupportModal}
        setOpen={setOpenMarketplaceSupportModal}
      />
      {openTracking && <TrackingModalIframe open={openTracking} />}
      {openInvoice && <InvoiceModalIframe open={openInvoice} />}
      {openOrders && <OrdersModalIframe open={openOrders} />}
    </>
  );
};

export default ModalsComponent;
