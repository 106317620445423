import { Button } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GroupingModal from './GroupingModal';

const Grouping = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        color="primary"
        size="small"
        onClick={() => {
          setOpen(true);
        }}
        startIcon={<FileCopyOutlinedIcon />}
      >
        {t('i18n.ticketsgrouping.GROUP_TICKETS')}
      </Button>
      <GroupingModal open={open} setOpen={setOpen} />
    </>
  );
};

export default Grouping;
