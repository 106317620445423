import React, { useState, useEffect } from 'react';
import { Card, Popover, Fade, Popper, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import ChatConnection from './ChatConnection';

import API from '../../../services/api';

import {
  CustomLink,
  OnlineButton,
  PaperStyled,
  StyledBox
} from './styles/styles';

import TabChatWebSocket from './components/TabChatWebSocket';
import TabTools from './components/TabTools';

let socketConnection = null;
let intervalToVerifyConnection;

const ChatTools = ({ socket, setSocket }) => {
  let subscribe;

  const disconnect = useSelector(state => state.disconnectChatReducer);

  const reduxDispatcher = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [newUserStatus, setNewUserStatus] = useState(false);
  const [historyPopOver, setHistoryPopOver] = useState(null);
  const [expanded, setExpanded] = useState('panel1');

  const handleClick = event => {
    if (event) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    } else {
      setAnchorEl(anchorEl && null);
    }
  };

  const open = Boolean(historyPopOver);
  const id = open ? 'simple-popover' : undefined;

  const closePopOver = () => {
    setHistoryPopOver(null);
  };

  const removeSocketConnection = () => {
    if (socket) socket.close();
    ChatConnection.removeInstance();
    if (subscribe) {
      subscribe.unsubscribe();
    }
    clearInterval(socketConnection);
  };

  const makeWebSocketConnection = () => {
    clearInterval(socketConnection);
    socketConnection = setInterval(() => {
      socket.close();
      ChatConnection.removeInstance();
      if (subscribe) {
        subscribe.unsubscribe();
      }
      setSocket(ChatConnection.getConnection());
    }, 7000000);
  };

  const handleToOnline = () => {
    if (socket) {
      removeSocketConnection();
    }
    reduxDispatcher({
      type: 'SET_CONNECTED_STATE_CHAT',
      connectedStateChat: 'true'
    });
    setNewUserStatus(true);
    handleClick();
  };

  const handleToOffline = async () => {
    clearInterval(intervalToVerifyConnection);
    clearInterval(socketConnection);
    handleClick();
    try {
      await API.get('/auth/ms-chat/chats/deactivate').then(() => {
        setNewUserStatus(false);
      });
    } catch (error) {
      enqueueSnackbar(t('i18n.loginview.ERROR'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
    }
  };

  const handleChangeTab = () => {
    reduxDispatcher({
      type: 'SET_NOTIFICATION',
      notification: {}
    });
  };

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    handleChangeTab();
  };

  useEffect(() => {
    return () => {
      if (socket) {
        removeSocketConnection();
      }
    };
  }, [socket, subscribe]);

  useEffect(() => {
    if (newUserStatus) {
      setSocket(ChatConnection.getConnection());
    }
  }, [newUserStatus]);

  useEffect(() => {
    if (socket) {
      makeWebSocketConnection();
      // initSubscribeSocket();
    }
  }, [socket]);

  useEffect(() => {
    if (disconnect) {
      setNewUserStatus(false);
    }
  }, [disconnect]);

  const connectedState = useSelector(state => state.connectedStateReducer);

  useEffect(() => {
    setInterval(() => {
      if (!navigator.onLine && connectedState === 'true') {
        reduxDispatcher({
          type: 'SET_CONNECTED_STATE_CHAT',
          connectedStateChat: 'false'
        });
        reduxDispatcher({
          type: 'DISCONNECT_CHAT',
          disconnect: true
        });
        setNewUserStatus(false);
      }

      if (navigator.onLine && connectedState === 'false') {
        reduxDispatcher({
          type: 'SET_CONNECTED_STATE_CHAT',
          connectedStateChat: 'true'
        });
      }
    }, 5000);

    reduxDispatcher({
      type: 'SET_CONNECTED_STATE_CHAT',
      connectedStateChat: 'true'
    });
  }, []);

  return (
    <>
      <Card style={{ height: '100%' }}>
        <StyledBox>
          <OnlineButton
            aria-describedby={anchorEl ? 'simple-popper' : undefined}
            variant="contained"
            size="small"
            onClick={e => handleClick(e)}
            disabled={disconnect}
          >
            <span
              className={`status ${newUserStatus ? 'online' : 'offline'} `}
            />
            {newUserStatus ? 'Online' : 'Offline'} <ExpandMoreIcon />
          </OnlineButton>
        </StyledBox>

        <TabChatWebSocket
          socket={socket}
          expanded={expanded}
          handleChange={handleChange}
          newUserStatus={newUserStatus}
        />

        <TabTools
          expanded={expanded}
          handleChange={handleChange}
          newUserStatus={newUserStatus}
          setSocket={setSocket}
        />
      </Card>

      <Popover
        id={id}
        open={open}
        anchorEl={historyPopOver}
        onClose={closePopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <CustomLink variant="h4">
          <a href="/historico-chats" target="_blank">
            {t('i18n.chatview.CHAT_HISTORY')}
          </a>
        </CustomLink>
      </Popover>

      <Popper
        id={anchorEl ? 'simple-popper' : undefined}
        open={!!anchorEl}
        anchorEl={anchorEl}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <PaperStyled>
              <Typography
                onClick={() =>
                  !newUserStatus ? handleToOnline() : handleToOffline()
                }
              >
                {t('i18n.chat.CHANGE_CHAT_STATUS')}{' '}
                {!newUserStatus ? 'Online' : 'Offline'}
              </Typography>
            </PaperStyled>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export { ChatTools };
