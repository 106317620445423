/* eslint-disable react/destructuring-assignment */
import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDataTable } from '../../../styles/components/CustomDataTable';
import CustomFooter from '../../../styles/components/CustomFooter';
import { COLORS } from '../../../styles/settings/colors.config';

const CustomRow = props => {
  const { data, reportData } = props;
  const handleSelectProtocol = () => {
    const { id } = reportData[props.dataIndex];
    window.open(`/legal-process/${id}`, '_blank');
  };
  return (
    <TableRow onClick={handleSelectProtocol} style={{ cursor: 'pointer' }}>
      {data.map((d, i) => {
        const returnValues = () => {
          if (i === 0 || i === 3 || i === 4)
            return <span style={{ color: COLORS.greenText }}>{d}</span>;
          if (i === 1)
            return (
              <span
                style={{ color: COLORS.cardMessageBlue, fontWeight: 'bold' }}
              >
                {d}
              </span>
            );
          return d;
        };

        return (
          <TableCell {...props} key={Math.random()}>
            {returnValues()}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default function LegalProcessTable({
  count,
  rows,
  columns,
  rowsPerPage,
  reportData,
  currentPage,
  handleSearch,
  loading
}) {
  const { t } = useTranslation();
  const options = {
    selectableRows: 'none',
    filter: false,
    pagination: true,
    viewColumns: false,
    print: false,
    search: false,
    rowsPerPage,
    rowsPerPageOptions: [20],
    count,
    download: false,
    page: currentPage,
    serverSide: true,
    hint: 'Ordenar',
    customRowRender: (data, dataIndex) => {
      return (
        <CustomRow {...{ data, reportData, dataIndex }} key={Math.random()} />
      );
    },
    customFooter: (changeRowsPerPage, textLabels) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={() => {}}
          rowsPerPageOptions={[20]}
          callback={pageNumber => {
            handleSearch(pageNumber);
          }}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: !loading
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: column =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };

  return <CustomDataTable data={rows} columns={columns} options={options} />;
}
