const reportsDashboardFilter = {};
const reportsDashboardList = [];
const reportsDashboardIndividualData = {};
const reportsDashboardLoading = true;
const reportsDashboardRemovedKey = '';

const reportsDashboardListReducer = (state = reportsDashboardList, action) => {
  switch (action.type) {
    case 'SET_REPORTS_DASHBOARD_LIST':
      state = action.list;
      return state;
    default:
      return state;
  }
};

const reportsDashboardRemovedKeyReducer = (
  state = reportsDashboardRemovedKey,
  action
) => {
  switch (action.type) {
    case 'SET_REPORTS_DASHBOARD_REMOVED_KEY':
      state = action.key;
      return state;
    default:
      return state;
  }
};

const reportsDashboardIndividualDataReducer = (
  state = reportsDashboardIndividualData,
  action
) => {
  switch (action.type) {
    case 'SET_REPORTS_DASHBOARD_INDIVIDUAL_DATA':
      state = action.individualData;
      return state;
    default:
      return state;
  }
};

const reportsDashboardLoadingReducer = (
  state = reportsDashboardLoading,
  action
) => {
  switch (action.type) {
    case 'SET_REPORTS_DASHBOARD_LOADING':
      state = action.loading;
      return state;
    default:
      return state;
  }
};

const reportsDashboardFilterReducer = (
  state = reportsDashboardFilter,
  action
) => {
  switch (action.type) {
    case 'SET_REPORTS_DASHBOARD_FILTER':
      state = action.filter;
      return state;
    default:
      return state;
  }
};

export {
  reportsDashboardFilterReducer,
  reportsDashboardLoadingReducer,
  reportsDashboardListReducer,
  reportsDashboardIndividualDataReducer,
  reportsDashboardRemovedKeyReducer
};
