import {
  Container,
  Grid,
  CircularProgress,
  Tooltip,
  Box,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import Page from '../../components/Page';
import PreSaleMessage from './components/preSaleMessage.component';
import PreSaleTab from './components/preSaleTab.component';
import PreSaleTitle from './components/preSaleTitle.component';
import PreSaleChatModal from './components/preSaleChatModal';
import PreSaleMoreDetailsModal from './components/preSaleMoreDetailsModal.component';
import PreSaleConfirmModal from './components/preSaleConfirmModal.component';
import { PreSaleCustomFooter } from './styles';
import { StyledSpinner } from '../../styles/components/SpinnerDefault';
import FetchContentError from '../../components/FetchContentError';
import TicketsFilter from './TicketsFilter';
import FilterCardDescriber from './components/FilterCardDescriber';
import { getFilterInStorage } from './services/presaleServices';
import {
  RemoveFilterCardButton,
  cardStyle,
  CustomTypography,
  FilterCardHeader,
  typographyStyle,
  CustomTypographyDescribe
} from '../../styles/components/Tags';

import presaleHooks from './presaleHook';

const TicketRow = styled('div')`
  display: flex;
`;

const NewQuestionsAction = ({ updateQuestions }) => {
  return (
    <>
      <span>Novas perguntas disponíveis!</span>
      <span
        onClick={updateQuestions}
        style={{
          textDecoration: 'underline',
          marginLeft: '5px',
          cursor: 'pointer'
        }}
        aria-hidden="true"
      >
        Atualizar
      </span>
    </>
  );
};

const PreSaleView = () => {
  const {
    classes,
    data,
    question,
    count,
    rowsPerPage,
    page,
    changeRowsPerPage,
    loading,
    opendetailsModal,
    openChatModal,
    chatModalData,
    detailsModalData,
    activateProtocol,
    openConfirmModal,
    blockInput,
    filterData,
    totalResults,
    setFilterData,
    setBlockInput,
    changePage,
    getData,
    setData,
    handleSetQuestionToAnswer,
    openModal,
    openProductDetailsModal,
    closeChatModal,
    closedetailsModal,
    closeConfirmModal,
    hasFetchError,
    componentSetMarketplaceFilter,
    setCurrentPage,
    filterFormData,
    removeFilterFromChip,
    setLoading,
    enableButton,
    setEnableButton,
    indexActivate,
    currentTicket,
    setCurrentTicket,
    handleShowTicket,
    haveMessage,
    setHaveMessage,
    getDefaultData,
    setTotalResults,
    setCount,
    setRowsPerPage,
    setPage
  } = presaleHooks();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let newData = {};

  const updateQuestions = () => {
    setData(newData?.data);

    setTotalResults(newData?.totalResults);
    setCount(newData?.totalResults);
    setRowsPerPage(newData?.rowsPerPage);
    setPage(newData?.page);
    closeSnackbar();
    setFilterData({
      marketPlace: getFilterInStorage().marketPlace,
      ticketType: 'PRE_SALE',
      marketPlaceList: '',
      orderBy: 'ASC',
      ticketStatus: 'NEW'
    });
  };

  const compareData = async () => {
    const defaultData = await getDefaultData();
    const content = defaultData?._embedded;
    const details = defaultData?.page;

    newData = {
      totalResults: details?.totalElements,
      data: content?.ticketResourceList,
      count: details?.totalElements,
      rowsPerPage: details?.size,
      page: 0
    };

    if (
      defaultData?.page?.totalElements > localStorage.getItem('totalResults')
    ) {
      enqueueSnackbar(<NewQuestionsAction {...{ updateQuestions }} />, {
        variant: 'warning',
        persist: true
      });
    }
  };

  const processComparison = () => {
    setInterval(() => {
      if (
        JSON.parse(sessionStorage.getItem('preSalefilterData')).ticketStatus ===
          'NEW' &&
        window.location.pathname === '/ticketsPreSale'
      ) {
        compareData();
      }
    }, 180000);
  };

  useEffect(() => {
    processComparison();
  }, []);

  useEffect(() => {
    localStorage.setItem('totalResults', totalResults);
  }, [totalResults]);

  const { t } = useTranslation();

  if (hasFetchError) {
    return <FetchContentError fetch={getData} />;
  }

  const FilterChip = ({ itemKey, value }) => {
    if (
      itemKey !== 'marketPlace' &&
      itemKey !== 'ticketType' &&
      itemKey !== 'marketPlaceList' &&
      !!filterData[itemKey]
    ) {
      return (
        <Box key={itemKey}>
          <Card
            style={{
              borderRadius: '10px'
            }}
          >
            <CardContent style={cardStyle}>
              <FilterCardHeader>
                <Typography variant="overline" style={typographyStyle}>
                  {t(`i18n.dashboard.filterHeader.${itemKey}`)}
                </Typography>
                <Tooltip title={t('i18n.dashboard.REMOVE_FILTER')}>
                  <RemoveFilterCardButton
                    disabled={
                      itemKey === 'startDate' ||
                      itemKey === 'isRealTime' ||
                      itemKey === 'freq' ||
                      itemKey === 'attendant'
                    }
                    color="primary"
                    aria-label="delete"
                    onClick={() => {
                      removeFilterFromChip(itemKey);
                    }}
                  >
                    <CloseIcon />
                  </RemoveFilterCardButton>
                </Tooltip>
              </FilterCardHeader>
              {itemKey === 'creationDate' ||
              itemKey === 'ticketStatus' ||
              itemKey === 'orderBy' ||
              itemKey === 'adsStatus' ? (
                <CustomTypography>
                  <FilterCardDescriber filterKey={itemKey} value={value} />
                </CustomTypography>
              ) : (
                <CustomTypographyDescribe>{value}</CustomTypographyDescribe>
              )}
            </CardContent>
          </Card>
        </Box>
        // </Tooltip>
      );
    }

    return <></>;
  };

  return (
    <Page title="Pré-venda" className={classes.root}>
      <TicketsFilter
        setMarketplaceFunction={componentSetMarketplaceFilter}
        setCurrentPage={setCurrentPage}
        filterData={filterData}
        setFilterData={setFilterData}
        filterFormData={filterFormData}
        removeFilterFromChip={removeFilterFromChip}
      />

      <Container maxWidth={false} style={{ opacity: loading ? '0.3' : '1' }}>
        <PreSaleTitle
          totalResults={totalResults}
          marketplace={filterData?.marketPlace[0]}
        />

        <Grid container style={{ borderRadius: '5px' }}>
          <Grid item xs={12}>
            <Box
              mb={2}
              style={{
                display: 'flex',
                width: '100%',
                flexWrap: 'wrap',
                gap: '15px'
              }}
            >
              {Object.entries(filterData)?.map(([key], index) => (
                <FilterChip
                  {...{ index }}
                  itemKey={key}
                  key={Math.random()}
                  value={filterData[key]}
                />
              ))}
            </Box>
          </Grid>
          {data && data?.length > 0 ? (
            <Grid item xs={12}>
              {data?.map((item, index) => (
                <TicketRow key={item.id}>
                  <div style={{ width: '50%' }}>
                    <PreSaleTab
                      data={item}
                      id={item.id}
                      index={index}
                      handleSetQuestionToAnswer={handleSetQuestionToAnswer}
                      setCurrentTicket={setCurrentTicket}
                      activateProtocol={activateProtocol}
                      getData={getData}
                      openProductDetailsModal={openProductDetailsModal}
                      haveMessage={haveMessage}
                    />
                  </div>
                  <div style={{ width: '50%' }}>
                    <div
                      id="presale-message-box"
                      style={{
                        width: '100%',
                        height: '100%',
                        position: 'relative'
                      }}
                    >
                      <PreSaleMessage
                        id={item.id}
                        activateProtocol={activateProtocol}
                        data={question}
                        openProductDetailsModal={openProductDetailsModal}
                        blockInput={blockInput}
                        getData={getData}
                        openModal={openModal}
                        enableButton={enableButton}
                        setEnableButton={setEnableButton}
                        lengthList={data.length - 1}
                        indexTicket={index}
                        setBlockInput={setBlockInput}
                        setHaveMessage={setHaveMessage}
                        setLoading={setLoading}
                      />
                    </div>
                  </div>
                </TicketRow>
              ))}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Box p={1} style={{ background: '#fff' }}>
                <Typography align="center">
                  Nenhum registro localizado.
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>

        {indexActivate === data?.length - 2 && (
          <div style={{ height: '170px', width: '100%', display: 'block' }} />
        )}

        <table style={{ display: 'block' }}>
          {page?.toString() && (
            <PreSaleCustomFooter
              count={count || 0}
              textLabels={{
                next: 'Próxima página',
                previous: 'Página anterior',
                rowsPerPage: 'Itens por página',
                displayRows: t('i18n.datatable.pagination.DISPLAYROWS'),
                jumpToPage: 'Jump to Page:'
              }}
              rowsPerPage={rowsPerPage || 20}
              page={page}
              changeRowsPerPage={changeRowsPerPage}
              changePage={changePage}
              rowsPerPageOptions={[20]}
            />
          )}
        </table>
      </Container>

      <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
        <CircularProgress />
      </StyledSpinner>

      <PreSaleChatModal
        openState={openChatModal}
        closeModal={closeChatModal}
        chatModalData={chatModalData}
        currentTicket={currentTicket}
      />

      <PreSaleMoreDetailsModal
        openState={opendetailsModal}
        closeModal={closedetailsModal}
        detailsModalData={detailsModalData}
      />

      <PreSaleConfirmModal
        openState={openConfirmModal}
        closeModal={closeConfirmModal}
        // question={question}
        currentTicket={currentTicket}
        setBlockInput={setBlockInput}
        getData={getData}
        setLoading={setLoading}
        setEnableButton={setEnableButton}
        handleShowTicket={handleShowTicket}
      />
    </Page>
  );
};

export default PreSaleView;
