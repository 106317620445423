import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterInput from '../../../../components/FilterInput.component';

const TicketNumber = ({ filterForm, handleSetFilter }) => {
  const { t } = useTranslation();
  return (
    <Box mb={2}>
      <Grid container>
        <Grid item xs={7}>
          <FilterInput
            {...{
              form: filterForm,
              setForm: handleSetFilter,
              name: 'id',
              label: `${t('i18n.ticketslistview.ID')} Conecta`
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TicketNumber;
