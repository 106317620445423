import { Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HelpIcon from '@material-ui/icons/Help';
import ErrorIcon from '@material-ui/icons/Error';
import { COLORS } from '../../../../styles/settings/colors.config';
import timezoneFormat from '../../../../utils/timezoneFormat';

const useStyles = makeStyles(() => ({
  gridItem: {
    marginBlockEnd: '3px'
  },
  typoLine: {
    fontSize: '14px',
    fontWeight: 400,
    color: `${COLORS.blackText}`
  },
  typoLineName: {
    fontSize: '12.5px',
    fontWeight: 400,
    color: `${COLORS.blackText}`
  },
  pointer: {
    cursor: 'pointer'
  }
}));

const appendAsterisk = description => {
  if (['API TESTE'].includes(description)) {
    description = `${description} *`;
  }
  return description;
};

const MarketplaceItem = ({ item, getDetail }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.gridItem}>
      <Grid item xs={1}>
        {item.status === 'green' && (
          <CheckCircleIcon
            fontSize="small"
            style={{ color: COLORS.btnSuccessColor, marginLeft: '12px' }}
            className={classes.pointer}
            onClick={() =>
              getDetail(item.status, item.text, item.datetimeOut, item.idlog)
            }
          />
        )}
        {item.status === 'yellow' && (
          <HelpIcon
            fontSize="small"
            style={{ color: COLORS.WarningColorSLA, marginLeft: '12px' }}
            className={classes.pointer}
            onClick={() =>
              getDetail(item.status, item.text, item.datetimeOut, item.idlog)
            }
          />
        )}
        {item.status === 'red' && (
          <ErrorIcon
            fontSize="small"
            style={{ color: COLORS.btnDangerColor, marginLeft: '12px' }}
            className={classes.pointer}
            onClick={() =>
              getDetail(item.status, item.text, item.datetimeOut, item.idlog)
            }
          />
        )}
      </Grid>
      <Grid item xs={3}>
        <Typography
          variant="h6"
          className={`${classes.typoLineName} ${classes.pointer}`}
          onClick={() =>
            getDetail(item.status, item.text, item.datetimeOut, item.idlog)
          }
        >
          {appendAsterisk(item.text)}
        </Typography>
      </Grid>
      {/* <Grid item xs={2}>
        <Typography variant="h6" className={classes.typoLine}>
          {item.progress}
        </Typography>
      </Grid> */}
      <Grid item xs={2}>
        <Typography
          variant="h6"
          className={classes.typoLine}
          align={!item.freq ? 'center' : 'left'}
        >
          {item.freq ? item.freq : '-'}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          variant="h6"
          className={classes.typoLine}
          align={!item.datetimeIn ? 'center' : 'left'}
        >
          {item.datetimeIn
            ? timezoneFormat(item.datetimeIn, 'DD/MM HH:mm')
            : '-'}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          variant="h6"
          className={classes.typoLine}
          align={!item.datetimeOut ? 'center' : 'left'}
        >
          {item.datetimeOut
            ? timezoneFormat(item.datetimeOut, 'DD/MM HH:mm')
            : '-'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MarketplaceItem;
