import { Box, CircularProgress, Container } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StyledSpinner } from '../../styles/components/SpinnerDefault';
import Page from '../../components/Page';
import config from '../../config';
import API from '../../services/api';
import {
  CustomDataTableWrapper,
  CustomDataTable,
  CustomTableButton
} from '../../styles/components/CustomDataTable';
import CustomFooter from '../../styles/components/CustomFooter';
import HeaderTitle from '../../components/HeaderTitle';

const MacrosList = () => {
  const { t } = useTranslation();

  const columns = [
    {
      name: 'id',
      label: '',
      options: {
        filter: false,
        sort: false,
        display: false
      }
      // options: defaultColumOptions
    },
    {
      name: 'name',
      label: t('i18n.groupcontainer.NAME'),
      options: {
        filter: false,
        sort: false
      }
      // options: defaultColumOptions
    },
    {
      name: 'enable',
      label: 'Habilitado?',
      options: {
        customBodyRender: (value) => {
          return value ? t('i18n.simplewords.YES') : t('i18n.simplewords.NO');
        },
        filter: false,
        sort: false
      }
      // options: defaultColumOptions
    },
    {
      name: 'description',
      label: 'Descrição',
      options: {
        filter: false,
        sort: false
      }
      // options: defaultColumOptions
    },
    {
      name: 'user_group',
      label: 'Grupos',
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        display: false
      }
    }
  ];
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  // const [page, setPage] = React.useState([]);
  const [load, setLoad] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [displayData, setDisplayData] = React.useState([]);
  const [currentSelectedRows, setCurrentSelectedRows] = React.useState([]);

  const refreshTableContent = () => {
    setLoad(false);
    API.get(config.backend.macroURL)
      .then((response) => {
        setLoad(true);
        setData(response.data.data);
        // setPage({
        //   size: 10,
        //   totalElements: response.data.data.length,
        //   totalPages: 1,
        //   number: 0
        // });
      })
      .catch(() => {
        setLoad(true);
      });
  };

  const deleteMacro = (id, name) => {
    API({
      method: 'DELETE',
      url: config.backend.macroURL,
      data: { id }
    })
      .then(() => {
        const successMessage =
          t('i18n.macros.MACRO_DELETE_SUCCESS_START') +
          name +
          t('i18n.macros.MACRO_DELETE_SUCCESS_END');
        enqueueSnackbar(successMessage, {
          variant: 'success'
        });
      })
      .catch(() => {
        const errorMessage = t('i18n.macros.MACRO_DELETE_ERROR_START') + id;
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        });
      });
  };

  const options = {
    serverSide: false,
    pagination: true,
    download: false,
    selectableRowsHeader: false,
    print: false,
    filter: false,
    search: false,
    viewColumns: false,
    onRowsDelete: () => {
      for (let i = 0; i < displayData.length; i++) {
        const item = displayData[i];
        const id = item.data[0];
        const name = item.data[1];
        if (currentSelectedRows.includes(i)) {
          deleteMacro(id, name);
        }
      }
      refreshTableContent();
    },
    onTableChange: (action, tableState) => {
      if (action === 'changePage' || action === 'changeRowsPerPage') {
        refreshTableContent(tableState);
      }

      if (action === 'rowSelectionChange') {
        const temp = [];
        tableState.selectedRows.data.forEach((item) => temp.push(item.index));
        setCurrentSelectedRows(temp);
        setDisplayData(tableState.displayData);
      }
    },
    customToolbar: () => {
      return (
        <>
          {/* <IconButton
            onClick={() =>
              refreshTableContent({ page: page.number, rowsPerPage: page.size })
            }
          >
            <RefreshIcon />
          </IconButton>
          <IconButton disabled size="large">
            |
          </IconButton> */}
          <CustomTableButton
            variant="text"
            color="primary"
            size="small"
            onClick={() => {
              history.push('/macros/new');
            }}
            startIcon={<AddIcon />}
          >
            {t('i18n.macros.NEW_MACRO')}
          </CustomTableButton>
        </>
      );
    },
    onRowClick: (rowData) => {
      const id = rowData[0];
      const nome = rowData[1];
      const habilitado = rowData[2];
      const descricao = rowData[3];
      const grupos = rowData[4];
      const acoes = rowData[5];
      history.push({
        pathname: `/macros/new`,
        state: { id, nome, habilitado, descricao, grupos, acoes, editing: true }
      });
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          rowsPerPageOptions={[10, 15, 20]}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: load
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: (column) =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };

  return (
    <Page title="Macros">
      <Container maxWidth={false}>
        <Box mt={3}>
          <CustomDataTableWrapper style={{ opacity: !load ? '0.3' : '1' }}>
            <CustomDataTable
              title={<HeaderTitle title={t('i18n.dashboardlayout.MACRO')} />}
              data={data}
              columns={columns}
              selectableRowsOnClick
              options={options}
              className="table-clickable"
            />
          </CustomDataTableWrapper>
          <StyledSpinner style={{ display: !load ? 'block' : 'none' }}>
            <CircularProgress />
          </StyledSpinner>
        </Box>
      </Container>
    </Page>
  );
};

export default MacrosList;
