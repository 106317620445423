import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  ListItemAvatar,
  Typography,
  List,
  ListItemText,
  ListItem
} from '@material-ui/core';
import styled from 'styled-components';

import { useMarketplaceList } from '../../../hooks/hooks';
import indexHook from '../indexHook';

import { COLORS } from '../../../styles/settings/colors.config';

const CustomListItem = styled(ListItem)`
  .MuiAvatar-circular {
    background: ${props => props.filteractivate === 'true' && COLORS.greenText};
  }
`;

const FilterAccordion = ({
  open,
  setFilter,
  setSubmit,
  filter
  // dashboardFilter
}) => {
  const { t } = indexHook();
  const MARKETS_PLACE = useMarketplaceList().filter(item => item.withGrades);
  const [expanded, setExpanded] = useState('panel1');
  const [marketPlacesSelected, setMarketPlacesSelected] = useState(
    filter?.marketplace
  );

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleSetMarketplace = marketplace => {
    setMarketPlacesSelected([...[marketplace]]);
    setFilter({
      ...filter,
      ...{ marketplace: [...[marketplace]] }
    });
    setSubmit(true);
  };

  useEffect(() => {
    setMarketPlacesSelected(filter?.marketplace);
  }, [filter]);

  return (
    <div>
      <Accordion
        square
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography className={`${open ? 'menu-opened' : 'menu-closed'}`}>
            {t('i18n.ticketslistview.MARKETPLACE_FILTER')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List className="marketplace-list">
            {MARKETS_PLACE.map(value => {
              const labelId = `checkbox-list-secondary-label-${value}`;

              return (
                <CustomListItem
                  onClick={() => {
                    handleSetMarketplace(value.value);
                  }}
                  filteractivate={
                    !!marketPlacesSelected &&
                    !!marketPlacesSelected?.find(item => {
                      return item === value.value;
                    })
                      ? 'true'
                      : 'false'
                  }
                  key={value.icon}
                  button
                >
                  <ListItemAvatar>
                    <Avatar
                      style={{
                        backgroundColor:
                          !!marketPlacesSelected &&
                          !!marketPlacesSelected?.find(item => {
                            return item === value.value;
                          }) &&
                          COLORS.greenText,
                        width: '27px',
                        height: '27px',
                        fontSize: '12px'
                      }}
                    >
                      {value.icon}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText id={labelId} primary={value.text} />
                </CustomListItem>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FilterAccordion;
