import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Typography, Box, Card, CardContent, Tooltip } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import userService from '../../../../services/user.service';
import { handleHistoricPermission } from '../services/historicServices';
import filterHelper from '../../../../utils/filterHelper';

import { COLORS } from '../../../../styles/settings/colors.config';

import {
  RemoveFilterCardButton,
  cardStyle,
  FilterCardHeader,
  CustomTypographyDescribe,
  typographyStyle
} from '../../../../styles/components/Tags';

export const CustomTitle = styled(Typography)`
  color: ${COLORS.greenText} !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

export const FilterChip = ({
  itemKey,
  index,
  filterData,
  removeFilterFromChip,
  filterFormData
}) => {
  const { t } = useTranslation();

  const showRemove = () => {
    if (itemKey === 'serviceAttendantLogin' && !userService.hasGroup('admin'))
      return false;
    return true;
  };

  return (
    itemKey !== 'orderBy' &&
    itemKey !== 'createdIni' &&
    itemKey !== 'createdFim' &&
    itemKey !== 'firstResponseTimeInSeconds' &&
    itemKey !== 'responseTimeRelationalOperator' &&
    itemKey !== 'page' &&
    itemKey !== 'sortBy' &&
    itemKey !== 'drillDown' && (
      <Box key={index}>
        <Card
          style={{
            borderRadius: '10px'
          }}
        >
          <CardContent style={cardStyle}>
            <FilterCardHeader>
              <Typography variant="overline" style={typographyStyle}>
                {t(filterFormData[itemKey]?.label)}
              </Typography>

              <Tooltip title={t('i18n.dashboard.REMOVE_FILTER')}>
                <span>
                  {showRemove() && (
                    <RemoveFilterCardButton
                      color="primary"
                      aria-label="delete"
                      onClick={() => {
                        removeFilterFromChip(itemKey);
                      }}
                      disabled={
                        filterFormData[itemKey]?.label ===
                          'i18n.ticketslistview.ATTENDANT' &&
                        !handleHistoricPermission()
                      }
                    >
                      <Close />
                    </RemoveFilterCardButton>
                  )}
                </span>
              </Tooltip>
            </FilterCardHeader>
            <CustomTypographyDescribe>
              {filterData[itemKey] !== '' &&
                filterHelper.filterChipLabeler(
                  itemKey,
                  filterData[itemKey],
                  t,
                  true
                )}
            </CustomTypographyDescribe>
          </CardContent>
        </Card>
      </Box>
    )
  );
};
