import { Grid, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import StyledSelect from './StyledSelect';
import { ItemHeader, useStyles } from './styles';

const Required = () => (
  <Typography style={{ color: 'red', lineHeight: '15px', marginLeft: '3px' }}>
    *
  </Typography>
);

export default function DynamicFields({ dynamicFields, setDynamicFields }) {
  const [localFields, setLocalFields] = useState(dynamicFields);
  const classes = useStyles();

  useEffect(() => {
    setDynamicFields(localFields);
  }, [localFields]);

  useEffect(() => {
    setLocalFields(dynamicFields);
  }, [dynamicFields]);

  return (
    <Grid container>
      {localFields.map((field, index) => {
        const options = field.optionsList?.map(item => {
          return { label: item, value: item };
        });
        return (
          <Grid item xs={4} key={Math.random()}>
            {field.fieldType === 'ListaDeOpcoes' ? (
              <>
                <ItemHeader>
                  {field.fieldLabel}
                  <Required />
                </ItemHeader>
                <StyledSelect
                  value={field.value}
                  id={field.id}
                  selectedValues={dynamicFields}
                  setSelectedValues={e => {
                    const updatedLocalFields = [...localFields];
                    updatedLocalFields[index].value = e.target.value;
                    setLocalFields(updatedLocalFields);
                  }}
                  {...{ options }}
                />
              </>
            ) : (
              <>
                <ItemHeader>
                  {field.fieldLabel}
                  <Required />
                </ItemHeader>
                <TextField
                  variant="outlined"
                  defaultValue={field.value}
                  onBlur={e => {
                    const updatedLocalFields = [...localFields];
                    updatedLocalFields[index].value = e.target.value;
                    setLocalFields(updatedLocalFields);
                  }}
                  className={classes.inputSelect}
                />
              </>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}
