import { combineReducers } from 'redux';
// import dashboardFilterReducer from './views/dashboardV2/redux/dashFilterReducer';
import {
  notificationReducer,
  showHistoricChatReducer,
  closeChatReducer,
  pulledChatReducer,
  disconnectChatReducer,
  clientListReducer,
  showVisitorsReducer,
  showClientTalkReducer,
  visitorsOnlineReducer,
  lastMessageReducer,
  clientInAttendanceDataReducer,
  changeTabViewChatReducer,
  clientInAttendanceNowReducer,
  clientInAttendanceReducer,
  connectedStateReducer,
  customerReasonReducer
} from './views/chats/NewChatV2/chatStore';

import {
  ticketsDashboardFilterReducer,
  ticketsDashboardLoadingReducer,
  ticketsDashboardEquipDataReducer,
  ticketsDashboardIndividualDataReducer,
  ticketsDashboardRemovedKeyReducer
} from './views/dashboardV2/TicketsDashboard/ticketsFilter.store';

import {
  messagesDashboardFilterReducer,
  messagesDashboardLoadingReducer,
  messagesDashboardEquipDataReducer,
  messagesDashboardIndividualDataReducer,
  messagesDashboardRemovedKeyReducer
} from './views/dashboardV2/MessagesDashboard/ticketsFilter.store';

import {
  chatsDashboardFilterReducer,
  chatsDashboardLoadingReducer,
  chatsDashboardEquipDataReducer,
  chatsDashboardIndividualDataReducer,
  chatsDashboardRemovedKeyReducer
} from './views/dashboardV2/ChatsDashboard/ticketsFilter.store';

import {
  reportsDashboardFilterReducer,
  reportsDashboardLoadingReducer,
  reportsDashboardListReducer,
  reportsDashboardIndividualDataReducer,
  reportsDashboardRemovedKeyReducer
} from './views/dashboardV2/ReportsDashboard/ticketsFilter.store';

import {
  presaleOnlineDashboardFilterReducer,
  presaleOnlineDashboardLoadingReducer,
  presaleOnlineDashboardEquipDataReducer,
  presaleOnlineDashboardIndividualDataReducer,
  presaleOnlineDashboardRemovedKeyReducer
} from './views/dashboardV2/PreSaleOnlineDashboard/ticketsFilter.store';

import {
  presaleOnlineB2BDashboardFilterReducer,
  presaleOnlineB2BDashboardLoadingReducer,
  presaleOnlineB2BDashboardEquipDataReducer,
  presaleOnlineB2BDashboardIndividualDataReducer,
  presaleOnlineB2BDashboardRemovedKeyReducer
} from './views/dashboardV2/PreSaleOnlineB2BDashboard/ticketsFilter.store';

import {
  presaleOfflineDashboardFilterReducer,
  presaleOfflineDashboardLoadingReducer,
  presaleOfflineDashboardEquipDataReducer,
  presaleOfflineDashboardIndividualDataReducer,
  presaleOfflineDashboardRemovedKeyReducer
} from './views/dashboardV2/PreSaleOfflineDashboard/ticketsFilter.store';

import {
  showFavoriteModalReducer,
  setFavoriteReducer,
  showFavoriteLimitReducer,
  editFavoriteReducer,
  loadFavoritesReducer
} from './layouts/DashboardLayout/favoritesBar/favoriteStore';

import {
  marketPlaceScoreLoadingReducer,
  marketplaceScoreDashboardTeamDataReducer,
  marketplaceScoreDashboardIndividualDataReducer,
  marketPlaceScoreDashboardFilterReducer
} from './views/dashboardV2/MarketplaceScoreDashboard/ticketsFilter.store';

const allReducers = combineReducers({
  notificationReducer,
  showHistoricChatReducer,
  closeChatReducer,
  pulledChatReducer,
  disconnectChatReducer,
  clientListReducer,
  showVisitorsReducer,
  showClientTalkReducer,
  visitorsOnlineReducer,
  lastMessageReducer,
  clientInAttendanceDataReducer,
  changeTabViewChatReducer,
  clientInAttendanceNowReducer,
  clientInAttendanceReducer,
  ticketsDashboardFilterReducer,
  ticketsDashboardLoadingReducer,
  ticketsDashboardEquipDataReducer,
  ticketsDashboardIndividualDataReducer,
  ticketsDashboardRemovedKeyReducer,
  messagesDashboardFilterReducer,
  messagesDashboardLoadingReducer,
  messagesDashboardEquipDataReducer,
  messagesDashboardIndividualDataReducer,
  messagesDashboardRemovedKeyReducer,
  chatsDashboardFilterReducer,
  chatsDashboardLoadingReducer,
  chatsDashboardEquipDataReducer,
  chatsDashboardIndividualDataReducer,
  chatsDashboardRemovedKeyReducer,
  reportsDashboardFilterReducer,
  reportsDashboardLoadingReducer,
  reportsDashboardListReducer,
  reportsDashboardIndividualDataReducer,
  reportsDashboardRemovedKeyReducer,
  connectedStateReducer,
  showFavoriteModalReducer,
  setFavoriteReducer,
  showFavoriteLimitReducer,
  editFavoriteReducer,
  loadFavoritesReducer,
  presaleOnlineDashboardFilterReducer,
  presaleOnlineDashboardLoadingReducer,
  presaleOnlineDashboardEquipDataReducer,
  presaleOnlineDashboardIndividualDataReducer,
  presaleOnlineDashboardRemovedKeyReducer,
  presaleOfflineDashboardFilterReducer,
  presaleOfflineDashboardLoadingReducer,
  presaleOfflineDashboardEquipDataReducer,
  presaleOfflineDashboardIndividualDataReducer,
  presaleOfflineDashboardRemovedKeyReducer,
  marketPlaceScoreLoadingReducer,
  marketplaceScoreDashboardTeamDataReducer,
  marketplaceScoreDashboardIndividualDataReducer,
  marketPlaceScoreDashboardFilterReducer,
  customerReasonReducer,
  presaleOnlineB2BDashboardFilterReducer,
  presaleOnlineB2BDashboardLoadingReducer,
  presaleOnlineB2BDashboardEquipDataReducer,
  presaleOnlineB2BDashboardIndividualDataReducer,
  presaleOnlineB2BDashboardRemovedKeyReducer
});

export default allReducers;
