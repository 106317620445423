import React from 'react';
import B2WMarketplaceSupportModal from '../../../../messages/b2w/B2WMarketplaceSupportModal';

export const B2WMarketplaceSupportModalIframe = ({
  marketplaceModalOpen,
  setMarketplaceModalOpen
}) => {
  return (
    <B2WMarketplaceSupportModal
      open={marketplaceModalOpen}
      setOpen={setMarketplaceModalOpen}
    />
  );
};
