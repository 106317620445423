const statusEnum = () => {
  return [
    {
      label: 'i18n.preSale.PAUSED',
      value: 'PAUSED',
      marketplace: 'MERCADO_LIVRE'
    },
    {
      label: 'i18n.preSale.CLOSED_UNANSWERED',
      value: 'CLOSED_UNANSWERED',
      marketplace: 'MERCADO_LIVRE'
    },
    {
      label: 'i18n.preSale.UNANSWERED',
      value: 'NEW',
      marketplace: 'MERCADO_LIVRE'
    },
    {
      label: 'i18n.preSale.REMOVED',
      value: 'REMOVED',
      marketplace: 'MERCADO_LIVRE'
    },
    {
      label: 'i18n.preSale.ANSWERED',
      value: 'ANSWERED',
      marketplace: 'MERCADO_LIVRE'
    },
    {
      label: 'i18n.preSale.UNDER_REVIEW',
      value: 'UNDER_REVIEW',
      marketplace: 'MERCADO_LIVRE'
    },
    {
      label: 'i18n.preSale.NEW',
      value: 'NEW',
      marketplace: 'YOURVIEWS'
    },
    {
      label: 'i18n.preSale.ANSWERED_PUBLIC',
      value: 'ANSWERED_PUBLIC',
      marketplace: 'YOURVIEWS'
    },
    {
      label: 'i18n.preSale.ANSWERED_REMOVED',
      value: 'ANSWERED_REMOVED',
      marketplace: 'YOURVIEWS'
    },
    {
      label: 'i18n.preSale.ANSWERED_PRIVATE',
      value: 'ANSWERED_PRIVATE',
      marketplace: 'YOURVIEWS'
    },
    {
      label: 'i18n.preSale.IGNORED',
      value: 'IGNORED',
      marketplace: 'YOURVIEWS'
    }
  ];
};

export default statusEnum;
