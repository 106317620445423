import { CardContent, Grid } from '@material-ui/core';
import React from 'react';
import marketPlacesEnum from '../../../../enums/marketPlaces.enum';
import AgentMessageCard from '../baloons/AgentMessageCard';
import ClientMessageCard from '../baloons/ClientMessageCard';
import RobotMessageCard from '../components/RobotMessageCard';
import userService from '../../../../services/user.service';
import { useMarketplaceList } from '../../../../hooks/hooks';

import { StyledPaper, Divider } from '../styles';

import MessageActions from '../components/MessageActions.component';

const GenericMessages = ({
  messages,
  chat,
  chatState,
  showBotMessage,
  botMessage,
  normalMessageRespostaLoading,
  dadosPedido,
  getTicket,
  handleAcceptAnswer,
  handleEditBotMessage,
  handleDeleteBotMessage,
  handleAppliedMacro,
  reason,
  resposta,
  setResposta,
  attachPDFTerm,
  files,
  filesToUpload,
  getRootProps,
  getInputProps,
  recipientClient,
  recipientOperator,
  mlTicketblocked,
  setRecipientClient,
  setRecipientOperator,
  setAssignResponsible,
  setSendEmail,
  isReasonFullfield,
  sendResponse
}) => {
  const marketplacesFromHooks = useMarketplaceList();

  // Atualmente somente CNOVA não utiliza o padrão para envio de mensagens
  const marketplaceWithActions = () => {
    const result = marketplacesFromHooks.some(
      el => el.allowedActions && el.value === chatState?.marketPlace
    );
    return result;
  };

  // Marketplaces 100% mediados tem exibição do balão da mensagem com cor e posição diferenciada'
  const marketplaceIs100PercentMediated = () => {
    const result = marketplacesFromHooks.some(
      el => el.fullMediated && el.value === chatState?.marketPlace
    );
    return result;
  };

  const checkToDisableSend = () => {
    // Enquanto está loading
    if (normalMessageRespostaLoading) {
      return true;
    }

    /**
     * Marketplace VIVO permite envio apenas para protocolos NEW ou AWAITING
     */
    if (
      !['NEW', 'AWAITING'].includes(chat.ticketStatus) &&
      chatState?.marketPlace === marketPlacesEnum.VIVO
    ) {
      return true;
    }

    // Se o protocolo estiver RESOLVIDO ou FECHADO e o usuário não tiver privilégio 'Enviar mensagem em protocolo Fechado/Resolvido'
    if (
      ['RESOLVED', 'CLOSED'].includes(chat.ticketStatus) &&
      !userService.hasRole('ticket_send_message_closed_resolved')
    ) {
      return true;
    }

    // Se o Motivo e Detalhe do Motivo não estiverem totalmente preenchidos
    if (!isReasonFullfield(reason)) {
      return true;
    }

    // Se há Reclamação aberta no MERCADO LIVRE
    if (
      chat?.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity?.status ===
      'opened'
    ) {
      return true;
    }

    // Se for da AMERICANAS e tiver processo de mediação em curso
    if (
      chatState?.marketPlace === marketPlacesEnum.B2W &&
      chat.marketplaceBundle?.dataMessagesMediadas?.mediation === true
    ) {
      return true;
    }

    // Se for da MAGALU e existir Reclamação aberta
    if (
      chatState?.marketPlace === marketPlacesEnum.MAGALU &&
      chat?.marketplaceBundle?.reason === 'Reclamação'
    ) {
      return true;
    }

    // Se for um dos marketplaces abaixo e não estiver preenchido o destinatário da mensagem
    if (
      [
        marketPlacesEnum.CARREFOUR,
        marketPlacesEnum.KABUM,
        marketPlacesEnum.COLOMBO
      ].includes(chatState?.marketPlace) &&
      !recipientClient &&
      !recipientOperator
    ) {
      return true;
    }

    return false;
  };

  const checkToDisableAttachment = () => {
    if (normalMessageRespostaLoading) {
      return true;
    }

    /**
     * Marketplace VIVO permite apenas um anexo
     */
    if (
      chatState?.marketPlace === marketPlacesEnum.VIVO &&
      filesToUpload.length > 0
    ) {
      return true;
    }

    // Americanas só permite um arquivo por vez e não pode ter texto junto
    if (
      chatState.marketPlace === marketPlacesEnum.B2W &&
      (filesToUpload.length > 0 || resposta?.length > 0)
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      {/* Mensagens aba genérica */}
      <StyledPaper className="message-paper">
        <CardContent>
          {messages.map(m => {
            let div;
            if (
              ['MARKETPLACE', 'BOT'].includes(m.agent) &&
              marketplaceIs100PercentMediated()
            ) {
              div = (
                <Grid container spacing={1} style={{ marginBottom: '12px' }}>
                  <Grid item md={10} xs={12}>
                    <ClientMessageCard
                      message={m}
                      customerName={m.agent}
                      marketPlace={chat.marketPlace}
                    />
                  </Grid>
                </Grid>
              );
            } else if (m.agent !== 'CLIENT') {
              div = (
                <Grid container spacing={1} style={{ marginBottom: '12px' }}>
                  <Grid item md={2} xs={12} />
                  <Grid item md={10} xs={12}>
                    <AgentMessageCard
                      message={m}
                      marketPlace={chat.marketPlace}
                      idIncidenteCNOVA={
                        chatState?.marketplaceBundle?.idIncidenteCNOVA || null
                      }
                    />
                  </Grid>
                </Grid>
              );
            } else {
              div = (
                <Grid container spacing={1} style={{ marginBottom: '12px' }}>
                  <Grid item md={10} xs={12}>
                    <ClientMessageCard
                      message={m}
                      customerName={chat.customerName}
                      marketPlace={chat.marketPlace}
                      idIncidenteCNOVA={
                        chatState?.marketplaceBundle?.idIncidenteCNOVA || null
                      }
                    />
                  </Grid>
                </Grid>
              );
            }
            return <div key={Math.random()}>{div}</div>;
          })}
          {/* Mesagem contendo resposta automática do robô, o valor vem de messagesHooks.js */}
          {showBotMessage === 'true' &&
            botMessage?.map((answer, index) => (
              <Grid
                container
                key={answer.answer}
                spacing={1}
                style={{ marginBottom: '12px' }}
              >
                <Grid item md={2} xs={12} />
                <Grid item md={10} xs={12}>
                  <RobotMessageCard
                    answer={answer}
                    handleAcceptAnswer={handleAcceptAnswer}
                    handleEditBotMessage={handleEditBotMessage}
                    handleDeleteBotMessage={handleDeleteBotMessage}
                    index={index}
                    showBtns={!!reason?.desc}
                  />
                </Grid>
              </Grid>
            ))}
          {normalMessageRespostaLoading && (
            <div key="loadingNewMlmessage">
              <Grid container spacing={1}>
                <Grid item lg={2} md={6} xs={12} />
                <Grid item lg={10} md={10} xs={12}>
                  <AgentMessageCard
                    message={{
                      message: resposta,
                      status: 'loading',
                      agent: 'ABO'
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </CardContent>
      </StyledPaper>

      <Divider />

      {/* Funcionalidades: Anexo, Termo de Devolução, Input mensagem e Enviar               */}
      {marketplaceWithActions() && (
        <MessageActions
          typeMessage="normalMessage"
          disableAttachment={checkToDisableAttachment()}
          loading={
            normalMessageRespostaLoading ||
            mlTicketblocked ||
            (chatState?.marketPlace === 'B2W' && filesToUpload?.length > 0)
          }
          resposta={resposta}
          setResposta={setResposta}
          disableSend={checkToDisableSend()}
          sendResponse={sendResponse}
          mlTicketblocked={mlTicketblocked}
          attachPDFTerm={attachPDFTerm}
          chatState={chatState}
          chat={chat}
          dadosPedido={dadosPedido}
          getTicket={getTicket}
          handleAppliedMacro={handleAppliedMacro}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          files={files}
          setRecipientClient={setRecipientClient}
          setRecipientOperator={setRecipientOperator}
          setAssignResponsible={setAssignResponsible}
          setSendEmail={setSendEmail}
        />
      )}
    </>
  );
};

export default GenericMessages;
