import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInputWhiteBg } from '../../../components/StyledInputWhiteBg';
import AcerModal from '../../../components/AcerModal';
import API from '../../../services/api';
import ConfirmationModal from '../../../utils/ConfirmationModal';
import timezoneFormat from '../../../utils/timezoneFormat';

const useStyles = makeStyles({
  root: {
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  grayCard: {
    backgroundColor: '#f8f4f4'
  }
});

const GroupingModal = ({ open, setOpen, defaultPedido = null }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [confirmModalOpenState, setConfirmModalOpenState] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState(
    defaultPedido ? 'order' : null
  );
  const [searchValues, setSearchValues] = useState({
    order: defaultPedido || '',
    cpf: '',
    name: ''
  });

  const defaultDisabledSearchInputs = {
    order: !defaultPedido,
    cpf: true,
    name: true
  };
  const [disabledSearchInputs, setDisabledSearchInputs] = useState(
    defaultDisabledSearchInputs
  );

  const [searchInputsRefs] = useState({
    order: useRef(),
    cpf: useRef(),
    name: useRef()
  });

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      searchInputsRefs[searchCriteria].current.focus();
    }
  }, [disabledSearchInputs]);

  const toggleEnabledSearchInput = inputToEnable => {
    setSearchCriteria(inputToEnable);
    const newDisabledSearchInputsDefinition = {
      ...defaultDisabledSearchInputs
    };
    newDisabledSearchInputsDefinition[inputToEnable] = false;
    setDisabledSearchInputs(newDisabledSearchInputsDefinition);
  };

  const setSearchValue = (key, value) => {
    setSearchValues(prevState => {
      const prev = { ...prevState };
      prev[key] = value;
      return prev;
    });
  };

  const [firstTicketSelected, setFirstTicketSelected] = useState({});
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [mainTicketId, setMainTicketId] = useState('');
  const [mainTicketDependents, setMainTicketDependents] = useState([]);

  const removeTicketFromSelectedList = ticketId => {
    const index = selectedTickets.indexOf(ticketId);
    const newTicketsSelected = [...selectedTickets];
    newTicketsSelected.splice(index, 1);
    setSelectedTickets(newTicketsSelected);
  };

  const putTicketInSelectedList = ticketId => {
    if (selectedTickets.includes(ticketId)) {
      return;
    }
    const newTicketsSelected = [...selectedTickets, ticketId];
    setSelectedTickets(newTicketsSelected);
  };

  const [searchResult, setSearchResult] = useState([]);
  const [filteredSearchResult, setFilteredSearchResult] = useState([]);
  const [
    filteredContentHasMainTicketAlreadyDefined,
    setFilteredContentHasMainTicketAlreadyDefined
  ] = useState(false);

  const onSelectFirstTicket = ticketData => {
    setMainTicketId('');
    setFirstTicketSelected(ticketData);
  };

  const isTicketAFather = ticket => {
    return ticket?.dependentTickets?.length > 0;
  };

  const filterResultsBasedOnFirstTicketSelected = () => {
    const newFilteredData = searchResult.filter(item => {
      return (
        item.orderId === firstTicketSelected.orderId &&
        item.customerCPF === firstTicketSelected.customerCPF
      );
    });
    newFilteredData.map(item => {
      if (isTicketAFather(item)) {
        putTicketInSelectedList(item.id);
        setMainTicketId(item.id);
        setFilteredContentHasMainTicketAlreadyDefined(true);
        setMainTicketDependents(item.dependentTickets);
      }
      return item;
    });
    setFilteredSearchResult(newFilteredData);
  };

  const thereIsFirstTicketSelected = () => {
    return firstTicketSelected?.id;
  };

  useEffect(() => {
    if (thereIsFirstTicketSelected()) {
      filterResultsBasedOnFirstTicketSelected();
    }
  }, [firstTicketSelected]);

  const selectTicket = ticketData => {
    const ticketId = ticketData?.id;
    if (selectedTickets.includes(ticketId)) {
      removeTicketFromSelectedList(ticketId);
    } else {
      putTicketInSelectedList(ticketId);
      if (!thereIsFirstTicketSelected()) {
        onSelectFirstTicket(ticketData);
      }
    }
  };

  useEffect(() => {
    if (selectedTickets.length === 0) {
      setFirstTicketSelected({});
      setMainTicketId('');
      setFilteredSearchResult(searchResult);
    }
  }, [selectedTickets]);

  const searchPayload = () => {
    const payload = {
      marketPlace: 'CUSTOMER_GUIDE'
    };
    if (searchCriteria === 'order') {
      payload.orderId = searchValues.order;
    }
    if (searchCriteria === 'cpf') {
      payload.customerCpf = searchValues.cpf;
    }
    if (searchCriteria === 'name') {
      payload.customerName = searchValues.name;
    }
    return payload;
  };

  const cleanModal = () => {
    setMainTicketId('');
    setSearchResult([]);
    setFilteredSearchResult([]);
    setSelectedTickets([]);
    setFirstTicketSelected({});
    setFilteredContentHasMainTicketAlreadyDefined(false);
    setMainTicketDependents([]);
  };

  const [loading, setLoading] = useState(false);
  const submitSearch = () => {
    if (!searchValues[searchCriteria]) {
      return;
    }
    cleanModal();
    setLoading(true);
    API.post(`/auth/ms-ticket/v1/tickets/?page=0&size=10000`, searchPayload())
      .then(response => {
        const content = response?.data?._embedded?.ticketResourceList;
        if (content) {
          setSearchResult(content);
          setFilteredSearchResult(content);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (defaultPedido) {
      submitSearch();
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    if (window.location.pathname === '/tickets') {
      setSearchValues({
        order: '',
        cpf: '',
        name: ''
      });
      cleanModal();
    }
  };

  const checkIfACtionIsSelected = value => {
    return selectedTickets.includes(value);
  };

  const groupingCheckbox = item => {
    return (
      <Checkbox
        checked={checkIfACtionIsSelected(item.id)}
        onClick={() => {
          selectTicket(item);
        }}
      />
    );
  };

  const renderGroupingCheckbox = item => {
    if (!thereIsFirstTicketSelected()) {
      return groupingCheckbox(item);
    }
    if (item.mainTicket) {
      return (
        <Tooltip
          title={`${t('i18n.grouping.ALREADY_GROUPED IN')} ${item.mainTicket}`}
        >
          <div>
            <Checkbox checked disabled />
          </div>
        </Tooltip>
      );
    }
    if (isTicketAFather(item)) {
      return <Checkbox checked disabled />;
    }
    return groupingCheckbox(item);
  };

  return (
    <>
      <AcerModal
        fullWidth
        freeBody
        maxWidth="lg"
        onClose={handleClose}
        open={open}
        hasAction
        confirmActionLabel={t('i18n.ticketsgrouping.GROUP_TICKETS')}
        modalTitle={t('i18n.ticketsgrouping.GROUP_TICKETS')}
        confirmActionFunc={() => {
          const listWithoutTicketsAlreadyIncludedInFather = selectedTickets.filter(
            item => !mainTicketDependents.includes(item)
          );
          if (listWithoutTicketsAlreadyIncludedInFather.length < 2) {
            enqueueSnackbar(
              t('i18n.ticketsgrouping.SHOULD_SELECT_AT_LEAST_TWO'),
              {
                variant: 'warning'
              }
            );
            return;
          }
          if (!mainTicketId || !selectedTickets.includes(mainTicketId)) {
            enqueueSnackbar(
              t('i18n.ticketsgrouping.SHOULD_SELECT_MAIN_TICKET'),
              {
                variant: 'warning'
              }
            );
            return;
          }
          setConfirmModalOpenState(true);
        }}
      >
        <Card className={classes.root}>
          <CardContent>
            <Typography>
              {`${t('i18n.ticketsgrouping.HINTa')} `}
              <strong>{`"${t('i18n.ticketsgrouping.HINTb')}"`}</strong>
              {` ${t('i18n.ticketsgrouping.HINTc')} `}
              <strong>{`"${t('i18n.ticketsgrouping.HINTd')}"`}</strong>
              {` ${t('i18n.ticketsgrouping.HINTe')} `}
              <strong>{`"${t('i18n.ticketsgrouping.HINTf')}"`}</strong>.
            </Typography>
          </CardContent>
          <Divider />
          <CardContent className={classes.grayCard}>
            <Typography variant="h5">
              {t('i18n.ticketsgrouping.SEARCH')}
            </Typography>
            <Box mt={2}>
              <Box display="flex" justifyContent="space-between">
                <Box width="465px">
                  <StyledInputWhiteBg
                    InputProps={{ disableUnderline: true }}
                    inputRef={searchInputsRefs.order}
                    onClick={() => {
                      toggleEnabledSearchInput('order');
                    }}
                    disabled={disabledSearchInputs.order}
                    size="small"
                    variant="filled"
                    fullWidth
                    label={t('Pedido')}
                    value={searchValues.order || ''}
                    onChange={evt => {
                      setSearchValue('order', evt.target.value);
                    }}
                  />
                </Box>
                <Box justifyContent="center" display="flex" alignItems="center">
                  <Typography>ou</Typography>
                </Box>
                <Box width="465px">
                  <StyledInputWhiteBg
                    InputProps={{ disableUnderline: true }}
                    inputRef={searchInputsRefs.cpf}
                    onClick={() => {
                      toggleEnabledSearchInput('cpf');
                    }}
                    disabled={disabledSearchInputs.cpf}
                    size="small"
                    fullWidth
                    label={t('CPF')}
                    variant="filled"
                    value={searchValues.cpf || ''}
                    onChange={evt => {
                      setSearchValue('cpf', evt.target.value);
                    }}
                  />
                </Box>
                <Box justifyContent="center" display="flex" alignItems="center">
                  <Button
                    onClick={() => {
                      submitSearch();
                    }}
                    color="primary"
                    fullWidth
                    variant="contained"
                  >
                    {t('i18n.ticketslistview.SEARCH')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </CardContent>
          <Divider />
          <CardContent>
            <Typography variant="h5">
              {t('i18n.ticketsgrouping.CUSTOMER_ORDER_DATA')}
            </Typography>
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item lg={3}>
                  <Typography>
                    {t('i18n.ticketsgrouping.CUSTOMER_ORDER_DATA_NAME')}
                  </Typography>
                  <Typography variant="h4">
                    {firstTicketSelected?.customerName || '-'}
                  </Typography>
                </Grid>
                <Grid item lg={3}>
                  <Typography>CPF:</Typography>
                  <Typography variant="h4">
                    {firstTicketSelected?.customerCPF || '-'}
                  </Typography>
                </Grid>
                <Grid item lg={3}>
                  <Typography>
                    {t('i18n.ticketsgrouping.CUSTOMER_ORDER_DATA_ORDER_ID')}
                  </Typography>
                  <Typography variant="h4">
                    {firstTicketSelected?.orderId || '-'}
                  </Typography>
                </Grid>
                <Grid item lg={3}>
                  <Typography>
                    {t('i18n.ticketsgrouping.CUSTOMER_ORDER_DATA_ORDER_DATE')}
                  </Typography>
                  <Typography variant="h4">-</Typography>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <Divider />
          <CardContent className={classes.grayCard}>
            <Typography variant="h5">
              {t('i18n.ticketsgrouping.TICKETS_TO_BE_GROUPED')}
            </Typography>
            <Box mt={2}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {t('i18n.ticketsgrouping.DO_GROUP')}
                      </TableCell>
                      <TableCell>{t('i18n.ticketslistview.ID')} </TableCell>
                      <TableCell>
                        {t('i18n.ticketsgrouping.ORDER_ID')}
                      </TableCell>
                      <TableCell>CPF</TableCell>
                      <TableCell>
                        {t('i18n.ticketslistview.OPENING_DATE')}
                      </TableCell>
                      <TableCell>SLA</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>
                        {t('i18n.ticketslistview.TICKET_TYPE')}
                      </TableCell>
                      <TableCell>Marketplace</TableCell>
                      <TableCell>{t('i18n.ticketslistview.SUBJECT')}</TableCell>
                      <TableCell>{t('i18n.ticketsgrouping.MAIN')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredSearchResult.map(item => {
                      return (
                        <TableRow key={item.id}>
                          <TableCell>{renderGroupingCheckbox(item)}</TableCell>
                          <TableCell>{item.id}</TableCell>
                          <TableCell>{item.orderId}</TableCell>
                          <TableCell>{item.customerCPF}</TableCell>
                          <TableCell>
                            {timezoneFormat(item.creationDate)}
                          </TableCell>
                          <TableCell>{item.sla_hoursSinceCreation}</TableCell>
                          <TableCell>
                            {t(
                              `i18n.ticketslistview.ticketStatus.${item.ticketStatus}`
                            )}
                          </TableCell>
                          <TableCell>
                            {t(`i18n.ticketslistview.${item.ticketType}`)}
                          </TableCell>
                          <TableCell>
                            {t(`i18n.marketplaces.${item.marketPlace}`)}
                          </TableCell>
                          <TableCell>{item.subject}</TableCell>
                          <TableCell>
                            {thereIsFirstTicketSelected() && (
                              <Checkbox
                                disabled={
                                  filteredContentHasMainTicketAlreadyDefined
                                }
                                checked={item.id === mainTicketId}
                                onClick={() => {
                                  setMainTicketId(item.id);
                                }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {filteredSearchResult.length === 0 && !loading && (
                      <TableRow>
                        <TableCell colSpan={11}>
                          <Typography>
                            {t('i18n.ticketsgrouping.NO_RESULTS')}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {loading && (
                      <TableRow>
                        <TableCell colSpan={11}>
                          <LinearProgress />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            {thereIsFirstTicketSelected() && (
              <Box mt={2}>
                <Button
                  onClick={() => {
                    setSelectedTickets([]);
                  }}
                >
                  {t('i18n.grouping.BACK')}
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      </AcerModal>
      <ConfirmationModal
        submitConfirmation={() => {
          const index = selectedTickets.indexOf(mainTicketId);
          const newTicketsSelected = [...selectedTickets];
          newTicketsSelected.splice(index, 1);

          API.post(
            `/auth/ms-ticket/v1/tickets/grouping/${mainTicketId}`,
            newTicketsSelected
          )
            .then(() => {
              enqueueSnackbar(t('i18n.grouping.SUCCESSFULLY_GROUPED'), {
                variant: 'success'
              });
            })
            .catch(error => {
              enqueueSnackbar(t(error?.response?.data?.key), {
                variant: 'error'
              });
            })
            .finally(() => {
              cleanModal();
              setConfirmModalOpenState(false);
              handleClose();
              if (window.location.pathname.includes('/ticket-container')) {
                window.location.reload();
              }
            });
        }}
        closeParent={() => {
          setConfirmModalOpenState(false);
        }}
        openState={confirmModalOpenState}
        message={t('i18n.grouping.CONFIRM_GROUPING_MESSAGE')}
      />
    </>
  );
};

export default GroupingModal;
