export const tableOptions = {
  serverSide: false,
  pagination: false,
  download: false,
  print: false,
  filter: false,
  search: false,
  viewColumns: false,
  selectableRows: 'none'
};

export const numericValue = (item, cardType) => {
  switch (cardType) {
    case 'chatsAnswered':
      return item.chats;
    case 'chatsNotAnswered':
      return item.chats_lost;
    case 'chatsLikes':
      return item.likes;
    case 'chatsDislikes':
      return item.deslikes;
    case 'firstMinusThirty':
      return item.tma_ok;
    case 'firstPlusThirty':
      return item.tma_nok;
    default:
      return 0;
  }
};

export const colors = [
  '#FF6B3B',
  '#626681',
  '#FFC100',
  '#9FB40F',
  '#76523B',
  '#DAD5B5',
  '#0E8E89',
  '#E19348',
  '#F383A2',
  '#247FEA',
  '#778899',
  '#FF00FF',
  '#DA70D6',
  '#800080',
  '#FF8C00',
  '#8FBC8F',
  '#4B0082',
  '#DB7093',
  '#DC143C',
  '#00FFFF'
];
