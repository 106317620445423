import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Close } from '@material-ui/icons';
import API from '../../../../../services/api';
import Loader from '../../../../../components/Loader';

const generateHoursOrMinutes = type => {
  return [...Array(type === 'h' ? 24 : 60).keys()].map(i =>
    (i < 10 ? `0${i}` : i).toString()
  );
};

const useStyles = makeStyles(() => ({
  menu: {
    height: 300
  },
  formControl: {
    minWidth: 120
  },
  textarea: {
    width: '280px',
    minHeight: '80px',
    maxHeight: '80px',
    margin: '15px 0 15px 0',
    borderColor: '#D6D6D6',
    borderRadius: '5px',
    color: '#555E63',
    resize: 'none',
    padding: '2px',
    '&:focus-visible': {
      outline: '2px solid #80c343'
    },
    fontFamily: 'Roboto'
  }
}));

const reloadScreen = () => {
  window.location.reload();
};

const insertSchedule = async (
  date,
  hour,
  minutes,
  description,
  setError,
  setIsLoading,
  setSuccess,
  chat
) => {
  setError(false);
  setIsLoading(true);
  date.setHours(hour);
  date.setMinutes(minutes);
  const json = {
    scheduledDateTime: `${moment(date).format('YYYY-MM-DDTHH:mm:ss')}`,
    description: `${description}`,
    status: date > new Date() ? 'ACTIVE' : 'EXPIRES_TODAY'
  };

  try {
    await API.post(
      `/auth/ms-ticket/v1/tickets/${chat.id}/protocol-scheduling`,
      json
    );
    setSuccess(true);
    setTimeout(() => {
      reloadScreen();
    }, 3000);
  } catch (error) {
    setError(error.response.data);
  }
  setIsLoading(false);
};

const ScheduleWindow = ({ closePopover, chat, editing, schedule }) => {
  const [date, setDate] = useState('');
  const [hour, setHour] = useState('');
  const [minutes, setMinutes] = useState('');

  const currentHour = moment().format('HH');
  const currentMinute = moment().format('mm');
  const currentDate = moment().format('YYYY-MM-DD');
  const [oldDate, setOldDate] = useState('');

  const [disableMinute, setDisableMinute] = useState(true);
  const [disableHour, setDisableHour] = useState(false);

  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (editing) {
      const scheduleDate = new Date(schedule.scheduledDateTime);
      const hoursString =
        scheduleDate.getHours() > 9
          ? scheduleDate.getHours().toString()
          : `0${scheduleDate.getHours().toString()}`;
      const minutesString =
        scheduleDate.getMinutes() > 9
          ? scheduleDate.getMinutes().toString()
          : `0${scheduleDate.getMinutes().toString()}`;
      const dateString = moment(scheduleDate).format('YYYY-MM-DD');
      setHour(hoursString);
      setMinutes(minutesString);
      setDate(dateString);
      setDescription(schedule.description);
    }
  }, [editing]);

  useEffect(() => {
    if (date === currentDate) {
      setOldDate(date);
      if (minutes <= currentMinute) {
        setDisableMinute(true);
      }

      if (Number(hour) >= currentHour) {
        if (Number(hour) === Number(currentHour)) {
          if (Number(minutes) <= Number(currentMinute)) {
            setMinutes('');
          }
          setDisableMinute(true);
        } else {
          setDisableMinute(false);
        }
      }

      if (hour !== '' || minutes !== '') {
        if (oldDate !== date) {
          setMinutes('');
          setHour('');
          setOldDate(date);
        }
      }

      if (currentMinute === 59) {
        setDisableMinute(false);
        if (hour >= currentHour) {
          setDisableHour(true);
          setHour('');
        }
      }

      if (hour === '' && minutes === '') {
        setDisableHour(true);
        setDisableMinute(false);
      }
    } else {
      if (hour !== '' || minutes !== '') {
        if (oldDate !== date) {
          setOldDate(date);
        }
      }
      setDisableMinute(false);
      setDisableHour(false);
    }
  }, [
    minutes,
    hour,
    disableMinute,
    disableHour,
    currentHour,
    currentMinute,
    date,
    oldDate
  ]);

  const processSchedule = async () => {
    setDisabled(true);
    insertSchedule(
      new Date(date.replace(/-/g, '/')),
      hour,
      minutes,
      description,
      setError,
      setIsLoading,
      setSuccess,
      chat,
      editing
    );
  };
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography>Agendar para</Typography>
        <IconButton size="small" onClick={closePopover}>
          <Close style={{ fontSize: '15px' }} />
        </IconButton>
      </Box>
      {isLoading && <Loader />}

      <Box minWidth="280px">
        <TextField
          style={{ minWidth: '100%', marginBottom: '15px' }}
          id="date"
          variant="outlined"
          type="date"
          required
          disabled={success}
          format="dd/MM/yyyy"
          InputProps={{
            inputProps: { min: moment(new Date()).format('YYYY-MM-DD') }
          }}
          value={date}
          onChange={item => {
            setDate(item.target.value);
          }}
        />
        <Box display="flex" justifyContent="space-between">
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="hour">Horas</InputLabel>
            <Select
              labelId="hour"
              id="hour"
              value={hour}
              onChange={ev => {
                setHour(ev.target.value);
              }}
              MenuProps={{
                className: classes.menu,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              }}
              label="Horas"
            >
              {generateHoursOrMinutes('h').map(item =>
                item < currentHour && date === currentDate ? (
                  <MenuItem
                    value={item}
                    disabled={disableHour}
                    key={Math.random()}
                  >{`${item}h`}</MenuItem>
                ) : (
                  <MenuItem
                    value={item}
                    key={Math.random()}
                  >{`${item}h`}</MenuItem>
                )
              )}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="minutes">Minutos</InputLabel>
            <Select
              labelId="minutes"
              id="minutes"
              value={minutes}
              onChange={ev => {
                setMinutes(ev.target.value);
              }}
              MenuProps={{
                className: classes.menu,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              }}
              label="Horas"
            >
              {generateHoursOrMinutes('m').map(item =>
                item <= currentMinute && date === currentDate ? (
                  <MenuItem
                    value={item}
                    disabled={disableMinute}
                    key={Math.random()}
                  >{`${item}min`}</MenuItem>
                ) : (
                  <MenuItem
                    value={item}
                    key={Math.random()}
                  >{`${item}min`}</MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Box>
        <Box display="flex" width="100%" justifyContent="space-between">
          <FormControl>
            <TextareaAutosize
              id="description"
              className={classes.textarea}
              minRows={5}
              placeholder="Descrição"
              aria-label="maximum height"
              value={description}
              onChange={item => setDescription(item.target.value)}
            />
          </FormControl>
        </Box>
        {(error || success) && (
          <Box style={{ marginTop: '10px' }}>
            {error && <Alert severity="error">Erro ao agendar: {error}</Alert>}
            {success && <Alert severity="success">Agendado com Sucesso!</Alert>}
          </Box>
        )}
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          marginTop="15px"
          minWidth="250px"
          gap={3}
        >
          {success ? (
            <Button
              color="primary"
              variant="contained"
              disabled={disabled}
              onClick={processSchedule}
              endIcon={<CircularProgress size={14} />}
            >
              AGENDAR
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              disabled={disabled}
              onClick={processSchedule}
            >
              AGENDAR
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ScheduleWindow;
