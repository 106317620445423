import { Box, Typography } from '@material-ui/core';
import { QueryBuilder } from '@material-ui/icons';
import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { CustomTypographLink } from '../../../dashboardV2/styles.v2';
import handleOpenNewTabHook from '../../../dashboardV2/components/handleGetTabFilterHook';

const TicketLabel = ({ ticketCount, title, textColor, handleOpenNewTab }) => {
  let ticketType = '';

  switch (title) {
    case 'vencidos':
      ticketType = 'EXPIRED';
      break;
    case 'ativos':
      ticketType = 'ACTIVE';
      break;
    case 'vencem hoje':
      ticketType = 'EXPIRES_TODAY';
      break;
    default:
      return '';
  }

  return (
    <span style={{ marginLeft: '10px', display: 'flex' }}>
      <CustomTypographLink
        align="right"
        style={{
          fontSize: '16px',
          fontWeight: '500'
        }}
        onClick={() => {
          handleOpenNewTab(ticketType);
        }}
      >
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {ticketCount}
          <span style={{ color: textColor, marginLeft: '5px' }}>
            <Typography
              component="span"
              style={{ fontWeight: 'bold', fontFamily: 'Roboto' }}
            >
              {title}
            </Typography>
          </span>
        </span>
      </CustomTypographLink>
    </span>
  );
};

const TicketScheduleIndicator = ({ filterData, indicators }) => {
  const { t } = useTranslation();

  const { handleOpenNewTab } = handleOpenNewTabHook({
    filterData,
    t,
    key: 'protocolSchedulingStatus'
  });

  return (
    <Box
      display="flex"
      style={{
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '5px'
      }}
    >
      <Box display="flex">
        <QueryBuilder style={{ marginRight: '10px' }} />
        <Typography>Agendamentos:</Typography>
      </Box>
      <TicketLabel
        ticketCount={indicators.active}
        title="ativos"
        textColor="#5280D1"
        handleOpenNewTab={handleOpenNewTab}
      />
      <TicketLabel
        ticketCount={indicators.expired}
        title="vencidos"
        textColor="#D9363C"
        handleOpenNewTab={handleOpenNewTab}
      />
      <TicketLabel
        ticketCount={indicators.expiresToday}
        title="vencem hoje"
        textColor="#F79E4F"
        handleOpenNewTab={handleOpenNewTab}
      />
    </Box>
  );
};

export default memo(TicketScheduleIndicator);
