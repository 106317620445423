import { Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../../styles/settings/colors.config';
import timezoneFormat from '../../../../utils/timezoneFormat';

const useStyles = makeStyles(theme => ({
  gridItem: {
    marginBlockEnd: '2px'
  },
  typoLine: {
    fontSize: '12px',
    fontWeight: 400,
    color: `${COLORS.blackText}`
  },
  lineBackground: {
    backgroundColor: theme.palette.background.dark
  },
  typoCenter: {
    fontWeight: 500,
    backgroundColor: theme.palette.background.dark,
    color: `${COLORS.blackText}`,
    textAlign: 'center',
    marginBlockEnd: '15px',
    paddingTop: '6.5px',
    paddingBottom: '6.5px',
    marginBlockStart: '-8px',
    marginInlineStart: '-7px',
    marginInlineEnd: '-7px'
  },
  typoLastDate: {
    backgroundColor: theme.palette.background.dark,
    color: `${COLORS.blackText}`,
    fontWeight: 400,
    textAlign: 'center',
    marginBlockEnd: '7px',
    paddingTop: '12px',
    paddingBottom: '7px',
    marginBlockStart: '-8px',
    marginInlineStart: '-7px',
    marginInlineEnd: '-7px'
  },
  typoCenterHint: {
    fontWeight: 300,
    color: `${COLORS.blackText}`,
    textAlign: 'center',
    marginBlockEnd: '15px',
    paddingTop: '10px',
    paddingBottom: '10px',
    marginTop: '-8px',
    marginInlineStart: '-7px',
    marginInlineEnd: '-7px'
  },
  details: {
    marginBlockStart: '15px'
  }
}));

const ApiDetailItem = ({ item, odd }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={0} className={classes.gridItem}>
      <Grid item xs={2} lg={1}>
        <Typography
          variant="h6"
          className={
            odd
              ? `${classes.typoLine} ${classes.lineBackground}`
              : classes.typoLine
          }
        >
          {timezoneFormat(item.datetime, 'HH:mm:ss')}
        </Typography>
      </Grid>
      <Grid item xs={10} lg={11}>
        <Typography
          variant="h6"
          className={
            odd
              ? `${classes.typoLine} ${classes.lineBackground}`
              : classes.typoLine
          }
        >
          {item.description}
        </Typography>
      </Grid>
    </Grid>
  );
};

const Log = ({ apiName, apiDetail, apiStatus, apiDate, loading }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <div>
        {loading && apiDetail.length === 0 && (
          <Typography variant="h6" className={classes.typoCenterHint}>
            {t('i18n.datatable.body.NOMATCH_LOADING')}
          </Typography>
        )}
        {!loading && apiName === '' && (
          <Typography variant="h6" className={classes.typoCenterHint}>
            {`(${t('i18n.monitor.CLICK_HINT')})`}
          </Typography>
        )}
        {apiName !== '' && (
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="h3" className={classes.typoCenter}>
                {apiName}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" className={classes.typoLastDate}>
                {timezoneFormat(apiDate, 'DD/MM HH:mm')}
              </Typography>
            </Grid>
          </Grid>
        )}
      </div>
      <div className={classes.details}>
        {apiDetail.length > 0 &&
          apiDetail.map((item, index) => (
            <ApiDetailItem
              item={item}
              key={`${item.datetime}${item.description}`}
              odd={index > 0 && index % 2 !== 0}
            />
          ))}
        {apiDetail.length === 0 && apiStatus === 'green' && (
          <Typography variant="h5">{`${t(
            'i18n.monitor.MSG_ALL_GOOD'
          )} :)`}</Typography>
        )}
        {apiDetail.length === 0 && apiStatus === 'red' && (
          <Typography variant="h5">{`${t(
            'i18n.monitor.MSG_SOMETHING_WRONG'
          )}`}</Typography>
        )}
      </div>
    </>
  );
};

export default Log;
