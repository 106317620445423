import { Collapse, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';

const CancellationAlertItem = ({ ticketType, quantity, show, classes }) => {
  const [cancellationMessage, setCancellationMessage] = useState(true);

  const message = () => {
    if (quantity === 2) {
      return `ATENÇÃO: esta é a 3ª solicitação de ${ticketType} deste mesmo CPF/CNPJ nos últimos 12 meses`;
    }

    if (quantity > 2) {
      return `ATENÇÃO: existem 3 ou mais solicitações de ${ticketType} deste mesmo CPF/CNPJ nos últimos 12 meses`;
    }

    return '';
  };

  if (quantity >= 2 && show) {
    return (
      <Collapse in={cancellationMessage}>
        <Alert
          severity="error"
          className={classes.alertError}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              style={{ marginRight: 10 }}
              onClick={() => {
                setCancellationMessage(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          {message()}
        </Alert>
      </Collapse>
    );
  }

  return <></>;
};

export default CancellationAlertItem;
