import moment from 'moment';

/** Default function to convert start and end dates to UTC.
 * Use these to convert every start dates on Conecta,
 * because backend needs to receive dates in UTC format.
 * Implementing these conversions takes time, and we will need to do this
 * on every form that uses this approach.
 *
 * @param {Date} date Date to be converted
 * @param {string} type use 'start' for start dates and 'end' for end dates
 */

export const brtToUTC = (date, type) => {
  if (type === 'start') {
    return moment(date).add(3, 'hours');
  }

  if (type === 'end') {
    return moment(date)
      .add(1, 'days')
      .add(2, 'hours')
      .add(59, 'minutes')
      .add(59, 'seconds');
  }

  return date;
};

/** End of default function */

const defaultDateFormat = momentInput => {
  return momentInput.format('YYYY-MM-DDTHH:mm:ss');
};

const convertToInput = (date, period) => {
  let newDate = '';

  if (period === 'start') {
    newDate = moment(new Date(date)).format('YYYY-MM-DD');
  }

  if (period === 'end') {
    newDate = moment(new Date(date))
      .subtract(1, 'day')
      .format('YYYY-MM-DD');
  }

  return newDate;
};

const proccessDateValuesForByPeriod = byPeriod => {
  let startDate;
  let endDate;
  if (byPeriod === 'in_current_month') {
    startDate = moment()
      .startOf('month')
      .add(3, 'hours');
    endDate = moment().add(3, 'hours');
  }

  if (byPeriod === 'previous_month') {
    startDate = moment()
      .subtract(1, 'months')
      .startOf('month')
      .add(3, 'hours');
    endDate = moment()
      .subtract(1, 'months')
      .endOf('month')
      .add(3, 'hours');
  }

  if (byPeriod === 'last_thirty_days') {
    startDate = moment()
      .subtract(30, 'days')
      .startOf('day')
      .add(3, 'hours');
    endDate = moment().add(3, 'hours');
  }

  if (byPeriod === 'last_six_months') {
    startDate = moment()
      .subtract(6, 'months')
      .startOf('month')
      .add(3, 'hours');
    endDate = moment().add(3, 'hours');
  }

  if (byPeriod === 'in_current_year') {
    startDate = moment()
      .startOf('year')
      .add(3, 'hours');
    endDate = moment().add(3, 'hours');
  }

  if (byPeriod === 'last_seven_days') {
    startDate = moment()
      .subtract(7, 'days')
      .startOf('day')
      .add(3, 'hours');
    endDate = moment()
      .subtract(1, 'days')
      .startOf('day')
      .add(1, 'days')
      .add(2, 'hours')
      .add(59, 'minutes')
      .add(59, 'seconds');
  }

  return { startDate, endDate, byPeriod };
};

const proccessDateValuesForCustomPeriod = (start, end) => {
  const startDate = moment(start, 'dd/MM/yyyy')
    .startOf('day')
    .add(3, 'hours');
  const endDate = moment(end, 'dd/MM/yyyy')
    .endOf('day')
    .add(2, 'hours')
    .add(59, 'minutes')
    .add(59, 'seconds');

  return { startDate, endDate };
};

const convertDateByHourly = hourly => {
  const startDate = defaultDateFormat(
    moment(Date.now())
      .add(3, 'hours')
      .set({ second: 0 })
      .subtract(hourly, 'h')
  );
  const endDate = defaultDateFormat(
    moment(Date.now())
      .add(3, 'hours')
      .set({ second: 59 })
      .subtract(0, 'h')
  );
  return { startDate, endDate };
};

const convertDateByHourlyV2 = hourly => {
  const startDate = moment(new Date())
    .add(3, 'hours')
    .set({ second: 0 })
    .subtract(hourly, 'h');
  const endDate = moment(new Date())
    .add(3, 'hours')
    .set({ second: 59 })
    .subtract(0, 'h');

  return { startDate, endDate };
};

const convertDateToFilter = filter => {
  if (filter.periodType === 'hourly') {
    const startDate = defaultDateFormat(
      moment(Date.now())
        .add(3, 'hours')
        .set({ second: 0 })
        .subtract(filter.dateAliasInAWayHumanCanRead, 'h')
    );
    const endDate = defaultDateFormat(
      moment(Date.now())
        .add(2, 'hours')
        .set({ seconds: 59 })
        .subtract(0, 'h')
    );
    return { startDate, endDate };
  }
  if (filter.periodType === 'byperiod') {
    const { startDate, endDate } = proccessDateValuesForByPeriod(
      filter.dateAliasInAWayHumanCanRead
    );

    return {
      startDate: defaultDateFormat(startDate),
      endDate: defaultDateFormat(endDate)
    };
  }

  if (filter.periodType === 'custom') {
    return {
      startDate: filter.startDate,
      endDate: filter.endDate
    };
  }
  if (filter.periodType === 'realTime') {
    return {
      startDate: undefined,
      endDate: undefined
    };
  }

  return filter;
};

const formatHour = time => {
  const hour = `${time?.replace(':', 'h').split(':')[0]}m`;
  return hour;
};

export {
  proccessDateValuesForByPeriod,
  convertDateByHourly,
  defaultDateFormat,
  proccessDateValuesForCustomPeriod,
  convertDateToFilter,
  convertToInput,
  formatHour,
  convertDateByHourlyV2
};
