import { FormControl, makeStyles, Select } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../styles/settings/colors.config';

const StyledSelect = styled(Select)`
  -webkit-appearance: none;

  option {
    -webkit-appearance: none;
  }

  option:checked {
    background-color: #d6ffc8;
    background-opacity: 6%;
  }
`;

const useStyles = makeStyles({
  selectRoot: {
    border: `1px solid ${COLORS.borderGray}`,
    padding: '10px',
    borderRadius: '5px',
    marginTop: '2px',
    width: '300px',
    backgroundColor: 'white',
    '&:focus': {
      backgroundColor: 'white'
    },
    '& .MuiInputBase-input': {
      border: 'transparent'
    }
  }
});

const SelectMultipleComponent = ({
  value,
  onChange,
  valueList,
  size,
  disabled
}) => {
  const classes = useStyles();
  const isPrimitiveList = valueList?.every((item) => typeof item === 'string');

  const listOptions = isPrimitiveList
    ? valueList?.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))
    : valueList
        ?.filter((item) => !!item)
        .map((item) => (
          <option key={item.value} value={item.value}>
            {item.text}
          </option>
        ));

  return (
    <FormControl>
      <StyledSelect
        multiple
        native
        value={value}
        onChange={onChange}
        disabled={disabled}
        classes={{ select: classes.selectRoot }}
        inputProps={{
          id: 'select-multiple-native',
          size,
          minheight: size,
          fontSize: '12px'
        }}
      >
        {listOptions}
      </StyledSelect>
    </FormControl>
  );
};

export default SelectMultipleComponent;
