import { Grid } from '@material-ui/core';
import React from 'react';
import {
  SectionContent,
  SectionContentTitle,
  SectionContentWrapper,
  SectionCustomGrid,
  SectionLink,
  SectionProductItem,
  SectionText
} from './SectionTabs';

const ProductItemML = ({ prod, formatLink, t }) => {
  const itemFontSize = '12px';
  return (
    <SectionProductItem container spacing={2} key={prod.item.id}>
      <Grid item lg={4} xs={4}>
        <SectionContentWrapper>
          <SectionContentTitle>
            <SectionText
              style={{ fontSize: itemFontSize, paddingLeft: '10px' }}
            >
              {t('i18n.ticketcontainer.CODE')}{' '}
              <span style={{ fontSize: itemFontSize }}>
                {prod.item.seller_sku}
              </span>
            </SectionText>
          </SectionContentTitle>
          <SectionContent>
            <SectionLink
              fSize={itemFontSize}
              bold
              target="_blank"
              rel="noreferrer"
              href={formatLink(prod.item.id, prod.item.title)}
            >
              {prod.item.title.substr(0, 75)}
            </SectionLink>
          </SectionContent>
        </SectionContentWrapper>
      </Grid>
      <SectionCustomGrid item lg={2} xs={2}>
        <SectionContentWrapper>
          <SectionContent>
            <SectionText fSize={itemFontSize}>{prod.quantity}</SectionText>
          </SectionContent>
        </SectionContentWrapper>
      </SectionCustomGrid>
      <SectionCustomGrid item lg={2} xs={2}>
        <SectionContentWrapper>
          <SectionContent>
            <SectionText fSize={itemFontSize}>
              {prod.unit_price.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              }) || 'R$ 0,00'}
            </SectionText>
          </SectionContent>
        </SectionContentWrapper>
      </SectionCustomGrid>
      <SectionCustomGrid item lg={2} xs={2}>
        <SectionContentWrapper>
          <SectionContent>
            <SectionText fSize={itemFontSize}>
              {prod?.desconto?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              }) || 'R$ 0,00'}
            </SectionText>
          </SectionContent>
        </SectionContentWrapper>
      </SectionCustomGrid>
      <SectionCustomGrid item lg={2} xs={2}>
        <SectionContentWrapper>
          <SectionContent>
            <SectionText fSize={itemFontSize}>
              {(prod.quantity * prod.unit_price).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              }) || 'R$ 0,00'}
            </SectionText>
          </SectionContent>
        </SectionContentWrapper>
      </SectionCustomGrid>
    </SectionProductItem>
  );
};

export default ProductItemML;
