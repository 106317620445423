export const brDay = day => {
  switch (day) {
    case 'Monday':
      return 'Seg';
    case 'Tuesday':
      return 'Ter';
    case 'Wednesday':
      return 'Qua';
    case 'Thursday':
      return 'Qui';
    case 'Friday':
      return 'Sex';
    case 'Saturday':
      return 'Sab';
    case 'Sunday':
      return 'Dom';
    default:
      return '';
  }
};

export const usDay = day => {
  switch (day) {
    case 'Seg':
      return 'Monday';
    case 'Ter':
      return 'Tuesday';
    case 'Qua':
      return 'Wednesday';
    case 'Qui':
      return 'Thursday';
    case 'Sex':
      return 'Friday';
    case 'Sab':
      return 'Saturday';
    case 'Dom':
      return 'Sunday';
    default:
      return '';
  }
};
