/*eslint-disable */
import React, { useState } from 'react';
import {
  Box,
  Container,
  Divider,
  makeStyles,
  CircularProgress,
  Tab,
  Tabs,
  Paper,
  CardContent,
  Typography
} from '@material-ui/core';
import { useSelector } from 'react-redux';

import { StyledSpinner } from '../../../styles/components/SpinnerDefault';
import { CustomTabPanel } from '../components/custom.components';
import DashboardContent from './dashboardContent';
import Header from './header.component';
import indexHook from '../indexHook';
import { messagesTabName } from './config';

const MessagesDashboard = ({ tabValue, dashboardFilter, setDashboardFilter }) => {
  const loading = useSelector(state => state.messagesDashboardLoadingReducer);
  
  const dataEquipDash = useSelector(
    state => state.messagesDashboardEquipDataReducer
  );

  const dataIndividualDash = useSelector(
    state => state.messagesDashboardIndividualDataReducer
  );

  const marketPlacesSelected = dashboardFilter.marketplace || [];

  return (
    <CustomTabPanel value={tabValue} index={messagesTabName}>
      <Header dashboardFilter={dashboardFilter} setDashboardFilter={setDashboardFilter} />
      <br />
      <Divider />
      <br />
      {!loading ? (
        <DashboardContent
          dataEquipDash={dataEquipDash}
          dataIndividualDash={dataIndividualDash}
          isAdmin={dashboardFilter.attendant == ""}
          dashboardFilter={dashboardFilter}
          marketPlacesSelected={marketPlacesSelected}
        />
      ) : (
        <>
          <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
            <CircularProgress />
          </StyledSpinner>
        </>
      )}
    </CustomTabPanel>
  );
};

export default MessagesDashboard;
