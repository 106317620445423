import userService from '../../../../services/user.service';

export const loadStatusOptions = (status, chat, mlTicketblocked, set) => {
  if (
    chat.marketPlace === 'MERCADO_LIVRE' &&
    mlTicketblocked &&
    userService.hasRole('protocol_change_status')
  ) {
    set(['RESOLVED']);
  } else if (status === 'NEW') {
    set([]);
  } else if (status === 'ANSWERED') {
    if (chat.marketPlace === 'MAGALU') {
      set(['AWAITING_CLOSED']);
    } else if (['KABUM', 'CARREFOUR'].includes(chat.marketPlace)) {
      set(['CUSTOMER_RETURN']);
    } else {
      set(['RESOLVED', 'CUSTOMER_RETURN']);
    }
  } else if (status === 'CUSTOMER_RETURN') {
    if (['MAGALU', 'KABUM', 'CARREFOUR'].includes(chat.marketPlace)) {
      set([]);
    } else {
      set(['RESOLVED']);
    }
  } else if (status === 'RESOLVED') {
    set([]);
  } else if (status === 'CLOSED') {
    set([]);
  } else if (status === 'AWAITING') {
    if (chat.marketPlace === 'MAGALU') {
      set([]);
    } else if (['KABUM', 'CARREFOUR'].includes(chat.marketPlace)) {
      set(['CUSTOMER_RETURN']);
    } else {
      set(['RESOLVED']);
    }
  } else if (status === 'AWAITING_PARTNER') {
    set([]);
  }

  if (chat?.marketPlace === 'KABUM' || chat?.marketPlace === 'CARREFOUR') {
    switch (status) {
      case 'NEW':
        set([]);
        break;
      case 'RESOLVED':
        set([]);
        break;
      case 'ANSWERED':
        set(['CUSTOMER_RETURN', 'RESOLVED']);
        break;
      case 'AWAITING':
        set(['RESOLVED']);
        break;
      case 'AWAITING_PARTNER':
        set(['CUSTOMER_RETURN', 'RESOLVED']);
        break;
      case 'CUSTOMER_RETURN':
        set(['RESOLVED']);
        break;
      default:
        set(['ANSWERED', 'CUSTOMER_RETURN', 'RESOLVED']);
        break;
    }
  }
};
