import { Box, Grid } from '@material-ui/core';
import React from 'react';

const MainHeader = () => {
  return (
    <Box
      display="flex"
      style={{ width: '100%', fontFamily: 'Roboto' }}
      py={4}
      px={2}
    >
      <Grid container>
        <Grid item xs={1} />

        <Grid item xs={3}>
          Descrição
        </Grid>

        <Grid item xs={2}>
          Horário de Atendimento
        </Grid>

        <Grid item xs={3}>
          Vigência
        </Grid>

        <Grid item xs={1}>
          Data da Criação
        </Grid>

        <Grid item xs={1} />

        <Grid item xs={1} />
      </Grid>
    </Box>
  );
};

export default MainHeader;
