import { Card } from '@material-ui/core';
import React from 'react';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { COLORS } from '../../../../../styles/settings/colors.config';
import { InConstruction } from '../../../styles.v2';
import { floatToDecimal } from '../../../../../helpers/utils';
import CardCustomTitle from '../components/CardCustomTitle';
import { CardText, PercentInfo } from './styles';
import GoalInfo from './GoalInfo';

const InfoCard = ({
  title,
  descriptionHint,
  saleQuantity,
  infoScore,
  criteriaText,
  currentScoreData,
  name,
  inConstruction
}) => {
  const goalScore = currentScoreData[name] || 0;

  const goalColors = {
    green: {
      bgColor: 'rgba(110, 165, 248, 0.1)',
      textColor: '#6EA5F8',
      textValue: `Abaixo de ${floatToDecimal(goalScore)}% permitido`
    },
    yellow: {
      bgColor: 'rgba(255, 139, 39, 0.1)',
      textColor: '#FF8B27CC',
      textValue: `Próximo de ${floatToDecimal(goalScore)}% permitido`
    },
    red: {
      bgColor: '#FCDEE2',
      textColor: 'red',
      textValue: `Acima de ${floatToDecimal(goalScore)}% permitido`
    }
  };

  const findScoreColor = () => {
    if (infoScore <= goalScore * 0.9) return goalColors.green;
    if (infoScore > goalScore * 0.9 && infoScore <= goalScore)
      return goalColors.yellow;
    return goalColors.red;
  };

  const scoreColors = findScoreColor();

  const gainLossIcon = () => {
    if (infoScore < goalScore) {
      return <ArrowDownward style={{ color: COLORS.greenText }} />;
    }
    return <ArrowUpward style={{ color: COLORS.btnDangerColor }} />;
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Card
          style={{
            minHeight: '270px',
            width: '100%',
            marginBottom: '20px',
            borderRadius: '10px'
          }}
        >
          <CardCustomTitle
            {...{ title, descriptionHint }}
            hr
            custompadding="true"
          />
          <div
            style={{
              padding: '16px 24px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px'
            }}
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <PercentInfo color="#434343">
                {floatToDecimal(infoScore)}%
              </PercentInfo>{' '}
              {gainLossIcon()}
            </span>
            <CardText>São {saleQuantity} das suas vendas</CardText>
            <GoalInfo {...{ ...scoreColors }} />
            <CardText>{criteriaText}</CardText>
          </div>
        </Card>
        {inConstruction && <InConstruction />}
      </div>
    </>
  );
};

export default InfoCard;
