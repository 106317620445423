import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../../../styles/settings/colors.config';
import DonutChart from '../../PresaleOnline/DonutChart';
import CardCustomTitle from '../components/CardCustomTitle';

const RightAlignedParagraph = styled.span`
  display: flex;
  justify-content: flex-end;
  color: #999999;
`;

const BoldInfo = styled.span`
  font-weight: bold;
  margin: 0 5px 0 5px;
  color: ${COLORS.grayText};
`;

const ScoreDetailText = ({ type, score_b2w }) => {
  switch (type) {
    case 'delaySending':
      return (
        <Typography>
          <RightAlignedParagraph>
            De
            <BoldInfo>
              {score_b2w?.details_expeditionIndex_status?.total}
            </BoldInfo>
            pedidos
          </RightAlignedParagraph>
          <RightAlignedParagraph>
            <BoldInfo>
              {score_b2w?.details_expeditionIndex_status?.amount}
            </BoldInfo>
            enviados com atraso
          </RightAlignedParagraph>
          <RightAlignedParagraph>
            Um total de
            <BoldInfo>
              {score_b2w?.details_expeditionIndex_status?.percentage}%
            </BoldInfo>
          </RightAlignedParagraph>
        </Typography>
      );
    case 'mediated':
      return (
        <Typography>
          <RightAlignedParagraph>
            De
            <BoldInfo>
              {score_b2w?.details_mediationIndex_status?.total}
            </BoldInfo>
            pedidos
          </RightAlignedParagraph>
          <RightAlignedParagraph>
            <BoldInfo>
              {score_b2w?.details_mediationIndex_status?.amount}
            </BoldInfo>
            mediados
          </RightAlignedParagraph>
          <RightAlignedParagraph>
            Um total de
            <BoldInfo>
              {score_b2w?.details_mediationIndex_status?.percentage}%
            </BoldInfo>
          </RightAlignedParagraph>
        </Typography>
      );
    case 'cancelled':
      return (
        <Typography>
          <RightAlignedParagraph>
            De
            <BoldInfo>
              {score_b2w?.details_cancellationIndex_status?.total}
            </BoldInfo>
            pedidos
          </RightAlignedParagraph>
          <RightAlignedParagraph>
            <BoldInfo>
              {score_b2w?.details_cancellationIndex_status?.amount}
            </BoldInfo>
            cancelados
          </RightAlignedParagraph>
          <RightAlignedParagraph>
            Um total de
            <BoldInfo>
              {score_b2w?.details_cancellationIndex_status?.percentage}%
            </BoldInfo>
          </RightAlignedParagraph>
        </Typography>
      );
    default:
      return <></>;
  }
};

const getScore = (type, score_b2w) => {
  switch (type) {
    case 'delaySending':
      return score_b2w?.details_expeditionIndex_score;
    case 'mediated':
      return score_b2w?.details_mediationIndex_score;
    case 'cancelled':
      return score_b2w?.details_cancellationIndex_score;
    default:
      return '';
  }
};

const ScoreInfoCardB2W = ({
  title,
  descriptionHint,
  type,
  score_b2w,
  fillDonutGraph,
  getChartColors,
  getTitleData,
  getTitleSubContent
}) => {
  const possibleScore = 5;
  const score = getScore(type, score_b2w);
  const chartData = fillDonutGraph(score, possibleScore);
  const titleData = getTitleData(score, '40px');
  const titleSubContent = getTitleSubContent(possibleScore);
  const colors = getChartColors(score, possibleScore);
  return (
    <Card style={{ width: '100%', marginBottom: '20px', borderRadius: '10px' }}>
      <CardCustomTitle {...{ title, descriptionHint }} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <DonutChart
              data={chartData}
              innerRadius={0.8}
              radius={0.6}
              totalValue={score}
              height="130px"
              {...{ colors, titleData, titleSubContent }}
            />
          </Grid>
          <Grid
            item
            xs={7}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <ScoreDetailText {...{ type, score_b2w }} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ScoreInfoCardB2W;
