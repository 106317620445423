/*eslint-disable */
// import { Skeleton } from '@material-ui/lab';
import { Card, CardContent, Divider } from '@material-ui/core';
import { Chart, Interval } from 'bizcharts';
import React from 'react';
import dashboardServices from '../services/dashboardServices';
import { useTranslation } from 'react-i18next';

// import { useTranslation } from 'react-i18next';
// import styled from 'styled-components';

import { CustomTypograph, InConstruction } from '../styles';

const BarChartDashboard = ({ data }) => {
  const { t } = useTranslation();
  // const [translateData, setTranslateData] = useState();
  const { dateList } = dashboardServices.get7LastDays();
  const newData = data?.filter(item => {
    if (dateList.indexOf(item.dia) > -1) {
      return item;
    }
    return false;
  });

  return data && newData.length > 0 ? (
    <Chart height={200} width="100%" autoFit data={newData}>
      <Interval position="dia_da_semana*qtd" />
    </Chart>
  ) : (
    <>
      <CustomTypograph>
        {t('i18n.dashboard.components.empty_data_period')}
      </CustomTypograph>
    </>
  );
};

const BarChartDashboardWrapper = ({ data, title, subtitle, className, showInConstruction }) => {
  const newData = data?.map(item => {
    const { currentDayMonth } = dashboardServices.formatDate(item.dia);
    item.dia_da_semana = dashboardServices.translateLabels(item.dia_da_semana) + ' - ' + currentDayMonth;
    return item;
  });

  return (
    <div style={{ position: 'relative' }}>
      <Card style={{ width: '100%', marginBottom: '20px' }} className={className}>
        <CardContent>
          <CustomTypograph variant="h6">{title}</CustomTypograph>
          <CustomTypograph>{subtitle}</CustomTypograph>
        </CardContent>
        <Divider />
        <CardContent>
          <BarChartDashboard data={data} />
        </CardContent>
      </Card>
      {showInConstruction && (
        <InConstruction />
      )}
    </div>
  );
};

export default BarChartDashboardWrapper;
