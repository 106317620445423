import { TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import { COLORS } from '../../../../styles/settings/colors.config';
import config from '../../../../config';
import API from '../../../../services/api';

const StyledMacroOption = styled.div`
  min-width: ${props => (props.small ? '300px;' : '160px;')};
  position: relative;
  span {
    align-items: flex-end;
    color: ${COLORS.greenText};
    display: flex;
    flex-flow: row wrap;
  }
  svg {
    margin-right: 8px;
  }
`;

const TicketMacroInput = ({
  chat,
  dadosPedido,
  handleAppliedMacro,
  typeMessage,
  label,
  disabled = false,
  noLabel,
  placeholder,
  type,
  place,
  small,
  style,
  setLoadingMacro
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [macros, setMacros] = useState([]);
  const [macro, setMacro] = useState('');

  const applyMacro = async value => {
    const data = {
      id: value?.id,
      orderId: chat.orderId || null,
      dadosPedido
    };

    if (place === 'chat' || place === 'chatB2b') {
      data.type = 'fast_response';
      data.chat_id = chat.id;
      data.hashConnection = chat.hashConnection;
    }

    if (place === 'pre') {
      data.type = 'fast_response';
      data.ticket_id = chat.id;
    }

    if (!type) {
      data.ticket_id = id;
    }

    try {
      const res = await API.post(`${config.backend.macroURL}/execute`, data);
      enqueueSnackbar(t('i18n.macros.APPLIED_MACRO_SUCCESS'), {
        variant: 'success'
      });
      handleAppliedMacro(res.data.answer, typeMessage);
      setMacro('');
    } catch (error) {
      enqueueSnackbar(t('i18n.macros.ERROR_APPLY_MACRO'), {
        variant: 'error'
      });
    } finally {
      if (setLoadingMacro) {
        setLoadingMacro(false);
      }
    }
  };

  const getMacros = async () => {
    if (setLoadingMacro) {
      setLoadingMacro(true);
    }
    API.get(`${config.backend.macroURL}${type === 'fast' ? '?fast=1' : ''}`)
      .then(response => {
        if (place === 'pre') {
          const filteredSaudacao = response.data.data.find(item =>
            item.type.includes('saudacao_pre_venda_off')
          );
          if (filteredSaudacao) {
            applyMacro(filteredSaudacao);
          }
        }

        if (place) {
          const filtered = response.data.data.filter(item =>
            item.type.includes(place)
          );
          setMacros(filtered);
        } else {
          setMacros(response.data.data);
        }
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.macros.ERROR_FETCHING_MACRO'), {
          variant: 'error'
        });
        if (setLoadingMacro) {
          setLoadingMacro(false);
        }
      });
  };

  useEffect(() => {
    if (place !== 'pre') {
      getMacros();
    }
  }, []);

  useEffect(() => {
    setMacro('');
    if (place === 'pre') {
      getMacros();
    }
  }, [chat]);

  return (
    <StyledMacroOption small={small}>
      {!noLabel && (
        <Typography variant="overline">
          <CompareArrowsIcon />
          {label || t('i18n.macros.APPLY_MACRO')}
        </Typography>
      )}

      <Autocomplete
        disableClearable
        options={macros}
        getOptionLabel={option => (option?.name ? option?.name : '')}
        handleHomeEndKeys
        disabled={disabled}
        value={macro}
        disablePortal
        renderInput={params => (
          <TextField
            {...params}
            label={macro || placeholder}
            variant="outlined"
            style={style}
            size={small ? 'small' : 'medium'}
          />
        )}
        onChange={(ev, value) => {
          setMacro(value.name);
          document.activeElement.blur();
          applyMacro(value);
        }}
        noOptionsText={t('i18n.ticketcontainer.STATUS_NO_OPTIONS')}
      />
    </StyledMacroOption>
  );
};

export default TicketMacroInput;
