import moment from 'moment';
import { COLORS } from '../../../styles/settings/colors.config';
import { defaultHoursNumber } from '../services/config';
import Auth from '../../../auth/auth.service';
import userService from '../../../services/user.service';

const filterNameInStorage = 'newfilterPreSale';
const presaleOnlineTabName = 'presaleOnlineTab';

const date1 = new Date();
const date2 = new Date();
date1.setHours(0, 0, 0);
const startDate = date1;
date2.setHours(0, 0, 0);
date2.setDate(date2.getDate() + 1);
const endDate = date2;

const checkPermission = () => {
  if (Auth.getAccessToken() !== '' && Auth.getAccessToken() !== null) {
    if (userService.hasRole('specialist_pre_sale_online')) return 'specialist';
    if (userService.hasRole('analyst_pre_sale_online')) return 'analyst';
  }
  return false;
};

const defaultFilter = {
  attendant: checkPermission() === 'analyst' ? userService.getUsername() : '',
  marketplace: ['All', 'CUSTOMER_GUIDE', 'MERCADO_LIVRE'],
  tabView: presaleOnlineTabName,
  periodType: 'custom',
  startDate: moment(startDate).format('YYYY-MM-DDT03:00:00'),
  endDate: moment(endDate).format('YYYY-MM-DDT02:59:59')
};

const getGoalColor = (goal, value) => {
  switch (goal) {
    case 'chatsNotAnswered':
      return Number(value < 8) ? COLORS.greenText : COLORS.btnDangerColor;
    case 'firstMinusThirty':
      return Number(value >= 80) ? COLORS.greenText : COLORS.btnDangerColor;
    case 'chatsLikes':
      return Number(value >= 30) ? COLORS.greenText : COLORS.btnDangerColor;
    case 'chatsDislikes':
      return Number(value) < 5 ? COLORS.greenText : COLORS.btnDangerColor;
    case 'firstPlusThirty':
      return Number(value < 20) ? COLORS.greenText : COLORS.btnDangerColor;
    default:
      return COLORS.grayText;
  }
};

const getDashboardData = (dataEquipDash) => {
  return [
    {
      title: 'Quantidade de Chats Atendidos',
      cardType: 'chatsAnswered',
      data: dataEquipDash?.qtd_chats_total_clerk || [],
      totalItem: dataEquipDash?.qtd_chats_total_team || 0,
      type: 'CHATS_ATTENDED'
    },
    {
      title: 'Quantidade de Chats Não Atendidos',
      cardType: 'chatsNotAnswered',
      data: dataEquipDash?.qtd_chats_lost_clerk || [],
      totalItem: dataEquipDash?.qtd_lost_chats_team || 0,
      goalPercent: dataEquipDash?.percent_lost_chats,
      tooltipText:
        '% sobre o número total de chats. A meta é sempre estar abaixo de 8%.',
      goalColor: getGoalColor(
        'chatsNotAnswered',
        dataEquipDash?.percent_lost_chats
      ),
      goalText: '< 8%',
      type: 'CHATS_NOT_ATTENDED'
    },
    {
      title: 'Quantidade 1° resposta <= 30 segundos',
      cardType: 'firstMinusThirty',
      data: dataEquipDash?.qtd_tma_clerk_ok || [],
      totalItem: dataEquipDash?.qtd_tma_team_ok || 0,
      goalPercent: dataEquipDash?.percent_tma_ok,
      tooltipText:
        '% sobre o número total de chats atendidos. A meta é sempre estar acima de 80%.',
      goalColor: getGoalColor(
        'firstMinusThirty',
        dataEquipDash?.percent_tma_ok
      ),
      goalText: '>= 80%',
      type: 'FIRST_ANSWER_LESS_THAN_THIRTY_SECONDS'
    },
    {
      title: 'Quantidade de Likes',
      cardType: 'chatsLikes',
      data: dataEquipDash?.qtd_likes_per_clerk || [],
      totalItem: dataEquipDash?.qtd_likes_total_team || 0,
      goalPercent: dataEquipDash?.percent_likes,
      tooltipText:
        '% sobre o número total de chats atendidos. A meta é sempre estar acima de 30%',
      goalColor: getGoalColor('chatsLikes', dataEquipDash?.percent_likes),
      goalText: '>= 30%',
      type: 'LIKES_QUANTITY'
    },

    {
      title: 'Quantidade de Dislikes',
      cardType: 'chatsDislikes',
      data: dataEquipDash?.qtd_deslikes_per_clerk || [],
      totalItem: dataEquipDash?.qtd_deslikes_total_team || 0,
      goalPercent: dataEquipDash?.percent_deslikes,
      tooltipText:
        '% sobre o número total de chats atendidos. A meta é sempre estar abaixo de 5%.',
      goalColor: getGoalColor('chatsDislikes', dataEquipDash?.percent_deslikes),
      goalText: '< 5%',
      type: 'DISLIKES_QUANTITY'
    },

    {
      title: 'Quantidade 1° resposta > 30 segundos',
      cardType: 'firstPlusThirty',
      data: dataEquipDash?.qtd_tma_clerk_nok || [],
      totalItem: dataEquipDash?.qtd_tma_team_nok || 0,
      goalPercent: dataEquipDash?.percent_tma_nok,
      tooltipText:
        '% sobre o número total de chats atendidos. A meta é sempre estar abaixo de 20%.',
      goalColor: getGoalColor(
        'firstPlusThirty',
        dataEquipDash?.percent_tma_nok
      ),
      goalText: '< 20%',
      type: 'FIRST_ANSWER_MORE_THAN_THIRTY_SECONDS'
    }
  ];
};

export {
  defaultHoursNumber,
  defaultFilter,
  filterNameInStorage,
  presaleOnlineTabName,
  getDashboardData
};
