import { Card, Grid, Tooltip, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import React from 'react';
import { CustomHintText } from '../../../styles';
import { PercentInfo } from './styles';

const HintComponent = () => {
  return (
    <>
      <Typography style={{ fontWeight: 'bold' }} component="span">
        Vendas:{' '}
      </Typography>
      <Typography component="span">
        &quot;Todas as vendas do seu período, concluídas ou não&quot;.{' '}
      </Typography>
      <Typography
        style={{ marginTop: '8px', fontWeight: 'bold' }}
        component="span"
      >
        Com o Mercado Envios:{' '}
      </Typography>
      <Typography component="span">
        &quot;São as vendas, concluídas ou não, que você vendeu com qualquer
        forma de entrega do Mercado Envio&quot;.{' '}
      </Typography>
      <Typography
        style={{ marginTop: '8px', fontWeight: 'bold' }}
        component="span"
      >
        Concluídas:{' '}
      </Typography>
      <Typography component="span">
        &quot;São as que já estão com o comprador. Em um carrinho, cada produto
        diferente conta como uma venda&quot;.{' '}
      </Typography>
    </>
  );
};

const SalesInfoCard = ({ period, score_MERCADO_LIVRE }) => {
  const salesInfo = [
    { label: 'Vendas', value: score_MERCADO_LIVRE?.quantity_sales || 0 },
    { label: 'Concluídas', value: score_MERCADO_LIVRE?.completed_sales || 0 }
  ];
  const hint = <HintComponent />;

  return (
    <Card
      style={{
        width: '100%',
        marginBottom: '10px',
        borderRadius: '5px',
        marginTop: '10px',
        padding: '16px 24px',
        minHeight: '160px'
      }}
    >
      <Grid container spacing={2} style={{ minHeight: '140px' }}>
        <Grid
          item
          xs={8}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            justifyContent: 'center'
          }}
        >
          <Typography
            style={{
              color: '#5A5A5A',
              size: '14px',
              lineHeight: '16px',
              fontWeight: '500',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <span>Suas vendas nos ultimos 60 dias</span>
            {hint && (
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                  popperOptions: {
                    positionFixed: true,
                    modifiers: {
                      preventOverflow: {
                        enabled: true,
                        boundariesElement: 'window'
                      }
                    }
                  }
                }}
                placement="top"
                arrow
                title={<CustomHintText>{hint}</CustomHintText>}
              >
                <InfoOutlined fontSize="medium" color="primary" />
              </Tooltip>
            )}
          </Typography>

          {period}
        </Grid>

        {salesInfo.map(item => {
          return (
            <Grid
              item
              key={Math.random()}
              xs={2}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <PercentInfo color="#666666">
                {item?.value?.toLocaleString('pt-BR')}
              </PercentInfo>
              <Typography
                style={{
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '16px',
                  color: '#999999'
                }}
              >
                {item.label}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
};

export default SalesInfoCard;
