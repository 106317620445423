import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import userService from '../../services/user.service';
import { createDefaultFilter, ticketsTabName } from './TicketsDashboard/config';

import {
  defaultFilter as MessageDefaultFilter,
  filterNameInStorage as MessageFilterNameInStorage,
  messagesTabName
} from './MessagesDashboard/config';

import {
  defaultFilter as ChatDefaultFilter,
  filterNameInStorage as ChatFilterNameInStorage,
  chatsTabName
} from './ChatsDashboard/config';

import {
  reportsTabName,
  defaultFilter as ReportDefaultFilter,
  filterNameInStorage as ReportFilterNameInStorage
} from './ReportsDashboard/config';

import {
  presaleOnlineTabName,
  defaultFilter as PresaleOnlineDefaultFilter,
  filterNameInStorage as PresaleOnlineFilterNameInStorage
} from './PreSaleOnlineDashboard/config';

import {
  presaleOnlineB2BTabName,
  defaultFilter as PresaleOnlineB2BDefaultFilter,
  filterNameInStorage as PresaleOnlineB2BFilterNameInStorage
} from './PreSaleOnlineB2BDashboard/config';

import {
  presaleOfflineTabName,
  defaultFilter as PresaleOfflineDefaultFilter,
  filterNameInStorage as PresaleOfflineFilterNameInStorage
} from './PreSaleOfflineDashboard/config';

import {
  marketplaceScoreTabName,
  defaultFilter as MarketplaceScoreDefaultFilter,
  filterNameInStorage as MarketplaceScoreNameInStorage
} from './MarketplaceScoreDashboard/config';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    overflow: 'hidden'
  }
}));

const getDefaultTab = () => {
  const roles = userService.getRolesFromToken();

  if (
    roles.includes('dashboard_analyst_pos_sale') ||
    roles.includes('dashboard_specialist_pos_sale')
  ) {
    return 'dashboardTab';
  }

  if (
    roles.includes('dashboard_analyst_pos_sale') ||
    roles.includes('dashboard_specialist_pos_sale')
  ) {
    return 'chatDashboardTab';
  }

  if (roles.includes('dashboard_show_report')) {
    return 'reportsDashboardTab';
  }

  if (roles.includes('dashboard_score_marketplace')) {
    return 'marketplaceScoreTab';
  }

  if (
    roles.includes('specialist_pre_sale_online') ||
    roles.includes('analyst_pre_sale_online')
  ) {
    return 'presaleOnlineTab';
  }

  if (
    roles.includes('specialist_pre_sale_offline') ||
    roles.includes('analyst_pre_sale_offline')
  ) {
    return 'presaleOfflineTab';
  }

  return '';
};

const IndexHook = () => {
  const classes = useStyles();

  const getAdmin = tabValue => {
    let tabPermission = '';
    switch (tabValue) {
      case 'dashboardTab':
        tabPermission = 'dashboard_specialist_pos_sale';
        break;
      case 'messageDashboardTab':
        tabPermission = 'dashboard_specialist_pos_sale';
        break;
      case 'presaleOfflineTab':
        tabPermission = 'specialist_pre_sale_offline';
        break;
      case 'presaleOnlineTab':
        tabPermission = 'specialist_pre_sale_online';
        break;
      case 'presaleOnlineB2BTab':
        tabPermission = 'specialist_pre_sale_online';
        break;
      default:
        tabPermission = '';
        break;
    }

    const permissions = userService
      .getRolesFromToken()
      .filter(item => item === tabPermission);

    sessionStorage.setItem(
      'dashAdmin',
      permissions.length > 0 ? 'true' : 'false'
    );

    return { isAdmin: permissions.length > 0, permissions };
  };

  const userName = userService.getUsername();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [tabValue, setTabValue] = useState(getDefaultTab());
  const [permissions, setPermissions] = useState([]);
  const [isAdmin, setIsAdmin] = useState(true);

  const [ticketsFilter, setTicketsFilter] = useState(() => {
    return createDefaultFilter();
  });
  const messageStorageFilter =
    JSON.parse(sessionStorage.getItem(MessageFilterNameInStorage)) ||
    MessageDefaultFilter;
  const [messagesFilter, setMessagesFilter] = useState(messageStorageFilter);

  const chatStorageFilter =
    JSON.parse(sessionStorage.getItem(ChatFilterNameInStorage)) ||
    ChatDefaultFilter;
  const [chatsFilter, setChatsFilter] = useState(chatStorageFilter);

  const reportStorageFilter =
    JSON.parse(sessionStorage.getItem(ReportFilterNameInStorage)) ||
    ReportDefaultFilter;
  const [reportsFilter, setReportsFilter] = useState(reportStorageFilter);

  const preSaleOnlineStorageFilter =
    JSON.parse(sessionStorage.getItem(PresaleOnlineFilterNameInStorage)) ||
    PresaleOnlineDefaultFilter;
  const [presaleOnlineFilter, setPresaleOnlineFilter] = useState(
    preSaleOnlineStorageFilter
  );

  const preSaleOnlineB2BStorageFilter =
    JSON.parse(sessionStorage.getItem(PresaleOnlineB2BFilterNameInStorage)) ||
    PresaleOnlineB2BDefaultFilter;
  const [presaleOnlineB2BFilter, setPresaleOnlineB2BFilter] = useState(
    preSaleOnlineB2BStorageFilter
  );

  const preSaleOfflineStorageFilter =
    JSON.parse(sessionStorage.getItem(PresaleOfflineFilterNameInStorage)) ||
    PresaleOfflineDefaultFilter;
  const [presaleOfflineFilter, setPresaleOfflineFilter] = useState(
    preSaleOfflineStorageFilter
  );

  const marketplaceScoreStorageFilter =
    JSON.parse(sessionStorage.getItem(MarketplaceScoreNameInStorage)) ||
    MarketplaceScoreDefaultFilter;
  const [marketplaceScoreFilter, setMarketplaceScoreFilter] = useState(
    marketplaceScoreStorageFilter
  );

  const tabsList = [
    {
      label: t('i18n.dashboardlayout.TICKETS_AFTER_SALE'),
      sublabel: t('i18n.dashboardlayout.TICKETS'),
      value: tabValue,
      index: ticketsTabName,
      show:
        userService.hasRole('dashboard_specialist_pos_sale') ||
        userService.hasRole('dashboard_analyst_pos_sale')
    },
    {
      label: t('i18n.dashboardlayout.TICKETS_AFTER_SALE'),
      sublabel: t('i18n.ticketslistview.TAB_MESSAGES'),
      value: tabValue,
      index: messagesTabName,
      show:
        userService.hasRole('dashboard_specialist_pos_sale') ||
        userService.hasRole('dashboard_analyst_pos_sale')
    },
    {
      label: t('i18n.dashboard.mktplacereport.reports'),
      value: tabValue,
      index: reportsTabName,
      show: userService.hasRole('dashboard_show_report')
    },
    {
      label: t('i18n.dashboard.tabs.powerbi'),
      value: tabValue,
      index: 'pbiDashboardTab',
      show: false
    },
    {
      label: t('i18n.dashboard.tabs.marketplace_score'),
      value: tabValue,
      index: marketplaceScoreTabName,
      show: userService.hasRole('dashboard_score_marketplace')
    },
    {
      label: t('i18n.dashboard.tabs.offline_pre_sale'),
      value: tabValue,
      index: presaleOfflineTabName,
      show:
        userService.hasRole('specialist_pre_sale_offline') ||
        userService.hasRole('analyst_pre_sale_offline')
    },
    {
      label: `${t('i18n.dashboard.tabs.online_pre_sale')} B2C`,
      value: tabValue,
      index: presaleOnlineTabName,
      show:
        userService.hasRole('specialist_pre_sale_online') ||
        userService.hasRole('analyst_pre_sale_online')
    },
    {
      label: `${t('i18n.dashboard.tabs.online_pre_sale')} B2B`,
      value: tabValue,
      index: presaleOnlineB2BTabName,
      show:
        userService.hasRole('specialist_pre_sale_online') ||
        userService.hasRole('analyst_pre_sale_online')
    }
  ];

  const getCurrentDashboardState = (tab, arrayState) => {
    if (tab === ticketsTabName) {
      return arrayState[0];
    }
    if (tab === messagesTabName) {
      return arrayState[1];
    }
    if (tab === chatsTabName) {
      return arrayState[2];
    }
    if (tab === reportsTabName) {
      return arrayState[3];
    }
    if (tab === presaleOnlineTabName) {
      return arrayState[4];
    }
    if (tab === presaleOnlineB2BTabName) {
      return arrayState[5];
    }
    if (tab === presaleOfflineTabName) {
      return arrayState[6];
    }
    if (tab === marketplaceScoreTabName) {
      return arrayState[7];
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    const adminInfo = getAdmin(newValue);
    setIsAdmin(adminInfo.isAdmin);
    setPermissions(adminInfo.permissions);
    const currentDashboardFilterState = getCurrentDashboardState(newValue, [
      ticketsFilter,
      messagesFilter,
      chatsFilter,
      reportsFilter,
      presaleOnlineFilter,
      presaleOnlineB2BFilter,
      presaleOfflineFilter,
      marketplaceScoreFilter
    ]);
    if (!adminInfo.isAdmin) {
      currentDashboardFilterState.attendant = userService.getUsername();
    }
  };

  useEffect(() => {
    if (!sessionStorage.getItem('dashAdmin')) {
      const startDefaultTab = getDefaultTab();
      const adminInfo = getAdmin(startDefaultTab);
      setIsAdmin(adminInfo.isAdmin);
      setPermissions(adminInfo.permissions);
      const currentDashboardFilterState = getCurrentDashboardState(
        startDefaultTab,
        [
          ticketsFilter,
          messagesFilter,
          chatsFilter,
          reportsFilter,
          presaleOnlineFilter,
          presaleOfflineFilter,
          marketplaceScoreFilter
        ]
      );
      if (!adminInfo.isAdmin) {
        currentDashboardFilterState.attendant = userService.getUsername();
      }
    }
  }, []);

  return {
    classes,
    permissions,
    isAdmin,
    userName,
    t,
    enqueueSnackbar,
    tabValue,
    setTabValue,
    tabsList,
    handleTabChange,
    ticketsFilter,
    setTicketsFilter,
    messagesFilter,
    setMessagesFilter,
    chatsFilter,
    setChatsFilter,
    reportsFilter,
    setReportsFilter,
    presaleOnlineFilter,
    setPresaleOnlineFilter,
    presaleOnlineB2BFilter,
    setPresaleOnlineB2BFilter,
    presaleOfflineFilter,
    setPresaleOfflineFilter,
    marketplaceScoreFilter,
    setMarketplaceScoreFilter
  };
};

export default IndexHook;
