/*eslint-disable */
import React, { useState } from 'react';
import {
  Box,
  Container,
  Divider,
  makeStyles,
  CircularProgress,
  Tab,
  Tabs,
  Paper,
  CardContent,
  Typography
} from '@material-ui/core';
import { useSelector } from 'react-redux';

import { StyledSpinner } from '../../../styles/components/SpinnerDefault';
import { CustomTabPanel } from '../components/custom.components';
// import DashboardContent from './dashboardContent';
import ChatDashboardContent from './chatDashboardContent';
import Header from './header.component';
import indexHook from '../indexHook';
import { chatsTabName } from './config';

const TicketsDashboard = ({ tabValue, dashboardFilter, setDashboardFilter }) => {
  const loading = useSelector(state => state.chatsDashboardLoadingReducer);

  const dataEquipDash = useSelector(
    state => state.chatsDashboardEquipDataReducer
  );

  const dataIndividualDash = useSelector(
    state => state.chatsDashboardIndividualDataReducer
  );

  return (
    <CustomTabPanel value={tabValue} index={chatsTabName}>
      <Header dashboardFilter={dashboardFilter} setDashboardFilter={setDashboardFilter} />
      <br />
      <Divider />
      <br />
      {!loading ? (
        <ChatDashboardContent
          dataEquipDashChat={dataEquipDash}
          dataIndividualDashChat={dataIndividualDash}
          isAdmin={dashboardFilter.attendant == ""}
          dashboardFilter={dashboardFilter}
        // actualProtocolPayload={actualProtocolPayload}
        />
      ) : (
        <>
          <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
            <CircularProgress />
          </StyledSpinner>
        </>
      )}
    </CustomTabPanel>
  );
};

export default TicketsDashboard;
