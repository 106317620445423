import React from 'react';
import ModalLinkComplaint from '../../../TicketComponents/ModalLinkComplaint';

export const ModalLinkComplaintIframe = ({
  openLinkModal,
  setOpenLinkModal,
  raTicketsList,
  ticketData,
  handleShowRADetails,
}) => {
  return (
    <ModalLinkComplaint
      openLinkModal={openLinkModal}
      setOpenLinkModal={setOpenLinkModal}
      raTickets={raTicketsList}
      raTicketsFromProtocol={ticketData?.customerComplaint}
      onlyLinkedRaTickets={raTicketsList[0]?.linked}
      handleShowRADetails={handleShowRADetails}
    />
  );
};
