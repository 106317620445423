import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';

export default function StyledSelect({
  value,
  selectedValues,
  setSelectedValues,
  options,
  setOptions,
  level = null,
  id = null
}) {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.inputSelect}>
      <Select
        value={value || ''}
        id={level || id}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              maxHeight: 30 * 4.5,
              width: '20ch'
            }
          }
        }}
        onChange={e => {
          if (level) {
            if (level === 'level1') {
              setOptions({ ...options, level2: null, level3: null });
            }

            if (level === 'level2') {
              setOptions({ ...options, level3: null });
            }
            setSelectedValues({
              ...selectedValues,
              [level]: e.target.value
            });
          } else {
            setSelectedValues(e);
          }
        }}
      >
        {level
          ? options[level]?.map(option => (
              <MenuItem
                value={option.value}
                style={{ fontSize: '12px' }}
                key={Math.random()}
              >
                {option.label}
              </MenuItem>
            ))
          : options?.map(option => (
              <MenuItem
                value={option.value}
                style={{ fontSize: '12px' }}
                key={Math.random()}
              >
                {option.label}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
}
