import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import moment from 'moment';
import React, { useState } from 'react';
import API from '../../../../../services/api';
import { getScheduleStatus } from './functions';

const ScheduleInfo = ({ schedule, closePopover, chat, setEditing }) => {
  const dataHora = moment(new Date(schedule.scheduledDateTime)).format(
    'DD/MM/YYYY HH:mm'
  );
  const [loading, setLoading] = useState(false);

  const removeSchedule = async () => {
    setLoading(true);
    await API.delete(
      `/auth/ms-ticket/v1/tickets/${chat.id}/delete-protocol-scheduling`
    );
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <IconButton size="small" onClick={closePopover}>
          <Close style={{ fontSize: '15px' }} />
        </IconButton>
      </Box>
      <Box minWidth="280px">
        <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
          Dados do Agendamento
        </Typography>
        <Typography>
          <b>Data/Hora:</b> {dataHora}
        </Typography>
        <Typography>
          <b>Status:</b> {getScheduleStatus(schedule.status)}
        </Typography>
        <Typography>
          <b>Descrição:</b> {schedule.description}
        </Typography>
        <Box display="flex" justifyContent="space-between" marginTop="10px">
          <Button
            variant="contained"
            color="primary"
            onClick={() => setEditing(true)}
          >
            Editar
          </Button>
          {loading ? (
            <Button
              variant="contained"
              color="secondary"
              disabled={loading}
              onClick={removeSchedule}
              endIcon={<CircularProgress size={14} />}
            >
              Remover
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              disabled={loading}
              onClick={removeSchedule}
            >
              Remover
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ScheduleInfo;
