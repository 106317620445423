import { Card, CardContent, Grid } from '@material-ui/core';
import React from 'react';
import { CardTitle } from '../../styles';
import { CustomTypograph } from '../custom.components';
import {
  fillDonutGraph,
  getChartColors,
  getTitleData,
  getTitleSubContent
} from './functions/commonFunctions';
import MainScoreCard from './components/MainScoreCard';
import { getMagaluScoreHint, getTitle, infoCards } from './Magalu/cardData';
import ScoreInfoCardMagalu from './Magalu/ScoreInfoCardMagalu';

const getGrowPercent = () => {
  // if (item === 'delaySending') {
  //   return 35.9;
  // }
  return false;
};

const getMainPercent = (item, score_MAGALU) => {
  switch (item) {
    case 'cancelling':
      return score_MAGALU?.percent_of_cancellation;
    case 'delaySending':
      return score_MAGALU?.percent_orders_delivery_on_time;
    case 'cr':
      return score_MAGALU?.percent_contact;
    case 'nps':
      return score_MAGALU?.percent_of_nps;
    default:
      return 0;
  }
};

const getNumberComplement = item => {
  if (item === 'cr') {
    return 'gerados';
  }

  if (item === 'nps') {
    return 'avaliados';
  }

  return 'pedidos';
};

const getCountNumber = (item, score_MAGALU) => {
  switch (item) {
    case 'cancelling':
      return score_MAGALU?.total_cancelled_orders;
    case 'delaySending':
      return score_MAGALU?.delivery_on_time_total;
    case 'cr':
      return score_MAGALU?.total_contact;
    case 'nps':
      return score_MAGALU?.total_orders_nps;
    default:
      return 0;
  }
};

const getGoal = item => {
  switch (item) {
    case 'cancelling':
      return 1;
    case 'delaySending':
      return 97;
    case 'cr':
      return 0.09;
    case 'nps':
      return 70;
    default:
      return 0;
  }
};

const getGoalText = item => {
  switch (item) {
    case 'cancelling':
      return 'Resultado desejado: Abaixo de 1,0%';
    case 'delaySending':
      return 'Resultado desejado: Acima de 97,0%';
    case 'cr':
      return 'Resultado desejado: Abaixo de 0,09';
    case 'nps':
      return 'Resultado desejado: Acima de 70';
    default:
      return 0;
  }
};

const MagaluDashboard = ({ score, possibleScore, score_MAGALU }) => {
  const chartData = fillDonutGraph(score, possibleScore);
  const colors = getChartColors(score);
  const totalValue = score;
  const titleData = getTitleData(score, '60px');
  const titleSubContent = getTitleSubContent(possibleScore);

  const hintText = `Representa a experiência de compra oferecida aos seus clientes 
  conforme o desempenho da sua loja. A nota é calculada no período dos últimos 30 dias`;

  return (
    <>
      <CustomTypograph variant="h3">Magalu</CustomTypograph>
      <Card style={{ backgroundColor: 'white', marginTop: '10px' }}>
        <CardTitle>
          <CustomTypograph variant="h5">Nota</CustomTypograph>
        </CardTitle>
        <CardContent style={{ marginTop: '-20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <MainScoreCard
                {...{
                  chartData,
                  colors,
                  totalValue,
                  titleData,
                  titleSubContent,
                  hintText
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <Grid container spacing={2}>
                {infoCards.map(item => (
                  <Grid item xs={6} key={Math.random()}>
                    <ScoreInfoCardMagalu
                      title={getTitle(item)}
                      type={item}
                      descriptionHint={getMagaluScoreHint(item)}
                      mainPercent={getMainPercent(item, score_MAGALU)}
                      growPercent={getGrowPercent(item)}
                      negative={
                        getGrowPercent(item) && getGrowPercent(item) < 0
                      }
                      countNumber={getCountNumber(item, score_MAGALU)}
                      numberComplement={getNumberComplement(item)}
                      goal={getGoal(item)}
                      goalText={getGoalText(item)}
                    />
                  </Grid>
                ))}
              </Grid>
              {/** TODO data */}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default MagaluDashboard;
