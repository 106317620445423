import { Card, Grid } from '@material-ui/core';
import React from 'react';
import { Content, Label } from './styles';

export default function CustomerDetails({ details }) {
  const cliente = details?.order?.cliente;
  return (
    <Card
      style={{
        width: '100%',
        borderRadius: '10px',
        padding: '15px 10px',
        marginLeft: '20px'
      }}
    >
      <Grid container style={{ fontFamily: 'Roboto' }} spacing={3}>
        <Grid item xs={6} style={{ marginBottom: '-10px' }}>
          <Label>Nome do Cliente</Label>
          <Content>{cliente?.nome}</Content>
        </Grid>
        <Grid item xs={6} style={{ marginBottom: '-10px' }}>
          <Label>CPF</Label>
          <Content>{cliente?.cep}</Content>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '-10px' }}>
          <Label>Endereço</Label>
          <Content>{`${cliente?.endereco || '--'} ${cliente?.numero ||
            '--'}`}</Content>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '-10px' }}>
          <Label>Complemento</Label>
          <Content>{cliente?.complemento || '--'}</Content>
        </Grid>
        <Grid item xs={6} style={{ marginBottom: '-10px' }}>
          <Label>Bairro</Label>
          <Content>{cliente?.bairro}</Content>
        </Grid>
        <Grid item xs={6} style={{ marginBottom: '-10px' }}>
          <Label>Lote</Label>
          <Content>-</Content>
        </Grid>
        <Grid item xs={6} style={{ marginBottom: '-10px' }}>
          <Label>Cidade</Label>
          <Content>{cliente?.cidade}</Content>
        </Grid>
        <Grid item xs={6} style={{ marginBottom: '-10px' }}>
          <Label>Estado</Label>
          <Content>{cliente?.uf}</Content>
        </Grid>
      </Grid>
    </Card>
  );
}
