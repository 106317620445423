import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  makeStyles,
  TextField,
  Typography,
  Grid
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import config from '../../config';
import LanguageButton from '../../layouts/DashboardLayout/Tools/LanguageButton';
import formHelper from '../../utils/formHelper';
import Page from '../../components/Page';
import API from '../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(35)
  },
  gridLeft: {
    textAlign: 'right'
  }
}));

const ForgotPasswordView = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormdata] = useState(
    formHelper.simpleData2FormData({
      name: {
        required: true
      },
      email: {
        required: true
      }
    })
  );

  const formIsOK = () => {
    let noFormError = true;
    if (formData.email.value === '' || formData.name.value === '') {
      enqueueSnackbar(t('i18n.validation.BLANK_FIELDS'), {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      noFormError = false;
    }
    if (formHelper.existsEmptySpaces(formData.name.value)) {
      enqueueSnackbar(t('i18n.validation.INVALIDUSERNAME'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      noFormError = false;
    }
    if (formData.email.value) {
      if (!formHelper.validateEmail(formData.email.value)) {
        enqueueSnackbar(t('i18n.validation.INVALIDEMAIL'), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        });
        noFormError = false;
      }
    }
    return noFormError;
  };

  const sendRetrieveInformation = async () => {
    if (!formIsOK()) {
      return;
    }
    const postData = formHelper.formData2PostData(formData);
    API.post(
      `${config.cognito.authUrl}/backoffice-force-password-change`,
      postData
    )
      .then(() => {
        history.push('/');
      })
      .catch(error => {
        if (
          error.response?.data?.message.key.includes(
            'i18n.user.User does not exist'
          ) ||
          error.response?.data?.message.key.includes(
            'i18n.user.EMAIL_DOES_NOT_MATCH'
          )
        ) {
          enqueueSnackbar(t('i18n.loginview.RETRIEVE_PAYLOAD_ERROR'), {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          });
        }
        if (
          error.response?.data?.message.key.includes(
            'i18n.user.USER_DOES_NOT_HAVE_EMAIL'
          )
        ) {
          enqueueSnackbar(t('i18n.loginview.RETRIEVE_LACK_OF_EMAIL'), {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          });
        }
        if (
          error.response?.data?.message.key.includes(
            'i18n.user.COULD_NOT_SEND_EMAIL'
          )
        ) {
          enqueueSnackbar(t('i18n.loginview.RETRIEVE_ERROR_EMAIL_SERVER'), {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          });
        }
      });
  };

  return (
    <Page className={classes.root} title="Recuperar senha">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box mb={3}>
            <Card>
              <CardContent>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" flexDirection="row">
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={10}
                    >
                      <Grid item md={8}>
                        <Typography color="textPrimary" variant="h2">
                          {t('i18n.loginview.RETRIEVE_PASSWORD')}
                        </Typography>
                      </Grid>
                      <Grid item md={4} className={classes.gridLeft}>
                        <LanguageButton />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box paddingTop={2}>
                    <Typography variant="subtitle1">
                      {t('i18n.loginview.RETRIEVE_INFORMATION')}
                      :
                    </Typography>
                  </Box>
                  <Box paddingTop={2}>
                    <TextField
                      label={t('i18n.loginview.LOGIN')}
                      variant="outlined"
                      fullWidth
                      onChange={htmlElemtEvent => {
                        formHelper.setFormvalue(
                          formData,
                          setFormdata,
                          'name',
                          htmlElemtEvent.target.value
                        );
                      }}
                      error={formData.name.error}
                    />
                  </Box>
                  <Box paddingTop={2}>
                    <TextField
                      label={t('i18n.loginview.EMAIL')}
                      type="email"
                      variant="outlined"
                      fullWidth
                      onChange={htmlElemtEvent => {
                        formHelper.setFormvalue(
                          formData,
                          setFormdata,
                          'email',
                          htmlElemtEvent.target.value
                        );
                      }}
                      error={formData.email.error}
                    />
                  </Box>
                  <Grid container justifyContent="space-between">
                    <Grid item lg>
                      <Box paddingTop={2}>
                        <Button
                          variant="contained"
                          color="default"
                          component={Link}
                          to="/"
                        >
                          {t('i18n.simplewords.CANCEL')}
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item lg className={classes.gridLeft}>
                      <Box paddingTop={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={
                            !formData?.name.value || !formData?.email.value
                          }
                          onClick={sendRetrieveInformation}
                        >
                          {t('i18n.loginview.RETRIEVE_PASSWORD')}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default ForgotPasswordView;
