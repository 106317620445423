/*eslint-disable */

import { Card, CardContent, Divider, Typography } from '@material-ui/core';
import {
  Chart,
  Point,
  // Area,
  Annotation,
  Axis,
  Coordinate,
  registerShape
  // registerAnimation
} from 'bizcharts';
import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CustomTypograph, InConstruction } from '../styles';

registerShape('point', 'pointer', {
  draw(cfg, container) {
    const group = container.addGroup();
    const center = this.parsePoint({ x: 0, y: 0 });
    const start = this.parsePoint({ x: 0, y: 0 });

    const line = group.addShape('line', {
      attrs: {
        x1: center.x,
        y1: center.y,
        x2: cfg.x,
        y2: cfg.y,
        stroke: cfg.color,
        lineWidth: 5,
        lineCap: 'round'
      }
    });
    group.addShape('circle', {
      attrs: {
        x: center.x,
        y: center.y,
        r: 9.75,
        stroke: cfg.color,
        lineWidth: 4.5,
        fill: '#fff'
      }
    });

    const preAngle = this.preAngle || 0;

    const angle1 = Math.atan((start.y - center.y) / (start.x - center.x));
    const angle = (Math.PI - 2 * angle1) * cfg.points[0].x;

    if (group.cfg.animable) {
      group.animate(ratio => {
        group.resetMatrix();
        group.rotateAtPoint(
          center.x,
          center.y,
          preAngle + (angle - preAngle) * ratio
        );
      }, 300);
    } else {
      group.rotateAtPoint(center.x, center.y, angle);
    }
    this.preAngle = angle;

    return group;
  }
});

const scale = {
  value: {
    min: 0,
    max: 1,
    tickInterval: 0.1,
    formatter: v => v * 100
  }
};

const GaugeChart = ({ gaugeData, height }) => {

  const [data] = useState([{ value: gaugeData }]);

  return (
    <Chart height={height} data={data} scale={scale} autoFit>
      <Coordinate
        type="polar"
        radius={0.75}
        startAngle={(-12 / 10) * Math.PI}
        endAngle={(2 / 10) * Math.PI}
      />
      <Axis name="1" />
      <Axis
        name="value"
        line={null}
        label={{
          offset: -36,
          style: {
            fontSize: 12,
            textAlign: 'center',
            textBaseline: 'middle'
          }
        }}
        subTickLine={{
          count: 4,
          length: -15
        }}
        tickLine={{
          length: -24
        }}
        grid={null}
      />
      <Point position="value*1" color="#1890FF" shape="pointer" />
      <Annotation.Arc
        start={[0, 1]}
        end={[1, 1]}
        style={{
          stroke: '#CBCBCB',
          lineWidth: 18,
          lineDash: null
        }}
      />
      <Annotation.Arc
        start={[0, 1]}
        end={[data[0].value, 1]}
        style={{
          stroke: '#1890FF',
          lineWidth: 18,
          lineDash: null
        }}
      />
    </Chart>
  );
};

const DonutChartDashboardWrapper = ({ data, title, firstLabel, height, showInConstruction }) => {
  return (
    <div style={{ position: 'relative' }}>
      <Card style={{ width: '100%', marginBottom: '20px' }}>
        <CardContent>
          <CustomTypograph variant="h5">{title}</CustomTypograph>
        </CardContent>
        <Divider />
        <CardContent>
          <Typography>{firstLabel}</Typography>
          <GaugeChart gaugeData={data} height={height} />
          {data && (
            <CustomTypograph align="center" variant="h2">{parseFloat(data * 100).toFixed(2)} %</CustomTypograph>
          )}
        </CardContent>
      </Card>
      {showInConstruction && <InConstruction />}
    </div>
  );
};

export default DonutChartDashboardWrapper;
