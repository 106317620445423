import React from 'react';
import { Button } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  height: calc(100vh - 64px);

  p {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    margin: 16px 0;
  }
`;

/**
 * This component aims to give the user an option to fetch
 * again the data from API.
 * @param {fetch} function The function to fetch again data
 */
const FetchContentError = ({ fetch }) => {
  const { t } = useTranslation();
  return (
    <ErrorContainer>
      <WarningRoundedIcon color="primary" style={{ fontSize: 56 }} />
      <p>{t('i18n.simplephrases.ERROR')}</p>
      <Button variant="contained" color="primary" onClick={() => fetch()}>
        {t('i18n.simplephrases.RELOAD')}
      </Button>
    </ErrorContainer>
  );
};

export default FetchContentError;
