function formatCPF(value) {
  const result = value.replace(/[^\d]/g, '');
  return result.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const convertFileToBase64String = async element => {
  const result = await toBase64(element);
  const resultedFileBase64 = result.split('base64,');
  return resultedFileBase64[1];
};

export const numberToBRL = (number = 0) =>
  number.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  });

export const floatToDecimal = (number = 0) =>
  number.toLocaleString('pt-br', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

export default formatCPF;
