import React from 'react';
import styled from 'styled-components';
import { Box, Link, Typography } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { emojiSentiments } from '../../../../../utils/emojiSentiments';
import { COLORS } from '../../../../../styles/settings/colors.config';
import tableHelper from '../../../../../utils/tableHelper';
import timezoneFormat from '../../../../../utils/timezoneFormat';
import formHelper from '../../../../../utils/formHelper';

import CustomFooter from '../../../../../styles/components/CustomFooter';

const TimeSLACount = styled('p')`
  font-weight: bold;
`;

const ClientAlert = styled('p')`
  color: ${props => (props.redAlert ? `${COLORS.btnDangerColor}` : '#000')};
  font-weight: ${props => (props.redAlert ? 'bold' : 'inherit')};
`;

export const ColumnsConfig = (t, data, defaultSortChanged) => {
  return [
    {
      name: 'id',
      label: t('i18n.ticketslistview.ID'),
      options: {
        setCellProps: (row, index) => ({
          style: tableHelper.setRowStyle(row, index, data)
        }),
        customBodyRender: value => (
          <strong key={value}>
            <Link
              style={{ color: '#000' }}
              href={`${value}`}
              rel="noopener"
              target="_blank"
            >
              {value}
            </Link>
          </strong>
        )
      }
    },
    {
      name: 'sentimentAnalysisScore',
      label: ' ',
      options: {
        setCellProps: () => ({
          style: { maxWidth: '10px' }
        }),
        customBodyRender: value =>
          value && (
            <span
              style={{ position: 'relative', bottom: '10px', right: '20px' }}
            >
              {emojiSentiments[value - 1].emoji}
            </span>
          )
      }
    },
    {
      name: 'creationDate',
      label: 'Data de abertura',
      options: {
        sort: true,
        customBodyRender: value => {
          return (
            <>
              <strong>{timezoneFormat(value)}</strong>
              <TimeSLACount days={tableHelper.timeDiffCalc(value).days}>
                {tableHelper.timeDiffCalc(value).difference}
              </TimeSLACount>
            </>
          );
        }
      }
    },
    {
      name: 'sla_hoursSinceCreation',
      label: '',
      options: {
        sort: true,
        customBodyRender: value => {
          const sla = tableHelper.formatMinutes(value, 'protocol');
          const slaAlertLimit = tableHelper.TOTAL_MINUTES_ONE_DAY;
          const slaWarningLimit = tableHelper.TOTAL_MINUTES_HALF_DAY;
          return (
            <span
              style={{
                color:
                  // eslint-disable-next-line no-nested-ternary
                  value >= slaAlertLimit
                    ? COLORS.btnDangerColor
                    : value >= slaWarningLimit
                    ? COLORS.WarningColorSLA
                    : COLORS.greenText,
                fontWeight: 'bold'
              }}
            >
              {sla}
            </span>
          );
        },
        customHeadLabelRender: () => {
          return (
            <Box display="flex" width={135}>
              <div className="hoursSinceCreationDefaultHeader">
                {t('i18n.ticketslistview.WAITING_TIME')}
                {!defaultSortChanged && (
                  <ArrowDownwardIcon className="font18" />
                )}
              </div>
            </Box>
          );
        }
      }
    },
    {
      name: 'ticketStatus',
      label: 'Status',
      options: {
        sort: true,
        customBodyRender: value => {
          return (
            <Box display="flex" width={135}>
              <Typography style={{ fontSize: '12px' }}>
                <strong
                  style={{
                    color: value === 'NEW' ? COLORS.greenText : '#263238'
                  }}
                >
                  {t(`i18n.ticketslistview.ticketStatus.${value}`)}
                </strong>
              </Typography>
            </Box>
          );
        }
      }
    },
    // {
    //   name: 'ticketType',
    //   label: t('i18n.ticketslistview.TICKET_TYPE'),
    //   options: {
    //     sort: true,
    //     customBodyRender: value => {
    //       return <strong>{t(`i18n.ticketslistview.${value}`)}</strong>;
    //     }
    //   }
    // },
    {
      name: 'subject',
      label: t('i18n.ticketslistview.SUBJECT'),
      options: {
        sort: true,
        customBodyRender: value => {
          return (
            <Box display="flex" width={230}>
              <Typography style={{ fontSize: '12px' }}>{value}</Typography>
            </Box>
          );
        }
      }
    },
    {
      name: 'marketPlace',
      label: 'Marketplace',
      options: {
        sort: true,
        customBodyRender: value => {
          return (
            <Box display="flex" width={135}>
              <Typography style={{ fontSize: '12px' }}>
                {t(`i18n.marketplaces.${value}`)}
              </Typography>
            </Box>
          );
        }
      }
    },
    {
      name: 'serviceAttendantLogin',
      label: t('i18n.ticketslistview.ATTENDANT'),
      options: {
        sort: true,
        customBodyRender: value => {
          return (
            <Box display="flex" width={135}>
              <Typography style={{ fontSize: '12px' }}>{value}</Typography>
            </Box>
          );
        }
      }
    },

    {
      name: 'reasonNew',
      label: 'Motivo',
      options: {
        sort: true,
        customBodyRender: value => {
          return (
            <Box display="flex" width={140}>
              <Typography style={{ fontSize: '12px' }}>
                {value?.name}
              </Typography>
            </Box>
          );
        }
      }
    },
    {
      name: 'reasonNew',
      label: 'Detalhe motivo',
      options: {
        sort: true,
        customBodyRender: value => {
          return (
            <Box display="flex" width={170}>
              <Typography style={{ fontSize: '12px' }}>
                {value?.desc}
              </Typography>
            </Box>
          );
        }
      }
    },
    {
      name: 'orderId',
      label: 'Nº pedido',
      options: {
        sort: true,
        customBodyRender: value => {
          return (
            <Box display="flex" width={135}>
              <Typography style={{ fontSize: '12px' }}>{value}</Typography>
            </Box>
          );
        }
      }
    },
    {
      name: 'customerName',
      label: t('i18n.ticketslistview.CUSTOMER'),
      options: {
        sort: true,
        customBodyRender: (value, rowData) => {
          const row = data[rowData.rowIndex];
          const { vipClient } = row;
          const mlAlert =
            row.marketplaceBundle.mercadoLivreAfterSaleClaimEntity;
          const magaluAlert = row.marketplaceBundle?.reason === 'Reclamação';
          const redAlert = !!(vipClient || mlAlert || magaluAlert);

          return (
            <ClientAlert redAlert={redAlert}>
              {vipClient && <> * </>}
              {value}
              {(mlAlert || magaluAlert) && <> ! </>}
            </ClientAlert>
          );
        }
      }
    },
    {
      name: 'customerCPF',
      label: t('i18n.ticketslistview.DOCUMENT'),
      options: {
        setCellProps: () => ({
          style: { minWidth: '11.6em' }
        }),
        customBodyRender: value => {
          return (
            <Box display="flex" width={130}>
              <Typography style={{ fontSize: '12px' }}>
                {formHelper.cpfCnpjFormat(value)}
              </Typography>
            </Box>
          );
        }
      }
    }
  ];
};

export const OptionsConfig = ({
  t,
  loading,
  data,
  currentPage,
  totalElements,
  size,
  sortTableData,
  callback,
  sortData,
  setSortData
}) => {
  return {
    page: currentPage,
    rowsPerPage: size,
    count: totalElements,
    rowsPerPageOptions: [20],

    serverSide: true,
    pagination: true,

    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRows: false,

    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        if (tableState.page !== currentPage) {
          callback(tableState.page);
        }
      }
      if (action === 'sort') {
        sortTableData(tableState, sortData, setSortData);
      }
    },

    onCellClick: (colData, cellMeta) => {
      if (cellMeta.colIndex === 0) {
        return;
      }
      const { id } = data[cellMeta.rowIndex];
      window.open(`${id}`, '_blank');
    },

    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          rowsPerPageOptions={[20]}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: !loading
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: column =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };
};
