import { Card, CardContent, Tooltip, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  RemoveFilterCardButton,
  cardStyle,
  FilterCardHeader,
  CustomTypographyDescribe,
  typographyStyle
} from '../../../../../../../styles/components/Tags';

export default function FilterChip({
  label,
  chipKey,
  value,
  removeFilterFromChip
}) {
  const { t } = useTranslation();

  return (
    <Card
      style={{
        borderRadius: '10px',
        height: 'fit-content',
        marginRight: '15px'
      }}
    >
      <CardContent style={cardStyle}>
        <FilterCardHeader>
          <Typography variant="overline" style={typographyStyle}>
            {label}
          </Typography>
          <Tooltip title={t('i18n.dashboard.REMOVE_FILTER')}>
            <RemoveFilterCardButton
              color="primary"
              aria-label="delete"
              onClick={() => {
                removeFilterFromChip(chipKey);
              }}
            >
              <Close />
            </RemoveFilterCardButton>
          </Tooltip>
        </FilterCardHeader>
        <CustomTypographyDescribe>{value}</CustomTypographyDescribe>
      </CardContent>
    </Card>
  );
}
