/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { CloudDownload } from '@material-ui/icons';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import AcerModal from '../../../components/AcerModal';
import API from '../../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    overflowY: 'auto'
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600]
  },
  table: {
    minWidth: 650
  },
  formControl: {
    'margin-bottom': theme.spacing(3)
  },
  dialogCustomizedWidth: {
    'max-width': '70%'
  },
  inputCpfCnpj: {
    marginBlockStart: '1.5em',
    width: '14em'
  },
  inputNf: {
    marginBlockStart: '1.5em',
    width: '10em'
  },
  inputSerie: {
    marginBlockStart: '1.5em',
    width: '6em'
  }
}));

const InvoiceModal = ({ onClose, open, customerCpf }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [cpfOrCnpj, setCpfOrCnpj] = useState(customerCpf || '');
  const [maskCpfCnpj, setMaskCpfCnpj] = useState('999.999.999-999');
  const [numNf, setNumNf] = useState('');
  const [codeSerie, setCodeSerie] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [indexDownload, setIndexDownload] = useState(0);

  const handleClose = () => {
    onClose(false);
    setRows([]);
    setCpfOrCnpj(customerCpf || '');
    setNumNf('');
  };

  const handleCpfCnpjMask = value => {
    value = value.replace(/\D/g, '');
    if (value.length > 11) {
      setMaskCpfCnpj('99.999.999/9999-99');
    } else if (value.length <= 11) {
      setMaskCpfCnpj('999.999.999-99');
    }
  };

  const createData = ({ numero, serie, chaveNFE, dataEmissao, valor }) => {
    return {
      numero,
      serie,
      chaveNFE,
      dataEmissao,
      valor
    };
  };

  const downloadNFE = (nfeCode, indexLoading) => {
    if (indexLoading) {
      setIndexDownload(indexLoading);
    }

    const config = {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf'
      }
    };
    setLoadingDownload(true);
    API.get(
      `/auth/ms-ticket/v1/notasfiscais/getpdf?numeroNF=${nfeCode}`,
      config
    )
      .then(response => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${nfeCode}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.invoicemodal.ERROR_GETTING_INFO'), {
          variant: 'error'
        });
      })
      .finally(() => {
        setLoadingDownload(false);
      });
  };

  const getEndpoint = () => {
    const onlyNumbersCpfCnpj = cpfOrCnpj.replace(/\D/g, '');
    const fixedWidthNf = 9;
    let numNf9digitis = numNf;
    if (numNf9digitis.length < fixedWidthNf) {
      const zerosToComplete = fixedWidthNf - numNf9digitis.length;
      for (let i = 1; i <= zerosToComplete; i++) {
        numNf9digitis = `0${numNf9digitis}`;
      }
    }
    if (cpfOrCnpj && numNf) {
      return `/auth/ms-ticket/v1/notasfiscais/find-invoice-by-cpfcnpj-and-numeronf?CpfCnpj=${onlyNumbersCpfCnpj}&numeroNF=${numNf9digitis}`;
    }
    if (cpfOrCnpj && !numNf) {
      setCodeSerie('');
      return `/auth/ms-ticket/v1/notasfiscais?CpfCnpj=${onlyNumbersCpfCnpj}`;
    }
    if (numNf && !cpfOrCnpj) {
      return `/auth/ms-ticket/v1/notasfiscais/find-invoice-by-numeronf-and-serie?numeroNF=${numNf9digitis}&serie=${codeSerie}`;
    }
  };

  const urlResponse = response => {
    if (numNf) {
      const newResponse = {
        notasFiscais: response
      };
      return newResponse;
    }
    return response;
  };

  const getInvoiceData = async () => {
    if ((!cpfOrCnpj || cpfOrCnpj === '') && !numNf) {
      enqueueSnackbar(t('i18n.invoicemodal.EMPTY_FIELD'), {
        variant: 'warning'
      });
      return;
    }
    if (!cpfOrCnpj && numNf && !codeSerie) {
      enqueueSnackbar(t('i18n.invoicemodal.EMPTY_SERIE'), {
        variant: 'warning'
      });
      return;
    }

    const url = getEndpoint();
    const tRows = [];
    setLoading(true);
    try {
      const response = await API.get(url);

      let { data } = response;
      data = urlResponse(data);
      if ((!data && !data.notasFiscais) || data.notasFiscais.length === 0) {
        setRows([]);
        setLoading(false);
        enqueueSnackbar(t('i18n.invoicemodal.NOT_FOUND'), {
          variant: 'error'
        });
        return;
      }

      for (let i = 0; i < data.notasFiscais.length; i++) {
        tRows.push(createData(data.notasFiscais[i]));
      }
      setRows(tRows);
    } catch (error) {
      enqueueSnackbar(t('i18n.invoicemodal.ERROR_GETTING_INFO'), {
        variant: 'error'
      });
      setRows([]);
    }
    setRows(tRows);
    setLoading(false);
  };

  return (
    <AcerModal
      fullWidth
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      onClose={handleClose}
      open={open}
      modalTitle={t('i18n.invoicemodal.INVOICES')}
    >
      <Card className={classes.root}>
        <CardContent>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Box paddingRight={3} className={classes.inputCpfCnpj}>
              <FormControl className={classes.formControl} fullWidth>
                <InputMask
                  mask={maskCpfCnpj}
                  maskChar=""
                  onPaste={e =>
                    handleCpfCnpjMask(e.clipboardData.getData('Text'))
                  }
                  value={cpfOrCnpj}
                  onChange={event => {
                    setCpfOrCnpj(event.target.value);
                    if (event.target.value.length > 14) {
                      setMaskCpfCnpj('99.999.999/9999-99');
                    } else {
                      setMaskCpfCnpj('999.999.999-999');
                    }
                  }}
                >
                  {() => (
                    <TextField
                      label={t('i18n.invoicemodal.CPFCNPJ')}
                      size="small"
                      variant="outlined"
                    />
                  )}
                </InputMask>
              </FormControl>
            </Box>
            <Box paddingRight={3}>
              <Typography>{t('i18n.simplewords.AND_OR')}</Typography>
            </Box>
            <Box className={classes.inputNf}>
              <FormControl className={classes.formControl} fullWidth>
                <InputMask
                  mask="999999999"
                  maskChar=""
                  value={numNf}
                  onChange={event => {
                    setNumNf(event.target.value);
                  }}
                >
                  {() => (
                    <TextField
                      label={`Nº ${t('i18n.invoicemodal.INVOICE')}`}
                      size="small"
                      variant="outlined"
                    />
                  )}
                </InputMask>
              </FormControl>
            </Box>
            <Box>
              <Typography>-</Typography>
            </Box>
            <Box className={classes.inputSerie} paddingRight={3}>
              <FormControl className={classes.formControl} fullWidth>
                <InputMask
                  mask="***"
                  maskChar=""
                  value={codeSerie}
                  onChange={event => {
                    setCodeSerie(event.target.value);
                  }}
                >
                  {() => (
                    <TextField
                      label={t('i18n.invoicemodal.SERIES')}
                      size="small"
                      variant="outlined"
                    />
                  )}
                </InputMask>
              </FormControl>
            </Box>
            <Box>
              <Button
                fullWidth
                onClick={getInvoiceData}
                variant="contained"
                size="large"
                color="primary"
                disabled={loading}
              >
                {loading
                  ? `${t('i18n.simplewords.LOADING')}...`
                  : t('i18n.invoicemodal.GET_INFORMATION')}
              </Button>
            </Box>
          </Box>
          {loading ? (
            <Box
              marginY={3}
              display="flex"
              justifyContent="center"
              flexDirection="row"
            >
              <CircularProgress />
            </Box>
          ) : (
            rows.length > 0 && (
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="Invoice table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('i18n.invoicemodal.NUMBER')}</TableCell>
                      <TableCell>{t('i18n.invoicemodal.SERIES')}</TableCell>
                      <TableCell>
                        {t('i18n.invoicemodal.INVOICE_KEY')}
                      </TableCell>
                      <TableCell>
                        {t('i18n.invoicemodal.EMISSION_DATE')}
                      </TableCell>
                      <TableCell>{t('i18n.invoicemodal.VALUE')}</TableCell>
                      <TableCell>Download</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.map((row, index) => (
                      <TableRow key={row.chaveNFE + row.numero}>
                        <TableCell>{row.numero}</TableCell>
                        <TableCell>{row.serie}</TableCell>
                        <TableCell>{row.chaveNFE}</TableCell>
                        <TableCell>
                          {moment(row.dataEmissao)
                            .utc()
                            .format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell>
                          {row.valor.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          })}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            disableFocusRipple
                            disableRipple
                            onClick={() => {
                              downloadNFE(row.chaveNFE, index);
                            }}
                            variant="contained"
                            color="primary"
                          >
                            {loadingDownload && index === indexDownload ? (
                              <CircularProgress size={24} />
                            ) : (
                              <CloudDownload />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          )}
        </CardContent>
      </Card>
    </AcerModal>
  );
};

InvoiceModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default InvoiceModal;
