import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Push from 'push.js';

import { ExpandMore } from '@material-ui/icons';
import chatSong from '../../../../assets/audio/chat-ring.mp3';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Notify,
  NotifyTitle,
  NotifyWrapper
} from '../styles/styles';
import { CustomerCards } from './ClientItem.component';

const chatRingtoneMessage = new Audio(chatSong);

const TabChatWebSocket = ({
  socket,
  expanded,
  handleChange,
  loadAttendances,
  newUserStatus
}) => {
  let subscribe;

  const { t } = useTranslation();
  const reduxDispatcher = useDispatch();

  const [clientPullded, setClientPullded] = useState({});
  const [myAttendances, setMyAttendances] = useState([]);
  const [newClient, setNewClient] = useState({});

  const initSubscribeSocket = () => {
    subscribe = socket?.newchatSubject.subscribe(value => {
      const response = JSON.parse(value.data);

      if (
        response?.system?.action === 'WARN' &&
        response?.system?.messages[0] !== null
      ) {
        // Quando o tipo de interação é uma conexão de cliente

        if (response?.system?.isNewChat) {
          chatRingtoneMessage.play();
        }

        setNewClient(response.system);
      }

      if (response?.system?.action === 'OUT') {
        // Quando um atendente puxa um atendimento
        // de outro atendente
        setClientPullded(response.system);

        reduxDispatcher({
          type: 'SHOW_CLIENT_TALK',
          showClientTalk: false
        });
      }

      if (
        response?.message &&
        response?.message !== 'Forbidden' &&
        response?.message !== 'Internal server error' &&
        response?.message !== 'Typing'
      ) {
        reduxDispatcher({
          type: 'SET_NOTIFICATION',
          notification: response
        });

        if (!document.hasFocus()) {
          Push.create(response.message, {
            body: `Nome: ${response.name}`
          });
          Push.clear();
        }

        chatRingtoneMessage.play();
        reduxDispatcher({ type: 'LAST_MESSAGE', lastMessage: response });
      }
    });
  };

  useEffect(() => {
    const newClientAlreadyExist = myAttendances.filter(attendance => {
      return attendance.hashConnection === newClient.hashConnection;
    });

    if (newClientAlreadyExist.length === 0 && newClient?.messages) {
      setMyAttendances([...myAttendances, ...[newClient]]);
      reduxDispatcher({
        type: 'SET_MY_CLIENTS',
        client: [...myAttendances, ...[newClient]]
      });
    }
  }, [newClient]);

  useEffect(() => {
    const refreshMyAttendances = myAttendances.filter(item => {
      return item.hashConnection !== clientPullded.hashConnection;
    });

    setMyAttendances(refreshMyAttendances);
    reduxDispatcher({
      type: 'SET_MY_CLIENTS',
      client: refreshMyAttendances
    });
  }, [clientPullded]);

  useEffect(() => {
    if (socket) {
      initSubscribeSocket();
    }
  }, [socket]);

  useEffect(() => {
    return () => {
      if (subscribe) {
        subscribe.unsubscribe();
      }
    };
  }, []);

  return (
    <Accordion
      square
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        <NotifyWrapper>
          <Notify>{myAttendances?.length}</Notify>
          <NotifyTitle>{t('i18n.chatview.MY_SERVICES')}</NotifyTitle>
        </NotifyWrapper>
      </AccordionSummary>
      <AccordionDetails>
        {myAttendances?.map(
          item =>
            item.action && (
              <CustomerCards
                chat={item}
                socket={socket}
                openChat
                loadAttendances={loadAttendances}
                newUserStatus={newUserStatus}
                key={item?.hashConnection}
                showOnlineBar
              />
            )
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default TabChatWebSocket;
