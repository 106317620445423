import React from 'react';
import ModalRequestDevolutionRefund from '../../../MarketPlaceToolbar/b2w/ModalRequestDevolutionRefund';

export const ModalRequestDevolutionRefundIframe = ({
  ticketData,
  openDevolutionModal,
  setOpenDevolutionModal,
  devolutionRefundFlag,
  stepsLimit,
  currentStep,
  handleStepsNext,
  handleStepsPrev,
  firstStep,
  handleNewAmericanasRefundExchangeCloseModal,
  causes,
  items,
}) => {
  return (
    <ModalRequestDevolutionRefund
      ticketData={ticketData}
      openState={openDevolutionModal}
      closeParent={() => {
        setOpenDevolutionModal(false);
      }}
      modalType={devolutionRefundFlag}
      stepsLimit={stepsLimit}
      currentStep={currentStep}
      handleStepsNext={handleStepsNext}
      handleStepsPrev={handleStepsPrev}
      firstStep={firstStep}
      handleCloseModal={handleNewAmericanasRefundExchangeCloseModal}
      causes={causes}
      items={items}
    />
  );
};
