/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../../services/api';
import config from '../../../../config';
import formHelper from '../../../../utils/formHelper';

const FilterAttendant = ({
  // localFilterData,
  // setLocalFilterData,
  isAdmin,
  // dashboardFilter,
  tabValue,
  classes,
  filterForm,
  setFilterForm,
  handleRemoveShortcutFlag,
  setCurrentShortcutShow
}) => {
  const { t } = useTranslation();
  const [attendantList, setAttendantList] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [attendantValue, setAttendantValue] = useState('');

  useEffect(() => {
    API.get(`/auth/ms-users/groups/atendente/users`).then(response => {
      setAttendantList(response.data.sort(formHelper.compareName));
    });
    // API.get(`/auth/ms-users/groups/${config.cognito.chatGroupName}/users`).then(
    //   response => {
    //     setChatList(response.data.sort(formHelper.compareName));
    //   }
    // );
  }, []);

  // useEffect(() => {
  //   if (!isAdmin) {
  //     setAttendantList([{ name: dashboardFilter.attendant }]);
  //     setLocalFilterData({
  //       ...localFilterData,
  //       ...{ attendant: dashboardFilter.attendant }
  //     });
  //   }
  // }, [dashboardFilter]);

  useEffect(() => {
    setAttendantValue(filterForm?.serviceAttendantLogin || '');
  }, [filterForm]);

  return (
    <>
      <Box mb={2}>
        <Grid container>
          <Grid item xs={7}>
            <FormControl size="small" variant="outlined" fullWidth>
              <InputLabel>{t('i18n.ticketslistview.ATTENDANT')}</InputLabel>
              <Select
                size="small"
                value={attendantValue}
                onChange={htmlElemtEvent => {
                  {
                    setAttendantValue(htmlElemtEvent.target.value);
                    setFilterForm({
                      ...filterForm,
                      serviceAttendantLogin: htmlElemtEvent.target.value
                    });
                    if (handleRemoveShortcutFlag) {
                      handleRemoveShortcutFlag();
                    }
                  }
                }}
                label={t('i18n.ticketslistview.ATTENDANT')}
                className="small-input"
              >
                <MenuItem value="">
                  <em>{t('i18n.ticketslistview.NONE')}</em>
                </MenuItem>
                {attendantList.map(user => {
                  return (
                    <MenuItem key={user?.name} value={user?.name}>
                      {user?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FilterAttendant;
