import { Grid } from '@material-ui/core';
import React from 'react';
import {
  SectionContent,
  SectionContentWrapper,
  SectionCustomGrid,
  SectionLink,
  SectionProductItem,
  SectionText
} from './SectionTabs';

const ProductItem = ({ item, t }) => {
  const itemFontSize = '12px';
  return (
    <SectionProductItem container spacing={2} key={item.partNumber}>
      <Grid item lg={4} xs={4}>
        <SectionContentWrapper>
          <SectionText style={{ fontSize: itemFontSize, paddingLeft: '10px' }}>
            {t('i18n.ticketcontainer.CODE')}{' '}
            <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
              {item.partNumber}
            </span>
          </SectionText>
          <SectionContent>
            {item.link ? (
              <SectionLink
                fSize={itemFontSize}
                bold
                target="_blank"
                rel="noreferrer"
                href={item.link}
              >
                {item.descricao.substr(0, 75)}
              </SectionLink>
            ) : (
              <SectionText fSize={itemFontSize}>{item.descricao}</SectionText>
            )}
          </SectionContent>
        </SectionContentWrapper>
      </Grid>
      <SectionCustomGrid item lg={2} xs={2}>
        <SectionContentWrapper>
          <SectionContent>
            <SectionText fSize={itemFontSize}>{item.quantidade}</SectionText>
          </SectionContent>
        </SectionContentWrapper>
      </SectionCustomGrid>
      <SectionCustomGrid item lg={2} xs={2}>
        <SectionContentWrapper>
          <SectionContent>
            <SectionText fSize={itemFontSize}>
              {item?.valorBruto?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              }) || 'R$ 0,00'}
            </SectionText>
          </SectionContent>
        </SectionContentWrapper>
      </SectionCustomGrid>
      <SectionCustomGrid item lg={2} xs={2}>
        <SectionContentWrapper>
          <SectionContent>
            <SectionText fSize={itemFontSize}>
              {item?.desconto?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              }) || 'R$ 0,00'}
            </SectionText>
          </SectionContent>
        </SectionContentWrapper>
      </SectionCustomGrid>
      <SectionCustomGrid item lg={2} xs={2}>
        <SectionContentWrapper>
          <SectionContent pLeft="0px">
            <SectionText fSize={itemFontSize}>
              {item?.totalLiquido?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              }) ||
                item?.valorBruto?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL'
                }) ||
                'R$ 0,00'}
            </SectionText>
          </SectionContent>
        </SectionContentWrapper>
      </SectionCustomGrid>
    </SectionProductItem>
  );
};

export default ProductItem;
