/*eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Chip,
  Container,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DialogActions from '@material-ui/core/DialogActions';
import AcerModal from '../../../../components/AcerModal';
import API from '../../../../services/api';
import { useSnackbar } from 'notistack';

import userService from '../../../../services/user.service';

import formHelper from '../../../../utils/formHelper';

const StyledDialogActions = styled(DialogActions)`
  margin-top: 1.5em;
`;

const AttribuitionModal = ({
  openProtocolAttribuition,
  setOpenProtocolAttribuition,
  ticketsListIds,
  setTicketsListIds,
  refreshTableContent,
  currentPage
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [attendantList, setAttendantList] = useState([]);
  const [selectDisabled, setSelectDisabled] = useState(false);
  const [attendantValue, setAttendantValue] = useState('');

  const handleSubmitAttribuition = () => {
    const data = {
      serviceAttendantLogin: attendantValue,
      ticketsListIds
    };

    try {
      API.post(`/auth/ms-ticket/v1/tickets/assign-tickets`, data).then((response) => {
        refreshTableContent(currentPage);
        setOpenProtocolAttribuition(false);
        setAttendantValue('');
      })
    } catch (e) {
      enqueueSnackbar(t('i18n.protocolView.ATTRIBUITION_PROTOCOLS_ERROR'), {
        variant: 'error'
      });
    }

  };

  useEffect(() => {
    if (openProtocolAttribuition) {
      if (userService.hasRole('ticket_assign_all')) {
        setSelectDisabled(true);
        const data = ['admin', 'especialista', 'atendente']
        try {
          API.post('auth/ms-users/groups/listUsersByGroupsNames', data).then(response => {
            setAttendantList(response.data.sort(formHelper.compareName));
            setSelectDisabled(false);
          });
        } catch (e) {
          enqueueSnackbar(t('i18n.protocolView.ATTRIBUITION_PROTOCOLS_GET_USERS_ERROR'), {
            variant: 'error'
          });
        }

      } else {
        setAttendantValue(userService.getUsername());
        setSelectDisabled(true);
        try {
          // API.get('/auth/ms-users/users').then(response => {
          API.get('/auth/ms-users/groups/atendente/users').then(response => {
            setAttendantList(response.data.sort(formHelper.compareName));
          });
        } catch (e) {
          enqueueSnackbar(t('i18n.protocolView.ATTRIBUITION_PROTOCOLS_GET_USERS_ERROR'), {
            variant: 'error'
          });
        }
      }
    }
  }, [openProtocolAttribuition]);

  return (
    <AcerModal
      maxWidth="sm"
      fullWidth
      onClose={() => setOpenProtocolAttribuition(false)}
      open={openProtocolAttribuition}
      modalTitle={t("i18n.protocolView.ATTRIBUITION_PROTOCOLS")}
    >
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <div style={{ display: 'flex', gap: '16px' }}>
              {selectDisabled && (
                <CircularProgress color='primary' />
              )}
              <FormControl size="small" variant="outlined" fullWidth>
                <InputLabel>{t('i18n.ticketslistview.ATTENDANT')}</InputLabel>
                <Select
                  size="small"
                  defaultValue=""
                  value={attendantValue || ''}
                  onChange={e => {
                    setAttendantValue(e.target.value);
                  }}
                  label={t('i18n.ticketslistview.ATTENDANT')}
                  disabled={selectDisabled}
                >
                  <MenuItem value="">
                    <em>{t('i18n.ticketslistview.NONE')}</em>
                  </MenuItem>
                  {attendantList.map(user => {
                    return (
                      <MenuItem key={user?.name} value={user?.name}>
                        {user?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </Box>
      <StyledDialogActions>
        <Button
          onClick={() => { setOpenProtocolAttribuition(false); setAttendantValue(''); }}
          color="primary"
        >
          {t('i18n.usercontainer.CANCEL')}
        </Button>
        <Button
          onClick={() => {
            handleSubmitAttribuition();
          }}
          color="primary"
          autoFocus
          variant="contained"
        >
          {t('i18n.simplewords.FINISH')}
        </Button>
      </StyledDialogActions>
    </AcerModal>
  );
};

export default AttribuitionModal;
