import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { useTranslation } from 'react-i18next';

const scores = [1, 2, 3, 4, 5];

export default function SelectEvaluation({ value, onChange, name }) {
  const { t } = useTranslation();

  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <InputLabel>{t('i18n.simplewords.RATING')}</InputLabel>
      <Select
        size="small"
        className="small-input"
        renderValue={(selected) =>
          `${selected} ${selected === 1 ? 'Estrela' : 'Estrelas'}`
        }
        onChange={onChange}
        value={value}
        name={name}
        label={t('i18n.simplewords.RATING')}
      >
        <MenuItem value="">
          <em>{t('i18n.ticketslistview.NONE')}</em>
        </MenuItem>
        {scores.map((score) => (
          <MenuItem key={score} value={score}>
            <div style={{ marginRight: 8 }}>
              {score} {score === 1 ? 'Estrela' : 'Estrelas'}
            </div>
            {scores.map((s) => (
              <StarIcon
                key={s}
                style={{ fill: s <= score ? '#FADB14' : '#F0F0F0' }}
              />
            ))}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
