import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { LocalPostOffice } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import AcerModal from '../../../components/AcerModal';
import API from '../../../services/api';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600]
  },
  table: {
    minWidth: 650
  },
  dialogCustomizedWidth: {
    'max-width': '80%'
  },
  root: {
    flexGrow: 1
  },
  textEditWidth: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  }
});

const TrackingModal = ({ onClose, open, searchCode }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [trackingCode, setTrackingCode] = useState(searchCode);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setRows([]);
    setTrackingCode('');
    onClose(false);
  };

  function createData({ dtHrCriado, unidade, descricao: description }) {
    const date = moment(dtHrCriado).format('DD/MM/YYYY');
    const time = moment(dtHrCriado).format('HH:mm');
    const dateTime = `${date} / ${time}`;

    const {
      tipo,
      endereco: { cidade, uf }
    } = unidade;

    const place = `${tipo} - ${cidade} - ${uf}`;
    return { dateTime, place, description };
  }

  const getTrackingData = async () => {
    if (!trackingCode || trackingCode === '') {
      enqueueSnackbar(t('i18n.trackingmodal.EMPTY_FIELD'), {
        variant: 'error'
      });
      return;
    }

    setLoading(true);

    try {
      const response = await API.get(
        `/auth/ms-ticket/v1/tracking/objeto-correio/${trackingCode}`
      );
      const { data } = response;

      const eventos = data?.objetos?.[0]?.eventos;

      if (!eventos) {
        setRows([]);
        return;
      }

      const tRows = [];
      eventos.forEach(eventData => tRows.push(createData(eventData)));

      setRows(tRows);
    } catch (error) {
      enqueueSnackbar(t('i18n.trackingmodal.ERROR_GETTING_INFO'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      setRows([]);
    }

    setLoading(false);
  };

  return (
    <AcerModal
      fullWidth
      maxWidth="lg"
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      onClose={handleClose}
      open={open}
      modalTitle={t('i18n.trackingmodal.TRACKING')}
    >
      <Card className={classes.root}>
        <CardContent>
          <Box display="flex" flexDirection="row" justifyContent="flex-start">
            <Box paddingRight={3}>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  fullWidth
                  className={classes.textEditWidth}
                  size="small"
                  id="trackingCodeEdit"
                  label={t('i18n.trackingmodal.TRACKING_CODE')}
                  value={trackingCode}
                  onChange={event => {
                    setTrackingCode(event.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {loading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <LocalPostOffice />
                        )}
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>
            </Box>
            <Box>
              <Button
                fullWidth
                onClick={getTrackingData}
                variant="contained"
                size="large"
                color="primary"
              >
                {t('i18n.trackingmodal.GET_INFORMATION')}
              </Button>
            </Box>
          </Box>
          <br />
          {loading ? (
            <> </>
          ) : (
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="Tracking table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>{t('i18n.trackingmodal.DATE_TIME')}</TableCell>
                    <TableCell>{t('i18n.trackingmodal.PLACE')}</TableCell>
                    <TableCell>{t('i18n.trackingmodal.DESCRIPTION')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows && rows.length > 0
                    ? rows.map(row => (
                        <TableRow key={row.dateTime}>
                          <TableCell component="th" scope="row">
                            {row.dateTime}
                          </TableCell>
                          <TableCell>{row.place}</TableCell>
                          <TableCell>{row.description}</TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </Card>
    </AcerModal>
  );
};

TrackingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default TrackingModal;
