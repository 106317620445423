import React, { useState, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { ArrowDropDown, ArrowDropUp, Cancel } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';

import { COLORS } from '../../../styles/settings/colors.config';
import {
  CustomTypography,
  CustomLink
} from '../../../components/Custom.components';
import { UnBlockButton } from '../NewChatV2/components/UnBlockUser.component';
import { BlockedIPsContext } from './context';

const BoldedTd = styled.td`
  font-weight: bold;
  min-width: ${(props) => props.tdMinWidth || '100px'};
  max-width: ${(props) => props.tdMaxWidth || 'inherit'};
  color: ${(props) => props.tdColor || 'black'};
  border-top: ${(props) => props.tdBorderTop || '1px solid #ccc !important'};
  border-bottom: ${(props) => props.tdBorderBottom || '1px solid #ccc'};
  // padding: 20px 0px !important;
`;

const CustomTd = styled('div')`
  p {
    max-width: ${(props) => props.tdMaxWidth || '100px'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const RenderButton = ({ items, opened, setOpened }) => {
  if (items?.length === 0) {
    return (
      <span style={{ display: 'flex', justifyContent: 'center' }}>
        <Cancel style={{ color: COLORS.lightGray }} />
      </span>
    );
  }

  return (
    <span style={{ display: 'flex', justifyContent: 'center' }}>
      {opened ? (
        <ArrowDropUp
          style={{ color: COLORS.greenText }}
          onClick={() => setOpened(!opened)}
        />
      ) : (
        <ArrowDropDown
          style={{ color: COLORS.greenText }}
          onClick={() => setOpened(!opened)}
        />
      )}
    </span>
  );
};

export const CustomRow = (props) => {
  const { setOpenModal, setUser } = useContext(BlockedIPsContext);

  const { data, dataIndex } = props;
  const [opened, setOpened] = useState(false);
  const itemData = data[dataIndex];
  const { blockedLogging, blockedReason } = itemData;

  const translatedBlockedReason = useMemo(() => {
    if (blockedReason === 'OFFENSIVE_VOCABULARY') return 'Vocabulário Ofensivo';
    if (blockedReason === 'SPAM') return 'SPAM';
    return blockedReason;
  }, [blockedReason]);

  return (
    <>
      <tr
        style={{
          fontFamily: 'Roboto',
          fontSize: '12px',
          height: '70px'
        }}
      >
        <BoldedTd>
          <CustomTypography>
            <b> {itemData.businessType}</b>
          </CustomTypography>
        </BoldedTd>
        <BoldedTd>
          <Tooltip title={itemData.ip}>
            <CustomTd>
              <CustomTypography>
                <b> {itemData.ip}</b>
              </CustomTypography>
            </CustomTd>
          </Tooltip>
        </BoldedTd>
        <BoldedTd>
          <Tooltip title={itemData.clientName}>
            <CustomTd>
              <CustomLink
                to={`/chat-container/${itemData.hashConnection}`}
                color="primary"
                target="_blank"
              >
                {itemData.clientName}
              </CustomLink>
            </CustomTd>
          </Tooltip>
        </BoldedTd>
        <BoldedTd BoldedTd tdMinWidth="130px" tdMaxWidth="130px">
          <Tooltip title={itemData.clientEmail}>
            <CustomTd>
              <CustomTypography>
                <b> {itemData.clientEmail}</b>
              </CustomTypography>
            </CustomTd>
          </Tooltip>
        </BoldedTd>
        <BoldedTd>
          <CustomTypography>
            <b> {itemData.blockedByUser}</b>
          </CustomTypography>
        </BoldedTd>
        <BoldedTd tdMinWidth="170px" tdMaxWidth="170px">
          <Tooltip title={translatedBlockedReason}>
            <CustomTd>
              <CustomTypography>
                <b> {translatedBlockedReason}</b>
              </CustomTypography>
            </CustomTd>
          </Tooltip>
        </BoldedTd>
        <BoldedTd>
          <Tooltip
            title={moment(itemData.blockedDate)
              .subtract({ hours: 3 })
              .format('DD/MM/YYYY HH:mm')}
          >
            <CustomTd>
              <CustomTypography>
                <b>
                  {' '}
                  {moment(itemData.blockedDate)
                    .subtract({ hours: 3 })
                    .format('DD/MM/YYYY HH:mm')}
                </b>
              </CustomTypography>
            </CustomTd>
          </Tooltip>
        </BoldedTd>
        <BoldedTd>
          <CustomTypography>
            <b> {itemData.unblockedByUser}</b>
          </CustomTypography>
        </BoldedTd>
        <BoldedTd>
          <Tooltip title={itemData.unblockedReason}>
            <CustomTd>
              <CustomTypography>
                <b> {itemData.unblockedReason}</b>
              </CustomTypography>
            </CustomTd>
          </Tooltip>
        </BoldedTd>
        <BoldedTd>
          <Tooltip
            title={
              itemData.unblockedDate &&
              moment(itemData.unblockedDate)
                .subtract({ hours: 3 })
                .format('DD/MM/YYYY HH:mm')
            }
          >
            <CustomTd>
              <CustomTypography>
                <b>
                  {' '}
                  {itemData.unblockedDate &&
                    moment(itemData.unblockedDate)
                      .subtract({ hours: 3 })
                      .format('DD/MM/YYYY HH:mm')}
                </b>
              </CustomTypography>
            </CustomTd>
          </Tooltip>
        </BoldedTd>
        <BoldedTd>
          <CustomTypography>
            {' '}
            <UnBlockButton {...{ setUser, setOpenModal, rowData: itemData }} />
          </CustomTypography>
        </BoldedTd>

        <BoldedTd>
          <RenderButton
            {...{ items: blockedLogging, opened, setOpened }}
            fontSize="large"
          />
        </BoldedTd>
      </tr>

      {blockedLogging?.length > 0 &&
        opened &&
        blockedLogging?.map((item) => {
          return (
            <tr
              style={{
                fontFamily: 'Roboto'
              }}
              key={Math.random()}
            >
              {/* eslint-disable-next-line */}
              <td style={{ backgroundColor: 'whitesmoke' }} />
              {/* eslint-disable-next-line */}
              <td style={{ backgroundColor: 'whitesmoke' }} />
              <td>
                <Tooltip title={item.clientName}>
                  <CustomTd>
                    <CustomLink
                      to={`/chat-container/${item.hashConnection}`}
                      color="primary"
                      target="_blank"
                    >
                      {item.clientName}
                    </CustomLink>
                  </CustomTd>
                </Tooltip>
              </td>
              <td>
                <Tooltip title={item.clientEmail}>
                  <CustomTd>
                    <CustomTypography>{item.clientEmail}</CustomTypography>
                  </CustomTd>
                </Tooltip>
              </td>
              <td>
                <Tooltip title={item.blockedByUser}>
                  <CustomTd>
                    <CustomTypography>{item.blockedByUser}</CustomTypography>
                  </CustomTd>
                </Tooltip>
              </td>
              <td>
                <Tooltip title={item.blockedReason}>
                  <CustomTd>
                    <CustomTypography>{item.blockedReason}</CustomTypography>
                  </CustomTd>
                </Tooltip>
              </td>
              <td>
                <Tooltip
                  title={moment(item.blockedDate)
                    .subtract({ hours: 3 })
                    .format('DD/MM/YYYY HH:mm')}
                >
                  <CustomTd>
                    <CustomTypography>
                      {moment(item.blockedDate)
                        .subtract({ hours: 3 })
                        .format('DD/MM/YYYY HH:mm')}
                    </CustomTypography>
                  </CustomTd>
                </Tooltip>
              </td>
              <td>
                <Tooltip title={item.unblockedByUser}>
                  <CustomTd>
                    <CustomTypography>{item.unblockedByUser}</CustomTypography>
                  </CustomTd>
                </Tooltip>
              </td>
              <td>
                <Tooltip title={item.unblockedReason}>
                  <CustomTd>
                    <CustomTypography>{item.unblockedReason}</CustomTypography>
                  </CustomTd>
                </Tooltip>
              </td>
              <td>
                <Tooltip
                  title={
                    item.unblockedDate &&
                    moment(item.unblockedDate)
                      .subtract({ hours: 3 })
                      .format('DD/MM/YYYY HH:mm')
                  }
                >
                  <CustomTd>
                    <CustomTypography>
                      {item.unblockedDate &&
                        moment(item.unblockedDate)
                          .subtract({ hours: 3 })
                          .format('DD/MM/YYYY HH:mm')}
                    </CustomTypography>
                  </CustomTd>
                </Tooltip>
              </td>
              {/* eslint-disable-next-line */}
              <td style={{ backgroundColor: 'whitesmoke' }} />
              {/* eslint-disable-next-line */}
              <td style={{ backgroundColor: 'whitesmoke' }} />
            </tr>
          );
        })}
    </>
  );
};
