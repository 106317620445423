/* eslint-disable */
import { Grid, Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PresaleOfflineCardList } from '../components/PresaleOffline/PresaleOfflineCardList.component';
import { PresaleOfflineTimeCardList } from '../components/PresaleOffline/PresaleOfflineTimeCardList.component';
import { PresaleOfflineDonutChartV2 } from '../components/PresaleOffline/PresaleOfflineDonutChartWrapper.component';
import { setDefaultnameToAttendant } from './config';
import { CustomSmall, CustomTypograph } from '../styles';
import { COLORS } from '../../../styles/settings/colors.config';
import userService from '../../../services/user.service';

const setDataByMarketplace = (market, data) => {
  const {
    qtd_per_status_ml,
    qtd_questions_ml,
    qtd_per_status_acer,
    qtd_questions_acer
  } = data;
  if (market === 'YOURVIEWS') {
    return { data: qtd_per_status_acer, total: qtd_questions_acer };
  }
  if (market === 'MERCADO_LIVRE') {
    return { data: qtd_per_status_ml, total: qtd_questions_ml };
  }
};

const factoryIndividualData = dataEquipDash => {
  const {
    qtd_answered_per_clerk,
    tma_answered_per_clerk,
    qtd_answered_total_team,
    qtd_questions_acer,
    qtd_questions_ml,
    qtd_per_status_acer,
    qtd_per_status_ml
  } = dataEquipDash;
  let qtdAnsweredPerClerk = [];
  let tmaAnsweredPerClerk = [];
  const total = qtd_questions_acer + qtd_questions_ml;

  if (qtd_answered_per_clerk?.length > 0) {
    qtdAnsweredPerClerk = [
      {
        attendant: 'Equipe',
        answered: qtd_answered_total_team - qtd_answered_per_clerk[0].answered
      },
      qtd_answered_per_clerk[0]
    ];
  }

  if (tma_answered_per_clerk?.length > 0) {
    tmaAnsweredPerClerk = tma_answered_per_clerk[0];
  }

  return {
    total,
    qtdAnsweredPerClerk,
    tmaAnsweredPerClerk,
    qtd_per_status_acer,
    qtd_per_status_ml,
    qtd_questions_acer,
    qtd_questions_ml
  };
};

const factoryColumnData = (
  label,
  show,
  dataEquipDash,
  dataIndividualDash,
  market,
  isTeamView,
  callback
) => {
  return {
    label: label,
    show: show,
    total: isTeamView
      ? callback(dataEquipDash, market)?.total
      : callback(dataIndividualDash, market)?.total,
    data: isTeamView
      ? callback(dataEquipDash, market)?.data
      : callback(dataIndividualDash, market)?.data
  };
};

const factorySimpleColumnData = (label, show, total) => {
  return {
    label,
    show,
    total
  };
};

const DashboardContent = ({
  // isAdmin,
  dataEquipDash,
  dashboardFilter,
  marketPlacesSelected
}) => {
  const { t } = useTranslation();

  const marketplace = marketPlacesSelected;
  const {
    qtd_questions_total_team,
    qtd_questions_acer_no_assign,
    qtd_questions_ml_no_assign,
    qtd_answered_total_team,
    qtd_answered_per_clerk = []
  } = dataEquipDash;
  const isTeamView = !dashboardFilter?.attendant.length > 0 ? true : false;
  const dataIndividualDash = !isTeamView
    ? factoryIndividualData(dataEquipDash)
    : {};

  const login = dashboardFilter?.attendant;

  const handleShowColumn = market => {
    const marketList = [market];
    const filtered = marketplace.filter(item => marketList.includes(item));
    if (filtered.length > 0) {
      return true;
    }
    return false;
  };

  const setData = (data, marketplace) => {
    return setDataByMarketplace(marketplace, data);
  };

  return (
    <>
      <Grid container spacing={5}>
        <Grid item md={12}>
          <Grid container spacing={3} justifyContent="flex-start">
            <Grid item xl={12} lg={12} md={12}>
              <Box mb={2}>
                <CustomTypograph variant="h3">
                  Pré-venda <b>Offline</b>
                </CustomTypograph>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              lg={4}
              md={6}
              sm={12}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <PresaleOfflineCardList
                descriptionHint="Número de perguntas sem atribuição de analista, de acordo com o período selecionado."
                isTeamView={true}
                cardType="qtdNewProtocols"
                userName={login}
                withDrillDown
                headerData
                secondaryLabel={
                  <span
                    style={{
                      fontFamily: 'Roboto',
                      color: COLORS.grayText,
                      fontSize: '16px'
                    }}
                  >
                    Total <b>Equipe</b>
                  </span>
                }
                secondaryData={
                  parseInt(qtd_questions_acer_no_assign) +
                  parseInt(qtd_questions_ml_no_assign)
                }
                extraDataColumn1={
                  handleShowColumn('YOURVIEWS')
                    ? factorySimpleColumnData(
                        'Acer Store B2C',
                        true,
                        qtd_questions_acer_no_assign
                      )
                    : false
                }
                extraDataColumn2={
                  handleShowColumn('MERCADO_LIVRE')
                    ? factorySimpleColumnData(
                        'Mercado Livre',
                        true,
                        qtd_questions_ml_no_assign
                      )
                    : false
                }
                showMarketplaceDetails={marketplace.length === 1}
              />

              <PresaleOfflineCardList
                descriptionHint="Número de perguntas divididas por status, de acordo com o marketplace e período selecionado."
                firstData={dataIndividualDash?.total}
                withDrillDown
                firstLabel={
                  <CustomTypograph>
                    Total <b>{login}</b>
                  </CustomTypograph>
                }
                secondaryLabel={
                  <span
                    style={{
                      fontFamily: 'Roboto',
                      color: COLORS.grayText,
                      fontSize: '16px'
                    }}
                  >
                    Total <b>Equipe</b>
                  </span>
                }
                secondaryData={qtd_questions_total_team}
                headerData
                showMoreDetails
                isTeamView={isTeamView}
                cardType={isTeamView ? 'qtdProtocols' : 'qtdAttendantProtocols'}
                cardName="offlineCardList"
                userName={login}
                dashboardFilter={dashboardFilter}
                extraDataColumn1={
                  handleShowColumn('YOURVIEWS')
                    ? factoryColumnData(
                        'Acer Store B2C',
                        true,
                        dataEquipDash,
                        dataIndividualDash,
                        'YOURVIEWS',
                        isTeamView,
                        setData
                      )
                    : false
                }
                extraDataColumn2={
                  handleShowColumn('MERCADO_LIVRE')
                    ? factoryColumnData(
                        'Mercado Livre',
                        true,
                        dataEquipDash,
                        dataIndividualDash,
                        'MERCADO_LIVRE',
                        isTeamView,
                        setData
                      )
                    : false
                }
                showMarketplaceDetails={marketplace.length === 1}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              md={6}
              sm={12}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <PresaleOfflineDonutChartV2
                dataChart={{
                  qtd_answered_total_team,
                  qtd_answered_per_clerk: isTeamView
                    ? setDefaultnameToAttendant(qtd_answered_per_clerk)
                    : setDefaultnameToAttendant(
                        dataIndividualDash.qtdAnsweredPerClerk
                      )
                }}
                descriptionHint="Total de perguntas respondidas por analista"
                isTeamView={isTeamView}
                showMoreDetails
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              md={6}
              sm={12}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <PresaleOfflineTimeCardList
                descriptionHint="Tempo médio de resposta de acordo com o período selecionado."
                firstData={0}
                secondaryData={0}
                firstLabel={t('i18n.dashboard.components.individual')}
                secondaryLabel={t('i18n.dashboard.components.equip')}
                showMoreDetails
                isTeamView={isTeamView}
                showIcon
                percent
                cardType="answers"
                extraDataColumn1={{
                  label: `${login}`,
                  show: !isTeamView,
                  total: dataIndividualDash?.tmaAnsweredPerClerk?.tma,
                  value: '92',
                  subLabel: 'da Meta'
                }}
                extraDataColumn2={{
                  label: 'Equipe',
                  show: true,
                  total: dataEquipDash.tma_answered_total_team,
                  value: '95',
                  subLabel: 'da Meta',
                  data: dataEquipDash.tma_answered_per_clerk
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardContent;
