import React from 'react';
import { Chip, CardContent, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import formHelper from '../../../../utils/formHelper';
import AgentMessageCard from '../baloons/AgentMessageCard';
import ClientMessageCard from '../baloons/ClientMessageCard';
import { StyledPaper, StyledDivider } from '../styles';
import MessageActions from '../components/MessageActions.component';

const MlClaimsMessages = ({
  mlClaims,
  chatState,
  firstMediatorResponseDate,
  mlClaimRespostaLoading,
  mlClaimResposta,
  chat,
  dadosPedido,
  getTicket,
  isMlClaimReturnType,
  sendMlClaimResponse,
  setMlClaimResposta,
  mlTicketblocked,
  handleAppliedMacro,
  getInputProps,
  getRootProps,
  files
}) => {
  const { t } = useTranslation();
  const ModeratorDivider = () => (
    <StyledDivider>
      <div className="container">
        <div className="border" />
        <span className="content">
          <Chip label={t('i18n.ticketcontainer.MEDIATION_FROM_HERE')} />
        </span>
        <div className="border" />
      </div>
    </StyledDivider>
  );
  return (
    <>
      <StyledPaper className="message-paper">
        <CardContent>
          {formHelper.reverseArr(mlClaims).map(m => {
            m.date = m.date_created;
            if (m.sender_role === 'complainant') {
              m.agent = 'CLIENT';
            }
            if (m.sender_role === 'mediator') {
              m.agent = 'MEDIATOR';
            }
            let div = (
              <Grid container spacing={1}>
                <Grid item md={10} xs={12}>
                  <ClientMessageCard
                    mlClaimId={
                      chatState?.marketplaceBundle
                        ?.mercadoLivreAfterSaleClaimEntity?.claim_id
                    }
                    message={m}
                  />
                </Grid>
              </Grid>
            );
            if (m.sender_role === 'respondent') {
              if (m.receiver_role === 'mediator') {
                m.agent = t(
                  'i18n.ticketcontainer.MERCADO_LIVRE_CLAIM_MESSAGE_ABO_TO_MEDIATOR'
                );
              } else
                m.agent = t(
                  'i18n.ticketcontainer.MERCADO_LIVRE_CLAIM_MESSAGE_ABO_TO_COMPLAINT'
                );
              div = (
                <Grid container spacing={1}>
                  <Grid item md={2} xs={12} />
                  <Grid item md={10} xs={12}>
                    <AgentMessageCard
                      mlClaimId={
                        chatState?.marketplaceBundle
                          ?.mercadoLivreAfterSaleClaimEntity?.claim_id
                      }
                      message={m}
                    />
                  </Grid>
                </Grid>
              );
            }
            return (
              <div key={Math.random()}>
                {m.date === firstMediatorResponseDate && <ModeratorDivider />}
                {div}
              </div>
            );
          })}
          {mlClaimRespostaLoading && (
            <div key="loadingNewMlmessage">
              <Grid container spacing={1}>
                <Grid item md={2} xs={12} />
                <Grid item md={10} xs={12}>
                  <AgentMessageCard
                    message={{
                      message: mlClaimResposta,
                      agent: 'ABO',
                      status: 'loading'
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </CardContent>
      </StyledPaper>

      <MessageActions
        typeMessage="MLClaim"
        disableAttachment={mlClaimRespostaLoading}
        loading={mlClaimRespostaLoading || isMlClaimReturnType()}
        resposta={mlClaimResposta}
        setResposta={setMlClaimResposta}
        disableSend={mlClaimRespostaLoading || isMlClaimReturnType()}
        sendResponse={sendMlClaimResponse}
        mlTicketblocked={mlTicketblocked}
        chatState={chatState}
        chat={chat}
        dadosPedido={dadosPedido}
        getTicket={getTicket}
        handleAppliedMacro={handleAppliedMacro}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        files={files}
      />
    </>
  );
};

export default MlClaimsMessages;
