/* eslint-disable no-nested-ternary */
import {
  Box,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Tooltip,
  Card,
  CardContent
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { StyledSpinner } from '../../styles/components/SpinnerDefault';
import FetchContentError from '../../components/FetchContentError';
import filterHelper from '../../utils/filterHelper';
import Page from '../../components/Page';
import config from '../../config';
import CustomQuickAwnserFooter from "../../styles/components/CustomQuickAwnserFooter"
import API from '../../services/api';
import {
  CustomDataTableWrapper,
  CustomDataTable,
  CustomTableButton
} from '../../styles/components/CustomDataTable';
import QuickAnswersFilter from './QuickAnswersFilter';

import {
  RemoveFilterCardButton,
  cardStyle,
  FilterCardHeader,
  CustomTypographyDescribe,
  typographyStyle
} from '../../styles/components/Tags';
import HeaderTitle from '../../components/HeaderTitle';

import { getLabel } from './services';

const FilterChip = ({
  itemKey,
  index,
  filterData,
  removeFilterFromChip,
  filterFormData
}) => {
  const { t } = useTranslation();

  return (
    <Box key={index} item>
      <Card
        style={{
          borderRadius: '10px'
        }}
      >
        <CardContent style={cardStyle}>
          <FilterCardHeader>
            <Typography variant="overline" style={typographyStyle}>
              {t(filterFormData[itemKey]?.label)}
            </Typography>
            <Tooltip title={t('i18n.dashboard.REMOVE_FILTER')}>
              <RemoveFilterCardButton
                color="primary"
                aria-label="delete"
                onClick={() => {
                  removeFilterFromChip(itemKey);
                }}
              >
                <CloseIcon />
              </RemoveFilterCardButton>
            </Tooltip>
          </FilterCardHeader>
          <CustomTypographyDescribe>
            {filterHelper.filterChipLabeler(
              itemKey,
              filterData[itemKey],
              t,
              true
            )}
          </CustomTypographyDescribe>
        </CardContent>
      </Card>
    </Box>
  );
};

const QuickAnswersList = () => {
  const { t } = useTranslation();
  const [hasFetchError, setFetchError] = useState(false);
  const [removedChip, setRemovedChip] = useState('');
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [load, setLoad] = useState(true);
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [currentSelectedRows, setCurrentSelectedRows] = useState([]);
  const [filterData, setFilterData] = useState({
    name: '',
    type: '',
    message: ''
  });

  const filterFormData = {
    name: { value: '', label: 'i18n.groupcontainer.NAME' },
    type: { value: '', label: 'i18n.ticketcontainer.TYPE' },
    message: { value: '', label: 'i18n.knowledge.ANSWER' }
  };

  const removeFilterFromChip = (key) => {
    setFilterData({ ...filterData, [key]: '' });
    setRemovedChip(key);
  };

  const columns = [
    {
      name: 'id',
      label: '',
      options: {
        filter: false,
        sort: false,
        display: false
      }
      // options: defaultColumOptions
    },
    {
      name: 'name',
      label: t('i18n.groupcontainer.NAME'),
      options: {
        customBodyRender: (value) => {
          return (
            <Box display="flex" width="100%" alignItems="center">
              <Typography style={{ fontSize: 12 }}>{value}</Typography>
            </Box>
          );
        },
        filter: false,
        sort: false
      }
    },
    {
      name: 'enable',
      label: 'Habilitado?',
      options: {
        customBodyRender: (value) => {
          return value ? t('i18n.simplewords.YES') : t('i18n.simplewords.NO');
        },
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'type',
      label: t('i18n.ticketcontainer.TYPE'),
      options: {
        customBodyRender: (value) => {
          const chips = [];
          if (Array.isArray(value)) {
            // verifica se value é um array
            value
              .sort((a, b) => {
                return a - b;
              })
              .forEach((v) => {
                chips.push(
                  <Box
                    sx={{ m: 1 }}
                    display="flex"
                    key={Math.random()}
                    width={150}
                    flexDirection="row"
                  >
                    <Chip key={Math.random()} label={t(getLabel(v))} />
                  </Box>
                );
              });
          } else {
            // se não for um array
            return (
              <Box
                sx={{ m: 1 }}
                display="flex"
                key={Math.random()}
                width={149}
                flexDirection="row"
              >
                <Chip key={Math.random()} label={t(getLabel(value))} />
              </Box>
            );
          }
          return chips;
        },
        filter: false,
        sort: false
      }
    },
    {
      name: 'message',
      label: t('i18n.knowledge.ANSWER'),
      options: {
        customBodyRender: (value) => {
          return (
            <Box display="flex" width="100%" alignItems="center">
              <Typography style={{ fontSize: 12 }}>{value}</Typography>
            </Box>
          );
        },
        filter: false,
        sort: false
      }
    },
    {
      name: 'user_group',
      label: 'Grupos',
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        display: false
      }
    }
  ];

  const refreshTableContent = (
    tableState = null,
    name = '',
    type = '',
    message = ''
  ) => {
    setLoad(false);

    API.get(
      `${config.backend.macroURL}?fast=1&page=${
        tableState?.page ? tableState?.page : 0
      }`
    )
      .then((response) => {
        setLoad(true);
        setFetchError(false);
        let result = response.data.data.map((item) => {
          let formattedMessage = '';
          const mx = item.actions[0].INSERT_MESSAGE.value.split('\\n');
          mx.forEach((m) => {
            formattedMessage += `${m} `;
          });

          return {
            ...item,
            message: formattedMessage
          };
        });
        if (name) {
          result = result.filter((item) =>
            item.name
              .toUpperCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .includes(
                name
                  .toUpperCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
              )
          );
        }

        if (type) {
          result = result.filter((item) => {
            const findItem = item.type.includes(type);
            if (findItem) {
              return item;
            }
            return '';
          });
        }

        if (message) {
          result = result.filter((item) =>
            item.message
              .toUpperCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .includes(
                message
                  .toUpperCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
              )
          );
        }
        setData(result);
      })
      .catch(() => {
        setData([]);
        setFetchError(true);
        setLoad(true);
      });
  };

  const deleteAnswer = (id, name) => {
    API({
      method: 'DELETE',
      url: config.backend.macroURL,
      data: { id }
    })
      .then(() => {
        const successMessage = `Resposta ${name}${t(
          'i18n.macros.MACRO_DELETE_SUCCESS_END'
        )}`;
        enqueueSnackbar(successMessage, {
          variant: 'success'
        });
        refreshTableContent();
      })
      .catch(() => {
        const errorMessage = t('i18n.macros.MACRO_DELETE_ERROR_START') + id;
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        });
      });
  };

  const options = {
    serverSide: false,
    pagination: true,
    selectableRowsHeader: false,
    download: false,
    print: false,
    filter: false,
    search: false,
    viewColumns: false,
    onRowsDelete: () => {
      for (let i = 0; i < displayData.length; i++) {
        const item = displayData[i];
        const id = item.data[0];
        const name = item.data[1];
        if (currentSelectedRows.includes(i)) {
          deleteAnswer(id, name);
        }
      }
    },

    onTableChange: (action, tableState) => {
      /*
      if (action === 'changePage' || action === 'changeRowsPerPage') {
        refreshTableContent(tableState);
      }
      */

      if (action === 'rowSelectionChange') {
        const temp = [];
        tableState.selectedRows.data.forEach((item) => temp.push(item.index));
        setCurrentSelectedRows(temp);
        setDisplayData(tableState.displayData);
      }
    },
    customToolbar: () => {
      return (
        <>
          <CustomTableButton
            variant="text"
            color="primary"
            size="small"
            onClick={() => {
              history.push('/quick-answers/new');
            }}
            startIcon={<AddIcon />}
          >
            {t('i18n.quickanswer.ADD_A_QUICK_ANSWER')}
          </CustomTableButton>
        </>
      );
    },
    onRowClick: (rowData) => {
      const filteredItem = data.find((item) => item.id === rowData[0]);

      const {
        id,
        name,
        enable,
        description,
        // eslint-disable-next-line
        user_group,
        actions,
        type
      } = filteredItem;

      history.push({
        pathname: `/quick-answers/new`,
        state: {
          id,
          nome: name,
          habilitado: enable,
          descricao: description,
          // eslint-disable-next-line
          grupos: user_group,
          acoes: actions,
          type,
          editing: true
        }
      });
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      return (
        <CustomQuickAwnserFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          filterData={filterData}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: load
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: (column) =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };

  useEffect(() => {
    refreshTableContent(
      null,
      filterData.name,
      filterData.type,
      filterData.message
    );
  }, [filterData]);

  if (hasFetchError) {
    return <FetchContentError fetch={refreshTableContent} />;
  }

  return (
    <>
      <QuickAnswersFilter
        setFilterData={setFilterData}
        filterData={filterData}
        removedChip={removedChip}
        setRemovedChip={setRemovedChip}
      />
      <Page title={t('i18n.dashboardlayout.QUICK_ANSWERS')}>
        <Container maxWidth={false}>
          <Box mt={3} ml={7}>
            <CustomDataTableWrapper style={{ opacity: !load ? '0.3' : '1' }}>
              <CustomDataTable
                title={
                  <Grid container direction="column" spacing={0}>
                    <Grid item>
                      <HeaderTitle
                        title={`
                      ${t('i18n.dashboardlayout.QUICK_ANSWERS')} - 
                      ${t('i18n.dashboardlayout.PRE')}-${t(
                          'i18n.dashboardlayout.SALE'
                        )}
                      `}
                      />
                    </Grid>
                    <Grid item>
                      <Box display="flex" mb={2} mt={3} style={{ gap: '15px' }}>
                        {Object.entries(filterData).map(
                          ([key], index) =>
                            key !== 'status' &&
                            filterData[key] !== '' && (
                              <>
                                <FilterChip
                                  {...{ index }}
                                  itemKey={key}
                                  key={Math.random()}
                                  filterData={filterData}
                                  removeFilterFromChip={removeFilterFromChip}
                                  filterFormData={filterFormData}
                                />
                              </>
                            )
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                }
                data={data}
                columns={columns}
                selectableRowsOnClick
                options={options}
                className="table-clickable"
              />
            </CustomDataTableWrapper>
            <StyledSpinner style={{ display: !load ? 'block' : 'none' }}>
              <CircularProgress />
            </StyledSpinner>
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default QuickAnswersList;
