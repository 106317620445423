import { Box, Typography } from '@material-ui/core';
import React from 'react';
import FilterChip from './FilterChip';

export default function ChipSection({
  search,
  status,
  removeFilterFromChip,
  statusList
}) {
  const findedStatus = statusList.find(s => s.value === status);

  return (
    <>
      <Box display="flex" flexDirection="column" mb={4}>
        {(status || search) && (
          <Typography style={{ fontSize: '12px', marginBottom: '5px' }}>
            Filtros Aplicados:
          </Typography>
        )}
        <Box display="flex">
          {search && (
            <FilterChip
              label="Nota Fiscal"
              chipKey="search"
              value={search}
              {...{ removeFilterFromChip }}
            />
          )}

          {status && status !== ' ' && (
            <FilterChip
              label="Status da Devolução"
              chipKey="status"
              value={findedStatus?.label}
              {...{ removeFilterFromChip }}
            />
          )}
        </Box>
      </Box>
    </>
  );
}
