import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const CardText = styled(Typography)`
  color: #a9a9a9;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

export const PercentInfo = styled.div`
  font-family: Roboto;
  font-size: 32px;
  line-height: 37.5px;
  font-weight: 700;
  color: ${props => props.color};
`;

export const CardGoalInfoText = styled(Typography)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`;
