import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Paper } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';
import {
  CardNoShadowNoBackground,
  LinkModal,
  SmallButton
} from './SectionTabs';
// import formatCPF from '../../../../helpers/utils';
import API from '../../../../services/api';
import TrackingModal from '../../../../layouts/DashboardLayout/Tools/TrackingModal';
import OrdersModal from '../../../../layouts/DashboardLayout/Tools/OrdersModal';
import InvoiceModal from '../../../../layouts/DashboardLayout/Tools/InvoiceModal';

import Bot from '../../../../assets/images/probot.png';

import { Cap } from '../RightMenuComponents/CAP/CAP';
// import ModalLinkComplaint from './ModalLinkComplaint';
import CustomerTabDetails from './CustomerTabDetails';
import PaymentTabDetails from './PaymentTabDetails';
import TabDetails from './TabDetails';
import TabProductSuggestion from './TabProductSuggestion';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      className="customTabs"
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const TicketDetails = ({
  data,
  orderData,
  productInfo,
  ticketData,
  updateAttendantAnswerToBeInputed,
  setOpenLinkModal,
  existRaRelatedToProtocolo,
  setFilterLinkedRaTickets,
  openHistoric,
  setOpenHistoric
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [tabValue, setTabValue] = useState('tabClientDelivery');
  const [openTracking, setOpenTracking] = useState(false);
  const [trackingCode, setTrackingCode] = useState('');
  const [openOrders, setOpenOrders] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [searchByOrder, setSearchByOrder] = useState(false);
  // const [openHistoric, setOpenHistoric] = useState(false);
  const [productSuggestion, setProductSuggestion] = React.useState('');

  const productsLIst = orderData.itens;
  const [vipStatus, setVipStatus] = useState(data.vipClient);
  const [loadingVip, setLoadingVip] = useState(false);

  const aftersaleMlWithoutOrder = !!(
    data.marketPlace === 'MERCADO_LIVRE' &&
    data.ticketType === 'AFTER_SALE' &&
    !orderData.marketplace
  );

  const setVip = (status, vipReason = '') => {
    const postData = {
      cpf: data.customerCPF || orderData.cliente.documento,
      vip: status,
      vipReason: !status ? '' : vipReason
    };
    const TO_BE_DETERMINATED = 'tbd';
    if (!postData.cpf || postData.cpf === TO_BE_DETERMINATED) {
      enqueueSnackbar(t('i18n.ticketslistview.ERRO_CPF_MESSAGE'), {
        variant: 'warning'
      });
      return;
    }
    API.post(`/auth/ms-ticket/v1/vip-client`, postData)
      .then(() => {
        setVipStatus(status);
      })
      .finally(() => {
        setLoadingVip(false);
      });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClickOpenTracking = trackingCodeFromOrigin => {
    setOpenTracking(true);
    setTrackingCode(trackingCodeFromOrigin);
  };

  const handleClickOpenOrders = byOrder => {
    setOpenOrders(true);
    if (byOrder) {
      setSearchByOrder(true);
    } else {
      setSearchByOrder(false);
    }
  };

  const handleClickOpenInvoice = () => {
    setOpenInvoice(true);
  };

  const handleCloseTracking = () => {
    setOpenTracking(false);
  };

  const handleCloseOrders = () => {
    setOpenOrders(false);
  };

  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };

  const handleCloseOpenHistoric = () => {
    if (openHistoric) {
      setOpenHistoric(false);
    } else {
      setOpenHistoric(true);
    }
  };

  const handleHaveReclameAqui = () => {
    if (existRaRelatedToProtocolo) {
      return (
        <LinkModal
          onClick={() => {
            setOpenLinkModal(true);
            setFilterLinkedRaTickets(true);
          }}
        >
          {/* {t('i18n.chatview.VIEW_CHAT')} Reclame Aqui */}
          Reclame Aqui vinculado
        </LinkModal>
      );
    }
  };

  const handleCPFSearch = () => {
    return (
      <SmallButton
        variant="contained"
        color="primary"
        size="small"
        style={{ margin: '0 10px' }}
        onClick={() => handleClickOpenOrders(false)}
      >
        <SearchIcon />
      </SmallButton>
    );
  };

  const handleSearchCode = trackingCodeFromOrigin => {
    return (
      <SmallButton
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {
          handleClickOpenTracking(trackingCodeFromOrigin);
        }}
      >
        <SearchIcon />
      </SmallButton>
    );
  };

  const handleSearchCAP = () => {
    return (
      <Cap
        updateAttendantAnswerToBeInputed={updateAttendantAnswerToBeInputed}
        ticketData={ticketData}
      />
    );
  };

  const handleSearchOrder = () => {
    return (
      <SmallButton
        variant="contained"
        color="primary"
        size="small"
        onClick={() => handleClickOpenOrders(true)}
      >
        <SearchIcon />
      </SmallButton>
    );
  };

  useEffect(() => {
    setProductSuggestion(data?.aiRecomendedProductsList);
  }, []);

  return (
    <>
      <CardNoShadowNoBackground>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            value="tabClientDelivery"
            label={
              data?.ticketType !== 'PRE_SALE'
                ? t('i18n.ticketcontainer.CLIENT_AND_DELIVERY')
                : t('i18n.ticketcontainer.CLIENT_AND_PRODUCT_DETAILS')
            }
          />

          {data?.ticketType !== 'PRE_SALE' && (
            <Tab
              value="tabCustomerPayment"
              label={t('i18n.ticketcontainer.ORDER_AND_PAYMENT')}
            />
          )}

          {data?.ticketType !== 'PRE_SALE' && (
            <Tab value="tabDetails" label={t('i18n.ticketcontainer.DETAILS')} />
          )}

          {data?.ticketType !== 'PRE_SALE' && productSuggestion && (
            <Tab
              value="tabProductSuggestion"
              className="botTabButton"
              label={t('i18n.ticketcontainer.PRODUCT_SUGGESTION')}
              icon={<img src={Bot} alt="robot img" />}
            />
          )}
        </Tabs>
        <TabPanel value={tabValue} index="tabClientDelivery">
          <Paper>
            <CustomerTabDetails
              handleHaveReclameAqui={handleHaveReclameAqui}
              // handleShowCustomerHistoric={handleShowCustomerHistoric}
              handleCloseOpenHistoric={handleCloseOpenHistoric}
              handleCPFSearch={handleCPFSearch}
              handleSearchCode={handleSearchCode}
              handleSearchCAP={handleSearchCAP}
              orderData={orderData}
              dataClient={orderData.cliente}
              data={data}
              productInfo={productInfo}
              vipStatus={vipStatus}
              loadingVip={loadingVip}
              setLoadingVip={setLoadingVip}
              setVip={setVip}
              aftersaleMlWithoutOrder={aftersaleMlWithoutOrder}
            />
          </Paper>
        </TabPanel>

        {data?.ticketType !== 'PRE_SALE' && (
          <TabPanel value={tabValue} index="tabCustomerPayment">
            <Paper>
              <PaymentTabDetails
                handleSearchOrder={handleSearchOrder}
                handleClickOpenInvoice={handleClickOpenInvoice}
                orderData={orderData}
                dataClient={orderData.cliente}
                data={data}
                aftersaleMlWithoutOrder={aftersaleMlWithoutOrder}
              />
            </Paper>
          </TabPanel>
        )}
        <TabPanel value={tabValue} index="tabDetails">
          <Paper style={{ boxShadow: 'none' }}>
            <TabDetails
              productsLIst={productsLIst}
              orderData={orderData}
              productInfo={productInfo}
              data={data}
              aftersaleMlWithoutOrder={aftersaleMlWithoutOrder}
            />
          </Paper>
        </TabPanel>
        <TabPanel value={tabValue} index="tabProductSuggestion">
          <Paper style={{ boxShadow: 'none' }} className="botTab">
            <TabProductSuggestion productSuggestion={productSuggestion} />
          </Paper>
        </TabPanel>
      </CardNoShadowNoBackground>

      {openOrders && (
        <OrdersModal
          open={openOrders}
          onClose={handleCloseOrders}
          data={data}
          orderData={orderData}
          dataClient={orderData.cliente}
          customerCpf={orderData.cliente?.documento || data?.customerCPF}
          customerName={orderData?.cliente?.nome || data?.customerName}
          orderId={orderData?.idPedMarket}
          searchByOrder={searchByOrder}
        />
      )}

      {openTracking && (
        <TrackingModal
          open={openTracking}
          onClose={handleCloseTracking}
          searchCode={trackingCode}
        />
      )}

      {openInvoice && (
        <InvoiceModal
          open={openInvoice}
          onClose={handleCloseInvoice}
          customerCpf={orderData.cliente?.documento || data?.customerCPF}
        />
      )}

      {/* {openHistoric && (
        <ModalCustomerHistoric
          open={openHistoric}
          onClose={handleCloseOpenHistoric}
          customerCpf={orderData.cliente?.documento || data?.customerCPF}
        />
      )} */}
    </>
  );
};

export default TicketDetails;
