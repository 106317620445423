/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
// import { CircularProgress } from '@material-ui/core';

import MonitorConnection from '../MonitorConnection';
import formHelper from '../../../utils/formHelper';
import { ThemeContext } from '../../../layouts/MonitorLayout';
import { CustomTypography } from './card.component';

let monitorWebSocket;
let progressInterval;
let socketConnection;

const MonitorCount = ({ setMonitorData, setIsLoading }) => {
  // const [progress, setProgress] = useState(0);
  const [timeCount, setTimeCount] = useState(0);
  const theme = useContext(ThemeContext);

  const handleStartProgress = () => {
    let count = 1;
    let timerCount = 1;

    // setProgress(count);
    setTimeCount(timerCount);

    clearInterval(progressInterval);
    const timeToRefreshWebsocket = 30;

    progressInterval = setInterval(() => {
      if (count === timeToRefreshWebsocket) {
        count = 1;
        timerCount = 1;
      } else {
        count += 100 / timeToRefreshWebsocket;
        timerCount += 1;
      }
      // setProgress(count);
      setTimeCount(timerCount);
    }, 1000);
  };

  const handleGetMonitorConnection = () => {
    monitorWebSocket = new MonitorConnection();
    monitorWebSocket.send({
      action: 'subscribe',
      channel: `dash-monitor-chats`
    });

    monitorWebSocket.onmessage(event => {
      const response = event;
      response.chats_status.chats_per_clerks = response.chats_status.chats_per_clerks.sort(
        formHelper.compareOrderChats
      );
      setMonitorData(response);
      setIsLoading(false);
      handleStartProgress();
    });
  };

  const handleMakeWebSocketConnection = () => {
    monitorWebSocket.close();
    handleGetMonitorConnection();
  };

  useEffect(() => {
    handleGetMonitorConnection();
    socketConnection = setInterval(() => {
      handleMakeWebSocketConnection();
    }, 7000000);
    return () => {
      clearInterval(progressInterval);
      clearInterval(socketConnection);
      monitorWebSocket.close();
    };
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        gap: '8px',
        width: theme.screen === 'tv' ? '40%' : '30%',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }}
    >
      {/* <CircularProgress variant="determinate" size={30} value={progress} /> */}
      <CustomTypography color={theme.color} font={theme?.font20 || '1rem'}>
        Última atualização há {timeCount} segundos.
      </CustomTypography>
    </div>
  );
};

export { MonitorCount };
