import {
  Box,
  Button,
  Chip,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import InputMask from 'react-input-mask';

import AcerModal from '../../../../components/AcerModal';
import { convertFileToBase64String } from '../../../../helpers/utils';
import {
  GraySectionTitle,
  ItemHeader,
  ItemValue,
  Section,
  StyledButton,
  useStyles
} from './newSupportComponents/styles';
import StyledSelect from './newSupportComponents/StyledSelect';
import DynamicFields from './newSupportComponents/DynamicFields';
import API from '../../../../services/api';

const Required = () => (
  <Typography style={{ color: 'red', lineHeight: '15px', marginLeft: '3px' }}>
    *
  </Typography>
);

export default function B2WMarketplaceNewSupportModal({
  openAddModal,
  setOpenAddModal
}) {
  const [options, setOptions] = useState({});
  const [selectedValues, setSelectedValues] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [description, setDescription] = useState('');
  const [dynamicFields, setDynamicFields] = useState([]);
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const fetchOptions = async id => {
    const { data } = await API.get(
      `/auth/ms-ticket/v1/marketplace-support/b2w/reason-list?idSalesforce=${id ||
        ''}`
    );
    const fetchedData = data.catalogList
      ? data.catalogList.map(item => {
          return {
            value: item.idSalesforce,
            label: item.name,
            classificationId: item.classificationId,
            fieldName: item.fieldName
          };
        })
      : null;
    return fetchedData;
  };

  const fetchDynamicFields = async classificationID => {
    const { data } = await API.get(
      `/auth/ms-ticket/v1/marketplace-support/b2w/reason-fields?classificationId=${classificationID}`
    );
    return data;
  };

  const parseDynamicFields = async (selectedOptions, level) => {
    const optionData = selectedOptions.find(
      option => option.value === selectedValues[level]
    );
    const retrievedFields = await fetchDynamicFields(
      optionData.classificationId
    );
    const filteredFields = retrievedFields.catalogVariables.filter(
      item =>
        item.fieldName !== 'Telefone_parceiro__c' &&
        item.fieldName !== 'Email_parceiro__c' &&
        item.fieldName !== 'Nome_de_contato__c'
    );
    setDynamicFields(filteredFields);
  };

  const onDrop = acceptedFiles => {
    const newFilesState = [...filesToUpload];

    if (newFilesState.length + acceptedFiles.length > 5) {
      enqueueSnackbar('Máximo de 5 arquivos permitidos', {
        variant: 'error'
      });
      return false;
    }

    acceptedFiles.forEach(async element => {
      newFilesState.push({
        path: element.path || element.name,
        name: element.path || element.name,
        key: element.name,
        content: await convertFileToBase64String(element)
      });
      setFilesToUpload(newFilesState);
    });
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    maxFiles: 5
  });

  useEffect(() => {
    onDrop(acceptedFiles);
  }, [acceptedFiles]);

  const files = filesToUpload.map((file, index) => (
    <Chip
      key={Math.random()}
      color="primary"
      className={classes.chip}
      variant="outlined"
      label={file.path}
      onDelete={() => {
        const newFilesState = [...filesToUpload];
        newFilesState.splice(index, 1);
        setFilesToUpload(newFilesState);
      }}
    />
  ));

  const fetchFirstLevelData = async () => {
    const retrievedOptions = await fetchOptions();
    setOptions({ ...options, level1: retrievedOptions });
  };

  const fetchLowerLevelData = async () => {
    if (options.level1 && !options.level2) {
      const retrievedOptions = await fetchOptions(selectedValues.level1);
      if (retrievedOptions) {
        setOptions({ ...options, level2: retrievedOptions });
      } else {
        parseDynamicFields(options.level1, 'level1');
      }
    }

    if (options.level2 && !options.level3) {
      const retrievedOptions = await fetchOptions(selectedValues.level2);
      if (retrievedOptions) {
        setOptions({ ...options, level3: retrievedOptions });
      } else {
        parseDynamicFields(options.level2, 'level2');
      }
    }

    if (options.level3) {
      parseDynamicFields(options.level3, 'level3');
    }
  };

  useEffect(() => {
    if (openAddModal) fetchFirstLevelData();
  }, [openAddModal]);

  useEffect(() => {
    fetchLowerLevelData();
  }, [selectedValues]);

  const validateEmptyFields = () => {
    let empty = 0;

    const serviceCatalogId = options?.level1?.find(
      option => option.value === selectedValues.level1
    );

    if (!serviceCatalogId?.classificationId) {
      empty += 1;
    }

    const emptyDynamicFields = dynamicFields.filter(
      item => item.content === ''
    );

    if (emptyDynamicFields.length > 0) {
      empty += 1;
    }

    if (!contactPhone || !contactEmail || !contactName || !description) {
      empty += 1;
    }

    if (empty > 0) {
      return false;
    }
    return true;
  };

  const cancelNewSupportOpening = () => {
    setOptions({});
    setSelectedValues([]);
    setFilesToUpload([]);
    setDescription('');
    setDynamicFields([]);
    setContactName('');
    setContactEmail('');
    setContactPhone('');
    setOpenAddModal(false);
  };

  const sendSupportRequest = async () => {
    if (!validateEmptyFields()) {
      enqueueSnackbar('Preencha todos os campos', {
        variant: 'error'
      });
      return false;
    }

    const serviceCatalogId = options?.level1?.find(
      option => option.value === selectedValues.level1
    );

    const itemCatalogId = options?.level2?.find(
      option => option.value === selectedValues.level2
    );

    const subItemCatalogId = options?.level3?.find(
      option => option.value === selectedValues.level3
    );

    const json = {
      partnerId: '11068167000453',
      email: 'ec.brasil@acer.com',
      serviceCatalogId: serviceCatalogId?.classificationId || null,
      itemCatalogId: itemCatalogId?.classificationId || null,
      subItemCatalogId: subItemCatalogId?.classificationId || null,
      description,
      variables: [
        { apiFieldName: 'Telefone_parceiro__c', content: contactPhone },
        { apiFieldName: 'Email_parceiro__c', content: contactEmail },
        { apiFieldName: 'Nome_de_contato__c', content: contactName }
      ],
      attachments: filesToUpload
    };

    dynamicFields.forEach(field => {
      json.variables.push({
        apiFieldName: field.fieldName,
        content: field.value
      });
    });

    try {
      await API.post(
        '/auth/ms-ticket/v1/marketplace-support/b2w/create-ticket',
        json
      );
      cancelNewSupportOpening();
    } catch (error) {
      enqueueSnackbar('Erro ao criar chamado.', { variant: 'error' });
    }
  };

  function validatePhoneInput(event) {
    let { value } = event.target;

    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})/, '($1) ');
    const isMobilePhone = /^[(][0-9][0-9][)][\s][9]/.test(value);

    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }

    if (isMobilePhone) {
      event.currentTarget.maxLength = 16;
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d{2})(\d{1})/, '($1) $2.');
      value = value.replace(/(\d{4})/, '$1-');
      value = value.replace(/(\d{4})/, '$1');
    } else {
      event.currentTarget.maxLength = 14;
      value = value.replace(/(\d{4})/, '$1-');
      value = value.replace(/(\d{4})/, '$1');
    }

    event.currentTarget.value = value;
  }

  const handlePhoneMask = e => {
    return validatePhoneInput(e);
  };

  return (
    <AcerModal
      fullWidth
      maxWidth="lg"
      onClose={() => setOpenAddModal(false)}
      open={openAddModal}
      modalTitle="Suporte Marketplace - Abrir Chamado"
      freeBody
    >
      <Section bg>
        <Grid container>
          <Grid item xs={2}>
            <ItemHeader>
              Qual o marketplace?
              <Required />
            </ItemHeader>
            <ItemValue>Americanas</ItemValue>
          </Grid>
          <Grid item xs={10}>
            <ItemHeader>
              Motivo do Suporte:
              <Required />
            </ItemHeader>
            <Grid container>
              <Grid item xs={4}>
                {options?.level1 && (
                  <StyledSelect
                    value={selectedValues.level1}
                    level="level1"
                    {...{
                      selectedValues,
                      setSelectedValues,
                      options,
                      setOptions
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {options?.level2 && (
                  <StyledSelect
                    value={selectedValues.level2}
                    level="level2"
                    {...{
                      selectedValues,
                      setSelectedValues,
                      options,
                      setOptions
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {options?.level3 && (
                  <StyledSelect
                    value={selectedValues.level3}
                    level="level3"
                    {...{
                      selectedValues,
                      setSelectedValues,
                      options,
                      setOptions
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>
      <Section>
        <DynamicFields {...{ dynamicFields, setDynamicFields }} />
      </Section>
      <Section bg>
        <Grid container>
          <Grid item xs={7}>
            <ItemHeader>
              Descreva o suporte que você precisa:
              <Required />
            </ItemHeader>

            <TextField
              multiline
              minRows={4}
              maxRows={4}
              variant="outlined"
              value={description}
              onChange={e => setDescription(e.target.value)}
              className={classes.inputTextArea}
            />
          </Grid>
          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <StyledButton
                variant="contained"
                color="primary"
                disabled={filesToUpload.length >= 5}
                {...getRootProps({ className: 'dropzone' })}
                size="small"
              >
                <AttachFile />
              </StyledButton>
              <ItemHeader>Envio de arquivo (até 5 arquivos):</ItemHeader>
            </Box>
            <Box my={2}>
              <input {...getInputProps()} />
              {files.length > 0 && <> {files} </>}
            </Box>
          </Grid>
        </Grid>
      </Section>
      <Section>
        <GraySectionTitle variant="h4">
          Dados para o Marketplace entrar em contato
        </GraySectionTitle>
        <Grid container>
          <Grid item xs={8}>
            <ItemHeader>
              Qual é o seu nome:
              <Required />
            </ItemHeader>
            <TextField
              variant="outlined"
              defaultValue={contactName}
              onChange={e => {
                setContactName(e.target.value);
              }}
              className={classes.inputSelect}
            />
          </Grid>

          <Grid item xs={4}>
            <ItemHeader>
              Telefone para contato:
              <Required />
            </ItemHeader>
            <InputMask
              onKeyPress={handlePhoneMask}
              variant="outlined"
              defaultValue={contactPhone}
              mask="(99) 99999-9999"
              onChange={e => {
                setContactPhone(e.target.value);
              }}
            >
              {inputProps => (
                <TextField {...inputProps} className={classes.inputSelect} />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={8}>
            <ItemHeader>
              Para qual e-mail o marketplace deve retornar?
              <Required />
            </ItemHeader>
            <TextField
              variant="outlined"
              value={contactEmail}
              onChange={e => {
                setContactEmail(e.target.value);
              }}
              onBlur={e => {
                const regExp = /.+@.+\.[A-Za-z]+$/;
                if (!regExp.test(e.target.value)) {
                  setContactEmail('');
                  enqueueSnackbar('E-mail inválido!', {
                    variant: 'error'
                  });
                  return false;
                }
              }}
              className={classes.inputSelect}
            />
          </Grid>
        </Grid>
      </Section>
      <Section>
        <Box display="flex" justifyContent="space-between">
          <Button
            variant="contained"
            color="default"
            className={classes.buttonNoDecoration}
            onClick={cancelNewSupportOpening}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonNoDecoration}
            onClick={sendSupportRequest}
          >
            Enviar
          </Button>
        </Box>
      </Section>
    </AcerModal>
  );
}
