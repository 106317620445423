import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
  makeStyles
} from '@material-ui/core';

/**
 * General Selects to forms
 * @param {String} label - String - Name of the checkbox group
 * @param {String} Name - String - Name of the field
 * @param {Object} form - Object - State of form that recieve the checkbox values
 * @param {Function} setForm - Function - Open controller
 * @param {Array} options - Array - List of the options
 */

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectMenu: {
    paddingTop: 1,
    paddingBottom: 1
  },
  subheader: {
    color: '#000',
    margin: '-9px'
  }
}));

const SelectGroupComponent = ({
  form,
  setForm,
  name,
  label,
  options,
  required,
  disabled
}) => {
  const classes = useStyles();

  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <InputLabel htmlFor="grouped-select">{label}</InputLabel>
      <Select
        size="small"
        id="select-group-component"
        label={label}
        className="small-input"
        value={form[name] || ''}
        name={name}
        disabled={disabled}
        onChange={(e) => {
          setForm(e);
        }}
        required={required}
      >
        {options?.map((optionGroup) => [
          <ListSubheader
            key={optionGroup?.name}
            classes={{ root: classes.subheader }}
          >
            {optionGroup?.label}
          </ListSubheader>,
          ...optionGroup?.itens?.map((option) => (
            <MenuItem
              key={option?.name}
              value={option?.name}
              className={classes.selectMenu}
              style={{margingTop: '8px', marginBottom: '8px', paddingTop: '4px', paddingBottom: '4px'}}
            >
              {option?.name}
            </MenuItem>
          ))
        ])}
      </Select>
    </FormControl>
  );
};

export default SelectGroupComponent;
