import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import AcerModal from '../../../components/AcerModal';
import Page from '../../../components/Page';
import API from '../../../services/api';
import OrdersDetails from './details/OrderDetails';
import { CustomDataTable } from '../../../styles/components/CustomDataTable';
import CustomFooter from '../../../styles/components/CustomFooter';
import formHelper from '../../../utils/formHelper';
import { COLORS } from '../../../styles/settings/colors.config';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  table: {
    minWidth: 650,
  },
  tableTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    width: '290px',
  },
});

const OrdersModal = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    onClose,
    open,
    customerCpf,
    orderId,
    searchByOrder,
    customerName,
  } = props;
  const [page, setPage] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [openDetails, setOpenDetails] = useState(false);
  const [pedido, setPedido] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState(customerCpf || '');
  const [orderDocument, setOrderDocument] = useState('');
  const [nome, setNome] = useState(customerName || '');
  const [nPedido, setNpedido] = useState(orderId || '');
  const [load, setLoad] = useState(true);
  const [maskCpfCnpj, setMaskCpfCnpj] = useState('999.999.999-999');

  const columns = [
    {
      name: 'idPedMarket',
      label: t('i18n.orderinfo.ORDER'),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'idPedEcom',
      label: 'PEDECOM',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'documento',
      label: t('i18n.orderview.DOCUMENT'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return formHelper.cpfCnpjFormat(value);
        },
      },
    },
    {
      name: 'nome',
      label: t('i18n.chatview.CLIENT_MESSAGE'),
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const handleClose = () => {
    setLoad(true);
    setPage([]);
    setNpedido(orderId || '');
    setNome(customerName || '');
    setCpfCnpj(customerCpf || '');
    setCurrentPage(0);
    onClose(false);
  };

  const handleCpfCnpjMask = value => {
    value = value.replace(/\D/g, '');
    if (value.length > 11) {
      setMaskCpfCnpj('99.999.999/9999-99');
    } else if (value.length <= 11) {
      setMaskCpfCnpj('999.999.999-99');
    }
  };

  const getLinkSearch = () => {
    let url = '';
    if (nome) {
      url += `&Nome=${nome}`;
    }
    if (cpfCnpj) {
      const onlyNumbers = cpfCnpj.replace(/\D/g, '');
      url += `&Documento=${onlyNumbers}`;
    }
    if (nPedido) {
      url += `&Id=${nPedido}`;
    }
    return url;
  };

  const getOrdersData = async (path, turn = 0) => {
    let url = `/auth/ms-ticket/v1/pedidos?pageSize=10`;
    if (!path) {
      return;
    }
    url += path;
    setLoad(false);
    API.get(url)
      .then(response => {
        setPage(response.data);
        const orders = response.data.data;
        if (!nPedido.substring(0, 3).includes('-')) {
          turn = 1;
        }
        if (response.status === 200 && orders.length === 0 && turn === 0) {
          getOrdersData(
            `${
              path.split('&Id=')[0]
            }&Id=${formHelper.convertOrderidtoDeliveryid(nPedido)}`,
            1,
          );
        }
        if (response.status === 200 && orders.length === 0 && turn === 1) {
          if (
            (nPedido && cpfCnpj) ||
            (nPedido && nome) ||
            (cpfCnpj && nome) ||
            (nPedido && cpfCnpj && nome)
          ) {
            enqueueSnackbar(t('i18n.orderinfo.NOT_FOUND_SEARCH'), {
              variant: 'error',
            });
          } else if (path.search('Id') >= 0) {
            enqueueSnackbar(t('i18n.orderinfo.NOT_FOUND_ORDER'), {
              variant: 'error',
            });
          } else if (path.search('Documento') >= 0) {
            if (path.length <= 22) {
              enqueueSnackbar(t('i18n.orderinfo.NOT_FOUND_CPF'), {
                variant: 'error',
              });
            } else {
              enqueueSnackbar(t('i18n.orderinfo.NOT_FOUND_CNPJ'), {
                variant: 'error',
              });
            }
          } else if (path.search('Nome') >= 0) {
            enqueueSnackbar(t('i18n.orderinfo.NOT_FOUND_NAME'), {
              variant: 'error',
            });
          }
        }
      })
      .catch(() => {
        enqueueSnackbar(
          `${t('i18n.simplephrases.ERROR')} - API Pedidos/Orders`,
          {
            variant: 'error',
          },
        );
      })
      .finally(() => {
        setLoad(true);
      });
  };

  const search = () => {
    setPage([]);
    setCurrentPage(0);
    const url = getLinkSearch();
    getOrdersData(url);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
    onClose(false);
    setPedido('');
  };

  useEffect(() => {
    setNpedido(searchByOrder ? orderId : '');
  }, [searchByOrder]);

  const refreshTableContent = (pageNumber, rowsPerPage) => {
    setCurrentPage(pageNumber);
    let url = `/auth/ms-ticket/v1/pedidos?page=${pageNumber +
      1}&pageSize=${rowsPerPage}`;
    const statusSerach = getLinkSearch();
    url += statusSerach;
    setLoad(false);
    API.get(url)
      .then(response => {
        setLoad(true);
        setPage(response.data);
      })
      .catch(() => {
        setLoad(true);
        enqueueSnackbar(t('i18n.trackingmodal.ERROR_GETTING_INFO'), {
          variant: 'error',
        });
      });
  };

  const findRow = (idPedMarket, data) => {
    const len = data.length;
    for (let i = 0; i < len; i++) {
      if (data[i].idPedMarket === idPedMarket) {
        return data[i];
      }
    }
  };
  const options = {
    selectableRows: false,
    serverSide: true,
    pagination: true,
    download: false,
    print: false,
    filter: false,
    search: false,
    viewColumns: false,
    rowsPerPage: 30,
    rowsPerPageOptions: [],
    count: page?.totalPages === 1 ? page?.data?.length : page?.totalPages * 30,
    page: currentPage,
    customToolbar: () => {},
    onRowClick: rowData => {
      const row = findRow(rowData[0], page.data);
      if (row?.marketplace === 'Acer') {
        setPedido(row?.idMarketplace);
      } else {
        setPedido(row?.idPedMarket);
      }
      setOrderDocument(row?.documento);
      setOpenDetails(true);
    },
    customFooter: (
      count,
      pages,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={pages}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          rowsPerPageOptions={[30]}
          callback={(pageNumber, rowsPage) => {
            refreshTableContent(pageNumber, rowsPage);
          }}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: '---',
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: column =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`,
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA'),
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS'),
      },
    },
  };

  return (
    <AcerModal
      open={open}
      fullWidth
      maxWidth="md"
      modalTitle={t('i18n.ordersmodal.ORDERS')}
      onClose={handleClose}
      scroll="body"
    >
      <>
        <Page title="Orders">
          <Container>
            <Box marginBottom={3}>
              <form className={classes.root} noValidate autoComplete="off">
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <TextField
                      label={t('i18n.ordersmodal.ORDER_CODE')}
                      value={nPedido}
                      size="small"
                      variant="outlined"
                      onChange={event => {
                        setNpedido(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputMask
                      mask={maskCpfCnpj}
                      maskChar=""
                      onPaste={e =>
                        handleCpfCnpjMask(e.clipboardData.getData('Text'))
                      }
                      value={cpfCnpj}
                      onChange={event => {
                        setCpfCnpj(event.target.value);
                        if (event.target.value.length > 14) {
                          setMaskCpfCnpj('99.999.999/9999-99');
                        } else {
                          setMaskCpfCnpj('999.999.999-999');
                        }
                      }}
                    >
                      {() => (
                        <TextField
                          label={t('i18n.ordersmodal.ORDER_DOCUMENT')}
                          size="small"
                          variant="outlined"
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label={t('i18n.ordersmodal.ORDER_CLIENT')}
                      value={nome}
                      size="small"
                      variant="outlined"
                      onChange={event => {
                        setNome(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Button
                        fullWidth
                        onClick={search}
                        variant="contained"
                        size="large"
                        color="primary"
                        disabled={!load}
                      >
                        {!load
                          ? t('i18n.simplewords.LOADING')
                          : t('i18n.trackingmodal.GET_INFORMATION')}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Box mt={1}>
                      <Typography
                        style={{
                          color: COLORS.WarningColorSLA,
                          fontWeight: 'semibold',
                          fontSize: '14px',
                        }}
                      >
                        Devido a integração com o Marketplace, pode levar alguns
                        minutos para um pedido novo aparecer no resultado
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Container>
          <Container maxWidth={false}>
            <Box marginY={3}>
              {!load && (
                <Box
                  marginY={3}
                  display="flex"
                  justifyContent="center"
                  flexDirection="row"
                >
                  <CircularProgress />
                </Box>
              )}
              {load && page?.data?.length > 0 && (
                <CustomDataTable
                  // title={t('i18n.ordersmodal.ORDERS_TITLE')}
                  title={
                    <div className={classes.tableTitle}>
                      <Typography variant="h6">
                        {t('i18n.ordersmodal.ORDERS_TITLE')}
                      </Typography>
                      <Typography variant="caption">
                        ({t('i18n.simplephrases.CLICK_DETAIL')})
                      </Typography>
                    </div>
                  }
                  data={page.data}
                  columns={columns}
                  selectableRowsOnClick
                  options={options}
                  className="table-clickable"
                />
              )}
            </Box>
          </Container>
        </Page>
        <OrdersDetails
          open={openDetails}
          orderId={pedido}
          onClose={handleCloseDetails}
          orderDocument={orderDocument}
          
        />
      </>
    </AcerModal>
  );
};

OrdersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default OrdersModal;
