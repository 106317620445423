import {
  Box,
  makeStyles,
  Breadcrumbs,
  Divider,
  CircularProgress
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import moment from 'moment';
import tableHelper from '../../../../../../utils/tableHelper';
import { CustomDataTable } from '../../../../../../styles/components/CustomDataTable';
import userService from '../../../../../../services/user.service';
import CustomFooter from '../../../../../../styles/components/CustomFooter';
import config from '../../../../../../config';
import { COLORS } from '../../../../../../styles/settings/colors.config';
import Page from '../../../../../../components/Page';
import API from '../../../../../../services/api';
import { StyledSpinner } from '../../../../../../styles/components/SpinnerDefault';
import ChipSection from '../Components/Global/ChipSection';
import CustomRow from './Components/CustomRow';
import MainDrawer from '../../Filter/MainDrawer';
import HeaderTitle from '../../../../../../components/HeaderTitle';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  breadcrumbs: {
    marginLeft: '15px'
  },
  divider: {
    width: '90%'
  }
}));

const statusList = [
  {
    value: 'moving',
    label: 'Aguardando Chegada na Acer'
  },
  { value: 'waiting', label: 'Aguardando Logística' },
  { value: 'finished', label: 'Parecer emitido pela logística' }
];

const CustomBreadCrumbs = styled(Breadcrumbs)`
  .MuiTypography-h6 {
    font-size: 20px;
    font-weight: 400;
    color: ${COLORS.blackText};
  }
`;

const MainDivider = () => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="center" width="100%" mb={2}>
      <Divider className={classes.divider} />
    </Box>
  );
};

const columns = [
  { name: 'nf', label: 'Nota Fiscal Origem' },
  { name: 'dateReceiptAcer', label: 'Chegada na Acer' },
  { name: 'capNumber', label: 'SLA' },
  { name: 'capStatus', label: 'Status CAP' },
  { name: 'trackingNumber', label: 'Rastreio Devolução' },
  { name: 'statusDevolucao', label: 'Status da Devolução' },
  { name: 'motivoDevolucao', label: 'Motivo da Devolução' },
  { name: 'solicitacaoDevolucao', label: 'Solicitação da Devolução' },
  { name: 'conectaTicketId', label: 'Protocolo Conecta' }
];

const Logistics = () => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [items, setItems] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [, setNumberOfElements] = useState(0);
  const [, setTotalPages] = useState(1);
  const { t } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [listening, setListening] = useState(false);
  const [status, setStatus] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const logisticsStatus = steps => {
    if (steps.find(s => s.name === 'Parecer emitido pela logística'))
      return 'Parecer emitido pela logística';
    if (steps.find(s => s.name === 'Aguardando Logística'))
      return 'Aguardando Logística';
    return 'Aguardando Chegada na Acer';
  };

  const getInfos = async searchJSON => {
    setIsLoading(true);

    const result = await API.get(
      `/auth/ms-ticket/v1/cancellationProcess/findFilteredLogistics`,
      { params: searchJSON }
    );
    const { data } = result;

    const tableData = data?.content?.map(item => {
      return {
        id: item?.id,
        nf: item?.order?.numeroNF,
        dateReceiptAcer: item?.dateReceiptAcer,
        capNumber: item?.reverseLogisticsEntity?.numeroColeta,
        capStatus: item?.reverseLogisticsEntity?.statusObjeto,
        trackingNumber: item?.trackingNumber,
        statusDevolucao: logisticsStatus(item?.stepsTimeLine),
        motivoDevolucao: item?.reasonCancellation,
        solicitacaoDevolucao: moment(item?.stepsTimeLine[0]?.date).format(
          'DD/MM/YYYY'
        ),
        conectaTicketId: item?.conectaTicketId
      };
    });

    setItems(tableData);
    setCurrentPage(data.number);
    setTotalElements(data.totalElements);
    setNumberOfElements(data.numberOfElements);
    setTotalPages(data.totalPages);
    setPageSize(data.size);

    setIsLoading(false);
  };

  const refreshTableContent = page => {
    const searchJSON = {
      page: page || 0,
      size: pageSize,
      isReviewedByLogistics: status === 'finished',
      isProductInPossessionAcer: true
    };
    getInfos(searchJSON);
  };

  const options = {
    filter: false,
    pagination: true,
    filterType: 'checkbox',
    viewColumns: false,
    selectableRows: 'none',
    print: false,
    search: false,
    rowsPerPage: pageSize,
    rowsPerPageOptions: [20],
    count: totalElements,
    serverSide: true,
    download: false,
    page: currentPage,
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        if (currentPage !== tableState.page)
          refreshTableContent(tableState.page);
      }
      if (action === 'sort') {
        // setDefaultSortChanged(true);
        // sortTableData(tableState);
        setCurrentPage(0);
      }
    },
    customRowRender: (data, index) => {
      const currentItem = items[index];
      return <CustomRow {...{ data, currentItem }} key={Math.random()} />;
    },
    setRowProps: (row, index) => {
      tableHelper.setRowStyle(row, index, items);
    },
    // onRowSelectionChange,
    onCellClick: (colData, cellMeta) => {
      if (cellMeta.colIndex === 0) {
        if (!userService.hasRole('ticket_detail')) {
          enqueueSnackbar(t('i18n.ticketslistview.DETAIL_DISABLED'), {
            variant: 'warning'
          });
        }
        return;
      }
      if (userService.hasRole('ticket_detail')) {
        const { id } = items[cellMeta.rowIndex];
        history.push({
          pathname: `/logistics-details/${id}`
        });
      } else {
        enqueueSnackbar(t('i18n.ticketslistview.DETAIL_DISABLED'), {
          variant: 'warning'
        });
      }
    },

    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          rowsPerPageOptions={[20]}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: !isLoading
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: column =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };

  useEffect(() => {
    let searchJSON;

    if (localStorage.getItem('cancellationFilter')) {
      const loadedFilter = JSON.parse(
        localStorage.getItem('cancellationFilter')
      );
      setSearch(loadedFilter.nf);
      setStatus(loadedFilter.status);

      searchJSON = { ...loadedFilter, page: 0 };
    } else {
      setStatus('waiting');
      searchJSON = {
        page: 0,
        size: pageSize,
        isReviewedByLogistics: status === 'finished',
        isProductInPossessionAcer: true,
        status: 'waiting'
      };
    }

    localStorage.setItem('cancellationFilter', JSON.stringify(searchJSON));
    getInfos(searchJSON);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const url = config.websocket.logisticsMessages;

    if (!listening) {
      const socket = token && new WebSocket(`${url}?authorization=${token}`);

      socket.onmessage = async event => {
        setMessages([...messages, event.data]);
      };

      setListening(true);
    }
  }, []);

  const returnPossession = (newStatus = null) => {
    if (newStatus === ' ') return false;
    if (status === 'moving') return false;
    if (status === 'waiting') return true;
    return true;
  };

  const handleSearch = async (newSearch = null, newStatus = null) => {
    const searchJSON = {
      page: currentPage,
      size: pageSize,
      nf: newSearch,
      status: newStatus
    };

    if (newStatus !== ' ' && newStatus !== '') {
      searchJSON.isReviewedByLogistics = newStatus === 'finished';
      searchJSON.isProductInPossessionAcer = returnPossession(newStatus);
    }

    localStorage.setItem('cancellationFilter', JSON.stringify(searchJSON));

    await getInfos(searchJSON);
  };

  const handleReload = async () => {
    setStatus('waiting');
    setSearch('');

    const searchJSON = {
      page: 0,
      size: pageSize,
      nf: search,
      status
    };

    if (status !== '') {
      searchJSON.isProductInPossessionAcer = returnPossession();
      searchJSON.isReviewedByLogistics = status === 'finished';
    }

    await getInfos(searchJSON);
  };

  const removeFilterFromChip = async type => {
    if (type === 'search') {
      setSearch('');
      handleSearch(' ', status);
    }

    if (type === 'status') {
      setStatus('');
      handleSearch(search, ' ');
    }
  };

  return (
    <Page className={classes.root} title="Logística">
      <MainDrawer {...{ search, setSearch, status, setStatus, handleSearch }} />
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        paddingX={10}
      >
        <CustomBreadCrumbs
          className={classes.breadcrumbs}
          gutterBottom
          aria-label="breadcrumb"
        >
          <HeaderTitle title="Logística" />
        </CustomBreadCrumbs>

        {(!!search || (!!status && status !== ' ')) && (
          <ChipSection
            {...{ search, status, removeFilterFromChip, statusList }}
          />
        )}
      </Box>
      <MainDivider />
      <Box style={{ cursor: 'pointer', padding: '0 70px' }}>
        {messages.length > 0 && (
          <Alert variant="filled" severity="error" onClick={handleReload}>
            Novas chegadas de produtos foram registradas! Clique aqui para
            atualizar.
          </Alert>
        )}
      </Box>
      {isLoading && (
        <StyledSpinner style={{ display: isLoading ? 'block' : 'none' }}>
          <CircularProgress />
        </StyledSpinner>
      )}

      {!isLoading && (
        <Box paddingX={10}>
          <CustomDataTable
            data={items}
            columns={columns}
            options={options}
            selectableRowsOnClick={!!userService.hasRole('ticket_detail')}
            className="table-clickable"
          />
        </Box>
      )}
    </Page>
  );
};

export default Logistics;
