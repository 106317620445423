/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import API from '../../../../services/api';
import { COLORS } from '../../../../styles/settings/colors.config';
import { CustomChip } from '../../../../styles/components/Tags';

const StyledTagField = styled(TextField)`
  .MuiInput-underline:before {
    border-bottom: 0;
  }
  .MuiInput-underline:after {
    border-bottom: 0;
  }
  .MuiInput-underline:hover {
    border-bottom: 0;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0;
  }
  .MuiInputBase-input::placeholder {
    color: ${COLORS.greenText};
    opacity: 1;
  }
  .MuiAutocomplete-inputRoot[class*='MuiInput-root'][class*='MuiInput-marginDense']
    .MuiAutocomplete-input {
    cursor: pointer;
  }
  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    width: auto;
  }
`;

const StyledAutoComplete = styled(Autocomplete)`
  .MuiAutocomplete-endAdornment {
    display: none;
  }
`;

const StyledAutoCompleteBox = styled(Box)`
  width: 65vw;
`;

const Tags = ({ ticketData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [tagOptions, setTagOptions] = useState([]);
  const [selectedTagOptions, setSelectedTagOptions] = useState([]);
  const backUrl = `/auth/lambda/ticket-tag`;

  const refreshTagsContent = () => {
    API.get(backUrl).then(response => {
      if (response.data.length > 0) {
        setTagOptions(response.data);
      }
    });
  };

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!loaded) {
      refreshTagsContent();
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (tagOptions.length > 0) {
      const ticketTags = ticketData?.tags || [];
      const selectedOptions = tagOptions.filter(item =>
        ticketTags.includes(item.tag_name)
      );
      setSelectedTagOptions(selectedOptions);
    }
  }, [tagOptions]);

  const getAlreadySelectedIds = () => {
    const alreadySelectedIds = selectedTagOptions.map(selectedItem => {
      return selectedItem?._id?.$oid;
    });
    return alreadySelectedIds;
  };

  const addTag = value => {
    const alreadySelectedIds = getAlreadySelectedIds();
    value.map(item => {
      const itemId = item?._id?.$oid;
      if (!alreadySelectedIds.includes(itemId)) {
        API.post(`${backUrl}/add`, {
          ticket_id: ticketData.id,
          tag_id: itemId
        })
          .then(() => {
            setSelectedTagOptions(value);
          })
          .catch(() => {
            enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
              variant: 'error'
            });
          });
      }
      return item;
    });
  };

  const removeTag = (value, option) => {
    const filteredOptions = value.filter(item => {
      return item?._id?.$oid !== option?._id?.$oid;
    });

    try {
      API.post(`${backUrl}/remove`, {
        ticket_id: ticketData.id,
        tag_name: option.tag_name
      }).then(() => {
        setSelectedTagOptions(filteredOptions);
      });
    } catch {
      enqueueSnackbar(t('i18n.simplephrases.NOT_AUTHORIZED'), {
        variant: 'error'
      });
    }
  };

  return (
    <StyledAutoCompleteBox mb={2}>
      <StyledAutoComplete
        fullWidth={false}
        multiple
        onChange={(event, value, reason) => {
          if (reason === 'select-option') {
            addTag(value);
          }
          // if (reason === 'remove-option') {
          //   removeTag(value);
          // }
        }}
        noOptionsText="-"
        disableClearable
        size="small"
        options={tagOptions}
        getOptionLabel={option => option.tag_name}
        defaultValue={[]}
        value={selectedTagOptions}
        renderTags={value =>
          value.map((option, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <CustomChip key={index}>
              <p>{option.tag_name}</p>
              <button type="button" onClick={() => removeTag(value, option)}>
                x
              </button>
            </CustomChip>
          ))
        }
        renderInput={params => (
          <StyledTagField {...params} variant="standard" placeholder="+ Tag" />
        )}
      />
    </StyledAutoCompleteBox>
  );
};

export default Tags;
