import {
  Box,
  Grid,
  Card,
  Paper,
  Button,
  Tooltip,
  TextField,
  Typography
} from '@material-ui/core';
import styled from 'styled-components';
import { COLORS } from '../../../styles/settings/colors.config';

const StyledPaper = styled(Paper)`
  max-height: 370px;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 10px;
    background-color: ${COLORS.btnSecondaryColor};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.divider};
    border: 2px solid ${COLORS.divider};
  }
`;

const Divider = styled.div`
  .container {
    display: flex;
    align-items: center;
    margin: 5px 0 5px 0;
  }

  .border {
    border-bottom: 1px solid black;
    width: 100%;
  }

  .content {
    padding: 0 10px 0 10px;
  }
`;

const StyledTooltip = styled(Tooltip)`
  margin-right: 2px;
`;

const StyledButton = styled(Button)`
  padding: 2px 5px !important;
  font-size: 0.8125rem;
  min-width: inherit;
`;

const CustomTextField = styled(TextField)`
  background: #fff;
  p {
    background: #f4f6f8;
    margin: 0;
    padding: 4px 14px 0px 14px;
  }
`;

const StyledTextarea = styled.div`
  align-items: flex-end;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  > div {
    width: 100%;
  }
  button {
    margin-left: auto;
  }
`;

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const StyledDivider = styled.div`
  .container {
    display: flex;
    align-items: center;
    margin: 5px 0 5px 0;
  }

  .border {
    border-bottom: 1px solid black;
    width: 100%;
  }

  .content {
    padding: 0 10px 0 10px;
  }
`;

const StyledMessage = styled.div`
  display: flex;
  flex-flow: column wrap;

  p {
    width: 100%;
  }

  .message-score {
    text-align: right;
  }

  .message-emoji {
    cursor: help;
  }

  .message-link {
    cursor: pointer;
  }
`;

const StyledLink = styled.a`
  font-family: Roboto;
`;

const FileContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${COLORS.greenText};
  }
`;

const RobotCardStyled = styled(Card)`
  border: 1px solid ${COLORS.btnDangerColor};

  .robot-bg {
    background-color: #ffffff;
    display: flex;
    flex-flow: row nowrap;
    padding: 0;
  }

  .message-footer {
    margin: 8px 0 0;
  }

  .message-body {
    padding: 16px 16px 24px;
    width: 90%;

    &--header {
      color: ${COLORS.btnDangerColor};
      margin-bottom: 8px;
    }
  }

  .message-actions {
    align-items: center;
    display: flex;
    flex-flow: column wrap;
    margin-right: 8px;
    padding: 4px 0;
    width: 10%;

    &--robot {
      margin: 8px 0 12px;
      height: 20px;
      width: 20px;
    }

    &--action {
      cursor: pointer;
    }

    .button {
      padding: 0px;
    }

    .action-color {
      color: ${COLORS.btnSuccessColor};
    }

    .action-nocolor {
      color: #aaa;
    }

    .action-refuse {
      color: ${COLORS.btnDangerColor};
    }

    svg {
      margin: 8px 0;
      width: 20px;
    }
  }
`;

const TypoBlockMessage = styled(Typography)`
  font-size: 13px;
  color: ${COLORS.btnDangerColor};
  margin-top: 10px;
`;

const GridActions = styled(Grid)`
  margin-right: 9px;
  margin-left: 9px;
`;

const GridActionsMacroAttachments = styled(Grid)`
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const GridActionsButtonAttachments = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
`;

const MessagesIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: #fff2cc;
  margin-left: -10px;
  margin-top: -23px;
  border-radius: 50px;
  height: 15px;
  width: 15px;
  font-size: 11px;
  color: black;
`;

export {
  StyledPaper,
  Divider,
  StyledTooltip,
  StyledButton,
  CustomTextField,
  StyledTextarea,
  StyledBox,
  StyledHeader,
  StyledDivider,
  StyledMessage,
  StyledLink,
  FileContainer,
  RobotCardStyled,
  TypoBlockMessage,
  GridActions,
  GridActionsMacroAttachments,
  GridActionsButtonAttachments,
  MessagesIndicator
};
