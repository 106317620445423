import { Box, Card, Divider, Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { CheckCircle } from '@material-ui/icons';
import moment from 'moment';

import { COLORS } from '../../../../../styles/settings/colors.config';
import { CardTitle, CustomTypograph } from '../../../../dashboardV2/styles';

const Connector = styled.div`
  height: 50%;
  background-color: ${props => props.bgColor};
  min-width: 5px;
  position: relative;
  z-index: 10;
  top: ${props => props.top};
`;

const HistoryCard = ({ selectedSupport }) => {
  const { commentHistory } = selectedSupport;
  return (
    <Card style={{ width: '100%', borderRadius: '20px' }}>
      <CardTitle>
        <CustomTypograph variant="h5">Histórico</CustomTypograph>
      </CardTitle>
      <Divider />
      <Box
        display="flex"
        flexDirection="column"
        padding="10px"
        maxHeight="150px"
        overflow="auto"
      >
        {commentHistory.map((comment, idx) => {
          return (
            <Grid container key={Math.random()}>
              <Grid
                item
                xs={2}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                <Connector bgColor={idx === 0 ? 'white' : 'grey'} top={0} />
                <CheckCircle
                  style={{
                    color: idx === 0 ? COLORS.greenText : COLORS.meduimGray
                  }}
                />
                <Connector bgColor="grey" top={0} />
              </Grid>
              <Grid item xs={10} style={{ padding: '10px 0' }}>
                <span
                  style={{
                    fontWeight: idx === 0 ? 'bold' : '',
                    fontSize: '12px',
                    color: idx === 0 ? COLORS.greenText : COLORS.meduimGray
                  }}
                >
                  {`${comment.commentOwner} respondeu o chamado em
                ${moment(comment.createdDate).format('DD/MM/YYYY HH:mm')}`}
                </span>
              </Grid>
            </Grid>
          );
        })}

        <Grid container>
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <Connector bgColor="grey" top={0} />
            <CheckCircle
              style={{
                color:
                  commentHistory.length === 0
                    ? COLORS.greenText
                    : COLORS.meduimGray
              }}
            />
            <Connector bgColor="white" top={0} />
          </Grid>
          <Grid item xs={10}>
            <span
              style={{
                fontWeight: commentHistory.length === 0 ? 'bold' : '',
                fontSize: '12px',
                padding: '10px 0',
                color:
                  commentHistory.length === 0
                    ? COLORS.greenText
                    : COLORS.meduimGray
              }}
            >
              Chamado aberto em{' '}
              {moment(selectedSupport.creationDate).format('DD/MM/YYYY HH:mm')}
            </span>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default HistoryCard;
