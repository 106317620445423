import { Divider, ListItemIcon, makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FilterListIcon from '@material-ui/icons/FilterList';
import clsx from 'clsx';
import React, { useState } from 'react';
import Container from './Container';
import { StyledDrawer, StyledFilterContainer } from './Styles/StyledComponents';

const drawerWidth = 560;

const drawerUseStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  hide: {
    display: 'none'
  },
  drawer: {
    position: 'absolute',
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '60px'
  },
  paper: {
    paddingTop: '60px',
    zIndex: '200'
  }
}));

const MainDrawer = ({
  search,
  setSearch,
  status,
  setStatus,
  handleSearch,
  filterType
}) => {
  const drawerClasses = drawerUseStyles();

  const [open, setOpen] = useState(false);
  return (
    <StyledDrawer
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      variant="permanent"
      className={clsx(drawerClasses.drawer, {
        [drawerClasses.drawerOpen]: open,
        [drawerClasses.drawerClose]: !open
      })}
      classes={{
        paper: clsx({
          [drawerClasses.drawerOpen]: open,
          [drawerClasses.drawerClose]: !open,
          [drawerClasses.paper]: drawerClasses.paper
        })
      }}
      PaperProps={{
        style: { marginLeft: '122px !important' }
      }}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
    >
      <List dense>
        <ListItem>
          <ListItemIcon>
            <FilterListIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Filtros" />
        </ListItem>
        <Divider />
      </List>

      <StyledFilterContainer>
        <Container
          {...{
            search,
            setSearch,
            status,
            setStatus,
            handleSearch,
            filterType
          }}
        />
      </StyledFilterContainer>
    </StyledDrawer>
  );
};

export default MainDrawer;
