/* eslint-disable react/no-array-index-key */
import { Grid, Typography, Card } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Loader from '../../../components/Loader';
import timezoneFormat from '../../../utils/timezoneFormat';
import {
  CustomDataTableWrapper,
  CustonVisitorsDatatable
} from '../../../styles/components/CustomDataTable';
// import './styles.css';
import ClosedChatsFilter from './components/ClosedChatsFilter.component';
import CustomFooter from '../../../styles/components/CustomFooter';
import formHelper from '../../../utils/formHelper';
import API from '../../../services/api';

import { searchB2bOnCurrentUrl } from '../../../utils/b2bHelper';

const NewChatHistoricChats = ({
  clientInAttendance,
  setPreviousChatsView,
  setChatDetail
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [sortData] = useState({
    column: 'created',
    direction: 'desc'
  });
  // eslint-disable-next-line
  const [size, setSize] = useState(0);
  // eslint-disable-next-line
  const [totalElements, setTotalElements] = useState('');
  // eslint-disable-next-line
  const [hasFetchError, setFetchError] = useState(false);
  // eslint-disable-next-line
  const [defaultSortChanged, setDefaultSortChanged] = useState(false);

  const [filterData, setFilterData] = useState({
    status: ['CLOSED']
  });

  const myClientNameEmail = useSelector(
    state => state.clientInAttendanceReducer
  );

  const filterFormData = {
    keyword: { value: '', label: 'Palavra-chave' },
    serviceAttendantLogin: {
      value: '',
      label: 'i18n.ticketslistview.ATTENDANT'
    },
    creationDate: { value: '', label: 'i18n.ticketslistview.OPENING_DATE_CHAT' }
  };

  const defaultColumOptions = {
    filter: false,
    sort: false
  };

  const getEmail = () => {
    const clientUpdatedList = myClientNameEmail.filter(item => {
      return item.hashConnection === clientInAttendance.hashConnection;
    });
    const lastUpdateClient = clientUpdatedList[clientUpdatedList.length - 1];
    if (lastUpdateClient?.email) {
      return lastUpdateClient.email;
    }
    if (lastUpdateClient === undefined) {
      return clientInAttendance.email;
    }
  };

  const refreshTableContent = async (page = currentPage) => {
    const emailActual = getEmail();
    if (emailActual) {
      if (filterData.status.length === 0) {
        enqueueSnackbar(t('i18n.chatview.STATUS_INFO'), {
          variant: 'info'
        });
        setLoading(false);
        return;
      }

      setCurrentPage(page);
      setLoading(true);

      const postData = {
        sortBy: sortData.direction !== 'none' ? sortData.column : 'created',
        orderBy: sortData.direction !== 'none' ? sortData.direction : 'desc',
        page,
        contactingMainReason: filterData.contactingMainReason || null,
        contactingSubReason: filterData.contactingSubReason || null,
        search: filterData.keyword || null,
        login: filterData.serviceAttendantLogin || null,
        email: emailActual,
        createdIni: filterData?.creationDate
          ? formHelper.formatPeriodDate(filterData.creationDate)[0]
          : null,
        createdFim: filterData?.creationDate
          ? formHelper.formatPeriodDate(filterData.creationDate)[1]
          : null,
        status: filterData?.status
      };
      try {
        const response = await API.post(
          `/auth/ms-chat/chats/filter-chats?businessType=${
            !searchB2bOnCurrentUrl() ? 'B2C' : 'B2B'
          }&&page=${page}`,
          postData
        );
        setData(response.data._embedded.chatResourceList);
        setSize(response.data.page.size);
        setTotalElements(response.data.page.totalElements);
      } catch (error) {
        setFetchError(true);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setData([]);
      setSize(0);
      setTotalElements(0);
    }
  };

  useEffect(() => {
    if (!filterData.limpar) {
      refreshTableContent(0);
    }
  }, [filterData]);

  const columns = [
    {
      name: 'created',
      label: '',
      options: {
        sort: true,
        customBodyRender: value => {
          return (
            <>
              <strong>{timezoneFormat(value)}</strong>
            </>
          );
        },
        customHeadLabelRender: () => {
          return (
            <div className="creationDefaultHeader">
              {t('i18n.ticketslistview.OPENING_DATE_CHAT')}
            </div>
          );
        }
      }
    },
    {
      name: 'login',
      label: t('i18n.chatview.SPECIALIST'),
      options: defaultColumOptions
    },
    {
      name: 'simpleMessages',
      label: t('i18n.ticketslistview.MESSAGES_QUANTITY'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return (
            <>
              <div>{value?.length}</div>
            </>
          );
        }
      }
    },
    {
      name: 'hashConnection',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return (
            <>
              <span style={{ display: 'none' }}>{value}</span>
            </>
          );
        }
      }
    }
  ];

  const options = {
    selectableRows: 'none',
    serverSide: true,
    pagination: true,
    download: false,
    print: false,
    filter: false,
    search: false,
    viewColumns: false,
    rowsPerPage: 20,
    rowsPerPageOptions: [],
    count: totalElements,
    page: currentPage,
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        if (currentPage !== tableState.page) {
          refreshTableContent(tableState.page);
          setCurrentPage(tableState.page);
        }
      }
    },
    onRowClick: rowData => {
      const row = data.filter(
        chat => rowData[3].props.children.props.children === chat.hashConnection
      );
      setChatDetail(row[0]);
      setPreviousChatsView('detail');
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          rowsPerPageOptions={[30]}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: !loading
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: column =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <ClosedChatsFilter
            shortcutSelected={{
              status: ['CLOSED']
            }}
            setCurrentPage={setCurrentPage}
            filterData={filterData}
            setFilterData={setFilterData}
            filterFormData={filterFormData}
            // removedChip={removedChip}
          />
          <Card>
            <Grid container>
              <Grid item xs={12}>
                <CustomDataTableWrapper
                  style={{
                    opacity: '1',
                    marginLeft: '60px',
                    borderLeft: '1px solid #eee'
                  }}
                >
                  <CustonVisitorsDatatable
                    title={
                      <Grid container direction="column" spacing={0}>
                        <Grid item>
                          <Typography variant="h6">
                            {t('i18n.chatview.CLOSED_CHATS')}
                            <Typography variant="caption">
                              {' '}
                              ({t('i18n.simplephrases.CLICK_DETAIL')})
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                    data={data}
                    columns={columns}
                    options={options}
                    selectableRowsOnClick
                    className="table-clickable"
                  />
                </CustomDataTableWrapper>
              </Grid>
            </Grid>
          </Card>
        </>
      )}
    </>
  );
};

export { NewChatHistoricChats };
