import React from 'react';

import { SvgIcon, MuiThemeProvider } from '@material-ui/core';

const Computer = ({ theme }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <SvgIcon
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="1280.000000pt"
        height="780.000000pt"
        viewBox="0 0 1280.000000 780.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,780.000000) scale(0.100000,-0.100000)"
          stroke="none"
        >
          <path
            d="M1389 7786 c-99 -26 -174 -89 -227 -189 l-27 -52 0 -3402 0 -3402 21
-28 c11 -15 36 -36 55 -45 32 -16 6 -17 -569 -18 -600 0 -602 0 -623 -21 -19
-19 -21 -28 -14 -78 3 -31 10 -128 14 -216 5 -126 11 -167 25 -195 25 -49 64
-88 111 -113 l40 -22 6205 0 6205 0 40 22 c47 25 86 64 111 113 14 28 20 69
25 195 4 88 11 185 14 216 7 50 5 59 -14 78 -21 21 -23 21 -623 21 -575 1
-601 2 -569 18 19 9 44 30 55 45 l21 28 0 3402 0 3402 -34 63 c-41 75 -94 125
-170 161 l-56 26 -4985 2 c-3929 1 -4995 -1 -5031 -11z m3269 -96 c46 -43 36
-117 -20 -146 -61 -32 -128 11 -128 82 0 34 5 47 26 63 35 27 93 28 122 1z
m6662 -3465 l0 -3225 -4920 0 -4920 0 0 3225 0 3225 4920 0 4920 0 0 -3225z
m-3320 -3585 c0 -21 -30 -69 -56 -89 l-27 -21 -1517 0 -1517 0 -27 21 c-26 20
-56 68 -56 89 0 7 490 10 1600 10 1110 0 1600 -3 1600 -10z m3355 -194 c93
-39 101 -187 12 -232 -44 -24 -111 -22 -145 2 -85 63 -73 188 22 230 40 17 69
17 111 0z m429 0 c44 -18 77 -66 78 -114 1 -105 -98 -167 -192 -120 -79 40
-101 126 -48 193 39 50 102 66 162 41z m451 -10 c37 -24 56 -60 57 -105 1 -58
-29 -102 -85 -125 -47 -19 -47 -19 -93 0 -61 25 -89 63 -89 119 0 104 122 168
210 111z m-10914 -76 c26 -14 24 -45 -3 -64 -34 -24 -762 -24 -796 0 -26 18
-28 31 -5 56 15 17 41 18 400 18 246 0 391 -4 404 -10z"
          />
        </g>
      </SvgIcon>
    </MuiThemeProvider>
  );
};

export default Computer;
