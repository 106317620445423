const messagesDashboardFilter = {};
const messagesDashboardEquipData = {};
const messagesDashboardIndividualData = {};
const messagesDashboardLoading = true;
const messagesDashboardRemovedKey = '';

const messagesDashboardEquipDataReducer = (
  state = messagesDashboardEquipData,
  action
) => {
  switch (action.type) {
    case 'SET_MESSAGES_DASHBOARD_EQUIP_DATA':
      state = action.equipData;
      return state;
    default:
      return state;
  }
};

const messagesDashboardRemovedKeyReducer = (
  state = messagesDashboardRemovedKey,
  action
) => {
  switch (action.type) {
    case 'SET_MESSAGES_DASHBOARD_REMOVED_KEY':
      state = action.key;
      return state;
    default:
      return state;
  }
};

const messagesDashboardIndividualDataReducer = (
  state = messagesDashboardIndividualData,
  action
) => {
  switch (action.type) {
    case 'SET_MESSAGES_DASHBOARD_INDIVIDUAL_DATA':
      state = action.individualData;
      return state;
    default:
      return state;
  }
};

const messagesDashboardLoadingReducer = (
  state = messagesDashboardLoading,
  action
) => {
  switch (action.type) {
    case 'SET_MESSAGES_DASHBOARD_LOADING':
      state = action.loading;
      return state;
    default:
      return state;
  }
};

const messagesDashboardFilterReducer = (
  state = messagesDashboardFilter,
  action
) => {
  switch (action.type) {
    case 'SET_MESSAGES_DASHBOARD_FILTER':
      state = action.filter;
      return state;
    default:
      return state;
  }
};

export {
  messagesDashboardFilterReducer,
  messagesDashboardLoadingReducer,
  messagesDashboardEquipDataReducer,
  messagesDashboardIndividualDataReducer,
  messagesDashboardRemovedKeyReducer
};
