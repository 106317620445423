import React from 'react';
import { Box, Container } from '@material-ui/core';
import indexHook from './indexHook';
import Page from '../../components/Page';
import FilterDrawer from './components/filterDrawer.component';

import TicketsDashboard from './TicketsDashboard';
import MessagesDashboard from './MessagesDashboard';
import ChatsDashboard from './ChatsDashboard';
import ReportsDashboard from './ReportsDashboard';
import PreSaleOnlineDashboard from './PreSaleOnlineDashboard';
import PreSaleOnlineB2BDashboard from './PreSaleOnlineB2BDashboard';
import PreSaleOfflineDashboard from './PreSaleOfflineDashboard';

import MessageScoreDashboard from './MarketplaceScoreDashboard';
import DashboardTabs from './components/dashboardTabs.component';

import { ticketsTabName } from './TicketsDashboard/config';
import { messagesTabName } from './MessagesDashboard/config';
import { chatsTabName } from './ChatsDashboard/config';
import { reportsTabName } from './ReportsDashboard/config';
import { presaleOnlineTabName } from './PreSaleOnlineDashboard/config';
import { presaleOnlineB2BTabName } from './PreSaleOnlineB2BDashboard/config';
import { presaleOfflineTabName } from './PreSaleOfflineDashboard/config';

import { marketplaceScoreTabName } from './MarketplaceScoreDashboard/config';
import userService from '../../services/user.service';

const getCurrentDashboardState = (tabValue, arrayState) => {
  if (tabValue === ticketsTabName) {
    return arrayState[0];
  }
  if (tabValue === messagesTabName) {
    return arrayState[1];
  }
  if (tabValue === chatsTabName) {
    return arrayState[2];
  }
  if (tabValue === reportsTabName) {
    return arrayState[3];
  }
  if (tabValue === presaleOnlineTabName) {
    return arrayState[4];
  }
  if (tabValue === presaleOnlineB2BTabName) {
    return arrayState[5];
  }
  if (tabValue === presaleOfflineTabName) {
    return arrayState[6];
  }
  if (tabValue === marketplaceScoreTabName) {
    return arrayState[7];
  }
};

const getCurrentDashboardSet = (tabValue, arraySet) => {
  if (tabValue === ticketsTabName) {
    return arraySet[0];
  }
  if (tabValue === messagesTabName) {
    return arraySet[1];
  }
  if (tabValue === chatsTabName) {
    return arraySet[2];
  }
  if (tabValue === reportsTabName) {
    return arraySet[3];
  }
  if (tabValue === presaleOnlineTabName) {
    return arraySet[4];
  }
  if (tabValue === presaleOnlineB2BTabName) {
    return arraySet[5];
  }
  if (tabValue === presaleOfflineTabName) {
    return arraySet[6];
  }
  if (tabValue === marketplaceScoreTabName) {
    return arraySet[7];
  }
};

const Dashboard = () => {
  const {
    classes,
    tabValue,
    tabsList,
    handleTabChange,
    ticketsFilter,
    setTicketsFilter,
    messagesFilter,
    setMessagesFilter,
    chatsFilter,
    setChatsFilter,
    reportsFilter,
    setReportsFilter,
    presaleOnlineFilter,
    setPresaleOnlineFilter,
    presaleOnlineB2BFilter,
    setPresaleOnlineB2BFilter,
    presaleOfflineFilter,
    setPresaleOfflineFilter,
    marketplaceScoreFilter,
    setMarketplaceScoreFilter
  } = indexHook();

  const currentDashboardFilterState = getCurrentDashboardState(tabValue, [
    ticketsFilter,
    messagesFilter,
    chatsFilter,
    reportsFilter,
    presaleOnlineFilter,
    presaleOnlineB2BFilter,
    presaleOfflineFilter,
    marketplaceScoreFilter
  ]);

  const currentDashboardFilterSet = getCurrentDashboardSet(tabValue, [
    setTicketsFilter,
    setMessagesFilter,
    setChatsFilter,
    setReportsFilter,
    setPresaleOnlineFilter,
    setPresaleOnlineB2BFilter,
    setPresaleOfflineFilter,
    setMarketplaceScoreFilter
  ]);

  return (
    <>
      <Page className={classes.root} title="Dashboard">
        <FilterDrawer
          tabValue={tabValue}
          dashboardFilter={currentDashboardFilterState}
          setDashboardFilter={currentDashboardFilterSet}
        />
        <Container maxWidth={false}>
          <DashboardTabs
            tabValue={tabValue}
            handleTabChange={handleTabChange}
            tabsList={tabsList}
            dashboardFilter={currentDashboardFilterState}
          />

          <Box mt={3} ml={7}>
            {tabValue === ticketsTabName && (
              <TicketsDashboard
                tabValue={tabValue}
                dashboardFilter={ticketsFilter}
                setDashboardFilter={setTicketsFilter}
              />
            )}
            {tabValue === messagesTabName && (
              <MessagesDashboard
                tabValue={tabValue}
                dashboardFilter={messagesFilter}
                setDashboardFilter={setMessagesFilter}
              />
            )}
            {tabValue === chatsTabName && (
              <ChatsDashboard
                tabValue={tabValue}
                dashboardFilter={chatsFilter}
                setDashboardFilter={setChatsFilter}
              />
            )}
            {tabValue === reportsTabName && (
              <ReportsDashboard
                tabValue={tabValue}
                dashboardFilter={reportsFilter}
                setDashboardFilter={setReportsFilter}
              />
            )}
            {tabValue === 'pbiDashboardTab' && (
              <iframe
                title="BF 4 Relatório Pós-venda"
                width="100%"
                height="780"
                src="https://app.powerbi.com/reportEmbed?reportId=e7e2e563-38a1-4fae-ae8d-3ba7b42eb591&autoAuth=true&ctid=e43c52f7-b96f-4a70-95dc-ef4ea2425085&pageName=ReportSectiondf25758f5bb0ab0ba4cc"
                frameBorder="0"
                allowFullScreen="true"
              />
            )}

            {tabValue === marketplaceScoreTabName && (
              <MessageScoreDashboard
                tabValue={tabValue}
                dashboardFilter={marketplaceScoreFilter}
                setDashboardFilter={setMarketplaceScoreFilter}
              />
            )}

            {tabValue === presaleOnlineTabName &&
              (userService.hasRole('specialist_pre_sale_online') ||
                userService.hasRole('analyst_pre_sale_online')) && (
                <PreSaleOnlineDashboard
                  tabValue={tabValue}
                  dashboardFilter={presaleOnlineFilter}
                  setDashboardFilter={setPresaleOnlineFilter}
                />
              )}

            {tabValue === presaleOnlineB2BTabName &&
              (userService.hasRole('specialist_pre_sale_online') ||
                userService.hasRole('analyst_pre_sale_online')) && (
                <PreSaleOnlineB2BDashboard
                  tabValue={tabValue}
                  dashboardFilter={presaleOnlineB2BFilter}
                  setDashboardFilter={setPresaleOnlineB2BFilter}
                />
              )}

            {tabValue === presaleOfflineTabName && (
              <PreSaleOfflineDashboard
                tabValue={tabValue}
                dashboardFilter={presaleOfflineFilter}
                setDashboardFilter={setPresaleOfflineFilter}
              />
            )}
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default Dashboard;
