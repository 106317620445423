import {
  Box,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  Button,
  Chip
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { InsertDriveFile } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import AcerModal from '../../../../../components/AcerModal';
import API from '../../../../../services/api';
import ConfirmationModal from '../../../../../utils/ConfirmationModal';

const CancellingConfirmation = ({
  openState,
  closeParent,
  ticketData,
  restitution,
  devolution,
  reason
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(openState);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
  const [confirmationModalAction, setConfirmationModalAction] = useState();
  const [productReceived, setProductReceived] = useState('');
  const [packagingStatus, setPackagingStatus] = useState('');
  const [comment, setComment] = useState('');
  const [filesList, setFilesList] = useState([]);

  const onDrop = acceptedFiles => {
    // const newFilesState = filesToUpload;
    const newFilesState = [];
    // if (filesToUpload.length + acceptedFiles.length > 4) {
    //   enqueueSnackbar(
    //     t('i18n.newticket.IMPOSSIBLE_TO_INCLUDE_MORE_THAN_4_FILES'),
    //     {
    //       variant: 'error'
    //     }
    //   );
    //   return;
    // }
    acceptedFiles.forEach(element => {
      if (element.size > 2000000) {
        enqueueSnackbar(
          t('i18n.newticket.THE_IMAGE') +
            element.path +
            t('i18n.newticket.CANNOT_BE_SENT'),
          {
            variant: 'error'
          }
        );
        return;
      }
      const reader = new FileReader();
      const fileByteArray = [];
      const base64file = [];
      // reader.readAsArrayBuffer(element);
      reader.readAsDataURL(element);
      reader.onloadend = evt => {
        if (evt.target.readyState === FileReader.DONE) {
          base64file.push(evt.currentTarget.result);
          const arrayBuffer = evt.target.result;
          const array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < array.length; i++) {
            fileByteArray.push(array[i]);
          }
        }

        newFilesState.push({
          path: element.path,
          name: element.name,
          binary: fileByteArray,
          data: base64file[0]
        });

        setFilesList(newFilesState);
      };
    });
  };

  const handleClose = () => {
    closeParent();
    setOpen(false);
    setComment('');
    setProductReceived('');
    setPackagingStatus('');
    setFilesToUpload([]);
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept:
      'image/jpeg,image/png,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  });

  const files = filesToUpload.map((file, index) => (
    <>
      <Chip
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        style={{ margin: '2px' }}
        label={file.path || file.name}
        onDelete={() => {
          const newFilesState = [...filesToUpload];
          newFilesState.splice(index, 1);
          setFilesToUpload(newFilesState);
        }}
      />
    </>
  ));

  const submitCancelling = async () => {
    const postData = {
      idIncidentCNOVA: ticketData?.marketplaceBundle?.idIncidenteCNOVA,
      ticketId: ticketData?.id,
      text: comment,
      solutionId: 2709,
      date: null,
      productReceived,
      packagingStatus,
      attachments:
        filesToUpload.length === 0
          ? null
          : filesToUpload.map(file => {
              return {
                fileName: file.path,
                data: file.data
              };
            })
    };
    try {
      await API.post(
        `/auth/ms-ticket/v1/tickets/cnova/accept-cancellation`,
        postData
      );
    } catch (error) {
      enqueueSnackbar(t('i18n.ticket.CNOVA_ACCEPT_CANCELLATION_FAIL'), {
        variant: 'error'
      });
    } finally {
      handleClose();
    }
  };

  const attachPDFTerm = () => {
    const findTerm = filesToUpload.filter(
      file => file.path === 'termoDevolucaoMercadoriaV2.pdf'
    );

    if (findTerm?.length > 0) {
      enqueueSnackbar(t('i18n.simplephrases.TERM_ALREADY_INSERTED'), {
        variant: 'warning'
      });
      return false;
    }

    fetch('/static/termoDevolucaoMercadoriaV2.pdf')
      .then(res => res.blob()) // Gets the response and returns it as a blob
      .then(blob => {
        const file = new File([blob], 'termoDevolucaoMercadoriaV2.pdf', {
          type: 'application/pdf'
        });
        onDrop([file]);
        enqueueSnackbar(t('i18n.simplephrases.TERM_SUCCESS'), {
          variant: 'success'
        });
      });
  };

  useEffect(() => {
    setOpen(openState);
  }, [openState]);

  useEffect(() => {
    onDrop(acceptedFiles);
  }, [acceptedFiles]);

  useEffect(() => {
    setFilesToUpload([...filesToUpload, ...filesList]);
  }, [filesList]);

  return (
    <>
      <ConfirmationModal
        message={confirmationModalMessage}
        submitConfirmation={() => {
          confirmationModalAction();
          setConfirmationModalOpen(false);
        }}
        openState={confirmationModalOpen}
        closeParent={() => {
          setConfirmationModalOpen(false);
        }}
      />

      <AcerModal
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}
        modalTitle={t('i18n.cnova.modal.cancellingconfirmation.TITLE')}
        hasAction
        confirmActionFunc={() => {
          setConfirmationModalAction(() => () => {
            submitCancelling();
          });
          setConfirmationModalMessage(
            t('i18n.cnova.phrases.viavarejoassignment.cancellation')
          );
          setConfirmationModalOpen(true);
        }}
        confirmActionLabel={t('i18n.simplewords.FINISH')}
        cancelActionLabel={t('i18n.simplewords.CANCEL')}
      >
        <Card>
          <CardContent>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {t('i18n.cnova.modal.cancellingconfirmation.PRODUCT')}
                    </TableCell>
                    <TableCell>
                      {t(
                        'i18n.cnova.modal.cancellingconfirmation.AVAILABLE_QTY'
                      )}
                    </TableCell>
                    <TableCell>
                      {t(
                        'i18n.cnova.modal.cancellingconfirmation.REQUIRED_QTY'
                      )}
                    </TableCell>
                    <TableCell>
                      {t(
                        'i18n.cnova.modal.cancellingconfirmation.PRODUCT_RECEIVED'
                      )}
                    </TableCell>
                    <TableCell>
                      {t(
                        'i18n.cnova.modal.cancellingconfirmation.PACKAGING_CONDITION'
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key="1">
                    <TableCell component="th" scope="row">
                      {
                        ticketData?.marketplaceBundle?.entregas?.[0]?.skus?.[0]
                          ?.nome
                      }
                    </TableCell>
                    <TableCell component="th" scope="row">
                      1
                    </TableCell>
                    <TableCell component="th" scope="row">
                      1
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Box m={1}>
                        <FormControl variant="outlined">
                          <Select
                            value={productReceived}
                            onChange={ev => setProductReceived(ev.target.value)}
                          >
                            <MenuItem value>
                              {t('i18n.simplewords.YES')}
                            </MenuItem>
                            <MenuItem value={false}>
                              {t('i18n.simplewords.NO')}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Box m={1}>
                        <FormControl variant="outlined">
                          <Select
                            value={packagingStatus}
                            onChange={ev => setPackagingStatus(ev.target.value)}
                          >
                            <MenuItem value="intacto">
                              {t('i18n.simplewords.GOOD')}
                            </MenuItem>
                            <MenuItem value="ruim">
                              {t('i18n.simplewords.BAD')}
                            </MenuItem>
                            <MenuItem value="danificado">
                              {t('i18n.simplewords.DAMAGED')}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <Grid container spacing={2}>
              <Grid xs={4} sm={4} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  disabled="true"
                  value={devolution}
                  label={t(
                    'i18n.cnova.modal.cancellingconfirmation.DEVOLUTION_TYPE'
                  )}
                />
              </Grid>
              <Grid xs={4} sm={4} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  disabled="true"
                  value={restitution}
                  label={t(
                    'i18n.cnova.modal.cancellingconfirmation.RESTITUTION_TYPE'
                  )}
                />
              </Grid>
              <Grid xs={4} sm={4} item>
                <TextField
                  fullWidth
                  disabled="true"
                  value={reason}
                  variant="outlined"
                  label={t('i18n.cnova.modal.cancellingconfirmation.REASON')}
                />
              </Grid>
            </Grid>
            <br />
            <TextField
              value={comment}
              onChange={ev => {
                setComment(ev.target.value);
              }}
              label={t('i18n.simplewords.COMMENTS')}
              fullWidth
              multiline
              variant="outlined"
              rows={3}
            />
          </CardContent>
          <CardContent>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '15px'
              }}
            >
              <Typography variant="overline">
                {t('i18n.newticket.SEND_FILES')}
              </Typography>
              <Tooltip title={t('i18n.ticketcontainer.INSERT_RETURN_TERM')}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => attachPDFTerm()}
                >
                  <InsertDriveFile />
                </Button>
              </Tooltip>
            </div>
            <Paper
              {...getRootProps({ className: 'dropzone' })}
              style={{
                padding: '20px',
                border: 'dashed 2px #ccc',
                textAlign: 'center'
              }}
              variant="outlined"
            >
              <input {...getInputProps()} />
              <Typography>
                {t('i18n.newticket.DROP_FILES_HERE')} <br />
                {t('i18n.newticket.MAX_FILES')}
              </Typography>
            </Paper>
            <br />
            {files}
          </CardContent>
        </Card>
      </AcerModal>
    </>
  );
};

export default CancellingConfirmation;
