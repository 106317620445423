import {
  Box,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import React from 'react';
import { COLORS } from '../../../../../../../styles/settings/colors.config';
import { CSSTextField, HairlineLabel, Label } from './styles';

const useStyles = makeStyles(() => ({
  selectParecer: {
    '& .MuiSelect-outlined': {
      height: '20px'
    },
    '& .MuiSelect-select': {
      fontSize: '12px',
      padding: '5px 5px !important'
    }
  },
  inputSelect: {
    minWidth: '100%',
    '& .MuiOutlinedInput-root': {
      height: '30px',
      fontSize: '12px',
      width: '100%',
      backgroundColor: 'white'
    }
  }
}));

const logisticsReviewList = ['Liberado', 'Liberado com Ressalva', 'Recusado'];

export default function FinalReview({
  details,
  serviceReview,
  setServiceReview,
  orderNumber,
  setOrderNumber
}) {
  const classes = useStyles();

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginBottom: '10px',
        marginTop: '10px',
        marginLeft: '20px'
      }}
    >
      <Label bold="true" customcolor={COLORS.orange}>
        Service - Novo Número de Pedido{' '}
        <HairlineLabel>(Obrigatório)</HairlineLabel>
      </Label>
      {details?.isReviewedByService ? (
        <Typography>{details?.newOrderNumber}</Typography>
      ) : (
        <CSSTextField
          variant="outlined"
          defaultValue={orderNumber}
          disabled={details?.isReviewedByService}
          onChange={e => {
            setOrderNumber(e.target.value);
          }}
        />
      )}

      <Label bold="true" customcolor={COLORS.orange} margintop="10px">
        Parecer Service <HairlineLabel>(Obrigatório)</HairlineLabel>
      </Label>
      {details?.isReviewedByService ? (
        <Typography>{details?.statusService}</Typography>
      ) : (
        <FormControl variant="outlined" className={classes.inputSelect}>
          <Select
            value={serviceReview}
            onChange={htmlElemtEvent => {
              setServiceReview(htmlElemtEvent.target.value);
            }}
            className={classes.selectParecer}
          >
            {logisticsReviewList.map(item => {
              return (
                <MenuItem key={Math.random()} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </Box>
  );
}
