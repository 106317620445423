import React, { useState, useEffect } from 'react';
import {
  FormControl,
  TextField,
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Button,
  FormLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import AcerModal from '../../../../components/AcerModal';
import { COLORS } from '../../../../styles/settings/colors.config';
import API from '../../../../services/api';

const CustomTextField = styled(TextField)`
  input[type='date'] {
    font-size: 14px;
    width: 100px;
  }
`;

const HeaderSubtitle = styled(Typography)`
  color: ${COLORS.WarningV3};
`;

const CounterWrapper = styled.span`
  font-family: Roboto;
`;

const CounterNumber = styled.span`
  font-weight: bold;
  color: ${props => props.red && 'red'};
`;

const useStyles = makeStyles(() => ({
  inputWrapper: { width: '30%', gap: '16px' },
  inputHour: { fontSize: '12px' },
  boxWrapper: { gap: '16px' }
}));

const CharacterCount = ({ currentLength, limit }) => {
  const closeToTheLimit = currentLength >= limit - 10;

  return (
    <CounterWrapper>
      <CounterNumber red={closeToTheLimit}>{currentLength}</CounterNumber>/
      <CounterNumber red>{limit}</CounterNumber> caracteres
    </CounterWrapper>
  );
};

const AddChatConfigModal = ({
  showModal,
  refreshTableContent,
  editItem,
  handlePost,
  handleClose,
  defaultSchedule
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();
  const [form, setForm] = useState({});

  const handleMountData = dataForm => {
    const {
      enabled,
      chatAvailable,
      startDate,
      endDate,
      startConfig,
      endConfig,
      standard
    } = dataForm;

    const validityPeriod = {
      startingDate: `${startDate}T00:00:00`,
      endingDate: endDate ? `${endDate}T23:59:59` : null
    };

    const attendingTimePeriod = {
      startingTime: `${startConfig}`,
      endingTime: `${endConfig}`
    };

    return {
      ...dataForm,
      validityPeriod,
      attendingTimePeriod,
      enabled: enabled === 'true',
      chatAvailable: chatAvailable === 'true',
      standard: standard || defaultSchedule.length === 0
    };
  };

  const handleMountForm = dataForm => {
    const {
      validityPeriod,
      attendingTimePeriod,
      enabled,
      chatAvailable,
      standard
    } = dataForm;

    dataForm.startDate = validityPeriod?.startingDate
      ? moment(validityPeriod?.startingDate).format('YYYY-MM-DD')
      : moment(new Date()).format('YYYY-MM-DD');
    dataForm.endDate = validityPeriod?.endingDate
      ? moment(validityPeriod?.endingDate).format('YYYY-MM-DD')
      : null;
    dataForm.startConfig = attendingTimePeriod?.startingTime;
    dataForm.endConfig = attendingTimePeriod?.endingTime;
    dataForm.enabled = enabled === undefined ? 'true' : enabled.toString();
    dataForm.standard = standard || defaultSchedule.length === 0;
    dataForm.chatAvailable =
      chatAvailable === undefined ? 'true' : chatAvailable.toString();

    setForm(dataForm);
  };

  const handleUpdate = async data => {
    try {
      await API.put(
        '/auth/ms-chat/attendingTime/update-attending-time',
        data
      ).then(() => {
        enqueueSnackbar(t('i18n.monitor.chat.SETTINGS_UPDATE'), {
          variant: 'success'
        });
        refreshTableContent();
        handleClose();
      });
    } catch (err) {
      enqueueSnackbar(err.response.data.Error, {
        variant: 'error'
      });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const data = handleMountData(form);

    if (data.id) {
      handleUpdate(data);
    } else {
      handlePost(data);
    }
  };

  useEffect(() => {
    if (!editItem?.id) {
      setForm({});
    }

    if (showModal) handleMountForm(editItem);
  }, [showModal, editItem]);

  return (
    <AcerModal
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={showModal}
      modalTitle={`${
        editItem?.id ? 'Editar' : 'Criar'
      } horário de atendimento do Chat`}
      freeBody
    >
      <form style={{ overflow: 'hidden' }} onSubmit={e => handleSubmit(e)}>
        <Box p={2} style={{ background: COLORS.bgModal }}>
          <FormControl fullWidth>
            <TextField
              label={t('i18n.monitor.chat.SETTINGS_MODAL_DESCRIPTION')}
              size="small"
              variant="outlined"
              required
              value={form.description || ''}
              onChange={e => {
                setForm({
                  ...form,
                  description: e.target.value
                });
              }}
            />
          </FormControl>
        </Box>
        <Box p={2}>
          <Grid container>
            <Grid item md={6}>
              <Box
                display="flex"
                alignItems="center"
                className={classes.boxWrapper}
              >
                <Box display="flex" alignItems="center">
                  <Typography>
                    {t('i18n.monitor.chat.SETTINGS_MODAL_VALIDITY')}
                  </Typography>
                </Box>
                <Box display="flex" className={classes.boxWrapper}>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.boxWrapper}
                  >
                    <Typography>
                      {t('i18n.monitor.chat.SETTINGS_MODAL_FROM')}
                    </Typography>
                    <CustomTextField
                      size="small"
                      variant="outlined"
                      type="date"
                      required
                      value={form.startDate || ''}
                      disabled={form.standard}
                      onChange={e => {
                        setForm({
                          ...form,
                          startDate: e.target.value
                        });
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        inputProps: {
                          min: moment(new Date()).format('YYYY-MM-DD')
                        }
                      }}
                    />
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.boxWrapper}
                  >
                    <Typography
                      style={{ color: form.standard && COLORS.grayText }}
                    >
                      {t('i18n.monitor.chat.SETTINGS_MODAL_UNTIL')}
                    </Typography>
                    <CustomTextField
                      size="small"
                      variant="outlined"
                      type="date"
                      disabled={form.standard}
                      value={form.endDate || ''}
                      onChange={e => {
                        setForm({
                          ...form,
                          endDate: e.target.value
                        });
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        inputProps: {
                          min: moment(new Date()).format('YYYY-MM-DD')
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box display="flex" pl={2}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Habilitar horário alternativo agora?
                  </FormLabel>
                  <RadioGroup
                    row
                    value={form.enabled || 'true'}
                    onChange={e =>
                      setForm({ ...form, enabled: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      disabled={form.standard}
                      label="Sim"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      disabled={form.standard}
                      label="Não"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              style={{
                background: COLORS.bgModal,
                borderRight: '8px solid #fff'
              }}
            >
              <Box p={2}>
                <Typography>
                  {t('i18n.monitor.chat.SETTINGS_MODAL_LABEL')}
                </Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                  className={classes.boxWrapper}
                  mt={2}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.inputWrapper}
                  >
                    <Typography>
                      {t('i18n.monitor.chat.SETTINGS_MODAL_FROM')}
                    </Typography>
                    <FormControl>
                      <InputMask
                        mask="99:99"
                        maskChar=""
                        value={form.startConfig || ''}
                        onChange={e => {
                          setForm({
                            ...form,
                            startConfig: e.target.value
                          });
                        }}
                      >
                        {() => (
                          <TextField variant="outlined" size="small" required />
                        )}
                      </InputMask>
                    </FormControl>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.inputWrapper}
                  >
                    <Typography>
                      {t('i18n.monitor.chat.SETTINGS_MODAL_UNTIL')}
                    </Typography>
                    <FormControl>
                      <InputMask
                        mask="99:99"
                        maskChar=""
                        value={form.endConfig || ''}
                        onChange={e => {
                          setForm({
                            ...form,
                            endConfig: e.target.value
                          });
                        }}
                      >
                        {() => (
                          <TextField variant="outlined" size="small" required />
                        )}
                      </InputMask>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <Box p={2}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    O que acontece com o chat neste período?
                  </FormLabel>
                  <RadioGroup
                    row
                    value={form.chatAvailable || 'false'}
                    onChange={e =>
                      setForm({ ...form, chatAvailable: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      disabled={form.standard}
                      label="Fica disponível para atendimento"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      disabled={form.standard}
                      label="Fica indisponível para atendimento"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              style={{
                background: COLORS.bgModal,
                borderLeft: '8px solid #fff'
              }}
            >
              <Box p={2}>
                <Typography>
                  {t('i18n.monitor.chat.SETTINGS_MODAL_LABEL_2')}
                </Typography>
                <Box display="flex" className={classes.boxWrapper} mt={2}>
                  <TextField
                    label={t('i18n.monitor.chat.SETTINGS_MODAL_MESSAGE')}
                    size="small"
                    fullWidth
                    multiline
                    required
                    inputProps={{ maxLength: 200 }}
                    minRows={6}
                    variant="outlined"
                    value={form.message || ''}
                    onChange={e => {
                      setForm({
                        ...form,
                        message: e.target.value
                      });
                    }}
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <CharacterCount
                    currentLength={form?.message?.length || 0}
                    limit={200}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={1} p={2} display="flex" justifyContent="space-between">
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleClose()}
          >
            {t('i18n.monitor.chat.SETTINGS_MODAL_CANCEL')}
          </Button>
          <Button color="primary" variant="contained" type="submit">
            {t('i18n.monitor.chat.SETTINGS_MODAL_SAVE')}
          </Button>
        </Box>
      </form>
    </AcerModal>
  );
};

export { AddChatConfigModal, HeaderSubtitle };
