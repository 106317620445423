import { Grid, Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTypograph } from '../components/custom.components';

import MarketplaceScoresCard from './cards/MarketplaceScoresCard';
import SLARenderCard from './cards/SLARenderCard';
import ClaimMagaluCard from './cards/ClaimMagaluCard';
import ClaimMLCard from './cards/ClaimMLCard';
import MediationCard from './cards/MediationCard';
import KabumCarrefourIncidents from './cards/KabumCarrefourIncidents';
import ProtocolCard from './cards/ProtocolCard';
import CurrentOpenedProtocolsCard from './cards/CurrentOpenedProtocolsCard';
import ReasonsRenderCard from './cards/ReasonsRenderCard';
import ReasonsRenderLevelsCard from './cards/ReasonsRenderLevelsCard';
import NoReasonsCard from './cards/NoReasonsCard';

const DashboardContent = ({
  isAdmin,
  dataEquipDash,
  dataIndividualDash,
  dashboardFilter,
  marketPlacesSelected
  // actualProtocolPayload // contém o payload mais recente /dash-protocolo-bi/filtered-bi, necessário para manter a mesma data e hora para o download do XLS
}) => {
  const { t } = useTranslation();
  const { isRealTime } = dashboardFilter;
  const marketplace = marketPlacesSelected;
  // const actualProtocolPayload = dashboardFilter;

  const handleIsRealTime = (realTimeData, periodData) => {
    if (isRealTime) {
      return realTimeData;
    }
    return periodData;
  };

  const marketplacesWithMediation =
    (marketplace.length > 0 &&
      marketplace?.find(item =>
        ['MERCADO_LIVRE', 'B2W', 'CNOVA', 'All'].includes(item)
      )) ||
    null;

  const mercadoLivre =
    (marketplace.length > 0 &&
      marketplace?.find(item => ['MERCADO_LIVRE', 'All'].includes(item))) ||
    null;

  const magalu =
    (marketplace.length > 0 &&
      marketplace?.find(item => ['MAGALU', 'All'].includes(item))) ||
    null;

  const kabumCarrefour =
    (marketplace.length > 0 &&
      marketplace?.find(item =>
        ['KABUM', 'CARREFOUR', 'All'].includes(item)
      )) ||
    null;

  const hintByMarketplace = marketplaceSelected => {
    if (marketplaceSelected.length === 1) {
      switch (marketplaceSelected[0]) {
        case 'B2W':
          return t('i18n.dashboard.components.hint_with_mediation_B2W');
        case 'MERCADO_LIVRE':
          return t('i18n.dashboard.components.hint_with_mediation_ML');
        case 'MAGALU':
          return t('i18n.dashboard.components.hint_complaints_MAGALU');
        default:
          return false;
      }
    }

    if (marketplaceSelected === 'magalu') {
      return t('i18n.dashboard.components.hint_complaints_MAGALU');
    }

    return t('i18n.dashboard.components.hint_with_mediation');
  };

  const showWith100Mediation = marketplaceSelected => {
    const marketplaces100Mediation =
      (marketplaceSelected.length > 0 &&
        marketplaceSelected?.find(item =>
          ['CUSTOMER_GUIDE', 'B2W', 'MERCADO_LIVRE'].includes(item)
        )) ||
      true;

    return marketplaces100Mediation === true;
  };

  return (
    <>
      {/** Header */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} justifyContent="flex-start">
            <Grid item xl={12} lg={12} md={12}>
              <Box mb={2}>
                <CustomTypograph variant="h3">
                  {t('i18n.dashboard.components.protocols')} -{' '}
                  <b>{t('i18n.dashboardlayout.TICKETS')}</b>
                </CustomTypograph>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/** Col 1 */}

        <Grid item lg={4} md={12} sm={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/** SLA */}
              <SLARenderCard
                {...{ dataIndividualDash, dataEquipDash, isAdmin }}
              />
            </Grid>

            {/** Motivos */}
            <Grid item xs={12}>
              <ReasonsRenderLevelsCard
                {...{ dataIndividualDash, dataEquipDash, isAdmin }}
              />
            </Grid>

            {/** Motivos por analista */}
            {isAdmin && (
              <Grid item xs={12}>
                <ReasonsRenderCard
                  {...{ dataIndividualDash, dataEquipDash, isAdmin }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        {/** Col 2 */}
        <Grid item lg={4} md={12} sm={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/** Protocolos abertos no período */}
              <CurrentOpenedProtocolsCard
                {...{
                  isRealTime,
                  isAdmin,
                  handleIsRealTime,
                  dataEquipDash,
                  dataIndividualDash
                }}
              />
            </Grid>
            {isAdmin && (
              <Grid item xs={12}>
                {/** Protocolos por analista */}
                <ProtocolCard
                  {...{
                    isRealTime,
                    isAdmin,
                    handleIsRealTime,
                    dataEquipDash,
                    dataIndividualDash
                  }}
                />
              </Grid>
            )}

            {/** Notas dos marketplaces */}
            <Grid item xs={12}>
              {isRealTime && (
                <MarketplaceScoresCard
                  {...{ dataEquipDash }}
                  descriptionHint="Notas do marketplace."
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        {/** Col 3 */}
        <Grid item lg={4} md={12} sm={12}>
          <Grid container spacing={3}>
            {/** Mercado Livre */}
            {mercadoLivre && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <ClaimMLCard
                  {...{ isAdmin, dataIndividualDash, dataEquipDash }}
                />
              </Grid>
            )}

            {/** MAGALU */}
            {magalu && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <ClaimMagaluCard
                  {...{
                    isAdmin,
                    dataEquipDash,
                    dataIndividualDash,
                    hintByMarketplace
                  }}
                />
              </Grid>
            )}

            {/* Mediações */}
            {marketplacesWithMediation && !showWith100Mediation(marketplace) && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <MediationCard
                  {...{
                    isAdmin,
                    dataEquipDash,
                    dataIndividualDash,
                    hintByMarketplace,
                    marketplace
                  }}
                />
              </Grid>
            )}

            {/** No reasons */}
            {isAdmin && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <NoReasonsCard
                  {...{
                    isAdmin,
                    dataEquipDash,
                    dataIndividualDash,
                    hintByMarketplace,
                    marketplace
                  }}
                />
              </Grid>
            )}

            {/* INCIDENTE KABUM CARREFOUR */}
            {kabumCarrefour && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <KabumCarrefourIncidents
                  id="kabumCarrefourIncidents"
                  {...{
                    isRealTime,
                    isAdmin,
                    handleIsRealTime,
                    dataIndividualDash,
                    dataEquipDash
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardContent;
