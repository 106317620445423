import {
  Card,
  Grid,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../../../../../../styles/settings/colors.config';

export const Label = styled(Typography)`
  font-size: 12px;
  text-transform: none;
  text-align: left;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  color: ${props => (props.customcolor ? props.customcolor : 'black')};
  '& .hairline' : {
    font-weight: normal;
  }
`;

const HairlineLabel = styled.span`
  font-weight: 300;
`;

export const Content = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
  text-transform: none;
  text-align: left;
`;

const CSSTextField = withStyles({
  root: {
    minWidth: '100% !important',

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#B7B7B7',
        height: '35px',
        width: '100%'
      },
      '&:hover fieldset': {
        borderColor: '#B7B7B7'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#B7B7B7'
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: '15px 5px 5px 10px',
      fontSize: '14px',
      marginTop: '-10px',
      width: '100%'
    }
  }
})(TextField);

export default function Item({ item, i, setDetails, details }) {
  const [detail, setDetail] = useState('');
  const [serialNumber, setSerialNumber] = useState('');

  const updateNewDetails = () => {
    const newData = { ...details };
    newData.itensToCancel[i].detailLogistics = detail || item.detailLogistics;
    newData.itensToCancel[i].serialNumber = serialNumber || item.serialNumber;
    setDetails(newData);
  };

  return (
    <Card style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Label>Item</Label>
          <Content>{i + 1}</Content>
        </Grid>
        <Grid item xs={3}>
          <Label>Descrição do item cancelado</Label>
          <Content>{item?.descricao}</Content>
        </Grid>
        <Grid item xs={2}>
          <Label>Part Number</Label>
          <Content>{item?.partNumber}</Content>
        </Grid>
        <Grid item xs={3}>
          <Label bold="true" customcolor={COLORS.orange}>
            Número de Série
          </Label>
          <CSSTextField
            variant="outlined"
            disabled={details.isReviewedByLogistics}
            defaultValue={item.serialNumber}
            onChange={e => {
              setSerialNumber(e.target.value);
            }}
            onBlur={updateNewDetails}
          />
        </Grid>
        <Grid item xs={3}>
          <Label bold="true" customcolor={COLORS.orange}>
            Parecer Logística - Item <HairlineLabel>(Opcional)</HairlineLabel>
          </Label>
          <CSSTextField
            variant="outlined"
            defaultValue={item.detailLogistics}
            disabled={details.isReviewedByLogistics}
            onChange={e => {
              setDetail(e.target.value);
            }}
            onBlur={updateNewDetails}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
