import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RadioComponent from '../../../../components/Radio.component';

const VIPCustomer = ({ filterForm, setFilterForm }) => {
  const { t } = useTranslation();

  return (
    <Box mt={1}>
      <Grid container display="flex" alignItems="center">
        <RadioComponent
          {...{
            name: 'vipClient',
            form: filterForm,
            setForm: setFilterForm,
            label: t('i18n.ticketslistview.VIPCLIENT')
          }}
          options={[
            { name: t('i18n.simplewords.NO'), value: 'false' },
            { name: t('i18n.simplewords.YES'), value: 'true' }
          ]}
        />
      </Grid>
    </Box>
  );
};

export default VIPCustomer;
