import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// import '.';
import { COLORS } from '../../../../styles/settings/colors.config';
import { chatClosingReasons } from '../../helpers/chatHelpers';

const FilterWrapper = styled('div')`
  overflow-y: auto;
  max-height: 950px;
  overflow-x: hidden;
  height: 100%;
  margin-bottom: 20px;
  margin-left: 50px;
  padding-right: 15px;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: ${COLORS.btnSecondaryColor};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.btnSecondaryColor};
    border: 2px solid ${COLORS.btnSecondaryColor};
  }

  @media (max-width: 1366px) {
    max-height: 350px;
  }
`;

const useStyles = makeStyles({
  root: {
    marginLeft: '50px'
  }
});

const ClosedChatsFilterContainer = ({
  filterData,
  setFilterData,
  onFilterChange,
  setOpen,
  removedChip
}) => {
  const { t } = useTranslation();
  const [filterForm, setFilterForm] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [inputValueCreationDate, setInputValueCreationDate] = useState({
    from: '',
    to: ''
  });

  const [defaultState, setDefaultState] = useState({});
  const [subReasons, setSubReasons] = useState([]);

  useEffect(() => {
    if (filterForm.contactingMainReason) {
      const { finishingReason } = chatClosingReasons.find(
        item => item.contactingMainReason === filterForm.contactingMainReason
      );
      setSubReasons(finishingReason);
    }
  }, [filterForm.contactingMainReason]);

  const [
    inputValueCreationDateUseEffectParams,
    setInputValueCreationDateUseEffectParams
  ] = useState();

  const dividerClasses = useStyles();

  const submitFilter = () => {
    const mergedFilterData = {
      ...filterData,
      ...filterForm
    };
    Object.entries(mergedFilterData).forEach(value => {
      const index = value[0];
      if (!mergedFilterData[index]) {
        delete mergedFilterData[index];
      }
    });
    if (inputValueCreationDate.from && inputValueCreationDate.to) {
      mergedFilterData.creationDate = `${moment
        .utc(inputValueCreationDate.from)
        .utcOffset(new Date().getTimezoneOffset())
        .format('DD/MM/YYYY HH:mm')}-${moment
        .utc(inputValueCreationDate.to)
        .utcOffset(new Date().getTimezoneOffset())
        .format('DD/MM/YYYY HH:mm')}`;
    }
    mergedFilterData.limpar = false;
    setFilterData(mergedFilterData);
    onFilterChange(mergedFilterData);
  };

  const clearFilterFormCreationDateInputs = () => {
    const newState = { ...inputValueCreationDate };
    newState.from = '';
    newState.to = '';
    setInputValueCreationDateUseEffectParams('remove');
    setInputValueCreationDate(newState);
  };

  const clearFilterForm = () => {
    setIsLoading(true);
    clearFilterFormCreationDateInputs();
    const defaultStateData = {};
    defaultStateData.keyword = '';
    defaultStateData.serviceAttendantLogin = '';
    defaultStateData.contactingMainReason = '';
    defaultStateData.contactingSubReason = '';
    setDefaultState({ ...defaultStateData });
    setFilterData({
      ...filterData,
      creationDate: '',
      keyword: '',
      serviceAttendantLogin: '',
      limpar: true
    });
  };

  const updateFormAfterRemoveChip = key => {
    switch (key) {
      case 'creationDate':
        clearFilterFormCreationDateInputs();
        break;
      case key:
        setFilterForm({ ...filterForm, [key]: '' });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (removedChip) {
      updateFormAfterRemoveChip(removedChip);
    }
  }, [removedChip]);

  useEffect(() => {
    setIsLoading(false);
    setFilterForm(defaultState);
  }, [defaultState]);

  const validadeInputValueCreation = () => {
    if (inputValueCreationDateUseEffectParams === 'remove') {
      setInputValueCreationDateUseEffectParams('');
    }
  };

  useEffect(validadeInputValueCreation, [inputValueCreationDate]);

  return (
    <>
      <Grid container>
        <Grid item>
          <Box
            style={{
              width: '500px',
              padding: '12px 24px 24px',
              border: '0',
              display: 'Flex',
              flexDirection: 'column'
            }}
          >
            <FilterWrapper>
              {!isLoading && (
                <>
                  <Box mb={2} mt={2}>
                    <TextField
                      size="small"
                      value={filterForm.keyword || filterData.keyword || ''}
                      onChange={htmlElemtEvent => {
                        setFilterForm({
                          ...filterForm,
                          keyword: htmlElemtEvent.target.value
                        });
                      }}
                      fullWidth
                      label="Palavra-chave"
                      variant="outlined"
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      size="small"
                      value={
                        filterForm.serviceAttendantLogin ||
                        filterData.serviceAttendantLogin ||
                        ''
                      }
                      onChange={htmlElemtEvent => {
                        setFilterForm({
                          ...filterForm,
                          serviceAttendantLogin: htmlElemtEvent.target.value
                        });
                      }}
                      fullWidth
                      label={t('i18n.ticketslistview.ATTENDANT')}
                      variant="outlined"
                    />
                  </Box>
                </>
              )}

              <Box mb={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl size="small" variant="outlined" fullWidth>
                      <InputLabel>
                        {t('i18n.ticketcontainer.REASON')}
                      </InputLabel>
                      <Select
                        defaultValue=""
                        value={filterForm.contactingMainReason || ''}
                        variant="outlined"
                        className="small-input"
                        onChange={htmlElemtEvent => {
                          setFilterForm({
                            ...filterForm,
                            contactingMainReason: htmlElemtEvent.target.value
                          });
                        }}
                        label={t('i18n.ticketcontainer.REASON')}
                      >
                        <MenuItem value="">
                          <em>{t('i18n.ticketslistview.NONE')}</em>
                        </MenuItem>
                        {chatClosingReasons.map(item => {
                          return (
                            <MenuItem
                              key={Math.random()}
                              value={item.contactingMainReason}
                            >
                              {item.contactingMainReason}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl size="small" variant="outlined" fullWidth>
                      <InputLabel>
                        {t('i18n.ticketcontainer.REASON_DETAIL')}
                      </InputLabel>
                      <Select
                        value={filterForm.contactingSubReason || ''}
                        variant="outlined"
                        className="small-input"
                        onChange={htmlElemtEvent => {
                          setFilterForm({
                            ...filterForm,
                            contactingSubReason: htmlElemtEvent.target.value
                          });
                        }}
                        label={t('i18n.ticketcontainer.REASON_DETAIL')}
                      >
                        <MenuItem value="">
                          <em>{t('i18n.ticketslistview.NONE')}</em>
                        </MenuItem>
                        {subReasons.map(item => {
                          return (
                            <MenuItem key={Math.random()} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>

              <Typography gutterBottom variant="h5">
                {t('i18n.ticketslistview.OPENING_DATE_CHAT')}
              </Typography>
              <Grid
                container
                className="mY-3"
                spacing={2}
                justifycontent="flex-start"
              >
                <Grid item lg={6}>
                  <TextField
                    size="small"
                    type="date"
                    label={t('i18n.dashboard.START_DATE')}
                    variant="outlined"
                    value={inputValueCreationDate.from}
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={htmlElemtEvent => {
                      const newState = { ...inputValueCreationDate };
                      newState.from = htmlElemtEvent.target.value;
                      setInputValueCreationDate(newState);
                    }}
                  />
                </Grid>
                <Grid item lg={6}>
                  <TextField
                    size="small"
                    type="date"
                    label={t('i18n.dashboard.END_DATE')}
                    variant="outlined"
                    value={inputValueCreationDate.to}
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={htmlElemtEvent => {
                      const newState = { ...inputValueCreationDate };
                      newState.to = htmlElemtEvent.target.value;
                      setInputValueCreationDate(newState);
                    }}
                  />
                </Grid>
              </Grid>
            </FilterWrapper>

            <Divider
              classes={{
                root: dividerClasses.root
              }}
            />

            <br />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginLeft: '50px'
              }}
            >
              <Button
                onClick={() => {
                  clearFilterForm();
                }}
                variant="contained"
              >
                {t('i18n.ticketslistview.CLEAR')}
              </Button>
              <Button
                onClick={() => {
                  submitFilter(true);
                  setOpen(false);
                }}
                variant="contained"
                color="primary"
              >
                {t('i18n.ticketslistview.SEARCH')}
              </Button>
            </div>
            <br />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ClosedChatsFilterContainer;
