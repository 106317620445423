import React from 'react';
import ModalTicketsDetailReclameAqui from '../../../ModalTicketsDetail/reclame_aqui';

export const ModalTicketsDetailReclameAquiIframe = ({
  openRATicketDetail,
  setOpenRATicketDetail,
  dataRaToShow,
  handleLinkRATicket,
}) => {
  return (
    <ModalTicketsDetailReclameAqui
      openState={openRATicketDetail}
      closeParent={() => setOpenRATicketDetail(false)}
      data={dataRaToShow}
      linkRATicket={handleLinkRATicket}
    />
  );
};
