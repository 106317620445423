import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import { StyledSpinner } from '../../../../../styles/components/SpinnerDefault';
import {
  CustomDataTable,
  CustomDataTableWrapper
} from '../../../../../styles/components/CustomDataTable';

import { ModalCustomerHistoricContext } from './context';
import { ColumnsConfig, OptionsConfig } from './datatable.config';

const DataTableComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    loading,
    data,
    currentPage,
    totalPages,
    totalElements,
    size,
    sortTableData,
    refreshTableContent,
    sortData,
    setSortData
  } = useContext(ModalCustomerHistoricContext);

  const options = OptionsConfig({
    t,
    history,
    data,
    currentPage,
    totalPages,
    totalElements,
    size,
    sortTableData,
    callback: refreshTableContent,
    sortData,
    setSortData
  });

  const columns = ColumnsConfig(t, data);

  return (
    <>
      <CustomDataTableWrapper style={{ opacity: loading ? '0.3' : '1' }}>
        <CustomDataTable
          data={data}
          columns={columns}
          options={options}
          className="table-clickable"
          selectableRowsOnClick
        />
      </CustomDataTableWrapper>
      <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
        <CircularProgress />
      </StyledSpinner>
    </>
  );
};

export default DataTableComponent;
