import API from '../../../../../../services/api';

/** Temp function - Please remove during final code review */

export const tempUpdate = async (data, step, t, enqueueSnackbar) => {
  if (step === 'Objeto Postado') {
    const json = {
      ...data?.cancellationProcess,
      lastStepName: 'Aguardando Chegada na Acer'
    };

    const newStep = {
      name: step,
      date: new Date(),
      reason: null,
      userName: 'danielrocha'
    };

    json.stepsTimeLine.push(newStep);

    await API.put(`/auth/ms-ticket/v1/cancellationProcess/update`, json).catch(
      () => {
        enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
          variant: 'error'
        });
        return false;
      }
    );
  }

  if (step === 'Objeto Entregue na Acer') {
    const json = {
      ...data?.cancellationProcess,
      lastStepName: 'Aguardando Logística',
      isProductInPossessionAcer: true,
      dateReceiptAcer: new Date()
    };

    const newStep = {
      name: step,
      date: new Date(),
      reason: null,
      userName: 'danielrocha'
    };

    json.stepsTimeLine.push(newStep);

    await API.put(`/auth/ms-ticket/v1/cancellationProcess/update`, json).catch(
      () => {
        enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
          variant: 'error'
        });
        return false;
      }
    );
  }

  if (
    step === 'Objeto Postado - processo finalizado' ||
    step === 'Cliente informado - processo finalizado'
  ) {
    const json = {
      ...data?.cancellationProcess,
      lastStepName: 'Finalizado',
      dateReceiptAcer: new Date()
    };

    const newStep = {
      name: step,
      date: new Date(),
      reason: null,
      userName: 'danielrocha'
    };

    json.stepsTimeLine.push(newStep);

    await API.put(`/auth/ms-ticket/v1/cancellationProcess/update`, json).catch(
      () => {
        enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
          variant: 'error'
        });
        return false;
      }
    );
  }

  setTimeout(() => {
    window.location.reload();
  }, 2000);
};
