/*eslint-disable */
import i18n from '../../../../i18n';
import { getParamsInUrl } from '../../../../helpers/globalFunctions';

import {
  handleHistoricPermission,
  handleUsername
} from '../services/historicServices';

export const clearFilterFactory = () => {
  let defaultPayload = {
    status: [
      'All',
      'WAITING_QUEUE',
      'ANSWERD',
      'CLOSED',
      'WAITING_ANSWER',
      'LOSE_CLERK',
      'ATTENDED',
      'NOT_ATTENDED'
    ],
    orderBy: 'desc',
    page: 0,
    sortBy: 'created'
  };

  return defaultPayload;
}
export const defaultFilterFactory = loadStorage => {
  const queryFilterData = getParamsInUrl();
  const sessionStorageFilter = sessionStorage.getItem('historyFilter');
  const presaleOfflinefilterUseQueryParams =
    sessionStorage.getItem('presaleOfflinefilterUseQueryParams') ?? 'true';

  let defaultPayload = {
    status: [
      'All',
      'WAITING_QUEUE',
      'ANSWERD',
      'CLOSED',
      'WAITING_ANSWER',
      'LOSE_CLERK',
      'ATTENDED',
      'NOT_ATTENDED'
    ],
    orderBy: 'desc',
    page: 0,
    sortBy: 'created'
  };

  if (!handleHistoricPermission()) {
    defaultPayload = { ...defaultPayload, ...{ login: handleUsername() } };
  }

  if (queryFilterData && presaleOfflinefilterUseQueryParams === 'true') {
    // queryFilterData.status = [
    //   'All',
    //   'WAITING_QUEUE',
    //   'ANSWERD',
    //   'CLOSED',
    //   'WAITING_ANSWER',
    //   'LOSE_CLERK',
    //   'ATTENDED',
    //   'NOT_ATTENDED'
    // ]
    return queryFilterData;
  }

  if (
    presaleOfflinefilterUseQueryParams === 'false' &&
    sessionStorageFilter
  ) {
    return JSON.parse(sessionStorageFilter);
  }

  return defaultPayload;
};

export const allStatus = [
  'WAITING_QUEUE',
  'ANSWERD',
  'CLOSED',
  'WAITING_ANSWER',
  'LOSE_CLERK',
  'All',
  'ATTENDED',
  'NOT_ATTENDED'
];

export const listOptions = [
  {
    icon: 'T',
    value: 'All',
    text: 'Todos'
  },
  // {
  //   icon: 'FA',
  //   value: 'LOSE_CLERK',
  //   text: i18n.t('i18n.chatview.STATUS_LOSE_CLERK')
  // },
  // {
  //   icon: 'AI',
  //   value: 'WAITING_QUEUE',
  //   text: i18n.t('i18n.chatview.STATUS_WAITING_QUEUE')
  // },
  // {
  //   icon: 'AA',
  //   value: 'WAITING_ANSWER',
  //   text: i18n.t('i18n.chatview.STATUS_WAITING_ANSWER')
  // },
  // {
  //   icon: 'AC',
  //   value: 'ANSWERD',
  //   text: i18n.t('i18n.chatview.STATUS_ANSWERD')
  // },
  // {
  //   icon: 'EN',
  //   value: 'CLOSED',
  //   text: i18n.t('i18n.chatview.STATUS_CLOSED')
  // },
  {
    icon: 'AT',
    value: 'ATTENDED',
    text: i18n.t('i18n.chatview.ATTENDED')
  },
  {
    icon: 'NA',
    value: 'NOT_ATTENDED',
    text: i18n.t('i18n.chatview.NOT_ATTENDED')
  }
];

export const setList = ({ val, filter, setFilter }) => {
  let list = filter;

  if (!list.includes(val)) {
    list = [...list, val];
  } else {
    list = list.filter(item => {
      return item !== val && item !== 'All';
    });
  }

  if (
    val === 'All' &&
    filter.length > 1 &&
    filter.length === clearFilterFactory().status.length
  ) {
    list = [];
  }

  if (
    val === 'All' &&
    filter.length > 1 &&
    filter.length < clearFilterFactory().status.length
  ) {
    list = clearFilterFactory().status;
  }

  if (val === 'All' && filter.length <= 1) {
    list = clearFilterFactory().status;
  }

  if (list.length === clearFilterFactory().status.length - 1) {
    list = clearFilterFactory().status;
  }

  setFilter(list);
};

export const filterLabel = (key, value) => {
  if (key === 'responseTimeRelationalOperator') {
    switch (value) {
      case 'LESS_THAN_OR_EQUAL':
        return 'Menor ou igual a';
      case 'GREATER_THAN':
        return 'Maior que';
      default:
        return 'teste';
    }
  }
};
