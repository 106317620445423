import React from 'react';
import { CardContent } from '@material-ui/core';
import AgentMessageCard from '../baloons/AgentMessageCard';
import MessageActions from '../components/MessageActions.component';
import { StyledPaper } from '../styles';

const InternalMessages = ({
  chat,
  dadosPedido,
  getTicket,
  handleAppliedMacro,
  internalMessages,
  internalMessageLoading,
  internalMessagesResposta,
  getInputProps,
  getRootProps,
  files,
  setInternalMessagesResposta,
  sendInternalMessage
}) => {
  return (
    <>
      <StyledPaper className="message-paper">
        <CardContent>
          {internalMessages.map(m => {
            m.date = m.create_date;
            m.agent = m.user;
            const div = <AgentMessageCard message={m} />;
            return (
              <div key={Math.random()}>
                {div}
                <br />
              </div>
            );
          })}
          {internalMessageLoading && (
            <div key="loadingNewMlmessage">
              <AgentMessageCard
                message={{
                  message: internalMessagesResposta,
                  status: 'loading'
                }}
              />
            </div>
          )}
        </CardContent>
      </StyledPaper>

      <MessageActions
        typeMessage="internalMessage"
        disableAttachment={internalMessageLoading}
        loading={internalMessageLoading}
        resposta={internalMessagesResposta}
        setResposta={setInternalMessagesResposta}
        disableSend={internalMessageLoading}
        sendResponse={sendInternalMessage}
        chatState={chat}
        chat={chat}
        dadosPedido={dadosPedido}
        getTicket={getTicket}
        handleAppliedMacro={handleAppliedMacro}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        files={files}
      />
    </>
  );
};

export default InternalMessages;
