/* eslint-disable no-underscore-dangle */
import {
  Box,
  CircularProgress,
  Container,
  IconButton
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/Add';
import { StyledSpinner } from '../../../styles/components/SpinnerDefault';
import FetchContentError from '../../../components/FetchContentError';
import Page from '../../../components/Page';
import API from '../../../services/api';
import {
  CustomDataTableWrapper,
  CustomDataTable,
  CustomTableButton
} from '../../../styles/components/CustomDataTable';
import CustomFooter from '../../../styles/components/CustomFooter';
import HeaderTitle from '../../../components/HeaderTitle';

const UsersListView = ({
  loadTableContent,
  customToolbar,
  customToolbarSelect,
  onRowSelectionChange
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [hasFetchError, setFetchError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const columns = [
    {
      name: '_id',
      options: {
        filter: false,
        sort: true,
        display: false,
        viewColumns: false
      }
    },
    {
      name: 'question',
      label: t('i18n.knowledge.QUESTION'),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'short_answer',
      label: t('i18n.knowledge.SHORT_ANSWER'),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'business_type',
      label: 'Tipo',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'category',
      label: t('i18n.knowledge.SECTION'),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'clicks',
      label: t('i18n.knowledge.CLICKS'),
      options: {
        filter: false,
        sort: true
      }
    }
  ];

  const [data, setData] = useState([]);

  const auxLoadTableContent = () => {
    setLoading(true);
    API.get(`/auth/lambda/knowledge`)
      .then((response) => {
        const articles = response.data;

        if (articles?.status) {
          setData([]);
        } else {
          setData(articles);
        }
        setLoading(false);
        setFetchError(false);
      })
      .catch(() => {
        setFetchError(true);
      });
  };

  const refreshTable = () => {
    const load = loadTableContent || auxLoadTableContent;
    load();
  };

  useEffect(refreshTable, []);

  if (hasFetchError) {
    return <FetchContentError fetch={auxLoadTableContent} />;
  }

  const options = {
    filterType: 'checkbox',
    selectableRowsHeader: false,
    viewColumns: false,
    filter: false,
    onRowClick: (rowData) => {
      const id = rowData[0].$oid;
      history.push({
        pathname: `knowledge-base/${id}`
      });
    },
    onRowsDelete: (deleteRow) => {
      const keys = Object.keys(deleteRow.lookup).map((s) => {
        return parseInt(s, 10);
      });

      const questions = keys.map((k) => {
        return { _id: data[k]._id.$oid };
      });

      API.post(`/auth/lambda/knowledge/delete`, questions)
        .then(() => {
          enqueueSnackbar(t('i18n.knowledge.DELETE_QUESTION_SUCCESS'), {
            variant: 'success'
          });
        })
        .catch(() => {
          enqueueSnackbar(t('i18n.knowledge.DELETE_QUESTION_ERROR'), {
            variant: 'error'
          });
        });
    },
    customToolbar:
      customToolbar ||
      function aux() {
        return (
          <>
            <IconButton disabled>|</IconButton>
            <CustomTableButton
              variant="text"
              color="primary"
              size="small"
              onClick={() => {
                history.push({ pathname: '/knowledge-base-categories' });
              }}
            >
              {t('i18n.knowledge.FAQ_SECTIONS')}
            </CustomTableButton>
            <IconButton disabled color="secondary">
              |
            </IconButton>
            <CustomTableButton
              variant="text"
              color="primary"
              size="small"
              onClick={() => {
                history.push({ pathname: 'knowledge-base/new' });
              }}
              startIcon={<AddIcon />}
            >
              {t('i18n.knowledge.CREATE_ARTICLE')}
            </CustomTableButton>
          </>
        );
      },
    customToolbarSelect,
    onRowSelectionChange,
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: !loading
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: (column) =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };

  return (
    <>
      <Page title={t('i18n.knowledge.FAQ')}>
        <Container maxWidth={false}>
          <Box mt={3}>
            <CustomDataTableWrapper style={{ opacity: loading ? '0.3' : '1' }}>
              <CustomDataTable
                title={<HeaderTitle title={t('i18n.knowledge.FAQ')} />}
                data={data}
                columns={columns}
                options={options}
                selectableRowsOnClick
                className="table-clickable"
              />
            </CustomDataTableWrapper>
            <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
              <CircularProgress />
            </StyledSpinner>
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default UsersListView;
