// import moment from 'moment';
import moment from 'moment';
import { defaultHoursNumber } from '../services/config';
import { defaultMarketplacesDashboardFilterPresaleOffline } from '../../../hooks/hooks';
import userService from '../../../services/user.service';
import Auth from '../../../auth/auth.service';

const filterNameInStorage = 'newfilterPresaleOffline';

const presaleOfflineTabName = 'presaleOfflineTab';

const checkPermission = () => {
  if (Auth.getAccessToken() !== '' && Auth.getAccessToken() !== null) {
    if (userService.hasRole('specialist_pre_sale_offline')) return 'specialist';
    if (userService.hasRole('analyst_pre_sale_offline')) return 'analyst';
  }
  return false;
};

const defaultFilter = {
  attendant: checkPermission() === 'analyst' ? userService.getUsername() : '',
  tabView: presaleOfflineTabName,
  periodType: 'custom',
  marketplace: defaultMarketplacesDashboardFilterPresaleOffline.filter(
    item => item === 'All' || item === 'YOURVIEWS' || item === 'MERCADO_LIVRE'
  ),
  startDate: moment(new Date()).format('YYYY-MM-DDT03:00:00'),
  endDate: moment(new Date())
    .add(1, 'day')
    .format('YYYY-MM-DDT03:00:00')
};

const setDefaultnameToAttendant = list => {
  list.forEach(item => {
    item.answered = parseInt(item?.answered, 10);
    item.attendant = item.attendant === '' ? 'Sem registro' : item.attendant;
    return item;
  });

  return list;
};

export {
  defaultHoursNumber,
  defaultFilter,
  filterNameInStorage,
  presaleOfflineTabName,
  setDefaultnameToAttendant
};
