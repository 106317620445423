import React from 'react';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// import HelpIcon from '@material-ui/icons/Help';
import Chip from '@material-ui/core/Chip';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../styles/settings/colors.config';

const IconAlert = ({ severity }) => {
  return severity === 'red' ? (
    <ErrorIcon style={{ color: COLORS.btnDangerColor }} />
  ) : (
    <HelpOutlineIcon style={{ color: COLORS.orange }} />
  );
};

const OnlineAlert = ({ online }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { online: on, severity } = online;

  return !on ? (
    <Chip
      icon={<IconAlert {...{ severity }} />}
      variant="outlined"
      label={t('i18n.monitor.ALERT')}
      style={{
        marginInlineEnd: '10px',
        // display: online ? 'none' : 'inherit',
        color: COLORS.whiteText,
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: 'bold'
      }}
      onClick={() => history.push('/monitor')}
    />
  ) : (
    <></>
  );
};

export default OnlineAlert;
