import React from 'react';
import { useTranslation } from 'react-i18next';
import ProtocolCardDataWithDrillDown from '../../components/ProtocolCardDataWithDrillDown.component';

const NoReasonsCard = ({ isAdmin, dataIndividualDash, dataEquipDash }) => {
  const { t } = useTranslation();

  return (
    <ProtocolCardDataWithDrillDown
      title="withoutReason"
      showMoreDetails={false}
      isTeamView={isAdmin}
      firstLabel={t('i18n.dashboard.components.individual')}
      secondaryLabel={t('i18n.dashboard.components.equip')}
      firstData={dataIndividualDash?.total_sem_motivo}
      secondaryData={dataEquipDash?.total_sem_motivo}
      currentPercent={
        dataEquipDash?.total_sem_motivo / dataEquipDash?.abertos_geral
      }
      descriptionHint="Protocolos abertos sem motivoProtocolos que não possuem motivo associado: Excluindo os protocolos com status Novo, os protocolos com status Aguardando Acer do marketplace Magalu (que ainda não tiveram nenhuma resposta da Acer) e os protocolos tratados diretamente na plataforma do marketplace (que possui o nome do analista igual ao nome do marketplace)."
      goalInfo={isAdmin}
      goalPercent={1}
    />
  );
};

export default NoReasonsCard;
