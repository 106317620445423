import React from 'react';
import {
  Card,
  CardContent,
  Divider,
  Tooltip,
  Grid,
  Typography
} from '@material-ui/core';

import {
  CustomTypograph,
  MainCardData,
  SecondaryCardData,
  CardDataWrapper,
  CustomCardContent,
  CustomLineCardContent,
  CardDataDivider,
  // LineCardData,
  CardTitle,
  CustomHelpIcon,
  CustomHintText,
  InConstruction,
  CustomArrowDropDownUp,
  CustomParagraphArrowDropDownUp
} from '../styles';

import formHelper from '../../../utils/formHelper';

const ProtocolCardData = ({
  title,
  firstLabel,
  secondaryLabel,
  isTeamView,
  headerData,
  showMoreDetails,
  extraData,
  firstData,
  secondaryData,
  subtitle,
  // isRealTime
  descriptionHint,
  showInConstruction
}) => {
  // firstData = firstData < 10 ? `0${firstData}` : firstData;
  // secondaryData = secondaryData < 10 ? `0${secondaryData}` : secondaryData;
  firstData = formHelper.addZeroToCompleteTwoDigits(firstData);
  secondaryData = formHelper.addZeroToCompleteTwoDigits(secondaryData);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Card
        style={{ width: '100%', marginBottom: '20px', borderRadius: '20px' }}
      >
        <CardTitle>
          <CustomTypograph variant="h5">{title}</CustomTypograph>
          {descriptionHint && (
            <Tooltip
              arrow
              title={<CustomHintText>{descriptionHint}</CustomHintText>}
            >
              <CustomHelpIcon fontSize="small" color="primary" />
            </Tooltip>
          )}
        </CardTitle>
        <Divider />
        {subtitle && (
          <CardContent>
            <CustomTypograph variant="h6">{subtitle}</CustomTypograph>
          </CardContent>
        )}
        {headerData && (
          <CustomCardContent>
            <CardDataWrapper>
              {!isTeamView && (
                <>
                  <MainCardData>
                    <p className="label">{firstLabel}</p>
                    <p className="main-data">{firstData}</p>
                    {showMoreDetails && (
                      <CustomParagraphArrowDropDownUp>
                        <CustomArrowDropDownUp />
                      </CustomParagraphArrowDropDownUp>
                    )}
                  </MainCardData>
                  <CardDataDivider />
                  <SecondaryCardData>
                    <p className="label">{secondaryLabel}</p>
                    <p className="main-data">{secondaryData}</p>
                  </SecondaryCardData>
                </>
              )}

              {isTeamView && (
                <MainCardData>
                  <p className="label">{secondaryLabel}</p>
                  <p className="main-data">{secondaryData}</p>
                  {showMoreDetails && (
                    <CustomParagraphArrowDropDownUp>
                      <CustomArrowDropDownUp />
                    </CustomParagraphArrowDropDownUp>
                  )}
                </MainCardData>
              )}
            </CardDataWrapper>
          </CustomCardContent>
        )}
        {showMoreDetails && (
          <>
            {headerData && <Divider />}
            {extraData?.map(
              (item, index) =>
                item.show && (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={`${item.label}${item.value}${index}`}>
                    <CustomLineCardContent key={item.label}>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={2}
                      >
                        {/* <Grid item lg={5}> */}
                        <Grid item lg={isTeamView ? 5 : 3}>
                          <Typography
                            align="right"
                            style={{ fontSize: headerData ? '35px' : '45px' }}
                          >
                            {item.value}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          lg={isTeamView ? 7 : 9}
                          direction="column"
                          alignItems="flex-start"
                        >
                          <Grid item lg={12}>
                            <p style={{ fontSize: '85%' }}>{item.label}</p>
                          </Grid>
                          <Grid item lg={12}>
                            <b>
                              <p style={{ fontSize: '85%', color: item.color }}>
                                {item.label2}
                              </p>
                            </b>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CustomLineCardContent>
                    {item.divider && <Divider />}
                  </div>
                )
            )}
          </>
        )}
      </Card>
      {showInConstruction && <InConstruction />}
    </div>
  );
};

export default ProtocolCardData;
