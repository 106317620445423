import { Box, Card, Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { COLORS } from '../../../../../../../../styles/settings/colors.config';

const Label = styled(Typography)`
  font-size: 12px;
  text-transform: none;
  text-align: left;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  color: ${props => (props.customcolor ? props.customcolor : 'black')};
`;

const Content = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
  text-transform: none;
  text-align: left;
  color: ${props => (props.customcolor ? props.customcolor : 'black')};
`;

export default function MainCard({ details, SLA }) {
  return (
    <Box paddingX={5}>
      <Card style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
        <Grid
          container
          style={{ fontFamily: 'Roboto', marginBottom: '5px' }}
          spacing={3}
        >
          <Grid item xs={2}>
            <Label>Nota Fiscal Origem</Label>
            <Content customcolor={COLORS.greenText}>
              {details?.order?.numeroNF}
            </Content>
          </Grid>
          <Grid item xs={1}>
            <Label>Data NF</Label>
            <Content> {moment(details?.dataNF).format('DD/MM/YYYY')}</Content>
          </Grid>
          <Grid item xs={2}>
            <Label>Chegada na ACER</Label>
            <Content>
              {moment(details?.dateReceiptAcer).format('DD/MM/YYYY')}
            </Content>
          </Grid>
          <Grid item xs={1}>
            <Label>SLA</Label>
            <Content
              customcolor={SLA.color}
            >{`${SLA.absHours}h${SLA.absMinutes}min`}</Content>
          </Grid>
          <Grid item xs={1}>
            <Label>CAP</Label>
            <Content customcolor={COLORS.greenText}>
              {details?.cap?.capNumber}
            </Content>
          </Grid>
          <Grid item xs={1}>
            <Label>Data CAP</Label>
            <Content>{details?.cap?.dateIssue}</Content>
          </Grid>
          <Grid item xs={2}>
            <Label>Status CAP</Label>
            <Content>{details?.cap?.status}</Content>
          </Grid>
          <Grid item xs={2}>
            <Label>Rastreio Devolução</Label>
            <Content customcolor={COLORS.greenText}>
              {details?.trackingNumber}
            </Content>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          style={{ fontFamily: 'Roboto', marginTop: '5px' }}
          spacing={3}
        >
          <Grid item md={2}>
            <Label>Motivo da Devolução</Label>
            <Content>{details?.reasonCancellation}</Content>
          </Grid>
          <Grid item md={2}>
            <Label>Detalhe do Motivo</Label>
            <Content>{details?.detail}</Content>
          </Grid>
          <Grid item md={1}>
            <Label>QTD Itens NF</Label>
            <Content>{details?.itensNumberNF}</Content>
          </Grid>
          <Grid item md={1}>
            <Label>QTD Devolução</Label>
            <Content>{details?.itensToCancel?.length}</Content>
          </Grid>
          <Grid item md={2}>
            <Label>Tipo de Devolução</Label>
            <Content>
              {details?.isCancellationOfAllItens ? 'Total' : 'Parcial'}
            </Content>
          </Grid>
          <Grid item md={2}>
            <Label>Status da Devolução</Label>
            <Content customcolor={COLORS.orange}>
              {details?.lastStepName}
            </Content>
          </Grid>
          <Grid item md={2}>
            <Label>Protocolo Conecta</Label>
            <Content customcolor={COLORS.greenText}>
              {details?.conectaTicketId}
            </Content>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}
