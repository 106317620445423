import React from 'react';
import { useTranslation } from 'react-i18next';
import formHelper from '../../../../utils/formHelper';
import { COLORS } from '../../../../styles/settings/colors.config';
import SLACard from './SLACard';

const SLARenderCard = ({ dataIndividualDash, dataEquipDash, isAdmin }) => {
  const { t } = useTranslation();

  const abertos_geral = dataEquipDash?.abertos_geral;

  return (
    <SLACard
      descriptionHint
      headerMultipleData={[
        {
          subtitle: t('i18n.dashboard.components.sla_expired'),
          color: COLORS.btnDangerColor,
          firstLabel: t('i18n.dashboard.components.individual'),
          secondaryLabel: t('i18n.dashboard.components.equip'),
          firstData: formHelper.addZeroToCompleteTwoDigits(
            dataIndividualDash?.sla_estourado
          ),
          secondaryData: formHelper.addZeroToCompleteTwoDigits(
            dataEquipDash?.sla_estourado_geral
          ),
          goalPercent: 0,
          currentPercent: dataEquipDash?.sla_estourado_geral / abertos_geral,
          action: true,
          showCurrentPercent: true
        },
        {
          subtitle: t('i18n.dashboard.components.sla_expiries_today'),
          color: COLORS.WarningColorSLA,
          firstLabel: t('i18n.dashboard.components.individual'),
          secondaryLabel: t('i18n.dashboard.components.equip'),
          firstData: formHelper.addZeroToCompleteTwoDigits(
            dataIndividualDash?.sla_estoura_12_plus
          ),
          secondaryData: formHelper.addZeroToCompleteTwoDigits(
            dataEquipDash?.sla_estoura_12_plus
          ),
          action: true
        }
      ]}
      isTeamView={isAdmin}
    />
  );
};

export default SLARenderCard;
