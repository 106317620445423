import React from 'react';
import { COLORS } from '../../../../../styles/settings/colors.config';

export const getScheduleStatus = scheduleStatus => {
  switch (scheduleStatus) {
    case 'EXPIRED':
      return (
        <span style={{ color: COLORS.btnDangerColor }}>
          Agendamento <b>Vencido</b>
        </span>
      );
    case 'EXPIRES_TODAY':
      return (
        <span style={{ color: COLORS.WarningColorSLA }}>
          Agendamento <b>Vence hoje</b>
        </span>
      );
    case 'ACTIVE':
      return (
        <span style={{ color: COLORS.blueText }}>
          Agendamento <b>Ativo</b>
        </span>
      );
    default:
      return '';
  }
};

export const getScheduleIconColor = scheduleStatus => {
  switch (scheduleStatus) {
    case 'EXPIRED':
      return COLORS.btnDangerColor;
    case 'EXPIRES_TODAY':
      return COLORS.WarningColorSLA;
    case 'ACTIVE':
      return COLORS.blueText;
    default:
      return '';
  }
};
