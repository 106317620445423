import { Button } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from 'notistack';
import ModalMagaluNewTicket from './ModalMagaluNewTicket';
import API from '../../../services/api';
import ModalNewTicket from './ModalNewTicket';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {}
}))(MenuItem);

const NewTicketSelection = ({ refreshTableContent }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [newMLTicketModalOpen, setNewMLTicketModalOpen] = useState(false);
  const [marketplace, setMarketplace] = useState('');

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const orderIdIsValid = async orderId => {
    try {
      const pedidosResponse = await API.get(
        `/auth/ms-ticket/v1/pedidos/${orderId}`
      );
      if (
        !pedidosResponse ||
        !pedidosResponse.data ||
        pedidosResponse.data?.marketplace !== 'Magazine Luiza'
      ) {
        return false;
      }
      return true;
    } catch (error) {
      if (error.response?.status !== 404) {
        enqueueSnackbar(
          `${t('i18n.simplephrases.ERROR')} - API Pedidos/Orders`,
          {
            variant: 'error'
          }
        );
        return false;
      }
      return false;
    }
  };

  const sendProtocol = async (storeSelected, orderId, protocolType) => {
    if (await orderIdIsValid(orderId)) {
      const postData = {
        store: storeSelected,
        slug: protocolType,
        idorder: orderId
      };
      try {
        await API.post(
          `/auth/ms-ticket/v1/tickets/magalu/create-protocol`,
          postData
        );
        setNewTicketModalOpen(false);
      } catch (error) {
        enqueueSnackbar(t('i18n.ticketcontainer.MAGALU_NEW_PROCOTOL_FAIL'), {
          variant: 'error'
        });
      }
    } else {
      enqueueSnackbar(t('i18n.orderinfo.NOT_FOUND_ORDER'), {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Button
        color="primary"
        size="small"
        onClick={e => {
          handleClick(e);
        }}
        startIcon={<AddIcon />}
      >
        {t('i18n.ticketslistview.NEW_GUIDE_TICKET')}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
          onClick={() => {
            setMarketplace('ACER STORE B2C');
            handleClose();
            setNewMLTicketModalOpen(true);
            // history.push('/newGuideTicket');
          }}
        >
          <ListItemIcon>
            <PlaylistAddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="ACER STORE B2C" />
        </StyledMenuItem>

        <StyledMenuItem
          onClick={() => {
            setMarketplace('ACER STORE B2B');
            handleClose();
            setNewMLTicketModalOpen(true);
            // history.push('/newGuideTicket');
          }}
        >
          <ListItemIcon>
            <PlaylistAddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="ACER STORE B2B" />
        </StyledMenuItem>

        <StyledMenuItem
          onClick={() => {
            setMarketplace('AMERICANAS');
            handleClose();
            setNewMLTicketModalOpen(true);
          }}
        >
          <ListItemIcon>
            <PlaylistAddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="AMERICANAS" />
        </StyledMenuItem>

        <StyledMenuItem
          onClick={() => {
            setMarketplace('CNOVA');
            handleClose();
            setNewMLTicketModalOpen(true);
          }}
        >
          <ListItemIcon>
            <PlaylistAddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="CNOVA" />
        </StyledMenuItem>

        <StyledMenuItem
          onClick={() => {
            handleClose();
            setNewTicketModalOpen(true);
          }}
        >
          <ListItemIcon>
            <PlaylistAddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('i18n.marketplaces.MAGALU')} />
        </StyledMenuItem>

        <StyledMenuItem
          onClick={() => {
            setMarketplace('MERCADO LIVRE');
            handleClose();
            setNewMLTicketModalOpen(true);
          }}
        >
          <ListItemIcon>
            <PlaylistAddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="MERCADO LIVRE" />
        </StyledMenuItem>
      </StyledMenu>

      <ModalNewTicket
        openState={newMLTicketModalOpen}
        marketplace={marketplace}
        setMarketplace={setMarketplace}
        closeParent={() => {
          setNewMLTicketModalOpen(false);
        }}
        refreshTableContent={refreshTableContent}
      />

      <ModalMagaluNewTicket
        openState={newTicketModalOpen}
        closeParent={() => {
          setNewTicketModalOpen(false);
        }}
        submitConfirmation={(storeSelected, orderId, protocolType) => {
          sendProtocol(storeSelected, orderId, protocolType);
        }}
      />
    </>
  );
};

export default NewTicketSelection;
