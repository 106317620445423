import React from 'react';
import { StyledInputWhiteBg } from '../../../components/StyledInputWhiteBg';

const AnalysisDescriptionInput = ({ value, onDebounce }) => {
  let searchInputTimeout = null;
  return (
    <>
      <StyledInputWhiteBg
        size="small"
        fullWidth
        variant="outlined"
        onChange={e => {
          const inputedValue = e.target.value;
          if (searchInputTimeout != null) {
            clearTimeout(searchInputTimeout);
          }
          searchInputTimeout = setTimeout(() => {
            onDebounce(inputedValue);
          }, 1000);
        }}
        onBlur={e => {
          onDebounce(e.target.value);
        }}
        defaultValue={value || ''}
      />
    </>
  );
};

export default AnalysisDescriptionInput;
