import { Typography, Button, FormControl, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import AcerModal from '../../../../../components/AcerModal';
import API from '../../../../../services/api';
import { COLORS } from '../../../../../styles/settings/colors.config';

const CustomBody = styled('div')`
  display: flex;
  justify-content: flex-start;
  padding: 0px 60px 0px;
  gap: 15px;
  margin: 20px 0px 0px;
`;

const AlertIcon = styled('div')`
  max-width: 60px;
  max-height: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLORS.btnWarningColor};
  color: ${COLORS.btnWarningColor};
  margin-right: 15px;
  font-size: 60px;
  width: 40%;
`;

const CustomTypography = styled(Typography)`
  color: ${COLORS.grayText};
`;

const ReopenForm = ({
  setShowForm,
  selectedSupport,
  setOpenDetailsModal,
  setOpenReopenTicketModal,
  fetchData
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { id } = selectedSupport;
  const [comment, setComment] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);

  const HandleReopenTicket = form => {
    setDisabledButton(true);
    form.preventDefault();
    const data = { comment, ticketId: id, action: 'Reaberto' };
    try {
      API.post(
        '/auth/ms-ticket/v1/marketplace-support/b2w/reopen-ticket',
        data
      ).then(() => {
        setDisabledButton(false);
        setOpenDetailsModal(false);
        setOpenReopenTicketModal(false);
        fetchData();
      });
    } catch (e) {
      enqueueSnackbar(t('i18n.B2W.SUPPORT.REOPEN_TICKET_ERROR'), {
        variant: 'error'
      });
    }
  };

  return (
    <form onSubmit={e => HandleReopenTicket(e)}>
      <CustomBody>
        <CustomTypography>
          Qual o motivo para reabrir o chamado?
        </CustomTypography>
      </CustomBody>

      <CustomBody>
        <FormControl fullWidth>
          <TextField
            id="outlined-multiline-static"
            label="Motivo da reabertura"
            multiline
            fullWidth
            rows={3}
            variant="outlined"
            required
            value={comment}
            onChange={e => setComment(e.target.value)}
          />
        </FormControl>
      </CustomBody>

      <CustomBody style={{ background: COLORS.gray }}>
        <div style={{ padding: '20px 0px', display: 'flex' }}>
          <AlertIcon>!</AlertIcon>
          <CustomTypography>
            Este pedido de reabertura será <b>avaliado</b> nas próximas{' '}
            <b>
              <span style={{ color: COLORS.btnWarningColor }}>48 horas</span>
            </b>
            . Após análise, se os motivos forem coerentes, o chamado será
            reaberto e você será avisado
          </CustomTypography>
        </div>
      </CustomBody>
      <CustomBody>
        <div
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            width: '100%',
            marginBottom: '20px'
          }}
        >
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => setShowForm(false)}
          >
            Voltar
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            type="submit"
            disabled={disabledButton}
          >
            Enviar
          </Button>
        </div>
      </CustomBody>
    </form>
  );
};

const ReopenContent = ({ setOpenReopenTicketModal, setShowForm }) => {
  return (
    <>
      <CustomBody>
        <CustomTypography>
          Se quiser conversar{' '}
          <b>
            sobre o{' '}
            <span style={{ color: COLORS.btnWarningColor }}>mesmo assunto</span>
          </b>
          , pode <b>reabrir o chamado</b> no máximo <b>até duas vezes</b>.
        </CustomTypography>
      </CustomBody>
      <CustomBody>
        <CustomTypography>
          Se for para conversar sobre{' '}
          <b>
            <span style={{ color: COLORS.btnWarningColor }}>outro assunto</span>
          </b>
          , abra um <b>novo chamado</b>
        </CustomTypography>
      </CustomBody>
      <CustomBody style={{ background: COLORS.gray }}>
        <div style={{ padding: '20px 0px', display: 'flex' }}>
          <AlertIcon>!</AlertIcon>
          <CustomTypography>
            Para chamados <b>reabertos com um assunto diferente</b> do
            atendimento inicial, será considerado um{' '}
            <b>
              <span style={{ color: COLORS.btnWarningColor }}>novo prazo</span>
            </b>{' '}
            para atender.
          </CustomTypography>
        </div>
      </CustomBody>
      <CustomBody>
        <CustomTypography variant="h4">O que deseja fazer?</CustomTypography>
      </CustomBody>
      <CustomBody>
        <div
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            width: '100%',
            marginBottom: '20px'
          }}
        >
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => setOpenReopenTicketModal(false)}
          >
            Voltar
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => setShowForm(true)}
          >
            Reabrir este chamado
          </Button>
        </div>
      </CustomBody>
    </>
  );
};

export default function ReopenTicketModal({
  openReopenTicketModal,
  setOpenReopenTicketModal,
  selectedSupport,
  setOpenDetailsModal,
  fetchData
}) {
  const [showForm, setShowForm] = useState(false);
  return (
    <AcerModal
      fullWidth
      maxWidth="sm"
      onClose={() => setOpenReopenTicketModal(false)}
      open={openReopenTicketModal}
      modalTitle="Reabrir um chamado"
      freeBody
    >
      {!showForm && (
        <ReopenContent
          {...{ openReopenTicketModal, setOpenReopenTicketModal, setShowForm }}
        />
      )}
      {showForm && (
        <ReopenForm
          {...{
            openReopenTicketModal,
            setOpenReopenTicketModal,
            setShowForm,
            selectedSupport,
            setOpenDetailsModal,
            fetchData
          }}
        />
      )}
    </AcerModal>
  );
}
