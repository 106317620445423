import { Box, Grid } from '@material-ui/core';
import { MonetizationOn } from '@material-ui/icons';
import React from 'react';
import {
  SectionText,
  SectionTextDiv,
  SectionTotalDetails
} from './SectionTabs';

const ProductSubtotal = ({ orderData, orderFromMarketplaceBundle, t }) => {
  let total = 0.0;
  if (orderData?.itens) {
    orderData.itens.forEach(item => {
      total += item.totalBruto;
    });
  }

  let totalDiscount = 0.0;
  let totalDiscountRaw = 0;
  if (orderData?.itens?.length > 0) {
    orderData.itens.forEach(element => {
      totalDiscount += element.totalDesconto || 0.0;
      totalDiscountRaw += element.totalDesconto || 0.0;
    });
  }

  let totalPayment = 0.0;
  totalPayment = total + (orderData?.totalFrete || 0.0) - totalDiscount;
  total = total.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  });
  totalDiscount = totalDiscount.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  });
  totalPayment = totalPayment.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  });

  return (
    <Grid container spacing={2}>
      <SectionTotalDetails item mt="true" lg={12} xs={12}>
        <SectionText>Subtotal:</SectionText>
        <SectionText>{total}</SectionText>
      </SectionTotalDetails>
      <SectionTotalDetails item lg={12} xs={12}>
        <SectionText>Desconto:</SectionText>
        <SectionText>{totalDiscount}</SectionText>
      </SectionTotalDetails>
      {totalDiscountRaw > 0 && (
        <SectionTotalDetails item lg={12} xs={12}>
          {orderData?.cuponsDesconto?.length > 0 &&
            orderData?.cuponsDesconto?.map(cupom => {
              return (
                <SectionTextDiv mb bold fSize="12px" key={cupom.cupom}>
                  <Box display="flex" alignItems="center">
                    <MonetizationOn
                      style={{ marginRight: '3px' }}
                      color="primary"
                    />
                    {cupom.cupom || t('i18n.orderinfo.NOT_PROVIDED')}
                  </Box>
                </SectionTextDiv>
              );
            })}
          {(!orderData?.cuponsDesconto ||
            orderData?.cuponsDesconto?.length === 0) &&
            orderFromMarketplaceBundle?.order?.payments?.map(payment => {
              return (
                <span key={Math.random()}>
                  {payment?.coupon_id && (
                    <SectionTextDiv mb bold fSize="12px">
                      <Box display="flex" alignItems="center">
                        <MonetizationOn
                          style={{ marginRight: '3px' }}
                          color="primary"
                        />

                        {payment.coupon_id || t('i18n.orderinfo.NOT_PROVIDED')}
                      </Box>
                    </SectionTextDiv>
                  )}
                </span>
              );
            })}
        </SectionTotalDetails>
      )}
      <SectionTotalDetails item lg={12} xs={12}>
        <SectionText>Frete:</SectionText>
        <SectionText>
          {orderData?.totalFrete?.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL'
          }) || 'R$ 0,00'}
        </SectionText>
      </SectionTotalDetails>
      <SectionTotalDetails borderdotted="true" item lg={12} xs={12}>
        <SectionText bold>Total:</SectionText>
        <SectionText bold>{totalPayment}</SectionText>
      </SectionTotalDetails>
    </Grid>
  );
};

export default ProductSubtotal;
