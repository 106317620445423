import { Button, Grid, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useMarketplaceList } from '../../../../hooks/hooks';
import marketPlacesEnum from '../../../../enums/marketPlaces.enum';
import { COLORS } from '../../../../styles/settings/colors.config';
import MarketPlaceToolbar from '../MarketPlaceToolbar/MarketPlaceToolbar';
import Tags from './Tags';
import { Campaign } from './Campaign';
import { isB2BProtocol } from '../../../../utils/b2bHelper';

const StyledTicketHeader = styled.header`
  border-bottom: 2px solid ${COLORS.cardMessageGray};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 0 0 24px;

  .header-details {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    width: 38%;

    img {
      position: relative;
      top: 2px;
      width: 90px;
    }

    .store-ml {
      top: 5px;
      width: 55px;
    }

    .store-acer {
      width: 110px;
    }

    .store-b2w {
      width: 55px;
    }

    &--marketplace {
      border-right: 3px solid #999999;
      color: ${COLORS.grayText};
      font-size: 28px;
      margin: 5px 32px 0 24px;
      padding-right: 32px;
      text-transform: capitalize;
    }

    &--marketplaceNoTextTransform {
      border-right: 3px solid #999999;
      color: ${COLORS.grayText};
      font-size: 28px;
      margin: 5px 32px 0 24px;
      padding-right: 32px;
    }

    &--ticket {
      color: ${COLORS.greenText};
      font-size: 27px;
      font-weight: bold;
      margin: 5px 0 0;
    }
  }

  .header-details--action {
    align-self: flex-end;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    top: 16px;

    button:not(:last-child) {
      &::after {
        background: #999999;
        content: '';
        height: 24px;
        margin-left: 24px;
        width: 1px;
      }
    }
  }

  .header-btn--icon {
    margin-right: 16px;
  }
`;

const cnovaStoreName = store => {
  switch (store) {
    case 'CASASBAHIA':
      return 'Casas Bahia';
    case 'PONTOFRIO':
      return 'Ponto Frio';
    case 'EXTRA':
      return 'Extra';
    default:
      return store;
  }
};

const b2wStoreName = store => {
  switch (store) {
    case 'AMERICANASEMPRESAS':
      return 'Americanas Empresas';
    case 'ShopTime':
      return 'Shoptime';
    default:
      return store;
  }
};

const magaluStoreName = store => {
  switch (store) {
    case 'aceroficial':
      return 'ec.brasil';
    case 'mobbibuy':
      return 'storecorp.brasil';
    default:
      return store || 'Magalu';
  }
};

const logistaNetshoes = id => {
  switch (id) {
    case '9364':
      return 'Acer do Brasil';
    default:
      return 'Acer';
  }
};

const getLogoImg = marketPlace => {
  return (
    <img
      src={marketPlace.logo}
      alt={`Logo da loja ${marketPlace.text}`}
      className={marketPlace.logoStyle}
      style={{ marginBlockStart: marketPlace.value === 'VIVO' ? '-20px' : '' }}
    />
  );
};

const getMarketplaceName = (ticketData, marketPlace, t) => {
  switch (ticketData?.marketPlace) {
    case 'CUSTOMER_GUIDE':
      return 'Acer Store B2C';
    case 'CUSTOMER_GUIDE_B2B':
      return 'Acer Store B2B';
    case marketPlacesEnum.CNOVA:
      return cnovaStoreName(ticketData?.marketplaceBundle?.marketPlace);
    case marketPlacesEnum.B2W:
      return b2wStoreName(ticketData?.marketplaceBundle?.dataOrders?.brand);
    case marketPlacesEnum.MAGALU:
      return magaluStoreName(ticketData?.marketplaceBundle?.store);
    case marketPlacesEnum.NETSHOES:
      return logistaNetshoes(ticketData?.marketplaceBundle?.codigoLojista);
    default:
      return t(`i18n.marketplaces.${marketPlace}`).toLocaleLowerCase();
  }
};

const TicketTitle = ({
  marketPlace,
  ticketType,
  setOpenLinkModal,
  ticketData,
  raTicketsList,
  raTicketsFromProtocol,
  setFilterLinkedRaTickets
}) => {
  const { t } = useTranslation();
  const marketPlaces = useMarketplaceList();

  const currentMarketPlace = marketPlaces.find(
    mktp => mktp.value === marketPlace
  );

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <StyledTicketHeader>
          <div className="header-details">
            {getLogoImg(currentMarketPlace)}

            <Typography
              component="h2"
              variant="h2"
              className={
                ticketData?.marketPlace === marketPlacesEnum.MAGALU
                  ? 'header-details--marketplaceNoTextTransform'
                  : 'header-details--marketplace'
              }
            >
              {getMarketplaceName(ticketData, marketPlace, t)}
            </Typography>
            <Typography
              variant="h3"
              component="span"
              className="header-details--ticket"
            >
              {t(`i18n.newAutomation.${ticketType}`)}
            </Typography>
          </div>

          <div className="header-details--action">
            <MarketPlaceToolbar ticketData={ticketData} />
            {(raTicketsList?.length > 0 ||
              raTicketsFromProtocol?.length > 0) && (
              <>
                {isB2BProtocol(ticketData) && (
                  <Button
                    onClick={() => {
                      setOpenLinkModal(true);
                      setFilterLinkedRaTickets(false);
                    }}
                    color="primary"
                  >
                    <ErrorIcon
                      style={{ fill: 'red' }}
                      fontSize="small"
                      className="header-btn--icon"
                    />
                    {t('i18n.ticketslistview.LINK_COMPLAINT')}
                  </Button>
                )}
              </>
            )}
          </div>
        </StyledTicketHeader>
      </Grid>
      <Grid item xs={6}>
        <Tags ticketData={ticketData} />
      </Grid>
      {isB2BProtocol(ticketData) && (
        <Grid item xs={6}>
          <Campaign data={ticketData} type="tickets" />
        </Grid>
      )}
    </Grid>
  );
};

export default TicketTitle;
