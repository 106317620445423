import { Box, CircularProgress, Container } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StyledSpinner } from '../../styles/components/SpinnerDefault';
import Page from '../../components/Page';
import API from '../../services/api';
import {
  CustomDataTableWrapper,
  CustomDataTable,
  CustomTableButton
} from '../../styles/components/CustomDataTable';
import CustomFooter from '../../styles/components/CustomFooter';
import HeaderTitle from '../../components/HeaderTitle';

const AutomationList = () => {
  const { t } = useTranslation();
  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'name',
      label: t('i18n.groupcontainer.NAME'),
      options: {
        filter: false,
        sort: false
      }
      // options: defaultColumOptions
    },
    {
      name: 'description',
      label: t('i18n.newticket.DESCRIPTION'),
      options: {
        filter: false,
        sort: false
      }
      // options: defaultColumOptions
    },
    {
      name: 'frequency',
      label: t('i18n.automationslistview.FREQUENCY'),
      options: {
        customBodyRender: (value) => {
          return t(`i18n.automationslistview.frequency.${value}`);
        },
        filter: false,
        sort: false
      }
      // options: defaultColumOptions
    },
    {
      name: 'enabled',
      label: t('i18n.groupcontainer.ACTIVE'),
      options: {
        customBodyRender: (value) => {
          if (value) {
            return t('i18n.groupcontainer.YES');
          }
          return t('i18n.groupcontainer.NO');
        },
        filter: false,
        sort: false
      }
    },
    {
      name: 'lastExecution',
      label: t('i18n.automationslistview.LAST_EXECUTATION'),
      options: {
        customBodyRender: (value) => {
          if (!value) {
            return '-';
          }
          const responseDate = moment(value).format('DD/MM/YYYY HH:mm');
          return responseDate;
        },
        filter: false,
        sort: false
      }
    }
  ];
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [currentSelectedRows, setCurrentSelectedRows] = useState([]);

  const refreshTableContent = (tableState) => {
    const url = `/auth/ms-ticket/v1/automations?page=${
      tableState ? tableState.page : 0
    }&size=${tableState ? tableState.rowsPerPage : 10}`;
    setLoad(false);
    API.get(url)
      .then((response) => {
        setLoad(true);
        if (response.data._embedded) {
          setData(response.data._embedded.automationResourceList);
        }
      })
      .catch(() => {
        setLoad(false);
      });
  };

  const deleteAutomation = (id) => {
    API.delete(`/auth/ms-ticket/v1/automations/${id}`)
      .then(() => {
        enqueueSnackbar(
          t('i18n.automationlistview.AUTOMATION_DELETE_SUCCESS'),
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          }
        );
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.automationlistview.AUTOMATION_DELETE_ERROR'), {
          variant: 'error'
        });
      });
  };

  const options = {
    serverSide: true,
    pagination: true,
    selectableRowsHeader: false,
    download: false,
    print: false,
    filter: false,
    search: false,
    viewColumns: false,
    onRowsDelete: () => {
      for (let i = 0; i < displayData.length; i++) {
        const item = displayData[i];
        const id = item.data[0];
        if (currentSelectedRows.includes(i)) {
          deleteAutomation(id);
        }
      }
    },
    onTableChange: (action, tableState) => {
      if (action === 'changePage' || action === 'changeRowsPerPage') {
        refreshTableContent(tableState);
      }

      if (action === 'rowSelectionChange') {
        const temp = [];
        tableState.selectedRows.data.forEach((item) => temp.push(item.index));
        setCurrentSelectedRows(temp);
        setDisplayData(tableState.displayData);
      }
    },
    customToolbar: () => {
      return (
        <>
          <CustomTableButton
            variant="text"
            color="primary"
            size="small"
            onClick={() => {
              history.push('/automation/new');
            }}
            startIcon={<AddIcon />}
          >
            {t('i18n.automationlistview.NEW_AUTOMATION')}
          </CustomTableButton>
        </>
      );
    },
    onRowClick: (rowData) => {
      history.push({
        pathname: `/automation/${String(rowData[0])}`,
        state: { data: rowData[0] }
      });
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: load
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: (column) =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };

  return (
    <Page title={t('i18n.dashboardlayout.AUTOMATIONS')}>
      <Container maxWidth={false}>
        <Box mt={3}>
          <CustomDataTableWrapper style={{ opacity: !load ? '0.3' : '1' }}>
            <CustomDataTable
              title={
                <HeaderTitle title={t('i18n.dashboardlayout.AUTOMATIONS')} />
              }
              data={data}
              columns={columns}
              selectableRowsOnClick
              options={options}
              className="table-clickable"
            />
          </CustomDataTableWrapper>
          <StyledSpinner style={{ display: !load ? 'block' : 'none' }}>
            <CircularProgress />
          </StyledSpinner>
        </Box>
      </Container>
    </Page>
  );
};

export default AutomationList;
