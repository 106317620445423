import { Box, CircularProgress } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionSummary,
  Notify,
  NotifyTitle,
  NotifyWrapper
} from '../styles/styles';

const TabVisitorsTable = ({ expanded }) => {
  const showVisitors = useSelector(state => state.showVisitorsReducer);
  const visitorsOnline = useSelector(state => state.visitorsOnlineReducer);

  const reduxDispatcher = useDispatch();

  const HandleShowVisitors = () => {
    reduxDispatcher({
      type: 'SHOW_VISITORS',
      showVisitors: !showVisitors
    });

    reduxDispatcher({
      type: 'SHOW_CLIENT_TALK',
      showClientTalk: false
    });

    if (showVisitors) {
      reduxDispatcher({
        type: 'VISITORS_ONLINE',
        visitorsOnline: (
          <CircularProgress
            size={8}
            style={{ color: 'white' }}
            component="span"
          />
        )
      });
    }
  };

  return (
    <Accordion
      square
      expanded={expanded === 'panel5'}
      onChange={HandleShowVisitors}
    >
      <AccordionSummary
        expandIcon={showVisitors ? <ChevronLeft /> : <ChevronRight />}
        aria-controls="panel4d-content"
        id="panel4d-header"
      >
        <NotifyWrapper>
          {!showVisitors ? (
            <Notify>{visitorsOnline}</Notify>
          ) : (
            <Box style={{ marginLeft: '36px' }} />
          )}
          <NotifyTitle>
            {showVisitors ? 'Voltar aos Chats' : 'Visitantes on-line'}
          </NotifyTitle>
        </NotifyWrapper>
      </AccordionSummary>
    </Accordion>
  );
};

export default TabVisitorsTable;
