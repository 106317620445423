import { Grid, Card, CardContent } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AcerModal from '../../../components/AcerModal';
import timezoneFormat from '../../../utils/timezoneFormat';
import { TabText } from '../styles';

const PreSaleProductDetailsModal = ({
  openState,
  closeModal,
  detailsModalData
}) => {
  const { t } = useTranslation();
  const data = detailsModalData?.marketplaceBundle?.user;
  const marketplace = detailsModalData?.marketPlace;

  return (
    <AcerModal
      fullWidth
      maxWidth="sm"
      open={openState}
      modalTitle={t('i18n.presale.INFOS')}
      onClose={closeModal}
    >
      <Grid
        container
        spacing={2}
        style={{ maxHeight: '380px', overflowY: 'auto', overFlowX: 'hidden' }}
      >
        <Grid item xs={12}>
          <Card>
            {marketplace === 'MERCADO_LIVRE' && (
              <CardContent>
                <TabText>
                  <b>Id: </b>
                  {data?.id}
                </TabText>
                <TabText>
                  <b>{t('i18n.userview.NAME')}: </b>
                  {data?.nickname}
                </TabText>
                <TabText>
                  <b>{t('i18n.CAP.city')}: </b>
                  {data?.address?.city}
                </TabText>
                <TabText>
                  <b>{t('i18n.CAP.uf')}: </b>
                  {data?.address?.state}
                </TabText>
                <TabText>
                  <b>{t('i18n.presale.REGISTRATION_DATE_IN')}: </b>
                  {timezoneFormat(data?.registration_date)}
                </TabText>
              </CardContent>
            )}
            {marketplace === 'YOURVIEWS' && (
              <CardContent>
                <TabText>
                  <b>{t('i18n.userview.NAME')}: </b>
                  {detailsModalData?.customerName}
                </TabText>
                <TabText>
                  <b>{t('i18n.simplewords.EMAIL')}: </b>
                  {detailsModalData?.customerEmail}
                </TabText>
              </CardContent>
            )}
          </Card>
        </Grid>
      </Grid>
    </AcerModal>
  );
};

export default PreSaleProductDetailsModal;
