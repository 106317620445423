/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { Box, CardContent, CardHeader, Divider } from '@material-ui/core';
import { ThemeContext } from '../../../layouts/MonitorLayout';

import { StatusComponent } from './status.component';
import { AttendantCardV2 } from './attendantCard.component';
import { CustomCard, CustomTypography } from './card.component';

const AttendantList = ({ monitorData }) => {
  const theme = useContext(ThemeContext);

  const column =
    monitorData?.chats_status?.chats_per_clerks.length < 7
      ? 1
      : monitorData?.chats_status?.chats_per_clerks < 14
      ? 2
      : 3;

  const clerksOnline = monitorData?.chats_status?.chats_per_clerks.filter(
    item => {
      return item.online === true;
    }
  );

  const clerksOffline = monitorData?.chats_status?.chats_per_clerks.filter(
    item => {
      return item.online === false;
    }
  );

  return (
    <CustomCard
      {...{
        header: 'Distribuição Equipe - ',
        title: 'Em andamento',
        color: theme.successV2,
        flag: <StatusComponent color={theme.color} online />
      }}
    >
      <CardContent>
        {clerksOnline?.length > 0 ? (
          <Box style={{ columnCount: column }}>
            {clerksOnline?.map(item => (
              <AttendantCardV2 {...item} key={item.clerk} />
            ))}
          </Box>
        ) : (
          <Box>
            <CustomTypography font="1rem" color={theme.color}>
              Não há nenhum atendente Online
            </CustomTypography>
          </Box>
        )}
      </CardContent>
      <CardHeader
        action={<StatusComponent color={theme.color} online={false} />}
      />
      <Divider />
      <CardContent>
        {clerksOffline?.length > 0 ? (
          <Box style={{ columnCount: column }}>
            {clerksOffline?.map(item => (
              <AttendantCardV2 {...item} key={item.clerk} />
            ))}
          </Box>
        ) : (
          <Box>
            <CustomTypography font="1rem" color={theme.color}>
              Não há nenhum atendente Offline
            </CustomTypography>
          </Box>
        )}
      </CardContent>
    </CustomCard>
  );
};

export { AttendantList };
