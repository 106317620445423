import {
  Box,
  FormControl,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import React from 'react';
import { TransportSectionHeader } from '../../../Dialogs/styles';
import { useStyles } from './styles';

export default function RetrieveInfo({
  reversedBy,
  setReversedBy,
  detail,
  setDetail
}) {
  const classes = useStyles();
  return (
    <Box mt={2} display="flex" style={{ padding: '5px 10px' }}>
      <Box display="flex" flexDirection="column" width="90%" mr={3}>
        <TransportSectionHeader>Estorno realizado por</TransportSectionHeader>
        <FormControl variant="outlined" className={classes.inputSelect}>
          <Select
            value={reversedBy || ''}
            onChange={e => {
              setReversedBy(e.target.value);
            }}
          >
            <MenuItem disabled value="">
              Selecione uma opção
            </MenuItem>

            <MenuItem value="ACER">ACER</MenuItem>
            <MenuItem value="MARKETPLACE">MARKETPLACE</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box display="flex" flexDirection="column" width="90%">
        <TransportSectionHeader>Comentários</TransportSectionHeader>
        <TextField
          defaultValue={detail}
          size="small"
          fullWidth
          variant="outlined"
          onChange={e => {
            setDetail(e.target.value);
          }}
          InputProps={{
            className: classes.input100
          }}
        />
      </Box>
    </Box>
  );
}
