import styled from 'styled-components';
import { Box, Card, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { COLORS } from '../../styles/settings/colors.config';

// export const SectionParam = styled('p')`
//   font-size: 18px;
//   font-weight: bold;
//   padding: 0px 0px 10px 0px;
//   margin-bottom: 10px;
//   margin-top: 20px;
//   color: ${COLORS.grayText};
// `;

export const ContainerParam = styled(Grid)`
  margin-left: 13px;
`;

export const PageTitle = styled(Box)`
  margin-left: 24px;
  margin-top: 40px;
  margin-bottom: 15px;
`;

export const DividerTitle = styled(Divider)`
  margin-left: 24px;
`;

export const TypoTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 400;
  color: ${COLORS.blackText};
`;

export const PaperParam = styled(Paper)`
  padding: 20px 20px 10px 20px;
  height: 100%;
`;

export const DescriptionParam = styled(Box)`
  gap: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const InputValue = styled(Box)`
  gap: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
`;

export const CardParam = styled(Card)`
  border-radius: 20px;
  margin-left: 13px;
  @media screen and (min-width: 1024px) {
    width: 53%;
  }
  @media screen and (min-width: 1440px) {
    width: 50%;
  }
  @media screen and (min-width: 1920px) {
    width: 40%;
  }
`;

export const BoxParam = styled(Box)``;

export const BoxActions = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;
