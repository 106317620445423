import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectComponent from '../../../../components/Select.component';

const B2WStatus = ({ filterForm, handleSetFilter }) => {
  const { t } = useTranslation();

  const options = [
    { name: t('i18n.ticketslistview.B2W_INSTANCES_NEW'), value: 'new' },
    {
      name: t('i18n.ticketslistview.B2W_INSTANCES_INPROGRESS'),
      value: 'inProgress'
    }
  ];

  return (
    <Box mb={2}>
      <Grid container>
        <Grid item xs={7}>
          <SelectComponent
            {...{
              form: filterForm,
              setForm: handleSetFilter,
              label: t('i18n.ticketslistview.B2W_STATUS'),
              name: 'filtersB2W'
            }}
            options={options.map(({ name, value }) => {
              return { name, value };
            })}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default B2WStatus;
