import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import statusEnum from '../enums/ticketsStatus.enum';

/**
 * Status Select to forms
 * @param {String} label - String - Name of the checkbox group
 * @param {String} Name - String - Name of the field
 * @param {Object} form - Object - State of form that recieve the checkbox values
 * @param {Function} setForm - Function - Open controller
 */

const SelectStatus = ({ form, setForm, name, label }) => {
  const { t } = useTranslation();

  return (
    <FormControl variant="outlined" fullWidth size="small">
      <InputLabel>{label}</InputLabel>
      <Select
        defaultValue=""
        className="small-input"
        label={label}
        value={form[name] || ''}
        name={name}
        onChange={e => {
          setForm(e);
        }}
      >
        <MenuItem value="">
          <em>{t('i18n.ticketslistview.NONE')}</em>
        </MenuItem>
        {statusEnum.map(status => {
          return (
            <MenuItem value={status} key={status}>
              {t(`i18n.ticketslistview.ticketStatus.${status}`)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectStatus;
