import {
  Box,
  Button,
  Grid,
  Typography,
  ThemeProvider,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import './styles.css';

import { COLORS } from '../../../styles/settings/colors.config';

import { theme } from '../../../components/inputStyle';

const FilterWrapper = styled('div')`
  overflow-y: auto;
  max-height: 650px;
  overflow-x: hidden;
  height: 100%;
  margin-bottom: 20px;
  padding-right: 15px;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: ${COLORS.btnSecondaryColor};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.btnSecondaryColor};
    border: 2px solid ${COLORS.btnSecondaryColor};
  }

  @media (max-width: 1366px) {
    max-height: 390px;
  }
`;

const FilterContainer = ({
  setFilterData,
  setOpen,
  removedChip,
  setRemovedChip,
  companyList
}) => {
  const { t } = useTranslation();
  const [filterForm, setFilterForm] = useState({});

  const submitFilter = () => {
    setFilterData({
      companyName: filterForm?.companyName || ''
    });
    setOpen(false);
  };

  const clearFilterForm = () => {
    setFilterForm({});
  };

  const updateFormAfterRemoveChip = key => {
    switch (key) {
      case key:
        setFilterForm({ ...filterForm, [key]: '' });
        break;
      default:
        break;
    }
    setRemovedChip('');
  };

  useEffect(() => {
    if (removedChip) {
      updateFormAfterRemoveChip(removedChip);
    }
  }, [removedChip]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Grid container>
          <Grid item style={{ backgroundColor: '#f4f6f8' }}>
            <Box
              style={{
                width: '390px',
                padding: '12px 24px 0px',
                border: '0',
                display: 'Flex',
                flexDirection: 'column',
                borderLeft: '1px solid #ccc'
              }}
            >
              <FilterWrapper>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography variant="body1">
                    {t('i18n.ticketslistview.MORE_FILTER_OPTIONS')}
                  </Typography>
                </div>
                <br />
                <Box mt={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel>{t('i18n.companies.NAME')}</InputLabel>
                        <Select
                          defaultValue=""
                          value={filterForm.companyName || ''}
                          className="medium-input"
                          onChange={htmlElemtEvent => {
                            setFilterForm({
                              ...filterForm,
                              companyName: htmlElemtEvent.target.value
                            });
                          }}
                          label={t('i18n.companies.NAME')}
                        >
                          {companyList.map(company => {
                            return (
                              <MenuItem key={company.name} value={company.name}>
                                <em>{company.name}</em>
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                <br />
              </FilterWrapper>
              <br />
            </Box>
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            background: '#fff',
            padding: '15px'
          }}
        >
          <Button
            onClick={() => {
              clearFilterForm();
            }}
            variant="contained"
          >
            {t('i18n.ticketslistview.CLEAR')}
          </Button>
          <Button
            onClick={() => {
              submitFilter(true);
            }}
            variant="contained"
            color="primary"
          >
            {t('i18n.ticketslistview.SEARCH')}
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default FilterContainer;
