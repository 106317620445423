import { Link, TableCell, TableRow } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import moment from 'moment';
import { COLORS } from '../../../../../../../styles/settings/colors.config';
import { msToHM } from '../../../functions/functions';
import userService from '../../../../../../../services/user.service';

const StyledCell = styled(TableCell)`
  font-weight: bold;
  color: ${props => (props.color ? props.color : 'black')};
`;

export default function CustomRow({ ...props }) {
  const { data, currentItem } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const now = new Date();
  const dateAcer = currentItem.dateReceiptAcer
    ? new Date(currentItem.dateReceiptAcer)
    : new Date();

  const difference = now.getTime() - dateAcer.getTime();
  const SLA = msToHM(difference);

  const openDetails = () => {
    if (!userService.hasRole('ticket_detail')) {
      enqueueSnackbar(t('i18n.ticketslistview.DETAIL_DISABLED'), {
        variant: 'warning'
      });
      return false;
    }
    history.push({
      pathname: `/logistics-details/${currentItem.id}`
    });
  };

  return (
    <TableRow>
      {data.map((d, i) => {
        if (i === 0) {
          return (
            <StyledCell key={Math.random()}>
              <Link
                style={{ color: COLORS.greenText }}
                rel="noopener"
                target="_blank"
                href={`/logistics-details/${currentItem.id}`}
              >
                {d}
              </Link>
            </StyledCell>
          );
        }
        if (i === 1) {
          return (
            <StyledCell key={Math.random()} onClick={openDetails}>
              {d ? moment(d).format('DD/MM/YYYY') : ''}
            </StyledCell>
          );
        }

        if (i === 2) {
          return (
            <StyledCell
              key={Math.random()}
              color={SLA.color}
              onClick={openDetails}
            >
              {`${SLA.absHours}h${SLA.absMinutes}m`}
            </StyledCell>
          );
        }

        if (i === 5) {
          return (
            <StyledCell
              key={Math.random()}
              color={COLORS.orange}
              onClick={openDetails}
            >
              {d}
            </StyledCell>
          );
        }

        return (
          <StyledCell key={Math.random()} onClick={openDetails}>
            {d}
          </StyledCell>
        );
      })}
    </TableRow>
  );
}
