import {
  TableCell,
  makeStyles,
  TableRow,
  CircularProgress,
  Box,
  createTheme,
  MuiThemeProvider
} from '@material-ui/core';

import MUIDataTable from 'mui-datatables';

import React, { useState, useEffect } from 'react';
import { CloudDownload, AddToPhotos } from '@material-ui/icons';
// import CustomFooter from '../../styles/components/CustomFooter';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useReportTypesList } from '../../../hooks/hooks';
import CustomFooter from '../../../styles/components/CustomFooter';
import { COLORS } from '../../../styles/settings/colors.config';
import API from '../../../services/api';

const useStyles = makeStyles({
  root: {
    '& .MuiPaper-rounded': {
      backgroundColor: 'transparent'
    }
  },
  cell: {
    borderTop: `1px solid ${COLORS.borderGray}`,
    borderBottom: `1px solid ${COLORS.borderGray}`,
    fontWeight: 'bold',
    backgroundColor: 'white'
  },
  leftBorder: {
    borderLeft: `1px solid ${COLORS.borderGray}`,
    borderRadius: '10px 0 0 10px'
  },
  rightBorder: {
    borderRight: `1px solid ${COLORS.borderGray}`,
    borderRadius: '0 10px 10px 0px'
  },
  defaultColor: {
    color: '#666666'
  },
  blueColor: {
    color: '#0B5394'
  }
});

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MUIDataTable: {
        tableRoot: {
          backgroundColor: 'transparent',
          borderCollapse: 'separate',
          borderSpacing: '0 10px'
        },
        paper: {
          boxShadow: 'none',
          backgroundColor: 'transparent'
        },
        head: {
          backgroundColor: 'transparent'
        }
      },
      MUIDataTableHeadCell: {
        root: {
          borderBottom: 'none',
          backgroundColor: 'transparent !important'
        }
      },
      MuiTableCell: {
        head: {
          backgroundColor: 'transparent !important'
        }
      },
      MuiTableBody: {
        root: {
          backgroundColor: 'white !important'
        }
      }
    }
  });

const CustomRow = props => {
  const { data, reportData, setBeDuplicated } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [filteredFullData, setFilteredFullData] = useState({});

  useEffect(() => {
    // eslint-disable-next-line
    const filter = reportData?.find((r) => r._id === data[3]);
    setFilteredFullData(filter);
  }, [reportData]);

  const downloadXlsDashboard = () => {
    setIsLoading(true);
    // eslint-disable-next-line
    API.get(`auth/lambda/reports?id=${filteredFullData._id}`)
      .then(response => {
        const downloadUrl = response.data.data;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'Report.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.simplephrases.DOWNLOAD_ERROR'), {
          variant: 'error'
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const duplicateReport = () => {
    // eslint-disable-next-line
    const itemData = reportData.find((item) => item._id === data[3]);
    setBeDuplicated(itemData);
  };

  const reportTypes = useReportTypesList();

  return (
    <TableRow key={Math.random()}>
      {data.map((d, i) => {
        const returnValue = () => {
          if (i === 0) {
            return (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                width="150px"
              >
                {moment(d).format('DD/MM/YYYY')} às {moment(d).format('HH:mm')}
              </Box>
            );
          }

          if (i === 2) {
            return (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                width="200px"
              >
                {reportTypes.find(item => item.value === d)?.label}
              </Box>
            );
          }

          if (i === 3) {
            if (
              filteredFullData?.status !== 'OK' &&
              filteredFullData?.status !== 'ERROR' &&
              filteredFullData?.status !== 'EMPTY'
            )
              return (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  width="200px"
                >
                  <span style={{ color: '#ED7D31' }}>
                    {t('i18n.dashboard.mktplacereport.processing')}
                  </span>
                </Box>
              );

            if (filteredFullData?.status === 'ERROR')
              return (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  width="200px"
                >
                  <span style={{ color: 'red' }}>
                    {t('i18n.dashboard.mktplacereport.error_processing')}
                  </span>
                </Box>
              );

            if (filteredFullData?.status === 'EMPTY')
              return (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  width="200px"
                >
                  <span style={{ color: 'red' }}>
                    {t('i18n.dashboard.mktplacereport.no_data')}
                  </span>
                </Box>
              );

            return (
              <span
                key={Math.random()}
                style={{
                  color: `${COLORS.greenText}`
                }}
              >
                {isLoading ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    width="200px"
                  >
                    <CircularProgress size={20} />

                    <span style={{ marginLeft: '10px' }}>
                      {t('i18n.dashboard.mktplacereport.processing')}
                    </span>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="150px"
                  >
                    <CloudDownload
                      onClick={downloadXlsDashboard}
                      style={{ cursor: 'pointer', marginRight: '20px' }}
                    />
                    <AddToPhotos
                      onClick={duplicateReport}
                      style={{ cursor: 'pointer' }}
                    />
                  </Box>
                )}
              </span>
            );
          }

          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="500px"
            >
              {d}
            </Box>
          );
        };

        return (
          <TableCell
            key={Math.random()}
            className={`${classes.cell} ${i === 0 && classes.leftBorder} ${i ===
              Number(data.length) - 1 && classes.rightBorder} ${
              i === 1 ? classes.blueColor : classes.defaultColor
            }`}
          >
            {returnValue()}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default function CustomRoundedTable({
  count,
  rows,
  columns,
  rowsPerPage,
  reportData,
  setCurrentPage,
  page,
  setBeDuplicated
}) {
  const { t } = useTranslation();
  const options = {
    selectableRows: 'none',
    filter: false,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    sort: true,
    rowsPerPage,
    hint: 'Ordenar',
    customRowRender: data => {
      return (
        <CustomRow
          {...{ data, reportData, setBeDuplicated }}
          key={Math.random()}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: t('i18n.datatable.body.NOMATCH'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: column =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    },
    customFooter: (...params) => {
      const [
        // eslint-disable-next-line
        rowCount,
        // eslint-disable-next-line
        internalPage,
        // eslint-disable-next-line
        internalRowsPerPage,
        changeRowsPerPage,
        // eslint-disable-next-line
        changePage,
        textLabels
      ] = params;
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={() => {}}
          rowsPerPageOptions={[15, 30, 50, 100]}
          // eslint-disable-next-line
          callback={(pageNumber, rowsPage) => {
            setCurrentPage(pageNumber);
            // refreshTableContent(pageNumber, rowsPage);
          }}
        />
      );
    }
  };

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable data={rows} columns={columns} options={options} />
    </MuiThemeProvider>
  );
}
