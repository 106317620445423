import _ from 'lodash';
import React from 'react';
import { floatToDecimal } from '../../../../../helpers/utils';
import { COLORS } from '../../../../../styles/settings/colors.config';
import { CustomTypographLink, MainCardData } from '../../../styles';

const GoalPercentInfo = ({ item }) => {
  const percentColor = () => {
    if (item.currentPercent === 0) {
      return '#999999';
    }

    if (item.currentPercent > item.goalPercent) {
      return COLORS.btnDangerColor;
    }

    return '#999999';
  };
  const filterMarketPlace = JSON.parse(sessionStorage.getItem('newfilter'));

  return (
    <div style={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '500' }}>
      {filterMarketPlace?.marketplace[0] !== 'CUSTOMER_GUIDE_B2B' ? (
        <>
          <span
            style={{
              color: percentColor(),
              marginRight: '8px'
            }}
          >
            {_.isNaN(item.currentPercent)
              ? 0
              : floatToDecimal(item.currentPercent * 100)}
            %
          </span>
          <span style={{ color: '#4F88C4' }}>Meta = {item.goalPercent}%</span>
        </>
      ) : null}
    </div>
  );
};

const TeamDataCard = ({ item, handleOpenNewTab, isTeamView }) => {
  const { secondaryData } = item;

  const labelClass = () => {
    if (item.subtitle === 'Vencido') {
      return 'sla-label-expired ';
    }

    if (item.subtitle === 'Vence hoje') {
      return 'sla-label-expires ';
    }

    return 'label';
  };

  return (
    <MainCardData>
      <p className={labelClass()} style={{color: item.color}}>{item.subtitle}</p>
      <div>
        {item.action && isTeamView ? (
          <CustomTypographLink
            style={{ fontSize: '30px', fontWeight: '500' }}
            onClick={() => handleOpenNewTab(item.subtitle)}
          >
            {secondaryData}
          </CustomTypographLink>
        ) : (
          <p className="main-data-stronger">{secondaryData}</p>
        )}
      </div>

      {item.showCurrentPercent && <GoalPercentInfo {...{ item }} />}
    </MainCardData>
  );
};

export default TeamDataCard;
