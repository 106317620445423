import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AcerModal from '../../../../components/AcerModal';

const ConfirmationModal = ({ openState, closeParent, submitConfirmation }) => {
  const { t } = useTranslation();

  return (
    <AcerModal
      maxWidth="sm"
      fullWidth
      open={openState}
      onClose={closeParent}
      modalTitle={`${t('i18n.ticketcontainer.B2W_MODAL_TITLE')}?`}
      hasAction
      confirmActionLabel={t('i18n.simplewords.CONFIRM')}
      confirmActionFunc={() => submitConfirmation()}
      cancelActionLabel={t('i18n.simplewords.CANCEL')}
    >
      <Typography>{`${t(
        'i18n.ticketcontainer.B2W_MODAL_MESSAGE'
      )}.`}</Typography>
    </AcerModal>
  );
};

export default ConfirmationModal;
