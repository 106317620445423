import React from 'react';
import AcerModal from '../../../../../../components/AcerModal';
import { Cap } from '../../../RightMenuComponents/CAP/CAP';

export const AcerCapModal = ({ openCapModal, setOpenCapModal, ticketData }) => {
  return (
    <AcerModal
      open={openCapModal}
      onClose={() => setOpenCapModal(false)}
      modalTitle=""
    >
      <Cap
        ticketData={ticketData}
        alreadyOpened={openCapModal}
        {...{
          setOpenCapModal,
        }}
      />
    </AcerModal>
  );
};
