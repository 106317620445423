import { Button, Divider } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import ConfirmationModal from './ConfirmationModal';
import API from '../../../../../services/api';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {}
}))(MenuItem);

const MagaluAnswerBar = ({ ticketData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [id, setId] = useState('');
  const [store, setStore] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmModalOpenState, setConfirmModalOpenState] = useState(false);
  const [protocolType, setProtocolType] = useState('');
  const [protocolName, setProtocolName] = useState('');

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const typesProtocol = [
    { type: 'mktplace-manuseio', name: 'Atraso na entrega' },
    { type: 'mktplace-duvidas-gerais', name: 'Dúvidas gerais' },
    { type: 'mktplace-entrega', name: 'Entrega' },
    { type: 'mktplace-pagamento', name: 'Pagamento' },
    { type: 'mktplace-produto', name: 'Produto' },
    { type: 'mktplace-reclamacao', name: 'Reclamação' },
    { type: 'mktplace-troca-de-produto', name: 'Troca' }
  ];

  const sendProtocol = async () => {
    const postData = {
      store,
      slug: protocolType,
      idorder: id
    };
    try {
      await API.post(
        `/auth/ms-ticket/v1/tickets/magalu/create-protocol`,
        postData
      );
    } catch (error) {
      enqueueSnackbar(t('i18n.ticketcontainer.MAGALU_NEW_PROCOTOL_FAIL'), {
        variant: 'error'
      });
    } finally {
      setProtocolName('');
      setProtocolType('');
    }
  };

  useEffect(() => {
    setId(ticketData?.marketplaceBundle?.id);
    setStore(ticketData?.marketplaceBundle?.store);
  }, []);

  return (
    <>
      <Button
        onClick={e => {
          handleClick(e);
        }}
        color="primary"
      >
        <AddIcon fontSize="small" className="header-btn--icon" />
        {t('i18n.ticketslistview.NEW_GUIDE_TICKET')}
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {typesProtocol.map(type => {
          return (
            <StyledMenuItem
              key={type.type}
              onClick={() => {
                handleClose();
                setProtocolType(type.type);
                setProtocolName(type.name);
                setConfirmModalOpenState(true);
              }}
            >
              <ListItemIcon>
                <PlaylistAddIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={type.name} />
            </StyledMenuItem>
          );
        })}
        <Divider />
        <StyledMenuItem
          onClick={() => {
            handleClose();
            setProtocolType('mktplace-cancelamento-de-pedido');
            setProtocolName('Cancelamento');
            setConfirmModalOpenState(true);
          }}
        >
          <ListItemIcon>
            <CancelPresentationIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Cancelamento" />
        </StyledMenuItem>
      </StyledMenu>
      <ConfirmationModal
        protocolName={protocolName}
        openState={confirmModalOpenState}
        closeParent={() => {
          setConfirmModalOpenState(false);
        }}
        submitConfirmation={() => {
          sendProtocol();
          setConfirmModalOpenState(false);
        }}
      />
    </>
  );
};

export default MagaluAnswerBar;
