import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  SectionTabs,
  SectionTitle,
  SectionContent,
  SectionContentWrapper,
  SectionContentTitle,
  SectionText,
  SectionTabImg,
  BotAlert
} from './SectionTabs';

const TabProductSuggestion = ({ productSuggestion }) => {
  const { t } = useTranslation();
  const { partNumber, descricao, imagens } = productSuggestion[0];

  return (
    <>
      <SectionTabs>
        <BotAlert>
          <SectionText alert>
            {t('i18n.ticketcontainer.PRODUCT_SUGGESTION_TEXT')}
          </SectionText>
        </BotAlert>
        <SectionTitle>
          {t('i18n.ticketcontainer.PRODUCT_SUGGESTION')}
        </SectionTitle>
        <Grid container spacing={2}>
          <Grid item lg={8} xs={12}>
            <SectionContentWrapper>
              <SectionContentTitle>
                {t('i18n.ticketcontainer.PRODUCT')}:
              </SectionContentTitle>
              <SectionContent>
                <SectionText>
                  {t('i18n.ticketcontainer.CODE')}: {partNumber}
                </SectionText>
              </SectionContent>
              <SectionContent>
                <SectionText bold>{descricao}</SectionText>
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
          <Grid item lg={4} xs={12}>
            <SectionContentWrapper>
              <SectionContent>
                <SectionTabImg
                  src={imagens?.[0]?.url}
                  alt="imagem do produto"
                />
              </SectionContent>
            </SectionContentWrapper>
          </Grid>
        </Grid>
      </SectionTabs>
    </>
  );
};

export default TabProductSuggestion;
