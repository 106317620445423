import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import FilterAccordion from './filterAccordion.component';
import FilterForm from './filterForm.component';
import {
  convertDateToFilter,
  defaultDateFormat
} from '../services/convertDate';
import indexHook from '../indexHook';
import API from '../../../services/api';
import dashboardServices from '../services/dashboardServices';
import formHelper from '../../../utils/formHelper';
import { filterNameInStorage } from './config';

const FilterDashboard = ({
  open,
  setOpen,
  dashboardFilter,
  setDashboardFilter
}) => {
  const reduxDispatcher = useDispatch();
  const { isAdmin, userName, enqueueSnackbar, t } = indexHook();

  const [filter, setFilter] = useState(dashboardFilter);
  const [submit, setSubmit] = useState(false);
  const [dataIndividualDash, setDataIndividualDash] = useState('');
  const [dataEquipDash, setDataEquipDash] = useState('');
  const [attendantList, setAttendantList] = useState([]);

  const handleErroGetAttendantData = () => {
    setDataIndividualDash(dashboardServices.handleNotHaveDataAttendant());
  };

  const handleGetPeriodAttendantData = (responseData, currentFilter) => {
    /**
     * Se tiver um atendente no filtro, a função vai procurar, nos dados retornados do backend, dados relacionados ao atendente.
     * O Backend, em alguns casos, está mandando dados individuais na mesma consulta que retorna os dados gerais.
     * Dados do dashboard de protocolo por período
     */

    if (currentFilter.attendant) {
      let attendantRefOpen = {};
      const openData = [];
      let attendantRefClosed = {};
      const closedData = [];

      if (responseData.data_ref_open.lista_final_profissionais.length > 0) {
        attendantRefOpen = responseData.data_ref_open.lista_final_profissionais.find(
          item => {
            return item.serviceAttendantLogin === currentFilter.attendant;
          }
        );
      }

      if (attendantRefOpen) {
        openData.push(attendantRefOpen);
      } else {
        openData.push(dashboardServices.handleNotHaveDataAttendant());
      }

      if (responseData.data_ref_closed.lista_final_profissionais.length > 0) {
        attendantRefClosed = responseData.data_ref_closed.lista_final_profissionais.find(
          item => {
            return item.serviceAttendantLogin === currentFilter.attendant;
          }
        );
      }

      if (attendantRefClosed) {
        closedData.push(attendantRefClosed);
      } else {
        closedData.push(dashboardServices.handleNotHaveDataAttendant());
      }

      if (!attendantRefOpen && !attendantRefClosed) {
        handleErroGetAttendantData();
      } else {
        setDataIndividualDash(
          dashboardServices.handleSeparateAttendantData(
            openData[0],
            closedData[0]
          )
        );
      }
    }
  };

  const handleGetDashboard = async currentFilter => {
    reduxDispatcher({
      type: 'SET_TICKETS_DASHBOARD_LOADING',
      loading: true
    });
    const data = { ...currentFilter, ...{ attendant: undefined } };

    await API.post(`auth/lambda/dash-protocolo-bi/filtered-bi`, data)
      .then(response => {
        handleGetPeriodAttendantData(response.data, currentFilter);
        setDataEquipDash(
          dashboardServices.handleSeparateEquipData(response.data)
        );
      })
      .catch(() => {
        setDataEquipDash(dashboardServices.handleNotHaveEquipData());
        enqueueSnackbar(t('i18n.dashboard.components.no_data'), {
          variant: 'error'
        });
      })
      .finally(() => {
        reduxDispatcher({
          type: 'SET_TICKETS_DASHBOARD_LOADING',
          loading: false
        });
      });
  };

  useEffect(() => {
    if (submit) {
      const { startDate, endDate } = convertDateToFilter(filter);
      filter.startDate = startDate;
      filter.endDate = endDate;
    }

    if (filter.periodType === 'realTime') {
      filter.startDate = defaultDateFormat(
        moment(new Date('2021-10-01')).add(6, 'hours')
      );
    }

    filter.attendant = !isAdmin ? userName : filter.attendant;
    reduxDispatcher({ type: 'SET_TICKETS_DASHBOARD_FILTER', filter });
    setDashboardFilter(filter);
  }, [filter, submit]);

  useEffect(() => {
    if (dataEquipDash !== '') {
      reduxDispatcher({
        type: 'SET_TICKETS_DASHBOARD_EQUIP_DATA',
        equipData: dataEquipDash
      });
    }
  }, [dataEquipDash]);

  useEffect(() => {
    if (dataIndividualDash !== '') {
      reduxDispatcher({
        type: 'SET_TICKETS_DASHBOARD_INDIVIDUAL_DATA',
        individualData: dataIndividualDash
      });
    }
  }, [dataIndividualDash]);

  useEffect(() => {
    sessionStorage.setItem(
      filterNameInStorage,
      JSON.stringify(dashboardFilter)
    );
    handleGetDashboard(dashboardFilter);
  }, [dashboardFilter]);

  useEffect(() => {
    API.get(`/auth/ms-users/groups/atendente/users`).then(response => {
      setAttendantList(response.data.sort(formHelper.compareName));
    });
  }, []);

  return (
    <>
      <FilterAccordion
        open={open}
        setFilter={setFilter}
        setSubmit={setSubmit}
        filter={dashboardFilter}
        // dashboardFilter={dashboardFilter}
        filterName="newfilter"
        // defaultFilter={defaultFilter}
      />
      {open && (
        <FilterForm
          setFilter={setFilter}
          setSubmit={setSubmit}
          setOpen={setOpen}
          filter={filter}
          filterName="newfilter"
          // defaultFilter={defaultFilter}
          attendantList={attendantList}
        />
      )}
    </>
  );
};

export default FilterDashboard;
