import {
  Box,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../../../../../../styles/settings/colors.config';

const Label = styled(Typography)`
  font-size: 12px;
  text-transform: none;
  text-align: left;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  color: ${props => (props.customcolor ? props.customcolor : 'black')};
`;

const HairlineLabel = styled.span`
  font-weight: 300;
`;

const logisticsReviewList = ['Liberado', 'Liberado com Ressalva', 'Recusado'];

const useStyles = makeStyles(() => ({
  inputSelect: {
    width: '25%',
    '& .MuiOutlinedInput-root': {
      height: '30px',
      fontSize: '12px',
      backgroundColor: 'white'
    }
  }
}));

export default function FinalConfirm({
  details,
  logisticsReview,
  setLogisticsReview
}) {
  const classes = useStyles();

  return (
    <Box
      paddingX={5}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginBottom: '10px'
      }}
    >
      <Label
        bold="true"
        customcolor={COLORS.orange}
        style={{ marginRight: '9px' }}
      >
        Parecer Logística Final - Nota Fiscal{' '}
        <HairlineLabel>(Obrigatório)</HairlineLabel>
      </Label>
      {details?.isReviewedByLogistics ? (
        <Typography>{details?.statusLogistics}</Typography>
      ) : (
        <FormControl variant="outlined" className={classes.inputSelect}>
          <Select
            style={{ marginLeft: '9px', marginRight: '9px' }}
            value={logisticsReview}
            onChange={htmlElemtEvent => {
              setLogisticsReview(htmlElemtEvent.target.value);
            }}
            className={classes.selectParecer}
          >
            {logisticsReviewList.map(item => {
              return (
                <MenuItem key={Math.random()} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </Box>
  );
}
