import { Drawer } from '@material-ui/core';
import styled from 'styled-components';
import { COLORS } from '../../../../../../styles/settings/colors.config';

export const StyledDrawer = styled(Drawer)`
  .menu-closed {
    max-width: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  > div {
    max-height: calc(100vh - 60px);
    overflow-x: hidden;
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      width: 5px;
      background-color: ${COLORS.btnSecondaryColor};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${COLORS.btnSecondaryColor};
      border: 2px solid ${COLORS.btnSecondaryColor};
    }
  }
  ul {
    padding-bottom: 0;
  }
`;

export const StyledFilterContainer = styled.div`
  padding-left: 60px;
`;
