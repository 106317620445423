import React from 'react';
import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';
import { numberToBRL } from '../../../../../../../helpers/utils';

const StyledOrderItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dotted #666666;
  padding: 20px 0 15px 0;
  background: ${props => (props.isToCancel ? '#ffcc0020' : 'transparent')};
  color: ${props => (props.isToCancel ? '#666666' : '#B7B7B7')};
`;

const ExpertOrderItem = ({ item, cancellingData }) => {
  const findItem = cancellingData.itensToCancel.find(
    i => i.partNumber === item.partNumber
  );

  return (
    <StyledOrderItem key={Math.random()} isToCancel={findItem}>
      <div style={{ width: '30px' }}>{findItem && <CheckIcon />}</div>
      <div style={{ width: '300px' }}>
        {item.descricao}
        {findItem?.possuiGarantia === 'S' && (
          <div style={{ marginTop: '10px' }}>Garantia Estendida</div>
        )}
        {cancellingData.isReviewedByLogistics && findItem && (
          <>
            <br />
            <span style={{ color: '#ed7d31' }}>
              Parecer do Item (Logística): {findItem?.detailLogistics}
            </span>
          </>
        )}
        {cancellingData.cancellationType === 'TROCA' &&
          cancellingData.isReviewedByService && (
            <>
              <br />
              <span style={{ color: '#ed7d31' }}>
                Parecer do Item (Service): {findItem?.detailService}
              </span>
            </>
          )}
      </div>
      <div style={{ width: '100px' }}>{item.partNumber}</div>
      <div style={{ width: '60px' }}>{item.quantidade}</div>
      <div style={{ width: '60px' }}>{findItem?.quantityToCancel}</div>
      <div style={{ width: '75px', fontSize: '13px', textAlign: 'right' }}>
        {numberToBRL(item.valorBruto)}
      </div>
      <div style={{ width: '75px', fontSize: '13px', textAlign: 'right' }}>
        {numberToBRL(item.totalBruto)}
      </div>
    </StyledOrderItem>
  );
};

export default ExpertOrderItem;
