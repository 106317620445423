import React, { useState, useContext } from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ThemeContext } from '../../../layouts/MonitorLayout';

import Page from '../../../components/Page';
import { MonitorHeader } from '../components/monitorHeader.component';
import Loader from '../../../components/Loader';

import {
  CustomCard,
  CustomTypography,
  GoBackButton
} from '../components/card.component';

import { AttendantList } from '../components/attendantList.component';

const MonitorChat = () => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);

  const [monitorData, setMonitorData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const handleSunAppSite = (totalApp, totalSite) => {
    if (totalApp || totalSite) {
      return totalApp + totalSite;
    }
    return '0';
  };

  const handlePercent = (value, total) => {
    return Math.round((value * 100) / total);
  };

  const handleAppSiteDetails = (app, site) => {
    const appValue = app?.visitor_peaks || app;
    const siteValue = site?.visitor_peaks || site;
    const total = appValue + siteValue;

    const list = [
      {
        key: 'App',
        value: appValue > 0 ? appValue : '0',
        percent: total > 0 ? handlePercent(appValue, total) : '0'
      },
      {
        key: 'Site',
        value: siteValue > 0 ? siteValue : '0',
        percent: total > 0 ? handlePercent(siteValue, total) : '0'
      }
    ];

    return list;
  };

  return (
    <>
      {isLoading && <Loader />}
      <Page title="Chats + Visitas: Tempo Real (Acer)">
        <Container maxWidth={false}>
          <Box mt={theme.m2 || 4} display="flex" alignItems="center">
            <MonitorHeader
              {...{
                setMonitorData,
                setIsLoading,
                title: t('i18n.monitor.chat.CHAT_MONITOR'),
                title2: 'Pré-Venda Online',
                marginRight: 16
              }}
            />
          </Box>
          <Box mt={theme.m2 || 3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2}>
                <Box mb={theme?.m1}>
                  <CustomTypography font="1.5em" color={theme.warningV4}>
                    Chats:
                  </CustomTypography>
                </Box>
                <Box>
                  <CustomCard
                    {...{
                      header: 'Em',
                      title: 'Andamento',
                      color: theme.successV2,
                      mainData: handleSunAppSite(
                        monitorData?.chats_status?.chats_in_attendance_app,
                        monitorData?.chats_status?.chats_in_attendance_site
                      ),
                      showMoreDetails: true,
                      extraData: handleAppSiteDetails(
                        monitorData?.chats_status?.chats_in_attendance_app,
                        monitorData?.chats_status?.chats_in_attendance_site
                      )
                    }}
                  />
                </Box>
                <Box mt={theme.m2 || 3}>
                  <CustomCard
                    {...{
                      header: 'Na',
                      title: 'Fila',
                      color: theme.warningV2,
                      mainData: handleSunAppSite(
                        monitorData?.chats_status?.clients_on_queue_app,
                        monitorData?.chats_status?.clients_on_queue_site
                      ),
                      showMoreDetails: true,
                      extraData: handleAppSiteDetails(
                        monitorData?.chats_status?.clients_on_queue_app,
                        monitorData?.chats_status?.clients_on_queue_site
                      )
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box style={{ marginTop: theme?.margin45 || '35px' }}>
                  <AttendantList {...{ monitorData }} />
                </Box>
              </Grid>
              <Grid item xs={12} md={2}>
                <Box mb={theme?.m1}>
                  <CustomTypography font="1.5em" color={theme.warningV4}>
                    Visitantes:
                  </CustomTypography>
                </Box>
                <Box>
                  <CustomCard
                    {...{
                      header: 'No',
                      title: 'Momento',
                      color: theme.successV2,
                      mainData: handleSunAppSite(
                        monitorData?.app_visitors?.visitor_peaks,
                        monitorData?.site_visitors?.visitor_peaks
                      ),
                      showMoreDetails: true,
                      extraData: handleAppSiteDetails(
                        monitorData?.app_visitors?.visitor_peaks,
                        monitorData?.site_visitors?.visitor_peaks
                      )
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Page>
      {theme.screen === 'tv' && <GoBackButton link="/monitor/chat" />}
    </>
  );
};

export default MonitorChat;
