import {
  makeStyles,
  createStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  TextField,
  Divider,
  Button
} from '@material-ui/core';
import React from 'react';
import { StyledDialog } from './styles';

const useStyles = makeStyles(() =>
  createStyles({
    input100: {
      fontSize: '12px'
    }
  })
);

function capitalizeFirstLetter(string) {
  const newString = string.toLowerCase();
  return newString.charAt(0).toUpperCase() + newString.slice(1);
}

const CancelConfirm = ({
  open,
  setOpen,
  handleCancel,
  reason,
  setReason,
  cancellingData
}) => {
  const classes = useStyles();

  return (
    <StyledDialog
      fullWidth
      open={open}
      PaperProps={{
        style: { borderRadius: 20 }
      }}
    >
      <DialogTitle className="acer-modal-header">
        Confirmação de Cancelamento de{' '}
        {capitalizeFirstLetter(cancellingData.cancellationType)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="text">
          Ao clicar em confirmar o{' '}
          <span className="redText boldText">processo será encerrado</span>.
        </DialogContentText>
        <DialogContentText className="text">
          <span className="grayText boldText">
            Informe o <span className="orangeText">motivo</span> do Cancelamento
            de {capitalizeFirstLetter(cancellingData.cancellationType)}:
          </span>
        </DialogContentText>
        <Box mb={4}>
          <TextField
            defaultValue={reason}
            size="small"
            fullWidth
            variant="outlined"
            placeholder={`Insira o Motivo do Cancelamento de ${capitalizeFirstLetter(
              cancellingData.cancellationType
            )}`}
            onChange={e => {
              setReason(e.target.value);
            }}
            InputProps={{
              className: classes.input100
            }}
          />
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" marginTop="20px">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={handleCancel}>
            Confirmar
          </Button>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default CancelConfirm;
