import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography
} from '@material-ui/core';
// import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import styled from 'styled-components';
import { Add } from '@material-ui/icons';
import API from '../../services/api';
import AcerModal from '../../components/AcerModal';
import { CustomTableButton } from '../../styles/components/CustomDataTable';
import { StyledSpinner } from '../../styles/components/SpinnerDefault';
import Page from '../../components/Page';
// import API from '../../services/api';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/pt-br';
import 'moment/locale/en-gb';
import HeaderTitle from '../../components/HeaderTitle';

const StyledCalendar = styled(Calendar)`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

const NewCampaignForm = ({
  handleSubmit,
  t,
  modalType,
  selectedEvent,
  handleRemove
}) => {
  const [data, setData] = useState({
    nome: '',
    // eslint-disable-next-line
    data_inicio: '',
    // eslint-disable-next-line
    data_fim: '',
    allDay: true
  });

  useEffect(() => {
    if (modalType === 'edit') {
      setData({
        ...selectedEvent,
        start: moment(selectedEvent.start).format('YYYY-MM-DD'),
        end: moment(selectedEvent.end).format('YYYY-MM-DD')
      });
    }
  }, [modalType]);

  return (
    <>
      <Box paddingBottom={2}>
        <TextField
          variant="outlined"
          label={t('i18n.macros.NAME')}
          fullWidth
          value={data?.nome}
          size="small"
          onChange={e => {
            setData({ ...data, nome: e.target.value });
          }}
        />
      </Box>
      <Box paddingBottom={2}>
        <TextField
          size="small"
          type="date"
          label={t('i18n.dashboard.START_DATE')}
          variant="outlined"
          value={data?.data_inicio}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          onChange={e => {
            // eslint-disable-next-line
            setData({ ...data, data_inicio: e.target.value });
          }}
        />
      </Box>
      <Box paddingBottom={2}>
        <TextField
          size="small"
          type="date"
          label={t('i18n.dashboard.END_DATE')}
          variant="outlined"
          value={data?.data_fim}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          onChange={e => {
            // eslint-disable-next-line
            setData({ ...data, data_fim: e.target.value });
          }}
        />
      </Box>

      <Box mb={1}>
        <Button
          onClick={() => handleSubmit(data, modalType)}
          variant="contained"
          color="primary"
          fullWidth
        >
          {t('i18n.simplewords.SAVE')}
        </Button>
      </Box>

      {modalType === 'edit' && (
        <Box>
          <Button
            onClick={() => handleRemove(data)}
            variant="contained"
            color="secondary"
            fullWidth
          >
            {`${t('i18n.presale.REMOVE_PROTOCOL')} ${t(
              'i18n.simplewords.CAMPAIGN'
            )}`}
          </Button>
        </Box>
      )}
    </>
  );
};

const CampaignCalendar = () => {
  const { t } = useTranslation();
  const [load] = useState(true);
  // const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState(false);
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [error, setError] = useState(false);

  const stringUSToDate = (string, initial = true) => {
    const mx = string.split('-');

    if (initial) {
      return new Date(mx[0], mx[1] - 1, mx[2], 0, 0, 0);
    }

    return new Date(mx[0], mx[1] - 1, mx[2], 23, 59, 59);
  };

  const normalizeJSON = json => {
    return json.map(item => {
      return {
        id: item.id,
        title: item.nome,
        start: stringUSToDate(item.data_inicio, true),
        end: stringUSToDate(item.data_fim, false),
        allDay: true
      };
    });
  };

  const normalizeItem = json => {
    return {
      id: json.id,
      nome: json.title,
      // eslint-disable-next-line
      data_inicio: moment(json.start).format('YYYY-MM-DD'),
      // eslint-disable-next-line
      data_fim: moment(json.end).format('YYYY-MM-DD'),
      allDay: true
    };
  };

  const getData = async () => {
    const { data } = await API.post(`/auth/ms-ticket/v1/campaign/list`, {});
    let normalizedData = [];
    // eslint-disable-next-line
    if (data._embedded && data._embedded?.campanhaResourceList) {
      normalizedData = normalizeJSON(data._embedded.campanhaResourceList);
    }

    setEvents(normalizedData);
  };

  const sendData = async data => {
    try {
      await API.post(`/auth/ms-ticket/v1/campaign`, data);
    } catch (err) {
      if (err.response.status === 401) {
        setError(true);
      }
    }
  };

  const removeData = async id => {
    try {
      await API.delete(`/auth/ms-ticket/v1/campaign/${id}`);
    } catch (err) {
      if (err.response.status === 401) {
        setError(true);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (data, type) => {
    let newData;

    if (type === 'new') {
      newData = {
        nome: data.nome,
        // eslint-disable-next-line
        data_inicio: moment(data.data_inicio).format('YYYY-MM-DD'),
        // eslint-disable-next-line
        data_fim: moment(data.data_fim).format('YYYY-MM-DD')
      };
    }

    if (type === 'edit') {
      newData = {
        ...data,
        nome: data.nome,
        // eslint-disable-next-line
        data_inicio: moment(data.data_inicio).format('YYYY-MM-DD'),
        // eslint-disable-next-line
        data_fim: moment(data.data_fim).format('YYYY-MM-DD')
      };
    }

    await sendData(newData);
    await getData();
    setOpen(false);
  };

  const handleSelect = event => {
    setSelectedEvent(normalizeItem(event));
    setModalType('edit');
    setOpen(true);
  };

  const handleRemove = async data => {
    await removeData(data.id);
    await getData();
    setOpen(false);
  };

  const eventStyleGetter = () => {
    const style = {
      backgroundColor: '#80c343'
    };
    return {
      style
    };
  };

  return (
    <Page title="Calendário de campanhas">
      <Container maxWidth={false}>
        <Box mt={5} display="flex" justifyContent="space-between">
          <HeaderTitle title={t('i18n.simplewords.CAMPAIGNS')} />
          <div>
            <CustomTableButton
              variant="text"
              color="primary"
              size="small"
              onClick={() => {
                setModalType('new');
                setOpen(true);
              }}
              startIcon={<Add />}
            >
              {`${t('i18n.simplewords.NEW_F')} ${t(
                'i18n.simplewords.CAMPAIGN'
              )}`}
            </CustomTableButton>
          </div>
        </Box>

        <Box mt={3}>
          <StyledCalendar
            events={events}
            culture={t('i18n.momentlanguage')}
            style={{ height: 400 }}
            views={['month']}
            step={60}
            showMultiDayTimes
            defaultDate={new Date()}
            localizer={localizer}
            eventPropGetter={eventStyleGetter}
            popup
            messages={{
              today: t('i18n.simplewords.TODAY'),
              previous: t('i18n.simplewords.PREVIOUS'),
              next: t('i18n.b2w.modal.return.NEXT'),
              showMore: total => `+${total} ${t('i18n.simplewords.MORE')}`
            }}
            onSelectEvent={event => handleSelect(event)}
          />
          <StyledSpinner style={{ display: !load ? 'block' : 'none' }}>
            <CircularProgress />
          </StyledSpinner>
        </Box>
      </Container>
      <AcerModal
        fullWidth
        onClose={handleClose}
        open={open}
        modalTitle={`${
          modalType === 'new'
            ? t('i18n.simplewords.NEW_F')
            : t('i18n.sales_ad_analysis.edit')
        } ${t('i18n.simplewords.CAMPAIGN')}`}
      >
        <NewCampaignForm
          {...{ t, handleSubmit, modalType, selectedEvent, handleRemove }}
        />
      </AcerModal>
      <AcerModal
        maxWidth="sm"
        onClose={() => {
          setError(false);
          handleClose();
        }}
        open={error}
        modalTitle={t('i18n.exception.ACCESS_DENIED')}
      >
        <Typography>{t('i18n.simplephrases.ACCESS_DENIED')}</Typography>
      </AcerModal>
    </Page>
  );
};

export default CampaignCalendar;
