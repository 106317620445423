import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const StyledDot = styled.div`
  height: 20px;
  width: 20px;
  background-color: ${props => props.bgColor};
  border: 1px solid ${props => props.border};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px;
  z-index: 11;
`;

export const InsideConnector = styled.div`
  background-color: ${props => props.bgColor};
  height: 2px;
  border-radius: 5px;
  width: ${props => props.width}%;
  position: absolute;
  top: 50%;
  left: ${props => (props.left ? props.left : '0')};
  right: ${props => (props.right ? props.right : '0')};
  z-index: 10;
`;

export const LabelDate = styled.div`
  font-family: Roboto;
  font-weight: bold;
  font-size: 10px;
  margin-bottom: 5px;
  height: 20px;
`;

export const LabelStatus = styled.div`
  font-family: Roboto;
  font-size: 12px;
  height: 20px;
  color: ${props => (props.showStatus ? props.bgColor : 'transparent')};
  margin: 5px 5px 0 5px;
  min-width: 120px;
  font-weight: bold;
  display: flex;
  justify-content: center;
`;

export const StyledLabelStartTime = styled.span`
  font-family: Roboto;
  font-size: 14px;
  color: ${props => (props.showTime ? props.color : 'transparent')};
  margin: 5px 5px 0 5px;
  min-width: 120px;
  font-weight: bold;
  display: flex;
  justify-content: center;
`;

export const StyledDotContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const StyledDotButton = styled(Button)`
  margin-top: -33px;
  margin-bottom: 2px;
  text-transform: unset !important;
`;

export const StyledDotSpace = styled('div')`
  margin-top: 0px;
  @media (min-width: 1598px) {
    margin-top: 0px;
  }
`;

export const SmallButton = styled(Button)`
  padding: 2px 2px !important;
  height: 20px;
  width: 100%;
  margin: 0 5px;
`;
