/*eslint-disable */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Tooltip,
  Typography,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import { Lock } from '@material-ui/icons';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import AcerModal from '../../../../components/AcerModal';
import userService from '../../../../services/user.service';

import SelectComponent from '../../../../components/Select.component';

import API from '../../../../services/api';

const handleBlockFactory = ({ allDataOfCurrentClient, reason }) => {
  const { ip, hashConnection, businessType, name, email } = allDataOfCurrentClient
  const dateNow = moment(new Date()).add({ hours: 3 }).format('YYYY-MM-DDTHH:mm:ss');

  const data = {
    ip,
    hashConnection,
    isBlocked: true,
    businessType,
    clientName: name,
    clientEmail: email,
    blockedByUser: userService.getUsername(),
    blockedReason: reason,
    blockedDate: dateNow,
    unblockedByUser: "",
    unblockedReason: "",
    unblockedDate: "",
    blockedLogging: []
  }

  return data
}

export const useBlockUserHooks = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseChat = async hashConnection => {
    await API.get(`/auth/ms-chat/chats/close/connection/${hashConnection}`)
      .then(() => {
        enqueueSnackbar(t('i18n.chatview.MESSAGE_CLOSE'), {
          variant: 'success'
        });
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.chatview.MESSAGE_CLOSE_ERROR'), {
          variant: 'error'
        });
      });
  };

  const handleBlockUser = async ({ closeChat, reason, allDataOfCurrentClient, callback }) => {

    const data = handleBlockFactory({ allDataOfCurrentClient, reason })

    if (allDataOfCurrentClient?.ip) {
      await API.post(
        `/auth/ms-chat/chats/block`,
        data
      ).then(async response => {
        if (
          response.data.message.key === 'i18n.chat.IP_BLOCKED_SUCCESS' &&
          closeChat
        ) {
          await handleCloseChat(allDataOfCurrentClient?.hashConnection);
        }

        if (callback) {
          callback();
        }
      }).catch((err) => {
        console.log(err.response)
      });
    }
  };

  return {
    handleBlockUser
  };
};

export const BlockReasonModal = ({
  showBlockReasonModal,
  setShowBlockReasonModal,
  setOpenState,
  closeChat,
  allDataOfCurrentClient,
  callback
}) => {
  const { handleBlockUser } = useBlockUserHooks();

  const [form, setForm] = useState({});

  const handleSetForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    setForm({})
  }, [])

  return (
    <AcerModal
      fullWidth
      open={showBlockReasonModal}
      maxWidth="sm"
      modalTitle="Motivo do bloqueio"
      hasAction
      confirmActionFunc={() => {
        handleBlockUser({
          reason: form.reason,
          closeChat,
          allDataOfCurrentClient,
          callback
        });
        setShowBlockReasonModal(false);
        setOpenState(false);
      }}
      confirmActionLabel="Confirmar"
      cancelActionFunc={() => setShowBlockReasonModal(false)}
      cancelActionLabel="Cancelar"
      onClose={() => setShowBlockReasonModal(false)}
      confirmActionFuncDisabled={!form['reason']}
    >
      <SelectComponent
        {...{ form, setForm: handleSetForm, label: 'Motivo do bloqueio do IP:', name: 'reason', required: true }}
        options={[
          { name: 'Vocabulário Ofensivo', value: 'Vocabulário Ofensivo' },
          { name: 'Spam', value: 'Spam' },
        ]}
      />

    </AcerModal>
  );
};

export const BlockModal = ({
  openState,
  setOpenState,
  closeChat,
  message = 'Esse chat será encerrado e o cliente não poderá criar novos chats.',
  allDataOfCurrentClient,
  callback
}) => {
  const [showBlockReasonModal, setShowBlockReasonModal] = useState(false);

  return (
    <>
      <AcerModal
        fullWidth
        open={openState}
        maxWidth="sm"
        modalTitle="Atenção"
        hasAction
        confirmActionFunc={() => setShowBlockReasonModal(true)}
        confirmActionLabel="Confirmar"
        cancelActionFunc={() => setOpenState(false)}
        cancelActionLabel="Cancelar"
        onClose={() => setOpenState(false)}
      >
        <Typography>
          <b>Tem certeza que deseja realizar o bloqueio do IP desse cliente?</b>
          <br />
          {message}
        </Typography>
      </AcerModal>
      <BlockReasonModal
        {...{
          showBlockReasonModal,
          setShowBlockReasonModal,
          setOpenState,
          closeChat,
          allDataOfCurrentClient,
          callback
        }}
      />
    </>
  );
};

export const BlockButton = ({ setOpenState }) => {
  return (
    <Tooltip title="Bloquear IP">
      <IconButton
        variant="contained"
        color="primary"
        onClick={() => setOpenState(true)}
      >
        <Lock />
      </IconButton>
    </Tooltip>
  );
};
