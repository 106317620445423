import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import AcerModal from '../../../../../components/AcerModal';
import API from '../../../../../services/api';

const ModalText = styled('p')`
  font-size: 16px;
  line-height: 1.5em;
`;

const CustomTableCell = styled(TableCell)`
  background: #eee;
`;

const ModalGrid = styled(Grid)`
  margin-top: 20px;
`;

const ModalGridButton = styled(Grid)`
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ModalRelease = ({ instance, ticketId, openState, closeParent }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const postData = {
    ticketId,
    instanceId: instance.id,
    actionId: instance.type === 'Troca' ? 'EXCHANGE' : 'RETURN'
  };

  const handleSubmit = () => {
    API.post(`/auth/ms-ticket/v1/tickets/b2w/release-exchange`, postData)
      .then(() => {})
      .catch(() => {
        enqueueSnackbar(t('i18n.ticket.B2W.RELEASE_EXCHANGE_FAIL'), {
          variant: 'error'
        });
      })
      .finally(() => {
        closeParent();
      });
  };

  return (
    <AcerModal
      fullWidth
      maxWidth="md"
      open={openState}
      modalTitle={
        instance?.type === 'Troca'
          ? t('i18n.b2w.modal.releaseexchange.TITLE')
          : t('i18n.b2w.modal.releaserefund.TITLE')
      }
      onClose={closeParent}
    >
      <Card>
        <CardContent>
          <Grid container>
            <ModalGrid item lg={12}>
              <ModalText>
                {instance?.type === 'Troca'
                  ? t('i18n.b2w.modal.releaseexchange.INSTRUCTION1')
                  : t('i18n.b2w.modal.releaserefund.INSTRUCTION1')}
                .
              </ModalText>
              <ModalText>
                {t('i18n.b2w.modal.releaseexchange.INSTRUCTION2a')}{' '}
                {instance?.newDelivery?.delivery}{' '}
                {t('i18n.b2w.modal.releaseexchange.INSTRUCTION2b')}:
              </ModalText>
            </ModalGrid>
            <ModalGrid item lg={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <ModalText>
                        {t('i18n.b2w.modal.postdelivery.PRODUCT_TITLE')}
                      </ModalText>
                    </TableCell>
                    <CustomTableCell>
                      <ModalText>
                        {t('i18n.b2w.modal.postdelivery.QUANTITY')}
                      </ModalText>
                    </CustomTableCell>
                    <CustomTableCell>
                      <ModalText>
                        {t('i18n.b2w.modal.postdelivery.VALUE')}
                      </ModalText>
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {instance?.originalItems?.map(product => (
                    <TableRow key={product.id}>
                      <TableCell>
                        <ModalText>{product.name}</ModalText>
                      </TableCell>
                      <CustomTableCell>
                        <ModalText>{product.quantity}</ModalText>
                      </CustomTableCell>
                      <CustomTableCell>
                        <ModalText>
                          {Number(product.salePrice).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          })}
                        </ModalText>
                      </CustomTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ModalGrid>
            <ModalGridButton item lg={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                {instance?.type === 'Troca'
                  ? t('i18n.b2w.modal.exchangerefund.ACTION_RELEASE_EXCHANGE')
                  : t('i18n.b2w.modal.exchangerefund.ACTION_RELEASE_REFUND')}
              </Button>
            </ModalGridButton>
          </Grid>
        </CardContent>
      </Card>
    </AcerModal>
  );
};

export default ModalRelease;
