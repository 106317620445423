import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import AcerModal from '../../../../components/AcerModal';
import ReopenTicketModal from './detailSupportComponents/ReopenTicket.modal';
import CloseTicketModal from './detailSupportComponents/CloseTicket.modal';
import HistoryCard from './detailSupportComponents/HistoryCard';
import ResumeCard from './detailSupportComponents/ResumeCard';
import ConversationCard from './detailSupportComponents/ConversationCard';
import AttachmentCard from './detailSupportComponents/AttachmentCard';

export default function B2WMarketplaceDetailsSupportModal({
  openDetailsModal,
  setOpenDetailsModal,
  selectedSupport,
  fetchData
}) {
  const [openReopenTicketModal, setOpenReopenTicketModal] = useState(false);
  const [openCloseTicketModal, setOpenCloseTicketModal] = useState(false);

  return (
    <>
      <AcerModal
        fullWidth
        maxWidth="lg"
        onClose={() => setOpenDetailsModal(false)}
        open={openDetailsModal}
        modalTitle="Suporte Marketplace - Americanas - Detalhes do Chamado"
      >
        <Grid container style={{ fontFamily: 'Roboto' }} spacing={3}>
          <Grid item xs={12}>
            <ResumeCard
              {...{
                selectedSupport,
                openReopenTicketModal,
                setOpenReopenTicketModal,
                openCloseTicketModal,
                setOpenCloseTicketModal
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <ConversationCard {...{ selectedSupport }} />
          </Grid>
          <Grid
            item
            xs={3}
            style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}
          >
            <HistoryCard {...{ selectedSupport }} />
            <AttachmentCard {...{ selectedSupport }} />
          </Grid>
        </Grid>
      </AcerModal>
      <ReopenTicketModal
        {...{
          selectedSupport,
          openReopenTicketModal,
          setOpenReopenTicketModal,
          setOpenDetailsModal,
          fetchData
        }}
      />
      <CloseTicketModal
        {...{
          selectedSupport,
          openCloseTicketModal,
          setOpenCloseTicketModal,
          setOpenDetailsModal,
          fetchData
        }}
      />
    </>
  );
}
