import { Box, Grid, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React, { useState } from 'react';
import styled from 'styled-components';
import { numberToBRL } from '../../../../../../helpers/utils';
import { COLORS } from '../../../../../../styles/settings/colors.config';
import { CSSTextField } from './Components/styles';

export const Label = styled(Typography)`
  font-size: 12px;
  text-transform: none;
  text-align: left;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  color: ${props => (props.customcolor ? props.customcolor : 'black')};
  '& .hairline' : {
    font-weight: normal;
  }
`;

const HairlineLabel = styled.span`
  font-weight: 300;
`;

export const Content = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
  text-transform: none;
  text-align: left;
`;

// eslint-disable-next-line
export default function ServiceItem({
  item,
  i,
  setDetails,
  details,
  cancelled
}) {
  const [detail, setDetail] = useState('');

  const updateNewDetails = () => {
    const newDetails = { ...details };
    newDetails.itensToCancel[i].detailService = detail;
    setDetails(newDetails);
  };

  return (
    <Box paddingY={cancelled ? 1 : 2}>
      <Grid
        container
        spacing={2}
        style={{
          color: cancelled ? 'black' : COLORS.meduimGray
        }}
      >
        <Grid item xs={4}>
          {i === 0 && cancelled && <Label>Itens Devolvidos</Label>}
          <Content>
            {cancelled && <Check color="primary" />}
            {item?.descricao}
          </Content>
        </Grid>
        <Grid item xs={2}>
          {i === 0 && cancelled && <Label>Part Number</Label>}
          <Content>{item?.partNumber}</Content>
        </Grid>
        <Grid item xs={2}>
          {i === 0 && cancelled && <Label>QTD Original</Label>}
          <Content>{item?.quantidade}</Content>
        </Grid>
        <Grid item xs={2}>
          {i === 0 && cancelled && <Label>QTD Devolvida</Label>}
          <Content>{item?.quantityToCancel}</Content>
        </Grid>
        <Grid item xs={1}>
          {i === 0 && cancelled && <Label>Valor Un</Label>}
          <Content>{numberToBRL(item?.valorBruto)}</Content>
        </Grid>
        <Grid item xs={1}>
          {i === 0 && cancelled && <Label>Valor Total</Label>}
          <Content>{numberToBRL(item?.totalBruto)}</Content>
        </Grid>
        {cancelled && (
          <>
            <Grid item xs={4}>
              {i === 0 && <Label>Parecer Logística - Item</Label>}
              <Content>{item?.detailLogistics}</Content>
            </Grid>

            <Grid item xs={8}>
              {i === 0 && (
                <Label bold="true" customcolor={COLORS.orange}>
                  Observação Service - Item{' '}
                  <HairlineLabel>(Opcional)</HairlineLabel>
                </Label>
              )}
              <CSSTextField
                variant="outlined"
                defaultValue={details?.itensToCancel[i].detailService}
                disabled={details.isReviewedByService}
                onChange={e => {
                  setDetail(e.target.value);
                }}
                onBlur={updateNewDetails}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}
