/* eslint-disable no-return-assign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import {
  Box,
  Button,
  CircularProgress,
  Container,
  LinearProgress,
  makeStyles,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';

import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import LaunchIcon from '@material-ui/icons/Launch';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

import ConfirmationModal from '../../../utils/ConfirmationModal';
import AcerModal from '../../../components/AcerModal';
import FetchContentError from '../../../components/FetchContentError';
import Page from '../../../components/Page';
import API from '../../../services/api';
import {
  CustomDataTable,
  CustomDataTableWrapper
} from '../../../styles/components/CustomDataTable';
import CustomFooter from '../../../styles/components/CustomFooter';
import { StyledSpinner } from '../../../styles/components/SpinnerDefault';
import AnalysisView from './AnalysisView';
import FilterDrawer from './FilterDrawer';
import SynonymsCRUD from './SynonymsCRUD';

import {
  RemoveFilterCardButton,
  cardStyle,
  FilterCardHeader,
  CustomTypographyDescribe,
  typographyStyle
} from '../../../styles/components/Tags';

const ButtonContainer = styled.div`
  a,
  button {
    margin: 10px;
  }
`;

const useStyles = makeStyles(() => ({
  dialogCustomizedWidth: {
    'max-width': '50%'
  }
}));

const AnaliseAnunciosListView = () => {
  const classes = useStyles();

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
  const [confirmationModalAction, setConfirmationModalAction] = useState();

  const [loadedDealersAnalysis, setLoadedDealersAnalysis] = useState(true);
  const [dealersAnalysisData, setDealersAnalysisData] = useState([]);
  const [loadedTableContent, setLoadedTableContent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [analysisModalOpenState, setAnalysisModalOpenState] = useState(false);
  const [analysisModalMode, setAnalysisModalMode] = useState('');
  const [showSynonyms, setShowSynonyms] = useState(false);

  const [dealersAnalysisProdutName, setDealersAnalysisProdutName] = useState(
    ''
  );

  const [hasFetchError, setFetchError] = useState(false);
  const [
    pageVariableToTriggerPageChangeFromParentComponent,
    setPageVariableToTriggerPageChangeFromParentComponent
  ] = useState({ page: 0 });

  const { t } = useTranslation();

  const [fullData, setFullData] = useState([]);
  const [triggerApplyFilter, setTriggerApplyFilter] = useState(new Date());
  const [categories, setCategories] = useState([]);
  const [reducedData, setReducedData] = useState([]);
  const [filterData, setFilterData] = useState({});

  const setCategoriesFromFullData = fullDataParam => {
    setCategories(
      fullDataParam.map(item => {
        return item?.category;
      })
    );
  };

  const constructDealersData = productArray => {
    return productArray.map(product => {
      product.dealersData = {
        dealerPageUrlHtml: product?.dealer_page_url_html,
        dealerPageUrlImage: product?.dealer_page_url_image,
        dealerPageUrlZip: product?.dealer_page_url_zip
      };
      return product;
    });
  };

  const getAllProductsFromFamilies = families => {
    let familiesProducts = [];
    families.map(familyItem => {
      let familyProducts = familyItem.products;
      familyProducts = constructDealersData(familyProducts);
      familiesProducts = [...familiesProducts, ...familyProducts];
      return familyItem;
    });
    return familiesProducts;
  };

  const reduceProductData = dataToReduce => {
    return dataToReduce.reduce((result, item) => {
      if (item.products) {
        item.products = constructDealersData(item.products);
        return [...result, ...item.products];
      }
      if (item.families) {
        const familiesProducts = getAllProductsFromFamilies(item.families);
        return [...result, ...familiesProducts];
      }
      return [...result];
    }, []);
  };

  const triggerAnFilterUpdate = () => {
    setTriggerApplyFilter(new Date());
  };

  const auxLoadTableContent = () => {
    setLoading(true);
    API.get(`/auth/ms-ia/api/v1/dealer-analysis/products`)
      .then(response => {
        const requestFullData = response.data;
        setCategoriesFromFullData(requestFullData);
        setFullData(requestFullData);
        setReducedData(reduceProductData(requestFullData));
        triggerAnFilterUpdate();
        setLoading(false);
      })
      .catch(() => {
        setFetchError(true);
      });
  };

  const loadDealersAnalysis = (buyersGuide, dealerHtml) => {
    setLoadedDealersAnalysis(false);
    const payload = { buyers_guide: buyersGuide, dealer_html: dealerHtml };
    API.post(`/auth/ms-ia/api/v1/dealer-analysis`, payload)
      .then(response => {
        setDealersAnalysisData(response.data);
        setLoadedDealersAnalysis(true);
        auxLoadTableContent();
      })
      .catch(() => {
        setDealersAnalysisData([]);
        setLoadedDealersAnalysis(true);
      });
  };

  const initTable = () => {
    const load = loadedTableContent || auxLoadTableContent;
    load();
    setLoadedTableContent(true);
  };

  const HandleShowSynonyms = () => {
    if (showSynonyms) {
      setShowSynonyms(false);
    } else {
      setShowSynonyms(true);
    }
  };

  useEffect(initTable, []);

  if (hasFetchError) {
    return <FetchContentError fetch={auxLoadTableContent} />;
  }

  const options = {
    pagination: true,
    viewColumns: false,
    selectableRows: false,
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    filter: false,
    customToolbar: () => {
      return (
        <>
          <IconButton disabled color="secondary">
            |
          </IconButton>
          <Button
            variant="text"
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => {
              HandleShowSynonyms();
            }}
          >
            {t('i18n.synonyms.edit')}
          </Button>
        </>
      );
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          pageVariableToTriggerPageChangeFromParentComponent={
            pageVariableToTriggerPageChangeFromParentComponent
          }
        />
      );
    },
    textLabels: {
      body: {
        noMatch: !loading
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: column =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };

  const applyFilter = (selectedCategories, selectedFamilies, goToPageZero) => {
    if (goToPageZero) {
      setPageVariableToTriggerPageChangeFromParentComponent({
        page: 0
      });
    }
    const clonedFullData = JSON.parse(JSON.stringify(fullData));
    const filteredData = clonedFullData.filter(item => {
      if (selectedCategories.length === 0) return true;

      if (selectedCategories.includes(item?.category)) {
        return true;
      }

      return false;
    });
    if (selectedFamilies.length > 0) {
      filteredData.map(item => {
        item.families = item.families.filter(family =>
          selectedFamilies.includes(family.name)
        );
        return item;
      });
    }
    setReducedData([...reduceProductData(filteredData)]);
    setFilterData({ categories: selectedCategories });
  };
  const columns = [
    {
      name: 'url_image',
      label: t('i18n.sales_ad_analysis.data.heroimage'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return value ? (
            <img src={value} alt="heroImage" style={{ maxWidth: '100px' }} />
          ) : (
            <BrokenImageIcon />
          );
        }
      }
    },
    {
      name: 'name',
      label: t('i18n.sales_ad_analysis.data.model'),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'buyers_guide_url_imagens_onedrive',
      label: t('i18n.sales_ad_analysis.data.images'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return (
            <Button
              href={value || '#'}
              disabled={!value}
              variant="outlined"
              startIcon={<LaunchIcon />}
            >
              OneDrive
            </Button>
          );
        }
      }
    },
    {
      name: 'buyers_guide_url_pdf',
      label: t('i18n.sales_ad_analysis.data.buyersguide'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return (
            <Button
              onClick={() => window.open(value || '#', '_blank')}
              disabled={!value}
              variant="outlined"
              startIcon={<LaunchIcon />}
            >
              PDF
            </Button>
          );
        }
      }
    },
    {
      name: 'dealersData',
      label: t('i18n.sales_ad_analysis.data.dealerpage'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return (
            <ButtonContainer>
              <Button
                href={value?.dealerPageUrlZip || '#'}
                disabled={!value?.dealerPageUrlZip}
                variant="outlined"
                startIcon={<GetAppIcon />}
              >
                ZIP
              </Button>
              <Button
                onClick={() =>
                  window.open(value?.dealerPageUrlImage || '#', '_blank')
                }
                disabled={!value?.dealerPageUrlImage}
                variant="outlined"
                startIcon={<LaunchIcon />}
              >
                JPG
              </Button>
              <Button
                onClick={() =>
                  window.open(value?.dealerPageUrlHtml || '#', '_blank')
                }
                disabled={!value?.dealerPageUrlHtml}
                variant="outlined"
                startIcon={<LaunchIcon />}
              >
                HTML
              </Button>
            </ButtonContainer>
          );
        }
      }
    },
    {
      name: 'name',
      label: t('i18n.sales_ad_analysis.data.analysis'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const productName = tableMeta?.rowData?.[1];
          const buyersGuide = tableMeta?.rowData?.[3];
          const dealerHtml = tableMeta?.rowData?.[4]?.dealerPageUrlHtml;
          const alreadyDoneAnalysis = tableMeta?.rowData?.[6]?.result_analise;

          return (
            <>
              <ButtonContainer>
                <Button
                  startIcon={<PlayCircleOutlineIcon />}
                  onClick={() => {
                    setConfirmationModalAction(() => () => {
                      setDealersAnalysisProdutName(productName);
                      setAnalysisModalMode('new');
                      setAnalysisModalOpenState(true);
                      loadDealersAnalysis(buyersGuide, dealerHtml);
                    });
                    if (alreadyDoneAnalysis) {
                      setConfirmationModalMessage(
                        t('i18n.sales_ad_analysis.confirmation.runEditAnalysis')
                      );
                      setConfirmationModalOpen(true);
                    } else {
                      confirmationModalAction();
                    }
                  }}
                  disabled={!value || !buyersGuide || !dealerHtml}
                  variant="outlined"
                >
                  {t('i18n.sales_ad_analysis.runAnalysis')}
                </Button>
                <Button
                  startIcon={<EditIcon />}
                  onClick={() => {
                    setDealersAnalysisProdutName(productName);
                    setAnalysisModalMode('viewedit');
                    setAnalysisModalOpenState(true);
                    setDealersAnalysisData(alreadyDoneAnalysis);
                  }}
                  disabled={!value || !alreadyDoneAnalysis}
                  variant="outlined"
                >
                  {t('i18n.sales_ad_analysis.viewEditAnalysis')}
                </Button>
              </ButtonContainer>
            </>
          );
        }
      }
    },
    {
      name: 'analysis',
      label: t('i18n.sales_ad_analysis.data.last_analysis'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return (
            <>
              {value?.data_execution
                ? moment(value?.data_execution).format('DD/MM/YYYY HH:mm')
                : '-'}
            </>
          );
        }
      }
    }
  ];

  const removeFilterFromChip = () => {
    setFilterData({});
    applyFilter([], []);
  };

  const FilterChip = ({ filter }) => {
    return (
      filter?.categories.length > 0 && (
        <Box item>
          <Card
            style={{
              borderRadius: '10px'
            }}
          >
            <CardContent style={cardStyle}>
              <FilterCardHeader>
                <Typography variant="overline" style={typographyStyle}>
                  {t('i18n.sales_ad_analysis.data.category')}
                </Typography>
                <Tooltip title={t('i18n.dashboard.REMOVE_FILTER')}>
                  <RemoveFilterCardButton
                    color="primary"
                    aria-label="delete"
                    onClick={() => {
                      removeFilterFromChip();
                    }}
                  >
                    <CloseIcon />
                  </RemoveFilterCardButton>
                </Tooltip>
              </FilterCardHeader>
              <CustomTypographyDescribe>
                {filter?.categories?.map(item => (item += ', '))}
              </CustomTypographyDescribe>
            </CardContent>
          </Card>
        </Box>
      )
    );
  };
  return (
    <>
      <Page title={t('i18n.sales_ad_analysis.title')}>
        <FilterDrawer
          applyFilter={applyFilter}
          categories={categories}
          fullData={fullData}
          triggerApplyFilter={triggerApplyFilter}
          filterData={filterData}
          setFilterData={setFilterData}
        />
        <Container maxWidth={false}>
          <Box mt={3} ml={7}>
            <CustomDataTableWrapper style={{ opacity: loading ? '0.3' : '1' }}>
              <CustomDataTable
                // title={t('i18n.sales_ad_analysis.title')}
                title={
                  <Box display="flex" mb={2} style={{ gap: '15px' }}>
                    {Object.entries(filterData).map(([key], index) => (
                      <FilterChip
                        {...{ index }}
                        itemKey={key}
                        key={Math.random()}
                        filter={filterData}
                      />
                    ))}
                  </Box>
                }
                data={reducedData}
                columns={columns}
                options={options}
                selectableRowsOnClick
              />
            </CustomDataTableWrapper>
            <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
              <CircularProgress />
            </StyledSpinner>
          </Box>
        </Container>
      </Page>
      <AcerModal
        fullWidth
        classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
        onClose={() => {
          setAnalysisModalOpenState(false);
        }}
        open={analysisModalOpenState}
        modalTitle={
          analysisModalMode === 'new'
            ? t('i18n.sales_ad_analysis.new')
            : t('i18n.sales_ad_analysis.viewEditAnalysis')
        }
      >
        {!loadedDealersAnalysis ? (
          <LinearProgress />
        ) : (
          <>
            <AnalysisView
              onSuccessfulEdition={() => {
                auxLoadTableContent();
                setAnalysisModalOpenState(false);
              }}
              dealersAnalysisProdutName={dealersAnalysisProdutName}
              dealersAnalysisData={dealersAnalysisData}
            />
          </>
        )}
      </AcerModal>

      <AcerModal
        fullWidth
        classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
        onClose={() => {
          HandleShowSynonyms();
        }}
        open={showSynonyms}
        modalTitle={t('i18n.synonyms.edit')}
      >
        {!loadedDealersAnalysis ? (
          <LinearProgress />
        ) : (
          <>
            <SynonymsCRUD />
          </>
        )}
      </AcerModal>

      <ConfirmationModal
        message={confirmationModalMessage}
        submitConfirmation={() => {
          confirmationModalAction();
          setConfirmationModalOpen(false);
        }}
        openState={confirmationModalOpen}
        closeParent={() => {
          setConfirmationModalOpen(false);
        }}
      />
    </>
  );
};

export default AnaliseAnunciosListView;
