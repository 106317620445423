import React, { useState, useEffect } from 'react';
import {
  Grid,
  CardContent,
  Card,
  Divider,
  IconButton,
  Button,
  TextField
} from '@material-ui/core';
import styled from 'styled-components';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { COLORS } from '../../../../styles/settings/colors.config';
import { inputStyles } from '../../../../components/inputStyle';
import API from '../../../../services/api';
// import ChatBotRecommendation from '../../new-chat/box-chat/Chat-bot-recomendation';

import { NewHistoryClient } from './HistoryClient.component';

import timezoneFormat from '../../../../utils/timezoneFormat';

const DetailsWrapper = styled(Grid)`
  border-left: 1px solid #ccc;
`;

const CustomHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MessageActions = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChatItem = styled('div')`
  margin-bottom: 20px;
  ${props =>
    props.ajust &&
    'margin-bottom: 0px; padding-left: 15px; padding-right: 15px;'};
`;

const ChatText = styled('p')`
  display: flex;
  font-size: ${props => (props.size ? props.size : '14px')};
  color: ${props => (props.color ? props.color : COLORS.grayText)};
  font-weight: ${props => (props.bold ? props.bold : 'normal')};
  padding: ${props => (props.padding ? props.padding : '0px 15px')};
  color: ${props => (props.color ? props.color : COLORS.grayText)};
`;

const ChatTitle = styled(ChatText)`
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-weight: ${props => props.bold && props.bold};
  font-size: 14px;
`;

const ChatWrapper = styled('div')`
  padding: 15px 0px;
`;

const ClientDetails = ({
  clientInAttendance,
  handleShowClientDetails,
  handleShowHistory
  // handleShowProductList,
  // chatName,
  // isLoading,
  // chatEmail,
  // botProductsList,
  // setAttendantMessage
}) => {
  const { t } = useTranslation();

  // const handleGetSuggestProduct = product => {
  //   setAttendantMessage(product.urlLink);
  // };

  let started;

  if (clientInAttendance && clientInAttendance?.messages) {
    started = timezoneFormat(clientInAttendance?.messages[0]?.date, 'HH:mm');
  }

  // useEffect(() => {
  //   handleShowProductList();
  // }, []);

  return (
    <DetailsWrapper item xs={3}>
      <CardContent>
        <CustomHeader>
          <MessageActions>
            <IconButton onClick={() => handleShowClientDetails()}>
              <ArrowBackIosIcon />
            </IconButton>
          </MessageActions>
        </CustomHeader>
      </CardContent>
      <Divider />
      <div>
        <ChatTitle bold="bold">{t('i18n.chatview.CHAT_DETAIL')}</ChatTitle>
        <ChatWrapper>
          <ChatItem>
            <ChatText>
              {t('i18n.chatview.CHAT_STARTED')} {started}
            </ChatText>
          </ChatItem>
          <ChatItem>
            <ChatText>{t('i18n.ticketcontainer.CLIENT_NAME')}:</ChatText>
            <ChatText>{clientInAttendance.name}</ChatText>
          </ChatItem>
          <ChatItem>
            <ChatText>Email:</ChatText>
            <ChatText>{clientInAttendance.email}</ChatText>
          </ChatItem>
          <ChatItem ajust="ajust">
            <Button
              onClick={() => handleShowHistory()}
              variant="outlined"
              size="small"
              color="primary"
            >
              {t('i18n.chatview.BROWSING_HISTORY')}
            </Button>
          </ChatItem>
        </ChatWrapper>
      </div>
      {/* <div>
        <ChatTitle bold="bold">
          {t('i18n.chatview.PRODUCT_SUGGESTION')}
        </ChatTitle>
        <ChatBotRecommendation
          // handleShowProductList={handleShowProductList}
          handleGetSuggestProduct={handleGetSuggestProduct}
          isLoading={isLoading}
          botProductsList={botProductsList}
        />
      </div> */}
    </DetailsWrapper>
  );
};

const NewClientDetails = ({
  clientInAttendance,
  // setClientInAttendance,
  socket,
  setShowDetails,
  showDetails,
  setShowHistoric
}) => {
  let subscribe;
  let started;

  const { enqueueSnackbar } = useSnackbar();
  const reduxDispatcher = useDispatch();
  const { t } = useTranslation();
  const classes = inputStyles();
  // const [botProductsList, setBotProductsList] = useState([]);
  const [showClientHistoric, setShowClienHistoric] = useState(false);
  // const [isLoading, setIsloading] = useState(false);
  const [newMessage, setNewMessage] = useState({});
  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const myClientNameEmail = useSelector(
    state => state.clientInAttendanceReducer
  );

  if (clientInAttendance && clientInAttendance?.messages) {
    started = timezoneFormat(clientInAttendance?.messages[0]?.date, 'HH:mm');
  }

  const initSubscribeSocket = () => {
    subscribe = socket?.newchatSubject.subscribe(value => {
      const response = JSON.parse(value.data);
      if (
        response?.message &&
        response?.message !== 'Forbidden' &&
        response?.message !== 'Internal server error'
      ) {
        // Quando o cliente manda uma mensagem
        setNewMessage(response);
      }
    });
  };

  // const handleGetSuggestProduct = product => {
  //   // Não me lembro o que essa função faz
  //   // setAttendantMessage(product.urlLink);
  // };

  // const handleShowProductList = data => {
  //   API.post(`auth/ms-chat/product-recomendations`, data)
  //     .then(res => {
  //       setBotProductsList(res.data);
  //       setIsloading(false);
  //     })
  //     .catch(() => { });
  // };

  const submitNewData = (nName, nEmail) => {
    const payload = {
      name: nName,
      email: nEmail,
      hashConnection: clientInAttendance.hashConnection
    };
    API.post(`/auth/ms-chat/customer-identification`, payload)
      .then(() => {})
      .catch(() => {
        enqueueSnackbar(t('i18n.customer.CUSTOMER_IDENTIFY_ERROR'), {
          variant: 'error'
        });
      });
  };

  // useEffect(() => {
  //   const data = {
  //     email: newMessage?.email,
  //     pageTitle: newMessage?.messagePage?.parentTitle
  //   };
  //   // if (newMessage && newMessage?.email) {
  //   //   handleShowProductList(data);
  //   // }
  // }, [newMessage]);

  // useEffect(() => {
  //   const data = {
  //     email: clientInAttendance.email,
  //     pageTitle: clientInAttendance.parentTitle
  //   };
  //   handleShowProductList(data);
  // }, [clientInAttendance]);

  useEffect(() => {
    if (showDetails) {
      initSubscribeSocket();
    }
  }, [showDetails]);

  useEffect(() => {
    initSubscribeSocket();
  }, [socket]);

  useEffect(() => {
    if (subscribe) {
      subscribe.unsubscribe();
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     myClientNameEmail.hashConnection === clientInAttendance.hashConnection
  //   ) {
  //     setNewName(myClientNameEmail.name);
  //     setNewEmail(myClientNameEmail.email);
  //   } else {
  //     setNewName(clientInAttendance.name);
  //     setNewEmail(clientInAttendance.email);
  //   }
  // }, [myClientNameEmail, clientInAttendance]);

  return (
    <>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={!showClientHistoric ? 12 : 6} style={{ height: '100%' }}>
          <Card style={{ height: '100%' }}>
            <DetailsWrapper item xs={12} style={{ height: '100%' }}>
              <CustomHeader>
                <ChatText>{t('i18n.chatview.CHAT_DETAIL')}</ChatText>
                <MessageActions>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setShowDetails(false);
                      setShowHistoric(false);
                    }}
                  >
                    <HighlightOff />
                  </IconButton>
                </MessageActions>
              </CustomHeader>
              <Divider />
              <div>
                <ChatWrapper>
                  <ChatItem>
                    <ChatText bold="bold" color={COLORS.blueText}>
                      {t('i18n.chatview.CHAT_STARTED')} {started}
                    </ChatText>
                  </ChatItem>
                  <ChatItem>
                    <ChatText>
                      <Button
                        onClick={() => {
                          setShowClienHistoric(true);
                          setShowHistoric(true);
                        }}
                        variant="outlined"
                        size="small"
                        color="primary"
                      >
                        {t('i18n.chatview.BROWSING_HISTORY')}
                      </Button>
                    </ChatText>
                  </ChatItem>
                  <ChatItem>
                    <ChatText bold="bold">Atualizar dados do cliente:</ChatText>
                  </ChatItem>
                  <ChatItem>
                    <ChatText>
                      <TextField
                        size="small"
                        value={newName}
                        onChange={htmlElemtEvent => {
                          setNewName(htmlElemtEvent.target.value);
                        }}
                        fullWidth
                        label={t('i18n.ticketcontainer.CLIENT_NAME')}
                        variant="outlined"
                        className={classes.inputMedium}
                      />
                    </ChatText>
                  </ChatItem>
                  <ChatItem>
                    <ChatText>
                      <TextField
                        size="small"
                        value={newEmail}
                        onChange={htmlElemtEvent => {
                          setNewEmail(htmlElemtEvent.target.value);
                        }}
                        fullWidth
                        label="E-mail"
                        variant="outlined"
                        className={classes.inputMedium}
                      />
                    </ChatText>
                  </ChatItem>
                  <ChatItem>
                    <ChatText style={{ justifyContent: 'end' }}>
                      <Button
                        disabled={newName.trim().length === 0}
                        onClick={() => {
                          const updatedClientInAttendance = {
                            ...clientInAttendance
                          };
                          updatedClientInAttendance.name = newName;
                          updatedClientInAttendance.email = newEmail;
                          // setClientInAttendance(updatedClientInAttendance);

                          reduxDispatcher({
                            type: 'SET_CLIENT_NAME_EMAIL',
                            clientNameEmail: [
                              ...myClientNameEmail,
                              ...[
                                {
                                  name: newName,
                                  email: newEmail,
                                  hashConnection:
                                    clientInAttendance.hashConnection
                                }
                              ]
                            ]
                          });

                          submitNewData(newName, newEmail);
                          setNewName('');
                          setNewEmail('');
                        }}
                        variant="contained"
                        size="small"
                        color="primary"
                      >
                        {t('i18n.chatview.TOOLTIP_REFRESH')}
                      </Button>
                    </ChatText>
                  </ChatItem>
                </ChatWrapper>
              </div>
              <Divider />
              {/* <div>
                <ChatTitle bold="bold">
                  {t('i18n.chatview.PRODUCT_SUGGESTION')}:
                </ChatTitle>
                <ChatBotRecommendation
                  handleShowProductList={handleShowProductList}
                  // handleGetSuggestProduct={handleGetSuggestProduct}
                  isLoading={isLoading}
                  botProductsList={botProductsList}
                />
              </div> */}
            </DetailsWrapper>
          </Card>
        </Grid>
        {showClientHistoric && (
          <Grid item xs={6} style={{ height: '100%' }}>
            <NewHistoryClient
              lastMessage={newMessage}
              clientInAttendance={clientInAttendance}
              setShowClienHistoric={setShowClienHistoric}
              setShowHistoric={setShowHistoric}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export { ClientDetails, NewClientDetails };
