import React from 'react';
import InvoiceModal from '../../../../../../layouts/DashboardLayout/Tools/InvoiceModal';

export const InvoiceModalIframe = ({
  openInvoiceModal,
  handleCloseInvoice,
  ticketData,
}) => {
  return (
    <InvoiceModal
      open={openInvoiceModal}
      onClose={handleCloseInvoice}
      customerCpf={ticketData?.customerCPF}
    />
  );
};
