import React from 'react';
import ModalCustomerHistoric from '../../../TicketComponents/ModalCustomerHistoric';

export const ModalCustomerHistoricIframe = ({
  ticketData,
  ticketOrder,
  openModal,
  setOpenModal
}) => {
  return (
    <ModalCustomerHistoric
      customerCpf={
        ticketData?.customerCPF || ticketOrder?.cliente?.documento || ''
      }
      open={openModal}
      onClose={() => setOpenModal(false)}
    />
  );
};
