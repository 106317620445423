import { Box, Container, Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import AcerModal from '../../../../components/AcerModal';
import Page from '../../../../components/Page';
import API from '../../../../services/api';
import OrderInfo from './OrderInfo';
import Loader from '../../../../components/Loader';

const OrdersDetails = ({
  onClose,
  selectedValue,
  open,
  orderId,
  orderDocument
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [pedido, setPedido] = useState([]);
  const history = useHistory();
  const [isLoading, setIsloading] = useState(false);

  const checkProtocolToAssociate = () => {
    return (
      history.location.pathname.search('/ticket-container/') === -1 &&
      history.location.pathname.search('/tickets/details/') === -1 && 
      history.location.pathname.search('/ticket/details/') === -1 
    );
  };

  const handleClose = () => {
    onClose(selectedValue);
  };

  const loadPedido = orderIdProp => {
    if (!orderIdProp) {
      return;
    }
    setIsloading(true);
    API.get(`/auth/ms-ticket/v1/pedidos/${orderIdProp}`)
      .then(pedidosResponse => {
        setPedido(pedidosResponse.data);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const associateTicket = async () => {
    if (checkProtocolToAssociate()) {
      enqueueSnackbar(t('i18n.order.TICKET_NOT_SELECTED'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      return;
    }
    try {
      await API.post(`/auth/ms-ticket/v1/pedidos/assign-ticket`, {
        ticketId: id,
        orderId
      });
      handleClose();
    } catch (error) {
      enqueueSnackbar(`${t(error.response.data.message.key)}`, {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    loadPedido(orderId);
  }, [orderId]);

  return (
    <Page>
      {isLoading ? (
        <Loader />
      ) : (
        <AcerModal
          fullWidth
          maxWidth="md"
          onClose={handleClose}
          open={open}
          modalTitle={`${t('i18n.orderinfo.ORDER')}: ${orderId}`}
          hasAction={!checkProtocolToAssociate()}
          cancelActionFunc={handleClose}
          cancelActionLabel={t('i18n.simplewords.CANCEL')}
          confirmActionFunc={associateTicket}
          confirmActionLabel={`${t('i18n.orderinfo.ASSOCIATE')} ${id}`}
        >
          <Page>
            <Container>
              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <OrderInfo pedido={pedido} orderDocument={orderDocument} />
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Page>
        </AcerModal>
      )}
    </Page>
  );
};

OrdersDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default OrdersDetails;
