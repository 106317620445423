import React, { useState } from 'react';
import { Typography, Box, Grid, Button } from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';
import { Delete } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../../utils/ConfirmationModal';
import { COLORS } from '../../../../styles/settings/colors.config';

const CustomBox = styled(Box)`
  font-family: Roboto;
  background: ${props => (props.active === 'true' ? '#FFEED2' : '#fff')};
  box-shadow: 0px 0px 6px #00000030;
  margin-top: 16px;
  border-radius: 20px;
  border: ${props =>
    props.active === 'true' && `2px solid ${COLORS.WarningColorSLA}`};
`;

const CustomTypography = styled.span`
  color: ${COLORS.grayText};
`;

const CustomText = styled(Typography)`
  color: ${COLORS.greenText};
  font-weight: bold;
  :hover {
    text-decoration: underline;
  }
`;

// const CustomSmall = styled(Typography)`
//   color: #aaa;
//   font-size: 12px;
// `;

const AvailableUnavailableText = ({ available }) => {
  const defineAvailable = () => {
    const availableType = typeof available;

    if (availableType === 'boolean') return available;

    if (availableType === 'string') {
      if (available === 'true') return true;
    }

    return false;
  };

  return (
    <span>
      <span
        style={{
          color: defineAvailable() ? COLORS.greenText : COLORS.btnDangerColor,
          fontWeight: 'bold'
        }}
      >
        {defineAvailable() ? 'Disponível' : 'Indisponível'}
      </span>{' '}
      para atendimento
    </span>
  );
};

const ChatPeriodText = ({ startDate, endDate }) => {
  const isoStartDate = moment(startDate).toISOString();
  const fixedStartDate = moment(isoStartDate).format('DD/MM/YYYY');
  let fixedEndDate = null;

  if (endDate) {
    const isoEndDate = moment(endDate).toISOString();
    fixedEndDate = moment(isoEndDate).format('DD/MM/YYYY');
  }

  return (
    <>
      {endDate && (
        <>
          <div>
            <b>de {fixedStartDate}</b>
          </div>
          <div>
            <b>a {fixedEndDate}</b>
          </div>
          <CustomTypography>
            Após a vigência retorna ao horário padrão
          </CustomTypography>
        </>
      )}

      {!endDate && (
        <>
          <div>
            <b>a partir de {fixedStartDate}</b>
          </div>
          <CustomTypography>Sem término</CustomTypography>
        </>
      )}
    </>
  );
};

const ConfigRow = ({
  item,
  handleDeleteUnique,
  handleEdit,
  type,
  handleEnable
}) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  return (
    <CustomBox
      display="flex"
      style={{ width: '100%' }}
      py={4}
      px={2}
      active={item?.current ? 'true' : 'false'}
    >
      <Grid container spacing={3}>
        <Grid item xs={1}>
          {type !== 'default' && (
            <Delete onClick={() => setConfirmationModalOpen(true)} />
          )}
        </Grid>

        <Grid item xs={3}>
          <CustomText>{item?.description}</CustomText>
        </Grid>

        <Grid item xs={2}>
          <div>
            <b>
              {`${item?.attendingTimePeriod?.startingTime} 
              às 
              ${item?.attendingTimePeriod?.endingTime}`}
            </b>
          </div>
          <div>
            <AvailableUnavailableText available={item?.chatAvailable} />
          </div>
        </Grid>

        <Grid item xs={3}>
          <ChatPeriodText
            startDate={item?.validityPeriod?.startingDate}
            endDate={item?.validityPeriod?.endingDate}
          />
        </Grid>

        <Grid item xs={1}>
          <b>{moment(item?.created).format('DD/MM/YYYY HH:MM')}</b>
        </Grid>

        <Grid item xs={1}>
          <Button color="primary" onClick={() => handleEdit(item)}>
            Editar
          </Button>
        </Grid>

        <Grid item xs={1}>
          {type !== 'default' && (
            <Button color="primary" onClick={() => handleEnable(item)}>
              {item?.enabled ? 'Desabilitar' : 'Habilitar'}
            </Button>
          )}
        </Grid>
      </Grid>

      <ConfirmationModal
        message="Confirma a exclusão do horário?"
        title="Atenção"
        submitConfirmation={() => {
          handleDeleteUnique(item?.id);
          setConfirmationModalOpen(false);
        }}
        openState={confirmationModalOpen}
        closeParent={() => {
          setConfirmationModalOpen(false);
        }}
      />
    </CustomBox>
  );
};

const ConfigSection = ({
  data,
  type,
  handleEdit,
  handleDeleteUnique,
  handleEnable
}) => {
  const { t } = useTranslation();
  return (
    <Box style={{ width: '100%' }}>
      {data.map(item => (
        <ConfigRow
          key={Math.random()}
          {...{ item, handleDeleteUnique, handleEdit, type, handleEnable }}
        />
      ))}
      {data.length === 0 && (
        <Alert severity="info">
          {t('i18n.monitor.chat.SETTINGS_NO_SCHEDULE')}
        </Alert>
      )}
    </Box>
  );
};

export { ConfigSection };
