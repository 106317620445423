import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SelectSubReasonComponent from '../../../../components/Select.subreason.component';
import reasonList from '../../../../utils/reasonList';

const FilterSubReason = ({ filterForm, handleSetFilter }) => {
  const reasons = reasonList();
  const { t } = useTranslation();

  const groupedData = reasons?.reduce((result, item) => {
    const group = result?.find(({ label }) => label === item?.group);

    if (group) {
      group.itens.push({ name: item?.name, detail: item?.detail });
      return result;
    }

    result.push({
      label: item?.group,
      itens: [{ name: item?.name, detail: item?.detail }]
    });
    return result;
  }, []);

  const [subList, setSubList] = useState([]);
  const [hide, setHide] = useState('');

  const handleFilterData = () => {
    let newSubList = [];
    let shouldHide = false;
    if (filterForm.reasonGroup === "N1") {
      const newData = groupedData.filter((e) => e.label === 'N1')
       newData.forEach((group) => {
        const currentGroup = group.itens.find(
          (value) => value.name === filterForm.reasonName
        );
        if (!currentGroup) return;
        const currentGroupSubReasons = currentGroup?.detail;
        const isDetailsEmpty = currentGroupSubReasons?.length === 0;
        const includesFreeText = currentGroupSubReasons?.find(
          ({ name }) => name === 'Texto livre'
        );
  
        if (includesFreeText || isDetailsEmpty) {
          shouldHide = true;
        }
  
        newSubList = currentGroupSubReasons;
      });
    } else if(filterForm.reasonGroup === "N2"){
      const newData = groupedData.filter((e) => e.label === 'N2')
       newData.forEach((group) => {
        const currentGroup = group.itens.find(
          (value) => value.name === filterForm.reasonName
        );
        if (!currentGroup) return;
        const currentGroupSubReasons = currentGroup?.detail;
        const isDetailsEmpty = currentGroupSubReasons?.length === 0;
        const includesFreeText = currentGroupSubReasons?.find(
          ({ name }) => name === 'Texto livre'
        );
  
        if (includesFreeText || isDetailsEmpty) {
          shouldHide = true;
        }
  
        newSubList = currentGroupSubReasons;
      });
    } else if (filterForm.reasonGroup === "N3"){
      const newData = groupedData.filter((e) => e.label === 'N3')
       newData.forEach((group) => {
        const currentGroup = group.itens.find(
          (value) => value.name === filterForm.reasonName
        );
        if (!currentGroup) return;
        const currentGroupSubReasons = currentGroup?.detail;
        const isDetailsEmpty = currentGroupSubReasons?.length === 0;
        const includesFreeText = currentGroupSubReasons?.find(
          ({ name }) => name === 'Texto livre'
        );
  
        if (includesFreeText || isDetailsEmpty) {
          shouldHide = true;
        }
  
        newSubList = currentGroupSubReasons;
      });
    }
    

    setHide(shouldHide ? 'none' : '');
    setSubList(newSubList);
  };

  useEffect(() => {
    handleFilterData();
  }, [filterForm]);

  return (
    <Box mb={2} display={hide}>
      <Grid container>
        <Grid item xs={7}>
          <SelectSubReasonComponent
            form={filterForm}
            setForm={handleSetFilter}
            label={t('i18n.ticketcontainer.REASON_DETAIL_LABEL')}
            name="reasonDesc"
            gapOptions="0px"
            options={subList}
            disabled={filterForm.reasonName === undefined}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterSubReason;
