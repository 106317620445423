import { Button, TableCell, ThemeProvider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createStore } from 'redux';

import App from './App';
import allReducers from './reducersCombiner';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import './sentry';

/**
 * Fix to isEmptyError
 * It's a temporaty error from component - once fixed, please remove this section
 */

const oldRender = TableCell.render;

// eslint-disable-next-line
TableCell.render = function(...args) {
  const [props, ...otherArgs] = args;
  if (typeof props === 'object' && props && 'isEmpty' in props) {
    const { isEmpty, ...propsWithoutEmpty } = props;
    return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
  }
  return oldRender.apply(this, args);
};

/** End of isEmpry Fix */

const store = createStore(
  allReducers,
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const notistackRef = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
};

const preventSessionStorageFromBeingClonedWhenTabIsDuplicated = () => {
  window.addEventListener('beforeunload', () => {
    sessionStorage.removeItem('__lock');
  });
  if (sessionStorage.getItem('__lock')) {
    sessionStorage.clear();
  }
  sessionStorage.setItem('__lock', '1');
};
preventSessionStorageFromBeingClonedWhenTabIsDuplicated();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          ref={notistackRef}
          action={key => (
            <Button onClick={onClickDismiss(key)} className="white-text">
              <CloseIcon className="white-text" />
            </Button>
          )}
        >
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
