import {
  Box,
  Card,
  Divider,
  ThemeProvider,
  Tooltip,
  createTheme
} from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import { COLORS } from '../../../../styles/settings/colors.config';
import { colors } from '../../services/dashboardServices';
import {
  CardDataWrapper,
  CardTitle,
  CustomArrowDropDown,
  CustomArrowDropDownUp,
  CustomCardContent,
  CustomHelpIcon,
  CustomHintText,
  CustomParagraphArrowDropDownUp,
  CustomTypograph,
  InConstruction
} from '../../styles';
import DonutChart from './DonutChart';
import TableValue from './components/TableValue';
import { numericValue, tableOptions } from './utils';

const theme = createTheme({
  overrides: {
    MuiToolbar: { root: { display: 'none' } },
    MuiTableHead: {
      root: {
        display: 'none'
      }
    }
  }
});

const GoalInfo = ({ goal }) => (
  <div
    style={{
      padding: '5px',
      borderRadius: '5px',
      marginLeft: '10px',
      fontSize: '14px',
      fontWeight: 'bold',
      color: COLORS.blueText
    }}
  >
    Meta: {goal}
  </div>
);

const DonutCard = ({
  title,
  showMoreDetails,
  descriptionHint,
  className,
  showInConstruction,
  data,
  cardType,
  dashboardFilter,
  totalItem,
  isAdmin,
  goalPercent,
  tooltipText,
  goalColor,
  goalText,
  type: chartType,
  hideMeta,
  isB2B
  // isRealTime
}) => {
  const [showDetails, setShowDetails] = useState(showMoreDetails);

  const { attendant } = dashboardFilter;

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const totalValue = !isAdmin
    ? totalItem
    : data?.reduce((acc, item) => acc + numericValue(item, cardType), 0);

  const tableData = data?.map((item, index) => ({
    type: item.attendant || 'Sem Registro',
    value: numericValue(item, cardType),
    color: colors[index],
    percent: (numericValue(item, cardType) / totalValue) * 100
  }));

  if (!isAdmin) {
    let totalItems = 0;
    let teamJSON;
    let itemJSON;
    if (data.length === 0) {
      teamJSON = {
        type: 'Equipe',
        value: totalValue,
        color: colors[0],
        percent: 100
      };
      itemJSON = {
        type: attendant,
        value: 0,
        color: colors[1],
        percent: 0
      };
      tableData.push(teamJSON);
      tableData.push(itemJSON);
    } else {
      totalItems = data?.reduce(
        (acc, item) => acc + numericValue(item, cardType),
        0
      );
      teamJSON = {
        type: 'Equipe',
        value: totalValue - totalItems,
        color: colors[1],
        percent: ((totalValue - totalItems) / totalValue) * 100
      };
      tableData.push(teamJSON);
    }
  }

  const chartData = tableData?.map(({ type, value }) => ({
    type,
    value
  }));

  const columns = [
    {
      name: 'value',
      label: 'Total',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const { value, percent, color, type: login } = tableData[dataIndex];

          return (
            <TableValue
              {...{
                value,
                percent,
                color,
                dashboardFilter,
                attendant: login,
                chartType,
                isB2B
              }}
            />
          );
        }
      }
    },
    {
      name: 'type',
      label: 'Atendente',
      options: {
        customBodyRender: (value) => (
          <span
            aria-hidden="true"
            style={{ color: COLORS.grayText /** cursor: 'pointer' */ }}
            /** Uncomment line below when implementing drilldown */
            // onClick={() => handleOpenNewTab(value)}
          >
            {value}
          </span>
        )
      }
    }
  ];

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Card
        style={{ width: '100%', marginBottom: '20px', borderRadius: '20px' }}
        className={className}
      >
        <CardTitle>
          <CustomTypograph variant="h5">{title}</CustomTypograph>
          <Box style={{ gap: '10px' }} display="flex" justifyContent="flex-end">
            {descriptionHint && (
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                  popperOptions: {
                    positionFixed: true,
                    modifiers: {
                      preventOverflow: {
                        enabled: true,
                        boundariesElement: 'window'
                      }
                    }
                  }
                }}
                placement="top"
                arrow
                title={<CustomHintText>{descriptionHint}</CustomHintText>}
              >
                <CustomHelpIcon fontSize="small" color="primary" />
              </Tooltip>
            )}
          </Box>
        </CardTitle>
        <Divider />
        <CustomCardContent style={{ zoom: '110%' }}>
          <CardDataWrapper
            style={{
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {totalValue ? (
              <DonutChart data={chartData} {...{ colors, totalValue }} />
            ) : (
              <Alert severity="info">Não há dados disponíveis</Alert>
            )}

            {goalPercent && !hideMeta && (
              <>
                <CustomParagraphArrowDropDownUp>
                  <CustomArrowDropDownUp />
                </CustomParagraphArrowDropDownUp>

                <div
                  style={{
                    marginTop: '15px',
                    width: '100%',
                    fontFamily: 'Roboto'
                  }}
                >
                  <Divider />
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="10px"
                  >
                    <span
                      style={{
                        color: goalColor,
                        fontWeight: 500,
                        fontSize: '30px'
                      }}
                    >
                      {goalPercent}%
                    </span>
                    <Tooltip title={tooltipText}>
                      <HelpOutline
                        style={{ fontSize: '18px', marginLeft: '10px' }}
                      />
                    </Tooltip>
                    <GoalInfo goal={goalText} />
                  </Box>
                </div>
              </>
            )}

            {totalValue !== 0 && (
              <CustomParagraphArrowDropDownUp>
                <CustomArrowDropDownUp />
              </CustomParagraphArrowDropDownUp>
            )}
          </CardDataWrapper>
        </CustomCardContent>

        {totalValue !== 0 && (
          <>
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CustomArrowDropDown
                onClick={toggleDetails}
                iconcolor={COLORS.greenText}
              />
            </div>
          </>
        )}

        {showDetails && totalValue !== 0 && (
          <ThemeProvider theme={theme}>
            <MUIDataTable
              title={' '}
              data={tableData}
              columns={columns}
              options={tableOptions}
            />
          </ThemeProvider>
        )}
      </Card>
      {showInConstruction && <InConstruction />}
    </div>
  );
};

export default DonutCard;
