import { Container } from '@material-ui/core';
import { KeyboardBackspace } from '@material-ui/icons';
import React from 'react';
import { StyledButton } from './styles';

export default function ActionButtons({ details, updateData }) {
  return (
    <Container
      maxWidth="xl"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px'
      }}
    >
      <StyledButton
        variant="outlined"
        color="primary"
        startIcon={<KeyboardBackspace />}
        onClick={() => {
          window.location.href = '/cancelling/service';
        }}
      >
        {!details.isReviewedByLogistics ? 'Cancelar e voltar' : 'Voltar'}
      </StyledButton>
      <StyledButton
        variant="contained"
        color="primary"
        onClick={updateData}
        disabled={details.isReviewedByService}
      >
        Enviar parecer service
      </StyledButton>
    </Container>
  );
}
