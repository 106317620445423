import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import {
  OrdersHeader,
  OrdersHeaderSection,
  OrdersHeaderValue
} from '../../../Dialogs/styles';

const OrdersHeaderTitle = styled.div`
  color: #666666;
  font-size: 12px;
`;

const OrdersHeaderDiv = ({
  orderHeaderDataLine1,
  orderHeaderDataLine2,
  orderHeaderDataLine3
}) => {
  return (
    <OrdersHeader>
      <Box
        display="flex"
        justifyContent="space-between"
        marginBottom={1}
        alignItems="center"
      >
        {orderHeaderDataLine1.map(({ margin, title, value }) => (
          <OrdersHeaderSection margin={margin} key={Math.random()}>
            <OrdersHeaderTitle>{title}</OrdersHeaderTitle>
            <OrdersHeaderValue>{value}</OrdersHeaderValue>
          </OrdersHeaderSection>
        ))}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        marginBottom={1}
        alignItems="center"
      >
        {orderHeaderDataLine2.map(({ margin, title, value }) => (
          <OrdersHeaderSection margin={margin} key={Math.random()}>
            <OrdersHeaderTitle>{title}</OrdersHeaderTitle>
            <OrdersHeaderValue>{value}</OrdersHeaderValue>
          </OrdersHeaderSection>
        ))}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {orderHeaderDataLine3.map(({ margin, title, value }) => (
          <OrdersHeaderSection margin={margin} key={Math.random()}>
            <OrdersHeaderTitle>{title}</OrdersHeaderTitle>
            <OrdersHeaderValue>{value}</OrdersHeaderValue>
          </OrdersHeaderSection>
        ))}
      </Box>
    </OrdersHeader>
  );
};

export default OrdersHeaderDiv;
