import moment from 'moment';
import { defaultDateFormat } from '../services/convertDate';
import { defaultHoursNumber } from '../services/config';
import { defaultMarketplacesDashboardFilter } from '../../../hooks/hooks';

const filterNameInStorage = 'newFilterDashChat';

const chatsTabName = 'chatDashboardTab';

const defaultFilter = {
  attendant: '',
  dateAliasInAWayHumanCanRead: defaultHoursNumber.toString(),
  // eslint-disable-next-line camelcase
  report_type: '',
  status: '',
  tabView: 'chatDashboardTab',
  ticket_type: 'AFTER_SALE',
  periodType: 'hourly',
  marketplace: defaultMarketplacesDashboardFilter,
  startDate: defaultDateFormat(
    moment(new Date())
      .add(3, 'hours')
      .subtract(defaultHoursNumber, 'hours')
  ),
  endDate: defaultDateFormat(moment(new Date()).add(3, 'hours'))
};

export { defaultHoursNumber, defaultFilter, filterNameInStorage, chatsTabName };
