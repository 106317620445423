const chatsDashboardFilter = {};
const chatsDashboardEquipData = {};
const chatsDashboardIndividualData = {};
const chatsDashboardLoading = true;
const chatsDashboardRemovedKey = '';

const chatsDashboardEquipDataReducer = (
  state = chatsDashboardEquipData,
  action
) => {
  switch (action.type) {
    case 'SET_CHATS_DASHBOARD_EQUIP_DATA':
      state = action.equipData;
      return state;
    default:
      return state;
  }
};

const chatsDashboardRemovedKeyReducer = (
  state = chatsDashboardRemovedKey,
  action
) => {
  switch (action.type) {
    case 'SET_CHATS_DASHBOARD_REMOVED_KEY':
      state = action.key;
      return state;
    default:
      return state;
  }
};

const chatsDashboardIndividualDataReducer = (
  state = chatsDashboardIndividualData,
  action
) => {
  switch (action.type) {
    case 'SET_CHATS_DASHBOARD_INDIVIDUAL_DATA':
      state = action.individualData;
      return state;
    default:
      return state;
  }
};

const chatsDashboardLoadingReducer = (
  state = chatsDashboardLoading,
  action
) => {
  switch (action.type) {
    case 'SET_CHATS_DASHBOARD_LOADING':
      state = action.loading;
      return state;
    default:
      return state;
  }
};

const chatsDashboardFilterReducer = (state = chatsDashboardFilter, action) => {
  switch (action.type) {
    case 'SET_CHATS_DASHBOARD_FILTER':
      state = action.filter;
      return state;
    default:
      return state;
  }
};

export {
  chatsDashboardFilterReducer,
  chatsDashboardLoadingReducer,
  chatsDashboardEquipDataReducer,
  chatsDashboardIndividualDataReducer,
  chatsDashboardRemovedKeyReducer
};
