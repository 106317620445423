import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Box } from '@material-ui/core';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import API from '../../../services/api';

const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  .hover {
    opacity: 0;
  }
  :hover {
    .hover {
      opacity: 1;
    }
  }
`;

const FavoritesBar = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const reduxDispatcher = useDispatch();
  const favoritesList = useSelector(state => state.loadFavoritesReducer);

  const handleGetFavorites = () => {
    try {
      API.get('auth/ms-ticket/favorites/findAllByUser').then(response => {
        reduxDispatcher({
          type: 'SHOW_FAVORITE_LIMIT',
          favoriteLimit: response.data.length
        });
        reduxDispatcher({
          type: 'LOAD_FAVORITES',
          favorites: response.data
        });
      });
    } catch (e) {
      enqueueSnackbar('Não foi possível carregar os favoritos', {
        variant: 'error'
      });
    }
  };

  const handleClick = favorite => {
    switch (favorite.type) {
      case 'link':
        history.push(favorite.fields[0].value);
        break;
      default:
        history.push(favorite.fields[0].value);
        break;
    }
  };

  useEffect(() => {
    handleGetFavorites();
  }, []);

  return (
    <Box display="flex" style={{ gap: '16px' }}>
      {favoritesList.map(item => (
        <ButtonWrapper display="flex" key={item.id}>
          <Button
            color="primary"
            key={item.name}
            onClick={() => handleClick(item)}
            variant="contained"
          >
            {item.name}
          </Button>
        </ButtonWrapper>
      ))}
    </Box>
  );
};

export { FavoritesBar };
