import React, { useEffect, useState } from 'react';
import {
  Divider,
  Grid,
  makeStyles,
  Typography,
  Button,
  DialogContent,
  createStyles,
  CircularProgress
} from '@material-ui/core';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Refresh } from '@material-ui/icons';
import moment from 'moment';
import AcerModal from '../../../components/AcerModal';
import { CustomTypograph } from '../styles';
import CustomRoundedTable from './CustomRoundedTable';
import { COLORS } from '../../../styles/settings/colors.config';
import API from '../../../services/api';
import { StyledSpinner } from '../../../styles/components/SpinnerDefault';
import NewReportComponent from './newReport.component';

const useStyles = makeStyles({
  divider: {
    margin: '10px 0'
  },
  root: {
    height: '50px'
  }
});

const modalStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '0px !important'
    }
  })
);

export const OrangeTypography = styled(Typography)`
  color: ${COLORS.btnWarningColor};
`;

const ReportDashboardHeaderFilter = ({
  setOpenModal,
  getData,
  reportFilter,
  currentPage,
  pageSize
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const refresh = () => {
    getData(reportFilter, currentPage, pageSize);
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <Button color="primary" onClick={() => setOpenModal(true)}>
            {`+ ${t('i18n.dashboard.mktplacereport.new_report')}`}
          </Button>
          <Button color="primary" onClick={refresh}>
            <Refresh />
          </Button>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </>
  );
};

const NewReportModal = ({
  openModal,
  setOpenModal,
  getData,
  pageSize,
  currentPage,
  reportFilter,
  beDuplicated,
  setBeDuplicated
}) => {
  const classes = modalStyles();
  const { t } = useTranslation();

  return (
    <AcerModal
      fullWidth
      open={openModal}
      modalTitle={t('i18n.dashboard.mktplacereport.generate_new_report')}
      onClose={() => {
        setBeDuplicated({});
        setOpenModal(false);
      }}
      classes={classes}
      freeBody
    >
      <DialogContent className={classes.root}>
        <NewReportComponent
          {...{
            setOpenModal,
            getData,
            pageSize,
            currentPage,
            reportFilter,
            beDuplicated,
            setBeDuplicated
          }}
        />
      </DialogContent>
    </AcerModal>
  );
};

export default function ReportDashboardContent({ marketPlacesSelected }) {
  const reportFilter = useSelector(
    (state) => state.reportsDashboardFilterReducer
  );

  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(15);
  const [reportData, setReportData] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [beDuplicated, setBeDuplicated] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (Object.keys(beDuplicated).length > 0) setOpenModal(true);
  }, [beDuplicated]);

  const columns = [
    {
      name: 'dateCreated',
      label: `${t('i18n.ticketcontainer.CREATED_DATE')}`
    },
    {
      name: 'descricao',
      label: `${t('i18n.dashboard.mktplacereport.report_title_column')}`
    },
    {
      name: 'report_type',
      label: `${t('i18n.dashboard.mktplacereport.report_type_column')}`
    },
    {
      name: '_id',
      label: `${t('i18n.dashboard.mktplacereport.report_file')}`
    }
  ];

  const getData = async (filter, cP, pS) => {
    if (filter.marketplace) {
      reportFilter.startDate = moment(reportFilter.startDate)
        .subtract(3, 'hours')
        .format('YYYY-MM-DDTHH:mm:ss');
      reportFilter.endDate = moment(reportFilter.endDate)
        .subtract(3, 'hours')
        .format('YYYY-MM-DDTHH:mm:ss');
      const body = {
        ...reportFilter,
        ...{
          page: cP,
          size: pS,
          attendant: undefined,
          marketplace: reportFilter?.marketplace.toString().replaceAll(',', ' ')
        }
      };
      setIsLoading(true);
      setReportData([]);
      const result = await API.post(`/auth/lambda/reports`, body);
      if (result.status === 200) {
        const { page, data } = result.data;
        setTotalElements(page?.totalElements || 0);
        setTotalPages(page?.totalPages || 1);
        setReportData(data);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(0);

    const marketPlaceFilter = {
      ...reportFilter,
      ...{ marketplace: marketPlacesSelected }
    };
    sessionStorage.setItem(
      'reportFilterData',
      JSON.stringify(marketPlaceFilter)
    );
  }, [marketPlacesSelected, reportFilter]);

  useEffect(() => {
    getData(reportFilter, currentPage, pageSize);
  }, [currentPage, pageSize, reportFilter]);

  return (
    <>
      <ReportDashboardHeaderFilter
        {...{ setOpenModal, getData, reportFilter, currentPage, pageSize }}
      />
      {!isLoading ? (
        <>
          <Grid container spacing={5}>
            <Grid item xl={12} lg={12} md={12}>
              <CustomTypograph variant="h3">
                {t('i18n.dashboard.mktplacereport.generated_reports')}
              </CustomTypograph>
            </Grid>
          </Grid>
          <CustomRoundedTable
            count={totalElements}
            rows={reportData}
            columns={columns}
            reportData={reportData}
            rowsPerPage={pageSize}
            page={currentPage}
            setCurrentPage={setCurrentPage}
            setOpenModal={setOpenModal}
            setBeDuplicated={setBeDuplicated}
          />
        </>
      ) : (
        <StyledSpinner
          style={{
            display: isLoading ? 'block' : 'none',
            position: 'relative'
          }}
        >
          <CircularProgress />
        </StyledSpinner>
      )}
      <NewReportModal
        {...{
          openModal,
          setOpenModal,
          getData,
          reportFilter,
          currentPage,
          pageSize,
          beDuplicated,
          setBeDuplicated
        }}
      />
    </>
  );
}
