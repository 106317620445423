import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  TextField
  // Card,
  // CardContent,
  // Box,
  // Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import AcerModal from '../../../components/AcerModal';
import API from '../../../services/api';

// const InfoLink = favorite => {
//   const { fields } = favorite;

//   return favorite ? (
//     <Card>
//       <CardContent>
//         <Typography>
//           Link:
//           <b>{fields[0].value}</b>
//         </Typography>
//       </CardContent>
//     </Card>
//   ) : (
//     <></>
//   );
// };

// const InfoFavorite = (type, favorite) => {
//   switch (type) {
//     case 'link':
//       return InfoLink(favorite);
//     default:
//       return InfoLink(favorite);
//   }
// };

const FavoriteModal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const showModal = useSelector(state => state.showFavoriteModalReducer);
  const favoriteDetail = useSelector(state => state.setFavoriteReducer);
  const [favoriteName, setFavoriteName] = useState('');
  const [actionButtonDisabled, setActionButtonDisabled] = useState(false);

  const reduxDispatcher = useDispatch();
  const handleClose = () => {
    reduxDispatcher({
      type: 'SHOW_FAVORITE_MODAL',
      showFavoriteModal: false
    });
  };

  const handleSubmitFavorite = async () => {
    setActionButtonDisabled(true);
    favoriteDetail.name = favoriteName;
    try {
      const response = !favoriteDetail.id
        ? await API.post('auth/ms-ticket/favorites/create', favoriteDetail)
        : await API.put('auth/ms-ticket/favorites/update', favoriteDetail);
      reduxDispatcher({
        type: 'LOAD_FAVORITES',
        favorites: response.data
      });
      reduxDispatcher({
        type: 'SHOW_FAVORITE_LIMIT',
        favoriteLimit: response.data.length
      });
      enqueueSnackbar('Favorito salvo com sucesso.', {
        variant: 'success'
      });
      setActionButtonDisabled(false);
    } catch (e) {
      enqueueSnackbar('Não foi possível salvar o favorito', {
        variant: 'error'
      });
    }

    handleClose();
  };

  const handleDeleteFavorite = async () => {
    try {
      const response = await API.delete(
        `auth/ms-ticket/favorites/deleteById/${favoriteDetail.id}`
      );
      reduxDispatcher({
        type: 'LOAD_FAVORITES',
        favorites: response.data.favorites
      });
      reduxDispatcher({
        type: 'SHOW_FAVORITE_LIMIT',
        favoriteLimit: response.data.favorites.length
      });
      enqueueSnackbar('Favorito apagado com sucesso.', {
        variant: 'success'
      });
      handleClose();
    } catch (e) {
      enqueueSnackbar('Não foi possível apagar o favorito', {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    setFavoriteName(favoriteDetail.name);
  }, [favoriteDetail]);

  return (
    <AcerModal
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      open={showModal}
      modalTitle="Editar favorito"
      hasAction
      confirmActionFunc={() => handleSubmitFavorite(favoriteDetail)}
      confirmActionFuncDisabled={actionButtonDisabled}
      confirmActionLabel={actionButtonDisabled ? 'Aguarde...' : 'Salvar'}
      cancelActionFunc={
        favoriteDetail?.id ? () => handleDeleteFavorite() : () => {}
      }
      cancelActionLabel={favoriteDetail?.id ? 'Excluir' : ''}
    >
      {/* favoriteDetail && (
        <Box pb={3}>{InfoFavorite(favoriteDetail.type, favoriteDetail)}</Box>
      ) */}
      <FormControl fullWidth>
        <TextField
          label="Nome do favorito"
          size="small"
          variant="outlined"
          inputProps={{ maxLength: 30 }}
          value={favoriteName}
          onChange={e => setFavoriteName(e.target.value)}
        />
      </FormControl>
    </AcerModal>
  );
};

export { FavoriteModal };
