import React from 'react';
import TrackingModal from '../../../../../../layouts/DashboardLayout/Tools/TrackingModal';

export const TrackingModalIframe = ({ openTracking, setOpenTracking }) => {
  return (
    <TrackingModal
      open={openTracking}
      onClose={() => setOpenTracking(false)}
      searchCode=""
    />
  );
};
