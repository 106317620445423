import {
  Breadcrumbs,
  Button,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import styled from 'styled-components';
import { COLORS } from '../../../../../../../styles/settings/colors.config';

export const Label = styled(Typography)`
  font-size: 12px;
  text-transform: none;
  text-align: left;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  color: ${props => (props.customcolor ? props.customcolor : 'black')};
  margin-top: ${props => (props.margintop ? props.margintop : '0')};
`;

export const HairlineLabel = styled.span`
  font-weight: 300;
`;

export const Content = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
  text-transform: none;
  text-align: left;
  color: ${props => (props.customcolor ? props.customcolor : 'black')};
`;

export const StyledButton = styled(Button)`
  text-transform: none;
`;

export const CustomBreadCrumbs = styled(Breadcrumbs)`
  .MuiTypography-h6 {
    font-size: 20px;
    font-weight: 400;
    color: ${COLORS.blackText};
  }
`;

export const CSSTextField = withStyles({
  root: {
    minWidth: '100% !important',

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#B7B7B7',
        height: '35px',
        width: '100%'
      },
      '&:hover fieldset': {
        borderColor: '#B7B7B7'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#B7B7B7'
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: '15px 5px 5px 10px',
      fontSize: '14px',

      width: '100%',
      height: '10px',

      backgroundColor: 'white'
    }
  }
})(TextField);
