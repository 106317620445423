import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import InputMask from 'react-input-mask';
import API from '../../../services/api';
import { CustomBreadCrumbs } from '../../../styles/components/CustomDataTable';
import Page from '../../../components/Page';
import timezoneFormat from '../../../utils/timezoneFormat';
import formHelper from '../../../utils/formHelper';

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  textEditWidth: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  tableScrollBody: {
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  tableScrollContainer: {
    height: '200px'
  },
  tableRow: {
    border: 'none'
  },
  breadcrumbs: {
    marginTop: '15px',
    marginLeft: '15px'
  }
}));

const UserView = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const history = useHistory();
  const user = history.location.state.data;
  const [updateUser, setUpdateUser] = useState({
    ...user,
    phone_number: user.phone_number.replace('+55', '')
  });
  const [editMode, setEditMode] = useState(false);
  const [profileList, setProfileList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const fetchPrimaryInfo = () => {
    // Perfis Cadastrados
    API.get(`/auth/ms-users/profile-journey/find-all`).then(response => {
      setProfileList(response.data);
    });
  };

  const handleSave = async () => {
    const {
      email,
      phone_number,
      profileName,
      userType,
      nickname,
      firstName,
      lastName
    } = updateUser;

    if (!formHelper.validateEmail(email)) {
      enqueueSnackbar(t('i18n.validation.INVALIDEMAIL'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      return false;
    }

    const postJSON = {
      username: updateUser.name,
      email,
      phone_number: phone_number.replace('+55', ''),
      profileName: profileName || '',
      nickname,
      userType,
      firstName,
      lastName
    };

    try {
      await API.post(`/auth/ms-users/users/update`, postJSON);
      setEditMode(false);
      setTimeout(() => {
        window.location = '/users';
      }, 2000);
    } catch (error) {
      enqueueSnackbar(`${t(error.response.data.key)}`, {
        variant: 'error'
      });
    }
  };

  const userTypeList = [
    { name: 'Temporário', value: 'temporario' },
    { name: 'Contrato', value: 'contrato' }
  ];

  useEffect(() => {
    fetchPrimaryInfo();
  }, []);

  function validatePhoneInput(event) {
    let { value } = event.target;

    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})/, '($1) ');
    const isMobilePhone = /^[(][0-9][0-9][)][\s][9]/.test(value);

    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }

    if (isMobilePhone) {
      event.currentTarget.maxLength = 16;
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d{2})(\d{1})/, '($1) $2.');
      value = value.replace(/(\d{4})/, '$1-');
      value = value.replace(/(\d{4})/, '$1');
    } else {
      event.currentTarget.maxLength = 14;
      value = value.replace(/(\d{4})/, '$1-');
      value = value.replace(/(\d{4})/, '$1');
    }

    event.currentTarget.value = value;
  }

  return (
    <Page className={classes.root} title="User Container">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxwidth="false">
          <CustomBreadCrumbs
            className={classes.breadcrumbs}
            gutterBottom
            aria-label="breadcrumb"
          >
            <Typography variant="h6">
              <Link
                onClick={() => {
                  history.push('/users');
                }}
                color="inherit"
                to="/users"
              >
                {t('i18n.userview.USERS')}
              </Link>
            </Typography>
            <Typography variant="h6" color="textPrimary">
              {user.name}
            </Typography>
          </CustomBreadCrumbs>

          <Box mt={3}>
            <Card>
              <CardContent>
                <Box display="flex" flexDirection="column">
                  <Box paddingBottom={2}>
                    <TextField
                      variant="outlined"
                      label={t('i18n.companies.COMPANY')}
                      fullWidth
                      value={updateUser?.companyName}
                      size="small"
                      disabled
                    />
                  </Box>
                  <Box paddingBottom={2} display="flex" style={{ gap: '10px' }}>
                    <TextField
                      variant="outlined"
                      label={t('i18n.usercontainer.NAME')}
                      fullWidth
                      value={updateUser?.firstName || ' '}
                      onChange={htmlElemtEvent => {
                        setUpdateUser({
                          ...updateUser,
                          firstName: htmlElemtEvent.target.value
                        });
                      }}
                      size="small"
                      disabled={!editMode}
                    />
                    <TextField
                      variant="outlined"
                      label={t('i18n.usercontainer.LASTNAME')}
                      fullWidth
                      value={updateUser?.lastName || ' '}
                      onChange={htmlElemtEvent => {
                        setUpdateUser({
                          ...updateUser,
                          lastName: htmlElemtEvent.target.value
                        });
                      }}
                      size="small"
                      disabled={!editMode}
                    />
                  </Box>

                  <Box paddingBottom={2} display="flex" style={{ gap: '10px' }}>
                    <TextField
                      variant="outlined"
                      label={t('i18n.usercontainer.NICKNAME')}
                      fullWidth
                      value={updateUser?.nickname || ' '}
                      onChange={htmlElemtEvent => {
                        setUpdateUser({
                          ...updateUser,
                          nickname: htmlElemtEvent.target.value
                        });
                      }}
                      size="small"
                      disabled={!editMode}
                    />

                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel>Perfil</InputLabel>
                      <Select
                        size="small"
                        disabled={!editMode}
                        value={updateUser?.profileName}
                        onChange={htmlElemtEvent => {
                          setUpdateUser({
                            ...updateUser,
                            profileName: htmlElemtEvent.target.value
                          });
                        }}
                        label="Perfil"
                      >
                        {profileList?.map(profile => (
                          <MenuItem
                            key={Math.random()}
                            // eslint-disable-next-line
                            value={profile}
                          >
                            <Chip
                              color="default"
                              size="medium"
                              label={profile}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box paddingBottom={2} display="flex" style={{ gap: '10px' }}>
                    <TextField
                      variant="outlined"
                      label={t('i18n.usercontainer.LOGIN')}
                      fullWidth
                      value={updateUser?.name}
                      size="small"
                      disabled
                      onChange={ev => {
                        setUpdateUser({ ...updateUser, name: ev.target.value });
                      }}
                    />

                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel>Tipo do Usuário</InputLabel>
                      <Select
                        value={updateUser?.userType}
                        disabled={!editMode}
                        onChange={htmlElemtEvent => {
                          setUpdateUser({
                            ...updateUser,
                            userType: htmlElemtEvent.target.value
                          });
                        }}
                        label="Tipo do Usuário"
                      >
                        {userTypeList?.map(type => (
                          <MenuItem key={type.name} value={type.value}>
                            <Chip
                              color="default"
                              size="medium"
                              label={type.name}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box paddingBottom={2} display="flex" style={{ gap: '10px' }}>
                    <TextField
                      variant="outlined"
                      label={t('i18n.userview.EMAIL')}
                      fullWidth
                      value={updateUser?.email || '-'}
                      size="small"
                      disabled={!editMode}
                      onChange={ev => {
                        setUpdateUser({
                          ...updateUser,
                          email: ev.target.value
                        });
                      }}
                    />

                    <InputMask
                      onKeyPress={e => validatePhoneInput(e)}
                      variant="outlined"
                      defaultValue={updateUser?.phone_number || '-'}
                      mask="(99) 99999-9999"
                      size="small"
                      fullWidth
                      disabled={!editMode}
                      onChange={ev => {
                        setUpdateUser({
                          ...updateUser,
                          phone_number: ev.target.value
                        });
                      }}
                    >
                      {inputProps => (
                        <TextField
                          {...inputProps}
                          label={t('i18n.userview.PHONE')}
                        />
                      )}
                    </InputMask>

                    {/* <TextField
                      variant="outlined"
                      label={t('i18n.userview.PHONE')}
                      fullWidth
                      value={updateUser?.phone_number || '-'}
                      size="small"
                      disabled={!editMode}
                      onChange={ev => {
                        setUpdateUser({
                          ...updateUser,
                          phone_number: ev.target.value
                        });
                      }}
                    /> */}
                  </Box>
                  <Box
                    paddingBottom={2}
                    display="flex"
                    justifyContent="space-between"
                    style={{ fontFamily: 'Roboto' }}
                  >
                    <span>
                      {t('i18n.userview.CREATION_DATE')}:{' '}
                      {timezoneFormat(user.userCreateDate, 'DD/MM/YYYY HH:mm')}
                    </span>
                    <span>
                      Status: {updateUser?.enabled ? 'Ativo' : 'Bloqueado'}
                    </span>
                    <span>
                      {t('i18n.userview.LAST_UPDATE_DATE')}:{' '}
                      {timezoneFormat(
                        user.userLastModifiedDate,
                        'DD/MM/YYYY HH:mm'
                      )}
                    </span>
                  </Box>
                  <Box
                    paddingBottom={2}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Button
                      type="button"
                      onClick={() => history.goBack()}
                      variant="contained"
                    >
                      {t('i18n.userview.GO_BACK')}
                    </Button>
                    {editMode ? (
                      <Box style={{ display: 'flex', gap: 15 }}>
                        <Button
                          variant="contained"
                          color="default"
                          type="button"
                          onClick={() => {
                            setUpdateUser(user);
                            setEditMode(false);
                          }}
                        >
                          {t('i18n.usercontainer.CANCEL')}
                        </Button>
                        <Button
                          onClick={handleSave}
                          variant="contained"
                          color="primary"
                          size="small"
                        >
                          {t('i18n.simplewords.SAVE')}
                        </Button>
                      </Box>
                    ) : (
                      <Button
                        type="button"
                        onClick={() => setEditMode(true)}
                        variant="contained"
                        color="primary"
                        size="small"
                      >
                        {t('i18n.sales_ad_analysis.edit')}
                      </Button>
                    )}
                  </Box>
                </Box>
              </CardContent>
              <Divider />
            </Card>
            <br />
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default UserView;
