import { Button, Dialog } from '@material-ui/core';
import styled from 'styled-components';
import { COLORS } from '../../../../../styles/settings/colors.config';

export const CancelSection = styled.div`
  font-family: Roboto;
  display: flex;
  background-color: #f3f3f3;
  margin-bottom: 10px;
  padding: 10px 20px;
  justify-content: space-between;
  width: 100%;
`;

export const OrderSection = styled.div`
  font-family: Roboto;
  display: flex;
  padding: 10px 20px;
  background-color: white;
  height: 130px;
  justify-content: space-between;
`;

export const DetailsSection = styled.div`
  font-family: Roboto;
  display: flex;
  background-color: #f3f3f3;
  flex-direction: column;
  font-size: 12px;
  padding: 10px;
  margin-top: 10px;
`;

export const ActionsSection = styled.div`
  font-family: Roboto;
  display: flex;
  background-color: white;
  font-size: 12px;
  padding: 10px;
  justify-content: ${props => (props.buttons ? 'space-between' : 'flex-end')};
`;

export const OrdersHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width};
`;

export const Transport = styled.div`
  height: 100px;
`;

export const VerticalDivider = styled.div`
  border-left: 1px dashed #44546a;
  height: ${props => (props.height ? props.height : '0px')};
  margin: 2px 10px 10px 2px;
`;

export const OrdersHeaderTitle = styled.div`
  font-size: 12px;
  color: #666666;
  font-size: 13px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)};
`;

export const OrdersHeaderValue = styled.div`
  color: #3d85c6;
  font-size: 12px;
  font-weight: bold;
`;

export const CustomTypography = styled.div`
  color: ${COLORS.grayText};
  font-size: 12px;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
`;

export const OrdersHeaderSection = styled.div`
  margin-right: ${props => (props.margin ? '10px' : '0px')};
  min-width: 150px;
`;

export const CustomerSection = styled.div`
  margin-right: ${props => (props.margin ? '10px' : '0px')};
  width: 400px;
`;

export const DetailsContainer = styled.div`
  padding: 0px 5px 20px 5px;
  border: 1px solid #b7b7b7;
  border-radius: 10px;
  background-color: #ffffff;
  font-weight: bold;
`;

export const TransportSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => (props.margin ? props.margin : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  align-items: center;
  padding: ${props => (props.padding ? props.padding : '')};
`;

export const TransportSectionHeader = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  color: #666666;
  margin-bottom: ${props => (props.marginbottom ? props.marginbottom : '10px')};
`;

export const CancelHeader = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #ff0000;
`;

export const OrangeWarning = styled.span`
  color: #ed7d31;
  font-weight: bold;
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
`;

export const StyledButton = styled(Button)`
  text-transform: unset !important;
`;

export const BoldValue = styled.span`
  font-weight: bold;
  font-size: 13px;
`;

export const StyledDialog = styled(Dialog)`
  .acer-modal-header {
    align-items: center;
    background-color: #ff9f00;
    color: ${COLORS.whiteText};
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    font-size: 14px;
  }

  .text {
    padding: 5px 10px;
    font-size: 14px;
  }

  .boldText {
    font-weight: bold;
  }

  .redText {
    color: red;
  }

  .orangeText {
    color: #ff9f00;
  }

  .blueText {
    color: #3d85c6;
  }

  .greyText {
    color: #666666;
  }
`;
