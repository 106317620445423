import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  TextField
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AcerModal from '../../components/AcerModal';
import { StyledSpinner } from '../../styles/components/SpinnerDefault';
import Page from '../../components/Page';
import API from '../../services/api';
import {
  CustomDataTableWrapper,
  CustomDataTable,
  CustomTableButton
} from '../../styles/components/CustomDataTable';
import CustomFooter from '../../styles/components/CustomFooter';
import HeaderTitle from '../../components/HeaderTitle';

const TagsListView = () => {
  const { t } = useTranslation();
  const columns = [
    {
      name: 'tag_name',
      label: t('i18n.groupcontainer.NAME'),
      options: {
        filter: false,
        sort: false
      }
    }
  ];
  const { enqueueSnackbar } = useSnackbar();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const [itemsToDelete, setItemsToDelete] = useState([]);
  const [newLabelTag, setNewLabelTag] = useState();
  const [addTicketOpen, setAddTicketOpen] = useState(false);
  const url = `/auth/lambda/ticket-tag`;

  const refreshTableContent = () => {
    setLoad(false);
    API.get(url)
      .then(response => {
        setLoad(true);
        setData([...response.data]);
      })
      .catch(() => {
        setLoad(true);
      });
  };

  useEffect(() => {
    refreshTableContent();
  }, []);

  const options = {
    download: false,
    print: false,
    filter: false,
    search: true,
    viewColumns: false,
    customToolbar: () => {
      return (
        <>
          <IconButton onClick={() => refreshTableContent()}>
            <RefreshIcon />
          </IconButton>
          <IconButton disabled color="secondary">
            |
          </IconButton>
          <CustomTableButton
            variant="text"
            color="primary"
            size="small"
            onClick={() => {
              setAddTicketOpen(true);
            }}
            startIcon={<AddIcon />}
          >
            {t('i18n.tags.ADD_TAG')}
          </CustomTableButton>
        </>
      );
    },
    onRowsDelete: () => {
      API.post(`${url}/delete`, itemsToDelete)
        .then(() => {
          enqueueSnackbar(t('i18n.simplephrases.REMOVED_SUCCESS'), {
            variant: 'success'
          });
        })
        .catch(() => {
          enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
            variant: 'error'
          });
        });
    },
    onTableChange: (action, tableState) => {
      if (action === 'rowSelectionChange') {
        const temp = [];
        tableState.selectedRows.data.forEach(item => temp.push(item.index));
        const listToDelete = temp.map(item => {
          // eslint-disable-next-line no-underscore-dangle
          return data[item]?._id?.$oid;
        });
        setItemsToDelete(listToDelete);
      }
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: load
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: column =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };

  return (
    <Page title="Tags">
      <Container maxWidth={false}>
        <Box mt={3} style={{ alignItems: 'center' }}>
          <CustomDataTableWrapper style={{ opacity: !load ? '0.3' : '1' }}>
            <CustomDataTable
              title={<HeaderTitle title={t('i18n.dashboardlayout.TAGS')} />}
              data={data}
              columns={columns}
              selectableRowsOnClick
              options={options}
              className="table-clickable"
            />
          </CustomDataTableWrapper>
          <StyledSpinner style={{ display: !load ? 'block' : 'none' }}>
            <CircularProgress />
          </StyledSpinner>
        </Box>
      </Container>
      <AcerModal
        hasAction
        fullWidth
        maxWidth="md"
        onClose={() => {
          setAddTicketOpen(false);
        }}
        confirmActionLabel={t('i18n.simplewords.CONFIRM')}
        confirmActionFunc={() => {
          if (!newLabelTag) {
            return;
          }
          API.post(`${url}`, {
            // eslint-disable-next-line camelcase
            tag_name: newLabelTag
          })
            .then(() => {
              enqueueSnackbar(t('i18n.simplephrases.ADD_SUCCESS'), {
                variant: 'success'
              });
              setAddTicketOpen(false);
              refreshTableContent();
            })
            .catch(() => {
              enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
                variant: 'error'
              });
            });
        }}
        cancelActionLabel={t('i18n.simplewords.CANCEL')}
        open={addTicketOpen}
        modalTitle={t('Adicionar TAG')}
      >
        <TextField
          fullWidth
          variant="outlined"
          id="trackingCodeEdit"
          label={t('Nome da Tag')}
          value={newLabelTag || ''}
          onChange={evt => {
            setNewLabelTag(evt.target.value);
          }}
        />
      </AcerModal>
    </Page>
  );
};

export default TagsListView;
