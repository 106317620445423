import React from 'react';
import { Pie } from '@ant-design/plots';

const DonutChart = ({
  data,
  colors,
  totalValue,
  innerRadius,
  titleData,
  titleSubContent,
  radius,
  height
}) => {
  const config = {
    appendPadding: 100,
    data,
    angleField: 'value',
    colorField: 'type',
    color: colors,
    autoFit: true,
    radius: radius || 1,
    innerRadius: innerRadius || 0.6,
    legend: false,
    label: {
      type: 'inner',
      content: ''
    },
    interactions: [
      {
        type: 'element-selected'
      },
      {
        type: 'element-active'
      }
    ],
    statistic: {
      title: titleData || false,
      content: titleSubContent || {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontFamily: 'Roboto',
        },
        content: totalValue
      }
    }
  };
  return (
    <div
      style={{
        width: '100%',
        overflow: 'hidden',
        height: height || '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ width: '710px', position: 'absolute' }}>
        <Pie {...config} />
      </div>
    </div>
  );
};

export default DonutChart;
