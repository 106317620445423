import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import config from '../../../config';
import { COLORS } from '../../../styles/settings/colors.config';
import userService from '../../../services/user.service';
import { StyledSpinner } from '../../../styles/components/SpinnerDefault';
import Page from '../../../components/Page';
import API from '../../../services/api';
import {
  CustomDataTableWrapper,
  CustomDataTable,
  CustomBreadCrumbs
} from '../../../styles/components/CustomDataTable';
import CustomFooter from '../../../styles/components/CustomFooter';
import rolesList from './rolesList';
import formHelper from '../../../utils/formHelper';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{ borderTop: '1px solid rgb(204 204 204 / 32%)' }}>
          {children}
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  tabsHeader: {
    background: '#f4f6f8'
  },
  breadcrumbs: {
    marginTop: '15px',
    marginLeft: '24px'
  }
}));

const GroupContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const groupName = useParams().groupname;
  const companyFromToken = userService.getCompanyName();
  const [groupNameInputValue, setGroupNameInputValue] = useState('');
  const [rolesData, setRolesData] = useState([]);
  const [rolesInGroup, setRolesInGroup] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [usersInGroup, setUsersInGroup] = useState([]);
  const [usersOrderedByCheck, setUsersOrderedByCheck] = useState([]);
  const [defaultSort, setDefaultSort] = useState(true);
  const groups = history.location?.state?.groups;
  const [loading, setLoading] = useState(true);
  const isCreateCase = () => groupName === 'create';

  const [tabSelectedValue, setTabSelectedValue] = useState(
    isCreateCase() ? 'groupName' : 'users'
  );

  const onUserCheckboxChange = value => {
    if (usersInGroup.includes(value)) {
      const index = usersInGroup.indexOf(value);
      const newUsersInGroup = [...usersInGroup];
      newUsersInGroup.splice(index, 1);
      setUsersInGroup(newUsersInGroup);
    } else {
      const newUsersInGroup = [...usersInGroup, value];
      setUsersInGroup(newUsersInGroup);
    }
  };

  const formatRoles = list => {
    const newList = [];
    list.forEach(valor => {
      newList.push({
        name: valor,
        description:
          rolesList()?.filter(item => item.role === valor)[0]?.description ||
          valor,
        menu:
          rolesList()?.filter(item => item.role === valor)[0]?.menu ||
          'Em desenvolvimento',
        order: rolesList()?.filter(item => item.role === valor)[0]?.order || 99
      });
    });
    const excludeHiddenElements = newList.filter(item => item.menu !== 'hide');
    return excludeHiddenElements.sort(formHelper.compareOrder);
  };

  const onRoleCheckboxChange = value => {
    if (rolesInGroup.includes(value)) {
      const index = rolesInGroup.indexOf(value);
      const newRolesInGroup = [...rolesInGroup];
      newRolesInGroup.splice(index, 1);
      setRolesInGroup(newRolesInGroup);
    } else {
      const newRolesInGroup = [...rolesInGroup, value];
      setRolesInGroup(newRolesInGroup);
    }
  };

  const checkIfUserIsChecked = value => {
    if (usersInGroup.filter(el => el === value).length > 0) {
      return true;
    }
    return false;
  };

  const checkIfRoleIsChecked = value => {
    if (rolesInGroup.filter(el => el === value).length > 0) {
      return true;
    }
    return false;
  };

  const userColumns = [
    {
      name: 'name',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: value => {
          return (
            <Checkbox
              checked={checkIfUserIsChecked(value)}
              onClick={() => {
                onUserCheckboxChange(value);
              }}
              color="primary"
            />
          );
        }
      }
    },
    {
      name: 'name',
      label: i18n.t('i18n.groupcontainer.NAME')
    },
    {
      name: 'email',
      label: i18n.t('i18n.groupcontainer.EMAIL')
    },
    {
      name: 'enabled',
      label: i18n.t('i18n.groupcontainer.ACTIVE'),
      options: {
        customBodyRender: value => {
          if (value) {
            return i18n.t('i18n.groupcontainer.YES');
          }
          return i18n.t('i18n.groupcontainer.NO');
        }
      }
    }
  ];

  const rolesColumns = [
    {
      name: 'name',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: value => {
          return (
            <Checkbox
              checked={checkIfRoleIsChecked(value)}
              onClick={() => {
                onRoleCheckboxChange(value);
              }}
              color="primary"
            />
          );
        }
      }
    },
    {
      name: 'description',
      label: i18n.t('i18n.groupcontainer.ROLE_DESCRIPTION')
    },
    {
      name: 'menu',
      label: i18n.t('i18n.groupcontainer.ROLE_MENU')
    }
  ];

  const loadUsersTableContent = () => {
    setLoading(true);
    API.get(`/auth/ms-users/users`).then(response => {
      setUsersData(response.data.sort(formHelper.compareName));
      setLoading(false);
    });
  };

  const loadRolesTableContent = () => {
    API.get(`/auth/ms-users/associations/system-roles`).then(response => {
      setRolesData(formatRoles(response.data));
    });
  };

  const refreshTables = () => {
    loadUsersTableContent();
    loadRolesTableContent();
  };

  const options = {
    filter: false,
    viewColumns: false,
    pagination: true,
    download: false,
    print: false,
    elevation: 0,
    selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    search: false,
    onTableChange: action => {
      if (action === 'sort') {
        setDefaultSort(false);
      }
    },
    customToolbar: () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          {defaultSort && (
            <Typography
              variant="caption"
              style={{ color: `${COLORS.btnWarningColor}` }}
            >
              * Ordenado por usuários associados ao grupo e em alfabético por
              nome
            </Typography>
          )}
          <Tooltip title={t('i18n.groupcontainer.REFRESH')}>
            <IconButton onClick={() => refreshTables()} aria-label="delete">
              <RefreshIcon color="primary" />
            </IconButton>
          </Tooltip>
        </div>
      );
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: !loading
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: column =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabSelectedValue(newValue);
  };

  const groupNameForPayload = () => {
    if (companyFromToken === config.cognito.ownerCompany) {
      return isCreateCase() ? groupNameInputValue : groupName;
    }
    return isCreateCase()
      ? `${companyFromToken}:${groupNameInputValue}`
      : groupName;
  };

  const submitGroup = () => {
    if (isCreateCase() && !groupNameInputValue.trim()) {
      enqueueSnackbar(t('i18n.groupcontainer.REQUIRED_FIELDS_ERROR'), {
        variant: 'warning'
      });
      return;
    }

    if (
      isCreateCase() &&
      groups.some(group => group.name === groupNameForPayload())
    ) {
      enqueueSnackbar(t('i18n.groupcontainer.GROUP_ALREADY_EXIST'), {
        variant: 'error'
      });
      return;
    }

    const defaultRoles = rolesList()
      ?.filter(item => {
        return item.menu === 'hide' && item.role;
      })
      .map(item => {
        return item.role;
      });

    const postData = {
      users: usersInGroup,
      groupName: groupNameForPayload(),
      roles: isCreateCase() ? [...rolesInGroup, ...defaultRoles] : rolesInGroup
    };

    API.post(`/auth/ms-users/associations`, postData)
      .then(() => {
        if (isCreateCase()) {
          history.push('/groups');
        }
      })
      .catch(() => {});
  };

  const loadExistentGroupData = async () => {
    if (isCreateCase()) {
      return;
    }
    const response = await API.get(`/auth/ms-users/associations/${groupName}`);
    const { data } = response;
    setUsersInGroup(data.users);
    setRolesInGroup(data.roles);
  };

  useEffect(() => {
    loadExistentGroupData();
    refreshTables();
  }, []);

  useEffect(() => {
    const usersChecked = [];
    const usersUnchecked = [];
    if (usersData && usersInGroup) {
      usersData.forEach(el => {
        if (usersInGroup.some(user => user === el.name)) {
          usersChecked.push(el);
        } else {
          usersUnchecked.push(el);
        }
      });
    }
    const orderedUsersData = [...usersChecked, ...usersUnchecked];
    setUsersOrderedByCheck(orderedUsersData);
  }, [usersData]);

  return (
    <Page className={classes.root} title="Grupo">
      <Container maxWidth={false}>
        <CustomBreadCrumbs
          className={classes.breadcrumbs}
          gutterBottom
          aria-label="breadcrumb"
        >
          <Typography variant="h2">
            <Link
              color="inherit"
              style={{ textDecoration: 'none' }}
              to="/groups"
            >
              {t('i18n.grouplistview.GROUPS')}
            </Link>
          </Typography>
          {isCreateCase() ? (
            <Typography variant="h2" color="textPrimary">
              {t('i18n.groupcontainer.CREATE_GROUP')}
            </Typography>
          ) : (
            <Typography variant="h2" color="textPrimary">
              {groupName}
            </Typography>
          )}
        </CustomBreadCrumbs>
        <Box mt={2}>
          <div style={{ opacity: loading ? '0.3' : '1' }}>
            <Card>
              <Tabs
                className={classes.tabsHeader}
                value={tabSelectedValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                aria-label="simple tabs example"
              >
                {isCreateCase() && (
                  <Tab
                    value="groupName"
                    label={t('i18n.groupcontainer.GROUP_NAME')}
                  />
                )}
                <Tab value="users" label={t('i18n.groupcontainer.USERS')} />
                <Tab value="roles" label={t('i18n.groupcontainer.ROLES')} />
              </Tabs>
              <TabPanel value={tabSelectedValue} index="groupName">
                <Card>
                  <CardContent>
                    <TextField
                      label={t('i18n.groupcontainer.GROUP_NAME')}
                      variant="outlined"
                      fullWidth
                      value={groupNameInputValue}
                      onChange={ev => {
                        setGroupNameInputValue(ev.target.value.trim());
                      }}
                    />
                    <Box mt={2}>
                      <Typography variant="caption">
                        {t('i18n.groupcontainer.TAB_HELPER')}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </TabPanel>
              <TabPanel value={tabSelectedValue} index="users">
                <CustomDataTableWrapper>
                  <CustomDataTable
                    data={usersOrderedByCheck}
                    columns={userColumns}
                    options={options}
                    className="table-clickable"
                  />
                </CustomDataTableWrapper>
              </TabPanel>
              <TabPanel value={tabSelectedValue} index="roles">
                <CustomDataTableWrapper>
                  <CustomDataTable
                    data={rolesData}
                    columns={rolesColumns}
                    options={options}
                    className="table-clickable"
                  />
                </CustomDataTableWrapper>
              </TabPanel>
              <Divider />
              <CardContent
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Button
                  variant="contained"
                  color="default"
                  type="button"
                  onClick={() => history.push('/groups')}
                >
                  {t('i18n.usercontainer.CANCEL')}
                </Button>
                <Button
                  onClick={() => {
                    submitGroup();
                  }}
                  variant="contained"
                  color="primary"
                >
                  {t('i18n.simplewords.SAVE')}
                </Button>
              </CardContent>
            </Card>
            <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
              <CircularProgress />
            </StyledSpinner>
          </div>
        </Box>
      </Container>
    </Page>
  );
};

export default GroupContainer;
