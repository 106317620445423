/*eslint-disable*/
import React from 'react';
import styled from 'styled-components';
import { Typography, CardContent, TextField } from '@material-ui/core';
import { COLORS } from '../../../styles/settings/colors.config';

const CustomTypograph = styled(Typography)`
  color: ${props => (props.colortext ? props.colortext : COLORS.grayText)};
  margin-top: ${props => (props.margintop ? props.margintop : '0px')};
`;

function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      className="customTabs"
      style={{ marginTop: '20px' }}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

export { CustomTypograph, CustomTabPanel };
