import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SelectGroupComponent from '../../../../components/Select.group.component';
// import reasonList from '../../../../utils/reasonList';
// import API from '../../../../services/api';

const FilterReasonLevel = ({ filterForm, handleSetFilterReasonLevel }) => {
  const { t } = useTranslation();
  // const reasons = reasonList();

  const reasonsLevel = [
    {
      itens: [{name: 'N1'}],
    },
    {
      itens: [{name: 'N2'}],
    },
    {
      itens: [{name: 'N3'}],
    },
  ]
  return (
    <Box mb={2}>
      <Grid container>
        <Grid item xs={7}>
          <SelectGroupComponent
            form={filterForm}
            setForm={handleSetFilterReasonLevel}
            label={t('i18n.ticketcontainer.REASON_LEVEL_LABEL')}
            name="reasonGroup"
            options={reasonsLevel}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterReasonLevel;
