import React from 'react';
import { Check } from '@material-ui/icons';
import { StyledDotButton } from '../styles';

export default function DotButton({ showButton, buttonActions, buttonLabel }) {
  return (
    <>
      {showButton && (
        <StyledDotButton
          size="small"
          color="primary"
          variant="contained"
          onClick={buttonActions}
          startIcon={<Check />}
        >
          {buttonLabel}
        </StyledDotButton>
      )}
    </>
  );
}
