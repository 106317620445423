/*eslint-disable */
import React from 'react';
import { Card, CardContent, Divider } from '@material-ui/core';
// import moment from 'moment';
// import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  CustomTypograph,
  CardDataWrapper,
  // CustomCardContent,
  CardDataDivider,
  MainCardData,
  SecondaryCardData,
  InConstruction
  // LineCardWrapper,
  // LineCardData
} from '../styles';

const CustomCardContent = styled(CardContent)`
  padding: 15px 120px;
  @media (max-width: 1440px) {
    padding: 15px 80px;
  }
`;

const AverangeTimeCard = ({
  title,
  firstLabel,
  secondaryLabel,
  isTeamView,
  extraData,
  showInConstruction
}) => {
  // const { t } = useTranslation();

  return (
    <div style={{ position: 'relative' }}>
      <Card style={{ width: '100%', marginBottom: '20px' }}>
        <CardContent>
          <CustomTypograph variant="h5">{title}</CustomTypograph>
        </CardContent>
        <Divider />

        {extraData?.map(
          item =>
            item.show && (
              <div key={item.label}>
                <CardContent>
                  <CustomTypograph variant="h6">{item.label}</CustomTypograph>
                </CardContent>
                <CustomCardContent>
                  <CardDataWrapper jusfityContent={!isTeamView}>
                    {!isTeamView && (
                      <>
                        <MainCardData>
                          <p className="label">{firstLabel}</p>
                          <p className="main-data">{item.attendantData}</p>
                        </MainCardData>
                        <CardDataDivider />
                        <SecondaryCardData>
                          <p className="label">{secondaryLabel}</p>
                          <p className="main-data">{item.equipData}</p>
                        </SecondaryCardData>
                      </>
                    )}

                    {isTeamView && (
                      <MainCardData>
                        <p className="label">{secondaryLabel}</p>
                        <p className="main-data">{item.equipData}</p>
                      </MainCardData>
                    )}
                  </CardDataWrapper>
                </CustomCardContent>
                {item.divider && <Divider />}
              </div>
            )
        )}
      </Card>
      {showInConstruction && <InConstruction />}
    </div>
  );
};

export default AverangeTimeCard;
