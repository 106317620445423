import {
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../styles/settings/colors.config';

const AgentMessageCard = ({
  message,
  wrap = false,
  bg = false,
  name = null,
  align = null
}) => {
  const { t } = useTranslation();
  return (
    <Card
      style={{
        backgroundColor: bg ? COLORS.lightGreen : '',
        display: 'flex',
        width: align ? '80%' : '100%',
        justifyContent: align || 'flex-start'
      }}
    >
      <CardContent>
        {wrap ? (
          <Typography>
            {message?.text?.split('\n').map(item => {
              return (
                <span key={Math.random()}>
                  {/* eslint-disable-next-line */}
                  <div dangerouslySetInnerHTML={{ __html: item }} />
                  <br />
                </span>
              );
            })}
          </Typography>
        ) : (
          <Typography variant="h5" component="h2">
            {message.text}
          </Typography>
        )}
        <Grid container>
          <Grid item lg={8}>
            <Typography
              color="textSecondary"
              align="left"
              variant="caption"
              component="h2"
            >
              {moment(message.date).format('DD/MM/YYYY HH:mm')}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography
              color="textSecondary"
              align="right"
              variant="caption"
              component="h2"
            >
              {name || t('i18n.chatview.ATTENDANT_MESSAGE')}
            </Typography>
          </Grid>
        </Grid>
        {message.status != null ? <LinearProgress /> : <></>}
      </CardContent>
    </Card>
  );
};

export default AgentMessageCard;
