import React from 'react';

import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox
} from '@material-ui/core';

import { inputStyles } from './inputStyle';

/**
 * Checkbox group to forms
 * @param {String} label - String - Name of the checkbox group
 * @param {Object} form - Object - State of form that recieve the checkbox values
 * @param {Function} setForm - Function - Open controller
 * @param {Array} options - Array - List of the checkbox
 */

const CheckboxComponent = ({
  label,
  form,
  setForm,
  options,
  marginLeft = true
}) => {
  const classes = inputStyles();

  return (
    <FormControl
      component="fieldset"
      style={{
        display: 'flex',
        gap: '22px',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <FormLabel style={{ color: 'inherit' }}>{label}</FormLabel>
      <FormGroup aria-label="position" row>
        <Box
          display="flex"
          flexDirection="row"
          style={{ marginInlineStart: marginLeft && '154px' }}
        >
          {options?.map(item => (
            <FormControlLabel
              key={item.name}
              control={
                <Checkbox
                  className={classes.checkboxFilter}
                  checked={form[item?.name] || false}
                  onChange={e => {
                    setForm({
                      ...form,
                      ...{ [item?.name]: e.target.checked }
                    });
                  }}
                  name={item.name}
                  color="primary"
                />
              }
              label={item.label}
            />
          ))}
        </Box>
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxComponent;
