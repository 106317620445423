import React from 'react';
import { CardContent, Grid } from '@material-ui/core';
import AgentMessageCard from '../baloons/AgentMessageCard';
import ClientMessageCard from '../baloons/ClientMessageCard';
import { StyledPaper } from '../styles';
import MessageActions from '../components/MessageActions.component';

const MagaluMessages = ({
  privateMessagesMagalu,
  privateMessageMagaluLoading,
  privateMessageAnswerMagalu,
  setPrivateMessageAnswerMagalu,
  sendPrivateMessageMagalu,
  reason,
  isReasonFullfield
}) => {
  const checkToDisableSend = () => {
    if (!isReasonFullfield(reason)) return true;
    if (privateMessageMagaluLoading) return true;
    return false;
  };

  return (
    <>
      <StyledPaper className="message-paper">
        <CardContent>
          {privateMessagesMagalu.map(m => {
            let div;
            if (m.sender === 'marketplace') {
              div = (
                <Grid container spacing={1} style={{ marginBottom: '12px' }}>
                  <Grid item md={6} xs={12}>
                    <ClientMessageCard message={m} magaluMediation />
                  </Grid>
                </Grid>
              );
            } else {
              div = (
                <Grid container spacing={1} style={{ marginBottom: '12px' }}>
                  <Grid item md={5} xs={12} />
                  <Grid item md={7} xs={12}>
                    <AgentMessageCard message={m} />
                  </Grid>
                </Grid>
              );
            }
            return (
              <div key={Math.random()}>
                {div}
                <br />
              </div>
            );
          })}
          {privateMessageMagaluLoading && (
            <div key="loadingNewMlmessage">
              <AgentMessageCard
                message={{
                  message: privateMessageAnswerMagalu,
                  status: 'loading'
                }}
              />
            </div>
          )}
        </CardContent>
      </StyledPaper>

      <MessageActions
        typeMessage="magaluMediationMessage"
        loading={privateMessageMagaluLoading}
        resposta={privateMessageAnswerMagalu}
        setResposta={setPrivateMessageAnswerMagalu}
        disableSend={checkToDisableSend()}
        sendResponse={sendPrivateMessageMagalu}
        hideMacro
      />
    </>
  );
};

export default MagaluMessages;
