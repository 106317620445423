import i18n from '../i18n';

function marketplaceBundleLabel(label) {
  switch (String(label).toLowerCase()) {
    case 'claim':
      return i18n.t('i18n.simplephrases.CLAIM');
    case 'dispute':
      return i18n.t('i18n.simplephrases.DISPUTE');
    case 'recontact ':
      return i18n.t('i18n.simplephrases.RECONTACT');
    case 'none':
      return i18n.t('i18n.simplephrases.NONE');
    case 'opened':
      return i18n.t('i18n.simplephrases.OPENED');
    case 'closed':
      return i18n.t('i18n.simplephrases.CLOSED');
    case 'mediations':
      return i18n.t('i18n.simplephrases.MEDIATIONS');
    case 'cancel_purchase':
      return i18n.t('i18n.simplephrases.CANCEL_PURCHASE');
    case 'returns':
      return i18n.t('i18n.simplephrases.RETURNS');
    case 'cancel_sale':
      return i18n.t('i18n.simplephrases.CANCEL_SALE');

    default:
      break;
  }
}

export default marketplaceBundleLabel;
