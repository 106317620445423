import { Box, Divider, Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import React from 'react';
import { CardTitle, CustomHintText } from '../../../styles';
import { CustomTypograph } from '../../custom.components';

const CardCustomTitle = ({ title, descriptionHint, hr, custompadding }) => {
  return (
    <>
      <CardTitle {...{ custompadding }}>
        <CustomTypograph variant="h5">{title}</CustomTypograph>
        <Box style={{ gap: '10px' }} display="flex" justifyContent="flex-end">
          {descriptionHint && (
            <Tooltip
              PopperProps={{
                disablePortal: true,
                popperOptions: {
                  positionFixed: true,
                  modifiers: {
                    preventOverflow: {
                      enabled: true,
                      boundariesElement: 'window'
                    }
                  }
                }
              }}
              placement="top"
              arrow
              title={<CustomHintText>{descriptionHint}</CustomHintText>}
            >
              <InfoOutlined fontSize="medium" color="primary" />
            </Tooltip>
          )}
        </Box>
      </CardTitle>
      {hr && <Divider />}
    </>
  );
};

export default CardCustomTitle;
