import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTextField } from '../styles';

const MessageInput = ({
  typeMessage,
  marketplace = '',
  loading,
  resposta,
  setResposta,
  fieldBlocked = false,
  i18nPlaceholder = 'i18n.chatview.TYPE_MESSAGE'
}) => {
  const { t } = useTranslation();
  const [respostaLocal, setRespostaLocal] = useState(resposta);

  /*
typeMessage options:      Nome da aba na tela:
  b2wHelpMessage          "Ajuda Americanas"
  normalMessage           "Mensagens"
  internalMessage         "Anotações internas"
  magaluMediationMessage  "Magalu"
  MLClaim                 "Reclamações ML"
  */

  const inputPropsRules = () => {
    if (marketplace === 'MERCADO_LIVRE' && typeMessage === 'normalMessage') {
      return { maxLength: '350' };
    }
    return { maxLength: '' };
  };

  const helperTextRules = answer => {
    if (marketplace === 'MERCADO_LIVRE' && typeMessage === 'normalMessage') {
      return `${answer?.length}/350`;
    }
    return '';
  };

  useEffect(() => {
    setRespostaLocal(resposta);
  }, [resposta]);

  return (
    <>
      <CustomTextField
        multiline
        className="message-textarea"
        minRows={8}
        disabled={loading}
        value={
          fieldBlocked
            ? 'Este protocolo está bloqueado para interações.'
            : respostaLocal
        }
        onChange={httmlElemtEvent => {
          setRespostaLocal(httmlElemtEvent.target.value);
        }}
        onBlur={() => {
          setResposta(respostaLocal);
          setRespostaLocal(resposta);
        }}
        size="small"
        fullWidth
        variant="outlined"
        inputProps={inputPropsRules()}
        helperText={helperTextRules(respostaLocal)}
        placeholder={t(`${i18nPlaceholder}`)}
      />
    </>
  );
};

export default MessageInput;
