/* eslint-disable */
/* eslint-disable no-underscore-dangle */
import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import API from '../../../../services/api';
import { COLORS } from '../../../../styles/settings/colors.config';
import userService from '../../../../services/user.service';
import { CustomChip } from '../../../../styles/components/Tags';

const StyledTagField = styled(TextField)`
  .MuiInput-underline:before {
    border-bottom: 0;
  }
  .MuiInput-underline:after {
    border-bottom: 0;
  }
  .MuiInput-underline:hover {
    border-bottom: 0;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0;
  }

  .MuiAutocomplete-tagSizeSmall {
    border-radius: 5px;
    background: ${COLORS.gray};
    border: 1px solid #ccc;
  }

  .MuiInputBase-input::placeholder {
    color: ${COLORS.greenText};
    opacity: 1;
  }
  
  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    width: auto;
  }
  .MuiAutocomplete-inputRoot[class*='MuiInput-root'][class*='MuiInput-marginDense']
    .MuiAutocomplete-input {
    cursor: pointer;
  }
`;

const StyledAutoComplete = styled(Autocomplete)`
  .MuiAutocomplete-endAdornment {
    display: none;
  };
  padding: 5px;
  background: ${(props) => props.disabled && '#f1f1f1'};

`;

const StyledAutoCompleteBox = styled(Box)`
  // width: 65vw;
`;

// let campaignIds = [];

const Campaign = ({
  clientData,
  postUrl,
  type,
  blockInput = false,
  getData = false,
  tagOptions
  // setSelectedTagOptions
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  // const [tagOptions, setTagOptions] = useState([]);
  const [selectedTagOptions, setSelectedTagOptions] = useState([]);
  const [addTagOptions, setAddTagOptions] = useState('');

  const userName = userService.getUsername();
  const isSameAttendant = userName === clientData.serviceAttendantLogin;

  const handleRemoveChip = chip => {

    const selectedTags = selectedTagOptions.filter(item => {
      return item._id.$oid !== chip._id.$oid;
    });

    setSelectedTagOptions(selectedTags);

    const post = {
      _id: type === "chats" ? clientData.hashConnection : clientData.id,
      type: type,
      campaign_ids: [{ campaign_id: chip._id.$oid }]
    }

    API.post(`/auth/lambda/campaing/remove-campaign`, post)
      .then((response) => {
        // setSelectedTagOptions(selectedTags);
        if (getData) {
          getData(false);
        }
        enqueueSnackbar('Campanha removida com sucesso', {
          variant: 'success'
        });
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
          variant: 'error'
        });
      });

    // TO DO POST selected tags
  };

  const getAlreadySelectedIds = () => {
    const alreadySelectedIds = selectedTagOptions.map(selectedItem => {
      return selectedItem?._id?.$oid;
    });
    return alreadySelectedIds;
  };

  const addTag = value => {
    const alreadySelectedIds = getAlreadySelectedIds();
    value.map(item => {
      const itemId = item?._id?.$oid;
      if (!alreadySelectedIds.includes(itemId)) {
        setSelectedTagOptions(value);
        // campaignIds.push({ campaign_id: itemId, campaign_name: item.campaign_name });
      }
      return item;
    });

    setAddTagOptions(true);

  };

  const handlePostCampaings = (list) => {
    let campaingIds = [];

    list.map((item) => {
      campaingIds.push({ campaign_id: item.campaign_id, campaign_name: item.campaign_name });
    });

    const post = {
      _id: type === "chats" ? clientData.hashConnection : clientData.id,
      type: type,
      campaign_ids: campaingIds
    }

    API.post(`/auth/lambda/campaing/add-campaign`, post)
      .then((response) => {
        if (getData) {
          getData(false);
        }
        enqueueSnackbar('Campanha adicionada com sucesso', {
          variant: 'success'
        });
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
          variant: 'error'
        });
      });
  }

  useEffect(() => {
    if (addTagOptions && selectedTagOptions.length > 0) {
      handlePostCampaings(selectedTagOptions);
    }
  }, [selectedTagOptions, addTagOptions])

  useEffect(() => {

    if (clientData?.campaigns?.length > 0) {
      const ticketcampaigns = clientData?.campaigns;
      const newTicketcampaigns = ticketcampaigns?.map((item) => {
        item._id = { $oid: item.campaign_id };
        item.tag_name = item.campaign_name;
        return item;
      }).filter((item) => {
        return item.active === true;
      });

      setSelectedTagOptions(newTicketcampaigns);
    } else {
      setSelectedTagOptions([]);
    }

  }, [clientData]);

  return (
    <StyledAutoCompleteBox mb={2}>
      <StyledAutoComplete
        fullWidth={false}
        multiple
        onChange={(event, value, reason) => {
          if (reason === 'select-option') {
            addTag(value);
          }
        }}
        disabled={blockInput}
        noOptionsText="-"
        disableClearable
        size="small"
        options={tagOptions}
        getOptionLabel={option => option.tag_name}
        defaultValue={[]}
        value={selectedTagOptions}
        renderTags={value =>
          value.map((option, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <CustomChip key={index}>
              <p>{option.tag_name}</p>
              <button type="button" onClick={() => handleRemoveChip(option)}>
                x
              </button>
            </CustomChip>
          ))
        }
        renderInput={params => (
          <StyledTagField
            {...params}
            variant="standard"
            placeholder="+ Campanha"
          />
        )}
      />
    </StyledAutoCompleteBox>
  );
};

export default Campaign;
