import React, { useState, useEffect } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  ListItemAvatar,
  Typography,
  Tooltip,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

import { listOptions, setList } from './filter.config';

export const FilterDrawerAccordion = ({
  open,
  filter,
  setFilter,
  fieldName
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const [expanded, setExpanded] = React.useState('panel1');
  const [itemsSelected, setItemsSelected] = useState(filter.status);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    setFilter({ ...filter, [fieldName]: itemsSelected });
  }, [itemsSelected]);

  return (
    <Accordion
      square
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Tooltip
          title={
            !open
              ? `${t('i18n.simplephares.FILTER')} ${t(
                  'i18n.ticketslistview.STATUS'
                )}`
              : ''
          }
        >
          <Typography className={`${open ? 'menu-opened' : 'menu-closed'}`}>
            Atalho Status
          </Typography>
        </Tooltip>
      </AccordionSummary>
      <AccordionDetails>
        <List className="marketplace-list">
          {listOptions?.map(value => {
            const selectedColor =
              itemsSelected?.length > 0 && itemsSelected?.includes(value.value);
            const labelId = `checkbox-list-secondary-label-${value}`;
            return (
              <ListItem
                onClick={() => {
                  setList({
                    val: value.value,
                    filter: itemsSelected,
                    setFilter: setItemsSelected
                  });
                }}
                key={value.icon}
                button
              >
                <ListItemAvatar>
                  <Tooltip title={!open ? value.text : ''}>
                    <Avatar
                      style={{
                        backgroundColor:
                          selectedColor && theme.palette.primary.main,
                        width: '27px',
                        height: '27px',
                        fontSize: '12px'
                      }}
                    >
                      {value.icon}
                    </Avatar>
                  </Tooltip>
                </ListItemAvatar>
                <ListItemText id={labelId} primary={value.text} />
              </ListItem>
            );
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
