import { Button, makeStyles } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../../../services/api';

const useStyles = makeStyles(theme => ({
  button: {
    marginBottom: theme.spacing(1)
  }
}));

withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const MercadoLivreAnswerBar = ({ ticketData }) => {
  const { enqueueSnackbar } = useSnackbar();
  useStyles();
  const { t } = useTranslation();
  const claimId =
    ticketData?.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity?.claim_id;
  const stageClaim =
    ticketData?.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity?.stage ===
    'claim';

  const checkTicketClaim = () => {
    if (
      ticketData?.ticketStatus === 'NEW' &&
      ticketData?.ticketType === 'AFTER_SALE' &&
      ticketData?.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity?.type ===
        'returns'
    ) {
      enqueueSnackbar(t('i18n.ticketcontainer.TICKET_ML_CLAIM_RETURNS'), {
        variant: 'warning'
      });
    }
  };
  useEffect(() => {
    if (ticketData) {
      checkTicketClaim();
    }
  }, []);

  const requestModerationML = async () => {
    try {
      await API.post(
        `/auth/ms-ticket/v1/tickets/mercadolivre/request-mediation`,
        { claimId }
      );
    } catch (error) {
      enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
        variant: 'error'
      });
    }
  };

  return (
    stageClaim && (
      <Button onClick={() => requestModerationML()} color="primary">
        <LiveHelpIcon fontSize="small" className="header-btn--icon" />
        {t('i18n.reclame_aqui.phrases.request_mediation')}
      </Button>
    )
  );
};

export default MercadoLivreAnswerBar;
