export const options = [
  {
    value: 'saudacao_pre_venda_off',
    label: 'i18n.quickanswer.TYPE_OFFLINE_DEFAULT',
    checked: 'saudacao_pre_venda_off',
    disabled: false
  },
  {
    value: 'chat',
    label: 'i18n.quickanswer.TYPE_ONLINE.B2C',
    checked: 'chat',
    disabled: 'saudacao_pre_venda_off'
  },
  {
    value: 'chatB2b',
    label: 'i18n.quickanswer.TYPE_ONLINE.B2B',
    checked: 'chatB2b',
    disabled: 'saudacao_pre_venda_off'
  },
  {
    value: 'preMl',
    label: 'i18n.quickanswer.TYPE_OFFLINE.ML',
    checked: 'preMl',
    disabled: 'saudacao_pre_venda_off'
  },
  {
    value: 'pre',
    label: 'i18n.quickanswer.TYPE_OFFLINE.ACER',
    checked: 'pre',
    disabled: 'saudacao_pre_venda_off'
  }
];

export const getLabel = v => {
  const finded = options.find(item => {
    return item.value === v;
  });
  return finded?.label;
};

export const hasItem = v => {
  const find = options.find(item => {
    return item.value === v;
  });
  if (find) {
    return true;
  }
  return false;
};
