/* eslint-disable react/no-array-index-key */
import { CardContent, Grid, Typography, Button } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import MessageWrapper from './MessageWrapper';
import Bot from '../../../../assets/images/probot.png';

import { RobotCardStyled } from '../styles';

const RobotMessageCard = ({
  answer,
  handleAcceptAnswer,
  handleEditBotMessage,
  handleDeleteBotMessage,
  index,
  showBtns,
  loadingMacro
}) => {
  const robotName = 'Mr. Robô';
  return (
    <RobotCardStyled>
      <CardContent className="robot-bg">
        <div className="message-body">
          <Typography
            color="textSecondary"
            align="left"
            variant="caption"
            className="message-body--header"
            component="span"
          >
            Sugestão de resposta do {robotName} -{' '}
            <strong>Pendente de aceite</strong>
          </Typography>
          <MessageWrapper message={answer.answer} />
          <Grid container className="message-footer">
            <Grid item lg={8}>
              <Typography
                color="textSecondary"
                align="left"
                variant="caption"
                component="h2"
              >
                Acurácia: {Number(answer.accuracy).toFixed(2) * 100}%
              </Typography>
            </Grid>
            <Grid item lg={4}>
              <Typography
                color="textSecondary"
                align="right"
                variant="caption"
                component="h2"
              >
                {robotName}
              </Typography>
            </Grid>
          </Grid>
        </div>
        {showBtns && (
          <div className="message-actions">
            <img src={Bot} alt="" className="message-actions--robot" />
            <Button
              onClick={() => handleAcceptAnswer(answer.answer)}
              className={
                loadingMacro ? 'button action-nocolor' : 'button action-color'
              }
              disabled={loadingMacro}
            >
              <DoneIcon className="" />
            </Button>
            <Button
              onClick={() => handleEditBotMessage(answer.answer)}
              className={
                loadingMacro ? 'button action-nocolor' : 'button action-color'
              }
              disabled={loadingMacro}
            >
              <EditIcon />
            </Button>
            <Button
              onClick={() => handleDeleteBotMessage(answer, index)}
              className="button action-refuse"
            >
              <BlockIcon />
            </Button>
          </div>
        )}
      </CardContent>
    </RobotCardStyled>
  );
};

export default RobotMessageCard;
