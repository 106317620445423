import * as Sentry from '@sentry/react';

if (
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_CURRENT_ENV
) {
  Sentry.init({
    environment: process.env.REACT_APP_CURRENT_ENV,
    dsn:
      'https://1b7fa4febca05521ce403572c565aed1@o4504084487340033.ingest.sentry.io/4505681598218240'
  });
}
