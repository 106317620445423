import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Grid } from '@material-ui/core';
import indexHook from '../indexHook';
import InputDate from '../components/date.component';
import { filterNameInStorage, defaultFilter } from './config';

const FilterForm = ({ filter, setFilter, setSubmit, setOpen }) => {
  const { t } = indexHook();

  const storageFilter = JSON.parse(sessionStorage.getItem(filterNameInStorage));

  const [localFilterData, setLocalFilterData] = useState({});
  const [blockButton, setBlockButton] = useState(false);

  const handleSubmitFilter = (e, filterData) => {
    e.preventDefault();
    filterData.marketplace = filter.marketplace;

    setFilter({
      ...filter,
      ...filterData
    });

    setSubmit(true);
    setOpen(false);
  };

  const clearFilter = () => {
    setFilter(defaultFilter);
    setLocalFilterData(defaultFilter);
    sessionStorage.setItem(filterNameInStorage, JSON.stringify(defaultFilter));
  };

  useEffect(() => {
    setLocalFilterData(storageFilter);
  }, []);

  return (
    <form onSubmit={e => handleSubmitFilter(e, localFilterData)}>
      <Grid container>
        <Grid item>
          <Box
            style={{ width: '500px', padding: '12px 24px 24px', border: '0' }}
          >
            <InputDate
              localFilterData={localFilterData}
              setLocalFilterData={setLocalFilterData}
              dashboardFilter={filter}
              setBlockButton={setBlockButton}
            />

            <Divider />
            <br />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="contained" onClick={() => clearFilter()}>
                {t('i18n.ticketslistview.CLEAR')}
              </Button>
              <Button
                disabled={blockButton}
                variant="contained"
                color="primary"
                type="submit"
              >
                {t('i18n.dashboard.APPLY_FILTER')}
              </Button>
            </div>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default FilterForm;
