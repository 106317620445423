import { getParamsInUrl } from '../../helpers/globalFunctions';
import { getFilterInStorage } from './services/presaleServices';

export const defaultFilterFactory = () => {
  const queryFilterData = getParamsInUrl();
  const storedFilterData = sessionStorage.getItem('preSalefilterData');
  const preSalefilterUseQueryParams =
    sessionStorage.getItem('preSalefilterUseQueryParams') ?? 'true';

  const defaultFilter = {
    ticketType: 'PRE_SALE',
    marketPlace: ['YOURVIEWS'],
    ticketStatus: 'NEW',
    orderBy: 'ASC',
    marketPlaceList: ['YOURVIEWS']
  };

  if (queryFilterData && preSalefilterUseQueryParams === 'true') {
    queryFilterData.marketPlace = queryFilterData.marketPlaceList;
    return queryFilterData;
  }

  if (
    !queryFilterData &&
    preSalefilterUseQueryParams === 'false' &&
    storedFilterData
  ) {
    return JSON.parse(storedFilterData);
  }

  return defaultFilter;
};

export const payloadFactory = filter => {
  const filterStorage = filter === false ? getFilterInStorage() : filter;

  const payload = {
    ...filterStorage,
    ...{
      marketPlace: '',
      ticketType: 'PRE_SALE',
      marketPlaceList: filterStorage?.marketPlace,
      ticketStatus: filterStorage?.ticketStatus
    }
  };

  return payload;
};
