import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import API from '../../../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    overflow: 'hidden',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(7)
  }
}));

const ChatTriggersHooks = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [deleteList, setDeleteList] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [defaultSchedule, setDefaultSchedule] = useState([]);
  const [otherEnabledSchedules, setOtherEnabledSchedules] = useState([]);
  const [disabledSchedules, setDisabledSchedules] = useState([]);
  const { business_type } = useParams();

  const handleGet = async () => {
    setLoading(true);
    try {
      const response = await API.get(
        `/auth/ms-chat/trigger?businessType=${business_type ? 'B2B' : 'B2C'}`
      );

      setDefaultSchedule(response.data.filter(item => item.isStandard));

      setOtherEnabledSchedules(
        response.data.filter(item => !item.isStandard && item.isActive)
      );

      setDisabledSchedules(
        response.data.filter(item => !item.isStandard && !item.isActive)
      );
    } catch (error) {
      setDefaultSchedule([]);
      setOtherEnabledSchedules([]);
      setDisabledSchedules([]);
    } finally {
      setLoading(false);
    }
  };

  const refreshTableContent = async () => {
    try {
      await handleGet();
    } catch (e) {
      enqueueSnackbar(t('i18n.monitor.chat.TRIGGERS_ERROR_1'), {
        variant: 'error'
      });
    }
  };

  const handleUpdate = async updateData => {
    try {
      await API.put('/auth/ms-chat/trigger/update', updateData).then(() => {
        refreshTableContent();
      });
    } catch (err) {
      enqueueSnackbar(t(err?.response?.data?.message?.key), {
        variant: 'error'
      });
    }
  };

  const handleEnable = async item => {
    const dataEnabled = {
      ...item,
      isActive: !item.isActive
    };

    handleUpdate(dataEnabled);
  };

  const handleClose = () => {
    setShowModal(false);
    setEditItem({});
  };

  // eslint-disable-next-line no-shadow
  const handlePost = async data => {
    await API.post('/auth/ms-chat/trigger/create', {
      ...data,
      businessType: business_type ? 'B2B' : 'B2C'
    })
      .then(() => {
        refreshTableContent();
        handleClose();
      })
      .catch(err => {
        enqueueSnackbar(t(err.response.data.message.key), {
          variant: 'error'
        });
      });
  };

  const handleDelete = async id => {
    setLoading(true);
    try {
      await API.delete(`/auth/ms-chat/trigger/${id}`);
    } catch (err) {
      enqueueSnackbar(t(err.response.data.message.key), {
        variant: 'error'
      });
    } finally {
      await refreshTableContent();
      setLoading(false);
    }
  };

  const handleEdit = async itemData => {
    setShowModal(true);
    setEditItem({ ...itemData, isActive: !!itemData.isActive });
  };

  const handleDeleteList = (value, item) => {
    if (value) {
      setDeleteList([...deleteList, item]);
    } else {
      const filteredList = deleteList.filter(i => {
        return i !== item;
      });
      setDeleteList(filteredList);
    }
  };

  useEffect(() => {
    if (deleteList.length > 0) {
      setShowDelete(true);
    } else {
      setShowDelete(false);
    }
  }, [deleteList]);

  return {
    t,
    classes,
    loading,
    data,
    showModal,
    filter,
    setShowModal,
    setData,
    setLoading,
    setFilter,
    refreshTableContent,
    showDelete,
    setShowDelete,
    handleDeleteList,
    handlePost,
    handleClose,
    deleteList,
    setDeleteList,
    handleEdit,
    handleUpdate,
    handleEnable,
    handleDelete,
    editItem,
    setEditItem,
    defaultSchedule,
    otherEnabledSchedules,
    disabledSchedules,
    handleGet
  };
};

export default ChatTriggersHooks;
