import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import AcerModal from '../../../../../components/AcerModal';
import API from '../../../../../services/api';

const ModalText = styled('p')`
  font-size: 16px;
  line-height: 1.5em;
`;

const ModalTextField = styled(TextField)`
  margin-top: 1em;
`;

const CustomTableCell = styled(TableCell)`
  background: #eee;
`;

const ModalGrid = styled(Grid)`
  margin-top: 20px;
`;

const ModalGridButton = styled(Grid)`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ModalDeniedExchange = ({
  ticketId,
  instance,
  openState,
  closeParent
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [textFieldDelivery, setTextFieldDelivery] = useState(
    `${t('i18n.b2w.modal.deniedexchange.TEXTAREA_4')} \n${t(
      'i18n.b2w.modal.deniedexchange.TEXTAREA_5'
    )} \n${t('i18n.b2w.modal.deniedexchange.TEXTAREA_6')}`
  );

  const postData = {
    ticketId,
    instanceId: instance.id,
    returnNote: textFieldDelivery
  };

  const handleSubmit = () => {
    API.post(`/auth/ms-ticket/v1/tickets/b2w/refuse-merchandise`, postData)
      .then(() => {})
      .catch(() => {
        enqueueSnackbar(t('i18n.ticket.B2W.SEND_RECUSA_MERCADORIA_FAIL'), {
          variant: 'error'
        });
      })
      .finally(() => {
        closeParent();
        setTextFieldDelivery(
          `${t('i18n.b2w.modal.deniedexchange.TEXTAREA_4')} \n${t(
            'i18n.b2w.modal.deniedexchange.TEXTAREA_5'
          )} \n${t('i18n.b2w.modal.deniedexchange.TEXTAREA_6')}`
        );
      });
  };

  return (
    <AcerModal
      fullWidth
      maxWidth="md"
      open={openState}
      modalTitle={t('i18n.b2w.modal.deniedexchange.TITLE')}
      onClose={closeParent}
    >
      <Card>
        <CardContent>
          <Grid container>
            <ModalGrid item lg={12}>
              <ModalText>
                {t('i18n.b2w.modal.postdelivery.HELLO')},{' '}
                {instance.customerName}
              </ModalText>
              <ModalText>
                {t('i18n.b2w.modal.postdelivery.PRODUCT')} {instance.orderId},{' '}
                {t('i18n.b2w.modal.postdelivery.DELIVERY')}
              </ModalText>
            </ModalGrid>
            <ModalGrid item lg={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <ModalText>
                        {t('i18n.b2w.modal.deniedexchange.PRODUCT')}
                      </ModalText>
                    </TableCell>
                    <CustomTableCell>
                      <ModalText>
                        {t('i18n.b2w.modal.postdelivery.QUANTITY')}
                      </ModalText>
                    </CustomTableCell>
                    <CustomTableCell>
                      <ModalText>
                        {t('i18n.b2w.modal.postdelivery.VALUE')}
                      </ModalText>
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {instance?.originalItems?.map(product => (
                    <TableRow key={product.id}>
                      <TableCell>
                        <ModalText>{product.name}</ModalText>
                      </TableCell>
                      <CustomTableCell>
                        <ModalText>{product.quantity}</ModalText>
                      </CustomTableCell>
                      <CustomTableCell>
                        <ModalText>
                          {Number(product.salePrice).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          })}
                        </ModalText>
                      </CustomTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ModalGrid>
            <ModalGrid item lg={12}>
              <ModalText>
                {t('i18n.b2w.modal.deniedexchange.SEE_BELOW')}
              </ModalText>
              <ModalTextField
                multiline
                className="message-textarea"
                rows={8}
                size="small"
                fullWidth
                value={textFieldDelivery}
                onChange={e => setTextFieldDelivery(e.target.value)}
                variant="outlined"
              />
            </ModalGrid>
            <Grid item container direction="row" justifyContent="flex-end">
              {/* <ModalGridButton item lg={7}>
                <Button
                  variant="contained"
                  color="default"
                  onClick={closeParent}
                >
                  {t('i18n.simplewords.CANCEL')}
                </Button>
              </ModalGridButton> */}
              <ModalGridButton item lg={5}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  {t('i18n.b2w.modal.postdelivery.SUBMIT_INFORMATIONS')}
                </Button>
              </ModalGridButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </AcerModal>
  );
};

export default ModalDeniedExchange;
