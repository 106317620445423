import userService from '../../../../services/user.service';

const handleSetUserName = filterForm => {
  const historicPermission = userService.hasRole('chat_historic_admin');
  const userName = userService.getUsername();

  return !historicPermission ? userName : filterForm.serviceAttendantLogin;
};

const handleSetAttendantLogin = () => {
  const historicPermission = userService.hasRole('chat_historic_admin');
  const userName = userService.getUsername();
  return !historicPermission ? userName : '';
};

const handleHistoricPermission = () => {
  const historicPermission = userService.hasRole('chat_historic_admin');
  return historicPermission;
};

const handleIsAdmin = () => {
  const isAdmin = userService.hasGroup('admin');
  return isAdmin;
};

const handleUsername = () => {
  const userName = userService.getUsername();
  return userName;
};

export {
  handleSetUserName,
  handleSetAttendantLogin,
  handleHistoricPermission,
  handleIsAdmin,
  handleUsername
};
