import {
  //   Box,
  //   FormControl,
  //   FormControlLabel,
  Grid
  //   Radio,
  //   RadioGroup,
  //   Typography
} from '@material-ui/core';
import React from 'react';
// import { useTranslation } from 'react-i18next';
// import { inputStyles } from '../../../../components/inputStyle';

const Whatsapp = () => {
  // const { t } = useTranslation();
  // const classes = inputStyles();
  return (
    <Grid container display="flex" alignItems="center">
      {/* Whatsapp comentado em 16/03/2022 para publicação da R3 - Descomentar assim que liberado pelo cliente 
      <Grid item xs={6}>
        <Typography>{t('i18n.ticketslistview.SERVICE_CHANNEL')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <FormControl
          component="fieldset"
          style={{
            display: 'flex',
            gap: '20px',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <RadioGroup
            value={filterForm.whatsapp || ''}
            onChange={htmlElemtEvent => {
              setFilterForm({
                ...filterForm,
                whatsapp: htmlElemtEvent.target.value
              });
              handleRemoveShortcutFlag();
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              style={{
                gap: '31px'
              }}
            >
              <FormControlLabel
                value="false"
                control={<Radio className={classes.radio} color="primary" />}
                label="Site"
              />
              <FormControlLabel
                value="true"
                control={<Radio className={classes.radio} color="primary" />}
                label="WhatsApp"
              />
            </Box>
          </RadioGroup>
        </FormControl>
      </Grid>
            */}
    </Grid>
  );
};

export default Whatsapp;
