import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import styled from 'styled-components';

const CustomCard = styled(Card)`
  width: 100%;
  border-right: 8px solid transparent;
  border-radius: 0px;
  box-shadow: 0px;
`;

const CustomLabel = styled(Typography)`
  font-size: 12px;
  margin-bottom: 0px;
`;

const CustomCardContent = styled(CardContent)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px !important;
`;

const ClientWrapper = styled('div')`
  max-width: 50%;
  overflow-x: hidden;
`;

const SpecialistItem = ({ login }) => {
  return (
    <>
      <CustomCard>
        <CustomCardContent>
          <ClientWrapper>
            <CustomLabel variant="h4">{login}</CustomLabel>
          </ClientWrapper>
        </CustomCardContent>
      </CustomCard>
    </>
  );
};

export { SpecialistItem };
