import styled from 'styled-components';
import { Grid, Card, Tabs, Tab, Button, TextField } from '@material-ui/core';
import { COLORS } from '../../../../styles/settings/colors.config';

export const SectionTabs = styled('div')`
  padding: 40px 40px 0px 40px;
`;

export const CustomTab = styled(Tab)`
  min-height: 20px !important;
  height: 30px;
`;

export const CustomTabs = styled(Tabs)`
  min-height: 20px !important;
  height: 30px;
`;

export const SectionTitle = styled('p')`
  border-bottom: 1px solid #eee;
  font-size: 18px;
  font-weight: bold;
  padding: 0px 0px 10px 0px;
  margin-bottom: 20px;
  color: ${COLORS.grayText};
`;

export const SectionTabImg = styled('img')`
  width: 100%;
  height: auto;
`;

export const SectionContent = styled('div')`
  margin-top: 10px;
  padding-left: ${props => (props.pLeft ? props.pLeft : '20px')};
`;

export const SectionContentWrapper = styled('div')`
  margin-bottom: 20px;
`;

export const SectionContentTitle = styled('p')`
  font-size: 14px;
  padding-left: 10px;
  color: ${COLORS.grayText};
`;

export const SectionTitleWarning = styled('span')`
  font-size: 12px;
  padding-left: 10px;
  color: ${COLORS.btnWarningColor};
`;

export const SectionText = styled('p')`
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-style: ${props => (props.italic ? 'italic' : 'normal')};
  cursor: ${props => props.event && 'pointer'};
  color: ${props =>
    props.alert === true ? COLORS.btnDangerColor : COLORS.grayText};
  margin-bottom: ${props => (props.mb ? '20px' : 'none')};
  :hover {
    text-decoration: ${props => props.event && 'underline'};
  }
  font-size: ${props => props.fSize && props.fSize};
`;

export const SectionTextDiv = styled.div`
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-style: ${props => (props.italic ? 'italic' : 'normal')};
  cursor: ${props => props.event && 'pointer'};
  color: ${props =>
    props.alert === true ? COLORS.btnDangerColor : COLORS.grayText};
  margin-bottom: ${props => (props.mb ? '20px' : 'none')};
  :hover {
    text-decoration: ${props => props.event && 'underline'};
  }
  font-size: ${props => props.fSize && props.fSize};
  font-family: Roboto;
`;

export const SectionLink = styled('a')`
  color: ${COLORS.greenText};
  font-size: ${props => (props.fSize ? props.fSize : '16px')};
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
`;

export const SectionProductItem = styled(Grid)`
  border-bottom: 1px dotted #ccc;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const SectionCustomGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SectionProductList = styled('div')`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const StyledTabs = styled(Tabs)`
  padding: 0;
`;

export const StyledTab = styled(Tab)`
  border: 1px solid #b7b7b7;

  border-radius: 10px;
  background: #f4f6f8;
  color: ${COLORS.greenText};
  margin: 0px 8px 0 0px;

  &.Mui-selected {
    background: #fff;
    color: ${COLORS.grayText};
    border-bottom: 1px solid transparent;
  }
`;

export const SectionTotalDetails = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: ${props =>
    props.borderdotted === 'true' ? '1px #ccc dotted;' : 'none;'};
  padding-top: ${props =>
    props.borderdotted === 'true' ? '20px !important;' : 'none;'};
  margin-top: ${props => (props.mt === 'true' ? '20px;' : 'none;')};
  margin-bottom: 20px;
`;

export const BotAlert = styled('div')`
  border: 1px solid ${COLORS.btnDangerColor};
  padding: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 20px;
`;

export const CardNoShadowNoBackground = styled(Card)`
  background: transparent;
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 2px 2px 0 rgb(63 63 68 / 15%);
  overflow: inherit;

  &.MuiPaper-rounded {
    box-shadow: none !important;
  }

  .customTabs {
    border: 1px solid ${COLORS.borderGray};
    border-radius: 0px 0px 10px 10px;
    border-top: 0px solid transparent;
    border-bottom: 9px solid transparent;
  }

  .botTab {
    box-shadow: 0px 0px 2px #c00, 0px 0px 2px #c00 !important;
    position: relative;
  }

  .customTabsAjustHeader {
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }

  .customTabsAjust {
    border-bottom: 1px solid transparent;
  }

  .customTabsDetails {
    border-bottom: 1px solid ${COLORS.borderGray};
  }

  .customTabsMessage {
    padding: 40px;
    border-radius: 6px;
    border-bottom: 1px solid ${COLORS.borderGray};
    border-top: 1px solid ${COLORS.borderGray};
    background: #fff;

    .message-paper {
      background: ${COLORS.backgroundGray};
      border: 1px solid ${COLORS.borderGray};
      border-radius: 6px;
    }

    .message-text {
      border: 1px solid ${COLORS.borderGray};
      margin-top: 15px;
      border-radius: 6px;
      background: ${COLORS.backgroundGray};
    }
  }

  .MuiPaper-rounded {
    border-radius: 0px 0px 10px 10px;
  }

  /*
  .MuiPaper-elevation1 {
    box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 0%);
  }
  */

  > button {
    background: #fff;
    border-radius: 4px 4px 0px 0px;
  }

  .MuiTab-textColorPrimary {
    color: ${COLORS.greenText} !important;
    background: #f4f6f8;
    text-transform: none;
    font-size: 16px;
    border: 1px solid ${COLORS.borderGray};
    border-radius: 6px 6px 0px 0px;
    margin-right: 10px;

    &.botTabButton {
      border-top-color: ${COLORS.btnDangerColor};
      border-left-color: ${COLORS.btnDangerColor};
      border-right-color: ${COLORS.btnDangerColor};

      .MuiTab-wrapper {
        display: flex !important;
        flex-direction: row !important;

        img {
          width: 25px;
        }
      }
    }

    :last-child {
      margin-right: 0px;
    }
  }

  &.cardHeader .MuiTab-wrapper {
    align-items: start;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: ${COLORS.grayText} !important;
    border-bottom: 1px solid transparent;
    background: #fff;

    &.botTabButton {
      border-bottom: 1px solid transparent;
      position: relative;
      z-index: 1;
    }
  }

  .MuiTabs-indicator {
    display: none;
  }
`;

export const ClientAlert = styled('span')`
  color: ${props => (props.redAlert ? `${COLORS.btnDangerColor}` : '#000')};
  font-weight: ${props => (props.redAlert ? 'bold' : 'inherit')};
  font-family: Roboto;
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const LinkModal = styled('a')`
  color: ${COLORS.greenText};
  font-weight: bold;
  margin-top: 5px;
  display: block;
`;

export const ButtonAlert = styled(Button)`
  .MuiButton-label {
    display: inherit;
  }
`;

export const CustomTextField = styled(TextField)`
  .MuiOutlinedInput-input .MuiInputLabel-outlined {
    font-size: smaller;
  }
`;

export const TicketAlertIcons = styled('span')`
  font-size: 30px;
  margin-right: 10px;
  height: 36px;
  margin-left: 10px;
  ::before {
    content: attr(data-tooltip);
    font-size: 12px;
    position: absolute;
    margin-top: -5px;
    color: #000;
    font-weight: normal;
    display: none;
  }
  :hover::before {
    display: block;
  }
`;

export const AlertIcon = styled(TicketAlertIcons)`
  font-size: 50px;
  margin-right: 10px;
  height: 46px;
  margin-left: 0px;
  color: ${props => (props.redAlert ? `${COLORS.btnDangerColor}` : `#aaa`)};
  cursor: pointer;
`;

export const SmallButton = styled(Button)`
  padding: 2px 5px !important;
  font-size: 0.8125rem;
  min-width: inherit;
`;

export const SmallButtonCancellation = styled(Button)`
  padding: 2px 2px !important;
  height: 15px;
  font-size: 8px;
  min-width: inherit;
`;

export const CardHeader = styled('p')`
  font-size: 12px;
  font-weight: ${props => (props.redAlert ? `bold` : `normal`)};
  color: ${props =>
    props.redAlert ? `${COLORS.btnDangerColor}` : `${COLORS.grayText}`};
  ::before {
    content: attr(data-tooltip);
    font-size: 12px;
    position: absolute;
    margin-top: -15px;
    color: #000;
    font-weight: normal;
    display: none;
  }
  :hover::before {
    display: block;
  }
`;

export const CardWrapper = styled(Grid)`
  padding: 0px 10px;
`;

export const CardContainer = styled(Grid)`
  padding: 20px 30px;
  border-left: 5px solid
    ${props => (props.border ? COLORS.btnDangerColor : 'transparent')};
`;

export const CardText = styled.span`
  font-family: Roboto;
  margin: 0px;
  font-weight: bold;
  color: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.alert === 'alert'
      ? COLORS.btnDangerColor
      : // eslint-disable-next-line no-nested-ternary
      props.alert === 'warning'
      ? COLORS.WarningColorSLA
      : props.alert === 'green'
      ? COLORS.greenText
      : COLORS.grayText};
`;

export const CardLink = styled(CardText)`
  color: ${COLORS.greenText};
  cursor: pointer;
`;

export const CardHeaderContent = styled('div')`
  padding: 10px 0px;
  font-size: 12px;

  @media (min-width: 1280px) {
    padding-bottom: 0px;
  }
`;

export const CustomTabButton = styled(Tab)`
  background: transparent !important;
  border: 1px solid transparent !important;
  max-width: inherit !important;
`;
