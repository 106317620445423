import styled from 'styled-components';
import { Breadcrumbs, Button } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { COLORS } from '../settings/colors.config';

export const CustomDataTableWrapper = styled('div')`
  .MuiPaper-elevation4 {
    box-shadow: none !important;
    background: transparent;
  }
`;

export const CustomDataTable = styled(MUIDataTable)`
  .MuiTableSortLabel-icon,
  thead tr th svg {
    color: transparent;
  }

  thead {
    display: ${props =>
      props.hideHeader === 'true' ? 'none' : 'table-header-group'};
  }

  .MuiTypography - h6 {
    font-size: 16px;
    font-weight: 400;
    color: ${COLORS.blackText};
  }

  .MuiToolbar - regular > div > button,
  .MuiToolbar - regular > div > span > span > button,
  .MuiTableSortLabel
    -
    root.MuiTableSortLabel
    -
    active.MuiTableSortLabel
    -
    root.MuiTableSortLabel
    -
    active
    .MuiTableSortLabel
    -
    icon,
  .MuiTablePagination - input {
    color: ${COLORS.greenText};
  }

  .MuiButtonBase - root.MuiIconButton - root.Mui - disabled.Mui - disabled {
    color: ${COLORS.divider};
  }

  .MuiToolbar - root.MuiToolbar - regular {
    border-bottom: 1.5px solid ${COLORS.divider};
  }

  .MuiToolbar
    -
    root.MuiToolbar
    -
    regular.MuiTablePagination
    -
    toolbar.MuiToolbar
    -
    gutters {
    border-bottom: 0px;
  }

  .MuiToolbar - gutters,
  thead tr,
  thead tr th {
    background: #f4f6f8;
  }

  thead tr th {
    font-size: 14px;
    border-bottom: 0px solid transparent;
    padding: ${props =>
      props.customPaddingTr ? props.customPaddingTr : '0 0 0 0px'};
    color: ${props => (props.customColorTr ? props.customColorTr : '#5a5a5a')};

    svg {
      opacity: 0.5;
    }
  }

  .MUIDataTable - responsiveBase - 21 {
    overflow: hidden !important;
  }

  .MUIDataTable - responsiveBase - 21 tbody tr {
    border-right: 2px solid #ddd;
  }

  table {
    border-spacing: 0 10px;
    border-collapse: separate;
    background: #f4f6f8;
  }

  table tr {
    background-color: #fff;
    padding: 0;
  }

  tbody td {
    font-size: 12px;
    padding: 10px 0px;
  }

  table tfoot tr {
    background: #f4f6f8;
  }

  tbody tr td {
    border-top: 1px solid #ddd;
  }

  tbodytrtd: last - child {
    border-right: 1px solid #ddd;
  }
`;

export const CustonVisitorsDatatable = styled(CustomDataTable)`
  .MuiTableCell - root {
    padding: 8px;
  }

  thead tr th,
  td {
    font-size: 12px;
  }

  table {
    border-spacing: 0 3px;
  }
`;

export const CustomTableButton = styled(Button)`
  text-transform: none;
  font-size: 1rem;
`;

export const CustomBreadCrumbs = styled(Breadcrumbs)`
  .MuiTypography - h6 {
    font-size: 20px;
    font-weight: 400;
    color: ${COLORS.blackText};
  }
`;
