import { CircularProgress, makeStyles, Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import userService from '../../../../../../services/user.service';
import { StyledSpinner } from '../../../../../../styles/components/SpinnerDefault';
import Page from '../../../../../../components/Page';
import API from '../../../../../../services/api';
import { msToHM } from '../../functions/functions';
import MainCard from './Components/Details/MainCard';
import ActionButtons from './Components/Details/ActionButtons';
import FinalConfirm from './Components/Details/FinalConfirm';
import ItemsSection from './Components/Details/ItemsSection';
import Header from './Components/Details/Header';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const fetchLogisticsDetails = async id => {
  const { data } = await API.get(
    `/auth/ms-ticket/v1/cancellationProcess/findById/${id}`
  );
  return data;
};

export default function LogisticsDetail(props) {
  const [details, setDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [logisticsReview, setLogisticsReview] = useState('');
  const [SLA, setSLA] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  const fetchData = async () => {
    setIsLoading(true);
    const { match } = props;
    const result = await fetchLogisticsDetails(match.params.id);
    setDetails(result);

    const now = new Date();
    const dateAcer = new Date(result.dateReceiptAcer);
    const difference = now.getTime() - dateAcer.getTime();
    setSLA(result.dateReceiptAcer ? msToHM(difference) : '-');

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateData = async () => {
    setIsLoading(true);
    let serialNumberNotFilled = 0;

    details.itensToCancel.forEach(item => {
      if (!item.serialNumber) {
        serialNumberNotFilled += 1;
      }
    });

    if (serialNumberNotFilled > 0 || !logisticsReview) {
      enqueueSnackbar('Preencha todos os campos obrigatórios', {
        variant: 'error'
      });
      setIsLoading(false);
      return false;
    }

    const newStep = {
      name: 'Parecer emitido pela logística',
      date: new Date(),
      reason: null,
      userName: userService.getUsername()
    };

    const json = {
      ...details,
      statusLogistics: logisticsReview.toUpperCase(),
      dateReviewedByLogistics: new Date(),
      isAuthorized: logisticsReview !== 'Recusado',
      isReviewedByLogistics: true,
      lastStepName: 'Aguardando conferência do especialista',
      stepsTimeLine: [...details.stepsTimeLine, newStep]
    };

    const result = await API.put(
      `/auth/ms-ticket/v1/cancellationProcess/update`,
      json
    );

    if (!result.data.id) {
      enqueueSnackbar('Erro ao atualizar item.', {
        variant: 'error'
      });
      return false;
    }

    enqueueSnackbar('Item atualizado.', {
      variant: 'success'
    });

    setTimeout(() => {
      window.location.href = '/cancelling/logistics';
    }, 2000);
  };

  const classes = useStyles();
  return (
    <Page className={classes.root} title="Logística">
      <Header />
      {isLoading && (
        <Box paddingX={5}>
          <StyledSpinner style={{ display: isLoading ? 'block' : 'none' }}>
            <CircularProgress />
          </StyledSpinner>
        </Box>
      )}

      {!isLoading && (
        <>
          <MainCard {...{ details, SLA }} />
          <ItemsSection {...{ details, setDetails }} />
          <FinalConfirm {...{ details, logisticsReview, setLogisticsReview }} />
          <ActionButtons {...{ details, updateData }} />
        </>
      )}
    </Page>
  );
}
