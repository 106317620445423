import {
  MenuItem,
  Box,
  FormControl,
  makeStyles,
  Select,
  TextField,
  Grid,
  Button,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMarketplaceList } from '../../../hooks/hooks';
import AcerModal from '../../../components/AcerModal';
import LegalProcessTable from './LegalProcessTable';
import API from '../../../services/api';

const useStyles = makeStyles(() => ({
  dialogCustomizedWidth: {
    'max-width': '100%',
    fontFamily: 'Roboto'
  },
  inputSelect: {
    '& .MuiOutlinedInput-root': {
      height: '30px',
      fontSize: '12px',
      width: '90%',
      backgroundColor: 'white'
    }
  },
  inputSelectFixed: {
    '& .MuiOutlinedInput-root': {
      height: '30px',
      fontSize: '12px',
      width: '220px',
      backgroundColor: 'white'
    }
  },
  inputSmall: {
    width: '220px',
    height: '30px',
    fontSize: '12px',
    marginLeft: '10px',
    backgroundColor: 'white'
  }
}));

const Section = styled.div`
  margin-bottom: ${props => (props.margin ? props.margin : '0')};
  align-items: center;
  padding: ${props => (props.padding ? props.padding : '')};
  background-color: ${props => (props.bg ? '#f3f3f3' : 'white')};
`;

const SectionHeader = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: #666666;
`;

const SectionHeaderTransparent = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: transparent;
`;

const StyledButton = styled(Button)`
  text-transform: unset !important;
  height: 30px;
`;

const OrdersHeaderValue = styled.div`
  color: #3d85c6;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
`;

const compare = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  // a deve ser igual a b
  return 0;
};

const legalProcessTypeList = [
  { value: 'processNumber', title: 'Nº do Processo' },
  { value: 'orderId', title: 'Nº do Pedido' },
  { value: 'delivery', title: 'Nº da Entrega' },
  { value: 'protocol', title: 'Nº do Protocolo Marketplace' },
  { value: 'ticketId', title: 'Nº do Protocolo Conecta' },
  { value: 'cpf', title: 'Nº do CPF/CNPJ Cliente' }
];

const ResultCounter = ({ length }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" mt={1}>
      <span
        style={{
          color: '#3d85c6',
          fontWeight: 'bold',
          marginRight: '5px'
        }}
      >
        {length}
      </span>{' '}
      <span style={{ color: '#666666' }}>{`${
        length > 1
          ? t('i18n.simplephrases.RESULTS_FOUND')
          : t('i18n.simplephrases.RESULT_FOUND')
      }`}</span>
    </Box>
  );
};

export default function LegalProcessSearchModal({ open, onClose }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedMarketplace, setSelectedMarketPlace] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [findedData, setFindedData] = useState([]);
  const [count, setCount] = useState(0);
  const [rowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [abertos, setAbertos] = useState(true);
  const [encerrados, setEncerrados] = useState(true);
  const [loading, setLoading] = useState(false);

  const marketPlaces = useMarketplaceList();

  useEffect(() => {
    setSelectedMarketPlace('B2W');
  }, [open]);

  const columns = [
    {
      name: 'processNumber',
      label: t('i18n.legalprotocol.PROCESS')
    },
    {
      name: 'status',
      label: t('i18n.legalprotocol.STATUS_PROCESS')
    },
    {
      name: 'protocol',
      label: `${t('i18n.ticketcontainer.PROTOCOL')} ${marketPlaces.find(
        m => m.value === selectedMarketplace
      )?.text || ''}`
    },
    {
      name: 'ticketId',
      label: `${t('i18n.ticketcontainer.PROTOCOL')} Conecta`
    },
    {
      name: 'order',
      label: t('i18n.orderinfo.ORDER')
    },
    {
      name: 'delivery',
      label: t('i18n.CAP.delivery')
    },
    {
      name: 'nomeCliente',
      label: t('i18n.ordersmodal.ORDER_CLIENT')
    },
    {
      name: 'cpf',
      label: t('i18n.legalprotocol.CUSTOMER_CPF')
    }
  ];

  const defaultJSON = {
    orderId: '',
    ticketId: '',
    cpf: '',
    marketplace: '',
    status: '',
    delivery: '',
    protocol: '',
    processNumber: ''
  };

  const cleanDocument = value => value.replace(/[^\d]+/g, '');

  const handleSearch = async (page = currentPage) => {
    setCurrentPage(page);

    setLoading(true);

    const returnStatus = () => {
      if (abertos && encerrados) {
        return '';
      }
      if (abertos) return 'ABERTOS';
      if (encerrados) return 'ENCERRADOS';
    };

    let json;

    if (searchBy) {
      json = {
        ...defaultJSON,
        [searchBy]:
          searchBy === 'cpf' ? cleanDocument(searchValue) : searchValue,
        status: returnStatus(),
        marketplace: selectedMarketplace
      };
    } else {
      json = {
        ...defaultJSON,
        status: returnStatus(),
        marketplace: selectedMarketplace
      };
    }

    const result = await API.post(
      `/auth/ms-ticket/v1/marketplace-juridical/find-filtered?page=${page}`,
      json
    );

    const cleanedResult = result.data.content.map(item => {
      const { status, protocol, ticketId, order, delivery, cpf, id } = item;
      const resultJSON = {
        id,
        status,
        protocol,
        ticketId,
        order,
        delivery,
        cpf
      };

      const nomeCliente = item.protocolsVariablesList.find(
        it =>
          it.variableId === 'nomeCliente' ||
          it.variableId === '963_nome_cliente' ||
          it.variableId === '809_prju_nome_cliente'
      );

      const processNumber = item.protocolsVariablesList.find(
        it =>
          it.variableId === 'numProcesso' ||
          it.variableId === '963_prju_processo_num' ||
          it.variableId === '809_prju_processo_num'
      );

      resultJSON.nomeCliente = nomeCliente.domains[0].desc;
      resultJSON.processNumber = processNumber.domains[0].desc;
      resultJSON.status = status === 'T' ? 'Aberto' : 'Encerrado';

      return resultJSON;
    });

    setFindedData(cleanedResult);
    setCount(result.data.totalElements);
    setLoading(false);
  };

  function capitalizeFirstLetter(string) {
    if (string) {
      const newString = string.toLowerCase();
      return newString.charAt(0).toUpperCase() + newString.slice(1);
    }
  }

  const handleClose = () => {
    // setSelectedMarketPlace('');
    setSearchBy('');
    setSearchValue('');
    setFindedData([]);
    setCount(0);
    setCurrentPage(0);
    setAbertos(true);
    setEncerrados(true);
    setLoading(false);
    onClose(false);
  };

  useEffect(() => {
    if (!abertos) {
      if (!encerrados) {
        setAbertos(true);
      }
    }
  }, [abertos]);

  useEffect(() => {
    if (!encerrados) {
      if (!abertos) {
        setEncerrados(true);
      }
    }
  }, [encerrados]);

  useEffect(() => {
    if (open) handleSearch(0);
  }, [abertos, encerrados, open]);

  return (
    <AcerModal
      fullWidth
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      onClose={handleClose}
      open={open}
      modalTitle={t('i18n.legalprotocol.LEGAL_PROCESSES_SEARCH')}
      freeBody
    >
      <Section bg padding="20px">
        <Grid container>
          <Grid item xs={2}>
            <Box display="flex" flexDirection="column">
              <SectionHeader>
                {t('i18n.legalprotocol.WHICH_MARKETPLACE')}
              </SectionHeader>
              {/** 
               * 
               * Comentado por Daniel em 27/01/2022. UX deseja que apareça apenas o Marketplace Americanas, 
               * mas deixei esse código aqui caso outro marketplace tenha a mesma função e seja necesário selecionar, para evitar de 
               * reescrever. Caso pense que isso não seja necessário, fique à vontade para apagar estas linhas.
               * 
              <FormControl variant="outlined" className={classes.inputSelect}>
                <Select
                  value={selectedMarketplace || ''}
                  onChange={e => {
                    setSelectedMarketPlace(e.target.value);
                  }}
                  disabled
                >
                  <MenuItem disabled value="">
                    {t('i18n.legalprotocol.SELECT_A_MARKETPLACE')}
                  </MenuItem>
                  {marketPlaces
                    .filter(
                      item => item.text !== 'Todos' && item.text !== 'All'
                    )
                    .sort((a, b) => compare(a.text, b.text))
                    .map(item => (
                      <MenuItem value={item.value} key={Math.random()}>
                        {item.text}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}
              <OrdersHeaderValue>
                {capitalizeFirstLetter(
                  marketPlaces.find(item => item.value === selectedMarketplace)
                    ?.text
                )}
              </OrdersHeaderValue>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" flexDirection="column">
              <SectionHeader>
                {t('i18n.simplephrases.SEARCH_BY')}:
              </SectionHeader>
              <Box display="flex">
                <FormControl
                  variant="outlined"
                  className={classes.inputSelectFixed}
                >
                  <Select
                    value={searchBy || ''}
                    onChange={e => {
                      setSearchBy(e.target.value);
                    }}
                  >
                    <MenuItem disabled value="">
                      {t('i18n.simplewords.SELECT')}
                    </MenuItem>
                    {legalProcessTypeList
                      .sort((a, b) => compare(a.title, b.title))
                      .map(item => (
                        <MenuItem value={item.value} key={Math.random()}>
                          {item.title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <TextField
                  value={searchValue}
                  placeholder={t('i18n.legalprotocol.INSERT_NUMBER')}
                  size="small"
                  variant="outlined"
                  onChange={ev => {
                    setSearchValue(ev.target.value);
                  }}
                  InputProps={{
                    className: classes.inputSmall
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box display="flex" flexDirection="column">
              <SectionHeaderTransparent>.</SectionHeaderTransparent>
              <StyledButton
                variant="contained"
                color="primary"
                size="small"
                onClick={() => handleSearch(0)}
              >
                {t('i18n.ticketslistview.SEARCH')
                  .toLowerCase()
                  .charAt(0)
                  .toUpperCase() +
                  t('i18n.ticketslistview.SEARCH')
                    .toLowerCase()
                    .slice(1)}
              </StyledButton>
            </Box>
          </Grid>
        </Grid>
      </Section>
      <Section padding="10px 20px">
        <Grid container>
          <Grid item xs={3}>
            <ResultCounter length={count} />
          </Grid>
          <Grid item xs={6}>
            {t('i18n.legalprotocol.FILTER_PROCESSES_BY')}:{'   '}
            <FormControlLabel
              control={
                <Checkbox
                  checked={abertos}
                  onChange={() => setAbertos(!abertos)}
                  name="abertos"
                  color="primary"
                />
              }
              label={t('i18n.ticketcontainer.RA_STATUS_OPEN_PLURAL')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={encerrados}
                  onChange={() => setEncerrados(!encerrados)}
                  name="encerrados"
                  color="primary"
                />
              }
              label={t('i18n.ticketcontainer.RA_STATUS_CESSED_PLURAL')}
            />
          </Grid>
        </Grid>
      </Section>
      <Section bg padding="10px 20px">
        <LegalProcessTable
          {...{
            count,
            columns,
            rowsPerPage,
            handleSearch,
            currentPage,
            loading
          }}
          rows={findedData}
          reportData={findedData}
        />
      </Section>
    </AcerModal>
  );
}
