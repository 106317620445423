import { Box, Card, Divider, Typography } from '@material-ui/core';
import React from 'react';
import { Description } from '@material-ui/icons';
import { COLORS } from '../../../../../styles/settings/colors.config';
import { CardTitle, CustomTypograph } from '../../../../dashboardV2/styles';
import API from '../../../../../services/api';

const downloadFile = async (attachmentIdSalesforce, name) => {
  const response = await API.get(
    `/auth/ms-ticket/v1/marketplace-support/b2w/download?marketplace=B2W&attachmentId=${attachmentIdSalesforce}`,
    { responseType: 'blob' }
  );

  const downloadUrl = window.URL.createObjectURL(response.data);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', `${name}`);
  document.body.appendChild(link);
  link.click();
};

const DownloadLink = ({ attachment }) => {
  const { attachmentIdSalesforce, pathOnClient } = attachment;

  return (
    <Box
      display="flex"
      alignItems="center"
      mb={1}
      onClick={() => downloadFile(attachmentIdSalesforce, pathOnClient)}
      style={{ color: COLORS.greenText, cursor: 'pointer' }}
    >
      <Description size="small" />{' '}
      <Typography style={{ fontSize: '12px' }}>{pathOnClient}</Typography>
    </Box>
  );
};

const AttachmentCard = ({ selectedSupport }) => {
  const { attachmentInformation } = selectedSupport;
  return (
    <Card style={{ width: '100%', borderRadius: '20px' }}>
      <CardTitle>
        <CustomTypograph variant="h5">Anexos</CustomTypograph>
      </CardTitle>
      <Divider />
      <Box
        display="flex"
        flexDirection="column"
        padding="10px"
        maxHeight="150px"
        overflow="auto"
      >
        {attachmentInformation.map(attachment => (
          <DownloadLink {...{ attachment }} key={Math.random()} />
        ))}
      </Box>
    </Card>
  );
};

export default AttachmentCard;
