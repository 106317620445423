import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { CustomTypograph } from '../custom.components';
import {
  fillDonutGraph,
  getChartColors,
  getTitleData,
  getTitleSubContent
} from './functions/commonFunctions';
import { CardTitle } from '../../styles';
import MainScoreCard from './components/MainScoreCard';
import SalesInfoCard from './ML/SalesInfoCard';
import InfoCard from './ML/InfoCard';
import BuyersScoreCard from './ML/BuyersScoreCard';

const reputationScoreLevels = [
  { score: 5, claim: 1, cancellation: 0.5, delay: 6, mediation: 0.5 },
  { score: 4, claim: 2, cancellation: 1.5, delay: 10, mediation: 0.5 },
  { score: 3, claim: 4.5, cancellation: 3.5, delay: 18, mediation: 0.5 },
  { score: 2, claim: 8, cancellation: 4, delay: 22, mediation: 0.5 },
  { score: 1, claim: 8.1, cancellation: 4.1, delay: 22.1, mediation: 0.5 }
];

const getCardInfoData = score_MERCADO_LIVRE => [
  {
    name: 'claim',
    title: 'Reclamações',
    descriptionHint:
      'São as vendas que receberam reclamações, devoluções ou cancelamentos de seus compradores e que afetam sua reputação',
    infoScore: score_MERCADO_LIVRE?.score_MERCADO_LIVRE_percent_claim || 0,
    saleQuantity: score_MERCADO_LIVRE?.score_MERCADO_LIVRE_quantity_claim,
    goalText: 'Abaixo de 2% permitido'
    // criteriaText: 'Muito bem! Para ser MercadoLider o limite é de 1%'
  },
  {
    name: 'mediation',
    title: 'Mediações',
    descriptionHint:
      'São vendas com reclamações que afetaram sua reputação, nas quais você ou o comprador solicitam a intervenção do Mercado Livre para resolvê-la. Elas afetam como mediações e reclamações',
    infoScore: score_MERCADO_LIVRE?.percent_mediation_MERCADO_LIVRE || 0,
    saleQuantity: score_MERCADO_LIVRE?.quantity_mediation_MERCADO_LIVRE || 0,
    goalText: 'Abaixo de 5% permitido'
    // criteriaText: 'Para ser MercadoLider você não pode ultrapassar o limite.'
  },
  {
    name: 'cancellation',
    title: 'Cancelado por você',
    descriptionHint:
      'São as vendas que você decide cancelar e que não tiveram reclamações',
    infoScore:
      score_MERCADO_LIVRE?.score_MERCADO_LIVRE_percent_cancellation || 0,
    saleQuantity:
      score_MERCADO_LIVRE?.score_MERCADO_LIVRE_quantity_cancellation,
    goalText: 'Abaixo de 2% permitido'
    // criteriaText: 'Muito bem! Para ser MercadoLider o limite é de 1%'
  },
  {
    name: 'delay',
    title: 'Você despachou com atraso ',
    descriptionHint:
      'São as vendas que você não entregou no prazo para o serviço de logística. Contam apenas as vendas realizadas através do Mercado Envios.',
    infoScore:
      score_MERCADO_LIVRE?.score_MERCADO_LIVRE_percent_delayed_handling_time ||
      0,
    saleQuantity:
      score_MERCADO_LIVRE?.score_MERCADO_LIVRE_quantity_delayed_handling_time,
    goalText: 'Proximo de 10% permitido'
    // criteriaText: 'Evite que sua cor seja afetada, melhore seu desempenho. Para ser mercadoLider, o limite é de 6%.'
  }
];

const MLDashboard = ({
  score,
  possibleScore,
  score_MERCADO_LIVRE,
  isRealTime,
  startDate
}) => {
  const [currentScoreData, setCurrentScoreData] = useState({});
  const chartData = fillDonutGraph(score, possibleScore);
  const colors = getChartColors(score);
  const totalValue = score;
  const titleData = getTitleData(score, '60px');
  const titleSubContent = getTitleSubContent(possibleScore);
  const cardInfoData = getCardInfoData(score_MERCADO_LIVRE);

  let period;

  if (isRealTime) {
    const end = new Date();
    end.setDate(end.getDate() - 60);
    const startFormatted = moment(end).format('DD/MM/YYYY');

    period = (
      <Typography
        component="span"
        style={{ fontSize: '12px', lineHeight: '14px', color: '#5F676C' }}
      >
        Período: <b>de {startFormatted} até hoje</b>
      </Typography>
    );
  } else {
    const endFormatted = moment(new Date(startDate)).format('DD/MM/YYYY');
    const end = new Date(startDate);
    end.setDate(end.getDate() - 60);
    const startFormatted = moment(end).format('DD/MM/YYYY');

    period = (
      <Typography
        component="span"
        style={{ fontSize: '12px', lineHeight: '14px', color: '#5F676C' }}
      >
        Período:{' '}
        <b>
          {startFormatted} a {endFormatted}
        </b>
      </Typography>
    );
  }

  useEffect(() => {
    if (score_MERCADO_LIVRE) {
      const keys = Object.keys(score_MERCADO_LIVRE);

      if (keys.length > 0) {
        const actualScoreData = reputationScoreLevels.find(
          item =>
            item.score === score_MERCADO_LIVRE?.score_MERCADO_LIVRE_real_time
        );
        setCurrentScoreData(actualScoreData);
      }
    }
  }, [score_MERCADO_LIVRE]);

  const hintText = `Representa a experiência de compra oferecida aos seus clientes 
  conforme o desempenho da sua loja. A nota é calculada no período dos últimos 60 dias`;

  return (
    <>
      <CustomTypograph variant="h3">Mercado Livre</CustomTypograph>
      <Card style={{ backgroundColor: 'white', marginTop: '10px' }}>
        <CardTitle>
          <CustomTypograph variant="h5">Nota</CustomTypograph>
        </CardTitle>
        <CardContent style={{ marginTop: '-20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <MainScoreCard
                {...{
                  chartData,
                  colors,
                  totalValue,
                  titleData,
                  titleSubContent,
                  hintText,
                  period
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <Grid container spacing={2}>
                {cardInfoData.map(item => {
                  const {
                    infoScore,
                    descriptionHint,
                    title,
                    saleQuantity,
                    criteriaText,
                    name
                  } = item;
                  return (
                    <Grid item xs={3} key={Math.random()}>
                      <InfoCard
                        {...{
                          title,
                          infoScore,
                          descriptionHint,
                          saleQuantity,
                          criteriaText,
                          currentScoreData,
                          name
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <BuyersScoreCard
            score={score_MERCADO_LIVRE?.score_estimated_MERCADO_LIVRE}
            {...{ period }}
          />
        </Grid>
        <Grid item xs={7}>
          <SalesInfoCard {...{ period, score_MERCADO_LIVRE }} />
        </Grid>
      </Grid>
    </>
  );
};

export default MLDashboard;
