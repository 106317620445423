const ticketsDashboardFilter = {};
const ticketsDashboardEquipData = {};
const ticketsDashboardIndividualData = {};
const ticketsDashboardLoading = true;
const ticketsDashboardRemovedKey = '';

const ticketsDashboardEquipDataReducer = (
  state = ticketsDashboardEquipData,
  action
) => {
  switch (action.type) {
    case 'SET_TICKETS_DASHBOARD_EQUIP_DATA':
      state = action.equipData;
      return state;
    default:
      return state;
  }
};

const ticketsDashboardRemovedKeyReducer = (
  state = ticketsDashboardRemovedKey,
  action
) => {
  switch (action.type) {
    case 'SET_TICKETS_DASHBOARD_REMOVED_KEY':
      state = action.key;
      return state;
    default:
      return state;
  }
};

const ticketsDashboardIndividualDataReducer = (
  state = ticketsDashboardIndividualData,
  action
) => {
  switch (action.type) {
    case 'SET_TICKETS_DASHBOARD_INDIVIDUAL_DATA':
      state = action.individualData;
      return state;
    default:
      return state;
  }
};

const ticketsDashboardLoadingReducer = (
  state = ticketsDashboardLoading,
  action
) => {
  switch (action.type) {
    case 'SET_TICKETS_DASHBOARD_LOADING':
      state = action.loading;
      return state;
    default:
      return state;
  }
};

const ticketsDashboardFilterReducer = (
  state = ticketsDashboardFilter,
  action
) => {
  switch (action.type) {
    case 'SET_TICKETS_DASHBOARD_FILTER':
      state = action.filter;
      return state;
    default:
      return state;
  }
};

export {
  ticketsDashboardFilterReducer,
  ticketsDashboardLoadingReducer,
  ticketsDashboardEquipDataReducer,
  ticketsDashboardIndividualDataReducer,
  ticketsDashboardRemovedKeyReducer
};
