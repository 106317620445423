import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TrackingModal from '../../../../layouts/DashboardLayout/Tools/TrackingModal';
import InvoiceModal from '../../../../layouts/DashboardLayout/Tools/InvoiceModal';
import OrdersModal from '../../../../layouts/DashboardLayout/Tools/OrdersModal';
import API from '../../../../services/api';
import AcerModal from '../../../../components/AcerModal';
import ModalCustomerHistoric from '../TicketComponents/ModalCustomerHistoric';
import { TicketContainerContext } from '../context';
import { Cap } from '../RightMenuComponents/CAP/CAP';
import ModalLinkComplaint from '../TicketComponents/ModalLinkComplaint';
import ModalTicketsDetailReclameAqui from '../ModalTicketsDetail/reclame_aqui';
import { isB2BProtocol } from '../../../../utils/b2bHelper';
import ModalRequestDevolutionRefund from '../MarketPlaceToolbar/b2w/ModalRequestDevolutionRefund';
import ModalExchangeRefund from '../MarketPlaceToolbar/b2w/ModalExchangeRefund';
import B2WMarketplaceSupportModal from '../../messages/b2w/B2WMarketplaceSupportModal';

const IframeWrapper = styled('div')`
  width: 100%;
  height: 100%;
  zoom: 110%;
  overflow: hidden;
`;

const StyledIframe = styled('iframe')`
  border: 0px;
  width: 100%;
  height: 100%;
`;

const Iframe = () => {
  const { id, marketplace } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [countTickets, setCountTickets] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [ticketData, setTicketData] = useState({});
  const [openRATicketDetail, setOpenRATicketDetail] = useState(false);
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [raTicketsList, setRaTicketsList] = useState([]);
  const [dataRaToShow, setDataRaToShow] = useState('');
  const [ticketOrders, setTicketOrders] = useState({});
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [openCapModal, setOpenCapModal] = useState(false);
  const [openTracking, setOpenTracking] = useState(false);
  const [messageReceived, setMessageReceived] = useState('');
  const [openDevolutionModal, setOpenDevolutionModal] = useState(false);
  const [exchangeRefundModalState, setExchangeRefundModalState] = useState(
    false,
  );
  const [devolutionRefundFlag, setDevolutionRefundFlag] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsLimit, setStepsLimit] = useState(1);
  const [causes, setCauses] = useState([]);
  const [items, setItems] = useState([]);
  const [marketplaceModalOpen, setMarketplaceModalOpen] = useState(false);

  const firstStep = 0;
  const handleStepsNext = () => {
    if (currentStep < stepsLimit) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleStepsPrev = () => {
    if (currentStep > firstStep) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNewAmericanasRefundExchangeCloseModal = () => {
    setOpenDevolutionModal(false);
  };

  const handleOpenModal = async ({ flag, dataTicket }) => {
    setDevolutionRefundFlag(flag);
    setOpenDevolutionModal(true);
    setTicketData(dataTicket);
    const data = {
      ticketId: dataTicket?.id,
      action: flag,
      deliveryId: dataTicket?.marketplaceBundle?.delivery_id,
      orderB2W: dataTicket?.marketplaceBundle?.order_id,
    };

    await API.post('/auth/ms-ticket/v1/tickets/b2w/new-instance-1', data).then(
      response => {
        setCauses(response.data.causes);
        setItems(response.data.items);
        if (response.data.causes.length === 0) {
          enqueueSnackbar(t('i18n.b2w.modal.exchange.alert'), {
            variant: 'warning',
          });
          setOpenDevolutionModal(false);
        }
      },
    );
  };

  const context = {
    countTickets,
    setCountTickets,
  };

  const getTicketDetails = async () => {
    const { data } = await API.get(`/auth/ms-ticket/v1/tickets/${id}`);
    setTicketData(data);
  };

  const handleShowRADetails = ra => {
    setOpenRATicketDetail(true);
    setDataRaToShow(ra);
  };

  const handleCloseInvoice = () => {
    setOpenInvoiceModal(false);
  };

  useEffect(() => {
    if (!openOrderModal) {
      const iframe = document.getElementById('ticketIframe');
      // eslint-disable-next-line
      iframe.src = iframe.src;
    }
  }, [openOrderModal]);

  useEffect(() => {
    if (!openCapModal && messageReceived === 'modal-cap') {
      const iframe = document.getElementById('ticketIframe');
      // eslint-disable-next-line
      iframe.src = iframe.src;
    }
  }, [messageReceived, openCapModal]);

  useEffect(() => {
    if (openCapModal) {
      getTicketDetails();
    }
  }, [openCapModal]);

  const getItemRaTicket = data => {
    const raData = {
      cpf: data?.customerCPF?.replace(/\D/g, ''),
    };

    API.post(
      `/auth/ms-ticket/v1/tickets/check-reclame-aqui/${data?.id}`,
      raData,
    )
      .then(response => {
        const raDataResponse = response.data;

        setRaTicketsList(raDataResponse);
        setOpenLinkModal(true);
        // setOpenRATicketDetail(false);
      })
      .catch(() => {});
  };

  const getRATickets = () => {
    const raData = {
      cpf: ticketData?.customerCPF?.replace(/\D/g, ''),
    };

    function compareID(a, b) {
      let comparison = 0;
      if (Number(a.idReclameAquiAPI) < Number(b.idReclameAquiAPI)) {
        comparison = 1;
      } else if (Number(a.idReclameAquiAPI) > Number(b.idReclameAquiAPI)) {
        comparison = -1;
      }
      return comparison;
    }

    if (
      ticketData?.customerCPF ||
      ticketData?.marketPlace === 'MERCADO_LIVRE'
    ) {
      if (isB2BProtocol(ticketData)) {
        API.post(
          `/auth/ms-ticket/v1/tickets/check-reclame-aqui/${ticketData?.id}`,
          raData,
        )
          .then(response => {
            let raDataResponse = response.data.sort(compareID);
            raDataResponse = raDataResponse.sort(x => {
              if (x.linked === false || x.linked === null) {
                return 0;
              }
              if (x) {
                return -1;
              }
              return 1;
            });
            setRaTicketsList(raDataResponse);
            setOpenRATicketDetail(false);
          })
          .catch(() => {});
      }
    }

    // checkRaRelatedToProcotol();
  };

  const handleLinkRATicket = raTicketId => {
    const dataRATicket = {
      idReclameAquiAPI: raTicketId,
    };
    API.post(
      `/auth/ms-ticket/v1/tickets/assign-reclame-aqui/${ticketData?.id}`,
      dataRATicket,
    )
      .then(() => {
        window.location.reload();
        getRATickets();
      })
      .catch(() => {
        // enqueueSnackbar(t('i18n.simplephrases.ERROR'), { variant: 'error' });
      })
      .finally(() => {
        setOpenRATicketDetail(false);
        setOpenLinkModal(false);
      });
  };

  const handleListener = useCallback(
    e => {
      if (e.data.type === 'modal-complain-here') {
        setTicketData(e.data.ticketData);
        getItemRaTicket(e.data.ticketData);
      }
      if (e.data.type === 'modal-historic') {
        setTicketData(e.data.ticketData);
        setOpenModal(true);
      }
      if (e.data.type === 'modal-cap') {
        setOpenCapModal(true);
        setMessageReceived('modal-cap');
      }
      if (e.data.type === 'modal-order-number' && e.data.ticketOrder) {
        setTicketData(e.data.ticketData);
        setTicketOrders(e.data.ticketOrder);
        setOpenOrderModal(true);
      }
      if (e.data.type === 'modal-invoice-number' && e.data.ticketOrder) {
        setTicketData(e.data.ticketData);
        setTicketOrders(e.data.ticketOrder);
        setOpenInvoiceModal(true);
      }
      if (e.data.type === 'modal-tracking') {
        setOpenTracking(true);
        setMessageReceived('modal-tracking');
      }
      if (e.data.type === 'modal-americanas-new-instance') {
        setCurrentStep(0);
        setStepsLimit(1);
        handleOpenModal({
          flag: e.data.flag,
          dataTicket: e.data.ticketData,
        });
        setOpenDevolutionModal(true);
      }
      if (e.data.type === 'modal-americanas-exchange-refund') {
        setTicketData(e.data.ticketData);
        setExchangeRefundModalState(true);
      }
      if (e.data.type === 'modal-americanas-support') {
        setMarketplaceModalOpen(true);
      }
    },
    [ticketData],
  );

  useEffect(() => {
    window.addEventListener('message', e => handleListener(e));
  }, []);

  return (
    <TicketContainerContext.Provider value={context}>
      <IframeWrapper>
        <StyledIframe
          id="ticketIframe"
          allow="clipboard-read; clipboard-write"
          src={`${
            process.env.REACT_APP_NEW_CONNECTA
          }/tickets/details/${marketplace}/${id}?refreshToken=${localStorage.getItem(
            'refreshToken',
          )}`}
        />{' '}
      </IframeWrapper>{' '}
      {openModal && (
        <ModalCustomerHistoric
          customerCpf={ticketData?.customerCPF}
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}{' '}
      {openLinkModal && (
        <ModalLinkComplaint
          openLinkModal={openLinkModal}
          setOpenLinkModal={setOpenLinkModal}
          raTickets={raTicketsList}
          raTicketsFromProtocol={ticketData?.customerComplaint}
          onlyLinkedRaTickets={raTicketsList[0].linked}
          handleShowRADetails={handleShowRADetails}
        />
      )}{' '}
      {exchangeRefundModalState && (
        <ModalExchangeRefund
          ticketData={ticketData}
          openState={exchangeRefundModalState}
          closeParent={() => {
            setExchangeRefundModalState(false);
          }}
        />
      )}{' '}
      {openDevolutionModal && (
        <ModalRequestDevolutionRefund
          ticketData={ticketData}
          openState={openDevolutionModal}
          closeParent={() => {
            setOpenDevolutionModal(false);
          }}
          modalType={devolutionRefundFlag}
          stepsLimit={stepsLimit}
          currentStep={currentStep}
          handleStepsNext={handleStepsNext}
          handleStepsPrev={handleStepsPrev}
          firstStep={firstStep}
          handleCloseModal={handleNewAmericanasRefundExchangeCloseModal}
          causes={causes}
          items={items}
        />
      )}{' '}
      {marketplaceModalOpen && (
        <B2WMarketplaceSupportModal
          open={marketplaceModalOpen}
          setOpen={setMarketplaceModalOpen}
        />
      )}{' '}
      {openRATicketDetail && (
        <ModalTicketsDetailReclameAqui
          openState={openRATicketDetail}
          closeParent={() => setOpenRATicketDetail(false)}
          data={dataRaToShow}
          linkRATicket={handleLinkRATicket}
        />
      )}{' '}
      <AcerModal
        open={openCapModal}
        onClose={() => setOpenCapModal(false)}
        modalTitle=""
      >
        <Cap
          ticketData={ticketData}
          alreadyOpened={openCapModal}
          {...{
            setOpenCapModal,
          }}
        />{' '}
      </AcerModal>{' '}
      {openOrderModal && (
        <OrdersModal
          key={ticketData?.id}
          open={!!openOrderModal}
          onClose={() => setOpenOrderModal(false)}
          customerCpf={ticketData?.customerCPF}
          orderId={ticketOrders?.idPedMarket || ''}
          customerName={
            ticketData?.customerName || ticketOrders?.cliente?.nome || ''
          }
        />
      )}{' '}
      {openInvoiceModal && (
        <InvoiceModal
          open={openInvoiceModal}
          onClose={handleCloseInvoice}
          customerCpf={ticketData?.customerCPF}
        />
      )}{' '}
      {/**
       * Comments: this modal is opened with a search code retrieved from a query from ML (getReturnsDataFromML)
       * and this data is filled inside searchCode parameter. Original code doesn´t have any mention
       * of this code come from another place, so I leave it blank. If this tracking code come inside ticket data
       * you can insert it on seachCode paramater below
       */}{' '}
      {openTracking && (
        <TrackingModal
          open={openTracking}
          onClose={() => setOpenTracking(false)}
          searchCode=""
        />
      )}{' '}
    </TicketContainerContext.Provider>
  );
};

export default Iframe;
