/*eslint-disable */
import React from 'react';
import { Card, Divider, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// import moment from 'moment';
import styled from 'styled-components';
import { getSatisfactionEmoji } from '../../../utils/emojiSentiments';

import {
  CustomTypograph,
  CardDataWrapper,
  CustomCardContent,
  CardDataDivider,
  LineCardWrapper,
  LineCardData,
  CardTitle,
  CustomHelpIcon,
  CustomHintText,
  InConstruction
} from '../styles';

const MainCardData = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .label {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .main-data {
    font-size: 45px;
    font-weight: 500;
    color: #666666;
    .emoji {
      font-size: 30px;
    }
  }
`;

const SecondaryCardData = styled(MainCardData)`
  .main-data {
    font-size: 35px;
    color: #aaa;
    .emoji {
      font-size: 25px;
    }
  }
`;

const SatisfactionCard = ({
  title,
  firstLabel,
  secondaryLabel,
  isTeamView,
  // showMoreDetails,
  // data,
  firstData,
  secondaryData,
  thirdData,
  isRealTime,
  descriptionHint,
  showInConstruction
}) => {
  const { t } = useTranslation();

  if (firstData !== '-') {
    if (Number.isNaN(firstData)) {
      firstData = 0;
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <Card style={{ width: '100%', marginBottom: '20px' }}>
        <CardTitle>
          <CustomTypograph variant="h5">{title}</CustomTypograph>
          {descriptionHint && (
            <Tooltip
              arrow
              title={<CustomHintText>{descriptionHint}</CustomHintText>}
            >
              <CustomHelpIcon fontSize="small" color="primary" />
            </Tooltip>
          )}
        </CardTitle>
        <Divider />
        <CustomCardContent>
          <CardDataWrapper jusfityContent={!isTeamView}>
            {!isTeamView && (
              <>
                <MainCardData>
                  <p className="label">{firstLabel}</p>
                  <p className="main-data">
                    <span className="emoji">
                      {getSatisfactionEmoji(firstData * 100)?.emoji}
                    </span>{' '}
                    {firstData !== '-'
                      ? `${Math.round(firstData * 100)}%`
                      : firstData}
                  </p>
                </MainCardData>
                <CardDataDivider />
                <SecondaryCardData>
                  <p className="label">{secondaryLabel}</p>
                  <p className="main-data">
                    <span className="emoji">
                      {getSatisfactionEmoji(secondaryData * 100)?.emoji}
                    </span>{' '}
                    {Math.round(secondaryData * 100)}%
                  </p>
                </SecondaryCardData>
              </>
            )}

            {isTeamView && (
              <MainCardData>
                <p className="label">{secondaryLabel}</p>
                <p className="main-data">
                  <span className="emoji">
                    {getSatisfactionEmoji(secondaryData * 100)?.emoji}
                  </span>{' '}
                  {Math.round(secondaryData * 100)}%
                </p>
              </MainCardData>
            )}
          </CardDataWrapper>
        </CustomCardContent>
        {isRealTime && (
          <CustomCardContent>
            <LineCardWrapper jusfityContent={!isTeamView}>
              <LineCardData>
                <p className="main-data">
                  {Math.round(thirdData * 100) || '0'}%
                </p>
                <p className="label">
                  {t('i18n.dashboard.components.last_ten_reviews')}
                </p>
              </LineCardData>
            </LineCardWrapper>
          </CustomCardContent>
        )}
      </Card>
      {showInConstruction && <InConstruction />}
    </div>
  );
};

export default SatisfactionCard;
