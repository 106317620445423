import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

/**
 * General Selects to forms
 * @param {String} label - String - Name of the checkbox group
 * @param {String} Name - String - Name of the field
 * @param {Object} form - Object - State of form that recieve the checkbox values
 * @param {Function} setForm - Function - Open controller
 * @param {Array} options - Array - List of the options
 */

const SelectComponent = ({ form, setForm, name, label, options, required }) => {
  const { t } = useTranslation();

  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        size="small"
        defaultValue=""
        label={label}
        className="small-input"
        value={form[name] || ''}
        name={name}
        onChange={e => {
          setForm(e);
        }}
        required={required}
      >
        <MenuItem value="">
          <em>{t('i18n.ticketslistview.NONE')}</em>
        </MenuItem>
        {options?.map(user => {
          return (
            <MenuItem key={user?.value} value={user?.value}>
              {user?.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectComponent;
