import { Tab } from '@material-ui/core';
import React from 'react';

export const reclameAquiPrivateMessagesTab = (marketplace, translator) => {
  if (
    marketplace === 'RECLAME_AQUI_ACER_BRASIL' ||
    marketplace === 'RECLAME_AQUI_ACER_LOJA'
  ) {
    return (
      <Tab
        value="reclameAquiPrivateMessages"
        label={translator(
          'i18n.reclame_aqui.phrases.tabtitle.PRIVATE_MESSAGES'
        )}
      />
    );
  }
};
