import { Box, Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { inputStyles } from '../../../../components/inputStyle';

const OpeningDate = ({
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  handleRemoveShortcutFlag
}) => {
  const classes = inputStyles();
  const { t } = useTranslation();
  return (
    <>
      <Typography gutterBottom variant="h5" style={{ marginTop: '15px' }}>
        {t('i18n.ticketslistview.OPENING_DATE')}:
      </Typography>
      <br />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              size="small"
              type="date"
              label={t('i18n.dashboard.START_DATE')}
              variant="outlined"
              className={classes.inputSmall}
              value={dateFrom}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              onChange={htmlElemtEvent => {
                setDateFrom(htmlElemtEvent.target.value);
                handleRemoveShortcutFlag();
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              type="date"
              label={t('i18n.dashboard.END_DATE')}
              variant="outlined"
              value={dateTo}
              className={classes.inputSmall}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              onChange={htmlElemtEvent => {
                setDateTo(htmlElemtEvent.target.value);
                handleRemoveShortcutFlag();
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default OpeningDate;
