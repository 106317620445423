import React from 'react';
import { Divider, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { StyledSpinner } from '../../../styles/components/SpinnerDefault';
import { CustomTabPanel } from '../components/custom.components';
import DashboardContent from './dashboardContent';
import Header from './header.component';
import { presaleOnlineTabName } from './config';

const PreSaleOnlineDashboard = ({
  tabValue,
  dashboardFilter,
  setDashboardFilter
}) => {
  const loading = useSelector(
    state => state.presaleOnlineDashboardLoadingReducer
  );
  const dataEquipDash = useSelector(
    state => state.presaleOnlineDashboardEquipDataReducer
  );

  const dataIndividualDash = useSelector(
    state => state.presaleOnlineDashboardIndividualDataReducer
  );

  const marketPlacesSelected = dashboardFilter.marketplace || [];

  return (
    <CustomTabPanel value={tabValue} index={presaleOnlineTabName}>
      <Header
        dashboardFilter={dashboardFilter}
        setDashboardFilter={setDashboardFilter}
      />
      <br />
      <Divider />
      <br />
      {!loading ? (
        <DashboardContent
          dataEquipDash={dataEquipDash}
          dataIndividualDash={dataIndividualDash}
          isAdmin={dashboardFilter.attendant === ''}
          dashboardFilter={dashboardFilter}
          marketPlacesSelected={marketPlacesSelected}
        />
      ) : (
        <>
          <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
            <CircularProgress />
          </StyledSpinner>
        </>
      )}
    </CustomTabPanel>
  );
};

export default PreSaleOnlineDashboard;
