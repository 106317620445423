import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { StyledSubmitActions } from '../../styles/components/StyledSubmitActions.styled';
import Page from '../../components/Page';
import API from '../../services/api';
import ActionModal from './ActionModal';
import { CustomBreadCrumbs } from '../../styles/components/CustomDataTable';

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  textEditWidth: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  tableScrollBody: {
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  tableScrollContainer: {
    height: '200px'
  },
  breadcrumbs: {
    marginTop: '15px',
    marginLeft: '24px'
  }
}));

const AutomationView = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const automationId = useParams().id;
  const isCreateCase = () => automationId === 'new';
  const history = useHistory();
  const [frequency, setFrequency] = React.useState('HOURLY');
  const [field, setField] = React.useState('');
  const [operator, setOperator] = React.useState('EQUAL_TO');
  const [valueOrRule, setValueOrRule] = React.useState('PRE_SALE');
  const [valueOptions, setValueOptions] = React.useState([]);
  const [name, setName] = React.useState(' ');
  const [description, setDescription] = React.useState(' ');
  const [conditionsLogic, setConditionsLogic] = React.useState('OR');
  const [conditions, setConditions] = React.useState([]);
  const [allActions, setAllActions] = React.useState([]);
  const isAllActionsInitialMount = useRef(true);
  const [checkedActions, setCheckedActions] = React.useState([]);
  const [actionModalOpenState, setActionModalOpenState] = React.useState(false);
  const [actionModalDataState, setActionModalDataState] = React.useState('');

  const loadActions = () => {
    API.get(`/auth/ms-ticket/v1/automation-actions?size=1000`).then(
      response => {
        const { data } = response;
        if (!response?.data?._embedded?.automationActionResourceList) {
          return;
        }
        const loadedActions = data._embedded.automationActionResourceList;
        setAllActions(loadedActions);
      }
    );
  };

  const prepareCheckBoxes = incomingCheckedActions => {
    const idSelectedActions = incomingCheckedActions.map(item => {
      return item.id;
    });
    setCheckedActions(idSelectedActions);
  };

  const loadDataForEditing = edData => {
    setName(edData.name);
    setDescription(edData.description);
    setFrequency(edData.frequency);
    setConditions(edData.conditions);
    setConditionsLogic(edData.conditionsLogic);
    prepareCheckBoxes(edData.actions);
  };

  const loadAutomation = () => {
    if (!isCreateCase()) {
      API.get(`/auth/ms-ticket/v1/automations/${automationId}`).then(
        response => {
          loadDataForEditing(response.data);
        }
      );
    }
  };

  const [initialLoad, setInitialLoad] = React.useState(false);
  useEffect(() => {
    if (!initialLoad) {
      loadActions();
      setInitialLoad(true);
    }
  }, []);

  useEffect(() => {
    if (isAllActionsInitialMount.current) {
      isAllActionsInitialMount.current = false;
    } else {
      loadAutomation();
    }
  }, [allActions]);

  const getArrayOfActionObjects = () => {
    return allActions.filter(item => {
      return checkedActions.includes(item.id);
    });
  };

  const submitData = () => {
    const finalObj = {
      name,
      description,
      frequency,
      conditions,
      conditionsLogic,
      actions: getArrayOfActionObjects(),
      enabled: true,
      lastExecution: null
    };

    if (
      !name ||
      !description ||
      !frequency ||
      conditions.length === 0 ||
      !conditionsLogic ||
      checkedActions.length === 0
    ) {
      enqueueSnackbar(t('i18n.newAutomation.EMPTY_FIELDS'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      return;
    }

    let request = API.post;
    if (!isCreateCase()) {
      finalObj.id = automationId;
      request = API.put;
    }

    request(`/auth/ms-ticket/v1/automations`, finalObj)
      .then(() => {
        enqueueSnackbar(t('i18n.newAutomation.SAVE_SUCCESS'), {
          variant: 'success'
        });
        history.push('/automations');
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.newAutomation.SAVE_ERROR'), {
          variant: 'error'
        });
        history.push('/automations');
      });
  };

  const deleteCondition = (fieldParam, operatorParam, value) => {
    setConditions(
      conditions.filter(
        item =>
          item.field !== fieldParam ||
          item.operator !== operatorParam ||
          item.value !== value
      )
    );
  };

  const getConditions = () => {
    return (
      <Box paddingTop={3}>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>{t('i18n.newAutomation.FIELD')}</TableCell>
                <TableCell>{t('i18n.newAutomation.OPERATOR')}</TableCell>
                <TableCell>{t('i18n.newAutomation.VALUE')}</TableCell>
                <TableCell>{t('i18n.newAutomation.DELETE')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {conditions.map(row => (
                <TableRow key={row.value}>
                  <TableCell>{t(`i18n.newAutomation.${row.field}`)}</TableCell>
                  <TableCell>
                    {t(`i18n.newAutomation.${row.operator}`)}
                  </TableCell>
                  <TableCell>
                    {row.field === 'tags'
                      ? row.value
                      : t(`i18n.newAutomation.${row.value}`)}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={t('i18n.newAutomation.DELETE_CONDITION')}>
                      <IconButton
                        onClick={() => {
                          deleteCondition(row.field, row.operator, row.value);
                        }}
                        color="primary"
                        aria-label="delete condition"
                        component="span"
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {(!conditions || conditions.length === 0) && (
                <TableRow key="noresults">
                  <TableCell align="center" colSpan={4}>
                    {t('i18n.newAutomation.ADD_CONDITIONS_ABOVE')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const deleteAction = id => {
    API.delete(`/auth/ms-ticket/v1/automation-actions/${id}`)
      .then(() => {
        enqueueSnackbar(t('i18n.newAutomation.ACTION_DELETE_SUCCESS'), {
          variant: 'success'
        });
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.newAutomation.ACTION_DELETE_ERROR'), {
          variant: 'error'
        });
      });
  };

  const addCondition = () => {
    conditions.filter(item => {
      if (
        item.field === field &&
        item.operator === operator &&
        item.value === valueOrRule
      ) {
        return enqueueSnackbar(t('i18n.newAutomation.ITEM_ALREADY_EXISTS'), {
          variant: 'error'
        });
      }
      return false;
    });

    if (!field || !operator || !valueOrRule) {
      enqueueSnackbar(t('i18n.newAutomation.EMPTY_FIELDS'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      return;
    }

    setConditions([
      ...conditions,
      {
        field,
        operator,
        value: valueOrRule
      }
    ]);
  };

  const selectAction = actionId => {
    if (checkedActions.includes(actionId)) {
      const index = checkedActions.indexOf(actionId);
      const newActionsSelected = [...checkedActions];
      newActionsSelected.splice(index, 1);
      setCheckedActions(newActionsSelected);
    } else {
      const newActionsSelected = [...checkedActions, actionId];
      setCheckedActions(newActionsSelected);
    }
  };

  const [tagOptions, setTagOptions] = useState([]);
  const refreshTagsContent = () => {
    API.get('/auth/lambda/ticket-tag').then(response => {
      if (response?.data.length > 0) {
        const labelList = response?.data?.map(item => item.tag_name);
        setTagOptions(labelList);
      }
    });
  };

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!loaded) {
      refreshTagsContent();
      setLoaded(true);
    }
  }, []);

  const changeField = event => {
    const { value } = event.target;
    setField(value);

    switch (value) {
      case 'ticketType':
        setValueOptions([
          { label: t('i18n.newAutomation.NO_OPTION'), value: null },
          { label: t('i18n.newAutomation.PRE_SALE'), value: 'PRE_SALE' },
          { label: t('i18n.newAutomation.AFTER_SALE'), value: 'AFTER_SALE' }
        ]);
        break;
      case 'marketPlace':
        setValueOptions([
          { label: t('i18n.newAutomation.NO_OPTION'), value: null },
          {
            label: t('i18n.newAutomation.MERCADO_LIVRE'),
            value: 'MERCADO_LIVRE'
          },
          { label: 'ACER STORE', value: 'CUSTOMER_GUIDE' }
        ]);
        break;
      case 'vipClient':
        setValueOptions([
          { label: t('i18n.newAutomation.NO_OPTION'), value: null },
          { label: t('i18n.newAutomation.YES'), value: true },
          { label: t('i18n.newAutomation.NO'), value: false }
        ]);
        break;
      case 'ticketStatus':
        setValueOptions([
          { label: t('i18n.newAutomation.NO_OPTION'), value: null },
          { label: t('i18n.newAutomation.NEW'), value: 'NEW' },
          { label: t('i18n.newAutomation.ANSWERED'), value: 'ANSWERED' },
          { label: t('i18n.newAutomation.AWAITING'), value: 'AWAITING' },
          { label: t('i18n.newAutomation.CUSTOMER_RETURN'), value: 'CUSTOMER_RETURN' },
          { label: t('i18n.newAutomation.RESOLVED'), value: 'RESOLVED' },
          { label: t('i18n.newAutomation.CLOSED'), value: 'CLOSED' }
        ]);
        break;
      case 'tags':
        setValueOptions(tagOptions);
        break;
      default:
        setValueOptions([
          { label: t('i18n.newAutomation.NO_OPTION'), value: null }
        ]);
    }
  };

  const checkIfACtionIsSelected = value => {
    return checkedActions.includes(value);
  };

  return (
    <Page
      className={classes.root}
      title={
        isCreateCase()
          ? t('i18n.newAutomation.NEW_AUTOMATION')
          : t('i18n.newAutomation.EDIT_AUTOMATION')
      }
    >
      <Container maxWidth={false}>
        <ActionModal
          dataState={actionModalDataState}
          openState={actionModalOpenState}
          closeParent={() => {
            loadActions();
            setActionModalOpenState(false);
          }}
        />
        <CustomBreadCrumbs
          className={classes.breadcrumbs}
          gutterBottom
          aria-label="breadcrumb"
        >
          <Typography variant="h6">
            <Link color="inherit" to="/automations">
              {t('i18n.dashboardlayout.AUTOMATIONS')}
            </Link>
          </Typography>
          {isCreateCase() ? (
            <Typography variant="h6" color="textPrimary">
              {t('i18n.newAutomation.NEW_AUTOMATION')}
            </Typography>
          ) : (
            <Typography color="textPrimary">{name}</Typography>
          )}
        </CustomBreadCrumbs>
        <Box mt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <TextField
                  value={name}
                  onChange={ev => {
                    setName(ev.target.value);
                  }}
                  fullWidth
                  label={t('i18n.newAutomation.NAME')}
                  variant="outlined"
                />
              </Box>
              <Box>
                <TextField
                  value={description}
                  onChange={ev => {
                    setDescription(ev.target.value);
                  }}
                  fullWidth
                  className={classes.textEditWidth}
                  label={t('i18n.newAutomation.DESCRIPTION')}
                  multiline
                  rows={4}
                  variant="outlined"
                />
              </Box>
            </CardContent>
            <Divider />
            <CardContent>
              <Box
                display="flex"
                mb={2}
                justifyContent="space-between"
                flexDirection="row"
              >
                <Typography variant="overline" gutterBottom>
                  {t('i18n.newAutomation.ACTIONS')}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    setActionModalDataState(null);
                    setActionModalOpenState(true);
                  }}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  {t('i18n.newAutomation.ADDS_NEW_ACTION')}
                </Button>
              </Box>
              <Box mt={3} mb={2}>
                <TableContainer
                  component={Paper}
                  className={classes.tableScrollContainer}
                >
                  <Table className={classes.table} size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Seleção</TableCell>
                        <TableCell>
                          {t('i18n.newAutomation.DESCRIPTION')}
                        </TableCell>
                        <TableCell>Tipo</TableCell>
                        <TableCell>Editar</TableCell>
                        <TableCell>Excluir</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableScrollBody}>
                      {allActions.map(row => (
                        <TableRow key={row.id}>
                          <TableCell>
                            <Box display="flex" flexDirection="row">
                              <Box>
                                <Checkbox
                                  checked={checkIfACtionIsSelected(row.id)}
                                  onClick={() => {
                                    selectAction(row.id);
                                  }}
                                />
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell>
                            {row.automationActionType ===
                            'CHANGE_TYCKET_DATA' ? (
                              <Chip label="Mudança de dados de tíquete" />
                            ) : (
                              <Chip label="Enviar email" />
                            )}
                          </TableCell>
                          <TableCell key={row.id}>
                            <Tooltip
                              title={t('i18n.newAutomation.EDIT_ACTION')}
                            >
                              <IconButton
                                onClick={() => {
                                  setActionModalDataState(row);
                                  setActionModalOpenState(true);
                                }}
                                color="primary"
                                aria-label="edit action"
                                component="span"
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              title={t('i18n.newAutomation.DELETE_ACTION')}
                            >
                              <IconButton
                                onClick={() => {
                                  deleteAction(row.id);
                                }}
                                color="primary"
                                aria-label="delete action"
                                component="span"
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </CardContent>
            <Divider />
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={4}>
                  <Box display="flex" flexDirection="column">
                    <Box paddingTop={2} paddingBottom={2}>
                      <Typography variant="h3" component="h3" gutterBottom>
                        {t('i18n.newAutomation.EXECUTE')}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                    >
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label">
                          Frequência
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={frequency}
                          onChange={ev => {
                            setFrequency(ev.target.value);
                          }}
                          label={t('i18n.newAutomation.FREQUENCY')}
                        >
                          <MenuItem value="ONCE">
                            {t('i18n.newAutomation.ONCE')}
                          </MenuItem>
                          <MenuItem value="HOURLY">
                            {t('i18n.newAutomation.HOURLY')}
                          </MenuItem>
                          <MenuItem value="DAILY">
                            {t('i18n.newAutomation.DAILY')}
                          </MenuItem>
                          <MenuItem value="WEEKLY">
                            {t('i18n.newAutomation.WEEKLY')}
                          </MenuItem>
                          <MenuItem value="MONTHLY">
                            {t('i18n.newAutomation.MONTHLY')}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    {/* {getSubmitBox()} */}
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box paddingTop={2}>
                    <Typography variant="h3" component="h3" gutterBottom>
                      {t('i18n.newAutomation.CONDITION')}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="row">
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="condicao"
                        name="condicao1"
                        value={conditionsLogic}
                        onChange={ev => {
                          setConditionsLogic(ev.target.value);
                        }}
                      >
                        <Box display="flex" flexDirection="row">
                          <FormControlLabel
                            value="AND"
                            control={<Radio />}
                            label={t('i18n.newAutomation.ALL_AND')}
                          />
                          <FormControlLabel
                            value="OR"
                            control={<Radio />}
                            label={t('i18n.newAutomation.AT_LEAST_ONE_OR')}
                          />
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box mt={2}>
                    <Grid container spacing={2}>
                      <Grid item lg={4}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="select-field">
                            {t('i18n.newAutomation.FIELD')}
                          </InputLabel>
                          <Select
                            value={field}
                            label="Campo"
                            onChange={changeField}
                          >
                            <MenuItem value={null}>
                              {t('i18n.newAutomation.NO_OPTION')}
                            </MenuItem>
                            <MenuItem value="ticketType">
                              {t('i18n.newAutomation.ticketType')}
                            </MenuItem>
                            <MenuItem value="marketPlace">
                              {t('i18n.newAutomation.marketPlace')}
                            </MenuItem>
                            {/* <MenuItem value="priority">
                                  {t('i18n.newAutomation.priority')} */}
                            {/* </MenuItem> */}
                            <MenuItem value="ticketStatus">
                              {t('i18n.newAutomation.ticketStatus')}
                            </MenuItem>
                            <MenuItem value="vipClient">
                              {t('i18n.newAutomation.vipClient')}
                            </MenuItem>
                            <MenuItem value="tags">{t('Tags')}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={3}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="select-operator">
                            {t('i18n.newAutomation.OPERATOR')}
                          </InputLabel>
                          <Select
                            value={operator}
                            label="Operador"
                            onChange={event => {
                              setOperator(event.target.value);
                            }}
                          >
                            <MenuItem value="EQUAL_TO">
                              {t('i18n.newAutomation.EQUAL_TO')}
                            </MenuItem>
                            <MenuItem value="NOT_EQUAL_TO">
                              {t('i18n.newAutomation.NOT_EQUAL_TO')}
                            </MenuItem>
                            <MenuItem value="GREATER_THAN">
                              {t('i18n.newAutomation.GREATER_THAN')}
                            </MenuItem>
                            <MenuItem value="GREATER_THAN_OR_EQUAL">
                              {t('i18n.newAutomation.GREATER_THAN_OR_EQUAL')}
                            </MenuItem>
                            <MenuItem value="MINOR_THAN">
                              {t('i18n.newAutomation.MINOR_THAN')}
                            </MenuItem>
                            <MenuItem value="MINOR_THAN_OR_EQUAL">
                              {t('i18n.newAutomation.MINOR_THAN_OR_EQUAL')}
                            </MenuItem>
                            <MenuItem value="CONTAINS">
                              {t('i18n.newAutomation.CONTAINS')}
                            </MenuItem>
                            <MenuItem value="NOT_CONTAINS">
                              {t('i18n.newAutomation.NOT_CONTAINS')}
                            </MenuItem>
                            <MenuItem value="STARTS_WITH">
                              {t('i18n.newAutomation.STARTS_WITH')}
                            </MenuItem>
                            <MenuItem value="ENDS_WITH">
                              {t('i18n.newAutomation.ENDS_WITH')}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={4}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="select-valor">
                            {t('i18n.newAutomation.VALUE')}
                          </InputLabel>
                          <Select
                            value={valueOrRule}
                            label={t('i18n.newAutomation.VALUE')}
                            onChange={event => {
                              setValueOrRule(event.target.value);
                            }}
                          >
                            {valueOptions?.map(item => {
                              if (item?.label) {
                                return (
                                  <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                );
                              }
                              return (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={1}>
                        <Box>
                          <Tooltip
                            title={t('i18n.newAutomation.ADD_CONDITION')}
                          >
                            <IconButton
                              onClick={addCondition}
                              color="primary"
                              aria-label="add condition"
                              component="span"
                            >
                              <Add />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  {getConditions()}
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <StyledSubmitActions>
                <Tooltip title="Cancelar">
                  <Button
                    onClick={() => {
                      history.push('/automations');
                    }}
                    variant="contained"
                    color="default"
                  >
                    Cancelar
                  </Button>
                </Tooltip>
                <Tooltip title={t('i18n.newAutomation.SAVE')}>
                  <Button
                    onClick={submitData}
                    variant="contained"
                    color="primary"
                  >
                    {t('i18n.newAutomation.SAVE')}
                  </Button>
                </Tooltip>
              </StyledSubmitActions>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default AutomationView;
