import React from 'react';
import { Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { CustomTabPanel } from '../components/custom.components';
import ReportDashboardContent from './reportDashboardContent';
import Header from './header.component';
import { reportsTabName } from './config';

const ReportsDashboard = ({
  tabValue,
  dashboardFilter,
  setDashboardFilter
}) => {
  const dataEquipDash = useSelector(
    state => state.chatsDashboardEquipDataReducer
  );

  const dataIndividualDash = useSelector(
    state => state.chatsDashboardIndividualDataReducer
  );

  return (
    <CustomTabPanel value={tabValue} index={reportsTabName}>
      <Header
        dashboardFilter={dashboardFilter}
        setDashboardFilter={setDashboardFilter}
      />
      <br />
      <Divider />
      <br />
      <ReportDashboardContent
        dataEquipDashChat={dataEquipDash}
        dataIndividualDashChat={dataIndividualDash}
        isAdmin={dashboardFilter.attendant === ''}
        dashboardFilter={dashboardFilter}
      />
    </CustomTabPanel>
  );
};

export default ReportsDashboard;
