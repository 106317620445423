import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RadioComponent from '../../../../components/Radio.component';

const SLA = ({ filterForm, setFilterForm }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <RadioComponent
        {...{
          name: 'sla',
          form: filterForm,
          setForm: setFilterForm,
          label: t('i18n.ticketslistview.SLA'),
          gapOptions: '0px'
        }}
        options={[
          { name: t('i18n.ticketslistview.SLA_EXPIRED'), value: 'expired' },
          {
            name: t('i18n.ticketslistview.SLA_EXPIRE_TODAY'),
            value: 'expires'
          }
        ]}
      />
    </Box>
  );
};

export default SLA;
