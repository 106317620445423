import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import timezoneFormat from '../../../../../../../utils/timezoneFormat';
import DotItem from './DotItem';
import tableHelper from '../../../../../../../utils/tableHelper';
import userService from '../../../../../../../services/user.service';
import API from '../../../../../../../services/api';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

const waitingStatuses = [
  'Aguardando Postagem',
  'Aguardando Chegada na Acer',
  'Aguardando Logística',
  'Aguardando conferência do especialista',
  'Aguardando Service',
  'Aguardando efetivação do estorno'
];

const removeProcess = async cod => {
  return API.delete(
    `/auth/ms-ticket/v1/cancellationProcess/delete/${cod.cancellationProcess.id}`
  );
};

const showMiddleButton = (lastStep, data, stepsSize) => {
  const groups = userService.getGroups();

  // Botão temporario, remover
  if (lastStep.name.includes('Postagem')) {
    if (groups.find(group => group === 'admin' || group === 'especialista')) {
      return true;
    }
  }

  // Botão temporário, remover
  if (lastStep.name.includes('Acer')) {
    if (groups.find(group => group === 'admin' || group === 'especialista')) {
      return true;
    }
  }

  if (lastStep.name.includes('Aguardando conf')) {
    if (groups.find(group => group === 'admin' || group === 'especialista')) {
      return true;
    }
  }

  if (lastStep.name === 'PI solicitada') {
    if (groups.find(group => group === 'admin' || group === 'especialista')) {
      return true;
    }
  }
  if (stepsSize === 1) {
    return !data?.cancellationProcess?.dateReceiptAcer;
  }

  if (lastStep.name.includes('Liberado pelo analista')) {
    if (groups.find(group => group === 'admin')) {
      return true;
    }
  }

  if (lastStep.name === 'Troca liberada pela Service') return true;
  if (lastStep.name === 'Estorno realizado') return true;

  return false;
};

const getButtonLabel = (stepsSize, lastStep) => {
  if (stepsSize === 2) return 'Atualizar (Temp)';
  if (lastStep?.name?.includes('Acer')) return 'Recebido (Temp)';
  if (lastStep?.name?.includes('Liberado pelo analista'))
    return 'Registrar estorno';
  if (
    lastStep?.name === 'Troca liberada pela Service' ||
    lastStep?.name === 'Estorno realizado'
  )
    return 'Cliente avisado';
  return 'Conferir ';
};

export default function DotsSection({
  data,
  viewModeAction,
  buttonActions,
  setCurrentCancelProcess
}) {
  const { creationDate } = data;
  const steps = data?.cancellationProcess?.stepsTimeLine;

  const stepsSize = steps.length;
  const lastStep = steps[stepsSize - 1];

  const filteredSteps = steps?.filter(
    step =>
      step.name !== 'Solicitação cancelada' &&
      step.name !== 'Estorno Efetivado' &&
      step.name !== 'Estorno não efetivado' &&
      step.name !== 'Finalizado'
  );

  const canceled = steps?.find(step => step.name === 'Solicitação cancelada');
  const efetivado = steps?.find(step => step.name === 'Estorno Efetivado');
  const naoEfetivado = steps?.find(
    step => step.name === 'Estorno não efetivado'
  );
  const oneStepToGo = steps?.find(
    step =>
      step.name === 'Objeto Postado - processo finalizado' ||
      step.name === 'Estorno realizado'
  );
  const finished = data?.cancellationProcess.lastStepName === 'Finalizado';

  return (
    <Container>
      {/** Dot inicial */}
      <DotItem
        position="start"
        date={timezoneFormat(creationDate)}
        status="Abertura Protocolo"
        statusCondition="passed"
        showStatus
        showTime
        timePassed={tableHelper.timeDiffCalc(data.creationDate).difference}
      />

      {/** Dots passos */}
      {filteredSteps?.map((item, i) => {
        if (i < filteredSteps.length - 1) {
          if (!waitingStatuses.includes(item.name)) {
            return (
              <DotItem
                key={Math.random()}
                position="middle"
                date={timezoneFormat(item.date)}
                status={item.name}
                statusCondition="passed"
                showStatus
                showDetailsButton={
                  !oneStepToGo &&
                  !efetivado &&
                  !naoEfetivado &&
                  !canceled &&
                  (filteredSteps.length - 2 === i ||
                    filteredSteps.length - 3 === i)
                }
                viewModeAction={viewModeAction}
              />
            );
          }
        }

        if (!waitingStatuses.includes(item.name)) {
          const conclusionStep =
            item.name === 'Objeto Postado - processo finalizado' ||
            item.name === 'Cliente informado - processo finalizado';

          return (
            <DotItem
              key={Math.random()}
              position="middle"
              status={item.name}
              statusCondition={conclusionStep ? 'passed' : 'actual'}
              showStatus
              date={conclusionStep ? timezoneFormat(item.date) : ''}
              showButton={showMiddleButton(lastStep)}
              buttonActions={buttonActions}
              buttonLabel={getButtonLabel(stepsSize, lastStep)}
              setCurrentCancelProcess={setCurrentCancelProcess}
              viewModeAction={viewModeAction}
            />
          );
        }

        if (
          waitingStatuses.includes(item.name) &&
          filteredSteps.length - 1 === i
        ) {
          return (
            <DotItem
              key={Math.random()}
              position="middle"
              status={item.name}
              statusCondition="actual"
              showStatus
              showButton={showMiddleButton(lastStep)}
              buttonActions={buttonActions}
              buttonLabel={getButtonLabel(stepsSize, lastStep)}
              setCurrentCancelProcess={setCurrentCancelProcess}
            />
          );
        }

        return false;
      })}

      {/** Dot final */}

      {efetivado && (
        <DotItem
          position="end"
          date={timezoneFormat(lastStep.date)}
          status={lastStep.name}
          showStatus
          showTime
          endProcess="ok"
          showDetailsButton
          viewModeAction={viewModeAction}
        />
      )}

      {naoEfetivado && (
        <DotItem
          position="end"
          date={timezoneFormat(lastStep.date)}
          status={lastStep.name}
          showStatus
          showTime
          endProcess="canceled"
          showDetailsButton
          viewModeAction={viewModeAction}
        />
      )}

      {canceled && (
        <DotItem
          position="end"
          date={timezoneFormat(lastStep.date)}
          status={lastStep.name}
          showStatus
          showTime
          endProcess="canceled"
          showDetailsButton
          viewModeAction={viewModeAction}
        />
      )}

      {!efetivado && !canceled && !naoEfetivado && (
        <DotItem
          position="end"
          date={finished ? timezoneFormat(lastStep.date) : ''}
          statusCondition={finished ? 'passed' : ''}
          status={finished ? 'Finalizado' : '.'}
          showStatus={finished}
          showTime={finished}
          showDetailsButton={finished}
          viewModeAction={viewModeAction}
        />
      )}
      <Button onClick={() => removeProcess(data)}>X</Button>
    </Container>
  );
}
