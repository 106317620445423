import { useEffect } from 'react';
import styled from 'styled-components';
import { searchB2bOnCurrentUrl } from '../chats/NewChatV2/services/notificationCount';

const IframeWrapper = styled.div`
  width: 100%;
  height: 100%;
  zoom: 110%;
  overflow: hidden;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;

const ChatHistory = () => {
  let url = `${process.env.REACT_APP_NEW_CONNECTA}/chat/historico`;
  if (searchB2bOnCurrentUrl()) url += '/b2b';
  url += `?refreshToken=${localStorage.getItem('refreshToken')}`;
  const query = new URLSearchParams(window.location.search).get('query');
  if (query) url += `&query=${query}`;

  useEffect(() => {
    const BASE_TITLE = `Histórico de Chat ${
      searchB2bOnCurrentUrl() ? 'B2B' : 'B2C'
    }`;
    document.title = BASE_TITLE;
  }, []);

  return (
    <IframeWrapper>
      <Iframe id="chat-history-iframe" src={url} />
    </IframeWrapper>
  );
};

export default ChatHistory;
