import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  ThemeProvider
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../components/inputStyle';

import { ChatsListContext } from './context';
import SelectComponent from '../../../../components/Select.component';
import SelectAttendant from '../../../../components/SelectAttendant.component';
import FilterInput from '../../../../components/FilterInput.component';
import CheckboxComponent from '../../../../components/Checkbox.component';
import DateComponent, {
  factoryDatesUTC
} from '../../../../components/Date.component';
import RadioComponent from '../../../../components/Radio.component';

import { clearFilterFactory, filterLabel } from './filter.config';
import { handleGetOptions } from '../../helpers/chatHelpers';

import userService from '../../../../services/user.service';

import { searchB2bOnCurrentUrl } from '../../../../utils/b2bHelper';

const FilterForm = ({ setOpen }) => {
  const { t } = useTranslation();
  const { setFilter, filter } = useContext(ChatsListContext);
  const [filterForm, setFilterForm] = useState({});
  const [creationDate, setCreationDate] = useState({});
  const [checkboxList, setCheckboxList] = useState({ hasOpinion: null });
  const [ruleFilter, setRuleFilter] = useState({});

  const userName = userService.getUsername();
  const isAdmin = userService.hasGroup('admin');
  const isB2B = searchB2bOnCurrentUrl();

  const handleSubmitFilter = e => {
    e.preventDefault();

    const newFilterForm = JSON.parse(JSON.stringify(filterForm));

    if (newFilterForm.hasOpinion === false) {
      const newFilter = JSON.parse(JSON.stringify(filter));
      delete newFilter.hasOpinion;
      delete newFilterForm.hasOpinion;

      setFilter({ ...newFilter, ...newFilterForm });
    } else {
      setFilter({ ...filter, ...filterForm });
    }

    sessionStorage.setItem('presaleOfflinefilterUseQueryParams', 'false');
    setOpen(false);
  };

  const handleSetFilter = e => {
    setFilterForm({
      ...filterForm,
      [e.target.name]: e.target.value
    });
  };

  const handleClearFilter = () => {
    setFilterForm({});
    setCheckboxList({});
    setRuleFilter({});
    setFilter(clearFilterFactory());
    setCreationDate({});
  };

  const handleSetCreationDate = e => {
    setCreationDate({
      ...creationDate,
      [e.target.name]: e.target.value
    });
  };

  const handleSetRuleFilter = e => {
    setRuleFilter({
      ...ruleFilter,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    const { start, end } = factoryDatesUTC(creationDate, 'YYYY-MM-DD');

    if (start && end) {
      setFilterForm({
        ...filterForm,
        creationDate: `${start}-${end}`
      });
    }
  }, [creationDate]);

  useEffect(() => {
    const {
      responseTimeRelationalOperator,
      firstResponseTimeInSeconds
    } = ruleFilter;

    if (responseTimeRelationalOperator) {
      setFilterForm({
        ...filterForm,
        responseTimeRelationalOperator,
        firstResponseTimeInSeconds,
        ruleOperator: `${filterLabel(
          'responseTimeRelationalOperator',
          responseTimeRelationalOperator
        )} ${firstResponseTimeInSeconds} segundos`
      });
    }
  }, [ruleFilter]);

  useEffect(() => {
    if (checkboxList.hasOpinion !== null) {
      setFilterForm({ ...filterForm, ...checkboxList });
    }
  }, [checkboxList]);

  return (
    <ThemeProvider theme={theme}>
      <div style={{ background: '#f4f6f8', borderLeft: '1px solid #cccccc' }}>
        <form onSubmit={e => handleSubmitFilter(e)}>
          <Grid container>
            <Grid item>
              <Box
                style={{
                  width: '500px',
                  padding: '12px 24px 24px',
                  border: '0'
                }}
              >
                <Typography variant="body1">
                  {t('i18n.ticketslistview.MORE_FILTER_OPTIONS')}
                </Typography>
                <Box mb={2}>
                  <FilterInput
                    {...{
                      form: filterForm,
                      setForm: handleSetFilter,
                      name: 'search',
                      label: 'Palavra-chave'
                    }}
                  />
                </Box>
                <Box mb={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FilterInput
                        {...{
                          form: filterForm,
                          setForm: handleSetFilter,
                          name: 'name',
                          label: t('i18n.ticketcontainer.CLIENT_NAME')
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FilterInput
                        {...{
                          form: filterForm,
                          setForm: handleSetFilter,
                          name: 'email',
                          label: 'E-mail'
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={2}>
                  <SelectAttendant
                    {...{
                      form: filterForm,
                      setForm: handleSetFilter,
                      name: 'login',
                      label: t('i18n.ticketslistview.ATTENDANT'),
                      isAdmin,
                      userName,
                      loadGroup: true
                    }}
                  />
                </Box>
                <Box mb={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <SelectComponent
                        {...{
                          form: filterForm,
                          setForm: handleSetFilter,
                          label: t('i18n.ticketcontainer.REASON'),
                          name: 'contactingMainReason'
                        }}
                        options={[
                          {
                            name: 'Dúvidas sobre Produto',
                            value: 'Dúvidas sobre Produto'
                          },
                          {
                            name: 'Dúvidas sobre site',
                            value: 'Dúvidas sobre site'
                          },
                          {
                            name: 'Solicitação pós venda',
                            value: 'Solicitação pós venda'
                          },
                          { name: 'Suporte Técnico', value: 'Suporte Técnico' },
                          { name: 'Críticas', value: 'Críticas' },
                          { name: 'Sugestões', value: 'Sugestões' },
                          { name: 'Cliente Offline', value: 'Cliente Offline' }
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectComponent
                        {...{
                          form: filterForm,
                          setForm: handleSetFilter,
                          label: t('i18n.ticketcontainer.REASON_DETAIL'),
                          name: 'contactingSubReason'
                        }}
                        options={handleGetOptions(
                          filterForm.contactingMainReason
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Typography
                  gutterBottom
                  variant="h5"
                  style={{ marginTop: '15px' }}
                >
                  Filtrar somente por:
                </Typography>

                {!isB2B && (
                  <>
                    <Box mb={2}>
                      <Grid>
                        <Grid item>
                          <RadioComponent
                            {...{
                              name: 'mobile',
                              form: filterForm,
                              setForm: setFilterForm,
                              label: 'Atendimento via aplicativo'
                            }}
                            options={[
                              {
                                name: t('i18n.simplewords.NO'),
                                value: 'false'
                              },
                              { name: t('i18n.simplewords.YES'), value: 'true' }
                            ]}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider />
                    <br />
                  </>
                )}

                <Box mb={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography gutterBottom variant="h5">
                        Avaliação:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <SelectComponent
                        {...{
                          form: filterForm,
                          setForm: handleSetFilter,
                          label: 'Status',
                          name: 'customerSatisfaction'
                        }}
                        options={[
                          { name: 'Like', value: 'LIKE' },
                          { name: 'Dislike', value: 'DISLIKE' },
                          { name: 'Sem avaliações', value: 'NONE' }
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={2} alignItems="start">
                  <CheckboxComponent
                    {...{
                      form: checkboxList,
                      setForm: setCheckboxList,
                      label: 'Comentário na avaliação'
                    }}
                    marginLeft={false}
                    options={[{ name: 'hasOpinion' }]}
                  />
                </Box>
                <Divider />
                <br />
                <Box mb={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography gutterBottom variant="h5">
                        {t('i18n.ticketcontainer.TIME_TO_ANSWER')}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <SelectComponent
                        {...{
                          form: ruleFilter,
                          setForm: handleSetRuleFilter,
                          label: 'Regra',
                          name: 'responseTimeRelationalOperator'
                        }}
                        options={[
                          {
                            name: 'Menor ou igual a',
                            value: 'LESS_THAN_OR_EQUAL'
                          },
                          {
                            name: 'Maior que',
                            value: 'GREATER_THAN'
                          }
                        ]}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FilterInput
                        {...{
                          form: ruleFilter,
                          setForm: handleSetRuleFilter,
                          name: 'firstResponseTimeInSeconds',
                          label: 'Segundos',
                          type: 'number',
                          disabled: !ruleFilter.responseTimeRelationalOperator
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box mb={2}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    style={{ marginTop: '15px' }}
                  >
                    Data de abertura do chat:
                  </Typography>
                </Box>
                <Box mb={2}>
                  <DateComponent
                    {...{ form: creationDate, setForm: handleSetCreationDate }}
                  />
                </Box>
                <Divider />
                <br />
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    variant="contained"
                    onClick={() => handleClearFilter()}
                  >
                    {t('i18n.ticketslistview.CLEAR')}
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    {t('i18n.dashboard.APPLY_FILTER')}
                  </Button>
                </div>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
    </ThemeProvider>
  );
};

export default FilterForm;
