import { Search } from '@material-ui/icons';
import React from 'react';
import { COLORS } from '../../../../../../../../styles/settings/colors.config';
import { LabelStatus, SmallButton } from '../styles';

export default function ItemLabel({
  status,
  statusCondition,
  showDetailsButton,
  showStatus,
  viewModeAction
}) {
  const statusColor = () => {
    if (
      status.includes('Recusado') ||
      status.includes('cancelada') ||
      status.includes('não efetivado')
    )
      return COLORS.timeline.errorBackgroundColor;
    if (statusCondition === 'actual') return COLORS.timeline.labelActiveColor;
    if (showDetailsButton) return COLORS.timeline.labelLastColor;
    return COLORS.timeline.labelDefaultColor;
  };

  return (
    <LabelStatus bgColor={statusColor} showStatus={showStatus}>
      <>
        {showDetailsButton && (
          <SmallButton
            variant="contained"
            color="primary"
            style={{
              maxWidth: '20px',
              maxHeight: '20px',
              minWidth: '20px',
              minHeight: '20px'
            }}
            onClick={viewModeAction}
          >
            <Search style={{ fontSize: '14px' }} />
          </SmallButton>
        )}
        <span
          style={{ cursor: `${showDetailsButton ? 'pointer' : ''}` }}
          onClick={() => {
            return showDetailsButton ? viewModeAction() : '';
          }}
          aria-hidden="true"
        >
          {status}
        </span>
      </>
    </LabelStatus>
  );
}
