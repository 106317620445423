import { Button, makeStyles } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import React from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../../../services/api';
import ConfirmationModal from '../../../../../utils/ConfirmationModal';
import ModerationInfo from './ModerationInfo';
import ModerationRequest from './ModerationRequest';

const useStyles = makeStyles(theme => ({
  button: {
    marginBottom: theme.spacing(1)
  }
}));

withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const ReclameAquiAnswerBar = ({ ticketData }) => {
  useStyles();
  const { t } = useTranslation();

  const [
    moderationRequestModalState,
    setModerationRequestModalState
  ] = React.useState(false);

  const [
    moderationInformationModalState,
    setModerationInformationModalState
  ] = React.useState(false);

  const moderationStatus = ticketData?.marketplaceBundle?.moderation?.status;

  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(
    false
  );
  const [evaluationRequestSent, setEvaluationRequestSent] = React.useState(
    ticketData?.requestEvaluation
  );
  const submitEvaluationRequest = () => {
    const formData = new FormData();
    formData.append('idTicket', ticketData?.id);
    formData.append('id', ticketData?.marketplaceBundle?.idHugmeAPI);
    API.post(
      `/auth/ms-ticket/v1/tickets/reclame-aqui/request-evaluation`,
      formData
    )
      .then(() => {
        setEvaluationRequestSent(true);
      })
      .catch(() => {});
  };
  return (
    <>
      {moderationStatus === null && (
        <Button
          onClick={() => {
            setModerationRequestModalState(true);
          }}
          color="primary"
        >
          <LiveHelpIcon fontSize="small" className="header-btn--icon" />
          {t('i18n.reclame_aqui.phrases.request_moderation')}
        </Button>
      )}
      {moderationStatus && (
        <Button
          onClick={() => {
            setModerationInformationModalState(true);
          }}
          color="primary"
        >
          <InfoIcon fontSize="small" className="header-btn--icon" />
          {t('i18n.reclame_aqui.phrases.moderation_status')}
        </Button>
      )}

      <Button
        disabled={evaluationRequestSent}
        onClick={() => {
          setConfirmationModalOpen(true);
        }}
        color="primary"
      >
        <StarHalfIcon fontSize="small" className="header-btn--icon" />
        {t('i18n.reclame_aqui.phrases.evaluation_request')}
      </Button>

      <ModerationRequest
        ticketData={ticketData}
        openState={moderationRequestModalState}
        closeParent={() => {
          setModerationRequestModalState(false);
        }}
      />
      <ModerationInfo
        moderationInfo={ticketData?.marketplaceBundle?.moderation}
        openState={moderationInformationModalState}
        closeParent={() => {
          setModerationInformationModalState(false);
        }}
      />
      <ConfirmationModal
        message={t('i18n.reclame_aqui.modal.evaluation_request.confirm')}
        submitConfirmation={() => {
          submitEvaluationRequest();
          setConfirmationModalOpen(false);
        }}
        openState={confirmationModalOpen}
        closeParent={() => {
          setConfirmationModalOpen(false);
        }}
      />
    </>
  );
};

export default ReclameAquiAnswerBar;
