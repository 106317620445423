import { Box, makeStyles, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import moment from 'moment';
import tableHelper from '../../../../../../utils/tableHelper';
import { CustomDataTable } from '../../../../../../styles/components/CustomDataTable';
import userService from '../../../../../../services/user.service';
import CustomFooter from '../../../../../../styles/components/CustomFooter';
import Page from '../../../../../../components/Page';
import API from '../../../../../../services/api';
import { StyledSpinner } from '../../../../../../styles/components/SpinnerDefault';
import MainDivider from './Components/Main/Divider';
import ChipSection from '../Components/Global/ChipSection';
import MainDrawer from '../../Filter/MainDrawer';
import CustomRow from './Components/CustomRow';
import { CustomBreadCrumbs } from './Components/styles';
import HeaderTitle from '../../../../../../components/HeaderTitle';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  breadcrumbs: {
    marginLeft: '15px'
  },
  divider: {
    width: '90%'
  }
}));

const statusList = [
  {
    value: 'moving',
    label: 'Aguardando chegada em Service'
  },
  { value: 'waiting', label: 'Aguardando Service' },
  { value: 'finished', label: 'Troca liberada pela Service' }
];

const columns = [
  { name: 'nf', label: 'Nota Fiscal Origem' },
  { name: 'logisticsDate', label: 'Liberado pelo Atendimento' },
  { name: 'sla', label: 'SLA' },
  { name: 'obsAtendimento', label: 'Observação Atendimento' },
  { name: 'parecerLogistica', label: 'Parecer Logística NF' },
  { name: 'statusDevolucao', label: 'Status da Devolução' },
  { name: 'tipoTroca', label: 'Tipo da Troca' },
  { name: 'motivoDevolucao', label: 'Motivo da Troca' },
  { name: 'conectaTicketId', label: 'Protocolo Conecta' }
];

const Service = () => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [items, setItems] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [, setNumberOfElements] = useState(0);
  const [, setTotalPages] = useState(1);
  const { t } = useTranslation();
  const [status, setStatus] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const serviceStatus = steps => {
    if (steps.find(s => s.name === 'Troca liberada pela Service'))
      return 'Troca liberada pela Service';
    if (steps.find(s => s.name === 'Aguardando Service'))
      return 'Aguardando Service';
    return 'Aguardando chegada em Service';
  };

  const getInfos = async searchJSON => {
    setIsLoading(true);

    const result = await API.get(
      `/auth/ms-ticket/v1/cancellationProcess/findFilteredLogistics`,
      { params: searchJSON }
    );
    const { data } = result;

    const tableData = data?.content?.map(item => {
      return {
        id: item.id,
        nf: item.order.numeroNF,
        isReviewedBySpecialist: item.isReviewedBySpecialist ? 'SIM' : 'NÃO',
        obsAtendimento: item.specialistDetail,
        parecerLogistica: item.statusLogistics,
        tipoTroca: item?.isCancellationOfAllItens ? 'Total' : 'Parcial',
        statusDevolucao: serviceStatus(item?.stepsTimeLine),
        motivoDevolucao: item.reasonCancellation,
        solicitacaoDevolucao: moment(item.stepsTimeLine[0].date).format(
          'DD/MM/YYYY'
        ),
        conectaTicketId: item.conectaTicketId
      };
    });
    setIsLoading(false);

    setItems(tableData);
    setCurrentPage(data.number);
    setTotalElements(data.totalElements);
    setNumberOfElements(data.numberOfElements);
    setTotalPages(data.totalPages);
    setPageSize(data.size);
  };

  const refreshTableContent = page => {
    const searchJSON = {
      page: page || 0,
      size: pageSize,
      isReviewedByService: status === 'finished',
      isReviewedByLogistics: true,
      isReviewedBySpecialist: true,
      cancellationType: 'TROCA'
    };
    getInfos(searchJSON);
  };

  const options = {
    filter: false,
    pagination: true,
    filterType: 'checkbox',
    viewColumns: false,
    selectableRows: 'none',
    print: false,
    search: false,
    rowsPerPage: pageSize,
    rowsPerPageOptions: [20],
    count: totalElements,
    serverSide: true,
    download: false,
    page: currentPage,
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        if (currentPage !== tableState.page)
          refreshTableContent(tableState.page);
      }
      if (action === 'sort') {
        setCurrentPage(0);
      }
    },
    customRowRender: (data, index) => {
      const currentItem = items[index];
      return <CustomRow {...{ data, currentItem }} key={Math.random()} />;
    },
    setRowProps: (row, index) => {
      tableHelper.setRowStyle(row, index, items);
    },
    // onRowSelectionChange,
    onCellClick: (colData, cellMeta) => {
      if (cellMeta.colIndex === 0) {
        if (!userService.hasRole('ticket_detail')) {
          enqueueSnackbar(t('i18n.ticketslistview.DETAIL_DISABLED'), {
            variant: 'warning'
          });
        }
        return;
      }
      if (userService.hasRole('ticket_detail')) {
        const { id } = items[cellMeta.rowIndex];
        history.push({
          pathname: `/service-details/${id}`
        });
      } else {
        enqueueSnackbar(t('i18n.ticketslistview.DETAIL_DISABLED'), {
          variant: 'warning'
        });
      }
    },

    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          rowsPerPageOptions={[20]}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: !isLoading
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: column =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };

  useEffect(() => {
    let searchJSON;

    if (localStorage.getItem('cancellationFilterService')) {
      const loadedFilter = JSON.parse(
        localStorage.getItem('cancellationFilterService')
      );
      setSearch(loadedFilter.nf);
      setStatus(loadedFilter.status);

      searchJSON = { ...loadedFilter, page: 0 };
    } else {
      setStatus('waiting');
      searchJSON = {
        page: 0,
        size: pageSize,
        isReviewedByService: status === 'finished',
        isReviewedByLogistics: true,
        isReviewedBySpecialist: true,
        cancellationType: 'TROCA'
      };

      localStorage.setItem(
        'cancellationFilterService',
        JSON.stringify({ ...searchJSON, status: 'waiting' })
      );
    }

    getInfos(searchJSON);
  }, []);

  const handleSearch = async (newSearch = null, newStatus = null) => {
    const searchJSON = {
      page: currentPage,
      size: pageSize,
      nf: newSearch,
      cancellationType: 'TROCA'
    };

    if (newStatus !== ' ' && newStatus !== '') {
      searchJSON.isReviewedByLogistics = newStatus !== 'moving';
      searchJSON.isReviewedBySpecialist = newStatus !== 'moving';
      searchJSON.isReviewedByService = newStatus === 'finished';
    }

    localStorage.setItem(
      'cancellationFilterService',
      JSON.stringify({ ...searchJSON, status: newStatus })
    );

    getInfos(searchJSON);
    setIsLoading(false);
  };

  const removeFilterFromChip = async type => {
    if (type === 'search') {
      setSearch('');
      handleSearch(' ', status);
    }

    if (type === 'status') {
      setStatus('');
      handleSearch(search, ' ');
    }
  };

  return (
    <Page className={classes.root} title="Service">
      <MainDrawer
        {...{ search, setSearch, status, setStatus, handleSearch }}
        filterType="service"
      />
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        paddingX={10}
      >
        <CustomBreadCrumbs
          className={classes.breadcrumbs}
          gutterBottom
          aria-label="breadcrumb"
        >
          <HeaderTitle title="Service" />
        </CustomBreadCrumbs>

        <ChipSection
          {...{ search, status, removeFilterFromChip, statusList }}
        />
      </Box>
      <MainDivider />
      {isLoading && (
        <StyledSpinner style={{ display: isLoading ? 'block' : 'none' }}>
          <CircularProgress />
        </StyledSpinner>
      )}

      {!isLoading && (
        <Box paddingX={10}>
          <CustomDataTable
            data={items}
            columns={columns}
            options={options}
            selectableRowsOnClick={!!userService.hasRole('ticket_detail')}
            className="table-clickable"
          />
        </Box>
      )}
    </Page>
  );
};

export default Service;
