import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ForwardIcon from '@material-ui/icons/Forward';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS } from '../../../../styles/settings/colors.config';
import API from '../../../../services/api';

import { handleGetNotification } from '../services/notificationCount';

import { searchB2bOnCurrentUrl } from '../../../../utils/b2bHelper';

const CustomCard = styled(Card)`
  cursor: pointer;
  width: 100%;
  // border-right: 8px solid transparent;
  border-right-color: ${props =>
    props.notification === 'true' && COLORS.greenText};
`;

const CustomLabel = styled(Typography)`
  font-size: 10px;
  margin-bottom: 0px;
  margin-top: 10px;
`;

const MinutesLabel = styled(CustomLabel)`
  text-align: center;
`;

const CustomCardContent = styled(CardContent)`
  display: flex;
  // display: table;
  // align-items: center;
  // justify-content: space-between;
  padding: 0px !important;
  opacity: ${props => (props.showisoffline === 'false' ? '1' : '0.5')};
`;

const ClientWrapper = styled('div')`
  // max-width: 50%;
  display: table-cell;
  width: 100%;
  overflow-x: hidden;
  padding: 10px;
`;

const ClientDetails = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NotificationDetails = styled(ClientDetails)`
  border-right: ${props => props.numberNotification && '8px solid #ccc'};
  vertical-align: middle;
  padding-right: 10px;
}`;

const Minutes = styled(Typography)`
  background-color: ${COLORS.grayText};
  color: #fff;
  font-weight: bold;
  height: 40px;
  width: 40px;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => props.notisnotification === 'true' && '10px'};
`;

const Count = styled(Minutes)`
  height: 30px;
  width: 30px;
  font-size: 14px;
`;

const Email = styled(Typography)`
  color: ${COLORS.grayText};
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (min-width: 1024px) {
    max-width: 60px;
  }
  @media screen and (min-width: 1366px) {
    max-width: 145px;
  }
  @media screen and (min-width: 1440px) {
    max-width: 165px;
  }
  @media screen and (min-width: 1920px) {
    max-width: 300px;
  }
`;

const StyledNotification = styled('div')`
  display: flex;
  border-right: 8px solid transparent;
  border-color: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.numberNotification
      ? COLORS.greenText
      : props.viewed
      ? COLORS.blueText
      : 'transparent'};
  align-items: center;
`;

const ClientOnline = styled('div')`
  width: 8px;
  display: block;
  background: ${props =>
    props.showisoffline === 'false' ? 'transparent' : COLORS.btnWarningColor};
`;

const NotificationCard = ({
  chat,
  showIsViewed,
  setShowIsViewed,
  hadReaded,
  setHadReaded,
  showIsNotified,
  setShowIsNotified
}) => {
  const [, setNotification] = useState(false);
  const [numberNotification, setNumberNotification] = useState(0);

  const messageNotification = useSelector(state => state.notificationReducer);

  const getNotification = async () => {
    const { messageCount, read } = await handleGetNotification(chat);
    setHadReaded(read);
    setNumberNotification(messageCount);
    setNotification(false);
    if (messageCount > 0) {
      setShowIsNotified(true);
    }
  };

  useEffect(() => {
    getNotification();
  }, []);

  useEffect(() => {
    if (
      messageNotification.hashConnection === chat.hashConnection &&
      messageNotification.login
    ) {
      setNumberNotification(0);
      setNotification(false);
      setShowIsViewed(false);
      setHadReaded(false);
    } else if (messageNotification.hashConnection === chat.hashConnection) {
      setNumberNotification(numberNotification + 1);

      setNotification(false);
    }
  }, [messageNotification]);

  return (
    <StyledNotification
      numberNotification={numberNotification > 0 && true}
      viewed={showIsViewed || hadReaded}
    >
      {numberNotification > 0 && (
        <NotificationDetails>
          <Count variant="h4">{numberNotification}</Count>
        </NotificationDetails>
      )}
      {(showIsViewed || (hadReaded && !showIsNotified)) && (
        <Typography style={{ fontSize: '10px', marginRight: '12px' }}>
          Lido
        </Typography>
      )}
    </StyledNotification>
  );
};

let interval;

const CustomerCards = ({
  socket,
  chat,
  loadAttendances,
  newUserStatus,
  showToolBars,
  openChat = false,
  showOnlineBar
}) => {
  let subscribe;
  const { name, email, clerk, minutesOnline, mobile } = chat;
  const [newName, setNewName] = useState(name);
  const [newEmail, setNewEmail] = useState(email);
  const [isOffline, setIsOffline] = useState({});
  const [showIsOffline, setShowIsOffline] = useState(false);
  const [showIsTyping, setShowIsTyping] = useState(false);
  const [showIsViewed, setShowIsViewed] = useState(null);
  const [hadReaded, setHadReaded] = useState(false);
  const [chatData, setChatData] = useState(chat);
  const [typing, setTyping] = useState({});
  const [viewed, setViewed] = useState({});
  const [showIsNotified, setShowIsNotified] = useState(false);

  const { t } = useTranslation();
  const reduxDispatcher = useDispatch();

  const myClientList = useSelector(state => state.clientListReducer);
  const myClientNameEmail = useSelector(
    state => state.clientInAttendanceReducer
  );

  const [showBtn, setShowBtn] = useState(true);

  const setChatForMe = async () => {
    const findAttendance = myClientList.find(item => {
      return item.hashConnection === chat.hashConnection;
    });
    if (!findAttendance) {
      await API.get(
        `/auth/ms-chat/chats/responsible/${chat.hashConnection}?businessType=${
          !searchB2bOnCurrentUrl() ? 'B2C' : 'B2B'
        }`
      );
      loadAttendances();
    }
  };

  const viewChat = async talk => {
    reduxDispatcher({
      type: 'SHOW_CHAT_HISTORIC',
      show: true
    });

    reduxDispatcher({
      type: 'SHOW_CLIENT_TALK',
      showClientTalk: true
    });

    reduxDispatcher({
      type: 'SET_CLIENT_IN_ATTENDANCE_NOW',
      clientInAttendanceNow: talk
    });
  };

  const initSubscribeSocket = () => {
    subscribe = socket?.newchatSubject.subscribe(value => {
      const response = JSON.parse(value.data);

      if (
        response?.system?.action === 'CLIENTOUT' &&
        chat.hashConnection === response.system.hashConnection
      ) {
        setIsOffline({
          hashConnection: response.system.hashConnection,
          offline: true
        });

        setTyping({
          hashConnection: response.system.hashConnection,
          typing: false
        });
      }

      if (
        response?.message &&
        response?.message !== 'Forbidden' &&
        response?.message !== 'Internal server error' &&
        response?.message !== 'Typing'
      ) {
        if (chat.hashConnection === response?.hashConnection) {
          setViewed({
            hashConnection: response.hashConnection,
            viewed: false
          });
        }
      }

      if (response?.system?.typing === 'Viewed') {
        setViewed({
          hashConnection: response.system.hashConnection,
          viewed: true
        });
      }

      if (response?.system?.typing === 'Typing') {
        setTyping({
          hashConnection: response.system.hashConnection,
          typing: true
        });
      }

      if (response?.system?.typing === 'Typing off') {
        setTyping({
          hashConnection: response.system.hashConnection,
          typing: false
        });
      }
    });
  };

  useEffect(() => {
    const clientUpdatedList = myClientNameEmail.filter(item => {
      return item.hashConnection === chat.hashConnection;
    });

    const lastUpdateClient = clientUpdatedList[clientUpdatedList.length - 1];

    if (lastUpdateClient?.name) {
      setNewName(lastUpdateClient.name);
      setNewEmail(lastUpdateClient.email);
    }
    return () => {
      if (subscribe) {
        subscribe.unsubscribe();
        clearInterval(interval);
      }
    };
  }, [myClientNameEmail, chat]);

  const handlePing = (ws, cht) => {
    ws.sendHeartBeatTo(
      {
        // eslint-disable-next-line
        heart_beat: 'heartBeat',
        // eslint-disable-next-line
        hash_connection: cht.hashConnection,
        to: cht.from
      },
      cht.hashConnection
    );
    setIsOffline({ hashConnection: cht.hashConnection, offline: false });
    setChatData({ chatData, offline: false });
  };

  useEffect(() => {
    if (socket && openChat) {
      initSubscribeSocket();
      handlePing(socket, chat);

      const heartBeat = setInterval(() => {
        handlePing(socket, chat);
      }, 10000);

      return () => {
        if (heartBeat) {
          clearInterval(heartBeat);
        }
      };
    }
  }, [socket, openChat]);

  useEffect(() => {
    return () => {
      if (subscribe) {
        subscribe.unsubscribe();
        clearInterval(interval);
      }
    };
  }, []);

  useEffect(() => {
    if (isOffline.hashConnection === chat.hashConnection && isOffline.offline) {
      setShowIsOffline(true);
    } else {
      setShowIsOffline(false);
    }
  }, [isOffline, chat]);

  useEffect(() => {
    if (typing.hashConnection === chat.hashConnection && typing.typing) {
      setShowIsTyping(true);
    } else {
      setShowIsTyping(false);
    }
  }, [typing, chat]);

  useEffect(() => {
    if (viewed.hashConnection === chat.hashConnection && viewed.viewed) {
      setShowIsViewed(true);
    }
    if (viewed.hashConnection === chat.hashConnection && !viewed.viewed) {
      setShowIsViewed(false);
    }
  }, [viewed, chat]);

  return (
    <>
      <CustomCard
        onClick={
          openChat
            ? () => {
                reduxDispatcher({
                  type: 'SET_CLIENT_IN_ATTENDANCE_NOW',
                  clientInAttendanceNow: chat
                });
                reduxDispatcher({ type: 'SHOW_CHAT_HISTORIC', show: false });
                reduxDispatcher({
                  type: 'SHOW_CLIENT_TALK',
                  showClientTalk: true
                });
                reduxDispatcher({
                  type: 'SET_TAB_VIEW_CHAT',
                  tabViewChat: true
                });
                reduxDispatcher({
                  type: 'SHOW_VISITORS',
                  showVisitors: false
                });
              }
            : () => {}
        }
      >
        <CustomCardContent showisoffline={showIsOffline.toString()}>
          {showOnlineBar && (
            <ClientOnline showisoffline={showIsOffline.toString()} />
          )}
          <ClientWrapper style={{ width: '100%' }}>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <div>{mobile ? <PhoneIphoneIcon /> : <DesktopMacIcon />}</div>
              <div>
                <Typography variant="h4">{newName}</Typography>
                <Tooltip title={newEmail || ''}>
                  <Email variant="subtitle2">{newEmail}</Email>
                </Tooltip>
              </div>
              <div>
                {showIsTyping && (
                  <Typography style={{ fontSize: '10px' }}>
                    Digitando...
                  </Typography>
                )}
                {showOnlineBar && showIsOffline && (
                  <Typography style={{ fontSize: '10px' }}>Offline</Typography>
                )}
              </div>
            </div>
            {clerk && (
              <CustomLabel>
                {t('i18n.chatview.SPECIALIST')}: <b>{clerk}</b>
              </CustomLabel>
            )}
          </ClientWrapper>
          {(minutesOnline || minutesOnline === 0) && (
            <ClientDetails style={{ width: '50%' }}>
              <Minutes variant="h4" notisnotification="true">
                {minutesOnline}
              </Minutes>
              <MinutesLabel>{t('i18n.chatview.MINUTES_ONLINE')}</MinutesLabel>
            </ClientDetails>
          )}

          {openChat && (
            <NotificationCard
              socket={socket}
              chat={chat}
              {...{
                showIsViewed,
                setShowIsViewed,
                hadReaded,
                setHadReaded,
                showIsNotified,
                setShowIsNotified
              }}
            />
          )}

          {showToolBars && (
            <ClientDetails style={{ width: '50%' }}>
              <Tooltip title="Ver mensagens do cliente">
                <IconButton
                  onClick={() => {
                    viewChat(chat);
                  }}
                  color="primary"
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
              {newUserStatus && (
                <Tooltip title="Puxar atendimento">
                  <div>
                    <IconButton
                      onClick={() => {
                        setShowBtn(false);
                        setChatForMe();
                      }}
                      disabled={!showBtn}
                      color="primary"
                    >
                      <ForwardIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              )}
            </ClientDetails>
          )}
        </CustomCardContent>
      </CustomCard>
      <br />
    </>
  );
};

export { CustomerCards };
