import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import API from '../../../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    overflow: 'hidden',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(7)
  }
}));

const ChatSettingsHooks = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [defaultSchedule, setDefaultSchedule] = useState([]);
  const [otherEnabledSchedules, setOtherEnabledSchedules] = useState([]);
  const [disabledSchedules, setDisabledSchedules] = useState([]);
  const { business_type } = useParams();

  const handleGet = async () => {
    const response = await API.get(
      `/auth/ms-chat/attendingTime?businessType=${
        business_type ? 'B2B' : 'B2C'
      }`
    );

    setDefaultSchedule(response.data.filter(item => item.standard));
    setOtherEnabledSchedules(
      response.data.filter(item => !item.standard && item.enabled)
    );
    setDisabledSchedules(
      response.data.filter(item => !item.standard && !item.enabled)
    );
  };

  const refreshTableContent = async () => {
    setLoading(true);

    try {
      await handleGet();
    } catch (e) {
      enqueueSnackbar(t('i18n.monitor.chat.SETTINGS_ERROR_1'), {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  // eslint-disable-next-line no-shadow
  const handlePost = async data => {
    try {
      await API.post('/auth/ms-chat/attendingTime/create-attending-time', {
        ...data,
        businessType: business_type ? 'B2B' : 'B2C'
      }).then(() => {
        enqueueSnackbar(t('i18n.monitor.chat.SETTINGS_POST'), {
          variant: 'success'
        });
        refreshTableContent();
        handleClose();
      });
    } catch (err) {
      enqueueSnackbar(err.response.data.Error, {
        variant: 'error'
      });
    }
  };

  const handleDelete = async ids => {
    setLoading(true);
    try {
      await API.delete('/auth/ms-chat/attendingTime/delete', {
        data: { ids }
      });
      enqueueSnackbar(t('i18n.monitor.chat.SETTINGS_DELETE'), {
        variant: 'success'
      });
    } catch (err) {
      enqueueSnackbar(err.response.data.Error, {
        variant: 'error'
      });
    } finally {
      refreshTableContent();
      setLoading(false);
    }
  };

  const handleDeleteUnique = async id => {
    setLoading(true);
    try {
      await API.delete(`/auth/ms-chat/attendingTime/${id}`);
      enqueueSnackbar(t('i18n.monitor.chat.SETTINGS_DELETE'), {
        variant: 'success'
      });
    } catch (err) {
      enqueueSnackbar(err.response.data.Error, {
        variant: 'error'
      });
    } finally {
      refreshTableContent();
      setLoading(false);
    }
  };

  const handleUpdate = async dataEnabled => {
    try {
      await API.put(
        '/auth/ms-chat/attendingTime/update-attending-time?businessType=B2B',
        dataEnabled
      ).then(() => {
        enqueueSnackbar(t('i18n.monitor.chat.SETTINGS_UPDATE'), {
          variant: 'success'
        });
        refreshTableContent();
      });
    } catch (err) {
      enqueueSnackbar(err.response.data.Error, {
        variant: 'error'
      });
    }
  };

  const handleEnable = async item => {
    const dataEnabled = {
      ...item,
      enabled: !item.enabled
    };

    handleUpdate(dataEnabled);
  };

  const handleEdit = item => {
    setShowModal(true);
    setEditItem(item);
  };

  useEffect(() => {
    refreshTableContent();
  }, []);

  return {
    t,
    classes,
    loading,
    data,
    showModal,
    setShowModal,
    setData,
    setLoading,
    refreshTableContent,
    handlePost,
    handleDelete,
    handleDeleteUnique,
    handleEdit,
    handleEnable,
    handleClose,
    editItem,
    business_type,
    defaultSchedule,
    otherEnabledSchedules,
    disabledSchedules
  };
};

export default ChatSettingsHooks;
