import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    inputOrangeSmall: {
      root: {
        textAlign: 'right',
        backgroundColor: '#FFFFFF'
      },
      width: '100px',
      height: '30px',
      fontSize: '10px',
      fontWeight: 'bold',
      color: '#ED7D31'
    },
    input100: {
      fontSize: '12px'
    },
    inputMedium: {
      width: '150px',
      height: '30px',
      fontSize: '12px'
    },
    buttonWithMargin: {
      marginRight: '10px'
    },
    inputSelect: {
      '& .MuiOutlinedInput-root': {
        height: '30px',
        fontSize: '12px',
        backgroundColor: 'white'
      }
    },
    alert: {
      border: '1px solid #FFC061',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      margin: '0 0 12px 0',
      backgroundColor: '#FFF7E0',
      color: 'black',
      fontWeight: 'bold',
      padding: '1px'
    },
    inputSmall100: {
      width: '500px',
      height: '30px',
      fontSize: '12px'
    }
  })
);
