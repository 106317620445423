import { Box, CircularProgress, Grid, Chip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
import { StyledSpinner } from '../../../styles/components/SpinnerDefault';
import Page from '../../../components/Page';
import API from '../../../services/api';
import {
  CustomDataTableWrapper,
  CustomDataTable,
  CustomTableButton
} from '../../../styles/components/CustomDataTable';
import CustomFooter from '../../../styles/components/CustomFooter';
import HeaderTitle from '../../../components/HeaderTitle';
import { brDay } from '../functions';

const ListView = ({ onRowSelectionChange }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const dataFetched = await API.get('/auth/ms-users/profile-journey/?page=0');
    const translatedData = dataFetched.data.profileJourneyData.map(item => {
      const translatedDays = item.weekdays.map(day => brDay(day));
      return { ...item, weekdays: translatedDays };
    });

    setData(translatedData);
  };

  const weekArray = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex'];
  const weekendArray = ['Sab', 'Dom'];

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'profileName',
      label: 'Nome',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'weekdays',
      label: 'Dias',
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => {
          if (_.isEqual(value, weekArray)) {
            return <Chip label="Dias da Semana" />;
          }

          if (_.isEqual(value, weekendArray)) {
            return <Chip label="Final de semana" />;
          }

          return value.map(v => {
            return <Chip label={v} key={Math.random()} />;
          });
        }
      }
    },
    {
      name: 'startJourney',
      label: 'Inicio',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'endJourney',
      label: `Final`,
      options: {
        filter: false,
        sort: true
      }
    }
  ];

  useEffect(() => {
    setLoading(false);
    fetchData();
  }, []);

  const options = {
    pagination: true,
    viewColumns: false,
    filter: false,
    search: false,
    download: false,
    print: false,
    selectableRows: 'none',
    onRowClick: rowData => {
      // eslint-disable-next-line
      const row = data.find(d => d._id === rowData[0]);
      history.push({
        pathname: `profiles/edit/${String(rowData[0])}`,
        state: { data: row }
      });
    },
    customToolbar: () => {
      return (
        <CustomTableButton
          variant="text"
          color="primary"
          size="small"
          onClick={() => {
            history.push({ pathname: 'profiles/new' });
          }}
          startIcon={<AddIcon />}
        >
          Criar Perfil
        </CustomTableButton>
      );
    },
    onRowSelectionChange,
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: !loading
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: column =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };

  return (
    <Page title={t('i18n.profilelistview.PROFILES')}>
      <Box mt={3} px={10}>
        <CustomDataTableWrapper style={{ opacity: loading ? '0.3' : '1' }}>
          <CustomDataTable
            title={
              <Grid container direction="column" spacing={0}>
                <Grid item>
                  <HeaderTitle title={t('i18n.profilelistview.PROFILES')} />
                </Grid>
              </Grid>
            }
            data={data}
            columns={columns}
            options={options}
            selectableRowsOnClick
            className="table-clickable"
          />
        </CustomDataTableWrapper>
        <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
          <CircularProgress />
        </StyledSpinner>
      </Box>
    </Page>
  );
};

export default ListView;
