// import { createStore, combineReducers } from 'redux';
import React from 'react';
import { CircularProgress } from '@material-ui/core';

const notificationContext = {};
const historicChatContext = '';
const disconnectChatContext = false;
const closeChatContext = {};
const pulledChatContext = {};
const myClientsListContext = [];
const showVisitorsTable = false;
const showClientTalk = true;
const visitorsOnline = <CircularProgress size={8} style={{ color: 'white' }} />;
const lastMessage = {};
const clientInAttendanceNow = {};
const clientInAttendanceData = {};
const myClientInAttendanceContext = [];
const tabViewChat = false;
const connectedStateChat = 'true';
const customerReasonState = [];

const clientListReducer = (state = myClientsListContext, action) => {
  switch (action.type) {
    case 'SET_MY_CLIENTS':
      state = action.client;
      return state;
    default:
      return state;
  }
};

const clientInAttendanceReducer = (
  state = myClientInAttendanceContext,
  action
) => {
  switch (action.type) {
    case 'SET_CLIENT_NAME_EMAIL':
      state = action.clientNameEmail;
      return state;
    default:
      return state;
  }
};

const notificationReducer = (state = notificationContext, action) => {
  switch (action.type) {
    case 'SET_NOTIFICATION':
      state = action.notification;
      return state;
    default:
      return state;
  }
};

const showHistoricChatReducer = (state = historicChatContext, action) => {
  switch (action.type) {
    case 'SHOW_CHAT_HISTORIC':
      state = action.show;
      return state;
    default:
      return state;
  }
};

const disconnectChatReducer = (state = disconnectChatContext, action) => {
  switch (action.type) {
    case 'DISCONNECT_CHAT':
      state = action.disconnect;
      return state;
    default:
      return state;
  }
};

const closeChatReducer = (state = closeChatContext, action) => {
  switch (action.type) {
    case 'CLOSE_CHAT':
      state = action.chat;
      return state;
    default:
      return state;
  }
};

const pulledChatReducer = (state = pulledChatContext, action) => {
  switch (action.type) {
    case 'PULLED_CHAT':
      state = action.chat;
      return state;
    default:
      return state;
  }
};

const showVisitorsReducer = (state = showVisitorsTable, action) => {
  switch (action.type) {
    case 'SHOW_VISITORS':
      state = action.showVisitors;
      return state;
    default:
      return state;
  }
};

const showClientTalkReducer = (state = showClientTalk, action) => {
  switch (action.type) {
    case 'SHOW_CLIENT_TALK':
      state = action.showClientTalk;
      return state;
    default:
      return state;
  }
};

const visitorsOnlineReducer = (state = visitorsOnline, action) => {
  switch (action.type) {
    case 'VISITORS_ONLINE':
      state = action.visitorsOnline;
      return state;
    default:
      return state;
  }
};

const lastMessageReducer = (state = lastMessage, action) => {
  switch (action.type) {
    case 'LAST_MESSAGE':
      state = action.lastMessage;
      return state;
    default:
      return state;
  }
};

const clientInAttendanceDataReducer = (
  state = clientInAttendanceData,
  action
) => {
  switch (action.type) {
    case 'SET_CLIENT_IN_ATTENDANCE':
      state = action.clientInAttendanceData;
      return state;
    default:
      return state;
  }
};

const clientInAttendanceNowReducer = (
  state = clientInAttendanceNow,
  action
) => {
  switch (action.type) {
    case 'SET_CLIENT_IN_ATTENDANCE_NOW':
      state = action.clientInAttendanceNow;
      return state;
    default:
      return state;
  }
};

const changeTabViewChatReducer = (state = tabViewChat, action) => {
  switch (action.type) {
    case 'SET_TAB_VIEW_CHAT':
      state = action.tabViewChat;
      return state;
    default:
      return state;
  }
};

const connectedStateReducer = (state = connectedStateChat, action) => {
  if (action.type && action.type === 'SET_CONNECTED_STATE_CHAT') {
    state = action.connectedStateChat;
  }
  return state;
};

const customerReasonReducer = (state = customerReasonState, action) => {
  if (action.type && action.type === 'UPDATE_CUSTOMER_REASON') {
    const foundedCustomer = state.findIndex(
      item => item.customer === action.payload.customer
    );
    if (foundedCustomer !== -1) {
      state[foundedCustomer].reason = action.payload.reason;
      return state;
    }
    state.push(action.payload);
  }
  return state;
};

export {
  notificationReducer,
  showHistoricChatReducer,
  closeChatReducer,
  pulledChatReducer,
  disconnectChatReducer,
  clientListReducer,
  showVisitorsReducer,
  showClientTalkReducer,
  visitorsOnlineReducer,
  lastMessageReducer,
  clientInAttendanceDataReducer,
  changeTabViewChatReducer,
  clientInAttendanceNowReducer,
  clientInAttendanceReducer,
  connectedStateReducer,
  customerReasonReducer
};
