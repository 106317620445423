// import styled from 'styled-components';
import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../styles/settings/colors.config';
import {
  CustomTableFooter,
  CustomTableFooterButton,
  CustomTablePagination,
  CustomTextField
} from './styles';

const useStyles = makeStyles(() => ({
  root: {
    flexShrink: 0,
    marginLeft: 'auto',
    display: 'flex'
  },
  pageSelection: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row',
    marginBottom: '0.1em',
    marginLeft: '2em',
    marginRight: '2em'
  },
  pageInput: {
    width: '70px',
    marginLeft: '10px',
    marginRight: '10px',
    color: 'red'
  },
  tableCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 12px 0px 12px'
  }
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const previousPageDisabled = page === 0;
  const lastPageNumber = Math.ceil(count / rowsPerPage);
  const nextPageDisabled = page >= lastPageNumber - 1;

  return (
    <div className={classes.root}>
      <CustomTableFooterButton
        style={{
          color: previousPageDisabled
            ? COLORS.btnSecondaryColor
            : COLORS.greenText
        }}
        color="secondary"
        onClick={handleBackButtonClick}
        disabled={previousPageDisabled}
        startIcon={<KeyboardArrowLeft />}
        aria-label="previous page"
      >
        {t('i18n.datatable.pagination.PREVIOUS')}
      </CustomTableFooterButton>
      <div className={classes.pageSelection}>
        {t('i18n.datatable.pagination.PAGE')}
        <div className={classes.pageInput}>
          <CustomTextField
            size="small"
            color="primary"
            variant="outlined"
            value={count === 0 ? page : page + 1}
            type="number"
            inputProps={{ maxLength: 3 }}
            onClick={e => {
              e.target.select();
            }}
            onChange={event => {
              if (
                event.target.value < 1 ||
                event.target.value > lastPageNumber
              ) {
                return;
              }
              onPageChange(event, Number(event.target.value) - 1);
            }}
          />
        </div>
        {t('i18n.datatable.pagination.DISPLAYROWS')} {lastPageNumber}
      </div>
      <CustomTableFooterButton
        onClick={handleNextButtonClick}
        style={{
          color: nextPageDisabled ? COLORS.btnSecondaryColor : COLORS.greenText
        }}
        disabled={nextPageDisabled}
        endIcon={<KeyboardArrowRight />}
        aria-label="next page"
      >
        {t('i18n.datatable.pagination.NEXT')}
      </CustomTableFooterButton>
    </div>
  );
};

const PreSalePagination = ({
  count,
  textLabels,
  rowsPerPage,
  page,
  rowsPerPageOptions = [10, 20, 40],
  changePage,
  changeRowsPerPage,
  callback,
  pageVariableToTriggerPageChangeFromParentComponent = null
}) => {
  const DEBOUNCE_TIME_FETCH = 1000 * 0.7;
  const { t } = useTranslation();
  const classes = useStyles();
  const timeoutId = useRef();
  const [auxPage, setAuxPage] = useState(page);

  const handleRowChange = event => {
    changeRowsPerPage(event.target.value);
    if (callback) {
      callback(auxPage, event.target.value);
    }
  };

  const handlePageChange = (_, newPage) => {
    setAuxPage(newPage);
    if (callback) {
      callback(newPage, rowsPerPage);
    }
  };

  useEffect(() => {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      changePage(auxPage);
    }, DEBOUNCE_TIME_FETCH);
  }, [auxPage]);

  useEffect(() => {
    if (pageVariableToTriggerPageChangeFromParentComponent) {
      handlePageChange(
        null,
        pageVariableToTriggerPageChangeFromParentComponent.page
      );
    }
  }, [pageVariableToTriggerPageChangeFromParentComponent]);

  useEffect(() => {
    if (auxPage !== page) {
      setAuxPage(page);
    }
  }, [page]);

  return (
    <CustomTableFooter>
      <TableRow>
        <TableCell variant="footer" className={classes.tableCell}>
          <CustomTablePagination
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowChange}
            page={auxPage}
            labelRowsPerPage={textLabels.rowsPerPage}
            labelDisplayedRows={({ from, to }) =>
              `${t('i18n.datatable.pagination.SHOWINGOFF')}: ${from}-${to} ${
                textLabels.displayRows
              } ${count}`
            }
            rowsPerPageOptions={rowsPerPageOptions}
            ActionsComponent={TablePaginationActions}
          />
        </TableCell>
      </TableRow>
    </CustomTableFooter>
  );
};

export default PreSalePagination;
