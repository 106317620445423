import React from 'react';
import { TextField } from '@material-ui/core';

import { inputStyles } from './inputStyle';

const FilterInput = ({ form, setForm, name, label, type, disabled }) => {
  const classes = inputStyles();

  return (
    <TextField
      size="small"
      label={label}
      type={type}
      variant="outlined"
      className={classes.inputSmall}
      fullWidth
      disabled={disabled}
      value={form[name] || ''}
      name={name}
      onChange={e => {
        setForm(e);
      }}
    />
  );
};

export default FilterInput;
