import formHelper from '../../../utils/formHelper';

export const formatedFilterData = filterData => {
  const filteredList = { ...filterData };

  // Provisório enquanto a API de Pedidos para B2W apenas retorna dados para número de Pedido igual ao N. de Entrega
  // from 02-832016258 to 283201625801
  if (
    filteredList?.marketPlace.includes('B2W') &&
    filteredList?.orderId &&
    ['02-', '03-', '09-'].includes(filteredList?.orderId.substring(0, 3))
  ) {
    filteredList.orderId = formHelper.convertOrderidtoDeliveryid(
      filteredList.orderId
    );
  }

  filteredList.marketPlace =
    filterData.marketPlace.length === 0
      ? 'none'
      : filterData.marketPlace.toString();

  if (filteredList?.customerCpf) {
    filteredList.customerCpf = filteredList.customerCpf.replace(/\D/g, '');
  }

  if (filterData.magaluClaim === 'false') filteredList.magaluClaim = false;
  if (filterData.magaluClaim === 'true') filteredList.magaluClaim = true;

  filteredList.ticketType = 'AFTER_SALE';

  if (filterData.satisfactionScore === 'no-evaluation') {
    delete filteredList.satisfactionScore;
    filteredList.withSomeScore = false;
  }

  if (
    filterData.satisfactionScore &&
    filterData.satisfactionScore !== 'no-evaluation'
  ) {
    filteredList.withSomeScore = true;
  }

  if (filterData.satisfactionScore === 'false')
    filteredList.satisfactionScore = false;
  if (filterData.satisfactionScore === 'true')
    filteredList.satisfactionScore = true;

  return filteredList;
};

export const sortTableData = (tableState, sortData, setSortData) => {
  const column =
    tableState.sortOrder.name === 'sla_hoursSinceCreation'
      ? 'controlTimeSLA'
      : tableState.sortOrder.name;
  const { direction } = tableState.sortOrder;
  const newSortData = { ...sortData };
  newSortData.column = column;
  newSortData.direction = direction;
  setSortData(newSortData);
};

const ajustDate = date => {
  const currentDate = date;
  const day = currentDate?.split('-')[0];
  const month = currentDate?.split('-')[1];
  const year = currentDate?.split('-')[2];
  return `${year}-${month}-${day}`;
};

const defaultDate = date => {
  const newDate = new Date(ajustDate(date));
  return newDate;
};

export const makeStartDateDefault = date => {
  return ajustDate(date);
};

export const makeEndDateDefault = date => {
  const newDate = defaultDate(date);
  const newDay = newDate?.getDate();
  const newMonth = newDate?.getMonth() + 1;
  const newYear = newDate?.getFullYear();
  return `${newYear}-${newMonth}-${
    newDay < 10 ? `0${newDay.toString()}` : newDay
  }`;
};
