import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../../styles/settings/colors.config';
import ChatProtocolCardDataSideBySide from '../../components/ChatProtocolCardDataSideBySide.component';

const KabumCarrefourIncidents = ({
  isRealTime,
  isAdmin,
  handleIsRealTime,
  dataIndividualDash,
  dataEquipDash,
  id
}) => {
  const { t } = useTranslation();
  return (
    <ChatProtocolCardDataSideBySide
      id={id}
      isRealTime={isRealTime}
      cardType="kabumCarrefourIncidents"
      firstLabel={t('i18n.dashboard.components.individual')}
      secondaryLabel={t('i18n.dashboard.components.equip')}
      isTeamView={isAdmin}
      headerData
      showMoreDetails
      firstData={handleIsRealTime(
        dataIndividualDash?.total_incidentes,
        dataIndividualDash?.total_incidentes
      )}
      secondaryData={handleIsRealTime(
        dataEquipDash?.total_incidentes,
        dataEquipDash?.total_incidentes
      )}
      descriptionHint="Quantidade de protocolos com Incidentes abertos e fechados no período informado. Aplica-se apenas aos Marketplaces com tal característica."
      extraData={[
        {
          label: t('i18n.ticketcontainer.OPENED'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.incidentes_abertos
            : dataIndividualDash?.incidentes_abertos,
          percent: isAdmin
            ? dataEquipDash?.incidentes_abertos /
              dataEquipDash?.total_incidentes
            : dataIndividualDash?.incidentes_abertos /
              dataEquipDash?.total_incidentes,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.CLOSED_BR_PLURAL'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.incidentes_fechados
            : dataIndividualDash?.incidentes_fechados,
          percent: isAdmin
            ? dataEquipDash?.incidentes_fechados /
              dataEquipDash?.total_incidentes
            : dataIndividualDash?.incidentes_fechados /
              dataEquipDash?.total_incidentes,
          divider: false,
          show: true
        }
      ]}
    />
  );
};

export default KabumCarrefourIncidents;
