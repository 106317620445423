import jwt_decode from 'jwt-decode';
import config from '../config';
import API from '../services/api';

const getRefreshToken = () => {
  return localStorage.getItem('refreshToken');
};

const setAccessTokens = tokenPayload => {
  if (tokenPayload.idToken) {
    localStorage.setItem('accessToken', tokenPayload.idToken);
  }

  if (tokenPayload.refreshToken) {
    localStorage.setItem('refreshToken', tokenPayload.refreshToken);
  }
};

const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};

const isTokenTimeValid = () => {
  const currentAccessToken = jwt_decode(getAccessToken());
  return currentAccessToken.exp > new Date().getTime() / 1000;
};

const cleanTokens = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  sessionStorage.removeItem('dashAdmin');
};

const redirectToLogin = (redirect, linkRedirect) => {
  redirect.push({
    pathname: '/login',
    state: {
      link: linkRedirect
    }
  });
};

const removeFilters = () => {
  localStorage.clear();
  sessionStorage.clear();
};

const logout = (redirect, linkRedirect) => {
  cleanTokens();
  removeFilters();
  redirectToLogin(redirect, linkRedirect);
};

const refreshToken = async (snack, redirect, success) => {
  if (isTokenTimeValid()) {
    if (success) success();
    return;
  }
  const response = await API.post(
    `${config.cognito.authUrl}/refresh-token`,
    { refreshToken: getRefreshToken() },
    {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json'
      }
    }
  );
  setAccessTokens(response.data);
  if (success) {
    success();
  }
};

export default {
  getAccessToken,
  setAccessTokens,
  redirectToLogin,
  getRefreshToken,
  cleanTokens,
  refreshToken,
  logout
};
