import { Box, Grid, TextField } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { inputStyles } from '../../../../components/inputStyle';

const Document = ({
  filterForm,
  setFilterForm,
  handleRemoveShortcutFlag,
  maskCpfCnpj,
  handleCpfCnpjMask,
  setMaskCpfCnpj
}) => {
  const { t } = useTranslation();
  const classes = inputStyles();
  return (
    <Box mt={1}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputMask
            mask={maskCpfCnpj}
            maskChar=""
            onPaste={e => handleCpfCnpjMask(e.clipboardData.getData('Text'))}
            value={filterForm.customerCpf || ''}
            onChange={htmlElemtEvent => {
              setFilterForm({
                ...filterForm,
                customerCpf: htmlElemtEvent.target.value
              });
              handleRemoveShortcutFlag();
              if (htmlElemtEvent.target.value.length > 14) {
                setMaskCpfCnpj('99.999.999/9999-99');
              } else {
                setMaskCpfCnpj('999.999.999-999');
              }
            }}
          >
            {() => (
              <TextField
                size="small"
                className={classes.inputSmall}
                label={t('i18n.ticketslistview.DOCUMENT')}
                variant="outlined"
                fullWidth
              />
            )}
          </InputMask>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Document;
