import React from 'react';
import {
  Card,
  Tooltip,
  Box,
  Grid,
  Typography,
  CardContent
} from '@material-ui/core';

import { InfoOutlined } from '@material-ui/icons';
import styled from 'styled-components';
import { CustomHintText, InConstruction } from '../styles';
import { CardTitle } from '../styles.v2';
import { COLORS } from '../../../styles/settings/colors.config';

const RightAlignedParagraph = styled.span`
  display: flex;
  justify-content: flex-end;
  color: ${props => (props.bold ? COLORS.grayText : '#999999')};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-family: Roboto;
  text-align: right;
`;

const SalesText = ({ sales, shippments }) => {
  return (
    <>
      <RightAlignedParagraph bold>{sales} vendas</RightAlignedParagraph>
      <RightAlignedParagraph bold>{shippments} entregas</RightAlignedParagraph>
    </>
  );
};

const CardDataWithIcons = ({
  title,
  icon,
  text,
  textBold,
  descriptionHint,
  showInConstruction,
  className,
  sales,
  shippments
}) => {
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Card
        style={{
          width: '100%',
          marginBottom: '20px',
          borderRadius: '20px',
          minHeight: '165px'
        }}
        className={className}
      >
        <CardTitle>
          <Typography style={{ fontWeight: 'bold' }}>{title}</Typography>
          <Box style={{ gap: '10px' }} display="flex" justifyContent="flex-end">
            {descriptionHint && (
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                  popperOptions: {
                    positionFixed: true,
                    modifiers: {
                      preventOverflow: {
                        enabled: true,
                        boundariesElement: 'window'
                      }
                    }
                  }
                }}
                placement="top"
                arrow
                title={<CustomHintText>{descriptionHint}</CustomHintText>}
              >
                <InfoOutlined fontSize="medium" color="primary" />
              </Tooltip>
            )}
          </Box>
        </CardTitle>

        <CardContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={4}
              style={{ marginBottom: '-25px', marginTop: '-10px' }}
            >
              {icon}
            </Grid>
            <Grid
              item
              xs={8}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              {sales && (
                <>
                  <SalesText {...{ sales, shippments }} />
                </>
              )}
              {text && <RightAlignedParagraph>{text}</RightAlignedParagraph>}
              {textBold && (
                <RightAlignedParagraph bold>{textBold}</RightAlignedParagraph>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {showInConstruction && <InConstruction />}
    </div>
  );
};

export default CardDataWithIcons;
