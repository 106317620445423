import { Box, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import userService from '../../../../../../../services/user.service';
import { capitalizeFirstLetter } from '../../../functions/functions';
import {
  ActionsSection,
  StyledButton,
  TransportSectionHeader
} from '../../../Dialogs/styles';
import { useStyles } from './styles';

export default function Actions({
  tracking,
  finance,
  view,
  cancellingData,
  handleReversal,
  updateTrackingData,
  setCancellingModal,
  requestPI,
  handleFinishConference
}) {
  const [specialistDetail, setSpecialistDetail] = useState('');

  const findEligiblePI = cancellingData.stepsTimeLine.find(
    step => step.name === 'Recusado pela Logística'
  );

  const findAlreadyPI = cancellingData.stepsTimeLine.find(
    step => step.name === 'PI solicitada'
  );

  const classes = useStyles();

  return (
    <>
      {tracking && (
        <ActionsSection>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={updateTrackingData}
          >
            Atualizar dados de rastreamento
          </StyledButton>
        </ActionsSection>
      )}

      {finance && !cancellingData.isReviewedByFinancial && (
        <ActionsSection buttons>
          <StyledButton
            variant="outlined"
            color="secondary"
            onClick={() => handleReversal('no')}
          >
            Estorno não realizado
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={() => handleReversal('yes')}
          >
            Estorno realizado
          </StyledButton>
        </ActionsSection>
      )}

      {!tracking && !finance && !view && (
        <ActionsSection buttons>
          {userService.hasRole('cancellation_process_cancel') && (
            <StyledButton
              variant="outlined"
              disabled
              color="secondary"
              onClick={() => setCancellingModal(true)}
            >
              Cancelar {capitalizeFirstLetter(cancellingData?.cancellationType)}
            </StyledButton>
          )}
          {cancellingData.statusLogistics === 'RECUSADO' &&
            findEligiblePI &&
            !findAlreadyPI && (
              <StyledButton
                variant="outlined"
                disabled
                color="primary"
                onClick={() => requestPI()}
                className={classes.buttonWithMargin}
              >
                Sinalizar como PI
              </StyledButton>
            )}

          <Box display="flex" flexDirection="column" marginRight="10px">
            <TransportSectionHeader marginbottom="2px">
              Observações do especialista
            </TransportSectionHeader>
            <div>
              <TextField
                value={specialistDetail}
                size="small"
                variant="outlined"
                onChange={ev => {
                  setSpecialistDetail(ev.target.value);
                }}
                InputProps={{
                  className: classes.inputSmall100
                }}
              />
            </div>
          </Box>

          <StyledButton
            variant="contained"
            color="primary"
            onClick={() => handleFinishConference(specialistDetail)}
          >
            Liberar para{' '}
            {capitalizeFirstLetter(cancellingData?.cancellationType)}
          </StyledButton>
        </ActionsSection>
      )}
    </>
  );
}
