import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import AcerModal from '../../../../../components/AcerModal';
import API from '../../../../../services/api';

const ModalText = styled('p')`
  font-size: 16px;
  line-height: 1.5em;
`;

const ModalAlert = styled(Alert)`
  font-size: 16px;
  margin-top: 1em;
`;

const CustomTableCell = styled(TableCell)`
  background: #eee;
`;

const ModalGrid = styled(Grid)`
  margin-top: 20px;
`;

const ModalGridButton = styled(Grid)`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  margin-top: 1.5em;
`;

const ModalReleaseExchangeRefund = ({
  ticketId,
  instance,
  openState,
  closeParent
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const postData = {
    ticketId,
    instanceId: instance.id,
    actionId: instance.type === 'Troca' ? 'EXCHANGE' : 'RETURN'
  };

  const handleSubmit = () => {
    API.post(`/auth/ms-ticket/v1/tickets/b2w/release-exchange`, postData)
      .then(() => {})
      .catch(() => {
        enqueueSnackbar(t('i18n.ticket.B2W.RELEASE_EXCHANGE_FAIL'), {
          variant: 'error'
        });
      })
      .finally(() => {
        closeParent();
      });
  };

  return (
    <AcerModal
      fullWidth
      maxWidth="md"
      open={openState}
      modalTitle={
        instance.type === 'Troca'
          ? t('i18n.b2w.modal.releaseexchangerefund.TITLE_EXCHANGE')
          : t('i18n.b2w.modal.releaseexchangerefund.TITLE_REFUND')
      }
      onClose={closeParent}
    >
      <Card>
        <CardContent>
          <Grid container>
            <ModalGrid item lg={12}>
              <ModalText>
                {instance.type === 'Troca'
                  ? t(
                      'i18n.b2w.modal.releaseexchangerefund.EXCHANGE_INSTRUCTION1'
                    )
                  : t(
                      'i18n.b2w.modal.releaseexchangerefund.REFUND_INSTRUCTION1'
                    )}
              </ModalText>
              <ModalText>
                {instance.type === 'Troca'
                  ? t('i18n.b2w.modal.releaseexchangerefund.SAC_EXCHANGE')
                  : t('i18n.b2w.modal.releaseexchangerefund.SAC_REFUND')}
              </ModalText>
              <ModalAlert severity="error">
                {instance.type === 'Troca'
                  ? t('i18n.b2w.modal.releaseexchangerefund.WARNING_EXCHANGE')
                  : t('i18n.b2w.modal.releaseexchangerefund.WARNING_REFUND')}
              </ModalAlert>
            </ModalGrid>
            <ModalGrid item lg={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <ModalText>
                        {t('i18n.b2w.modal.deniedexchange.PRODUCT')}
                      </ModalText>
                    </TableCell>
                    <CustomTableCell>
                      <ModalText>
                        {t('i18n.b2w.modal.postdelivery.QUANTITY')}
                      </ModalText>
                    </CustomTableCell>
                    <CustomTableCell>
                      <ModalText>
                        {t('i18n.b2w.modal.postdelivery.VALUE')}
                      </ModalText>
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {instance?.originalItems?.map(product => (
                    <TableRow key={product.id}>
                      <TableCell>
                        <ModalText>{product.name}</ModalText>
                      </TableCell>
                      <CustomTableCell>
                        <ModalText>{product.quantity}</ModalText>
                      </CustomTableCell>
                      <CustomTableCell>
                        <ModalText>
                          {Number(product.salePrice).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          })}
                        </ModalText>
                      </CustomTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ModalGrid>
            <Grid item container direction="row" justifyContent="flex-end">
              {/* <ModalGridButton item lg={7}>
                <Button
                  variant="contained"
                  color="default"
                  onClick={closeParent}
                >
                  {t('i18n.simplewords.CANCEL')}
                </Button>
              </ModalGridButton> */}
              <ModalGridButton item lg={5}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  {instance.type === 'Troca'
                    ? t('i18n.b2w.modal.exchangerefund.ACTION_RELEASE_EXCHANGE')
                    : t('i18n.b2w.modal.exchangerefund.ACTION_RELEASE_REFUND')}
                </StyledButton>
              </ModalGridButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </AcerModal>
  );
};

export default ModalReleaseExchangeRefund;
