import moment from 'moment';
import { filterNameInStorage } from '../TicketsDashboard/config';
import {
  convertDateByHourlyV2,
  defaultDateFormat,
  proccessDateValuesForByPeriod
} from '../services/convertDate';

const formatDate = (momentDate) => {
  return moment(momentDate).format('DD/MM/YYYY HH:mm:ss');
};

const creationDateFactory = ({ startDate, endDate }) => {
  return `${startDate}-${endDate}`;
};

const monthDateFactory = (dashboardFilter) => {
  const { startDate, endDate } = proccessDateValuesForByPeriod(
    dashboardFilter.dateAliasInAWayHumanCanRead
  );
  const newDate = {};

  newDate.startDate = formatDate(moment(startDate));
  if (
    dashboardFilter.dateAliasInAWayHumanCanRead === 'in_current_month' ||
    dashboardFilter.dateAliasInAWayHumanCanRead === 'in_current_year'
  ) {
    newDate.endDate = formatDate(moment(endDate));
  } else {
    newDate.endDate = formatDate(moment(endDate));
  }

  return newDate;
};

const hourlyDateFactory = (dashboardFilter) => {
  const { startDate, endDate } = convertDateByHourlyV2(
    dashboardFilter.dateAliasInAWayHumanCanRead
  );
  const newDate = {};
  newDate.startDate = formatDate(moment(startDate));
  newDate.endDate = formatDate(moment(endDate));

  return newDate;
};

const customDateFactory = (dashboardFilter) => {
  const { startDate, endDate } = dashboardFilter;
  const newDate = {};

  newDate.startDate = formatDate(moment(startDate));
  newDate.endDate = endDate
    ? formatDate(moment(endDate))
    : formatDate(moment(new Date()));

  return newDate;
};

export const filterLabel = (key, value) => {
  if (key === 'responseTimeRelationalOperator') {
    switch (value) {
      case 'LESS_THAN_OR_EQUAL':
        return 'Menor ou igual a';
      case 'GREATER_THAN':
        return 'Maior que';
      default:
        return 'teste';
    }
  }
};

export const dataTableFilterFactory = ({
  dashboardFilter,
  attendant,
  isML
}) => {
  const type =
    attendant !== 'Equipe' && attendant !== 'Sem registro'
      ? 'individual'
      : 'equip';
  const isTeamView = !(attendant !== 'Equipe' && attendant !== 'Sem registro');
  const status = isML ? 'ANSWERED' : 'NEW';
  const newFilter = {
    ...dashboardFilter
  };

  if (attendant !== 'Equipe' && attendant !== 'Sem registro') {
    newFilter.attendant = attendant;
  }

  return {
    dashboardFilter: newFilter,
    status,
    type,
    isTeamView
  };
};

export const handleOpenNewTabv3 = (filter) => {
  const { dashboardFilter, status, type, isTeamView, cardName } = filter;

  const newFilter = {
    ticketType: 'PRE_SALE',
    orderBy: 'ASC',
    marketPlaceList: dashboardFilter.marketplace
  };

  switch (dashboardFilter.periodType) {
    case 'hourly':
      hourlyDateFactory(dashboardFilter);
      newFilter.creationDate = creationDateFactory(
        hourlyDateFactory(dashboardFilter)
      );
      break;
    case 'byperiod':
      newFilter.creationDate = creationDateFactory(
        monthDateFactory(dashboardFilter)
      );
      break;
    default:
      newFilter.creationDate = creationDateFactory(
        customDateFactory(dashboardFilter)
      );
      break;
  }

  if (status) {
    newFilter.ticketStatus = status;
  }

  if (isTeamView) {
    newFilter.serviceAttendantLogin = '';
  } else if (type === 'equip') {
    newFilter.serviceAttendantLogin = '';
  } else {
    newFilter.serviceAttendantLogin = dashboardFilter.attendant;
  }

  if (cardName === 'offlineCardList' && dashboardFilter.attendant === '') {
    delete newFilter.serviceAttendantLogin;
  }

  sessionStorage.removeItem('preSalefilterUseQueryParams');

  const queryFilter = JSON.stringify(newFilter);

  const win = window.open(`/ticketsPreSale?query=${queryFilter}`, '_blank');

  win.focus();
};

export const handleOpenNewTabPresaleOnline = (filter, isB2B) => {
  const { dashboardFilter, chartType, attendant } = filter;

  const newFilterV2 = {
    business_type: { value: isB2B ? 'B2B' : 'B2C', comparator: 'EQUALS' },
    closed_by: { value: ['ATTENDANT', 'CLIENT'], comparator: 'IN' },
    created_date: {
      value: [
        defaultDateFormat(
          moment(dashboardFilter.startDate).subtract(3, 'h').startOf('day')
        ),
        defaultDateFormat(
          moment(dashboardFilter.endDate).subtract(3, 'h').endOf('day')
        )
      ],
      comparator: 'BETWEEN'
    },
    channels: {
      value: ['CHAT_WEB', 'CHAT_MOBILE', 'WHATSAPP'],
      comparator: 'IN'
    },
    attendant_name: { value: attendant || null, comparator: 'EQUALS' }
  };

  switch (chartType) {
    case 'CHATS_NOT_ATTENDED':
      newFilterV2.attendant_to_respond = { value: null, comparator: 'EQUALS' };
      break;
    case 'FIRST_ANSWER_LESS_THAN_THIRTY_SECONDS':
      newFilterV2.response_time = {
        value: 30,
        comparator: 'LESS_THAN_OR_EQUAL'
      };
      break;
    case 'FIRST_ANSWER_MORE_THAN_THIRTY_SECONDS':
      newFilterV2.response_time = {
        value: 30,
        comparator: 'GREATER_THAN'
      };
      break;
    case 'LIKES_QUANTITY':
      newFilterV2.evaluation_score = { value: [4, 5], comparator: 'IN' };
      break;
    case 'DISLIKES_QUANTITY':
      newFilterV2.evaluation_score = { value: [1, 2, 3], comparator: 'IN' };
      break;
    default:
      newFilterV2.attendant_to_respond = {
        value: null,
        comparator: 'NOT_EQUALS'
      };
      break;
  }

  const queryFilter = JSON.stringify(newFilterV2);
  const route = isB2B ? '/chat/historico/b2b' : '/chat/historico';
  const win = window.open(`${route}?query=${queryFilter}`, '_blank');
  win.focus();
};

const handleOpenNewTabHook = ({ t, key }) => {
  const handleGetLabelSla = (label) => {
    switch (label) {
      case t('i18n.dashboard.components.sla_expired'):
        return 'expired';
      case t('i18n.dashboard.components.sla_expiries_today'):
        return 'expires';
      default:
        return '';
    }
  };

  const handleGetLabelStatus = (label) => {
    switch (label) {
      case t('i18n.ticketcontainer.NEW'):
        return 'NEW';
      case t('i18n.ticketcontainer.AWAITING'):
        return 'AWAITING';
      case t('i18n.ticketcontainer.ANSWERED'):
        return 'ANSWERED';
      case t('i18n.ticketslistview.ticketStatus.AWAITING_PARTNER'):
        return 'AWAITING_PARTNER';
      case t('i18n.ticketslistview.ticketStatus.AWAITING_CLOSED'):
        return 'AWAITING_CLOSED';
      case t('i18n.ticketcontainer.CUSTOMER_RETURN'):
        return 'CUSTOMER_RETURN';
      case t('i18n.ticketcontainer.RESOLVED'):
        return 'RESOLVED';
      case t('i18n.ticketcontainer.AWAITING_COMMERCIAL'):
        return 'AWAITING_COMMERCIAL';
      case t('i18n.ticketcontainer.AWAITING_REFUND'):
        return 'AWAITING_REFUND';
      case t('i18n.ticketcontainer.AWAITING_CLOSED'):
        return 'AWAITING_CLOSED';
      case t('i18n.ticketcontainer.AWAITING_FISCAL'):
        return 'AWAITING_FISCAL';
      case t('i18n.ticketcontainer.AWAITING_FINANCIAL'):
        return 'AWAITING_FINANCIAL';
      case t('i18n.ticketcontainer.AWAITING_LOGISTICS'):
        return 'AWAITING_LOGISTICS';
      case t('i18n.ticketcontainer.AWAITING_MARKETING'):
        return 'AWAITING_MARKETING';
      case t('i18n.ticketcontainer.AWAITING_PARTNER'):
        return 'AWAITING_PARTNER';
      case t('i18n.ticketcontainer.AWAITING_CLIENT_POST'):
        return 'AWAITING_CLIENT_POST';
      case t('i18n.ticketcontainer.AWAITING_RA'):
        return 'AWAITING_RA';
      case t('i18n.ticketcontainer.AWAITING_TI'):
        return 'AWAITING_TI';
      case t('i18n.ticketcontainer.CLOSED'):
        return 'CLOSED';
      default:
        return '';
    }
  };

  const handleOpenNewTab = (label, reasonGroup = null, reason = null) => {
    const storageFilter = JSON.parse(
      sessionStorage.getItem(filterNameInStorage)
    );
    if (key === 'byAnalyst') {
      storageFilter.attendant = label === 'Sem analista' ? '' : label;

      if (storageFilter.isRealTime) {
        storageFilter.startDate = new Date('2021-10-01 00:00:00');
        storageFilter.endDate = new Date();
        storageFilter.ticketStatus =
          'NEW,CUSTOMER_RETURN,RESOLVED,CLOSED,AWAITING,ANSWERED,AWAITING_COMMERCIAL,AWAITING_REFUND,AWAITING_CLOSED,AWAITING_FISCAL,AWAITING_FINANCIAL,AWAITING_LOGISTICS,AWAITING_MARKETING,AWAITING_PARTNER,AWAITING_CLIENT_POST,AWAITING_RA,AWAITING_TI';
      }
      storageFilter.report_type = 'byAnalyst';
    }

    if (key === 'mediation') {
      storageFilter.mediatedClosed = storageFilter.periodType !== 'realTime';
      storageFilter.mediatedOpened = true;
      if (storageFilter.attendant === '') delete storageFilter.attendant;
    }

    if (key === 'magaluClaim') {
      storageFilter.magaluClaim = true;
      if (storageFilter.attendant === '') delete storageFilter.attendant;
    }

    if (key === 'withoutReason') {
      storageFilter.withReason = 'false';
      if (storageFilter.attendant === '') delete storageFilter.attendant;
    }

    if (key === 'ticketStatus') {
      storageFilter.ticketStatus = handleGetLabelStatus(label);
      if (storageFilter.attendant === '') delete storageFilter.attendant;
    }

    if (key === 'lastStepName') {
      storageFilter.lastStepName = label;
    }

    if (key === 'sla') {
      storageFilter.sla = handleGetLabelSla(label);
      storageFilter.report_type = 'sla';
      if (storageFilter.attendant === '') delete storageFilter.attendant;
      if (label === 'N1') {
        storageFilter.reasonGroup = 'N1';
      }
      if (label === 'N2') {
        storageFilter.reasonGroup = 'N2';
      }
      if (label === 'N3') {
        storageFilter.reasonGroup = 'N3';
      }

      if (reasonGroup && reason == null) {
        storageFilter.reasonGroup = reasonGroup;
        storageFilter.attendant = label;
      }

      const getReasonNameN1 = (itemLabel, group) => {
        if (group === 'N1' && reason === true) {
          storageFilter.reasonGroup = group;
          storageFilter.reasonName = itemLabel;
        } else {
          return '';
        }
      };

      const getReasonNameN2 = (itemLabel, group) => {
        if (group === 'N2' && reason === true) {
          storageFilter.reasonGroup = group;
          storageFilter.reasonName = itemLabel;
        } else {
          return '';
        }
      };
      const getReasonNameN3 = (itemLabel, group) => {
        if (group === 'N3' && reason === true) {
          storageFilter.reasonGroup = group;
          storageFilter.reasonName = itemLabel;
        } else {
          return '';
        }
      };

      getReasonNameN1(label, reasonGroup);
      getReasonNameN2(label, reasonGroup);
      getReasonNameN3(label, reasonGroup);
    }

    if (key === 'protocolSchedulingStatus') {
      storageFilter.protocolSchedulingStatus = label;
      storageFilter.marketplace.push('CUSTOMER_GUIDE_B2B');
    }

    if (key === 'claimML') {
      storageFilter.marketplace = ['MERCADO_LIVRE'];
      storageFilter.reclamacaoMercadoLivre = 'true';
      if (storageFilter.attendant === '') delete storageFilter.attendant;
    }

    if (storageFilter.periodType === 'custom') {
      const endDate = moment(storageFilter.endDate).format(
        'YYYY-MM-DDT02:59:59'
      );
      storageFilter.endDate = endDate;
    }

    if (storageFilter.periodType === 'realTime') {
      const today = new Date();
      today.setDate(today.getDate() + 1);
      storageFilter.startDate = moment(new Date('2021-10-02')).format(
        'YYYY-MM-DDT03:00:00'
      );
      storageFilter.endDate = moment(today).format('YYYY-MM-DDT02:59:59');
    }

    if (key === 'ticketStatus') {
      storageFilter.report_type = 'ticketStatus';
      if (label === 'Abertos') {
        storageFilter.incidentOpened = true;
      }

      if (label === 'Fechados') {
        storageFilter.incidentClosed = true;
      }
    }

    if (key === 'protocolSchedulingStatus') {
      delete storageFilter.isRealTime;
      delete storageFilter.attendant;
      delete storageFilter.startDate;
      delete storageFilter.endDate;
    }

    const queryFilter = JSON.stringify(storageFilter);
    localStorage.setItem('cypress_query_filter_ticket_after_sale', queryFilter);
    const win = window.open(`/ticketsAfterSale?query=${queryFilter}`, '_blank');
    win.focus();
  };

  const handleOpenNewTabv2 = (filter, label, status, sessionStorageFilter) => {
    const storageFilter = JSON.parse(
      sessionStorage.getItem(sessionStorageFilter)
    );

    if (label === 'Mercado Livre') {
      storageFilter.marketplace = storageFilter.marketplace.filter((item) => {
        return item === 'ALL' || item === 'MERCADO_LIVRE';
      });
    }

    if (label === 'Acer Store B2C') {
      storageFilter.marketplace = storageFilter.marketplace.filter((item) => {
        return item === 'ALL' || item === 'CUSTOMER_GUIDE';
      });
    }

    storageFilter.attendant = filter.attendant;
    storageFilter.ticketStatus = status; // CONVERT TO REAL TICKET STATUS

    const queryFilter = JSON.stringify(storageFilter);

    const win = window.open(`/ticketsAfterSale?query=${queryFilter}`, '_blank');
    win.focus();
  };

  return {
    handleOpenNewTab,
    handleOpenNewTabv2
  };
};

export default handleOpenNewTabHook;
