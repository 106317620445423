/*eslint-disable */
import React from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import moment from 'moment';
// import { Delete, LockOpen } from '@material-ui/icons';
import { LockOpen } from '@material-ui/icons';
import CustomFooter from '../../../styles/components/CustomFooter';
import { CustomRow } from './datatable.custom.components';
import { CustomLink } from '../../../components/Custom.components';

import styled from 'styled-components';
import { COLORS } from '../../../styles/settings/colors.config';

import {
  UnBlockButton,
  UnBlockModal
} from '../NewChatV2/components/UnBlockUser.component';

export const mockData = [
  {
    ip: '191.8.20.85',
    hashConnection: 'abc111',
    isBlocked: false,
    businessType: 'B2B',
    clientName: 'mm1',
    clientEmail: 'm@m1.com',
    blockedByUser: 'at1',
    blockedReason: 'Ofensivo',
    blockedDate: '2023-02-05T10:54:00',
    unblockedByUser: 'at2',
    unblockedReason: 'Bloqueio indevido',
    unblockedDate: '2023-02-06T16:54:00',
    blockedLogging: [
      {
        ip: '191.8.20.85',
        hashConnection: 'abc111',
        isBlocked: true,
        businessType: 'B2B',
        clientName: 'mm1',
        clientEmail: 'm@m1.com',
        blockedByUser: 'at1',
        blockedReason: 'Ofensivo',
        blockedDate: '2023-02-05T10:54:00',
        unblockedByUser: '',
        unblockedReason: '',
        unblockedDate: '',
        blockedLogging: []
      },
      {
        ip: '191.8.20.85',
        hashConnection: 'abc111',
        isBlocked: true,
        businessType: 'B2B',
        clientName: 'mm1',
        clientEmail: 'm@m1.com',
        blockedByUser: 'at1',
        blockedReason: 'Ofensivo',
        blockedDate: '2023-02-05T10:54:00',
        unblockedByUser: '',
        unblockedReason: '',
        unblockedDate: '',
        blockedLogging: []
      },
      {
        ip: '191.8.20.85',
        hashConnection: 'abc111',
        isBlocked: true,
        businessType: 'B2B',
        clientName: 'mm1',
        clientEmail: 'm@m1.com',
        blockedByUser: 'at1',
        blockedReason: 'Ofensivo',
        blockedDate: '2023-02-05T10:54:00',
        unblockedByUser: '',
        unblockedReason: '',
        unblockedDate: '',
        blockedLogging: []
      },
      {
        ip: '191.8.20.85',
        hashConnection: 'abc111',
        isBlocked: true,
        businessType: 'B2B',
        clientName: 'mm1',
        clientEmail: 'm@m1.com',
        blockedByUser: 'at1',
        blockedReason: 'Ofensivo',
        blockedDate: '2023-02-05T10:54:00',
        unblockedByUser: '',
        unblockedReason: '',
        unblockedDate: '',
        blockedLogging: []
      }
    ]
  },
  {
    ip: '999.999.9.99',
    hashConnection: 'abc111',
    isBlocked: false,
    businessType: 'B2B',
    clientName: 'mm1',
    clientEmail: 'm@m1.com',
    blockedByUser: 'at1',
    blockedReason: 'Ofensivo',
    blockedDate: '2023-02-05T10:54:00',
    unblockedByUser: 'at2',
    unblockedReason: 'Bloqueio indevido',
    unblockedDate: '2023-02-06T16:54:00',
    blockedLogging: [
      {
        ip: '999.999.9.99',
        hashConnection: 'abc111',
        isBlocked: true,
        businessType: 'B2B',
        clientName: 'mm1',
        clientEmail: 'm@m1.com',
        blockedByUser: 'at1',
        blockedReason: 'Ofensivo',
        blockedDate: '2023-02-05T10:54:00',
        unblockedByUser: '',
        unblockedReason: '',
        unblockedDate: '',
        blockedLogging: []
      },
      {
        ip: '999.999.9.99',
        hashConnection: 'abc111',
        isBlocked: true,
        businessType: 'B2B',
        clientName: 'mm1',
        clientEmail: 'm@m1.com',
        blockedByUser: 'at1',
        blockedReason: 'Ofensivo',
        blockedDate: '2023-02-05T10:54:00',
        unblockedByUser: '',
        unblockedReason: '',
        unblockedDate: '',
        blockedLogging: []
      },
      {
        ip: '999.999.9.99',
        hashConnection: 'abc111',
        isBlocked: true,
        businessType: 'B2B',
        clientName: 'mm1',
        clientEmail: 'm@m1.com',
        blockedByUser: 'at1',
        blockedReason: 'Ofensivo',
        blockedDate: '2023-02-05T10:54:00',
        unblockedByUser: '',
        unblockedReason: '',
        unblockedDate: '',
        blockedLogging: []
      },
      {
        ip: '999.999.9.99',
        hashConnection: 'abc111',
        isBlocked: true,
        businessType: 'B2B',
        clientName: 'mm1',
        clientEmail: 'm@m1.com',
        blockedByUser: 'at1',
        blockedReason: 'Ofensivo',
        blockedDate: '2023-02-05T10:54:00',
        unblockedByUser: '',
        unblockedReason: '',
        unblockedDate: '',
        blockedLogging: []
      }
    ]
  },
  {
    ip: '191.8.20.85',
    hashConnection: 'abc111',
    isBlocked: true,
    businessType: 'B2B',
    clientName: 'mm1',
    clientEmail: 'm@m1.com',
    blockedByUser: 'at1',
    blockedReason: 'Ofensivo',
    blockedDate: '2023-02-05T10:54:00',
    unblockedByUser: '',
    unblockedReason: '',
    unblockedDate: '',
    blockedLogging: []
  }
];

export const ColumnsConfig = ({ t, data, setOpenModal, setUser }) => {
  return [
    {
      name: 'businessType',
      label: t('i18n.chat.BUSINESS_TYPE'),
      options: {
        filter: false,
        sort: false,
        display: true
      }
    },
    {
      name: 'ip',
      label: 'IP',
      options: {
        filter: false,
        sort: false,
        display: true
      }
    },
    {
      name: 'clientName',
      label: 'Nome do cliente',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, dataIndex) => {
          const { rowData } = dataIndex;
          return (
            <CustomLink
              to={`/chat-container/${rowData[1]}`}
              color="primary"
              target="_blank"
            >
              {value}
            </CustomLink>
          );
        }
      }
    },
    {
      name: 'clientEmail',
      label: 'E-mail do cliente',
      options: {
        filter: false,
        sort: false,
        display: true
      }
    },
    {
      name: 'blockedByUser',
      label: 'Bloqueado por',
      options: {
        filter: false,
        sort: false,
        display: true
      }
    },
    {
      name: 'blockedReason',
      label: 'Motivo do bloqueio',
      options: {
        filter: false,
        sort: false,
        display: true
      }
    },
    {
      name: 'blockedDate',
      label: 'Data do bloqueio',
      options: {
        customBodyRender: value => {
          return moment(value).format('DD/MM/YYYY HH:mm');
        },
        filter: false,
        sort: false
      }
    },
    {
      name: 'unblockedByUser',
      label: 'Desbloqueado por',
      options: {
        filter: false,
        sort: false,
        display: true
      }
    },
    {
      name: 'unblockedReason',
      label: 'Motivo do desbloqueio',
      options: {
        filter: false,
        sort: false,
        display: true
      }
    },
    {
      name: 'unblockedDate',
      label: 'Data do desbloqueio',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return moment(value)
            .utcOffset('-0600')
            .format('DD/MM/YYYY HH:mm');
        }
      }
    },
    {
      name: 'actions',
      label: t('i18n.chat.UNBLOCK_IP'),
      options: {
        customBodyRenderLite: dataIndex => {
          const rowData = data[dataIndex];

          return <UnBlockButton {...{ setOpenModal, setUser, rowData }} />;
        },
        filter: false,
        sort: false,
        display: true
      }
    },
    {
      name: 'actionsArrow',
      label: 'Ações',
      options: {
        customBodyRenderLite: dataIndex => {
          const rowData = data[dataIndex];

          return <UnBlockButton {...{ setOpenModal, setUser, rowData }} />;
        },
        filter: false,
        sort: false,
        display: true
      }
    }
  ];
};

export const OptionsConfig = ({
  t,
  loading,
  data,
  currentPage,
  totalElements,
  size
}) => {
  return {
    page: currentPage,
    rowsPerPage: size,
    count: totalElements,
    selectableRows: 'none',
    serverSide: false,
    pagination: true,
    download: false,
    print: false,
    filter: false,
    search: false,
    viewColumns: false,
    customRowRender: (rowData, dataIndex) => (
      <CustomRow key={Math.random()} {...{ rowData, dataIndex, data }} />
    ),
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: loading
          ? t('i18n.datatable.body.NOMATCH_LOADING')
          : t('i18n.datatable.body.NOMATCH'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: column =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };
};
