import React from 'react';
import { useTranslation } from 'react-i18next';
import ProtocolCardDataWithDrillDown from '../../components/ProtocolCardDataWithDrillDown.component';

const ClaimMagaluCard = ({
  isAdmin,
  dataEquipDash,
  dataIndividualDash,
  hintByMarketplace
}) => {
  const { t } = useTranslation();
  return (
    <ProtocolCardDataWithDrillDown
      title="magaluClaim"
      showMoreDetails={false}
      isTeamView={isAdmin}
      firstLabel={t('i18n.dashboard.components.individual')}
      secondaryLabel={t('i18n.dashboard.components.equip')}
      secondaryData={dataEquipDash?.reclamacao_magalu}
      firstData={dataIndividualDash?.reclamacao_magalu}
      descriptionHint={hintByMarketplace('magalu')}
    />
  );
};

export default ClaimMagaluCard;
