import {
  Box,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from '@material-ui/core';
import React from 'react';
import { StyledDialog } from './styles';

const OpenConfirm = ({
  open,
  setOpen,
  handleCancelling,
  cancellationType,
  cancelAll,
  dataOrder,
  totalRefund
}) => (
  <StyledDialog
    open={open}
    PaperProps={{
      style: { borderRadius: 20, width: '400px' }
    }}
  >
    <DialogTitle className="acer-modal-header">
      Confirmação de solicitação
    </DialogTitle>
    <DialogContent>
      <DialogContentText className="text">
        Deseja realmente{' '}
        <b>
          solicitar o <span className="orangeText">cancelamento</span> com{' '}
          {cancellationType?.toLowerCase()}{' '}
          <span className="orangeText">
            {cancelAll === 'SIM' ? 'total' : 'parcial'}
          </span>
        </b>{' '}
        do <b>pedido</b> no.{' '}
        <span className="blueText">{dataOrder?.idPedMarket} </span>
        {cancellationType === 'ESTORNO' ? (
          <>
            no <b>valor</b> de <br />
            <span className="blueText">R$ {totalRefund}</span>?
          </>
        ) : (
          '?'
        )}
      </DialogContentText>
      <Divider />
      <Box display="flex" justifyContent="space-between" marginTop="20px">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setOpen(false)}
        >
          NÃO
        </Button>
        <Button variant="contained" color="primary" onClick={handleCancelling}>
          SIM
        </Button>
      </Box>
    </DialogContent>
  </StyledDialog>
);

export default OpenConfirm;
