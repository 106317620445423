import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
  Box
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import indexHook from '../indexHook';
import {
  FilterChipDescribe,
  FilterHeaderDescribe
} from '../components/filterChipDescribe.component';

import {
  RemoveFilterCardButton,
  cardStyle,
  FilterCardHeader,
  CustomTypography,
  CustomTypographyDescribe,
  typographyStyle
} from '../../../styles/components/Tags';

import { COLORS } from '../../../styles/settings/colors.config';

const Header = ({ dashboardFilter, setDashboardFilter, dataEquipDash }) => {
  const { t, isAdmin } = indexHook();

  const chipsList = Object.keys(dashboardFilter).filter(item => {
    return (
      item !== 'page' &&
      item !== 'size' &&
      item !== 'periodType' &&
      item !== 'startDate' &&
      item !== 'endDate' &&
      item !== 'ticket_type' &&
      item !== 'attendant' &&
      item !== 'marketplace' &&
      item !== 'dateAliasInAWayHumanCanRead' &&
      item !== 'tabView' &&
      item !== 'isRealTime'
    );
  });

  const FilteredByTitle = () => {
    if (
      (dashboardFilter.tabView === 'dashboardTab' &&
        (dashboardFilter.status !== '' || dashboardFilter.attendant !== '')) ||
      (dashboardFilter.tabView === 'chatDashboardTab' &&
        dashboardFilter.attendant !== '') ||
      (dashboardFilter.tabView === 'reportDashboardTab' &&
        dashboardFilter.report_type)
    ) {
      return (
        <CustomTypography variant="h5">
          {t('i18n.dashboard.components.filtered')}:
        </CustomTypography>
      );
    }
    return <></>;
  };

  const removeFilter = filterKey => {
    const changes = {};
    changes[filterKey] = '';
    const newFilter = { ...dashboardFilter, ...changes };
    setDashboardFilter(newFilter);
  };

  const cloneDate = Object.values(dataEquipDash ?? {})[0]?.clone_date;

  return (
    <Grid container spacing={5}>
      <Grid item>
        <CustomTypography variant="h5">
          {t('i18n.dashboard.components.viewed_by')}:
        </CustomTypography>
        <Box mt={1}>
          <CustomTypography
            variant="h3"
            style={{ color: COLORS.WarningColorSLA, fontSize: '20px' }}
          >
            <FilterHeaderDescribe
              filterKey={dashboardFilter.periodType}
              value={dashboardFilter.dateAliasInAWayHumanCanRead}
              startDate={dashboardFilter.startDate}
              endDate={dashboardFilter.endDate}
            />
          </CustomTypography>
          {cloneDate && (
            <Typography
              variant="h6"
              style={{
                color: COLORS.orange,
                fontSize: '16px',
                marginTop: '8px',
                fontWeight: '400'
              }}
            >
              Plataforma com instabilidade. Dados exibidos é referente ao dia{' '}
              {moment(cloneDate).format('DD/MM/yyyy')}.{' '}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item>
        <Box mb={1}>{FilteredByTitle()}</Box>
        <Grid container spacing={2}>
          {chipsList.map(
            key =>
              dashboardFilter[key] !== '' && (
                <Grid key={key} item>
                  <Card
                    style={{
                      borderRadius: '10px'
                    }}
                  >
                    <CardContent style={cardStyle}>
                      <FilterCardHeader>
                        <Typography variant="overline" style={typographyStyle}>
                          {t(`i18n.dashboard.filterHeader.${key}`)}
                        </Typography>
                        <Tooltip title={t('i18n.dashboard.REMOVE_FILTER')}>
                          <RemoveFilterCardButton
                            disabled={!isAdmin && key === 'attendant'}
                            aria-label="delete"
                            onClick={() => {
                              removeFilter(key);
                            }}
                            color="primary"
                          >
                            <CloseIcon />
                          </RemoveFilterCardButton>
                        </Tooltip>
                      </FilterCardHeader>
                      <CustomTypographyDescribe>
                        <FilterChipDescribe
                          filterKey={key}
                          value={dashboardFilter[key]}
                        />
                      </CustomTypographyDescribe>
                    </CardContent>
                  </Card>
                </Grid>
              )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
