import moment from 'moment';

/**
 * This function will format the informed
 * date, adding the correct timezone
 * for the currently user.
 * @param {*} date Date without formataion
 * @param {*} format The wanted format output date, default value is "DD/MM/YYYY HH:mm"
 */
const timezoneFormat = (date, format = 'DD/MM/YYYY HH:mm') => {
  const userTimeZone = new Date().getTimezoneOffset();

  return moment
    .utc(date)
    .utcOffset(userTimeZone * -1)
    .format(format);
};

export const utcFormat = (date, format) => {
  return moment.utc(date).format(format);
};

export const utcFormatEndDate = (date, format) => {
  return moment
    .utc(date)
    .add({ day: 1 })
    .format(format);
};

export const brFormat = (date, format) => {
  return moment(date)
    .subtract({ hours: 3 })
    .format(format);
};

export const brFormatEndDate = (date, format) => {
  return moment(date)
    .subtract({ days: 1 })
    .format(format);
};

export default timezoneFormat;
