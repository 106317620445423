import {
  Box,
  CircularProgress,
  Container,
  Tooltip,
  IconButton,
  Card,
  CardContent,
  Typography,
  Grid
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Lock, LockOpen } from '@material-ui/icons';
import filterHelper from '../../../utils/filterHelper';
import config from '../../../config';
import userService from '../../../services/user.service';
import formHelper from '../../../utils/formHelper';
import { StyledSpinner } from '../../../styles/components/SpinnerDefault';
import FetchContentError from '../../../components/FetchContentError';
import Page from '../../../components/Page';
import API from '../../../services/api';
import {
  CustomDataTableWrapper,
  CustomDataTable,
  CustomTableButton
} from '../../../styles/components/CustomDataTable';
import CustomFooter from '../../../styles/components/CustomFooter';
import UsersFilter from './UsersFilter';
import {
  RemoveFilterCardButton,
  cardStyle,
  FilterCardHeader,
  CustomTypographyDescribe,
  typographyStyle
} from '../../../styles/components/Tags';
import HeaderTitle from '../../../components/HeaderTitle';

const FilterChip = ({
  itemKey,
  index,
  filterData,
  removeFilterFromChip,
  filterFormData
}) => {
  const { t } = useTranslation();

  return (
    <Box key={index} item="true">
      <Card
        style={{
          borderRadius: '10px'
        }}
      >
        <CardContent style={cardStyle}>
          <FilterCardHeader>
            <Typography variant="overline" style={typographyStyle}>
              {t(filterFormData[itemKey]?.label)}
            </Typography>
            <Tooltip title={t('i18n.dashboard.REMOVE_FILTER')}>
              <RemoveFilterCardButton
                color="primary"
                aria-label="delete"
                onClick={() => {
                  removeFilterFromChip(itemKey);
                }}
              >
                <CloseIcon />
              </RemoveFilterCardButton>
            </Tooltip>
          </FilterCardHeader>
          <CustomTypographyDescribe>
            {filterHelper.filterChipLabeler(
              itemKey,
              filterData[itemKey],
              t,
              true
            )}
          </CustomTypographyDescribe>
        </CardContent>
      </Card>
    </Box>
  );
};

const UsersListView = ({ onRowSelectionChange }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [hasFetchError, setFetchError] = useState(false);
  const [removedChip, setRemovedChip] = useState('');
  const [companyList, setCompanyList] = useState([]);
  const companyFromToken = userService.getCompanyName();
  const [filterData, setFilterData] = useState({
    companyName: ''
  });

  const filterFormData = {
    companyName: { value: '', label: 'i18n.companies.NAME' }
  };

  const removeFilterFromChip = key => {
    setFilterData({ ...filterData, [key]: '' });
    setRemovedChip(key);
  };

  const columns = [
    {
      name: 'name',
      label: t('i18n.usercontainer.LOGIN'),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'firstName',
      label: t('i18n.usercontainer.NAME'),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'lastName',
      label: t('i18n.usercontainer.LASTNAME'),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'email',
      label: t('i18n.userlistview.EMAIL'),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'profileName',
      label: t('i18n.usercontainer.PROFILE_NAME'),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'enabled',
      label: `${t('i18n.userlistview.ACTIVE')}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => {
          if (value) {
            return t('i18n.groupcontainer.ACTIVE');
          }
          return t('i18n.ticketslistview.ticketStatus.INACTIVE');
        }
      }
    },
    /* {
      name: 'userCreateDate',
      label: t('i18n.userlistview.CREATION_DATE'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => {
          return moment(value).format('DD/MM/YYYY HH:mm');
        }
      }
    },
    {
      name: 'userLastModifiedDate',
      label: t('i18n.userlistview.LAST_MODIFICATION'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => {
          return moment(value).format('DD/MM/YYYY HH:mm');
        }
      }
    } */
    {
      name: 'userType',
      label: t('i18n.ticketcontainer.TYPE'),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'companyName',
      label: t('i18n.companies.COMPANY'),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'nickname',
      label: t('i18n.usercontainer.NICKNAME'),
      options: {
        filter: false,
        sort: true
      }
    }
  ];

  const findRow = (id, data) => {
    const len = data.length;
    for (let i = 0; i < len; i++) {
      if (data[i].name === id) {
        return data[i];
      }
    }
  };

  const [data, setData] = useState([]);

  function compareCompanyAndName(a, b) {
    const companyA = a.companyName;
    const companyB = b.companyName;
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (companyA === companyB) {
      return nameA < nameB ? -1 : 1;
    }
    return companyA < companyB ? -1 : 1;
  }

  const refreshTable = () => {
    let endpoint = `/auth/ms-users/users`;
    if (filterData.companyName) {
      endpoint += `/findAllUsersByCompany/${filterData.companyName}`;
    }
    setLoading(true);
    API.get(endpoint)
      .then(response => {
        const users = response.data;
        const sorted = users.sort(compareCompanyAndName);
        const mapped = sorted.map(user => {
          return {
            ...user,
            phone_number: user?.phone_number?.replace('+55', '') || ''
          };
        });
        setData(mapped);
        setLoading(false);
        setFetchError(false);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
    if (companyFromToken === config.cognito.ownerCompany) {
      API.post(`/auth/ms-ticket/company/list`)
        .then(response => {
          const companies = response.data._embedded.companyResourceList;
          const sorted = companies.sort(formHelper.compareName);
          setCompanyList(sorted);
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    refreshTable();
  }, [filterData]);

  if (hasFetchError) {
    return <FetchContentError fetch={refreshTable} />;
  }

  const options = {
    pagination: true,
    filterType: 'checkbox',
    viewColumns: false,
    filter: false,
    search: false,
    download: false,
    print: false,
    onRowClick: rowData => {
      const row = findRow(rowData[0], data);
      history.push({
        pathname: `users/${String(rowData[0])}`,
        state: { data: row }
      });
    },
    onRowsDelete: deleteRow => {
      const keys = Object.keys(deleteRow.lookup).map(s => {
        return parseInt(s, 10);
      });
      const users = keys.map(k => {
        return { name: data[k].name };
      });
      API.post(`/auth/ms-users/users/delete`, users)
        .then(() => {})
        .catch(() => {});
    },
    customToolbar: () => {
      return (
        <>
          <CustomTableButton
            variant="text"
            color="primary"
            size="small"
            onClick={() => {
              history.push({ pathname: 'groups' });
            }}
          >
            {t('i18n.userlistview.GROUPS')}
          </CustomTableButton>
          <>
            <IconButton disabled color="secondary">
              |
            </IconButton>
            <CustomTableButton
              variant="text"
              color="primary"
              size="small"
              onClick={() => {
                history.push({ pathname: 'user-container/new' });
              }}
              startIcon={<AddIcon />}
            >
              {t('i18n.userlistview.CREATE_USER')}
            </CustomTableButton>
          </>
        </>
      );
    },
    customToolbarSelect: selectedRows => {
      const keys = Object.keys(selectedRows.lookup).map(s => {
        return parseInt(s, 10);
      });
      const users = keys.map(k => {
        return { name: data[k].name };
      });

      const handleDelete = async () => {
        try {
          await API.post(`/auth/ms-users/users/delete`, users);
          refreshTable();
        } catch {
          enqueueSnackbar(t('i18n.userlistview.IMPOSSIBLE_TO_DELETE_USERS'), {
            variant: 'error'
          });
        }
      };

      const handleDisable = async () => {
        try {
          await API.post(`/auth/ms-users/users/disable`, users);
          refreshTable();
        } catch {
          enqueueSnackbar(t('i18n.userlistview.IMPOSSIBLE_TO_DISABLE_USERS'), {
            variant: 'error'
          });
        }
      };

      const handleEnable = async () => {
        try {
          await API.post(`/auth/ms-users/users/enable`, users);
          refreshTable();
        } catch {
          enqueueSnackbar(t('i18n.userlistview.IMPOSSIBLE_TO_ENABLE_USERS'), {
            variant: 'error'
          });
        }
      };

      return (
        <>
          <div>
            <Tooltip title={t('i18n.userlistview.ENABLE_USER')}>
              <IconButton onClick={() => handleEnable()}>
                <LockOpen />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('i18n.userlistview.DISABLE_USER')}>
              <IconButton onClick={() => handleDisable()}>
                <Lock />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('i18n.userlistview.DELETE_USER')}>
              <IconButton onClick={() => handleDelete()}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </>
      );
    },
    onRowSelectionChange,
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: !loading
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: column =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };

  return (
    <>
      <Page title={t('i18n.userlistview.USERS')}>
        {companyFromToken === config.cognito.ownerCompany && (
          <UsersFilter
            {...{ setFilterData, removedChip, setRemovedChip, companyList }}
          />
        )}
        <Container maxWidth={false}>
          <Box
            mt={3}
            ml={companyFromToken === config.cognito.ownerCompany ? 7 : 1}
          >
            <CustomDataTableWrapper style={{ opacity: loading ? '0.3' : '1' }}>
              <CustomDataTable
                title={
                  <Grid container direction="column" spacing={0}>
                    <Grid item>
                      <HeaderTitle title={t('i18n.userlistview.USERS')} />
                    </Grid>
                    <Grid item>
                      <Box display="flex" mb={2} mt={3} style={{ gap: '15px' }}>
                        {Object.entries(filterData).map(
                          ([key], index) =>
                            key !== 'status' &&
                            filterData[key] !== '' && (
                              <>
                                <FilterChip
                                  {...{ index }}
                                  itemKey={key}
                                  key={Math.random()}
                                  filterData={filterData}
                                  removeFilterFromChip={removeFilterFromChip}
                                  filterFormData={filterFormData}
                                />
                              </>
                            )
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                }
                data={data}
                columns={columns}
                options={options}
                selectableRowsOnClick
                className="table-clickable"
              />
            </CustomDataTableWrapper>
            <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
              <CircularProgress />
            </StyledSpinner>
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default UsersListView;
