/* eslint-disable react/no-array-index-key */
import {
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AcerModal from '../../../../../components/AcerModal';

const ModerationInfo = ({ moderationInfo, openState, closeParent }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(openState);

  const handleClose = () => {
    closeParent();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(openState);
  }, [openState]);

  return (
    <AcerModal
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={handleClose}
      modalTitle={t('i18n.reclame_aqui.phrases.moderation_status')}
      hasAction
      confirmActionFunc={handleClose}
      confirmActionLabel={t('i18n.simplewords.CLOSE')}
    >
      <Card>
        <CardContent>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow key={1}>
                  <TableCell align="left" component="th" scope="row">
                    {t(
                      'i18n.reclame_aqui.modal.moderation_information.LABEL.REASON'
                    )}
                  </TableCell>
                  <TableCell align="left">{moderationInfo?.reason}</TableCell>
                </TableRow>
                <TableRow key={1}>
                  <TableCell align="left" component="th" scope="row">
                    {t(
                      'i18n.reclame_aqui.modal.moderation_information.LABEL.REQUEST_DATE'
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {moderationInfo?.request_date}
                  </TableCell>
                </TableRow>
                <TableRow key={1}>
                  <TableCell align="left" component="th" scope="row">
                    {t(
                      'i18n.reclame_aqui.modal.moderation_information.LABEL.RESPONSE_DATE'
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {moderationInfo?.response_date}
                  </TableCell>
                </TableRow>
                <TableRow key={1}>
                  <TableCell align="left" component="th" scope="row">
                    {t(
                      'i18n.reclame_aqui.modal.moderation_information.LABEL.STATUS'
                    )}
                  </TableCell>
                  <TableCell align="left">{moderationInfo?.status}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </AcerModal>
  );
};

export default ModerationInfo;
