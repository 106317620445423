import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { COLORS } from '../../../../../styles/settings/colors.config';
import userService from '../../../../../services/user.service';
import { CustomTypographLink } from '../../../styles.v2';
import { handleOpenNewTabPresaleOnline } from '../../handleGetTabFilterHook';

const TableValueLabel = styled.span`
  font-size: 24px;
  color: ${COLORS.grayText};
  font-weight: ${(props) => props.weight || 'bold'};
`;

const TableValuePercentLabel = styled.span`
  font-size: 12px;
  color: ${COLORS.meduimGray};
  font-weight: bold;
`;

const TableValue = ({
  value,
  percent,
  color,
  dashboardFilter,
  attendant: login,
  chartType,
  isB2B
}) => {
  const attendant =
    login !== 'Sem Registro' && login !== 'Equipe' ? login : undefined;

  const isAdmin = userService.hasGroup('admin');
  const withDrillDown = () => {
    if (!isAdmin && login === 'Sem Registro') {
      return false;
    }

    if (!isAdmin && login === 'Equipe') {
      return false;
    }

    if (login !== 'Sem Registro' && login !== 'Equipe') {
      return true;
    }

    return !!isAdmin;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} style={{ display: 'flex' }}>
        <div
          style={{ width: '20px', height: '20px', backgroundColor: color }}
        />
      </Grid>

      <Grid
        item
        xs={8}
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'column'
        }}
      >
        {withDrillDown() ? (
          <CustomTypographLink
            fontWeight="500"
            onClick={() => {
              handleOpenNewTabPresaleOnline(
                {
                  dashboardFilter,
                  attendant,
                  chartType
                },
                isB2B
              );
            }}
          >
            {value}
          </CustomTypographLink>
        ) : (
          <TableValueLabel weight="500">{value}</TableValueLabel>
        )}
        <TableValuePercentLabel>{Math.round(percent)}%</TableValuePercentLabel>
      </Grid>
    </Grid>
  );
};

export default TableValue;
