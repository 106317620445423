import {
  CircularProgress,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { StyledSpinner } from '../../../../../../styles/components/SpinnerDefault';
import Page from '../../../../../../components/Page';
import API from '../../../../../../services/api';
import Header from './Components/Header';
import MainDetails from './Components/MainDetails';
import ItemDetails from './Components/ItemDetails';
import FinalReview from './Components/FinalReview';
import ActionButtons from './Components/ActionButtons';
import CustomerDetails from './Components/CustomerDetails';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const fetchLogisticsDetails = async id => {
  const { data } = await API.get(
    `/auth/ms-ticket/v1/cancellationProcess/findById/${id}`
  );
  return data;
};

const cleanItens = itens => {
  const copyItens = [...itens];
  const newItens = copyItens?.map(item => {
    delete item.valueToCancel;
    delete item.date;
    delete item.detailLogistics;
    delete item.detailSe;
    delete item.id;
    delete item.quantityToCancel;
    delete item.serialNumber;
    return { ...item };
  });
  return newItens;
};

export default function ServiceDetail(props) {
  const [details, setDetails] = useState({});
  const [cleanedDetails, setCleanedDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [serviceReview, setServiceReview] = useState('');
  const [orderNumber, setOrderNumber] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const fetchData = async () => {
    setIsLoading(true);
    const { match } = props;
    const result = await fetchLogisticsDetails(match.params.id);
    const cleanedResult = JSON.parse(JSON.stringify(result));
    setDetails(result);

    const newDetails = {
      ...cleanedResult,
      itensToCancel: cleanItens(cleanedResult.itensToCancel)
    };
    setCleanedDetails(newDetails);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateData = async () => {
    setIsLoading(true);
    let serialNumberNotFilled = 0;

    details.itensToCancel.forEach(item => {
      if (!item.serialNumber) {
        serialNumberNotFilled += 1;
      }
    });

    if (serialNumberNotFilled > 0 || !serviceReview) {
      enqueueSnackbar('Preencha todos os campos obrigatórios', {
        variant: 'error'
      });
      setIsLoading(false);
      return false;
    }

    const json = {
      ...details,
      statusService: serviceReview.toUpperCase(),
      newOrderNumber: orderNumber,
      dateReviewedByService: new Date(),
      isReviewedByService: true,
      lastStepName: 'Troca liberada pela Service'
    };

    const result = await API.put(
      `/auth/ms-ticket/v1/cancellationProcess/update`,
      json
    );

    if (!result.data.id) {
      enqueueSnackbar('Erro ao atualizar item.', {
        variant: 'error'
      });
      setIsLoading(false);
      return false;
    }

    enqueueSnackbar('Item atualizado.', {
      variant: 'success'
    });

    setTimeout(() => {
      window.location.href = '/cancelling/service';
    }, 2000);
  };

  const classes = useStyles();
  return (
    <Page className={classes.root} title="Service | Detalhes da Troca">
      <Header />
      {isLoading && (
        <StyledSpinner style={{ display: isLoading ? 'block' : 'none' }}>
          <CircularProgress />
        </StyledSpinner>
      )}

      <Container maxWidth="xl">
        <Grid
          container
          style={{ opacity: isLoading ? '0.3' : '1' }}
          spacing={1}
        >
          <Grid item xs={9}>
            <MainDetails {...{ details }} />
            <ItemDetails {...{ details, setDetails, cleanedDetails }} />
          </Grid>
          <Grid item xs={3}>
            <CustomerDetails {...{ details }} />
            <FinalReview
              {...{
                details,
                serviceReview,
                setServiceReview,
                orderNumber,
                setOrderNumber
              }}
            />
          </Grid>
        </Grid>
        <ActionButtons {...{ details, updateData }} />
      </Container>
    </Page>
  );
}
