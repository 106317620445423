import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { COLORS } from '../styles/settings/colors.config';

export const CustomTypography = styled(Typography)`
  color: ${COLORS.grayText};
  font-size: 12px !important;
  max-width: 100% !important;
`;

export const CustomLink = styled(Link)`
  font-size: 12px;
  color: ${COLORS.greenText};
`;
