import i18n from '../i18n';

function mlOrderStatusTranslate(label) {
  switch (String(label).toLowerCase()) {
    case 'cancelled':
      return i18n.t('i18n.ticketcontainer.ml_status.cancelled');
    case 'confirmed':
      return i18n.t('i18n.ticketcontainer.ml_status.confirmed');
    case 'payment_required':
      return i18n.t('i18n.ticketcontainer.ml_status.payment_required');
    case 'payment_in_process':
      return i18n.t('i18n.ticketcontainer.ml_status.payment_in_process');
    case 'partially_paid':
      return i18n.t('i18n.ticketcontainer.ml_status.partially_paid');
    case 'paid':
      return i18n.t('i18n.ticketcontainer.ml_status.paid');
    case 'invalid':
      return i18n.t('i18n.ticketcontainer.ml_status.invalid');
    case 'reverted':
      return i18n.t('i18n.ticketcontainer.ml_status.reverted');
    case 'refunded':
      return i18n.t('i18n.ticketcontainer.ml_status.refunded');
    case 'approved':
      return i18n.t('i18n.ticketcontainer.ml_status.approved');
    case 'in_mediation':
      return i18n.t('i18n.ticketcontainer.ml_status.in_mediation');
    case 'pending_cancel':
      return i18n.t('i18n.ticketcontainer.ml_status.cancelled');
    default:
      return label;
  }
}

export default mlOrderStatusTranslate;
