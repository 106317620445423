/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs, Paper, CardContent } from '@material-ui/core';
import indexHook from '../indexHook';
import { CustomTypograph } from './custom.components';
import { CardNoShadowNoBackground } from '../../tickets/TicketContainer/TicketComponents/SectionTabs';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      className="customTabs"
      style={{ borderBottom: 'inset' }}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function CustomTabContent({ tabValue, dashboardFilter }) {
  const [attendant, setAttendant] = useState('');

  useEffect(() => {
    setAttendant(dashboardFilter.attendant);
  }, [dashboardFilter.attendant]);

  const { t } = indexHook();
  return tabValue !== 'reportsDashboardTab' ? (
    <Paper>
      <CardContent style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {attendant !== '' &&
        tabValue !== 'reportsDashboardTab' &&
        tabValue !== 'marketplaceScoreTab' ? (
          <div style={{ display: 'flex' }}>
            <CustomTypograph variant="h4" style={{ marginRight: '20px' }}>
              {`${t('i18n.dashboard.components.view_specialist')}:`}
            </CustomTypograph>
            <CustomTypograph>{attendant}</CustomTypograph>
          </div>
        ) : (
          <CustomTypograph variant="h4">
            {t('i18n.dashboard.components.view_admin')}
          </CustomTypograph>
        )}
      </CardContent>
    </Paper>
  ) : (
    <Paper>
      <CardContent style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CustomTypograph variant="h4">
          {t('i18n.dashboard.components.view_admin')}
        </CustomTypograph>
      </CardContent>
    </Paper>
  );
}

const CustomLabel = ({ title, subtitle }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ marginBottom: '-5px' }}>{title}</span>
      <b>{subtitle}</b>
    </div>
  );
};

const DashboardTabs = ({
  tabValue,
  handleTabChange,
  tabsList,
  dashboardFilter
}) => {
  return (
    <Box mt={3} ml={7}>
      <Box mt={2}>
        <CardNoShadowNoBackground>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable auto tabs example"
          >
            {tabsList?.map(item => {
              if (item.show) {
                return (
                  <Tab
                    style={{ height: '40px' }}
                    key={item.index}
                    label={
                      <CustomLabel
                        title={item.label}
                        subtitle={item.sublabel}
                      />
                    }
                    value={item.index}
                  />
                );
              }
            })}
          </Tabs>
          {tabsList?.map(item => {
            if (item.show) {
              return (
                <TabPanel
                  key={item.index}
                  value={item.value}
                  index={item.index}
                >
                  <CustomTabContent {...{ tabValue, dashboardFilter }} />
                </TabPanel>
              );
            }
          })}
        </CardNoShadowNoBackground>
      </Box>
    </Box>
  );
};

export default DashboardTabs;
