/*eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  ThemeProvider
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../components/inputStyle';

import { BlockedIPsContext } from './context';

import SelectComponent from '../../../components/Select.component';
import SelectAttendant from '../../../components/SelectAttendant.component';
import FilterInput from '../../../components/FilterInput.component';
import DateComponent, {
  InputDateComponent,
  formatDateUTC
} from '../../../components/Date.component';

import userService from '../../../services/user.service';

const FilterForm = ({ setOpen }) => {

  const { t } = useTranslation();
  const { setFilter } = useContext(BlockedIPsContext);
  const [filterForm, setFilterForm] = useState({});
  const [creationDate, setCreationDate] = useState({});

  const userName = userService.getUsername();
  const isAdmin = userService.hasGroup('admin');

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setFilter(filterForm);
    setOpen(false);
  };

  const handleSetFilter = (e) => {
    setFilterForm({
      ...filterForm,
      [e.target.name]: e.target.value
    });
  }

  const handleSetCreationDate = e => {
    setCreationDate({
      ...creationDate,
      [e.target.name]: e.target.value
    });
  };

  const handleClearFilter = () => {
    setFilterForm({ blockedByUser: !isAdmin ? userName : '', unblockedByUser: !isAdmin ? userName : '' })
    setFilter({ blockedByUser: !isAdmin ? userName : '', unblockedByUser: !isAdmin ? userName : '' })
    setCreationDate({})
  }

  const handleSetAttendantsOnBlockAndUnblockFilter = (e) => {
    setFilterForm({
      ...filterForm,
      blockedByUser: e.target.value,
      unblockedByUser: e.target.value
    });
  }

  useEffect(() => {
    const { blockedDate } = creationDate;

    if (blockedDate) {
      setFilterForm({
        ...filterForm,
        blockedDate: `${formatDateUTC(blockedDate)}`,
        unblockedDate: `${formatDateUTC(blockedDate)}`
      });
    }

  }, [creationDate]);

  return (
    <ThemeProvider theme={theme}>
      <div style={{ background: "#f4f6f8", borderLeft: '1px solid #cccccc' }}>
        <form onSubmit={(e) => handleSubmitFilter(e)}>
          <Grid container>
            <Grid item>
              <Box
                style={{ width: '500px', padding: '12px 24px 24px', border: '0' }}
              >
                <Typography variant="body1">
                  {t('i18n.ticketslistview.MORE_FILTER_OPTIONS')}
                </Typography>

                <Box mb={2}>
                  <FilterInput
                    {...{
                      form: filterForm,
                      setForm: handleSetFilter,
                      name: 'clientName',
                      label: t('i18n.ticketcontainer.CLIENT_NAME')
                    }}
                  />
                </Box>

                <Box mb={2}>
                  <SelectAttendant
                    {...{
                      form: filterForm,
                      setForm: handleSetAttendantsOnBlockAndUnblockFilter,
                      name: 'blockedByUser',
                      label: 'Analista responsável',
                      isAdmin,
                      userName
                    }}
                  />
                </Box>

                <Box mb={2}>
                  <SelectComponent
                    {...{ form: filterForm, setForm: handleSetFilter, label: 'Status do IP:', name: 'isBlocked' }}
                    options={[
                      { name: 'Bloqueado', value: 'true' },
                      { name: 'Desbloqueado', value: 'false' }
                    ]}
                  />
                </Box>

                <Box mb={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <SelectComponent
                        {...{
                          form: filterForm,
                          setForm: handleSetFilter,
                          label: 'Motivo do bloqueio do IP:',
                          name: 'blockedReason'
                        }}
                        options={[
                          { name: 'Vocabulário Ofensivo', value: 'Vocabulário Ofensivo' },
                          { name: 'Spam', value: 'Spam' }
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectComponent
                        {...{
                          form: filterForm,
                          setForm: handleSetFilter,
                          label: 'Motivo do desbloqueio do IP:',
                          name: 'unblockedReason'
                        }}
                        options={[
                          { name: 'Bloqueio indevido', value: 'Bloqueio indevido' }
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Divider />

                <Box mb={2}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    style={{ marginTop: '15px' }}
                  >
                    Data:
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputDateComponent
                        {...{
                          form: creationDate,
                          setForm: handleSetCreationDate,
                          name: 'blockedDate',
                          label: 'Data do bloqueio e desbloqueio'
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Divider />
                <br />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button variant="contained" onClick={() => handleClearFilter()}>
                    {t('i18n.ticketslistview.CLEAR')}
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    {t('i18n.dashboard.APPLY_FILTER')}
                  </Button>
                </div>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
    </ThemeProvider>
  );
};

export default FilterForm;
