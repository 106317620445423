/*eslint-disable */
import React, { useState } from 'react';
import {
  Divider,
  CircularProgress,
} from '@material-ui/core';
import { useSelector } from 'react-redux';

import { StyledSpinner } from '../../../styles/components/SpinnerDefault';
import { CustomTabPanel } from '../components/custom.components';
import DashboardContent from './dashboardContent';
import Header from './header.component';
import { presaleOfflineTabName } from './config';

const TicketsDashboard = ({ tabValue, dashboardFilter, setDashboardFilter }) => {
  const loading = useSelector(state => state.presaleOfflineDashboardLoadingReducer);
  const dataEquipDash = useSelector(
    state => state.presaleOfflineDashboardEquipDataReducer
  );

  const dataIndividualDash = useSelector(
    state => state.presaleOfflineDashboardIndividualDataReducer
  );

  const marketPlacesSelected = dashboardFilter.marketplace || [];

  return (
    <CustomTabPanel value={tabValue} index={presaleOfflineTabName}>
      <Header dashboardFilter={dashboardFilter} setDashboardFilter={setDashboardFilter} />
      <br />
      <Divider />
      <br />
      {!loading ? (
        <DashboardContent
          dataEquipDash={dataEquipDash}
          dataIndividualDash={dataIndividualDash}
          isAdmin={dashboardFilter.attendant == ""}
          dashboardFilter={dashboardFilter}
          // actualProtocolPayload={actualProtocolPayload}
          marketPlacesSelected={marketPlacesSelected}
        />
      ) : (
        <>
          <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
            <CircularProgress />
          </StyledSpinner>
        </>
      )}
    </CustomTabPanel>
  );
};

export default TicketsDashboard;
