import { Box, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import SelectEvaluation from '../../../../components/SelectEvaluation.component';

const LikeAndDislike = ({
  filterForm,
  setFilterForm,
  handleRemoveShortcutFlag
}) => {
  const handleSetFilter = (e) => {
    setFilterForm({
      ...filterForm,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Box mb={2}>
      <Grid container>
        <Grid item xs={6}>
          <SelectEvaluation
            name="satisfactionScore"
            onChange={handleSetFilter}
            value={filterForm.satisfactionScore || ''}
          />
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            paddingLeft: '10px',
            display: 'flex',
            justifyContent: 'flex-start'
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                disabled={
                  !filterForm.satisfactionScore ||
                  filterForm.satisfactionScore === 'no-evaluation'
                }
                checked={filterForm.commentOnTheScore || false}
                onChange={(event) => {
                  setFilterForm({
                    ...filterForm,
                    commentOnTheScore: event.target.checked
                  });
                  handleRemoveShortcutFlag();
                }}
                color="primary"
              />
            }
            label="Comentário da Avaliação"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LikeAndDislike;
