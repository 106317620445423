import React, { useState } from 'react';
import { Card, CardContent, Tab, Tabs, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { COLORS } from '../../../../styles/settings/colors.config';
import OrderPaymentTabDetails from './OrderPaymentTabDetails';
import OrderClientAndDeliveryTabDetails from './OrderClientAndDeliveryTabDetails';
import OrderTabDetails from './OrderTabDetails';
import InvoiceModal from '../InvoiceModal';

export const CardContentItems = styled(CardContent)`
  overflow-y: scroll;
  max-height: 35vh;
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: ${COLORS.btnSecondaryColor};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.btnSecondaryColor};
    border: 2px solid ${COLORS.btnSecondaryColor};
  }
`;

export const CardContentPayments = styled(CardContent)`
  overflow-y: scroll;
  max-height: 20vh;
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: ${COLORS.btnSecondaryColor};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.btnSecondaryColor};
    border: 2px solid ${COLORS.btnSecondaryColor};
  }
`;

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      className="customTabs"
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const OrderInfo = ({ pedido, orderDocument }) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState('tabClientDelivery');
  const [openInvoice, setOpenInvoice] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };

  const handleClickOpenInvoice = () => {
    setOpenInvoice(true);
  };

  return (
    <>
      <Card>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            value="tabClientDelivery"
            label={t('i18n.ticketcontainer.CLIENT_AND_DELIVERY')}
          />
          <Tab
            value="tabCustomerPayment"
            label={t('i18n.ticketcontainer.ORDER_AND_PAYMENT')}
          />
          <Tab value="tabDetails" label={t('i18n.ticketcontainer.DETAILS')} />
        </Tabs>
        <TabPanel value={tabValue} index="tabClientDelivery">
          <Paper>
            <OrderClientAndDeliveryTabDetails
              handleClickOpenInvoice={handleClickOpenInvoice}
              orderData={pedido}
              dataClient={pedido.cliente}
            />
          </Paper>
        </TabPanel>
        <TabPanel value={tabValue} index="tabCustomerPayment">
          <Paper>
            <OrderPaymentTabDetails
              handleClickOpenInvoice={handleClickOpenInvoice}
              orderData={pedido}
              dataClient={pedido.cliente}
            />
          </Paper>
        </TabPanel>
        <TabPanel value={tabValue} index="tabDetails">
          <Paper style={{ boxShadow: 'none' }}>
            <OrderTabDetails productslist={pedido.itens} orderData={pedido} />
          </Paper>
        </TabPanel>
      </Card>
      {openInvoice && (
        <InvoiceModal
          open={openInvoice}
          onClose={handleCloseInvoice}
          customerCpf={orderDocument}
        />
      )}
    </>
  );
};

export default OrderInfo;
