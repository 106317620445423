export const assertTicketDetailsUrl = (marketplace, protocol) => {
  const isMigratedMarketplace = [
    'B2W',
    'CARREFOUR',
    'KABUM',
    'COLOMBO',
    'CNOVA',
    'MAGALU',
    'MERCADO_LIVRE',
    'NETSHOES',
    'VIVO'
  ].includes(marketplace);

  if (
    marketplace === 'CUSTOMER_GUIDE' ||
    marketplace === 'CUSTOMER_GUIDE_B2B'
  ) {
    return `ticket/details/acer/${protocol}`;
  }

  if (marketplace === 'B2W') {
    return `ticket/details/americanas/${protocol}`;
  }

  if (marketplace === 'MERCADO_LIVRE') {
    return `ticket/details/mercado-livre/${protocol}`;
  }

  if (isMigratedMarketplace) {
    return `ticket/details/${marketplace?.toLowerCase()}/${protocol}`;
  }

  return `ticket-container/${protocol}`;
};
