import { Card, CardContent, Divider, Grid, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { COLORS } from '../../../../styles/settings/colors.config';
import {
  CardTitle,
  CardTitleTypography,
  CustomHelpIcon,
  CustomHintText
} from '../../styles';

const addDigits = value => {
  const num = parseFloat(value).toFixed(1);
  return num;
};

const DescriptionHint = ({ descriptionHint }) => {
  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
        popperOptions: {
          positionFixed: true,
          modifiers: {
            preventOverflow: {
              enabled: true,
              boundariesElement: 'window'
            }
          }
        }
      }}
      placement="top"
      arrow
      title={<CustomHintText>{descriptionHint}</CustomHintText>}
    >
      <CustomHelpIcon fontSize="small" color="primary" />
    </Tooltip>
  );
};

const StyledScore = styled.div`
  font-family: Roboto;
  font-size: 30px;
  color: ${COLORS.dashboardNoDrilldownItems};
  font-weight: 500;
  display: flex;
  justify-content: center;
`;

const StyledMarketplaceName = styled.div`
  font-family: Roboto;
  font-size: 12px;
  color: ${COLORS.dashboardNoDrilldownItems};
  font-weight: 500;
  display: flex;
  justify-content: center;
`;

const scoreValue = (dataEquipDash, marketplace) => {
  switch (marketplace) {
    case 'b2w':
      return dataEquipDash?.score_marketplace?.score_b2w || 0;
    case 'magalu':
      return dataEquipDash?.score_marketplace?.score_MAGALU || 0;
    case 'mercado_livre':
      return (
        dataEquipDash?.score_marketplace?.score_MERCADO_LIVRE_real_time || 0
      );
    case 'cnova':
      return dataEquipDash?.score_marketplace?.score_CNOVA || 0;
    default:
      return 0;
  }
};

const marketplaceName = marketplace => {
  switch (marketplace) {
    case 'b2w':
      return 'Americanas';
    case 'magalu':
      return 'Magalu';
    case 'mercado_livre':
      return 'Mercado Livre';
    case 'cnova':
      return 'CNova';
    default:
      return '';
  }
};

const marketplaceList = ['b2w', 'magalu', 'mercado_livre', 'cnova'];

const getMarketplaceValues = dataEquipDash => {
  return marketplaceList.map(item => {
    return {
      name: marketplaceName(item),
      value: scoreValue(dataEquipDash, item)
    };
  });
};

const MarketplaceScoresCard = ({ descriptionHint, dataEquipDash }) => {
  const [marketplaceValues, setMarketplaceValues] = useState([]);
  const [gridSize, setGridSize] = useState(3);

  useEffect(() => {
    setMarketplaceValues(getMarketplaceValues(dataEquipDash));
  }, []);

  useEffect(() => {
    const { length } = marketplaceValues;

    switch (length) {
      case 1:
        setGridSize(12);
        break;
      case 2:
        setGridSize(6);
        break;
      case 3:
        setGridSize(4);
        break;
      default:
        break;
    }
  }, [marketplaceValues]);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Card
        style={{ width: '100%', marginBottom: '20px', borderRadius: '20px' }}
      >
        <CardTitle>
          <CardTitleTypography>Notas dos Marketplaces</CardTitleTypography>
          {descriptionHint && <DescriptionHint {...{ descriptionHint }} />}
        </CardTitle>
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            {marketplaceValues?.map(({ name, value }) => (
              <Grid item xs={gridSize} key={Math.random()}>
                <StyledScore>{addDigits(value)}</StyledScore>
                <StyledMarketplaceName>{name}</StyledMarketplaceName>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default MarketplaceScoresCard;
