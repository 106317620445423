import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MERCADO_LIVRE_LOGO from '../../../assets/images/logo_mercado_livre.png';
import ACER_LOGO from '../../../assets/images/acer_logo.png';
import { StyledTicketHeader } from '../styles';
import { COLORS } from '../../../styles/settings/colors.config';
import marketPlacesEnum from '../../../enums/marketPlaces.enum';

const TicketTitle = ({ totalResults, marketplace }) => {
  const { t } = useTranslation();

  const getLogoImg = marketPlace => {
    switch (marketPlace) {
      case marketPlacesEnum.MERCADO_LIVRE:
        return (
          <img
            src={MERCADO_LIVRE_LOGO}
            alt="Logo da loja Mercado Livre"
            className="store-ml"
          />
        );
      case 'YOURVIEWS':
        return (
          <img
            src={ACER_LOGO}
            alt="Logo da loja Acer Store"
            className="store-acer"
          />
        );
      default:
        break;
    }
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <StyledTicketHeader>
          <div className="header-details">
            {getLogoImg(marketplace)}
            {marketplace ? (
              <>
                <Typography
                  component="h2"
                  variant="h2"
                  className="header-details--marketplace"
                >
                  {t(`i18n.marketplaces.${marketplace}`).toLocaleLowerCase()}
                </Typography>
                <Typography
                  variant="h3"
                  component="span"
                  className="header-details--ticket"
                >
                  {t('i18n.dashboardlayout.TICKETS_PRE_SALE')} -
                </Typography>
                <Typography
                  component="span"
                  variant="h6"
                  className="header-details--ticket"
                  style={{ color: COLORS.grayText, marginLeft: '5px' }}
                >
                  {t('i18n.dashboard.preSale.results_of_filter')} {totalResults}{' '}
                  {t('i18n.dashboard.preSale.questions')}
                </Typography>
              </>
            ) : (
              <Typography
                style={{
                  color: COLORS.WarningColorSLA,
                  fontWeight: 'semibold',
                  fontSize: '14px'
                }}
              >
                Selecione o Marketplace
              </Typography>
            )}
          </div>
        </StyledTicketHeader>
      </Grid>
    </Grid>
  );
};

export default TicketTitle;
