import { defaultHoursNumber } from '../services/config';
import { defaultMarketplaceScoreFilter } from '../../../hooks/hooks';

const filterNameInStorage = 'marketplaceScoreFilter';

const marketplaceScoreTabName = 'marketplaceScoreTab';

const defaultFilter = {
  attendant: '',
  dateAliasInAWayHumanCanRead: 'realTime',
  // eslint-disable-next-line camelcase
  report_type: '',
  status: '',
  tabView: 'marketplaceScoreTab',
  ticket_type: 'AFTER_SALE',
  periodType: 'realTime',
  isRealTime: true,
  marketplace: defaultMarketplaceScoreFilter,
  startDate: '',
  endDate: ''
};

export {
  defaultHoursNumber,
  defaultFilter,
  filterNameInStorage,
  marketplaceScoreTabName
};
