import { Grid, Typography, CardContent } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import AcerModal from '../../../components/AcerModal';
import API from '../../../services/api';

const PreSaleChatModal = ({
  openState,
  closeModal,
  // question,
  setBlockInput,
  getData,
  setLoading,
  setEnableButton,
  currentTicket,
  handleShowTicket
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const question = currentTicket?.data;
  const index = currentTicket?.index;

  const handleAssignment = attr => {
    API.get(
      `/auth/ms-ticket/v1/tickets/lock-presale?ticketId=${question?.id}&reassignment=${attr}`
    ).then(() => {
      enqueueSnackbar('Protocolo atribuido com sucesso', {
        variant: 'success'
      });
      getData(false, false);
      setLoading(false);
      setBlockInput(false);
      setEnableButton(false);
    });
  };

  const handleSetAssignment = () => {
    handleShowTicket(question, index);

    if (question?.serviceAttendantLogin === '') {
      handleAssignment(false);
    }
    if (question?.serviceAttendantLogin !== '') {
      handleAssignment(true);
    }
  };

  const handleSetQuestion = () => {
    closeModal();
    handleSetAssignment();
  };

  return (
    <AcerModal
      hasAction
      fullWidth
      maxWidth="sm"
      open={openState}
      modalTitle="Atribuir atendimento"
      onClose={closeModal}
      cancelActionLabel={t('i18n.simplewords.CANCEL')}
      confirmActionLabel={t('i18n.simplewords.FINISH')}
      confirmActionFunc={() => {
        handleSetQuestion();
      }}
      cancelActionFunc={() => closeModal()}
    >
      <Grid
        container
        spacing={2}
        style={{ maxHeight: '380px', overflowY: 'auto', overFlowX: 'hidden' }}
      >
        <CardContent>
          <Typography>
            {question?.serviceAttendantLogin === '' ? (
              <> Deseja atribuir esse atendimento ao seu usuário? </>
            ) : (
              <>
                {' '}
                Esse protocolo já está em atendimento. Deseja atribuir esse
                atendimento ao seu usuário?{' '}
              </>
            )}
          </Typography>
        </CardContent>
      </Grid>
    </AcerModal>
  );
};

export default PreSaleChatModal;
