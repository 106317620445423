import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectComponent from '../../../../components/Select.component';

const SchedulingDropdown = ({ filterForm, handleSetFilter }) => {
  const { t } = useTranslation();
  return (
    <Box mb={2}>
      <Grid container>
        <Grid item xs={7}>
          <SelectComponent
            {...{
              form: filterForm,
              setForm: handleSetFilter,
              label: t('i18n.ticketslistview.SCHEDULING'),
              name: 'protocolSchedulingStatus',
              gapOptions: '0px'
            }}
            options={[
              { name: t('i18n.ticketslistview.SLA_ACTIVE'), value: 'ACTIVE' },
              { name: t('i18n.ticketslistview.SLA_EXPIRED'), value: 'EXPIRED' },
              {
                name: t('i18n.ticketslistview.SLA_EXPIRE_TODAY'),
                value: 'EXPIRES_TODAY'
              }
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SchedulingDropdown;
