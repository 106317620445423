import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  Grid,
  Breadcrumbs,
  Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BlockedIPsContext } from './context';

import { OptionsConfig, ColumnsConfig } from './datatable.config';

import API from '../../../services/api';
import config from '../../../config';
import { StyledSpinner } from '../../../styles/components/SpinnerDefault';
import {
  searchB2bOnCurrentUrlV2,
  searchB2bOnCurrentUrl
} from '../../../utils/b2bHelper';

import { COLORS } from '../../../styles/settings/colors.config';

import {
  CustomDataTable,
  CustomDataTableWrapper
} from '../../../styles/components/CustomDataTable';

import { UnBlockModal } from '../NewChatV2/components/UnBlockUser.component';

const CustomTitle = styled(Typography)`
  color: ${COLORS.greenText} !important;
`;

const DataTableComponent = () => {
  const { t } = useTranslation();

  const {
    loading,
    data,
    refreshTableContent,
    openModal,
    setOpenModal,
    setUser
  } = useContext(BlockedIPsContext);

  const handleRemove = sendedIP => {
    API.delete(
      `${
        config.backend.url
      }/auth/ms-chat/chats/block/connection/${sendedIP}?businessType=${searchB2bOnCurrentUrlV2()}`
    ).then(() => {
      refreshTableContent();
    });
  };

  const columns = ColumnsConfig({
    t,
    data,
    handleRemove,
    openModal,
    setOpenModal,
    setUser
  });
  const options = OptionsConfig({ t, loading, data });

  return (
    <>
      <CustomDataTableWrapper style={{ opacity: loading ? '0.3' : '1' }}>
        <CustomDataTable
          customPaddingTr="10px 0 10px 5px"
          customColorTr="#263238"
          title={
            <Grid container direction="column" spacing={0}>
              <Grid item>
                <Breadcrumbs gutterBottom aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    style={{ textDecoration: 'none' }}
                    to="/chats"
                  >
                    <CustomTitle variant="h2">Chats</CustomTitle>
                  </Link>
                  <Typography variant="h2">
                    {`${t('i18n.chatview.BLOCKED_UNBLOCKED_IPS')} ${
                      searchB2bOnCurrentUrl() ? 'B2B' : 'B2C'
                    }`}
                  </Typography>
                </Breadcrumbs>
              </Grid>
            </Grid>
          }
          data={data}
          columns={columns}
          options={options}
          selectableRowsOnClick
        />
      </CustomDataTableWrapper>
      <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
        <CircularProgress />
      </StyledSpinner>
      <UnBlockModal />
    </>
  );
};

export default DataTableComponent;
