const presaleOfflineDashboardFilter = {};
const presaleOfflineDashboardEquipData = {};
const presaleOfflineDashboardIndividualData = {};
const presaleOfflineDashboardLoading = true;
const presaleOfflineDashboardRemovedKey = '';

const presaleOfflineDashboardEquipDataReducer = (
  state = presaleOfflineDashboardEquipData,
  action
) => {
  switch (action.type) {
    case 'SET_PRESALE_OFFLINE_DASHBOARD_EQUIP_DATA':
      state = action.equipData;
      return state;
    default:
      return state;
  }
};

const presaleOfflineDashboardRemovedKeyReducer = (
  state = presaleOfflineDashboardRemovedKey,
  action
) => {
  switch (action.type) {
    case 'SET_PRESALE_OFFLINE_DASHBOARD_REMOVED_KEY':
      state = action.key;
      return state;
    default:
      return state;
  }
};

const presaleOfflineDashboardIndividualDataReducer = (
  state = presaleOfflineDashboardIndividualData,
  action
) => {
  switch (action.type) {
    case 'SET_PRESALE_OFFLINE_DASHBOARD_INDIVIDUAL_DATA':
      state = action.individualData;
      return state;
    default:
      return state;
  }
};

const presaleOfflineDashboardLoadingReducer = (
  state = presaleOfflineDashboardLoading,
  action
) => {
  switch (action.type) {
    case 'SET_PRESALE_OFFLINE_DASHBOARD_LOADING':
      state = action.loading;
      return state;
    default:
      return state;
  }
};

const presaleOfflineDashboardFilterReducer = (
  state = presaleOfflineDashboardFilter,
  action
) => {
  switch (action.type) {
    case 'SET_PRESALE_OFFLINE_DASHBOARD_FILTER':
      state = action.filter;
      return state;
    default:
      return state;
  }
};

export {
  presaleOfflineDashboardFilterReducer,
  presaleOfflineDashboardLoadingReducer,
  presaleOfflineDashboardEquipDataReducer,
  presaleOfflineDashboardIndividualDataReducer,
  presaleOfflineDashboardRemovedKeyReducer
};
