import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import formHelper from '../utils/formHelper';
import API from '../services/api';
import config from '../config';

/**
 * Attendant Select to forms
 * @param {String} label - String - Name of the checkbox group
 * @param {String} Name - String - Name of the field
 * @param {Object} form - Object - State of form that recieve the checkbox values
 * @param {Function} setForm - Function - Open controller
 */

const SelectAttendant = ({
  form,
  setForm,
  name,
  label,
  isAdmin,
  userName,
  loadGroup = false
}) => {
  const { t } = useTranslation();
  const [attendantList, setAttendantList] = useState([]);

  const handleLoadAttendants = () => {
    API.get(`/auth/ms-users/groups/atendente/users`).then(response => {
      setAttendantList(response.data.sort(formHelper.compareName));
    });
  };

  const handleLoadAttendantsByGroup = () => {
    API.get(`/auth/ms-users/groups/${config.cognito.chatGroupName}/users`).then(
      response => {
        setAttendantList(response.data.sort(formHelper.compareName));
      }
    );
  };

  useEffect(() => {
    if (!isAdmin) {
      setForm({ target: { name, value: userName } });
    }
    if (loadGroup) {
      handleLoadAttendantsByGroup();
    } else {
      handleLoadAttendants();
    }
  }, []);

  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        size="small"
        defaultValue=""
        label={label}
        className="small-input"
        value={!isAdmin ? userName : form[name] || ''}
        disabled={!isAdmin && true}
        name={name}
        onChange={e => {
          setForm(e);
        }}
      >
        <MenuItem value="">
          <em>{t('i18n.ticketslistview.NONE')}</em>
        </MenuItem>
        {attendantList?.map(user => {
          return (
            <MenuItem key={user?.name} value={user?.name}>
              {user?.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectAttendant;
