import { Box, Button, Card, Grid, Typography } from '@material-ui/core';
// import { CancelOutlined } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

const Label = styled(Typography)`
  font-size: 12px;
  text-transform: none;
  text-align: left;
`;

const Content = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
  text-transform: none;
  text-align: left;
`;

const ResumeCard = ({
  selectedSupport,
  setOpenReopenTicketModal,
  setOpenCloseTicketModal
}) => {
  const {
    status,
    creationDate,
    id,
    category,
    subject,
    timesReopened
  } = selectedSupport;

  return (
    <Card style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
      <Grid container>
        <Grid item xs={2}>
          <Label>Protocolo do Chamado</Label>
          <Content>{id}</Content>
        </Grid>
        <Grid item xs={2}>
          <Label>Data de Abertura</Label>
          <Content>{moment(creationDate).format('DD/MM/YYYY HH:mm')}</Content>
        </Grid>
        <Grid item xs={2}>
          <Label>Status</Label>
          <Content>{status}</Content>
        </Grid>
        <Grid item xs={2}>
          <Label>Categoria</Label>
          <Content>{category}</Content>
        </Grid>
        <Grid item xs={2}>
          <Label>Assunto</Label>
          <Content>{subject}</Content>
        </Grid>
        <Grid item xs={2}>
          {status === 'Em análise' && (
            <Box display="flex" padding="10px" justifyContent="center">
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => setOpenCloseTicketModal(true)}
              >
                Fechar Chamado
              </Button>
            </Box>
          )}
          {status === 'Fechado' && timesReopened === 2 && (
            <Box display="flex" padding="10px" justifyContent="center">
              <Button
                color="secondary"
                variant="contained"
                size="small"
                disabled
              >
                Reaberto 2 vezes
              </Button>
            </Box>
          )}
          {status === 'Fechado' && timesReopened !== 2 && (
            <Box display="flex" padding="10px" justifyContent="center">
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => setOpenReopenTicketModal(true)}
              >
                Reabrir chamado
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default ResumeCard;
