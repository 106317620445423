import {
  Grid,
  Button,
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import formHelper from '../../../../../utils/formHelper';
import banks from '../../../../../utils/banks';
import { COLORS } from '../../../../../styles/settings/colors.config';

import AcerModal from '../../../../../components/AcerModal';

import ModalRequestSelectComponent from './ModalRequestSelectComponent';

import API from '../../../../../services/api';

const StepItem = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props =>
    props.step === props.currentStep ? COLORS.greenText : COLORS.grayText};

  svg {
    font-size: 2.5em;
    margin-bottom: 15px;
  }
`;

const Steps = styled('div')``;

const StepContent = styled('div')`
  display: ${props => (props.step === props.currentStep ? 'block' : 'none')};
`;

const StepWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 30px auto;
`;

const CustonGrid = styled(Grid)`
  margin-top: 20px;
`;

const ModalGridButton = styled(Grid)`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CustonArrowForwardOutlinedIcon = styled(ArrowForwardOutlinedIcon)`
  font-size: 2.5em;
  color: #ccc;
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  icon: {
    fill: theme.palette.primary.main
  },
  divider: {
    marginTop: '1em'
  },
  button: {
    marginTop: '2em'
  },
  productDescription: {
    marginRight: '1.5em'
  },
  containerPayment: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  paymentMethod: {
    display: 'flex',
    alignItems: 'center'
  },
  paymentAlert: {
    fontSize: '16px'
  },
  stockZero: {
    color: 'red'
  }
}));

const ModalExchangeRefund = ({
  ticketData,
  openState,
  closeParent,
  modalType,
  stepsLimit,
  currentStep,
  handleStepsNext,
  handleStepsPrev,
  firstStep,
  handleCloseModal,
  causes,
  items
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [reason, setReason] = useState('');
  const [mainReason, setMainReason] = useState([]);
  const [reasonsList, setReasonsList] = useState([]);
  const [reasonDetails, setReasonDetails] = useState('');
  const [mainReasonSelected, setMainReasonSelected] = useState('');
  const [reasonSelected, setReasonSelected] = useState('');
  const [paymentMethodsList, setPaymentMethodsList] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState({});
  const [bankCode, setBankCode] = useState('');
  const [agencyNumber, setAgencyNumber] = useState('');
  const [agencyDigit, setAgencyDigit] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountDigit, setAccountDigit] = useState('');
  const [selectQTDProduct, setSelectQTDProduct] = useState([]);

  const mountPaymentMethod = () => {
    if (paymentMethodSelected.id === 'CREDIT_ACCOUNT') {
      const newPaymentMethodSelected = {
        ...paymentMethodSelected,
        bankAccount: {
          bankCode,
          agency: {
            number: agencyNumber,
            digit: agencyDigit
          },
          account: {
            number: accountNumber,
            digit: accountDigit
          }
        },
        customer: {
          cpfCnpj: ticketData?.marketplaceBundle?.customerDocument,
          name: ticketData?.marketplaceBundle?.customerName
        }
      };
      setPaymentMethodSelected(newPaymentMethodSelected);
      return [newPaymentMethodSelected];
    }
    return [paymentMethodSelected];
  };

  const HandleSubmit = () => {
    const data = {};
    const selectQTDProductArray = Object.values(selectQTDProduct);

    mainReasonSelected.reasons = [reasonSelected];
    data.ticketId = ticketData?.id;
    data.deliveryId = ticketData?.marketplaceBundle?.delivery_id;
    data.orderB2W = ticketData?.marketplaceBundle?.order_id;
    data.action = {};
    data.action.id = modalType;
    data.causes = [mainReasonSelected];
    data.note = reasonDetails;
    data.items = selectQTDProductArray;
    data.refunds = modalType === 'RETURN' ? mountPaymentMethod() : [null];
    if (data.items.length > 0) {
      API.post('/auth/ms-ticket/v1/tickets/b2w/new-instance-final', data)
        .then(() => {
          handleCloseModal();
        })
        .catch(error => {
          const msgErrorFormatada = error.response.data.key
            .split('"message":"')[1]
            .split('"}}]');
          enqueueSnackbar(msgErrorFormatada[0], {
            variant: 'error'
          });
        });
    } else {
      enqueueSnackbar(t('i18n.b2w.modal.newinstance.QUANTITY_ZERO'), {
        variant: 'error'
      });
    }
  };

  const HandleSelectProductUpdate = (value, productId, item) => {
    item.quantity = value.toString();
    item.totals.valid_to_exchange_refund_selected = value.toString();

    const currentSelectQTDProduct = selectQTDProduct;
    if (item.quantity !== '0') {
      currentSelectQTDProduct[productId] = item;
    }
    setSelectQTDProduct({ ...currentSelectQTDProduct });
  };

  const handleGetPaymentMethods = () => {
    if (currentStep === 1 && modalType === 'RETURN') {
      const data = {
        ticketId: ticketData.id,
        deliveryId: ticketData?.marketplaceBundle?.delivery_id
      };
      API.post('/auth/ms-ticket/v1/tickets/b2w/new-instance-2', data)
        .then(response => {
          setPaymentMethodsList(response.data);
        })
        .catch(() => {
          enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
            variant: 'error'
          });
        });
    }
  };

  const handleDisabled = () => {
    if (modalType === 'RETURN' && currentStep === 0) {
      return !mainReason || !reason;
    }
    if (modalType === 'RETURN' && currentStep === 1) {
      return selectQTDProduct.length === 0;
    }

    if (modalType === 'RETURN' && currentStep === 2) {
      return paymentMethod.length === 0;
    }

    if (modalType === 'EXCHANGE' && currentStep === 1) {
      return selectQTDProduct.length === 0;
    }

    return !mainReason || !reason;
  };

  useEffect(() => {
    setReason('');
    setMainReason([]);
    setReasonsList([]);
    setSelectQTDProduct([]);
    setPaymentMethod([]);
    setPaymentMethodSelected({});
    setBankCode('');
    setAgencyNumber('');
    setAgencyDigit('');
    setAccountNumber('');
    setAccountDigit('');
    setReasonDetails('');
  }, [openState]);

  return (
    <AcerModal
      fullWidth
      maxWidth="md"
      open={openState}
      modalTitle={
        modalType === 'EXCHANGE'
          ? t('i18n.b2w.modal.exchange.NEW_REQUEST_EXCHANGE')
          : t('i18n.b2w.modal.return.NEW_REQUEST_RETURN')
      }
      onClose={closeParent}
    >
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <StepWrapper>
              <StepItem currentStep={currentStep} step={0}>
                <HelpOutlineIcon />
                <p>{t('i18n.b2w.modal.exchange.STEP_SELECT_REASON')}</p>
              </StepItem>
              <CustonArrowForwardOutlinedIcon />
              <StepItem currentStep={currentStep} step={1}>
                <ShoppingCartIcon />
                <p>{t('i18n.b2w.modal.exchange.STEP_SELECT_PRODUCT')}</p>
              </StepItem>
              {modalType === 'RETURN' && (
                <>
                  <CustonArrowForwardOutlinedIcon />
                  <StepItem currentStep={currentStep} step={2}>
                    <MonetizationOnOutlinedIcon />
                    <p>{t('i18n.b2w.modal.exchange.STEP_PAYMENT_SELECT')}</p>
                  </StepItem>
                </>
              )}
            </StepWrapper>
          </Grid>
        </Grid>

        <Steps>
          <StepContent step={0} currentStep={currentStep}>
            <CustonGrid container>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>
                    {modalType === 'EXCHANGE'
                      ? t('i18n.b2w.modal.exchange.REASON_TO_CHANGE')
                      : t('i18n.b2w.modal.return.REASON_TO_REFUND')}
                  </InputLabel>
                  <Select
                    label={
                      modalType === 'EXCHANGE'
                        ? t('i18n.b2w.modal.exchange.REASON_TO_CHANGE')
                        : t('i18n.b2w.modal.return.REASON_TO_REFUND')
                    }
                    value={mainReason}
                    onChange={e => {
                      setMainReason(e.target.value);
                    }}
                  >
                    {causes?.map(item => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        onClick={() => {
                          setReasonsList(item.reasons);
                          setMainReasonSelected({
                            id: item.id,
                            name: item.name,
                            reasons: []
                          });
                        }}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </CustonGrid>

            <CustonGrid container>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <Select
                    value={reason}
                    onChange={e => {
                      setReason(e.target.value);
                    }}
                  >
                    {reasonsList?.map(item => (
                      <MenuItem
                        value={item.id}
                        key={item.id}
                        onClick={() => setReasonSelected(item)}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </CustonGrid>

            <CustonGrid>
              <Grid item xs={12}>
                <Box>
                  <TextField
                    value={reasonDetails}
                    onChange={e => {
                      setReasonDetails(e.target.value);
                    }}
                    fullWidth
                    label={t('i18n.b2w.modal.exchange.DETAILS')}
                    multiline
                    minRows={4}
                    variant="outlined"
                  />
                </Box>
              </Grid>
            </CustonGrid>
          </StepContent>

          <StepContent step={1} currentStep={currentStep}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <Typography variant="h5" component="h5">
                    {t('i18n.b2w.modal.exchange.ITEM')}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h5" component="h5">
                    {t('i18n.b2w.modal.exchange.QUANTITY')}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>

            {items?.map(item => {
              if (item.totals.valid_to_exchange_refund !== null) {
                return (
                  <div key={item.id}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={2}>
                            <img src={item.image} alt={item.image} />
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="h5" component="h5">
                              {item.name}
                            </Typography>
                            <br />
                            <Typography component="p">
                              {t('i18n.b2w.modal.exchange.ID')}:{' '}
                              {item.productId} |{' '}
                              {t('i18n.b2w.modal.exchange.SKU')}: {item.sku}
                            </Typography>
                            {modalType === 'EXCHANGE' && item.stock === 0 && (
                              <Typography className={classes.stockZero}>
                                {t('i18n.b2w.modal.newinstance.STOCK_ZERO')}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={2}>
                            <ModalRequestSelectComponent
                              item={item}
                              onChange={HandleSelectProductUpdate}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                    <br />
                  </div>
                );
              }
              return <></>;
            })}
          </StepContent>

          <StepContent step={2} currentStep={currentStep}>
            {Object.values(selectQTDProduct)?.map(item => (
              <div key={item.id}>
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={2}>
                        <img src={item.image} alt={item.image} />
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="h5" component="h5">
                          {item.name}
                        </Typography>
                        <br />
                        <Typography component="p">
                          {t('i18n.b2w.modal.exchange.ID')}: {item.productId} |{' '}
                          {t('i18n.b2w.modal.exchange.SKU')}: {item.sku}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="h6" component="h5">
                          {t('i18n.b2w.modal.exchange.QTD')}
                        </Typography>
                        <Typography>{item.quantity}</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <br />
              </div>
            ))}
            <Grid container className={classes.containerPayment}>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel component="legend">
                    {t('i18n.b2w.modal.return.REFUND_TYPE_CHOICE')}
                    :
                  </FormLabel>
                  <RadioGroup
                    aria-label="payment"
                    name="paymentMethod"
                    value={paymentMethod}
                    onChange={e => setPaymentMethod(e.target.value)}
                  >
                    {paymentMethodsList?.map(item => (
                      <FormControlLabel
                        key={item.id}
                        value={item.id}
                        control={<Radio color="default" />}
                        label={item.name}
                        onClick={() => setPaymentMethodSelected(item)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              {paymentMethodSelected.id === 'CREDIT_ACCOUNT' && (
                <Grid item xs={6} container direction="column" spacing={2}>
                  <Grid item md={12}>
                    <FormLabel component="legend">
                      {t('i18n.b2w.modal.return.ACCOUNT_INSTRUCTION')}
                      :
                    </FormLabel>
                  </Grid>
                  <Grid item md={12}>
                    <Autocomplete
                      disableClearable
                      options={banks}
                      getOptionLabel={option =>
                        `${option.code} - ${option.name}`
                      }
                      noOptionsText={t(
                        'i18n.ticketcontainer.STATUS_NO_OPTIONS'
                      )}
                      onChange={(event, newValue) => {
                        setBankCode(newValue.code);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={t('i18n.b2w.modal.return.ACCOUNT_BANK')}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    container
                    spacing={1}
                    justifyContent="space-between"
                  >
                    <Grid item sm={3}>
                      <TextField
                        size="medium"
                        value={agencyNumber}
                        onChange={event => {
                          setAgencyNumber(event.target.value);
                        }}
                        fullWidth
                        label={t('i18n.b2w.modal.return.ACCOUNT_BRANCH')}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <TextField
                        size="medium"
                        value={agencyDigit}
                        onChange={event => {
                          setAgencyDigit(event.target.value);
                        }}
                        fullWidth
                        label={t('i18n.b2w.modal.return.ACCOUNT_DIGIT')}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item sm={1}>
                      {}
                    </Grid>
                    <Grid item sm={4}>
                      <TextField
                        size="medium"
                        value={accountNumber}
                        onChange={event => {
                          setAccountNumber(event.target.value);
                        }}
                        fullWidth
                        label={t('i18n.b2w.modal.return.ACCOUNT')}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <TextField
                        size="medium"
                        value={accountDigit}
                        onChange={event => {
                          setAccountDigit(event.target.value);
                        }}
                        fullWidth
                        label={t('i18n.b2w.modal.return.ACCOUNT_DIGIT')}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {paymentMethodSelected.id === 'CREDIT_ACCOUNT' && (
              <Alert severity="warning" className={classes.paymentAlert}>
                {t('i18n.b2w.modal.return.INSTRUCTION')}{' '}
                <strong>
                  {ticketData?.marketplaceBundle?.customerName.toUpperCase()}
                </strong>{' '}
                {ticketData?.marketplaceBundle?.customerDocument.length < 14
                  ? 'CPF '
                  : 'CNPJ'}{' '}
                <strong>
                  {formHelper.cpfCnpjFormat(
                    ticketData?.marketplaceBundle?.customerDocument
                  )}
                </strong>
              </Alert>
            )}
            {paymentMethodSelected.information && (
              <Alert severity="info" className={classes.paymentAlert}>
                {paymentMethodSelected.information}
              </Alert>
            )}
          </StepContent>
        </Steps>

        <ModalGridButton
          lg={12}
          item
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {currentStep !== firstStep ? (
            <Button
              variant="contained"
              color="default"
              onClick={handleStepsPrev}
            >
              {t('i18n.b2w.modal.return.PREV')}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="default"
              onClick={handleCloseModal}
            >
              {t('i18n.b2w.modal.return.CLOSE')}
            </Button>
          )}

          {currentStep < stepsLimit ? (
            <Button
              disabled={handleDisabled()}
              variant="contained"
              color="primary"
              onClick={() => {
                handleStepsNext();
                handleGetPaymentMethods();
              }}
            >
              {t('i18n.b2w.modal.return.NEXT')}
            </Button>
          ) : (
            <Button
              disabled={
                handleDisabled() ||
                (paymentMethodSelected.id === 'CREDIT_ACCOUNT' &&
                  (bankCode === '' ||
                    agencyNumber === '' ||
                    accountNumber === ''))
              }
              variant="contained"
              color="primary"
              onClick={HandleSubmit}
            >
              {t('i18n.b2w.modal.return.FINISH')}
            </Button>
          )}
        </ModalGridButton>
      </div>
    </AcerModal>
  );
};

export default ModalExchangeRefund;
