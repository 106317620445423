import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
  Box
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import indexHook from '../indexHook';
import {
  FilterChipDescribe,
  FilterHeaderDescribe
} from '../components/filterChipDescribe.component';

import {
  RemoveFilterCardButton,
  cardStyle,
  FilterCardHeader,
  CustomTypography,
  CustomTypographyDescribe,
  typographyStyle
} from '../../../styles/components/Tags';

import { COLORS } from '../../../styles/settings/colors.config';

const Header = ({ dashboardFilter, setDashboardFilter }) => {
  const { t } = indexHook();
  const [isAdmin, setIsAdmin] = useState('');

  useEffect(() => {
    setIsAdmin(sessionStorage.getItem('dashAdmin') === 'true');
  }, []);

  const chipsList = Object.keys(dashboardFilter).filter(item => {
    return (
      item !== 'dateAliasInAWayHumanCanRead' &&
      item !== 'tabView' &&
      item !== 'periodType' &&
      item !== 'startDate' &&
      item !== 'endDate' &&
      item !== 'ticket_type' &&
      item !== 'marketplace' &&
      item !== 'report_type' &&
      item !== 'isRealTime'
    );
  });

  const FilteredByTitle = () => {
    if (
      (dashboardFilter.tabView === 'dashboardTab' &&
        (dashboardFilter.status !== '' || dashboardFilter.attendant !== '')) ||
      (dashboardFilter.tabView === 'chatDashboardTab' &&
        dashboardFilter.attendant !== '') ||
      (dashboardFilter.tabView === 'reportDashboardTab' &&
        dashboardFilter.report_type)
    ) {
      return (
        <CustomTypography variant="h5">
          {t('i18n.dashboard.components.filtered')}:
        </CustomTypography>
      );
    }
    return <></>;
  };

  const removeFilter = filterKey => {
    const changes = {};
    changes[filterKey] = '';
    const newFilter = { ...dashboardFilter, ...changes };
    setDashboardFilter(newFilter);
  };

  return (
    <Grid container spacing={5}>
      <Grid item>
        <CustomTypography variant="h5">
          {t('i18n.dashboard.components.viewed_by')}:
        </CustomTypography>
        <Box mt={1}>
          <CustomTypography
            variant="h3"
            style={{ color: COLORS.WarningColorSLA, fontSize: '20px' }}
          >
            <FilterHeaderDescribe
              filterKey={dashboardFilter.periodType}
              value={dashboardFilter.dateAliasInAWayHumanCanRead}
              startDate={dashboardFilter.startDate}
              endDate={dashboardFilter.endDate}
            />
          </CustomTypography>
        </Box>
      </Grid>
      <Grid item>
        <Box mb={1}>{FilteredByTitle()}</Box>
        <Grid container spacing={2}>
          {chipsList.map(
            key =>
              dashboardFilter[key] !== '' && (
                <Grid key={key} item>
                  <Card
                    style={{
                      borderRadius: '10px'
                    }}
                  >
                    <CardContent style={cardStyle}>
                      <FilterCardHeader>
                        <Typography variant="overline" style={typographyStyle}>
                          {t(`i18n.dashboard.filterHeader.${key}`)}
                        </Typography>
                        <Tooltip title={t('i18n.dashboard.REMOVE_FILTER')}>
                          <span>
                            <RemoveFilterCardButton
                              disabled={!isAdmin && key === 'attendant'}
                              aria-label="delete"
                              onClick={() => {
                                removeFilter(key);
                              }}
                              color="primary"
                            >
                              <CloseIcon />
                            </RemoveFilterCardButton>
                          </span>
                        </Tooltip>
                      </FilterCardHeader>
                      <CustomTypographyDescribe>
                        <FilterChipDescribe
                          filterKey={key}
                          value={dashboardFilter[key]}
                        />
                      </CustomTypographyDescribe>
                    </CardContent>
                  </Card>
                </Grid>
              )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
