import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';

const ModalRequestSelectComponent = ({ item, onChange }) => {
  const [selected, setSelected] = useState({});

  const renderOptions = items => {
    const options = [];
    for (let i = 1; i <= items.totals.valid_to_exchange_refund; i++) {
      options.push({ value: i });
    }

    return options?.map(subitem => (
      <MenuItem value={subitem.value} key={subitem.value}>
        {subitem.value}
      </MenuItem>
    ));
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>Qtd.</InputLabel>
      <Select
        label="Qtd."
        size="small"
        value={selected}
        onChange={e => {
          onChange(e.target.value, item.productId, item);
          setSelected(e.target.value);
        }}
      >
        <MenuItem value="0">0</MenuItem>
        {renderOptions(item)}
      </Select>
    </FormControl>
  );
};

export default ModalRequestSelectComponent;
