import { Box, Divider, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  divider: {
    width: '90%'
  }
}));

const MainDivider = () => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="center" width="100%" mb={2}>
      <Divider className={classes.divider} />
    </Box>
  );
};

export default MainDivider;
