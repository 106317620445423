import {
  makeStyles,
  createStyles,
  Checkbox,
  TextField
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const useStyles = makeStyles(() =>
  createStyles({
    inputSmallProdutc: {
      width: '50px',
      height: '30px',
      fontSize: '12px'
    }
  })
);

const StyledOrderItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dotted #666666;
  padding: 15px 0;
`;

const toBRL = string => {
  return string.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
};

const OrderItem = ({
  item,
  cancelAll,
  selectedItems,
  setSelectedItems,
  i,
  orderItems,
  setCancelAll
}) => {
  const [itemSelected, setItemSelected] = useState(false);
  const [quantityToCancel, setQuantityToCancel] = useState(0);
  const findedItem = selectedItems.find(s => s.i === i);
  const classes = useStyles();

  const processCheckedItem = () => {
    if (findedItem) {
      const filteredItems = selectedItems.filter(s => s.i !== i);
      setSelectedItems(filteredItems);
      setItemSelected(false);
    } else {
      const processedItem = {
        ...item,
        i,
        quantityToCancel,
        valueToCancel: item.valorBruto
      };
      const items = [...selectedItems, processedItem];
      if (items.length === orderItems.length) {
        setCancelAll('SIM');
      }
      setSelectedItems(items);
      setItemSelected(true);
    }
  };

  const processQuantity = value => {
    const filteredItems = selectedItems.filter(s => s.i !== i);
    const processedItem = {
      ...item,
      i,
      quantityToCancel: Number(value) || '',
      valueToCancel: item.valorBruto
    };
    const items = [...filteredItems, processedItem];
    setSelectedItems(items);
  };

  useEffect(() => {
    if (quantityToCancel === 0) setQuantityToCancel(item.quantidade);
  }, []);

  useEffect(() => {
    const filteredItems = selectedItems.find(
      s => s.partNumber === item.partNumber
    );
    if (filteredItems) {
      setItemSelected(true);
    } else {
      setItemSelected(false);
    }
  }, [selectedItems]);

  return (
    <StyledOrderItem key={Math.random()}>
      <div style={{ width: '30px' }}>
        {cancelAll === 'SIM' ? (
          <Checkbox checked disabled />
        ) : (
          <Checkbox
            onChange={() => {
              processCheckedItem();
            }}
            checked={itemSelected}
          />
        )}
      </div>
      <div style={{ width: '300px', color: '#434343' }}>
        {item.descricao}
        {item?.possuiGarantia === 'S' && (
          <div style={{ marginTop: '10px' }}>Garantia Estendida</div>
        )}
      </div>
      <div style={{ width: '100px', color: '#666666' }}>{item.partNumber}</div>
      <div style={{ width: '60px', color: '#666666' }}>{item.quantidade}</div>
      <div style={{ width: '60px' }}>
        <TextField
          disabled={!itemSelected}
          value={
            selectedItems.find(s => s.partNumber === item.partNumber)
              ?.quantityToCancel
          }
          size="small"
          variant="outlined"
          onKeyPress={event => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={e => {
            if (
              Number(e.target.value) <= item.quantidade ||
              e.target.value === ''
            ) {
              setQuantityToCancel(e.target.value);
              processQuantity(e.target.value);
            }
          }}
          InputProps={{
            className: classes.inputSmallProdutc
          }}
        />
      </div>
      <div
        style={{
          width: '75px',
          color: '#434343',
          fontSize: '13px',
          textAlign: 'right'
        }}
      >
        {toBRL(item.valorBruto)}
      </div>
      <div
        style={{
          width: '90px',
          color: '#434343',
          fontSize: '13px',
          textAlign: 'right'
        }}
      >
        {toBRL(item.totalBruto)}
      </div>
    </StyledOrderItem>
  );
};

export default OrderItem;
