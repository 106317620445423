/* eslint-disable react/no-array-index-key */
import {
  Box,
  CardContent,
  Chip,
  Divider,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import AcerModal from '../../../../../components/AcerModal';
import API from '../../../../../services/api';

const NewDeliveryDate = ({
  openState,
  closeParent,
  ticketId,
  idIncidenteCNOVA
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [open, setOpen] = useState(openState);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [formData, setFormData] = useState({});

  const onDrop = acceptedFiles => {
    const newFilesState = [...filesToUpload];
    // if (newFilesState.length + acceptedFiles.length > 4) {
    //   enqueueSnackbar(
    //     t('i18n.newticket.IMPOSSIBLE_TO_INCLUDE_MORE_THAN_4_FILES'),
    //     { variant: 'error' }
    //   );
    //   return;
    // }
    acceptedFiles.forEach(element => {
      if (element.size > 2000000) {
        enqueueSnackbar(
          t('i18n.newticket.THE_IMAGE') +
            element.path +
            t('i18n.newticket.CANNOT_BE_SENT'),
          { variant: 'error' }
        );
        return;
      }
      const reader = new FileReader();
      const fileByteArray = [];
      const base64file = [];
      // reader.readAsArrayBuffer(element);
      reader.readAsDataURL(element);
      reader.onloadend = evt => {
        if (evt.target.readyState === FileReader.DONE) {
          base64file.push(evt.currentTarget.result);
          const arrayBuffer = evt.target.result;
          const array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < array.length; i++) {
            fileByteArray.push(array[i]);
          }
        }
        newFilesState.push({
          path: element.path,
          binary: fileByteArray,
          data: base64file[0]
        });
      };
    });
    setFilesToUpload(newFilesState);
  };

  const handleClose = () => {
    closeParent();
    setOpen(false);
    setFilesToUpload([]);
    setFormData({});
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      'image/jpeg,image/png,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  });

  useEffect(() => {
    setOpen(openState);
  }, [openState]);

  const files = filesToUpload.map((file, index) => (
    <Chip
      key={index}
      style={{ margin: '2px' }}
      label={file.path}
      onDelete={() => {
        const newFilesState = [...filesToUpload];
        newFilesState.splice(index, 1);
        setFilesToUpload(newFilesState);
      }}
    />
  ));

  const defineNewDeliveryDate = async () => {
    const newDateResponse = {
      idIncidentCNOVA: idIncidenteCNOVA,
      ticketId,
      solutionId: 3657,
      ...formData,
      attachments:
        filesToUpload.length === 0
          ? null
          : filesToUpload.map(file => {
              return {
                fileName: file.path,
                data: file.data
              };
            })
    };
    try {
      await API.post(
        `/auth/ms-ticket/v1/tickets/cnova/new-delivery-date`,
        newDateResponse
      );
    } catch (error) {
      enqueueSnackbar(t('i18n.ticket.CNOVA_NEW_DELIVERY_DATE_FAIL'), {
        variant: 'error'
      });
    } finally {
      handleClose();
    }
  };

  return (
    <AcerModal
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleClose}
      modalTitle={t('i18n.cnova.phrases.newdeliverydate')}
      hasAction
      confirmActionLabel={t('i18n.simplewords.FINISH')}
      confirmActionFunc={defineNewDeliveryDate}
      cancelActionLabel={t('i18n.simplewords.CANCEL')}
    >
      <CardContent>
        <Box>
          <TextField
            InputLabelProps={{
              shrink: true
            }}
            type="date"
            variant="outlined"
            label={t('i18n.cnova.phrases.newdeliverydate')}
            onChange={e =>
              setFormData({
                ...formData,
                date: moment(e.target.value).format('YYYY-MM-DD')
              })
            }
          />
        </Box>
        <br />
        <TextField
          label={t('i18n.simplewords.COMMENTS')}
          fullWidth
          multiline
          variant="outlined"
          rows={3}
          onChange={e => setFormData({ ...formData, text: e.target.value })}
          // inputProps={{ maxLength: '255' }}
          // helperText={
          //   formData?.text?.length ? `${formData?.text?.length}/255` : `0/255`
          // }
        />
      </CardContent>
      <Divider />
      <CardContent>
        <Typography variant="overline">
          {t('i18n.newticket.SEND_FILES')}
        </Typography>
        <Paper
          {...getRootProps({ className: 'dropzone' })}
          style={{
            padding: '20px',
            border: 'dashed 2px #ccc',
            textAlign: 'center'
          }}
          variant="outlined"
        >
          <input {...getInputProps()} />
          <Typography>
            {t('i18n.newticket.DROP_FILES_HERE')} <br />
            {t('i18n.newticket.MAX_FILES')}
          </Typography>
        </Paper>
        <br />
        {files}
      </CardContent>
      <Divider />
    </AcerModal>
  );
};

export default NewDeliveryDate;
