import { Box, Grid } from '@material-ui/core';
import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import { MessagesIndicator } from '../styles';

export default function TabTitleWithIndicator({
  title,
  messagesCount,
  isMlClaim
}) {
  const messagesQtd = messagesCount?.length > 9 ? '+9' : messagesCount?.length;

  return !isMlClaim ? (
    <Box display="flex" alignItems="center">
      <div>{title}</div>
      {messagesCount?.length > 0 && (
        <div style={{ textAlign: 'flex-end' }}>
          <MessagesIndicator>{messagesQtd}</MessagesIndicator>
        </div>
      )}
    </Box>
  ) : (
    <Grid container style={{ paddingTop: '9px' }} spacing={2}>
      <Grid lg={2} item>
        <ErrorIcon style={{ fill: 'red' }} />{' '}
      </Grid>
      <Grid lg={10} item>
        <Box display="flex" alignItems="center">
          <div>{title}</div>
          {messagesCount?.length > 0 && (
            <div style={{ textAlign: 'flex-end' }}>
              <MessagesIndicator>{messagesQtd}</MessagesIndicator>
            </div>
          )}
        </Box>
        {/* <Typography variant="button">{title}</Typography> */}
      </Grid>
    </Grid>
  );
}
