import { Box, Container, Hidden, Tooltip } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Popover from '@material-ui/core/Popover';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { IconFlagBR, IconFlagUS } from 'material-ui-flags';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from '../../../i18n';

const LanguageButton = () => {
  const { t } = useTranslation();
  const [lang, setLang] = useState('pt_br');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event, newLang) => {
    setLang(newLang);
    changeLanguage(newLang);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    setLang(localStorage.getItem('language') ?? 'pt_br');
  }, []);

  return (
    <Hidden>
      <Tooltip
        title={t('i18n.topbar.LANGUAGE')}
        className="cursor-pointer"
        style={{ margin: '0 12px' }}
      >
        {lang === 'pt_br' ? (
          <div>
            <IconFlagBR color="inherit" onClick={handleClick} />
          </div>
        ) : (
          <div>
            <IconFlagUS color="inherit" onClick={handleClick} />
          </div>
        )}
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Container>
          <Box paddingTop={3} paddingBottom={3} justifyContent="center">
            <Box>
              <InputLabel id="demo-simple-select-label">
                {' '}
                {t('i18n.topbar.LANGUAGE')}{' '}
              </InputLabel>
            </Box>
            <Box display="flex" justifyContent="center" paddingTop={2}>
              <ToggleButtonGroup
                value={lang}
                exclusive
                onChange={handleChange}
                aria-label="text alignment"
              >
                <ToggleButton value="pt_br" aria-label="Português">
                  <IconFlagBR />
                </ToggleButton>
                <ToggleButton value="en" aria-label="English">
                  <IconFlagUS />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
        </Container>
      </Popover>
    </Hidden>
  );
};

export default LanguageButton;
