import React, { useState, useEffect } from 'react';
import { Box, Grid, Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Page from '../../../components/Page';
import { ChatTools } from './NewChatTools';
import { NewChatDetailsContext } from './NewChatDetailsContext';

const AlertNewConnection = ({ socket, setClientInAttendance }) => {
  /**
   * Ao se conectar numa nova aba do navegador, é exibido um alerta que avisa ao usuário
   * que ele está conectado em mais de um lugar.
   */

  let subscribe;

  const { t } = useTranslation();
  const reduxDispatcher = useDispatch();

  const [disconnect, setDisconnect] = useState(false);

  const initSubscribeSocket = () => {
    subscribe = socket?.newchatSubject.subscribe(value => {
      const response = JSON.parse(value.data);
      if (response?.system?.action === 'DISCONNECT') {
        setDisconnect(true);

        reduxDispatcher({
          type: 'DISCONNECT_CHAT',
          disconnect: true
        });
      }
    });
  };

  useEffect(() => {
    if (socket) {
      setDisconnect(false);
      initSubscribeSocket();
    }
  }, [socket]);

  useEffect(() => {
    setClientInAttendance({});
    reduxDispatcher({
      type: 'SET_CLIENT_IN_ATTENDANCE_NOW',
      clientInAttendanceNow: {}
    });
  }, [disconnect]);

  useEffect(() => {
    if (subscribe) {
      subscribe.unsubscribe();
    }
  }, []);

  return disconnect ? (
    <Box mt={3} mb={3}>
      <Alert variant="filled" severity="error">
        {t('i18n.chat.CHAT_NEW_CONNECTION_DETECT')}
      </Alert>
    </Box>
  ) : (
    <></>
  );
};

const NewChatsListView = () => {
  const reduxDispatcher = useDispatch();

  const [socket, setSocket] = useState(undefined);
  const [clientInAttendance, setClientInAttendance] = useState({});

  useEffect(() => {
    return () => {
      reduxDispatcher({
        type: 'SET_CLIENT_IN_ATTENDANCE_NOW',
        clientInAttendanceNow: {}
      });
    };
  }, []);

  return (
    <Page title="Chats">
      <Container maxWidth={false}>
        <AlertNewConnection
          socket={socket || undefined}
          setClientInAttendance={setClientInAttendance}
        />
        <Box mt={3} mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={4} md={3} lg={3}>
              <ChatTools socket={socket || undefined} setSocket={setSocket} />
            </Grid>
            <Grid item xs={8} md={9} lg={9}>
              <NewChatDetailsContext
                clientInAttendance={clientInAttendance}
                socket={socket || undefined}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default NewChatsListView;
