import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { inputStyles } from '../../../../components/inputStyle';

const Mediated = ({ filterForm, setFilterForm, handleRemoveShortcutFlag }) => {
  const { t } = useTranslation();
  const classes = inputStyles();
  return (
    <Box mb={1}>
      <FormControl
        component="fieldset"
        style={{
          display: 'flex',
          gap: '22px',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <FormLabel style={{ color: 'inherit' }}>
          {t('i18n.ticketslistview.MEDIATED')}
        </FormLabel>
        <FormGroup aria-label="position" row>
          <Box
            display="flex"
            flexDirection="row"
            style={{ marginInlineStart: '154px' }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checkboxFilter}
                  checked={filterForm.mediatedOpened || false}
                  onChange={event => {
                    setFilterForm({
                      ...filterForm,
                      mediatedOpened: event.target.checked
                    });
                    handleRemoveShortcutFlag();
                  }}
                  color="primary"
                />
              }
              label="Abertos"
            />
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checkboxFilter}
                  checked={filterForm.mediatedClosed || false}
                  onChange={event => {
                    setFilterForm({
                      ...filterForm,
                      mediatedClosed: event.target.checked
                    });
                    handleRemoveShortcutFlag();
                  }}
                  color="primary"
                />
              }
              label="Fechados"
            />
          </Box>
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default Mediated;
