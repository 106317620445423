/* eslint-disable no-underscore-dangle */
import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  Breadcrumbs,
  Typography,
  makeStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/Add';
import { StyledSpinner } from '../../../styles/components/SpinnerDefault';
import FetchContentError from '../../../components/FetchContentError';
import Page from '../../../components/Page';
import API from '../../../services/api';
import {
  CustomDataTableWrapper,
  CustomDataTable,
  CustomTableButton
} from '../../../styles/components/CustomDataTable';
import CustomFooter from '../../../styles/components/CustomFooter';
import AcerModal from '../../../components/AcerModal';

const useStyles = makeStyles(() => ({
  breadcrumbs: {
    marginTop: '15px'
  }
}));

const KnowledgeCategoriesListView = ({
  loadTableContent,
  customToolbar,
  customToolbarSelect,
  onRowSelectionChange
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [hasFetchError, setFetchError] = useState(false);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState({});
  const [categoryTitleToDelete, setCategoryTitleToDelete] = useState([]);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    {
      name: 'title',
      label: t('i18n.knowledge.TITLE'),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'business_type',
      label: t('i18n.ticketcontainer.TYPE'),
      options: {
        filter: false,
        sort: true
      }
    }
  ];

  const findRow = (id, rowData) => {
    const len = rowData.length;
    for (let i = 0; i < len; i++) {
      if (rowData[i].title === id) {
        return rowData[i];
      }
    }
  };

  const auxLoadTableContent = () => {
    setLoading(true);
    API.get(`/auth/lambda/knowledge/category`)
      .then((response) => {
        setData(response.data);
        setLoading(false);
        setFetchError(false);
        return response.data[0]._id.$oid;
      })
      .catch(() => {
        setFetchError(true);
      });
  };

  const refreshTable = () => {
    const load = loadTableContent || auxLoadTableContent;
    load();
  };

  useEffect(refreshTable, []);

  if (hasFetchError) {
    return <FetchContentError fetch={auxLoadTableContent} />;
  }

  const handleCategoryDelete = () => {
    setOpen(false);
    setLoading(true);
    API.post(`/auth/lambda/knowledge/category/delete`, categoryIdToDelete)
      .then(() => {
        setLoading(false);
        enqueueSnackbar(t('i18n.knowledge.DELETE_SECTION_SUCCESS'), {
          variant: 'success'
        });
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.knowledge.DELETE_SECTION_ERROR'), {
          variant: 'error'
        });
      })
      .finally(() => {
        auxLoadTableContent();
      });
  };

  const options = {
    filterType: 'checkbox',
    viewColumns: false,
    filter: false,
    onRowClick: (rowData) => {
      const row = findRow(rowData[0], data);
      history.push({
        pathname: `knowledge-base-categories/${row._id.$oid}`,
        state: { data: row }
      });
    },
    onRowsDelete: (deleteRow) => {
      const keys = Object.keys(deleteRow.lookup).map((s) => {
        return parseInt(s, 10);
      });

      const categoryTitle = [];
      const categories = keys.map((k) => {
        categoryTitle.push(data[k].title);
        return { _id: data[k]._id.$oid };
      });

      setOpen(true);
      setCategoryIdToDelete(categories);
      setCategoryTitleToDelete(categoryTitle);
    },
    customToolbar:
      customToolbar ||
      function aux() {
        return (
          <>
            <IconButton disabled>|</IconButton>
            <CustomTableButton
              variant="text"
              color="primary"
              size="small"
              onClick={() => {
                history.push({ pathname: 'knowledge-base-categories/new' });
              }}
              startIcon={<AddIcon />}
            >
              {t('i18n.knowledge.CREATE_CATEGORY')}
            </CustomTableButton>
          </>
        );
      },
    customToolbarSelect,
    onRowSelectionChange,
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: !loading
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: (column) =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };

  return (
    <>
      <Page title={t('i18n.knowledge.SECTIONS')}>
        <Container maxWidth={false}>
          <Box mt={3}>
            <CustomDataTableWrapper style={{ opacity: loading ? '0.3' : '1' }}>
              <CustomDataTable
                title={
                  <Breadcrumbs
                    className={classes.breadcrumbs}
                    gutterBottom
                    aria-label="breadcrumb"
                  >
                    <Typography variant="h6">
                      <Link color="inherit" to="/knowledge-base">
                        {t('i18n.knowledge.FAQ')}
                      </Link>
                    </Typography>
                    <Typography variant="h6" color="textPrimary">
                      {t('i18n.knowledge.SECTIONS')}
                    </Typography>
                  </Breadcrumbs>
                }
                data={data}
                columns={columns}
                options={options}
                selectableRowsOnClick
              />
            </CustomDataTableWrapper>
            <StyledSpinner style={{ display: loading ? 'block' : 'none' }}>
              <CircularProgress />
            </StyledSpinner>
          </Box>
        </Container>
      </Page>
      <AcerModal
        maxWidth="md"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        modalTitle={`${t('i18n.knowledge.DELETE_CONFIRMATION')}?`}
        hasAction
        confirmActionLabel={t('i18n.simplewords.CONFIRM')}
        confirmActionFunc={() => handleCategoryDelete()}
        cancelActionLabel={t('i18n.simplewords.CANCEL')}
        cancelActionFunc={() => setOpen(false)}
      >
        <Typography>{`${t('i18n.knowledge.DELETE_WARNING')}!`}</Typography>
        {categoryTitleToDelete &&
          categoryTitleToDelete.map((item) => (
            <Typography key={item}>
              <strong>{item}</strong>
            </Typography>
          ))}
      </AcerModal>
    </>
  );
};

export default KnowledgeCategoriesListView;
