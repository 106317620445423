import React from 'react';
import CancellationAlertItem from './CancellationAlertItem';

const CancellationAlertList = ({ fraudListKeys, chat, classes }) => {
  return (
    <>
      {fraudListKeys?.map(item => {
        const ticketType = item === 'cancellations' ? 'Cancelamento' : 'Troca';

        const show = ticketType === chat?.reasonNew?.name;

        return (
          <CancellationAlertItem
            key={Math.random()}
            quantity={
              chat?.historicCancellations
                ? chat?.historicCancellations[item]?.length
                : 0
            }
            {...{ ticketType, show, classes }}
          />
        );
      })}
    </>
  );
};

export default CancellationAlertList;
