import { Box, Grid } from '@material-ui/core';
import React from 'react';
import FilterInput from '../../../../components/FilterInput.component';

const MarketplaceTicket = ({ filterForm, handleSetFilter }) => {
  return (
    <Box mb={2}>
      <Grid container>
        <Grid item xs={7}>
          <FilterInput
            {...{
              form: filterForm,
              setForm: handleSetFilter,
              name: 'protocoloMarketplace',
              label: 'Protocolo Marketplace'
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MarketplaceTicket;
