const infoCards = ['delaySending', 'cancelling', 'cr', 'nps'];

const getTitle = type => {
  switch (type) {
    case 'cancelling':
      return 'Cancelamentos';
    case 'delaySending':
      return 'Pedidos entregues no Prazo';
    case 'cr':
      return 'Contato com Cliente (CR)';
    case 'nps':
      return 'NPS (Experiência de Compra)';
    default:
      return '';
  }
};

const getMagaluScoreHint = item => {
  switch (item) {
    case 'cancelling':
      return 'Total de cancelamentos solicitados pelo cliente ou pela loja antes do despacho';
    case 'delaySending':
      return 'Pedidos que foram finalizados e entregues no prazo correto';
    case 'cr':
      return 'Indicador de experiência do cliente';
    case 'nps':
      return 'Indica a satisfação dos clientes com a loja e seu potencial de recomendação';
    default:
      return '';
  }
};

export { infoCards, getTitle, getMagaluScoreHint };
