import styled from 'styled-components';
import { spacing } from '../settings/spacing.config';

export const StyledSubmitActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  button:first-child {
    margin-right: ${spacing[2]}px;
  }
`;
