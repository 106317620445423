/* eslint-disable camelcase */
import {
  Chip,
  FormControlLabel,
  Checkbox,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  Fab,
  Popover
} from '@material-ui/core';

import { Add } from '@material-ui/icons';

import { useSnackbar } from 'notistack';
import React, { useEffect, useReducer, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Loader from '../../components/Loader';
import Page from '../../components/Page';
import config from '../../config';
import API from '../../services/api';
import { CustomBreadCrumbs } from '../../styles/components/CustomDataTable';

import { options as marketplaceOptions, hasItem } from './services';

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  textEditWidth: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  tableScrollBody: {
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  tableScrollContainer: {
    height: '200px'
  },
  tableRow: {
    border: 'none'
  },
  breadcrumbs: {
    marginTop: '15px',
    marginLeft: '15px'
  }
}));

const AtalhosButton = ({ updateMensagem }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseButton = () => {
    setAnchorEl(null);
  };

  const [options, setOptions] = useState([]);

  const default_keys = ['cliente.nome', 'cliente.email', 'saudacao'];

  const getKeys = () => {
    API.get(`${config.backend.macroURL}/shortcuts?origin=fast`)
      .then(response => {
        const { data } = response;
        const temp = [];
        data.shortcuts.forEach(item => {
          temp.push({ label: item, value: `{{${item}}}` });
        });
        setOptions(temp);
      })
      .catch(() => {
        const temp = [];
        default_keys.forEach(item => {
          temp.push({ label: item, value: `{{${item}}}` });
        });
        setOptions(temp);
      });
  };

  const addItem = i => {
    updateMensagem(i.value);
  };

  useEffect(() => {
    getKeys();
  }, []);

  return (
    <>
      <Tooltip title="Adicionar atalhos">
        <Fab size="small" color="primary" onClick={handleClick}>
          <Add />
        </Fab>
      </Tooltip>
      <Popover
        id="simple-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseButton}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Container>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box paddingTop={3}>
              <Typography variant="caption">Atalhos</Typography>
            </Box>
            {options.map(item => (
              <Box key={item.label} paddingTop={1} paddingBottom={1}>
                <Chip
                  label={item.label}
                  onClick={() => {
                    addItem(item);
                  }}
                />
              </Box>
            ))}
          </Box>
        </Container>
      </Popover>
    </>
  );
};

const QuickAnswersEdit = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [macroId, setMacroId] = useState();
  const [nome, setNome] = useState();
  const [grupo, setGrupo] = useState('ALL');
  const [grupos, setGrupos] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [acoes, setAcoes] = useState();
  const [mensagem, setMensagem] = useState();
  const [editing, setEditing] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [title, setTitle] = useState(t('i18n.quickanswer.ADD_A_QUICK_ANSWER'));
  const [type, setType] = useState({});

  const submitData = () => {
    forceUpdate();
    const actions = [];

    if (mensagem) {
      actions.push({
        INSERT_MESSAGE: {
          description: '',
          value: mensagem
            .replace(/\n/g, '\\n')
            .replace(/\r/g, '\\r')
            .replace(/\t/g, '\\t')
        }
      });
    }

    if (!nome) {
      enqueueSnackbar(t('i18n.macros.EMPTY_FIELD_NAME'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      return;
    }

    if (
      !type.pre &&
      !type.preMl &&
      !type.chat &&
      !type.saudacao_pre_venda_off &&
      !type.chatB2b
    ) {
      enqueueSnackbar(t('i18n.quickanswer.SELECT_ERROR_QUICK_ANSWER_OPTION'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      return;
    }

    const types = Object.keys(type);

    const processType = [];

    types.forEach(tItem => {
      if (hasItem(tItem)) {
        if (type[tItem]) {
          processType.push(tItem);
        }
      }
    });

    const data = {
      name: nome,
      description: '',
      type: processType,
      user_group: grupo,
      actions
    };

    if (!editing) {
      API.post(config.backend.macroURL, data)
        .then(() => {
          history.push('/quick-answers');
        })
        .catch(error => {
          if (error?.response?.data?.error === 'REGISTER_EXISTS') {
            enqueueSnackbar(t('i18n.quickanswer.TYPE_DEFAULT_ALREADY_EXISTS'), {
              variant: 'error'
            });
          } else {
            enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
              variant: 'error'
            });
          }
        });
    } else {
      data.id = macroId;
      API.put(config.backend.macroURL, data)
        .then(() => {
          history.push('/quick-answers');
        })
        .catch(() => {
          enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          });
        });
    }
  };

  const getGroups = () => {
    API.get(`${config.backend.url}/auth/ms-users/groups`).then(response => {
      const { data } = response;
      const temp = [];
      data.forEach(item => {
        temp.push(item.name);
      });
      setGrupos(temp);
      setLoaded(true);
    });
  };

  useEffect(() => {
    setType({
      saudacao_pre_venda_off: false,
      chat: false,
      pre: false,
      chatB2b: false
    });
    getGroups();
    const editData = history?.location?.state;
    if (!editData) return;
    setTitle(t('i18n.quickanswer.CHANGE_QUICK_ANSWER'));
    setMacroId(editData.id);
    setNome(editData.nome);
    setAcoes(editData.acoes);
    setEditing(editData.editing);
    setGrupo(editData.grupos);

    const newType = {};

    editData.type.forEach(item => {
      if (item !== 'null') {
        newType[item] = true;
      }
    });

    setType(newType);

    let oMensagem;
    const len = editData?.acoes?.length;
    if (!len || len === 0) return;
    for (let i = 0; i < len; i++) {
      if (editData?.acoes[i]?.INSERT_MESSAGE?.value)
        oMensagem = editData?.acoes[i]?.INSERT_MESSAGE?.value;
    }

    let formattedMessage = '';
    const mx = oMensagem.split('\\n');
    mx.forEach(m => {
      formattedMessage += `${m}\n`;
    });

    setMensagem(formattedMessage);
  }, []);

  const inserirAtalho = atalho => {
    const msg = mensagem || '';
    setMensagem(msg + atalho);
  };

  const getActions = () => {
    const customActions = [
      {
        id: 3,
        action: (
          <Typography variant="caption">
            {`${t('i18n.macros.CHANGE')} ${t('i18n.macros.MESSAGE')}`}
          </Typography>
        ),
        value: (
          <Box display="flex" flexDirection="row">
            <Box paddingRight={2} flexGrow={1}>
              <TextField
                variant="outlined"
                label={t('i18n.knowledge.ANSWER')}
                fullWidth
                multiline
                minRows={4}
                value={mensagem}
                size="small"
                onChange={ev => {
                  setMensagem(ev.target.value);
                }}
                onBlur={ev => {
                  setMensagem(ev.target.value);
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box>
              <AtalhosButton updateMensagem={inserirAtalho} />
            </Box>
          </Box>
        )
      }
    ];

    return customActions.map(row => (
      <TableRow key={row.id}>
        {/* <TableCell>{row.action}</TableCell> */}
        <TableCell className={classes.tableRow}>{row.value}</TableCell>
      </TableRow>
    ));
  };

  const handleChange = e => {
    const { id, checked } = e.target;
    if (id === 'saudacao_pre_venda_off' && checked) {
      setType({ [id]: checked, chat: false, pre: false });
    } else {
      setType({ ...type, [id]: checked });
    }
  };

  return (
    <Page
      className={classes.root}
      title={t('i18n.dashboardlayout.QUICK_ANSWERS')}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxwidth="false">
          <CustomBreadCrumbs
            className={classes.breadcrumbs}
            gutterBottom
            aria-label="breadcrumb"
          >
            <Typography variant="h6">
              <Link
                onClick={() => {
                  history.push('/quick-answers');
                }}
                color="inherit"
                to="/quick-answers"
              >
                {t('i18n.quickanswer.ANSWERS')}
              </Link>
            </Typography>
            <Typography variant="h6" color="textPrimary">
              {title}
            </Typography>
          </CustomBreadCrumbs>
          {loaded ? (
            <Box mb={3}>
              <Card>
                <CardContent>
                  <Box display="flex" flexDirection="column">
                    <Box paddingBottom={2}>
                      <TextField
                        variant="outlined"
                        label={t('i18n.macros.NAME')}
                        fullWidth
                        value={nome}
                        size="small"
                        onChange={ev => {
                          setNome(ev.target.value);
                        }}
                      />
                    </Box>
                    <Box paddingBottom={2}>
                      <InputLabel size="small" id="select-grupos">
                        {t('i18n.macros.GROUPS')}
                      </InputLabel>
                      <Select
                        size="small"
                        labelId="select-grupos"
                        variant="outlined"
                        fullWidth
                        value={grupo}
                        onChange={ev => {
                          setGrupo(ev.target.value);
                        }}
                        onBlur={ev => {
                          setGrupo(ev.target.value);
                        }}
                      >
                        <MenuItem value="ALL">Todos</MenuItem>
                        {grupos
                          ? grupos.map(item => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </Box>
                    <Box alignItems="center" display="flex">
                      <Typography variant="subtitle1" color="textSecondary">
                        {t('i18n.quickanswer.USE_ANSWER_FOR')}&nbsp;
                      </Typography>

                      {marketplaceOptions.map(item => (
                        <FormControlLabel
                          key={item.value}
                          control={
                            <Checkbox
                              checked={type[item.value]}
                              color="primary"
                              id={item.value}
                              onChange={handleChange}
                              disabled={type[item.disabled]}
                            />
                          }
                          label={t(item.label)}
                        />
                      ))}
                    </Box>
                    <Box paddingBottom={1}>
                      <Box display="flex" flexDirection="column">
                        <Box paddingTop={3}>
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              size="small"
                              // aria-label="a dense table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      variant="subtitle1"
                                      color="textSecondary"
                                    >
                                      {t(
                                        'i18n.quickanswer.TYPE_QUICK_ANSWER_BELOW'
                                      )}
                                    </Typography>
                                  </TableCell>
                                  {/* <TableCell>
                                    {t('i18n.macros.VALUE')}
                                  </TableCell> */}
                                </TableRow>
                              </TableHead>
                              <TableBody>{getActions()}</TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                    >
                      <Box paddingRight={2}>
                        <Button
                          variant="contained"
                          color="default"
                          onClick={() => {
                            history.push('/quick-answers');
                          }}
                        >
                          {t('i18n.macros.CANCEL')}
                        </Button>
                      </Box>
                      <Box paddingRight={3}>
                        <Button
                          disabled={!mensagem}
                          variant="contained"
                          color="primary"
                          onClick={submitData}
                        >
                          {t('i18n.macros.SAVE')}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          ) : (
            <Loader />
          )}
        </Container>
      </Box>
    </Page>
  );
};

export default QuickAnswersEdit;
