import { Grid, Button } from '@material-ui/core';
import { CallMade } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AcerModal from '../../../../components/AcerModal';
import { COLORS } from '../../../../styles/settings/colors.config';

const ButtonSubject = styled(Button)`
  margin-top: 1em;
  width: 100%;
  &:hover {
    background-color: ${COLORS.btnSuccessColor};
    color: ${COLORS.whiteText};
  }
  justify-content: left;
`;

const B2wClassificationModal = ({
  openState,
  closeParent,
  classification,
  submitSelection
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(openState);

  const handleClose = () => {
    closeParent();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(openState);
  }, [openState]);

  return (
    <AcerModal
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      modalTitle={`${t(
        'i18n.ticketcontainer.B2W_MODAL_CLASSIFICATION_TITLE'
      )}:`}
    >
      <div>
        {classification?.map(option => {
          return (
            <Grid
              key={option.id}
              container
              alignItems="stretch"
              direction="column"
            >
              <Grid item sm={12}>
                <ButtonSubject
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullwidth="true"
                  onClick={() => submitSelection(option.id, option.description)}
                >
                  {option.description}
                </ButtonSubject>
              </Grid>
            </Grid>
          );
        })}
        <Grid container alignItems="stretch" direction="column">
          <Grid item sm={12}>
            <ButtonSubject
              variant="outlined"
              color="primary"
              size="large"
              fullwidth="true"
              onClick={() => submitSelection(null, 'SUPPORT')}
            >
              SUPORTE MARKETPLACE <CallMade />
            </ButtonSubject>
          </Grid>
        </Grid>
      </div>
    </AcerModal>
  );
};

export default B2wClassificationModal;
