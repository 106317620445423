import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Typography } from '@material-ui/core';
import Check from '../../../../../icons/b2w/Check';
import { numberToBRL } from '../../../../../helpers/utils';
import Attending from '../../../../../icons/b2w/Attending';
import {
  BronzeMedal,
  DiamondMedal,
  GoldMedal,
  SilverMedal
} from '../../../../../icons/b2w/StoreMedals';
import {
  svgTheme,
  svgThemeDanger,
  svgThemeGray,
  svgThemeSuccess
} from '../theme/theme';
import Computer from '../../../../../icons/b2w/Computer';
import Cancel from '../../../../../icons/b2w/Cancel';

const SuccessIcon = ({ children }) => (
  <ThemeProvider theme={svgThemeSuccess}>{children}</ThemeProvider>
);

const DangerIcon = ({ children }) => (
  <ThemeProvider theme={svgThemeDanger}>{children}</ThemeProvider>
);

const GrayIcon = ({ children }) => (
  <ThemeProvider theme={svgThemeGray}>{children}</ThemeProvider>
);

const storeLevelList = [
  {
    level: 'Loja Faixa 5',
    title: 'Loja Diamante',
    icon: <DiamondMedal theme={svgTheme} />,
    text: 'Conhecida no mercado e',
    textBold: 'bem avaliada',
    descriptionHint: 'Loja Diamante – muda de acordo com a reputação'
  },
  {
    level: 'Loja Faixa 4',
    title: 'Loja Ouro',
    icon: <GoldMedal theme={svgTheme} />,
    text: 'Campeã de vendas e',
    textBold: 'bem avaliada',
    descriptionHint: 'Loja Ouro – muda de acordo com a reputação'
  },
  {
    level: 'Loja Faixa 3',
    title: 'Loja Prata',
    icon: <SilverMedal theme={svgTheme} />,
    text: 'Alto volume de vendas e',
    textBold: 'bem avaliada',
    descriptionHint: 'Loja Prata – muda de acordo com a reputação'
  },
  {
    level: 'Loja Faixa 2',
    title: 'Loja Bronze',
    icon: <BronzeMedal theme={svgTheme} />,
    text: 'Destaque de vendas e',
    textBold: 'bem avaliada',
    descriptionHint: 'Loja Bronze – muda de acordo com a reputação'
  }
];

const infoCards = ['delaySending', 'mediated', 'cancelled'];

const NonAttendanceText = () => (
  <Typography>
    Consiga o selo melhorando o indicador de pedidos mediados para <b>3</b>
  </Typography>
);

const OnTimeText = () => (
  <Typography>
    Entrega <b>no prazo</b>
  </Typography>
);

const getReputationCards = (currentStoreLevel, score_b2w) => [
  {
    type: 'store',
    title: currentStoreLevel?.title,
    icon: currentStoreLevel?.icon,
    text: currentStoreLevel?.text,
    textBold: currentStoreLevel?.textBold,
    descriptionHint: currentStoreLevel?.descriptionHint
  },
  {
    type: 'entrega',
    title: 'Entrega',
    icon: score_b2w?.loyalty_selo_no_prazo ? (
      <SuccessIcon>
        <Check theme={svgThemeSuccess} />
      </SuccessIcon>
    ) : (
      <DangerIcon>
        <Cancel theme={svgThemeDanger} />
      </DangerIcon>
    ),
    text: score_b2w?.loyalty_selo_no_prazo ? (
      <OnTimeText />
    ) : (
      'Não oferece entrega no prazo'
    ),
    descriptionHint:
      'Entrega no Prazo – Indicador de pedidos com atraso no envio é superior a 3'
  },
  {
    type: 'atendimento',
    title: 'Atendimento',
    icon: (
      <GrayIcon>
        <Attending theme={svgThemeGray} />
      </GrayIcon>
    ),
    text: score_b2w?.loyalty_selo_bom_atendimento ? (
      'Bom Atendimento'
    ) : (
      <NonAttendanceText />
    ),
    descriptionHint:
      'Bom atendimento – Indicador de bom atendimento é superior a 3'
  },
  {
    type: 'sales',
    title: 'Vendas nos últimos 30 dias',
    icon: (
      <GrayIcon>
        <Computer theme={svgThemeGray} />
      </GrayIcon>
    ),
    sales: numberToBRL(score_b2w?.loyalty_gmv_mes),
    shippments: score_b2w?.loyalty_qtd_pedidos_mes,
    descriptionHint:
      'Bom atendimento – Indicador de bom atendimento é superior a 3'
  }
];

const getTitle = type => {
  switch (type) {
    case 'mediated':
      return (
        <>
          Pedidos <b>Mediados</b>
        </>
      );
    case 'delaySending':
      return (
        <>
          Pedidos com <b>atraso no envio</b>
        </>
      );
    case 'cancelled':
      return (
        <>
          Pedidos <b>Cancelados</b>
        </>
      );
    default:
      return '';
  }
};

const getB2WScoreHint = type => {
  switch (type) {
    case 'mediated':
      return `Aqui a gente considera os pedidos mediados pelo nosso SAC, 
        exceto pedidos Americanas Entrega que tiveram problemas na entrega ou 
        que estavam atrasados e em trânsito. Pedidos com retirada na loja, Entrega 
        Full ou Americanas Entrega também não são considerados.`;
    case 'delaySending':
      return `Aqui a gente considera os pedidos enviados ao transportador após o 
        prazo previsto. Pedidos do Entrega Full ou com retirada na loja não são considerados.`;
    case 'cancelled':
      return `Aqui a gente considera os pedidos cancelados por erro operacional, como 
        produto divergente ou com defeito, expedidos fora do prazo  ou quando você solicitou 
        o cancelamento. Pedidos do Entrega Full ou com retirada na loja não são considerados.`;

    default:
      return '';
  }
};

export {
  storeLevelList,
  infoCards,
  getReputationCards,
  getTitle,
  getB2WScoreHint
};
