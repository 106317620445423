/* eslint-disable react/no-array-index-key */
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Tooltip
} from '@material-ui/core';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ThumbDown, ThumbUp } from '@material-ui/icons';
import API from '../../../services/api';
import { COLORS } from '../../../styles/settings/colors.config';
import timezoneFormat from '../../../utils/timezoneFormat';
import { MessageItem } from './components/MessageItem.component';
import './components/messages.css';

const CustomHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 47px;
  width: 100%;
`;

const CustomTypography = styled(Typography)`
  cursor: pointer;
  color: ${COLORS.greenText};
`;

const MessagesBox = styled('div')`
  min-height: 200px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: #eee;
  padding: 20px;
  @media (min-width: 1400px) {
    min-height: 500px;
    max-height: 500px;
  }
`;

const MessageClientDetails = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ChatWrapper = styled('div')`
  padding: 10px 15px 0px 15px;
`;

const ChatItem = styled('div')`
  margin-bottom: 10px;
  ${props =>
    props.ajust &&
    'margin-bottom: 0px; padding-left: 15px; padding-right: 15px;'}
`;

const ChatItemIcon = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: -2px;
`;

const ChatText = styled('p')`
  font-size: ${props => (props.size ? props.size : '16px')};
  color: ${props => (props.color ? props.color : COLORS.grayText)};
  bold: ${props => (props.bold ? props.bold : 'normal')};
  padding: ${props => (props.padding ? props.padding : '0px 15px')};
`;

const ChatTextBold = styled('p')`
  font-weight: bold;
  font-size: ${props => (props.size ? props.size : '16px')};
  color: ${props => (props.color ? props.color : COLORS.grayText)};
  bold: ${props => (props.bold ? props.bold : 'normal')};
  padding: ${props => (props.padding ? props.padding : '0px 15px')};
`;

const ChatScroll = styled('div')`
  max-height: 300px;
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ChatLinkCard = styled('a')`
  background-color: ${COLORS.whiteText};
  padding: 0px 15px 3px 15px;
  display: block;
  color: ${COLORS.greenText};
  :hover {
    text-decoration: underline;
  }
`;

const ChatLinkTitle = styled('p')`
  font-size: smaller;
`;

// id equals hashConnection from MessageWrapper.component.js and \ChatContainer\index.js
const NewChatHistoricChatDetails = ({ id, setPreviousChatsView }) => {
  const { t } = useTranslation();
  const [messages, setMessages] = React.useState([]);
  const [pageHistory, setPageHistory] = React.useState([]);
  const [chat, setChat] = React.useState({});
  const [login, setLogin] = React.useState('');

  const distinctPages = allmessages => {
    const clientMessages = allmessages.filter(item => item.login === null);
    const unique = [];
    const distinct = [];
    for (let i = 0; i < clientMessages.length; i++) {
      if (!unique[clientMessages[i].messagePage.parentURL]) {
        distinct.push({
          parentURL: clientMessages[i].messagePage.parentURL,
          parentTitle: clientMessages[i].messagePage.parentTitle
        });
        unique[clientMessages[i].messagePage.parentURL] = 1;
      }
    }
    setPageHistory(distinct);
  };

  const renderMessage = () => {
    if (chat.chatCustomerOpinion?.opinion) {
      return chat.chatCustomerOpinion?.opinion.split('\n').map(i => {
        return (
          <ChatTextBold key={Math.random()} align="left">
            {i}
          </ChatTextBold>
        );
      });
    }
    return (
      <ChatTextBold align="left">
        {`(${t('i18n.chatview.EVALUATION_NO_COMMENT')})`}
      </ChatTextBold>
    );
  };

  const renderOpinion = () => {
    if (!chat.chatCustomerOpinion) {
      return (
        <ChatTextBold>{`(${t('i18n.chatview.EVALUATION_NONE')})`}</ChatTextBold>
      );
    }
    if (chat.chatCustomerOpinion?.satisfactionScore === true) {
      return (
        <>
          <ChatItemIcon>
            <ChatText>{`${t('i18n.chatview.EVALUATION_POSITIVE')}:`}</ChatText>
            <ThumbUp color="primary" style={{ marginBottom: '7px' }} />
          </ChatItemIcon>
          {renderMessage()}
        </>
      );
    }
    if (chat.chatCustomerOpinion?.satisfactionScore === false) {
      return (
        <>
          <ChatItemIcon>
            <ChatText>{`${t('i18n.chatview.EVALUATION_NEGATIVE')}:`}</ChatText>
            <ThumbDown
              style={{ marginBottom: '7px', color: COLORS.btnDangerColor }}
            />
          </ChatItemIcon>
          {renderMessage()}
        </>
      );
    }
  };

  useEffect(() => {
    API.get(`/auth/ms-chat/chats/${id}`).then(resp => {
      setMessages(resp.data.simpleMessages);
      setChat(resp.data);
      distinctPages(resp.data.simpleMessages);
    });
  }, []);

  useEffect(() => {
    if (messages?.length > 0) {
      const attendantMessages = messages?.filter(item => {
        return item.login;
      });
      setLogin(attendantMessages[0]?.login);
    }
  }, [messages]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={3} style={{ display: 'Flex' }}>
        <Card style={{ width: '100%' }}>
          <CardContent>
            <CustomHeader>
              <MessageClientDetails>
                {setPreviousChatsView ? (
                  <CustomTypography
                    variant="h5"
                    onClick={() => {
                      setPreviousChatsView('list');
                    }}
                  >
                    {t('i18n.ticketcontainer.COMEBACK_TO_LIST')}
                  </CustomTypography>
                ) : (
                  <Typography variant="h4">
                    {t('i18n.ticketcontainer.CLIENT_DATA')}
                  </Typography>
                )}
              </MessageClientDetails>
            </CustomHeader>
          </CardContent>
          <Divider />
          <ChatWrapper>
            <ChatItem>
              <ChatText>{t('i18n.ticketcontainer.CLIENT_NAME')}:</ChatText>
              <ChatTextBold>{chat.name}</ChatTextBold>
            </ChatItem>
            <ChatItem>
              <ChatText>{t('i18n.CAP.email')}:</ChatText>
              <ChatTextBold>{chat.email}</ChatTextBold>
            </ChatItem>
            <ChatItem>
              <ChatText>{t('i18n.trackingmodal.PLACE')}:</ChatText>
              <ChatTextBold>{chat.local || 'N/A'}</ChatTextBold>
            </ChatItem>
            <ChatItem>
              <ChatText>IP:</ChatText>
              <ChatTextBold>{chat.ip || 'N/A'}</ChatTextBold>
            </ChatItem>
            <ChatItem>
              <ChatText>{t('i18n.chatview.SPECIALIST')}:</ChatText>
              <ChatTextBold>{login}</ChatTextBold>
            </ChatItem>
            <ChatItem>
              <ChatText>Status:</ChatText>
              <ChatTextBold>{chat.statusChat}</ChatTextBold>
            </ChatItem>
            <ChatItem>
              <ChatText>{t('i18n.ticketcontainer.REASON')}:</ChatText>
              <ChatTextBold>{chat.contactingMainReason || 'N/A'}</ChatTextBold>
            </ChatItem>
            <ChatItem>
              <ChatText>{t('i18n.ticketcontainer.REASON_DETAIL')}:</ChatText>
              <ChatTextBold>{chat.contactingSubReason || 'N/A'}</ChatTextBold>
            </ChatItem>
            <ChatItem>{renderOpinion()}</ChatItem>
          </ChatWrapper>
          <Divider />
          <ChatWrapper>
            <ChatItem>
              <ChatText>{t('i18n.chatview.PAGES_HISTORY')}:</ChatText>
            </ChatItem>
            <ChatScroll>
              {pageHistory
                .map((item, index) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={`${item?.parentURL}${index}`}>
                      <ChatItem>
                        <ChatLinkCard href={item?.parentURL} target="_blank">
                          <Tooltip title={item?.parentURL || ''}>
                            <ChatLinkTitle>
                              {item?.parentTitle?.substr(0, 83)}
                            </ChatLinkTitle>
                          </Tooltip>
                        </ChatLinkCard>
                      </ChatItem>
                    </div>
                  );
                })
                .reverse()}
            </ChatScroll>
          </ChatWrapper>
        </Card>
      </Grid>
      <Grid item xs={9}>
        <Card>
          <CardContent>
            <CustomHeader>
              <MessageClientDetails>
                <Typography variant="h4" align="center">
                  {t('i18n.chatview.TITLE_HISTORY')}
                </Typography>
                <Typography variant="h4" align="center">
                  {t('i18n.ticketcontainer.RA_DATE')}:{' '}
                  {timezoneFormat(chat.created)}
                </Typography>
              </MessageClientDetails>
            </CustomHeader>
          </CardContent>
          <Divider />
          <CardContent>
            <MessagesBox id="message-box">
              {messages?.map(
                (item, index) =>
                  item && (
                    <MessageItem
                      key={index}
                      name={item.name}
                      email={item.email}
                      message={item.text || item.message}
                      hour={item.date}
                      login={item.login}
                      isAttendant={!(item.name || item.email)}
                      nameAttachment={item.name_attachment}
                    />
                  )
              )}
            </MessagesBox>
            <br />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export { NewChatHistoricChatDetails };
