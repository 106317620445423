import { Tooltip, IconButton, Grid, Card, Tabs, Tab } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLastLocation } from 'react-router-last-location';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useDispatch } from 'react-redux';
import { ArrowDropDown, ArrowDropUp, Cancel } from '@material-ui/icons';
import styled from 'styled-components';
import FetchContentError from '../../../components/FetchContentError';
import API from '../../../services/api';
import { COLORS } from '../../../styles/settings/colors.config';
import {
  CustomDataTableWrapper,
  CustonVisitorsDatatable
} from '../../../styles/components/CustomDataTable';
import CustomFooter from '../../../styles/components/CustomFooter';

import tableHelper from '../../../utils/tableHelper';

import { searchB2bOnCurrentUrl } from './services/notificationCount';
import { HelpIcon, CustomText } from './components/HelpIcon.component';

const NewChatVisitors = ({ showVisitorsTable }) => {
  const reduxDispatcher = useDispatch();

  const { t } = useTranslation();
  const lastLocation = useLastLocation();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasFetchError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(0);
  const [totalElements, setTotalElements] = useState('');

  const refreshTableContent = async (page = 0) => {
    setLoading(true);

    try {
      const response = await API.get(
        `/auth/ms-chat/chats/all-visitors/?page=${page}&businessType=${
          !searchB2bOnCurrentUrl() ? 'B2C' : 'B2B'
        }`
      );

      reduxDispatcher({
        type: 'VISITORS_ONLINE',
        visitorsOnline: response?.data?.visitorData.length
      });

      setData(response.data);

      setTotalElements(response.data?.visitorPageData?.totalRecords);
      setSize(response.data?.visitorPageData?.pageSize);
    } catch (error) {
      // eslint-disable-next-line
      console.warn(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    refreshTableContent();
  }, []);

  const defaultColumOptions = {
    filter: false,
    sort: false
  };

  const columns = [
    { name: 'visitorIp', label: 'IP' },
    {
      name: 'visitorName',
      label: 'Visitante no.'
    },
    {
      name: 'onlineTime',
      label: 'Tempo no Site',
      options: {
        filter: false,
        customHeadLabelRender: () => (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <p>Tempo no Site</p>
            <HelpIcon tooltip="Tempo contado a partir da primeira página visitada no site. Contabiliza enquanto estiver com o site aberto, ou seja, o tempo total deste IP dentro do site da Acer Store" />
          </div>
        )
      }
    },

    {
      name: 'parentTitle',
      label: t('i18n.visitorsList.VISITING'),
      options: {
        filter: false,
        customBodyRender: value => {
          return <CustomText text={value} primary />;
        }
      }
    },
    {
      name: 'onlineTime',
      label: 'Tempo na página',
      options: {
        filter: false
      }
    },
    {
      name: 'referrer',
      label: t('i18n.visitorsList.REFERRER'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          if (value === 'NOT FOUND') {
            return <span>Acer Store</span>;
          }
          return (
            <>
              <span>{value}</span>
            </>
          );
        }
      }
    },
    {
      name: 'hash_connection_chat',
      label: 'Outras páginas',
      options: {
        customBodyRender: value => {
          return (
            <>
              <span style={{ display: 'none' }}>{value}</span>
            </>
          );
        },
        ...defaultColumOptions
      }
    }
  ];

  if (lastLocation?.pathname.search('ticket-container') === -1) {
    sessionStorage.setItem('lastTicketsPage', 0);
    lastLocation.pathname = 'ticket-container';
  }

  useEffect(() => {
    let isSubscribed = true;
    if (showVisitorsTable && isSubscribed) {
      refreshTableContent();
    }

    return () => {
      isSubscribed = false;
    };
  }, [showVisitorsTable]);

  if (hasFetchError) {
    return <FetchContentError fetch={() => refreshTableContent()} />;
  }

  const RenderButton = ({ items, opened, setOpened }) => {
    if (items?.length === 0) {
      return (
        <span style={{ display: 'flex', justifyContent: 'center' }}>
          <Cancel style={{ color: COLORS.lightGray }} />
        </span>
      );
    }

    return (
      <span style={{ display: 'flex', justifyContent: 'center' }}>
        {opened ? (
          <ArrowDropUp
            style={{ color: COLORS.greenText }}
            onClick={() => setOpened(!opened)}
          />
        ) : (
          <ArrowDropDown
            style={{ color: COLORS.greenText }}
            onClick={() => setOpened(!opened)}
          />
        )}
      </span>
    );
  };

  const BoldedTd = styled.td`
    font-weight: bold;
    min-width: ${props => props.tdMinWidth || '100px'};
    max-width: ${props => props.tdMaxWidth || '100px'};
    color: ${props => props.tdColor || 'black'};
    border-top: ${props => props.tdBorderTop || '1px solid #ccc !important'};
    border-bottom: ${props => props.tdBorderBottom || '1px solid #ccc'};
  `;

  const CustomRow = props => {
    const [opened, setOpened] = useState(false);
    const { dataIndex } = props;
    const itemData = data.visitorData[dataIndex];
    const { items } = itemData;
    return (
      <>
        <tr style={{ fontFamily: 'Roboto' }}>
          <BoldedTd tdColor={COLORS.greenText}>
            <CustomText text={itemData.visitorIp} primary />
          </BoldedTd>
          <BoldedTd>
            <CustomText text={itemData.visitorName.split(' ').pop()} />
          </BoldedTd>
          <BoldedTd>
            <CustomText text={itemData.oldestPageTime} />
          </BoldedTd>
          <BoldedTd BoldedTd tdMinWidth="200px" tdMaxWidth="200px">
            <CustomText text={itemData.parentTitle} primary />
          </BoldedTd>
          <BoldedTd tdMinWidth="150px" tdMaxWidth="150px">
            <CustomText text={itemData.onlineTime} />
          </BoldedTd>
          <BoldedTd>
            <a
              href={
                itemData.referrer === '	Não identificado'
                  ? ''
                  : itemData.referrer
              }
              target="_blank"
              style={{ color: COLORS.greenText }}
              rel="noreferrer"
            >
              {itemData.referrer.slice(0, 30)}
              {itemData.referrer.length > 30 && '...'}
            </a>
          </BoldedTd>

          <BoldedTd>
            <RenderButton {...{ items, opened, setOpened }} fontSize="large" />
          </BoldedTd>
        </tr>

        {items?.length > 0 &&
          opened &&
          items?.map(item => {
            return (
              <tr
                style={{
                  fontFamily: 'Roboto'
                }}
                key={Math.random()}
              >
                {/* eslint-disable-next-line */}
                <td style={{ backgroundColor: 'whitesmoke' }} />
                <td>{item.visitorName.split(' ').pop()}</td>
                {/* eslint-disable-next-line */}
                <td />
                <td>{item.parentTitle}</td>
                <td>{item.onlineTime}</td>
                <td>
                  <a
                    href={
                      item.referrer === '	Não identificado' ? '' : item.referrer
                    }
                    target="_blank"
                    style={{ color: COLORS.greenText }}
                    rel="noreferrer"
                  >
                    {item.referrer.slice(0, 30)}
                    {item.referrer.length > 30 && '...'}
                  </a>
                </td>
                {/* eslint-disable-next-line */}
                <td style={{ backgroundColor: 'whitesmoke' }} />
              </tr>
            );
          })}
      </>
    );
  };

  const options = {
    filter: false,
    pagination: true,
    viewColumns: false,
    selectableRows: 'none',
    print: false,
    search: false,
    sort: false,
    rowsPerPage: size,
    rowsPerPageOptions: [20],
    count: parseInt(totalElements, 10),
    serverSide: true,
    download: false,
    page: currentPage,
    customRowRender: (rowData, dataIndex) => (
      <CustomRow key={Math.random()} {...{ rowData, dataIndex, data }} />
    ),
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        if (currentPage !== tableState.page) {
          refreshTableContent(tableState.page);
          setCurrentPage(tableState.page);
        }
      }
    },
    setRowProps: (row, index) => ({
      style: tableHelper.setRowStyle(row, index, data)
    }),
    customToolbar: () => {
      return (
        <Tooltip title={t('i18n.groupcontainer.REFRESH')}>
          <IconButton onClick={() => refreshTableContent()} aria-label="delete">
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      );
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          rowsPerPageOptions={[20]}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: !loading
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: column =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };

  return (
    <>
      <Card>
        <Grid container>
          <Grid item xs={12}>
            <CustomDataTableWrapper style={{ opacity: loading ? '0.3' : '1' }}>
              <Tabs value={0} textColor="primary" indicatorColor="primary">
                <Tab
                  label={t('i18n.chatview.VISITORS_ONLINE')}
                  style={{ fontSize: 'small' }}
                />
              </Tabs>
              <div
                role="tabpanel"
                id="simple-tabpanel-0"
                aria-labelledby="simple-tab-0"
                style={{ backgroundColor: '#f5f5f5' }}
              >
                <CustonVisitorsDatatable
                  data={data?.visitorData}
                  columns={columns}
                  options={options}
                  selectableRowsOnClick
                  className="table-clickable"
                />
              </div>
            </CustomDataTableWrapper>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export { NewChatVisitors };
