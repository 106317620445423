const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const containsSpecialChars = str => {
  const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  return specialChars.test(str);
};

const validaForm = (formData, setFormdata) => {
  const newState = { ...formData };
  let valid = true;
  Object.entries(newState).forEach(value => {
    const index = value[0];
    const object = value[1];
    const error =
      (!object.value?.trim() && object.required) ||
      (object.integerNumber &&
        !Number.isInteger(Number(object.value.trim()))) ||
      (object.timeField &&
        (object.value.length !== 5 ||
          Number(object.value.substring(0, 2)) >= 99)) ||
      (object.masked && object.required && object.value.includes('_')) ||
      (index === 'cpf' && object.cnpjOnly && object.value.length !== 14) ||
      (index === 'cpf' &&
        (object.value.length < 11 ||
          object.value.length === 12 ||
          object.value.length === 13)) ||
      (index === 'email' && object.required && !validateEmail(object.value));
    newState[index].error = error;
    if (error) {
      valid = false;
    }
  });
  setFormdata(newState);
  return valid;
};

const setFormError = (formData, setFormdata, key) => {
  const newErrorFieldFormData = formData[key];
  newErrorFieldFormData.error = true;
  const newFormData = { ...formData, newErrorFieldFormData };
  setFormdata(newFormData);
};

const setFormvalue = (formData, setFormdata, key, value) => {
  const newState = { ...formData };
  newState[key].value = value;
  setFormdata(newState);
};

const existsEmptySpaces = field => {
  return field.includes(' ');
};

const telefoneFlex = (
  key,
  formData,
  setFormdata,
  setMascaraTelefone,
  htmlElemtEvent
) => {
  const inputedValue = htmlElemtEvent.target.value;
  setFormvalue(formData, setFormdata, key, inputedValue);
  if (formData[key].value.includes('_')) {
    setMascaraTelefone('(99)9999-9999');
    const numberOfUnderlines = (inputedValue.match(/_/g) || []).length;
    let newNumber;
    if (numberOfUnderlines === 1) {
      newNumber = inputedValue.substring(0, inputedValue.length - 1);
    }
    setFormvalue(formData, setFormdata, key, newNumber);
  } else {
    setMascaraTelefone('(99)99999-9999');
  }
};

const formData2PostData = formData => {
  const postData = {};
  Object.entries(formData).forEach(value => {
    const index = value[0];
    const object = value[1];
    postData[index] = object.value;
    if (object.leaveOnlyNumbersWhenSubmit) {
      postData[index] = object.value.replace(/\D/g, '');
    }
  });
  delete postData.newErrorFieldFormData;
  return postData;
};

const simpleData2FormData = formData => {
  Object.entries(formData).forEach(value => {
    const index = value[0];
    if (!formData[index].value) {
      formData[index].value = '';
    }
  });
  return formData;
};

const cpfCnpjFormat = value => {
  if (value) {
    value = value.replace(/\D/g, '');
    if (value.length === 11) {
      value = value.replace(/(\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else if (value.length === 14) {
      value = value.replace(/^(\d{2})(\d)/, '$1.$2');
      value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
      value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
      value = value.replace(/(\d{4})(\d)/, '$1-$2');
    } else {
      value = value.replace(/\D/g, '');
    }
  }
  return value;
};

// Recebe esse exemplo de String:    "01/08/2021 06:33 - 12/08/2021 06:34"
// Retorna Array com dois elementos: ["2021-08-01", "2021-08-12"]
const formatPeriodDate = period => {
  const originalInitial = period
    .split('-')[0]
    .substr(0, 10)
    .split('/');
  const originalFinal = period
    .split('-')[1]
    .substr(0, 10)
    .split('/');
  return [
    `${originalInitial[2]}-${originalInitial[1]}-${originalInitial[0]}`,
    `${originalFinal[2]}-${originalFinal[1]}-${originalFinal[0]}`
  ];
};

// Provisório enquanto a API de Pedidos para B2W apenas retorna dados para número de Pedido igual ao N. de Entrega
// from 02-832016258 to 283201625801
const convertOrderidtoDeliveryid = orderNum => {
  return `${orderNum.substring(1).replace('-', '')}01`;
};

const compareName = (a, b) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
};

const compareNameInlist = (a, b) => {
  const nameA = a.label.toUpperCase();
  const nameB = b.label.toUpperCase();
  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
};

const compareOrder = (a, b) => {
  let comparison = 0;
  if (a.order > b.order) {
    comparison = 1;
  } else if (a.order < b.order) {
    comparison = -1;
  }
  return comparison;
};

const compareOrderChats = (a, b) => {
  let comparison = 0;
  if (a.chats_per_clerks < b.chats_per_clerks) {
    comparison = 1;
  } else if (a.chats_per_clerks > b.chats_per_clerks) {
    comparison = -1;
  }
  return comparison;
};

const compareCreationDate = (a, b) => {
  let comparison = 0;
  if (a.creationDate > b.creationDate) {
    comparison = -1;
  } else if (a.creationDate < b.creationDate) {
    comparison = 1;
  }
  return comparison;
};

const compareItemId = (a, b) => {
  let comparison = 0;
  if (a.marketplaceBundle.item_id > b.marketplaceBundle.item_id) {
    comparison = -1;
  } else if (a.marketplaceBundle.item_id < b.marketplaceBundle.item_id) {
    comparison = 1;
  }
  return comparison;
};

const capitalize = word => {
  let wordTransformed = word.toLowerCase();
  wordTransformed =
    wordTransformed.charAt(0).toUpperCase() + wordTransformed.slice(1);
  return wordTransformed;
};

function reverseArr(input) {
  if (!input) {
    return [];
  }
  const ret = [];
  for (let i = input.length - 1; i >= 0; i--) {
    ret.push(input[i]);
  }
  return ret;
}

const numberFormatDashboard = number => {
  if (number?.length > 2 || !number?.length) {
    const num = Intl.NumberFormat('pt-BR', {
      maximumSignificantDigits: 6
    }).format(number);
    return num;
  }
  return number;
};

const addZeroToCompleteTwoDigits = value => {
  if (value < 10 && value >= 0 && typeof value === 'number') {
    return `0${value}`;
  }
  if (value === '0') {
    return `0${value}`;
  }

  return numberFormatDashboard(value);
};

const isHourValid = string => {
  const parts = string.split(':');

  if (parts[0] < 24 && parts[1] < 59) {
    return true;
  }
  return false;
};

const formatHour = time => {
  const hour = `${time?.replace(':', 'h').split(':')[0]}m`;
  return hour;
};

export default {
  validaForm,
  setFormvalue,
  telefoneFlex,
  formData2PostData,
  simpleData2FormData,
  validateEmail,
  setFormError,
  existsEmptySpaces,
  cpfCnpjFormat,
  formatPeriodDate,
  convertOrderidtoDeliveryid,
  compareName,
  compareNameInlist,
  compareOrder,
  addZeroToCompleteTwoDigits,
  compareCreationDate,
  capitalize,
  compareItemId,
  reverseArr,
  containsSpecialChars,
  numberFormatDashboard,
  compareOrderChats,
  isHourValid,
  formatHour
};
