import { Card, Grid, Box } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { Description } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../styles/settings/colors.config';
import API from '../../../services/api';

const StyledCard = styled(Card)`
  border: 1px solid #b7b7b7;
  border-radius: 8px;
  padding: 10px 20px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 12px;
  margin-top: 10px;
`;

const StyledTitle = styled.div`
  font-weight: bold;
  color: #434343;
  display: flex;
  justify-content: flex-start;
  margin: 0px 0px 10px 0px;
`;

const File = ({ content, marketplace }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleDownload = async id => {
    try {
      const {
        data
      } = await API.get(
        `/auth/ms-ticket/v1/marketplace-juridical/download-juridical?attachmentId=${id}&marketplace=${marketplace}`,
        { responseType: 'blob' }
      );
      const downloadUrl = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = content.name;
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
        variant: 'error'
      });
    }
  };

  return (
    <Box
      display="flex"
      style={{ color: COLORS.greenText, cursor: 'pointer' }}
      alignItems="center"
      onClick={() => handleDownload(content.attachmentId)}
    >
      <Description size="small" />
      <span>{content.name}</span>
    </Box>
  );
};

export default function LegalProcessFiles({ protocolData }) {
  const { t } = useTranslation();
  const { attachment, marketplace } = protocolData;
  return (
    <StyledCard>
      <Grid container>
        <Grid item xs={12}>
          <StyledTitle>{t('i18n.legalprotocol.ATTACHMENTS')}</StyledTitle>
          <Box display="flex" flexDirection="column">
            {attachment?.map(file => (
              <File content={file} marketplace={marketplace} />
            ))}
          </Box>
        </Grid>
      </Grid>
    </StyledCard>
  );
}
