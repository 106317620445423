import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SelectGroupComponent from '../../../../components/Select.group.component';
import reasonList from '../../../../utils/reasonList';

const FilterReasonGroups = ({ filterForm, handleSetFilterReason }) => {
  const { t } = useTranslation();
  const reasons = reasonList();
const groupedData = reasons?.reduce((result, item) => {
  if (item.group !== filterForm.reasonGroup) return result; 
  // eslint-disable-next-line no-shadow
  const group = result?.find((group) => group?.label === item?.group);
  if (group) {
    group.itens.push({ name: item?.name });
  } else {
    result.push({ label: item?.group, itens: [{ name: item?.name }] });
  }
  return result;
}, []);
  const [subList, setSubList] = useState([]);

  const handleFilterData = () => {
    const filteredGroup = groupedData.find(
      (group) => group.label === filterForm.reasonGroup
    );
   
    const newSubList = filteredGroup
      ? [{ label: filteredGroup.label, itens: filteredGroup.itens }]
      : [];
    setSubList(newSubList);
    
  };
  useEffect(() => {
    handleFilterData();
  }, [filterForm]);

  return (
    <Box mb={2}>
      <Grid container>
        <Grid item xs={7}>
          <SelectGroupComponent
            form={filterForm}
            setForm={handleSetFilterReason}
            label={t('i18n.ticketcontainer.REASON_LABEL')}
            name="reasonName"
            gapOptions="0px"
            options={subList}
            disabled={filterForm.reasonGroup === undefined}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterReasonGroups;
