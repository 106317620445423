import React from 'react';
import styled from 'styled-components';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Tooltip, Typography } from '@material-ui/core';
import { COLORS } from '../../../../styles/settings/colors.config';

export const CustomHelpIcon = styled(HelpOutlineIcon)`
  color: ${COLORS.greenText} !important;
  svg: {
    opacity: 1 !important;
  }
`;

export const CustomTypography = styled(Typography)`
  color: ${props =>
    props.primary === 'true' ? COLORS.greenText : COLORS.grayText} !important;
  font-size: 12px;
  font-weight: bold;
`;

const HelpIcon = ({ tooltip }) => {
  return (
    <Tooltip title={tooltip}>
      <CustomHelpIcon />
    </Tooltip>
  );
};

const CustomText = ({ text, primary }) => {
  return (
    <CustomTypography primary={primary ? 'true' : 'false'}>
      {text}
    </CustomTypography>
  );
};

export { HelpIcon, CustomText };
