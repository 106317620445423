export const getParamsInUrl = () => {
  const local = window.location.href;
  const url = new URL(local);
  const c = url.searchParams.get('query');
  const useQueryParam = sessionStorage.getItem('useFilterInParams');
  if (useQueryParam === 'false') {
    return null;
  }
  return JSON.parse(c);
};
