import { Grid } from '@material-ui/core';
import React from 'react';
import {
  SectionContentTitle,
  SectionContentWrapper,
  SectionCustomGrid
} from './SectionTabs';

const ProductListHeader = ({ t }) => {
  return (
    <Grid container spacing={2}>
      <Grid item lg={4} xs={4}>
        <SectionContentWrapper>
          <SectionContentTitle>Item:</SectionContentTitle>
        </SectionContentWrapper>
      </Grid>
      <SectionCustomGrid item lg={2} xs={2}>
        <SectionContentWrapper>
          <SectionContentTitle>
            {t('i18n.ticketcontainer.QUANTITY')}:
          </SectionContentTitle>
        </SectionContentWrapper>
      </SectionCustomGrid>
      <SectionCustomGrid item lg={2} xs={2}>
        <SectionContentWrapper>
          <SectionContentTitle>
            {t('i18n.ticketcontainer.UNIT_VALUE')}:
          </SectionContentTitle>
        </SectionContentWrapper>
      </SectionCustomGrid>
      <SectionCustomGrid item lg={2} xs={2}>
        <SectionContentWrapper>
          <SectionContentTitle>Desconto:</SectionContentTitle>
        </SectionContentWrapper>
      </SectionCustomGrid>
      <SectionCustomGrid item lg={2} xs={2}>
        <SectionContentWrapper>
          <SectionContentTitle>
            {t('i18n.ticketcontainer.TOTAL_VALUE')}:
          </SectionContentTitle>
        </SectionContentWrapper>
      </SectionCustomGrid>
    </Grid>
  );
};

export default ProductListHeader;
