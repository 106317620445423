import { Card, CardContent, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AcerModal from '../../../../components/AcerModal';
import RaStatusTranslate from '../raStatusTranslate';

const ModalTitleStatus = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ConfirmationModal = ({
  openState,
  data,
  closeParent,
  linkRATicket,
  hasAction = true
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(openState);
  const [ticketData, setData] = useState(data);

  const internalLinkRATicket = ticketId => {
    linkRATicket(ticketId);
  };

  useEffect(() => {
    setOpen(openState);
    setData(data);
  }, [openState, ticketData]);

  return (
    <>
      <AcerModal
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={closeParent}
        modalTitle={`RA #${data.idReclameAquiAPI} - ${moment(
          data.creationDate
        ).format('DD/MM/YYYY HH:mm')}`}
        hasAction={hasAction}
        cancelActionLabel={t('i18n.simplewords.CANCEL')}
        confirmActionLabel={
          data.linked
            ? t('i18n.ticket.RECLAMEAQUI_ASSIGN_UNLINK')
            : t('i18n.ticket.RECLAMEAQUI_ASSIGN_LINK')
        }
        confirmActionFunc={() => internalLinkRATicket(data.idReclameAquiAPI)}
      >
        <ModalTitleStatus>
          <Typography color="textPrimary" variant="h4">
            {data.complaintTitle}
          </Typography>
          <Typography color="textPrimary" variant="h4">
            <RaStatusTranslate status={data.idStatusReclameAqui} />
          </Typography>
        </ModalTitleStatus>
        <br />
        <Card>
          <CardContent>
            <Typography color="textPrimary">
              Descrição: <br /> {data.complaintContent}
            </Typography>
          </CardContent>
        </Card>
      </AcerModal>
    </>
  );
};

export default ConfirmationModal;
