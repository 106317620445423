import {
  TextField,
  Button,
  TablePagination,
  TableFooter
} from '@material-ui/core';
import styled from 'styled-components';
import { COLORS } from '../../../styles/settings/colors.config';

const CustomTablePagination = styled(TablePagination)`
  width: 100%;
  .MuiTablePagination-spacer {
    display: none;
  }
`;
const CustomTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    color: ${COLORS.greenText};
  }
`;

const CustomTableFooter = styled(TableFooter)`
  display: grid;
`;

const CustomTableFooterButton = styled(Button)`
  text-transform: none;
`;

export {
  CustomTablePagination,
  CustomTextField,
  CustomTableFooter,
  CustomTableFooterButton
};
