import { Button, Tooltip } from '@material-ui/core';
import React from 'react';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import userService from '../../../../services/user.service';
import tableHelper from '../../../../utils/tableHelper';
import CustomFooter from '../../../../styles/components/CustomFooter';
import { sortTableData } from '../functions';
import { assertTicketDetailsUrl } from '../helpers/assertTicketsDetailUrl';

export const getOptions = (
  pageData,
  currentPage,
  refreshTableContent,
  setDefaultSortChanged,
  sortData,
  setSortData,
  setCurrentPage,
  data,
  setSelectedRowsState,
  enqueueSnackbar,
  t,
  history,
  setTicketsListIds,
  setOpenProtocolAttribuition,
  ticketsListIds,
  selectedRowsState,
  loading
) => {
  const options = {
    filter: false,
    pagination: true,
    filterType: 'checkbox',
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRows: true,
    print: false,
    search: false,
    rowsPerPage: pageData?.size,
    rowsPerPageOptions: [20],
    count: pageData?.totalElements,
    serverSide: true,
    download: false,
    page: currentPage,
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        if (tableState.page !== currentPage) {
          refreshTableContent(tableState.page);
        }
      }
      if (action === 'sort') {
        setDefaultSortChanged(true);
        sortTableData(tableState, sortData, setSortData);
        sessionStorage.setItem('lastTicketsPage', 0);
        setCurrentPage(0);
      }
    },
    setRowProps: (row, index) => {
      tableHelper.setRowStyle(row, index, data);
    },
    onRowSelectionChange: (current, all) => {
      setSelectedRowsState(all);
    },
    onCellClick: (colData, cellMeta) => {
      if (cellMeta.colIndex === 0) {
        if (!userService.hasRole('ticket_detail')) {
          enqueueSnackbar(t('i18n.ticketslistview.DETAIL_DISABLED'), {
            variant: 'warning'
          });
        }
        return;
      }
      if (userService.hasRole('ticket_detail')) {
        const { id, marketPlace } = data[cellMeta.rowIndex];
        history.push({
          pathname: assertTicketDetailsUrl(marketPlace?.toUpperCase(), id)
        });
      } else {
        enqueueSnackbar(t('i18n.ticketslistview.DETAIL_DISABLED'), {
          variant: 'warning'
        });
      }
    },
    customToolbarSelect: (selectedRows) => {
      const keys = Object.keys(selectedRows.lookup).map((s) => {
        return parseInt(s, 10);
      });
      const listIds = keys.map((k) => {
        return data[k].id;
      });
      const handleEnable = async () => {
        setTicketsListIds(listIds);
        setOpenProtocolAttribuition(true);
      };
      return (
        <>
          <div>
            <Tooltip title={t('i18n.protocolView.ATTRIBUITION_PROTOCOLS')}>
              <Button
                color="primary"
                size="small"
                onClick={() => handleEnable()}
                startIcon={<PlaylistAddCheckIcon />}
              >
                {t('i18n.protocolView.ATTRIBUITION_PROTOCOLS')}
              </Button>
            </Tooltip>
          </div>
        </>
      );
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          textLabels={textLabels}
          rowsPerPage={rowsPerPage}
          page={page}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          rowsPerPageOptions={[20]}
          ticketsListIds={ticketsListIds}
          selectedRowsState={selectedRowsState}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: !loading
          ? t('i18n.datatable.body.NOMATCH')
          : t('i18n.datatable.body.NOMATCH_LOADING'),
        toolTip: t('i18n.datatable.body.TOOLTIP'),
        colummHeaderTooltip: (column) =>
          `${t('i18n.datatable.body.COLUMN_HEADER_TOOLTIP')} ${column.label}`
      },
      selectedRows: {
        text: t('i18n.datatable.selectedrows.TEXT'),
        delete: t('i18n.datatable.selectedrows.DELETE'),
        deleteAria: t('i18n.datatable.selectedrows.DELETEARIA')
      },
      pagination: {
        next: t('i18n.datatable.pagination.NEXT'),
        previous: t('i18n.datatable.pagination.PREVIOUS'),
        rowsPerPage: t('i18n.datatable.pagination.ROWSPERPAGE'),
        displayRows: t('i18n.datatable.pagination.DISPLAYROWS')
      }
    }
  };

  return options;
};
