import React, { useState } from 'react';
import { Box, Popover, Typography } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing(1)
  }
}));

const SchedulePopover = ({ id, agendamento }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  let colorSchedule = '#434645';
  let none = '';
  let textSchedule = '';

  if (agendamento?.status === 'ACTIVE') {
    colorSchedule = '#5280D1';
    textSchedule = 'Agendamento Ativo';
  } else if (agendamento?.status === 'EXPIRES_TODAY') {
    colorSchedule = '#F79E4F';
    textSchedule = 'Agendamento Vence hoje';
  } else if (agendamento?.status === 'EXPIRED') {
    colorSchedule = '#D9363C';
    textSchedule = 'Agendamento Vencido';
  } else {
    none = 'none';
  }

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography
        aria-owns={open ? `mouse-over-popover${id}` : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <ScheduleIcon style={{ color: colorSchedule, display: `${none}` }} />
      </Typography>
      <Popover
        id={`mouse-over-popover${id}`}
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box
          display="flex"
          flexDirection="column"
          padding={1}
          maxWidth={280}
          minWidth={280}
          height="100%"
        >
          <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
            Dados do Agendamento
          </Typography>
          <Typography>
            <b>Data/Hora:</b>{' '}
            {moment(agendamento?.scheduledDateTime).format('DD/MM/YYYY HH:mm')}
          </Typography>
          <Typography>
            <b>Status:</b>{' '}
            <b style={{ color: colorSchedule }}>{textSchedule}</b>
          </Typography>
          <Typography>
            <b>Descrição:</b> {agendamento?.description}
          </Typography>
        </Box>
      </Popover>
    </div>
  );
};

export default SchedulePopover;
