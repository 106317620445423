import React from 'react';
import { COLORS } from '../../../../../../../../styles/settings/colors.config';
import { StyledDot } from '../styles';
import PositionIcon from './PositionIcon';

export default function Dot({ status, statusCondition, endProcess, position }) {
  const bgColor = () => {
    if (
      status.includes('Recusado') ||
      status.includes('cancelada') ||
      status.includes('não efetivado')
    )
      return COLORS.timeline.errorBackgroundColor;
    if (statusCondition === 'passed')
      return COLORS.timeline.passedBackgroundColor;
    if (statusCondition === 'actual')
      return COLORS.timeline.actualBackgroundColor;
    if (endProcess) return COLORS.timeline.passedBackgroundColor;
    return COLORS.timeline.defaultBackgroundColor;
  };

  const borderColor = () => {
    if (
      status.includes('Recusado') ||
      status.includes('cancelada') ||
      status.includes('não efetivado')
    )
      return COLORS.timeline.errorBackgroundColor;
    if (statusCondition === 'passed') return COLORS.timeline.passedBorderColor;
    if (statusCondition === 'actual') return COLORS.timeline.actualBorderColor;
    if (endProcess) return COLORS.timeline.passedBackgroundColor;
    return COLORS.timeline.defaultBorderColor;
  };

  return (
    <StyledDot
      bgColor={bgColor || COLORS.timeline.defaultBackgroundColor}
      border={borderColor}
    >
      <PositionIcon {...{ status, endProcess, position, statusCondition }} />
    </StyledDot>
  );
}
