import { Grid } from '@material-ui/core';
import React from 'react';
import {
  GridData,
  GridDivider,
  GridLabel
} from '../../../styles/components/Cards';
import { CustomTypographLink } from '../styles';

const IndividualDataCard = ({
  firstLabel,
  secondaryLabel,
  handleOpenNewTab,
  firstData,
  secondaryData,
  withDrillDown,
  title
}) => {
  
  return (
    <Grid container justifyContent="center">
      <GridLabel item xs={5}>
        {firstLabel}
      </GridLabel>
      <Grid item xs={2} />
      <GridLabel item xs={5}>
        {secondaryLabel}
      </GridLabel>
      <GridData item xs={5}>
        {withDrillDown ? (
          <CustomTypographLink
            align="right"
            style={{
              fontSize: '30px',
              fontWeight: '500'
            }}
            onClick={() => {
              handleOpenNewTab(title);
            }}
          >
            {firstData}
          </CustomTypographLink>
        ) : (
          <span style={{ color: '#5f676c' }}>{firstData}</span>
        )}
      </GridData>
      <GridDivider item xs={2}>
        |
      </GridDivider>
      <GridData item xs={5}>
        {secondaryData}
      </GridData>
    </Grid>
  );
};

export default IndividualDataCard;
