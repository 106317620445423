export default [
  'NEW',
  'CUSTOMER_RETURN',
  'RESOLVED',
  'CLOSED',
  'AWAITING',
  'ANSWERED',
  "AWAITING_COMMERCIAL",
  'AWAITING_REFUND',
  'AWAITING_CLOSED',
  'AWAITING_FISCAL',
  'AWAITING_FINANCIAL',
  'AWAITING_LOGISTICS',
  'AWAITING_MARKETING',
  'AWAITING_PARTNER',
  'AWAITING_CLIENT_POST',
  'AWAITING_RA',
  'AWAITING_TI',
];



