import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { COLORS } from '../settings/colors.config';

export const GridLabel = styled(Grid)`
  display: flex;
  justify-content: center;
  font-family: Roboto;
  font-size: 12px;
  color: #999999;
`;

export const GridData = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  color: ${COLORS.dashboardTeamDataGray};
  font-size: 30px;
  align-items: center;
  font-weight: 500;
`;

export const GridDivider = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  color: #d9d9d9;
`;
