/* eslint-disable */
import React, { useState, useContext, createContext } from 'react';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { COLORS } from '../../styles/settings/colors.config';

const useLight = makeStyles(theme => ({
  root: {
    backgroundColor: '#F4F6F8',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    paddingBottom: 24
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    backgroundColor: '#F4F6F8',
    paddingTop: 0,
    paddingLeft: 0
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    backgroundColor: '#F4F6F8'
  },
  content: {
    flex: '1 1 auto',
    backgroundColor: '#F4F6F8',
  }
}));

const useDark = makeStyles(theme => ({
  root: {
    backgroundColor: '#484644',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    paddingBottom: 24
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    backgroundColor: '#484644',
    paddingTop: 0,
    paddingLeft: 0
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    backgroundColor: '#484644'
  },
  content: {
    flex: '1 1 auto',
    backgroundColor: '#484644',
  }
}));

const theme = {
  light: {
    color: COLORS.grayText,
    warning: COLORS.grayText,
    warningV2: COLORS.grayText,
    warningV3: COLORS.WarningV3,
    warningV4: COLORS.grayText,
    success: COLORS.whiteText,
    successV2: COLORS.grayText,
    successV3: COLORS.greenText,
    danger: COLORS.whiteText,
    dangerV2: COLORS.btnDangerColor,
    cardBackground: COLORS.whiteText
  },
  dark: {
    color: COLORS.whiteText,
    warning: '#FFE600',
    warningV2: COLORS.WarningV2,
    warningV3: '#FF981F',
    warningV4: '#FF981F',
    warningV4: '#FF981F',
    success: '#69FF66',
    successV2: '#69FF66',
    successV3: '#69FF66',
    danger: '#FD2F2F',
    dangerV2: '#FD2F2F',
    cardBackground: '#2B2B30',
    font32: '32px',
    font30: '30px',
    font28: '28px',
    font24: '24px',
    font20: '20px',
    padding8: '8px',
    padding16: '16px',
    rounded0: '0px',
    m1: 1,
    m2: 2,
    m3: 3,
    m4: 4,
    margin45: '45px'
  }
}

const ThemeContext = createContext(theme.light);

const MonitorLayout = ({ children }) => {
  const { screen } = useParams();

  const classes = screen !== 'tv' ? useLight() : useDark();
  const themeProvider = screen !== 'tv' ? theme.light : theme.dark;

  return (
    <ThemeContext.Provider value={{ ...themeProvider, ...{ screen } }}>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>{children}</div>
          </div>
        </div>
      </div>
    </ThemeContext.Provider>
  );
};

export { MonitorLayout, ThemeContext };
