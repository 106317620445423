import React, { useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import HttpInterceptor from './auth/HttpInterceptor';
import GlobalStyles from './components/GlobalStyles';
import './mixins/chartjs';
import Routes from './Routes';
import { store } from './stores/userStatusStore';
import GlobalAppStyle from './styles/GlobalApp.styles';

const App = () => {
  {
    useEffect(() => {
      setInterval(() => {
        store.dispatch({ type: 'CHANGE_COUNTER' });
      }, 5000);

      // window.addEventListener('message', e => {
      //   console.log(e);
      // });

      window.addEventListener('click', (e) => {
        if (!localStorage.getItem('busy')) {
          const text = document.getElementById('busy')?.innerText;
          if (e.target.id === 'busy') {
            if (text !== 'Em outra atividade') {
              store.dispatch({ type: 'CHANGE_STATUS', status: 'active' });
              return false;
            }
            return false;
          }
          if (localStorage.getItem('accessToken')) {
            store.dispatch({ type: 'CHANGE_STATUS', status: 'active' });
          }
          return false;
        }
      });

      window.addEventListener('keypress', () => {
        if (!localStorage.getItem('busy')) {
          if (localStorage.getItem('accessToken')) {
            store.dispatch({ type: 'CHANGE_STATUS', status: 'active' });
          }
          return false;
        }
      });

      if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
        const factor = 0.95;
        const all = document.getElementsByTagName('*');
        for (let i = 0, max = all.length; i < max; i++) {
          const style = window.getComputedStyle(all[i]);
          const fontSize = style.getPropertyValue('font-size');

          if (fontSize) {
            all[i].style.fontSize = `${parseFloat(fontSize) * factor}px`;
          }
          if (all[i].nodeName === 'IMG') {
            const width = style.getPropertyValue('width');
            const height = style.getPropertyValue('height');
            all[i].style.height = `${parseFloat(height) * factor}px`;
            all[i].style.width = `${parseFloat(width) * factor}px`;
          }
        }
      } else {
        window.document.body.style.zoom = '90%';
        // window.location.pathname !== '/dashboardPanel' && '90%';
      }
    }, []);

    return (
      <div>
        <HttpInterceptor>
          <GlobalStyles />
          <GlobalAppStyle />
          <Routes />
        </HttpInterceptor>
      </div>
    );
  }
};

export default App;
