import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import AcerModal from '../../../../../components/AcerModal';
import API from '../../../../../services/api';

const ModalText = styled('p')`
  font-size: 16px;
  line-height: 1.5em;
`;

const ModalTextField = styled(TextField)`
  margin-top: 1em;
`;

const CustomTableCell = styled(TableCell)`
  background: #eee;
`;

const ModalGrid = styled(Grid)`
  margin-top: 20px;
`;

const ModalGridButton = styled(Grid)`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ModalReportProblem = ({
  instance,
  ticketId,
  deliveryId,
  openState,
  closeParent
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [textFieldDelivery, setTextFieldDelivery] = useState('');
  const [reportCode, setReportCode] = useState('');

  const postData = {
    ticketId,
    dataTrocaReembolsoId: instance.id,
    causeId: reportCode,
    note: textFieldDelivery,
    // eslint-disable-next-line camelcase
    delivery_id: deliveryId
  };

  const clearForm = () => {
    setTextFieldDelivery('');
    setReportCode('');
  };

  const handleSubmit = () => {
    API.post(`/auth/ms-ticket/v1/tickets/b2w/report-problem`, postData)
      .then(() => {})
      .catch(() => {
        enqueueSnackbar(t('i18n.ticket.B2W.REPORT_PROBLEM_FAIL'), {
          variant: 'error'
        });
      })
      .finally(() => {
        closeParent();
        clearForm();
      });
  };

  const handleReportChange = e => {
    setReportCode(e.target.value);
  };

  return (
    <AcerModal
      fullWidth
      maxWidth="md"
      open={openState}
      modalTitle={t('i18n.b2w.modal.reportproblem.TITLE')}
      onClose={closeParent}
    >
      <Card>
        <CardContent>
          <Grid container>
            <ModalGrid item lg={12}>
              <ModalText>
                {t('i18n.b2w.modal.postdelivery.HELLO')},{' '}
                {instance.customerName}
              </ModalText>
              <ModalText>
                {instance.type === 'Reembolso'
                  ? t('i18n.b2w.modal.reportproblem.YOUR_SOLICITATION_REFUND')
                  : t(
                      'i18n.b2w.modal.reportproblem.YOUR_SOLICITATION_EXCHANGE'
                    )}
              </ModalText>
            </ModalGrid>
            <ModalGrid item lg={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <ModalText>
                        {t('i18n.b2w.modal.postdelivery.PRODUCT_TITLE')}
                      </ModalText>
                    </TableCell>
                    <CustomTableCell>
                      <ModalText>
                        {t('i18n.b2w.modal.postdelivery.QUANTITY')}
                      </ModalText>
                    </CustomTableCell>
                    <CustomTableCell>
                      <ModalText>
                        {t('i18n.b2w.modal.postdelivery.VALUE')}
                      </ModalText>
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {instance?.originalItems?.map(product => (
                    <TableRow key={product.id}>
                      <TableCell>
                        <ModalText>{product.name}</ModalText>
                      </TableCell>
                      <CustomTableCell>
                        <ModalText>{product.quantity}</ModalText>
                      </CustomTableCell>
                      <CustomTableCell>
                        <ModalText>
                          {Number(product.salePrice).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          })}
                        </ModalText>
                      </CustomTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ModalGrid>

            <ModalGrid
              item
              container
              alignItems="center"
              justifyContent="flex-start"
            >
              <ModalGrid item lg={4}>
                <Typography>
                  {t('i18n.b2w.modal.reportproblem.REPORT')}:
                </Typography>
              </ModalGrid>
              <ModalGrid item lg={8}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className="product-select"
                >
                  <InputLabel>
                    {t('i18n.b2w.phrases.WHERE_IS_PRODUCT.IN_TRANSPORT_SELECT')}
                  </InputLabel>
                  <Select
                    label={t('i18n.newAutomation.TYPE_OF_ACTION')}
                    onChange={handleReportChange}
                    value={reportCode}
                  >
                    <MenuItem value="131">
                      {t('i18n.b2w.modal.reportproblem.REPORT_CODE131')}
                    </MenuItem>
                    <MenuItem value="133">
                      {t('i18n.b2w.modal.reportproblem.REPORT_CODE133')}
                    </MenuItem>
                    <MenuItem value="134">
                      {t('i18n.b2w.modal.reportproblem.REPORT_CODE134')}
                    </MenuItem>
                    <MenuItem value="135">
                      {t('i18n.b2w.modal.reportproblem.REPORT_CODE135')}
                    </MenuItem>
                    <MenuItem value="136">
                      {t('i18n.b2w.modal.reportproblem.REPORT_CODE136')}
                    </MenuItem>
                    <MenuItem value="137">
                      {t('i18n.b2w.modal.reportproblem.REPORT_CODE137')}
                    </MenuItem>
                    <MenuItem value="138">
                      {t('i18n.b2w.modal.reportproblem.REPORT_CODE138')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </ModalGrid>
            </ModalGrid>

            <ModalGrid item lg={12}>
              <ModalText>
                {t('i18n.b2w.modal.reportproblem.SEE_BELOW')}
              </ModalText>
              <ModalTextField
                multiline
                className="message-textarea"
                rows={8}
                size="small"
                fullWidth
                value={textFieldDelivery}
                onChange={e => setTextFieldDelivery(e.target.value)}
                variant="outlined"
              />
            </ModalGrid>
            <ModalGridButton item lg={12}>
              <Button
                disabled={reportCode === ''}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                {t('i18n.b2w.modal.postdelivery.SUBMIT_INFORMATIONS')}
              </Button>
            </ModalGridButton>
          </Grid>
        </CardContent>
      </Card>
    </AcerModal>
  );
};

export default ModalReportProblem;
