import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';

import { useSelector } from 'react-redux';
import { ChatHeader } from './NewChatHeader';
import { ChatContext } from './NewChatContext';
import { NewClientDetails } from './components/ClientDetails.component';
import { NewChatVisitors } from './NewChatVisitors';
import { NewChatMoreInformations } from './NewChatMoreInformations';
import { NewChatHistoricChats } from './NewChatHistoricChats';
import { NewChatHistoricChatDetails } from './NewChatHistoricChatDetails';

import API from '../../../services/api';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      className="customTabs"
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const HistoricChatContext = ({ clientInAttendance }) => {
  const [previousChatsView, setPreviousChatsView] = useState('list');
  const [chatDetail, setChatDetail] = useState({});

  return (
    <>
      {previousChatsView === 'list' ? (
        <NewChatHistoricChats
          clientInAttendance={clientInAttendance}
          setPreviousChatsView={setPreviousChatsView}
          setChatDetail={setChatDetail}
        />
      ) : (
        <NewChatHistoricChatDetails
          setPreviousChatsView={setPreviousChatsView}
          id={chatDetail.hashConnection}
        />
      )}
    </>
  );
};

const NewChatDetailsContext = ({ socket }) => {
  const disconnect = useSelector(state => state.disconnectChatReducer);
  const showVisitors = useSelector(state => state.showVisitorsReducer);
  const lastMessage = useSelector(state => state.lastMessageReducer);
  const chatTabView = useSelector(state => state.changeTabViewChatReducer);
  const clientInAttendanceNow = useSelector(
    state => state.clientInAttendanceNowReducer
  );

  const [showDetails, setShowDetails] = useState(false);
  const [showHistoric, setShowHistoric] = useState(false);
  const [chatTabValue, setChatTabValue] = useState('currentChat');
  const [productTagOptions, setProductTagOptions] = useState([]);
  const [campaingTagOptions, setCampaingTagOptions] = useState([]);
  const [clientData, setClientData] = useState({});
  const [clientInAttendance, setClientInAttendance] = useState({});

  const loadProductsFromVtex = () => {
    API.get('/auth/ms-chat/productFromVtex/findAll').then(response => {
      const products = response.data;
      setProductTagOptions(products);
    });
  };

  const loadCampaings = () => {
    API.get('/auth/lambda/campaing/get-campaigns-available').then(response => {
      const ticketcampaigns = response.data;
      const newTicketcampaigns = ticketcampaigns?.map(item => {
        return {
          ...item,
          _id: { $oid: item.campaign_id },
          // eslint-disable-next-line
          tag_name: item.campaign_name
        };
      });

      setCampaingTagOptions(newTicketcampaigns);
    });
  };

  const handleGetCurrentClient = async set => {
    if (clientInAttendance.hashConnection) {
      try {
        const clientHistory = await API.get(
          `no-auth/ms-chat/existing-chat/${clientInAttendance.hashConnection}`
        );
        set(clientHistory.data);
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (disconnect) {
      setShowDetails(false);
      setShowHistoric(false);
    }
  }, [disconnect]);

  useEffect(() => {
    setClientInAttendance(clientInAttendanceNow);
  }, [clientInAttendanceNow]);

  useEffect(() => {
    handleGetCurrentClient(setClientData);
  }, [clientInAttendance]);

  useEffect(() => {
    if (chatTabValue === 'chatDetails') {
      handleGetCurrentClient(setClientData);
    }
  }, [chatTabValue]);

  useEffect(() => {
    if (chatTabView) {
      setChatTabValue('currentChat');
    }
  }, [chatTabView]);

  useEffect(() => {
    loadProductsFromVtex();
    loadCampaings();
  }, []);

  const getXsSize = () => {
    if (!showDetails && !showHistoric) {
      return 12;
    }
    if (showDetails && !showHistoric) {
      return 9;
    }
    return 6;
  };

  return (
    <Grid container>
      {showVisitors ? (
        <Grid item xs={12}>
          <NewChatVisitors
            socket={socket || undefined}
            clientInAttendance={clientInAttendance}
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <ChatHeader
              socket={socket || undefined}
              clientInAttendance={clientInAttendance}
              chatTabValue={chatTabValue}
              setChatTabValue={setChatTabValue}
              setShowDetails={setShowDetails}
              showDetails={showDetails}
              showHistoric={showHistoric}
              setShowHistoric={setShowHistoric}
            />
            <TabPanel value={chatTabValue} index="currentChat">
              <Grid container>
                <Grid item xs={getXsSize()}>
                  <ChatContext
                    socket={socket || undefined}
                    clientInAttendance={clientInAttendance}
                  />
                </Grid>
                {showDetails && (
                  <Grid item xs={showDetails && !showHistoric ? 3 : 6}>
                    <NewClientDetails
                      setClientInAttendance={setClientInAttendance}
                      socket={socket || undefined}
                      clientInAttendance={clientInAttendance}
                      setShowDetails={setShowDetails}
                      showDetails={showDetails}
                      showHistoric={showHistoric}
                      setShowHistoric={setShowHistoric}
                    />
                  </Grid>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={chatTabValue} index="chatDetails">
              <NewChatMoreInformations
                clientInAttendance={clientInAttendance}
                lastMessage={lastMessage}
                productTagOptions={productTagOptions}
                campaingTagOptions={campaingTagOptions}
                clientData={clientData}
              />
            </TabPanel>
            <TabPanel value={chatTabValue} index="chatHistory">
              <HistoricChatContext clientInAttendance={clientInAttendance} />
            </TabPanel>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export { NewChatDetailsContext };
