import { createGlobalStyle } from 'styled-components';
import { COLORS } from './settings/colors.config';
import { spacing } from './settings/spacing.config';

export default createGlobalStyle`

  body{
    overflow-y: hidden
  }

  #root > div{
    height: 100%
  }

  .table-clickable tbody tr {
    cursor: pointer;
  }
 
  p {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  a {
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline
    }
  }

  .MuiBreadcrumbs-li {
    a {
      color: ${COLORS.greenText}
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .MuiBreadcrumbs-ol {
    margin-bottom: 24px;
  }

  .white-text {
    color: #fff!important;
  }

  .justify-end {
    justify-content: flex-end
  }

  .justify-start {
    justify-content: flex-start
  }

  .ml-3 {
    margin-left: ${spacing[2]}px;
  }
  .mr-3 {
    margin-right: ${spacing[2]}px;
  }

  .mb-2 {
    margin-bottom: ${spacing[1]}px;
  }

  .mb-3 {
    margin-bottom: ${spacing[2]}px;
  }

  .mY-3 {
    margin: ${spacing[2]}px 0;
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: 0.6;
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator:hover {
    opacity: 1
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: 0.6;
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator:hover {
    opacity: 1
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: 0.6;
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator:hover {
    opacity: 1
  }

  input[type="date"]{
    font-size: 12px;
  }

  .PrivateRadioButtonIcon-root-286 {
    background: #fff;
    border-radius: 1000px;
}

`;
