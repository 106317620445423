import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../../styles/settings/colors.config';

const useStyles = makeStyles(() => ({
  processItem: {
    color: COLORS.greenText,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const ProcessList = ({ items }) => {
  const openProtocol = item => {
    window.open(`/legal-process/${item}`, '_blank');
  };
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <span>
        {t('i18n.legalprotocol.PROCESSES')}:{' '}
        {items.map((item, i) => (
          <span
            className={classes.processItem}
            onClick={() => openProtocol(item.idProcessoConecta)}
            aria-hidden="true"
          >
            {`${item.idProcessoB2W}${i !== items.length - 1 ? ', ' : '.'}`}
          </span>
        ))}
      </span>
    </>
  );
};

export default ProcessList;
