import {
  Select,
  ListItemText,
  ListItemAvatar,
  ListItem,
  TextField
} from '@material-ui/core';
import styled from 'styled-components';
import { COLORS } from '../../../styles/settings/colors.config';

export const CustomSelect = styled(Select)`
  .MuiFormControl-root.MuiFormControl-fullWidth {
    height: 40px;
  }

  label {
    top: -8px;
  }
`;

export const CustomListItemText = styled(ListItemText)`
  span {
    font-weight: ${props => props.selectedcolor && '500'};
    text-transform: capitalize !important;
    color: ${COLORS.grayText};
  }
`;

export const CustomListItemAvatar = styled(ListItemAvatar)`
  min-width: 43px;
`;

export const CustomListItem = styled(ListItem)`
  padding-top: 5px;
  padding-bottom: 4px;
`;

export const CustomTextField = styled(TextField)`
  .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 12px) scale(0.8);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }

  .PrivateNotchedOutline-legendNotched-46 {
    max-width: 1000px;
    transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
  }
`;
