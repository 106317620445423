import React from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';

const IframeWrapper = styled.div`
  width: 100%;
  height: 100%;
  zoom: 110%;
  overflow: hidden;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;

const DepartmentDetailsIframe = () => {
  const { id, department } = useParams();
  return (
    <IframeWrapper>
      <Iframe
        src={`${
          process.env.REACT_APP_NEW_CONNECTA
        }/department/${department}/details/${id}?refreshToken=${localStorage.getItem(
          'refreshToken'
        )}`}
        allow="clipboard-read;clipboard-write"
      />
    </IframeWrapper>
  );
};

export default DepartmentDetailsIframe;
