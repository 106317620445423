/* eslint-disable */
import { Subject } from 'rxjs';
import config from '../../../config';
import AuthService from '../../../auth/auth.service';
import { searchB2bOnCurrentUrl } from '../../../utils/b2bHelper';

class ChatConnection {
  static instance = null;

  static haveError = false;

  autoReconnectInterval = 3 * 1000;

  constructor() {
    this.newchatSubject = new Subject();
    this.closeChatSubject = new Subject();
    this.errorChatSubject = new Subject();
    this.url = config.websocket.chaturl;
    this.identificationMsg = { action: 'sendmessage', identifier: searchB2bOnCurrentUrl() ? 'abo_b2b' : 'abo_b2c' };
    sessionStorage.removeItem('connected');
    this.connectChat();
  }

  connectChat = async status => {
    const token = localStorage.getItem('accessToken');
    this.socket =
      token &&
      new WebSocket(`${this.url}?authorization=${token}&status=${status}`);
    if (this.socket) {
      await AuthService.refreshToken();

      this.socket.addEventListener('close', function () {
        sessionStorage.setItem('connectedServer', 'false');
      });

      this.socket.onopen = event => {
        this.onConnectionOpen(event);
        this.haveError = false;
        sessionStorage.setItem('connected', 'true');
      };
      this.socket.onerror = e => {
        // this.onerror(e);
        sessionStorage.setItem('connected', 'false');
      };
    }
  };

  onerror() {
    setTimeout(async () => {
      await AuthService.refreshToken();
      this.connectChat();
    }, this.autoReconnectInterval);
  }

  async heartBeat() {
    while (true) {
      const wait = seconds =>
        new Promise(resolve => {
          setTimeout(resolve, seconds);
        });
      await wait(60000).then(() => {
        this.send({ action: 'heartbeat' });
      });
    }
  }

  static getConnection() {
    if (this.instance) {
      return this.instance;
    }
    this.instance = new ChatConnection();
    return this.instance;
  }

  static removeInstance() {
    if (this.instance) {
      this.instance = null;
    }
  }

  static getError() {
    return this.haveError;
  }

  static newGetConnection() {
    if (this.instance) {
      return this.instance;
    }
  }

  onConnectionOpen() {
    this.send(this.identificationMsg);
    this.setOnMessage();
    this.setOnClose();
    this.setOnError();
    this.heartBeat();
  }

  send(message, callback) {
    this.waitForConnection(() => {
      this.socket.send(JSON.stringify(message));
      if (typeof callback !== 'undefined') {
        callback();
      }
    }, 1000);
  }

  close() {
    this.socket && this.socket.close();
  }

  sendMessageTo(attendantMessage, hashConnection, sendFile) {
    const {
      message,
      login,
      urlAnexoRaw,
      attachment,
      heart_beat
    } = attendantMessage;

    this.send({
      action: 'sendmessage',
      data: message,
      to: hashConnection,
      heart_beat,
      login,
      name_attachment: sendFile && attachment.name,
      url_anexo_raw: urlAnexoRaw
    });
  }

  sendMessageControl(attendantMessage, hashConnection) {
    const { message } = attendantMessage;

    this.send({
      action: 'sendcontrol',
      typing: message,
      hash_connection_chat: hashConnection,
      to: hashConnection
    });
  }

  sendHeartBeatTo(attendantMessage, hashConnection, sendFile) {
    const {
      message,
      login,
      urlAnexoRaw,
      attachment,
      heart_beat
    } = attendantMessage;

    this.send({
      action: 'sendcontrol',
      data: message,
      to: hashConnection,
      heart_beat,
      login,
      name_attachment: sendFile && attachment.name,
      url_anexo_raw: urlAnexoRaw
    });
  }

  waitForConnection(callback, interval) {
    if (this.socket.readyState === 1) {
      callback();
    } else {
      setTimeout(() => {
        this.waitForConnection(callback, interval);
      }, interval);
    }
  }

  setOnMessage() {
    this.socket.onmessage = event => {
      this.newchatSubject.next(event);
    };
  }

  setOnClose() {
    this.socket.onclose = event => {
      this.closeChatSubject.next(event);
    };
  }

  setOnError() {
    this.socket.onerror = event => {
      this.errorChatSubject.next(event);
    };
  }
}

export default ChatConnection;
