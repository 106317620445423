import { Card, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

import MLColorGraph from './MLColorGraph';
import CardCustomTitle from '../components/CardCustomTitle';

const BuyersScoreCard = ({ score, period }) => {
  const title = 'Nota que os compradores veem';
  const descriptionHint = null;

  return (
    <>
      <Card
        style={{
          width: '100%',
          marginBottom: '10px',
          borderRadius: '5px',
          marginTop: '10px',
          minHeight: '160px'
        }}
      >
        {score ? (
          <>
            <CardCustomTitle
              {...{ title, descriptionHint }}
              custompadding="true"
            />
            <div
              style={{
                padding: '16px 24px',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px'
              }}
            >
              <Typography
                style={{
                  color: '#A9A9A9',
                  size: '14px',
                  lineHeight: '16px',
                  fontWeight: '500',
                  marginTop: '-25px'
                }}
              >
                No momento eles te veem como{' '}
                <span style={{ color: '#5A5A5A' }}>MercadoLíder Platinum</span>
              </Typography>

              <MLColorGraph {...{ score }} />
              {period}
            </div>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '10px'
            }}
          >
            <Alert severity="info" style={{ width: '100%' }}>
              Nenhum dado encontrado.
            </Alert>
          </div>
        )}
      </Card>
    </>
  );
};

export default BuyersScoreCard;
