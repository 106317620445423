import moment from 'moment';
import { defaultDateFormat } from './convertDate';

const WEEK_INTERVAL = {
  startDate: defaultDateFormat(
    moment()
      .subtract(7, 'days')
      .startOf('day')
      .add(3, 'hours')
  ),
  endDate: defaultDateFormat(
    moment()
      .subtract(1, 'days')
      .startOf('day')
      .add(1, 'days')
      .add(2, 'hours')
      .add(59, 'minutes')
      .add(59, 'seconds')
  )
};

export { WEEK_INTERVAL };
